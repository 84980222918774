<style scoped lang="scss">

.deal-maintenance-package-row {
  display: grid;
  grid-template-columns: 1fr 1fr 130px 64px;

  &.loading {
    .column {
      &.actions-column {
        ::v-deep .movida-common__app-button {
          color: $gray-2;

          &:hover {
            color: $orange;
          }
        }
      }

      .text,
      .link ::v-deep .app-span {
        color: $gray-2;
      }
    }
  }

  .column {
    padding: 0 12px;
    border-bottom: 1px solid $light-gray-3;
    height: 48px;
    overflow: hidden;

    &.maintenance-package-column,
    &.vehicle-model-group-column {
      .discarded-icon {
        margin-right: 4px;
      }
    }

    &.price-column {
      .text {
        font-family: $secondary-font;
      }
    }

    .tag {
      margin-left: 4px;
    }

    ::v-deep .movida-common__app-button {
      white-space: unset;
    }

    .button {
      overflow: hidden;
    }

    .text,
    .link ::v-deep .app-span {
      font-family: $primary-font;
      font-weight: 400;
      font-size: 14px;
      color: $dark-gray;
      text-align: initial;
    }

    .link ::v-deep .app-span:hover {
      color: $orange;
    }
  }
}

</style>


<template lang="pug">

  .deal-maintenance-package-row(:class="{ loading }")
    .column.maintenance-package-column.flex.vertical-center
      discarded-icon.discarded-icon(v-if="maintenancePackage.$discarded")
      app-button.button(
        theme="link",
        :to="maintenancePackageRoute"
      )
        app-span.link(
          :value="maintenancePackage.name",
          crop,
          data-testid="dealMaintenancePackageMaintenancePackageName"
        )
      id-tag.tag(:value="maintenancePackage.id", data-testid="dealMaintenancePackageMaintenancePackageId")

    .column.vehicle-model-group-column.flex.vertical-center
      discarded-icon.discarded-icon(v-if="vehicleModelGroup.$discarded")
      app-button.button(
        theme="link",
        :to="vehicleModelGroupRoute",
      )
        app-span.link(
          :value="vehicleModelGroup.name",
          crop,
          data-testid="dealMaintenancePackageVehicleModelGroupName"
        )
      id-tag.tag(:value="vehicleModelGroup.id", data-testid="dealMaintenancePackageVehicleModelGroupId")

    .column.price-column.flex.vertical-center
      span.text(
        data-testid="dealMaintenancePackageLaborPrice"
      ) {{ dealMaintenancePackage.formattedPrice }}

    .column.actions-column.flex.vertical-center
      .actions-column
        popover-menu(
          v-if="popoverMenuAuthorization",
          :show.sync="showPopover",
          data-testid="popoverMenu"
        )
          popover-menu-button(
            v-if="$can('read', 'Deal')",
            icon="fal fa-eye",
            :to="showRoute",
            :text="$t('.actions.btn.show.label')",
            @close-menu="showPopover = false"
          )

          popover-menu-button(
            v-if="$can('update', 'Deal') && editable"
            icon="fal fa-pencil",
            :to="editRoute",
            :text="$t('.actions.btn.edit.label')",
            @close-menu="showPopover = false"
          )

          destroy-action.destroy-button.popover-menu-button(
            v-if="editable"
            :name="maintenancePackage.name",
            :deal-maintenance-package-id="dealMaintenancePackage.id"
            size="normal",
            border="square",
            @click.native="showPopover = false"
            @destroy="$emit('destroy')"
          )
            .icon-content
              i.icon.fal.fa-trash-alt
            span {{ $t('.actions.btn.destroy.label') }}

</template>


<script>

import DestroyAction from "./destroy-action"

export default {
  components: {
    DestroyAction
  },

  props: {
    dealMaintenancePackage: { type: Object, required: true },
    editable:               { type: Boolean, required: true },
    loading:                { type: Boolean, default: false }
  },

  data() {
    return {
      i18nScope: "suppliers.deals.maintenance-packages.index.deal-maintenance-package-row",

      supplierId: this.$params.asInteger(this.$route.params.id),
      dealId:     this.$params.asInteger(this.$route.params.dealId),

      showPopover: false
    }
  },

  computed: {
    maintenancePackage() {
      return _.get(this.dealMaintenancePackage, "maintenancePackage")
    },

    vehicleModelGroup() {
      return _.get(this.dealMaintenancePackage, "vehicleModelGroup")
    },

    popoverMenuAuthorization() {
      return ["read", "update"].some(action => this.$can(action, "Deal"))
    },

    showRoute() {
      return {
        name:   "supplierDealMaintenancePackage",
        params: {
          id:                   this.supplierId,
          dealId:               this.dealId,
          maintenancePackageId: this.dealMaintenancePackage.id
        }
      }
    },

    editRoute() {
      return {
        name:   "editSupplierDealMaintenancePackage",
        params: {
          id:                   this.supplierId,
          dealId:               this.dealId,
          maintenancePackageId: this.dealMaintenancePackage.id
        }
      }
    },

    maintenancePackageRoute() {
      return {
        name:   "supplierDealMaintenancePackageMaintenancePackage",
        params: {
          id:                   this.supplierId,
          dealId:               this.dealId,
          maintenancePackageId: this.dealMaintenancePackage.id
        }
      }
    },

    vehicleModelGroupRoute() {
      return {
        name:   "supplierDealMaintenancePackageVehicleModelGroup",
        params: {
          id:                   this.supplierId,
          dealId:               this.dealId,
          maintenancePackageId: this.dealMaintenancePackage.id
        }
      }
    }
  }
}

</script>

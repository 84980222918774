<!--
# AttachIcon
Exibe um ícone correspondente ao tipo do anexo, com variadas cores.
-->

<template lang="pug">
  i.attach-icon.fas(:class="iconClass", :style="iconStyle")
</template>

<script>
const ICONS = {
  audio:        "fa-volume",
  compressed:   "fa-file-archive",
  doc:          "fa-file",
  email:        "fa-envelope",
  file:         "fa-file",
  html:         "fa-file",
  image:        "fa-image",
  pdf:          "fa-file-pdf",
  presentation: "fa-presentation",
  spreadsheet:  "fa-file-excel",
  text:         "fa-file",
  video:        "fa-video"
}

const COLORS = {
  audio:        "#C291D3",
  compressed:   "#7D61CE",
  doc:          "#959595",
  email:        "#959595",
  file:         "#959595",
  html:         "#959595",
  image:        "#CE9C61",
  pdf:          "#AD6767",
  presentation: "#F04420",
  spreadsheet:  "#93AD67",
  text:         "#959595",
  video:        "#677BAD"
}

export default {
  name: "AttachIcon",

  props: {
    icon: { type: String, default: "file", validator: (value) => value in ICONS }
  },

  computed: {
    iconClass() {
      return ICONS[this.icon] || ICONS.file
    },

    iconStyle() {
      return { color: COLORS[this.icon] || COLORS.file }
    }
  }
}
</script>

<style lang="scss" scoped>

</style>


<template lang="pug">

  .app-timestamp
    span(v-tooltip.bottom="tooltip") {{ $fromNow(timestamp) }}

</template>


<script>

export default {
  name: "AppTimestamp",

  props: {
    timestamp: { type: String, required: true }
  },

  computed: {
    tooltip() {
      return this.$standardTime(this.timestamp)
    }
  }
}

</script>

// Ability
import { ability } from "@/modules/ability"

// Routes
import NewVehicleModelGroup from "@/views/vehicle-model-groups/new.vue"
import VehicleModelGroup from "@/views/vehicle-model-groups/show.vue"
import EditVehicleModelGroup from "@/views/vehicle-model-groups/edit.vue"
import VehicleModelGroups from "@/views/vehicle-model-groups/index.vue"
import NewVehicleModelGroupMaintenanceTable from "@/views/vehicle-model-groups/maintenance-tables/new.vue"
import EditVehicleModelGroupMaintenanceTable from "@/views/vehicle-model-groups/maintenance-tables/edit.vue"
import VehicleModelGroupMaintenanceTable from "@/views/vehicle-model-groups/maintenance-tables/show.vue"

import VehicleModelGroupDefaulTab from "@/views/vehicle-model-groups/default-tab/show.vue"
import VehicleModelGroupMaintenanceTables from "@/views/vehicle-model-groups/maintenance-tables/index.vue"
import VehicleModelGroupSuppliers from "@/views/vehicle-model-groups/suppliers/index.vue"

export default [
  {
    path:      "/vehicle-model-groups/new",
    name:      "newVehicleModelGroup",
    component: NewVehicleModelGroup,
    meta:      {
      auth:        true,
      permissions: [() => ability.can("create", "VehicleModelGroup")]
    }
  },

  {
    path:      "/vehicle-model-groups/:id",
    component: VehicleModelGroup,
    meta:      {
      auth:        true,
      permissions: [() => ability.can("read", "VehicleModelGroup")]
    },

    children: [
      {
        path:      "",
        name:      "vehicleModelGroup",
        component: VehicleModelGroupDefaulTab
      },
      {
        path:      "maintenance-tables",
        name:      "vehicleModelGroupMaintenanceTables",
        component: VehicleModelGroupMaintenanceTables
      },
      {
        path:      "suppliers",
        name:      "vehicleModelGroupSuppliers",
        component: VehicleModelGroupSuppliers
      },
      {
        path:      "maintenance-tables/new",
        name:      "newVehicleModelGroupMaintenanceTable",
        component: NewVehicleModelGroupMaintenanceTable,
        meta:      {
          hideVehicleModelGroupTabs:    true,
          hideVehicleModelGroupActions: true,
          permissions:                  [() => ability.can("update", "VehicleModelGroup")]
        }
      },
      {
        path:      "maintenance-tables/:maintenanceTableId/edit",
        name:      "editVehicleModelGroupMaintenanceTable",
        component: EditVehicleModelGroupMaintenanceTable,
        meta:      {
          hideVehicleModelGroupTabs:    true,
          hideVehicleModelGroupActions: true,
          permissions:                  [() => ability.can("update", "VehicleModelGroup")]
        }
      },
      {
        path:      "maintenance-tables/:maintenanceTableId",
        name:      "vehicleModelGroupMaintenanceTable",
        component: VehicleModelGroupMaintenanceTable
      }
    ]
  },

  {
    path:      "/vehicle-model-groups/:id/edit",
    name:      "editVehicleModelGroup",
    component: EditVehicleModelGroup,
    meta:      {
      auth:        true,
      permissions: [() => ability.can("update", "VehicleModelGroup")]
    }
  },

  {
    path:      "/vehicle-model-groups",
    name:      "vehicleModelGroups",
    component: VehicleModelGroups,
    meta:      {
      auth:        true,
      permissions: [() => ability.can("index", "VehicleModelGroup")]
    }
  }
]

import Vue from "vue"

const viewStore = Vue.observable({
  appError: null,

  get hasError() {
    return !!this.appError
  }
})

export default viewStore

import { AbilityBuilder } from "@caiena/abilities/dist/abilities.umd"

export default class Role {
  static get roleName() {
    return _.snakeCase(this.name.replace("Role", ""))
  }

  static define(...args) {
    return AbilityBuilder.define(...args)
  }
}

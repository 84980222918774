<style scoped lang="scss">

.row + .row {
  margin-top: 24px;
}

.section + .section {
  margin-top: 24px;
  padding-top: 24px;
  border-top: 1px solid $light-gray-3;
}

.type {
  .label {
    display: block;
    margin-bottom: 8px;
    font-size: 14px;
    line-height: 17px;
    font-weight: 500;
    font-family: $secondary-font;
  }

  .supplier-icon {
    margin-right: 8px;
  }
}

.hierarchy-radio {
  height: 100%;
}

.title {
  font-weight: bold;
  font-size: 20px;
  margin-bottom: 4px;
  font-family: $secondary-font;
  font-weight: 500;
}

.subtitle {
  font-family: $primary-font;
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 22px;
  margin-bottom: 16px;
}

.sector-multiple-select-field {
  margin-bottom: 24px;
}

</style>


<template lang="pug">

  .form-fields
    .section.basic
      h2.title {{ $t('.sections.basic.title') }}
      h3.subtitle {{ $t('.sections.basic.subtitle') }}

      ui-row.row
        ui-col(:span="6")
          input-field(
            name="supplier[name]",
            v-model="resource.name",
            :loading="submitting",
            :disabled="fetching",
            :placeholder="$t('.fields.name.placeholder')",
            :errors="errors.name",
            data-test="nameInput"
          )

        ui-col(:span="6")
          input-field(
            name="supplier[tradingName]",
            v-model="resource.tradingName",
            :loading="submitting",
            :disabled="fetching",
            :placeholder="$t('.fields.tradingName.placeholder')",
            :errors="errors.tradingName",
            data-test="tradingNameInput"
          )

      ui-row.row
        ui-col(:span="3")
          supplier-classification-finder-field(
            v-model="resource.classification"
            :disabled="fetching || submitting"
            :errors="errors.classification"
            :listbox-config="{ width: 302 }"
            name="supplier[classification]"
          )

        ui-col(:span="3")
          input-field(
            name="supplier[im]",
            v-model="resource.im",
            :loading="submitting",
            :disabled="fetching",
            :placeholder="$t('.fields.im.placeholder')",
            :errors="errors.im",
            disabled,
            data-test="imInput"
          )
        ui-col(:span="3")
          input-field(
            name="supplier[ie]",
            v-model="resource.ie",
            :loading="submitting",
            :disabled="fetching",
            :placeholder="$t('.fields.ie.placeholder')",
            :errors="errors.ie",
            disabled,
            data-test="ieInput"
          )
        ui-col(:span="3")
          input-field(
            name="supplier[sapId]",
            v-model="resource.sapId",
            :loading="submitting",
            :placeholder="$t('.fields.sapId.placeholder')",
            :errors="errors.sapId",
            disabled,
            data-test="sapIdInput"
          )

      ui-row.row
        ui-col(:span="6")
          input-field(
            name="supplier[cnae]",
            :label="$t('.fields.cnae.label.main')",
            v-model="resource.cnae",
            :loading="submitting",
            :disabled="fetching",
            :placeholder="$t('.fields.cnae.placeholder')",
            :errors="errors.cnae",
            data-test="cnaeInput",
            optional
            disabled
          )

        ui-col(:span="6")
          ui-row.row
            ui-col(:span="12")
              sector-multiple-select-field.sector-multiple-select-field(
                name="supplier[sectors]",
                :placeholder="$t('.fields.sectors.placeholder')",
                :loading="submitting",
                :disabled="fetching",
                :errors="errors.sectors",
                v-model="sectors",
                hide-create-option,
                data-test="sectorsInput"
              )

              sectors-list(
                :loading="fetching",
                :sectors="resource.sectors",
                :empty-text="$t('.fields.sectors.empty')"
                destroyable,
                @destroy="onRemoveSector"
              )

    .section.address
      h2.title {{ $t('.sections.address.title') }}
      h3.subtitle {{ $t('.sections.address.subtitle') }}

      ui-row.row
        ui-col(:span="12")
          loading-lines(v-if="fetching", :height="40", :min="100", :max="100")
          address-place-field(
            v-else,
            name="address[placeId]",
            v-model="address.placeId",
            :errors="addressErrors.placeId",
            :initialText="address.addressLine1",
            :loading="submitting",
            hide-label
          )

      ui-row.row
        ui-col(:span="6")
          input-field(
            name="address[addressLine2]",
            v-model="address.addressLine2",
            :loading="submitting",
            :disabled="fetching",
            :placeholder="$t('.fields.addressLine2.placeholder')",
            :errors="addressErrors.addressLine2"
          )
        ui-col(:span="6")

    .section.service-order-contact
      h2.title  {{ $t('.sections.serviceOrderContact.title') }}
      h3.subtitle  {{ $t('.sections.serviceOrderContact.subtitle') }}

      ui-row.row
        ui-col(:span="6")
          input-field(
            name="supplier[serviceOrderContactName]",
            v-model="resource.serviceOrderContactName",
            :loading="submitting",
            :disabled="fetching",
            :placeholder="$t('.fields.serviceOrderContactName.placeholder')",
            :errors="errors.serviceOrderContactName",
            data-test="serviceOrderContactNameInput"
          )

        ui-col(:span="6")
          input-field(
            name="supplier[serviceOrderEmail]",
            v-model="resource.serviceOrderEmail",
            :loading="submitting",
            :disabled="fetching",
            :placeholder="$t('.fields.serviceOrderEmail.placeholder')",
            :errors="errors.serviceOrderEmail",
            data-test="serviceOrderEmailInput"
          )

    .section.contacts
      contacts-list(
        v-model="resource.contacts",
        :fetching="fetching",
        :submitting="submitting",
        :errors="errors",
        edit
      )

</template>


<script>

// Components
import ContactsList from "./contacts/index"
import SectorMultipleSelectField from "./components/sector-multiple-select-field"
import SectorsList from "./components/sectors-list"
import SupplierClassificationFinderField from "./components/supplier-classification-finder-field.vue"

// Models
import models from "@/models"
const { Sector } = models

export default {
  components: {
    ContactsList,
    SectorMultipleSelectField,
    SectorsList,
    SupplierClassificationFinderField
  },

  props: {
    fetching:   { type: Boolean, default: false },
    submitting: { type: Boolean, default: false },
    errors:     { type: Object, default: () => ({}) },
    resource:   { type: Object, default: () => ({}) }
  },

  data() {
    return {
      i18nScope: "suppliers.form-fields"
    }
  },

  computed: {
    sectors: {
      get() {
        if (_.blank(this.resource.sectors)) return []

        return this.resource.sectors.map(sector => ({
          value: sector.id,
          label: sector.name,
          data:  new Sector(sector)
        }))
      },

      set(sectors) {
        // eslint-disable-next-line vue/no-mutating-props
        this.resource.sectors = sectors.map(sector => sector.data)
      }
    },

    address() {
      return _.get(this.resource, "address") || {}
    },

    addressErrors() {
      return this.errors.addressAttributes || {}
    }
  },

  methods: {
    onRemoveSector(removedSector) {
      const sectors = [...this.resource.sectors]
      const sectorIndex = sectors.findIndex(sector => sector.id === removedSector.id)

      if (sectorIndex < 0) return

      sectors.splice(sectorIndex, 1)
      // eslint-disable-next-line vue/no-mutating-props
      this.resource.sectors = sectors
    }
  }
}

</script>

<style lang="scss" scoped>

.vehicle-model-group-maintenance-table-row {
  display: grid;
  grid-template-columns: 80px 2fr 2fr 1fr 64px;

  &.loading {
    .column {
      .id-tag {
        color: $gray-2;
      }

      &.actions-column {
        ::v-deep .movida-common__app-button {
          color: $gray-2;

          &:hover {
            color: $orange;
          }
        }
      }

      .text,
      .link ::v-deep .app-span {
        color: $gray-2;
      }
    }
  }

  .column {
    padding: 0 12px;
    border-bottom: 1px solid $light-gray-3;
    height: 56px;
    overflow: hidden;

    .button {
      overflow: hidden;
    }

    .text,
    .link ::v-deep .app-span {
      font-family: $primary-font;
      font-weight: 400;
      font-size: 14px;
      color: $dark-gray;
      text-align: initial;
    }

    .link ::v-deep .app-span:hover {
      color: $orange;
    }
  }
}

</style>


<template lang="pug">

  .vehicle-model-group-maintenance-table-row(:class="{ loading }")
    .column.id-column.flex.vertical-center
      id-tag.id-tag(:value="vehicleModelGroupMaintenanceTable.id", data-testid="vehicleModelGroupMaintenanceTableId")

    .column.name-column.flex.vertical-center
      app-button.button(
        theme="link",
        :to="showRoute"
      )
        app-span.link(:value="vehicleModelGroupMaintenanceTable.name", crop, data-testid="vehicleModelGroupMaintenanceTableName")

    .column.description-column.flex.vertical-center
      app-span.text(:value="vehicleModelGroupMaintenanceTable.description", crop, data-testid="vehicleModelGroupMaintenanceTableDescription")

    .column.kind-column.flex.vertical-center
      maintenance-table-kind-tag(
        :kind="vehicleModelGroupMaintenanceTable.kind",
        :muted="loading",
        data-testid="vehicleModelGroupMaintenanceTableKind"
      )

    .column.actions-column.flex.vertical-center
      popover-menu(
        v-if="popoverMenuAuthorization",
        :show.sync="showPopover",
        data-testid="popoverMenu"
      )
        popover-menu-button(
          v-if="$can('read', 'VehicleModelGroup')",
          icon="fal fa-eye",
          :to="showRoute",
          :text="$t('.actions.btn.show.label')",
          @close-menu="showPopover = false"
        )

        popover-menu-button(
          v-if="$can('update', 'VehicleModelGroup')",
          icon="fal fa-pencil",
          :to="editRoute",
          :text="$t('.actions.btn.edit.label')",
          @close-menu="showPopover = false"
        )

        destroy-action.destroy-button.popover-menu-button(
          v-if="vehicleModelGroup.id",
          :vehicle-model-group-id="vehicleModelGroup.id",
          :maintenance-table-id="vehicleModelGroupMaintenanceTable.id",
          :name="vehicleModelGroupMaintenanceTable.name",
          size="normal",
          border="square",
          @click.native="showPopover = false"
          @destroy="$emit('destroy')"
        )
          .icon-content
            i.icon.fal.fa-trash-alt
          span {{ $t('.actions.btn.destroy.label') }}

</template>


<script>

// Components
import DestroyAction from "../components/destroy-action"

export default {
  components: {
    DestroyAction
  },

  props: {
    vehicleModelGroupMaintenanceTable: { type: Object, required: true },
    loading:                           { type: Boolean, default: false }
  },

  data() {
    return {
      i18nScope: "vehicle-model-groups.maintenance-tables.index.vehicle-model-group-maintenance-table-row",

      showPopover: false
    }
  },

  computed: {
    popoverMenuAuthorization() {
      return ["read", "update"].some(action => this.$can(action, "VehicleModelGroup"))
    },

    showRoute() {
      if (!this.$can("read", "VehicleModelGroup")) return null

      return {
        name:   "vehicleModelGroupMaintenanceTable",
        params: { maintenanceTableId: this.vehicleModelGroupMaintenanceTable.id }
      }
    },

    editRoute() {
      return {
        name:   "editVehicleModelGroupMaintenanceTable",
        params: { maintenanceTableId: this.vehicleModelGroupMaintenanceTable.id }
      }
    },

    vehicleModelGroup() {
      return _.get(this.vehicleModelGroupMaintenanceTable, "vehicleModelGroup") || {}
    }
  }
}

</script>

<!--
<doc lang="markdown">
Exibe um checkbox

Como usar:
```pug
checkbox-field(
  name="model[prop]",
  label="Primário",
  v-model="resource.value"
)
```
</doc>
-->

<style lang="scss" scoped>

.checkbox-field {
  .field-label {
    display: block;
    margin-bottom: 8px;
    font-size: 14px;
    line-height: 17px;
    font-weight: 500;
    font-family: $secondary-font;
    color: $dark-gray;
    transition: color 0.1s cubic-bezier(.645,.045,.355,1);

    > * + * {
      padding-left: 4px;
    }

    &.focus {
      color: $orange;
    }

    &.error {
      color: $error;
    }
  }

  .checkbox {
    display: flex;
    align-items: center;

    .checkbox-input {
      flex-shrink: 0;
      appearance: none;
      width: 20px;
      height: 20px;
      border-radius: 4px;
      background-color: $light-gray;
      border: 1px solid $gray;
      margin: 4px 0;
      transition: all .1s;
      cursor: pointer;

      &:hover,
      &:focus,
      &:active {
        border-color: $orange;
      }

      &:active {
        background-color: $light-orange-2;
      }

      &.disabled,
      &.loading {
        background-color: $light-gray;
        border-color: $gray-3;
      }

      &.disabled {
        cursor: not-allowed;
      }

      &.loading {
        cursor: progress;
      }

      &:checked,
      &.indeterminate {
        background-color: $orange;
        border-color: $orange;
        position: relative;

        &:after {
          font-size: 14px;
          line-height: 14px;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          color: $white;
        }

        &:focus,
        &:active {
          border-color: $dark-orange-2;
        }

        &:active {
          background-color: $dark-orange-2;
        }

        &.disabled,
        &.loading {
          background-color: $light-gray-3;
          border-color: $light-gray-4;
        }
      }

      &:checked:not(.indeterminate) {
        &:after {
          font-family: 'Font Awesome 6 Pro';
          font-weight: 400;
          content: '\f00c';
        }
      }

      &.indeterminate {
        &:after {
          font-weight: 900;
          content: '—';
        }
      }
    }

    .checkbox-label {
      padding-left: 12px;
      margin-right: 12px;
      font-family: $secondary-font;
      font-weight: 300;
      cursor: pointer;

      &.disabled {
        cursor: not-allowed;
      }

      &.loading {
        cursor: progress;
      }

      &.disabled,
      &.loading {
        color: $gray-2;
      }

      &.error {
        color: $error;
      }
    }
  }

  .error-message {
    display: block;
    color: $error;
    font-size: 12px;
    line-height: 1;
    padding-top: 4px;
  }
}

.loading-lines {
  width: 20px;
  height: 20px;
}

</style>


<template lang="pug">

  .checkbox-field
    label.field-label(v-if="!hideLabel && !!label", :for="inputId", :class="{ error: hasErrors }")
      span {{ label }}
      span(v-if="optional") {{ $t('form.optional') }}

    loading-lines.loading-lines(v-if="loading", :min="100", :max="100")
    .checkbox(
      v-else
      :class="{ error: hasErrors }"
    )
      input.checkbox-input(
        type="checkbox",
        :name="name",
        :id="inputId"
        :disabled="disabled || loading",
        :checked="value",
        :class="{ disabled, loading, indeterminate }",
        @click="onClick"
      )

      slot(name="optionText", :props="{ inputId, disabled, error: hasErrors }")
        template(v-if="!hideValueText")
          label.checkbox-label(
            :for="inputId",
            :class="{ disabled, loading, error: hasErrors }"
          )
            span {{ optionText }}

    span.error-message(v-for="error in errors", :key="error") {{ error }}

</template>


<script>

import FieldMixin from "@/mixins/field"

export default {
  name: "CheckboxField",

  mixins: [FieldMixin],

  props: {
    value:         { type: Boolean, default: false },
    valueText:     { type: String, default: null },
    hideValueText: { type: Boolean, default: false },
    indeterminate: { type: Boolean, default: false },
    loading:       { type: Boolean, default: false }
  },

  computed: {
    optionText() {
      return this.valueText
        ? this.valueText
        : this.$t(`models.${this.modelName}.attributes.${this.attrName}`)
    }
  },

  methods: {
    onClick(e) {
      if (this.disabled) return

      this.$emit("input", !this.value)
    }
  }
}

</script>

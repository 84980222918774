// Base
import Base from "../Base"

// Models
import models from "@/models"
const { SupplierServiceSupport } = models

class SupplierServiceSupports extends Base {
  static get selector() {
    return "suppliers.serviceSupports"
  }

  all({ supplierId, params = {}, options = {} }) {
    this.verifyData("all", { supplierId })

    return this.action({
      method:  "get",
      url:     `/suppliers/${supplierId}/service_supports`,
      params,
      options: {
        model: SupplierServiceSupport,

        ...options
      }
    })
  }

  find({
    supplierId, serviceTypeSlug, params = {}, options = {}
  }) {
    this.verifyData("find", { supplierId, serviceTypeSlug })

    return this.action({
      method:  "get",
      url:     `/suppliers/${supplierId}/service_supports/${serviceTypeSlug}`,
      params,
      options: {
        model: SupplierServiceSupport,

        ...options
      }
    })
  }

  destroy({ supplierId, serviceTypeSlug } = {}) {
    this.verifyData("destroy", { supplierId, serviceTypeSlug })

    return this.action({
      method: "delete",
      url:    `/suppliers/${supplierId}/service_supports/${serviceTypeSlug}`
    })
  }
}

export default SupplierServiceSupports

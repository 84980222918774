<template lang="pug">

  .supplier-block-preview
    template(v-if="supplierBlock.state === 'scheduled'")
      supplier-block-scheduled-preview(:supplier-block="supplierBlock")
    template(v-if="supplierBlock.state === 'ongoing'")
      supplier-block-ongoing-preview(:supplier-block="supplierBlock")

</template>


<script>

// Components
import SupplierBlockScheduledPreview from "./supplier-block-scheduled-preview"
import SupplierBlockOngoingPreview from "./supplier-block-ongoing-preview.vue"

export default {
  components: {
    SupplierBlockScheduledPreview,
    SupplierBlockOngoingPreview
  },

  props: {
    supplierBlock: { type: Object, required: true }
  }
}

</script>

<style lang="scss" scoped>

.cancellation {
  .section {
    padding: 16px 24px;

    &:not(:first-child) {
      border-top: 1px solid $light-gray-3;
    }
  }

  .description {
    margin-top: 16px;

    ::v-deep .input-inner {
      height: 110px;
    }
  }
}

.success {
  padding: 64px 24px;

  .icon-container {
    width: 64px;
    height: 64px;

    .icon {
      font-size: 36px;
    }
  }

  .message {
    margin-top: 16px;
    font-family: $secondary-font;
    font-weight: 500;
    font-size: 38px;
  }
}

</style>


<template lang="pug">

  .service-order-cancellation-modal
    app-modal(
      :heading="$t('.heading')",
      :loading="submitting",
      :width="716",
      @close="onClose"
    )
      .cancellation
        .section
          cancellation-motive-select-field(
            v-model="cancellation.motive",
            mandatory,
            name="serviceOrderCancellation[motive]",
            :disabled="submitting",
            :errors="errors.motive"
          )

          textarea-field.description(
            v-model="cancellation.description",
            name="serviceOrderCancellation[description]",
            :disabled="submitting",
            :errors="errors.description",
            :placeholder="$t('.fields.description.placeholder')"
          )

        .section
          checkbox-field(
            v-model="cancellation.cancelTicket",
            name="serviceOrderCancellation[cancelTicket]",
            :value-text="$t('.fields.cancelTicket.label')",
            :loading="submitting",
            :disabled="submitting",
            :errors="errors.cancelTicket",
            data-testid="cancelTicketToggle"
          )

      template(#footer)
        .footer
          app-button(
            outline,
            theme="gray",
            :loading="submitting",
            @click="onClose"
          ) {{ $t('.btn.cancel.label') }}

          app-button(
            :disabled="!hasMotive"
            :loading="submitting",
            :theme="hasMotive ? 'primary' : 'gray'",
            @click="submit"
          )
            span {{ $t('.btn.confirm.label') }}
            span(slot="loading") {{ $t('.btn.confirm.loading') }}

</template>


<script>

// Mixins
import FormMixin from "@/mixins/form-mixin"

// Components
import CancellationMotiveSelectField from "./cancellation-motive-select-field.vue"

// Models
import models from "@/models"
const { ServiceOrderCancellation } = models


export default {
  components: {
    CancellationMotiveSelectField
  },

  mixins: [FormMixin],

  props: {
    serviceOrder: { type: Object, required: true }
  },

  data() {
    return {
      i18nScope: "monitoring.service-order-cancellation-modal",

      resource: new ServiceOrderCancellation()
    }
  },

  computed: {
    cancellation: {
      get()      { return this.resource  },
      set(value) { this.resource = value }
    },

    hasMotive() {
      return _.present(this.cancellation.motive)
    }
  },

  created() {
    this.initialSerializedAttributes = this.serializeAttributes()
  },

  methods: {
    // @override Form mixin
    serializeAttributes() {
      return this.resource.$serialize()
    },

    // @override Form mixin
    submitRequest() {
      let params = this.serializeAttributes()

      return this.$newSdk.monitoring.serviceOrders.cancellation
        .create({
          serviceOrderId: this.serviceOrder.$id,
          params
        })
    },

    // @override Form mixin
    onSubmitSuccess(response) {
      // XXX: usando id do Vetor (remoteId) para ficar coerente com a listagem
      let successMessage = this.$t(".notifications.submit.success", { id: this.serviceOrder.remoteId })
      this.$notifications.info(successMessage)

      this.$emit("update")
      this.$emit("close")
    },

    async onClose() {
      const isDirty = this.checkDirty()

      if (!isDirty || await this.askDirtyLeaveConfirmation()) this.$emit("close")
    }
  }
}

</script>

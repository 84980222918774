<style scoped lang="scss">

.row + .row {
  margin-top: 24px;
}

.header {
  margin-bottom: 32px;
  padding-bottom: 16px;
  border-bottom: 1px solid $light-gray-3;
}

.title {
  font-size: 30px;
  font-weight: 500;
  font-family: $secondary-font;
}

.form,
.fields {
  margin-bottom: 24px;
}

.actions {
  margin-top: 24px;
  padding-top: 24px;
  border-top: 1px solid $light-gray-3;
}

</style>


<template lang="pug">

  .new-vehicle-part
    header.header
      h1.title {{ $t('.title') }}

    form.form(@submit.prevent="submit", method="post")

      form-fields.fields(
        :submitting="submitting",
        :errors="errors",
        :resource="resource"
      )

      section.actions.flex.space-between.vertical-center.row
        app-button(
          :to="{ name: 'vehicleParts' }",
          theme="gray",
          outline,
          :disabled="submitting"
        ) {{ $t('btn.cancel') }}

        app-button(
          type="submit",
          :loading="submitting",
          data-testid="submitButton"
        ) {{ $t('.btn.create.label') }}

</template>


<script>

// Mixins
import { mixins } from "movida-common.vue"

// Extends
import View from "@/views/view"

// Local Components
import FormFields from "./form/form-fields.vue"

// Models
import models from "@/models"

const { VehiclePart } = models
const { FormMixin } = mixins

export default {
  name: "NewVehiclePart",

  components: {
    FormFields
  },

  extends: View,

  mixins: [FormMixin],

  data() {
    return {
      i18nScope: "vehicle-parts.new",

      resource: new VehiclePart()
    }
  },

  methods: {
    // @override Form mixin
    submitRequest() {
      const symptomIds = this.resource.symptoms.map(symptom => symptom.id)
      const params = { ...this.resource.$serialize(), symptom_ids: symptomIds }

      return this.$sdk.vehicleParts.create({ params })
    },

    // @override Form mixin
    submitSuccess({ data: { id, name } }) {
      this.$notifications.info(this.$t(".notifications.submit.success", { name }))

      this.$router.push({ name: "vehiclePart", params: { id } })
    },

    // @override Form mixin
    submitError(err) {
      this.$err.log(err)

      this.$notifications.error(this.$t(".notifications.submit.error"))
    }
  }
}

</script>

<template lang="pug">

  select-field(
    v-bind="$props",
    v-on="$listeners",
    :options="options"
  )

</template>


<script>

import Vue from "vue"

import SelectField from "@/components/form/select-field"

export default {
  extends: SelectField,

  data() {
    return {
      i18nScope: "stores.index.external-maintenance-select-field"
    }
  },

  computed: {
    options() {
      return [
        { label: this.$t(".enabled.label"), value: true },
        { label: this.$t(".disabled.label"), value: false }
      ]
    }
  },

  // Removendo props 'options' que está sendo reescrita como _computed_
  beforeCreate() {
    Vue.delete(this.$options.props, "options")
  }
}

</script>

<style lang="scss" scoped>

.tickets-table {
  .skeleton-table {
    margin-top: 16px;

    .skeleton-row + .skeleton-row {
      margin-top: 24px;
    }
  }

  .empty {
    margin-top: 24px;

    .message {
      font-style: italic;
      font-weight: 400;
    }

    .icon {
      user-select: none;
      margin-bottom: 4px;
      height: 88px;
      width: 88px;
    }
  }

  .list {
    margin-top: 16px;
  }
}

</style>


<template lang="pug">

  .tickets-table
    template(v-if="initializing")
      .skeleton-table
        ticket-row-skeleton.skeleton-row(
          v-for="i in 5",
          :key="`ticket-row-skeleton-${i}`"
        )

    template(v-else)
      tickets-table-header(
        :loading="loading",
        @sort="param => $emit('sort', param)"
        :sort-param="sortParam",
        :sort-order="sortOrder",
        data-testid="ticketsTableHeader"
      )

      template(v-if="empty")
        .empty.flex.center
          .flex.column-direction.vertical-center
            img.icon(:src="emptyIcon")
            span.message {{ $t('index.empty.message') }}

      template(v-else)
        template(v-for="ticket in tickets")
          ticket-row(
            :key="ticket.id",
            :ticket="ticket",
            :loading="loading",
            @destroy="$emit('changed')",
            @disabled="$emit('changed')",
            @enabled="$emit('changed')",
            data-testid="ticketRow"
          )

</template>


<script>

// Assets
import emptyIcon from "@/assets/images/no-results.svg"

// Components
import TicketRow from "./ticket-row.vue"
import TicketRowSkeleton from "./ticket-row-skeleton"
import TicketsTableHeader from "./tickets-table-header"

export default {
  components: {
    TicketRow,
    TicketRowSkeleton,
    TicketsTableHeader
  },

  props: {
    tickets:      { type: Array, required: true },
    initializing: { type: Boolean, default: false },
    loading:      { type: Boolean, default: false },
    sortedBy:     { type: String, default: "" }
  },

  data() {
    return {
      i18nScope: "tickets.index.tickets-table",

      emptyIcon
    }
  },

  computed: {
    empty() {
      return _.blank(this.tickets)
    },

    sortParam() {
      return this.sortedBy.split(":")[0]
    },

    sortOrder() {
      return this.sortedBy.split(":")[1] || "asc"
    }
  }
}

</script>

.<style lang="scss" scoped>

.tickets-table-header {
  display: grid;
  grid-template-columns: var(--grid-columns);

  background-color: $light-gray;
  height: 40px;
  border-bottom: 1px solid $light-gray-3;
  border-top: 1px solid $light-gray-3;

  &.loading {
    border-bottom: 2px solid $light-orange-2;
  }

  .unsortable {
    padding: 0 12px;
  }

  .column {
    font-size: 12px;

    .icon {
      font-size: 16px;
      color: $gray-2;
    }

    .text {
      font-family: $secondary-font;
      font-size: 14px;
      color: $dark-gray;
      font-weight: 400;
    }

    .loader-icon {
      font-size: 16px;
      color: $orange;
    }
  }
}

</style>


<template lang="pug">

  .tickets-table-header(:class="{ loading }", :style="styles")
    .column.photo-content.unsortable.flex.vertical-center(data-testid="idColumn")
      span.text #

    .column.photo-content.unsortable.flex.vertical-center.center(data-testid="stateColumn")
      i.icon.fas.fa-user-headset

    .column.photo-content.unsortable.flex.vertical-center(data-testid="ticketColumn")
      span.text {{ $t('.columns.ticket.label') }}

    .column.photo-content.unsortable.flex.vertical-center(data-testid="supplierColumn")
      span.text {{ $t('.columns.supplier.label') }}

    .column(data-testid="createdAtColumn")
      sortable-table-header-column(
        sort-param="created_at",
        :sort-order="sortOrder",
        :active="isSortedBy('created_at')",
        @sort="param => $emit('sort', param)"
      )
        span.text {{ $t('.columns.createdAt.label') }}

    .column.flex.unsortable.vertical-center.center
      template(v-if="loading")
        i.loader-icon.fas.fa-spin.fa-spinner

</template>


<script>

export default {
  props: {
    loading:   { type: Boolean, default: false },
    sortParam: { type: String, required: true },
    sortOrder: { type: String, required: true },

    columnSizes: { type: String, required: true }
  },

  data() {
    return {
      i18nScope: "tickets.drafts.vehicles.components.last-tickets.tickets-table-header"
    }
  },

  computed: {
    styles() {
      return {
        "--grid-columns": this.columnSizes
      }
    }
  },

  methods: {
    isSortedBy(param) {
      return this.sortParam === param
    }
  }
}

</script>

// Base
import BaseRequests from "../Base"

class MonitoringServiceOrderAssignmentRequests extends BaseRequests {
  static get selector() {
    return "monitoring.serviceOrderAssignments"
  }

  create({ params = {}, options = {} } = {}) {
    return this.action({
      method: "post",
      url:    `/monitoring/service_order_assignments/batches`,
      params,
      options
    })
  }
}

export default MonitoringServiceOrderAssignmentRequests

<template lang="pug">

select-field.cancellation-motive-select-field(
  v-model="_value",
  v-bind="selectFieldProps",
  :options="options"
)

</template>


<script>

// Mixins
import FieldMixin from "@/mixins/field"

// Models
import models from "@/models"
const { ServiceOrderCancellation } = models

export default {
  mixins: [FieldMixin],

  data() {
    return {
      i18nScope: "monitoring.cancellation-motive-select-field"
    }
  },

  computed: {
    _value: {
      get() { return this.value },

      set(newValue) { this.$emit("input", newValue) }
    },

    selectFieldProps() {
      return _.deleteBlanks(this.$props)
    },

    options() {
      return ServiceOrderCancellation.$enums.motive.values.map(motive => ({
        label: ServiceOrderCancellation.$tEnum("motive", motive),
        value: motive
      }))
    }
  }
}

</script>

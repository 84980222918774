// Base
import BaseRequests from "../Base"

// Models
import models from "@/models"
const { VehicleModelGroupMaintenanceTableSettings } = models

class VehicleModelGroupMaintenanceTableSettingsRequests extends BaseRequests {
  static get selector() {
    return "vehicleModelGroups.maintenanceTableSettings"
  }

  update({ vehicleModelGroupId, params = {}, options = {} } = {}) {
    if (_.blank(vehicleModelGroupId)) {
      throw new Error('SDK VehicleModelGroups - #update() - "vehicleModelGroupId" is a required prop')
    }

    return this.action({
      method:  "patch",
      url:     `/vehicle_model_groups/${vehicleModelGroupId}/maintenance_table_settings`,
      params,
      options: {
        model: VehicleModelGroupMaintenanceTableSettings,

        ...options
      }
    })
  }
}

export default VehicleModelGroupMaintenanceTableSettingsRequests

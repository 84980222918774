// Base
import BaseRequests from "./Base"

// Models
import models from "@/models"
const { Service } = models

class ServicesRequests extends BaseRequests {
  static get selector() {
    return "services"
  }

  all({ params = {}, options = {} } = {}) {
    return this.action({
      method:  "get",
      url:     "/services",
      params,
      options: {
        model: Service,

        ...options
      }
    })
  }

  create({ params = {}, options = {} } = {}) {
    return this.action({
      method:  "post",
      url:     `/services`,
      params,
      options: {
        model: Service,

        ...options
      }
    })
  }

  find({ serviceId, params = {}, options = {} } = {}) {
    this.verifyData("find", { serviceId })

    return this.action({
      method:  "get",
      url:     `/services/${serviceId}`,
      params,
      options: {
        model: Service,

        ...options
      }
    })
  }

  update({ serviceId, params = {}, options = {} } = {}) {
    this.verifyData("update", { serviceId })

    return this.action({
      method:  "patch",
      url:     `/services/${serviceId}`,
      params,
      options: {
        model: Service,

        ...options
      }
    })
  }

  destroy({ serviceId }) {
    this.verifyData("destroy", { serviceId })

    return this.action({
      method: "delete",
      url:    `/services/${serviceId}`
    })
  }
}

export default ServicesRequests

<style scoped lang="scss">

.label {
  display: block;
  margin-bottom: 8px;
  font-size: 14px;
  line-height: 17px;
  font-weight: 500;
  font-family: $secondary-font;
  color: $dark-gray;

  .info-icon {
    margin-left: 4px;
    font-size: 14px;
    cursor: pointer;
  }
}

.icon {
  margin-right: 4px;
}

</style>


<template lang="pug">

  .tire-load-index-list
    label.label
      span {{ $t('.fields.size.label') }}
      v-popover.inline-block(
        placement="top",
        trigger="manual",
        :open="showPopover",
        @hide="showPopover = false"
      )
        i.info-icon.far.fa-info-circle(@click="showPopover = true")

        template(#popover)
          tire-load-index-popover-content

    template(v-if="fetching")
      loading-lines

    template(v-else)
      tire-load-index-fields(
        v-for="(resource, index) in collection",
        :key="`tire-specification-load-index-${keyFor(resource)}`",
        v-show="!resource.$markedForDestruction",
        :name="`${name}[${keyFor(resource)}]`"
        :loading="submitting",
        :resource="resource",
        :errors="nestedErrors[keyFor(resource)]",
        :hide-remove="collection.length === 1",
        @remove="remove(index)"
      )

    app-button.btn(theme="link", @click="add", size="small", :disabled="submitting || fetching")
      i.icon.far.fa-plus
      span {{ $t('.btn.add') }}

</template>


<script>

// Mixins
import NestedFormMixin from "@/mixins/nested-form"

// Local Components
import TireLoadIndexFields from "./tire-load-index-fields"


export default {
  components: {
    TireLoadIndexFields
  },

  mixins: [NestedFormMixin],

  props: {
    errors:     { type: Object, default: () => ({}) },
    fetching:   { type: Boolean, default: false },
    submitting: { type: Boolean, default: false },
    name:       { type: String, required: true },

    error: { type: Boolean, default: false }
  },

  data() {
    return {
      i18nScope:      "vehicle-models.forms.tire-specification-load-index-nested-fields",
      model:          "TireLoadIndex",
      nestedResource: "loadIndices",

      showPopover: false
    }
  }
}

</script>

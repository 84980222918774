<template>
  <div>
    <movida-header title="Configurar recursos" icon="fa-wrench"/>
    <movida-card>
      <movida-list :to="{ name: 'manageResourcesModelGroups' }" title="Grupos de modelos">
        Vincule ou desvincule um Grupo de Modelo para um determinado tipo de serviço, para um ou vários fornecedores, em um clique.

        <template v-slot:actions>
          <router-link :to="{ name: 'manageResourcesModelGroups' }">
            <movida-button icon="far fa-wrench">Configurar</movida-button>
          </router-link>
        </template>

      </movida-list>
    </movida-card>
  </div>
</template>

<script>

export default {
  name: "ManageResources",

  data() {
    return {
      i18nScope: "manage-resources.index",
    }
  }
}
</script>

<style lang="scss" scoped>

.forbidden-in-internal-facilities-toggle {
  $size: 24px;

  .loading {
    width: $size;
  }

  .checkbox-input {
    appearance: none;
    width: $size;
    height: $size;
    border-radius: 50%;
    margin: 0;
    position: relative;
    cursor: pointer;

    &:after {
      height: $size;
      width: $size;
      content: '';
      font-weight: 300;
      color: $gray-3;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background-color: $gray-3;
      mask-image: var(--svg-icon);
      mask-repeat: no-repeat;
      mask-position: center;
    }

    &:active::after {
      background-color: $light-orange-2;
    }

    &:checked {
      background-color: $light-orange;

      &:after {
        background-color: $orange;
        mask-image: var(--svg-icon-selected);
      }

      &:active::after {
        background-color: $dark-orange-2;
      }
    }
  }

  .error-message {
    display: block;
    color: $error;
    font-size: 12px;
    line-height: 1;
    padding-top: 4px;
  }
}

</style>


<template lang="pug">

  .forbidden-in-internal-facilities-toggle(:style="cssVars")
    loading-lines.loading(v-if="loading", :min="100", :max="100", :height="24")
    .checkbox(
      v-else
      :class="{ error: hasErrors }"
    )
      input.checkbox-input(
        type="checkbox",
        :name="name",
        :id="inputId"
        :disabled="disabled || loading",
        :checked="value",
        :class="{ disabled, loading, indeterminate }",
        v-tooltip.bottom="{ content: $t('.forbiddenInInternalFacilities.tooltip') }",
        @click="onClick"
      )

    span.error-message(v-for="error in errors", :key="error") {{ error }}

</template>


<script>

import icon from "@/assets/images/icons/forbidden-in-internal-facilities/light.svg"
import iconSolid from "@/assets/images/icons/forbidden-in-internal-facilities/solid.svg"

import CheckboxField from "@/components/form/checkbox-field"

export default {
  extends: CheckboxField,

  data() {
    return {
      i18nScope: "vehicle-model-groups.maintenance-tables.form.maintenance-fields.forbidden-in-internal-facilities-toggle-field"
    }
  },

  computed: {
    cssVars() {
      return {
        "--svg-icon":          `url(${icon})`,
        "--svg-icon-selected": `url(${iconSolid})`
      }
    }
  }
}

</script>

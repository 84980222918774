<style lang="scss" scoped>

.sidebar-item {
  .disabled-item {
    cursor: not-allowed;
    color: $gray-3;
  }

  .icon {
    margin: 0 8px 0 24px;
  }

  .text {
    font-family: $secondary-font;
    font-weight: 400;
    font-size: 14px;
    color: $gray-4;
  }

  .item {
    padding: 10px 0;
    border-left: 4px solid $light-gray-3;
    border-radius: 0;
    width: 100%;
    font-size: 16px;
    user-select: none;

    &.link {
      color: $gray-3;
      position: relative;
      height: 40px;

      &:hover {
        color: $orange;

        & ::v-deep .custom-icon {
          background-color: $orange;
        }

        .text {
          color: $orange;
        }
      }

      &.active {
        color: $orange;
        background-color: $light-orange-2;

        & ::v-deep .custom-icon {
          background-color: $orange;
        }

        .text {
          color: $orange;
        }
      }

      &.active::before {
        content: '';
        height: 40px;
        position: absolute;
        left: -4px;
        border-right: 4px solid $orange;
        border-radius: 0 4px 4px 0;
      }
    }
  }
}

</style>


<template lang="pug">

  .sidebar-item
    template(v-if="_disabled")
      .disabled-item.flex.vertical-center.item
        sidebar-icon.icon(
          :active="isActive",
          :custom="custom",
          :icon="icon",
          :tooltip="tooltip"
        )
        template(v-if="expanded")
          span.text {{ label }}

    template(v-else)
      router-link.item.link.flex.vertical-center(
        :class="{ active: isActive }",
        :disabled="disabled",
        :to="to"
      )
        sidebar-icon.icon(
          :active="isActive",
          :custom="custom",
          :icon="icon",
          :tooltip="tooltip"
        )
        template(v-if="expanded")
          span.text {{ label }}

</template>


<script>

export default {
  name: "SidebarItem",

  props: {
    disabled: { type: Boolean, default: false },
    to:       { type: Object, default: null },
    active:   { type: Array, default: () => [] },
    icon:     { type: String, default: null },
    custom:   { type: Boolean, default: false },
    label:    { type: String, default: null },
    expanded: { type: Boolean, required: true }
  },

  data() {
    return {
      i18nScope: "components.sidebar.sidebar-item"
    }
  },

  computed: {
    _disabled() {
      return this.disabled || _.blank(this.to)
    },

    isActive() {
      const split = this.$route.path.split("/")
      if (_.blank(this.to) || split.length < 2) return false

      const section = split[1]
      return this.active.includes(section)
    },

    tooltip() {
      return this.expanded
        ? null
        : this.label
    }
  }
}

</script>

<!--
<doc lang="markdown">
Exibe um checkbox com estilo toggle

Como usar:
```pug
toggle-field(
  name="model[prop]",
  label="Primário",
  v-model="resource.value"
)
```
</doc>
-->

<style lang="scss" scoped>

.checkbox-field {
  .field-label {
    display: block;
    margin-bottom: 8px;
    font-size: 14px;
    line-height: 17px;
    font-weight: 500;
    font-family: $secondary-font;
    color: $dark-gray;
    transition: color 0.1s cubic-bezier(.645,.045,.355,1);

    > * + * {
      padding-left: 4px;
    }

    &.focus {
      color: $orange;
    }

    &.error {
      color: $error;
    }
  }

  .checkbox {
    display: flex;
    align-items: center;

    .checkbox-input {
      appearance: none;
      height: 20px;
      width: 35px;
      border: 1px solid transparent;
      border-radius: 16px;
      display: inline-block;
      position: relative;
      margin: 0;
      background-color: $light-gray-4;
      cursor: pointer;
      flex-shrink: 0;

      &:after {
        content: '';
        position: absolute;
        top: 1px;
        left: 1px;
        width: 16px;
        height: 16px;
        border-radius: 50%;
        background: $white;
      }

      &:hover {
        background-color: $gray-2;
      }

      &:focus {
        &:before {
          content: '';
          width: 41px;
          display: block;
          height: 26px;
          border: 1px solid #F47920;
          border-radius: 20px;
          position: absolute;
          top: -5px;
          left: -5px;
        }
      }

      // &:active {}

      &.disabled,
      &.loading {
        background-color: $light-gray-3;

        &:after {
          background-color: $gray-3;
        }
      }

      &.disabled {
        cursor: not-allowed;
      }

      &.loading {
        cursor: progress;
      }

      &:checked {
        background-color: $orange;

        &:after {
          transform: translatex(15px);
        }

        &:hover {
          background-color: $dark-orange-2;
        }

        // &:focus {}

        // &:active {}

        &.disabled,
        &.loading {
          background-color: $light-gray-3;

          &:after {
            background-color: $gray-3;
          }
        }

        &.disabled {
          cursor: not-allowed;
        }

        &.loading {
          cursor: progress;
        }
      }
    }

    .checkbox-label {
      padding-left: 12px;
      margin-right: 12px;
      font-family: $secondary-font;
      font-weight: 500;
      cursor: pointer;

      &.disabled {
        cursor: not-allowed;
      }

      &.loading {
        cursor: progress;
      }

      &.disabled,
      &.loading {
        color: $gray-2;
      }

      &.error {
        color: $error;
      }
    }
  }

  .error-message {
    display: block;
    color: $error;
    font-size: 12px;
    line-height: 1;
    padding-top: 4px;
  }
}

</style>


<script>

import CheckboxField from "./checkbox-field"

export default {
  name: "ToggleField",

  extends: CheckboxField
}

</script>

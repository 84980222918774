<style lang="scss" scoped>

.quote-item {
  .section {
    padding: 16px 24px;

    & + .section {
      border-top: 1px solid $light-gray-3;
    }
  }

  .selector {
    background-color: $light-gray;
    height: 97px;
    border-radius: 8px;
    border: 2px dashed $light-gray-4;

    .message {
      font-family: $secondary-font;
      font-size: 14px;
      font-weight: 400;
      color: $gray-3;
    }

    .file-selector {
      margin-top: 8px;
    }

    .button {
      font-size: 14px;

      .icon {
        margin-right: 4px;
      }
    }
  }

  .attachments {
    margin-top: 16px;
    gap: 10px;
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
}

</style>


<template lang="pug">

  app-modal(
    footer,
    :confirm-label="$t('.btn.confirm')",
    :heading="$t('.heading')",
    :width="716",
    @close="$emit('close')",
    @confirm="onConfirm"
  )
    .quote-item
      .section
        .selector.flex.column-direction.center.vertical-center
          span.message {{ $t('.attachments.message') }}

          file-selector.file-selector(
            multiple,
            @selected-files="createAttachments"
          )
            template(#default="{ props: { selectFiles } }")
              app-button.button(theme="link", @click="selectFiles")
                i.icon.far.fa-paperclip
                span {{ $t('.attachments.add') }}

        .attachments.grid(v-if="hasAttachments")
          template(v-for="attachment in item.attachments")
            quote-attachment-card(
              v-if="!attachment.$markedForDestruction",
              local-edit,
              :attachment="attachment",
              :quote-id="quoteId",
              @destroy="removeAttachment(attachment)",
              @update:attachment="value => updateAttachment(attachment, value)",
              @uploaded="signedId => onAttachmentUploaded(attachment, signedId)"
            )

      .section
        textarea-field(
          v-model="item.description",
          name="quoteItem[description]",
          :errors="errors.description",
          :label="$t('.description.label')",
          :placeholder="$t('.description.placeholder')"
        )

</template>


<script>

// Models
import models from "@/models"
const { Attachment } = models


export default {
  props: {
    value:  { type: Object, required: true },
    errors: { type: Object, default: () => ({}) },
    model:  { type: String, required: true }
  },

  data() {
    return {
      i18nScope: "monitoring.quotes.quote-item",

      item: new this.$models[this.model](_.cloneDeep(this.value)),

      nestedAttributes: {
        attachments: null
      }
    }
  },

  computed: {
    hasAttachments() {
      return _.present(this.item.attachments)
    },

    quoteId() {
      return this.value?.quoteId
    }
  },

  created() {
    this.initialSerializedAttributes = this.serializeAttributes()
  },

  methods: {
    serializeAttributes() {
      return this.item.$serialize({}, this.nestedAttributes)
    },

    onConfirm() {
      this.$emit("input", this.item)
      this.$emit("close")
    },

    createAttachment(file) {
      const attachment = new Attachment({
        fileContentType: file.type,
        name:            file.name,
        fileSize:        file.size
      })
      attachment.fileToUpload = file

      return attachment
    },

    createAttachments(files) {
      this.item.attachments = [
        ...this.item.attachments,

        ...files.map(file => this.createAttachment(file))
      ]
    },

    onAttachmentUploaded(attachment, signedId) {
      attachment.file = signedId
      attachment.fileToUpload = null
    },

    removeAttachment(attachment) {
      const index = _.findIndex(this.item.attachments, attachment)

      const attachments = [...this.item.attachments]
      attachments.splice(index, 1)

      this.item.attachments = attachments
    },

    updateAttachment(attachment, newAttachment) {
      const index = _.findIndex(this.item.attachments, attachment)

      const attachments = [...this.item.attachments]
      attachments[index] = newAttachment

      this.item.attachments = attachments
    }
  }
}

</script>

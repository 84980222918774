import GoogleMapsLoader from "./index"

const GoogleMapsPlugin = {
  installed: false,

  install(Vue, { key }) {
    if (GoogleMapsPlugin.installed) return
    GoogleMapsPlugin.installed = true

    GoogleMapsLoader.key = key

    const googleMaps = {
      load: ({ language, libraries } = {}) => GoogleMapsLoader.load({ language, libraries })
    }

    Vue.$googleMaps = googleMaps
    // add an instance method
    Vue.prototype.$googleMaps = googleMaps
  }
}

export default GoogleMapsPlugin

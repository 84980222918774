<style scoped lang="scss">

.vehicle-model-row {
  padding: 12px 0;
  border-top: 1px solid $light-gray-3;
  border-bottom: 1px solid $light-gray-3;

  &.loading {
    background: $light-gray-2;
  }
}

.vehicle-model-row + .vehicle-model-row {
  border-top: 1px solid transparent;
}

.icon-column {
  min-width: 72px;
}

.upper-row {
  margin-bottom: 4px;
}

.vehicle-model-name {
  font-family: $secondary-font;
  font-size: 18px;
  font-weight: normal;
  color: $dark-gray;
  word-break: normal;
  overflow-wrap: break-word;

  &.link {
    &:hover {
      color: $primary;
    }
  }
}

.info-column {
  font-size: 14px;
  font-weight: normal;
  padding-right: 24px;

  .schedule-icon {
    font-size: 18px;
    margin-right: 8px;
    color: $gray-2;
  }
}

.content-column {
  flex-grow: 1;
  padding-right: 12px;
  margin-left: 16px;
}

.year-tag {
  margin-left: 8px;
}

.year-range {
  font-weight: 300;
  font-size: 12px;
  color: $gray-3;
  margin-left: 8px;
}

.incomplete {
  font-weight: 500;
  font-family: $secondary-font;

  .icon {
    font-weight: 900;
    color: $dark-warning;
    width: 24px;
    height: 24px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    margin-right: 8px;
  }
}

.details > span {
  font-size: 14px;
}

.schedule-name {
  word-break: normal;
  overflow-wrap: break-word;
  max-width: 270px;
  cursor: auto;
}

.diamond-icon {
  font-size: 4px;
  font-weight: 900;
  color: $gray-3;
  width: 4px;
  height: 4px;
  margin: 8px;
}

.checkbox-select {
  padding-left: 16px
}
</style>


<template lang="pug">

  .vehicle-model-row.flex.vertical-center(:class="{ loading }")
    .checkbox-select(v-if="hasCheckbox")
      checkbox-field(
        :name="`vehicleModel-${vehicleModel.id}`",
        hide-value-text,
        :disabled="disabled",
        :loading="loading",
        :value="value",
        @input="(value) => $emit('input', { id: vehicleModel.id, value })"
      )

    .content-column
      .upper-row.flex.vertical-center
        label.vehicle-model-name.flex.vertical-center
          span.model {{ mobiautoVersion.model.name }}
          i.diamond-icon.fal.fa-diamond
          span {{ cropName(mobiautoVersion.name) }}

        app-tag.year-tag(v-if="vehicleModel.year", :value="vehicleModel.year", color="white")
        span.year-range {{ $t('.yearRange', { initial: mobiautoVersion.initialYear, final: mobiautoVersion.finalYear }) }}

      .details.flex.vertical-center
        fipe-code-tag.fipe-tag(:value="mobiautoVersion.fipeId", size="small")
        i.diamond-icon.fal.fa-diamond
        span {{ mobiautoVersion.$get('model.manufacturer.name') }}

        i.diamond-icon.fal.fa-diamond
        span {{ category }}
        i.diamond-icon.fal.fa-diamond
        app-span.text(:value="$t(`models.vehicleModel.enums.type.${vehicleModel.type}`)")
        template(v-if="hasCombustion")
          i.diamond-icon.fa.fa-diamond
          template(v-if="vehicleModel.fuel")
            app-span.text(
              data-testid="vehicleModelFuel",
              :value="$t(`models.vehicleModelCombustion.enums.fuel.${vehicleModel.fuel}`)"
            )
          template(v-else)
            app-span -

</template>


<script>

import models from "@/models"
const { VehicleModel, MobiautoVersion } = models

export default {
  name: "VehicleModelsSelectListRowFields",

  props: {
    vehicleModel: { type: Object, default: () => ({}) },
    value:        { type: Boolean, default: false },
    disabled:     { type: Boolean, default: false },
    loading:      { type: Boolean, default: false },
    hasCheckbox:  { type: Boolean, default: true }
  },

  data() {
    return {
      i18nScope: "components.vehicle-models-select-list-row-fields"
    }
  },

  computed: {
    hasCombustion() {
      return ["VehicleModels::Combustion", "VehicleModels::Hybrid"].includes(this.vehicleModel.type)
    },

    mobiautoVersion() {
      return this.vehicleModel.mobiautoVersion || new MobiautoVersion()
    },

    category() {
      return VehicleModel.$tEnum("category", this.vehicleModel.category)
    }
  },

  methods: {
    crop(text, length) {
      return _.truncate(text, { length })
    },

    cropName(name) {
      return this.crop(name, 60 - this.mobiautoVersion.model.name.length)
    }
  }
}

</script>

// Base
import BaseRequests from "./Base"

class AttachmentsRequests extends BaseRequests {
  static get selector() {
    return "attachments"
  }

  update({ attachmentId, params = {}, options = {} } = {}) {
    this.verifyData("update", { attachmentId })

    return this.action({
      method: "patch",
      url:    `/attachments/${attachmentId}`,
      params,
      options
    })
  }
}

export default AttachmentsRequests

<template lang="pug">

  .search-form
    form(
      @submit.prevent="$emit('search')",
      method="get",
      data-test="form"
    )
      ui-row(vertical-align="bottom")
        ui-col(:span="10")
          input-field(
            name="search[q]",
            v-model="params.q",
            :loading="loading",
            hide-label,
            :placeholder="$t('.fields.q.placeholder')",
            icon-left="far fa-search"
          )

        ui-col(:span="2")
          app-button(
            type="submit",
            :loading="loading",
            full-width,
            outline,
            data-testid="searchSubmit"
          )
            span {{ $t('btn.search.label') }}
            span(slot="loading") {{ $t('btn.search.loading') }}

</template>


<script>

export default {
  props: {
    loading: { type: Boolean, default: false },
    params:  { type: Object, default: () => ({}) }
  },

  data() {
    return {
      i18nScope: "service-packages.index.search-form"
    }
  }
}

</script>

import Base from "./Base"

class MaintenancePackages extends Base {

  create({ params = {} } = {}) {
    return this.action({
      method: "post",
      url:    "/maintenance_packages",
      params
    })
  }

  list({ params = {} } = {}) {
    return this.action({
      method: "get",
      url:    "/maintenance_packages",
      params
    })
  }

  get({ id, params = {} }) {
    if (_.blank(id)) {
      throw new Error('SDK MaintenancePackages - #get() - "id" is a required prop')
    }

    return this.action({
      method: "get",
      url:    `/maintenance_packages/${id}`,
      params
    })
  }

  destroy({ id }) {
    if (_.blank(id)) {
      throw new Error('SDK MaintenancePackages - #destroy() - "id" is a required prop')
    }

    return this.action({
      method: "delete",
      url:    `/maintenance_packages/${id}`
    })
  }

  update({ id, params = {} } = {}) {
    if (_.blank(id)) {
      throw new Error('SDK MaintenancePackages - #update() - "id" is a required prop')
    }

    return this.action({
      method: "patch",
      url:    `/maintenance_packages/${id}`,
      params
    })
  }
}

export default MaintenancePackages

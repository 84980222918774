<!--
<doc lang="markdown">
Base para componentes aninhados do tipo _index_.

Define valores e comportamentos básicos ligados a paginação e busca, sem utilizar a URL

Como usar:
```pug
  // Extends
  import IndexComponent from "@/components/index-component"

  ...

  extends: IndexComponent
```
</doc>
-->


<script>

// Libs
import parseLinkHeaders from "parse-link-header"

export default {
  name: "IndexComponent",

  data() {
    return {
      // Mantém dados de paginação: página atual, resultados por página e total de resultados
      pagination: {},
      params:     {},

      // Lista de parâmetros de query que devem ser ignorados no mecanismo de limpeza de filtros, e.g. filtros por abas
      clearFiltersIgnoreList: []
    }
  },

  computed: {
    hasFilters() {
      const filterList = Object.keys(_.omit(this.params, ["page", "sort", ...this.clearFiltersIgnoreList]))
      const currentParams = _.pick(this.params, filterList)

      return _.present(currentParams)
    },

    showPaginator() {
      if (!this.pagination) return false

      return this.pagination.total > this.pagination.perPage
    },

    // Retorna os parâmetros de busca em formato canônico snake_case, como é utilizado no backend
    requestParams() {
      return this.$params.toRouteParams(this.params)
    }
  },

  methods: {
    fetch() {
      throw new Error("IndexComponent - #fetch() - Must be implemented")
    },

    clearFilters() {
      this.params = _.deleteBlanks(_.pick(this.params, ["sort", ...this.clearFiltersIgnoreList]))

      this.fetch()
    },

    onPageChange(page) {
      this.params.page = page
      this.fetch()
    },

    onSortChange(sort) {
      this.params.sort = sort
      this.fetch()
    },

    search() {
      // XXX: Ao realizar uma nova busca, precisamos voltar para a primeira página
      //      passando undefined para causar detecção no Vue
      this.params.page = undefined
      this.fetch()
    },

    setPagination({ link, "x-total": total, "x-per-page": perPage }) {
      let pagination = _.present(link) ? parseLinkHeaders(link) : {}
      pagination.total = this.$params.asInteger(total)
      pagination.perPage = this.$params.asInteger(perPage)

      if (_.blank(pagination.total) || _.blank(pagination.perPage)) return

      this.pagination = {
        ...pagination,
        first: this.$params.asInteger(_.get(pagination, "first.page")),
        prev:  this.$params.asInteger(_.get(pagination, "prev.page")),
        next:  this.$params.asInteger(_.get(pagination, "next.page")),
        last:  this.$params.asInteger(_.get(pagination, "last.page"))
      }
    }
  }
}

</script>

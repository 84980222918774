<!--
  Lista de Serviços em uma Ordem de Serviço para consulta.
  Exibe, em forma de tabela, as quantidades e preços, com um sumário (subtotal) no rodapé.

  Espera uma coleção de models.movida.ServiceOrderService

  usage:
    service-order-services-list(:items="serviceOrder.serviceOrderServices")
-->

<style scoped lang="scss">

.dot {
  font-size: 4px;
  color: $gray;
}

.list {
  background: white;
  border-radius: 4px;

  .row {
    display: grid;
    align-items: center;  // vertical align

    // name, quantity, price, totalPrice
    grid-template-columns: 1fr 42px 100px 100px;

    border-bottom: 1px solid $light-gray-3;

    .column {
      display: flex;
      align-items: center;  // vertical align
      flex-grow: 1;

      padding: 8px;
      min-height: 32px;
    }
  }

  > .header {
    background: $light-gray-2;
    border-radius: 4px 4px 0 0;

    .column {
      font-family: $secondary-font;
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 14px;

      color: $gray-3;
    }
  }

  > .body::after {
    background: $light-gray-2;
    border-radius: 0 0 4px 4px;
    content: ' ';
    display: block;
    height: 3px;
  }

  > .footer {
    .total {
      gap: 8px;
      padding: 0 8px;
      min-height: 32px;

      .label {
        font-family: $primary-monospace-font;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        color: $gray-3;
      }
    }
  }
}


.text {
  font-family: $secondary-font;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: $dark-gray;
}

.subtext {
  font-family: $secondary-font;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  color: $gray-3;
}

.number {
  font-family: $primary-monospace-font;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: $dark-gray;
}

</style>


<template lang="pug">

.service-order-services-list
  .list
    .header
      .row
        span.column
          | {{ $t(".columns.name.label") }}
        span.column.h-align-right
          | {{ $t(".columns.quantity.label") }}
        span.column.h-align-right
          | {{ $t(".columns.price.label") }}
        span.column.h-align-right
          | {{ $t(".columns.totalPrice.label") }}

    .body
      template(v-for="serviceOrderService in serviceOrderServices")
        .row
          span.column.name.text
            | {{ serviceOrderService.name }}
          span.column.quantity.number.h-align-right
            | {{ serviceOrderService.quantity }}
          span.column.price.number.h-align-right
            | {{ $asCurrency(serviceOrderService.price, { unit: "" }) }}
          span.column.total-price.number.h-align-right
            | {{ $asCurrency(serviceOrderService.totalPrice, { unit: "" }) }}

    .footer
      .total.flex-row.v-align-middle.h-align-right
        span.label
          | {{ $t(".footer.total.label") }}
        i.dot.fas.fa-diamond
        span.value.number
          | {{ $asCurrency(total, { unit: "" }) }}

</template>


<script>

export default {
  props: {
    items: { type: Array, required: true }
  },

  emits: [],

  data() {
    return {
      i18nScope: "monitoring.service-orders.service-order-services-list"
    }
  },

  computed: {
    // alias
    serviceOrderServices() { return this.items },

    total() {
      return (this.items || [])
        .filter(item => !item.$markedForDestruction)
        .map(item => item.totalPrice)
        .filter(totalPrice => totalPrice != null)
        .reduce((accumulated, current) => accumulated + current, 0) || 0
    }
  }
}

</script>

<template lang="pug">
  finder-field.vehicle-category-finder-field(
    v-bind="$attrs"
    v-on="$listeners"
    :options="options"
  )
</template>

<script>
import { CATEGORIES } from "@/enums/vehicles"

export default {
  inheritAttrs: false,

  data() {
    return {
      options: CATEGORIES.values.map((category) => ({
        label: this.$t(`enums.vehicles.CATEGORIES.${category}`),
        value: category
      }))
    }
  },

  computed: {
    forwardedProps() {
      return _.omit(this.$attrs, ["options"])
    }
  }
}
</script>

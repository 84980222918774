<style lang="scss" scoped>

.approval.new {

  .content {
    padding: 18px 24px;

    .confirmation-message {
      font-family: $primary-font;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 150%;
    }
  }

}

</style>


<template lang="pug">

  app-modal.approval.new(
    footer,
    :confirm-label-loading="$t('.btn.confirm.loading')",
    :confirm-label="$t('.btn.confirm.label')",
    :heading="$t('.title')",
    :width="720",
    @cancel="onClose",
    @close="onClose",
    @confirm="submit"
  )
    .content
      span.confirmation-message {{ confirmationText }}

    //- template(#footer)
    //-   .footer.border

</template>


<script>

// Mixins
import { mixins } from "movida-common.vue"

// Models
import models from "@/models"

const { FormMixin } = mixins

const { ReviewApproval } = models

export default {
  mixins: [FormMixin],

  props: {
    quote: { type: Object, required: true }
  },

  data() {
    return {
      i18nScope: "monitoring.quotes.reviews.approvals.new",

      resource: new ReviewApproval(),

      isSubmitted: false
    }
  },

  computed: {
    confirmationText() {
      return this.$t(".confirmation.text")
    }
  },

  methods: {
    onClose() {
      this.$emit("close")
    },

    // @override Form mixin
    submitRequest() {
      const params = this.resource.$serialize()

      return this.$newSdk.quotes.reviews.approvals.create({ quoteId: this.quote.id, params })
    },

    // @override Form mixin
    submitSuccess(response) {
      this.isSubmitted = true

      // XXX: usando id do Vetor (remoteId) para ficar coerente com a listagem
      let successMessage = this.$t(".notifications.submit.success", { id: this.quote.serviceOrder.remoteId })
      this.$notifications.info(successMessage)

      this.$emit("change")
    },

    // @override Form mixin
    submitError(err) {
      this.$err.log(err)
      this.$notifications.error(this.$t(".notifications.submit.error"))
    }
  }
}

</script>

import { i18n } from "utils.vue"
import Base from "./base"

class DealProduct extends Base {
  static get attrs() {
    return _.uniq([
      ...super.attrs,
      "dealId",
      "price",
      "productId",
      "partOrigin",
      "detached",
      "vehicleModelGroupId"
    ])
  }

  static get relations() {
    return {
      product:           { type: "belongsTo", model: "Product" },
      vehicleModelGroup: { type: "hasOne", model: "VehicleModelGroup" },
      deal:              { type: "belongsTo", model: "Deal" }
    }
  }

  static get enums() {
    return {
      partOrigin: {
        original:    "original",
        genuine:     "genuine",
        alternative: "alternative",
        reuse:       "reuse"
      }
    }
  }

  static get constraints() {
    return {
      productId: { presence: true },

      vehicleModelGroupId: { presence: true },

      partOrigin: {
        presence(value, attrs) {
          return _.get(attrs.product, "type") === "Part"
        }
      },

      price: { presence: true, numericality: { greaterThanOrEqualTo: 0 } }
    }
  }

  static get originOptions() {
    return DealProduct.$enums.partOrigin.values.map(origin => ({
      label: i18n.t(`models.dealProduct.enums.partOrigin.${origin}`),
      value: origin
    }))
  }

  get formattedPrice() {
    if (_.blank(this.price)) return null

    return i18n.l("currency", this.price, { unit: "R$" })
  }
}

export default DealProduct

import _ from "@caiena/lodash-ext"
import { i18nPlugin, i18n } from "utils.vue"
import { lib as movidaCommonLib } from "movida-common.vue"

// XXX: dados construídos por `yarn run build:i18n`
import translations from "@/assets/translations.json"


const ParseErrors = movidaCommonLib.ParseErrors

let allTranslations = _.merge({}, i18n.translations, translations)


i18n.init({
  locales:       ["pt-BR"/* , "en-US" */],
  defaultLocale: "pt-BR",
  translations:  allTranslations
})


const { parseServerErrors, i18nError } = ParseErrors

// Reescrevendo errify para tratar erros do servidor
i18n.errify = parseServerErrors
// Reescrevendo error para tratar options
i18n.error = i18nError

function initialize(Vue) {
  Vue.prototype.$toSentence = i18n.toSentence.bind(i18n)
  Vue.use(i18nPlugin)
}


export default initialize

import { Model } from "@caiena/model"
import Base from "./base"


class VehicleModel extends Base {
  static get attrs() {
    return _.uniq([
      ...super.attrs,
      "mobiautoVersionId",
      "year",
      "category",
      "type",
      "fuel"
    ])
  }

  static get enums() {
    return {
      category: {
        operational:      "operational",
        intermediary:     "intermediary",
        suv:              "suv",
        light_utility:    "light_utility",
        heavy_utility:    "heavy_utility",
        executive:        "executive",
        luxury_executive: "luxury_executive"
      },

      type: {
        "VehicleModels::Combustion": "VehicleModels::Combustion",
        "VehicleModels::Electric":   "VehicleModels::Electric",
        "VehicleModels::Hybrid":     "VehicleModels::Hybrid"
      }
    }
  }

  static get relations() {
    return {
      mobiautoVersion:        { type: "belongsTo", model: "MobiautoVersion" },
      tireSpecification:      { type: "hasOne",    model: "TireSpecification" },
      spareTireSpecification: { type: "hasOne",    model: "SpareTireSpecification" }
    }
  }

  static get constraints() {
    return {
      ...super.constraints,

      year: {
        presence:     true,
        numericality: {
          greaterThan: 0
        }
      },

      category: {
        presence: true
      },

      // - XXX: tornando pneu specs opcional
      // ref: https://github.com/caiena/movida-gmf/issues/1191
      // tireSpecification: {
      //   presence: true
      // },

      spareTireSpecification(value, instance, attrName, options, constraints) {
        return {
          presence() {
            return instance.$present("tireSpecification")
              && instance.$get("tireSpecification.kind") !== "run_flat"
          }
        }
      },

      mobiautoVersionId: {
        presence: true
      }
    }
  }

  static get classNames() {
    return [
      "VehicleModel",
      "VehicleModelCombustion",
      "VehicleModelElectric",
      "VehicleModelHybrid"
    ]
  }

  static build(data) {
    switch (_.get(data, "type")) {
      case "VehicleModels::Combustion": {
        const VehicleModelCombustion = Model.$lookupModel("VehicleModelCombustion")
        return new VehicleModelCombustion(data)
      }
      case "VehicleModels::Electric": {
        const VehicleModelElectric = Model.$lookupModel("VehicleModelElectric")
        return new VehicleModelElectric(data)
      }
      case "VehicleModels::Hybrid": {
        const VehicleModelHybrid = Model.$lookupModel("VehicleModelHybrid")
        return new VehicleModelHybrid(data)
      }
      default:
        return new VehicleModel(data)
    }
  }

  // Overrides
  get mobiautoVersionId() {
    return this.$get("mobiautoVersion.id")
  }

  // General Getters
  get displayName() {
    return `${this.mobiautoVersion.displayName}, ${this.year}`
  }

  get name() {
    const manufacturerName = _.get(this.manufacturer, "name")
    const modelName = _.get(this.model, "name")
    const versionName = _.get(this.version, "name")

    return `${manufacturerName} ${modelName} ${versionName}`
  }

  // Delegators
  get version() {
    return this.mobiautoVersion
  }

  get model() {
    return _.get(this, "mobiautoVersion.model")
  }

  get manufacturer() {
    return _.get(this, "mobiautoVersion.model.manufacturer")
  }
}

export default VehicleModel

<style lang="scss" scoped>

.products-table {
  .skeleton-table {
    margin-top: 16px;

    .skeleton-row + .skeleton-row {
      margin-top: 24px;
    }
  }

  .empty{
    text-align: center;
    font-style: italic;
    padding: 32px 0;
    width: 100%;

    color: $gray-3;
  }

  .list {
    margin-top: 16px;
  }
}

</style>


<template lang="pug">

  .products-table
    template(v-if="initializing")
      .skeleton-table
        store-row-skeleton.skeleton-row(
          v-for="i in 5",
          :key="`store-row-skeleton-${i}`"
        )

    template(v-else)
      stores-table-header(
        :loading="loading",
        @sort="param => $emit('sort', param)"
        :sort-param="sortParam",
        :sort-order="sortOrder",
        data-testid="productsTableHeader"
      )

      template(v-if="empty")
        .empty
          span {{ $t('.empty') }}

      template(v-else)
        store-row(
          v-for="store in stores",
          :key="store.id",
          :store="store",
          :loading="loading",
          @destroy="$emit('changed')",
          data-testid="storeRow"
        )

</template>


<script>

// Components
import StoreRow from "./store-row.vue"
import StoreRowSkeleton from "./store-row-skeleton"
import StoresTableHeader from "./stores-table-header.vue"

export default {
  components: {
    StoreRow,
    StoreRowSkeleton,
    StoresTableHeader
  },

  props: {
    stores:       { type: Array, required: true },
    initializing: { type: Boolean, default: false },
    loading:      { type: Boolean, default: false },
    sortedBy:     { type: String, default: "" }
  },

  data() {
    return {
      i18nScope: "stores.index.stores-table"
    }
  },

  computed: {
    empty() {
      return _.blank(this.stores)
    },

    sortParam() {
      return this.sortedBy.split(":")[0]
    },

    sortOrder() {
      return this.sortedBy.split(":")[1] || "asc"
    }
  }
}

</script>

<style lang="scss" scoped>

.table-kind-tab-group {
  background-color: $light-gray-3;
  border-radius: 32px;
  padding: 8px;

  .option {
    padding: 8px 16px;
    border-radius: 32px;
    color: $gray-4;
    font-weight: 400;
    cursor: pointer;

    + .option {
      margin-left: 4px;
    }

    &.selected {
      background-color: $white;
      color: $dark-gray;
    }

    .icon {
      margin-right: 8px;
      font-size: 14px;
    }
  }
}

</style>


<template lang="pug">

  .table-kind-tab-group.flex
    template(v-for="option in options")
      .option(:class="{ selected: option.selected }", @click="$emit('select', option.kind)")
        i.icon.fas(:class="{ [option.icon]: true }")
        app-span(:value="VehicleModelGroupMaintenanceTable.$tEnum('kind', option.kind)")

</template>


<script>

// Models
import models from "@/models"
const { VehicleModelGroupMaintenanceTable } = models

export default {
  props: {
    selected: { type: String, required: true }
  },

  data() {
    return {
      i18nScope: "suppliers.service-supports.maintenance.vehicle-model-groups.new.table-kind-tab-group",

      VehicleModelGroupMaintenanceTable
    }
  },

  computed: {
    options() {
      return VehicleModelGroupMaintenanceTable.$enums.kind.values.map(kind => ({
        kind,
        icon:     this.iconFor(kind),
        selected: kind === this.selected
      }))
    }
  },

  methods: {
    iconFor(kind) {
      switch (kind) {
        case "dealership": return "fa-copyright"
        case "multiservice": return "fa-car-garage"
        default: return null
      }
    }
  }
}

</script>

<style lang="scss" scoped>

.timeline-popover-quote-issued {
  padding: 16px;
  width:   400px;

  > .loading {
    width: 100%;
  }

  > .erred {
    font-size: 16px;
  }

  > .content {
    font-size: 14px;
    line-height: 150%;
    font-family: $secondary-font;

    .title {
      display: flex;
      justify-content: space-between;
      align-items: baseline;

      font-family: $secondary-font;
      font-size: 16px;
      font-weight: 500;
      color: $dark-gray;

      > .state.badge {
        padding: 4px 12px;
        border-radius: 24px;

        font-family: $secondary-font;
        font-size: 12px;
        line-height: 150%;

        &.draft {
          background: $light-gray-3;
        }

        &.reviewed {
          background: $light-purple;
        }
      }
    } // .title

    .subtitle {
      font-family: $primary-font;
      font-size: 14px;
      font-weight: 300;
      color: $dark-gray;
    }

    .info {
      margin-top: 8px;
    }
  }

  .empty {
    .empty-message {
      font-weight: 400;
      font-style: italic;
      font-size: 14px;
      color: $gray-3;
    }
  }
}


.icon-container {
  width: 16px;
  height: 16px;
  margin-right: 8px;

  .icon {
    font-size: 12px;
    color: $gray-3;

    &.warning {
      color: $dark-warning;
    }
  }

  &.main {
    width: 24px;
    height: 24px;
    margin-right: 16px;

    .icon {
      font-size: 20px;
      color: $purple;
    }

    &.empty {
      .icon {
        color: $gray-2;
      }
    }
  }
}

</style>


<template lang="pug">

  .timeline-popover-quote-issued
    template(v-if="fetching")
      .loading
        loading-lines(:lines="3")

    template(v-else-if="erred")
      .erred.flex.column-direction.vertical-center
        span.text-center {{ $t(".fetch-error.message") }}
        app-button.retry(theme="link", @click="$fetch") {{ $t(".fetch-error.retry") }}

    template(v-else-if="quoteIssued")
      .content.flex
        .icon-container.main.flex.center.vertical-center
          i.icon.fas.fa-memo

        .header.full-width.flex.column-direction
          span.title
            | {{ $t(".title") }}
            template(v-if="state")
              label.state.badge(:class="state") {{ stateText }}
          span.subtitle {{ subtitleText }}

          .info.flex.vertical-baseline
            .icon-container.flex.center.vertical-center(v-tooltip="$t('.fields.description.tooltip')")
              i.icon.fas.fa-message-lines
            span.preserve-whitespace(:class="{ italic: !hasDescription }") {{ descriptionText }}

          template(v-if="reviewApproval")
            .info.flex.vertical-baseline(style="padding-top: 20px;")
              .icon-container.flex.center.vertical-center(v-tooltip="$t('.fields.reviewApproval.tooltip')")
                i.icon.fas.fa-paper-plane-top
              span {{ reviewApprovalText }}

            //- XXX: escondido!
            //- @see https://github.com/caiena/movida-gmf/issues/1850
            //- .info.flex.vertical-baseline(style="padding-left: 18px")
            //-   .icon-container.flex.center.vertical-center(v-tooltip="$t('.fields.reviewApproval.description.tooltip')")
            //-     i.icon.fas.fa-message-lines
            //-   span.preserve-whitespace(:class="{ italic: !hasReviewApprovalDescription }") {{ reviewApprovalDescriptionText }}

    template(v-else)
      .empty.flex.vertical-center
        .icon-container.main.empty.flex.center.vertical-center
          i.icon.fas.fa-memo

        .content.full-width
          span.empty-message {{ $t(".empty") }}

</template>


<script>

// Models
import models from "@/models"
const { Quote } = models


export default {
  components: {},
  emits:      [],

  props: {
    serviceOrderRid: { type: [Number, String], required: true },
    quoteId:         { type: [Number, String], default: null },
    show:            { type: Boolean, required: true }
  },

  data() {
    return {
      i18nScope: "monitoring.timeline-popover-quote-issued",

      // flags
      initialized: false,
      erred:       false,
      fetching:    false,
      submitting:  false,

      serviceOrder: null,
      events:       null,
      resource:     null,

      errors: {}
    }
  },

  computed: {
    quote: {
      get()      { return this.resource  },
      set(value) { this.resource = value }
    },

    initializing() {
      return !this.initialized
    },

    loading() {
      return this.initializing || this.fetching || this.submitting
    },

    hasQuote() {
      return _.present(this.quote)
    },

    hasDescription() {
      return _.present(this.quote?.description)
    },

    descriptionText() {
      return this.hasDescription
        ? this.quote.description
        : this.$t(".fields.description.empty")
    },

    quoteIssued() {
      return this.serviceOrder?.quoteIssued
    },

    atText() {
      if (!this.quoteIssued) return this.$t(".fields.at.unavailable")

      return this.$l("time.formats.short", this.serviceOrder.quoteIssuedAt)
    },

    atFromNowText() {
      if (!this.quoteIssued) return ""

      return this.$fromNow(this.serviceOrder.quoteIssuedAt)
    },


    reviewApproval() {
      if (_.blank(this.events)) return null

      return this.events.find((event) => event.type == "Events::Quotes::Reviews::Approval")
    },

    reviewApprovalText() {
      if (_.blank(this.reviewApproval)) return null

      return this.$t(".fields.reviewApproval.text", {
        at:     this.$l("time.formats.short", this.reviewApproval.createdAt),
        author: this.simpleNameFor(this.reviewApproval.authorName)
      })
    },

    hasReviewApprovalDescription() {
      return _.present(this.reviewApproval?.description)
    },

    reviewApprovalDescriptionText() {
      return this.hasReviewApprovalDescription
        ? this.reviewApproval.description
        : this.$t(".fields.reviewApproval.description.empty")
    },

    subtitleText() {
      if (!this.quoteIssued) return null

      return this.$t(".subtitle", { at: this.atText })
    },

    state() { return this.quote?.state },

    stateText() {
      return Quote.$enums.state.has(this.state)
        ? Quote.$tEnum("state", this.state)
        : this.$t("abbr.unavailable")
    }
  },

  watch: {
    show: {
      immediate: true,
      handler() {
        if (this.show) this.$fetch()
      }
    }
  },

  created() {
    this.$initialize()
  },

  beforeDestroy() {
    if (this.loading) this.$cancel()
  },

  methods: {
    async $initialize() {
      await this.initialize()

      this.initialized = true
    },

    async initialize() {},

    async $cancel() {
      if (this.fetchPromise) this.fetchPromise.cancel()

      await this.cancel()
    },

    async cancel() {},

    async $fetch() {
      if (this.fetching) await this.$cancel()

      this.erred    = false
      this.fetching = true

      await this.fetch()

      this.fetching = false
    },

    async fetch() {
      await this.fetchServiceOrder()

      if (this.quoteId) {
        await this.fetchQuote()
        await this.fetchQuoteEvents()
      }
    },

    async fetchServiceOrder() {
      try {
        this.fetchPromise = this.$newSdk.monitoring.serviceOrders
          .find(this.serviceOrderRid)
        let response = await this.fetchPromise

        this.serviceOrder = response.data
      }
      catch (err) {
        let error = err.error || err

        // XXX: Pode não haver dados registrados
        if (error.cancelled || error.status === 404) return

        this.erred = true
        this.$err.log(err)
        this.$notifications.error(this.$t(".notifications.fetch.failure"))
      }
      finally {
        this.fetchPromise = null
      }
    },

    async fetchQuote() {
      try {
        this.fetchPromise = this.$newSdk.quotes.find({ quoteId: this.quoteId })
        let response = await this.fetchPromise

        this.quote = response.data
      }
      catch (err) {
        let error = err.error || err

        // XXX: Pode não haver dados registrados
        if (error.cancelled || error.status === 404) return

        this.erred = true
        this.$err.log(err)
        this.$notifications.error(this.$t(".notifications.fetch.failure"))
      }
      finally {
        this.fetchPromise = null
      }
    },

    async fetchQuoteEvents() {
      try {
        this.fetchPromise = this.$newSdk.quotes.events(this.quoteId)
        let response = await this.fetchPromise

        this.events = response.data
      }
      catch (err) {
        let error = err.error || err

        // XXX: Pode não haver dados registrados
        if (error.cancelled || error.status === 404) return

        this.erred = true
        this.$err.log(err)
        this.$notifications.error(this.$t(".notifications.fetch.failure"))
      }
      finally {
        this.fetchPromise = null
      }
    },

    // utils
    simpleNameFor(fullname) {
      if (_.blank(fullname)) return null

      let names = fullname.replace(/[^\w\s]/g, "").replace(/\s+/g, " ").trim().split(" ")

      if (_.blank(names)) return "?"

      let firstName = names[0]
      let lastName  = names.length > 1 ? names.pop() : ""

      return `${firstName} ${lastName}`.trim()
    }
  } // methods
}

</script>

import { mixin } from "@caiena/model"

import Base from "./base"
import Discardable from "./mixins/discardable"

class Product extends mixin(Base, [Discardable]) {
  static get attrs() {
    return _.uniq([
      ...super.attrs,
      "name",
      "type",
      "brand",
      "brandId",
      "sapId"
    ])
  }

  static get relations() {
    return {
      brand:        { type: "belongsTo", model: "Brand" },
      dealProducts: { type: "hasMany", model: "DealProduct" }
    }
  }

  static get enums() {
    return {
      type: {
        MotorOil: "MotorOil",
        Tire:     "Tire",
        Part:     "Part",
        Product:  "Product"
      }
    }
  }

  static get constraints() {
    return {
      name: { presence: true, type: "string", length: { maximum: 1000 } },

      sapId: { presence: true, type: "string", length: { minimum: 10, maximum: 10 } }
    }
  }

  static get classNames() {
    return [
      "Product",
      "MotorOil",
      "Part",
      "Tire"
    ]
  }

  // SAMPLE: after init hook
  // ---
  // $afterInit(...args) {
  //   super.$afterInit(...args)
  //
  //   // Se não tiver tipo definido, inicia como Product
  //   if (_.blank(this.type)) {
  //     this.type = "Product"
  //   }
  // }

  get productName() {
    const name = this.name || ""

    return `${name}`
  }

  static get genderInflection() {
    return "masculine"
  }
}

export default Product

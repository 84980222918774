<!--

  Tag para o estado de uma Ordem de Serviço (movida.ServiceOrder tem enum state).

  Defina-o como o :value e o componente exibe uma tag representando o estado, com ícone e texto,
  se adaptando ao tipo da Ordem de Serviço (compra vs serviço).

  Se o estado não for conhecido, exibe _interrogação_ [?] como ícone.

  usage:
    state-tag(:value="serviceOrder.state")

  Para definir um tipo específico de OS, faça:
    // para OS de compra de peça
    state-tag(:value="serviceOrder.state", kind="purchase")

    // para OS de serviço (default kind)
    state-tag(:value="serviceOrder.state", kind="service")

-->

<style scoped lang="scss">

  .state-tag {
    display: flex;
    flex-direction: row;
    align-items: center; // or baseline

    padding: 2px 8px;
    gap: 4px;

    background: $light-gray-3;
    border-radius: 4px;
  }

  .icon {
    font-size: 14px;
    line-height: 14px;

    width: 20px;
    text-align: center;
  }

  .text {
    color: $dark-gray;
    font-family: $secondary-font;
    font-size: 16px;
    font-weight: 400;
    line-height: 150%;

    .state-tag.small & {
      font-size: 12px;
    }
  }

</style>


<template lang="pug">

  .state-tag(:class="{ [state]: true, [size]: true }")
    slot(name="icon")
      i.icon(:class="cssIconClassFor(state)")

    span.text {{ stateText }}

</template>


<script>
import models from "@/models"

const iconThemes = {
  line:    "fal",
  regular: "far",
  solid:   "fas"
}

const SIZES = ["small", "normal"]

export default {
  props: {
    iconTheme: {
      type:      String,
      default:   "regular",
      validator: (value) => Object.keys(iconThemes).includes(value)
    },

    kind: {
      type:      String,
      default:   "service",
      validator: (kind) => ["purchase", "service"].includes(kind)
    },

    value: { type: String, required: true },

    size: {
      type:      String,
      default:   "normal",
      validator: (value) => SIZES.includes(value)
    }
  },

  emits: [],

  data() {
    return {
      i18nScope: "monitoring.service-orders.state-tag"
    }
  },

  computed: {
    state() { return this.value },

    stateText() {
      return models.MovidaServiceOrder.$tEnum("state", this.state)
    },

    isPurchase() {
      return this.kind == "purchase"
    }
  },

  methods: {
    cssIconClassFor(state) {
      const icons = {
        open:     "fa-file-invoice-dollar",
        ongoing:  this.isPurchase ? "fa-truck" : "fa-wrench-simple",
        executed: "fa-check",
        canceled: "fa-times"
      }

      return {
        [iconThemes[this.iconTheme]]:    true,
        [icons[state] || "fa-question"]: true
      }
    }
  }
}

</script>

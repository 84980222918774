<template lang="pug">

  app-modal(:width="1156", @close="onModalClose")
    quote-attachment-preview-data(
      :attachment="attachment",
      :editable="editable",
      :editing="editing",
      :local-edit="localEdit",
      :quote-id="quoteId",
      ref="content"
      @close="$emit('close')",
      @download="$emit('download')",
      @update:attachment="value => $emit('update:attachment', value)",
      @update:editing="value => $emit('update:editing', value)"
    )

</template>


<script>

// Extends
import AttachmentPreview from "./attachment-preview.vue"

export default {
  name: "QuoteAttachmentPreview",

  extends: AttachmentPreview,

  props: {
    quoteId: { type: [String, Number], required: true }
  },

  methods: {
    onModalClose() {
      // XXX: usando controle de componente interno para tratar "dirty" em form
      this.$refs.content.onClose()
    }
  }
}

</script>

function omitDeep(object, paths, { except = [] } = {}) {
  let result = _.cloneDeep(object)
  result = _.omit(result, paths)

  _.forEach(result, (value, key) => {
    if (except.includes(key)) return

    if (_.isArray(value)) {
      result[key] = value.map(val => omitDeep(val, paths, { except }))
    }
    else if (_.isPlainObject(value)) {
      result[key] = omitDeep(value, paths, { except })
    }
  })

  return result
}

export default omitDeep

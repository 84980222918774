import Base from "./base"

import { Sizes } from "./tire/enums"

class TireSize extends Base {
  static get attrs() {
    return _.uniq([
      ...super.attrs,
      "size"
    ])
  }

  static get constraints() {
    return {
      size: { presence: true }
    }
  }

  static get enums() {
    return {
      size: Sizes.asEnum()
    }
  }
}

export default TireSize

<style lang="scss" scoped>
</style>


<template lang="pug">

  router-view(:supplier="supplier")

</template>


<script>

export default {
  name: "SupplierDealsBase",

  props: {
    supplier: { type: Object, default: () => ({}) }
  }
}

</script>

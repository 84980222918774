<!--
<doc lang="markdown">
Exibe uma placa de carro com fundo branco

_Props_: value

Como usar:
```pug
  vehicle-plate(value="ABC1D23")
```
</doc>
-->

<style lang="scss" scoped>

.vehicle-plate {
  background-color: $white;
  padding: 4px 8px;
  border-radius: 4px;
  border: 1px solid $light-gray-4;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);

  .value {
    font-family: $primary-monospace-font;
    font-weight: 400;
    font-size: 16px;
  }
}

</style>


<template lang="pug">

  .vehicle-plate
    span.value {{ value }}

</template>


<script>

export default {
  name: "VehiclePlate",

  props: {
    value: { type: String, required: true }
  },

  data() {
    return {
      i18nScope: "components.vehicle-plate"
    }
  }
}

</script>

<style lang="scss" scoped>

.edit-quote {
  .back{
    color: $dark-gray;
    font-family: $secondary-font;
    font-weight: 500;
    font-size: 14px;

    &:hover {
      color: $orange;
    }

    .icon {
      font-size: 12px;
      margin-right: 4px;
    }
  }

  .load {
    margin-top: 18px;
  }

  .title {
    margin-top: 18px;
    font-family: $secondary-font;
    font-weight: 500;
    font-size: 30px;
  }

  .cards {
    margin-top: 18px;
    grid-template-columns: 1fr 1fr;
    gap: 24px;
  }

  .history {
    margin-top: 16px;
  }

  .section {
    margin-top: 24px;
    padding-top: 24px;
    border-top: 1px solid $light-gray-4;

    &.footer {
      .save {
        margin-left: 24px;
        color: $dark-gray;

        &:hover {
          color: $orange;
        }
      }
    }

    .section-row {
      grid-template-columns: minmax(728px, 1fr) 448px;
      gap: 24px;

      .section-title,
      .subsection-title {
        font-family: $secondary-font;
        font-weight: 500;
      }

      .section-header {
        * + * {
          margin-left: 8px;
        }

        .section-title {
          font-size: 24px;
        }

        .id {
          ::v-deep .content {
            font-size: 20px;
          }
        }

        .pending {
          background-color: $light-gray-2;
          padding: 4px 12px;
          border-radius: 24px;
          font-size: 14px;
          font-weight: 500;
          font-family: $secondary-font;
        }
      }

      .date {
        font-size: 14px;

        .icon {
          font-size: 12px;
          color: $purple;
          margin-right: 8px;
        }
      }

      .wave-separator {
        height: 16px;
        border-radius: 4px;
        margin-top: 16px;

        &.quote-separator {
          background: var(--purple-waves-background)
        }

        &.service-order-separator {
          background: var(--orange-waves-background)
        }
      }

      .subsection-header {
        margin-bottom: 8px;

        .subsection-title {
          font-size: 16px;
        }
      }

      .icon-container {
        width: 16px;
        height: 16px;
        margin-right: 8px;

        .icon {
          font-size: 14px;
        }
      }

      .diamond-icon {
        font-size: 4px;
        color: $gray-3;
        margin: 0 8px;
      }

      .count {
        font-family: $secondary-font;
        font-weight: 400;
        font-size: 16px;
        color: $gray-3;
      }

      .description {
        font-size: 14px;
      }

      .summary {
        height: 60px;
        box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.25);
        border-radius: 8px;
        padding: 0 16px 0 8px;

        .subtotal {
          width: 114px;
        }

        .summary-label {
          font-family: $secondary-font;
          font-weight: 400;
          font-size: 12px;
        }

        .summary-value {
          font-family: $primary-monospace-font;
          font-weight: 400;
          font-size: 12px;

          &.total {
            font-weight: 500;
            font-size: 18px;
          }
        }

        .total-container {
          .icon {
            margin-right: 4px;

            &.cheaper {
              color: $success;
            }

            &.more-expensive {
              color: $error;
            }
          }
        }
      }

      .attachments {
        grid-template-columns: minmax(212px, 1fr) minmax(212px, 1fr) minmax(212px, 1fr);
        gap: 8px 24px;
      }

      .empty {
        background-color: $light-gray-3;
        padding: 8px;
        border-radius: 4px;
        text-align: center;
        font-weight: 300;
        font-size: 12px;
        font-style: italic;
      }

      .quote-column {
        padding: 16px 0;

        .summary {
          background: var(--purple-light-waves-background), $light-gray;
        }
      }

      .service-order-column {
        padding: 0 16px;
        background-color: $light-gray;

        .section-title,
        .subsection-title {
          color: $gray-3;
        }

        .container {
          padding: 16px 0;
        }

        .summary {
          background: var(--orange-light-waves-background), $white;
        }
      }

      & + .section-row {
        .quote-column {
          border-top: 1px solid $light-gray-4;
        }

        .service-order-column {
          .container {
            border-top: 1px solid $light-gray-3;
          }
        }
      }

      &:last-child {
        .quote-column {
          padding: 16px 0 0 0;
        }
      }
    }
  }
}

</style>


<template lang="pug">

  .edit-quote(:style="cssVars")
    app-button.back(theme="link", @click="goBack")
      i.icon.fas.fa-arrow-left
      span {{ $t(".back") }}

    template(v-if="fetching")
      loading-lines.load(:lines="6")

    template(v-else)
      h1.title {{ $t(".title") }}

      .cards.grid
        .vehicle
          vehicle-card(:vehicle="vehicle")

        .supplier
          supplier-card(:supplier="supplier")

      vehicle-history.history(:vehicle-id="vehicle.id")

      .section.form
        .section-row.grid
          .quote-column
            .section-header.flex.vertical-center
              h2.section-title {{ $t(".sections.quote.section-title") }}
              id-tag.id(:value="quote.id")
              template(v-if="quote.state === 'issued'")
                .pending.flex.vertical-center
                  i.icon.far.fa-file-invoice-dollar
                  span {{ $t(".pending") }}

            .date.flex.vertical-center
              i.icon.fas.fa-calendar
              span {{ $t(".sections.quote.date", { date: $fromNow(quote.issuedAt) }) }}
              i.diamond-icon.fas.fa-diamond
              span {{ $l("time.formats.short", quote.issuedAt) }}

            .wave-separator.quote-separator

          .service-order-column
            .container
              .section-header.flex.vertical-center
                h2.section-title {{ $t(".sections.service-order.section-title") }}
                vetor-code-tag(:value="serviceOrder.id")

              .date.flex.vertical-center
                i.icon.fas.fa-calendar
                span {{ $t(".sections.service-order.date", { date: $fromNow(serviceOrder.scheduledAt) }) }}
                i.diamond-icon.fas.fa-diamond
                span {{ $l("time.formats.short", serviceOrder.scheduledAt) }}

              .wave-separator.service-order-separator

        .section-row.grid
          .quote-column
            .subsection-header.flex.vertical-center
              .icon-container.flex.center.vertical-center
                i.icon.far.fa-message-lines
              h2.subsection-title {{ $t(".sections.quote.description.label") }}

            span.description.preserve-whitespace {{ quote.description }}

          .service-order-column
            .container
              .subsection-header.flex.vertical-center
                .icon-container.flex.center.vertical-center
                  i.icon.far.fa-message-lines
                h2.subsection-title {{ $t(".sections.service-order.description.label") }}

              span.description.preserve-whitespace {{ serviceOrder.description }}

        .section-row.grid
          .quote-column
            .subsection-header.flex.vertical-center
              .icon-container.flex.center.vertical-center
                i.icon.far.fa-message-lines
              h2.subsection-title {{ $t(".sections.quote.services.label") }}
              i.diamond-icon.fas.fa-diamond
              span.count {{ $t(".services.count", { count: quote.quoteServices.length }) }}

            quote-items-table(
              :empty="quote.quoteServices.length === 0",
              :empty-text="$t('.services.empty')",
              :subtotal="quoteServicesSubtotal"
            )
              template(#rows)
                quote-service-row(
                  v-for="quoteService in quote.quoteServices",
                  :errors="quoteServicesErrors[keyFor(quoteService)]",
                  :key="`quote-service-${quoteService.id}`",
                  :quote-service="quoteService",
                  :submitting="submitting"
                )

          .service-order-column
            .container
              .subsection-header.flex.vertical-center
                .icon-container.flex.center.vertical-center
                  i.icon.far.fa-message-lines
                h2.subsection-title {{ $t(".sections.service-order.services.label") }}
                i.diamond-icon.fas.fa-diamond
                span.count {{ $t(".services.count", { count: serviceOrder.serviceOrderServices.length }) }}

              service-order-items-table(
                :empty="serviceOrder.serviceOrderServices.length === 0",
                :empty-text="$t('.services.empty')",
                :subtotal="serviceOrderServicesSubtotal"
              )
                template(#rows)
                  service-order-service-row(
                    v-for="serviceOrderService in serviceOrder.serviceOrderServices",
                    :key="`service-order-service-${serviceOrderService.id}`",
                    :service-order-service="serviceOrderService"
                  )

        .section-row.grid
          .quote-column
            .subsection-header.flex.vertical-center
              .icon-container.flex.center.vertical-center
                i.icon.far.fa-message-lines
              h2.subsection-title {{ $t(".sections.quote.products.label") }}
              i.diamond-icon.fas.fa-diamond
              span.count {{ $t(".products.count", { count: unprovidedQuoteProducts.length }) }}

            quote-items-table(
              :empty="unprovidedQuoteProducts.length === 0",
              :empty-text="$t('.products.empty')",
              :subtotal="quoteProductsSubtotal"
            )
              template(#rows)
                quote-product-row(
                  v-for="quoteProduct in unprovidedQuoteProducts",
                  :errors="quoteProductsErrors[keyFor(quoteProduct)]",
                  :key="`quote-product-${quoteProduct.id}`",
                  :quote-product="quoteProduct",
                  :submitting="submitting"
                )

          .service-order-column
            .container
              .subsection-header.flex.vertical-center
                .icon-container.flex.center.vertical-center
                  i.icon.far.fa-message-lines
                h2.subsection-title {{ $t(".sections.service-order.products.label") }}
                i.diamond-icon.fas.fa-diamond
                span.count {{ $t(".products.count", { count: serviceOrder.serviceOrderProducts.length }) }}

              service-order-items-table(
                :empty="serviceOrder.serviceOrderProducts.length === 0",
                :empty-text="$t('.products.empty')",
                :subtotal="serviceOrderProductsSubtotal"
              )
                template(#rows)
                  service-order-product-row(
                    v-for="serviceOrderProduct in serviceOrder.serviceOrderProducts",
                    :key="`service-order-product-${serviceOrderProduct.id}`",
                    :service-order-product="serviceOrderProduct"
                  )

        .section-row.grid
          .quote-column
            .subsection-header.flex.vertical-center
              .icon-container.flex.center.vertical-center
                i.icon.far.fa-message-lines
              h2.subsection-title {{ $t(".sections.quote.provided-products.label") }}
              i.diamond-icon.fas.fa-diamond
              span.count {{ $t(".products.count", { count: providedQuoteProducts.length }) }}

            quote-provided-products-table(
              :empty="providedQuoteProducts.length === 0",
              :empty-text="$t('.products.empty')"
            )
              template(#rows)
                quote-provided-product-row(
                  v-for="quoteProduct in providedQuoteProducts",
                  :errors="quoteProductsErrors[keyFor(quoteProduct)]",
                  :key="`quote-product-${quoteProduct.id}`",
                  :quote-product="quoteProduct",
                  :submitting="submitting"
                )

          .service-order-column
            .subsection-header.container

        .section-row.grid
          .quote-column
            .summary.flex.vertical-center.space-between
              .left.flex.vertical-center
                .subtotal.flex.column-direction
                  span.summary-label {{ $t(".summary.services-subtotal") }}
                  span.summary-value {{ $asCurrency(quoteServicesSubtotal) }}

                .subtotal.flex.column-direction
                  span.summary-label {{ $t(".summary.products-subtotal") }}
                  span.summary-value {{ $asCurrency(quoteProductsSubtotal) }}

              .total
                .flex.column-direction.vertical-bottom
                  span.summary-label {{ $t(".summary.total") }}
                  .total-container
                    template(v-if="isQuoteCheaper")
                      i.icon.fas.fa-caret-down.cheaper
                    template(v-else-if="isQuoteMoreExpensive")
                      i.icon.fas.fa-caret-up.more-expensive
                    span.summary-value.total {{ $asCurrency(quoteTotal) }}

          .service-order-column
            .container
              .summary.flex.vertical-center.space-between
                .left.flex.vertical-center
                  .subtotal.flex.column-direction
                    span.summary-label {{ $t(".summary.services-subtotal") }}
                    span.summary-value {{ $asCurrency(serviceOrderServicesSubtotal) }}

                  .subtotal.flex.column-direction
                    span.summary-label {{ $t(".summary.products-subtotal") }}
                    span.summary-value {{ $asCurrency(serviceOrderProductsSubtotal) }}

                .total
                  .flex.column-direction.vertical-bottom
                    span.summary-label {{ $t(".summary.total") }}
                    span.summary-value.total {{ $asCurrency(serviceOrderTotal) }}

        .section-row.grid
          .quote-column
            .subsection-header.flex.vertical-center.space-between
              .flex.vertical-center
                .icon-container.flex.center.vertical-center
                  i.icon.far.fa-message-lines
                h2.subsection-title {{ $t(".sections.quote.attachments.label") }}
                i.diamond-icon.fas.fa-diamond
                span.count {{ $t(".attachments.count", { count: quoteAttachments.length }) }}

              template(v-if="hasAttachments && !submitting")
                app-button(
                  theme="link",
                  @click="previewAttachment(0)"
                ) {{ $t(".sections.quote.attachments.gallery") }}

            template(v-if="hasAttachments")
              .attachments.grid
                narrow-quote-attachment-card(
                  v-for="(attachment, index) in quoteAttachments",
                  :attachment="attachment",
                  :deletable="false",
                  :editable="false",
                  :key="`quote-attachment-${attachment.id}`",
                  :loading="submitting",
                  :own-preview="false",
                  :quote-id="quoteId",
                  @preview="previewAttachment(index)"
                )

              quote-attachments-carousel(
                v-if="showAttachmentsCarousel",
                :attachments="quoteAttachments",
                :index.sync="attachmentIndex",
                :quote-id="quoteId",
                @close="showAttachmentsCarousel = false"
              )

            template(v-else)
              .empty {{ $t(".sections.quote.attachments.empty") }}

          .service-order-column
            .container
              service-order-attachments(:service-order-id="serviceOrder.id")

      .section.footer.flex.space-between
        .left.flex.vertical-center
          app-button(
            v-if="$can('update', 'Quote')",
            theme="link",
            @click="openChangeRequestModal"
          ) {{ $t(".btn.request-changes.label") }}

          app-button.save(
            v-if="$can('update', 'Quote')",
            theme="link",
            @click="submit",
          ) {{ $t(".btn.save.label") }}

        app-button(
          v-if="$can('update', 'Quote')",
          @click="openApprovalModal"
        ) {{ $t(".btn.approve-review.label") }}

      new-quotes-reviews-changes-request(
        v-if="showChangesRequestModal",
        :quote-id="quoteId",
        @change="redirect",
        @close="showChangesRequestModal = false"
      )

      new-quotes-reviews-approval(
        v-if="showApprovalModal",
        :quote-id="quoteId",
        @change="redirect",
        @close="showApprovalModal = false"
      )

</template>


<script>

// Assets
import purpleWaves      from "@/assets/images/waves-purple.png"
import purpleLightWaves from "@/assets/images/waves-purple-light.png"
import orangeWaves      from "@/assets/images/waves-orange.png"
import orangeLightWaves from "@/assets/images/waves-orange-light.png"

// View
import LoadedView from "@/views/loaded-view"

// Mixins
import FetchMixin from "@/mixins/fetch-mixin"
import FormMixin  from "@/mixins/form-mixin"

// Components
import NewQuotesReviewsChangesRequest from "@/views/monitoring/quotes/reviews/changes-requests/new.vue"
import NewQuotesReviewsApproval       from "@/views/monitoring/quotes/reviews/approvals/new.vue"
import SupplierCard   from "../_components/supplier-card.vue"
import VehicleCard    from "../_components/vehicle-card.vue"
import VehicleHistory from "../_components/vehicle-history.vue"
import ServiceOrderItemsTable  from "./_components/service-order-items-table.vue"
import ServiceOrderServiceRow  from "./_components/service-order-service-row.vue"
import ServiceOrderProductRow  from "./_components/service-order-product-row.vue"
import ServiceOrderAttachments from "./_components/service-order-attachments.vue"
import QuoteItemsTable from "./_components/quote-items-table.vue"
import QuoteServiceRow from "./_components/quote-service-row.vue"
import QuoteProductRow from "./_components/quote-product-row.vue"
import QuoteProvidedProductsTable from "./_components/quote-provided-products-table.vue"
import QuoteProvidedProductRow    from "./_components/quote-provided-product-row.vue"


export default {
  name: "ReviewQuote",

  components: {
    NewQuotesReviewsChangesRequest,
    NewQuotesReviewsApproval,
    SupplierCard,
    VehicleCard,
    ServiceOrderItemsTable,
    ServiceOrderServiceRow,
    ServiceOrderProductRow,
    QuoteItemsTable,
    QuoteServiceRow,
    QuoteProductRow,
    QuoteProvidedProductsTable,
    QuoteProvidedProductRow,
    VehicleHistory,
    ServiceOrderAttachments
  },

  extends: LoadedView,

  mixins: [FetchMixin, FormMixin],

  data() {
    return {
      i18nScope: "monitoring.quotes.reviews.edit",
      routeName: "reviewQuote",

      quoteId: null,

      resource: {},

      showChangesRequestModal: false,
      showApprovalModal:       false,
      showAttachmentsCarousel: false,
      attachmentIndex:         0,

      nestedAttributes: {
        quoteProducts: null,
        quoteServices: null
      }
    }
  },

  computed: {
    backRoute() {
      return {
        name: "monitoring"
      }
    },

    quote: {
      get() { return this.resource },

      set(value) { this.resource = value }
    },

    serviceOrder() {
      return this.quote?.serviceOrder
    },

    vehicle() {
      return this.serviceOrder?.vehicle
    },

    supplier() {
      return this.serviceOrder?.supplier
    },

    providedQuoteProducts() {
      return this.quote?.quoteProducts?.filter(quoteProduct => quoteProduct.provided)
    },

    unprovidedQuoteProducts() {
      return this.quote?.quoteProducts?.filter(quoteProduct => !quoteProduct.provided)
    },

    quoteServicesSubtotal() {
      return this.getTotalPrice(this.quote?.quoteServices)
    },

    quoteProductsSubtotal() {
      return this.getTotalPrice(this.quote?.quoteProducts)
    },

    quoteTotal() {
      return this.quoteServicesSubtotal + this.quoteProductsSubtotal
    },

    serviceOrderServicesSubtotal() {
      return this.getTotalPrice(this.serviceOrder?.serviceOrderServices)
    },

    serviceOrderProductsSubtotal() {
      return this.getTotalPrice(this.serviceOrder?.serviceOrderProducts)
    },

    serviceOrderTotal() {
      return this.serviceOrderServicesSubtotal + this.serviceOrderProductsSubtotal
    },

    isQuoteCheaper() {
      return this.quoteTotal < this.serviceOrderTotal
    },

    isQuoteMoreExpensive() {
      return this.quoteTotal > this.serviceOrderTotal
    },

    quoteAttachments() {
      return [...this.quote.quoteServices, ...this.quote.quoteProducts].map(item => item.attachments).flat()
    },

    hasAttachments() {
      return this.quoteAttachments.length > 0
    },

    cssVars() {
      return {
        "--purple-waves-background":       `url(${purpleWaves})`,
        "--purple-light-waves-background": `url(${purpleLightWaves})`,
        "--orange-waves-background":       `url(${orangeWaves})`,
        "--orange-light-waves-background": `url(${orangeLightWaves})`
      }
    },

    quoteProductsErrors() {
      return this.errors?.quoteProductsAttributes || []
    },

    quoteServicesErrors() {
      return this.errors?.quoteServicesAttributes || []
    }
  },

  methods: {
    goBack() {
      this.$goBack({ fallback: this.backRoute })
    },

    async redirect() {
      await this.$nextTick()

      this.$router.push({ name: "monitoring" })
    },

    getTotalPrice(list) {
      return list
        ?.filter(item => !item.$markedForDestruction)
        ?.map(item => item.totalPrice)
        ?.filter(totalPrice => totalPrice !== null)
        ?.reduce((accumulated, current) => accumulated + current, 0) || 0
    },

    previewAttachment(index) {
      this.attachmentIndex = index
      this.showAttachmentsCarousel = true
    },

    openChangeRequestModal() {
      this.submit({ requestChanges: true })
    },

    openApprovalModal() {
      this.submit({ approve: true })
    },

    keyFor(resource) {
      return resource.id || resource.tempId
    },

    // @override Loaded view
    parseRoute() {
      this.quoteId = this.$params.asInteger(this.$route.params.id)
    },

    // @override FetchMixin
    fetchRequest() {
      return this.$newSdk.quotes.find({ quoteId: this.quoteId })
    },

    // @override Fetch mixin
    afterFetchSuccess() {
      this.initialSerializedAttributes = this.serializeAttributes()
    },

    // @override Form mixin
    serializeAttributes() {
      const relations = ["quote_products_attributes", "quote_services_attributes"]
      const serialized = _.pick(this.resource.$serialize({}, this.nestedAttributes), relations)

      relations.forEach(relation => {
        if (_.blank(serialized[relation])) return

        serialized[relation] = serialized[relation].map(item => (_.present(item.discarded_at)
          ? _.pick(item, ["discarded_at", "id"])
          : item
        ))
      })

      return serialized
    },

    // @override Form mixin
    submitRequest() {
      return this.$newSdk.quotes.update({ quoteId: this.quoteId, params: this.serializeAttributes() })
    },

    // @override Form mixin
    afterSubmitSuccess({ requestChanges, approve }) {
      if (requestChanges) this.showChangesRequestModal = true
      else if (approve) this.showApprovalModal = true
      else this.fetch()
    }
  }
}

</script>

// Ability
import { ability } from "@/modules/ability"

// Routes
import NewVehiclePart from "@/views/vehicle-parts/new.vue"
import VehiclePart from "@/views/vehicle-parts/show.vue"
import EditVehiclePart from "@/views/vehicle-parts/edit.vue"
import VehicleParts from "@/views/vehicle-parts/index.vue"

export default [
  {
    path:      "/vehicle-parts/new",
    name:      "newVehiclePart",
    component: NewVehiclePart,
    meta:      {
      auth:        true,
      permissions: [() => ability.can("create", "VehiclePart")]
    }
  },

  {
    path:      "/vehicle-parts/:id",
    name:      "vehiclePart",
    component: VehiclePart,
    meta:      {
      auth:        true,
      permissions: [() => ability.can("read", "VehiclePart")]
    }
  },

  {
    path:      "/vehicle-parts/:id/edit",
    name:      "editVehiclePart",
    component: EditVehiclePart,
    meta:      {
      auth:        true,
      permissions: [() => ability.can("update", "VehiclePart")]
    }
  },

  {
    path:      "/vehicle-parts",
    name:      "vehicleParts",
    component: VehicleParts,
    meta:      {
      auth:        true,
      permissions: [() => ability.can("index", "VehiclePart")]
    }
  }
]


<style lang="scss" scoped>

.form-fields {
  .section {
    padding: 16px 24px;

    & + .section {
      border-top: 1px solid $light-gray-3;
    }

    &.service {
      .vehicle-issue-message {
        margin-top: 16px;
        font-size: 14px;

        .icon {
          color: $warning;
          margin-right: 8px;
        }

        .message {
          font-weight: 400;
          color: $gray-3;
        }
      }
    }
  }
}

</style>


<template lang="pug">

  .form-fields
    .section.issue
      ui-row.ui-row
        ui-col(:span="6")
          vehicle-issue-select-field(
            name="service[vehicleIssue]",
            :label="$t('.fields.vehicleIssue.label')",
            :loading="submitting",
            v-model="vehicleIssue",
            data-as-value,
            @change-option="onChangeVehicleIssue"
          )

        ui-col(:span="6")
          template(v-if="vehicleIssue")
            targets-nested-fields(
              v-model="resource.targets",
              :vehicle-part="vehiclePart",
              :submitting="submitting",
              :errors="errors",
              :minimum="minimumTargets"
            )

    .section.service
      ui-row.ui-row
        ui-col(:span="10")
          vehicle-issue-solution-select-field(
            name="service[vehicleIssueSolution]",
            :label="$t('.fields.vehicleIssueSolution.label')",
            :loading="submitting",
            :errors="errors.serviceId",
            :vehicle-issue-id="vehicleIssueId",
            v-model="resource.vehicleIssueSolutionId",
            @option-loaded="onLoadVehicleIssueSolution",
          )
          template(v-if="!hasVehicleIssue")
            .vehicle-issue-message
              i.icon.far.fa-info-circle
              span.message {{ $t('.fields.vehicleIssueSolution.disabled.label') }}

        ui-col(:span="2")
          integer-field(
            name="service[quantity]",
            :label="$t('.fields.quantity.label')",
            :loading="submitting",
            v-model="resource.quantity",
            :errors="errors.quantity",
            :min="1",
            :max="999"
          )

    .section.attachments
      service-attachments-index(
        :service-order-service-id="resource.id",
        :new-resource="!this.edit",
        :attachments="resource.attachments",
        :submitting="submitting",
        editableList,
        editableAttachments
      )

    .section.description
      ui-row.ui-row
        ui-col(:span="12")
          textarea-field(
            name="service[description]",
            :loading="submitting",
            v-model="resource.description",
            :errors="errors.description",
            :placeholder="$t('.fields.description.placeholder')"
            hide-label
          )

</template>


<script>

// Components
import VehicleIssueSelectField from "./vehicle-issue-select-field.vue"
import TargetsNestedFields from "./targets-nested-fields.vue"
import VehicleIssueSolutionSelectField from "./vehicle-issue-solution-select-field.vue"
import ServiceAttachmentsIndex from "../attachments/index.vue"

// Models
import models from "@/models"
const { VehiclePartTarget } = models

export default {
  components: {
    VehicleIssueSelectField,
    TargetsNestedFields,
    VehicleIssueSolutionSelectField,
    ServiceAttachmentsIndex
  },

  props: {
    resource:   { type: Object, default: () => ({}) },
    submitting: { type: Boolean, default: false },
    errors:     { type: Object, default: () => ({}) },
    edit:       { type: Boolean, default: false }
  },

  data() {
    return {
      i18nScope: "tickets.drafts.contents.services.components.form-fields",

      vehicleIssue: null
    }
  },

  computed: {
    vehicleIssueId() {
      return _.get(this.vehicleIssue, "id")
    },

    hasVehicleIssue() {
      return _.present(this.vehicleIssueId)
    },

    vehiclePart() {
      return _.get(this.vehicleIssue, "vehiclePart") || {}
    },

    availablePositions() {
      return VehiclePartTarget.$enums.position.values.filter(position => this.vehiclePart[`${position}Position`])
    },

    availableLocations() {
      return VehiclePartTarget.$enums.location.values.filter(location => this.vehiclePart[`${location}Location`])
    },

    hasAvailablePositions() {
      return _.present(this.availablePositions)
    },

    hasAvailableLocations() {
      return _.present(this.availableLocations)
    },

    minimumTargets() {
      return this.hasAvailablePositions && this.hasAvailableLocations ? 1 : 0
    }
  },

  methods: {
    onChangeVehicleIssue() {
      // eslint-disable-next-line vue/no-mutating-props
      this.resource.vehicleIssueSolutionId = null
      // eslint-disable-next-line vue/no-mutating-props
      this.resource.targets = []

      this.$emit("update:errors", {})
    },

    onLoadVehicleIssueSolution(option) {
      this.vehicleIssue = option.data.vehicleIssue
    }
  }
}

</script>

<template lang="pug">

  .new
    ticket-draft-wizard-header

    template(v-if="!hasVehicle")
      vehicle-search-step(
        :license-plate="licensePlate",
        @input="setVehicle"
      )

    template(v-else)
      form.form(ref="form", method="post", @submit.prevent="submit")
        ticket-details-step(
          :vehicle="vehicle",
          :resource="resource",
          :errors="errors",
          @back="vehicle = null",
          @submit="submit"
        )

</template>


<script>

// View
import BaseView from "@/views/base-view"

// Mixins
import FormMixin from "@/mixins/form-mixin"

// Components
import VehicleSearchStep from "./new/vehicle-search-step.vue"
import TicketDetailsStep from "./vehicles/components/ticket-details-step.vue"

// Models
import models from "@/models"
const { ServiceOrderRepair, TicketRepair } = models

export default {
  name: "NewDraftTicket",

  components: {
    VehicleSearchStep,
    TicketDetailsStep
  },

  extends: BaseView,

  mixins: [FormMixin],

  data() {
    return {
      i18nScope: "tickets.drafts.new",
      routeName: "newDraftTicket",

      vehicleNotFound: false,

      vehicle: null,

      licensePlate: this.$route.query.licensePlate,

      resource: new TicketRepair({ serviceOrders: [] }),

      nestedAttributes: {
        serviceOrders: null
      }
    }
  },

  computed: {
    hasVehicle() {
      return _.present(this.vehicle)
    }
  },

  created() {
    this.initialSerializedAttributes = this.serializeAttributes()
  },

  methods: {
    setVehicle(vehicle) {
      if (_.present(this.$route.query)) this.$router.replace({ query: {} })

      const serviceOrder = new ServiceOrderRepair({ kind: vehicle.state === "damaged" ? "damage" : "corrective" })
      serviceOrder.tempId = 0

      this.resource = new TicketRepair({
        externalVehicleId:   vehicle.id,
        storeId:             vehicle.currentStoreId,
        vehicleLicensePlate: vehicle.licensePlate,
        vehicleKm:           vehicle.km,
        vehicleModelId:      vehicle.vehicleModel.id,
        fuelLevel:           vehicle.fuelLevel,

        serviceOrders: [serviceOrder]
      })

      this.errors = {}

      this.vehicle = vehicle
    },

    // @override Form mixin
    serializeAttributes() {
      const serviceOrdersAttributes = this.resource.serviceOrders.map(serviceOrder => serviceOrder.$serialize({ virtuals: true }))

      return {
        ...this.resource.$serialize({ virtuals: true }),

        service_orders_attributes: serviceOrdersAttributes
      }
    },

    // @override Form mixin
    submitRequest() {
      return this.$newSdk.tickets.drafts.create({ params: this.serializeAttributes() })
    },

    // @override Form mixin
    onSubmitSuccess({ data }) {
      this.$router.push({ name: "editDraftTicketContent", params: { id: data.id } })
    }
  }
}

</script>

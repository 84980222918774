<style lang="scss" scoped>

.index {
  .header {
    margin-top: 24px;
    margin-bottom: 32px;

    .title {
      .title-text {
        font-size: 30px;
        font-weight: normal;
        font-weight: 500;
        font-family: $secondary-font;
      }
    }

    .actions {
      .app-button + .app-button {
        margin-left: 8px;
      }

      .fa-link {
        margin-right: 8px;
      }
    }
  }

  .no-resource-registered {
    padding: 24px;
    border: 1px solid $light-gray-3;
    border-radius: 4px;
    color: $gray-3;
    font-size: 14px;

    .no-resource-registered-icon {
      user-select: none;
      margin-bottom: 8px;
      height: 78px;
      width: 89px;
    }
  }

  .deal-info-card + .deal-info-card {
    margin-top: 24px;
  }
}

</style>


<template lang="pug">

  .index
    header.header.flex.space-between
      .title.flex.vertical-center
        h1.title-text {{ $t('.title') }}
      .actions
        template(v-if="false")
          app-button.app-button(@click="confirmFollowParentDeal", outline)
            i.far.fa-link
            | {{ $t('.btn.followParentDeal') }}
        app-button.app-button(
          v-if="$can('create', 'Deal')",
          @click="createDeal"
        ) {{ $t('.btn.new') }}

    loading-lines(
      v-if="fetching",
      :lines="4"
    )

    template(v-else-if="empty && !fetching")
      .no-resource-registered.flex.center.column-direction.vertical-center
        img.no-resource-registered-icon(:src="dealPlaceholderIcon")
        span {{ $t('.empty') }}

    template(v-else)
      template(v-if="ongoingDeal")
        deal-info-card(
          :deal="ongoingDeal",
          :supplierId="supplierId",
          :loading="fetching",
          data-testid="dealInfoCard"
        )

      deal-info-card(
        v-for="deal in nonOngoingDeals",
        :key="deal.id",
        :deal="deal",
        :supplierId="supplierId",
        :loading="fetching",
        @destroy="onDestroy",
        data-testid="dealInfoCard"
      )

</template>


<script>

// View
import LoadedView from "@/views/loaded-view"

// Mixins
import FetchMixin from "@/mixins/fetch-mixin"

// Components
import DealInfoCard from "./components/deal-info-card"

// Assets
import dealPlaceholderIcon from "@/assets/images/placeholder-icons/deal.svg"
import noResultsIcon from "@/assets/images/no-results.svg"

// Models
import models from "@/models"
const { Deal } = models

export default {
  name: "SupplierDeals",

  components: {
    DealInfoCard
  },

  extends: LoadedView,

  mixins: [FetchMixin],

  data() {
    return {
      i18nScope: "suppliers.deals.index",
      routeName: "supplierDeals",

      supplierId: null,

      resource: [],

      // Create
      submitting:    false,
      submitRequest: null,

      dealPlaceholderIcon,
      noResultsIcon,

      Deal
    }
  },

  computed: {
    deals: {
      get() { return this.resource },

      set(value) { this.resource = value }
    },

    empty() {
      return _.blank(this.resource)
    },

    ongoingDeal() {
      return this.deals.find(deal => deal.isOngoing)
    },

    nonOngoingDeals() {
      return this.deals.filter(deal => !deal.isOngoing)
    }
  },

  methods: {
    parseRoute() {
      this.supplierId = this.$params.asInteger(this.$route.params.id)
    },

    onDestroy() {
      this.fetch()
    },

    // @override Fetch Mixin
    fetchRequest() {
      return this.$newSdk.suppliers.listDeals({ supplierId: this.supplierId })
    },

    async confirmFollowParentDeal() {
      const isConfirmed = await this.$confirm({
        heading: this.$t(".confirmFollowParentDeal.title"),
        message: this.$t(".confirmFollowParentDeal.message"),

        actions: {
          confirm: this.$t(".confirmFollowParentDeal.confirm")
        }
      })

      // eslint-disable-next-line no-useless-return
      if (!isConfirmed) return

      // TODO: Completar ou remover a feature
    },

    async createDeal() {
      this.$notifications.clear()
      this.submitting = true

      let currentRequest

      try {
        if (this.submitRequest) this.submitRequest.cancel("Duplicated submit")
        currentRequest = this.$sdk.suppliers.createDeal({ id: this.supplierId })
        this.submitRequest = currentRequest

        const response = await currentRequest
        const deal = new Deal(response.data)

        this.$router.push({ name: "editSupplierDeal", params: { id: this.supplierId, dealId: deal.id } })
      }
      catch (err) {
        if (!err.cancelled) {
          this.$err.log(err)
          this.$notifications.error(this.$t(".notifications.submit.error"))
        }
      }
      finally {
        if (this.submitRequest === currentRequest) {
          this.submitRequest = null
          this.submitting = false
        }
      }
    }
  }
}

</script>

<style scoped lang="scss">

.supplier-section {
  .photo {
    margin-right: 16px;
  }

  .content {
    overflow: hidden;

    .address,
    .name-loading {
      margin-bottom: 16px;
    }

    .name {
      font-size: 20px;
      font-weight: normal;
      font-family: $secondary-font;
      margin-bottom: 4px;
    }

    .diamond-icon {
      margin: 0 8px;
      font-size: 4px;
      color: $gray-3;
    }

    .address {
      .icon {
        color: $gray-2;
        font-size: 14px;
        margin-right: 8px;
      }
    }

    .contact {
      ::v-deep .movida-common__info-label-info-content {
        overflow: hidden;
      }

      .copy-icon {
        margin-left: 4px;
      }
    }
  }
}

</style>


<template lang="pug">

  .supplier-section
    .flex
      avatar.photo(placeholder="fas fa-user-hard-hat")
      .content.full-width
        template(v-if="fetching")
          loading-lines.name-loading(:min="50", :max="80", :lines="2")
        template(v-else)
          .name-content.flex.vertical-center
            app-span.name(:value="name", crop)
            i.diamond-icon.fa.fa-diamond.flex.center.vertical-center
            hierarchy-code-tag(:value="supplier.hierarchy")
          .address.flex.vertical-center
            i.icon.fal.fa-house-user
            app-span(:value="addressLine1", crop)
        .contact
          info-label.info-label(
            :loading="fetching",
            :label="$t('.contact.title')",
            :empty-text="$t('.contact.empty')"
          )
            .flex.vertical-center(v-if="hasServiceOrderEmail || hasServiceOrderContactName")
              app-span.contact-name(:value="supplier.serviceOrderContactName", crop)
              i.diamond-icon.spacer.fa.fa-diamond.flex.center.vertical-center(v-if="hasServiceOrderEmaiAndContactName")
              app-span.contact-email(:value="supplier.serviceOrderEmail", crop)
              copy-icon.copy-icon(:copy-value="supplier.serviceOrderEmail")

</template>


<script>

export default {
  props: {
    supplier: { type: Object, default: () => ({}) },
    fetching: { type: Boolean, default: false }
  },

  data() {
    return {
      i18nScope: "stores.tickets.service-orders.show.service-order-supplier-card"
    }
  },

  computed: {
    name() {
      return this.supplier.tradingName || this.supplier.name
    },

    addressLine1() {
      return _.get(this.supplier, "address.addressLine1")
    },

    hasServiceOrderContactName() {
      return _.present(this.supplier.serviceOrderContactName)
    },

    hasServiceOrderEmail() {
      return _.present(this.supplier.serviceOrderEmail)
    },

    hasServiceOrderEmaiAndContactName() {
      return this.hasServiceOrderContactName && this.hasServiceOrderEmail
    }
  }
}

</script>


<style scoped lang="scss">

.row + .row {
  margin-top: 24px;
}

.labor-cost-linked {
  margin-top: 25px;
  border: 1px solid $light-gray-4;
  border-radius: 4px;
  padding: 9px;

  .indicator {
    margin-left: 16px;
  }
}

.section {
  margin-bottom: 24px;
  padding-bottom: 24px;
  border-bottom: 1px solid $light-gray-3;
}

.type {
  .label {
    display: block;
    margin-bottom: 8px;
    font-size: 14px;
    line-height: 17px;
    font-weight: 500;
    font-family: $secondary-font;
  }

  .service-icon {
    margin-right: 8px;
  }
}

.radio-label {
  padding-left: 12px;
  font-size: 14px;
  font-family: $secondary-font;
  font-weight: 500;
  margin-right: 8px;

  .info-icon {
    padding-left: 4px;
  }
}

.radio-icon {
  margin-left: 12px;
}

:not(.disabled-option):not(.disabled) {
  > .label-container > * {
    cursor: pointer;
    user-select: none;
  }
}

.movida-common__radio-field.movida-common__horizontal {
  ::v-deep.movida-common__radio-field-input {
    border: 1px solid $light-gray-4;
    border-radius: 4px;
    padding: 5px;
  }
}

.section-title {
  margin-bottom: 16px;

  .title-icon {
    margin-right: 8px;
    font-size: 16px;
  }

  .title {
    font-family: $secondary-font;
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 4px;
  }
}

.subtitle {
  font-weight: 300;
  font-size: 16px;
}

.issues-list {
  margin-top: 16px;
}

.vehicle-issues {
  ::v-deep.rows {
    max-height: 520px;
    overflow-y: scroll;
  }
}

.spacer {
  margin-bottom: 900px;
}

</style>


<template lang="pug">

  .form-fields
    .section
      ui-row.row(:columns="36")
        ui-col(:span="18")
          input-field(
            name="service[name]",
            v-model="resource.name",
            :loading="submitting",
            :disabled="fetching",
            :placeholder="$t('.fields.name.placeholder')",
            :errors="errors.name",
            data-test="nameInput"
          )

        ui-col(:span="7")
          template(v-if="edit")
            service-type-label(:loading="fetching", :value="resource.type")

          template(v-else)
            service-type-select-field(
              name="service[type]",
              v-model="resource.type",
              :loading="submitting",
              :disabled="fetching",
              :placeholder="$t('.fields.type.placeholder')",
              :errors="errors.type"
            )

        ui-col(:span="7")
          input-field.sap-input(
            name="service[sap_id]",
            v-model="resource.sapId",
            :errors="errors.sapId",
            mask="6000000000",
            :loading="submitting",
            :disabled="fetching",
            :placeholder="$t('.fields.sapId.placeholder')"
          )

      ui-row.row(:columns="36")
        ui-col(:span="7")
          input-field(
            name="service[targetExecutionTime]",
            v-model.number="resource.targetExecutionTime",
            mask="#",
            :loading="submitting",
            :disabled="fetching",
            :suffix="$t('.fields.targetExecutionTime.suffix')",
            :errors="errors.targetExecutionTime"
          )

        ui-col(:span="12")
          radio-field(
            name="service[kind]",
            :options="kindOptions",
            :disabled="submitting || fetching",
            :value="resource.kind",
            @input="onChangeKind",
            :errors="errors.kind",
            data-testid="kindInput"
          )
            template(#default="{ props: { option, radioClass, checked, radioClick } }")
              input.movida-common__radio-button(
                type="radio",
                name="service[kind]",
                :value="option.value",
                :id="`service[kind]-${option.value}`"
                :disabled="submitting || fetching || option.disabled",
                :class="radioClass",
                :checked="checked",
                @click="radioClick"
              )
              .label-container.flex(
                v-tooltip.bottom="{ content: option.tooltip, offset: '4', classes: 'small-tooltip' }"
              )
                label.radio-label(
                  :for="`service[kind]-${option.value}`",
                  :class="radioClass"
                ) {{ option.label }}
                  i.info-icon.far.fa-info-circle
                  i.radio-icon.fal(v-if="option.icon", :class="option.icon")

        ui-col(:span="11")
          .labor-cost-linked.flex.vertical-center
            toggle-field(
              name="service[laborCostLinked]",
              v-model="resource.laborCostLinked",
              hide-label,
              hide-value-text,
              :loading="submitting",
              :disabled="fetching",
              :errors="errors.laborCostLinked",
              data-testid="laborCostLinkedToggle"
            )
            labor-cost-linked-indicator.indicator

      ui-row.row(:columns="16")
        ui-col(:span="9")
          textarea-field(
            name="service[description]",
            v-model="resource.description",
            :loading="submitting",
            :disabled="fetching",
            :placeholder="$t('.fields.description.placeholder')",
            :errors="errors.description",
            optional
          )

    .section(v-if="resource.isComposite")
      .section-title
        h2.title
          i.title-icon.far.fa-box-alt
          span {{ $t('.sections.servicePackages.title') }}

        h3.subtitle {{ $t('.sections.servicePackages.subtitle') }}

      ui-row.row
        ui-col(:span="12")
          service-package-select-field(
            name="service[servicePackge]",
            hide-label,
            v-model="resource.servicePackageId",
            :placeholder="$t('.fields.servicePackage.placeholder')",
            :loading="submitting",
            :disabled="fetching",
            :errors="errors.servicePackageId"
          )

    .section
      .section-title.flex.vertical-center
        i.title-icon.far.fa-lightbulb
        h2.title {{ $t('.sections.vehicleIssues.title') }}

      .vehicle-issues
        vehicle-issues-list(
          :service-id="serviceId",
          v-model="resource.vehicleIssueIds"
        )

</template>


<script>

// Components
import LaborCostLinkedIndicator from "./components/labor-cost-linked-indicator"
import ServiceTypeLabel from "./components/service-type-label"
import VehicleIssuesList from "./components/vehicle-issues/select-list.vue"

// Models
import models from "@/models"
const { Service } = models

export default {
  components: {
    LaborCostLinkedIndicator,
    ServiceTypeLabel,
    VehicleIssuesList
  },

  props: {
    edit:       { type: Boolean, default: false },
    fetching:   { type: Boolean, default: false },
    submitting: { type: Boolean, default: false },
    errors:     { type: Object, default: () => ({}) },
    resource:   { type: Object, default: () => ({}) }
  },

  data() {
    return {
      i18nScope: "services.form-fields"
    }
  },

  computed: {
    serviceId() {
      return _.get(this.$route.params, "id")
    },

    kindOptions() {
      return Service.$enums.kind.values.map(value => {
        let icon

        switch (value) {
          case "single": icon = "fa-wrench"; break
          case "composite": icon = "fa-layer-plus"; break
          default: icon = null
        }

        return {
          value,
          label:   this.$t(`models.service.enums.kind.${value}`),
          icon,
          tooltip: this.$t(`.fields.kind.${value}.tooltip`)
        }
      })
    }
  },

  methods: {
    onChangeKind(value) {
      // eslint-disable-next-line vue/no-mutating-props
      this.resource.kind = value
      if (value === "composite") return

      // XXX: apagando Pacote de Serviço caso não seja mais "composto"
      // eslint-disable-next-line vue/no-mutating-props
      this.resource.servicePackageId = null
    }
  }
}

</script>

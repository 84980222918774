<style lang="scss" scoped>

.total-price-row {
  display: grid;
  grid-template-columns: 1fr;
  background-color: $light-gray;
  height: 40px;
  border-bottom: 1px solid $light-gray-3;
  border-top: 1px solid $light-gray-3;

  .column {
    font-size: 12px;
    padding: 12px;

    .text {
      font-family: $secondary-font;
      font-size: 14px;
      color: $gray-2;
      font-weight: 500;
    }

    .currency {
      margin-left: 24px;
      font-family: $primary-monospace-font;
      font-weight: 400;
      font-size: 14px;
    }
  }
}

</style>


<template lang="pug">

  .total-price-row
    .column.flex.vertical-center.end
      app-span.text(:value="message")
      app-span.currency(:value="total")

</template>


<script>

export default {
  props: {
    totalPrice: { type: Number, required: true },
    message:    { type: String, required: true }
  },

  data() {
    return {
      i18nScope: "service-orders.show.total-price-row"
    }
  },

  computed: {
    total() {
      return this.$i18n.l("currency", this.totalPrice)
    }
  }
}

</script>

<style scoped lang="scss">

.header {
  margin-bottom: 24px;
}

.title {
  font-size: 20px;
  font-weight: 500;
  font-family: $secondary-font;
}

.actions {
  margin-top: 24px;
  padding-top: 24px;
  border-top: 1px solid $light-gray-3;
}

.back {
  font-family: $secondary-font;
  font-weight: 400;
  font-size: 14px;
  margin-bottom: 16px;

  &::v-deep.link {
    color: $gray-2;

    &:hover {
      color: $gray-3;
    }
  }

  .icon {
    margin-right: 8px;
    font-size: 12px;
  }
}

</style>


<template lang="pug">

  .vehicle-model-group-maintenance-table
    app-button.back(
      theme="link",
      :to="{ name: 'vehicleModelGroupMaintenanceTables' }",
      :disabled="submitting"
    )
      .flex.vertical-center
        i.icon.fas.fa-arrow-left
        span {{ $t('.back.label') }}

    header.header
      h1.title {{ $t('.title') }}

    form.form(@submit.prevent="submit", method="post")

      form-fields.fields(
        :submitting="submitting",
        :errors="errors",
        :resource="resource"
      )

      section.actions.flex.space-between.vertical-center
        app-button(
          :to="{ name: 'vehicleModelGroupMaintenanceTables' }",
          theme="gray",
          outline,
          :disabled="submitting"
        ) {{ $t('btn.cancel') }}

        app-button(
          type="submit",
          :loading="submitting",
          data-testid="submitButton"
        ) {{ $t('.btn.create.label') }}

</template>


<script>

// Mixins
import { mixins } from "movida-common.vue"

// Extends
import View from "@/views/view"

// Local Components
import FormFields from "./form/form-fields.vue"

// Models
import models from "@/models"

const { VehicleModelGroupMaintenanceTable } = models
const { FormMixin } = mixins

export default {
  name: "NewVehicleModelGroupMaintenanceTable",

  components: {
    FormFields
  },

  extends: View,

  mixins: [FormMixin],

  data() {
    return {
      i18nScope: "vehicle-model-groups.maintenance-tables.new",

      resource: new VehicleModelGroupMaintenanceTable(),

      id: this.$route.params.id,

      nestedAttributes: {
        vehicleModelGroupMaintenances: false
      }
    }
  },

  methods: {
    // @override Form mixin
    submitRequest() {
      let params = this.serialize(this.resource, this.nestedAttributes)

      return this.$sdk.vehicleModelGroups.createMaintenanceTable({ id: this.id, params })
    },

    // @override Form mixin
    submitSuccess({ data: { id: maintenanceTableId, name } }) {
      this.$notifications.info(this.$t(".notifications.submit.success", { name }))
      this.$router.push({
        name:   "vehicleModelGroupMaintenanceTable",
        params: {
          id: this.id,
          maintenanceTableId
        }
      })
    },

    // @override Form mixin
    submitError(err) {
      this.$err.log(err)
      this.$notifications.error(this.$t(".notifications.submit.error"))
    }
  }
}

</script>

<style scoped lang="scss">

.copy-icon {
  cursor: pointer;

  &:hover {
    color: $orange;
  }
}

</style>


<template lang="pug">

  i.copy-icon.fal.fa-copy(@click="!noClickCopy && copyToClipboard()")

</template>


<script>

export default {
  name: "CopyIcon",

  props: {
    copyValue:   { type: [Number, String], default: null },
    noClickCopy: { type: Boolean, default: false }
  },

  data() {
    return {
      copyElementTemp: document.createElement("input")
    }
  },

  computed: {
    _copyValue() {
      return this.copyValue
    }
  },

  methods: {
    copyToClipboard() {
      if (this.loading || !this._copyValue) return

      document.body.appendChild(this.copyElementTemp)
      this.copyElementTemp.value = this._copyValue
      this.copyElementTemp.select()

      document.execCommand("copy")
      document.body.removeChild(this.copyElementTemp)

      let notificationText = this.$t(
        "mixins.copyable.notifications.success",
        { value: this._copyValue }
      )

      this.$notifications.info(notificationText)
    }
  }
}

</script>

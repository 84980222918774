import { i18n } from "utils.vue"
import Base from "./base"

class ServiceOrderService extends Base {
  static get attrs() {
    return _.uniq([
      ...super.attrs,
      "description",
      "distanceLimit",
      "timeLimit",
      "type",
      "externalLimit",
      "freeOfCharge",
      "id",
      "sapId",
      "name",
      "price",
      "targetExecutionTime"
    ])
  }

  static get enums() {
    return {
      type: {
        Assistance:       "Assistance",
        AutoBodyAndPaint: "AutoBodyAndPaint",
        Documentation:    "Documentation",
        External:         "External",
        Glass:            "Glass",
        Internal:         "Internal",
        Maintenance:      "Maintenance",
        Mechanic:         "Mechanic",
        Setup:            "Setup",
        TiresAndWheels:   "TiresAndWheels",
        Withdrawal:       "Withdrawal"
      }
    }
  }

  get formattedDistanceLimit() {
    if (_.blank(this.distanceLimit)) return null

    const distance = i18n.l("number", this.distanceLimit, { precision: 0 })

    return i18n.t("models.maintenance.formattedAttributes.distanceLimit", {
      distance
    })
  }

  get formattedTimeLimit() {
    if (_.blank(this.timeLimit)) return null

    return i18n.t("models.maintenance.formattedAttributes.timeLimit", {
      count: this.timeLimit
    })
  }
}

export default ServiceOrderService

import Base from "./Base"

class VehicleModels extends Base {
  create({ versionId, params = {} } = {}) {
    if (_.blank(versionId)) {
      throw new Error('SDK VehicleModels - #create() - "versionId" is a required prop')
    }

    return this.action({
      method: "post",
      url:    `/mobiauto/versions/${versionId}/vehicle_models`,
      params
    })
  }

  list({ params = {} } = {}) {
    return this.action({
      // Estamos usando POST pois o filtro por ids pode ser muito grande e estourar o limite da URL.
      method: "post",
      url:    `/vehicle_models/search`,
      params
    })
  }

  mobiautoList({ params = {} } = {}) {
    return this.action({
      method: "get",
      url:    "/mobiauto/versions",
      params
    })
  }

  mobiautoWithModelList({ params = {} } = {}) {
    return this.action({
      method: "get",
      url:    "/mobiauto/versions_with_model",
      params
    })
  }

  get({ id, params = {} }) {
    if (_.blank(id)) {
      throw new Error('SDK VehicleModels - #get() - "id" is a required prop')
    }

    return this.action({
      method: "get",
      url:    `/vehicle_models/${id}`,
      params
    })
  }

  destroy({ id }) {
    if (_.blank(id)) {
      throw new Error('SDK VehicleModels - #destroy() - "id" is a required prop')
    }

    return this.action({
      method: "delete",
      url:    `/vehicle_models/${id}`
    })
  }

  update({ id, params = {} } = {}) {
    if (_.blank(id)) {
      throw new Error('SDK VehicleModels - #update() - "id" is a required prop')
    }

    return this.action({
      method: "patch",
      url:    `/vehicle_models/${id}`,
      params
    })
  }

  getMaintenanceSupportReplication({ id, params = {} } = {}) {

    if (_.blank(id)) {
      throw new Error('SDK VehicleModels - #getMaintenanceSupportReplication() - "id" is a required prop')
    }

    return this.action({
      method: "get",
      url:    `/vehicle_models/replications/${id}`,
      params
    })
  }

  maintenanceSupportReplicationList({ params = {} } = {}) {
    return this.action({
      method: "get",
      url:    `/vehicle_models/replications`,
      params
    })
  }

  maintenanceSupportReplicationErrorEventList({ id, params = {} } = {}) {
    if (_.blank(id)) {
      throw new Error('SDK VehicleModels - #maintenanceSupportReplicationErrorEventList() - "id" is a required prop')
    }

    return this.action({
      method: "get",
      url:    `vehicle_models/replications/${id}/errors`,
      params
    })
  }

  replicateMaintenanceSupport({ id, params = {} } = {}) {
    if (_.blank(id)) {
      throw new Error('SDK VehicleModels - #replicateMaintenanceSupport() - "id" is a required prop')
    }

    return this.action({
      method: "post",
      url:    `vehicle_models/${id}/replications/`,
      params
    })
  }
}

export default VehicleModels

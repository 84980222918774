import { i18n } from "utils.vue"
import ServiceSupport from "./service-support"

class SupplierServiceSupport extends ServiceSupport {
  static get attrs() {
    return _.uniq([
      ...super.attrs,
      "hourlyCapacity",
      "supplierId",
      "totalCapacity"
    ])
  }

  static get relations() {
    return {
      supplier: { type: "belongsTo", model: "Supplier" }
    }
  }

  static get constraints() {
    return {
      hourlyCapacity: { presence: true, numericality: { greaterThan: 0 } },
      totalCapacity:  {
        presence: true,
        numericality(value, attrs) {
          return {
            greaterThan: _.present(attrs.hourlyCapacity) ? Math.max(0, attrs.hourlyCapacity - 1) : 0,
            message:     i18n.t("models.supplierServiceSupport.errors.totalCapacity.greaterThan")
          }
        }
      }
    }
  }
}

export default SupplierServiceSupport

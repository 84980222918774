// Ability
import { ability } from "@/modules/ability"

// Routes
import NewService from "@/views/services/new.vue"
import Service from "@/views/services/show.vue"
import EditService from "@/views/services/edit.vue"
import Services from "@/views/services/index.vue"

import VehicleIssues from "@/views/services/vehicle-issues/index.vue"

export default [
  {
    path:      "/services/new",
    name:      "newService",
    component: NewService,
    meta:      {
      auth:        true,
      permissions: [() => ability.can("create", "Service")]
    }
  },

  {
    path:      "/services/:id",
    name:      "service",
    component: Service,
    meta:      {
      auth:        true,
      permissions: [() => ability.can("read", "Service")]
    },

    redirect: {
      name: "serviceVehicleIssues"
    },

    children: [
      {
        path:      "",
        name:      "serviceVehicleIssues",
        component: VehicleIssues
      }
    ]
  },

  {
    path:      "/services/:id/edit",
    name:      "editService",
    component: EditService,
    meta:      {
      auth:        true,
      permissions: [() => ability.can("update", "Service")]
    }
  },

  {
    path:      "/services",
    name:      "services",
    component: Services,
    meta:      {
      auth:        true,
      permissions: [() => ability.can("index", "Service")]
    }
  }
]


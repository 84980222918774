<style scoped lang="scss">
.info-label + .info-label {
  margin-left: 40px;
}

.target-icon {
  color: $success;
  font-size: 24px;
}

.type-icon {
  margin-right: 8px;
}

</style>


<template lang="pug">

  .product.flex.vertical-center
    info-label.info-label(
      :loading="loading",
      :value="Product.$tEnum('type', resource.type)"
    )
      template(slot="icon")
        product-type-icon.type-icon(:type="resource.type")

    info-label.info-label(
      :loading="loading",
      :label="$t('.fields.brand.label')",
      :value="resource.$get('brand.name')"
    )

</template>


<script>

// Models
import models from "@/models"
const { Product } = models

export default {
  props: {
    loading:  { type: Boolean, default: false },
    resource: { type: Object, default: () => ({}) }
  },

  data() {
    return {
      i18nScope: "products.show.products.product",

      Product
    }
  }
}

</script>

<style lang="scss" scoped>

.service-option {
  display: grid;
  grid-template-columns: 75px 48px 1fr 48px;
  height: 58px;

  &.active,
  &.focus {
    background-color: $light-gray-2;
  }

  &.focus {
    cursor: pointer;

    .column {
      &.actions-column {
        .remove-icon-content {
          background: $light-gray-3;
          color: $primary;
        }
      }

      .name {
        color: $orange;
      }
    }
  }

  .column {
    padding: 12px;
    overflow: hidden;
    font-weight: 400;

    .name {
      font-family: $secondary-font;
      font-size: 14px;
    }

    .description {
      font-size: 12px;
      color: $gray-3;
    }

    &.actions-column {
      .remove-icon-content {
        display: inline-block;
        width: 24px;
        height: 24px;
        line-height: 24px;
        text-align: center;
        border-radius: 50px;
        color: $gray-2;
        transition: all 100ms linear;
      }
    }
  }
}

</style>


<template lang="pug">

  .service-option(:class="{ focus, active }")
    .column.id-column.flex.vertical-center
      id-tag(:value="service.id")

    .column.service-type-column.flex.vertical-center
      service-type-icon(:type="service.type")

    .column.service-column.flex.column-direction.center
      app-span.name(:value="service.name", crop)
      app-span.description(:value="service.description", crop)

    .column.actions-column.flex.vertical-center
      .remove-icon-content(v-if="active")
        i.fal.fa-times

</template>


<script>

export default {
  name: "ServiceOption",

  props: {
    service: { type: Object, default: () => ({}) },
    active:  { type: Boolean, default: false },
    focus:   { type: Boolean, default: false }
  },

  data() {
    return {
      i18nScope: "components.service.service-option"
    }
  }
}

</script>

import Base from "./base"

class DraftTicketSupplierBooking extends Base {
  static get attrs() {
    return _.uniq([
      ...super.attrs,
      "bookingHistory"
    ])
  }

  static get relations() {
    return {
      supplier: { type: "belongsTo", model: "Supplier" }
    }
  }
}

export default DraftTicketSupplierBooking

<!--
  <doc lang="markdown">
    Componente para números inteiros com tratamento para _paste_

    Como usar:
    ```pug
    integer-field(
      name="service[quantity]",
      v-model="service.quantity",
      :min="-5",
      :max="100"
    )
    ```
  </doc>
-->


<style scoped lang="scss">

$height: 40px;

.integer-field {
  .label-content {
    margin-bottom: 8px;
    font-size: 14px;
    line-height: 17px;
    font-weight: 500;
    font-family: $secondary-font;
    color: $dark-gray;
    transition: color 0.1s cubic-bezier(.645,.045,.355,1);

    > * + * {
      padding-left: 4px;
    }

    &.focus {
      color: $orange;
    }

    &.error {
      color: $red;
    }

    .mandatory-icon {
      font-size: 4px;
      color: $orange;
    }

    .info-icon {
      font-size: 14px;
      cursor: pointer;
    }
  }

  .input-wrapper {
    position: relative;
    font-size: 16px;
    display: inline-block;
    width: 100%;

    .controls {
      position: absolute;
      top: 8px;
      right: 12px;

      &:not(.disabled):not(.loading) {
        &:hover + .input-inner {
          border-color: $orange;
        }

        &:focus + .input-inner {
          outline: none;
          border-color: $orange;
          border-width: 2px;
          padding: 0 calc(var(--controls-width) + var(--prefix-width) + 12px) 0 12px;
        }
      }

      &.disabled {
        cursor: not-allowed;

        .control {
          cursor: not-allowed;
          background-color: $light-gray-4;
        }
      }

      &.loading {
        cursor: progress;

        .control {
          cursor: progress;
          background-color: $light-gray-4;
        }
      }

      .control {
        width: 32px;
        height: 24px;
        background-color: $light-orange;
        border-radius: 4px;
        font-family: $secondary-font;
        font-weight: 500;
        font-size: 14px;
        cursor: pointer;
        user-select: none;

        & + .control {
          margin-left: 8px;
        }
      }
    }

    .input-inner {
      font-family: $primary-font;
      appearance: none;
      background-color: $white;
      background-image: none;
      border-radius: 4px;
      border: 1px solid $gray;
      color: $gray-3;
      display: inline-block;
      font-size: inherit;
      height: $height;
      line-height: $height;
      outline: none;
      padding: 1px calc(var(--controls-width) + var(--prefix-width) + 13px) 1px 13px;
      width: 100%;

      text-align: right;

      &:hover {
        border-color: $orange;
      }

      &:focus {
        outline: none;
        border-color: $orange;
        border-width: 2px;
        padding: 0 calc(var(--controls-width) + var(--prefix-width) + 12px) 0 12px;
      }

      &::placeholder {
        font-weight: 300;
        color: $gray-2;
        opacity: 1;
      }

      &.disabled {
        background-color: $light-gray-3;
        border-color: $light-gray-4;
        color: $gray-2;
        cursor: not-allowed;
      }

      &.loading {
        background-color: $light-gray-3;
        border-color: $light-gray-4;
        color: $gray-3;
        cursor: progress;
      }

      &.error {
        border-color: $red;
      }
    }

    .suffix {
      position: absolute;
      font-size: 14px;
      font-weight: normal;
      color: $gray-3;
      top: 12px;
      right: calc(var(--controls-width) + 12px);
      display: block;
      user-select: none;
      pointer-events: none;
    }

    .icon {
      position: absolute;
      font-size: 16px;
      top: calc(#{$height} / 2);
      transform: translateY(-50%);
      color: $gray-4;

      &.right {
        right: 12px;
      }

      &.left {
        left: 12px;
      }

      &.clickable {
        cursor: pointer;
      }
    }
  }

  .error-message {
    display: block;
    color: $red;
    font-size: 12px;
    line-height: 1;
    padding-top: 4px;

    text-align: right;
  }
}

</style>


<template lang="pug">

  .integer-field
    label.label-content.flex.vertical-center(
      v-if="!hideLabel",
      :class="{ error: hasErrors, focus }",
      :for="inputId"
    )
      span {{ labelText }}
      template(v-if="optional")
        span {{ $t('form.optional') }}

      template(v-else-if="mandatory")
        i.mandatory-icon.fas.fa-circle(v-tooltip.top="$t('form.mandatory.tooltip')")

      template(v-if="tooltip")
        i.info-icon.far.fa-info-circle(v-tooltip="tooltip")

    .input-wrapper(:style="cssVars")
      .suffix(
        v-if="suffix",
        ref="suffix"
      )
        span {{ suffix }}

      .controls.flex(
        v-if="!hideControls",
        tabindex="-1",
        :class="{ loading, disabled }",
        @blur="onBlur",
        @focus="onFocus"
      )
        .control.flex.center.vertical-center(@click="decrease") -
        .control.flex.center.vertical-center(@click="increase") +

      input.input-inner(
        v-model="localValue",
        autocomplete="off",
        ref="input",
        type="text"
        :autofocus="autofocus",
        :class="{ loading, disabled, error: hasErrors }",
        :disabled="disabled || loading",
        :id="inputId",
        :max="max",
        :maxlength="maxLength",
        :min="min",
        :name="name",
        :placeholder="placeholder",
        :readonly="readonly",
        @blur="onBlur",
        @focus="onFocus",
        @keydown="onKeydown",
        @paste="onPaste"
      )

    span.error-message(v-for="error in errors", :key="error") {{ error }}

</template>


<script>

// Extends
import { components } from "movida-common.vue"
const { InputField } = components

export default {
  name: "IntegerField",

  extends: InputField,

  props: {
    value:        { type: [String, Number], default: null },
    min:          { type: Number, default: Number.MIN_SAFE_INTEGER },
    max:          { type: Number, default: Number.MAX_SAFE_INTEGER },
    hideControls: { type: Boolean, default: false },
    mandatory:    { type: Boolean, default: false },
    tooltip:      { type: [String, Object], default: null }
  },

  data() {
    return {
      i18nScope: "components.core.form.integer-field",

      prefixWidth: 0
    }
  },

  computed: {
    localValue: {
      get() { return this.value },

      set(newValue) {
        let value

        if (_.blank(newValue)) value = null
        else if (newValue === "-") value = newValue
        // Força o valor a ficar entre min e max
        else value = Math.min(this.max, Math.max(this.min, newValue))

        this.$emit("input", value)
      }
    },

    valueAsNumber() {
      return Number(this.localValue)
    },

    hasNegativeBoundary() {
      return this.min < 0 || this.max < 0
    },

    maxLength() {
      // Verifica se algum limite é negativo para incluir o caractere "-" na contagem de caracteres do maior limite
      // Ex: min = -100, max = 2, então o número máximo de caracteres é 4
      const characters = String(Math.max(Math.abs(this.min), Math.abs(this.max))).length
      return this.hasNegativeBoundary
        ? characters + 1
        : characters
    },

    previousValue() {
      return _.present(this.localValue)
        ? this.localValue
        : 0
    },

    cssVars() {
      return {
        "--controls-width": this.hideControls ? "0px" : "80px",
        "--prefix-width":   this.prefixWidth ? `${this.prefixWidth + 8}px` : "0px"
      }
    }
  },

  async mounted() {
    await this.$nextTick()
    const ref = this.$refs.suffix

    this.prefixWidth = ref ? ref.clientWidth : 0
  },

  methods: {
    onFocus() {
      if (this.loading || this.disabled) return

      this.focus = true
      this.$emit("focus")
    },

    onBlur() {
      this.focus = false
      this.$emit("blur")
    },

    onPaste(event) {
      // Captura o valor colado, insere na seleção atual e verifica se o valor resultante é um número válido
      event.preventDefault()
      let number = (event.clipboardData || window.clipboardData).getData("text").replace(/[^0-9-]/g, "")
      if (_.blank(number)) return

      const ref = this.$refs.input
      const { selectionStart, selectionEnd } = ref
      const currentValue = String(this.localValue || "")

      const result = `${currentValue.substring(0, selectionStart)}${number}${currentValue.substring(selectionEnd)}`
      if (_.blank(result)) return

      if (Number.isNaN(Number(result)) || result > this.max || result < this.min) return

      this.localValue = Number(result)
    },

    onKeydown(event) {
      // Captura a tecla apertada, verifica se é válida, insere na seleção atual e verifica o resultado
      if (event.altKey || event.ctrlKey || event.metaKey || event.key.length > 1) return

      const ref = this.$refs.input
      const { selectionStart, selectionEnd } = ref
      const currentValue = String(this.previousValue)

      let regex = selectionStart === 0 && this.hasNegativeBoundary
        ? /^[^0-9-]$/
        : /^[^0-9]$/

      if (regex.test(event.key)) return event.preventDefault()

      const result = `${currentValue.substring(0, selectionStart)}${event.key}${currentValue.substring(selectionEnd)}`

      if (result === "-") return
      if (Number.isNaN(Number(result)) || result > this.max || result < this.min) event.preventDefault()
    },

    decrease() {
      if (!this.loading && !this.disabled) this.localValue = this.previousValue - 1
    },

    increase() {
      if (!this.loading && !this.disabled) this.localValue = this.previousValue + 1
    }
  }
}

</script>

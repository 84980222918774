<style lang="scss" scoped>

.services-table-header {
  display: grid;
  grid-template-columns: var(--grid-columns);

  background-color: $light-gray;
  height: 40px;
  border-bottom: 1px solid $light-gray-3;
  border-top: 1px solid $light-gray-3;

  .unsortable {
    padding: 0 12px;
  }

  .column {
    font-size: 12px;

    .icon {
      font-size: 16px;
      color: $gray-2;
    }

    .diamond-icon {
      font-size: 8px;
      font-weight: 900;
      color: $gray-3;
      width: 8px;
      height: 8px;
    }

    .text {
      font-family: $secondary-font;
      font-size: 14px;
      color: $dark-gray;
      font-weight: 400;
    }
  }
}

</style>


<template lang="pug">

  .services-table-header(:style="styles")
    .column.unsortable.flex.vertical-center.center
      i.icon.fas.fa-wrench

    .column.unsortable.flex.vertical-center
      span.text {{ $t('.columns.service.label') }}

    .column.unsortable.flex.vertical-center
      span.text {{ $t('.columns.quantity.label') }}

    .column.unsortable.flex.vertical-center
      span.text {{ $t('.columns.issue.label') }}

    .column.unsortable.flex.vertical-center
      i.icon.fas.fa-paperclip

    .column.flex.unsortable.vertical-center.center
      i.diamond-icon.fas.fa-diamond

</template>


<script>

export default {
  props: {
    columnSizes: { type: String, required: true }
  },

  data() {
    return {
      i18nScope: "tickets.drafts.contents.services.index.services-table-header"
    }
  },

  computed: {
    styles() {
      return {
        "--grid-columns": this.columnSizes
      }
    }
  }
}

</script>

<style lang="scss" scoped>

.supplier-preview-icon {
  .section {
    .icon {
      font-size: 14px;
      margin-right: 4px;

      &.check {
        color: $green;
      }

      &.scheduled {
        color: $yellow;
      }

      &.blocked {
        color: $red;
        cursor: pointer;
      }
    }
  }
}

.popover {
  padding: 16px;
}

</style>


<template lang="pug">

  .supplier-preview-icon
    template(v-if="missing")
      .section(v-tooltip.bottom="$t('.missing.tooltip')")
        i.icon.check.fal.fa-check-circle

    template(v-else)
      .section
        v-popover(
          trigger="hover",
          :delay="{ show: 300, hide: 300 }",
          @apply-show="verifyAndFetch"
        )
          template(v-if="state == 'scheduled'")
            i.icon.scheduled.fal.fa-check-circle

          template(v-else)
            i.icon.blocked.fal.fa-times-circle

          template(slot="popover")
            .popover
              template(v-if="fetching")
                popover-preview-loading(:width="261")

              template(v-else-if="erred")
                popover-reload(@reload="fetch")

              template(v-else)
                supplier-block-preview(:supplier-block="resource")

</template>


<script>

// Mixins
import FetchMixin from "@/mixins/fetch-mixin"

// Components
import PopoverPreviewLoading from "./popover-preview-loading"
import SupplierBlockPreview from "./supplier-block-preview"

export default {
  components: {
    PopoverPreviewLoading,
    SupplierBlockPreview
  },

  mixins: [FetchMixin],

  props: {
    id:    { type: Number, default: null },
    state: { type: String, default: null }
  },

  data() {
    return {
      i18nScope: "suppliers.components.supplier-block-preview-icon",

      autofetch: false,

      resource: {}
    }
  },

  computed: {
    missing() {
      return _.blank(this.id)
    }
  },

  methods: {
    verifyAndFetch() {
      if (_.present(this.resource)) return
      this.fetch()
    },

    fetchRequest() {
      return this.$newSdk.supplierBlocks.find({ supplierBlockId: this.id })
    }
  }
}

</script>

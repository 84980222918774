<style scoped lang="scss">

.store-row {
  display: grid;
  grid-template-columns: 70px 95px 1fr 1fr 64px 64px 64px;

  &.loading {
    .column {
      &.id-column {
        .id-tag {
          color: $gray-2;
        }
      }

      &.actions-column {
        ::v-deep .movida-common__app-button {
          color: $gray-2;

          &:hover {
            color: $orange;
          }
        }
      }

      .text,
      .link ::v-deep .app-span {
        color: $gray-2;
      }
    }
  }

  .column {
    padding: 0 12px;
    border-bottom: 1px solid $light-gray-3;
    height: 48px;
    overflow: hidden;

    .discarded-icon {
      margin-right: 6px;
    }

    .icon {
      margin-left: 4px;
      font-weight: 300;
      color: $dark-gray;
      font-size: 16px;
    }

    &.models-count-column {
      .text {
        font-family: $secondary-font;
      }

      .car-icon {
        margin-right: 8px;
        color: $gray-2;
      }
    }

    ::v-deep .movida-common__app-button {
      white-space: unset;
    }

    .button {
      overflow: hidden;
    }

    .initials {
      font-family: $secondary-font;
      font-weight: 500;
      font-size: 14px;
      color: $gray-3;
    }

    .cnpj {
      font-family: $primary-monospace-font;
      color: $dark-gray;
      font-weight: 400;
    }

    .text,
    .link ::v-deep .app-span {
      font-family: $primary-font;
      font-weight: 400;
      font-size: 14px;
      color: $dark-gray;
      text-align: initial;
    }

    .link ::v-deep .app-span:hover {
      color: $orange;
    }
  }
}

</style>


<template lang="pug">

  .store-row(:class="{ loading }")
    .column.id-column.flex.vertical-center
      id-tag.id-tag(
        :value="store.id",
        data-testid="storeId"
      )

    .column.flex.vertical-center
      app-span.initials(
        :value="store.initials",
        crop,
        data-testid="storeInitials"
      )

    .column.flex.vertical-center
      app-button.button(
        theme="link",
        :to="showRoute"
      )
        app-span.link(
          :value="store.name",
          crop,
          data-testid="storeName"
        )
    .column.flex.vertical-center
      template(v-if="store.cnpj")
        app-span.cnpj(
          :value="store.cnpj",
          crop,
          data-testid="storeCNPJ"
        )
      template(v-else)
        app-span -

    .column.flex.vertical-center
      template(v-if="store.phone")
        .tooltip(v-tooltip.bottom="store.phone")
          i.icon.fal.fa-phone
      template(v-else)
        app-span -

    .column.flex.vertical-center
      template(v-if="store.email")
        .tooltip(v-tooltip.bottom="store.email")
          i.icon.fal.fa-envelope
      template(v-else)
        app-span -

    .column.actions-column.flex.vertical-center.flex
      popover-menu(
        v-if="$can('read', store)",
        :show.sync="showPopover",
        data-testid="popoverMenu"
      )
        popover-menu-button(
          v-if="$can('read', store)",
          :to="{ name: 'store', params: { id: store.id }}",
          icon="fal fa-eye",
          :text="$t('.actions.btn.show')",
          @close-menu="showPopover = false"
        )

</template>


<script>

export default {
  props: {
    store:   { type: Object, default: () => ({}) },
    loading: { type: Boolean, default: false }
  },

  data() {
    return {
      i18nScope: "stores.index.store-row",

      showPopover: false
    }
  },

  computed: {
    showRoute() {
      return {
        name:   "store",
        params: {
          id: this.store.id
        }
      }
    }
  }
}

</script>

<style lang="scss" scoped>

.supplier-info-card {
  padding: 24px;
  background: $light-gray;
  border-radius: 8px;
  width: 802px;

  .icon {
    color: $gray-3;
    font-size: 16px;
    line-height: 16px;
  }

  .diamond-icon {
    --size: 4px;
    font-weight: 900;
    font-size: var(--size);
    color: $gray-3;
    width: var(--size);
    height: var(--size);
    margin: 10px;
  }

  .info-header {
    .best-supplier-badge {
      margin-bottom: 12px;
    }

    .trading-name {
      font-family: $secondary-font;
      font-size: 38px;
      font-weight: 500;
      line-height: 35px;
    }

    .address-and-cnpj {
      margin-top: 8px;

      .city {
        font-size: 16px;
        font-weight: 400;
        line-height: 22px;
      }

      .cnpj {
        font-family: $primary-monospace-font;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 21px;
      }
    }
  }

  .info-body {
    margin-top: 24px;

    .icon-container {
      width: 24px;
      height: 24px;
      margin-right: 8px;
    }

    .title {
      font-size: 16px;
      font-weight: 400;
      line-height: 22px;
    }

    .description {
      font-size: 16px;
      font-weight: 300;
      line-height: 22px;
    }

    .description-label {
      margin-left: 32px;
    }

    .pickup-service,
    .scheduling-notice {
      margin-top: 24px;
    }
  }
}

</style>


<template lang="pug">

  .supplier-info-card
    .info-header
      .best-supplier-badge
        template(v-if="showBadge")
          best-supplier-badge

      app-span.trading-name(:value="supplier.tradingName")
      .address-and-cnpj.flex
        app-span.city(:value="cityAndState")
        i.diamond-icon.fas.fa-diamond
        app-span.cnpj(:value="supplier.cnpj")

    .info-body

      .distance
        .flex.vertical-center
          .icon-container.flex.vertical-center.center
            i.icon.fas.fa-map-marker-alt
          app-span.title(:value="$t('.fields.distanceToStore', { count: distanceToStore })")

        .description-label.flex.vertical-center
          .flex
            supplier-hierarchy-icon(:value="hierarchy")
            app-span.description(:value="$t(`models.supplier.enums.hierarchy.${hierarchy}`)")

          i.diamond-icon.fas.fa-diamond
          app-span.description(:value="fullAddress")

      .pickup-service
        .flex.vertical-center
          .icon-container.flex.vertical-center.center
            i.icon.fa-people-arrows(:class="pickupService ? 'fas' : 'fal'")
          check-item.title(
            :label="$t('models.supplier.attributes.pickupService')",
            :checked="!pickupService",
            :tooltip="$t('.fields.pickupService.tooltip')"
          )

        template(v-if="pickupService")
          .description-label
            app-span.description(:value="$t('.fields.pickupService.description')")      .pickup-service
      
        .flex.vertical-center
          .icon-container.flex.vertical-center.center
            i.icon.fa-people-arrows(:class="rabbotPlataform ? 'fas' : 'fal'")
          check-item.title(
            :label="$t('models.supplier.attributes.rabbotPlataform')",
            :checked="!rabbotPlataform",
            :tooltip="$t('.fields.rabbotPlataform.tooltip')"
          )

        template(v-if="rabbotPlataform")
          .description-label
            app-span.description(:value="$t('.fields.rabbotPlataform.description')")

      .scheduling-notice
        .flex.vertical-center
          .icon-container.flex.vertical-center.center
            i.icon.fa-calendar-exclamation(:class="schedulingNotice ? 'fas' : 'fal'")
          check-item.title(
            :label="$t('models.supplier.attributes.schedulingNotice')",
            :checked="!schedulingNotice",
            :tooltip="$t('.fields.schedulingNotice.tooltip')"
          )

        template(v-if="schedulingNotice")
          .description-label
            app-span.description(:value="$t('.fields.schedulingNotice.description', { count: schedulingNotice })")  .scheduling-notice

</template>


<script>

// Components
import BestSupplierBadge from "../edit/best-supplier-badge.vue"

export default {
  name: "SupplierInfoCard",

  components: {
    BestSupplierBadge
  },

  props: {
    serviceOrderSupplier: { type: Object, required: true },
    showBadge:            { type: Boolean, default: false }
  },

  data() {
    return {
      i18nScope: "tickets.drafts.bookings.components.supplier-info-card"
    }
  },

  computed: {
    supplier() {
      return _.get(this.serviceOrderSupplier, "supplier")
    },

    pickupService() {
      return _.get(this.supplier, "pickupService")
    },
    
    rabbotPlataform() {
      return _.get(this.supplier, "rabbotPlataform")
    },

    address() {
      return _.get(this.supplier, "address")
    },

    schedulingNotice() {
      return _.get(this.supplier, "schedulingNotice")
    },

    fullAddress() {
      return _.get(this.address, "addressLine1")
    },

    city() {
      return _.get(this.address, "city")
    },

    hierarchy() {
      return _.get(this.supplier, "hierarchy")
    },

    distanceToStore() {
      return _.get(this.serviceOrderSupplier, "distance")
    },

    cityAndState() {
      return `${this.city.name} - ${this.city.stateCode}, Brasil`
    }
  }
}

</script>

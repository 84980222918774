<style lang="scss" scoped>

.attachment-thumbnail {
  background-color: $light-gray-4;
  width: 72px;
  height: 72px;
  border-radius: 8px;
  cursor: pointer;
  overflow: hidden;

  &.selected {
    border: 2px solid $orange;
  }

  .spin {
    animation: fa-spin 2s infinite linear;
  }

  .image-preview {
    width: 72px;
    height: 72px;
    object-fit: cover;
  }

  .file-icon {
    color: var(--icon-color)
  }
}

</style>


<template lang="pug">

  .attachment-thumbnail.flex.center.vertical-center(:class="{ selected }")
    template(v-if="fetching")
      i.icon.fas.fa-rotate-right.spin

    template(v-else-if="erred")
      i.icon.fas.fa-rotate-right(@click.stop="fetch")

    template(v-else-if="hasPreview")
      img.image-preview(v-show="showPreview", :src="previewURL")

    template(v-else)
      i.file-icon.fas(:class="{ [attachment.icon]: true }", :style="cssVars")

</template>


<script>

// Mixins
import FetchMixin from "@/mixins/fetch-mixin"

export default {
  name: "AttachmentThumbnail",

  mixins: [FetchMixin],

  props: {
    attachment: { type: Object, required: true },
    selected:   { type: Boolean, default: false }
  },

  data() {
    return {
      i18nScope: "components.attachments.attachment-thumbnail",

      autofetch: false,

      previewURL:  null,
      showPreview: false
    }
  },

  computed: {
    hasPreview() {
      return this.attachment.fileType === "image"
    },

    cssVars() {
      return {
        "--icon-color": this.attachment.iconColor
      }
    }
  },

  created() {
    if (this.hasPreview) this.fetch()
  },

  methods: {
    // @override Fetch mixin
    fetchRequest() {
      return this.$newSdk.blobs.getDownloadURL({ blobId: this.attachment.blobId })
    },

    // @override Fetch mixin
    onFetchSuccess({ data }) {
      this.previewURL = data.url

      setTimeout(() => {
        this.showPreview = true
      }, 100)
    }
  }
}

</script>

// Base
import BaseRequests from "../Base"

// Models
import models from "@/models"
const { SignedFile } = models

class BlobsRequests extends BaseRequests {
  static get selector() {
    return "quotes.blobs"
  }

  create({ params = {}, options = {} } = {}) {
    return this.action({
      method:  "post",
      url:     `/monitoring/blobs`,
      params,
      options: {
        raw:   true,
        model: SignedFile,

        ...options
      }
    })
  }

  getDownloadURL({ quoteId, blobId, params = {}, options = {} } = {}) {
    this.verifyData("getDownloadURL", { quoteId, blobId })

    return this.action({
      method: "get",
      url:    `/quotes/${quoteId}/blobs/${blobId}/download_url`,
      params,
      options
    })
  }
}

export default BlobsRequests

<style lang="scss" scoped>

.modal-body {
  .header {
    border-bottom: 1px solid $light-gray-3;
    display: grid;
    font-family: $secondary-font;
    gap: 8px 24px;
    padding: 16px 24px;
    grid-template-columns: repeat(2, 1fr);

    .info {
      align-items: baseline;
      display: flex;
      font-size: 14px;

      .icon {
        color: $gray-3;
        margin-right: 8px;
      }

      .text {
        color: $dark-gray;
        font-weight: 400;
        line-height: 1.5;
      }

      .number {
        font-family: $primary-monospace-font;
      }

      .separator {
        align-self: center;
        color: $gray;
        font-size: 4px;
        margin: 4px;
      }

      .license-plate {
        align-self: center;
      }
    }
  }

  .content {
    flex-grow: 1;
    overflow-y: auto;
    padding: 16px 24px;

    .messages {
      list-style: none;
      margin: 0;
      padding: 0;

      .terminator {
        .icon-wrapper {
          align-items: center;
          display: inline-flex;
          justify-content: center;
          height: 24px;
          width: 24px;

          .icon {
            color: $gray;
            font-size: 12px;
          }
        }

        .label {
          color: $gray-3;
          font-size: 14px;
          margin-left: 16px;
        }
      }

      &.loading {
        > .row {
          display: flex;
          gap: 32px;
          padding-left: 56px;

          .column {
            &:first-child {
              width: 74px;
            }

            &:last-child {
              flex-grow: 1;
            }
          }

          &:not(:last-child) {
            margin-bottom: 60px;
          }
        }
      }
    }

    .empty {
      font-style: italic;
      padding: 32px;
      text-align: center;
    }
  }
}


</style>

<template lang="pug">
  app-modal.ticket-messages-modal(
    :heading="$t('.heading')"
    @close="$emit('close')"
  )
    .modal-body
      .header
        .info
          i.icon.fas.fa-file-invoice-dollar
          span.text.number \#{{ serviceOrder.remoteId }}
          i.separator.fas.fa-diamond
          state-tag(
            icon-theme="solid"
            kind="service"
            :value="serviceOrder.state"
            size="small"
          )

        .info
          i.icon.fas.fa-car
          license-plate.license-plate(:value="licensePlate", size="small")
          i.separator.fas.fa-diamond
          span.text {{ modelName }}

        .info
          i.icon.fas.fa-user-hard-hat
          span.text {{ supplierName }}

        .info
          i.icon.fas.fa-user
          business-unit-tag(:value="serviceOrder.businessUnit")
          template(v-if="customerName")
            i.separator.fas.fa-diamond
            span.text {{ customerName }}

      .content
        template(v-if="!loading")
          ul.messages(v-if="!isEmpty")
            li.terminator
              .icon-wrapper
                i.icon.fas.fa-grip-lines
              span.label {{ $t(".terminators.recent") }}

            li.message-item(v-for="ticketMessage in ticketMessages", :key="ticketMessage.id")
              ticket-message(:ticket-message="ticketMessage")

            li.terminator
              .icon-wrapper
                i.icon.fal.fa-arrow-up-long
              span.label {{ $t(".terminators.beginning") }}
          .empty(v-else)
            span.error(v-if="failed") {{ $t(".empty.error") }}
            span.empty-message(v-else) {{ $t(".empty.message") }}

        template(v-else)
          ul.messages.loading
            li.row(v-for="i in 3", :key="i")
              .column
                loading-lines(:lines="3", :min="50", :max="100", :height="21")
              .column
                loading-lines(:height="21", :min="50", :max="100", :style="{ width: '300px', marginBottom: '8px' }")
                loading-lines(:height="54", :min="100", :max="100")
</template>

<script>
import models from "@/models"
import StateTag from "@/views/monitoring/service-orders/_components/state-tag.vue"
import TicketMessage from "./ticket-message.vue"

const { MovidaServiceOrder } = models

export default {
  components: {
    StateTag,
    TicketMessage
  },

  props: {
    serviceOrder: { type: MovidaServiceOrder, required: true }
  },

  emits: ["close"],

  data() {
    return {
      i18nScope: "monitoring.ticket-messages-modal",

      failed:  false,
      loading: false,

      ticketMessages: []
    }
  },

  computed: {
    customerName() {
      const { customer } = this.serviceOrder

      if (!customer) return ""

      return customer.tradingName || customer.name
    },

    isEmpty() {
      return this.ticketMessages.length == 0
    },

    licensePlate() {
      const { vehicle } = this.serviceOrder

      if (!vehicle) return ""

      return vehicle.licensePlate
    },

    modelName() {
      const { vehicle } = this.serviceOrder

      if (!vehicle) return ""

      return vehicle.fullModelName
    },

    sdk() {
      return this.$newSdk.monitoring.serviceOrders.ticketMessages
    },

    supplierName() {
      const { supplier } = this.serviceOrder

      if (!supplier) return ""

      return supplier.tradingName
    }
  },

  mounted() {
    this.fetch()
  },

  methods: {
    async fetch() {
      try {
        this.loading = true

        const result = await this.sdk.all({ serviceOrderId: this.serviceOrder.id })

        this.ticketMessages = result.data
      }
      catch (error) {
        this.failed = true
        this.$err.log(error)
        this.$notifications.error(this.$t(".notifications.fetch.error"))
      }
      finally {
        this.loading = false
      }
    }
  }
}
</script>

<!--
# DaterangeField

Campo de formulário para selecionar período de datas.

## Props
- label?: {string}
- tooltip?: {string | object}
- value: {[string, string]} Data início e fim em formato ISO-8601

Demais props são encaminhadas para o input principal do componente, permitindo
usar atributos como `placeholder` e `disabled`.

## Uso
```pug
daterange-field(
  :disabled="loading"
  label="Período"
  placeholder="Data"
  :value="dateRange"
  @input="handleDateRangeInput"
)
```
-->

<style lang="scss" scoped>
.label {
  color: $dark-gray;
  display: block;
  font-family: $secondary-font;
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  margin-bottom: 8px;

  .daterange-field:focus-within & {
    color: $primary;
  }

  .has-error & {
    color: $error;
  }
}

.info-icon {
  cursor: help;
  font-size: 14px;
  margin-left: 4px;
}

.error-message {
  color: $error;
  font-size: 12px;
  line-height: 1;
  margin-top: 4px;
}
</style>

<template lang="pug">
  .daterange-field(:class="{ 'has-error': hasErrors }")
    label.label(:for="fieldId") {{ label }}
      i(v-if="hasTooltip" v-tooltip="tooltip").info-icon.far.fa-info-circle
    app-daterange-picker(
      v-bind="$attrs"
      v-on="$listeners"
      :id="fieldId"
      :error="hasErrors"
    )
    .error-message(v-for="error in errors" :key="error") {{ error }}
</template>

<script>
export default {
  name: "DaterangeField",

  inheritAttrs: false,

  props: {
    errors:  { type: Array, default: null },
    label:   { type: String, default: "" },
    tooltip: { type: [String, Object], default: null }
  },

  data() {
    return {
      fieldId: _.uniqueId("daterange-field-")
    }
  },
  computed: {
    hasErrors() {
      return _.present(this.errors)
    },
    hasTooltip() {
      return _.present(this.tooltip)
    }
  }
}
</script>

<style lang="scss" scoped>

.quote-provided-product-row {
  grid-template-columns: minmax(296px, 1fr) 40px 40px 50px 40px;
  gap: 8px;
  min-height: 40px;

  &.discarded {
    background-color: $light-gray-2;

    .name {
      color: $gray-2;
      text-decoration-line: line-through;
    }
  }

  .column {
    padding: 0 8px;
    height: 40px;
  }

  .name {
    font-family: $secondary-font;
    font-weight: 400;
    font-size: 14px;
  }

  .indicator.submitting {
    cursor: not-allowed;

    ::v-deep {
      .icon {
        color: $gray-3;
      }

      .data-indicator {
        background-color: $light-gray-4;
      }
    }
  }

  .value {
    font-family: $primary-monospace-font;
    font-weight: 400;
    font-size: 14px;
  }

  .action {
    height: 40px;

    &.submitting {
      .action-button {
        color: $gray-3;
        cursor: not-allowed;

        &.icon-container:hover {
          color: $gray-3;
          background-color: unset;
          cursor: not-allowed;
        }
      }
    }

    .action-button {
      height: 100%;
      width: 100%;

      &.icon-container:hover {
        width: 100%;
        background-color: $light-gray-2;
        border-radius: 50%;
        color: $orange;
        cursor: pointer;
      }
    }
  }
}

</style>


<template lang="pug">

  .quote-provided-product-row.grid.vertical-start(:class="{ discarded }")
    .column.flex.vertical-center
      span.name {{ quoteProduct.product.name }}

    .column.center.flex.vertical-center
      template(v-if="hasData")
        extra-data-indicator.indicator(
          :count="quoteProduct.attachments.length",
          :class="{ submitting }",
          @click.native="showItemModal"
        )

      template(v-else)
        span -

    .field-column
      warranty-checkbox-field(
        v-model="quoteProduct.warranty",
        hide-value-text,
        name="quoteProduct[warranty]",
        :disabled="discarded",
        :errors="errors.warranty",
        :loading="submitting"
      )

    .field-column
      integer-field(
        v-model="quoteProduct.quantity",
        hide-label,
        hide-controls,
        name="quoteProduct[quantity]",
        :disabled="discarded",
        :errors="errors.quantity",
        :loading="submitting",
        :max="99",
        :min="1"
      )

    .action.flex.center.vertical-center(:class="{ submitting }")
      template(v-if="discarded")
        app-button.action-button(theme="link", @click="restore")
          i.icon.far.fa-arrow-rotate-right

      template(v-else)
        .icon-container.action-button.flex.center.vertical-center(@click="discard")
          i.icon.far.fa-times

    item-show(
      v-if="showItem",
      :item="quoteProduct",
      :name="quoteProduct.product.name",
      @close="showItem = false"
    )

</template>


<script>

// Components
import ItemShow from "./item-show.vue"
import WarrantyCheckboxField from "@/views/monitoring/quotes/_components/warranty-checkbox-field.vue"


export default {
  components: {
    ItemShow,
    WarrantyCheckboxField
  },

  props: {
    quoteProduct: { type: Object, required: true },
    submitting:   { type: Boolean, default: false },
    errors:       { type: Object, default: () => ({}) }
  },

  data() {
    return {
      i18nScope: "monitoring.quotes.reviews.quote-provided-product-row",

      showItem: false
    }
  },

  computed: {
    discarded() {
      return _.present(this.quoteProduct?.discardedAt)
    },

    hasData() {
      return _.present(this.quoteProduct?.description) || _.present(this.quoteProduct?.attachments)
    }
  },

  methods: {
    showItemModal() {
      if (this.submitting) return

      this.showItem = true
    },

    discard() {
      if (this.submitting) return
      // eslint-disable-next-line vue/no-mutating-props
      this.quoteProduct.discardedAt = moment().toISOString()
    },

    restore() {
      if (this.submitting) return
      // eslint-disable-next-line vue/no-mutating-props
      this.quoteProduct.discardedAt = null
    }
  }
}

</script>

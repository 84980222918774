<style lang="scss" scoped>

.index {
  .clear-filters {
    position: relative;

    .filters-button {
      position: absolute;
      margin-top: 10px;
    }
  }

  .list {
    margin-top: 50px;

    .table {
      margin-top: 16px;
    }
  }

  .paginator-content {
    padding-top: 24px;

    &.loading {
      max-width: 400px;
      margin: 0 auto;
    }
  }
}

</style>


<template lang="pug">

  .index
    service-support-info(:supplier-id="supplierId", :service-type='serviceType')

    .actions-bar
      ui-row(vertical-align="center")
        ui-col(:span="8")
          search-form.search(
            :loading="fetching",
            :params="parsedQueryParams",
            @search="search"
          )

        ui-col.flex.end.vertical-start(:span="2")
          app-button(
            v-if="$can('update', 'Supplier')",
            :to="batchDestroyRoute",
            theme="link"
          ) {{ $t('.btn.destroy.label') }}

        ui-col.flex.end.vertical-start(:span="2")
          app-button(
            v-if="$can('update', 'Supplier')",
            :to="batchAddRoute",
            theme="link"
          ) {{ $t('.btn.add.label') }}

    .clear-filters
      filters-button.filters-button(
        v-if="hasFilters",
        @clear="clearFilters"
      )

    .list
      .flex.end.vertical-bottom
        results-indicator(
          :page="parsedQueryParams.page",
          :per-page="pagination.perPage",
          :total="pagination.total",
          :loading="fetching"
        )

      service-vehicle-model-groups-table.table(
        :error="erred",
        :empty="empty",
        :initializing="initializing",
        :loading="fetching",
        :sorted-by="parsedQueryParams.sort",
        :column-sizes="columnSizes"
        @sort="onSortChange",
        @changed="fetch"
      )
        template(#rows)
          service-vehicle-model-groups-table-row(
            v-for="supplierServiceVehicleModelGroup in supplierServiceVehicleModelGroups",
            :supplier-service-vehicle-model-group="supplierServiceVehicleModelGroup",
            :key="supplierServiceVehicleModelGroup.id",
            :loading="fetching",
            :column-sizes="columnSizes",
            @destroy="fetch",
            data-testid="supplierServiceVehicleModelGroupRow"
          )

    .paginator-content(v-if="fetching || showPaginator", :class="{ loading: fetching }")
      paginator(
        :loading="fetching",
        :per-page="pagination.perPage",
        :total="pagination.total",
        :value="parsedQueryParams.page",
        :disabled="fetching",
        @input="onPageChange",
        data-testid="paginator"
      )

    router-view(
      @change="fetch",
      :back-route-query="currentRouteQuery"
    )

</template>


<script>

// Models
import models from "@/models"

// View
import IndexView from "@/views/index-view"

// Mixins
import FetchMixin from "@/mixins/fetch-mixin"

// Components
import ServiceSupportInfo from "@/views/suppliers/service-supports/components/service-support-info"
import SearchForm from "@/views/suppliers/service-supports/components/search-form"
import ServiceVehicleModelGroupsTable from "@/views/suppliers/service-supports/components/service-vehicle-model-groups/table"
import ServiceVehicleModelGroupsTableRow from "@/views/suppliers/service-supports/components/service-vehicle-model-groups/table-row"

const { Service } = models

export default {
  name: "SupplierServiceSupportServiceVehicleModelGroups",

  components: {
    ServiceSupportInfo,
    SearchForm,
    ServiceVehicleModelGroupsTable,
    ServiceVehicleModelGroupsTableRow
  },

  extends: IndexView,

  mixins: [FetchMixin],

  data() {
    return {
      i18nScope: "suppliers.service-supports.service-vehicle-model-groups.index",
      routeName: "supplierServiceSupportServiceVehicleModelGroups",

      supplierId: this.$route.params.id,

      resource: [],

      // Query Mixin data
      queryParamsStructure: {
        q:                "string",
        vehicle_model_id: "string",
        page:             "integer",
        sort:             "string"
      }
    }
  },

  computed: {
    supplierServiceVehicleModelGroups: {
      get() { return this.resource },
      set(value) { this.resource = value }
    },

    serviceTypeSlug() {
      return this.$route.params.type
    },

    serviceType() {
      return Service.parseParamType(this.serviceTypeSlug)
    },

    empty() {
      return _.blank(this.supplierServiceVehicleModelGroups)
    },

    columnSizes() {
      return "70px 1fr 1fr 64px 64px 64px"
    },

    allowedRowComponents() {
      return {
        mechanic: "MechanicVehicleModelGroupsTableRow"
      }
    },

    rowComponent() {
      const rowComponent = this.allowedRowComponents[this.serviceTypeSlug]

      if (_.blank(rowComponent)) return null

      return rowComponent
    },

    batchDestroyRoute() {
      return {
        name:   "destroySupplierServiceSupportServiceVehicleModelGroups",
        params: { id: this.supplierId }
      }
    },

    batchAddRoute() {
      return {
        name:   "newSupplierServiceSupportServiceVehicleModelGroups",
        params: { id: this.supplierId }
      }
    }
  },

  methods: {
    parseRoute() {
      this.supplierId = this.$route.params.id
    },

    // @override Fetch mixin
    fetchRequest() {
      return this.$newSdk.suppliers.serviceVehicleModelGroups.all({
        supplierId: this.supplierId,
        params:     {
          service_type: this.serviceType,
          ...this.requestQueryParams
        }
      })
    }
  }
}

</script>

<style lang="scss" scoped>

.popover {
  .popover-title {
    &.active {
      cursor: pointer;
    }

    .icon {
      color: $gray-2;
      margin-right: 4px;
    }
  }

  .vehicle-issues-preview {
    min-width: 128px;
    max-width: 450px;
    max-height: 450px;
    padding: 16px;
    overflow-y: scroll;

    .title {
      font-family: $secondary-font;
      font-weight: 400;
      margin-top: 8px;

      .icon {
        margin-right: 8px;
      }

      .id-tag {
        margin-left: 4px;
      }
    }

    .symptom-row {
      margin-top: 4px;
      margin-left: 24px;

      .icon {
        margin-right: 8px;
        color: $gray-2;
      }

      .text {
        font-family: $secondary-font;
        font-weight: 400;
      }
    }
  }
}

</style>


<template lang="pug">

  .popover
    v-popover(
      trigger="hover",
      :delay="{ show: 300, hide: 300 }",
      :auto-hide="false",
      @apply-show="verifyAndFetch"
    )
      slot
        .popover-title(:class="{ active: hasIssueSolutions }")
          i.icon.fal.fa-lightbulb
          app-span.maintenance-package(
            :value="vehicleIssueSolutionsCount",
            data-testid="vehicleIssueSolutionsCount"
          )

        template(v-if="hasIssueSolutions", slot="popover")
          .vehicle-issues-preview(data-testid="vehicleIssuesPreview")
            template(v-if="fetching")
              loading-lines.loading(:min="100", :max="100", :lines="4")

            template(v-else-if="erred")
              popover-reload.reload(@reload="fetch")

            template(v-else)
              template(v-for="vehicleIssue in vehicleIssues")
                .title.flex.vertical-center
                  img.icon(:src="vehiclePartIcon")
                  app-span(
                    :value="vehicleIssue.vehiclePart.name",
                    crop,
                    data-testid="vehiclePartName"
                  )

                  id-tag.id-tag(
                    :value="vehicleIssue.vehiclePart.id",
                    data-testid="vehiclePartId"
                  )

                .symptom-row.flex.vertical-center(data-testid="product")
                  i.icon.fal.fa-engine-warning
                  app-span.text(:value="vehicleIssue.symptom.name")

</template>


<script>

// Mixins
import FetchMixin from "@/mixins/fetch-mixin"

import vehiclePartIcon from "@/assets/images/icons/sidebar/vehicle-part.svg"

export default {
  mixins: [FetchMixin],

  props: {
    serviceId:                  { type: [String, Number], required: true },
    vehicleIssueSolutionsCount: { type: [String, Number], required: true }
  },

  data() {
    return {
      i18nScope: "services.components.vehicle-issues-preview",
      resource:  [],
      autofetch: false,
      vehiclePartIcon
    }
  },

  computed: {
    vehicleIssues: {
      get() { return this.resource },

      set(value) { this.resource = value }
    },

    hasIssueSolutions() {
      return this.vehicleIssueSolutionsCount > 0
    }
  },

  methods: {
    verifyAndFetch() {
      if (_.present(this.vehicleIssues)) return

      this.fetch()
    },

    fetchRequest() {
      return this.$newSdk.services.vehicleIssues.all({ serviceId: this.serviceId })
    }
  }
}

</script>

<style lang="scss" scoped>

.service-orders-table-header {
  display: grid;
  grid-template-columns: 130px 40px 129px 99px 1fr 93px 124px 100px 64px;
  background-color: $light-gray;
  height: 40px;
  border-bottom: 1px solid $light-gray-3;
  border-top: 1px solid $light-gray-3;

  &.loading {
    border-bottom: 2px solid $light-orange-2;
  }

  .unsortable {
    padding: 0 12px;
  }

  .column {
    font-size: 12px;

    &.state-column {
      .icon {
        font-size: 16px;
        color: $gray-2;
      }
    }

    .text {
      font-family: $secondary-font;
      font-size: 14px;
      color: $dark-gray;
      font-weight: 400;
    }

    .loader-icon {
      font-size: 16px;
      color: $orange;
    }
  }
}

</style>


<template lang="pug">

  .service-orders-table-header(:class="{ loading }")
    .column.id-column(data-testid="idColumn")
      sortable-table-header-column(
        sort-param="id",
        :sort-order="sortOrder",
        :active="isSortedBy('id')",
        @sort="param => $emit('sort', param)"
      )
        span.text {{ $t('.columns.id.label') }}

    .column.state-column.unsortable.flex.vertical-center.center(data-testid="stateColumn")
      i.icon.fas.fa-file-invoice-dollar(v-tooltip.bottom="{ offset: 4, content: $t('.columns.state.tooltip') }")

    .column.type-column.unsortable.flex.vertical-center(data-testid="typeColumn")
      span.text {{ $t('.columns.type.label') }}

    .column.vetor-column.unsortable.flex.vertical-center(data-testid="licensePlateColumn")
      span.text {{ $t('.columns.licensePlate.label') }}

    .column.supplier-column.unsortable.flex.vertical-center(data-testid="supplierColumn")
      span.text {{ $t('.columns.supplier.label') }}

    .column.supplier-column.unsortable.flex.vertical-center(data-testid="storeColumn")
      span.text {{ $t('.columns.store.label') }}

    .column.total-column(data-testid="totalColumn")
      sortable-table-header-column(
        sort-param="total",
        :sort-order="sortOrder",
        :active="isSortedBy('total')",
        @sort="param => $emit('sort', param)"
      )
        span.text {{ $t('.columns.total.label') }}

    .column.created-at-column(data-testid="createdAtColumn")
      sortable-table-header-column(
        sort-param="created_at",
        :sort-order="sortOrder",
        :active="isSortedBy('created_at')",
        @sort="param => $emit('sort', param)"
      )
        span.text {{ $t('.columns.createdAt.label') }}

    .column.flex.unsortable.vertical-center.center
      template(v-if="loading")
        i.loader-icon.fas.fa-spin.fa-spinner

</template>


<script>

export default {
  props: {
    loading:   { type: Boolean, default: false },
    sortParam: { type: String, required: true },
    sortOrder: { type: String, required: true }
  },

  data() {
    return {
      i18nScope: "service-orders.index.service-orders-table-header"
    }
  },

  methods: {
    isSortedBy(param) {
      return this.sortParam === param
    }
  }
}

</script>

<style lang="scss" scoped>

.container {
  border: 1px solid $light-gray-3;
  border-left-width: 6px;
  border-radius: 4px;

  .details-content {
    padding: 16px;
  }

  &.ongoing {
    border-color: $green;
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.2);
  }

  &.canceled {
    border-left-color: $light-gray-4;
  }

  &.done,
  &.amended {
    border-left-color: $gray-2;
  }

  &.draft {
    border-left-color: $dark-yellow;
  }
}

.deal-ongoing-identification {
  padding: 8px;
  border-bottom: 1px solid $light-gray-2;
}

.info + .info {
  margin-left: 24px;
}

.deal-state:not(.ongoing-state) {
  margin-bottom: 4px;
}

.ongoing-state {
  margin-right: 16px;
}

.block {
  display: block;
}

.inheritance-content {
  background: #FFF6D9;
  padding: 10px 16px;

  .fa-link {
    margin-right: 8px;
  }

  .follow {
    font-size: 16px;
    font-family: $secondary-font;
    font-weight: 500;
  }
}

.actions-column {
  margin-right: 8px;
}

</style>


<template lang="pug">

  .deal-info-card
    loading-lines(v-if="loading", :height="111", :min="100", :max="100")
    template(v-else)
      .container(:class="{ [deal.state]: true }")
        .inheritance-content(v-if="false")
          i.far.fa-link
          span {{ $t('.follow') }}

        .flex.vertical-center.space-between(:class="{ block: deal.isOngoing }")
          .deal-ongoing-identification.flex.vertical-center.space-between.info(v-if="deal.isOngoing")
            .content.flex.vertical-center
              deal-state.ongoing-state(:value="deal.state")
              deal-info-title(:deal="deal", :supplier-id="supplierId")

            actions(
              :supplier-id="supplierId",
              :deal="deal",
              @destroy="$emit('destroy')"
            )

          .details-content.flex.vertical-center
            .deal-identification.info(v-if="!deal.isOngoing")
              deal-state.deal-state(:value="deal.state")
              deal-info-title(:deal="deal", :supplier-id="supplierId")

            deal-info-details.info(:deal="deal")

          .actions-column(v-if="!deal.isOngoing")
            actions(
              :supplier-id="supplierId",
              :deal="deal",
              @destroy="$emit('destroy')"
            )

</template>


<script>

// Local components
import DealState from "./deal-state"
import DestroyAction from "./destroy-action"
import Actions from "./deal-info-card/actions"
import DealInfoDetails from "./deal-info-card/details"
import DealInfoTitle from "./deal-info-card/title"

export default {
  components: {
    DealState,
    DealInfoTitle,
    Actions,
    DealInfoDetails,
    DestroyAction
  },

  props: {
    supplierId: { type: [String, Number], required: true },
    deal:       { type: Object, default: () => ({}) },
    loading:    { type: Boolean, default: false }
  },

  data() {
    return {
      i18nScope: "suppliers.deals.components.deal-info-card",

      showPopover: false
    }
  }
}

</script>

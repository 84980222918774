<style lang="scss" scoped>

.popover-reload {
  font-size: 14px;

  .icon {
    color: $dark-warning;
  }

  * + * {
    margin-top: 4px;
  }
}

</style>


<template lang="pug">

  .popover-reload.flex.column-direction.center
    i.icon.fas.fa-exclamation-triangle
    span {{ $t('.message') }}
    app-button(theme="link", @click="$emit('reload')") {{ $t('.reload.label') }}

</template>


<script>

export default {
  name: "PopoverReload",

  data() {
    return {
      i18nScope: "components.popover-reload"
    }
  }
}

</script>

<style lang="scss" scoped>

.index {
  .search-form {
    margin-bottom: 24px;

    .starting-at-field {
      margin-right: 24px;
    }
  }

  .empty {
    padding: 24px 0;
    background-color: $light-gray;
    border-radius: 8px;

    .not-found-icon {
      margin-bottom: 8px;
    }

    .message {
      font-weight: 400;
      font-size: 14px;
      font-style: italic;
      color: $gray-3;
    }
  }

  .suppliers-rows{
    border: 1px solid $light-gray-3;
    border-radius: 8px;

    .supplier-row + .supplier-row {
      border-top: 1px solid $light-gray-3;
    }
  }
}

</style>


<template lang="pug">

  .index
    .search-form
      form.form(
        @submit.prevent="fetch",
        method="get",
        data-testid="form"
      )
        ui-row.row(vertical-align="bottom")
          ui-col(:span="3")
            datetime-field.starting-at-field(
              name="startingAt",
              :label="$t('.search-form.fields.startingAt.label')",
              :placeholder="$t('.search-form.fields.startingAt.placeholder')",
              :loading="fetching",
              :disabled="submitting",
              future-only,
              v-model="startingAt"
            )

          ui-col(:span="1")
            app-button(
              type="submit",
              :loading="fetching",
              :disabled="submitting",
              full-width
            )
              span {{ $t('btn.search.label') }}
              span(slot="loading") {{ $t('btn.search.loading') }}

    template(v-if="fetching")
      loading-lines(:lines="5")

    template(v-else-if="empty")
      .empty.flex.column-direction.vertical-center
        img.not-found-icon(:src="notFoundIllustration")
        span.message {{ $t('.empty.label') }}

    template(v-else)
      .suppliers-rows
        supplier-row(
          v-for="(serviceOrderSupplier, index) in serviceOrderSuppliers",
          :key="serviceOrderSupplier.id",
          :service-order-supplier="serviceOrderSupplier",
          :index="index",
          :disabled="submitting",
          :show-badge="index === 0",
          @show="$emit('show', { serviceOrderSupplier, startingAt, showBadge: index === 0 })"
        )

</template>


<script>

// Assets
import notFoundIllustration from "@/assets/images/illustrations/not-found.svg"

// Mixins
import FetchMixin from "@/mixins/fetch-mixin"

// Components
import SupplierRow from "./index/supplier-row.vue"

export default {
  components: { SupplierRow },

  mixins: [FetchMixin],

  props: {
    serviceOrderId: { type: [String, Number], required: true },
    submitting:     { type: Boolean, default: false }
  },

  data() {
    return {
      i18nScope: "tickets.drafts.bookings.suppliers.index",

      resource: {},

      startingAt: moment().toISOString(),

      notFoundIllustration
    }
  },

  computed: {
    serviceOrderSuppliers: {
      get() { return this.resource },

      set(value) { this.resource = value }
    },

    empty() {
      return _.blank(this.serviceOrderSuppliers)
    }
  },

  methods: {
    // @override Fetch mixin
    fetchRequest() {
      return this.$newSdk.serviceOrders.availableSuppliers.all({
        serviceOrderId: this.serviceOrderId,

        params: { starting_at: this.startingAt }
      })
    }
  }
}

</script>

import Base from "./Base"

class VehicleModelGroupMaintenanceTables extends Base {
  createPrimary({ id, params = {} } = {}) {
    if (_.blank(id)) {
      throw new Error('SDK VehicleModelGroupMaintenanceTables - #createPrimary() - "id" is a required prop')
    }

    return this.action({
      method: "post",
      url:    `/vehicle_model_group_maintenance_tables/${id}/primary`,
      params
    })
  }
}

export default VehicleModelGroupMaintenanceTables

import Base from "./base"

import { STORE_BUSINESS_UNITS } from "@/enums/business-units"


// Representa ima filial Movida, podendo ou não ser Loja RAC! (veja attr business_unit)
class Branch extends Base {

  static get attrs() {
    return _.uniq([
      ...super.attrs,
      "businessUnit",  // enum
      "cnpj",
      "initials",
      "name"
    ])
  }

  static get enums() {
    return {
      businessUnit: STORE_BUSINESS_UNITS.all
    }
  }

}


export default Branch

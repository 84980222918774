// Base
import BaseRequests from "../Base"

// Models
import models from "@/models"
const { MovidaConsultant } = models

class MonitoringConsultantRequests extends BaseRequests {
  static get selector() {
    return "monitoring.consultants"
  }

  all({ params = {}, options = {} } = {}) {
    return this.action({
      method:  "get",
      url:     `/monitoring/consultants`,
      params,
      options: {
        model: MovidaConsultant,

        ...options
      }
    })
  }

  find({ consultantRid, params = {}, options = {} } = {}) {
    this.verifyData("find", { consultantRid })

    return this.action({
      method:  "get",
      url:     `/monitoring/consultants/${consultantRid}`,
      params,
      options: {
        model: MovidaConsultant,

        ...options
      }
    })
  }
}

export default MonitoringConsultantRequests

<style lang="scss" scoped>
.complementary-state {
  border: 1px solid $light-gray-3;
  border-radius: 4px;
  padding: 4px 8px;

  .icon {
    color: $gray-3;
    margin-right: 6px;
  }

  .label {
    color: $dark-gray;
    font-family: $secondary-font;
    font-size: 12px;
    line-height: 1.5;
  }
}
</style>

<template lang="pug">
  .complementary-state
    template(v-if="isComplementary")
      i.icon.fas.fa-rectangle-vertical-history
    span.label {{ label }}
</template>

<script>
import models from "@/models"

const { MovidaServiceOrder } = models

export default {
  props: {
    serviceOrder: { type: MovidaServiceOrder, required: true }
  },

  data() {
    return {
      i18nScope: "monitoring.complementary-state"
    }
  },

  computed: {
    isComplementary() {
      return _.present(this.serviceOrder.remoteParentId)
    },

    label() {
      return this.$t(this.isComplementary ? ".complementary" : ".main")
    }
  }
}
</script>

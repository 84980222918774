<template lang="pug">

  select-field(
    v-bind="$props",
    v-on="$listeners",
    :options="options"
  )

</template>


<script>

import Vue from "vue"

import SelectField from "@/components/form/select-field"

import models from "@/models"
const { VehicleModel } = models

export default {
  extends: SelectField,

  data() {
    return {
      i18nScope: "vehicle-models.components.vehicle-model-type-select-field"
    }
  },

  computed: {
    options() {
      return VehicleModel.$enums.type.values.map(type => ({
        label: VehicleModel.$tEnum("typePowerSource", type),
        value: type
      }))
    }
  },

  // Removendo props 'options' que está sendo reescrita como _computed_
  beforeCreate() {
    Vue.delete(this.$options.props, "options")
  }
}

</script>

/**
 * Singleton que contém os dados de índices de carga de pneu
 *
 * A propridade `data` (getter) retorna todos os dados.
 *
 * Existem métodos de apoio, como `keys` e `values` - para ficar próximo a um Object -
 * além do `asEnum()`, para uso como `enum` em model.js.
 *
 * E também expõe metadados com métodos próprios, como o `kgFor(key)`.
 * ```js
 * let index = 70
 * weightLimitInKg = LoadIndices.kgFor(index)
 * // => 335
 * ````
 *
 * @type {Object}
 */
const LoadIndices = {
  get data() {
    // ref: https://www.tyresizecalculator.com/charts/tire-load-index-chart
    return {
      70:  { code: 70, kg: 335 },
      71:  { code: 71, kg: 345 },
      72:  { code: 72, kg: 355 },
      73:  { code: 73, kg: 365 },
      74:  { code: 74, kg: 375 },
      75:  { code: 75, kg: 387 },
      76:  { code: 76, kg: 400 },
      77:  { code: 77, kg: 412 },
      78:  { code: 78, kg: 425 },
      79:  { code: 79, kg: 437 },
      80:  { code: 80, kg: 450 },
      81:  { code: 81, kg: 462 },
      82:  { code: 82, kg: 475 },
      83:  { code: 83, kg: 487 },
      84:  { code: 84, kg: 500 },
      85:  { code: 85, kg: 515 },
      86:  { code: 86, kg: 530 },
      87:  { code: 87, kg: 545 },
      88:  { code: 88, kg: 560 },
      89:  { code: 89, kg: 580 },
      90:  { code: 90, kg: 600 },
      91:  { code: 91, kg: 615 },
      92:  { code: 92, kg: 630 },
      93:  { code: 93, kg: 650 },
      94:  { code: 94, kg: 670 },
      95:  { code: 95, kg: 690 },
      96:  { code: 96, kg: 710 },
      97:  { code: 97, kg: 730 },
      98:  { code: 98, kg: 750 },
      99:  { code: 99, kg: 775 },
      100: { code: 100, kg: 800 },
      101: { code: 101, kg: 825 },
      102: { code: 102, kg: 850 },
      103: { code: 103, kg: 875 },
      104: { code: 104, kg: 900 },
      105: { code: 105, kg: 925 },
      106: { code: 106, kg: 950 },
      107: { code: 107, kg: 975 },
      108: { code: 108, kg: 1000 },
      109: { code: 109, kg: 1030 },
      110: { code: 110, kg: 1060 },
      111: { code: 111, kg: 1090 },
      112: { code: 112, kg: 1120 },
      113: { code: 113, kg: 1150 },
      114: { code: 114, kg: 1180 },
      115: { code: 115, kg: 1215 },
      116: { code: 116, kg: 1250 },
      117: { code: 117, kg: 1285 },
      118: { code: 118, kg: 1320 },
      119: { code: 119, kg: 1360 },
      120: { code: 120, kg: 1400 },
      121: { code: 121, kg: 1450 },
      122: { code: 122, kg: 1500 },
      123: { code: 123, kg: 1550 },
      124: { code: 124, kg: 1600 },
      125: { code: 125, kg: 1650 },
      126: { code: 126, kg: 1700 },
      127: { code: 127, kg: 1750 },
      128: { code: 128, kg: 1800 },
      129: { code: 129, kg: 1850 },
      130: { code: 130, kg: 1900 },
      131: { code: 131, kg: 1950 },
      132: { code: 132, kg: 2000 },
      133: { code: 133, kg: 2060 },
      134: { code: 134, kg: 2120 },
      135: { code: 135, kg: 2180 },
      136: { code: 136, kg: 2240 },
      137: { code: 137, kg: 2300 },
      138: { code: 138, kg: 2360 },
      139: { code: 139, kg: 2430 },
      140: { code: 140, kg: 2500 },
      141: { code: 141, kg: 2575 },
      142: { code: 142, kg: 2650 },
      143: { code: 143, kg: 2725 },
      144: { code: 144, kg: 2800 },
      145: { code: 145, kg: 2900 },
      146: { code: 146, kg: 3000 },
      147: { code: 147, kg: 3075 },
      148: { code: 148, kg: 3150 },
      149: { code: 149, kg: 3250 },
      150: { code: 150, kg: 3350 }
    }
  },

  get keys() {
    return Object.keys(this.data)
  },

  get values() {
    return _.map(this.data, "code")
  },

  asEnum() {
    return _.reduce(this.data, (result, item, key) => {
      result[key] = item.code
      return result
    }, {})
  },

  kgFor(key) {
    return _.get(this.data[key], "kg")
  }
  // findBy..()
}

export default LoadIndices

<style lang="scss" scoped>

.show {
  .back {
    color: $dark-gray;
    font-size: 14px;
    margin-bottom: 8px;

    &:hover {
      color: $orange;
    }

    .icon {
      font-size: 12px;
      margin-right: 4px;
    }
  }

  .header {
    padding-bottom: 24px;
    border-bottom: 1px solid $light-gray-3;
    margin-bottom: 24px;

    .title {
      font-family: $secondary-font;
      font-weight: 500;
      font-size: 30px;
      margin-bottom: 8px;

      * + * {
        margin-left: 8px;
      }
    }

    .info {
      * + * {
        margin-left: 8px;
      }

      .version {
        font-family: $secondary-font;
        font-weight: 500;
        font-size: 20px;
        color: $gray-3;
      }

      .icon-container {
        height: 20px;
        width: 20px;

        .icon {
          font-size: 12px;
        }
      }

      .id,
      .fipe {
        font-family: $primary-monospace-font;
        font-weight: 400;
        font-size: 18px;
        color: $gray-2;
      }
    }

    .info-row {
      margin-top: 24px;
      padding: 16px 0;
      border: 1px solid $light-gray-3;
      border-radius: 8px;

      .info-row-item {
        flex: 1 1 0;
        padding: 0 16px;

        + .info-row-item {
          border-left: 1px solid $light-gray-3;
        }

        .info-icon {
          font-family: $secondary-font;
          font-weight: 400;
          font-size: 18px;

          .icon-container {
            width: 20px;
            height: 20px;
            margin-right: 16px;

            .icon {
              color: $purple;
              font-size: 16px;
            }
          }
        }

        .composite-info {
          font-family: $secondary-font;
          font-weight: 400;

          .icon-container {
            width: 24px;
            height: 24px;
            margin-right: 16px;

            &.store-icon {
              mask-size: cover;
              background-color: $gray-3;
            }

            .icon {
              color: $gray-3;
            }
          }

          .gmf {
            height: 8px;
          }

          .state-origin {
            margin-left: 4px;
          }

          .unknown,
          .icon-container .icon.unknown {
            color: $gray-2;
          }
        }
      }
    }
  }

  .tickets-title {
    font-family: $secondary-font;
    font-weight: 500;
    font-size: 20px;
  }
}

.modal {
  padding: 16px 24px;
}

</style>


<template lang="pug">

  .show
    app-button.back(theme="link", @click="goBack")
      i.icon.fas.fa-arrow-left
      span {{ $t('.go-back.label') }}

    template(v-if="fetching")
      loading-lines(:lines="6")

    template(v-else)
      header.header
        .flex.space-between.vertical-center
          .title.flex.vertical-center
            vehicle-manufacturer-icon(:value="manufacturer.name", :size="32")
            template(v-if="hasVehicleModel")
              span.manufacturer {{ manufacturer.name }}
              span.model {{ model.name }}

            template(v-else)
              span.model {{ vehicle.fullModelName }}
            span.year {{ vehicle.year }}

          app-button(:to="{ name: 'newDraftTicket', query: { licensePlate: vehicle.licensePlate } }") {{ $t('.create-ticket.label') }}

        .info.flex.vertical-center
          template(v-if="hasVehicleModel")
            span.version {{ version.name }}
            .icon-container.flex.vertical-center.center
              v-popover(
                placement="bottom",
                trigger="click"
              )
                i.icon.fas.fa-chevron-down
                template(slot="popover")
                  vehicle-details-popover-content(:vehicle="vehicle")

          span.id {{ $t('.id.label', { id: vehicle.id }) }}
          span.fipe {{ $t('.fipe.label', { fipe: vehicle.fipeId }) }}

        .info-row.flex.vertical-center
          .info-row-item.flex.center.vertical-center
            vehicle-plate(:value="vehicle.licensePlate")

          span.info-row-item.flex.vertical-center.center
            .info-icon.flex.vertical-center
              .icon-container.flex.vertical-center.center
                i.icon.fas.fa-swatchbook
              span {{ vehicle.colorName }}

          span.info-row-item.flex.vertical-center.center
            .info-icon.flex.vertical-center
              .icon-container.flex.vertical-center.center
                i.icon.fas.fa-tachometer-alt-average
              span {{ $t('.km.label', { km: $asNumber(vehicle.km, { precision: 0 }) }) }}

          span.info-row-item.flex.vertical-center.center
            .info-icon.flex.vertical-center
              .icon-container.flex.vertical-center.center
                i.icon.fas.fa-gas-pump
              span {{ fuel }}

        .info-row.flex.vertical-center
          span.info-row-item
            template(v-if="vehicle.currentStoreRac")
              .composite-info.flex.vertical-center
                .store-icon.icon-container.flex.vertical-center.center(:style="storeIconStyle")

                .rows.grow
                  .top
                    span {{ $t('.business-unit.RAC.label') }}
                  .bottom
                    store-minimum-info(:store-id="vehicle.currentStoreId")

            template(v-else)
              .composite-info.flex.vertical-center
                .icon-container.flex.vertical-center.center
                  i.icon.fas.fa-building.unknown

                span.unknown {{ $t('.business-unit.unknown.label') }}

          span.info-row-item
            .composite-info.flex.vertical-center
              .icon-container.flex.vertical-center.center
                i.icon.fas.fa-circle

              .flex.vertical-center
                span {{ vehicleState }}
                gmf-icon.gmf.state-origin

      span.tickets-title {{ $t('.last-tickets.title') }}

      last-tickets-index(
        :vehicle="vehicle",
        @select="id => serviceOrderId = id"
      )

      app-modal(
        v-if="hasServiceOrderId"
        full-height,
        :width="1156",
        :heading="$t('.ticket.title')",
        @close="serviceOrderId = null"
      )
        last-tickets-show.modal(
          :vehicleId="vehicle.id",
          :service-order-id="serviceOrderId"
        )

</template>


<script>

// View
import LoadedView from "@/views/loaded-view"

// Mixins
import FetchMixin from "@/mixins/fetch-mixin"

// Assets
import storesSolid from "@/assets/images/icons/sidebar/stores-icon-solid.svg"

// Components
import LastTicketsIndex from "@/views/tickets/drafts/vehicles/components/last-tickets/index.vue"
import LastTicketsShow from "@/views/tickets/drafts/vehicles/components/last-tickets/show.vue"
import StoreMinimumInfo from "./show/store-minimum-info.vue"
import VehicleDetailsPopoverContent from "./show/vehicle-details-popover-content.vue"

// Models
import models from "@/models"
const { VehicleModel, Vehicle, VehicleModelCombustion } = models

export default {
  name: "Vehicle",

  components: {
    LastTicketsIndex,
    LastTicketsShow,
    StoreMinimumInfo,
    VehicleDetailsPopoverContent
  },

  extends: LoadedView,

  mixins: [FetchMixin],

  data() {
    return {
      i18nScope: "vehicles.show",
      routeName: "vehicle",

      vehicleId: null,

      serviceOrderId: null,

      // FetchMixin
      resource: {}
    }
  },

  computed: {
    backRoute() {
      return { name: "vehicles" }
    },

    vehicle: {
      get() { return this.resource },

      set(value) { this.resource = value }
    },

    vehicleModel() {
      return _.get(this.vehicle, "vehicleModel") || {}
    },

    hasVehicleModel() {
      return _.present(this.vehicleModel.id)
    },

    version() {
      return _.get(this.vehicleModel, "mobiautoVersion") || {}
    },

    model() {
      return _.get(this.version, "model") || {}
    },

    manufacturer() {
      return _.get(this.model, "manufacturer") || {}
    },

    fuel() {
      switch (this.vehicleModel.type) {
        case "VehicleModels::Electric": return VehicleModel.$tEnum("type", this.vehicleModel.type)
        case "VehicleModels::Hybrid": return `${VehicleModel.$tEnum("type", this.vehicleModel.type)} - ${VehicleModelCombustion.$tEnum("fuel", this.vehicleModel.fuel)}`
        case "VehicleModels::Combustion":
        default: return VehicleModelCombustion.$tEnum("fuel", this.vehicleModel.fuel)
      }
    },

    vehicleState() {
      return _.present(this.vehicle.state)
        ? Vehicle.$tEnum("state", this.vehicle.state)
        : this.$t(".vehicle-state.unknown.label")
    },

    storeIconStyle() {
      return {
        "mask-image": `url(${storesSolid})`
      }
    },

    hasServiceOrderId() {
      return _.present(this.serviceOrderId)
    }
  },

  methods: {
    parseRoute() {
      this.vehicleId = this.$params.asInteger(this.$route.params.id)
    },

    fetchRequest() {
      return this.$newSdk.vehicles.find({ licensePlateOrId: this.vehicleId, options: { model: null } })
    },

    // @override FetchMixin
    onFetchSuccess({ data }) {
      const vehicleModel = VehicleModel.build(data.vehicleModel)
      this.resource = new Vehicle({ ...data, vehicleModel })
    },

    setServiceOrder(serviceOrderId) {
      this.serviceOrderId = serviceOrderId
    }
  }
}

</script>

import Base from "./base"
import { LoadIndices } from "./tire/enums"

class TireLoadIndex extends Base {
  static get attrs() {
    return _.uniq([
      ...super.attrs,
      "loadIndex"
    ])
  }

  static get constraints() {
    return {
      loadIndex: { presence: true }
    }
  }

  static get enums() {
    return {
      loadIndex: LoadIndices.asEnum()
    }
  }
}

export default TireLoadIndex

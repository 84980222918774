<template lang="pug">

  .supplier-info-banner
    .supplier-info
      .row.flex.vertical-center
        app-span.title(:value="supplier.tradingName")

      .row.flex.vertical-center
        i.icon.fas.fa-house-user
        app-span.label(:value="supplier.fullAddress || emptyMessage")

      .row.flex.vertical-center
        i.icon.fas.fa-envelope
        app-span.label(:value="supplier.email || emptyMessage")
        copy-icon.copy-icon(v-if="supplier.email", :copy-value="supplier.email")

</template>


<script>

import SupplierInfoBanner from "@/views/service-orders/show/supplier-info-banner.vue"

export default {
  name: "SupplierInfoTag",

  extends: SupplierInfoBanner,

  computed: {
    emptyMessage() {
      return this.$t("unregistered.masculine")
    }
  }
}

</script>

<style lang="scss" scoped>

.table-row {
  display: grid;
  grid-template-columns: var(--grid-columns);

  &.loading {
    .column {
      .id-tag {
        color: $gray-2;
      }

      &.actions-column {
        ::v-deep .movida-common__app-button {
          color: $gray-2;

          &:hover {
            color: $orange;
          }
        }
      }

    }
  }

  .column {
    padding: 0 12px;
    border-bottom: 1px solid $light-gray-3;
    height: 56px;
    overflow: hidden;

    .id-tag {
      margin-left: 4px;
    }

    .button {
      overflow: hidden;
    }

    .text {
      font-family: $primary-font;
      font-weight: 400;
      font-size: 14px;
      text-align: initial;
    }

    .link ::v-deep .app-span:hover {
      color: $orange;
    }

    .vehicle-solutions-count {
      margin-left: 4px;
    }
  }
}

</style>


<template lang="pug">

  .table-row(:class="{ loading }", :style="styles")
    .column.selection-column.flex.vertical-center.center.flex
      checkbox-field(
        :name="`serviceVehicleModelGroup-${this.serviceVehicleModelGroup.id}`",
        hide-value-text,
        :disabled="loading",
        :value="value",
        @input="(value) => $emit('input', { id: serviceVehicleModelGroup.id, value })",
        data-testid="checkbox"
      )

    .column.flex.vertical-center.center.column-direction
      service-type-icon(:type="serviceType")

    .column.flex.center.column-direction
      .flex.vertical-center
        app-span.text(:value="service.name", crop, data-testid="serviceName")
        id-tag.id-tag(:value="service.id", data-testid="serviceId")
        service-kind-icon(:kind="service.kind")

      app-span.service-description(
        crop,
        :value="service.description"
        v-tooltip.bottom="{ offset: 4, content: service.description }"
      )

    .column.vehicle-model-group-column.flex.center.column-direction
      .flex.vertical-center
        app-span.text(:value="vehicleModelGroup.name", crop, data-testid="vehicleModelGroupName")
        id-tag.id-tag(:value="vehicleModelGroup.id", data-testid="vehicleModelGroupId")

      app-span.vehicle-models-count(
        :value="$t('.vehicleModelsCount', { count: vehicleModelGroup.vehicleModelsCount })",
        data-testid="vehicleModelsCount"
      )

    .column.actions-column.flex.vertical-center
      i.fal.fa-lightbulb(v-tooltip.bottom="{ offset: 4, content: $t('.tooltip.vehicleIssueSolutions.label') }")
      span.vehicle-solutions-count {{ service.vehicleIssueSolutionsCount }}

    .column.actions-column.flex.vertical-center
      template(v-if="serviceWithCost")
        i.icon.fal.fa-fist-raised(v-tooltip.bottom="{ offset: 4, content: $t('.tooltip.laborCostLinked.label') }")

</template>


<script>

export default {
  props: {
    serviceVehicleModelGroup: { type: Object, required: true },

    value: { type: Boolean, default: false },

    loading: { type: Boolean, default: false },

    columnSizes: { type: String, required: true }
  },

  data() {
    return {
      i18nScope: "suppliers.service-supports.service-vehicle-model-groups.destroy.table-row"
    }
  },

  computed: {
    serviceType() {
      return _.get(this.service, "type")
    },

    service() {
      return _.get(this.serviceVehicleModelGroup, "service") || {}
    },

    vehicleModelGroup() {
      return _.get(this.serviceVehicleModelGroup, "vehicleModelGroup") || {}
    },

    serviceWithCost() {
      return _.get(this.service, "laborCostLinked")
    },

    styles() {
      return {
        "--grid-columns": this.columnSizes
      }
    }
  }
}

</script>

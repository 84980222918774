<style lang="scss" scoped>

.vehicle-history {
  background-color: $light-gray;
  padding: 16px;
  border-radius: 8px;

  .title {
    font-family: $secondary-font;
    font-weight: 500;
    font-size: 18px;
  }

  .action {
    font-family: $secondary-font;
    font-weight: 500;
    font-size: 14px;

    &:hover {
      color: $orange;
      cursor: pointer;
    }

    .open-icon {
      font-size: 12px;
      margin-right: 4px;
    }

    .icon-container {
      width: 24px;
      height: 24px;

      .close-icon {
        font-size: 16px;
      }
    }
  }

  .content {
    .interactions {
      margin: 16px 0;

      .tabs {
        border-bottom: 1px solid $light-gray-3;
        width: 480px;

        .tab {
          font-family: $secondary-font;
          font-weight: 400;
          font-size: 16px;
          height: 40px;
          line-height: 40px;
          color: $gray-3;
          cursor: pointer;
          user-select: none;

          &.selected {
            color: $orange;
            box-shadow: inset 0px -4px 0px $orange;
          }

          & + .tab {
            margin-left: 16px;
          }
        }
      }

      .search {
        width: 432px;
        display: grid;
        gap: 8px;
        grid-template-columns: 1fr 40px;

        .search-button {
          min-width: revert;
        }
      }
    }

    .table {
      > .header,
      .row {
        grid-template-columns: minmax(389px, 1fr) minmax(389px, 1fr) 125px 125px;
      }

      > .erred,
      > .empty {
        background-color: $white;
        border-radius: 4px;
        padding: 8px;
      }

      > .erred {
        .retry {
          margin-left: 4px;
        }
      }

      > .empty {
        font-size: 12px;
        font-style: italic;

        min-height: 130px;

        .icon {
          font-size: 32px;
          color: $gray;

          background: $light-gray-2;
          border-radius: 64px;
        }
      }

      .header {
        font-family: $secondary-font;
        font-weight: 400;
        font-size: 12px;
        color: $gray-3;

        .column {
          padding: 4px 8px;
        }
      }

      .rows {
        background-color: $white;
        border-radius: 4px;

        .row {
          font-weight: 400;
          font-size: 12px;

          & + .row {
            border-top: 1px solid $light-gray-3;
          }

          .column {
            padding: 8px;

            .text {
              font-family: $secondary-font;
            }

            .value {
              font-family: $primary-monospace-font;
            }
          }
        }
      }
    }

    .paginator-content {
      padding-top: 12px;

      &.loading {
        max-width: 400px;
        margin: 0 auto;
      }

      ::v-deep .paginator {
        background-color: transparent;

        .content .btn:not(.current) {
          background-color: transparent;
        }
      }
    }
  }
}

</style>


<template lang="pug">

  .vehicle-history
    .flex.space-between
      h3.title {{ $t('.title') }}

      template(v-if="open")
        .action.flex.vertical-center(@click="open = false")
          i.open-icon.fas.fa-chevron-up
          span {{ $t('.btn.close') }}

      template(v-else)
        .action.flex.vertical-center(@click="open = true")
          i.open-icon.fas.fa-chevron-down
          span {{ $t('.btn.open') }}

    .content(v-show="open")
      .interactions.flex.space-between.vertical-center
        .tabs.flex.vertical-center
          .tab(
            :class="{ selected: tab === 'services' }",
            @click="setTab('services')"
          )
            span {{ $t('.tabs.services') }}

          .tab(
            :class="{ selected: tab === 'products' }",
            @click="setTab('products')"
          )
            span {{ $t('.tabs.products') }}

        form.search(
          @submit.prevent="search",
          method="get"
        )
          input-field(
            name="search[q]",
            v-model="params.q",
            :loading="fetching",
            hide-label,
            :placeholder="$t('.fields.q.placeholder')",
            icon-left="far fa-search"
          )

          app-button.search-button(
            type="submit",
            :loading="fetching",
            outline
          )
            i.icon.far.fa-search



      .table
        .header.grid
          .column
            span {{ $t('.table.header.name') }}
          .column
            span {{ $t('.table.header.supplier') }}
          .column
            span {{ $t('.table.header.service-order') }}
          .column
            span {{ $t('.table.header.date') }}

        template(v-if="erred")
          .erred.flex.vertical-center.center
            span {{ $t('.fetch-error.message') }}
            app-button.retry(theme="link", @click="fetch") {{ $t('.fetch-error.retry.label') }}

        template(v-else-if="fetching")
          loading-lines(:lines="6")

        template(v-else-if="empty")
          .empty.flex.center.vertical-center.column-direction
            i.icon(:class="emptyIconClass")
            span {{ emptyText }}

        template(v-else)
          .rows
            .row.grid(v-for="item in collection")
              .column.flex.vertical-center
                app-span.text(crop, :value="nameFor(item)")
              .column.flex.vertical-center
                app-span.text(crop, :value="item.serviceOrder.supplier.tradingName")
              .column.flex.vertical-center
                span.value {{ item.serviceOrder.id }}
              .column.flex.vertical-center
                span.value {{ $l('date.formats.default', item.serviceOrder.scheduledAt) }}

      .paginator-content(v-if="fetching || showPaginator", :class="{ loading: fetching }")
        paginator(
          :loading="fetching",
          :per-page="pagination.perPage",
          :total="pagination.total",
          :value="params.page",
          :disabled="fetching",
          @input="onPageChange",
          data-testid="paginator"
        )

</template>


<script>

// Mixins
import FetchMixin from "@/mixins/fetch-mixin"

// Extends
import IndexComponent from "@/components/index-component"


export default {
  extends: IndexComponent,

  mixins: [FetchMixin],

  props: {
    vehicleId: { type: [String, Number], required: true }
  },

  data() {
    return {
      i18nScope: "monitoring.quotes.vehicle-history",

      open: false,

      tab: "services",

      resource: {}
    }
  },

  computed: {
    empty() {
      return _.blank(this.resource)
    },

    collection: {
      get()    { return this.resource },
      set(val) { this.$emit("update:resource", val) } // para funcionar .sync
    },

    emptyIconClass() {
      switch (this.tab) {
        case "services": return "fal fa-wrench"
        case "products": return "fal fa-cube"
      }
    },

    emptyText() {
      switch (this.tab) {
        case "services": return this.$t(".empty.services")
        case "products": return this.$t(".empty.products")
      }
    }
  },

  methods: {
    setTab(tab) {
      this.tab = tab
      this.search()
    },

    nameFor(item) {
      return this.tab === "services"
        ? item?.service?.name
        : item?.product?.name
    },

    // @override Fetch Mixin
    fetchRequest() {
      return this.tab === "services"
        ? this.$newSdk.monitoring.vehicles.serviceOrderServices.all({ vehicleId: this.vehicleId, params: this.requestParams })
        : this.$newSdk.monitoring.vehicles.serviceOrderProducts.all({ vehicleId: this.vehicleId, params: this.requestParams })
    }
  }
}

</script>

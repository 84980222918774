<style lang="scss" scoped>

.target-location-select-field {
  .icon {
    width: 16px;
    height: 16px;
    font-size: 16px;
    margin-right: 8px;
  }
}

</style>


<template lang="pug">

  select-field.target-location-select-field(
    v-bind="$props",
    v-on="$listeners",
    :placeholder="$t('.placeholder')",
    :options="options"
  )

</template>


<script>

// Extends
import SelectField from "@/components/form/select-field"

export default {
  extends: SelectField,

  props: {
    // Mudando comportamento para default ser true
    searchable: { type: Boolean, default: true }
  },

  data() {
    return {
      i18nScope: "tickets.drafts.contents.services.components.target-location-select-field"
    }
  }
}

</script>

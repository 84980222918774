<template lang="pug">
  finder-field(
    v-bind="forwardedProps"
    v-on="$listeners"
    :options="options"
  )
</template>

<script>
import { STATES } from "@/enums/geo"

export default {
  inheritAttrs: false,

  data() {
    return {
      options: STATES.values.map(state => ({
        label: this.$t(`enums.geo.STATES.${state}`),
        value: state
      }))
    }
  },

  computed: {
    forwardedProps() {
      return _.omit(this.$attrs, ["options"])
    }
  }
}
</script>

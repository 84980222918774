<script>

import ProductsTable from "@/views/products/index/products-table.vue"
import ProductsTableHeader from "./products-table-header.vue"

export default {
  components: {
    ProductsTableHeader
  },

  extends: ProductsTable
}

</script>

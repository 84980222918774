// Base
import BaseRequests from "../Base"

// Models
import models from "@/models"
const { SupplierDetails } = models

class SupplierDetailsRequests extends BaseRequests {
  static get selector() {
    return "suppliers.details"
  }

  find({ supplierId, params = {}, options = {} } = {}) {
    this.verifyData("find", { supplierId })

    return this.action({
      method:  "get",
      url:     `/suppliers/${supplierId}/details`,
      params,
      options: {
        model: SupplierDetails,

        ...options
      }
    })
  }

  update({ supplierId, params = {}, options = {} } = {}) {
    this.verifyData("update", { supplierId })

    return this.action({
      method:  "patch",
      url:     `/suppliers/${supplierId}/details`,
      params,
      options: {
        model: SupplierDetails,

        ...options
      }
    })
  }
}

export default SupplierDetailsRequests

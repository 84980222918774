<style lang="scss" scoped>

.not-found {
  min-height: 100%;
  width: 100%;
  background: $white;
}

.img {
  margin-top: 10vh;
  margin-bottom: 32px;
  width: 70%;

  @media only screen and (min-width: 1600px) {
    width: 1200px;
  }
}

.button-content {
  display: block;
  width: 200px;
  padding-bottom: 20px;
}

</style>


<template lang="pug">

  .not-found.flex.vertical-center.column-direction(data-test="not-found")
    img.img(:src="illustration")
    .button-content
      app-button(
        outline,
        size="small",
        full-width
        @click="goBack",
      ) {{ $t(".btn.goBack") }}

</template>


<script>

// Assets
import illustration from "@/assets/images/404.png"

export default {
  name: "NotFound",

  data() {
    return {
      i18nScope: "not-found",

      illustration
    }
  },

  methods: {
    goBack() {
      this.$goBack()
    }
  }
}

</script>

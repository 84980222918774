import { render, staticRenderFns } from "./deal-product-row-skeleton.vue?vue&type=template&id=421d8347&scoped=true&lang=pug&"
var script = {}
import style0 from "./deal-product-row-skeleton.vue?vue&type=style&index=0&id=421d8347&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "421d8347",
  null
  
)

export default component.exports
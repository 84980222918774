<style lang="scss" scoped>

.product-row {
  display: grid;
  grid-template-columns: var(--grid-columns);

  .column {
    padding: 12px;
    border-bottom: 1px solid $light-gray-3;
    overflow: hidden;

    &.total-price-column {
      flex-direction: column;
      align-items: flex-end;
    }

    .empty {
      font-family: $primary-monospace-font;
      font-weight: 400;
      font-size: 14px;
      color: $gray-2;
    }

    .label {
      font-family: $secondary-font;
      font-size: 14px;
      font-weight: 400;
    }

    .sap-id,
    .currency {
      font-family: $primary-monospace-font;
      font-weight: 400;
      font-size: 14px;
    }

    .discount {
      display: block;
      font-size: 12px;
      color: $gray-3;
      line-height: 16px;
    }
  }
}

</style>


<template lang="pug">

  .product-row(:style="styles")
    .column.type-column.flex.vertical-center
      app-span.sap-id(:value="product.sapId")

    .column.product-column.flex.column-direction.center
      app-span.label(:value="product.description")

    .column.product-column.flex.column-direction.center
      app-span.label(:value="partOrigin")

    .column.quantity-column.flex.vertical-center.end
      app-span.label(:value="product.quantity")

    .column.price-column.flex.vertical-center.end
      app-span.currency(:value="price")

    .column.total-price-column.flex.vertical-center.end
      template(v-if="hasDiscount")
        app-span.currency.discount(:value="discount")
      app-span.currency(:value="totalPrice")

</template>


<script>

export default {
  props: {
    product:     { type: Object, required: true },
    columnSizes: { type: String, required: true }
  },

  data() {
    return {
      i18nScope: "product-orders.edit.products-table.product-row",

      showPopover: false
    }
  },

  computed: {
    styles() {
      return {
        "--grid-columns": this.columnSizes
      }
    },

    price() {
      return this.$i18n.l("currency", this.product.price, { unit: "R$" })
    },

    hasDiscount() {
      return this.product.discount > 0
    },

    discount() {
      return `- ${parseFloat(this.product.discount)}`
    },

    totalPrice() {
      return this.$i18n.l("currency", this.product.totalPrice, { unit: "R$" })
    },

    partOrigin() {
      const { partOrigin } = this.product

      return this.$i18n.t(`models.dealProduct.enums.partOrigin.${partOrigin}`)
    }
  }
}

</script>

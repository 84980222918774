
<style lang="scss" scoped>

.vehicle-model-group-maintenance-table-row {
  padding: 0 24px;
  min-height: 68px;
  height: 70px;

  .id-column {
    width: 60px;
    margin-right: 24px;
  }

  .content-column {
    overflow: hidden;

    .name,
    .description {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .name {
      font-family: $secondary-font;
      font-size: 18px;
      font-weight: 400;
      color: $dark-gray;

      .link {
        color: $dark-gray;

        &:hover {
          color: $primary;
        }
      }
    }
  }

  .counts-column {
    margin-left: 24px;
    margin-right: 16px;

    .count {
      & + .count {
        margin-left: 24px;
      }

      .count-icon {
        margin-right: 4px;
      }
    }
  }
}

</style>


<template lang="pug">

  .vehicle-model-group-maintenance-table-row.flex.vertical-center
    .id-column.no-shrink(data-testid="maintenanceTableId")
      id-tag(:value="maintenanceTable.id")

    .content-column.grow
      .name
        label.name {{ maintenanceTable.name }}

      .description(
        v-if="maintenanceTable.description",
        data-testid="description"
      )
        span {{ maintenanceTable.description }}

    .kind-column
      maintenance-table-kind-tag.info-label(:kind="maintenanceTable.kind")

    .counts-column.flex
      .count.flex.vertical-center(
        v-tooltip.bottom="{ offset: '4', content: $t('.fields.maintenancesCount.tooltip') }",
        data-testid="maintenancesCount"
      )
        i.count-icon.fal.fa-car-mechanic
        label {{ maintenanceTable.maintenancesCount }}

</template>


<script>

export default {
  props: {
    maintenanceTable: { type: Object, default: () => ({}) }
  },

  data() {
    return {
      i18nScope: "vehicle-model-groups.maintenance-tables.import.vehicle-model-group-maintenance-table-row",

      showPopover: false
    }
  }
}

</script>

<style lang="scss" scoped>

.vehicle-model-group-option {
  padding: 0 12px;
  height: 56px;
  line-height: normal;

  cursor: pointer;

  * {
    cursor: pointer;
  }

  &.active {
    background: $light-gray-2;

    .name {
      color: $primary;
    }
  }

  &.focus {
    background: $light-gray-2;

    .name {
      color: $dark-primary-2;
    }

    .remove-icon-content {
      background: $light-gray-3;
      color: $primary;
    }
  }

  .content {
    overflow: hidden;

    .top {
      .name {
        font-family: $secondary-font;
        font-weight: 400;
        font-size: 18px;
      }

      .tag {
        margin-left: 8px;
      }
    }

    .bottom {
      overflow: hidden;

      .description {
        font-size: 14px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        display: block;
      }
    }
  }

  .actions-column {
    min-width: 42px;
    margin-left: 12px;

    .remove-icon-content {
      display: inline-block;
      width: 24px;
      height: 24px;
      line-height: 24px;
      text-align: center;
      border-radius: 50px;
      color: $gray-2;
      transition: all 100ms linear;
    }
  }
}

</style>


<template lang="pug">

  .vehicle-model-group-option.flex.vertical-center(:class="{ focus, active }")
    .content.grow
      .top.flex.vertical-center
        app-span.name(
          :value="vehicleModelGroup.name",
          crop
        )
        id-tag.tag(:value="vehicleModelGroup.id")
      .bottom
        template(v-if="vehicleModelGroup.description")
          app-span.description(
            :value="vehicleModelGroup.description",
            crop
          )

    .actions-column.center(v-if="!hideActionColumn")
      .remove-icon-content(v-if="active")
        i.fal.fa-times

</template>


<script>

export default {
  props: {
    option:           { type: Object, required: true },
    active:           { type: Boolean, default: false },
    focus:            { type: Boolean, default: false },
    hideActionColumn: { type: Boolean, default: false }
  },

  data() {
    return {
      i18nScope: "suppliers.deals.products.components.vehicle-model-group-option"
    }
  },

  computed: {
    vehicleModelGroup() {
      return this.option.data
    }
  }
}

</script>

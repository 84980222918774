<template lang="pug">

  app-modal.modal(
    modal-id="attachments-carousel",
    :width="1348"
  )
    .attachments-carousel
      .flex.center.vertical-center
        .navigation.flex.center.vertical-center(
          :class="{ disabled: !hasPrevious }",
          @click="goToPrevious"
        )
          i.icon.far.fa-chevron-left

        quote-attachment-preview-data.preview(
          ref="preview",
          :attachment="selectedAttachment",
          :editable="editable",
          :editing.sync="editing",
          :key="selectedAttachment.id",
          :quote-id="quoteId",
          @close="$emit('close')",
          @download="onDownload"
        )

        .navigation.flex.center.vertical-center(
          :class="{ disabled: !hasNext }",
          @click="goToNext"
        )
          i.icon.far.fa-chevron-right

      .flex.center.vertical-center
        quote-attachment-thumbnail.thumbnail(
          v-for="(attachment, index) in attachmentsInPage",
          :attachment="attachment",
          :key="`thumbnail-${attachment.id}`",
          :quote-id="quoteId",
          :selected="attachment.id === selectedAttachment.id",
          @click.native="$emit('update:index', (currentPage - 1) * perPage + index )"
        )

      .pages.flex.center.vertical-center(v-if="pages > 1")
        template(v-for="page in pages")
          .page.flex.center.vertical-center(
            :class="{ selected: page === currentPage }",
            @click="currentPage = page"
          )
            span {{ page }}

</template>


<script>

// Extends
import AttachmentsCarousel from "./attachments-carousel.vue"

export default {
  name: "QuoteAttachmentsCarousel",

  extends: AttachmentsCarousel,

  props: {
    quoteId: { type: [String, Number], required: true }
  },

  methods: {
    downloadRequest() {
      return this.$newSdk.quotes.blobs.getDownloadURL({ quoteId: this.quoteId, blobId: this.selectedAttachment.blobId, params: { disposition: "attachment" } })
    }
  }
}

</script>

<style lang="scss" scoped>

.deal-state-change-row {
  .top {
    padding-top: 16px;
    border-left: 1px solid $light-gray-3;
    margin-left: 12px;
  }

  .header {
    margin: 8px 0;

    .icon-container {
      width: 24px;
      margin-right: 24px;
    }

    .status {
      font-weight: 400;
      margin-right: 8px;
    }

    .info {
      white-space: pre;
    }

    .deal-link {
      .icon {
        margin-right: 8px;
      }

      .deal {
        font-family: $primary-font;
        font-weight: 300;
      }
    }
  }

  .bottom {
    padding-bottom: 16px;
    border-left: 1px solid $light-gray-3;
    margin-left: 12px;

    .description {
      margin-left: 35px;
      font-size: 14px;
      white-space: pre;
    }
  }
}

</style>


<template lang="pug">

  .deal-state-change-row
    .top
    .header.flex.vertical-center
      .icon-container.flex.vertical-center.center
        deal-state-change-icon(:state="state")
      span.status(data-testid="dealStateChangeRowState") {{ Deal.$tEnum('state', state) }}
      span.info(data-testid="dealStateChangeRowInfo") {{ infoText }}
      template(v-if="showParent")
        app-button.deal-link(
          theme="link",
          :to="{ name: 'supplierDeal', params: { id: supplierId, dealId: parentId } }",
          data-testid="dealStateChangeRowParentLink"
        )
          i.icon.fal.fa-paste
          span.deal {{ $t('.deal.label', { id: parentId }) }}
      template(v-if="showAmendment")
        app-button.deal-link(
          theme="link",
          :to="{ name: 'supplierDeal', params: { id: supplierId, dealId: amendmentId } }",
          data-testid="dealStateChangeRowAmendmentLink"
        )
          i.icon.fal.fa-paste
          span.deal {{ $t('.deal.label', { id: amendmentId }) }}

    .bottom.flex(
      v-if="!isLast",
      data-testid="dealStateChangeRowBottom"
    )
      span.description(
        v-if="dealStateChange.description",
        data-testid="dealStateChangeRowDescription"
      ) {{ dealStateChange.description }}

</template>


<script>

// Models
import models from "@/models"

// Components
import DealStateChangeIcon from "./deal-state-change-icon"

const { Deal } = models

export default {
  components: {
    DealStateChangeIcon
  },

  props: {
    dealStateChange: { type: Object, required: true },
    deal:            { type: Object, required: true },
    isLast:          { type: Boolean, default: false }
  },

  data() {
    return {
      i18nScope: "suppliers.deals.information.show.deal-state-change-row",

      supplierId: this.$params.asInteger(this.$route.params.id),

      Deal
    }
  },

  computed: {
    state() {
      return _.get(this.dealStateChange, "to")
    },

    timestamp() {
      return this.$standardTime(_.get(this.dealStateChange, "createdAt"))
    },

    author() {
      return _.get(this.dealStateChange, "author")
    },

    parentId() {
      return _.get(this.deal, "parentId")
    },

    showParent() {
      return this.state === "draft" && _.present(this.parentId)
    },

    amendmentId() {
      return _.get(this.deal, "amendmentId")
    },

    showAmendment() {
      return this.state === "amended" && _.present(this.amendmentId)
    },

    infoText() {
      let timestampText = _.present(this.timestamp) ? this.$t(".info.createdAt", { timestamp: this.timestamp }) : ""
      let authorText = _.present(this.author) ? this.$t(".info.author", { author: this.author }) : ""
      let amendmentPrefix = ""
      if (this.showParent) amendmentPrefix = this.$t(".info.parent")
      else if (this.showAmendment) amendmentPrefix = this.$t(".info.amendment")

      return `${timestampText}${authorText}${amendmentPrefix}`
    }
  }
}

</script>

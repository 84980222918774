<style scoped lang="scss">

.actions {
  margin-bottom: 8px;
}

.icon {
  font-size: 16px;
  margin-right: 8px;
}

.info {
  ::v-deep .movida-common__info-label-label {
    font-family: $secondary-font;
    font-weight: 500;
  }
}

.info {
  margin: 24px 24px 0 0;
}

.amendment-label {
  margin-bottom: 4px;

  .icon,
  .from {
    color: $dark-gray;
  }

  .from {
    font-weight: normal;
    margin-right: 4px;
  }
}

.section + .section {
  margin-top: 24px;
  padding-top: 24px;
  border-top: 1px solid $light-gray-3;

  &.history-section {
    padding-top: 0;
  }
}

.title {
  font-family: $secondary-font;
  font-weight: 500;
  font-size: 20px;
  margin-bottom: 24px;
}

.row + .row {
  padding-top: 24px;
}

.info-section {
  margin-top: -24px;
}

.error {
  margin-top: 4px;
}

</style>


<template lang="pug">

  .supplier-deal-info
    loading-lines(v-if="fetching", :lines="4")
    template(v-else)
      template(v-if="deal.isDraft")
        .actions.flex.end
          app-button.btn(
            v-if="$can('update', deal)",
            theme="neutral",
            size="small",
            :to="{ name: 'editSupplierDeal', params: { id, dealId }}",
            data-testid="editSupplierDeal"
          )
            i.fal.fa-pencil.icon
            span {{ $t('.btn.edit') }}

      section.section.info-section
        .flex.wrap
          .startAt(data-testid="startAt")
            info-label.info(
              :label="$t('models.deal.attributes.startAt')",
              :value="formattedStartAt"
            )
            span.error.movida-common__error-message(v-if="hasStartAtError") {{ startAtError }}

          .duration(data-testid="duration")
            info-label.info(
              :label="$t('models.deal.attributes.duration')",
              :value="formattedDuration",
              :empty-text="$t('unregistered.feminine')"
            )
            span.error.movida-common__error-message(v-if="hasDurationError") {{ durationError }}

          info-label.info(
            :label="$t('models.deal.attributes.mechanicLaborPrice')",
            :value="formattedMechanicLaborPrice",
            :empty-text="$t('unregistered.feminine')",
            data-testid="mechanicLaborPrice"
          )

          template(v-if="deal.isOngoing")
            info-label.info(
              :label="$t('.status.ongoing.validUntil', { date: formattedExpirationDate })",
              :value="$t('.status.ongoing.expiresIn', { count: expirationMonthsDifference })",
              data-testid="expirationData"
            )

      section.section.history-section
        deal-state-history(
          :deal="deal",
          data-testid="dealStateHistory"
        )

</template>


<script>

// Extends
import View from "@/views/view"

// Components
import DealStateHistory from "./show/deal-state-history"

export default {
  name: "SupplierDealInformation",

  components: {
    DealStateHistory
  },

  extends: View,

  props: {
    deal:     { type: Object, default: () => ({}) },
    fetching: { type: Boolean, default: false },
    errors:   { type: Object, default: () => ({}) }
  },

  data() {
    return {
      i18nScope: "suppliers.deals.information.show",

      id:     this.$params.asInteger(this.$route.params.id),
      dealId: this.$params.asInteger(this.$route.params.dealId)
    }
  },

  computed: {
    startAtError() {
      return _.get(this.errors, "startAt[0]")
    },

    hasStartAtError() {
      return _.present(this.durationError)
    },

    durationError() {
      return _.get(this.errors, "duration[0]")
    },

    hasDurationError() {
      return _.present(this.durationError)
    },

    startAt() {
      if (_.blank(this.deal.startAt)) return null
      return moment(this.deal.startAt, "YYYY-MM-DD")
    },

    formattedStartAt() {
      if (_.blank(this.deal.startAt)) return null
      return this.startAt.format("L")
    },

    formattedExpirationDate() {
      if (!this.deal.hasExpirationData) return null
      return this.deal.expirationDate.format("LL")
    },

    expirationMonthsDifference() {
      if (!this.deal.hasExpirationData) return null
      return this.deal.expirationDate.diff(moment(), "months")
    },

    formattedMechanicLaborPrice() {
      if (_.blank(this.deal.mechanicLaborPrice)) return null
      return this.$asCurrency(this.deal.mechanicLaborPrice)
    },

    formattedDuration() {
      if (_.blank(this.deal.duration)) return null
      return this.$t("models.deal.formattedAttributes.duration", { count: this.deal.duration })
    }
  }
}

</script>

export default {
  props: {
    hideCreateOption: { type: Boolean, default: false }
  },

  data() {
    return {
    }
  },

  computed: {
    empty() {
      let options = this.options.filter(option => option.id !== "__create")

      return options.length === 0
    },

    createOption() {
      return { id: "__create", label: this.$t(".create", { search: this.search }) }
    }


  },

  methods: {
    onSelectCreate() {
      throw new Error("Vue mixin select/create - #onSelectCreate() - Not implemented yet")
    },

    hasSearchTextAsOption(options) {
      if (_.blank(this.search)) return false

      for (const option of options) {
        if (option.id === "__create") continue

        const formattedOption = option.label.toLowerCase()
        const formattedSearch = this.search.toLowerCase()

        if (formattedOption === formattedSearch) return true
      }

      return false
    },

    onClose() {
      this.options = []
    },

    onSelectGuard(option) {
      if (option && option.id === "__create") {
        this.onSelectCreate()
        return false
      }

      return true
    }
  }
}

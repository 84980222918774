<!--
# AttachCard

Cartão para listagem de anexos, contendo ações de visualização, edição, remoção.
Também é capaz de exibir status de upload.
-->

<style lang="scss" scoped>
.attach-card {
  --fold-color: #{$gray-2};
  --fold-bg-color: #{$light-gray-3};
  --card-height: 140px;

  border-radius: 8px 24px 8px 8px;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.1);
  font-family: $secondary-font;
  height: var(--card-height);
  overflow: hidden;
  width: 212px;

  &.loading {
    border-top-right-radius: 8px;
  }
}

.fold {
  background: var(--fold-bg-color);
  height: 24px;

  &::after {
    background: var(--fold-color);
    border-radius: 0 24px 0 8px;
    content: ' ';
    display: block;
    height: 24px;
    margin-left: auto;
    width: 24px;
  }

  .loading > & {
    display: none;
  }
}

.header {
  background: var(--fold-bg-color);
  padding: 0 16px 16px 16px;

  .loading & {
    display: none;
  }
}

.type-badge {
  align-items: center;
  background: white;
  border-radius: 4px;
  display: flex;
  height: 32px;
  padding: 0 8px;
  width: max-content;

  .icon {
    margin-right: 8px;
  }

  .label {
    color: $gray-3;
    font-size: 12px;
    font-weight: 400;
  }
}

.body {
  background: $light-gray-2;
  padding: 8px 16px 16px 16px;
  transition: transform ease-in-out 200ms;
  transform: translateY(0);
  width: 100%;

  > :not(:last-child) {
    margin-bottom: 8px;
  }

  .attach-name {
    display: -webkit-box;
    font-size: 14px;
    font-weight: 400;
    height: 32px;
    line-height: 16px;
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-all;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;

    .label {
      color: $dark-gray;
      transition: color 100ms;

      &:not(.disabled):hover {
        color: $primary;
      }

      &.disabled {
        cursor: default;
        pointer-events: none;
      }
    }
  }

  .content-length {
    color: $gray-3;
    font-size: 12px;
    font-weight: 400;
    line-height: 14px;
  }

  .attach-card:hover & {
    transform: translateY(-48px);
  }

  .attach-card.loading & {
    background: $light-gray;
    height: var(--card-height);
    padding-top: 16px;
    transform: unset;
  }
}

.progress {
  margin: 10px 0;
}

.actions {
  display: flex;
  gap: 8px;
  height: 32px;
}

.action {
  align-items: center;
  background: white;
  border: none;
  border-radius: 16px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
  color: $dark-gray;
  cursor: pointer;
  display: flex;
  font-size: 16px;
  height: 32px;
  justify-content: center;
  transition: box-shadow 200ms, color 100ms;
  width: 32px;

  &:hover {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
    color: $dark-primary-2;
  }
}
</style>

<template lang="pug">
  .attach-card(:class="classes", :style="style")
    .fold
    .content
      .header
        .type-badge
          attach-icon.icon(:icon="fileCategory")
          span.label {{ typeLabel }}
      .body
        .attach-name
          a.label(
            :class="{ disabled: disableView || isLoading }"
            @click.prevent="onView"
          )
            | {{ fileName }}
        .content-length
          span(v-if="isLoading") {{ progressSize }} / {{ fileSize }}
          span(v-else) {{ fileSize }}
          progress-bar.progress(
            v-if="isLoading"
            :indeterminate="isProgressIndeterminate"
            :value="progressRate"
          )
        .actions
          button.action(v-if="canDownload", @click="onDownload")
            i.icon.fas.fa-download
          button.action(v-if="canEdit", @click="onEdit")
            i.icon.fas.fa-pencil
          button.action(v-if="canDelete", @click="onDelete")
            i.icon.fas.fa-trash-can
          button.action(v-if="canAbort", @click="onAbort")
            i.icon.fas.fa-trash-can
</template>

<script>
import BaseAttachment from "./base-attachment"
import models from "@/models"

const { Attachment } = models

const COLORS = {
  audio:        ["#F2E5F7", "#C291D3"],
  compressed:   ["#DFDCE7", "#7D61CE"],
  doc:          ["#EAEAEA", "#959595"],
  email:        ["#EAEAEA", "#959595"],
  file:         ["#EAEAEA", "#959595"],
  html:         ["#EAEAEA", "#959595"],
  image:        ["#E7E1DC", "#CE9C61"],
  pdf:          ["#E7DCDC", "#AD6767"],
  presentation: ["#E7DCDC", "#AD6767"],
  spreadsheet:  ["#E3E7DC", "#93AD67"],
  text:         ["#EAEAEA", "#959595"],
  video:        ["#DCDEE7", "#677BAD"]
}

export default {
  name: "AttachCard",

  extends: BaseAttachment,

  computed: {
    classes() {
      return {
        loading: this.isLoading
      }
    },

    style() {
      const [bg, fold] = COLORS[this.fileCategory] || COLORS.file

      return {
        "--fold-bg-color": bg,
        "--fold-color":    fold
      }
    },
    typeLabel() {
      return Attachment.$tEnum("fileType", this.fileCategory)
    }
  }
}
</script>

<style scoped lang="scss">

.form {
  .body {
    padding: 16px 24px;
  }

  .footer {
    padding: 16px 24px;
    border-top: 1px solid $light-gray-3;
  }
}

</style>


<template lang="pug">

  .new-brand
    form.form(ref="form", method="post", @submit.prevent="submit")
      .body
        ui-row.fields
          ui-col(:span="12")

            input-field(
              name="brand[name]",
              :loading="submitting",
              v-model="resource.name",
              :errors="errors.name"
            )

      .footer.flex.space-between.vertical-center
        app-button(@click="() => $emit('cancel')", type="button", outline, :loading="submitting")
          span {{ $t('btn.cancel') }}

        app-button(type="submit", :loading="submitting", data-test="submitButton")
          span {{ $t('btn.add.label') }}
          span(slot="loading") {{ $t('btn.add.loading') }}

</template>


<script>

// Mixins
import { mixins } from "movida-common.vue"

// Libs
import HttpError from "@/lib/http-error"

// Models
import models from "@/models"

const { FormMixin } = mixins
const { Brand } = models

export default {
  mixins: [FormMixin],

  props: {
    params: { type: Object, default: () => ({}) }
  },

  data() {
    return {
      i18nScope: "products.components.brand-select-field.new",

      resource: null
    }
  },

  created() {
    this.resource = new Brand(this.params)
  },

  methods: {
    // @override Form mixin
    submitRequest() {
      return this.$sdk.brands.create({ params: this.resource.$serialize() })
    },

    // @override Form mixin
    submitSuccess(response) {
      this.$notifications.info(this.$t(".notifications.submit.success", { name: this.resource.name }))

      let resource = new Brand(response.data)
      this.$emit("create", resource)
    },

    // @override Form mixin
    submitError(err) {
      this.$err.log(err)
      this.$notifications.error(this.$t(".notifications.submit.error"))

      let originalError = err instanceof HttpError ? err.original.originalError : err.originalError

      let errData = _.get(originalError, "response.data.errors") || {}
      let errors = this.$i18n.errify(errData, { model: "brand" })
      this.errors = _.camelizeKeys(errors)
    }
  }
}

</script>

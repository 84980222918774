<style scoped lang="scss">

.footer {
  height: 40px;
  border-top: 1px solid $light-gray-3;
  background: $light-gray;

  .row {
    flex-grow: 1;
    padding: 0 16px;
    text-align: right;
    font-size: 14px;
    font-weight: normal;
    color: $dark-gray;
    height: 100%;
  }

  .total {
    width: 150px;
    border-left: 1px solid $light-gray-3;
    padding: 0 16px;
    font-size: 14px;
    font-weight: normal;
    color: $dark-gray;
    text-align: right;
    height: 100%;
  }
}

.subtotal-loading {
  width: 100px;
}

::v-deep .product-table {
  td {
    padding-top: 8px;
    padding-bottom: 8px;
  }

  #table-header-product-price,
  #table-header-product-quantity,
  #table-header-product-total
   {
    text-align: right;
  }
}

</style>


<template lang="pug">

  .products-table
    app-table.product-table(
      :headers="headers",
      :loading="fetching"
    )
      product-row.product-row(
        v-for="product in products",
        :key="`product-row-${product.id}`",
        :product="product"
      )

      .footer.flex.vertical-center.space-between(slot="footer")
        span.row.flex.vertical-center.end {{ $t('.subtotal') }}
        .total.flex.vertical-center.end
          loading-lines.subtotal-loading(v-if="fetching", :min="100", :max="100")
          span(v-else) {{ $l('currency', total) }}

</template>


<script>

import ProductRow from "./product-row"

export default {
  components: {
    ProductRow
  },

  props: {
    products: { type: Array, default: () => [] },
    fetching: { type: Boolean, default: false }
  },

  data() {
    return {
      i18nScope: "stores.tickets.service-orders.show.products-table"
    }
  },

  computed: {
    total() {
      return this.products.reduce((total, product) => (
        product.freeOfCharge ? total : (total + product.price * product.quantity)),
      0)
    },

    headers() {
      return [
        this.$t(`.header.code`),
        this.$t(`.header.type`),
        this.$t(`.header.name`),
        this.$t(`.header.partOrigin`),
        { name: this.$t(`.header.quantity`), id: "table-header-product-quantity" },
        { name: this.$t(`.header.price`), id: "table-header-product-price" },
        { name: this.$t(`.header.total`), id: "table-header-product-total" }
      ]
    }
  }
}

</script>


import { render, staticRenderFns } from "./supplier-info-card.vue?vue&type=template&id=16d48c80&scoped=true&lang=pug&"
import script from "./supplier-info-card.vue?vue&type=script&lang=js&"
export * from "./supplier-info-card.vue?vue&type=script&lang=js&"
import style0 from "./supplier-info-card.vue?vue&type=style&index=0&id=16d48c80&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "16d48c80",
  null
  
)

export default component.exports
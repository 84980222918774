import Base from "./base"

class Vehicle extends Base {
  static get attrs() {
    return _.uniq([
      ...super.attrs,
      "chassisId",
      "currentStoreId",
      "fuelLevel",
      "licensePlate",
      "year",
      "km",
      "purchasedAt",
      "colorName",
      "fipeId",
      "currentStoreRac",
      "state",

      "lastMovementAt",
      "fullModelName"  // TODO: reformar, trazendo as partes em separado
    ])
  }

  static get relations() {
    return {
      vehicleModel: { type: "belongsTo", model: "VehicleModel" },

      currentStore:     { type: "belongsTo", model: "Store" },
      lastServiceOrder: { type: "belongsTo", model: "VehicleServiceOrder" }
    }
  }

  static get enums() {
    return {
      state: {
        setup:                  "setup",
        filling_up:             "filling_up",
        washing:                "washing",
        ready_for_rental:       "ready_for_rental",
        rented:                 "rented",
        transferring:           "transferring",
        preventive_maintenance: "preventive_maintenance",
        corrective_maintenance: "corrective_maintenance",
        damaged:                "damaged",
        operational_problems:   "operational_problems",
        withdrawal:             "withdrawal",
        preparation:            "preparation",
        for_sale:               "for_sale",
        dealership_delivered:   "dealership_delivered",
        operational:            "operational",
        reserved:               "reserved",
        reserved_for_customer:  "reserved_for_customer",
        backup:                 "backup",
        asset_loss:             "asset_loss",
        sold:                   "sold"
      }
    }
  }
}

export default Vehicle

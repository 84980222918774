<style scoped lang="scss">

.header {
  margin-bottom: 32px;
  padding-bottom: 16px;
  border-bottom: 1px solid $light-gray-3;
}

.title-icon {
  margin-right: 16px;
  font-size: 30px;
}

.title-text {
  font-size: 30px;
  font-weight: normal;
  font-weight: 500;
  font-family: $secondary-font;
}

.search {
  margin-bottom: 28px;
}

.paginator-content {
  padding: 14px;
  margin: 0 auto;
  &.loading {
    max-width: 400px;
  }
}

.list-info {
  padding: 0 4px;
}

.selected-info {
  border: 1px solid $light-purple-2;
  padding: 8px 16px;
  border-radius: 4px;
  margin-bottom: 16px;
}

.empty-selected {
  color: $gray-2;
  font-style: italic;
}

.list-header {
  padding: 8px;
}

.secondary-button + .secondary-button {
  margin-left: 8px;
}

.secondary-button {
  color: $light-gray-4;
  font-weight: normal;

  &.active {
    color: $dark-gray;
  }

  .secondary-text {
    font-size: 14px;
  }
}

.icon-container {
  width: 12px;
  height: 12px;
  margin-right: 12px;

  .icon {
    font-size: 16px;
  }
}


</style>


<template lang="pug">

  .vehicle-models
    search-form.search(
      :params="params",
      @search="search",
      :loading="fetching"
    )

    app-list-header.list-info(
      v-if="showAppListHeader"

      :has-filters="hasFilters",
      :page="params.page",
      :pagination="pagination",
      :loading="fetching",

      @clear="clearFilters"
    )

    app-list(
      :collection="resource",
      :loading="fetching",
      :empty="empty",
      :empty-text="$t('.empty')",
      :error-text="$t('.error')",
      :error="hasFetchError"
    )
      template(slot="loadingRow")
        mobiauto-version-row-loading(
          v-for="version in 4",
          :key="`mobiauto-version-row-loading-${version}`"
        )

      vehicle-models-select-list-row-fields(
        v-for="(vehicleModel, index) in resource",
        :key="`mobiauto-version-${vehicleModel.id}-${index}`",
        :vehicle-model="vehicleModel",
        :has-checkbox="hasCheckbox"
        data-testid="vehicleModelRow"
      )

    .paginator-content(v-if="fetching || showPaginator", :class="{ loading: fetching }")
      paginator(
        :loading="fetching",
        :per-page="pagination.perPage",
        :total="pagination.total",
        @input="onChangePage",
        :value="params.page",
        data-test="paginator"
      )

</template>


<script>

import { mixins } from "movida-common.vue"

// Components
import SearchForm from "@/views/vehicle-models/index/search-form"

// Models
import models from "@/models"
const { VehicleModel } = models

const { Fetchable, Paginatable, UrlQuery } = mixins

export default {
  name: "VehicleModelsList",

  components: {
    SearchForm
  },

  mixins: [Fetchable, Paginatable, UrlQuery],

  props: {
    value:       { type: Array, default: () => [] },
    hasCheckbox: { type: Boolean, default: true }
  },

  data() {
    return {
      i18nScope: "components.vehicle-models-list",

      id: this.$route.params.id,

      // Fetchable mixin data
      resource: [],

      updateQueryWithReplace: true,

      model:     null,
      autofetch: false,
      fetching:  false,

      // Query Mixin data
      parseQueryParams: {
        q:               "string",
        manufacturer_id: "integer",
        category:        "string",
        starting_year:   "integer",
        group_id:        "integer",
        page:            "integer"
      }
    }
  },

  computed: {
    showPaginator() {
      if (!this.pagination) return false
      return this.pagination.total > this.pagination.perPage
    },

    showAppListHeader() {
      return this.fetching || this.hasFilters || !this.empty
    },

    empty() {
      return _.blank(this.resource)
    },

    hasFilters() {
      const filterList = Object.keys(_.omit(this.parseQueryParams, "page"))
      const currentParams = _.pick(this.$route.query, filterList)

      return _.present(currentParams)
    }
  },

  watch: {
    $route(to, from) {
      this.parseAndFetch()
    }
  },

  created() {
    // TODO Extrair para Query mixin e entender
    // como executar antes do _fetch()
    this.parseAndFetch()
  },

  methods: {
    clearFilters() {
      this.$router.push({ name: this.routeName })
    },

    parseAndFetch() {
      this.parseRoute()
      this._fetch()
    },

    updateOrFetch() {
      this.updateUrlQuery()
      if (!this.queryChanged) {
        this._fetch()
      }
    },

    onChangePage(page) {
      this.params.page = page
      this.updateOrFetch()
    },

    search() {
      // XXX Ao realizar uma nova busca, precisamos voltar para a primeira página
      // XXX passando undefined para causar detecção no Vue
      this.params.page = undefined
      this.updateOrFetch()
    },

    fetch() {
      return this.$sdk.suppliers.listVehicleModels({
        id:     this.id,
        params: {
          ...this.httpParams,
          ids: this.value
        }
      })
    },

    // @override Fetchable mixin
    onFetchSuccess(response) {
      this.setPagination(response.headers)

      this.resource = this.resource.map(data => VehicleModel.build(data))
    },

    // @override Fetchable mixin
    onFetchError(err) {
      this.$err.log(err)
      this.$notifications.error(this.$t(".notifications.fetch.failure"))
    }
  }
}

</script>

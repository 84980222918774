<style lang="scss" scoped>

.store-tickets-header {
  .header {
    margin-bottom: 32px;

    .title-text {
      font-size: 30px;
      font-weight: normal;
      font-weight: 500;
      font-family: $secondary-font;
    }
  }
}

</style>


<template lang="pug">

  .store-tickets-header
    header.header
      .title.flex.vertical-center
        h1.title-text {{ $t('.title') }}
      .g-tab-navbar-content
        router-tab(
          :to="{ name: 'storeTickets', params: { id: this.storeId } }",
        ) {{ $t('.tab.tickets') }}

        router-tab(
          :to="{ name: 'storeServiceOrders', params: { id: this.storeId } }",
        ) {{ $t('.tab.serviceOrders') }}

</template>


<script>

export default {
  data() {
    return {
      i18nScope: "stores.components.store-tickets-header",

      storeId: this.$params.asInteger(this.$route.params.id)
    }
  }
}

</script>

<style lang="scss" scoped>
.info-icon {
  cursor: help;
  font-size: 12px;

  :disabled & {
    visibility: hidden;
  }
}

.tooltip-container {
  color: $dark-gray;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5;
  padding: 16px;

  .heading {
    display: flex;
    gap: 8px;
  }
}


.title {
  flex-grow: 1;
  font-family: $secondary-font;
  font-weight: 500;
}

.date {
  font-family: $primary-font;
}

.description {
  font-family: $primary-font;
  font-size: 12px;
}

.filters-container {
  background: $light-gray-2;
  border-radius: 8px;
  margin-top: 16px;
  padding: 8px;

  .heading {
    font-family: $secondary-font;
    font-size: 12px;
  }
}

.filters {
  list-style: none;
  margin: 0;
  padding: 0;
}

.filter-name {
  color: $gray-3;
  font-family: $secondary-font;
}

.filter-desc {
  font-family: $primary-font;
}
</style>

<template lang="pug">
  v-popover.quick-filter-tooltip(trigger="hover")
    template
      i.info-icon.far.fa-info-circle
    template(#popover)
      .tooltip-container
        .heading
          span.title {{ title }}
          span.date(v-if="date") {{ date }}
        .description {{ description }}
        .filters-container
          span.heading {{ $t(".filters-heading") }}:
          ul.filters
            li(v-for="(desc, name) of filters" :key="name")
              span.filter-name {{ name }}:&nbsp;
              span.filter-desc {{ desc }}
</template>

<script>
export default {
  props: {
    date:        { type: String, default: null },
    description: { type: String, default: null },
    filters:     { type: Object, required: true },
    title:       { type: String, required: true }
  },

  data() {
    return {
      i18nScope: "monitoring.quick-filters"
    }
  }
}
</script>

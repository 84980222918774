<style scoped lang="scss">

.loading-lines {
  padding-top: 14px;
}

</style>


<template lang="pug">

  .ticket
    template(v-if="fetching")
      loading-lines(:lines="4")

    template(v-else)
      component(
        :is="componentType",
        :ticket="ticket",
        :store-id="storeId"
      )

</template>


<script>

// Mixins
import FetchMixin from "@/mixins/fetch-mixin"

// View
import LoadedView from "@/views/loaded-view"

// Local Components
import InternalMaintenance from "./show/internal-maintenance"
import ExternalMaintenance from "./show/external-maintenance"
import Repair from "./show/repair"

// Models
import models from "@/models"
const { VehicleModel } = models

export default {
  name: "StoreTicket",

  components: {
    InternalMaintenance,
    ExternalMaintenance,
    Repair
  },

  extends: LoadedView,

  mixins: [FetchMixin],

  data() {
    return {
      i18nScope: "stores.tickets.show",
      routeName: "storeTicket",

      storeId:  null,
      ticketId: null,

      resource: {}
    }
  },

  computed: {
    ticket: {
      get() { return this.resource },

      set(value) { this.resource = value }
    },

    allowedComponents() {
      return {
        "Tickets::InternalMaintenance": InternalMaintenance,
        "Tickets::Maintenance":         ExternalMaintenance,
        "Tickets::Repair":              Repair
      }
    },

    componentType() {
      const { type } = this.resource
      return this.allowedComponents[type]
    }
  },

  methods: {
    parseRoute() {
      this.storeId = this.$params.asInteger(this.$route.params.id)
      this.ticketId = this.$params.asInteger(this.$route.params.ticketId)
    },

    // @override Fetch Mixin
    fetchRequest() {
      return this.$newSdk.stores.tickets.find({
        storeId:  this.storeId,
        ticketId: this.ticketId
      })
    },

    // @override Fetchable mixin
    onFetchSuccess({ data }) {
      const ticketModels = {
        "Tickets::InternalMaintenance": "TicketInternalMaintenance",
        "Tickets::Maintenance":         "TicketExternalMaintenance",
        "Tickets::Repair":              "TicketRepair"
      }
      const modelName = ticketModels[data.type]

      const { vehicleModel } = data.vehicle
      data.vehicle.vehicleModel = VehicleModel.build(vehicleModel)

      this.resource = new models[modelName](data)
    }
  }
}

</script>

/**
 * Singleton que contém os dados de índices de velocidade de pneu
 *
 * A propridade `data` (getter) retorna todos os dados.
 *
 * Existem métodos de apoio, como `keys` e `values` - para ficar próximo a um Object -
 * além do `asEnum()`, para uso como `enum` em model.js.
 *
 * E também expõe metadados com métodos próprios, como o `kmhFor(key)`.
 * ```js
 * let index = 'K'
 * speedLimitInKmh = SpeedRatings.kmhFor(index)
 * // => 110
 * ````
 *
 * @type {Object}
 */
const SpeedRatings = {
  get data() {
    return {
      G: { code: "G", kmh: 90 },
      J: { code: "J", kmh: 100 },
      K: { code: "K", kmh: 110 },
      L: { code: "L", kmh: 120 },
      M: { code: "M", kmh: 130 },
      N: { code: "N", kmh: 140 },
      P: { code: "P", kmh: 150 },
      Q: { code: "Q", kmh: 160 },
      R: { code: "R", kmh: 170 },
      S: { code: "S", kmh: 180 },
      T: { code: "T", kmh: 190 },
      U: { code: "U", kmh: 200 },
      H: { code: "H", kmh: 210 },
      V: { code: "V", kmh: 240 },
      W: { code: "W", kmh: 270 },
      Y: { code: "Y", kmh: 300 }
      // ZR: { code: 'ZR', kmh: 240 }  // 240+
    }
  },

  get keys() {
    return Object.keys(this.data)
  },

  get values() {
    return _.map(this.data, "code")
  },

  asEnum() {
    return _.reduce(this.data, (result, item, key) => {
      result[key] = item.code
      return result
    }, {})
  },

  kmhFor(key) {
    return _.get(this.data[key], "kmh")
  }
  // findBy..()
}

export default SpeedRatings

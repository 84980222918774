<style scoped lang="scss">

.vehicle-issue-empty {
  text-align: center;
  font-size: 14px;
  padding: 20px 0;
}

.vehicle-issue-row {
  border: 1px solid $light-gray-3;
  border-bottom: none;
  padding: 8px 24px;
  font-size: 16px;
  font-weight: normal;

  + .vehicle-issue-row {
    border-top: 1px solid $light-gray-3;
  }

  &:first-child {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }

  &:last-child {
    border-bottom: 1px solid $light-gray-3;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
  }
}

.name {
  display: block;
}

.description {
  display: block;
  font-size: 12px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 300;
}

::v-deep .vehicle-issue-row.disabled {
  background-color: $light-gray-3;
}

</style>


<template lang="pug">

  .vehicle-issues-list
    loading-lines(v-if="loading", :lines="4")
    template(v-else-if="!hasVehicleIssues")
      .vehicle-issue-empty.global-empty-text.global-empty-color.text.center
        label {{ emptyText }}
    template(v-else)
      vehicle-issue-row(
        v-for="vehicleIssue in vehicleIssues",
        :key="`vehicle-issue-${vehicleIssue.id}`",
        :vehicle-issue="vehicleIssue",
        :class="{ disabled }",
        :data-testid="`vehicleIssue-${vehicleIssue.id}`"
      )
        template(slot="actions")
          .actions-column(v-if="destroyable && !disabled")
            app-button(theme="neutral", size="small", @click="$emit('destroy', vehicleIssue)")
              i.fal.fa-trash-alt

</template>


<script>

import VehicleIssueRow from "./vehicle-issue-row"

export default {
  components: {
    VehicleIssueRow
  },

  props: {
    loading:       { type: Boolean, default: false },
    destroyable:   { type: Boolean, default: false },
    disabled:      { type: Boolean, default: false },
    vehicleIssues: { type: Array, default: () => [] },
    emptyText:     { type: String, default: null }
  },

  data() {
    return {
      i18nScope: "services.components.vehicle-issues-list"
    }
  },

  computed: {
    hasVehicleIssues() {
      return _.present(this.vehicleIssues)
    }
  }
}

</script>

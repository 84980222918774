<style lang="scss" scoped>
.service-order-toolbar {
  display: flex;
  gap: 16px;
  list-style: none;
  margin: 0;
  padding: 0;
}


.action {
  min-width: unset;
  width: 40px;
}
</style>

<template lang="pug">
  ul.service-order-toolbar
    li(
      v-for="{ icon, name, theme, ...action } in actions"
      :key="name"
    )
      app-button.action(
        v-tooltip="$t(`.actions.${name}.tooltip`)"
        :disabled="isDisabled(action)"
        :name="name"
        outline
        :theme="theme"
        @click="() => $emit(name)"
      )
        i.icon.fas(:class="icon")
</template>

<script>
import models from "@/models"

const { MovidaServiceOrder } = models

export default {
  props: {
    disabled:     { type: Boolean, default: false },
    serviceOrder: { type: MovidaServiceOrder, required: true }
  },

  emits: [
    "cancel",
    "create-vehicle-receipt",
    "edit-quote",
    "execute",
    "postpone",
    "reopen",
    "resend-approval-notification",
    "show-messages",
    "show-ticket-messages",
    "withdraw-vehicle"
  ],

  data() {
    return {
      i18nScope: "monitoring.service-orders.service-order-toolbar"
    }
  },

  computed: {
    actions() {
      /**
       * Descrição das ações disponíveis
       *
       * name: Chave usada para o nome do evento e texto do tooltip.
       * icon: Ícone a ser exibido no botão.
       * stages: Estágios que o botão estará habilitado.
       * domains: Domínios que o botão estará habilitado. Se ausente, disponível para todos os domínios.
       * theme: Tema (cor) do botão.
       */
      return [
        {
          name:   "show-messages",
          icon:   "fa-message-pen",
          stages: [
            "open",
            "vehicleStopped",
            "quoteIssuedStopped",
            "quoteIssuedNotStopped",
            "ongoingNotStopped",
            "ongoingStopped",
            "executed",
            "vehicleWithdrawed",
            "canceled"
          ],
          theme: "primary"
        },
        {
          name:   "create-vehicle-receipt",
          icon:   "fa-car-garage",
          stages: ["open", "quoteIssuedNotStopped", "ongoingNotStopped"],
          theme:  "primary"
        },
        {
          name:   "edit-quote",
          icon:   "fa-memo",
          stages: ["open", "vehicleStopped"],
          theme:  "primary"
        },
        {
          name:   "resend-approval-notification",
          icon:   "fa-envelope",
          stages: ["ongoingNotStopped", "ongoingStopped"],
          theme:  "primary"
        },
        {
          name:   "execute",
          icon:   "fa-check-square",
          stages: ["ongoingNotStopped", "ongoingStopped"],
          theme:  "primary"
        },
        {
          name:   "withdraw-vehicle",
          icon:   "fa-car-side",
          stages: ["executed"],
          theme:  "primary"
        },
        {
          name:   "postpone",
          icon:   "fa-clock",
          stages: ["ongoingNotStopped", "ongoingStopped"],
          theme:  "primary"
        },
        {
          name:   "cancel",
          icon:   "fa-file-xmark",
          stages: [
            "open",
            "vehicleStopped",
            "quoteIssuedStopped",
            "quoteIssuedNotStopped",
            "ongoingNotStopped",
            "ongoingStopped"
          ],
          theme: "gray"
        },
        {
          name:    "reopen",
          icon:    "fa-file-plus",
          stages:  ["canceled"],
          domains: ["gtf", "zkm"],
          theme:   "primary"
        },
        {
          name:   "show-ticket-messages",
          icon:   "fa-user-headset",
          stages: [
            "open",
            "vehicleStopped",
            "quoteIssuedStopped",
            "quoteIssuedNotStopped",
            "ongoingNotStopped",
            "ongoingStopped",
            "executed",
            "vehicleWithdrawed",
            "canceled"
          ],
          theme: "primary"
        }
      ]
    },
    stage() {
      const stages = {
        open: ({ quoteIssued, state, vehicleStopped }) => (
          state == "open" && !vehicleStopped && !quoteIssued
        ),
        vehicleStopped: ({ quoteIssued, state, vehicleStopped }) => (
          state == "open" && vehicleStopped && !quoteIssued
        ),
        quoteIssuedStopped: ({ quoteIssued, state, vehicleStopped }) => (
          state == "open" && vehicleStopped && quoteIssued
        ),
        quoteIssuedNotStopped: ({ quoteIssued, state, vehicleStopped }) => (
          state == "open" && !vehicleStopped && quoteIssued
        ),
        ongoingNotStopped: ({ state, vehicleStopped }) => (
          state == "ongoing" && !vehicleStopped
        ),
        ongoingStopped: ({ state, vehicleStopped }) => (
          state == "ongoing" && vehicleStopped
        ),
        executed: ({ state, vehicleWithdrawed }) => (
          state == "executed" && !vehicleWithdrawed
        ),
        vehicleWithdrawed: ({ state, vehicleWithdrawed }) => (
          state == "executed" && vehicleWithdrawed
        ),
        canceled: ({ state }) => state == "canceled"
      }

      return Object.keys(stages)
        .find((stage) => stages[stage](this.serviceOrder))
    }
  },

  methods: {
    isDisabled({ stages, domains }) {
      return this.disabled
        || !stages.includes(this.stage)
        || (domains && !domains.includes(this.serviceOrder.domain))
    }
  }
}
</script>

// Base
import BaseRequests from "./Base"

// Models
import models from "@/models"
const { SupplierServiceScoreSettings } = models

class SettingsRequests extends BaseRequests {
  static get selector() {
    return "settings"
  }

  getSupplierServiceScoreSettings({ params = {}, options = {} } = {}) {
    return this.action({
      method:  "get",
      url:     "/settings/supplier_service_score",
      params,
      options: {
        model: SupplierServiceScoreSettings,

        ...options
      }
    })
  }

  updateSupplierServiceScoreSettings({ params = {}, options = {} } = {}) {
    return this.action({
      method:  "patch",
      url:     "/settings/supplier_service_score",
      params,
      options: {
        model: SupplierServiceScoreSettings,

        ...options
      }
    })
  }
}

export default SettingsRequests

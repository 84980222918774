<style lang="scss" scoped>

.store-select-field {
  .error {
    display: block;
    text-align: center;
    padding: 4px 12px;
    font-size: 14px;
    font-style: italic;

    .link {
      margin-left: 5px;
      font-size: 14px;
      font-style: italic;
      font-weight: normal;
    }
  }

  .empty {
    display: block;
    text-align: center;
    padding: 4px 12px;
    font-size: 14px;
    font-style: italic;
  }

  .trading-name {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
    flex-grow: 1;
  }

  ::v-deep .listed-option + .listed-option {
    border-top: 1px solid $light-gray-3;
  }
}

</style>


<template lang="pug">

  .store-select-field
    select-field(
      :name="name",
      :label="label",
      :fetching="fetching",
      :disabled="disabled",
      :placeholder="placeholder",
      :optional="optional",

      option-as-value,
      :loading="loading || fetchingOption",
      :options="options",
      :default-filter="false",
      :errors="errors",
      searchable,

      :value="option",
      @input="onSelect",

      @search="onSearch",
      @open="_fetch",
      @close="onClose",
      @inputSearch="(newSearch) => search = newSearch"
    )
      template(#menuContentHeader)
        template(v-if="fetchError")
          label.error
            span {{ $t('.error.message') }}
            app-button.link(theme="link", @click="onSearch") {{ $t('.error.link') }}

      template(#menuContentFooter)
        template(v-if="!fetching && !fetchError && empty")
          label.empty {{ $t('.empty') }}

      template(#fetching)
        li.option {{ $t('.loading') }}

      template(#selected="{ props: { selected } }")
        app-span.trading-name(:value="selected.data.tradingName", crop)

      template(#option="{ props: { option, index, focus, active } }")
        supplier-option(
          :option="option",
          :active="active",
          :focus="focus",
        )

      template(#menuContentFooter)
        template(v-if="!fetching && !fetchError && empty")
          label.empty {{ $t('.empty') }}

</template>


<script>

// Mixins
import FieldMixin from "@/mixins/field"
import AsyncSelect from "@/mixins/select/async"

import models from "@/models"

export default {
  name: "SupplierSelectField",

  extends: AsyncSelect,

  mixins: [FieldMixin],

  data() {
    return {
      i18nScope: "components.supplier.supplier-select-field",

      model: "Supplier",

      fetchingOption: false,
      fetchError:     false
    }
  },

  methods: {
    // @overrides extends AsyncSelect
    dataToOption(data) {
      const formattedData = (_.present(this.model))
        ? new models[this.model](data) : data

      return {
        value: formattedData.id,
        data:  formattedData
      }
    },

    // @overrides extends AsyncSelect
    async fetchOption(id) {
      try {
        this.fetchingOption = true

        const response = await this.$sdk.suppliers.get({ id })
        const normalizedData = _.camelizeKeys(response.data)

        this.option = this.dataToOption(normalizedData)
      }
      catch (err) {
        this.$err.log(err)
      }
      finally {
        this.fetchingOption = false
      }
    },

    // @overrides extends AsyncSelect
    fetchRequest() {
      return this.$sdk.suppliers.list({ params: { q: this.search } })
    },

    // @overrides extends AsyncSelect
    onFetchSuccess(response, { normalizedData }) {
      const fetchedOptions = normalizedData.map(data => (
        this.dataToOption(data)
      ))

      this.options =  [...fetchedOptions]
    },

    // @overrides extends AsyncSelect
    onFetchError(error) {
      this.$err.log(error)
    }
  }
}

</script>

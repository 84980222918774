<style lang="scss" scoped>

.loading {
  width: 172px;
}

.app-button.action {
  color: $dark-warning;
  font-size: 14px;
}

.icon {
  margin-right: 8px;
}

// .modal-header {
//   color: $primary;
//   margin-top: 0;
//   padding: 24px;
//   border-bottom: 1px solid $light-gray-3;
// }

.heading-container {
  color: $dark-gray;

  .heading {
    font-size: 18px;
    font-weight: 500;
    height: 32px;
    line-height: 32px;
    font-family: $secondary-font;
  }

  .icon {
    font-size: 16px;
  }
}

.body {
  height: 100%;
  overflow: auto;

  .section + .section,
  .scheduled-block + .scheduled-block {
    border-top: 1px solid $light-gray-3;
  }

  .supplier-section,
  .scheduled-block {
    padding: 16px 24px;
  }
}

.schedule {
  height: 100%;
  overflow: auto;
}

.row + .row {
  margin-top: 16px;
}

.info:not(:last-of-type) {
  margin-right: 72px;
}

.destroy {
  font-size: 14px;
}

::v-deep .description .message {
  line-height: unset;
}

.message {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.action {
  overflow: hidden;
}

</style>


<template lang="pug">

  .supplier-block-schedule.flex
    template(v-if="fetching")
      loading-lines.loading(:min="100", :max="100")

    template(v-else)
      slot(name="link", :props="{ openUnblockModal: openModal }")
        app-button.app-button.action(
          v-if="hasScheduledBlocks",
          theme="link",
          @click="openModal"
        )
          i.icon.far.fa-times-circle
          span.message {{ $t('.message') }}

    .modal(v-if="showModal")
      app-modal(
        v-if="!showConfirmation",
        :width="1200",
        footer,
        :confirm-button="false",
        @close="closeModal"
      )
        template(slot="header")
          .header-asd
            .heading-container.flex.vertical-center
              i.icon.fas.fa-times-circle
              h1.heading {{ $t('.heading') }}

        .body.flex.column-direction
          section.section.supplier-section
            supplier-info-card.supplier-info-card(:resource="supplier")

          section.section.schedule
            .scheduled-block(
              v-for="(block, index) in schedule",
              :key="`scheduled-block-${index}-${block.startAt}`"
            )
              ui-row.row
                info-label.info(
                  :label="$t('models.supplierBlock.attributes.startAt')",
                  :value="$standardTime(block.startAt)"
                )
                info-label.info(
                  :label="$t('models.supplierBlock.attributes.endAt')",
                  :value="$standardTime(block.endAt)",
                  :empty-text="$t('unregistered.feminine')"
                )
                info-label.info(
                  :label="$t('models.supplierBlock.attributes.author')",
                  :value="block.author"
                )
              ui-row.row
                info-label.info.description(
                  :label="$t('models.supplierBlock.attributes.description')",
                  :value="block.description"
                )
              ui-row.row
                app-button(
                  v-if="$can('update', supplier)",
                  theme="link",
                  @click="confirmDestroy(block)"
                )
                  span.destroy {{ $t('.btn.destroy') }}

      app-modal(
        v-else,
        :close-button="!submitting",
        :width="920",
        :heading="$t('.confirmation.heading')",
        @close="closeModal"
      )
        .body
          span.text {{ $t('.confirmation.message', { startAt: $standardTime(selectedBlock.startAt) }) }}

        template(slot="footer")
          .footer
            app-button(
              @click="showConfirmation = false"
              outline,
              theme="gray",
              :loading="submitting"
            ) {{ $t('btn.cancel') }}

            app-button(
              @click="destroy(selectedBlock.id)",
              :loading="submitting"
            )
              span {{ $t('btn.destroy.label') }}
              span(slot="loading") {{ $t('btn.destroy.loading') }}

</template>


<script>

// Mixins
import { mixins } from "movida-common.vue"

const { Fetchable } = mixins

export default {
  name: "SupplierBlockSchedule",

  mixins: [Fetchable],

  props: {
    supplier: { type: Object, default: () => ({}) }
  },

  data() {
    return {
      i18nScope: "components.supplier.supplier-block-schedule",

      showModal:        false,
      showConfirmation: false,

      id: this.supplier.id,

      selectedBlock: null,

      resource: [],

      submitting: false,
      request:    null
    }
  },

  computed: {
    schedule() {
      if (this.fetching) return []

      return this.resource.filter(block => moment(block.startAt).isAfter(this.today)).reverse()
    },

    scheduledCount() {
      return this.schedule.length
    },

    hasScheduledBlocks() {
      return this.scheduledCount > 0
    }
  },

  methods: {
    fetch() {
      return this.$sdk.suppliers.listBlocks({ id: this.id })
    },

    // @override Fetchable mixin
    // onFetchSuccess(response) {},

    onFetchError(err) {
      this.$err.log(err)
      this.$notifications.error(this.$t(".notifications.fetch.failure"))
    },

    confirmDestroy(block) {
      this.selectedBlock = block
      this.showConfirmation = true
    },

    async destroy(blockId) {
      this.$notifications.clear()
      this.submitting = true

      let currentRequest

      try {
        if (this.request) this.request.cancel("Duplicated submit")
        currentRequest = this.submitRequest(blockId)
        this.request = currentRequest

        const response = await currentRequest

        this.submitSuccess(response)
      }
      catch (err) {
        if (!err.cancelled) {
          this.submitError(err)
        }
      }
      finally {
        if (this.request === currentRequest) {
          this.request = null
          this.submitting = false
        }
      }
    },

    submitRequest(blockId) {
      return this.$sdk.suppliers.destroyBlock({ id: this.id, blockId })
    },

    submitSuccess(response) {
      this.$emit("destroy")
      this.closeModal()
    },

    submitError(err) {
      this.$err.log(err)
    },

    openModal() {
      this.showModal = true
    },

    closeModal() {
      if (!this.submitting) {
        this.selectedBlock = null
        this.showModal = false
        this.showConfirmation = false
      }
    }
  }
}

</script>

<!--
  <doc lang="markdown">
    Header "fixo" para as listas em formato de tabela com ordenação, componente para ser utilizado em conjunto com
    service-vehicle-model-groups-table (`components/service-vehicle-model-groups/table.vue`)

    ## Props:

      loading:   [Boolean]: Informando se o carregamento foi concluido,
      sortParam: [String]:  Parâmetro de ordenação, ex: 'id'
      sortOrder: [String],  Sentido da ordenação, "asc", "desc"
      columnSizes: [String], Valores dos tamanhos da colunas utilizadas em `grid-template-columns`

    ## Slots:
      action: Slot na última coluna de ações

    Como utilizar:
    ```pug
      table($attrs)
        table-header(
          :loading="fetching",
          :columnSizes="columnSizes",
          :sortOrder="sortOrder",
          :sortParam="sortParam"
        )
    ```
  </doc>
-->


<style lang="scss" scoped>

.table-header {
  display: grid;
  grid-template-columns: var(--grid-columns);

  background-color: $light-gray;
  height: 40px;
  border-bottom: 1px solid $light-gray-3;
  border-top: 1px solid $light-gray-3;

  &.loading {
    border-bottom: 2px solid $light-orange-2;
  }

  .unsortable {
    padding: 0 12px;
  }

  .column {
    font-size: 12px;

    .icon {
      font-size: 16px;
      color: $gray-2;
    }

    .diamond-icon {
      font-size: 8px;
      font-weight: 900;
      color: $gray-3;
      width: 8px;
      height: 8px;
    }

    .text {
      font-family: $secondary-font;
      font-size: 14px;
      color: $dark-gray;
      font-weight: 400;
    }

    .loader-icon {
      font-size: 16px;
      color: $orange;
    }
  }
}

</style>


<template lang="pug">

  .table-header(:class="{ loading }" :style="styles")
    .column.flex.unsortable.vertical-center.center
      i.icon.fas.fa-wrench

    .column
      sortable-table-header-column(
        sort-param="service_name",
        :sort-order="sortOrder",
        :active="isSortedBy('service_name')",
        @sort="param => $emit('sort', param)"
      )
        span.text {{ $t('.columns.service.label') }}

    .column
      sortable-table-header-column(
        sort-param="group_name",
        :sort-order="sortOrder",
        :active="isSortedBy('group_name')",
        @sort="param => $emit('sort', param)"
      )
        span.text {{ $t('.columns.vehicle-model-group.label') }}

    .column.flex.unsortable.vertical-center
      i.icon.fas.fa-lightbulb(v-tooltip.bottom="{ offset: 4, content: $t('.tooltip.vehicle-issue-solutions.label') }")

    .column.flex.unsortable.vertical-center
      i.icon.fas.fa-fist-raised(v-tooltip.bottom="{ offset: 4, content: $t('.tooltip.labor-cost-linked.label') }")

    slot(name="actions")
      .column.flex.unsortable.vertical-center.center
        template(v-if="loading")
          i.loader-icon.fas.fa-spin.fa-spinner
        template(v-else)
          i.diamond-icon.fas.fa-diamond

</template>


<script>

export default {
  props: {
    loading:     { type: Boolean, default: false },
    sortParam:   { type: String, required: true },
    sortOrder:   { type: String, required: true },
    columnSizes: { type: String, required: true }
  },

  data() {
    return {
      i18nScope: "suppliers.service-supports.components.service-vehicle-model-groups.table-header"
    }
  },

  computed: {
    styles() {
      return {
        "--grid-columns": this.columnSizes
      }
    }
  },

  methods: {
    isSortedBy(param) {
      return this.sortParam === param
    }
  }
}

</script>

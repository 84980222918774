<style scoped lang="scss">

.body {
  min-height: 490px;
  height: 490px;

  .section {
    padding: 16px 32px;
    border-bottom: 1px solid $light-gray-3;

    .selected-option {
      padding-right: 0;
    }

    .global-price {
      margin-right: 24px;
      height: 40px;
      background-color: $light-gray-2;

      .icon {
        margin-left: 12px;
        margin-right: 16px;
        font-size: 20px;
        color: $gray-2;
      }

      .text {
        font-family: $secondary-font;
        font-size: 16px;
        color: $dark-gray;
        font-weight: normal;
      }
    }

    .option {
      border-bottom: 1px solid $light-gray-3;
    }

    .maintenance-package-select-field {
      ::v-deep .options-wrapper {
        max-height: 300px;
      }
    }
  }
}

</style>


<template lang="pug">

  app-modal(
    :width="996",
    :heading="$t('.title.label')",
    :confirm-label="$t('.btn.submit.label')",
    footer,
    @close="goBack",
    @confirm="submit"
  )
    .body
      section.section
        maintenance-package-select-field.field(
          ref="maintenancePackageSelectField",
          name="dealMaintenancePackage[maintenancePackage]",
          v-model="resource.maintenancePackageId",
          :label="$t('.fields.maintenancePackage.label')",
          :placeholder="$t('.fields.maintenancePackage.placeholder')",
          :loading="submitting",
          :errors="maintenancePackageErrors"
        )
          template(#option="{ props: { option, index, focus, active } }")
            maintenance-package-option.option(
              :option="option",
              :active="active",
              :focus="focus"
            )
          template(#selected="{ props: { selected } }")
            maintenance-package-option.selected-option(
              :option="selected",
              hide-action-column
            )

      section.section
        vehicle-model-group-select-field(
          ref="vehicleModelGroupSelectField",
          name="dealMaintenancePackage[vehicleModelGroupId]",
          v-model="resource.vehicleModelGroupId",
          :label="$t('.fields.vehicleModelGroup.label')",
          :placeholder="$t('.fields.vehicleModelGroup.placeholder')",
          :loading="submitting",
          :errors="vehicleModelGroupErrors"
        )
          template(#option="{ props: { option, index, focus, active } }")
            vehicle-model-group-option.option(
              :option="option",
              :active="active",
              :focus="focus"
            )
          template(#selected="{ props: { selected } }")
            vehicle-model-group-option.selected-option(
              :option="selected",
              hide-action-column
            )

      section.section
        ui-row
          ui-col(:span="8")
            .global-price.flex.vertical-center
              i.icon.fal.fa-globe
              span.text {{ $t('.laborPrice.title.label') }}
          ui-col(:span="4")
            money-field(
              name="dealMaintenancePackage[laborPrice]",
              v-model="resource.laborPrice",
              :loading="submitting",
              hide-label,
              :placeholder="$t('.fields.laborPrice.placeholder')",
              :errors="errors.laborPrice"
            )

</template>


<script>

// Mixins
import { mixins } from "movida-common.vue"

// Models
import models from "@/models"

// Components
import VehicleModelGroupOption from "@/views/suppliers/deals/products/components/vehicle-model-group-option"
import MaintenancePackageOption from "./components/maintenance-package-option"

const { FormMixin } = mixins
const { DealMaintenancePackage } = models

export default {
  name: "NewSupplierDealMaintenancePackage",

  components: {
    VehicleModelGroupOption,
    MaintenancePackageOption
  },

  mixins: [FormMixin],

  data() {
    return {
      i18nScope: "suppliers.deals.maintenance-packages.new",

      dirtyable: false, // XXX: dirty manager e modal não combinam!

      model:    "DealMaintenancePackage",
      resource: new DealMaintenancePackage({ dealId: this.$route.params.dealId }),

      autofetch: false,

      id:     this.$params.asInteger(this.$route.params.id),
      dealId: this.$params.asInteger(this.$route.params.dealId)
    }
  },

  computed: {
    backRoute() {
      return {
        name:   "supplierDealMaintenancePackages",
        params: { id: this.id, dealId: this.dealId }
      }
    },

    maintenancePackageName() {
      return _.get(
        this.$refs.maintenancePackageSelectField,
        "selectedOption.label"
      )
    },

    maintenancePackageErrors() {
      return _.compact(
        _.concat(
          this.errors.maintenancePackageId,
          this.errors.maintenancePackage
        )
      )
    },

    vehicleModelGroupName() {
      return _.get(
        this.$refs.vehicleModelGroupSelectField,
        "selectedOption.label"
      )
    },

    vehicleModelGroupErrors() {
      return _.compact(
        _.concat(
          this.errors.vehicleModelGroupId,
          this.errors.vehicleModelGroup
        )
      )
    }
  },

  methods: {
    // @override Form mixin
    serializeAttributes() {
      return this.resource.$serialize()
    },

    goBack() {
      // Voltando para rota pai ao fechar o modal
      if (_.get(this.$router.referrer, "name") === this.backRoute.name) {
        this.$goBack({ fallback: this.backRoute })
      }
      else {
        this.$router.push(this.backRoute)
      }
    },

    // @override Form mixin
    submitRequest() {
      const params = this.serializeAttributes()
      return this.$sdk.deals.createMaintenancePackage({ id: this.dealId, params })
    },

    // @override Form mixin
    submitSuccess(response) {
      // TODO: evoluir para mostrar ${nomePacote} e ${nomeGrupo}
      let message = this.$t(".notifications.submit.success", {
        name: this.maintenancePackageName
      })
      this.$notifications.info(message)

      this.$emit("change")
      this.$router.push(this.backRoute)
    },

    // @override Form mixin
    submitError(err) {
      this.$err.log(err)
      this.$notifications.error(this.$t(".notifications.submit.error"))
    }
  }
}

</script>

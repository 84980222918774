<template lang="pug">

  .rich-text.trix-content(v-html="value")

</template>


<script>

export default {
  name: "RichText",

  props: {
    value: { type: String, default: null }
  },

  data() {
    return {
      i18nScope: "components.rich-text"
    }
  }
}

</script>

<script>

// Extends
import VehicleModelsList from "@/components/vehicle-models-list"

export default {
  extends: VehicleModelsList,

  props: {
    vehicleModelGroupId: { type: [String, Number], required: true }
  },

  methods: {
    // @override Fetchable mixin
    fetch() {
      return this.$sdk.vehicleModelGroups.listVehicleModels({
        id:     this.vehicleModelGroupId,
        params: {
          ...this.httpParams
        }
      })
    }
  }
}

</script>

<style lang="scss" scoped>

.supplier-info-banner {
  border-radius: 8px;
  padding: 12px;
  background-color: $light-gray;

  .avatar {
    margin-right: 14px;
  }

  .row {
    margin-bottom: 4px;
    font-size: 14px;

    .title {
      font-family: $secondary-font;
      font-weight: 400;
      font-size: 16px;
    }

    .icon {
      color: $gray-2;
      margin-right: 7px;
    }


    .copy-icon {
      margin-left: 4px;
    }
  }
}

</style>

<template lang="pug">

  .supplier-info-banner.flex
    .flex.center
      avatar.avatar(:size="42")

    .supplier-info
      .row.flex.vertical-center
        app-span.title(:value="supplier.tradingName")

      .row.flex.vertical-center
        i.icon.fas.fa-house-user
        app-span.label(:value="fullAdress")

      template(v-if="supplier.serviceOrderContactName")
        .row.flex.vertical-center
          i.icon.fas.fa-user
          app-span.label(:value="supplier.serviceOrderContactName")

      template(v-if="supplier.serviceOrderEmail")
        .row.flex.vertical-center
          i.icon.fas.fa-envelope
          app-span.label(:value="supplier.serviceOrderEmail")
          copy-icon.copy-icon(:copy-value="supplier.serviceOrderEmail")

</template>

<script>

export default {
  name: "SupplierInfoBanner",

  props: {
    supplier: { type: Object, required: true }
  },

  computed: {
    address() {
      return _.get(this.supplier, "address")
    },

    fullAdress() {
      return _.get(this.address, "fullAdress") || _.get(this.address, "addressLine1")
    }
  }
}

</script>

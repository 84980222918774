<style lang="scss" scoped>

.electric-form-fields {
  .subtitle {
    font-weight: bold;
    font-size: 20px;
    font-family: $secondary-font;
    font-weight: 500;
  }

  .section-message {
    display: block;
    font-size: 16px;
    margin-bottom: 16px;
    line-height: 22px;
  }

  .section {
    border-bottom: 1px solid $light-gray-3;
    padding: 24px 0;
  }

  .row:not(:first-of-type) {
    padding-top: 24px;
  }
}

</style>


<template lang="pug">

  .electric-form-fields
    h2.subtitle {{ $t('.sections.electric.subtitle') }}
    span.section-message {{ $t('.sections.electric.message') }}

    ui-row.row
      ui-col(:span="3")
        money-field(
          name="vehicleModelElectric[electricEngineBatteryCapacity]",
          v-model.number="resource.electricEngineBatteryCapacity",
          :loading="submitting",
          :disabled="fetching",
          :errors="errors.electricEngineBatteryCapacity",
          :placeholder="$t('.fields.electricEngineBatteryCapacity.placeholder')",
          :suffix="$t('.fields.electricEngineBatteryCapacity.suffix')",
          :maxlength="6",
          :show-currency="false"
          data-testid= "electricEngineBatteryCapacity"
        )
      ui-col.empty(:span="1")

</template>


<script>

export default {
  props: {
    fetching:   { type: Boolean, default: false },
    submitting: { type: Boolean, default: false },
    errors:     { type: Object, default: () => ({}) },
    resource:   { type: Object, default: () => ({}) }
  },

  data() {
    return {
      i18nScope: "vehicle-models.forms.form-fields.electric-form-fields"
    }
  }
}

</script>

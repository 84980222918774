<style lang="scss" scoped>

.vehicle-card {
  .photo {
    margin-right: 16px;
  }

  .content {
    overflow: hidden;

    .diamond-icon {
      margin: 0 8px;
      font-size: 4px;
      color: $gray-3;
    }

    .top-info {
      margin-bottom: 4px;
      font-size: 20px;
      font-weight: normal;
      font-family: $secondary-font;
    }

    .middle-info {
      margin-bottom: 16px;
      color: $dark-gray;
      font-size: 14px;
    }

    .bottom-info {
      .value {
        color: $gray-4;
        font-weight: 400;
        overflow: hidden;

        .movida-common__vehicle-plate-tag {
          padding: 2px 8px;
        }

        .icon {
          margin-right: 12px;
          color: $gray-2;
        }

        & + .value {
          margin-left: 24px;
        }
      }
    }
  }
}

</style>


<template lang="pug">

  .vehicle-card.flex
    template(v-if="loading")
      avatar.photo(placeholder="fas fa-car")
      loading-lines.full-width(:min="50", :max="80", :lines="3")

    template(v-else)
      vehicle-manufacturer-icon.photo(
        :value="manufacturer.name",
        :size="48"
      )
      .content.full-width
        .top-info
          app-span.model(:value="mobiautoVersionModel.name", crop)
          .flex.vertical-center
            app-span.version(:value="mobiautoVersion.name", crop)
            i.diamond-icon.fa.fa-diamond.flex.center.vertical-center
            app-span.year {{ vehicle.year }}

        .middle-info.flex.vertical-center
          app-span.manufacturer(:value="manufacturer.name", crop)
          i.diamond-icon.fas.fa-diamond

          app-span.text(:value="$t(`models.vehicleModel.enums.typePowerSource.${vehicleModel.type}`)")
          template(v-if="hasCombustion && vehicleModel.fuel")
            i.diamond-icon.fa.fa-diamond
            app-span.text(
              data-testid="vehicleModelFuel",
              :value="$t(`models.vehicleModelCombustion.enums.fuel.${vehicleModel.fuel}`)"
            )

          i.diamond-icon.fas.fa-diamond
          fipe-code-tag(:value="mobiautoVersion.fipeId")
          i.diamond-icon.fas.fa-diamond
          id-tag.vehicle-id(:value="vehicleModel.id")

        .bottom-info
          .flex.vertical-center
            .value.plate.no-shrink
              vehicle-plate-tag(:value="vehicleLicensePlate")
            .value.color.flex.vertical-center
              i.icon.far.fa-swatchbook
              app-span(:value="vehicle.colorName", crop)
            .value.km.flex.vertical-center.no-shrink
              i.icon.far.fa-tachometer-alt-average
              app-span {{ kilometrage }}

</template>


<script>

export default {
  props: {
    vehicleKm:           { type: [Number, String], default: null },
    vehicleLicensePlate: { type: String, default: null },
    vehicle:             { type: Object, default: () => ({}) },
    loading:             { type: Boolean, default: false }
  },

  data() {
    return {
      i18nScope: "stores.tickets.components.service-order-vehicle-card"
    }
  },

  computed: {
    hasCombustion() {
      return ["VehicleModels::Combustion", "VehicleModels::Hybrid"].includes(this.vehicleModel.type)
    },

    vehicleModel() {
      return _.get(this.vehicle, "vehicleModel") || {}
    },

    mobiautoVersion() {
      return _.get(this.vehicleModel, "mobiautoVersion") || {}
    },

    mobiautoVersionModel() {
      return _.get(this.mobiautoVersion, "model") || {}
    },

    manufacturer() {
      return _.get(this.mobiautoVersionModel, "manufacturer") || {}
    },

    kilometrage() {
      if (_.blank(this.vehicleKm)) return this.$t("unregistered.masculine")

      return this.$t(".km", { kilometrage: this.$asNumber(this.vehicleKm, { precision: 0 }) })
    }
  }
}

</script>

import Base from "./Base"

class Deals extends Base {
  createProduct({ id, params = {} } = {}) {
    if (_.blank(id)) {
      throw new Error('SDK Deals - #addProduct() - "id" is a required prop')
    }

    return this.action({
      method: "post",
      url:    `/deals/${id}/products`,
      params
    })
  }

  cancel({ id, params = {} } = {}) {
    if (_.blank(id)) {
      throw new Error(
        'SDK Deals - #cancel() - "id" is a required prop'
      )
    }

    return this.action({
      method: "post",
      url:    `/deals/${id}/cancellations`,
      params
    })
  }

  getProduct({ id, productId, params = {} } = {}) {
    if (_.blank(id, productId)) {
      throw new Error('SDK Deals - #getProduct() - "id" is a required prop')
    }

    return this.action({
      method: "get",
      url:    `/deals/${id}/products/${productId}`,
      params
    })
  }

  updateProduct({ id, productId, params = {} } = {}) {
    if (_.blank(id, productId)) {
      throw new Error('SDK Deals - #updateProduct() - "id" is a required prop')
    }

    return this.action({
      method: "patch",
      url:    `/deals/${id}/products/${productId}`,
      params
    })
  }

  destroyProduct({ id, productId, params = {} } = {}) {
    if (_.blank(id, productId)) {
      throw new Error('SDK Deals - #destroyProduct() - "id" is a required prop')
    }

    return this.action({
      method: "delete",
      url:    `/deals/${id}/products/${productId}`,
      params
    })
  }

  listProducts({ id, params = {} } = {}) {
    if (_.blank(id)) {
      throw new Error('SDK Deals - #listProducts() - "id" is a required prop')
    }

    return this.action({
      method: "get",
      url:    `/deals/${id}/products`,
      params
    })
  }

  listProductsDataLoads({ id, params = {} } = {}) {
    if (_.blank(id)) {
      throw new Error('SDK Deals - #listProductsDataLoads() - "id" is a required prop')
    }

    return this.action({
      method: "get",
      url:    `/deals/${id}/products/data_loads`,
      params
    })
  }

  getProductsDataLoad({ id, dataLoadId, params = {} } = {}) {
    if (_.blank(id)) {
      throw new Error('SDK Deals - #getProductsDataLoad() - "id" is a required prop')
    }

    if (_.blank(dataLoadId)) {
      throw new Error('SDK Deals - #getProductsDataLoad() - "dataLoadId" is a required prop')
    }

    return this.action({
      method: "get",
      url:    `/deals/${id}/products/data_loads/${dataLoadId}`,
      params
    })
  }

  createProductsDataLoad({ id, params = {} } = {}) {
    if (_.blank(id)) {
      throw new Error('SDK Deals - #createProductsDataLoad() - "id" is a required prop')
    }

    return this.action({
      method: "post",
      url:    `/deals/${id}/products/data_loads`,
      params
    })
  }

  createMaintenancePackage({ id, params = {} } = {}) {
    if (_.blank(id)) {
      throw new Error(
        'SDK Deals - #createMaintenancePackage() - "id" is a required prop'
      )
    }

    return this.action({
      method: "post",
      url:    `/deals/${id}/maintenance_packages`,
      params
    })
  }

  listMaintenancePackages({ id, params = {} } = {}) {
    if (_.blank(id)) {
      throw new Error(
        'SDK Deals - #listMaintenancePackages() - "id" is a required prop'
      )
    }

    return this.action({
      method: "get",
      url:    `/deals/${id}/maintenance_packages`,
      params
    })
  }

  getMaintenancePackage({ id, maintenancePackageId, params = {} } = {}) {
    if (_.blank(id)) {
      throw new Error('SDK Deals - #getMaintenancePackage() - "id" is a required prop')
    }

    if (_.blank(maintenancePackageId)) {
      throw new Error('SDK Deals - #getMaintenancePackage() - "maintenancePackageId" is a required prop')
    }

    return this.action({
      method: "get",
      url:    `/deals/${id}/maintenance_packages/${maintenancePackageId}`,
      params
    })
  }

  updateMaintenancePackage({ id, maintenancePackageId, params = {} } = {}) {
    if (_.blank(id)) {
      throw new Error('SDK Deals - #updateMaintenancePackage() - "id" is a required prop')
    }

    if (_.blank(maintenancePackageId)) {
      throw new Error('SDK Deals - #updateMaintenancePackage() - "maintenancePackageId" is a required prop')
    }

    return this.action({
      method: "patch",
      url:    `/deals/${id}/maintenance_packages/${maintenancePackageId}`,
      params
    })
  }

  destroyMaintenancePackage({ id, maintenancePackageId, params = {} } = {}) {
    if (_.blank(id)) {
      throw new Error('SDK Deals - #destroyMaintenancePackage() - "id" is a required prop')
    }

    if (_.blank(maintenancePackageId)) {
      throw new Error('SDK Deals - #destroyMaintenancePackage() - "maintenancePackageId" is a required prop')
    }

    return this.action({
      method: "delete",
      url:    `/deals/${id}/maintenance_packages/${maintenancePackageId}`,
      params
    })
  }

  listMaintenancePackagesDataLoads({ id, params = {} } = {}) {
    if (_.blank(id)) {
      throw new Error('SDK Deals - #listMaintenancePackageDataLoads() - "id" is a required prop')
    }

    return this.action({
      method: "get",
      url:    `/deals/${id}/maintenance_packages/data_loads`,
      params
    })
  }

  createMaintenancePackagesDataLoad({ id, params = {} } = {}) {
    if (_.blank(id)) {
      throw new Error('SDK Deals - #createMaintenancePackagesDataLoad() - "id" is a required prop')
    }

    return this.action({
      method: "post",
      url:    `/deals/${id}/maintenance_packages/data_loads`,
      params
    })
  }

  getMaintenancePackagesDataLoad({ id, dataLoadId, params = {} } = {}) {
    if (_.blank(id)) {
      throw new Error('SDK Deals - #getMaintenancePackagesDataLoad() - "id" is a required prop')
    }

    if (_.blank(dataLoadId)) {
      throw new Error('SDK Deals - #getMaintenancePackagesDataLoad() - "dataLoadId" is a required prop')
    }

    return this.action({
      method: "get",
      url:    `/deals/${id}/maintenance_packages/data_loads/${dataLoadId}`,
      params
    })
  }

  listStateChanges({ id, params = {} } = {}) {
    if (_.blank(id)) {
      throw new Error('SDK Deals - #listStateChanges() - "id" is a required prop')
    }

    return this.action({
      method: "get",
      url:    `/deals/${id}/state_changes`,
      params
    })
  }
}

export default Deals

<!--
# SupplierClassification
Badge com tooltip indicando a classificação do fornecedor

## Uso
```pug
  supplier-classification-badge(value="A")
```

## Props
- value: {"CMM" | "A" | "B" | "C" | "D"} Classificação do fornecedor
- disableTooltip: {Boolean} Desabilita tooltip
-->

<style lang="scss" scoped>

.supplier-classification-badge {
  align-items: center;
  display: flex;
  flex-grow: 0;
  flex-shrink: 0;
  height: 22px;
  justify-content: center;
  width: 22px;

  &.icon-cmm {
    width: 45px;
  }
}

</style>


<template lang="pug">

  .supplier-classification-badge(
    v-tooltip.bottom="tooltip"
    :class="classes"
  )
    img(:src="icon")

</template>


<script>

import icons from "@/assets/images/icons/suppliers/supplier-classification"
import models from "@/models"

const { Supplier } = models

const ICONS = {
  CMM:  icons.iconCMM,
  A:    icons.iconA,
  B:    icons.iconB,
  C:    icons.iconC,
  D:    icons.iconD,
  none: icons.iconND  // XXX: "none" é special value para filtro de busca, e tbm fallback para "não preenchido"
}


export default {
  name: "SupplierClassificationBadge",

  props: {
    disableTooltip: { type: Boolean, default: false },
    value:          {
      type:      String,
      default:   null,
      validator: (value) => (_.blank(value) || Object.keys(ICONS).includes(value))
    }
  },

  data() {
    return {
      i18nScope: "components.supplier.supplier-classification-badge"
    }
  },

  computed: {
    classes() {
      const iconName = this.value?.toLowerCase() || "none"

      return {
        [`icon-${iconName}`]: true
      }
    },

    icon() {
      return ICONS[this.value] || ICONS.none
    },

    tooltip() {
      if (this.disableTooltip) return null

      const value = this.value

      if (_.blank(value)) return this.$t(".nd-tooltip")

      if (value == "CMM") return this.$t(".cmm-tooltip")

      return this.$t(".tooltip", { value })
    }
  }
}

</script>

<!--

  Timeline (de eventos e/ou estados) de uma Ordem de Serviço.

  Componente fachada para construir o componenete específico de timeline de acordo com o "tipo" da
  Ordem de Serviço.

  usage:
    timeline(:service-order="serviceOrder")

-->

<style scoped lang="scss">
</style>


<template lang="pug">

  component(
    :is="timelineComponent"
    v-bind="{ ...$attrs, ...$props }"
    v-on="$listeners"
  )

</template>


<script>

import PurchaseTimeline from "@/views/monitoring/_components/purchase-timeline.vue"
import ServiceTimeline  from "@/views/monitoring/_components/service-timeline.vue"

import models from "@/models"


export default {
  components: {
    PurchaseTimeline,
    ServiceTimeline
  },

  // emits: [], // wrapper component
  inheritAttrs: false, // wrapper component

  props: {
    serviceOrder: { type: models.MovidaServiceOrder, required: true }
  },

  data() {
    return {
      i18nScope: "monitoring.service-orders.timeline"
    }
  },

  computed: {
    timelineComponent() {
      return this.serviceOrder.serviceKind == "purchase"
        ? PurchaseTimeline
        : ServiceTimeline
    }
  }
}

</script>

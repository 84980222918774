import Base from "../base"

class MovidaServiceOrderProduct extends Base {
  static get attrs() {
    return _.uniq([
      ...super.attrs,
      "description",
      "quantity",
      "price",
      "totalPrice",
      "discount",
      "partOrigin", // enum
      "warranty",   // boolean

      // virtual associations
      "product"  // { name: string, sapId: string }
    ])
  }

  static get enums() {
    return {
      partOrigin: {
        original:    "original",
        alternative: "alternative",
        reuse:       "reuse"
      }
    }
  }

  get name() {
    return this.product?.name
  }

  get sapId() {
    return this.product?.sapId
  }
}

export default MovidaServiceOrderProduct

<style lang="scss" scoped>

.option {
  height: 40px;
  padding: 0 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid $light-gray-3;
  cursor: pointer;

  .remove-icon-content {
    width: 24px;
    height: 24px;
    line-height: 24px;
    text-align: center;
    border-radius: 50px;
    color: $gray-2;
    transition: all 100ms linear;
  }

  &.active {
    color: $primary;
    background: $light-gray-2;
  }

  &.focus {
    background: $light-gray-2;
    color: $dark-primary-2;

    .remove-icon-content {
      background: $light-gray-3;
      color: $primary;
    }
  }
}

.icon {
  width: 22px;
  margin-right: 8px;
}

.content {
  overflow: hidden;

  .label {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
  }
}

</style>


<template lang="pug">

  select-field(
    v-bind="$props",
    v-on="$listeners",
    :options="options"
  )
    template(#selected="{ props: { selected } }")
      .content.flex.vertical-center
        img.icon(:src="selected.icon")
        label.label {{ selected.label }}

    template(#option="{ props: { option, index, focus, active } }")
      .option(:class="{ focus, active }")
        .content.flex.vertical-center
          img.icon(:src="option.icon")
          label.label {{ option.label }}
        .remove-icon-content(v-if="active")
          i.fal.fa-times

</template>


<script>

import Vue from "vue"

import SelectField from "@/components/form/select-field"

import maintenance from "@/assets/images/icons/service-types/maintenance.svg"
import assistance from "@/assets/images/icons/service-types/assistance.svg"
import autoBodyAndPaint from "@/assets/images/icons/service-types/autoBodyAndPaint.svg"
import documentation from "@/assets/images/icons/service-types/documentation.svg"
import glass from "@/assets/images/icons/service-types/glass.svg"
import mechanic from "@/assets/images/icons/service-types/mechanic.svg"
import setup from "@/assets/images/icons/service-types/setup.svg"
import tireAndWheels from "@/assets/images/icons/service-types/tireAndWheels.svg"
import withdrawal from "@/assets/images/icons/service-types/withdrawal.svg"
import armoring from "@/assets/images/icons/service-types/armoring.svg"
import locksmith from "@/assets/images/icons/service-types/locksmith.svg"
import electric from "@/assets/images/icons/service-types/electric.svg"
import transport from "@/assets/images/icons/service-types/transport.svg"
import tapestry from "@/assets/images/icons/service-types/tapestry.svg"

import models from "@/models"
const { Service } = models

export default {
  name: "ServiceTypeSelectField",

  extends: SelectField,

  props: {
    // Mudando comportamento para default ser true
    searchable: { type: Boolean, default: true }, 
    showMaintenance: { type: Boolean, default: false }
  },

  data() {
    return {
      i18nScope: "components.services.components.service-type-select-field"
    }
  },

  computed: {
    options() {

      let values = Service.$enums.type.values
      
      if ( ! this.showMaintenance ) {
        // XXX: Revisão tem cadastro específico
        values = values.filter(value => value !== "Maintenance")
      }

      return values
        .map(value => {
          let icon

          switch (value) {
            case "Maintenance": icon = maintenance; break
            case "Services::Armoring": icon = armoring; break
            case "Services::Locksmith": icon = locksmith; break
            case "Services::Assistance": icon = assistance; break
            case "Services::AutoBodyAndPaint": icon = autoBodyAndPaint; break
            case "Services::Documentation": icon = documentation; break
            case "Services::Glass": icon = glass; break
            case "Services::Mechanic": icon = mechanic; break
            case "Services::Transport": icon = transport; break
            case "Services::Electric": icon = electric; break
            case "Services::Setup": icon = setup; break
            case "Services::TiresAndWheels": icon = tireAndWheels; break
            case "Services::Tapestry": icon = tapestry; break
            case "Services::Withdrawal": icon = withdrawal; break
            default: icon = assistance
          }

          return {
            icon,
            value,
            label: this.$t(`models.service.enums.type.${value}`)
          }
        })
    }
  },

  // Removendo props 'options' que está sendo reescrita como _computed_
  beforeCreate() {
    Vue.delete(this.$options.props, "options")
  }
}

</script>

<style lang="scss" scoped>

.business-unit-multiple-select-field {
  &.selected {
    ::v-deep .placeholder {
      color: $dark-gray;
    }
  }

  .selected-items {
    padding: 8px;
    font-family: $secondary-font;
    font-weight: 400;
    font-size: 14px;
    border-bottom: 1px solid $light-gray-3;

    .link {
      font-size: 14px;
      font-weight: 400;
    }

    .icon {
      font-size: 12px;
      margin-right: 4px;
      margin-left: 8px;
    }
  }

  .option {
    min-height: 40px;
    padding: 8px 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid $light-gray-3;
    cursor: pointer;

    .remove-icon-content {
      width: 24px;
      height: 24px;
      line-height: 24px;
      text-align: center;
      border-radius: 50px;
      color: $gray-2;
      transition: all 100ms linear;
    }

    &.active {
      color: $primary;
      background: $light-gray-2;
    }

    &.focus {
      background: $light-gray-2;
      color: $dark-primary-2;

      .remove-icon-content {
        background: $light-gray-3;
        color: $primary;
      }
    }
  }
}

</style>


<template lang="pug">

  .business-unit-multiple-select-field(:class="{ selected: hasSelected }")
    select-field(
      searchable,
      :close-on-select="false",
      :default-filter="false",
      :disabled="disabled",
      :errors="errors",
      :hide-label="hideLabel",
      :label="label",
      :loading="loading",
      :name="name",
      :optional="optional",
      :options="options",
      :placeholder="placeholderText"
      @input="onSelect",
      @inputSearch="newSearch => search = newSearch",
      @search="onSearch",
      @open="onOpen"
    )
      template(#menuContentHeader)
        .selected-items.flex.vertical-center(v-if="hasSelected")
          app-span(crop, :value="selectedItemsText")
          app-button.link(theme="link", @click="onClear")
            i.icon.far.fa-times
            span {{ $t('.clear.label') }}

      template(#option="{ props: { option, index, focus } }")
        .option(:class="{ focus, active: isActiveOption(option) }")
          span {{ option.label }}
          .remove-icon-content(v-if="isActiveOption(option)")
            i.fal.fa-times

</template>


<script>

// Mixins
import FieldMixin from "@/mixins/field"

import { BUSINESS_UNITS } from "@/enums/business-units"


export default {
  mixins: [FieldMixin],

  props: {
    value:         { type: Array,   default: () => [] },  // XXX: é array!
    optional:      { type: Boolean, default: false    },
    hideLabel:     { type: Boolean, default: false    },
    optionsFilter: { type: Array,   default: null     }
  },

  data() {
    return {
      i18nScope: "monitoring.business-unit-multiple-select-field",

      search:  "",
      options: []
    }
  },

  computed: {
    originalOptions() {
      let businessUnits = BUSINESS_UNITS.values
      if (_.present(this.optionsFilter)) {
        businessUnits = businessUnits.filter(value => this.optionsFilter.includes(value))
      }

      return businessUnits.map(businessUnit => ({
        label: this.$t(`enums.business-units.BUSINESS_UNITS.${businessUnit}`),
        value: businessUnit
      }))
    },

    // XXX: se tiver valor selecionado, sobrepõe o placeholder!
    placeholderText() {
      return _.blank(this.value)
        ? this.placeholder
        : this.selectedItemsText
    },

    selectedItemsText() {
      // return this.$t(".placeholder", { count: this.value.length })
      return this.value
        .map(businessUnit => this.$t(`enums.business-units.BUSINESS_UNITS.${businessUnit}`))
        .join(", ")
    },

    hasSelected() {
      return _.present(this.value)
    }
  },

  methods: {
    onOpen() {
      this.options = this.originalOptions
    },

    onSelect(option) {
      if (!option) return

      let selectedOptions = this.value
      const optionIndex = selectedOptions.findIndex(value => value === option)

      if (optionIndex < 0) selectedOptions = [...selectedOptions, option]
      else selectedOptions.splice(optionIndex, 1)

      this.$emit("input", selectedOptions)
    },

    async onSearch() {
      await this.$nextTick()

      this.options = this.originalOptions.filter(({ label }) => {
        const formattedOption = _.deburr(String(label).toLowerCase())
        const formattedSearch = _.deburr(this.search.toLowerCase())
        return formattedOption.indexOf(formattedSearch) >= 0
      })
    },

    isActiveOption(option) {
      return !!this.value.find(value => value === option.value)
    },

    onClear() {
      this.$emit("input", [])
    }
  }
}

</script>

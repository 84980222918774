<style scoped lang="scss">

.actions {
  padding: 24px;
  border-top: 1px solid $light-gray-3;
}

</style>


<template lang="pug">

  .new-publication(v-if="$can('publish', 'Deal')")
    app-button(
      :disabled="disabled",
      :loading="submitting",
      @click="showModal = true",
    )
      slot

    app-modal(
      v-if="showModal",
      :close-button="!submitting",
      :heading="$t('.heading', { id })",
      :width="540",
      :loading="submitting",
      footer,
      :confirm-label="$t('.btn.submit.label')"
      :confirm-label-loading="$t('.btn.submit.loading')",
      @close="onCloseModal",
      @cancel="onCloseModal",
      @confirm="submit"
    )

      .body
        span.text {{ $t('.message') }}

</template>


<script>

export default {
  props: {
    id:       { type: [String, Number], required: true },
    disabled: { type: Boolean, default: false }
  },

  data() {
    return {
      i18nScope: "suppliers.deals.publication.draft.new",

      supplierId: this.$route.params.id,

      submitting: false,

      showModal: false
    }
  },

  methods: {
    onCloseModal() {
      if (!this.submitting) {
        this.showModal = false
      }
    },

    async submit() {
      try {
        this.submitting = true
        await this.$sdk.suppliers.publishDeal({ id: this.supplierId, dealId: this.id })

        this.$notifications.info(this.$t(".notifications.submit.success", { id: this.id }))
        this.$emit("published")
      }
      catch (error) {
        this.$err.log(error)
        this.$notifications.error(this.$t(".notifications.submit.error"))
        let errData = _.get(error, "originalError.response.data.errors") || {}

        let errors = this.$i18n.errify(errData, { model: "Deal" })

        let keyTranslate = {
          'deal': 'Acordo',
          'startAt': 'Data de início do acordo',
          'duration': 'Duração do acordo',
          'dealProducts': 'Produtos do acordo',
          'dealMaintenancePackages': 'Pacotes de manutenção do acordo',
        }

        for (let key in errors) {
          this.$notifications.error(keyTranslate[key] + ': ' + errors[key])
        }

        this.$emit("errors", errors)
      }
      finally {
        this.submitting = false
        this.showModal = false
      }
    }
  }
}

</script>

<style scoped lang="scss">

.app-list.default {
  border: 1px solid $light-gray-3;
  border-radius: 4px;

  &.validation-error {
    border-color: $error;
  }

  .empty,
  .error {
    text-align: center;
    font-style: italic;
    padding: 32px 0;
    width: 100%;

    color: $gray-3;
  }

  .table-edge {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    min-height: 8px;
    background: $light-gray;
  }

  .list-content {
    overflow: hidden;
    overflow-y: auto;
  }
}

/* .app-list.none {

} */

</style>


<template lang="pug">

  .app-list(:class="{ [theme]: true, 'validation-error': validationError }")

    .table-edge
      slot(name="header")

    .content(:class="{ 'list-content': contentHeight }", :style="contentStyle")
      template(v-if="error")
        .error
          slot(name="error")
            span {{ errorText || $t(".error") }}

      template(v-else-if="loading")
        slot(name="loadingRow")
          generic-row-loading(
            v-for="generic in 4",
            :key="`generic-row-loading-${generic}`"
          )

      template(v-else-if="empty")
        .empty
          slot(name="empty")
            span {{ emptyText || $t(".empty") }}

      template(v-else)
        slot

    slot(name="footer")
    template(v-if="!hasFooter")
      .table-edge

</template>


<script>

export default {
  name: "AppList",

  props: {
    // flags
    error:           { type: Boolean, default: false },
    loading:         { type: Boolean, default: false },
    empty:           { type: Boolean, default: false },
    validationError: { type: Boolean, default: false },
    emptyText:       { type: String, default: null },
    errorText:       { type: String, default: null },
    contentHeight:   { type: Number, default: null },
    theme:           { type: String, default: "default" }
  },

  data() {
    return {
      i18nScope: "components.app-list"
    }
  },

  computed: {
    contentStyle() {
      if (!this.contentHeight) return {}

      return {
        height: `${this.contentHeight}px`
      }
    },

    hasHeader() {
      return _.has(this.$slots, "header[0]")
    },

    hasFooter() {
      return _.has(this.$slots, "footer[0]")
    }
  }
}

</script>

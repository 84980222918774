.<style lang="scss" scoped>

.service-orders-table-header {
  display: grid;
  grid-template-columns: var(--grid-columns);

  background-color: $light-gray;
  height: 40px;
  border-bottom: 1px solid $light-gray-3;
  border-top: 1px solid $light-gray-3;

  &.loading {
    border-bottom: 2px solid $light-orange-2;
  }

  .unsortable {
    padding: 0 8px;
  }

  .column {
    font-size: 12px;

    .icon-container {
      width: 20px;
      height: 20px;

      & + .text {
        margin-left: 8px;
      }

      .icon {
        font-size: 14px;
        color: $gray-2;
      }

      .diamond-icon {
        font-size: 8px;
        color: $light-gray-4;
      }
    }

    .text {
      font-family: $secondary-font;
      font-size: 14px;
      color: $dark-gray;
      font-weight: 400;
    }

    .loader-icon {
      font-size: 16px;
      color: $orange;
    }
  }
}

</style>


<template lang="pug">

  .service-orders-table-header(:class="{ loading }", :style="styles")
    template(v-if="canSelectRow")
      .column.unsortable.flex.vertical-center.center(data-testid="selectColumn")
        checkbox-field(
          hide-value-text,
          name="select-all",
          :disabled="false",
          :indeterminate="indeterminate",
          :loading="loading",
          :value="allSelected",
          @input.native.prevent="$emit('select', !allSelected)"
        )

    .column.unsortable.flex.vertical-center(data-testid="serviceOrderColumn")
      span.text {{ $t('.columns.service-order.label') }}

    .column.unsortable.flex.vertical-center(data-testid="vehicleColumn")
      span.text {{ $t('.columns.vehicle.label') }}

    .column.unsortable.flex.vertical-center(data-testid="supplierColumn")
      span.text {{ $t('.columns.supplier.label') }}

    .column.unsortable.flex.vertical-center(data-testid="customerColumn")
      span.text {{ $t('.columns.customer.label') }}

    .column.unsortable.flex.vertical-center.center(data-testid="messagesColumn")
      .icon-container.flex.center.vertical-center
        i.icon.far.fa-message-pen

    .column.unsortable.flex.vertical-center.center(data-testid="assignmentColumn")
      .icon-container.flex.center.vertical-center
        i.icon.far.fa-user

    .column.unsortable.flex.vertical-center(data-testid="statusColumn")
      span.text {{ $t('.columns.status.label') }}

    .column.unsortable.flex.vertical-center.center(data-testid="actionsColumn")
      template(v-if="loading")
        i.loader-icon.fas.fa-spin.fa-spinner

      template(v-else)
        .icon-container.flex.center.vertical-center
          i.diamond-icon.fas.fa-diamond

</template>


<script>

export default {
  props: {
    loading:   { type: Boolean, default: false },
    sortParam: { type: String, required: true },
    sortOrder: { type: String, required: true },

    columnSizes: { type: String, required: true },

    allSelected:   { type: Boolean, required: true },
    indeterminate: { type: Boolean, required: true }
  },

  data() {
    return {
      i18nScope: "monitoring.service-orders-table-header",

      check: false
    }
  },

  computed: {
    styles() {
      return {
        "--grid-columns": this.columnSizes
      }
    },

    canSelectRow() {
      return this.$can("create", "ServiceOrderAssignment")
    }
  },

  methods: {
    isSortedBy(param) {
      return this.sortParam === param
    }
  }
}

</script>

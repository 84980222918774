// Base
import BaseRequests from "./Base"

// Models
import models from "@/models"
const { SupplierBlock } = models

class SupplierBlocksRequests extends BaseRequests {
  static get selector() {
    return "supplierBlocks"
  }

  find({ supplierBlockId, params = {}, options = {} } = {}) {
    if (_.blank(supplierBlockId)) {
      throw new Error('NewSDK supplierBlocks - #find() - "supplierBlockId" is a required prop')
    }

    return this.action({
      method:  "get",
      url:     `/supplier_blocks/${supplierBlockId}`,
      params,
      options: {
        model: SupplierBlock,

        ...options
      }
    })
  }
}

export default SupplierBlocksRequests

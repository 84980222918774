import { i18n } from "utils.vue"
import Base from "./base"

// Icons
import preventiveMaintenance from "@/assets/images/icons/ticket-types/maintenance.svg"
import repair from "@/assets/images/icons/ticket-types/mechanic.svg"
import autoBodyAndPaint from "@/assets/images/icons/ticket-types/auto-body-and-paint.svg"
import glass from "@/assets/images/icons/ticket-types/glass.svg"
import tiresAndWheels from "@/assets/images/icons/ticket-types/tires-and-wheels.svg"
import assistance from "@/assets/images/icons/ticket-types/assistance.svg"
import withdrawal from "@/assets/images/icons/ticket-types/withdrawal.svg"
import documentation from "@/assets/images/icons/ticket-types/documentation.svg"

const ICONS = {
  preventiveMaintenance,
  repair,
  autoBodyAndPaint,
  glass,
  tiresAndWheels,
  assistance,
  withdrawal,
  documentation
}

// XXX: Mapeamento para atendimentos do CRM, não confundir com os atendimentos do GMF
class TicketType extends Base {
  static typeToParam(type) {
    const typeWithoutPrefix = type.replace(/tickets::/i, "")

    return _.kebabCase(typeWithoutPrefix)
  }

  static parseParamType(paramType) {
    // Lodash não possui _.pascalCase, sendo assim precisamos fazer este tratamento
    const typeWithoutPrefix = _.startCase(_.camelCase(paramType)).replace(/ /g, "")

    return `Tickets::${typeWithoutPrefix}`
  }

  static get attrs() {
    return _.uniq([
      ...super.attrs,
      "type"
    ])
  }

  static get enums() {
    return {
      type: {
        "Tickets::PreventiveMaintenance": "Tickets::PreventiveMaintenance",
        "Tickets::Repair":                "Tickets::Repair",
        "Tickets::Electric":              "Tickets::Electric",
        "Tickets::AutoBodyAndPaint":      "Tickets::AutoBodyAndPaint",
        "Tickets::Lamp":                  "Tickets::Lamp",
        "Tickets::TiresAndWheels":        "Tickets::TiresAndWheels",
        "Tickets::Glass":                 "Tickets::Glass",
        "Tickets::Locksmith":             "Tickets::Locksmith",
        "Tickets::Tapestry":              "Tickets::Tapestry",
        "Tickets::Armoring":              "Tickets::Armoring",
        "Tickets::Assistance":            "Tickets::Assistance",
        "Tickets::Withdrawal":            "Tickets::Withdrawal",
        "Tickets::Documentation":         "Tickets::Documentation"
      }
    }
  }

  get name() {
    return TicketType.$tEnum("type", this.type)
  }

  get description() {
    return i18n.t(`models.ticketType.descriptions.${this.type}`)
  }

  get icon() {
    const typeWithoutPrefix = this.type.replace(/tickets::/i, "")
    const camelizedType = _.camelCase(typeWithoutPrefix)

    return ICONS[camelizedType] || ICONS.preventiveMaintenance
  }

  get typeAsParam() {
    return TicketType.typeToParam(this.type)
  }
}

export default TicketType

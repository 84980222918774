<style lang="scss" scoped>

.deal-state {
  display: inline-block;
  border: 1px solid $dark-yellow;
  padding: 2px 8px;
  border-radius: 32px;
  font-family: $secondary-font;
  height: 24px;
  font-weight: 500;
}

.ongoing {
  border-color: $green;
  color: $white;
  background-color: $green;
}

.canceled {
  border-color: $light-gray-4;
}

.done,
.amended {
  border-color: $gray-2;
}

.draft {
  border-color: $dark-yellow;
}

</style>


<template lang="pug">

  .deal-state.flex.vertical-center(:class="{[value]: true }")
      slot
        span {{ Deal.$tEnum('state', value) }}

</template>


<script>

// Models
import models from "@/models"
const { Deal } = models

export default {
  props: {
    value: { type: String, default: null }
  },

  data() {
    return {
      Deal
    }
  }
}

</script>

<style lang="scss" scoped>

.narrow-service-order-attachment-card {
  .container {
    background-color: $white;
    border-radius: 4px;
    overflow: hidden;
    height: 32px;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25);
    position: relative;

    &:hover {
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);

      .actions {
        visibility: visible;
      }
    }

    .actions {
      height: 24px;
      background: $light-gray-2;
      padding: 4px 8px;
      position: absolute;
      right: 8px;
      top: 4px;
      border-radius: 4px;
      visibility: hidden;
      cursor: default;
      z-index: 1;

      .action {
        cursor: pointer;

        &:hover {
          color: $orange;
        }

        & + .action {
          margin-left: 16px;
        }

        &.downloading {
          cursor: progress;
          color: $gray-2;
        }
      }
    }

    .content {
      height: 32px;
      padding: 0 8px;
      font-family: $secondary-font;
      font-weight: 400;

      .icon-container {
        width: 20px;
        height: 20px;
        margin-right: 8px;

        .icon {
          color: var(--attachment-color);
        }
      }

      .content-container {
        height: 32px;
        position: relative;
        width: 100%;
        overflow: hidden;

        .info-container {
          height: 32px;
          width: 100%;

          .name {
            font-size: 14px;
            font-weight: 400;
            overflow: hidden;
          }
        }
      }
    }
  }
}

</style>


<template lang="pug">

  .narrow-service-order-attachment-card(:style="cssVars")
    .container
      .actions.flex.vertical-center.no-shrink(
        v-if="canDownload",
        @click.stop=""
      )
        .action.flex.center.vertical-center(
          :class="{ downloading }",
          @click="onDownload"
        )
          i.icon.far.fa-download

      .content.flex.vertical-center
        .icon-container.flex.center.vertical-center.no-shrink
          i.icon.fas(
            v-tooltip="MovidaServiceOrderAttachment.$tEnum('category', attachment.category)",
            :class="{ [attachment.icon]: true }"
          )

        .content-container.flex.vertical-center
          .info-container.flex.vertical-center.no-shrink
            app-span.name(crop, :value="attachment.fileName")

</template>


<script>

// Models
import models from "@/models"
import axios from 'axios';
import { conf} from "utils.vue"
const { MovidaServiceOrderAttachment } = models

export default {
  name: "NarrowServiceOrderAttachmentCard",

  props: {
    serviceOrderId: { type: [String, Number], required: true },
    attachment:     { type: Object, default: null },
    downloadable:   { type: Boolean, default: true },
    loading:        { type: Boolean, default: false }
  },

  data() {
    return {
      i18nScope:   "components.attachments.narrow-service-order-attachment-card",
      downloading: false,

      MovidaServiceOrderAttachment
    }
  },

  computed: {
    cssVars() {
      return {
        "--attachment-color": this.attachment.color
      }
    },

    canDownload() {
      return this.downloadable && !this.loading
    }
  },

  methods: {
    async onDownload() {
      if (!this.canDownload || this.downloading) return

      this.downloading = true

      try {
        const { data: { url } } = await this.downloadRequest()
        
        const response = await axios.get(url, {
          responseType: 'blob', // Define o tipo de resposta como um Blob
          headers:{'Api-Token': conf.api2.token}
        });
        
        const urlObject = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a")
        link.href = urlObject
        link.setAttribute("download", this.attachment.fileName)

        document.body.appendChild(link)
        link.click()
        link.parentNode.removeChild(link)
      }
      catch (error) {
        console.error(error)
        this.$notifications.error(this.$t(".notifications.download.error", { name: this.attachment.fileName }))
      }
      finally {
        this.downloading = false
      }
    },

    downloadRequest() {
      return this.$newSdk.monitoring.serviceOrders.attachments.getDownloadURL({
        serviceOrderId: this.serviceOrderId,
        attachmentId:   this.attachment.id
      })
    }
  }
}

</script>

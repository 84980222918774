<style lang="scss" scoped>

.store-minimum-info {
  .retry {
    margin-left: 4px;
  }
}

</style>


<template lang="pug">

  .store-minimum-info
    template(v-if="fetching")
      loading-lines(:min="50", :max="90")

    template(v-else-if="erred")
      span {{ $t('.fetch-error.message') }}
      app-button.retry(theme="link", @click="fetch") {{ $t('.fetch-error.retry.label') }}

    template(v-else)
      app-span(crop, :value="storeInfo")

</template>


<script>

// Mixins
import FetchMixin from "@/mixins/fetch-mixin"

export default {
  mixins: [FetchMixin],

  props: {
    storeId: { type: [String, Number], required: true }
  },

  data() {
    return {
      i18nScope: "vehicles.show.store-minimum-info",

      resource: {}
    }
  },

  computed: {
    store: {
      get() { return this.resource },

      set(value) { this.resource = value }
    },

    storeInfo() {
      return `${this.store.initials} - ${this.store.name}`
    }
  },

  methods: {
    // @override Fetch mixin
    fetchRequest() {
      return this.$newSdk.stores.find({ storeId: this.storeId })
    }
  }
}

</script>

<style lang="scss" scoped>

.ticket-draft-wizard-header {
  margin-bottom: 24px;
}

</style>


<template lang="pug">

  wizard-header.ticket-draft-wizard-header(
    :structure="structure",
    :current-section-index="currentSectionIndex",
    :current-step-index="currentStepIndex"
  )

</template>


<script>

// Libs
import { events } from "utils.vue"

export default {
  name: "TicketDraftWizardHeader",

  data() {
    return {
      i18nScope: "components.wizard-header.ticket-draft-wizard-header",

      currentSectionIndex: 1,
      currentStepIndex:    1
    }
  },

  computed: {
    structure() {
      return [
        { name: this.$t(".steps.identification"), steps: 2 },
        { name: this.$t(".steps.services"), steps: 1 },
        { name: this.$t(".steps.supplier"), steps: 1 },
        { name: this.$t(".steps.confirmation"), steps: 0 }
      ]
    }
  },

  watch: {
    $route: {
      immediate: true,
      handler() {
        this.currentStepIndex = 1
        this.currentSectionIndex = this.routeSection(this.$route.name)
      }
    }
  },

  created() {
    events.$on("ticket-draft:step", step => { this.currentStepIndex = step })
  },

  beforeDestroy() {
    events.$off(["ticket-draft:step"])
  },

  methods: {
    routeSection(routeName) {
      switch (routeName) {
        case "newDraftTicket":
        case "editDraftTicketVehicle":
        default: return 1
        case "editDraftTicketContent": return 2
        case "draftTicketBooking":
        case "editDraftTicketBooking": return 3
        case "newDraftTicketConfirmation": return 4
      }
    }
  }
}

</script>

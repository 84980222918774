// Base
import BaseRequests from "../../Base"

// Models
import models from "@/models"
const { Quote } = models


class MonitoringServiceOrderQuotesRequests extends BaseRequests {
  static get selector() {
    return "monitoring.serviceOrders.quotes"
  }

  createDraft({ serviceOrderId, params = {}, options = {} } = {}) {
    this.verifyData("createDraft", { serviceOrderId })

    return this.action({
      method:  "post",
      url:     `/monitoring/service_orders/${serviceOrderId}/quotes/draft`,
      params,
      options: {
        model: Quote,
        ...options
      }
    })
  }
}


export default MonitoringServiceOrderQuotesRequests

<style lang="scss" scoped>
.rabbot-row-enabled {
  background: #fad390;
}
</style>

<template lang="pug">

  index-table.service-orders-table(
    :empty="empty",
    :initializing="initializing",
    :cols-count="9",
    :column-sizes="columnSizes"
  )
    template(#tableHeader)
      service-orders-table-header(
        :all-selected="allSelected",
        :column-sizes="columnSizes",
        :indeterminate="indeterminate",
        :loading="loading",
        :sort-order="sortOrder",
        :sort-param="sortParam",
        @select="selectAll",
        @sort="param => $emit('sort', param)",
        data-testid="serviceOrdersTableHeader"
      )

    template(#tableRows)
      service-order-row(
        v-for="serviceOrder in serviceOrders",
        :class="{ 'rabbot-row-enabled': usePlatform(serviceOrder) }"
        :key="serviceOrder.rid",
        :serviceOrder="serviceOrder",
        :loading="loading",
        :column-sizes="columnSizes",
        :selected="isSelected(serviceOrder.rid)",
        @select="value => onRowSelect(serviceOrder.rid, value)",
        data-testid="serviceOrderRow"

        @cancel="(serviceOrder) => $emit('cancel', serviceOrder)"
        @create-vehicle-receipt="(serviceOrder) => $emit('create-vehicle-receipt', serviceOrder)"
        @execute="(serviceOrder) => $emit('execute', serviceOrder)"
        @postpone="(serviceOrder) => $emit('postpone', serviceOrder)"
        @reopen="(serviceOrder) => $emit('reopen', serviceOrder)"
        @resend-approval-notification="(serviceOrder) => $emit('resend-approval-notification', serviceOrder)"
        @show-messages="(serviceOrder) => $emit('show-messages', serviceOrder)"
        @withdraw-vehicle="(serviceOrder) => $emit('withdraw-vehicle', serviceOrder)"
      )

</template>


<script>

// Assets
import emptyIcon from "@/assets/images/icons/search/products/none-found.svg"

// Components
import ServiceOrderRow from "./service-order-row.vue"
import ServiceOrdersTableHeader from "./service-orders-table-header.vue"


export default {
  components: {
    ServiceOrderRow,
    ServiceOrdersTableHeader
  },

  props: {
    serviceOrders: { type: Array,   required: true    },
    initializing:  { type: Boolean, default: false    },
    loading:       { type: Boolean, default: false    },
    sortedBy:      { type: String,  default: ""       },
    selected:      { type: Array,   default: () => [] }
  },

  emits: [
    "cancel",
    "create-vehicle-receipt",
    "execute",
    "postpone",
    "reopen",
    "resend-approval-notification",
    "show-messages",
    "withdraw-vehicle",
    "sort" // table header e clique para ordenação
  ],


  data() {
    return {
      i18nScope: "monitoring.service-orders-table",

      emptyIcon
    }
  },

  computed: {
    selectedRids: {
      get() { return this.selected },
      set(value) { this.$emit("update:selected", value) }
    },

    empty() {
      return _.blank(this.serviceOrders)
    },

    allSelected() {
      return !this.empty && this.serviceOrders.every(serviceOrder => this.selectedRids.includes(serviceOrder.rid))
    },

    indeterminate() {
      return this.serviceOrders.some(serviceOrder => this.selectedRids.includes(serviceOrder.rid)) && !this.allSelected
    },

    columnSizes() {
      // return "38px 126px 180px 180px 180px 64px 64px 1fr 56px"
      if (this.canSelectRow) return "38px 126px 120px minmax(0, 1fr) minmax(0, 1fr) 64px 64px 270px 56px"

      return "126px 120px minmax(0, 1fr) minmax(0, 1fr) 64px 64px 270px 56px"
    },

    sortParam() {
      return this.sortedBy.split(":")[0]
    },

    sortOrder() {
      return this.sortedBy.split(":")[1] || "asc"
    },

    canSelectRow() {
      return this.$can("create", "ServiceOrderAssignment")
    }
  },

  methods: {
    isSelected(rid) {
      return this.selectedRids.indexOf(rid) >= 0
    },

    onRowSelect(rid, value) {
      let selectedRids = [...this.selectedRids]

      if (value) {
        const index = selectedRids.indexOf(rid)
        if (index === -1) selectedRids.push(rid)
      }
      else {
        selectedRids = selectedRids.filter(entry => entry !== rid)
      }

      this.selectedRids = selectedRids
    },

    selectAll(value) {
      if (value) {
        this.selectedRids = this.serviceOrders.map(serviceOrder => serviceOrder.rid)
      }
      else {
        this.selectedRids = []
      }
    },
    usePlatform(serviceOrder) {
      return serviceOrder.supplier.rabbotPlataform ?? false
    },
    useMovidaApp(serviceOrder) {
      return serviceOrder.supplier.movidaApp ?? false
    }
  }
}

</script>

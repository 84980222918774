<style lang="scss" scoped>

.vehicle-model-group {
  .header {
    margin-bottom: 16px;

    .discarded-icon {
      margin-right: 4px;
    }

    .name {
      font-family: $secondary-font;
      font-weight: 500;
      font-size: 20px;
    }

    .description {
      margin-top: 4px;
      font-size: 14px
    }
  }
}

</style>


<template lang="pug">

  .vehicle-model-group
    header.header
      .flex.vertical-center
        discarded-icon.discarded-icon(v-if="vehicleModelGroup.$discarded")
        h2.name {{ vehicleModelGroup.name }}
      span.description(v-if="vehicleModelGroup.description") {{ vehicleModelGroup.description }}

    vehicle-models-list(
      :vehicle-model-group-id="vehicleModelGroup.id",
      :has-checkbox="false"
    )

</template>


<script>

// Components
import VehicleModelsList from "./show/vehicle-models-list"

export default {
  name: "SupplierDealMaintenancePackageVehicleModelGroup",

  components: {
    VehicleModelsList
  },

  props: {
    vehicleModelGroup: { type: Object, required: true }
  },

  data() {
    return {
      i18nScope: "suppliers.deals.maintenance-packages.vehicle-model-group"
    }
  }
}

</script>

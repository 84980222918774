<style lang="scss" scoped>

.ticket-type-row {
  padding: 24px;
}

.main-icon {
  margin-right: 24px;
}

.title {
  font-family: $secondary-font;
  font-weight: 400;
  font-size: 18px;
  color: $dark-gray;

  &:hover {
    color: $primary;
  }
}

.description {
  font-size: 14px;
}

</style>


<template lang="pug">

  .ticket-type-row.flex.vertical-center
    img.main-icon(:src="ticketType.icon")

    .content.grow
      .flex.column-direction
        .top
          app-button(
            theme="link",
            :to="ticketTypeServicesLink"
          )
            span.title {{ ticketType.name }}
        span.description {{ ticketType.description }}

    .actions
      popover-menu(:show.sync="showPopover")
        popover-menu-button(
          :to="ticketTypeServicesLink",
          icon="fal fa-eye",
          :text="$t('.actions.btn.show')",
          @close-menu="showPopover = false"
        )

</template>


<script>

export default {
  props: {
    ticketType: { type: Object, required: true }
  },

  data() {
    return {
      i18nScope: "ticket-types.index.ticket-type-row",

      showPopover: false
    }
  },

  computed: {
    ticketTypeServicesLink() {
      return { name: "ticketTypeServices", params: { type: this.ticketType.typeAsParam } }
    }
  }
}

</script>

<style lang="scss" scoped>

.upload-area {
  .file-info {
    height: 56px;
    background-color: $white;
    border: 1px solid $light-gray-3;
    border-radius: 4px;
    position: relative;

    .file-icon {
      font-size: 24px;
      width: 24px;
      margin-right: 24px;
    }

    .progress {
      height: 100%;
      background-color: $light-gray;
      width: var(--progress);
      transition: width .1s;
    }

    .content {
      position: absolute;
      height: 54px;
      padding: 0 12px;
      font-size: 14px;
      overflow: hidden;

      .info {
        overflow: hidden;

        .name {
          font-family: $secondary-font;
          font-weight: 400;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }

      .action {
        width: 24px;
        margin-left: 12px;
        font-size: 16px;

        .remove-button {
          color: $dark-gray;

          &:hover {
            color: $orange;
          }
        }
      }
    }
  }

  .file-selection {
    border: 1px dashed $light-orange-2;
    border-radius: 4px;
    padding: 16px;

    &.error {
      border-color: $error;
    }

    .icon {
      font-size: 20px;
      color: $orange;
    }

    * + * {
      margin-top: 8px;
    }
  }

  .errors {
    color: $error;

    .error{
      display: block;
      font-size: 12px;
      line-height: 1;
      padding-top: 4px;
    }
  }
}

</style>


<template lang="pug">

  .upload-area
    template(v-if="uploaded || uploading")
      .file-info.flex
        .progress(:style="cssVars")
        .content.flex.vertical-center.full-width
          i.icon.file-icon.fal.fa-file-excel.center.no-shrink
          .info.flex.column-direction.grow
            span.name {{ fileName }}
            span.size {{ uploadSize }}
          .action.no-shrink.flex.center
            template(v-if="uploading")
              i.icon.fal.fa-spinner-third.fa-spin
            template(v-else)
              app-button.remove-button(theme="link", @click="removeFile")
                i.fal.fa-trash-alt

    template(v-else)
      .file-selection.flex.column-direction.vertical-center(:class="{ error: hasErrors }")
        i.icon.far.fa-file-upload
        app-button(theme="link", @click="selectFile") {{ $t('.btn.add.label') }}
        span {{ $t('.restrictions') }}
      .errors(v-if="hasErrors")
        span.error(
          v-for="error in errors",
          :key="error"
        ) {{ error }}

</template>


<script>

// Libs
import axios from "axios"
import FileChecksum from "@/lib/checksum"

export default {
  props: {
    file: { type: [Object, File], default: () => ({}) }
  },

  data() {
    return {
      i18nScope: "suppliers.deals.products.data-loads.new.upload-area",

      uploaded:  false,
      uploading: false,

      progress: {
        loaded: 0,
        total:  0
      },

      errors: []
    }
  },

  computed: {
    percentage() {
      const { total, loaded } = this.progress

      // Evita divisão por 0
      if (!total) return 0

      let value = (loaded / total) * 100
      return parseInt(value, 10)
    },

    cssVars() {
      return {
        "--progress": `${this.percentage}%`
      }
    },

    uploadSize() {
      let loaded = this.$asHumanSize(this.progress.loaded)
      let total = this.$asHumanSize(this.progress.total)
      return `${loaded}/${total}`
    },

    hasFile() {
      return _.present(_.get(this.file, "name"))
    },

    fileName() {
      if (!this.hasFile) return null
      return this.file.name
    },

    hasErrors() {
      return _.present(this.errors)
    }
  },

  created() {
    if (this.hasFile) {
      this.uploaded = true
      this.progress = {
        loaded: this.file.size,
        total:  this.file.size
      }
    }
  },

  methods: {
    readFile() {
      return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.addEventListener("load", () => resolve(reader.result))
        // eslint-disable-next-line prefer-promise-reject-errors
        reader.addEventListener("error", () => reject(`Error reading ${this.file.name}`))

        reader.readAsArrayBuffer(this.file)
      })
    },

    removeFile() {
      this.uploaded = false

      this.$emit("update:file", null)
      this.$emit("input", null)
    },

    async submit() {
      if (!this.file) return

      this.progress = {
        loaded: 0,
        total:  this.file.size
      }

      this.uploading = true

      try {
        const blob = await this.createBlob()
        const data = await this.readFile()

        await axios.put(blob.direct_upload.url, data, {
          headers: blob.direct_upload.headers,

          transformRequest: [(requestData, requestHeaders) => {
            delete requestHeaders.put["Content-Type"]
            return requestData
          }],

          onUploadProgress: ({ loaded, total }) => {
            this.progress = { loaded, total }
          }
        })

        this.uploaded = true
        this.$emit("input", blob.signed_id)
      }
      catch (error) {
        this.$err.log(error)
        this.$notifications.error(this.$t(".notifications.submit.failure"))
      }
      finally {
        this.uploading = false
      }
    },

    async createBlob() {
      const checksum = await FileChecksum.create(this.file)

      const response = await this.$newSdk.blobs.create({
        params: {
          blob: {
            filename:     this.file.name,
            content_type: this.file.type,
            byte_size:    this.file.size,
            checksum
          }
        }
      })

      return response.data
    },

    validateFile({ size, type }) {
      this.errors = []

      if (size > 20971520) {
        this.errors.push(this.$t(".errors.size"))
      }
      if (type !== "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") {
        this.errors.push(this.$t(".errors.type"))
      }

      return !this.hasErrors
    },

    selectFile() {
      const input = document.createElement("input")
      input.type = "file"
      input.accept = ".xlsx"  // ex com vários: '.xlsx, .csv'
      input.style.display = "none"
      input.dataset.testid = "uploadInput"

      input.addEventListener("change", async () => {
        let file = input.files[0]
        if (!file || !this.validateFile(file)) return

        this.$emit("update:file", file)

        // XXX this.submit utiliza this.file, então é preciso atualizar a prop e isso leva um ciclo do Vue
        await this.$nextTick()

        await this.submit()

        if (input.parentNode) input.parentNode.removeChild(input)
      })

      document.body.appendChild(input)
      input.click()
    }
  }
}

</script>

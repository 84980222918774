<style lang="scss" scoped>

.services-selected-badge {
  background-color: $light-gray;
  height: 54px;
  width: 54px;
  border-radius: 8px;

  .count {
    font-family: $secondary-font;
    font-size: 16px;
    color: $dark-gray;
    font-weight: 400;
  }

  .icon {
    color: $gray-3;
    margin-right: 4px;
    font-size: 14px;
  }
}

</style>


<template lang="pug">

  .services-selected-badge.flex.center.vertical-center
    i.icon.fas.fa-wrench
    app-span.count(:value="servicesCount")

</template>


<script>

export default {
  props: {
    servicesCount: { type: Number, required: true }
  },

  data() {
    return {
      i18nScope: "tickets.drafts.bookings.edit.services-selected-badge"
    }
  }
}

</script>

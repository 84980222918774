<template lang="pug">

  .mobiauto-version-row(:class="{ loading }")
    .column.id-column.flex.vertical-center
      id-tag.id-tag(
        :value="mobiautoVersion.id",
        data-testid="vehicleModelId"
      )

    .column.brand-column.flex.vertical-center
      vehicle-manufacturer-icon.icon-model(:value="manufacturer.name")
      app-span.text(
        :value="manufacturer.name",
        crop,
        data-testid="vehicleModelManufacturer"
      )

    .column.vehicle-column.flex.vertical-center
      app-button.button(
        theme="link",
        :to="showRoute"
      )
        .content.flex.vertical-center
          template(v-if="!mobiautoVersion.id")
            i.alert-icon.fal.fa-exclamation-triangle

          app-span.link(
            :value="mobiautoVersion.model.name",
            crop,
            data-testid="vehicleModelName"
          )

          i.diamond-icon.fa.fa-diamond
          app-span.link(data-testid="vehicleModelVersion") {{ mobiautoVersion.name }}

          i.diamond-icon.fa.fa-diamond
          template(v-if="mobiautoVersion.year")
            app-span.link(data-testid="vehicleModelYear") {{ mobiautoVersion.year }}
          template(v-else)
             app-span.text {{ $t('.yearRange.label', { initial: mobiautoVersion.$get('mobiautoVersion.initialYear'), final: mobiautoVersion.$get('mobiautoVersion.finalYear') } )}}

    .column.flex.vertical-center
      app-span.text(:value="$t(`models.vehicleModel.enums.typePowerSource.${mobiautoVersion.type}`)")
      template(v-if="hasCombustion")
        i.diamond-icon.fa.fa-diamond
        template(v-if="mobiautoVersion.fuel")
          app-span.text(
            data-testid="vehicleModelFuel",
            :value="$t(`models.vehicleModelCombustion.enums.fuel.${mobiautoVersion.fuel}`)"
          )
        template(v-else)
          app-span -

    .column.fipe-column.flex.vertical-center
      app-span.text-secondary(data-testid="vehicleModelFipe") {{ mobiautoVersion.$get('mobiautoVersion.fipeId') }}

    .column.category-column.flex.vertical-center
      template(v-if="mobiautoVersion.category")
        app-span.text(
          data-testid="vehicleModelCategory"
        ) {{ $t(`models.vehicleModel.enums.category.${mobiautoVersion.category}`) }}
      template(v-else)
        app-span.text -

    .column.actions-column

</template>


<script>

import MobiautoVersionRow from "@/views/vehicle-models/index/mobiauto-version-row.vue"

export default {
  extends: MobiautoVersionRow,

  computed: {
    hasCombustion() {
      // XXX deveria ser `this.vehicleModel`. Essa listagem foi herdada de outra com modelo diferente
      // TODO: refazer listagem com modelos de VehicleModel
      return ["VehicleModels::Combustion", "VehicleModels::Hybrid"].includes(this.mobiautoVersion.type)
    },

    showRoute() {
      if (_.blank(this.mobiautoVersion.id)) return this.newRoute

      return {
        name:   "vehicleModel",
        params: {
          id: this.mobiautoVersion.id
        }
      }
    }
  }
}

</script>

import Base from "./base"


class ServiceOrderPostponement extends Base {
  static get attrs() {
    return _.uniq([
      ...super.attrs,

      "from",
      "to",
      "description",
      "createdAt",

      "authorId",
      "authorName"
    ])
  }

  static get constraints() {
    return {
      to:          { presence: true },
      description: { presence: true }
    }
  }
}


export default ServiceOrderPostponement

<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle
      cx="5"
      cy="21"
      r="2.5"
      :stroke="colorHash"
    />
    <circle
      cx="18"
      cy="21"
      r="2.5"
      :stroke="colorHash"
    />
    <path
      d="M13.4899 16.9445H9.00877C8.7401 16.9445 8.48244 16.8364 8.29246 16.644L6.85985 15.4842C6.66987 15.2918 6.41221 15.1837 6.14354 15.1837H3.76324C3.60597 15.1837 3.45086 15.2207 3.3102 15.292L2.24024 15.8338C0.605863 16.6615 -0.279375 17.0722 0.0789844 18.887L0.322161 20.1185C0.416863 20.5981 0.83262 20.9433 1.31551 20.9433H2.69326L3.19977 19.9173H1.31551L1.07233 18.6858C0.803559 17.3247 1.46749 17.2648 2.69327 16.6441L3.76324 16.2097L6.14354 16.2097L7.57615 17.3695C7.9561 17.7543 8.47144 17.9705 9.00877 17.9705H13.4899C13.7076 17.9705 13.924 17.935 14.1305 17.8652L16.8553 16.9453C20.6391 16.9833 21.7524 17.2701 22.8061 18.6072C22.9563 18.7979 22.9866 18.9436 22.987 19.0391C22.9874 19.1411 22.9534 19.2661 22.8531 19.4046C22.6419 19.6963 22.1778 19.9739 21.5769 19.9739L20.2386 19.9173L20.7451 20.9433L21.5769 20.9999C23.3549 20.9999 24.7069 19.3746 23.5978 17.9672C22.3281 16.3559 20.9084 15.9588 16.8604 15.9193C16.7515 15.9183 16.6406 15.9363 16.5373 15.9712L13.8102 16.8919C13.7069 16.9267 13.5987 16.9445 13.4899 16.9445Z"
      :fill="colorHash"
    />
    <path
      d="M7.57615 19.9173H15.1735L14.667 20.9433H8.08236L7.57615 19.9173Z"
      :fill="colorHash"
    />
    <path
      d="M4 15.5L4.39867 15.1167C5.1438 14.4002 6.13738 14 7.1711 14H12.4751C13.3845 14 14.2667 14.3099 14.9763 14.8785L17 16.5"
      :stroke="colorHash"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M8 4C8 5.65685 9.34315 7 11 7C12.6569 7 14 5.65685 14 4C14 2.34315 12.6569 1 11 1C9.34315 1 8 2.34315 8 4ZM11 0C8.79086 0 7 1.79086 7 4C7 6.20914 8.79086 8 11 8C13.2091 8 15 6.20914 15 4C15 1.79086 13.2091 0 11 0Z"
      :fill="colorHash"
    />
    <path
      d="M15 10H7C5.68703 10 4.57105 10.8435 4.16437 12.0181C3.98368 12.54 3.55228 13 3 13C3 10.7909 4.79086 9 7 9H15C17.2091 9 19 10.7909 19 13V15C18.4477 15 18 14.5523 18 14V13C18 11.3431 16.6569 10 15 10Z"
      :fill="colorHash"
    />
  </svg>
</template>


<script>

import SupplierIconBase from "./supplier-icon-base"

export default {
  name: "PremiumIcon",

  extends: SupplierIconBase
}

</script>

<style lang="scss" scoped>

.section + .section {
  margin-top: 24px;
  padding-top: 24px;
  border-top: 1px solid $light-gray-3;
}

.title {
  font-family: $secondary-font;
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 4px;
}

.subtitle {
  font-family: $primary-font;
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 22px;
  margin-bottom: 16px;
}

.info-label + .info-label {
  margin-top: 10px;
}

.info-section {
  margin-top: 32px;

  .title {
    margin-bottom: 16px;
  }
}

.fields {
  margin-bottom: 24px;
  padding-bottom: 24px;
  border-bottom: 1px solid $light-gray-3;
}

</style>


<template lang="pug">

  .edit-store-info

    form.form(@submit.prevent="submit", method="post")
      .section.info-section
        h2.title {{ $t('.sections.basic.title') }}

        ui-row
          ui-col(:span="4")
            info-label.info-label(
              :value="store.cnpj",
              theme="secondary",
              :tooltip="{ offset: '4', content: Store.$tAttr('cnpj') }",
              :loading="fetching"
            )
              span(slot="icon")
                img.custom(:src="storesIcon")

            info-label.info-label(
              :value="store.sapId",
              theme="secondary",
              :tooltip="{ offset: '4', content: Store.$tAttr('sapId') }",
              :loading="fetching"
            )
              span(slot="icon")
                sap-icon(color="gray")

          ui-col(:span="4")
            info-label.info-label(
              :value="store.email",
              icon="fal fa-envelope",
              theme="secondary",
              :empty-text="$t('.fields.email.empty')",
              :tooltip="{ offset: '4', content: Store.$tAttr('email') }",
              :loading="fetching"
            )

            info-label.info-label(
              :value="store.phone",
              icon="fal fa-phone",
              theme="secondary",
              :empty-text="$t('.fields.phone.empty')",
              :tooltip="{ offset: '4', content: Store.$tAttr('phone') }",
              :loading="fetching"
            )

      .section.fields
        h2.title {{ $t('.sections.manager.title') }}
        h3.subtitle {{ $t('.sections.manager.subtitle') }}

        ui-row
          ui-col(:span="6")
            input-field(
              name="store[fleetManagerName]",
              v-model="resource.fleetManagerName",
              :loading="submitting",
              :disabled="fetching",
              :placeholder="$t('.fields.fleetManagerName.placeholder')",
              :errors="errors.fleetManagerName"
            )
          ui-col(:span="6")
            input-field(
              name="store[fleetManagerEmail]",
              v-model="resource.fleetManagerEmail",
              :loading="submitting",
              :disabled="fetching",
              :placeholder="$t('.fields.fleetManagerEmail.placeholder')",
              :errors="errors.fleetManagerEmail"
            )

      section.flex.space-between.vertical-center
        app-button(
          :to="{ name: 'store', params: { id } }",
          theme="gray",
          outline,
          :disabled="submitting"
        ) {{ $t('btn.cancel') }}

        .submit-actions
          app-button.button(
            type="submit",
            :loading="submitting"
          )
            span {{ $t('.btn.submit.label') }}
            span(slot="loading") {{ $t('.btn.submit.loading') }}

</template>


<script>

// Mixins
import { mixins } from "movida-common.vue"

// Icons
import storesIcon from "@/assets/images/icons/sidebar/stores-icon.svg"

// Models
import models from "@/models"

const { FormMixin } = mixins
const { Store } = models

class StoreInfo extends Store {
  static get constraints() {
    return {
      fleetManagerName:  { presence: true, type: "string" },
      fleetManagerEmail: { presence: true, type: "string", email: true }
    }
  }
}

export default {
  name: "EditStoreDefaultTab",

  mixins: [FormMixin],

  props: {
    store:    { type: Object, default: () => ({}) },
    fetching: { type: Boolean, default: false }
  },

  data() {
    return {
      i18nScope: "stores.default-tab.edit",

      id: this.$route.params.id,

      resource: {},

      dirtyable: true,

      Store,
      storesIcon
    }
  },

  watch: {
    fetching() {
      if (this.fetching) return

      this.initialize()
    }
  },

  created() {
    if (this.fetching) return

    this.initialize()
  },

  methods: {
    initialize() {
      this.resource = new StoreInfo(this.store)
      this.initialSerializedAttributes = this.serializeAttributes()
    },

    // @override Form mixin
    serializeAttributes() {
      return this.resource.$serialize()
    },

    // @override Form mixin
    submitRequest() {
      return this.$sdk.stores.update({ id: this.id, params: this.serializeAttributes() })
    },

    submitSuccess({ data: { id, name } }) {
      this.$notifications.info(this.$t(".notifications.submit.success", { name }))

      this.$emit("update")
      this.$router.push({ name: "store", params: { id } })
    },

    submitError(err) {
      this.$err.log(err)
      this.$notifications.error(this.$t(".notifications.submit.error"))
    }
  }
}

</script>

<template lang="pug">
  finder-field(
    v-bind="forwardedProps"
    v-on="$listeners"
    debounce
    :fetch="fetch"
    searchable
  )
</template>

<script>
import models from '@/models'

export default {
  data() {
    return {
      request: null,
    }
  },
  computed: {
    forwardedProps() {
      return _.omit(
          this.$attrs,
          ['fetch', 'multiple', 'onFetchComplete', 'options', 'searchable']
      )
    }
  },
  methods: {
    async fetch(query) {
      let request
      try {
        if (this.request) this.request.cancel('Duplicated fetch')
        //request = this.$sdk.manufacturers.list({ params: { q: query } }){ params: { q: this.requestQueryParams } }
        request = this.$newSdk.vehicleModelGroups.list({ params: { q: query, no_page: true } })
        this.request = request

        const { data } = await request

        const newData = [{
          id: 0,
          name: 'Nenhum'
        }, ...data]

        const norm = this.normalizeOptions(newData)

        return norm
      } catch (error) {
        this.$err.log(error)
      } finally {
        if (request === this.request) this.request = null
      }
    },
    normalizeOptions(options) {
      return _.camelizeKeys(options)
          .map((option) => new models.VehicleModelGroup(option))
          .map((option) => ({
            value: option.id,
            label: option.name,
            data: option,
          }))
    }
  },
}
</script>

<!--
<doc lang="markdown">
Exibe um código, prefixado por # (ícone, não selecionável)

_Props_: value, size

Como usar:
```pug
  id-tag(value="25061987")
```

Ou passando valor por prop:
```
  id-tag(:value="myCodeValue")
```
</doc>
-->

<style lang="scss" scoped>

.id-tag {
  font-family: $primary-monospace-font;
  display: inline-block;
  color: $purple;

  &.crop {
    width: 100%;
  }

  .content {
    &.small {
      font-size: 12px;
    }

    &.normal {
      font-size: 14px;
    }

    &.big {
      font-size: 24px;
    }

    .text {
      font-family: $primary-monospace-font;
      font-weight: 400;
    }
  }
}

</style>


<template lang="pug">

  .id-tag(:class="{ crop }")
    .content.flex.vertical-center(:class="{[size]: true }")
      template(v-if="!hideHashtag")
        app-span.text #

      slot
        app-span.text(:value="value", :crop="crop")

</template>


<script>

export default {
  name: "IdTag",

  props: {
    value:       { type: [String, Number], default: null },
    size:        { type: String, default: "normal", validator: size => ["small", "normal", "big"].includes(size) },
    hideHashtag: { type: Boolean, default: false },
    crop:        { type: Boolean, default: false }
  },

  data() {
    return {
      i18nScope: "components.id-tag"
    }
  }
}

</script>

<style lang="scss" scoped>

.supplier-row {
  padding: 12px;
  color: $dark-gray;

  .position-badge {
    height: 24px;
    width: 24px;
    border-radius: 80px;
    background-color: $light-gray-3;
    margin-right: 12px;
    display: flex;
    justify-content: center;
    align-items: center;

    .position {
      font-family: $secondary-font;
      font-size: 14px;
      font-weight: 400;
      line-height: 17px;
    }
  }

  .content {
    overflow: hidden;

    .supplier-info {
      overflow: hidden;

      .header {
        margin-bottom: 8px;

        .best-supplier-badge {
          margin-bottom: 8px;
        }

        .trading-name {
          font-family: $secondary-font;
          font-weight: 400;
          font-size: 20px;
          line-height: 19px;
        }

        .address-and-hierarchy {
          font-size: 14px;

          .hierarchy-icon {
            margin-right: 4px;

            ::v-deep .icon {
              font-size: 14px;
            }
          }

          .address {
            overflow: hidden;
          }
        }
      }
    }

    .icon {
      color: $gray-3;
      font-size: 16px;
      font-weight: 900;
      line-height: 16px;
      margin-right: 8px;
    }

    .label {
      color: $gray-3;
      font-size: 14px;
      font-weight: 400;
      line-height: 19px;
      letter-spacing: 0em;
    }

    .diamond-icon {
      --size: 4px;
      font-weight: 900;
      font-size: var(--size);
      color: $gray-3;
      width: var(--size);
      height: var(--size);
      margin: 0 8px;
    }

    .available-date {
      margin-left: 16px;
    }

    .actions {
      margin: 0 24px 0 100px;

      ::v-deep .movida-common__app-button.primary {
        min-width: unset;
        height: 24px;
      }
    }
  }
}

</style>


<template lang="pug">

.supplier-row
  .flex
    .position-badge.flex.no-shrink
      app-span.position(:value="$t('.fields.placing', { placing })")

    .flex.vertical-center.content.grow
      .supplier-info.grow
        .header
          template(v-if="showBadge")
            best-supplier-badge.best-supplier-badge

          app-span.trading-name(:value="supplier.tradingName")
          .address-and-hierarchy.flex.vertical-center
            app-span.city.no-shrink(:value="cityAndState")
            i.diamond-icon.fas.fa-diamond
            .flex.address
              supplier-hierarchy-icon.hierarchy-icon(:value="hierarchy")
              app-span.description(crop, :value="fullAddress")

        .flex
          .flex.center.vertical-center
            i.location-icon.icon.fas.fa-map-marker-alt
            app-span.label(
              :value="$t('.fields.distanceToStore', { count: distanceToStore })"
            )

          template(v-if="pickupService")
            .flex.center.vertical-center
              i.diamond-icon.fas.fa-diamond
              i.location-icon.icon.fas.fa-people-arrows
              app-span.label(:value="$t('models.supplier.attributes.pickupService')")

          template(v-if="schedulingNotice")
            .flex.center.vertical-center
              i.diamond-icon.fas.fa-diamond
              i.location-icon.icon.fas.fa-calendar-exclamation
              app-span.label(
                :value="$t('models.supplier.attributes.schedulingNotice')"
              )

          template(v-if="rabbotPlataform")
            .flex.center.vertical-center
              i.diamond-icon.fas.fa-diamond
              i.location-icon.icon.fas.fa-calendar-exclamation
              app-span.label(
                :value="$t('models.supplier.attributes.rabbotPlataform')"
          )

      .flex.no-shrink
        supplier-available-date.available-date(:timestamp="availableDate")

        .actions.actions-column.flex.vertical-center.flex
          app-button.select(
            size="small",
            @click="emit('show')"
          ) {{ $t('.actions.btn.select.label') }}

</template>


<script>

// Extends
import SupplierInfoCardVue from "../../components/supplier-info-card.vue"

// Components
import SupplierAvailableDate from "../../edit/supplier-available-date.vue"

export default {
  components: {
    SupplierAvailableDate
  },

  extends: SupplierInfoCardVue,

  props: {
    index:    { type: Number, required: true },
    disabled: { type: Boolean, default: false }
  },

  data() {
    return {
      i18nScope: "tickets.drafts.bookings.suppliers.index.supplier-row",

      showPopover: false
    }
  },

  computed: {
    placing() {
      return this.index + 1
    },

    availableDate() {
      return _.get(this.serviceOrderSupplier, "supplierAvailableAt.startAt")
    }
  },

  methods: {
    emit(...args) {
      if (!this.disabled) this.$emit(...args)
    }
  }
}

</script>

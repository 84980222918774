<!--
<doc lang="markdown">
Exibe um campo de busca com bordas arredondadas, feito para ser usado isoladamente e em destaque

_Props_: type, maxlength, mask e demais _props_ do mixin Field

Como usar:
```pug
search-field(
  name="search[q]",
  v-model="params.q",
  :loading="loading",
  :placeholder="$t('.fields.q.placeholder')",
  :maxlength="7",
  mask="SSS0A00",
  icon-left="far fa-search",
  :errors="errors.q",
  @search="search"
)
```
</doc>
-->

<style lang="scss" scoped>

.search-field {
  .input {
    position: relative;
    font-size: 14px;

    &.disabled {
      cursor: not-allowed;

      * {
        cursor: not-allowed;
      }
    }

    .input-inner {
      appearance: none;
      border-radius: 80px;
      border: 1px solid $light-gray-4;
      font-size: inherit;
      font-weight: 300;
      padding: 16px 24px 16px 52px;
      width: 100%;

      &.error {
        border-color: $error;
      }

      &:hover {
        border-color: $orange;
      }

      &:focus {
        border: 2px solid $orange;
        padding: 15px 23px 15px 51px;
      }
    }

    .icon {
      position: absolute;
      user-select: none;
      left: 27px;

      &.spin {
        animation: fa-spin 0.6s infinite linear;
        cursor: wait;
      }
    }
  }

  .error-message {
    color: $error;
    margin-top: 4px;
    font-size: 12px;
  }
}

</style>


<template lang="pug">

  .search-field
    .input.flex.vertical-center(:class="{ disabled }")
      input.input-inner(
        :class="{ disabled, error: hasErrors }",
        :id="inputId",
        :autofocus="autofocus",
        :disabled="disabled",
        :readonly="readonly",
        :name="name",
        :placeholder="placeholder",
        :type="type",
        :value="value",
        :maxlength="maxlength",
        v-mask="mask",
        @input="onEmit('input', $event.target.value)",
        @keyup.enter="onEmit('search', value)"
      )
      template(v-if="loading")
        i.icon.fas.fa-spinner-third.spin
      template(v-else)
        i.icon.fal.fa-search.search-icon(:class="{ disabled }")

    template(v-if="hasMessageErrors")
      .error-message.flex.center
        span {{ errors[0] }}

</template>


<script>

// Mixins
import FieldMixin from "@/mixins/field"

export default {
  name: "SearchField",

  mixins: [FieldMixin],

  props: {
    type:      { type: String, default: "text" },
    maxlength: { type: Number, default: null },
    mask:      { type: [String, Object], default: null }
  },

  data() {
    return {
      i18nScope: "tickets.new.search-field"
    }
  },

  computed: {
    hasMessageErrors() {
      return _.present(this.errors)
    }
  },

  methods: {
    onEmit(event, value) {
      if (!this.disabled) this.$emit(event, value === "" ? null : value)
    }
  }
}

</script>

<style lang="scss" scoped>

.vehicle-model-group-option {
  padding-left: 10px;
  margin-top: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid $light-gray-3;
  cursor: pointer;

  .name {
    padding-top: 5px;
    font-size: 14px;
  }

  min-height: initial;
  line-height: initial;

  overflow-x: hidden; // XXX: overflow horizontal

  * {
    cursor: pointer;
  }

  .remove-icon-content {
    display: inline-block;
    width: 24px;
    height: 24px;
    line-height: 24px;
    text-align: center;
    border-radius: 50px;
    color: $gray-2;
    transition: all 100ms linear;
  }

  &.active {
    color: $primary;
    background: $light-gray-2;
  }

  &.focus {
    background: $light-gray-2;
    color: $dark-primary-2;

    .remove-icon-content {
      background: $light-gray-3;
      color: $primary;
    }
  }
}

.id-tag-column {
  margin-right: 10px;
}

.name {
  font-family: $secondary-font;
  font-size: 18px;
  font-weight: normal;
  color: $dark-gray;
  word-break: normal;
  overflow-wrap: break-word;
  margin-bottom: 4px;
  display: block;

  &.active {
    color: $primary;
  }

  &.focus {
    color: $dark-primary-2;
  }
}

.description {
  display: block;
  font-size: 14px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 700px;
}

.vehicles-count-column {
  flex-shrink: 0;
  font-size: 16px;
  font-weight: normal;
  height: 100%;
  color: $gray-4;

  .fa-car-mechanic {
    margin-right: 8px;
    color: $gray-2;
  }
}

.content-column {
  flex-grow: 1;
}

.actions-column {
  min-width: 42px;
  margin-left: 12px;
}

</style>

<template lang="pug">

  .vehicle-model-group-option.flex.vertical-center(:class="{ focus, active }")
    .id-tag-column.flex.center.vertical-center
      id-tag.id-tag(:value="vehicleModelGroup.id")

    .content-column
      label.name(:class="{ focus, active }") {{ vehicleModelGroup.name }}
      template(v-if="!minimal")
        label.description(:class="{ focus, active }") {{ vehicleModelGroup.description }}

    template(v-if="!minimal")
      .vehicles-count-column.flex.vertical-center
        i.far.fa-car-mechanic
        span {{ vehicleModelGroup.vehicleModelsCount || 0 }}

    .actions-column.center(v-if="!selected")
      .remove-icon-content(v-if="active")
        i.fal.fa-times

</template>


<script>

export default {
  name: "VehicleModelGroupOption",

  props: {
    option:   { type: Object, required: true },
    active:   { type: Boolean, default: false },
    focus:    { type: Boolean, default: false },
    selected: { type: Boolean, default: false },
    // minimal: esconde atributos auxiliares (descrição, contagem de veículos)
    minimal:  { type: Boolean, default: false }
  },

  data() {
    return {
      i18nScope: "components.vehicle-model-group.select-field.vehicle-model-group-option"
    }
  },

  computed: {
    vehicleModelGroup() {
      return this.option.data
    }
  }
}

</script>

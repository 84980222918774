// Base
import BaseRequests from "@/modules/new-sdk/requests/Base.js"

// Models
import models from "@/models"
const { VehicleServiceOrder } = models

class VehicleServiceOrdersRequests extends BaseRequests {
  static get selector() {
    return "vehicles.serviceOrders"
  }

  all({ vehicleId, params = {}, options = {} } = {}) {
    this.verifyData("all", { vehicleId })

    return this.action({
      method:  "get",
      url:     `/vetor/vehicles/${vehicleId}/service_orders`,
      params,
      options: {
        model: VehicleServiceOrder,

        ...options
      }
    })
  }

  find({
    serviceOrderId, vehicleId, params = {}, options = {}
  } = {}) {
    this.verifyData("find", { vehicleId, serviceOrderId })

    return this.action({
      method:  "get",
      url:     `/vetor/vehicles/${vehicleId}/service_orders/${serviceOrderId}`,
      params,
      options: {
        model: VehicleServiceOrder,

        ...options
      }
    })
  }

}

export default VehicleServiceOrdersRequests

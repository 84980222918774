import Base from "../base"

class MovidaContact extends Base {
  static get attrs() {
    return _.uniq([
      ...super.attrs,
      "phone",
      "label"
    ])
  }
}

export default MovidaContact

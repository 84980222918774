<style lang="scss" scoped>

.app-span {
  &.crop {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
  }
}

</style>


<template lang="pug">

  span.app-span(
    ref="textElement",
    :class="{ crop }",
    v-tooltip="tooltipOptions",
    v-observe-visibility="handleCropping"
  )
    slot
      | {{ value }}

</template>


<script>

export default {
  name: "AppSpan",

  props: {
    value:              { type: [String, Number], default: null },
    crop:               { type: Boolean, default: false },
    cropTooltipOptions: { type: Object, default: () => ({}) }
  },

  data() {
    return {
      i18nScope: "components.app-span",

      isCropped: false
    }
  },

  computed: {
    cropTooltip() {
      return this.value
    },

    tooltipOptions() {
      if (!this.crop || !this.isCropped) return null

      return _.merge(
        {
          content:   this.value,
          placement: "top",
          offset:    "4"
        },
        this.cropTooltipOptions
      )
    }
  },

  watch: {
    cropTooltip() {
      this.handleCropping()
    }
  },

  created() {
    window.addEventListener("resize", this.handleCropping)
  },

  destroyed() {
    window.removeEventListener("resize", this.handleCropping)
  },

  methods: {
    async handleCropping() {
      // XXX necessário para atualizar o layout antes de pegar a referência
      await this.$nextTick()

      const { textElement } = this.$refs
      if (_.blank(textElement)) return

      this.isCropped = textElement.scrollWidth !== textElement.clientWidth
    }
  }
}

</script>

/* eslint-disable no-console */
import { register } from "register-service-worker"
import { events }   from "utils.vue"


if (process.env.NODE_ENV === "production") {
  register(`${process.env.BASE_URL}service-worker.js`, {
    ready() {
      console.log(`App is being served from cache by a service worker.
                   For more details, visit https://goo.gl/AFskqB`)
    },

    cached() {
      console.log("Content has been cached for offline use.")
    },

    updated() {
      events.$emit("app:outdated")
    },

    offline() {
      console.log("No internet connection found. App is running in offline mode.")
    },

    error(error) {
      console.error("Error during service worker registration:", error)
    }
  })
}

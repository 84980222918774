import _       from "@caiena/lodash-ext"
import Vue     from "vue"
import VueGtag from "vue-gtag"

import { conf, events } from "utils.vue"

const id = _.get(conf, "googleAnalytics.id")

if (process.env.NODE_ENV === "production" && _.present(id)) {
  events.$on("app:init", ({ router }) => {
    Vue.use(VueGtag, {
      config: { id },
      router
    })
  })
}

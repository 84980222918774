<script>

// Extends
import DataLoads from "@/views/suppliers/deals/products/data-loads/index"

export default {
  name: "SupplierDealMaintenancePackagesDataLoads",

  extends: DataLoads,

  data() {
    return {
      i18nScope: "suppliers.deals.maintenance-packages.data-loads.index"
    }
  },

  computed: {
    backRoute() {
      return {
        name:   "supplierDealMaintenancePackages",
        params: { id: this.id, dealId: this.dealId },
        query:  this.backRouteQuery
      }
    }
  },

  methods: {
    showRoute(dataLoad) {
      return {
        name:   "supplierDealMaintenancePackagesDataLoad",
        params: { id: this.id, dealId: this.dealId, dataLoadId: dataLoad.id }
      }
    },

    // @override Fetchable mixin
    fetch() {
      return this.$sdk.deals.listMaintenancePackagesDataLoads({ id: this.dealId, params: this.httpParams })
    }
  }
}

</script>

<style lang="scss" scoped>

</style>

<template lang="pug">
  finder-field(
    v-bind="forwardedProps"
    v-on="$listeners"
    :options="options"
  )
</template>

<script>
import models from "@/models"

const { VehicleModel } = models

export default {
  data() {
    return {
      i18nScope: "vehicle-models.components.vehicle-model-category-select-field"
    }
  },
  computed: {
    forwardedProps() {
      return _.omit(this.$attrs, ['options'])
    },
    options() {
      return VehicleModel.$enums.category.values.map(category => ({
        label: this.$t(`models.vehicleModel.enums.category.${category}`),
        value: category
      }))
    },
  },
}
</script>

<template lang="pug">
  finder-field(
    v-bind="forwardedProps"
    v-on="$listeners"
    autofetch
    debounce
    :fetch="fetch"
    searchable
    :value="value"
  )
</template>

<script>
const DOMAINS = ["gtf", "rac"]

export default {
  props: {
    domain: {
      type:      String,
      default:   null,
      validator: (value) => DOMAINS.includes(value)
    },

    value: { type: String, default: null }
  },

  data() {
    return {
      currentOption: null,
      request:       null
    }
  },

  computed: {
    forwardedProps() {
      return _.omit(
        this.$attrs,
        ["fetch", "multiple", "onFetchComplete", "options", "searchable"]
      )
    }
  },

  watch: {
    value(value) {
      this.updateCurrentOption(value)
    }
  },

  methods: {
    async fetch(query) {
      let request
      try {
        if (this.request) this.request.cancel("Duplicated fetch")

        request = this.$newSdk.monitoring.consultants.all({
          params: {
            domain: this.domain,
            q:      query
          }
        })
        this.request = request

        if (this.value && this.value != this.currentOption?.value) {
          await this.updateCurrentOption(this.value)
        }

        const { data } = await request

        const options = data.map(({ rid, name }) => ({
          label: name,
          value: rid
        }))

        return _.chain([this.currentOption, ...options])
          .filter(_.identity)
          .uniqBy("value")
          .value()
      }
      catch (error) {
        this.$err.log(error)
      }
      finally {
        if (this.request == request) this.request = null
      }
    },

    async updateCurrentOption(value) {
      if (!value) {
        this.currentOption = null
        return
      }

      const { data } = await this.$newSdk.monitoring.consultants.find({ consultantRid: value })

      this.currentOption = {
        label: data.name,
        value: data.rid
      }
    }
  }
}
</script>

<style lang="scss" scoped>

.vehicles-table-header {
  display: grid;
  grid-template-columns: var(--grid-columns);

  background-color: $light-gray;
  height: 40px;
  border-bottom: 1px solid $light-gray-3;
  border-top: 1px solid $light-gray-3;

  &.loading {
    border-bottom: 2px solid $light-orange-2;
  }

  .unsortable {
    padding: 0 12px;
  }

  .column {
    font-size: 12px;

    .icon {
      font-size: 16px;
      color: $gray-2;
    }

    .text {
      font-family: $secondary-font;
      font-size: 14px;
      color: $dark-gray;
      font-weight: 400;
    }

    .gmf {
      height: 8px;
      margin-left: 8px;
    }

    .loader-icon,
    .download-icon {
      font-size: 16px;
      color: $orange;
    }

    .download-icon {
      cursor: pointer;

      &.downloading {
        cursor: progress;
        color: $light-gray-4;
      }
    }
  }
}

</style>


<template lang="pug">

  .vehicles-table-header(:class="{ loading }", :style="styles")
    .column.state-column(data-testid="stateColumn")
      sortable-table-header-column(
        sort-param="last_movement_at",
        :sort-order="sortOrder",
        :active="isSortedBy('last_movement_at')",
        @sort="param => $emit('sort', param)"
      )
        span.text {{ $t('.columns.state.label') }}

    .column.license-plate-column.unsortable.flex.vertical-center(data-testid="licensePlateColumn")
      span.text {{ $t('.columns.licensePlate.label') }}

    .column.vehicle-column.unsortable.flex.vertical-center(data-testid="vehicleColumn")
      span.text {{ $t('.columns.vehicle.label') }}

    .column.store-column.unsortable.flex.vertical-center(data-testid="storeColumn")
      span.text {{ $t('.columns.store.label') }}

    .column.service-order-column.unsortable.flex.vertical-center(data-testid="serviceOrderColumn")
      span.text {{ $t('.columns.serviceOrder.label') }}

    .column.loader-column.unsortable.flex.vertical-center.center
      template(v-if="loading")
        i.loader-icon.fas.fa-spin.fa-spinner

      template(v-else)
        vehicles-spreadsheet-download(:request-query-params="requestQueryParams")
          template(#default="{ props: { downloading, onDownload, percentage } }")
            i.download-icon.far.fa-arrow-to-bottom(
              :class="{ downloading }",
              @click="onDownload",
              data-testid="downloadButton"
            )

</template>


<script>

import VehiclesSpreadsheetDownload from "./vehicles-spreadsheet-download.vue"

export default {
  components: {
    VehiclesSpreadsheetDownload
  },

  props: {
    loading:     { type: Boolean, default: false },
    sortParam:   { type: String, required: true },
    sortOrder:   { type: String, required: true },
    columnSizes: { type: String, required: true },

    requestQueryParams: { type: Object, default: () => ({}) }
  },

  data() {
    return {
      i18nScope: "vehicles.index.vehicles-table-header"
    }
  },

  computed: {
    styles() {
      return {
        "--grid-columns": this.columnSizes
      }
    }
  },

  methods: {
    isSortedBy(param) {
      return this.sortParam === param
    }
  }
}

</script>

// Base
import BaseRequests from "../Base"

// Models
import models from "@/models"
const { MovidaSupplier } = models

class MonitoringSupplierRequests extends BaseRequests {
  static get selector() {
    return "monitoring.suppliers"
  }

  find({ supplierRid, params = {}, options = {} } = {}) {
    this.verifyData("find", { supplierRid })

    return this.action({
      method:  "get",
      url:     `/monitoring/suppliers/${supplierRid}`,
      params,
      options: {
        model: MovidaSupplier,

        ...options
      }
    })
  }
}

export default MonitoringSupplierRequests

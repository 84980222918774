import Enum from "@caiena/enum"


const BUSINESS_UNITS = new Enum({
  rac:          "rac",  // XXX: quando veículo está parado em loja
  rac_eventual: "rac_eventual",
  rac_mensal:   "rac_mensal",
  mensal_flex:  "mensal_flex",
  zkm:          "zkm",
  gtf:          "gtf",
  seminovos:    "seminovos"
})


const RAC_BUSINESS_UNITS = new Enum({
  rac_eventual: "rac_eventual",
  rac_mensal:   "rac_mensal",
  mensal_flex:  "mensal_flex"
})


const STORE_BUSINESS_UNITS = new Enum({
  rac:       "rac",
  seminovos: "seminovos",
  gtf:       "gtf",
  zkm:       "zkm",
  cargo:     "cargo"
})


export { BUSINESS_UNITS, RAC_BUSINESS_UNITS, STORE_BUSINESS_UNITS }

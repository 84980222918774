<template lang="pug">

  .vehicles-spreadsheet-download
    slot(:props="{ downloading, onDownload, percentage }")

</template>


<script>

export default {
  props: { requestQueryParams: { type: Object, default: () => ({}) } },

  data() {
    return {
      i18nScope: "vehicles.index.vehicles-spreadsheet-download",

      downloading: false,

      progress: {
        loaded: 0,
        total:  0
      }
    }
  },

  computed: {
    percentage() {
      const { total, loaded } = this.progress

      // Evita divisão por 0
      if (!total) return 0

      let value = (loaded / total) * 100
      return parseInt(value, 10)
    },

    cssVars() {
      return {
        "--progress": `${this.percentage}%`
      }
    },

    fileName() {
      return `veículos-${moment().format("YYYY-MM-DD")}.xlsx`
    }
  },

  methods: {
    async onDownload() {
      try {
        this.downloading = true

        const { data } = await this.fetchRequest()

        this.startDownload(data)
      }
      catch (err) {
        this.$err.log(err)
        this.$notifications.error(this.$t(".notifications.fetch.failure"))
      }
      finally {
        this.downloading = false

        this.progress = {
          loaded: 0,
          total:  0
        }
      }
    },

    fetchRequest() {
      return this.$http.post("/vetor/vehicles/search.xlsx", this.requestQueryParams, {
        responseType:       "blob",
        onDownloadProgress: this.onDownloadProgress
      })
    },

    startDownload(data) {
      const url = window.URL.createObjectURL(new Blob([data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      }))
      const link = document.createElement("a")

      link.href = url
      link.setAttribute("download", this.fileName)

      document.body.appendChild(link)
      link.click()
      link.parentNode.removeChild(link)
    }
  }
}

</script>

import Base from "./base"

class ServiceOrderSupplierAuthorizationMailDelivery extends Base {
  static get attrs() {
    return _.uniq([
      ...super.attrs,
      "to"
    ])
  }

  static get constraints() {
    return {
      to: { presence: true, email: true }
    }
  }
}

export default ServiceOrderSupplierAuthorizationMailDelivery

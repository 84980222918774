import { render, staticRenderFns } from "./sectors-list.vue?vue&type=template&id=ce0f7456&scoped=true&lang=pug&"
import script from "./sectors-list.vue?vue&type=script&lang=js&"
export * from "./sectors-list.vue?vue&type=script&lang=js&"
import style0 from "./sectors-list.vue?vue&type=style&index=0&id=ce0f7456&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ce0f7456",
  null
  
)

export default component.exports
<style scoped lang="scss">

.vehicle-part {
  display: grid;
  grid-template-columns: 70px 280px 1fr 120px 64px;

  &.loading {
    .column {
      &.id-column {
        .id-tag {
          color: $gray-2;
        }
      }

      &.sap-column {
        ::v-deep.outside {
          fill: $gray-2;
        }
      }

      &.actions-column {
        ::v-deep .movida-common__app-button {
          color: $gray-2;

          &:hover {
            color: $orange;
          }
        }
      }

      .text,
      .link ::v-deep .app-span {
        color: $gray-2;
      }
    }
  }

  .column {
    padding: 0 12px;
    border-bottom: 1px solid $light-gray-3;
    height: 48px;
    overflow: hidden;


    .symptoms-icon {
      margin-right: 8px;
      color: $gray-2;
    }

    .discarded-icon {
      margin-right: 6px;
    }

    &.models-count-column {
      .text {
        font-family: $secondary-font;
      }

      .car-icon {
        margin-right: 8px;
        color: $gray-2;
      }
    }

    ::v-deep .movida-common__app-button {
      white-space: unset;
    }

    .button {
      overflow: hidden;
    }

    .text,
    .link ::v-deep .app-span {
      font-family: $primary-font;
      font-weight: 400;
      font-size: 14px;
      color: $dark-gray;
      text-align: initial;
    }

    .link ::v-deep .app-span:hover {
      color: $orange;
    }
  }
}

</style>


<template lang="pug">

  .vehicle-part(:class="{ loading }")
    .column.id-column.flex.vertical-center
      id-tag.id-tag(
        :value="vehiclePart.id",
        data-testid="vehiclePartId"
      )

    .column.name-column.flex.vertical-center
       app-button.button(
        theme="link",
        :to="showRoute"
      )
        app-span.link(
          :value="vehiclePart.name",
          crop,
          data-testid="vehiclePartName"
        )

    .column.description-column.flex.vertical-center
      template(v-if="vehiclePart.description")
        app-span.text(
          :value="vehiclePart.description",
          crop,
          data-testid="vehiclePartDescription"
        )
      template(v-else)
        app-span.text -

    .column.symptoms-column.flex.vertical-center
      i.symptoms-icon.fal.fa-engine-warning(v-tooltip.bottom="$t('.symptoms.tooltip')")
      span.text(data-testid="vehiclePartSymptomsCount") {{ vehiclePart.symptomsCount }}

    .column.actions-column.flex.vertical-center.flex
      popover-menu(
        v-if="popoverMenuAuthorization",
        :show.sync="showPopover",
        data-testid="popoverMenu"
      )
        popover-menu-button(
          v-if="$can('read', 'VehiclePart')",
          icon="fal fa-eye",
          :to="{ name: 'vehiclePart', params: { id: vehiclePart.id }}",
          :text="$t('.actions.btn.show')",
          @close-menu="showPopover = false"
        )

        popover-menu-button(
          v-if="$can('update', 'VehiclePart')",
          icon="fal fa-pencil",
          :to="{ name: 'editVehiclePart', params: { id: vehiclePart.id }}",
          :text="$t('.actions.btn.edit')",
          @close-menu="showPopover = false"
        )

        destroy-action.destroy-button.popover-menu-button(
          :name="vehiclePart.name",
          :vehiclePartId="vehiclePart.id"
          size="normal",
          border="square",
          @click.native="showPopover = false"
          @destroy="$emit('destroy')"
        )
          .icon-content
            i.icon.fal.fa-trash-alt
          span {{ $t('.actions.btn.destroy') }}

</template>


<script>

import DestroyAction from "../components/destroy-action.vue"

export default {
  components: {
    DestroyAction
  },

  props: {
    vehiclePart: { type: Object, default: () => ({}) },
    loading:     { type: Boolean, default: false },
    showActions: { type: Boolean, default: true }
  },

  data() {
    return {
      i18nScope: "vehicle-parts.index.vehicle-part-row",

      showPopover: false,

      deleting: false
    }
  },

  computed: {
    popoverMenuAuthorization() {
      return ["read", "update", "destroy"].some(action => this.$can(action, "VehiclePart"))
    },

    showRoute() {
      if (!this.$can("read", "VehiclePart")) return null

      return {
        name:   "vehiclePart",
        params: {
          id: this.vehiclePart.id
        }
      }
    }
  }
}

</script>

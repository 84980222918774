// Base
import BaseRequests from "./Base"

// Models
import models from "@/models"
const { Quote, MovidaService, MovidaProduct } = models


class QuotesRequests extends BaseRequests {
  static get selector() {
    return "quotes"
  }

  find({ quoteId, params = {}, options = {} } = {}) {
    this.verifyData("find", { quoteId })

    return this.action({
      method:  "get",
      url:     `/quotes/${quoteId}`,
      params,
      options: {
        model: Quote,

        ...options
      }
    })
  }

  update({ quoteId, params = {}, options = {} } = {}) {
    this.verifyData("update", { quoteId })

    return this.action({
      method:  "patch",
      url:     `/quotes/${quoteId}`,
      params,
      options: {
        model: Quote,

        ...options
      }
    })
  }


  availableProducts(quoteId, { params = {}, options = {} } = {}) {
    this.verifyData("availableProducts", { quoteId })

    return this.action({
      method:  "post",
      url:     `/quotes/${quoteId}/available_products/search`,
      params,
      options: {
        model: MovidaProduct,
        ...options
      }
    })
  }


  availableServices(quoteId, { params = {}, options = {} } = {}) {
    this.verifyData("availableServices", { quoteId })

    return this.action({
      method:  "post",
      url:     `/quotes/${quoteId}/available_services/search`,
      params,
      options: {
        model: MovidaService,
        ...options
      }
    })
  }

  events(quoteId, { params = {}, options = {} } = {}) {
    this.verifyData("events", { quoteId })

    return this.action({
      method: "get",
      url:    `/quotes/${quoteId}/events`,
      params,
      options

      // XXX: não tem model definido, pois é STI
      // options: {
      //   model: MovidaService,
      //   ...options
      // }
    })
  }

}

export default QuotesRequests

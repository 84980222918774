import models from "@/models"

export default {
  props: {
    value:       { type: [Number, String, Object], default: null },
    dataAsValue: { type: Boolean, default: false }
  },

  data() {
    return {
      search:  "",
      options: [],
      option:  null,

      // Async
      fetching:   false,
      fetchError: false,
      request:    null,

      optionAttributeMapping: {
        value: "id",
        label: "name"
      }
    }
  },

  computed: {
    empty() {
      return this.options.length === 0
    },

    // TODO: refatorar e usar o nome selectedOption ao invés de option
    // workaround: usando computed como alias
    selectedOption() {
      return this.option
    }
  },

  watch: {
    value() {
      if (this.value === _.get(this.option, "value")) return

      if (_.blank(this.value)) {
        this.option = null
        return
      }

      if (!this.dataAsValue) {
        this.fetchOption(this.value)
      }
      else {
        this.option = this.dataToOption(this.value)
      }
    }
  },

  created() {
    if (_.blank(this.value)) return

    if (!this.dataAsValue) {
      this.fetchOption(this.value)
    }
    else {
      this.option = this.dataToOption(this.value)
    }
  },

  methods: {
    async fetchOption(id) {
      throw new Error("Vue mixin select/async - #fetchOption() - Must be implemented if dataAsValue is false")
    },

    dataToOption(data) {
      const formattedData = (_.present(this.model))
        ? new models[this.model](data) : data

      return {
        value: _.get(data, this.optionAttributeMapping.value),
        label: _.get(data, this.optionAttributeMapping.label),
        data:  formattedData
      }
    },

    onClose() {
      this.options = []
      this.$emit("close")
    },

    onOpen() {
      this.$emit("open")
      this._fetch()
    },

    // Guard permitindo que seja sobreescrito para alguma validação.
    // Um exemplo de uso é  mixins/select/create.js
    onSelectGuard(option) {
      return true
    },

    onSelect(option) {
      if (!this.onSelectGuard(option)) return

      this.option = option
      this.$emit("change-option", option)

      if (this.dataAsValue) {
        this.$emit("input", _.get(option, "data") || null)
      }
      else {
        // XXX precisamos enviar `null` ao invés de `undefined`
        // para que o Axios não desconsidere o parametro
        this.$emit("input", _.get(option, "value") || null)
      }
    },

    // Busca assincrona de _options_
    async _fetch() {
      let currentRequest
      this.fetchError = false

      try {
        if (this.request) this.request.cancel("Duplicated fetch")

        currentRequest = this.fetchRequest()
        this.request = currentRequest

        this.fetching = true

        const response = await currentRequest
        let normalizedData = _.camelizeKeys(response.data)

        this.onFetchSuccess(response, { normalizedData })
      }
      catch (error) {
        if (!error.cancelled) this.fetchError = true

        this.onFetchError(error)
      }
      finally {
        if (this.request === currentRequest) {
          this.request = null
          this.fetching = false

          await this.afterFetch()
        }
      }
    },

    onFetchSuccess({ data }) {
      this.options = [...data.map(entry => this.dataToOption(entry))]
    },

    onFetchError(err) {
      this.fetching = false
      console.error(err)
    },

    // @hook
    // sobrecarregue para transformar o dado após o carregamento.
    // espera-se que this.options já esteja carregado (pelo @hook onFetchSuccess)
    // verifique sucesso ou falha com this.fetchError (boolean).
    async afterFetch() {},

    // Debounce para evitar sobrecarregar servidor com disparos de busca
    fetch: _.debounce(function debounce() {
      this._fetch()
    }, 500),

    onSearch() {
      this.fetching = true
      this.fetchError = false
      this.fetch()
    },

    updateSearch(value) {
      this.search = value
    }
  }
}

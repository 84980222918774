<style scoped lang="scss">

.form-fields {
  .row + .row {
    margin-top: 24px;
  }

  .radio-label {
    padding-left: 8px;
    margin-right: 8px;
    cursor: pointer;

    .radio-icon {
      margin-left: 8px;
    }
  }

  .maintenances {
    padding-top: 24px;
    margin-top: 24px;
    border-top: 1px solid $light-gray-3;
  }
}

</style>


<template lang="pug">

  .form-fields
    ui-row.row
      ui-col(:span="5")
        input-field(
          name="vehicleModelGroupMaintenanceTable[name]",
          v-model="resource.name",
          :loading="submitting",
          :disabled="fetching",
          :errors="errors.name",
          :placeholder="$t('.fields.name.placeholder')"
          data-testid="nameInput"
        )

      ui-col(:span="7")
        ui-row(:columns="10")
          ui-col(:span="6")
            radio-field(
              name="vehicleModelGroupMaintenanceTable[kind]",
              :options="kindOptions",
              :disabled="submitting || fetching",
              v-model="resource.kind",
              :errors="errors.kind",
              data-testid="kindInput"
            )
              template(#default="{ props: { option, radioClass, checked, radioClick } }")
                input.movida-common__radio-button(
                  type="radio",
                  name="vehicleModelGroupMaintenanceTable[kind]",
                  :value="option.value",
                  :id="`vehicleModelGroupMaintenanceTable[kind]-${option.value}`"
                  :disabled="submitting || fetching || option.disabled",
                  :class="radioClass",
                  :checked="checked",
                  @click="radioClick"
                )
                .label-container.flex
                  label.radio-label(
                    :for="`vehicleModelGroupMaintenanceTable[kind]-${option.value}`",
                    :class="radioClass"
                  ) {{ option.label }}
                    i.radio-icon.fas(:class="option.icon")

    ui-row.row
      ui-col(:span="5")
        textarea-field(
          name="vehicleModelGroupMaintenanceTable[description]",
          v-model="resource.description",
          :loading="submitting",
          :disabled="fetching",
          :errors="errors.description",
          :placeholder="$t('.fields.description.placeholder')",
          optional
        )
      ui-col(:span="7")
        ui-row
          ui-col(:span="5")
            input-field.field(
              name="vehicleModelGroupMaintenanceTable[kilometrageLimitTolerance]",
              v-model="resource.kilometrageLimitTolerance",
              :loading="submitting",
              :disabled="fetching",
              :errors="this.errors.kilometrageLimitTolerance",
              :placeholder="$t('.fields.kilometrageLimitTolerance.placeholder')",
              :suffix="$t('.fields.kilometrageLimitTolerance.suffix')",
              mask="#",
              optional
            )

          ui-col(:span="5")
            input-field.field(
              name="vehicleModelGroupMaintenanceTable[timeLimitTolerance]",
              v-model="resource.timeLimitTolerance",
              :loading="submitting",
              :disabled="fetching",
              :errors="this.errors.timeLimitTolerance",
              :placeholder="$t('.fields.timeLimitTolerance.placeholder')",
              :suffix="$t('.fields.timeLimitTolerance.suffix')",
              mask="#",
              optional
            )

    maintenances-nested-fields.maintenances(
      v-model="resource.vehicleModelGroupMaintenances",
      :fetching="fetching",
      :submitting="submitting",
      :disabled="fetching",
      :errors="errors",
    )

</template>


<script>

// Components
import MaintenancesNestedFields from "./maintenances-nested-fields.vue"

// Models
import models from "@/models"
const { VehicleModelGroupMaintenanceTable } = models

export default {
  components: {
    MaintenancesNestedFields
  },

  props: {
    fetching:   { type: Boolean, default: false },
    submitting: { type: Boolean, default: false },
    errors:     { type: Object, default: () => ({}) },
    resource:   { type: Object, default: () => ({}) }
  },

  data() {
    return {
      i18nScope: "vehicle-model-groups.maintenance-tables.form.form-fields"
    }
  },

  computed: {
    kindOptions() {
      return VehicleModelGroupMaintenanceTable.$enums.kind.values.map(value => {
        let icon

        switch (value) {
          case "dealership": icon = "fa-copyright"; break
          case "multiservice": icon = "fa-car-garage"; break
          default: icon = null
        }

        return {
          value,
          icon,
          label: VehicleModelGroupMaintenanceTable.$tEnum("kind", value)
        }
      })
    }
  }
}

</script>

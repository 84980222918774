<style lang="scss" scoped>

.purchase-timeline {

  > .timeline {
    padding: 8px 0;

    .stage {
      position: relative;

      border-radius: 8px;
      border: 1px solid $light-gray-3;

      width:  26px;
      height: 26px;

      cursor: pointer;
      background: white;
      color: $gray;

      .icon {
        font-weight: 400;  // font-awesome outline
        font-size: 14px;
        color: $gray;

        &.stacked {
          --stacked-icon-size: 12px;
          position: absolute;
          right: calc(-1 * var(--stacked-icon-size) / 3);
          top:   calc(-1 * var(--stacked-icon-size) / 3);
          font-size: var(--stacked-icon-size) !important;
          border-radius: var(--stacked-icon-size);
          padding: 2px;
          background: white;

          &.warning {
            color: $dark-yellow;
          }
        }
      }

      &.past {
        border: 1px solid $light-purple-3;
        color: $purple;

        .icon {
          font-weight: 900;  // font-awesome solid
          color: $purple;
        }

        &:hover {
          border-color: $purple;
        }
      }

      &.current {
        border: 1px solid $light-purple;

        width:  40px;
        height: 40px;

        background: $purple;

        .icon {
          font-weight: 900;  // font-awesome solid
          font-size: 16px;
          color: $white;
        }

        &:hover {
          border-color: $light-purple-2;
        }

        &:active {
          background: $dark-purple;
        }
      }

      &.canceled {
        background: $gray-2;

        .icon {
          font-weight: 900; // font-awesome solid
          color: $white;
        }

        &:active {
          background: $gray-4;
        }
      }
    } // .stage

    .connector {
      width: 24px;
      border-top: 1px solid $light-gray-3;

      &.past,
      &.current {
        border-color: $light-purple-3;
      }
    }
  } // .timeline


  > .description {
  } // .description

  .text {
    font-family: $secondary-font;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 150%;

    vertical-align: center;

    &.label {
      color: $purple;
    }

    &.tip {
      color: $dark-gray;
    }

    &.canceled {
      color: $dark-gray;
      padding-left: 6px;
    }
  }

}

</style>


<template lang="pug">

  .purchase-timeline.flex.column-direction

    template(v-if="canceled")
      .timeline.flex.vertical-center
        v-popover.popover(
          placement="bottom",
          trigger="manual",
          :open="popoverFlagFor('canceled')",
          @hide="hidePopoverFor('canceled')"
        )
          .stage.canceled.flex.center.vertical-center(
            @click="showPopoverFor('canceled')"
          )
            i.icon.fa.fa-times

          template(#popover)
            component(
              :is="popoverComponentFor('canceled')",
              :service-order-rid="serviceOrder.rid",
              :quote-id="serviceOrder.quoteId",
              :show="popoverFlagFor('canceled')"
            )

        span.text.label.canceled
          | {{ $t(".stages.canceled.description.label") }}
          |
          template(v-if="serviceOrder.$present('canceledAt')")
            span.timestamp(v-tooltip="$l('time.formats.short', serviceOrder.canceledAt)")
              | {{ $fromNow(serviceOrder.canceledAt) }}


    //- se não estiver cancelada, renderiza a timeline
    template(v-else)
      .timeline.flex.vertical-center

        //- stage == "open"
        //----
        template(v-if="stageAvailable('open')")
          v-popover.popover(
            placement="bottom",
            trigger="manual",
            :open="popoverFlagFor('open')",
            @hide="hidePopoverFor('open')"
          )
            .stage.open.flex.center.vertical-center(
              :class="cssClassFor('open')",
              @click="showPopoverFor('open')"
            )
              i.icon.fa(:class="cssIconClassFor('open')")

            template(#popover)
              component(
                :is="popoverComponentFor('open')",
                :service-order-rid="serviceOrder.rid",
                :quote-id="serviceOrder.quoteId",
                :show="popoverFlagFor('open')"
              )

        //- stage == "quote_issued"
        //----
        template(v-if="stageAvailable('quote_issued')")
          .connector(:class="cssClassFor('quote_issued')")

          v-popover.popover(
            placement="bottom",
            trigger="manual",
            :open="popoverFlagFor('quote_issued')",
            @hide="hidePopoverFor('quote_issued')"
          )
            .stage.quote_issued.flex.center.vertical-center(
              :class="cssClassFor('quote_issued')",
              @click="showPopoverFor('quote_issued')"
            )
              i.icon.fa(:class="cssIconClassFor('quote_issued')")

            template(#popover)
              component(
                :is="popoverComponentFor('quote_issued')",
                :service-order-rid="serviceOrder.rid",
                :quote-id="serviceOrder.quoteId",
                :show="popoverFlagFor('quote_issued')"
              )


        //- stage == "ongoing"
        //----
        template(v-if="stageAvailable('ongoing')")
          .connector(:class="cssClassFor('ongoing')")

          v-popover.popover(
            placement="bottom",
            trigger="manual",
            :open="popoverFlagFor('ongoing')",
            @hide="hidePopoverFor('ongoing')"
          )
            .stage.ongoing.flex.center.vertical-center(
              :class="cssClassFor('ongoing')",
              @click="showPopoverFor('ongoing')"
            )
              i.icon.fa(:class="cssIconClassFor('ongoing')")
              template(v-if="serviceOrder.postponementsCount > 0")
                i.icon.stacked.warning.fa.fa-clock

            template(#popover)
              component(
                :is="popoverComponentFor('ongoing')",
                :service-order-rid="serviceOrder.rid",
                :quote-id="serviceOrder.quoteId",
                :show="popoverFlagFor('ongoing')"
              )


        //- stage == "executed"
        //----
        template(v-if="stageAvailable('executed')")
          .connector(:class="cssClassFor('executed')")

          v-popover.popover(
            placement="bottom",
            trigger="manual",
            :open="popoverFlagFor('executed')",
            @hide="hidePopoverFor('executed')"
          )
            .stage.executed.flex.center.vertical-center(
              :class="cssClassFor('executed')",
              @click="showPopoverFor('executed')"
            )
              i.icon.fa(:class="cssIconClassFor('executed')")

            template(#popover)
              component(
                :is="popoverComponentFor('executed')",
                :service-order-rid="serviceOrder.rid",
                :quote-id="serviceOrder.quoteId",
                :show="popoverFlagFor('executed')"
              )
      //- [end] timeline


      //- "description", abaixo da timeline
      //- tem variação para cada estado!
      .description.flex.column-direction

        //- stage == "open"
        //----
        template(v-if="currentStage == 'open'")
          span.text.label
            | {{ $t(".stages.open.description.label") }}
            |
            span.timestamp(v-tooltip="$l('time.formats.short', serviceOrder.issuedAt)")
              | {{ $fromNow(serviceOrder.issuedAt) }}

          span.text.tip
            | {{ $t(".stages.open.description.tip") }}
            |
            span.timestamp(v-tooltip="$l('time.formats.short', serviceOrder.scheduledAt)")
              | {{ $fromNow(serviceOrder.scheduledAt) }}


        //- stage == "quote_issued"
        //----
        template(v-else-if="currentStage == 'quote_issued'")
          span.text.label
            | {{ $t(".stages.quote_issued.description.label") }}
            |
            span.timestamp(v-tooltip="$l('time.formats.short', serviceOrder.quoteIssuedAt)")
              | {{ $fromNow(serviceOrder.quoteIssuedAt) }}

          span.text.tip
            | {{ $t(".stages.quote_issued.description.tip") }}


        //- stage == "ongoing"
        //----
        template(v-else-if="currentStage == 'ongoing'")

          span.text.label
            | {{ $t(".stages.ongoing.description.label") }}
            |
            span.timestamp(v-tooltip="$l('time.formats.short', serviceOrder.authorizedAt)")
              | {{ $fromNow(serviceOrder.authorizedAt) }}

          span.text.tip
            | {{ $t(".stages.ongoing.description.tip") }}
            |
            span.timestamp(v-tooltip="$l('time.formats.short', serviceOrder.estimatedCompletedAt)")
              | {{ $fromNow(serviceOrder.estimatedCompletedAt) }}


        //- stage == "executed"
        //----
        template(v-else-if="currentStage == 'executed'")
          span.text.label
            | {{ $t(".stages.executed.description.label") }}
            |
            span.timestamp(v-tooltip="$l('time.formats.short', serviceOrder.completedAt)")
              | {{ $fromNow(serviceOrder.completedAt) }}

      //- [end] .description

</template>


<script>

// Components
import PopoverCancellation from "./timeline-popover-cancellation.vue"
import PopoverExecuted     from "./timeline-popover-executed.vue"
import PopoverOngoing      from "./timeline-popover-ongoing.vue"
import PopoverOpen         from "./timeline-popover-open.vue"
import PopoverQuoteIssued  from "./timeline-popover-quote-issued.vue"

// Models
import models from "@/models"
const { MovidaServiceOrder } = models


export default {
  components: {
    PopoverCancellation,
    PopoverExecuted,
    PopoverOngoing,
    PopoverOpen,
    PopoverQuoteIssued
  },

  props: {
    serviceOrder: { type: MovidaServiceOrder, required: true }
  },

  data() {
    return {
      i18nScope: "monitoring.purchase-timeline",

      showPopoverCancellation: false,
      showPopoverOpen:         false,
      showPopoverQuoteIssued:  false,
      showPopoverOngoing:      false,
      showPopoverExecuted:     false
    }
  },

  computed: {
    stages() {
      // em ordem!
      return [
        "open",
        "quote_issued",
        "ongoing",
        "executed"
      ]
    },

    currentStage() {
      let state = this.serviceOrder.state

      if (state == "open") {
        if (this.quoteIssued) {
          return "quote_issued"
        }
      }

      return state
    },

    canceled() {
      return this.serviceOrder.state == "canceled"
    },

    quoteIssued() {
      return this.serviceOrder.quoteIssued
    }
  },

  methods: {
    cssClassFor(stage) {
      return {
        past:    this.isPastStage(stage),
        current: this.isCurrentStage(stage)
      }
    },

    cssIconClassFor(stage) {
      switch (stage) {
        case "open":            return "fa-file-invoice-dollar"
        case "quote_issued":    return "fa-memo"
        case "ongoing":         return "fa-truck"
        case "executed":        return "fa-check"
        default: return null
      }
    },

    isPastStage(stage) {
      switch (stage) {
        case "quote_issued": {
          return this.currentStage != stage && this.quoteIssued
        }

        default: return this.stages.indexOf(stage) < this.stages.indexOf(this.currentStage)
      }
    },

    isCurrentStage(stage) {
      return this.currentStage == stage
    },

    stageAvailable(stage) {
      return true
    },

    popoverFlagNameFor(stage) {
      switch (stage) {
        case "open":         return "showPopoverOpen"
        case "quote_issued": return "showPopoverQuoteIssued"
        case "ongoing":      return "showPopoverOngoing"
        case "executed":     return "showPopoverExecuted"
        case "canceled":     return "showPopoverCancellation"
        default: return null
      }
    },

    popoverFlagFor(stage) {
      const flagName = this.popoverFlagNameFor(stage)

      return this[flagName]
    },

    hidePopoverFor(stage) {
      const flagName = this.popoverFlagNameFor(stage)

      this[flagName] = false
    },

    showPopoverFor(stage) {
      const flagName = this.popoverFlagNameFor(stage)

      this[flagName] = true
    },

    popoverComponentFor(stage) {
      switch (stage) {
        case "open":         return PopoverOpen
        case "quote_issued": return PopoverQuoteIssued
        case "ongoing":      return PopoverOngoing
        case "executed":     return PopoverExecuted
        case "canceled":     return PopoverCancellation
        default: return null
      }
    }
  }
}

</script>

<!--

TODO: remover esse arquivo!
Só foi mantido por conta do ConfirmationModalPlugin do movida-common.vue,
que facilita modais de confirmações espalhados pela app!
- ref: https://github.com/caiena/movida-gmf/issues/1419


<doc lang="markdown">

Exibe um modal

Como usar:
```pug
modal-wnd(
  :width="width",
  :heading="heading",
  avoid-close-on-click-outside,

  footer,
  :cancel-label="this.actions.cancel",
  @cancel="confirm(false)",
  :confirm-label="this.actions.confirm",
  @confirm="confirm(true)",
)
  .movida-common__modal-body
    span {{ message }}
```

_Props_:
footer:                   Define se o rodapé padrão deve ser exibido na ausência do slot "footer" [false]
loading:                  Informa ao componente quando o aplicativo está carregando os dados      [false]
closeButton:              Define a presença de um botão com ícone para fechar o modal             [false]
heading:                  Define o texto (e a presença) de título do cabeçalho                    ['']
transition:               Transição do modal                                                      ['modal']
avoidCloseOnClickOutside: Evita o fechamento do modal ao clicar fora dele                         [false]
maxHeight:                Define se o modal deve cobrir a tela inteira verticalmente              [false]
isView:                   Define se o modal deve ter a mesma largura das demais views             [false]
width:                    Largura do modal                                                        [null]
confirmButton:            Define se o rodapé padrão possui botão de confirmação                   [true]
confirmLabel:             Texto do botão de confirmação do rodapé                                 [i18n.t('btn.submit.label')]
confirmLabelLoading:      Texto do botão de confirmação do rodapé em estado de carregamento       [i18n.t('btn.submit.loading')]
cancelLabel:              Texto do botão de cancelamento do rodapé                                [i18n.t('btn.cancel')]
theme:                    Tema do modal                                                           ['default']
modalId:                  Id do modal                                                             [random value]

_Eventos_:
confirm: Ao clicar no botão de confirmação do rodapé padrão
cancel:  Ao clicar no botão de cancelamento do rodapé padrão
open:    Ao iniciar a criação do modal
opened:  Ao finalizar a criação do modal
close:   Ao iniciar a destruição do componente
closed:  Ao finalizar a destruição do componente

_Slots_:
default: O corpo do modal
header:  Cabeçalho com padrão
footer:  Rodapé com padrão

</doc>
-->


<template lang="pug">

    portal(:to="portal")
      //- delegating class to "root" element (besides portal)
      //- ref: https://github.com/LinusBorg/portal-vue/issues/101#issuecomment-540431702
      .movida-common__modal-wnd(:id="modalId", :class="$vnode.data.staticClass")
        transition(
          :name="transition",
          @before-enter="beforeEnter",
          @after-enter="afterEnter"
          appear
        )
          .movida-common__modal-wrapper
            .movida-common__modal-container(
              v-click-outside="vClickOutsideConfig",
              :style="style",
              :class="{ [`movida-common__modal-${theme}`]: true, 'full-height': maxHeight, view: isView }"
            )

              button.movida-common__modal-close-btn(type="button", @click="close", v-if="closeButton")
                i.fal.fa-times(aria-hidden="true")

              slot(name="header")
                header.movida-common__modal-header(v-if="hasHeaderSlot || heading")
                  h1.movida-common__modal-heading {{ heading }}

              .movida-common__modal-content(v-if="hasDefaultSlot")
                slot

              slot(name="footer")
                .movida-common__modal-footer(
                  v-if="footer",
                  :class="{ 'with-border': hasDefaultSlot }"
                )
                  app-button(
                    @click="cancel",
                    :key="`modal-wnd-footer-cancel-${uuid()}`",
                    outline,
                    theme="gray",
                    :loading="loading"
                  ) {{ cancelLabel }}

                  app-button(
                    v-if="confirmButton",
                    @click="confirm",
                    :loading="loading"
                  )
                    span {{ confirmLabel }}
                    span(slot="loading") {{ confirmLabelLoading }}

</template>


<script>

// XXX: Cópia do componente em `movida-common.vue`
// ref: https://github.com/caiena/movida-gmf/issues/1419

import { i18n, events } from "utils.vue"
import { v4 as uuid } from "uuid"

import { freezeScrolling } from "@/lib"

const themeValidator = value => ["default", "photo"].includes(value.toLowerCase())

export default {
  name: "ModalWnd",

  props: {
    footer:                   { type: Boolean, default: false },
    loading:                  { type: Boolean, default: false },
    closeButton:              { type: Boolean, default: false },
    heading:                  { type: String, default: "" },
    transition:               { type: String, default: "modal" },
    avoidCloseOnClickOutside: { type: Boolean, default: false },
    maxHeight:                { type: Boolean, default: false },
    isView:                   { type: Boolean, default: false },
    width:                    { type: Number, default: null },
    confirmButton:            { type: Boolean, default: true },
    confirmLabel:             { type: String, default: () => i18n.t("btn.submit.label") },
    confirmLabelLoading:      { type: String, default: () => i18n.t("btn.submit.loading") },
    cancelLabel:              { type: String, default: () => i18n.t("btn.cancel") },
    modalId:                  { type: String, default: () => uuid() },
    theme:                    {
      type:      String,
      validator: value => themeValidator(value),
      default:   "default"
    }
  },

  data() {
    return {
      uuid,
      portal: null,

      vClickOutsideConfig: {
        handler: this.onClickOutside,
        middleware(event) {
          return event.target.className !== "modal"
        },
        events: ["mousedown"]
      }
    }
  },

  computed: {
    hasDefaultSlot() {
      return _.present(this.$slots.default)
    },

    hasHeaderSlot() {
      return _.present(this.$slots.header)
    },

    parentScrollableElem() {
      return document.body
    },

    style() {
      if (!this.width) return
      return {
        width:       `${this.width}px`,
        "max-width": `100vw`
      }
    }
  },

  // lifecycle hooks
  // --
  created() {
    const callback = portal => { this.portal = portal }

    events.$emit("create-portal", callback)
  },

  beforeDestroy() {
    // garante que, caso o modal não tenha sido fechado, o scroll seja "religado"
    this.cleanup()

    events.$emit("destroy-portal", this.portal)
  },

  destroyed() {
    this.$emit("closed")
  },

  methods: {
    onClickOutside() {
      if (this.avoidCloseOnClickOutside) return

      this.close()
    },

    // transition hooks
    // --
    beforeEnter() {
      // Já tenta congelar o scroll aqui, pois usa `position: fixed` e isso causa um "flick"
      // nos dispositivos móveis caso seja feito apenas no afterEnter()
      this.stopScrolling()
      this.$emit("open")
    },

    afterEnter() {
      // XXX garantindo que o scroll esteja congelado.
      if (this.isScrolling()) this.stopScrolling()
      this.focus()
      this.$emit("opened")
    },
    // --

    focus() {
      let autofocusEl = this.$el.querySelector("[autofocus]")
      if (autofocusEl) autofocusEl.focus()
    },

    cancel() {
      this.$emit("cancel")
      this.close()
    },

    cleanup() {
      // XXX evita "religar" scroll caso exista um overlay na página!
      // Isso permite a abertura de modal "em cima" de overlay.
      if (document.body.querySelector(".overlay")) return
      this.startScrolling()
    },

    close() {
      if (this.loading) return

      this.$emit("close")
    },

    confirm() {
      if (this.loading) return

      this.$emit("confirm")
    },

    isScrolling() {
      return !freezeScrolling.isFrozen(this.parentScrollableElem)
    },

    // "religa" scroll no body (conteúdo abaixo do overlay).
    startScrolling() {
      freezeScrolling.release(this.parentScrollableElem)
    },

    // evita scroll no body (conteúdo abaixo do overlay) enquanto o overlay
    // estiver aberto.
    stopScrolling() {
      freezeScrolling.freeze(this.parentScrollableElem)
    }
  }
}

</script>

<style lang="scss" scoped>

.form-fields {
  .section {
    padding: 16px 24px;
    gap: 16px;  // vertical

    & + .section {
      border-top: 1px solid $light-gray-3;
    }

    .row {
      grid-template-columns: repeat(2, minmax(0, 1fr));
      gap: 24px;  // horizontal
    }
  }
}

</style>


<template lang="pug">

  .form-fields
    .section.grid
      datetime-field(
        mandatory,
        v-model="resource.at",
        name="vehicleReceipt[at]",
        :disabled="submitting",
        :errors="errors.at",
        :label="$t('.fields.at.label')",
        :loading="fetching",
        :max-date="now"
      )

      extended-toggle-field(
        v-model="resource.towTransport",
        mandatory,
        name="vehicleReceipt[towTransport]",
        :disabled="submitting",
        :errors="errors.towTransport",
        :label="$t('.fields.tow-transport.label')",
        :loading="fetching"
      )

      extended-toggle-field(
        v-model="resource.ignitionProblem",
        mandatory,
        name="vehicleReceipt[ignitionProblem]",
        :disabled="submitting",
        :errors="errors.ignitionProblem",
        :label="$t('.fields.ignition-problem.label')",
        :loading="fetching"
      )

    .section.grid
      .row.grid
        fuel-level-select-field(
          v-model="resource.fuelLevel",
          mandatory,
          name="vehicleReceipt[fuelLevel]",
          :disabled="submitting",
          :errors="errors.fuelLevel",
          :label="$t('.fields.fuel-level.label')",
          :loading="fetching"
        )

        integer-field(
          v-model="resource.currentKm",
          hide-controls,
          mandatory,
          name="vehicleReceipt[currentKm]",
          suffix="km",
          :disabled="submitting",
          :errors="errors.currentKm",
          :label="$t('.fields.current-km.label')",
          :loading="fetching",
          :min="0"
        )

      textarea-field(
        v-model="resource.description",
        name="vehicleReceipt[description]",
        :disabled="submitting",
        :errors="errors.description",
        :label="$t('.fields.description.label')",
        :loading="fetching",
        :placeholder="$t('.fields.description.placeholder')"
      )

</template>


<script>

// Components
import FuelLevelSelectField from "./fuel-level-select-field.vue"

// Models
import models from "@/models"
const { VehicleReceipt } = models


export default {
  components: {
    FuelLevelSelectField
  },

  props: {
    resource:   { type: VehicleReceipt, required: true },
    fetching:   { type: Boolean, default: false },
    submitting: { type: Boolean, default: false },
    errors:     { type: Object, default: () => ({}) }
  },

  data() {
    return {
      i18nScope: "monitoring.vehicle-receipts.form-fields",

      now: moment().toISOString()
    }
  }
}

</script>

import Base from "./base"

class VetorSupplier extends Base {
  static get attrs() {
    return _.uniq([
      ...super.attrs,
      "email",
      "name",
      "tradingName",
      "cnpj",
      "fullAddress"
    ])
  }
}

class VetorStore extends Base {
  static get attrs() {
    return _.uniq([
      ...super.attrs,
      "name",
      "cnpj",
      "initials",
      "sapId"
    ])
  }
}

class ServiceOrderItem extends Base {
  static get attrs() {
    return _.uniq([
      ...super.attrs,
      "itemId",
      "description",
      "serviceOrderId",
      "userId",
      "kind",
      "partOrigin",
      "sapId",
      "freeOfCharge",
      "quantity",
      "price",
      "totalPrice",
      "discount"
    ])
  }
}

class VehicleServiceOrder extends Base {
  static get attrs() {
    return _.uniq([
      ...super.attrs,
      "serviceKind",
      "authorizedAt",
      "createdAt",
      "description",
      "state",
      "sapId",
      "totalPrice",
      "enteringAt",
      "leavingAt"
    ])
  }

  static get enums() {
    return {
      serviceKind: {
        damage:     "damage",
        preventive: "preventive",
        corrective: "corrective",

        // XXX: Retorna `null` se a categoria não estiver mapeada.
        null: "null"
      }
    }
  }

  static get relations() {
    return {
      vehicle:           { type: "hasOne", model: "Vehicle" },
      supplier:          { type: "hasOne", model: VetorSupplier },
      store:             { type: "hasOne", model: VetorStore },
      serviceOrderItems: { type: "hasMany", model: ServiceOrderItem }
    }
  }
}

export default VehicleServiceOrder

/**
 * Plugin para adicionar cabeçalho com inputs de texto para as datas do período
 * ao Flatpickr
 *
 * @example
 * const fp = flatpickr(inputElement, {
 *   plugins: flatpickrRangeInputPlugin({ labels: { start: 'De', end: 'Até' } })
 * })
 *
 * @returns {(fp: object) => object }
 */
const flatpickrRangeInputPlugin = ({ labels } = {}) => {
  const createInput = (value, onChange) => {
    const input = document.createElement('input')
    input.className = 'input'
    input.type = 'text'
    input.value = value
    input.addEventListener('change', onChange)
    return input
  }

  const createInputField = (label, input) => {
    const container = document.createElement('div')
    const labelElement = document.createElement('label')
    const labelDiv = document.createElement('div')

    container.className = 'range-input-field'

    labelDiv.innerText = label
    labelDiv.className = 'label'
    labelElement.appendChild(labelDiv)
    labelElement.appendChild(input)
    container.appendChild(labelElement)

    return container
  }

  return function (fp) {
    let startInput, endInput
    return {
      onChange(selectedDates) {
        const [start, end] = selectedDates.map((date) => moment(date).format('L'))
        startInput.value = start || ''
        endInput.value = end || ''
      },
      onOpen() {
        startInput.focus()
      },
      onReady() {
        const inputsContainer = document.createDocumentFragment()

        const inputs = document.createElement('div')
        inputs.className = 'inputs'
        inputsContainer.appendChild(inputs)

        startInput = createInput('', (event) => {
          const value = moment(event.target.value, 'L')
          const [start, end] = fp.selectedDates

          if (!value.isValid()) {
            event.target.value = start ? moment(start).format('L') : '';
            return
          }

          if (_.isNil(start) || _.isNil(end)) {
            fp.setDate([value.toDate()], true)
            return
          }

          const endValue = moment(end)

          fp.setDate(
            value.isAfter(endValue)
              ? [endValue.toDate(), value.toDate()]
              : [value.toDate(), endValue.toDate()],
            true
          )
        })

        endInput = createInput('', (event) => {
          const value = moment(event.target.value, 'L')
          const [start, end] = fp.selectedDates

          if (!value.isValid()) {
            event.target.value = end ? moment(end).format('L') : '';
            return
          }

          if (_.isNil(start)) {
            fp.setDate([value.toDate()], true)
            return
          }

          const startValue = moment(start)

          fp.setDate(
            value.isBefore(startValue)
              ? [value.toDate(), startValue.toDate()]
              : [startValue.toDate(), value.toDate()],
            true
          )
        })

        const dash = document.createElement('div')
        dash.innerText = '-'
        dash.className = 'dash'

        inputs.appendChild(
          createInputField(_.get(labels, 'start', 'Início'), startInput)
        )
        inputs.appendChild(dash)
        inputs.appendChild(
          createInputField(_.get(labels, 'end', 'Fim'), endInput)
        )

        fp.calendarContainer.insertBefore(inputsContainer, fp.monthNav)
      }
    }
  }
}
export default flatpickrRangeInputPlugin

/**
 * Singleton que contém os dados de tamanhos de pneu
 *
 * A propridade `data` (getter) retorna todos os dados.
 *
 * Existem métodos de apoio, como `keys` e `values` - para ficar próximo a um Object -
 * além do `asEnum()`, para uso como `enum` em model.js.
 *
 * Ainda não há metadados associados - como "aro" ou "perfil" de pneu.
 *
 * @type {Object}
 */
const Sizes = {
  get data() {
    return SIZES
  },

  get keys() {
    return Object.keys(this.data)
  },

  get values() {
    return _.map(this.data, "code")
  },

  asEnum() {
    return _.reduce(this.data, (result, item, key) => {
      result[key] = item.code
      return result
    }, {})
  }

  // findBy..()
}


// ref: Pirelli b2fleet https://github.com/caiena/movida-crm/issues/332#issuecomment-639494261
// ref: https://tiresize.com/tiresizes
const SIZES = {
  "115/70R15":      { code: "115/70R15" },
  "115/95R17":      { code: "115/95R17" },
  "125/70R15":      { code: "125/70R15" },
  "125/70R16":      { code: "125/70R16" },
  "125/70R17":      { code: "125/70R17" },
  "125/70R18":      { code: "125/70R18" },
  "125/70R19":      { code: "125/70R19" },
  "125/80R15":      { code: "125/80R15" },
  "125/80R16":      { code: "125/80R16" },
  "125/80R17":      { code: "125/80R17" },
  "125/90R16":      { code: "125/90R16" },
  "135/70R16":      { code: "135/70R16" },
  "135/70R17":      { code: "135/70R17" },
  "135/70R18":      { code: "135/70R18" },
  "135/70R19":      { code: "135/70R19" },
  "135/80R16":      { code: "135/80R16" },
  "135/80R17":      { code: "135/80R17" },
  "135/80R18":      { code: "135/80R18" },
  "135/90R16":      { code: "135/90R16" },
  "135/90R17":      { code: "135/90R17" },
  "145/60R20":      { code: "145/60R20" },
  "145/65R15":      { code: "145/65R15" },
  "145/70R17":      { code: "145/70R17" },
  "145/70R18":      { code: "145/70R18" },
  "145/80R17":      { code: "145/80R17" },
  "145/80R19":      { code: "145/80R19" },
  "145/85R18":      { code: "145/85R18" },
  "145/90R16":      { code: "145/90R16" },
  "155/60R15":      { code: "155/60R15" },
  "155/60R20":      { code: "155/60R20" },
  "155/65R20":      { code: "155/65R20" },
  "155/70R17":      { code: "155/70R17" },
  "155/70R19":      { code: "155/70R19" },
  "155/80R13":      { code: "155/80R13" },
  "155/80R15":      { code: "155/80R15" },
  "155/80R17":      { code: "155/80R17" },
  "155/80R19":      { code: "155/80R19" },
  "155/85R18":      { code: "155/85R18" },
  "155/90R16":      { code: "155/90R16" },
  "155/90R17":      { code: "155/90R17" },
  "155/90R18":      { code: "155/90R18" },
  "165/60R15":      { code: "165/60R15" },
  "165/60R20":      { code: "165/60R20" },
  "165/65R14":      { code: "165/65R14" },
  "165/65R15":      { code: "165/65R15" },
  "165/70R13":      { code: "165/70R13" },
  "165/80R17":      { code: "165/80R17" },
  "165/90R17":      { code: "165/90R17" },
  "175/50R16":      { code: "175/50R16" },
  "175/55R15":      { code: "175/55R15" },
  "175/55R16":      { code: "175/55R16" },
  "175/55R20":      { code: "175/55R20" },
  "175/60R15":      { code: "175/60R15" },
  "175/60R16":      { code: "175/60R16" },
  "175/60R19":      { code: "175/60R19" },
  "175/65R14":      { code: "175/65R14" },
  "175/65R15":      { code: "175/65R15" },
  "175/70R13":      { code: "175/70R13" },
  "175/70R14":      { code: "175/70R14" },
  "175/80R13":      { code: "175/80R13" },
  "175/80R14":      { code: "175/80R14" },
  "175/80R19":      { code: "175/80R19" },
  "175/90R18":      { code: "175/90R18" },
  "185/50R16":      { code: "185/50R16" },
  "185/55R14":      { code: "185/55R14" },
  "185/55R15":      { code: "185/55R15" },
  "185/55R16":      { code: "185/55R16" },
  "185/60R13":      { code: "185/60R13" },
  "185/60R14":      { code: "185/60R14" },
  "185/60R15":      { code: "185/60R15" },
  "185/60R16":      { code: "185/60R16" },
  "185/65R14":      { code: "185/65R14" },
  "185/65R15":      { code: "185/65R15" },
  "185/70R13":      { code: "185/70R13" },
  "185/70R14":      { code: "185/70R14" },
  "185/70R15":      { code: "185/70R15" },
  "185/75R14":      { code: "185/75R14" },
  "185R14":         { code: "185R14" },
  "185/80R13":      { code: "185/80R13" },
  "195/40R17":      { code: "195/40R17" },
  "195/45R15":      { code: "195/45R15" },
  "195/45R16":      { code: "195/45R16" },
  "195/45R17":      { code: "195/45R17" },
  "195/50R15":      { code: "195/50R15" },
  "195/50R16":      { code: "195/50R16" },
  "195/50R20":      { code: "195/50R20" },
  "195/55R15":      { code: "195/55R15" },
  "195/55R16":      { code: "195/55R16" },
  "195/60R14":      { code: "195/60R14" },
  "195/60R15":      { code: "195/60R15" },
  "195/60R16":      { code: "195/60R16" },
  "195/65R15":      { code: "195/65R15" },
  "195/65R16":      { code: "195/65R16" },
  "195/70R14":      { code: "195/70R14" },
  "195/70R15":      { code: "195/70R15" },
  "195/75R14":      { code: "195/75R14" },
  "195/75R16":      { code: "195/75R16" },
  "195R14":         { code: "195R14" },
  "205/40R16":      { code: "205/40R16" },
  "205/40R17":      { code: "205/40R17" },
  "205/40R18":      { code: "205/40R18" },
  "205/45R16":      { code: "205/45R16" },
  "205/45R17":      { code: "205/45R17" },
  "205/50R15":      { code: "205/50R15" },
  "205/50R16":      { code: "205/50R16" },
  "205/50R17":      { code: "205/50R17" },
  "205/55R14":      { code: "205/55R14" },
  "205/55R15":      { code: "205/55R15" },
  "205/55R16":      { code: "205/55R16" },
  "205/55R17":      { code: "205/55R17" },
  "205/60R13":      { code: "205/60R13" },
  "205/60R14":      { code: "205/60R14" },
  "205/60R15":      { code: "205/60R15" },
  "205/60R16":      { code: "205/60R16" },
  "205/65R15":      { code: "205/65R15" },
  "205/65R16":      { code: "205/65R16" },
  "205/70R14":      { code: "205/70R14" },
  "205/70R15":      { code: "205/70R15" },
  "205/70R16":      { code: "205/70R16" },
  "205/75R14":      { code: "205/75R14" },
  "205/75R15":      { code: "205/75R15" },
  "205/75R16":      { code: "205/75R16" },
  "205/80R16":      { code: "205/80R16" },
  "215/35R17":      { code: "215/35R17" },
  "215/35R18":      { code: "215/35R18" },
  "215/35R19":      { code: "215/35R19" },
  "215/40R17":      { code: "215/40R17" },
  "215/40R18":      { code: "215/40R18" },
  "215/45R16":      { code: "215/45R16" },
  "215/45R17":      { code: "215/45R17" },
  "215/45R18":      { code: "215/45R18" },
  "215/45R20":      { code: "215/45R20" },
  "215/50R16":      { code: "215/50R16" },
  "215/50R17":      { code: "215/50R17" },
  "215/50R18":      { code: "215/50R18" },
  "215/55R16":      { code: "215/55R16" },
  "215/55R17":      { code: "215/55R17" },
  "215/55R18":      { code: "215/55R18" },
  "215/60R14":      { code: "215/60R14" },
  "215/60R15":      { code: "215/60R15" },
  "215/60R16":      { code: "215/60R16" },
  "215/60R17":      { code: "215/60R17" },
  "215/65R15":      { code: "215/65R15" },
  "215/65R16":      { code: "215/65R16" },
  "215/65R17":      { code: "215/65R17" },
  "215/70R14":      { code: "215/70R14" },
  "215/70R15":      { code: "215/70R15" },
  "215/70R16":      { code: "215/70R16" },
  "215/70R17":      { code: "215/70R17" },
  "215/75R14":      { code: "215/75R14" },
  "215/75R15":      { code: "215/75R15" },
  "215/75R16":      { code: "215/75R16" },
  "215/80R16":      { code: "215/80R16" },
  "215/85R16":      { code: "215/85R16" },
  "22.5X4.5R15":    { code: "22.5X4.5R15" },
  "225/30R20":      { code: "225/30R20" },
  "225/35R17":      { code: "225/35R17" },
  "225/35R18":      { code: "225/35R18" },
  "225/35R19":      { code: "225/35R19" },
  "225/35R20":      { code: "225/35R20" },
  "225/40R17":      { code: "225/40R17" },
  "225/40R18":      { code: "225/40R18" },
  "225/40R19":      { code: "225/40R19" },
  "225/40R20":      { code: "225/40R20" },
  "225/45R13":      { code: "225/45R13" },
  "225/45R15":      { code: "225/45R15" },
  "225/45R16":      { code: "225/45R16" },
  "225/45R17":      { code: "225/45R17" },
  "225/45R18":      { code: "225/45R18" },
  "225/45R19":      { code: "225/45R19" },
  "225/50R14":      { code: "225/50R14" },
  "225/50R15":      { code: "225/50R15" },
  "225/50R16":      { code: "225/50R16" },
  "225/50R17":      { code: "225/50R17" },
  "225/50R18":      { code: "225/50R18" },
  "225/55R16":      { code: "225/55R16" },
  "225/55R17":      { code: "225/55R17" },
  "225/55R18":      { code: "225/55R18" },
  "225/55R19":      { code: "225/55R19" },
  "225/60R14":      { code: "225/60R14" },
  "225/60R15":      { code: "225/60R15" },
  "225/60R16":      { code: "225/60R16" },
  "225/60R17":      { code: "225/60R17" },
  "225/60R18":      { code: "225/60R18" },
  "225/65R16":      { code: "225/65R16" },
  "225/65R17":      { code: "225/65R17" },
  "225/70R14":      { code: "225/70R14" },
  "225/70R15":      { code: "225/70R15" },
  "225/70R16":      { code: "225/70R16" },
  "225/70R17":      { code: "225/70R17" },
  "225/70R19.5":    { code: "225/70R19.5" },
  "225/75R15":      { code: "225/75R15" },
  "225/75R16":      { code: "225/75R16" },
  "225/75R17":      { code: "225/75R17" },
  "22X8R15":        { code: "22X8R15" },
  "235/30R20":      { code: "235/30R20" },
  "235/30R22":      { code: "235/30R22" },
  "235/35R18":      { code: "235/35R18" },
  "235/35R19":      { code: "235/35R19" },
  "235/35R20":      { code: "235/35R20" },
  "235/40R17":      { code: "235/40R17" },
  "235/40R18":      { code: "235/40R18" },
  "235/40R19":      { code: "235/40R19" },
  "235/40R20":      { code: "235/40R20" },
  "235/45R17":      { code: "235/45R17" },
  "235/45R18":      { code: "235/45R18" },
  "235/45R19":      { code: "235/45R19" },
  "235/45R20":      { code: "235/45R20" },
  "235/45R21":      { code: "235/45R21" },
  "235/50R15":      { code: "235/50R15" },
  "235/50R17":      { code: "235/50R17" },
  "235/50R18":      { code: "235/50R18" },
  "235/50R19":      { code: "235/50R19" },
  "235/50R20":      { code: "235/50R20" },
  "235/55R16":      { code: "235/55R16" },
  "235/55R17":      { code: "235/55R17" },
  "235/55R18":      { code: "235/55R18" },
  "235/55R19":      { code: "235/55R19" },
  "235/55R20":      { code: "235/55R20" },
  "235/60R14":      { code: "235/60R14" },
  "235/60R15":      { code: "235/60R15" },
  "235/60R16":      { code: "235/60R16" },
  "235/60R17":      { code: "235/60R17" },
  "235/60R18":      { code: "235/60R18" },
  "235/65R16":      { code: "235/65R16" },
  "235/65R17":      { code: "235/65R17" },
  "235/65R18":      { code: "235/65R18" },
  "235/65R19":      { code: "235/65R19" },
  "235/70R15":      { code: "235/70R15" },
  "235/70R16":      { code: "235/70R16" },
  "235/70R17":      { code: "235/70R17" },
  "235/70R18":      { code: "235/70R18" },
  "235/75R15":      { code: "235/75R15" },
  "235/75R16":      { code: "235/75R16" },
  "235/75R17":      { code: "235/75R17" },
  "235/80R16":      { code: "235/80R16" },
  "235/80R17":      { code: "235/80R17" },
  "235/85R16":      { code: "235/85R16" },
  "235/85R17":      { code: "235/85R17" },
  "24.5X8R15":      { code: "24.5X8R15" },
  "24.5X9R13":      { code: "24.5X9R13" },
  "245/30R19":      { code: "245/30R19" },
  "245/30R20":      { code: "245/30R20" },
  "245/30R21":      { code: "245/30R21" },
  "245/30R22":      { code: "245/30R22" },
  "245/35R17":      { code: "245/35R17" },
  "245/35R18":      { code: "245/35R18" },
  "245/35R19":      { code: "245/35R19" },
  "245/35R20":      { code: "245/35R20" },
  "245/35R21":      { code: "245/35R21" },
  "245/40R15":      { code: "245/40R15" },
  "245/40R17":      { code: "245/40R17" },
  "245/40R18":      { code: "245/40R18" },
  "245/40R19":      { code: "245/40R19" },
  "245/40R20":      { code: "245/40R20" },
  "245/40R21":      { code: "245/40R21" },
  "245/45R16":      { code: "245/45R16" },
  "245/45R17":      { code: "245/45R17" },
  "245/45R18":      { code: "245/45R18" },
  "245/45R19":      { code: "245/45R19" },
  "245/45R20":      { code: "245/45R20" },
  "245/45R21":      { code: "245/45R21" },
  "245/50R15":      { code: "245/50R15" },
  "245/50R16":      { code: "245/50R16" },
  "245/50R17":      { code: "245/50R17" },
  "245/50R18":      { code: "245/50R18" },
  "245/50R19":      { code: "245/50R19" },
  "245/50R20":      { code: "245/50R20" },
  "245/55R17":      { code: "245/55R17" },
  "245/55R18":      { code: "245/55R18" },
  "245/55R19":      { code: "245/55R19" },
  "245/60R14":      { code: "245/60R14" },
  "245/60R15":      { code: "245/60R15" },
  "245/60R18":      { code: "245/60R18" },
  "245/60R20":      { code: "245/60R20" },
  "245/65R17":      { code: "245/65R17" },
  "245/65R18":      { code: "245/65R18" },
  "245/70R16":      { code: "245/70R16" },
  "245/70R17":      { code: "245/70R17" },
  "245/75R16":      { code: "245/75R16" },
  "245/75R17":      { code: "245/75R17" },
  "24X4.5R15":      { code: "24X4.5R15" },
  "24X5R15":        { code: "24X5R15" },
  "255/30R19":      { code: "255/30R19" },
  "255/30R20":      { code: "255/30R20" },
  "255/30R21":      { code: "255/30R21" },
  "255/30R22":      { code: "255/30R22" },
  "255/30R24":      { code: "255/30R24" },
  "255/35R18":      { code: "255/35R18" },
  "255/35R19":      { code: "255/35R19" },
  "255/35R20":      { code: "255/35R20" },
  "255/35R21":      { code: "255/35R21" },
  "255/35R22":      { code: "255/35R22" },
  "255/40R13":      { code: "255/40R13" },
  "255/40R17":      { code: "255/40R17" },
  "255/40R18":      { code: "255/40R18" },
  "255/40R19":      { code: "255/40R19" },
  "255/40R20":      { code: "255/40R20" },
  "255/40R21":      { code: "255/40R21" },
  "255/40R22":      { code: "255/40R22" },
  "255/45R17":      { code: "255/45R17" },
  "255/45R18":      { code: "255/45R18" },
  "255/45R19":      { code: "255/45R19" },
  "255/45R20":      { code: "255/45R20" },
  "255/45R22":      { code: "255/45R22" },
  "255/50R16":      { code: "255/50R16" },
  "255/50R17":      { code: "255/50R17" },
  "255/50R18":      { code: "255/50R18" },
  "255/50R19":      { code: "255/50R19" },
  "255/50R20":      { code: "255/50R20" },
  "255/50R21":      { code: "255/50R21" },
  "255/55R17":      { code: "255/55R17" },
  "255/55R18":      { code: "255/55R18" },
  "255/55R19":      { code: "255/55R19" },
  "255/55R20":      { code: "255/55R20" },
  "255/60R15":      { code: "255/60R15" },
  "255/60R17":      { code: "255/60R17" },
  "255/60R18":      { code: "255/60R18" },
  "255/60R19":      { code: "255/60R19" },
  "255/60R20":      { code: "255/60R20" },
  "255/65R16":      { code: "255/65R16" },
  "255/65R17":      { code: "255/65R17" },
  "255/65R18":      { code: "255/65R18" },
  "255/65R19":      { code: "255/65R19" },
  "255/70R15":      { code: "255/70R15" },
  "255/70R16":      { code: "255/70R16" },
  "255/70R17":      { code: "255/70R17" },
  "255/70R18":      { code: "255/70R18" },
  "255/75R15":      { code: "255/75R15" },
  "255/75R17":      { code: "255/75R17" },
  "255/80R17":      { code: "255/80R17" },
  "255/85R16":      { code: "255/85R16" },
  "265/30R19":      { code: "265/30R19" },
  "265/30R20":      { code: "265/30R20" },
  "265/30R21":      { code: "265/30R21" },
  "265/30R22":      { code: "265/30R22" },
  "265/35R18":      { code: "265/35R18" },
  "265/35R19":      { code: "265/35R19" },
  "265/35R20":      { code: "265/35R20" },
  "265/35R21":      { code: "265/35R21" },
  "265/35R22":      { code: "265/35R22" },
  "265/40R17":      { code: "265/40R17" },
  "265/40R18":      { code: "265/40R18" },
  "265/40R19":      { code: "265/40R19" },
  "265/40R20":      { code: "265/40R20" },
  "265/40R21":      { code: "265/40R21" },
  "265/40R22":      { code: "265/40R22" },
  "265/45R18":      { code: "265/45R18" },
  "265/45R19":      { code: "265/45R19" },
  "265/45R20":      { code: "265/45R20" },
  "265/45R21":      { code: "265/45R21" },
  "265/50R15":      { code: "265/50R15" },
  "265/50R19":      { code: "265/50R19" },
  "265/50R20":      { code: "265/50R20" },
  "265/55R19":      { code: "265/55R19" },
  "265/60R17":      { code: "265/60R17" },
  "265/60R18":      { code: "265/60R18" },
  "265/60R20":      { code: "265/60R20" },
  "265/65R17":      { code: "265/65R17" },
  "265/65R18":      { code: "265/65R18" },
  "265/70R15":      { code: "265/70R15" },
  "265/70R16":      { code: "265/70R16" },
  "265/70R17":      { code: "265/70R17" },
  "265/70R18":      { code: "265/70R18" },
  "265/75R15":      { code: "265/75R15" },
  "265/75R16":      { code: "265/75R16" },
  "265/80R16":      { code: "265/80R16" },
  "26X10.5R15":     { code: "26X10.5R15" },
  "26X10R15":       { code: "26X10R15" },
  "26X6R15":        { code: "26X6R15" },
  "26X7.5R15":      { code: "26X7.5R15" },
  "26X8.5R15":      { code: "26X8.5R15" },
  "26X8R15":        { code: "26X8R15" },
  "275/25R20":      { code: "275/25R20" },
  "275/25R24":      { code: "275/25R24" },
  "275/30R19":      { code: "275/30R19" },
  "275/30R20":      { code: "275/30R20" },
  "275/30R21":      { code: "275/30R21" },
  "275/30R22":      { code: "275/30R22" },
  "275/30R24":      { code: "275/30R24" },
  "275/35R15":      { code: "275/35R15" },
  "275/35R17":      { code: "275/35R17" },
  "275/35R18":      { code: "275/35R18" },
  "275/35R19":      { code: "275/35R19" },
  "275/35R20":      { code: "275/35R20" },
  "275/35R21":      { code: "275/35R21" },
  "275/35R22":      { code: "275/35R22" },
  "275/40R17":      { code: "275/40R17" },
  "275/40R18":      { code: "275/40R18" },
  "275/40R19":      { code: "275/40R19" },
  "275/40R20":      { code: "275/40R20" },
  "275/40R21":      { code: "275/40R21" },
  "275/40R22":      { code: "275/40R22" },
  "275/45R16":      { code: "275/45R16" },
  "275/45R18":      { code: "275/45R18" },
  "275/45R19":      { code: "275/45R19" },
  "275/45R20":      { code: "275/45R20" },
  "275/45R21":      { code: "275/45R21" },
  "275/45R22":      { code: "275/45R22" },
  "275/50R15":      { code: "275/50R15" },
  "275/50R17":      { code: "275/50R17" },
  "275/50R19":      { code: "275/50R19" },
  "275/50R20":      { code: "275/50R20" },
  "275/50R21":      { code: "275/50R21" },
  "275/50R22":      { code: "275/50R22" },
  "275/55R17":      { code: "275/55R17" },
  "275/55R18":      { code: "275/55R18" },
  "275/55R19":      { code: "275/55R19" },
  "275/55R20":      { code: "275/55R20" },
  "275/60R15":      { code: "275/60R15" },
  "275/60R17":      { code: "275/60R17" },
  "275/60R18":      { code: "275/60R18" },
  "275/60R20":      { code: "275/60R20" },
  "275/65R17":      { code: "275/65R17" },
  "275/65R18":      { code: "275/65R18" },
  "275/65R20":      { code: "275/65R20" },
  "275/70R16":      { code: "275/70R16" },
  "275/70R17":      { code: "275/70R17" },
  "275/70R18":      { code: "275/70R18" },
  "275/80R17":      { code: "275/80R17" },
  "27X8.5R14":      { code: "27X8.5R14" },
  "27X9.5R14":      { code: "27X9.5R14" },
  "27X9.5R15":      { code: "27X9.5R15" },
  "285/25R20":      { code: "285/25R20" },
  "285/25R22":      { code: "285/25R22" },
  "285/30R18":      { code: "285/30R18" },
  "285/30R19":      { code: "285/30R19" },
  "285/30R20":      { code: "285/30R20" },
  "285/30R21":      { code: "285/30R21" },
  "285/30R22":      { code: "285/30R22" },
  "285/30R24":      { code: "285/30R24" },
  "285/35R18":      { code: "285/35R18" },
  "285/35R19":      { code: "285/35R19" },
  "285/35R20":      { code: "285/35R20" },
  "285/35R21":      { code: "285/35R21" },
  "285/35R22":      { code: "285/35R22" },
  "285/35R23":      { code: "285/35R23" },
  "285/35R24":      { code: "285/35R24" },
  "285/40R17":      { code: "285/40R17" },
  "285/40R18":      { code: "285/40R18" },
  "285/40R19":      { code: "285/40R19" },
  "285/40R20":      { code: "285/40R20" },
  "285/40R21":      { code: "285/40R21" },
  "285/40R22":      { code: "285/40R22" },
  "285/40R23":      { code: "285/40R23" },
  "285/40R24":      { code: "285/40R24" },
  "285/45R18":      { code: "285/45R18" },
  "285/45R19":      { code: "285/45R19" },
  "285/45R20":      { code: "285/45R20" },
  "285/45R21":      { code: "285/45R21" },
  "285/45R22":      { code: "285/45R22" },
  "285/50R18":      { code: "285/50R18" },
  "285/50R20":      { code: "285/50R20" },
  "285/50R22":      { code: "285/50R22" },
  "285/55R20":      { code: "285/55R20" },
  "285/55R22":      { code: "285/55R22" },
  "285/60R18":      { code: "285/60R18" },
  "285/60R20":      { code: "285/60R20" },
  "285/65R16":      { code: "285/65R16" },
  "285/65R17":      { code: "285/65R17" },
  "285/65R18":      { code: "285/65R18" },
  "285/65R20":      { code: "285/65R20" },
  "285/70R16":      { code: "285/70R16" },
  "285/70R17":      { code: "285/70R17" },
  "285/70R18":      { code: "285/70R18" },
  "285/75R16":      { code: "285/75R16" },
  "285/75R17":      { code: "285/75R17" },
  "285/75R18":      { code: "285/75R18" },
  "28X10.5R15":     { code: "28X10.5R15" },
  "28X11.5R15":     { code: "28X11.5R15" },
  "28X12.5R15":     { code: "28X12.5R15" },
  "28X4.5R15":      { code: "28X4.5R15" },
  "28X7.5R15":      { code: "28X7.5R15" },
  "28X9R15":        { code: "28X9R15" },
  "29.5X11.5R15":   { code: "29.5X11.5R15" },
  "29.5X9R15":      { code: "29.5X9R15" },
  "295/25R19":      { code: "295/25R19" },
  "295/25R20":      { code: "295/25R20" },
  "295/25R21":      { code: "295/25R21" },
  "295/25R22":      { code: "295/25R22" },
  "295/30R18":      { code: "295/30R18" },
  "295/30R19":      { code: "295/30R19" },
  "295/30R20":      { code: "295/30R20" },
  "295/30R21":      { code: "295/30R21" },
  "295/30R22":      { code: "295/30R22" },
  "295/30R24":      { code: "295/30R24" },
  "295/35R15":      { code: "295/35R15" },
  "295/35R17":      { code: "295/35R17" },
  "295/35R18":      { code: "295/35R18" },
  "295/35R19":      { code: "295/35R19" },
  "295/35R20":      { code: "295/35R20" },
  "295/35R21":      { code: "295/35R21" },
  "295/35R22":      { code: "295/35R22" },
  "295/35R24":      { code: "295/35R24" },
  "295/40R18":      { code: "295/40R18" },
  "295/40R19":      { code: "295/40R19" },
  "295/40R20":      { code: "295/40R20" },
  "295/40R21":      { code: "295/40R21" },
  "295/40R22":      { code: "295/40R22" },
  "295/45R18":      { code: "295/45R18" },
  "295/45R19":      { code: "295/45R19" },
  "295/45R20":      { code: "295/45R20" },
  "295/50R15":      { code: "295/50R15" },
  "295/50R20":      { code: "295/50R20" },
  "295/55R15":      { code: "295/55R15" },
  "295/55R20":      { code: "295/55R20" },
  "295/55R22":      { code: "295/55R22" },
  "295/60R20":      { code: "295/60R20" },
  "295/65R15":      { code: "295/65R15" },
  "295/65R18":      { code: "295/65R18" },
  "295/65R20":      { code: "295/65R20" },
  "295/70R17":      { code: "295/70R17" },
  "295/70R18":      { code: "295/70R18" },
  "295/75R16":      { code: "295/75R16" },
  "29X10.5R15":     { code: "29X10.5R15" },
  "29X11.5R15":     { code: "29X11.5R15" },
  "29X12.5R15":     { code: "29X12.5R15" },
  "29X15.5R15":     { code: "29X15.5R15" },
  "29X18.5R15":     { code: "29X18.5R15" },
  "29X18R15":       { code: "29X18R15" },
  "29X4.5R15":      { code: "29X4.5R15" },
  "29X8.5R15":      { code: "29X8.5R15" },
  "305/25R20":      { code: "305/25R20" },
  "305/25R21":      { code: "305/25R21" },
  "305/25R22":      { code: "305/25R22" },
  "305/30R19":      { code: "305/30R19" },
  "305/30R20":      { code: "305/30R20" },
  "305/30R21":      { code: "305/30R21" },
  "305/30R22":      { code: "305/30R22" },
  "305/35R18":      { code: "305/35R18" },
  "305/35R19":      { code: "305/35R19" },
  "305/35R20":      { code: "305/35R20" },
  "305/35R21":      { code: "305/35R21" },
  "305/35R22":      { code: "305/35R22" },
  "305/35R24":      { code: "305/35R24" },
  "305/40R18":      { code: "305/40R18" },
  "305/40R20":      { code: "305/40R20" },
  "305/40R22":      { code: "305/40R22" },
  "305/40R23":      { code: "305/40R23" },
  "305/45R17":      { code: "305/45R17" },
  "305/45R18":      { code: "305/45R18" },
  "305/45R22":      { code: "305/45R22" },
  "305/50R20":      { code: "305/50R20" },
  "305/50R22":      { code: "305/50R22" },
  "305/55R20":      { code: "305/55R20" },
  "305/60R18":      { code: "305/60R18" },
  "305/65R17":      { code: "305/65R17" },
  "305/65R18":      { code: "305/65R18" },
  "305/70R16":      { code: "305/70R16" },
  "305/70R17":      { code: "305/70R17" },
  "305/70R18":      { code: "305/70R18" },
  "30X11.5R15":     { code: "30X11.5R15" },
  "30X11.5R16":     { code: "30X11.5R16" },
  "30X9.5R15":      { code: "30X9.5R15" },
  "315/25R19":      { code: "315/25R19" },
  "315/25R20":      { code: "315/25R20" },
  "315/25R22":      { code: "315/25R22" },
  "315/30R18":      { code: "315/30R18" },
  "315/30R19":      { code: "315/30R19" },
  "315/30R20":      { code: "315/30R20" },
  "315/30R21":      { code: "315/30R21" },
  "315/30R22":      { code: "315/30R22" },
  "315/35R17":      { code: "315/35R17" },
  "315/35R18":      { code: "315/35R18" },
  "315/35R19":      { code: "315/35R19" },
  "315/35R20":      { code: "315/35R20" },
  "315/35R21":      { code: "315/35R21" },
  "315/35R22":      { code: "315/35R22" },
  "315/35R24":      { code: "315/35R24" },
  "315/40R18":      { code: "315/40R18" },
  "315/40R19":      { code: "315/40R19" },
  "315/40R21":      { code: "315/40R21" },
  "315/45R22":      { code: "315/45R22" },
  "315/50R24":      { code: "315/50R24" },
  "315/60R20":      { code: "315/60R20" },
  "315/70R17":      { code: "315/70R17" },
  "315/70R18":      { code: "315/70R18" },
  "315/75R16":      { code: "315/75R16" },
  "31X10.5R15":     { code: "31X10.5R15" },
  "31X10.50R15":    { code: "31X10.50R15" },
  "31X10.5R16":     { code: "31X10.5R16" },
  "31X10.5R17":     { code: "31X10.5R17" },
  "31X11.5R15":     { code: "31X11.5R15" },
  "31X11.5R16":     { code: "31X11.5R16" },
  "31X11R15":       { code: "31X11R15" },
  "31X11R16":       { code: "31X11R16" },
  "31X12.5R15":     { code: "31X12.5R15" },
  "31X12.5R16":     { code: "31X12.5R16" },
  "31X13.5R15":     { code: "31X13.5R15" },
  "31X16.5R15":     { code: "31X16.5R15" },
  "31X18.5R15":     { code: "31X18.5R15" },
  "31X18R15":       { code: "31X18R15" },
  "31X18R20":       { code: "31X18R20" },
  "31X9.5R15":      { code: "31X9.5R15" },
  "325/25R20":      { code: "325/25R20" },
  "325/25R21":      { code: "325/25R21" },
  "325/30R19":      { code: "325/30R19" },
  "325/30R20":      { code: "325/30R20" },
  "325/30R21":      { code: "325/30R21" },
  "325/30R23":      { code: "325/30R23" },
  "325/35R20":      { code: "325/35R20" },
  "325/35R22":      { code: "325/35R22" },
  "325/35R23":      { code: "325/35R23" },
  "325/40R21":      { code: "325/40R21" },
  "325/40R22":      { code: "325/40R22" },
  "325/45R24":      { code: "325/45R24" },
  "325/50R15":      { code: "325/50R15" },
  "325/50R22":      { code: "325/50R22" },
  "325/55R22":      { code: "325/55R22" },
  "325/60R18":      { code: "325/60R18" },
  "325/60R20":      { code: "325/60R20" },
  "325/65R18":      { code: "325/65R18" },
  "32X10.5R15":     { code: "32X10.5R15" },
  "32X11.5R15":     { code: "32X11.5R15" },
  "32X11.5R16":     { code: "32X11.5R16" },
  "32X11.5R17":     { code: "32X11.5R17" },
  "32X12R16.5":     { code: "32X12R16.5" },
  "32X14R15":       { code: "32X14R15" },
  "32X16R15":       { code: "32X16R15" },
  "32X17.5R15":     { code: "32X17.5R15" },
  "32X9.5R15":      { code: "32X9.5R15" },
  "32X9R16":        { code: "32X9R16" },
  "335/25R20":      { code: "335/25R20" },
  "335/25R22":      { code: "335/25R22" },
  "335/30R18":      { code: "335/30R18" },
  "335/30R19":      { code: "335/30R19" },
  "335/30R20":      { code: "335/30R20" },
  "335/35R17":      { code: "335/35R17" },
  "33X10.5R15":     { code: "33X10.5R15" },
  "33X10.5R16":     { code: "33X10.5R16" },
  "33X10.5R17":     { code: "33X10.5R17" },
  "33X11.5R15":     { code: "33X11.5R15" },
  "33X11.5R16":     { code: "33X11.5R16" },
  "33X11.5R16.5":   { code: "33X11.5R16.5" },
  "33X11.5R20":     { code: "33X11.5R20" },
  "33X12.5R15":     { code: "33X12.5R15" },
  "33X12.5R16":     { code: "33X12.5R16" },
  "33X12.5R16.5":   { code: "33X12.5R16.5" },
  "33X12.5R17":     { code: "33X12.5R17" },
  "33X12.5R18":     { code: "33X12.5R18" },
  "33X12.5R20":     { code: "33X12.5R20" },
  "33X12.5R22":     { code: "33X12.5R22" },
  "33X12R20":       { code: "33X12R20" },
  "33X13.5R15":     { code: "33X13.5R15" },
  "33X13.5R16":     { code: "33X13.5R16" },
  "33X13.5R17":     { code: "33X13.5R17" },
  "33X13.5R18":     { code: "33X13.5R18" },
  "33X13.5R20":     { code: "33X13.5R20" },
  "33X13R15":       { code: "33X13R15" },
  "33X13R16":       { code: "33X13R16" },
  "33X13R17":       { code: "33X13R17" },
  "33X13R18":       { code: "33X13R18" },
  "33X14.5R15":     { code: "33X14.5R15" },
  "33X14.5R16":     { code: "33X14.5R16" },
  "33X14.5R16.5":   { code: "33X14.5R16.5" },
  "33X14.5R22":     { code: "33X14.5R22" },
  "33X14R15":       { code: "33X14R15" },
  "33X14R16":       { code: "33X14R16" },
  "33X14R16.5":     { code: "33X14R16.5" },
  "33X15.5R15":     { code: "33X15.5R15" },
  "33X15.5R16.5":   { code: "33X15.5R16.5" },
  "33X19.5R15":     { code: "33X19.5R15" },
  "33X21.5R15":     { code: "33X21.5R15" },
  "33X9.5R15":      { code: "33X9.5R15" },
  "33X9.5R16":      { code: "33X9.5R16" },
  "33X9.5R17":      { code: "33X9.5R17" },
  "34.5X17R16":     { code: "34.5X17R16" },
  "345/25R20":      { code: "345/25R20" },
  "345/30R19":      { code: "345/30R19" },
  "345/30R20":      { code: "345/30R20" },
  "345/35R15":      { code: "345/35R15" },
  "345/35R18":      { code: "345/35R18" },
  "345/35R19":      { code: "345/35R19" },
  "345/40R17":      { code: "345/40R17" },
  "345/50R24":      { code: "345/50R24" },
  "34X10.5R15":     { code: "34X10.5R15" },
  "34X10.5R16":     { code: "34X10.5R16" },
  "34X10.5R17":     { code: "34X10.5R17" },
  "34X12.5R15":     { code: "34X12.5R15" },
  "34X12.5R18":     { code: "34X12.5R18" },
  "34X9.5R15":      { code: "34X9.5R15" },
  "34X9R16":        { code: "34X9R16" },
  "355/25R21":      { code: "355/25R21" },
  "355/30R19":      { code: "355/30R19" },
  "355/40R22":      { code: "355/40R22" },
  "35X10.5R15":     { code: "35X10.5R15" },
  "35X10.5R16":     { code: "35X10.5R16" },
  "35X10.5R17":     { code: "35X10.5R17" },
  "35X11.5R17":     { code: "35X11.5R17" },
  "35X11.5R18":     { code: "35X11.5R18" },
  "35X11.5R20":     { code: "35X11.5R20" },
  "35X12.5R15":     { code: "35X12.5R15" },
  "35X12.5R16":     { code: "35X12.5R16" },
  "35X12.5R16.5":   { code: "35X12.5R16.5" },
  "35X12.5R17":     { code: "35X12.5R17" },
  "35X12.5R18":     { code: "35X12.5R18" },
  "35X12.5R20":     { code: "35X12.5R20" },
  "35X12.5R22":     { code: "35X12.5R22" },
  "35X12.5R24":     { code: "35X12.5R24" },
  "35X13.5R15":     { code: "35X13.5R15" },
  "35X13.5R17":     { code: "35X13.5R17" },
  "35X13.5R20":     { code: "35X13.5R20" },
  "35X13.5R24":     { code: "35X13.5R24" },
  "35X13R18":       { code: "35X13R18" },
  "35X13R20":       { code: "35X13R20" },
  "35X14.5R15":     { code: "35X14.5R15" },
  "35X14.5R16":     { code: "35X14.5R16" },
  "35X14.5R16.5":   { code: "35X14.5R16.5" },
  "35X14.5R17":     { code: "35X14.5R17" },
  "35X14.5R18":     { code: "35X14.5R18" },
  "35X14.5R20":     { code: "35X14.5R20" },
  "35X14.5R22":     { code: "35X14.5R22" },
  "35X15.5R15":     { code: "35X15.5R15" },
  "35X15.5R16.5":   { code: "35X15.5R16.5" },
  "35X15.5R22":     { code: "35X15.5R22" },
  "35X15R15":       { code: "35X15R15" },
  "35X15R16":       { code: "35X15R16" },
  "35X15R16.5":     { code: "35X15R16.5" },
  "35X16R15":       { code: "35X16R15" },
  "35X16R16":       { code: "35X16R16" },
  "35X16R16.5":     { code: "35X16R16.5" },
  "36X12.5R15":     { code: "36X12.5R15" },
  "36X12.5R16":     { code: "36X12.5R16" },
  "36X12.5R16.5":   { code: "36X12.5R16.5" },
  "36X12.5R17":     { code: "36X12.5R17" },
  "36X13.5R15":     { code: "36X13.5R15" },
  "36X13.5R16":     { code: "36X13.5R16" },
  "36X13.5R16.5":   { code: "36X13.5R16.5" },
  "36X13.5R17":     { code: "36X13.5R17" },
  "36X13.5R20":     { code: "36X13.5R20" },
  "36X13R16":       { code: "36X13R16" },
  "36X14.5R15":     { code: "36X14.5R15" },
  "36X14.5R16":     { code: "36X14.5R16" },
  "36X14.5R16.5":   { code: "36X14.5R16.5" },
  "36X15.5R20":     { code: "36X15.5R20" },
  "375/40R24":      { code: "375/40R24" },
  "375/45R22":      { code: "375/45R22" },
  "375/65R16":      { code: "375/65R16" },
  "37X11.5R18":     { code: "37X11.5R18" },
  "37X11.5R20":     { code: "37X11.5R20" },
  "37X12.5R15":     { code: "37X12.5R15" },
  "37X12.5R16":     { code: "37X12.5R16" },
  "37X12.5R16.5":   { code: "37X12.5R16.5" },
  "37X12.5R17":     { code: "37X12.5R17" },
  "37X12.5R18":     { code: "37X12.5R18" },
  "37X12.5R20":     { code: "37X12.5R20" },
  "37X12.5R22":     { code: "37X12.5R22" },
  "37X13.5R15":     { code: "37X13.5R15" },
  "37X13.5R16":     { code: "37X13.5R16" },
  "37X13.5R17":     { code: "37X13.5R17" },
  "37X13.5R18":     { code: "37X13.5R18" },
  "37X13.5R20":     { code: "37X13.5R20" },
  "37X13.5R22":     { code: "37X13.5R22" },
  "37X13.5R24":     { code: "37X13.5R24" },
  "37X13.5R26":     { code: "37X13.5R26" },
  "37X13R15":       { code: "37X13R15" },
  "37X13R16":       { code: "37X13R16" },
  "37X13R16.5":     { code: "37X13R16.5" },
  "37X13R17":       { code: "37X13R17" },
  "37X14.5R15":     { code: "37X14.5R15" },
  "37X14.5R16":     { code: "37X14.5R16" },
  "37X14.5R20":     { code: "37X14.5R20" },
  "37X14.5R24":     { code: "37X14.5R24" },
  "37X14R15":       { code: "37X14R15" },
  "37X14R16":       { code: "37X14R16" },
  "37X14R16.5":     { code: "37X14R16.5" },
  "37X14R17":       { code: "37X14R17" },
  "38.5X11R15":     { code: "38.5X11R15" },
  "38.5X11R16":     { code: "38.5X11R16" },
  "38.5X11R16.5":   { code: "38.5X11R16.5" },
  "38.5X13.5R15":   { code: "38.5X13.5R15" },
  "38.5X13.5R16":   { code: "38.5X13.5R16" },
  "38.5X13.5R16.5": { code: "38.5X13.5R16.5" },
  "38.5X13.5R17":   { code: "38.5X13.5R17" },
  "38.5X13.5R20":   { code: "38.5X13.5R20" },
  "38.5X14.5R15":   { code: "38.5X14.5R15" },
  "38.5X14.5R16":   { code: "38.5X14.5R16" },
  "38.5X14.5R16.5": { code: "38.5X14.5R16.5" },
  "38.5X14.5R17":   { code: "38.5X14.5R17" },
  "38.5X14.5R18":   { code: "38.5X14.5R18" },
  "38.5X14.5R20":   { code: "38.5X14.5R20" },
  "38.5X15R15":     { code: "38.5X15R15" },
  "38.5X15R16":     { code: "38.5X15R16" },
  "38.5X15R16.5":   { code: "38.5X15R16.5" },
  "38.5X16R15":     { code: "38.5X16R15" },
  "38.5X16R16.5":   { code: "38.5X16R16.5" },
  "385/70R16":      { code: "385/70R16" },
  "38X12.5R15":     { code: "38X12.5R15" },
  "38X12.5R16.5":   { code: "38X12.5R16.5" },
  "38X13.5R18":     { code: "38X13.5R18" },
  "38X13.5R20":     { code: "38X13.5R20" },
  "38X13.5R22":     { code: "38X13.5R22" },
  "38X13.5R24":     { code: "38X13.5R24" },
  "38X13R15":       { code: "38X13R15" },
  "38X13R16":       { code: "38X13R16" },
  "38X14.5R17":     { code: "38X14.5R17" },
  "38X14.5R20":     { code: "38X14.5R20" },
  "38X15.5R15":     { code: "38X15.5R15" },
  "38X15.5R16":     { code: "38X15.5R16" },
  "38X15.5R16.5":   { code: "38X15.5R16.5" },
  "38X15.5R17":     { code: "38X15.5R17" },
  "38X15.5R18":     { code: "38X15.5R18" },
  "38X15.5R20":     { code: "38X15.5R20" },
  "38X15.5R22":     { code: "38X15.5R22" },
  "38X15.5R24":     { code: "38X15.5R24" },
  "38X15.5R26":     { code: "38X15.5R26" },
  "39.5X13.5R15":   { code: "39.5X13.5R15" },
  "39.5X13.5R16":   { code: "39.5X13.5R16" },
  "39.5X13.5R16.5": { code: "39.5X13.5R16.5" },
  "39.5X13.5R17":   { code: "39.5X13.5R17" },
  "39.5X13.5R20":   { code: "39.5X13.5R20" },
  "39.5X15R15":     { code: "39.5X15R15" },
  "39.5X15R16":     { code: "39.5X15R16" },
  "39.5X15R16.5":   { code: "39.5X15R16.5" },
  "39.5X15R17":     { code: "39.5X15R17" },
  "39.5X15R20":     { code: "39.5X15R20" },
  "39.5X18R15":     { code: "39.5X18R15" },
  "39.5X18R16.5":   { code: "39.5X18R16.5" },
  "39X13.5R17":     { code: "39X13.5R17" },
  "405/25R24":      { code: "405/25R24" },
  "40X13.5R17":     { code: "40X13.5R17" },
  "40X13.5R20":     { code: "40X13.5R20" },
  "40X13.5R24":     { code: "40X13.5R24" },
  "40X13.5R26":     { code: "40X13.5R26" },
  "40X14.5R20":     { code: "40X14.5R20" },
  "40X14.5R22":     { code: "40X14.5R22" },
  "40X14.5R24":     { code: "40X14.5R24" },
  "40X15.5R20":     { code: "40X15.5R20" },
  "40X15.5R22":     { code: "40X15.5R22" },
  "40X15.5R24":     { code: "40X15.5R24" },
  "40X15.5R26":     { code: "40X15.5R26" },
  "40X15.5R28":     { code: "40X15.5R28" },
  "40X16R15":       { code: "40X16R15" },
  "40X16R16":       { code: "40X16R16" },
  "40X16R17":       { code: "40X16R17" },
  "41X14.5R16":     { code: "41X14.5R16" },
  "41X14.5R17":     { code: "41X14.5R17" },
  "41X14.5R18":     { code: "41X14.5R18" },
  "41X14.5R20":     { code: "41X14.5R20" },
  "41X14.5R22":     { code: "41X14.5R22" },
  "42.5X13.5R15":   { code: "42.5X13.5R15" },
  "42.5X13.5R16":   { code: "42.5X13.5R16" },
  "42.5X13.5R16.5": { code: "42.5X13.5R16.5" },
  "42.5X13.5R17":   { code: "42.5X13.5R17" },
  "42.5X13.5R20":   { code: "42.5X13.5R20" },
  "42.5X13.5R22":   { code: "42.5X13.5R22" },
  "42X14.5R17":     { code: "42X14.5R17" },
  "42X14.5R20":     { code: "42X14.5R20" },
  "42X14R15":       { code: "42X14R15" },
  "42X14R16":       { code: "42X14R16" },
  "42X14R16.5":     { code: "42X14R16.5" },
  "42X14R17":       { code: "42X14R17" },
  "42X15.5R24":     { code: "42X15.5R24" },
  "42X15.5R26":     { code: "42X15.5R26" },
  "42X15.5R28":     { code: "42X15.5R28" },
  "42X15R15":       { code: "42X15R15" },
  "42X15R16":       { code: "42X15R16" },
  "42X15R16.5":     { code: "42X15R16.5" },
  "42X15R17":       { code: "42X15R17" },
  "42X15R20":       { code: "42X15R20" },
  "43X14.5R16.5":   { code: "43X14.5R16.5" },
  "43X14.5R17":     { code: "43X14.5R17" },
  "43X14.5R20":     { code: "43X14.5R20" },
  "44X18.5R15":     { code: "44X18.5R15" },
  "44X18.5R16.5":   { code: "44X18.5R16.5" },
  "44X19.5R15":     { code: "44X19.5R15" },
  "44X19.5R16":     { code: "44X19.5R16" },
  "44X19.5R16.5":   { code: "44X19.5R16.5" },
  "44X19.5R20":     { code: "44X19.5R20" },
  "44X21R15":       { code: "44X21R15" },
  "44X21R16":       { code: "44X21R16" },
  "44X21R16.5":     { code: "44X21R16.5" },
  "44X21R17":       { code: "44X21R17" },
  "44X21R20":       { code: "44X21R20" },
  "45X14.5R20":     { code: "45X14.5R20" },
  "46X19.5R16":     { code: "46X19.5R16" },
  "46X19.5R20":     { code: "46X19.5R20" },
  "47X17R15":       { code: "47X17R15" },
  "47X17R16.5":     { code: "47X17R16.5" },
  "47X17R17":       { code: "47X17R17" },
  "49X21R16.5":     { code: "49X21R16.5" },
  "49X21R17":       { code: "49X21R17" },
  "49X21R20":       { code: "49X21R20" },
  "54X19.5R20":     { code: "54X19.5R20" }
}

export default Sizes

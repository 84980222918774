import { VETOR_APPROVAL_GROUPS } from "@/enums/service-orders"
import Base from "./base"


class Quote extends Base {

  static get relations() {
    return {
      serviceOrder:  { type: "belongsTo", model: "MovidaServiceOrder" },
      quoteProducts: { type: "hasMany",   model: "QuoteProduct" },
      quoteServices: { type: "hasMany",   model: "QuoteService" },
      attachments:   { type: "hasMany",   model: "Attachment" }
    }
  }

  static get attrs() {
    return _.uniq([
      ...super.attrs,

      // - enums
      "state",
      "approvalGroup",

      "description",
      "targetExecutionTime",
      "serviceOrderId",
      "issuedAt",
      "total",

      "consultantRid"
    ])
  }

  static get enums() {
    return {
      state: {
        draft:             "draft",
        issued:            "issued",
        reviewed:          "reviewed",
        changes_requested: "changes_requested"
      },

      approvalGroup: VETOR_APPROVAL_GROUPS.all
    }
  }

  static get constraints() {
    return {
      issuedAt: { presence: true },

      approvalGroup: {
        presence(value, instance) {
          return instance.domain == "gtf"
        }
      },

      total: {
        presence(value, instance) {
          return instance.domain == "rac"
        }
      }
    }
  } // constraints


  get domain() { return this.serviceOrder?.domain }

}


export default Quote

<style lang="scss" scoped>

.form-fields {
  .row + .row {
    padding-top: 24px;
  }

  .identification-section {
    border-bottom: 1px solid $light-gray-3;
    padding-bottom: 24px;
    margin-bottom: 24px;
  }

  .services-section {
    margin-bottom: 24px;
  }
}

</style>


<template lang="pug">

  .form-fields
    section.identification-section
      loading-lines(
        v-if="fetching",
        :lines="4"
      )

      template(v-else)
        ui-row.row
          ui-col(:span="12")
            input-field(
              name="servicePackage[name]",
              v-model="resource.name",
              :loading="submitting",
              :errors="errors.name",
              :placeholder="$t('.fields.name.placeholder')"
            )

        ui-row.row
          ui-col(:span="12")
            textarea-field(
              name="servicePackage[description]",
              v-model="resource.description",
              :loading="submitting",
              :errors="errors.description",
              :placeholder="$t('.fields.description.placeholder')",
              optional
            )

          ui-col(:span="1")

    edit-services.services-section(
      :resource="resource",
      :submitting="submitting",
      :fetching="fetching",
      :errors="errors",
      :initial-ids="initialServiceIds"
    )

    edit-products(
      :resource="resource",
      :submitting="submitting",
      :fetching="fetching",
      :errors="errors",
      :initial-ids="initialProductIds"
    )

</template>


<script>

// Components
import EditProducts from "./products/edit"
import EditServices from "./services/edit"

export default {
  components: {
    EditProducts,
    EditServices
  },

  props: {
    fetching:          { type: Boolean, default: false },
    submitting:        { type: Boolean, default: false },
    errors:            { type: Object, default: () => ({}) },
    resource:          { type: Object, default: () => ({}) },
    initialProductIds: { type: Array, default: () => [] },
    initialServiceIds: { type: Array, default: () => [] }
  },

  data() {
    return {
      i18nScope: "service-packages.form-fields"
    }
  }
}

</script>

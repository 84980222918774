<style lang="scss" scoped>

.sap-sync-button {
  height: 40px;

  .retry,
  .wait {
    border-radius: 4px;
    height: 100%;
    padding: 0 16px;
  }

  .message {
    font-family: $secondary-font;
    font-weight: 500;

    .icon {
      margin-right: 8px;
    }
  }

  .button {
    .icon {
      margin-left: 8px;
    }
  }

  .retry {
    background-color: $light-warning;
  }

  .wait {
    background-color: $light-gray-2;
  }
}

</style>


<template lang="pug">

  .sap-sync-button
    template(v-if="!hasSAPError")
      .wait.flex.vertical-center
        .message
          i.icon.fas.fa-spin.fa-spinner
          template(v-if="hasFetchError")
            span {{ $t('.wait.error.message') }}
          template(v-else)
            span {{ $t('.wait.message') }}

    template(v-else)
      .retry
        form.form.flex.space-between.vertical-center.full-height(
          @submit.prevent="submit",
          method="post"
        )
          .message
            i.icon.fas.fa-times-circle
            span {{ $t('.retry.message') }}

          .button
            template(v-if="submitting")
              i.icon.fas.fa-sync.fa-spin
            template(v-else)
              app-button.link(
                theme="link",
                @click="submit"
              )
                span {{ $t('.retry.link') }}
                i.icon.fas.fa-sync

</template>


<script>

// Mixins
import { mixins } from "movida-common.vue"
const { FormMixin, Fetchable } = mixins

export default {
  mixins: [FormMixin, Fetchable],

  props: {
    id: { type: [String, Number], required: true }
  },

  data() {
    return {
      i18nScope: "stores.tickets.service-orders.show.sap-sync",

      hasSAPError: false,

      // @override Fetchable mixin
      resource: {},

      pollingTimeout: null
    }
  },

  computed: {
    loading() {
      return this.fetching || this.submitting
    }
  },

  beforeDestroy() {
    if (this.pollingTimeout) clearTimeout(this.pollingTimeout)
  },

  methods: {
    poll() {
      this.pollingTimeout = setTimeout(async () => {
        await this._fetch()
      }, 60000)
    },

    // @override Fetchable mixin
    fetch() {
      return this.$sdk.serviceOrders.lastSAPSync({ id: this.id })
    },

    // @override Fetchable mixin
    onFetchSuccess(response) {
      const status = _.get(this.resource, "data.status")

      switch (status) {
        case "error":
          this.hasSAPError = true; break
        case "done":
          this.$emit("synced"); break
        case "scheduled":
        case "running":
        default:
          this.poll(); break
      }
    },

    // @override Fetchable mixin
    onFetchError(err) {
      if (_.get(err, "statusCode") === 404) this.hasSAPError = true
      else this.poll()
    },

    // @override Form mixin
    submitRequest() {
      return this.$sdk.serviceOrders.syncSAP({ id: this.id })
    },

    // @override Form mixin
    submitSuccess(response) {
      this.hasSAPError = false
      this.$notifications.info(this.$t(".notifications.submit.success"))
      this.poll()
    },

    // @override Form mixin
    submitError(err) {
      const errorCode = _.get(err, "original.originalError.response.data.errors.service_order[0]")

      switch (errorCode) {
        case "synced":
          this.$emit("synced"); break
        case "last_event_not_concluded":
          this.hasSAPError = false
          this.$notifications.error(this.$t(".notifications.submit.errors.notConcluded"))
          this.poll()
          break
        default:
          this.$err.log(err)
          this.$notifications.error(this.$t(".notifications.submit.errors.server"))
          break
      }
    }
  }
}

</script>

<style lang="scss" scoped>

.settings {
  .header {
    margin-bottom: 32px;

    .title {
      font-family: $secondary-font;
      font-size: 30px;
      font-weight: 500;
    }
  }

  // .menu {
  //   margin-right: 16px;
  // }
}

</style>


<template lang="pug">

  .settings
    section.header
      .flex.space-between
        h1.title {{ $t('.title') }}

    .flex
      //- section.menu.flex.column-direction

      section.content.grow
        router-view

</template>


<script>

// View
import BaseView from "@/views/base-view"

export default {
  name: "Settings",

  extends: BaseView,

  data() {
    return {
      i18nScope: "settings.show",
      routeName: "settings"
    }
  }
}

</script>

<style lang="scss" scoped>

.heading {
  font-family: $secondary-font;
  font-size: 20px;
  font-weight: 400;
}

.supplier-deal-products-data-load {
  padding: 24px;

  .action-link {
    font-family: $secondary-font;
    font-weight: 500;
    font-size: 14px;
    color: $dark-gray;

    &:hover {
      color: $gray-3;
    }

    .icon {
      font-size: 12px;
      margin-right: 4px;
    }
  }

  .back {
    padding: 4px 0;
  }

  .row + .row {
    padding-top: 16px;
    border-top: 1px solid $light-gray-3;
  }

  .top-row,
  .middle-row {
    height: 54px;
  }

  .top-row {
    .file-icon {
      width: 24px;
      height: 24px;
      margin-right: 24px;

      .icon {
        font-size: 24px;
      }
    }

    .name {
      font-family: $secondary-font;
      font-weight: 400;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .download {
      padding: 0 8px;
    }
  }

  .middle-row {
    .info {
      margin-right: 24px;

      .icon {
        margin-right: 8px;
        color: $gray-3;
      }
    }
  }

  .bottom-row {
    .status {
      .icon {
        width: 20px;
        height: 20px;
        font-size: 20px;
        margin-right: 16px;

        &.success {
          color: $dark-success;
        }

        &.error {
          color: $error;
        }
      }
    }

    .error-messages {
      background-color: $light-gray;
      border-radius: 4px;
      margin-top: 16px;
      font-weight: 400;
      padding: 12px;
      color: $error;
      max-height: 400px;
      overflow-y: auto;

      .error-message {
        .icon {
          font-size: 4px;
          margin: 0 8px;
        }
      }

      .error-line + .error-line {
        margin-top: 8px;
      }
    }
  }
}

</style>


<template lang="pug">

  app-modal.supplier-deal-products-data-load(
    :width="996",
    @close="goBack"
  )
    template(#header)
      header.header
        h1.heading {{ $t('.heading') }}

    .body
      app-button.back.action-link(
        theme="link",
        :to="indexRoute"
      )
        i.icon.fas.fa-arrow-left
        span {{ $t('.btn.back.label') }}

      template(v-if="fetching")
        loading-lines(:lines="4")

      template(v-else-if="hasFetchError")
        .fetch-error-message
          span {{ $t('.error.message') }}

      template(v-else)
        .top-row.row.flex.vertical-center
          .file-icon
            i.icon.fal.fa-file-excel
          .grow
            span.name {{ resource.fileName }}

          download-button.download(:blob-id="resource.blobId")

        .middle-row.row.flex
          .info
            i.icon.fal.fa-file
            span.info-data {{ $asHumanSize(resource.fileSize) }}
          .info
            i.icon.fal.fa-calendar-alt
            span.info-data {{ $fromNow(resource.createdAt) }}
          .info
            i.icon.fal.fa-user
            span.info-data {{ resource.author }}
          template(v-if="resource.replaceAll")
            .info
              i.icon.fal.fa-check
              span.info-data {{ $t('.fields.replaceAll.label') }}

        .bottom-row.row
          .status.flex.vertical-center
            i.icon(:class="iconClass")
            span {{ $t(`.status.${resource.status}.label`) }}

          .error-messages(v-if="hasErrors")
            template(v-if="hasReadErrors")
              .error-message(
                v-for="(error, index) in errors.read",
                :key="`read-error-${index}`"
              )
                span {{ error }}

            template(v-if="hasParseErrors")
              .error-message(
                v-for="(error, index) in errors.parse",
                :key="`parse-error-${index}`"
              )
                span {{ error }}

            template(v-if="hasSaveErrors")
              .error-line(
                v-for="(errorLine, index) in errors.save",
                :key="`save-error-line-${index}`"
              )
                span {{ $t('.errors.line', { line: errorLine.line }) }}
                .error-message.flex.vertical-center(
                  v-for="(error, index) in errorLine.errors",
                  :key="`save-error-${index}`"
                )
                  i.icon.fas.fa-circle
                  span {{ error }}

</template>


<script>

// Libs
import { v4 as uuid } from "uuid"

// Mixins
import { mixins } from "movida-common.vue"

// Extends
import View from "@/views/view"

// Components
import DownloadButton from "./show/download-button"

const { Fetchable } = mixins

export default {
  name: "SupplierDealProductsDataLoad",

  components: {
    DownloadButton
  },

  extends: View,

  mixins: [Fetchable],

  props: {
    backRouteQuery: { type: Object, default: () => ({}) }
  },

  data() {
    return {
      i18nScope: "suppliers.deals.products.data-loads.show",

      supplierId: this.$params.asInteger(this.$route.params.id),
      dealId:     this.$params.asInteger(this.$route.params.dealId),
      dataLoadId: this.$params.asInteger(this.$route.params.dataLoadId),

      uuid,

      // @override Fetchable mixin
      resource: {},
      model:    "DealDataLoad",

      // XXX: Flag para diferenciar a origem da navegação, que pode ser do histórico ou do upload (new)
      isNew: this.$params.asBoolean(this.$route.query.new),

      errors: [],

      pollingTimeout: null
    }
  },

  computed: {
    indexRoute() {
      return {
        name:   "supplierDealProductsDataLoads",
        params: { id: this.supplierId, dealId: this.dealId }
      }
    },

    backRoute() {
      return {
        name:   "supplierDealProducts",
        params: { id: this.supplierId, dealId: this.dealId },
        query:  this.backRouteQuery
      }
    },

    iconClass() {
      switch (this.resource.status) {
        case "scheduled":
        default:
          return "fal fa-hourglass"
        case "running":
          return "fas fa-spinner fa-spin"
        case "done":
          return "fas fa-check-circle success"
        case "error":
          return "fas fa-times-circle error"
      }
    },

    isProcessed() {
      return ["done", "error"].includes(this.resource.status)
    },

    isDone() {
      return this.resource.status === "done"
    },

    hasErrors() {
      return this.resource.status === "error"
    },

    hasReadErrors() {
      if (!this.hasErrors) return false

      return this.resource.step === "read"
    },

    hasParseErrors() {
      if (!this.hasErrors) return false

      return this.resource.step === "parse"
    },

    hasSaveErrors() {
      if (!this.hasErrors) return false

      return this.resource.step === "save"
    }
  },

  beforeDestroy() {
    if (this.pollingTimeout) clearTimeout(this.pollingTimeout)
  },

  methods: {
    // @override extends view.vue
    goBack() {
      // Voltando para rota pai ao fechar o modal
      this.$router.push(this.backRoute)
    },

    // @override Fetchable mixin
    fetch() {
      return this.$sdk.deals.getProductsDataLoad({
        id:         this.dealId,
        dataLoadId: this.dataLoadId
      })
    },

    // @override Fetchable mixin
    onFetchSuccess(response) {
      // XXX: _.camelize (_.camelCase) está removendo caracteres [ e ] das strings,
      // perdendo a estrutura `row[7]` dos erros! então estamos resetando com o dado
      // não camelizado aqui.
      this.resource.log = response.data.log

      // XXX: Polling
      if (!this.isProcessed) {
        this.isNew = false

        this.pollingTimeout = setTimeout(async () => {
          await this._fetch()

          if (this.isDone) this.$emit("change")
        }, 5000)
      }

      if (this.hasErrors) this.parseErrors()

      // XXX: Caso a navegação seja oriunda do upload, já atualiza a index de produtos na primeira
      // oportunidade, dado que o processamento pode ocorrer muito rapidamente e modificar os dados
      // sem a necessidade de polling. Caso a navegação seja do histórico, são dados anteriores que
      // não devem modificar a index de produtos
      if (this.isDone && this.isNew) this.$emit("change")
    },

    // @override Fetchable mixin
    onFetchError(err) {
      // Dealing with appError from extension view.vue
      if (this.hasViewError(err)) this.appError = err

      this.$err.log(err)
      this.$notifications.error(this.$t(".notifications.fetch.failure"))
    },

    // extrai índice de "nested resource".
    // ex: "row[7]" => "7"
    getIndexFromString(string) {
      let matches = string.match(/\w+\[(\d+)\]/)
      return _.present(matches) ? matches[1] : null
    },

    parseErrors() {
      let errors = {}

      const { log } = this.resource

      if (this.hasReadErrors) {
        errors.read = []

        if (_.present(log.file)) errors.read.push(this.$t(".errors.read.file"))
        if (_.present(log.sheet)) errors.read.push(this.$t(".errors.read.sheet"))
      }

      if (this.hasParseErrors) {
        errors.parse = []

        if (_.present(log.header)) errors.parse.push(this.$t(".errors.parse.header"))
        if (_.present(log.rows)) errors.parse.push(this.$t(".errors.parse.rows"))

        const rowKeys = Object.keys(log).filter(key => key.startsWith("row"))
        const replicatedRowKeys = rowKeys.filter(key => log[key].find(entry => entry.error === "replicated"))
        const replicatedRows = replicatedRowKeys.map(key => this.getIndexFromString(key))
        if (_.present(replicatedRows)) {
          errors.parse.push(this.$t(".errors.parse.replicated", {
            ids: replicatedRowKeys.map(key => this.getIndexFromString(key)).join(", ")
          }))
        }
      }

      if (this.hasSaveErrors) {
        errors.save = []

        const dealProductKeys = Object.keys(log).filter(key => key.startsWith("deal_products"))
        const invalidDealProductKeys = dealProductKeys.filter(key => log[key].find(entry => entry.error === "invalid"))

        errors.save = invalidDealProductKeys.map(key => ({
          line:   this.getIndexFromString(key),
          errors: log[key][0].data.errors
        }))
      }

      this.errors = errors
    }
  }
}

</script>

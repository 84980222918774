<script>

import SupplierAvailableDate from "../edit/supplier-available-date.vue"

export default {
  extends: SupplierAvailableDate,

  data() {
    return {
      i18nScope: "tickets.drafts.bookings.show.reserved-supplier-date"
    }
  }
}

</script>

<style scoped lang="scss">

.service-row {
  border-top: 1px solid $light-gray-3;
  height: 69px;

  &:hover {
    background-color: $light-gray;
  }
}

.deleting {
  margin-left: 24px;
}

.side-label {
  width: 8px;
  height: 100%;
  background-color: $light-gray-2;

  &.active {
    background-color: $success;
  }

  &.disabled {
    background-color: $light-error;
  }
}

.spacer {
  width: 48px;
  height: 100%;
}

.status-icon {
  width: 20px;
  height: 20px;
  font-size: 20px;
  color: $gray-2;

  &.active {
    color: $dark-success;
  }

  &.disabled {
    color: $light-gray-3;
  }
}

.content-column {
  overflow: hidden;

  .content {
    overflow: hidden;
    margin-left: 12px;
    margin-right: 24px;

    .service-name {
      font-size: 16px;
      font-weight: normal;
      color: $dark-gray;
      word-break: normal;
      overflow-wrap: break-word;
      margin-bottom: 4px;
      display: block;
    }

    .description {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      display: block;

      font-size: 12px;
    }
  }
}

.right-side {
  .service-kind {
    color: $gray-2;
    margin-right: 24px;
    user-select: none;

    .kind-icon {
      margin-right: 4px;

      ::v-deep.service-kind-icon {
        background-color: unset;
      }
    }

    .service-kind-name {
      font-style: italic;
      font-size: 14px;
      font-weight: 400;
    }
  }

  .status-tag {
    margin-right: 12px;
  }

  .activate {
    margin-right: 24px;
    padding: 8px;

    &:hover {
      background-color: $light-gray-3;
      color: $orange;
    }

    .text {
      font-size: 14px;
    }

    .icon {
      margin-right: 8px;
    }
  }

  .action-menu {
    margin-right: 12px;

    ::v-deep .app-button:hover {
      background-color: $light-gray-3;
    }
  }
}

</style>


<template lang="pug">

  .service-row.flex.vertical-center(:class="{ disabled: isDisabled }", :data-testid="`service-${service.id}`")
    template(v-if="deleting || creating")
      loading-lines.deleting.grow

    template(v-else)
      .spacer.flex.center.vertical-center.no-shrink
        template(v-if="isActive")
          i.status-icon.active.fas.fa-check-circle(v-tooltip.bottom="$t('.status.active')")

        template(v-else-if="isDisabled")
          i.status-icon.disabled.fas.fa-times-circle(v-tooltip.bottom="$t('.status.disabled')")

        template(v-else)
          i.status-icon.fas.fa-minus-circle(v-tooltip.bottom="$t('.status.inactive')")

      .spacer.flex.center.vertical-center.no-shrink
        service-type-icon.service-type-icon(
          :type="service.type"
        )

      .content-column.flex.vertical-center.grow
        .content
          .flex.vertical-center
            template(v-if="isActive")
              span.active.service-name {{ service.name }}

            template(v-else)
              span.service-name {{ service.name }}

          .details.flex.vertical-center(v-if="service.description")
            span.description {{ service.description }}

      .right-side.flex.vertical-center.no-shrink
        template(v-if="!service.isSingle")
          .service-kind.flex.vertical-center(v-tooltip.bottom="$t(`.kind.${service.kind}.tooltip`)")
            service-kind-icon.kind-icon(:kind="service.kind", hide-tooltip)
            span.service-kind-name {{ $t('.kind.text', { kind: Service.$tEnum('kind', service.kind) }) }}

        template(v-else)
          .actions.flex.vertical-center
            template(v-if="!isActive")
              app-button.activate(
                @click="activateService",
                theme="link",
                data-testid="activateButton",
                :disabled="creating"
              )
                i.icon.fal.fa-plus
                span.text {{ $t('.actions.btn.active') }}

            template(v-else)
              supplier-status-tag.status-tag(
                :tooltip="$t('.status.active')",
                value
              )
              popover-menu.action-menu(:show.sync="showPopover", data-testid="popoverMenu")
                popover-menu-button(
                  @click.native="confirmDestroy",
                  icon="fal fa-minus-circle",
                  :text="$t('.actions.btn.destroy')",
                  @close-menu="showPopover = false",
                  data-testid="destroyButton"
                )

</template>


<script>

// Models
import models from "@/models"

const { Service, TicketType } = models

export default {
  name: "ServiceRow",

  props: {
    service: { type: Object, default: () => ({}) }
  },

  data() {
    return {
      i18nScope: "ticket-types.services.index.service-row",

      ticketType: this.$route.params.type,

      deleting: false,
      creating: false,

      showPopover: false,

      Service
    }
  },

  computed: {
    ticketTypeServiceId() {
      return _.get(this.service, "ticketTypeService.id")
    },

    isActive() {
      return _.present(this.ticketTypeServiceId)
    },

    isDisabled() {
      return !this.service.isSingle
    },

    type() {
      return TicketType.parseParamType(this.$route.params.type)
    },
  },

  methods: {
    async confirmDestroy() {
      const isConfirmed = await this.$confirm({
        heading: this.$t(".confirmDestroy.title"),
        message: this.$t(".confirmDestroy.message", {
          serviceName: this.service.name,
          ticketType:  TicketType.$tEnum("type", this.type)
        }),

        actions: {
          confirm: this.$t(".confirmDestroy.confirm")
        }
      })

      if (!isConfirmed) return

      this.destroy()
    },

    async destroy() {
      this.deleting = true

      try {
        await this.$sdk.ticketTypes.destroyTicketTypeService({
          type:                this.ticketType,
          serviceId:           this.service.id,
          ticketTypeServiceId: this.ticketTypeServiceId
        })

        const infoMessage = this.$t(".notifications.destroy.success", {
          serviceName: this.service.name
        })
        this.$notifications.info(infoMessage)
        this.$emit("destroy", this.service.id)
      }
      catch (error) {
        this.$err.log(error)

        const errorMessage = this.$t(".notifications.destroy.error", {
          serviceName: this.service.name
        })

        this.$notifications.error(errorMessage)
      }
      finally {
        this.deleting = false
      }
    },

    async activateService() {
      this.creating = true

      try {
        const { data } = await this.$sdk.ticketTypes.createTicketTypeService({
          type:      this.ticketType,
          serviceId: this.service.id
        })

        this.$notifications.info(this.$t(".notifications.create.success", {
          serviceName: this.service.name,
          serviceType: TicketType.$tEnum("type", this.type)
        }))

        this.$emit("create", data)
      }
      catch (error) {
        this.$err.log(error)
        this.$notifications.error(this.$t(".notifications.create.error"))
      }
      finally {
        this.creating = false
      }
    }
  }
}

</script>

<style scoped lang="scss">

.text {
  font-size: 16px;
}

.icon {
  margin-right: 8px;
}

.checked {
  color: $light-gray-4;

  .text {
    text-decoration: line-through;
  }
}

.info-icon {
  margin-left: 8px;
}

</style>


<template lang="pug">

  .check-item.flex(:class="{ checked }")
    i.icon(v-if="hasIconSlot")
      slot(name="icon")

    .flex.vertical-center
      .text {{ label }}
      template(v-if="tooltip")
        i.info-icon.far.fa-info-circle(v-tooltip.bottom="tooltipOptions")

</template>


<script>

export default {
  name: "CheckItem",

  props: {
    label:   { type: String, default: null },
    checked: { type: Boolean, default: false },
    tooltip: { type: String, default: null }
  },

  data() {
    return {
      i18nScope: "components.check-item"
    }
  },

  computed: {
    hasIconSlot() {
      return _.present(this.$slots.icon)
    },

    tooltipOptions() {
      return {
        content: this.tooltip,
        offset:  "4",
        classes: ["preserve-whitespace"]
      }
    }
  }
}

</script>

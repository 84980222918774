<!--
<doc lang="markdown">
Menu popover

Como usar:
```pug
  popover-menu(:show.sync="showPopover")
    popover-menu-button(
      :to="{ name: 'storeTicket', params: { id, ticketId: ticket.id } }",
      icon="fal fa-eye",
      :text="$t('.actions.btn.show')",
      @close-menu="showPopover = false"
    )
```

</doc>
-->

<style scoped lang="scss">

::v-deep {
  .menu-button.link,
  .popover-menu-button .neutral,
  .button-menu {
    height: 38px;
    padding: 8px 12px;
  }

  .menu-button.link,
  .popover-menu-button.link,
  .popover-menu-button .neutral {
    display: block;
    color: $dark-gray;
    font-weight: 300;

    .content {
      justify-content: flex-start;
    }

    &:hover {
      background: $light-gray-2;
    }
  }

  .menu-button.link:hover,
  .popover-menu-button.link:hover {
    color: $primary;
  }

  .popover-menu-button {
    min-width: 100%;
  }

  .popover-menu-button .movida-common__app-button {
    min-width: 100%;
  }

  .destroy-button .movida-common__app-button.neutral:hover {
    color: $red;
  }

  .icon-content {
    width: 18px;
    margin-right: 8px;
    text-align: center;
  }

  .popover-menu-button + .popover-menu-button {
    border-top: 1px solid $light-gray-3;
  }
}

</style>


<template lang="pug">

  v-popover(
    placement="bottom-end",
    trigger="manual",
    :open="show",
    @hide="$emit('update:show', false)"
  )
    slot(name="presentation")
      app-button.app-button.menu-button(
        theme="link",
        prevent-default,
        :loading="loading",
        @click="$emit('update:show', !show)"
      )
        i.icon.far.fa-ellipsis-h

    .popover-content(slot="popover")
      slot

</template>


<script>

export default {
  name: "PopoverMenu",

  props: {
    show:    { type: Boolean, default: false },
    loading: { type: Boolean, default: false }
  },

  data() {
    return {
      i18nScope: "components.popover-menu"
    }
  }
}

</script>

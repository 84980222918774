<style lang="scss" scoped>

.repair-kind-radio-field {
  .radio-option {
    width: 271px;
    padding: 16px;
    border-radius: 4px;
    border: 1px solid $light-gray-3;
    cursor: pointer;

    &.disabled {
      cursor: not-allowed;
    }

    &.checked {
      background-color: $light-orange;
      border-color: $orange;
    }

    &.error {
      border-color: $error;
    }

    .label {
      margin-left: 16px;
      font-family: $secondary-font;
      font-weight: 400;
    }
  }
}

</style>


<template lang="pug">

  .repair-kind-radio-field
    radio-field(
      name="serviceOrder[kind]",
      :options="kindOptions",
      :disabled="disabled",
      v-model="kind",
      hide-label,
      :errors="errors"
    )
      template(#default="{ props: { option, radioClass, checked, radioClick } }")
        label.flex.vertical-center.radio-option(
          :class="{ checked, disabled, error: hasErrors }",
          :for="`serviceOrder[kind]-${option.value}`",
        )
          input.movida-common__radio-button(
            type="radio",
            :value="option.value",
            :id="`serviceOrder[kind]-${option.value}`"
            :disabled="disabled",
            :class="radioClass",
            :checked="checked",
            @click="radioClick"
          )
          span.label {{ option.label }}

</template>


<script>

// Models
import models from "@/models"
const { ServiceOrderRepair } = models

export default {
  props: {
    value:    { type: String, default: null },
    disabled: { type: Boolean, default: false },
    errors:   { type: Array, default: () => [] }
  },

  data() {
    return {
      i18nScope: "tickets.drafts.vehicles.components.repair-kind-radio-field"
    }
  },

  computed: {
    kindOptions() {
      return ServiceOrderRepair.$enums.kind.values.map(kind => ({
        value: kind, label: ServiceOrderRepair.$tEnum("kind", kind)
      }))
    },

    kind: {
      get() { return this.value },
      set(kind) { this.$emit("input", kind) }
    },

    hasErrors() {
      return _.present(this.errors)
    }
  }
}

</script>

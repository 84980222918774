// Ability
import { ability } from "@/modules/ability"

// Routes
import TicketTypes from "@/views/ticket-types/index.vue"
import TicketTypeServices from "@/views/ticket-types/services/index.vue"

export default [
  {
    path:      "/ticket-types/:type",
    name:      "ticketTypeServices",
    component: TicketTypeServices,
    meta:      {
      auth:        true,
      permissions: [() => ability.can("index", "TicketTypeService")]
    },
  },

  {
    path:      "/ticket-types",
    name:      "ticketTypes",
    component: TicketTypes,
    meta:      {
      auth:        true,
      permissions: [() => ability.can("index", "TicketType")]
    }
  }
]

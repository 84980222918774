<style lang="scss" scoped>

.ticket-types {
  padding-bottom: 24px;
}

.header {
  margin-bottom: 32px;
  padding-bottom: 16px;
  border-bottom: 1px solid $light-gray-3;
}

.title-icon {
  margin-right: 16px;
  font-size: 30px;
}

.title-text {
  font-size: 30px;
  font-weight: normal;
  font-weight: 500;
  font-family: $secondary-font;
}

.ticket-type-rows {
  border: 1px solid $light-gray-3;
  border-radius: 4px;

  .ticket-type-row + .ticket-type-row {
    border-top: 1px solid $light-gray-3;
  }
}

</style>


<template lang="pug">

  .ticket-types
    header.header.flex.space-between.vertical-center
      .title.flex.vertical-center
        i.title-icon.product-icon.fal.fa-headset
        h1.title-text {{ $t('.title') }}

    .ticket-type-rows
      ticket-type-row(
        v-for="ticketType in ticketTypes",
        :key="ticketType.type",
        :ticket-type="ticketType"
      )

</template>


<script>

import models from "@/models"

import TicketTypeRow from "./index/ticket-type-row"

const { TicketType } = models

export default {
  name: "TicketTypes",

  components: {
    TicketTypeRow
  },

  data() {
    return {
      i18nScope: "ticket-types.index",

      ticketTypes: TicketType.$enums.type.values.map(type => new TicketType({ type }))
    }
  }
}

</script>

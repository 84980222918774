<!--
<doc lang="markdown">

Exibe um checkbox com estilo toggle e label estendido

Como usar:
```pug
extended-toggle-field(
  mandatory,
  name="model[prop]",
  label="Primário",
  v-model="resource.value"
)
```

</doc>
-->

<style lang="scss" scoped>

.extended-toggle-field {
  background-color: $light-gray;
  border-radius: 8px;
  padding: 8px;

  .label-content {
    font-size: 14px;
    font-weight: 500;
    font-family: $secondary-font;
    transition: color 0.1s cubic-bezier(.645,.045,.355,1);

    > * + * {
      padding-left: 4px;
    }

    &.focus {
      color: $orange;
    }

    &.error {
      color: $red;
    }

    .mandatory-icon {
      font-size: 4px;
      color: $orange;
    }
  }

  .toggle {
    min-width: 30px;
  }

  .error-message {
    display: block;
    color: $red;
    font-size: 12px;
    line-height: 1;
    padding-top: 4px;
  }
}

</style>


<template lang="pug">

  .extended-toggle-field
    .flex.space-between.vertical-center
      label.label-content.flex.vertical-center(:class="{ error: hasErrors, focus }")
        slot(name="label")
          app-span(crop, :value="label")

        template(v-if="optional")
          span {{ $t('form.optional') }}

        template(v-else-if="mandatory")
          i.mandatory-icon.fas.fa-circle(v-tooltip.top="$t('form.mandatory.tooltip')")

      toggle-field.toggle(
        v-model="_value",
        hide-label,
        hide-value-text,
        :disabled="disabled",
        :id="inputId",
        :loading="loading",
        :name="name"
      )

    template(v-if="hasMessageErrors")
      span.error-message {{ errors[0] }}

</template>


<script>

// Mixins
import FieldMixin from "@/mixins/field"

export default {
  name: "ExtendedToggleField",

  mixins: [FieldMixin],

  data() {
    return {
      i18nScope: "components.extended-toggle-field"
    }
  },

  computed: {
    _value: {
      get() { return this.value },

      set(newValue) { this.$emit("input", newValue) }
    }
  }
}

</script>

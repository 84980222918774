<style lang="scss" scoped>
.totals {
  align-items: stretch;
  background: $light-gray-2;
  border-radius: 4px;
  display: flex;
  gap: 48px;
  justify-content: flex-end;
  padding: 16px;
}

.label {
  font-family: $secondary-font;

  .total:not(.grand-total) & {
    margin-bottom: 4px;
  }
}

.value {
  font-family: $primary-monospace-font;
  font-size: 16px;

  .grand-total & {
    font-size: 20px;
    font-weight: 700;
    line-height: 26px;
  }
}

.separator {
  border-left: 1px solid $light-gray-4;
}
</style>

<template lang="pug">
  service-order-section-layout.service-order-cost-summary-section
    .totals
      .total
        .label {{ $t(".servicesTotal.label") }}
        .value {{ $asCurrency(servicesTotal) }}

      .total
        .label {{ $t(".productsTotal.label") }}
        .value {{ $asCurrency(productsTotal) }}

      .separator

      .total.grand-total
        .label.text-right {{ $t(".total.label") }}
        .value {{ $asCurrency(total) }}
</template>

<script>
import ServiceOrderSection from "./service-order-section"

export default {
  extends: ServiceOrderSection,

  computed: {
    i18nScope() {
      return "monitoring.service-orders.service-order-cost-summary-section"
    },

    productsTotal() {
      return (this.serviceOrder.serviceOrderProducts || [])
        .filter((item) => !item.$markedForDestruction)
        .map((item) => item.totalPrice)
        .filter((totalPrice) => totalPrice != null)
        .reduce((accumulated, current) => accumulated + current, 0) || 0
    },

    servicesTotal() {
      return (this.serviceOrder.serviceOrderServices || [])
        .filter((item) => !item.$markedForDestruction)
        .map((item) => item.totalPrice)
        .filter((totalPrice) => totalPrice != null)
        .reduce((accumulated, current) => accumulated + current, 0) || 0
    },

    total() {
      return this.productsTotal + this.servicesTotal
    }
  }
}
</script>

<style scoped lang="scss">

::v-deep .value {
  width: 100%;
}

</style>


<template lang="pug">

  select-field(
    v-bind="$props",
    v-on="$listeners",
    :options="options"
  )

</template>


<script>

import Vue from "vue"

import SelectField from "@/components/form/select-field"
import models from "@/models"

const { DealProduct } = models

export default {
  extends: SelectField,

  data() {
    return {
      i18nScope: "suppliers.deals.components.part-origin-select-field"
    }
  },

  computed: {
    options() {
      return DealProduct.$enums.partOrigin.values.map(origin => ({
        label: this.$t(`models.dealProduct.enums.partOrigin.${origin}`),
        value: origin
      }))
    }
  },

  // Removendo props 'options' que está sendo reescrita como _computed_
  beforeCreate() {
    Vue.delete(this.$options.props, "options")
  }
}

</script>

// Ability
import { ability } from "@/modules/ability"

// Routes
import ManageResources from "@/views/manage-resources/index.vue"
import ManageResourcesModelGroups from "@/views/manage-resources/model-groups/index.vue"

export default [
  {
    path:      "/manage-resources",
    name:      "manageResources",
    component: ManageResources,
    meta:      {
      auth:        true,
      // permissions: [() => ability.can("index", "TicketType")]
    }
  }, 
  {
    path:      "/manage-resources/model-groups",
    name:      "manageResourcesModelGroups",
    component: ManageResourcesModelGroups,
    meta:      {
      auth:        true,
      // permissions: [() => ability.can("index", "TicketType")]
    }
  }, 
]

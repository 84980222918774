import { Model } from "@caiena/model"
import Base from "../base"

import { BUSINESS_UNITS } from "@/enums/business-units"


class MovidaServiceOrder extends Base {

  static get relations() {
    return {
      vehicle:  { type: "belongsTo", model: "MovidaVehicle"  },
      supplier: { type: "belongsTo", model: "MovidaSupplier" },
      branch:   { type: "belongsTo", model: "Branch"         },

      assignment: { type: "hasOne", model: "ServiceOrderAssignment" },
      contract:   { type: "hasOne", model: "MovidaContract"         },
      quote:      { type: "hasOne", model: "Quote"                  },
      // XXX: é polimórfica! vai ser definida como attr e manipulada sob-demanda
      // customer:   { type: "hasOne", model: "Customer"               },

      serviceOrderServices: { type: "hasMany", model: "MovidaServiceOrderService" },
      serviceOrderProducts: { type: "hasMany", model: "MovidaServiceOrderProduct" }
    }
  }


  static get attrs() {
    return _.uniq([
      ...super.attrs,

      "remoteId",
      "provider",

      "remoteParentId",  // indica que é "complementar"
      "sapId",

      "domain",        // enum
      "businessUnit",  // enum
      "serviceKind",   // enum

      "state",         // enum
      "remoteState",   // enum
      "quoteState",    // enum

      "currentKm",
      "description",
      "ticketDescription",

      // - fks
      "vehicleId",
      "supplierId",
      "branchId",
      "quoteId",

      // - counts
      "messagesCount",
      "postponementsCount",

      // - timestamps
      "issuedAt",
      "scheduledAt",
      "enteringAt",
      "quoteIssuedAt",
      "authorizedAt",
      "estimatedCompletedAt",
      "completedAt",
      "leavingAt",
      "canceledAt",

      // - raw data
      "rawStateLabel",
      "rawServiceKindLabel",

      "consultantRid",
      "consultantName",

      // virtual associations
      "customer"  // XXX: "polimórfica", construída no $afterInit
    ])
  }

  static get enums() {
    return {
      // TODO: remoteState
      // TODO: quoteState

      businessUnit: BUSINESS_UNITS.all,

      serviceKind: {
        enhancement:   "enhancement",   // Acessórios (melhoria do Veículo)
        preventive:    "preventive",    // Manutenção Preventiva (Revisão)
        corrective:    "corrective",    // Manutenção Corretiva
        damage:        "damage",        // Avaria ou Sinistro
        purchase:      "purchase",      // Compra (de peças)
        tow:           "tow",           // Reboque
        key:           "key",           // Chave
        license_plate: "license_plate"  // Placa
      },

      state: {
        open:     "open",
        ongoing:  "ongoing",
        executed: "executed",
        canceled: "canceled"
      }
    }
  } // enums


  // XXX: hack para construir a relação de maneira adequada ("polimórfica")
  $afterInit(...args) {
    super.$afterInit(...args)

    if (this.$present("customer")) {
      const Customer = Model.$lookupModel("Customer")
      this.customer = Customer.build(this.customer)
    }
  }


  get isComplementary() {
    return this.$present("remoteParentId")
  }

  get vehicleStopped() {
    return this.$present("enteringAt") && moment(this.enteringAt).isSameOrBefore(moment())
  }

  get vehicleWithdrawed() {
    return this.$present("leavingAt") && moment(this.leavingAt).isSameOrBefore(moment())
  }

  get quoteIssued() {
    return this.$present("quoteIssuedAt") && moment(this.quoteIssuedAt).isSameOrBefore(moment())
  }

  get authorized() {
    return this.$present("authorizedAt") && moment(this.authorizedAt).isSameOrBefore(moment())
  }

}


export default MovidaServiceOrder

<style lang="scss" scoped>

.occurrence-info {
  overflow: hidden;
  font-size: 14px;

  .header {
    margin-bottom: 4px;
    .id-tag {
      margin-right: 4px;
    }

    .status-badge {
      height: 20px;
      padding: 0 4px;
      border-radius: 12px;
      background-color: $light-gray-3;

      .status-label {
        font-size: 12px;
        font-weight: 500;
      }
    }
  }

  .label {
    .kind {
      margin-right: 2px;
    }

    .diamond-icon {
      font-size: 4px;
      font-weight: 900;
      color: $gray-3;
      width: 4px;
      height: 4px;
      margin: 0 4px;
    }
  }

  .description {
    margin-top: 4px;
  }
}

</style>


<template lang="pug">

  .occurrence-info.vertical-center
    .header.flex
      vetor-code-tag.id-tag(:value="occurrence.id")
      //- XXX: existem Ocorrências com status "null"!
      .status-badge.vertical-center.center(v-if="!!occurrence.status")
        app-span.status-label(:value="Occurrence.$tEnum('status', occurrence.status)")

    .content
      .label.flex.vertical-center
        app-span(:value="$t('.createdAt', { date: $l('date.formats.default', occurrence.createdAt) })")
        i.diamond-icon.fal.fa-diamond
        app-span.kind(:value="Occurrence.$tEnum('kind', occurrence.kind)")

      .description(v-if="hasDescription")
        app-span(:value="occurrence.description")

</template>


<script>

// Models
import models from "@/models"
const { Occurrence } = models

export default {
  props: {
    occurrence: { type: Object, required: true }
  },

  data() {
    return {
      i18nScope: "tickets.drafts.vehicles.components.occurrence-info",

      Occurrence
    }
  },

  computed: {
    hasDescription() {
      return _.present(this.occurrence.description)
    }
  }
}

</script>

<!--
<doc lang="markdown">
Exibe um código, prefixado por # (ícone, não selecionável)

_Props_: value, size

Como usar:
```pug
  code-tag(value="25061987")
```

Ou passando valor por prop:
```
  code-tag(:value="myCodeValue")
```
</doc>
-->

<style lang="scss" scoped>

.tag {
  font-family: $primary-monospace-font;

  .icon {
    margin-right: 4px;
  }

  .normal {
    .icon {
      font-size: 12px;
    }
  }

  .big {
    .icon {
      font-size: 18px;
    }
  }

  .small {
    .icon {
      font-size: 10px;
    }
  }
}

.disabled {
  ::v-deep .app-tag-label {
    background-color: $light-gray-4;
    color: $gray-3;
  }
}

</style>


<template lang="pug">

  app-tag.tag(:size="size", :color="color", :class="{ disabled }")
    .icon.flex.vertical-center(v-if="!withoutIcon")
      slot(name="icon")
        i(:class="iconClass")

    slot
      span {{ value }}

</template>


<script>

export default {
  name: "CodeTag",

  props: {
    color:       { type: String, default: "purple" },
    iconClass:   { type: String, default: "fal fa-hashtag" },
    withoutIcon: { type: Boolean, default: false },
    value:       { type: [String, Number], default: null },
    size:        { type: String, default: "normal", validator: size => ["small", "normal", "big"].includes(size) },
    disabled:    { type: Boolean, default: false }
  },

  data() {
    return {
      i18nScope: "components.code-tag"
    }
  }
}

</script>

<style scoped lang="scss">

.edit-service {
  padding-bottom: 24px;
}

.header {
  margin-bottom: 32px;
  padding: 18px 0 24px 0;
  border-bottom: 1px solid $light-gray-3;
}

.title {
  font-size: 30px;
  font-weight: 500;
  font-family: $secondary-font;
}

.id-tag {
  margin: 0 8px;
}

</style>


<template lang="pug">

  .edit-service
    header.header.flex.vertical-center
      h1.title {{ $t('.title') }}
      template(v-if="!fetching && resource")
        id-tag.id-tag(:value="resource.id", size="big")

    form.form(@submit.prevent="submit", method="post")
      form-fields(
        edit,
        :resource="resource",
        :fetching="fetching",
        :submitting="submitting",
        :errors="errors"
      )

      section.flex.space-between.vertical-center
        app-button(
          :to="{ name: 'service', params: { serviceId } }",
          theme="gray",
          outline,
          :disabled="submitting"
        ) {{ $t('btn.cancel') }}

        app-button(
          type="submit",
          :loading="submitting"
        )
          span {{ $t('btn.update.label') }}
          span(slot="loading") {{ $t('btn.update.loading') }}

</template>


<script>

// Mixins
import { mixins } from "movida-common.vue"
import FetchMixin from "@/mixins/fetch-mixin"

// Local Components
import FormFields from "./form-fields"

// Extends
import LoadedView from "@/views/loaded-view"

const { FormMixin } = mixins

export default {
  name: "EditService",

  components: {
    FormFields
  },

  extends: LoadedView,

  mixins: [FormMixin, FetchMixin],

  data() {
    return {
      i18nScope: "services.edit",
      routeName: "editService",

      serviceId: null,

      resource: {}
    }
  },

  methods: {
    parseRoute() {
      this.serviceId = this.$params.asInteger(this.$route.params.id)
    },

    // @override Form mixin
    serializeAttributes() {
      return {
        ...this.resource.$serialize(),

        vehicle_issue_ids: this.resource.vehicleIssueIds
      }
    },

    // @override Form mixin
    submitRequest() {
      return this.$newSdk.services.update({ serviceId: this.serviceId, params: this.serializeAttributes() })
    },

    // @override Form mixin
    submitSuccess(response) {
      this.$notifications.info(this.$t(".notifications.submit.success", { name: this.resource.name }))

      this.$router.push({ name: "service", params: { id: this.serviceId } })
    },

    // @override Form mixin
    submitError(err) {
      this.$err.log(err)
      this.$notifications.error(this.$t(".notifications.submit.error"))
    },

    // @override FetchMixin mixin
    fetchRequest() {
      return this.$newSdk.services.find({ serviceId: this.serviceId })
    },

    // @override FetchMixin mixin
    onFetchSuccess({ data }) {
      data.vehicleIssueIds = this.resource.vehicleIssueIds
      this.resource = data
      this.initialSerializedAttributes = this.serializeAttributes()
    }
  }
}

</script>

<script>

import EmailHistory from "./email-history.vue"

export default {
  extends: EmailHistory,

  props: {
    serviceOrderId: { type: [String, Number], required: true }
  },

  methods: {
    fetchRequest() {
      return this.$newSdk.serviceOrders.allExecutedMailDeliveries({ serviceOrderId: this.serviceOrderId })
    },

    submitRequest() {
      const params = this.resource.$serialize()

      return this.$newSdk.serviceOrders.resendExecutedEmail({
        serviceOrderId: this.serviceOrderId,
        params
      })
    }
  }
}

</script>

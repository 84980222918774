<!--
# AppDaterangePicker

Input para período de datas. Utiliza a biblioteca Flatpickr como base e adiciona
seletor de períodos predeterminados e inputs de texto para cada das datas.

## Props
- end-of-day?: {boolean} Data fim deve corresponder ao final do dia.
- error?: {boolean} Indica se há erro na validação do campo.
- labels?: {object} Configura as labels dos inputs internos
- labels.start?: {string}
- labels.end?: {string}
- value: {[string, string]} Data início e fim em formato ISO-8601.

Demais props são encaminhadas para o input principal do componente, permitindo
usar atributos como `placeholder` e `disabled`.

## Uso
```pug
app-daterange-picker(
  v-model="dateRange"
  :disabled="loading"
  placeholder="Data"
)
```
-->

<style lang="scss" scoped>
.app-daterange-picker {
  position: relative;
  width: 100%;
}

.input {
  border: 1px solid $gray;
  border-radius: 4px;
  color: $gray-3;
  font-family: $primary-font;
  font-size: 16px;
  height: 40px;
  line-height: 40px;
  padding: 1px 62px 1px 13px;
  width: 100%;

  &::placeholder {
    color: $gray;
    font-weight: 300;
  }

  &.active,
  &:hover,
  &:focus {
    border-color: $primary;
  }
  &.active {
    outline: 2px solid $primary;
    outline-offset: -2px;
  }

  &:disabled {
    background-color: $light-gray-3;
    border-color: $light-gray-4;
    color: $gray-2;
    cursor: not-allowed;
  }
  .error .flatpickr-wrapper > & {
    border-color: $error;
  }
}

.clear-button {
  align-items: center;
  background: transparent;
  border: none;
  border-radius: 24px;
  color: $dark-gray;
  display: flex;
  font-size: 16px;
  height: 24px;
  justify-content: center;
  margin: 7px;
  position: absolute;
  right: 25px;
  top: 0;
  width: 24px;

  &:hover {
    background: $light-gray-3;
    color: $primary;
  }
}

.icon {
  color: $gray-4;
  margin: 11px;
  position: absolute;
  right: 0;
  top: 0;
}

::v-deep .flatpickr-wrapper {
  width: 100%;

  .flatpickr-calendar {
    border: none;
    border-radius: 8px;
    box-shadow: 0 8px 24px rgba(0, 0, 0, 0.1);
    font-family: $secondary-font;
    padding: 16px;
  }

  .inputs {
    align-items: flex-end;
    display: flex;
    font-family: $secondary-font;
    justify-content: space-between;
    margin-bottom: 16px;

    .input {
      @extend .input;
    }
  }

  .range-input-field {
    flex-grow: 1;

    .label {
      color: $dark-gray;
      font-size: 14px;
      font-weight: 500;
      line-height: 17px;
      text-align: left;
    }
  }

  .dash {
    color: $dark-gray;
    flex-shrink: 0;
    font-size: 16px;
    font-weight: 500;
    line-height: 40px;
    margin: 0 16px;
  }

  .flatpickr-months {
    .flatpickr-prev-month,
    .flatpickr-next-month {
      height: 16px;
      padding: 8px;
      position: static;
    }

    .flatpickr-month:first-of-type {
      margin-right: 42px;
    }
    .flatpickr-month:last-of-type {
      margin-left: 42px;
    }
  }

  .flatpickr-weekdays {
    background: transparent;

    .flatpickr-weekday {
      font-size: 14px;
      line-height: 1.5;
    }
  }
  .flatpickr-weekdaycontainer {
    &:not(:first-child) {
      padding-left: 12px;
    }
    &:not(:last-child) {
      padding-right: 12px;
    }
  }

  .flatpickr-current-month {
    color: $gray-4;
    font-size: 14px;
    font-weight: 500;
    line-height: 1.5;

    .cur-month,
    .cur-year {
      font-weight: 500;
    }

    .numInputWrapper {
      min-width: 6ch;
    }
  }

  .dayContainer {
    box-shadow: none;
    padding: 0;

    &:not(:first-child) {
      padding-left: 12px;
    }
    &:not(:last-child) {
      padding-right: 12px;
    }

    .flatpickr-day {
      border: none;
      color: $dark-gray;

      &.inRange {
        background: $light-brand;
        box-shadow: -5px 0 0 $light-brand, 5px 0 0 $light-brand;

      }

      &.startRange,
      &.endRange {
        background: $brand;
        color: white;
      }
      &.startRange {
        border-radius: 8px 0 0 8px;
      }
      &.endRange {
        border-radius: 0 8px 8px 0;
      }
      &.startRange.endRange {
        border-radius: 8px;
      }

      &.nextMonthDay,
      &.prevMonthDay {
        background: transparent;
        box-shadow: none;
        color: $gray;
        visibility: visible;
      }
    }
    &:nth-child(n+1) {
      .flatpickr-day:nth-child(7n+1) {
        &.inRange {
          box-shadow: -2px 0 0 $light-brand, 5px 0 0 $light-brand !important;
        }
      }
      .flatpickr-day:nth-child(7n+7) {
        &.inRange {
          box-shadow: -5px 0 0 $light-brand !important;
        }
      }
      .flatpickr-day:nth-child(7n+1),
      .flatpickr-day:nth-child(7n+7) {
        &.nextMonthDay,
        &.prevMonthDay {
          box-shadow: none !important;
        }
      }
    }
  }

  .footer {
    display: flex;
    gap: 24px;
    margin-top: 16px;

    .preset-range-button {
      background: white;
      border: 1px solid $gray;
      border-radius: 4px;
      color: $dark-gray;
      font-family: $primary-font;
      font-size: 16px;
      line-height: 22px;
      padding: 8px 16px;

      &:hover {
        border-color: $primary;
        color: $primary;
      }
    }
  }
}
</style>

<template lang="pug">
.app-daterange-picker(:class="{ 'error': error }")
  //- XXX .flatpickr-wrapper INICIO (adicionado pelo plugin)
  input.input(
    v-bind="$attrs"
    :disabled="disabled"
    ref="input"
  )
  //- XXX .flatpickr-wrapper FIM

  button.clear-button(
    v-if="isSet && !disabled"
    tabindex="-1"
    type="button"
    @click.prevent="clearValue"
  )
    i.far.fa-times

  i.icon.far.fa-calendar-alt
</template>

<script>
import flatpickr from "flatpickr"
import {
  flatpickrPresetRangesPlugin,
  flatpickrRangeInputPlugin,
  flatpickrWidthPaddingPlugin
} from "@/lib/flatpickr-plugins"

export default {
  name: "AppDaterangePicker",

  inheritAttrs: false,

  props: {
    disabled: { type: Boolean, default: false },
    endOfDay: { type: Boolean, default: true },
    error:    { type: Boolean, default: false },
    labels:   { type: Object, default: null },
    presets:  { type: String, default: "past", validator: (value) => ["past", "future"].includes(value) },
    value:    { type: Array, default: null }
  },

  data() {
    return {
      pickerInstance:         null,
      shouldIgnoreNextUpdate: false
    }
  },

  computed: {
    isSet() {
      return _.present(this.value)
    }
  },

  watch: {
    value: {
      deep: true,
      handler(value) {
        this.handleModelChange(value)
      }
    }
  },

  mounted() {
    this.pickerInstance = flatpickr(
      this.$refs.input,
      {
        dateFormat: "d/m/Y",
        mode:       "range",
        onChange:   this.handleChange,
        plugins:    [
          flatpickrWidthPaddingPlugin({ padding: 16 }),
          flatpickrPresetRangesPlugin({ presets: this.presets }),
          flatpickrRangeInputPlugin({
            labels: {
              start: _.get(this.labels, "start", "A partir de"),
              end:   _.get(this.labels, "end", this.endOfDay ? "Até" : "Antes de")
            }
          })
        ],
        showMonths: 2,
        static:     true
      }
    )
    this.pickerInstance.setDate(this.value, true)
  },

  beforeDestroy() {
    if (this.pickerInstance) this.pickerInstance.destroy()
  },

  methods: {
    clearValue() {
      this.pickerInstance.clear()
    },

    handleChange(event) {
      const isoDates = event
        .map((date) => moment(date))
        .map((date, index) => (this.endOfDay && index == 1 ? date.endOf("day") : date))
        .map((date) => moment(date).toISOString())

      this.ignoreNextModelChange()
      this.$emit("input", isoDates)
    },

    handleModelChange(value) {
      // XXX verificação para evitar atualizar novamente após um emit
      if (!this.shouldUpdatePicker()) return

      this.pickerInstance.setDate(value)
    },

    ignoreNextModelChange() {
      this.shouldIgnoreNextUpdate = true
    },

    shouldUpdatePicker() {
      if (this.shouldIgnoreNextUpdate) {
        this.shouldIgnoreNextUpdate = false
        return false
      }
      return true
    }
  }
}
</script>

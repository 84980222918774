// Base
import Base from "./Base"

// Models
import models from "@/models"
const { User } = models

class UserRequests extends Base {
  static get selector() {
    return "user"
  }

  me({ token, params = {}, options = {} } = {}) {
    this.verifyData("me", { token })

    return this.action({
      method:  "get",
      url:     "/me",
      params,
      options: {
        model: User,
        token,

        ...options
      }
    })
  }
}

export default UserRequests

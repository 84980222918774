<template lang="pug">

  select-field(
    v-bind="$props",
    v-on="$listeners",
    :options="options"
  )

</template>


<script>

import Vue from "vue"

// Extends
import SelectField from "@/components/form/select-field"

export default {
  extends: SelectField,

  data() {
    return {
      i18nScope: "tickets.drafts.vehicles.components.fuel-level-select-field"
    }
  },

  computed: {
    options() {
      return [...Array(9).keys()].map(level => ({
        label: `${level}/8`,
        value: level
      }))
    }
  },

  // Removendo props 'options' que está sendo reescrita como _computed_
  beforeCreate() {
    Vue.delete(this.$options.props, "options")
  }
}

</script>

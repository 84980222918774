<template lang="pug">
  finder-field.geo-region-finder-field(
    v-bind="forwardedProps"
    v-on="$listeners"
    :options="options"
  )
</template>

<script>
import { REGIONS } from "@/enums/geo"

export default {
  inheritAttrs: false,

  data() {
    return {
      options: REGIONS.values.map((region) => ({
        label: this.$t(`enums.geo.REGIONS.${region}`),
        value: region
      }))
    }
  },

  computed: {
    forwardedProps() {
      return _.omit(this.$attrs, ["options"])
    }
  }
}
</script>

<style scoped lang="scss">

.product-row {
  padding: 12px 0;
  min-height: 70px;
  border-top: 1px solid $light-gray-3;
  border-bottom: 1px solid $light-gray-3;
}

.product-row + .product-row {
  border-top: 1px solid transparent;
}

.icon-column {
  min-width: 72px;
}

.product-name {
  font-family: $secondary-font;
  font-size: 18px;
  font-weight: normal;
  color: $dark-gray;
  word-break: normal;
  overflow-wrap: break-word;
  margin-bottom: 4px;
  display: block;

  &.link {
    &:hover {
      color: $primary;
    }
  }
}

.id-tag {
  margin-right: 8px;
}

.brand {
  font-size: 14px;
  font-weight: normal;
  color: $dark-gray;
}

.price-column {
  flex-shrink: 0;
  font-size: 14px;
  font-weight: normal;
  color: $dark-gray;
  height: 100%;

  padding-right: 24px;

  .target-icon {
    font-size: 18px;
    margin-right: 8px;
    color: $success;
  }
}

.content-column {
  padding-right: 12px;

  .discarded-icon {
    margin-right: 4px;
  }
}

</style>


<template lang="pug">

  .product-row.flex.vertical-center
    .icon-column.flex.center.vertical-center
      product-type-icon(:type="product.type")

    .content-column.grow
      .flex
        discarded-icon.discarded-icon(v-if="product.$discarded")
        template(v-if="showLink")
          router-link.product-name.link(:to="{ name: 'product', params: { id: product.id } }")
            | {{ product.productName }}
        span.product-name(v-else) {{ product.productName }}

      .details.flex.vertical-center
        id-tag.id-tag(:value="product.id")

        sap-code-tag.id-tag(:value="product.sapId")

        .brand
          span(v-if="product.$get('brand.name')") {{ product.$get('brand.name') }}
          span.global-empty-text(v-else) {{ $t('.unregistered.brand') }}

    .actions-column(v-if="hasActionsSlot")
      slot(name="actions")

</template>


<script>

export default {
  name: "BaseProductRow",

  props: {
    product:   { type: Object, default: () => ({}) },
    showLink:  { type: Boolean, default: true },
    showPrice: { type: Boolean, default: true }
  },

  data() {
    return {
      i18nScope: "components.product.base-product-row"
    }
  },

  computed: {
    hasActionsSlot() {
      return !!this.$slots.actions
    }
  }
}

</script>

<style lang="scss" scoped>

.supplier-tickets-header {
  .header {
    margin-bottom: 32px;

    .title-text {
      font-size: 30px;
      font-weight: normal;
      font-weight: 500;
      font-family: $secondary-font;
    }
  }
}

</style>


<template lang="pug">

  .supplier-tickets-header
    header.header
      .title.flex.vertical-center
        h1.title-text {{ $t('.title') }}
      .g-tab-navbar-content
        router-tab(
          :to="{ name: 'supplierTickets', params: { id: supplierId } }",
        ) {{ $t('.tab.tickets') }}

        router-tab(
          :to="{ name: 'supplierServiceOrders', params: { id: supplierId } }",
        ) {{ $t('.tab.serviceOrders') }}

    .page-content
      router-view

</template>


<script>

export default {
  props: { supplierId: { type: [String, Number], required: true } },

  data() {
    return {
      i18nScope: "suppliers.components.supplier-tickets-header"
    }
  }
}

</script>

import Base from "./Base"

class TicketTypes extends Base {
  listServices({ params = {} } = {}) {
    if (_.blank(params.ticket_type)) {
      throw new Error(
        'SDK TicketTypes - #listServices() - "params.ticket_type" is a required prop'
      )
    }

    return this.action({
      method: "get",
      url:    `/services_with_ticket_type_services`,
      params
    })
  }

  listActiveServices({ type, params = {} } = {}) {
    if (_.blank(type)) {
      throw new Error(
        'SDK TicketTypes - #listActiveServices() - "type" is a required prop'
      )
    }

    return this.action({
      method: "get",
      url:    `/ticket_types/${type}/services`,
      params
    })
  }

  createTicketTypeService({ type, serviceId, params = {} } = {}) {
    if (_.blank(type)) {
      throw new Error(
        'SDK TicketTypes - #createTicketTypeService() - "type" is a required prop'
      )
    }

    if (_.blank(serviceId)) {
      throw new Error(
        'SDK TicketTypes - #createTicketTypeService() - "serviceId" is a required prop'
      )
    }

    return this.action({
      method: "post",
      url:    `/ticket_types/${type}/services/${serviceId}/ticket_type_services`,
      params
    })
  }

  getTicketTypeService({
    type, serviceId, ticketTypeServiceId, params = {}
  } = {}) {
    if (_.blank(type)) {
      throw new Error(
        'SDK TicketTypes - #getTicketTypeService() - "type" is a required prop'
      )
    }

    if (_.blank(serviceId)) {
      throw new Error(
        'SDK TicketTypes - #getTicketTypeService() - "serviceId" is a required prop'
      )
    }

    if (_.blank(ticketTypeServiceId)) {
      throw new Error(
        'SDK TicketTypes - #getTicketTypeService() - "ticketTypeServiceId" is a required prop'
      )
    }

    return this.action({
      method: "get",
      url:    `/ticket_types/${type}/services/${serviceId}/ticket_type_services/${ticketTypeServiceId}`,
      params
    })
  }

  destroyTicketTypeService({
    type, serviceId, ticketTypeServiceId, params = {}
  } = {}) {
    if (_.blank(type)) {
      throw new Error(
        'SDK TicketTypes - #destroyTicketTypeService() - "type" is a required prop'
      )
    }

    if (_.blank(serviceId)) {
      throw new Error(
        'SDK TicketTypes - #destroyTicketTypeService() - "serviceId" is a required prop'
      )
    }

    if (_.blank(ticketTypeServiceId)) {
      throw new Error(
        'SDK TicketTypes - #destroyTicketTypeService() - "ticketTypeServiceId" is a required prop'
      )
    }

    return this.action({
      method: "delete",
      url:    `/ticket_types/${type}/services/${serviceId}/ticket_type_services/${ticketTypeServiceId}`,
      params
    })
  }
}

export default TicketTypes

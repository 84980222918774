import Base from "./base"

class VehicleIssue extends Base {
  static get attrs() {
    return _.uniq([
      ...super.attrs,
      "symptomId",
      "vehiclePartId"
    ])
  }

  static get relations() {
    return {
      vehiclePart: { type: "belongsTo", model: "VehiclePart" },
      symptom:     { type: "belongsTo", model: "Symptom" }
    }
  }

  static get constraints() {
    return {
      symptomId: {
        presence: true
      }
    }
  }
}

export default VehicleIssue

<style scoped lang="scss">

.service-kind-icon {
  --size: 14px;
  color: $gray-3;
  font-size: var(--size);
  line-height: var(--size);
  font-weight: 900;
  justify-content: center;
  align-items: center;
  margin-left: 8px;
}

</style>

<template lang="pug">

  .icon
    i.fal.service-kind-icon(
      :class="icon",
      v-tooltip.bottom="{ content: tooltip, offset: '4' }"
    )

</template>

<script>

import models from "@/models"
const { Service } = models

export default {
  name: "ServiceKindIcon",

  props: {
    kind:        { type: String, default: null },
    hideTooltip: { type: Boolean, default: false }
  },

  computed: {
    tooltip() {
      if (!this.kind || this.hideTooltip) return null

      return Service.$tEnum("kind", this.kind)
    },

    icon() {
      switch (this.kind) {
        case "single": return "fa-wrench"
        case "composite": return "fa-layer-plus"
        default: return null
      }
    }
  }
}

</script>

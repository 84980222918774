<style lang="scss" scoped>

.products-table {
  .skeleton-table {
    margin-top: 16px;

    .skeleton-row + .skeleton-row {
      margin-top: 24px;
    }
  }

  .empty {
    margin-top: 24px;

    .message {
      font-style: italic;
      font-weight: 400;
    }

    .icon {
      user-select: none;
      margin-bottom: 4px;
      height: 88px;
      width: 88px;
    }
  }

  .list {
    margin-top: 16px;
  }
}

</style>


<template lang="pug">

  .products-table
    template(v-if="initializing")
      .skeleton-table
        product-row-skeleton.skeleton-row(
          v-for="i in 5",
          :key="`products-row-skeleton-${i}`"
        )

    template(v-else)
      products-table-header(
        :loading="loading",
        @sort="param => $emit('sort', param)"
        :sort-param="sortParam",
        :sort-order="sortOrder",
        data-testid="productsTableHeader"
      )

      template(v-if="empty")
        .empty.flex.center
          .flex.column-direction.vertical-center
            img.icon(:src="emptyIcon")
            span.message {{ $t('index.empty.message') }}

      template(v-else)
        product-row(
          v-for="product in products",
          :key="product.id",
          :product="product",
          :loading="loading",
          :show-actions="showActions"
          @destroy="$emit('changed')",
          @disabled="$emit('changed')",
          @enabled="$emit('changed')",
          data-testid="productRow"
        )

</template>


<script>

// Assets
import emptyIcon from "@/assets/images/icons/search/products/none-found.svg"

// Components
import ProductRow from "./product-row.vue"
import ProductRowSkeleton from "./product-row-skeleton"
import ProductsTableHeader from "./products-table-header.vue"

export default {
  components: {
    ProductRow,
    ProductRowSkeleton,
    ProductsTableHeader
  },

  props: {
    products:     { type: Array, required: true },
    initializing: { type: Boolean, default: false },
    loading:      { type: Boolean, default: false },
    sortedBy:     { type: String, default: "" },
    showActions:  { type: Boolean, default: true }
  },

  data() {
    return {
      i18nScope: "products.index.products-table",

      emptyIcon
    }
  },

  computed: {
    empty() {
      return _.blank(this.products)
    },

    sortParam() {
      return this.sortedBy.split(":")[0]
    },

    sortOrder() {
      return this.sortedBy.split(":")[1] || "asc"
    }
  }
}

</script>

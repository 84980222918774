<style lang="scss" scoped>

.service-support-info {
  margin-bottom: 24px;
  padding-bottom: 24px;
  border-bottom: 1px solid $light-gray-3;

  .fetch-error {
    .retry {
      margin-left: 4px;
    }
  }

  .top-row {
    margin-bottom: 16px;

    &.active {
      margin-bottom: 8px;
    }

    .back {
      font-family: $secondary-font;
      font-weight: 500;
      font-size: 14px;

      &.link {
        color: $gray-3;

        &:hover {
          color: $orange;
        }
      }

      .icon {
        margin-right: 4px;
      }
    }

    .actions {
      .action {
        font-size: 14px;

        +.action {
          margin-left: 24px;
        }

        .icon {
          margin-right: 4px;
        }
      }
    }
  }

  .bottom-row {
    .info {
      .service-type-icon {
        width: 40px;
        height: 40px;
        margin-right: 16px;
      }

      .name {
        font-family: $secondary-font;
        font-weight: 500;
        font-size: 30px;
      }

      .status {
        margin-left: 16px;
        height: 32px;
        font-weight: 500;
        font-size: 18px;

        ::v-deep .icon {
          font-size: 18px;
        }
      }

      .capacities {
        margin-left: 16px;

        .diamond-icon {
          margin: 0 8px;
          font-size: 4px;
          color: $gray-3;
        }
      }
    }

    .activate {
      .icon {
        margin-right: 8px;
      }
    }
  }
}

</style>


<template lang="pug">

  .service-support-info
    template(v-if="fetching")
      loading-lines(:lines="3")

    template(v-else)
      .top-row.flex.space-between.vertical-center(:class="{ active: isActive }")
        app-button.back(
          theme="link",
          :to="{ name: 'supplierServiceSupports', params: { id: supplierId } }"
        )
          i.icon.far.fa-arrow-left
          span {{ $t('.btn.back.label') }}

        template(v-if="isActive")
          .actions.flex
            destroy-action.action.destroy(
              :supplier-id="supplierId",
              service-type-slug="Maintenance",
              :service-name="SupplierServiceSupport.$tEnum('serviceType', 'Maintenance')",
              size="small",
              border="rounded",
              @click.native="showPopover = false"
              @destroy="_fetch",
              data-testid="deactivateButton"
            )
              i.icon.fal.fa-minus-circle
              span {{ $t('.btn.destroy.label') }}

            app-button.action.edit(
              v-if="$can('update', 'Supplier')",
              theme="neutral",
              size="small",
              @click="showEditServiceSupportModal = true"
            )
              i.fal.fa-pencil.icon
              span {{ $t('.btn.edit.label') }}

      template(v-if="hasError")
        .fetch-error
          span.error-message {{ $t('.fetch-error.message') }}
          app-button.retry(theme="link", @click="_fetch") {{ $t('.fetch-error.retry.label') }}

      template(v-else)
        .bottom-row.flex.space-between
          .info.flex.vertical-center
            service-type-icon.service-type-icon(type="Maintenance")
            h2.name {{ SupplierServiceSupport.$tEnum('serviceType', 'Maintenance') }}
            supplier-status-tag.status(:value="isActive")
            template(v-if="isActive")
              .capacities.flex
                span.total(
                  v-tooltip.bottom="$t('models.supplierServiceSupport.tooltip.totalCapacity')"
                ) {{ $t('.capacity.total', { count: resource.totalCapacity }) }}
                i.diamond-icon.fa.fa-diamond.flex.center.vertical-center
                span.hourly {{ $t('.capacity.hourly', { count: resource.hourlyCapacity }) }}

          template(v-if="!isActive")
            app-button.activate(
              v-if="$can('update', 'Supplier')",
              @click="showNewServiceSupportModal = true"
            )
              i.icon.far.fa-check
              span {{ $t('.btn.activate.label') }}

      edit-service-support(
        v-if="showEditServiceSupportModal",
        :supplier-id="supplierId",
        service-type-slug="Maintenance",
        @close="showEditServiceSupportModal = false",
        @update="_fetch"
      )

      new-service-support(
        v-if="showNewServiceSupportModal",
        :supplier-id="supplierId",
        service-type-slug="Maintenance",
        @close="showNewServiceSupportModal = false",
        @create="_fetch"
      )

</template>


<script>

// Mixins
import { mixins } from "movida-common.vue"

// Models
import models from "@/models"

// Components
import NewServiceSupport from "@/views/suppliers/service-supports/new"
import EditServiceSupport from "@/views/suppliers/service-supports/edit"
import DestroyAction from "@/views/suppliers/service-supports/components/destroy-action"

const { Fetchable } = mixins
const { SupplierServiceSupport } = models

export default {
  components: {
    NewServiceSupport,
    EditServiceSupport,
    DestroyAction
  },

  mixins: [Fetchable],

  props: {
    supplierId: { type: [String, Number], required: true }
  },

  data() {
    return {
      i18nScope: "suppliers.service-supports.maintenance.vehicle-model-groups.index.service-support-info",

      // @override Fetchable mixin
      resource: {},
      model:    "SupplierServiceSupport",

      showNewServiceSupportModal:  false,
      showEditServiceSupportModal: false,

      SupplierServiceSupport,

      hasError: false
    }
  },

  computed: {
    isActive() {
      return _.present(_.get(this.resource, "id"))
    }
  },

  methods: {
    // @override Fetchable mixin
    fetch() {
      return this.$sdk.suppliers.getServiceSupport({
        id:              this.supplierId,
        serviceTypeSlug: "Maintenance"
      })
    },

    // @override Fetchable mixin
    // onFetchSuccess(response) {},

    // @override Fetchable mixin
    onFetchError(err) {
      // XXX erro 404 significa que o service-support não está ativado
      if (err.statusCode === 404) {
        this.resource = new SupplierServiceSupport({ serviceType: "Maintenance" })
      }
      else {
        this.hasError = true

        this.$err.log(err)
        this.$notifications.error(this.$t(".notifications.fetch.failure"))
      }
    }
  }
}

</script>

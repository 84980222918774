<style lang="scss" scoped>

.index {
  .header {
    margin-bottom: 16px;

    .title {
      font-family: $secondary-font;
      font-weight: 500;
      font-size: 20px;
    }

    .add {
      font-size: 14px;

      .icon {
        margin-right: 4px;
      }
    }

    .count {
      font-size: 14px;
      font-weight: 400;
    }
  }

  .retry {
    margin-left: 4px;
  }

  .empty {
    background-color: $light-gray;
    border-radius: 8px;
    padding: 24px 0;

    .illustration {
      --size: 48px;
      width: var(--size);
      height: var(--size);
    }

    .empty-message {
      font-family: $secondary-font;
      font-weight: 400;
      font-size: 14px;
      margin-top: 8px;
      color: $gray-3;
    }
  }

  .attachments {
    margin: -24px 0 0 -24px;

    .attachment-card,
    .empty-card {
      margin-left: 24px;
      margin-top: 24px;
    }
  }
}

</style>


<template lang="pug">

  .index
    slot(name="header", :props="{ attachmentsCount }")
      .header
        .flex.space-between.vertical-center
          h2.title {{ $t('.title') }}

          file-selector.flex(
            v-if="editableList",
            multiple,
            :max-size="maxSize",
            @selected-files="uploadFiles"
          )
            template(#default="{ props: { selectFiles } }")
              app-button.add(
                theme="link",
                :disabled="loading",
                @click="selectFiles"
              )
                i.icon.far.fa-plus
                span {{ $t('.btn.add.label') }}

        .count(v-if="!fetching && !erred")
          span {{ $t('.attachmentsCount', { count: attachmentsCount }) }}

    template(v-if="fetching")
      loading-lines(:lines="4")

    template(v-else-if="erred")
      span {{ $t('.fetch-error.message') }}
      app-button.retry(theme="link", @click="fetch") {{ $t('.fetch-error.retry.label') }}

    template(v-else-if="empty")
      slot(name="empty")
        .empty.flex.column-direction.center.vertical-center
          img.illustration(:src="paperclipIllustration")
          app-span.empty-message(:value="$t('.empty.message')")

    .attachments.flex.wrap(v-else)
      template(v-for="attachment in _attachments")
        service-order-attachment-card(
          :attachment="attachment",
          :deletable="editableList && !submitting",
          :editable="editableAttachments && !submitting",
          :service-order-id="serviceOrderId",
          @uploaded="signedId => onAttachmentUploaded(attachment, signedId)",
          @destroy="removeAttachment(attachment)"
        )

      file-selector.empty-card(
        v-if="editableList && !loading",
        multiple,
        :max-size="maxSize",
        @selected-files="uploadFiles"
      )

</template>


<script>

// Mixins
import FetchMixin from "@/mixins/fetch-mixin"

// Assets
import paperclipIllustration from "@/assets/images/illustrations/paperclip.svg"

// Components
import ServiceOrderAttachmentCard from "./service-order-attachment-card.vue"

// Models
import models from "@/models"
const { Attachment } = models

export default {
  components: {
    ServiceOrderAttachmentCard
  },

  mixins: [FetchMixin],

  props: {
    serviceOrderId:      { type: [String, Number], default: null },
    submitting:          { type: Boolean, default: false },
    editableList:        { type: Boolean, default: false },
    editableAttachments: { type: Boolean, default: false },
    newResource:         { type: Boolean, default: false },
    attachments:         { type: Array, default: () => [] }
  },

  data() {
    return {
      i18nScope: "tickets.drafts.contents.attachments.index",

      paperclipIllustration,

      resource: [],

      creating: 0,

      autofetch: !this.newResource,

      maxSize: 20 * 1024 * 1024 // 20 Mb
    }
  },

  computed: {
    _attachments() {
      return this.newResource ? this.attachments : this.resource
    },

    empty() {
      return _.blank(this._attachments)
    },

    attachmentsCount() {
      return this._attachments.length
    },

    loading() {
      return this.submitting || this.creating !== 0
    }
  },

  methods: {
    // @override Fetchable mixin
    fetchRequest() {
      return this.$newSdk.serviceOrders.drafts.attachments.all({ serviceOrderId: this.serviceOrderId })
    },

    uploadFiles(files) {
      files.forEach(file => {
        const attachment = new Attachment({
          fileContentType: file.type,
          name:            file.name,
          fileSize:        file.size
        })
        attachment.fileToUpload = file

        this._attachments.push(attachment)
      })
    },

    onAttachmentUploaded(attachment, signedId) {
      attachment.file = signedId
      attachment.fileToUpload = null

      if (!this.newResource) this.create(attachment)
    },

    removeAttachment(attachment) {
      const index = _.findIndex(this._attachments, attachment)

      this._attachments.splice(index, 1)
    },

    async create(attachment) {
      try {
        this.creating += 1
        const { data } = await this.createRequest(attachment)

        attachment.blobId = data.blobId
        attachment.id = data.id
        attachment.fileName = data.fileName

        this.$notifications.info(this.$t(".notifications.create.success"))
      }
      catch (error) {
        this.$err.log(error)
        this.$notifications.error(this.$t(".notifications.create.error"))

        this.removeAttachment(attachment)
      }
      finally {
        this.creating -= 1
      }
    },

    async createRequest(attachment) {
      return this.$newSdk.serviceOrders.drafts.attachments.create({
        serviceOrderId: this.serviceOrderId,
        params:         _.pick(attachment, ["file"])
      })
    }
  }
}

</script>

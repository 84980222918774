import { i18n } from "utils.vue"
import VehicleModel from "./vehicle-model"

class VehicleModelHybrid extends VehicleModel {
  static get attrs() {
    return _.uniq([
      ...super.attrs,
      "fuel",
      "fuelTankCapacity",
      "motorOilCapacity",
      "electricEngineBatteryCapacity"
    ])
  }

  static get enums() {
    const enums = {
      ...super.enums,

      fuel: {
        diesel:   "diesel",
        ethanol:  "ethanol",
        flex:     "flex",
        gasoline: "gasoline"
      }
    }

    delete enums.type

    return enums
  }

  static get constraints() {
    return {
      ...super.constraints,

      fuel: {
        presence: true
      },

      fuelTankCapacity: {
        presence:     true,
        numericality: {
          greaterThan: 0
        }
      },

      motorOilCapacity: {
        presence:     true,
        numericality: {
          greaterThan: 0
        }
      },

      electricEngineBatteryCapacity: {
        presence:     true,
        numericality: {
          greaterThan: 0
        }
      }
    }
  }

  static get virtuals() {
    return ["type"]
  }

  // Virtuals
  get type() {
    return "VehicleModels::Hybrid"
  }

  // Overrides
  get mobiautoVersionId() {
    return this.$get("mobiautoVersion.id")
  }

  // Formatted Attributes
  get formattedFuelTankCapacity() {
    if (_.blank(this.fuelTankCapacity)) return null

    const count = this.fuelTankCapacity
    const capacity = i18n.l("number", this.fuelTankCapacity, { precision: 2 })

    return i18n.t("models.vehicleModelHybrid.formattedAttributes.fuelTankCapacity", { capacity, count })
  }

  get formattedMotorOilCapacity() {
    if (_.blank(this.motorOilCapacity)) return null

    const count = this.motorOilCapacity
    const capacity = i18n.l("number", this.motorOilCapacity, { precision: 2 })

    return i18n.t("models.vehicleModelHybrid.formattedAttributes.motorOilCapacity", { capacity, count })
  }

  get formattedElectricEngineBatteryCapacity() {
    if (_.blank(this.electricEngineBatteryCapacity)) return null

    const count = this.electricEngineBatteryCapacity
    const capacity = i18n.l("number", this.electricEngineBatteryCapacity, { precision: 2 })

    return i18n.t("models.vehicleModelHybrid.formattedAttributes.electricEngineBatteryCapacity", { capacity, count })
  }
}

export default VehicleModelHybrid

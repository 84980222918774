<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M21.403 6.78125L20.7026 4.60417C20.3944 3.65885 19.5259 3 18.5733 3H13.9504C12.9978 3 12.1293 3.65885 11.8211 4.60417L11.1207 6.78125C10.7245 6.90782 10.27 7.26768 10 7.58333H11.7931H20.7586C21.4871 7.58333 22.1034 8.21354 22.1034 8.95833V10.3333V11.25H15.3793L15.8276 12.1667H19.8621V12.625C19.8621 13.3984 20.4504 14 21.2069 14H21.6552C22.3836 14 23 13.3984 23 12.625V8.95833C23 7.92708 22.2996 7.06771 21.403 6.78125ZM13.9504 3.91667C13.362 3.91667 12.8577 4.31771 12.6896 4.89062L12.1013 6.66667H20.4224L19.834 4.89062C19.6659 4.31771 19.1616 3.91667 18.5733 3.91667H13.9504ZM21.6552 13.0833C21.8793 13.0833 22.1034 12.8828 22.1034 12.625V12.1667H20.7586V12.625C20.7586 12.8828 20.9547 13.0833 21.2069 13.0833H21.6552Z"
      :fill="colorHash"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M20.0582 8.27083C19.3858 8.27083 18.5172 8.95833 18.5172 9.64583C18.5172 9.98958 18.6573 10.5911 19.778 10.5911L20.0582 10.5625C20.7866 10.5625 21.319 10.1042 21.319 9.41667C21.319 8.75781 20.7866 8.27083 20.0582 8.27083ZM20.0582 9.875H19.778C19.4698 9.875 19.1896 9.875 19.1896 9.64583C19.1896 9.38802 19.694 8.95833 20.0582 8.95833C20.3944 8.95833 20.6465 9.15885 20.6465 9.41667C20.6465 9.70312 20.3944 9.875 20.0582 9.875Z"
      :fill="colorHash"
    />
    <path
      d="M14.9812 14.8125L14.0958 12.0417C13.7063 10.8385 12.6083 10 11.4042 10H5.56042C4.35625 10 3.25833 10.8385 2.86875 12.0417L1.98333 14.8125C0.85 15.1771 0 16.2708 0 17.5833V22.25C0 23.2344 0.74375 24 1.7 24H2.26667C3.1875 24 3.96667 23.2344 3.96667 22.25V21.6667H13.0333V22.25C13.0333 23.2344 13.7771 24 14.7333 24H15.3C16.2208 24 17 23.2344 17 22.25V17.5833C17 16.2708 16.1146 15.1771 14.9812 14.8125ZM3.96667 12.4062C4.17917 11.6771 4.81667 11.1667 5.56042 11.1667H11.4042C12.1479 11.1667 12.7854 11.6771 12.9979 12.4062L13.7417 14.6667H3.22292L3.96667 12.4062ZM2.83333 22.25C2.83333 22.5781 2.55 22.8333 2.26667 22.8333H1.7C1.38125 22.8333 1.13333 22.5781 1.13333 22.25V21.6667H2.83333V22.25ZM15.8667 22.25C15.8667 22.5781 15.5833 22.8333 15.3 22.8333H14.7333C14.4146 22.8333 14.1667 22.5781 14.1667 22.25V21.6667H15.8667V22.25ZM15.8667 19.3333V20.5H1.13333V17.5833C1.13333 16.6354 1.87708 15.8333 2.83333 15.8333H14.1667C15.0875 15.8333 15.8667 16.6354 15.8667 17.5833V19.3333ZM3.68333 16.7083C2.7625 16.7083 2.125 17.3281 2.125 18.1667C2.125 19.0417 2.7625 19.625 3.68333 19.625H4.0375C5.45417 19.625 5.66667 18.8958 5.66667 18.4583C5.66667 17.5833 4.53333 16.7083 3.68333 16.7083ZM4.0375 18.75C3.93125 18.75 3.78958 18.75 3.68333 18.75C3.25833 18.75 2.975 18.5312 2.975 18.1667C2.975 17.8385 3.25833 17.5833 3.68333 17.5833C4.14375 17.5833 4.81667 18.1302 4.81667 18.4583C4.81667 18.75 4.42708 18.75 4.0375 18.75ZM13.2812 16.7083C12.4312 16.7083 11.3333 17.5833 11.3333 18.4583C11.3333 18.8958 11.5104 19.6615 12.9271 19.6615L13.2812 19.625C14.2021 19.625 14.875 19.0417 14.875 18.1667C14.875 17.3281 14.2021 16.7083 13.2812 16.7083ZM13.2812 18.75C13.175 18.75 13.0333 18.75 12.9271 18.75C12.5375 18.75 12.1833 18.75 12.1833 18.4583C12.1833 18.1302 12.8208 17.5833 13.2812 17.5833C13.7063 17.5833 14.025 17.8385 14.025 18.1667C14.025 18.5312 13.7063 18.75 13.2812 18.75Z"
      :fill="colorHash"
    />
  </svg>
</template>


<script>

import SupplierIconBase from "./supplier-icon-base"

export default {
  name: "GtfIcon",

  extends: SupplierIconBase
}

</script>

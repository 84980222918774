<style lang="scss" scoped>

.attachment-card {
  .container {
    background-color: $light-gray;
    border-radius: 8px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
    width: 212px;
    height: 140px;
    overflow: hidden;

    &.uploaded {
      border-top-right-radius: 24px;

      &:hover .content {
        transform: translateY(-48px);
      }
    }

    .content {
      transition: transform .2s ease;
      background-color: $light-gray;
      padding: 8px 16px;
      font-family: $secondary-font;
      font-weight: 400;

      .name-container {
        height: 34px;
        margin-bottom: 8px;

        .name {
          font-size: 14px;
          font-weight: 400;
          color: $dark-gray;
          word-break: break-all;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
          letter-spacing: 0.3px;

          &.download:hover {
            cursor: pointer;
            color: $orange;
          }
        }
      }

      .size {
        font-size: 12px;
        color: $gray-3;
      }

      .checking {
        margin-top: 10px;
      }

      .progress-bar {
        width: 100%;
        height: 8px;
        background-color: $light-gray-4;
        border-radius: 4px;
        overflow: hidden;
        margin-top: 10px;

        .progress {
          width: var(--progress);
          height: 100%;
          background-color: $orange;
        }
      }

      .actions {
        margin-top: 8px;

        .action {
          cursor: pointer;
          --size: 32px;
          width: var(--size);
          height: var(--size);
          border-radius: var(--size);
          background-color: $white;
          box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.5);

          & + .action {
            margin-left: 8px;
          }

          &.downloading {
            cursor: progress;
            color: $gray-2;
          }
        }
      }
    }
  }

  .description {
    margin-top: 8px;
    width: 212px;
    font-size: 14px;
    font-weight: 300;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
}

</style>


<template lang="pug">

  .attachment-card
    .container(:class="{ uploaded }")
      template(v-if="uploaded")
        attachment-card-header.header(
          :attachment="attachment",
          @download="download"
        )

      .content.flex.column-direction
        .name-container
          template(v-if="canDownload")
            app-span.name.download(
              :value="attachment.name",
              @click.native="showPreviewModal = true"
            )

          template(v-else)
            app-span.name(:value="attachment.name")
        app-span.size(:value="humanReadableSize")

        template(v-if="computingChecksum")
          loading-lines.checking(:height="8", :min="100", :max="100")

        template(v-else-if="uploading")
          .progress-bar(:style="progressStyle")
            .progress

        .actions.flex
          .action.flex.center.vertical-center(
            v-if="canDownload",
            :class="{ downloading }",
            @click="download"
          )
            i.icon.fas.fa-download
          .action.flex.center.vertical-center(
            v-if="canEdit",
            @click="editPreview"
          )
            i.icon.fas.fa-pencil
          .action.flex.center.vertical-center(v-if="canDelete", @click="onDestroy")
            i.icon.fas.fa-trash-alt

    .description(v-if="description")
      app-span(:value="description")

    attachment-preview(
      v-if="showPreviewModal",
      v-bind="extraPreviewProps",
      :attachment="attachment",
      :editable="editable",
      :editing.sync="editing",
      :local-edit="localEdit",
      @close="onClosePreview",
      @download="download",
      @update:attachment="value => $emit('update:attachment', value)"
    )

</template>


<script>

// Libs
import axios from "axios"
import FileChecksum from "@/lib/checksum"

export default {
  name: "AttachmentCard",

  props: {
    attachment:         { type: Object, default: null },
    downloadable:       { type: Boolean, default: true },
    editable:           { type: Boolean, default: true },
    deletable:          { type: Boolean, default: true },
    markForDestruction: { type: Boolean, default: true },
    localEdit:          { type: Boolean, default: false }
  },

  data() {
    return {
      i18nScope: "components.attachments.attachment-card",

      uploading:   false,
      downloading: false,

      progress: null,

      computingChecksum: false,

      showPreviewModal: false,
      editing:          false
    }
  },

  computed: {
    extraPreviewProps() {
      return {}
    },

    fileToUpload() {
      return _.get(this.attachment, "fileToUpload")
    },

    humanReadableSize() {
      return this.$asHumanSize(this.attachment.fileSize)
    },

    description() {
      return this.attachment.description
    },

    uploaded() {
      return _.present(this.attachment.blobId) || _.present(this.attachment.file)
    },

    canDownload() {
      return _.present(this.attachment.blobId) && this.downloadable
    },

    canEdit() {
      return _.present(this.attachment.id) && this.editable
    },

    canDelete() {
      return this.uploaded && this.deletable
    },

    percentage() {
      if (_.blank(this.progress)) return 0

      const { total, loaded } = this.progress

      // Evita divisão por 0
      if (!total) return 0

      let value = (loaded / total) * 100
      return parseInt(value, 10)
    },

    progressStyle() {
      return {
        "--progress": `${this.percentage}%`
      }
    }
  },

  created() {
    if (!this.fileToUpload) return

    this.progress = {
      loaded: 0,
      total:  this.attachment.fileSize
    }

    this.upload()
  },

  methods: {
    async onDestroy() {
      if (!this.canDelete) return

      if (this.attachment.$newRecord) return this.$emit("destroy")

      if (this.markForDestruction) {
        this.attachment.$markForDestruction()
        return this.$emit("softDestroy")
      }

      try {
        this.destroying = true

        await this.destroyRequest()

        this.$notifications.info(this.$t(".notifications.destroy.success"))

        this.$emit("destroy")
      }
      catch (error) {
        this.$err.log(error)
        this.$notifications.error(this.$t(".notifications.destroy.error"))
      }
      finally {
        this.destroying = true
      }
    },

    async destroyRequest() {
      throw new Error("AttachmentCard - #destroyRequest() - Not implemented yet")
    },

    onClosePreview() {
      this.showPreviewModal = false
      this.editing = false
    },

    editPreview() {
      this.editing = true
      this.showPreviewModal = true
    },

    async createBlob() {
      this.computingChecksum = true
      const checksum = await FileChecksum.create(this.fileToUpload)
      this.computingChecksum = false

      const response = await this.$newSdk.blobs.create({
        params: {
          blob: {
            filename:     this.fileToUpload.name,
            content_type: this.fileToUpload.type,
            byte_size:    this.fileToUpload.size,
            checksum
          }
        }
      })

      return response.data
    },

    readFile() {
      return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.addEventListener("load", () => resolve(reader.result))
        // eslint-disable-next-line prefer-promise-reject-errors
        reader.addEventListener("error", () => reject(`Error reading ${this.fileToUpload.name}`))

        reader.readAsArrayBuffer(this.fileToUpload)
      })
    },

    async upload() {
      this.progress = {
        loaded: 0,
        total:  this.attachment.fileSize
      }

      this.uploading = true

      try {
        const blob = await this.createBlob()
        const data = await this.readFile()

        await axios.put(blob.direct_upload.url, data, {
          // XXX: Removendo header "Content-Disposition" para permitir controlar na hora do download, dado
          // que um valor enviado aqui não pode ser sobrescrito depois.
          headers: _.omit(blob.direct_upload.headers, "Content-Disposition"),

          transformRequest: [(requestData, requestHeaders) => {
            delete requestHeaders.put["Content-Type"]
            return requestData
          }],

          onUploadProgress: ({ loaded, total }) => {
            this.progress = { loaded, total }
          }
        })

        this.$emit("uploaded", blob.signed_id)
      }
      catch (error) {
        this.$err.log(error)
        this.$notifications.error(this.$t(".notifications.upload.error", { name: this.fileToUpload.name }))
        this.$emit("destroy")
      }
      finally {
        this.uploading = false
      }
    },

    downloadRequest() {
      return this.$newSdk.blobs.getDownloadURL({ blobId: this.attachment.blobId, params: { disposition: "attachment" } })
    },

    async download() {
      if (this.downloading) return

      this.downloading = true

      try {
        const { data: { url } } = await this.downloadRequest()

        const link = document.createElement("a")
        link.href = url
        link.setAttribute("download", this.attachment.fileName)

        document.body.appendChild(link)
        link.click()
        link.parentNode.removeChild(link)
      }
      catch (error) {
        this.$err.log(error)
        this.$notifications.error(this.$t(".notifications.download.error", { name: this.attachment.name }))
      }
      finally {
        this.downloading = false
      }
    }
  }
}

</script>

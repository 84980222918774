
<!--
<doc lang="markdown">

_Props_:
options -> No formato [{ label: 'Text', value: 1 }]
value -> Se optionAsValue for ativo precisa voltar { label: 'Text', value: 1 }. Caso contrário apenas `value` 1
optionAsValue -> Define se `v-model` é a _option_ inteira, ou apenas o `value`
defaultFilter -> filtro padrão por label
searchable -> `input` para filtrar

Como usar:
dado:
```javascript
data() {
  return {
    value: 2
    options: [
      { label: 'Value 1', value: 1 },
      { label: 'Value 2', value: 2 },
    ]

  }
}
```

```pug
  select-field(
    name="model[attr]",
    :loading="submitting",
    :disabled="fetching",
    placeholder="Selecione uma opção",
    :errors="['não pode ficar em branco']",
    v-model="value",
    :options="options",
    searchable
  )
```

USANDO `optionAsValue` Caso queira toda a option
```javascript
data() {
  return {
    value: { label: 'Value 1', value: 1 }
    options: [
      { label: 'Value 1', value: 1 },
      { label: 'Value 2', value: 2 },
    ]

  }
}
```

```pug
  select-field(
    value-as-option,
    name="model[attr]",
    :loading="submitting",
    :disabled="fetching",
    placeholder="Selecione uma opção",
    :errors="['não pode ficar em branco']",
    v-model="value",
    :options="options",
    searchable
  )
```
</doc>
-->


<style lang="scss" scoped>

.select-search-content {
  display: flex;
  flex-direction: column;

  &.left {
    align-items: flex-end;
  }

  &.right {
    align-items: flex-start;
  }

  .select-field-input {
    &:not(.open) {
      width: 100%;
    }

    &.open {
      min-width: 100%;
    }
  }
}

.tooltip-icon {
  margin-left: 8px;
}

.menu-wrapper {
  position: relative;
}

.menu-wrapper {
  width: var(--select-field-width);
  min-width: 100%;
}

.menu-content {
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  background: white;
  border-radius: 5px;
  border: 1px solid $gray;
  z-index: 1000;
  padding: 0;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.input-wrapper {
  padding: 8px;
  border-bottom: 1px solid $gray;
  background: white;
  top: 0;
  position: relative;

  input {
    font-size: 16px;
    height: 40px;
    width: 100%;
    border: 1px solid $gray;
    border-radius: 4px;
    padding-left: 8px;
    padding-right: 35px;
  }

  .search-icon {
    position: absolute;
    top: 21px;
    right: 20px;
    color: $gray-2;
  }
}

.options-wrapper {
  max-height: 200px;
  overflow-y: auto;
}

.options-content {
  padding: 0;
  margin: 0;
}

li {
  list-style: none;

  &:last-child {
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
  }

  &.option {
    height: 40px;
    padding: 0 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid $light-gray-3;
    cursor: pointer;

    .remove-icon-content {
      width: 24px;
      height: 24px;
      line-height: 24px;
      text-align: center;
      border-radius: 50px;
      color: $gray-2;
      transition: all 100ms linear;
    }

    &.active {
      color: $primary;
      background: $light-gray-2;
    }

    &.focus {
      background: $light-gray-2;
      color: $dark-primary-2;

      .remove-icon-content {
        background: $light-gray-3;
        color: $primary;
      }
    }
  }
}

.hidden {
  opacity: 0;

  &, & > * {
    height: 0;
    border: 0;
    padding: 0;
    z-index: -1;
  }
}

.input {
  font-size: 0px;
}

.input-button {
  cursor: pointer;
  position: relative;
  background-color: $white;
  background-image: none;
  border-radius: 4px;
  border: 1px solid $gray;
  border-style:solid;
  box-sizing: border-box;
  color: $gray-3;
  display: inline-block;
  font-size: 16px;
  min-height: 40px;
  line-height: 36px;
  outline: none;
  padding: 1px 13px;
  padding-right: 68px;
  width: 100%;
  text-align: left;
  font-weight: 400;

  &:hover {
    border-color: $orange;

    .arrow-icon {
      color: $primary;
    }
  }

  &.open {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    border-width: 2px;
    padding: 0 12px;
    padding-right: 67px;

    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);
    z-index: 1001;
  }

  &:focus,
  &.focus {
    border-style:solid;
    outline: none;
    border-color: $orange;
  }

  &.disabled {
    background-color: $light-gray-3;
    border-color: $light-gray-4;
    color: $gray-3;
    cursor: not-allowed;

    .value {
      color: $gray-2;
    }

    .arrow-icon {
      color: $light-gray-4;
    }

    &:hover {
      .arrow-icon {
        color: $light-gray-4;
      }
    }
  }
}

.placeholder {
  font-weight: 300;
  color: $gray;
}

.arrow-icon {
  position: absolute;
  width: 24px;
  height: 38px;
  right: 6px;
  top: calc(50% - 19px);
  font-size: 12px;
  text-align: center;
  transition: transform .2s ease;
  color: $dark-gray;

  &.open {
    height: 34px;
    color: $primary;
    top: calc(50% - 17px);
    transform: rotate(180deg);
  }
}

.clear-button {
  position: absolute;
  appearance: none;
  border: none;
  background: transparent;
  cursor: pointer;
  width: 24px;
  height: 38px;
  right: 36px;
  top: calc(50% - 19px);
  font-size: 16px;
  padding: 0;
  text-align: center;
  transition: transform .2s ease;
  color: $dark-gray;


  .remove-icon-content {
    width: 24px;
    height: 24px;
    line-height: 26px;
    border-radius: 50px;
    transition: all 100ms linear;
  }

  &.open {
    right: 35px;
    height: 36px;
    top: calc(50% - 18px);
  }

  &:hover {
    color: $primary;

    .remove-icon-content {
      background: $light-gray-3;
    }
  }
}

.input-button.error {
  border-color: $error;
}

.input-button.loading {
  background-color: $light-gray-3;
  border-color: $light-gray-4;
  color: $gray-3;
  cursor: progress;

  input {
    cursor: progress;
  }

  .arrow-icon {
    color: $light-gray-4;
  }

  &:hover {
    .arrow-icon {
      color: $light-gray-4;
    }
  }
}

.error-message {
  display: block;
  color: $error;
  font-size: 12px;
  line-height: 1;
  padding-top: 4px;
}

.label-content {
  margin-bottom: 8px;
  font-size: 14px;
  line-height: 17px;
  font-weight: 500;
  font-family: $secondary-font;
  color: $dark-gray;
  transition: color 0.1s cubic-bezier(.645,.045,.355,1);

  > * + * {
    padding-left: 4px;
  }

  &.focus {
    color: $orange;
  }

  &.error {
    color: $error;
  }

  .mandatory-icon {
    font-size: 4px;
    color: $orange;
  }
}

.info-icon {
  font-size: 14px;
  cursor: pointer;
}

.value, .placeholder {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
}

</style>


<template lang="pug">

  .select-field(
    :style="menuStyle",
    :data-testid="name"
  )
    .flex.vertical-baseline
      label.label-content.flex.vertical-center(
        v-if="!hideLabel",
        :class="{ error: hasErrors, focus: isOpen }",
        :for="inputId"
      )
        span {{ labelText }}
        template(v-if="optional")
          span {{ $t('form.optional') }}

        template(v-else-if="mandatory")
          i.mandatory-icon.fas.fa-circle(v-tooltip.top="$t('form.mandatory.tooltip')")

        //- XXX: aqui habilita pela prop :info (use eventos @info-mouse-* para conteúdo)
        i.info-icon.far.fa-info-circle(
          v-if="info",
          @click="$emit('info-click')",
          @mouseenter="$emit('info-mouse-enter')"
          @mouseleave="$emit('info-mouse-leave')"
        )

        //- XXX: aqui habilita pela prop :tooltip (com texto direto!)
        i.info-icon.far.fa-info-circle(v-if="hasTooltip", v-tooltip="tooltip")


    .select-search-content(
      v-click-outside="onClickOutside",
      :class="{ [growthSideClass]: true }"
    )
      .select-field-input(:class="{ open: isOpen }")
        .input
          .input-button(
            :class="{ disabled, focus: isOpen, open: isOpen, error: hasErrors, loading }",
            ref="button",
            :tabindex="disabled ? -1 : 0",
            @click="toggleMenu",

            @keyup.enter="toggleMenu",

            @keyup.down="openMenu",
            @keydown.down.prevent="",
            @keydown.enter.prevent="",
            @keydown.space.prevent="toggleMenu",
          )
            template(v-if="hasValue")
              slot(name="selected", :props="{ selected: selectedOption }")
                app-span.value(:value="valueLabel", crop)

            template(v-else)
              slot(name="placeholder")
                template(v-if="placeholder")
                  span.placeholder {{ placeholder }}

            template(v-if="showClear && !_disabled")
              button.clear-button.flex.center.vertical-center(
                :class="{ open: isOpen }",
                @click.stop="clear",
                tabindex="-1",
                type="button"
              )
                .remove-icon-content
                  i.far.fa-times

            .arrow-icon.flex.center.vertical-center(v-if="!disabled", :class="{ open: isOpen }")
              i.fas.fa-chevron-down

      .menu-wrapper
        .menu-content(v-if="isOpen", data-testid="menuContent")
          .input-wrapper(
            :class="{ disabled, focus: isOpen, error: hasErrors, loading, hidden: !searchable }",
          )
            input(
              type="text",
              ref="input",
              autocomplete="disabled",

              :disabled="_disabled",

              @input="onSearch",
              :value="search",

              v-mask="mask",
              :maxlength="maxlength",

              @focus="focus = true",

              @keyup.enter="onSelectByEnter",
              @keydown.enter.prevent="",

              @keyup.esc="onKeyEsc",
              @keyup.up="onKeyUp",
              @keyup.down="onKeyDown",

              @keydown.up="e => e.preventDefault()"
              @keydown.down="e => e.preventDefault()"

              @keydown.tab="closeMenu",
            )
            i.search-icon.far.fa-search

          .options-wrapper
            slot(name="menuContentHeader")

            slot(v-if="fetching", name="fetching")
              li.option {{ $t('.loading') }}

            ul.options-content(
              v-else,
              ref="optionsContent",
              data-testid="optionsContent",
              @mouseleave="focusedOption = null"
            )
              li.listed-option(
                :class="defaultOptionClass(option, index)",
                v-for="(option, index) in options$", :key="option.value",
                @mousemove="focusedOption = index",
                :aria-select-option="`option-${index}`",
                @click="onSelectByClick(option)",
              )
                slot(name="option", :props="optionProps(option, index)")
                  span {{ option.label }}
                  .remove-icon-content(v-if="isActiveOption(option)")
                    i.far.fa-times

            slot(name="menuContentFooter")

      span.error-message(v-for="error in errors", :key="error") {{ error }}

</template>


<script>

import { i18n } from "utils.vue"

import FieldMixin from "@/mixins/field"
import AppSelect from "./base/app-select"

export default {
  name: "SelectField",

  extends: AppSelect,

  mixins: [FieldMixin],

  props: {
    value:          { type: [String, Number, Boolean, Object, Array], default: null },  // XXX: Array para seleção múltipla!
    mask:           { type: [String, Object], default: null },
    tooltip:        { type: [String, Object], default: null },  // String ou { content:, offset:, placement: }
    menuWidth:      { type: Number, default: null },
    menuGrowthSide: { type: String, default: "right", validator: side => ["left", "right"].includes(side) },

    // @override Field Mixin
    placeholder: { type: String, default: i18n.t("form.select.placeholder") }
  },

  data() {
    return {
      i18nScope: "components.core.select.select-field",

      isCropped: false
    }
  },

  computed: {
    _disabled() {
      return this.disabled || this.loading
    },

    hasValue() {
      return _.present(this.value)
    },

    hasTooltip() {
      return _.present(this.tooltip)
    },

    hasWidth() {
      return _.present(this.menuWidth)
    },

    growthSideClass() {
      return this.hasWidth ? this.menuGrowthSide : "none"
    },

    menuStyle() {
      return {
        "--select-field-width": this.hasWidth ? `${this.menuWidth}px` : "100%"
      }
    }
  }
}

</script>

<style lang="scss" scoped>

.popover-menu-button {
  ::v-deep .button-menu {
    height: 30px;
  }
}

</style>


<template lang="pug">

  .service-order-actions
    popover-menu(
      v-if="popoverMenuAuthorization",
      :show.sync="showPopover",
      data-testid="popoverMenu"
    )
      popover-menu-button(
        v-if="canShow",
        icon="fal fa-eye",
        :text="$t('.actions.show.label')",
        @click="$emit('show')",
        @close-menu="showPopover = false"
      )

      popover-menu-button(
        :disabled="!canReadMessages",
        icon="fal fa-message-pen",
        :text="$t('.actions.messages.label')",
        @click="$emit('messages')",
        @close-menu="showPopover = false"
      )

      popover-menu-button(
        :disabled="!canUpdateVehicleReceipt",
        icon="fal fa-car-garage",
        :text="$t('.actions.vehicle-receipt.label')",
        @click="$emit('vehicle-receipt')",
        @close-menu="showPopover = false"
      )

      template(v-if="!hasQuote")
        new-quote.popover-menu-button(:service-order-id="serviceOrder.$id")
          template(#default="{ props: { submit: createQuote, submitting: creating } }")
            popover-menu-button(
              :disabled="!canCreateQuote",
              icon="fal fa-memo",
              :loading="creating",
              :text="$t('.actions.edit-quote.label')",
              @click="createQuote",
              @close-menu="showPopover = false"
            )

      template(v-else)
        popover-menu-button(
          :disabled="!canEditQuote",
          icon="fal fa-memo",
          :text="$t('.actions.edit-quote.label')",
          @click="$emit('edit-quote')",
          @close-menu="showPopover = false"
        )

      popover-menu-button(
        :disabled="!canResendApprovalNotification",
        icon="fal fa-envelope",
        :text="$t('.actions.resend-approval-notification.label')",
        @click="$emit('resend-approval-notification')",
        @close-menu="showPopover = false"
      )

      popover-menu-button(
        :disabled="!canExecute",
        icon="fal fa-check-square",
        :text="$t('.actions.service-execution.label')",
        @click="$emit('service-execution')",
        @close-menu="showPopover = false"
      )

      popover-menu-button(
        :disabled="!canWithdrawVehicle",
        icon="fal fa-car-side",
        :text="$t('.actions.withdraw-vehicle.label')",
        @click="$emit('withdraw-vehicle')",
        @close-menu="showPopover = false"
      )

      popover-menu-button(
        :disabled="!canPostpone",
        icon="fal fa-clock",
        :text="$t('.actions.postpone.label')",
        @click="$emit('postpone')",
        @close-menu="showPopover = false"
      )

      popover-menu-button(
        :disabled="!canCancel",
        icon="fal fa-file-xmark",
        :text="$t('.actions.cancel.label')",
        @click="$emit('cancel')",
        @close-menu="showPopover = false"
      )

      popover-menu-button(
        :disabled="!canReopen",
        icon="fal fa-file-plus",
        :text="$t('.actions.reopen.label')",
        @click="$emit('reopen')",
        @close-menu="showPopover = false"
      )

</template>


<script>

// Components
import NewQuote from "@/views/monitoring/quotes/new.vue"

// Models
import models from "@/models"
const { MovidaServiceOrder } = models


export default {
  components: {
    NewQuote
  },

  props: {
    serviceOrder: { type: MovidaServiceOrder, required: true }
  },

  emits: [
    "cancel",             // quando botão/action :cancel é acionado
    "edit-quote",         // quando botão/action :edit-quote é acionado
    "messages",           // quando botão/action :messages é acionado
    "postpone",           // quando botão/action :postpone é acionado
    "reopen",
    "resend-approval-notification",
    "review-quote",       // quando botão/action :review-quote é acionado
    "service-execution",  // quando botão/action :service-execution é acionado
    "show",               // quando botão/action :show é acionada.
    "vehicle-receipt",    // quando botão/action :vehicle-receipt é acionado
    "withdraw-vehicle"    // quando botão/action :withdraw-vehicle é acionado
  ],

  data() {
    return {
      i18nScope: "monitoring.service-order-actions",

      showPopover: false
    }
  },

  computed: {
    popoverMenuAuthorization() {
      return ["read", "update", "destroy"].some(action => this.$can(action, this.serviceOrder))
    },

    isDomainRAC() {
      return this.serviceOrder?.domain == "rac"
    },

    isDomainGTF() {
      return this.serviceOrder?.domain == "gtf"
    },

    hasQuote() {
      return this.serviceOrder.$present("quoteState")
    },

    quoteState() {
      return this.serviceOrder?.quoteState || null
    },

    showActionEnabled() {
      return true
    },

    messagesActionEnabled() {
      return true
    },

    vehicleReceiptActionEnabled() {
      return ["open", "ongoing"].includes(this.serviceOrder.state) && !this.serviceOrder.vehicleStopped
    },

    createQuoteActionEnabled() {
      return _.blank(this.quoteState) && this.serviceOrder.state == "open" && !this.serviceOrder.quoteIssued
    },

    editQuoteActionEnabled() {
      return this.quoteState != "reviewed" && this.serviceOrder.state == "open"
    },

    executeActionEnabled() {
      return this.serviceOrder.state == "ongoing"
    },

    withdrawVehicleActionEnabled() {
      return this.serviceOrder.state == "executed"
    },

    postponeActionEnabled() {
      return this.serviceOrder.state == "ongoing"
    },

    cancelActionEnabled() {
      return !["executed", "canceled"].includes(this.serviceOrder.state)
    },

    reopenActionEnabled() {
      return this.serviceOrder.state == "canceled"
        && ["gtf", "zkm"].includes(this.serviceOrder.domain)
    },

    resendApprovalNotificationEnabled() {
      return this.serviceOrder.state == "ongoing"
    },

    canShow() {
      return this.$can("read", this.serviceOrder) && this.showActionEnabled
    },

    canReadMessages() {
      return this.$can("update", this.serviceOrder) && this.messagesActionEnabled
    },

    canUpdateVehicleReceipt() {
      return this.$can("update", this.serviceOrder) && this.vehicleReceiptActionEnabled
    },

    canCreateQuote() {
      return this.$can("update", this.serviceOrder) && this.createQuoteActionEnabled
    },

    canEditQuote() {
      return this.$can("update", this.serviceOrder) && this.editQuoteActionEnabled
    },

    canExecute() {
      return this.$can("update", this.serviceOrder) && this.executeActionEnabled
    },

    canWithdrawVehicle() {
      return this.$can("update", this.serviceOrder) && this.withdrawVehicleActionEnabled
    },

    canPostpone() {
      return this.$can("update", this.serviceOrder) && this.postponeActionEnabled
    },

    canCancel() {
      return this.$can("update", this.serviceOrder) && this.cancelActionEnabled
    },

    canReopen() {
      return this.$can("update", this.serviceOrder) && this.reopenActionEnabled
    },

    canResendApprovalNotification() {
      return this.$can("update", this.serviceOrder) && this.resendApprovalNotificationEnabled
    }
  }
}

</script>

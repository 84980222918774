<style lang="scss" scoped>

.product-part-icon {
  --color: #{$gray-3};

  .icon {
    height: 100%;
    height: var(--size);
    width: var(--size);
    mask-image: var(--svg-icon);
    background-color: var(--color);
    mask-size: cover;
  }
}

</style>


<template lang="pug">

  .product-part-icon(:style="cssVars")
    .icon

</template>


<script>

import light from "@/assets/images/icons/product-part/light.svg"
import solid from "@/assets/images/icons/product-part/solid.svg"

export default {
  name: "ProductPartIcon",

  props: {
    size:   { type: Number, default: 24 },
    weight: { type: String, default: "light", validator: weight => ["light", "solid"].includes(weight) }
  },

  computed: {
    cssVars() {
      return {
        "--size":     `${this.size}px`,
        "--svg-icon": `url(${this.icon})`
      }
    },

    icon() {
      switch (this.weight) {
        case "light":
        default:
          return light
        case "solid":
          return solid
      }
    }
  }
}

</script>

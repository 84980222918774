<style scoped lang="scss">

.service-type-label {
  .label {
    display: block;
    margin-bottom: 8px;
    font-size: 14px;
    line-height: 17px;
    font-weight: 500;
    font-family: $secondary-font;
  }

  .service-icon {
    margin-right: 8px;
  }
}

</style>


<template lang="pug">

  .service-type-label
    label.label {{ $t('.label') }}

    template(v-if="loading")
      loading-lines.loading

    template(v-else)
      .flex.vertical-center
        service-type-icon.service-icon(:type="value", hide-tooltip)
        label {{ typeText }}

</template>


<script>

// Models
import models from "@/models"
const { Service } = models

export default {
  props: {
    value:   { type: String, default: null },
    loading: { type: Boolean, default: false }
  },

  data() {
    return {
      i18nScope: "services.components.service-type-label"
    }
  },

  computed: {
    typeText() {
      if (_.blank(this.value)) return

      return Service.$tEnum("type", this.value)
    }
  }
}

</script>

<template lang="pug">

 .new
    slot(:props="{ submit, submitting }")

</template>


<script>

// Mixins
import FormMixin from "@/mixins/form-mixin"


export default {
  mixins: [FormMixin],

  props: {
    // XXX: id or rid
    serviceOrderId: { type: [String, Number], required: true }
  },

  data() {
    return {
      i18nScope: "monitoring.quotes.new"
    }
  },

  methods: {
    // @override Form mixin
    submitRequest() {
      return this.$newSdk.monitoring.serviceOrders.quotes
        .createDraft({ serviceOrderId: this.serviceOrderId })
    },

    // @override Form mixin
    onSubmitSuccess({ data }) {
      this.$router.push({ name: "editQuote", params: { id: data.id } })
    }
  }
}

</script>

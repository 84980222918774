<!--
<doc lang="markdown">
Base para componentes que representam uma uma página com URL própria (_views_).

Define comportamentos básicos ligados à rota e navegação.

Como usar:
```pug
  // View
  import BaseView from '@/views/base-view'

  ...

  extends: BaseView
```
</doc>
-->

<script>

import viewStore from "@/store/view"

export default {
  data() {
    return {
      // Comunica um erro intratável ao aplicativo para mostrar uma tela de erro
      appError:  null,
      // O nome da rota, como definido no arquivo de rotas
      routeName: null,
      // A rota que a view representa
      path:      null
    }
  },

  computed: {
    // A rota padrão para a ação de voltar, usada quando o histórico não está disponível ou seu uso é inviável
    backRoute() {
      return { name: "root" }
    }
  },

  watch: {
    appError() {
      viewStore.appError = this.appError
    }
  },

  created() {
    if (_.blank(this.routeName)) throw new Error('Base View - #created() - "routeName" is required')

    this.computePath()
  },

  methods: {
    // Não pode ser um _computed_ porque o valor não é recomputado quando a rota muda
    computePath() {
      const { route } = this.$router.resolve({ name: this.routeName })
      // XXX: $router.resolve retorna a rota após redirect, mantendo a rota original em redirectedFrom
      this.path = route.redirectedFrom || route.path
    },

    goBack() {
      this.$goBack({ fallback: this.backRoute })
    },

    hasViewError(err) {
      // HttpError pode ser local ou de `movida-common.vue`, então a referência não é garantida
      return _.get(err, "constructor.name") === "HttpError"
    }
  }
}

</script>

<style lang="scss" scoped>
.label-wrapper {
  align-items: center;
  display: flex;
  margin-bottom: 8px;

  > *:not(:first-child) {
    margin-left: 4px;
  }
}

.label {
  color: $dark-gray;
  font-family: $secondary-font;
  font-size: 14px;
  font-weight: 500;
}

.mandatory-indicator {
  background: $primary;
  border-radius: 2px;
  margin: 4px;
  height: 4px;
  width: 4px;
}

.info-icon {
  cursor: help;
  font-size: 14px;
}

.input-wrapper {
  overflow: hidden;
  position: relative;

}

.icon-wrapper {
  color: $gray-4;
  font-size: 16px;
  line-height: 38px;
  position: absolute;
}

.icon-left {
  left: 12px;
}

.icon-right {
  right: 12px;
}

.clear-button {
  background: transparent;
  border: none;
  border-radius: 24px;
  color: $dark-gray;
  font-size: 16px;
  height: 24px;
  line-height: 24px;
  position: absolute;
  right: 12px;
  top: 8px;
  width: 24px;

  .icon-right ~ & {
    right: 36px;
  }

  &:hover,
  &:active {
    background: $light-gray-3;
  }
  &:hover {
    color: $primary;
  }
  &:active {
    color: $dark-primary-2;
  }
}

.input {
  border: 1px solid $gray;
  border-radius: 4px;
  color: $gray-3;
  font-family: $primary-font;
  font-size: 16px;
  font-weight: 400;
  height: 40px;
  line-height: 40px;
  padding: 1px 13px;
  width: 100%;

  .error & {
    border-color: $error;
  }

  .icon-left ~ & {
    padding-left: 40px;
  }

  .icon-right ~ & {
    padding-right: 40px;
  }

  .clear-button ~ & {
    padding-right: 48px;
  }

  .icon-right ~ .clear-button ~ & {
    padding-right: 68px;
  }

  &::placeholder {
    color: $gray;
    font-weight: 300;
  }

  &:hover,
  &:focus,
  &:active {
    border-color: $primary;
  }
  &:focus {
    outline: 2px solid $primary;
    outline-offset: -2px;
  }

  &:disabled {
    background-color: $light-gray-3;
    border-color: $light-gray-4;
    color: $gray-2;
    cursor: not-allowed;
  }
}

.error-message {
  color: $error;
  font-size: 12px;
  line-height: 1;
  margin-top: 4px;
}
</style>

<template lang="pug">
  .clearable-input-field(:class="{ error: hasErrors }")
    .label-wrapper
      label.label(:for="fieldId") {{ label }}
      span.mandatory-indicator(v-if="mandatory", v-tooltip="$t('form.mandatory.tooltip')")
      i.info-icon.far.fa-info-circle(v-if="hasTooltip", v-tooltip="tooltip")
    .input-wrapper
      .icon-wrapper.icon-left(v-if="'iconLeft' in $slots")
        slot(name="iconLeft")
      .icon-wrapper.icon-right(v-if="'iconRight' in $slots")
        slot(name="iconRight")
      button.clear-button(
        v-if="isFilled"
        tabindex="-1"
        type="button"
        @click="onClearClick"
      )
        i.far.fa-times
      input.input(
        v-bind="$attrs"
        :id="fieldId"
        :value="value"
        @input="onInput"
      )
    .error-message(v-for="error in errors" :key="error") {{ error }}
</template>

<script>
export default {
  inheritAttrs: false,

  props: {
    errors:    { type: Array, default: null },
    label:     { type: String, default: "" },
    mandatory: { type: Boolean, default: false },
    tooltip:   { type: [String, Object], default: null },
    value:     { type: String, default: "" }
  },

  data() {
    return {
      fieldId: _.uniqueId("clearable-input-field-")
    }
  },

  computed: {
    isFilled() {
      return _.present(this.value)
    },

    hasErrors() {
      return _.present(this.errors)
    },

    hasTooltip() {
      return _.present(this.tooltip)
    }
  },

  methods: {
    onClearClick() {
      this.$emit("input", "")
    },
    onInput(event) {
      this.$emit("input", event.target.value)
    }
  }
}
</script>

// Base
import BaseRequests from "../Base"

class SupplierTicketsRequests extends BaseRequests {
  static get selector() {
    return "suppliers.tickets"
  }

  all({ supplierId, params = {}, options = {} }) {
    if (_.blank(supplierId)) {
      throw new Error('SDK suppliers.tickets - #all() - "supplierId" is a required prop')
    }

    return this.action({
      method:  "get",
      url:     `/suppliers/${supplierId}/tickets`,
      params,
      options: {
        model: null,

        ...options
      }
    })
  }
}

export default SupplierTicketsRequests

<style lang="scss" scoped>

.attachment-file-type {
    font-size: 12px;
    font-family: $secondary-font;
    font-weight: 400;

    .icon {
      font-size: 16px;
      margin-right: 8px;
      color: var(--color);
    }
}

</style>


<template lang="pug">

  .attachment-file-type.flex.vertical-center(:style="styles")
    i.icon.fas(:class="{ [attachment.icon]: true }")
    app-span.type(:value="Attachment.$tEnum('fileType', attachment.fileType)")

</template>


<script>

// Models
import models from "@/models"
const { Attachment } = models

export default {
  name: "AttachmentFileType",

  props: {
    attachment: { type: Object, required: true }
  },

  data() {
    return {
      i18nScope: "components.attachments.attachment-file-type",

      Attachment
    }
  },

  computed: {
    styles() {
      return {
        "--color": this.attachment.iconColor
      }
    }
  }
}

</script>

<style scoped lang="scss">

.maintenance-table-kind-tag {
  font-family: $secondary-font;
  font-size: 12px;
  font-weight: 500;

  &.multiservice {
    color: $dark-info;
  }

  &.dealership {
    color: $purple;
  }

  &.muted {
    color: $gray-2;
  }

  .icon {
    margin-right: 4px;
    font-size: 12px;
  }
}

</style>


<template lang="pug">

  .maintenance-table-kind-tag.flex.vertical-center(:class="{ [kind]: true, muted }")
    i.icon.fas(:class="iconClass")
    app-span(:value="VehicleModelGroupMaintenanceTable.$tEnum('kind', kind)")

</template>


<script>

// Models
import models from "@/models"
const { VehicleModelGroupMaintenanceTable } = models

export default {
  name: "MaintenanceTableKindTag",

  props: {
    kind:  { type: String, required: true },
    muted: { type: Boolean, default: false }
  },

  data() {
    return {
      i18nScope: "components.vehicle-model-group-maintenance-table.maintenance-table-kind-tag",

      VehicleModelGroupMaintenanceTable
    }
  },

  computed: {
    iconClass() {
      switch (this.kind) {
        case "multiservice": return { "fa-car-garage": true }
        case "dealership": return { "fa-copyright": true }
        default: return {}
      }
    }
  }
}

</script>

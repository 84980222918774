<style scoped lang="scss">

.block-supplier .block-button,
.unblock-supplier .unblock-button {
  border-top: 1px solid $light-gray-3;
}

</style>


<template lang="pug">

  base-supplier-row(
    :supplier="supplier",
    :hide-hierarchy="hideHierarchy",
    @modified="() => $emit('modified')"
  )
    .actions(slot="actions")
      popover-menu(:show.sync="showPopover")
        popover-menu-button(
          :to="{ name: 'supplier', params: { id: supplier.id }}",
          icon="fal fa-eye",
          :text="$t('.actions.btn.show')",
          @close-menu="showPopover = false"
        )

        popover-menu-button(
          :to="{ name: 'editSupplier', params: { id: supplier.id }}",
          icon="fal fa-pencil",
          :text="$t('.actions.btn.edit')",
          @close-menu="showPopover = false"
        )

        unblock-supplier(
          v-if="supplier.blocked",
          :id="supplier.id",
          :name="supplier.name"
          @unblocked="$emit('modified')"
        )
          template(#link="{ props: { openUnblockModal } }")
            app-button.popover-menu-button.unblock-button(
              theme="link",
              border="square",
              @click="[showPopover = false, openUnblockModal()]"
            )
              .button-menu.flex.vertical-center.grow
                .icon-content
                  i.icon.fal.fa-check-circle
                span {{ $t('.actions.btn.unblock') }}

        block-supplier(
          v-else,
          :supplier="supplier",
          @blocked="$emit('modified')"
        )
          template(#link="{ props: { openBlockForm } }")
            app-button.popover-menu-button.block-button(
              theme="link",
              border="square",
              @click="[showPopover = false, openBlockForm()]"
            )
              .button-menu.flex.vertical-center.grow
                .icon-content
                  i.icon.fal.fa-times-circle
                span {{ $t('.actions.btn.block') }}
        //- destroy-action.destroy-button.popover-menu-button(
        //-   :id="supplier.id",
        //-   :name="supplier.name",
        //-   @click.native="showPopover = false"
        //-   @destroy="$emit('destroy')",
        //-   data-test="destroyAction"
        //- )
        //-   .icon-content
        //-     i.icon.fal.fa-trash-alt
        //-   span {{ $t('.actions.btn.destroy') }}

</template>


<script>

// import DestroyAction from '../show/destroy-action'
import BlockSupplier from "./block-supplier"
import UnblockSupplier from "./unblock-supplier"

export default {
  components: {
    BlockSupplier,
    UnblockSupplier
    // DestroyAction
  },

  props: {
    supplier:      { type: Object, default: () => ({}) },
    hideHierarchy: { type: Boolean, default: false }
  },

  data() {
    return {
      i18nScope: "suppliers.components.supplier-row",

      showPopover: false
    }
  }
}

</script>

<style scoped lang="scss">

.store-info-card {
  border: 1px solid $light-gray-3;
  border-radius: 4px;
  padding: 16px;
  margin-bottom: 16px;
  height: 115px;

  .initials-content {
    margin-right: 20px;
    margin-left: 4px;
    text-align: center;
  }

  .loading-lines + .loading-lines {
    margin-top: 4px;
  }

  .address {
    margin-right: 8px;
  }

  .name {
    font-family: $secondary-font;
    font-size: 30px;
    font-weight: normal;
    margin-bottom: 4px;
  }
}

.content {
  overflow: hidden;
  flex-grow: 1;
}

.diamond-icon {
  margin: 0 8px;
  font-size: 4px;
  color: $gray-3;
}

.name,
.address {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
}

.secondary-content {
  font-weight: normal;
}

.initials {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  background-color: $primary;
  display: flex;
  align-items: center;
  justify-content: center;
  color: $white;
  font-weight: 500;
  font-size: 20px;
  font-family: $secondary-font;
}

.initials-loading {
  width: 82px;
}

</style>


<template lang="pug">

  .store-info-card.flex.vertical-center
    .initials-content
      template(v-if="loading")
        loading-lines.initials-loading(:height="80", :min="100", :max="100")
      template(v-else)
        span.initials {{ resource.initials }}

    .content
      template(v-if="loading")
        loading-lines(:height="18")
        loading-lines(:height="18")

      template(v-else)
        .name-content.flex.vertical-center
          span.name {{ resource.name }}

        .secondary-content.flex
          span.cnpj {{ resource.cnpj }}
          //- i.diamond-icon.spacer.fa.fa-diamond.flex.center.vertical-center
          //- span.address {{ resource.$get('address.addressLine1') }}

</template>


<script>

export default {
  props: {
    resource: { type: Object, default: () => ({}) },
    loading:  { type: Boolean, default: false }
  },

  data() {
    return {
      i18nScope: "stores.components.store-info-card"
    }
  }
}

</script>

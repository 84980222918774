<!--
<doc lang="markdown">
Exibe local de atendimento (interno, externo), prefixado por um ícone

_Props_: value, disabled

Como usar:
```pug
  facilities-tag(value="internal", disabled)
```
</doc>
-->


<style lang="scss" scoped>

.facilities-tag {
  background: #FFEEDF;
  border-radius: 32px;
  font-family: $secondary-font;

  &.normal {
    padding: 0px 4px;
    height: 19px;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;

    .icon {
      font-size: 12px;
    }
  }

  &.big {
    padding: 2px 8px;
    height: 26px;
    font-weight: 500;
    font-size: 16px;
  }

  &.disabled {
    color: $gray-2;
    background-color: $light-gray-2;
  }
}

.icon {
  width: 20px;
  text-align: center;
  margin-right: 4px;
}

.external {
  background: $light-green;
}

</style>


<template lang="pug">

  .facilities-tag.flex.vertical-center(:class="{ external: isExternal, disabled, [size]: true }")
    i.icon(:class="icon")
    slot
      span {{ $t(`.${value}`) }}

</template>


<script>

export default {
  name: "FacilitiesTag",

  props: {
    value:    { type: String, default: "internal" },
    disabled: { type: Boolean, default: false },
    size:     { type: String, default: "normal" }
  },

  data() {
    return {
      i18nScope: "components.supplier.facilities-tag"
    }
  },


  computed: {
    isExternal() {
      return this.value === "external"
    },

    icon() {
      if (this.isExternal) return "far fa-external-link-square-alt"
      return "far fa-house"
    }
  }
}

</script>

import { mixin } from "@caiena/model"

import Base from "./base"
import Discardable from "./mixins/discardable"

class VehicleModelGroupVehicleModel extends mixin(Base, [Discardable]) {
  static get attrs() {
    return _.uniq([
      ...super.attrs,
      "id",
      "vehicleModelGroupId",

      "vehicleModelId"
    ])
  }

  static build(data) {
    return new VehicleModelGroupVehicleModel(data)
  }

}

export default VehicleModelGroupVehicleModel

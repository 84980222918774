<style lang="scss" scoped>

.spreadsheet-download-modal {
  padding: 16px 32px;

  .button + .button {
    margin-left: 24px;
  }
}

</style>


<template lang="pug">

  app-modal(
    :close-button="!downloading",
    :heading="$t('.heading')",
    :width="600",
    @close="$emit('close')"
  )
    .spreadsheet-download-modal.flex
      template-download-button.button.grow(
        :deal-id="dealId",
        :disabled="downloading",
        @downloading="isDownloading => this.downloading = isDownloading"
      )
      current-spreadsheet-download-button.button.grow(
        :deal-id="dealId",
        :disabled="downloading",
        @downloading="isDownloading => this.downloading = isDownloading"
      )

</template>


<script>

import TemplateDownloadButton from "./template-download-button"
import CurrentSpreadsheetDownloadButton from "./current-spreadsheet-download-button"

export default {
  components: {
    TemplateDownloadButton,
    CurrentSpreadsheetDownloadButton
  },

  props: {
    dealId: { type: [String, Number], required: true }
  },

  data() {
    return {
      i18nScope: "suppliers.deals.products.index.spreadsheet-download-modal",

      downloading: false
    }
  }
}

</script>

import Base from "./base"

import { SpeedRatings } from "./tire/enums"

class TireSpeedRating extends Base {
  static get attrs() {
    return _.uniq([
      ...super.attrs,
      "speedRating"
    ])
  }

  static get constraints() {
    return {
      speedRating: { presence: true }
    }
  }

  static get enums() {
    return {
      speedRating: SpeedRatings.asEnum()
    }
  }
}

export default TireSpeedRating

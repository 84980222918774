<style lang="scss" scoped>

.index {
  .header {
    margin-bottom: 16px;

    .title {
      font-family: $secondary-font;
      font-weight: 500;
      font-size: 20px;
    }

    .add {
      font-size: 14px;

      .icon {
        margin-right: 4px;
      }
    }

    .count {
      font-size: 14px;
      font-weight: 400;
    }
  }

  .retry {
    margin-left: 4px;
  }

  .empty {
    background-color: $light-gray;
    border-radius: 8px;
    padding: 24px 0;

    .illustration {
      --size: 48px;
      width: var(--size);
      height: var(--size);
    }

    .empty-message {
      font-family: $secondary-font;
      font-weight: 400;
      font-size: 14px;
      margin-top: 8px;
      color: $gray-3;
    }
  }

  .attachments {
    margin: -24px 0 0 -24px;

    .attachment-card,
    .empty-card {
      margin-left: 24px;
      margin-top: 24px;
    }

    .empty-card {
      border: 1px dashed $gray-3;
      border-radius: 8px;
      width: 212px;
      height: 140px;
      cursor: pointer;

      .icon {
        color: $gray-2;
        font-size: 20px;
      }
    }
  }
}

</style>


<template lang="pug">

  .index
    slot(name="header", :props="{ servicesCount, editable, fetching, erred, openNewServiceModal }")
      .header
        .flex.space-between.vertical-center
          h2.title {{ $t('.title') }}
          app-button.add(
            v-if="editable && !fetching && !erred"
            theme="link",
            @click="openNewServiceModal"
          )
            i.icon.far.fa-plus
            span {{ $t('.btn.add.label') }}

        .count
          span(v-if="!fetching && !erred") {{ $t('.servicesCount', { count: servicesCount }) }}

    template(v-if="fetching")
      loading-lines(:lines="4")

    template(v-else-if="erred")
      span {{ $t('.fetch-error.message') }}
      app-button.retry(theme="link", @click="fetch") {{ $t('.fetch-error.retry.label') }}

    template(v-else-if="empty")
      .empty.flex.column-direction.center.vertical-center
        img.illustration(:src="wrenchIllustration")
        app-span.empty-message(:value="$t('.empty.message')")

    template(v-else)
      .services
        index-table.services-table(
          :empty="empty",
          :cols-count="6",
          :column-sizes="columnSizes"
        )
          template(#tableHeader)
            services-table-header(:column-sizes="columnSizes")

          template(#tableRows)
            service-row(
              v-for="(serviceOrderService, index) in serviceOrderServices"
              :key="serviceOrderService.id",
              :service-order-service="serviceOrderService",
              :service-order-id="serviceOrderId",
              :loading="submitting",
              :column-sizes="columnSizes",
              :editable="editable",
              @update="fetch"
            )

    new-service(
      v-if="showNewServiceModal",
      :service-order-id="serviceOrderId",
      @close="showNewServiceModal = false",
      @create="fetch"
    )

</template>


<script>

// Mixins
import FetchMixin from "@/mixins/fetch-mixin"

// Assets
import wrenchIllustration from "@/assets/images/illustrations/wrench.svg"

// Components
import ServicesTableHeader from "./index/services-table-header.vue"
import ServiceRow from "./index/service-row.vue"
import NewService from "./new.vue"

export default {
  components: {
    ServicesTableHeader,
    ServiceRow,
    NewService
  },

  mixins: [FetchMixin],

  props: {
    serviceOrderId: { type: [String, Number], required: true },
    submitting:     { type: Boolean, default: false },
    editable:       { type: Boolean, default: false }
  },

  data() {
    return {
      i18nScope: "tickets.drafts.contents.services.index",

      wrenchIllustration,

      resource: [],

      showNewServiceModal: false
    }
  },

  computed: {
    serviceOrderServices: {
      get() { return this.resource },

      set(value) { this.resource = value }
    },

    empty() {
      return _.blank(this.serviceOrderServices)
    },

    servicesCount() {
      return this.serviceOrderServices.length
    },

    columnSizes() {
      return "44px 1fr 50px 212px 68px 64px"
    }
  },

  watch: {
    empty() {
      this.$emit("status", !this.empty)
    }
  },

  methods: {
    openNewServiceModal() {
      this.showNewServiceModal = true
    },

    // @override Fetchable mixin
    fetchRequest() {
      return this.$newSdk.serviceOrders.drafts.services.all({ serviceOrderId: this.serviceOrderId })
    }
  }
}

</script>

<style lang="scss" scoped>

.mobiauto-version-option {
  padding: 0 24px;
  min-height: 68px;
  border-top: 1px solid $light-gray-3;
  border-bottom: 1px solid $light-gray-3;
  cursor: pointer;

  &.active {
    background: $light-gray-2;

    & .mobiauto-version-name {
      color: $primary;
    }
  }

  &.focus {
    background: $light-gray-2;

    & .mobiauto-version-name {
      color: $dark-primary-2;
    }

    .remove-icon-content {
      background: $light-gray-3;
      color: $primary;
    }
  }
}

.mobiauto-version-option + .mobiauto-version-option {
  border-top: 1px solid transparent;
}

.mobiauto-version-name {
  font-size: 18px;
  font-weight: normal;
  color: $gray-3;
  word-break: normal;
  overflow-wrap: break-word;
  margin-bottom: 4px;
  display: block;
}

.content-column {
  flex-grow: 1;
}

.actions-column {
  min-width: 42px;
  margin-left: 12px;
}

.remove-icon-content {
  display: inline-block;
  width: 24px;
  height: 24px;
  line-height: 24px;
  text-align: center;
  border-radius: 50px;
  color: $gray-2;
  transition: all 100ms linear;
}

.diamond-icon {
  font-size: 4px;
  font-weight: 900;
  color: $gray-3;
  width: 4px;
  height: 4px;
  margin: 8px;
}

.year-range {
  font-weight: 300;
  font-size: 12px;
  color: $gray-3;
  margin-left: 8px;
}

</style>


<template lang="pug">

  .mobiauto-version-option.flex.vertical-center(:class="{ active, focus }")
    .content-column
      .upper-row.flex.vertical-center
        .mobiauto-version-name
          span.model {{ mobiautoVersion.model.name }}
          i.diamond-icon.fal.fa-diamond
          span.name {{ cropName(mobiautoVersion.name) }}

        span.year-range {{ $t('.yearRange', { initial: mobiautoVersion.initialYear, final: mobiautoVersion.finalYear }) }}

      .details.flex.vertical-center
        fipe-code-tag.fipe-tag(:value="mobiautoVersion.fipeId")
        i.diamond-icon.fal.fa-diamond
        span {{ mobiautoVersion.$get('model.manufacturer.name') }}

    .actions-column.center
      .remove-icon-content(v-if="active")
        i.fal.fa-times

</template>


<script>

export default {
  props: {
    option: { type: Object, required: true },
    active: { type: Boolean, default: false },
    focus:  { type: Boolean, default: false }
  },

  data() {
    return {
      i18nScope: "vehicle-models.forms.form-fields.mobiauto-version-select-field.mobiauto-version-option"
    }
  },

  computed: {
    mobiautoVersion() {
      return this.option.data
    }
  },

  methods: {
    cropName(name) {
      return _.truncate(name, { length: 100 - this.mobiautoVersion.model.name.length })
    }
  }
}

</script>

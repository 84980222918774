<!--
<doc lang="markdown">

Tabela de referência para índices de velocidade de pneu.
Componente para exibição de dados, sem nenhuma prop, projetado com tamanho limitado,
pensando em popover ou modal.

Está em uso nos _fields_ de índice de velocidade de pneu, como "tooltip" (popover).


Uso em popover
---

```pug

v-popover(...)
  app-element(...)

  template(#popover)
    tire-speed-rating-popover-content

```

</doc>
-->


<style lang="scss" scoped>

.tire-speed-rating-popover-content {
  padding: 14px;

  .title {
    display: block;
    padding: 0px 12px;
    padding-bottom: 8px;

    margin: 0 12px;
    margin-bottom: 8px;
    border-bottom: 1px solid $light-gray-3;

    font-family: $secondary-font;
    font-size:   18px;
    font-weight: 500;
    color: $dark-gray;
  }

  .reference-table {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;

    width: 600px;
    max-height: 130px;

    padding: 4px 20px;

    font-size: 14px;

    .item {
      padding: 2px 4px;
    }

    .value {
      font-family: $primary-monospace-font;
      font-weight: 500;
      padding-right: 4px;
    }

    .label {
      font-family: $primary-font;
      font-weight: 300;
    }
  }
}

</style>


<template lang="pug">

  .tire-speed-rating-popover-content
    label.title {{ $t(".title") }}

    .reference-table
      .item(v-for="(label, index) in ratings")
        span.value {{ index }}:
        span.label {{ label }}

</template>


<script>

import SpeedRatings from "@/models/tire/enums/speed-ratings"

export default {
  name: "TireSpeedRatingPopoverContent",

  data() {
    return {
      i18nScope: "components.tire.tire-speed-rating-popover-content"
    }
  },

  computed: {
    ratings() {
      return _.reduce(SpeedRatings.keys, (result, key) => {
        let label = this.$t(".reference-table.item.label", {
          value: SpeedRatings.kmhFor(key)
        })
        result[key] = label

        return result
      }, {})
    }
  }
}

</script>

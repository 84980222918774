<style scoped lang="scss">

.generic-row-loading {
  padding: 12px 0;
  min-height: 70px;
  border-top: 1px solid $light-gray-3;
  border-bottom: 1px solid $light-gray-3;
}

.generic-row-loading + .generic-row-loading {
  border-top: 1px solid transparent;
}

.icon-column {
  padding-left: 24px;
  width: 72px;
}

.content-column {
  display: block;
  min-width: 500px;
  flex-grow: 1;
}

.price-column {
  display: block;
  min-width: 100px;
}

</style>


<template lang="pug">

  .generic-row-loading.flex.vertical-center(data-testid="genericRowLoading")
    .icon-column
      loading-lines(:min="60", :max="60", :height="30")
    .content-column
      loading-lines

    .price-column
      loading-lines(:min="80", :max="80")

</template>


<script>

export default {
  name: "GenericRowLoading"
}

</script>

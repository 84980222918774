<script>

import SupplierRow from "@/views/suppliers/index/supplier-row.vue"

export default {
  extends: SupplierRow,

  computed: {
    popoverMenuAuthorization() {
      return this.$can("read", "Supplier") || this.$can("update", "Store")
    }
  }
}

</script>

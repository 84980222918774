import Enum from "@caiena/enum"


const VETOR_APPROVAL_GROUPS = new Enum({
  // ID_Vetor  Grupo Bolsão_Vetor
  tires_and_glass: 14,  // PNEUS / VIDROS
  preventive:      23,  // PRÉ-APROVADO  :: "Mudar nome: PREVENTIVA"
  super_vip:       26,  // PREMIUM       :: "Mudar nome: SUPER VIP"
  high_priority:   28,  // URGENTE
  level_1:         30,  // 0 à 1.000
  level_2:         33,  // 1.000 À 2.000
  level_3:         34,  // 2.000 à 4.000
  level_4:         35,  // 4.000 à 8.000
  highest:         36   // Acima de 8.000
})



export { VETOR_APPROVAL_GROUPS }

<style lang="scss" scoped>

.service-order-row {
  display: grid;
  grid-template-columns: 40px 132px 109px 88px 1fr 124px 100px 64px;

  &.loading {
    .column {
      color: $gray-2;

      &.actions-column {
        ::v-deep .movida-common__app-button {
          color: $gray-2;

          &:hover {
            color: $orange;
          }
        }
      }

      .vetor-tag {
        ::v-deep .outside {
          fill: $gray-2;
        }
      }

      .app-tag ::v-deep .app-tag-label {
        color: $gray-2;
      }

      .id-tag ::v-deep .text {
        color: $gray-2;

        &:hover {
          color: $orange;
        }
      }

      .text,
      .link ::v-deep .app-span {
        color: $gray-2;
      }
    }
  }

  .column {
    padding: 0 12px;
    border-bottom: 1px solid $light-gray-3;
    height: 48px;
    overflow: hidden;

    &.vetor-column {
      .vetor-tag {
        font-family: $primary-monospace-font;
        font-weight: 400;
        font-size: 14px;
        padding: 0 4px;
        background-color: $light-orange-2;
        border-radius: 4px;
      }
    }

    &.total-column {
      .text {
        font-family: $secondary-font;
      }
    }

    &.supplier-column {
      .supplier {
        width: 100%;

        .address {
          font-size: 12px;

          .icon {
            color: $gray-2;
            margin-right: 4px;
          }
        }
      }
    }

    .text,
    .link ::v-deep .app-span {
      font-family: $primary-font;
      font-weight: 400;
      font-size: 14px;
      color: $dark-gray;
      text-align: initial;
    }

    .link ::v-deep .app-span:hover,
    .id-tag:hover {
      color: $orange;
    }
  }
}

</style>


<template lang="pug">

  .service-order-row(:class="{ loading }")
    .column.state-column.flex.vertical-center.center
      service-order-state-icon(:state="serviceOrder.state", data-testid="serviceOrderState")

    .column.type-column.flex.vertical-center
      app-button.button(
        theme="link",
        :to="showRoute"
      )
        app-span.link(
          :value="$t(`.fields.type.${serviceOrder.type}`)",
          crop,
          data-testid="serviceOrderType"
        )

    .column.code-column.flex.vertical-center
      app-button.button(
        theme="link",
        :to="showRoute"
      )
        id-tag.id-tag(
          :value="serviceOrder.code",
          hide-hashtag,
          data-testid="serviceOrderCode"
        )

    .column.vetor-column.flex.vertical-center
      .vetor-tag(data-testid="vetorCode")
        app-span(:value="serviceOrder.externalId")

    .column.supplier-column.flex.vertical-center
      .supplier(data-testid="serviceOrderSupplier")
        app-span.text(:value="supplier.tradingName", crop)
        .address.flex.vertical-center
          i.icon.fal.fa-house-user
          app-span(:value="supplierAddress", crop)

    .column.total-column.flex.vertical-center
      app-span.text(
        :value="$asCurrency(serviceOrder.total)",
        data-testid="serviceOrderTotal"
      )

    .column.created-at-column.flex.vertical-center
      app-span.text(
        :value="$standardTime(serviceOrder.createdAt)",
        data-testid="serviceOrderCreatedAt"
      )

    .column.actions-column.flex.vertical-center.flex
      popover-menu(
        :show.sync="showPopover",
        data-testid="popoverMenu"
      )
        popover-menu-button(
          :to="showRoute",
          icon="fal fa-eye",
          :text="$t('.actions.btn.show.label')",
          @close-menu="showPopover = false"
        )

</template>


<script>

export default {
  props: {
    serviceOrder: { type: Object, default: () => ({}) },
    loading:      { type: Boolean, default: false },
    storeId:      { type: [Number, String], required: true },
    ticketId:     { type: [Number, String], required: true }
  },

  data() {
    return {
      i18nScope:   "stores.tickets.show.service-order-row",
      showPopover: false
    }
  },

  computed: {
    supplier() {
      return this.serviceOrder.supplier || {}
    },

    supplierAddress() {
      return _.get(this.supplier, "address.addressLine1")
    },

    showRoute() {
      return {
        name:   "storeTicketServiceOrder",
        params: {
          id:             this.storeId,
          ticketId:       this.ticketId,
          serviceOrderId: this.serviceOrder.id
        }
      }
    }
  }
}

</script>

<style scoped lang="scss">

.maintenance-package-row {
  display: grid;
  grid-template-columns: 70px 1fr 1fr 125px 64px;

  &.loading {
    .column {
      &.id-column {
        .id-tag {
          color: $gray-2;
        }
      }

      &.actions-column {
        ::v-deep .movida-common__app-button {
          color: $gray-2;

          &:hover {
            color: $orange;
          }
        }
      }

      .text,
      .link ::v-deep .app-span {
        color: $gray-2;
      }
    }
  }

  .column {
    padding: 0 12px;
    border-bottom: 1px solid $light-gray-3;
    height: 48px;
    overflow: hidden;

    &.name-column {
      .discarded-icon {
        margin-right: 6px;
      }
    }

    &.products-count-column {
      .text {
        font-family: $secondary-font;
      }

      .product-icon {
        margin-right: 8px;
        color: $gray-2;
      }
    }

    ::v-deep .movida-common__app-button {
      white-space: unset;
    }

    .button {
      overflow: hidden;
    }

    .text,
    .link ::v-deep .app-span {
      font-family: $primary-font;
      font-weight: 400;
      font-size: 14px;
      color: $dark-gray;
      text-align: initial;
    }

    .link ::v-deep .app-span:hover {
      color: $orange;
    }
  }
}

</style>


<template lang="pug">

  .maintenance-package-row(:class="{ loading }")
    .column.id-column.flex.vertical-center
      id-tag.id-tag(
        :value="maintenancePackage.id",
        data-testid="maintenancePackageId"
      )

    .column.name-column.flex.vertical-center
      discarded-icon.discarded-icon(v-if="maintenancePackage.$discarded")
      app-button.button(
        theme="link",
        :to="showRoute"
      )
        app-span.link(
          :value="maintenancePackage.name",
          crop,
          data-testid="maintenancePackageName"
        )

    .column.description-column.flex.vertical-center
      app-button.button(
        theme="link",
        :to="showRoute",
      )
        app-span.link(
          :value="maintenancePackage.description",
          crop,
          data-testid="maintenancePackageDescription"
        )

    .column.products-count-column.flex.vertical-center
      maintenance-package-preview(
        :id="maintenancePackage.id",
        :products-count="maintenancePackage.productsCount",
        :name="maintenancePackage.name"
      )

    .column.actions-column.flex.vertical-center
      .actions-column
        popover-menu(
          v-if="popoverMenuAuthorization",
          :show.sync="showPopover",
          data-testid="popoverMenu"
        )
          popover-menu-button(
            v-if="$can('read', 'MaintenancePackage')",
            icon="fal fa-eye",
            :to="showRoute",
            :text="$t('.actions.btn.show.label')",
            @close-menu="showPopover = false"
          )

          popover-menu-button(
            v-if="$can('update', 'MaintenancePackage')",
            icon="fal fa-pencil",
            :to="editRoute",
            :text="$t('.actions.btn.edit.label')",
            @close-menu="showPopover = false"
          )

          template(v-if="maintenancePackage.$discarded")
            enable-action.popover-menu-button(
              :name="maintenancePackage.name",
              :id="maintenancePackage.id"
              size="normal",
              border="square",
              @click.native="showPopover = false"
              @enabled="$emit('enabled')"
            )
              .icon-content
                i.icon.fal.fa-check-circle
              span {{ $t('.actions.btn.enable.label') }}

          template(v-else)
            disable-action.popover-menu-button(
              :name="maintenancePackage.name",
              :id="maintenancePackage.id"
              size="normal",
              border="square",
              @click.native="showPopover = false"
              @disabled="$emit('disabled')"
            )
              .icon-content
                i.icon.fal.fa-lock
              span {{ $t('.actions.btn.disable.label') }}

          destroy-action.destroy-button.popover-menu-button(
            :name="maintenancePackage.name",
            :id="maintenancePackage.id"
            size="normal",
            border="square",
            @click.native="showPopover = false"
            @destroy="$emit('destroy')"
          )
            .icon-content
              i.icon.fal.fa-trash-alt
            span {{ $t('.actions.btn.destroy.label') }}

</template>


<script>

import DestroyAction from "../components/destroy-action"
import DisableAction from "../components/disable-action"
import EnableAction from "../components/enable-action"
import MaintenancePackagePreview from "../components/maintenance-package-preview.vue"

export default {
  components: {
    DestroyAction,
    DisableAction,
    EnableAction,
    MaintenancePackagePreview
  },

  props: {
    maintenancePackage: { type: Object, required: true },
    loading:            { type: Boolean, default: false }
  },

  data() {
    return {
      i18nScope: "maintenance-packages.index.maintenance-package-row",

      showPopover: false
    }
  },

  computed: {
    popoverMenuAuthorization() {
      return ["read", "update", "destroy"].some(action => this.$can(action, "MaintenancePackage"))
    },

    showRoute() {
      if (!this.$can("read", "MaintenancePackage")) return null

      return {
        name:   "maintenancePackage",
        params: {
          id: this.maintenancePackage.id
        }
      }
    },

    editRoute() {
      return {
        name:   "editMaintenancePackage",
        params: {
          id: this.maintenancePackage.id
        }
      }
    }
  }
}

</script>

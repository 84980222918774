import { mixins as movidaMixins } from "movida-common.vue"
import viewStore from "@/store/view"

const { Fetchable, Paginatable, UrlQuery } = movidaMixins

export default {
  mixins: [Fetchable, Paginatable, UrlQuery],

  data() {
    return {
      // TODO: como renomear `resource` para `collection`?
      // XXX: por ora estamos usando uma computed `collection` como "alias"`
      resource:  [],
      autofetch: false,

      // @replicated from View (view.vue)
      // XXX: como View e esse SearchableIndexView "competem" como componente-base
      //   para extensão, precisamos replicar coisas aqui.
      // TODO: refatorar: fazer um estender o outro (SearchableIndexView extends View)
      appError:  null,
      routeName: this.$route.name
    }
  },

  created() {
    if (_.blank(this.parseQueryParams)) {
      throw new Error(`Vue SearchableIndexView - #parseQueryParams data - Not implemented yet. At least 'page' must be specified.`)
    }

    // TODO Extrair para Query mixin e entender
    // como executar antes do _fetch()
    this.parseAndFetch()
  },

  watch: {
    $route(to, from) {
      // TODO: remover isso! rever esse comportamento que tá bastante confuso.
      this.onRouteChange(to, from)
    },

    // @replicated from View (view.vue)
    appError() {
      viewStore.appError = this.appError
    }
  },

  computed: {
    // @replicated from View (view.vue)
    backRoute() {
      return null
    },

    // @replicated from View (view.vue)
    route() {
      return this.$router.resolve({ name: this.routeName }).route
    },

    // TODO: fazer essa computed `collection` substituir o data.resource
    collection() {
      return this.resource
    },

    showPaginator() {
      if (!this.pagination) return false
      return this.pagination.total > this.pagination.perPage
    },

    showAppListHeader() {
      return this.fetching || this.hasFilters || !this.empty
    },

    empty() {
      return _.blank(this.collection)
    },

    hasFilters() {
      const filterList = Object.keys(_.omit(this.parseQueryParams, "page"))
      const currentParams = _.pick(this.$route.query, filterList)

      return _.present(currentParams)
    }
  },

  methods: {
    // @replicated from View (view.vue)
    goBack() {
      this.$goBack({ fallback: this.backRoute })
    },

    clearFilters() {
      this.$router.push({ name: this.routeName })
      this.currentRouteQuery = this.$route.query
    },

    onRouteChange() {
      this.parseAndFetch()
    },

    parseAndFetch() {
      this.parseRoute()
      this._fetch()
    },

    updateOrFetch() {
      this.updateUrlQuery()
      if (!this.queryChanged) {
        this._fetch()
      }
    },

    onChangePage(page) {
      this.params.page = page
      this.updateOrFetch()
    },

    search() {
      // XXX Ao realizar uma nova busca, precisamos voltar para a primeira página
      // XXX passando undefined para causar detecção no Vue
      this.params.page = undefined
      this.updateOrFetch()
    },

    // View method
    // Erros de renderização de paginas devem
    // ser tratados neste método
    hasViewError(err) {
      return _.get(err, "constructor.name") === "HttpError"
    }
  }
}

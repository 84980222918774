import Base from "./base"

class VehiclePart extends Base {
  static get attrs() {
    return _.uniq([
      ...super.attrs,
      "name",
      "description",
      "rearPosition",
      "frontPosition",
      "centralPosition",
      "leftLocation",
      "rightLocation",
      "topLocation",
      "bottomLocation",
      "internalLocation",
      "externalLocation",
      "symptomsCount"
    ])
  }

  static get relations() {
    return {
      symptoms: { type: "hasMany", model: "Symptom" }
    }
  }

  static get constraints() {
    return {
      name: { presence: true, type: "string", length: { maximum: 1000 } }
    }
  }
}

export default VehiclePart

<style lang="scss" scoped>

.last-tickets-modal {
  padding: 16px 24px;

  .back-button {
    font-size: 14px;
    margin-bottom: 8px;

    .icon {
      font-size: 12px;
      margin-right: 4px;
    }
  }

  .tickets-table {
    margin-top: 24px;
  }
}

</style>


<template lang="pug">

  app-modal(
    :width="1156",
    :heading="$t('.title')",
    @close="$emit('close')"
  )
    .last-tickets-modal
      template(v-if="serviceOrderChosen")
        .back-button.flex.vertical-center
          app-button(@click="setServiceOrder(null)", theme="link")
            i.icon.fas.fa-arrow-left
            span {{ $t('.goBack.message') }}

        last-tickets-show(
          :vehicleId="vehicle.id",
          :service-order-id="serviceOrderId"
        )

      template(v-else)
        vehicle-info-banner(:vehicle="vehicle")

        last-tickets-index(
          :vehicle="vehicle"
          @select="setServiceOrder"
        )

</template>


<script>

// Components
import LastTicketsIndex from "./last-tickets/index.vue"
import LastTicketsShow from "./last-tickets/show.vue"
import VehicleInfoBanner from "@/views/tickets/drafts/components/vehicle-info-banner.vue"

export default {
  components: {
    LastTicketsIndex,
    LastTicketsShow,
    VehicleInfoBanner
  },

  props: {
    vehicle:        { type: Object, required: true },
    serviceOrderId: { type: [String, Number], default: null },

    sortedBy: { type: String, default: "" }
  },

  data() {
    return {
      i18nScope: "tickets.drafts.vehicles.components.last-tickets-modal"
    }
  },

  computed: {
    serviceOrderChosen() {
      return _.present(this.serviceOrderId)
    }
  },

  methods: {
    setServiceOrder(serviceOrderId) {
      this.$emit("update:service-order-id", serviceOrderId)
    }
  }
}

</script>

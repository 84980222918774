import Base from "./base"

class VehiclePartTarget extends Base {
  static get attrs() {
    return _.uniq([
      ...super.attrs,
      "serviceOrderServiceId",
      "position",
      "location"
    ])
  }

  static get enums() {
    return {
      position: {
        rear:    "rear",
        front:   "front",
        central: "central"
      },

      location: {
        left:     "left",
        right:    "right",
        top:      "top",
        bottom:   "bottom",
        internal: "internal",
        external: "external"
      }
    }
  }

  static get constraints() {
    return {
      position: { presence: true },
      location: { presence: true }
    }
  }

  get empty() {
    return !this.$markedForDestruction && _.blank(this.position) && _.blank(this.location)
  }
}

export default VehiclePartTarget

import ServiceOrder from "./service-order"

class ServiceOrderExternalMaintenance extends ServiceOrder {
  static get attrs() {
    const attrs = super.attrs.filter(attr => attr !== "type")

    return _.uniq([
      ...attrs,
      "dealId",
      "dealMaintenancePackageId"
    ])
  }

  static get relations() {
    return {
      ...super.relations,
      vehicle:  { type: "hasOne", model: "Vehicle" },
      services: { type: "hasMany", model: "ServiceOrderService" },
      products: { type: "hasMany", model: "ServiceOrderProduct" }
    }
  }

  static get enums() {
    const enums = { ...super.enums }
    delete enums.type

    return enums
  }

  static get virtuals() {
    return [
      "type"
    ]
  }

  // virtuals
  get type() {
    return "ServiceOrders::Maintenance"
  }
}

export default ServiceOrderExternalMaintenance

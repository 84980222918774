<style lang="scss" scoped>

.form {
  width: 100%;
  gap: 8px;

  .row {
    gap: 16px;

    &:first-child {
      grid-template-columns: minmax(0, 2fr) minmax(0, 1fr) minmax(0, 1fr) 40px;
    }
  }

  .submit-button {
    min-width: revert; // XXX: remove regra de min-width do app-button
  }
}

</style>


<template lang="pug">

  .search-form
    form.form.grid(
      @submit.prevent="$emit('search')",
      method="get",
      data-test="form"
    )
      .row.grid.vertical-bottom
        clearable-input-field(
          v-model="params.q"
          :disabled="loading"
          :placeholder="$t('.fields.q.placeholder')"
          hide-label
          name="search[q]"
        )
          template(#iconLeft)
            i.far.fa-search

        service-type-select-field(
          v-model="params.type"
          :label="$t('.fields.type.label')"
          :loading="loading"
          :placeholder="$t('.fields.type.placeholder')"
          name="search[type]"
        )

        finder-field.field.active(
          v-model="params.active"
          :disabled="loading"
          :label="$t('.fields.active.label')"
          name="search[active]"
          :options="activeOptions"
          :placeholder="$t('.fields.active.placeholder')"
        )

        app-button.submit-button(
          type="submit"
          :loading="loading"
          outline
        )
          i.icon.far.fa-search

</template>


<script>

// TODO: tranformar em app component (global - src/components/)
import ClearableInputField from "@/views/monitoring/_components/clearable-input-field"


export default {
  components: {
    ClearableInputField
  },

  props: {
    loading: { type: Boolean, default: false },
    params:  { type: Object, default: () => ({}) }
  },

  data() {
    return {
      i18nScope: "ticket-types.services.index.search-form"
    }
  },

  computed: {
    activeOptions() {
      return [{
        label: this.$t(".fields.active.options.enabled.label"),
        value: true
      }, {
        label: this.$t(".fields.active.options.disabled.label"),
        value: false
      }]
    }
  }
}

</script>

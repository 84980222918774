<style scoped lang="scss">

.suppliers {
  padding-bottom: 24px;

  .header {
    margin-bottom: 24px;
    padding-bottom: 16px;

    .title-icon {
      margin-right: 16px;
      font-size: 30px;
    }

    .title-text {
      font-size: 30px;
      font-weight: normal;
      font-weight: 500;
      font-family: $secondary-font;
    }
  }

  .clear-filters {
    position: relative;

    .filters-button {
      position: absolute;
      margin-top: 10px;
    }
  }

  .list {
    margin-top: 50px;

    .table {
      margin-top: 16px;
    }
  }

  .paginator-content {
    padding-top: 24px;

    &.loading {
      max-width: 400px;
      margin: 0 auto;
    }
  }
}

</style>


<template lang="pug">

  .suppliers
    header.header.flex.space-between
      .title.flex.vertical-center
        i.title-icon.supplier-icon.fal.fa-user-hard-hat
        h1.title-text {{ $t('.title') }}
      app-button(
        v-if="$can('update', store)",
        :to="{ name: 'newStoreSupplier', params: { id: this.storeId } }",
        data-testid="addSupplier"
      ) {{ $t('.btn.new') }}

    search-form.search(
      :loading="fetching",
      :params="parsedQueryParams",
      @search="search"
    )

    .clear-filters
      filters-button.filters-button(
        v-if="hasFilters",
        @clear="clearFilters"
      )

    .list
      .flex.end.vertical-bottom
        results-indicator(
          :page="parsedQueryParams.page",
          :per-page="pagination.perPage",
          :total="pagination.total",
          :loading="fetching"
        )

      suppliers-table.table(
        :error="erred",
        :empty="empty",
        :initializing="initializing",
        :loading="fetching",
        :suppliers="suppliers",
        :store-name="store.name"
        :sorted-by="parsedQueryParams.sort",
        @sort="onSortChange",
        @changed="fetch",
        @destroy="fetch"
      )

    .paginator-content(v-if="fetching || showPaginator", :class="{ loading: fetching }")
      paginator(
        :loading="fetching",
        :per-page="pagination.perPage",
        :total="pagination.total",
        :value="parsedQueryParams.page",
        :disabled="fetching",
        @input="onPageChange",
        data-testid="paginator"
      )

    router-view(
      :back-route-query="currentRouteQuery",
      @changed="fetch"
    )

</template>


<script>

// View
import IndexView from "@/views/index-view"

// Mixins
import FetchMixin from "@/mixins/fetch-mixin"

// Local components
import SuppliersTable from "./index/suppliers-table"
import SearchForm from "./index/search-form"

export default {
  name: "StoreSuppliers",

  components: {
    SuppliersTable,
    SearchForm
  },

  extends: IndexView,

  mixins: [FetchMixin],

  props: { store: { type: [Array, Object], required: true } },

  data() {
    return {
      i18nScope: "stores.suppliers.index",
      routeName: "storeSuppliers",

      model: null,

      storeId: null,

      resource: [],

      // Query Mixin data
      queryParamsStructure: {
        q:         "string",
        city_id:   "string",
        sector_id: "string",
        page:      "integer",
        sort:      "string"
      }
    }
  },

  computed: {
    storeSuppliers: {
      get() { return this.resource },
      set(value) { this.resource = value }
    },

    empty() {
      return _.blank(this.storeSuppliers)
    },

    suppliers() {
      return this.storeSuppliers.map(storeSupplier => ({ ...storeSupplier.supplier, storeSupplierId: storeSupplier.id }))
    }
  },

  methods: {
    parseRoute() {
      this.storeId = this.$route.params.id
    },

    // @override Fetchable mixin
    fetchRequest() {
      return this.$newSdk.stores.suppliers.all({ storeId: this.storeId, params: this.requestQueryParams })
    }
  }
}

</script>

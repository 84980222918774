import Vue from "vue"
import VueRouter from "vue-router"

import { ErrPlugin, LoadingPlugin, NotificationsPlugin, ParamsPlugin } from "utils.vue"
import PortalVue from "portal-vue"
import vClickOutside from "v-click-outside"
import VueObserveVisibility from "vue-observe-visibility"
import { VueMaskPlugin } from "@/lib/mask/v-mask"
import ModelsPlugin from "@/models/plugin"

Vue.use(ErrPlugin)
Vue.use(LoadingPlugin)
Vue.use(NotificationsPlugin, { error: { dismiss: false } })
Vue.use(ParamsPlugin)
Vue.use(PortalVue)
Vue.use(VueMaskPlugin)
Vue.use(vClickOutside)
Vue.use(VueObserveVisibility)
Vue.use(ModelsPlugin)
Vue.use(VueRouter)


// console for debugging in templates
// ---
// Vue.use({
//   installed: false,
//   install(Vue, options) {
//     if (this.installed) return
//
//     this.installed = true
//
//     // Vue.console = globalThis.console
//     Vue.prototype.console = globalThis.console
//   }
// })


// _ (lodash) to use in vue component templates
Vue.use({
  installed: false,
  install(Vue, options) {
    if (this.installed) return

    this.installed = true

    // Vue._ = globalThis._
    Vue.prototype._ = globalThis._
  }
})

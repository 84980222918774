<style lang="scss" scoped>

.vehicle-models {
  overflow: auto;

  .section {
    padding: 24px;
  }
}

</style>


<template lang="pug">

  .vehicle-models
    section.section
      vehicle-models-list(:vehicleModelGroupId="vehicleModelGroupId", :has-checkbox="false")

</template>


<script>

// Components
import VehicleModelsList from "./index/vehicle-models-list"

export default {
  name: "SupplierServiceSupportMaintenanceVehicleModelGroupVehicleModels",

  components: {
    VehicleModelsList
  },

  props: {
    vehicleModelGroupId: { type: [String, Number], required: true }
  },

  data() {
    return {
      i18nScope: "suppliers.service-supports.maintenance.vehicle-model-groups.vehicle-models.index"
    }
  }
}

</script>

<style lang="scss" scoped>

.supplier-option {
  padding: 4px 12px;

  cursor: pointer;

  min-height: initial;
  line-height: initial;

  &.selected {
    padding: 12px 0;

    .service-option-container {
      border: none;
    }
  }

  &.active {
    color: $primary;
    background: $light-gray-2;
  }

  &.focus {
    background: $light-gray-2;
    color: $dark-primary-2;

    .service-name {
      color: $primary;
    }

    .remove-icon-content {
      background: $light-gray-3;
      color: $primary;
    }
  }
}

.fa-check-circle {
  color: $dark-green;
  font-size: 14px;
  margin-right: 8px;
}

.content {
  overflow: hidden;
  flex-grow: 1;
}

.tradingName {
  cursor: pointer;
  font-size: 18px;
  font-weight: normal;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
}

.secondary-text {
  cursor: pointer;
  font-size: 14px;
}

.address {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
}

.divisor {
  margin: 0 8px;
  font-size: 4px;
  color: $gray-3;
}

.remove-icon-content {
  margin-left: 12px;
  width: 24px;
  height: 24px;
  line-height: 24px;
  text-align: center;
  border-radius: 50px;
  color: $gray-2;
  transition: all 100ms linear;
  flex-shrink: 0;
}

</style>


<template lang="pug">

  .supplier-option.flex.vertical-center(:class="{ active, focus, selected }")
    .content
      .main-content.flex.vertical-center
        i.far.fa-check-circle
        app-span.tradingName(:value="supplier.tradingName", crop)
        i.fas.fa-diamond.divisor
        label.cnpj.secondary-text.no-shrink {{ supplier.cnpj }}

      .address-content.flex.vertical-center(v-if="supplier.address")
        app-span.address.secondary-text(
          :value="supplier.address.addressLine1",
          crop,
          :cropTooltipOptions="{ placement: 'bottom' }"
        )

    .remove-icon-content(v-if="active")
      i.fal.fa-times

</template>


<script>

export default {
  name: "SupplierOption",

  props: {
    option:   { type: Object, required: true },
    active:   { type: Boolean, default: false },
    focus:    { type: Boolean, default: false },
    selected: { type: Boolean, default: false }
  },

  computed: {
    supplier() {
      return _.get(this.option, "data") || {}
    }
  }
}

</script>

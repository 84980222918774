<style lang="scss" scoped>

.table-header {
  display: grid;
  grid-template-columns: var(--grid-columns);
  background-color: $light-gray;
  height: 40px;
  border-bottom: 1px solid $light-gray-3;
  border-top: 1px solid $light-gray-3;

  .unsortable {
    padding: 0 12px;
  }

  .column {
    font-size: 12px;

    &.price-column {
      margin-left: auto;
    }

    .text {
      font-family: $secondary-font;
      font-size: 14px;
      color: $dark-gray;
      font-weight: 400;
    }
  }
}

</style>


<template lang="pug">

  .table-header(:style="styles")
    .column.unsortable.flex.vertical-center
      sap-icon(disabled)

    .column.unsortable.flex.vertical-center
      span.text {{ $t('.fields.description') }}

    .column.unsortable.flex.vertical-center
      span.text {{ $t('.fields.partOrigin') }}

    .column.unsortable.flex.vertical-center.end
      span.text {{ $t('.fields.quantity') }}

    .column.unsortable.flex.vertical-center.price-column
      span.text {{ $t('.fields.price') }}

    .column.unsortable.flex.vertical-center.price-column
      span.text {{ $t('.fields.totalPrice') }}

</template>


<script>

export default {
  props: {
    columnSizes: { type: String, required: true }
  },

  data() {
    return {
      i18nScope: "service-orders.show.products-table.table-header"
    }
  },

  computed: {
    styles() {
      return {
        "--grid-columns": this.columnSizes
      }
    }
  }
}

</script>

<style lang="scss" scoped>

.help-text {
  font-size: 12px;
  color: $gray-3;
  line-height: 1;
  display: block;
  overflow: hidden;
  padding: 4px 8px;
}

.error {
  display: block;
  text-align: center;
  padding: 4px 12px;
  font-size: 14px;
  font-style: italic;

  .link {
    margin-left: 5px;
    font-size: 14px;
    font-style: italic;
    font-weight: normal;
  }
}

.empty {
  display: block;
  text-align: center;
  padding: 4px 12px;
  font-size: 14px;
  font-style: italic;
}

</style>


<template lang="pug">

  .product-select-field
    select-field(
      :name="name",
      :label="label",
      :fetching="fetching",
      :disabled="disabled",
      :placeholder="placeholder",
      :optional="optional",

      option-as-value,
      :loading="loading || fetchingOption",
      :options="options",
      :default-filter="false",
      :errors="errors",
      searchable,

      :value="option",
      @input="onSelect",

      @open="_fetch",
      @close="onClose",
      @inputSearch="(newSearch) => search = newSearch",
      @search="onSearch"
    )
      template(#menuContentHeader)
        template(v-if="fetchError")
          label.error
            span {{ $t('.error.message') }}
            app-button.link(theme="link", @click="onSearch") {{ $t('.error.link') }}

      template(#menuContentFooter)
        template(v-if="!fetching && !fetchError && empty")
          label.empty {{ $t('.empty') }}

      template(#fetching)
        li.option {{ $t('.loading') }}

      template(#selected="{ props: { selected } }")
        slot(name="selected", :props="{ selected }")
          product-option.option(:option="option")

      template(#option="{ props: { option, index, focus, active } }")
        slot(name="option", :props="{ option, index, focus, active }")
          product-option(
            :option="option",
            :active="active",
            :focus="focus"
          )

</template>


<script>

// Mixins
import FieldMixin from "@/mixins/field"
import AsyncSelect from "@/mixins/select/async"

// Local components
import ProductOption from "./product-option.vue"

// Models
import models from "@/models"

export default {
  name: "ProductSelectField",

  components: {
    ProductOption
  },

  extends: AsyncSelect,

  mixins: [FieldMixin],

  data() {
    return {
      i18nScope: "components.product.select-field.product-select-field",

      model: "Product",

      fetchingOption: false,
      fetchError:     false
    }
  },

  computed: {
    empty() {
      return this.options.length === 0
    }
  },

  methods: {
    // @overrides extends AsyncSelect
    dataToOption(data) {
      const product = new models[data.type]({ ...data })

      return {
        value: product.id,
        label: product.productName,
        data:  product
      }
    },

    // @overrides extends AsyncSelect
    async fetchOption(id) {
      try {
        this.fetchingOption = true

        const response = await this.$sdk.products.get({ id })
        const normalizedData = _.camelizeKeys(response.data)

        this.option = this.dataToOption(normalizedData)

      }
      catch (err) {
        this.$err.log(err)
      }
      finally {
        this.fetchingOption = false
      }
    },

    // @overrides extends AsyncSelect
    fetchRequest() {
      return this.$sdk.products.list({ params: { q: this.search } })
    },

    // @overrides extends AsyncSelect
    onFetchSuccess(response, { normalizedData }) {
      const fetchedOptions = normalizedData.map(data => (
        this.dataToOption(data)
      ))

      this.options =  [...fetchedOptions]
    },

    // @overrides extends AsyncSelect
    onFetchError(error) {
      this.$err.log(error)
    }
  }
}

</script>

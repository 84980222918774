<style scoped lang="scss">

.service-show {
  padding-bottom: 24px;
}

.header {
  padding: 18px 0 8px 0;
}

.service-name {
  font-size: 30px;
  font-weight: bold;
  word-break: normal;
  overflow-wrap: break-word;
}

.id-tag {
  margin: 0 8px;
}

.btn + .btn {
  margin-left: 8px;
}

.icon {
  font-size: 16px;
  margin-right: 8px;
}

.description {
  font-size: 16px;
  font-weight: normal;
  margin: 0;
}

.table {
  margin-bottom: 24px;
}

.info-label + .info-label {
  margin-left: 40px;
}

.products {
  font-family: $secondary-font;
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  padding-bottom: 24px;
}

.section + .section {
  margin-top: 24px;
  padding-top: 24px;
  border-top: 1px solid $light-gray-3;
}

.section-title {
  margin-bottom: 24px;

  .title-icon {
    margin-right: 8px;
  }

  .title {
    font-family: $secondary-font;
    font-size: 20px;
    font-weight: 500;
  }
}

::v-deep.service-package-option{
  border: 1px solid $light-gray-3;
  border-radius: 5px;
  cursor: unset;

  * {
    cursor: unset;
  }

  .actions-column{
    min-width: unset;
  }
}

.movida-common__info-label {
  ::v-deep.movida-common__info-label-icon {
    font-size: 16px;
    color: $black;
  }
}

</style>


<template lang="pug">

  .service-show
    loading-lines(v-if="fetching", :lines="4")
    template(v-else)
      .section
        header.header.flex.space-between.vertical-center
          .flex.vertical-center
            h2.service-name(data-testid="name") {{ service.name }}
            id-tag.id-tag(:value="service.id", size="big")
            sap-code-tag(
              v-if="service.sapId",
              :value="service.sapId",
              size="big",
              data-testid="serviceSapId"
            )

          .actions.flex
            destroy-action.btn(
              :id="service.id",
              :name="service.name",
              @destroy="onDestroy"
            )
              i.fal.fa-trash-alt.icon
              span {{ $t('.btn.destroy') }}

            app-button.btn(
              v-if="$can('update', 'Service')",
              theme="neutral",
              size="small",
              :to="{ name: 'editService', params: { id }}"
            )
              i.fal.fa-pencil.icon
              span {{ $t('.btn.edit') }}

        section.description-content.preserve-whitespace(v-if="service.description", data-testid="description")
          p.description {{ service.description }}

      section.section.info.flex.vertical-center
        service-type-info-label.info-label(
          :label="$t('.fields.type.label')",
          :type="service.type",
          :value="Service.$tEnum('type', service.type)"
          :loading="fetching",
          data-testid="type"
        )

        info-label.info-label(
          :loading="fetching",
          :label="$t('.fields.kind.label')",
          :value="Service.$tEnum('kind', service.kind)",
          data-testid="kind"
        )
          template(#icon)
            service-kind-icon.icon(hide-tooltip :kind="service.kind")

        info-label.info-label(
          :loading="fetching",
          :label="$t('.fields.targetExecutionTime.label')",
          :value="targetExecutionTime",
          icon='fal fa-clock',
          data-testid="targetExecutionTime"
        )

        labor-cost-linked-indicator.info-label(
          v-if="service.laborCostLinked",
          data-testid="laborCostLinkedIndicator"
        )

      section.section(v-if="hasServicePackage", data-testid="servicePackage")
        .section-title.flex.vertical-center
          i.title-icon.far.fa-box-alt
          h2.title {{ $t('.sections.service-packages.title') }}

        service-package-option(:option="servicePackageAsOption")

      section.section.issues
        router-view(:service="service")

      section.section.footer.flex.space-between
        app-button(:to="{ name: 'services' }", outline)
          i.far.fa-arrow-left.icon
          span {{ $t('.btn.back') }}

        app-button(
          v-if="$can('create', 'Service')",
          theme="link",
          :to="{ name: 'newService' }"
        )
          span {{ $t('.btn.new') }}

</template>


<script>

// Models
import models from "@/models"

// Mixins
import FetchMixin from "@/mixins/fetch-mixin"

// View
import LoadedView from "@/views/loaded-view"

// Local Components
import DestroyAction from "./show/destroy-action"
import ServiceTypeInfoLabel from "./show/service-type-info-label"
import LaborCostLinkedIndicator from "./components/labor-cost-linked-indicator"
import VehicleIssuesList from "./components/vehicle-issues-list"

const { Service } = models

export default {
  name: "Service",

  components: {
    DestroyAction,
    ServiceTypeInfoLabel,
    LaborCostLinkedIndicator,
    VehicleIssuesList
  },

  extends: LoadedView,

  mixins: [FetchMixin],

  data() {
    return {
      i18nScope: "services.show",
      routeName: "service",

      id: this.$params.asInteger(this.$route.params.id),

      // FetchMixin
      resource: {},

      Service
    }
  },

  computed: {
    service: {
      get() { return this.resource },
      set(value) { this.resource = value }
    },

    targetExecutionTime() {
      if (_.blank(this.service.targetExecutionTime)) return "-"

      return this.$t("count.hours", {
        count: this.service.targetExecutionTime
      })
    },

    hasServicePackage() {
      return _.present(this.service.servicePackage)
    },

    servicePackageAsOption() {
      if (!this.hasServicePackage) return null

      let option = { data: this.service.servicePackage }

      return option
    }
  },

  methods: {
    parseRoute() {},

    onDestroy() {
      this.$router.push({ name: "services" })
    },

    // @override FetchMixin
    fetchRequest() {
      return this.$newSdk.services.find({ serviceId: this.id })
    }
  }
}

</script>

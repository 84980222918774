<style scoped lang="scss">

.mobiauto-version-status-icon {
  font-size: 20px;
  width: 25px;
  text-align: center;
}

.success {
  color: $dark-success;
}

.warning {
  color: $dark-warning;
  font-weight: 900;
}

</style>

<template lang="pug">

  .mobiauto-version-status-icon
    i.icon(:class="iconClass")

</template>


<script>

export default {
  name: "MobiautoVersionStatusIcon",

  props: {
    isComplete: { type: Boolean, default: false }
  },

  computed: {
    iconClass() {
      return this.isComplete ? "fal fa-check-circle success" : "fal fa-exclamation-triangle warning"
    }
  }
}

</script>

<!--
# AppFinder

Seletor de opções, com busca interna e externa, e possibilidade de seleção
múltipla

## Props
- clearable?: {boolean} habilita botão para limpar o campo.
- disabled?: {boolean} desabilita entrada.
- error?: {boolean} indica que o campo contém erro.
- listboxConfig?: {object} configurações da listbox.
  - listboxConfig.align? {'left' | 'right'} lado que a listbox se alinhará com o
    combobox.
  - listboxConfig.maxHeight? {number} altura máxima em px da listbox.
  - listboxConfig.width? {number} largura em px da listbox.
- modelAsValue?: {boolean} Determina se o valor emitido para o model deve ser
  apenas o `value` (quando TRUE) ou o objeto inteiro (quando FALSE).
- multiple?: {boolean} habilitação seleção múltipla.
- name?: {string} nome do campo do formulario.
- options: {array<{label: string, value: string} | string | number>} opções
  disponíveis.
- placeholder?: {string} texto a se exibir quando não há seleção.
- searchable?: {boolean} habilita busca.
- searchMode?: {'external' | 'local'} modo de busca, sendo que `external` apenas
  emite o valor inserido no buscador.
- theme?: {'default' | 'borderless'} tema visual do componente.
- value: {array | number | object | string} valor selecionado.

## Emits
- input: {array | number | object | string} houve mudança na seleção. O valor
  emitido corresponde à exata estrutura da opção, assim como fornecido na prop
  `options`.
- open: {void} a listbox foi aberta.
- search: {string} houve mudança no valor da busca.

## Slots
- option: Conteúdo de cada opção listada.
  props:
    - option: {{label: string, value: string} | string | number}

- selectedLabel: Conteúdo do `combobox` quando há seleção.
  props:
    - value: {{label: string, value: string} | string | number} Valor(es) selecionado(s).
    - displayValue: {string} Valor da seleção formatado, separado por vírgula.
- emptyOptions: Conteúdo da `listbox` quando não há opções.

## Uso
### Básico
```pug
app-finder(
  v-model="turno"
  name="turno"
  :options="['matutino', 'vespertino', 'noturno']"
)
```

### Com busca interna
```pug
app-finder(
  v-model="pais"
  name="pais"
  :options="paises"
  searchable
)
```

### Com busca externa
```pug
app-finder(
  v-model="cidade"
  name="cidade"
  :options="cidade"
  searchable
  search-mode="external"
  @search="buscaCidade"
)
```

### Com slot `option`
```pug
app-finder(
  v-model="avatar"
  :options="[{ label: 'Avatar 1', value: 'avatar1.jpg' }]"
)
  template(#option="{ option }")
    img(:src="option.value")
    span {{ option.label }}
```
-->

<style lang="scss" scoped>
.app-finder {
  position: relative;

  &:not(.disabled):focus-within {
    .combobox {
      outline: 2px solid $primary;
      outline-offset: -2px;
    }
  }
}

.combobox {
  align-items: center;
  background: #fff;
  border: 1px solid $gray;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  padding: 1px 13px;
  width: 100%;

  .caret {
    color: $dark-gray;
    transition: ease-in-out 200ms transform;
  }

  .error & {
    border-color: $error;
  }

  .disabled & {
    background: $light-gray-3;
    cursor: not-allowed;

    .caret {
      display: none;
    }
  }

  &[aria-expanded=true] {
    .caret {
      transform: rotate(-180deg)
    }
  }

  &[aria-expanded=true],
  .app-finder:not(.disabled) &:hover,
  .app-finder:not(.disabled) &:focus {
    border-color: $primary;

    .caret {
      color: $primary;
    }
  }
}

.clear-button {
  align-items: center;
  background: transparent;
  border: none;
  border-radius: 24px;
  color: $dark-gray;
  display: flex;
  font-size: 16px;
  height: 24px;
  justify-content: center;
  margin: 8px 7px;
  position: absolute;
  right: 25px;
  top: 0;
  width: 24px;

  .disabled & {
    display: none;
  }

  &:hover {
    background: $light-gray-3;
    color: $primary;
  }
}

.selected-label,
.placeholder {
  font-family: $primary-font;
  font-size: 16px;
  height: 36px;
  line-height: 36px;
  margin-right: 0;
  overflow: hidden;
  text-align: start;
  text-overflow: ellipsis;
  white-space: nowrap;

  .clearable.selected & {
    margin-right: 31px;
  }
}
.selected-label {
  color: $gray-3;

  .disabled & {
    color: $gray-2;
  }
}
.placeholder {
  color: $gray;
  font-weight: 300;

  .disabled & {
    color: $gray;
  }
}

.listbox {
  --listbox-margin: 4px;
  border: 1px solid $light-gray-4;
  border-radius: 8px;
  box-shadow: 0 8px 16px rgba(0,0,0,0.25);
  background: #fff;
  display: flex;
  flex-direction: column;
  margin: var(--listbox-margin);
  overflow: hidden;
  position: absolute;
  z-index: 1000;
}

.search-bar {
  border-bottom: 1px solid $light-gray-4;
  padding: 8px;

  input {
    border: 1px solid $gray;
    border-radius: 4px;
    color: $gray-4;
    font-family: $primary-font;
    font-size: 16px;
    line-height: 36px;
    padding: 1px 13px;
    width: 100%;
  }
}

.option-list {
  list-style: none;
  margin: 0;
  padding: 0;
  position: relative;
  overflow-y: auto;
}

.option {
  padding: 12px;

  &:not(:first-of-type) {
    border-top: 1px solid $light-gray-4;
  }

  .default-option-slot {
    display: flex;

    .label {
      flex-grow: 1;
      flex-shrink: 1;
    }

    .remove-button {
      align-items: center;
      border-radius: 24px;
      display: none;
      flex-grow: 0;
      flex-shrink: 0;
      justify-content: center;
      height: 24px;
      width: 24px;
    }
  }

  &:not(.empty) {
    cursor: pointer;

    &[aria-selected=true] {
      background: $light-gray-2;
      color: $primary;

      .default-option-slot {
        .remove-button {
          color: $gray-2;
          display: flex;
        }
      }
    }

    &:hover,
    &:focus,
    &.current-focus {
      background: $light-gray-2;
      color: $dark-primary-2;

      .default-option-slot {
        .remove-button {
          color: inherit;
          background: $light-gray-3;
        }
      }
    }
  }
}

/* THEMES */
/* THEME: borderless */
.app-finder.borderless {
  .combobox {
    background: none;
    border: none;
    padding: 8px 12px 8px 24px;
  }
  &.disabled .combobox {
    .caret {
      color: $gray;
      display: block;
    }
  }

  .selected-label,
  .placeholder {
    font-family: $secondary-font;
    font-size: 14px;
    font-weight: 400;
    height: 24px;
    line-height: 24px;
    margin-right: 4px;
  }
  .selected-label {
    color: $dark-gray;
  }
  &.disabled .selected-label {
    color: $gray;
  }
}
</style>

<template lang="pug">
  .app-finder(:class="classes" @focusout="onFocusOut")
    input(
      :name="name"
      type="hidden"
      :value="formValue"
    )
    button.combobox(
      :aria-controls="listboxId"
      :aria-expanded="String(expanded)"
      :disabled="disabled"
      ref="combobox"
      role="combobox"
      :tabindex="expanded || disabled ? '-1' : '0'"
      type="button"
      @click="toggleExpand"
      @keydown.alt.down="open"
    )
      .selected-label(v-if="displayValue")
        slot(name="selectedLabel", :value="value", :display-value="displayValue")
          span  {{ displayValue }}
      .placeholder(v-else) {{ placeholder }}
      i.caret.fas.fa-chevron-down
    button.clear-button(
      v-if="clearable && hasSelection"
      tabindex="-1"
      type="button"
      @click.prevent="clearSelection"
    )
      i.far.fa-times
    .listbox(
      v-if="expanded",
      :id="listboxId"
      role="listbox",
      :style="listboxStyle"
      v-click-outside="onClickOutsideListbox"
    )
      .search-bar(v-if="searchable")
        input(
          v-model="query"
          type="text"
          @keydown.alt.up="close()"
          @keydown.down="onKeyDownDownSearch"
          @keydown.enter="onKeyDownEnterSearch"
          @keydown.esc="close()"
          @keydown.up="onKeyDownUpSearch"
        )
      ul.option-list(ref="optionList")
        template(v-if="_.present(options)")
          li.option(
            v-for="option in filteredOptions",
            :aria-selected="String(isSelected(option))"
            :class="{ 'current-focus': hasFocus(option) }"
            :key="getValue(option)"
            role="option"
            tabindex="-1"
            @click.prevent="select(option)"
            @keydown.alt.up="close()"
            @keydown.down="onKeyDownDown"
            @keydown.enter.prevent="select(option)"
            @keydown.esc="close()"
            @keydown.space="select(option, false)"
            @keydown.up="onKeyDownUp"
          )
            template(v-if="$slots.option || $scopedSlots.option")
              slot(name="option", :option="option")
            template(v-else)
              .default-option-slot
                .label {{ getLabel(option) }}
                .remove-button
                  i.fal.fa-times
        template(v-else)
          li.option.empty
            slot(name="emptyOptions")
              em Não há opções disponíveis

</template>

<script>
const [MODE_LOCAL, MODE_EXTERNAL] = ["local", "external"]

const THEMES = ["default", "borderless"]

export default {
  name: "AppFinder",

  props: {
    clearable:     { type: Boolean, default: true },
    disabled:      { type: Boolean, default: false },
    error:         { type: Boolean, default: false },
    listboxConfig: { type: Object, default: null },
    modelAsValue:  { type: Boolean, default: true },
    multiple:      { type: Boolean, default: false },
    name:          { type: String, default: null },
    options:       {
      type:    Array,
      default: null,
      validator(value) {
        if (value === null) return true
        return value.every(
          (option) => ["string", "number"].includes(typeof option)
          || (
            typeof option === "object"
            && ["label", "value"].every(
              (property) => Object.keys(option).includes(property)
            )
          )
        )
      }
    },
    placeholder: { type: String, default: "Selecione..." },
    searchable:  { type: Boolean, default: false },
    searchMode:  {
      type:      String,
      default:   MODE_LOCAL,
      validator: (value) => [MODE_LOCAL, MODE_EXTERNAL].includes(value)
    },
    theme: {
      type:      String,
      default:   "default",
      validator: (value) => THEMES.includes(value)
    },
    value: { type: [String, Number, Object, Array, Boolean], default: null }
  },

  data() {
    return {
      currentFocus: null,
      expanded:     false,
      listboxId:    _.uniqueId("app-finder-"),
      query:        ""
    }
  },

  computed: {
    classes() {
      return {
        clearable:    this.clearable,
        disabled:     this.disabled,
        error:        this.error,
        selected:     this.hasSelection,
        [this.theme]: true
      }
    },

    displayValue() {
      if (_.blank(this.value)) return ""
      return this.multiple
        ? this.value.map((option) => this.getDisplayValue(option)).join(", ")
        : this.getDisplayValue(this.value)
    },

    filteredOptions() {
      if (!(
        this.searchable
        && this.searchMode === MODE_LOCAL
        && this.query
      )) {
        return this.options
      }

      return this.search(this.query)
    },

    formValue() {
      if (_.blank(this.value)) return ""
      return this.multiple
        ? this.value.map((option) => this.getValue(option)).join()
        : this.getValue(this.value)
    },

    hasSelection() {
      return _.present(this.value)
    },

    listboxMaxHeight() {
      return (
        _.has(this.listboxConfig, "maxHeight")
          ? `${this.listboxConfig.maxHeight}px`
          : "auto"
      )
    },

    listboxPosition() {
      const side = _.get(this.listboxConfig, "align", "left")
      if (side === "right") return { right: 0 }
      return { left: 0 }
    },

    listboxStyle() {
      return {
        width:     this.listboxWidth,
        maxHeight: this.listboxMaxHeight,
        ...this.listboxPosition
      }
    },

    listboxWidth() {
      return (
        _.has(this.listboxConfig, "width")
          ? `${this.listboxConfig.width}px`
          : "calc(100% - 2 * var(--listbox-margin))"
      )
    }
  },

  watch: {
    currentFocus(value, oldValue) {
      if (value !== null) this.scrollToCurrentItem(value > oldValue ? "down" : "up")
    },
    query() {
      this.currentFocus = null
      if (this.searchMode === MODE_EXTERNAL) this.$emit("search", this.query)
    }
  },

  methods: {
    clearSelection() {
      this.$emit("input", this.multiple ? [] : null)
    },

    close(focus = true) {
      if (focus) this.$refs.combobox.focus()
      this.expanded = false
      if (this.query) this.query = ""
      if (this.currentFocus !== null) this.currentFocus = null
    },

    focusInput() {
      this.$el.querySelector(".search-bar input").focus()
    },

    focusOption() {
      const selected = this.$el.querySelector("[aria-selected=true]")
      if (selected) {
        selected.focus()
        return
      }

      const first = this.$el.querySelector(".option")
      if (first) first.focus()
    },

    getDisplayValue(option) {
      const optionValue = this.getValue(option)
      const _option = _.present(this.options) && this.options.find((item) => this.getValue(item) === optionValue)

      return this.getLabel(_option || option)
    },

    getLabel(option) {
      if (typeof option !== "object") return option
      return option.label
    },

    getValue(option) {
      if (typeof option !== "object") return option
      return option.value
    },

    hasFocus(option) {
      if (this.currentFocus === null) return false

      const current = this.filteredOptions[this.currentFocus]
      if (!current) return false
      return this.getValue(option) === this.getValue(current)
    },

    isSelected(option) {
      if (this.multiple) {
        const optionValue = this.getValue(option)
        return (
          Boolean(this.value)
          && this.value instanceof Array
          && this.value.some((item) => this.getValue(item) === optionValue)
        )
      }

      return Boolean(this.value) && this.getValue(this.value) === this.getValue(option)
    },

    onClickOutsideListbox() {
      this.close(false)
    },

    onFocusOut(event) {
      if (this.$el.contains(event.relatedTarget)) return
      this.close(false)
    },

    onKeyDownDown(event) {
      const next = event.target.nextSibling
      if (next) next.focus()
    },

    onKeyDownDownSearch(event) {
      event.preventDefault()
      if (this.currentFocus === null) {
        this.currentFocus = 0
        return
      }
      if (this.currentFocus < this.filteredOptions.length - 1) this.currentFocus++
    },

    onKeyDownEnterSearch(event) {
      event.preventDefault()
      if (this.currentFocus === null) return
      this.select(this.filteredOptions[this.currentFocus])
    },

    onKeyDownUp(event) {
      const previous = event.target.previousSibling
      if (previous) previous.focus()
    },

    onKeyDownUpSearch(event) {
      event.preventDefault()
      if (this.currentFocus === null) {
        this.currentFocus = this.filteredOptions.length - 1
        return
      }
      if (this.currentFocus > 0) this.currentFocus--
    },

    open() {
      this.expanded = true
      this.$emit("open")
      this.$nextTick(this.searchable ? this.focusInput : this.focusOption)
    },

    scrollToCurrentItem(direction = "down") {
      const list = this.$refs.optionList
      const item = list.children[this.currentFocus]

      if (!item) return

      const isItemCovered = (
        item.offsetTop < list.scrollTop
        || item.offsetTop + item.offsetHeight > list.scrollTop + list.offsetHeight
      )
      if (!isItemCovered) return

      if (direction === "down") {
        list.scrollTop = item.offsetTop + item.offsetHeight - list.offsetHeight
      }
      else {
        list.scrollTop = item.offsetTop
      }
    },

    search(value) {
      const query = value.toLowerCase()
      return this.options
        .filter((option) => String(this.getLabel(option)).toLowerCase().includes(query))
    },

    select(option, close = true) {
      const optionValue = this.getValue(option)
      const optionModelValue = this.modelAsValue ? optionValue : option

      if (this.multiple) {
        if (!(this.value instanceof Array)) {
          this.$emit("input", [optionModelValue])
        }
        else {
          const _option = this.value.find((item) => this.getValue(item) === optionValue)
          if (_option) {
            this.$emit("input", _.without(this.value, this.modelAsValue ? optionValue : _option))
          }
          else {
            this.$emit("input", [...this.value, optionModelValue])
          }
        }
        return
      }

      if (
        this.value != null
        && optionValue === this.getValue(this.value)
      ) {
        this.$emit("input", null)
      }
      else {
        this.$emit("input", optionModelValue)
      }

      if (close) this.close()
    },

    toggleExpand() {
      if (this.expanded) this.close()
      else this.open()
    }
  }
}
</script>

<style lang="scss" scoped>

.service-support-card {
  width: 271px;
  height: 185px;
  border: 1px solid $light-gray-3;
  border-radius: 16px;
  padding: 24px;

  &.active {
    border-color: $dark-green;
    box-shadow: 0px 2px 6px 0px $gray-2;
  }

  .top {
    margin-bottom: 24px;

    ::v-deep .menu-button {
      height: 24px;
      padding: 4px;
    }

    .status-loading {
      width: 74px;
    }

    .action-loading {
      width: 24px;
    }
  }

  .middle {
    .service-type-icon {
      width: 40px;
      height: 40px;
      margin-right: 16px;
    }

    .service-name {
      font-family: $secondary-font;
      font-weight: 500;
      font-size: 20px;
      overflow-wrap: anywhere;
      color: $dark-gray;

      .name {
        height: 40px;
      }

      &:hover {
        color: $orange;
      }
    }
  }

  .bottom {
    height: 32px;

    .capacity {
      line-height: 16px;

      .total,
      .hourly {
        display: inline-block;
      }
    }

    .disabled {
      padding: 4px 0;
      font-style: italic;
    }

    .show-button {
      color: $gray-2;

      &:hover {
        color: $orange;
      }
    }
  }
}

</style>


<template lang="pug">

  .service-support-card.no-shrink.flex.column-direction(
    :class="{ active: isActive }",
    data-testid="supplierServiceSupportCard"
  )
    .top.flex.space-between
      template(v-if="loading")
        loading-lines.status-loading(
          :height="24",
          :min="100",
          :max="100"
        )

      template(v-else)
        supplier-status-tag(:value="isActive")

      template(v-if="loading")
        loading-lines.action-loading(
          :height="24",
          :min="100",
          :max="100"
        )

      template(v-else)
        edit-service-support(
          v-if="showEditServiceSupportModal",
          :supplier-id="supplierId",
          :service-type-slug="serviceTypeSlug",
          @close="showEditServiceSupportModal = false",
          @update="$emit('change')"
        )

        new-service-support(
          v-if="showNewServiceSupportModal",
          :supplier-id="supplierId",
          :service-type-slug="serviceTypeSlug",
          @close="showNewServiceSupportModal = false",
          @create="$emit('change')"
        )

        popover-menu.action-menu(
          v-if="popoverMenuAuthorization",
          :show.sync="showPopover",
          data-testid="popoverMenu"
        )

          template(v-if="isActive")
            popover-menu-button(
              v-if="$can('read', 'Supplier')",
              icon="fal fa-eye",
              :to="serviceSupportRoute",
              :text="$t('.actions.btn.show.label')",
              @close-menu="showPopover = false"
            )

            popover-menu-button(
              v-if="$can('update', 'Supplier')",
              icon="fal fa-pencil",
              :text="$t('.actions.btn.edit.label')",
              @click="showEditServiceSupportModal = true",
              @close-menu="showPopover = false",
              data-testid="editButton"
            )

            destroy-action.destroy-button.popover-menu-button(
              size="normal",
              border="square",
              :supplier-id="supplierId",
              :service-type-slug="serviceTypeSlug",
              :service-name="name",
              @click.native="showPopover = false"
              @destroy="$emit('change')",
              data-testid="deactivateButton"
            )
              .icon-content
                i.icon.fal.fa-trash-alt
              span {{ $t('.actions.btn.destroy.label') }}

          template(v-else)
            popover-menu-button(
              v-if="$can('update', 'Supplier')",
              icon="fal fa-check",
              :text="$t('.actions.btn.activate.label')",
              @click="showNewServiceSupportModal = true",
              @close-menu="showPopover = false",
              data-testid="activateButton"
            )

    slot(name="actions")

      .middle.flex.grow
        template(v-if="loading")
          loading-lines.name-loading.full-width(
            :height="48",
            :min="100",
            :max="100"
          )

        template(v-else)
          service-type-icon.service-type-icon(
            :type="supplierServiceSupport.serviceType"
          )

          router-link.service-name(:to="serviceSupportRoute")
            .name.flex.vertical-center
              span(data-testid="serviceName") {{ name }}

      .bottom.flex.space-between
        template(v-if="loading")
          loading-lines.capacity-loading.full-width(
            :height="32",
            :min="100",
            :max="100"
          )

        template(v-else)
          template(v-if="isActive")
            .capacity(data-testid="capacity")
              span.total(
                v-tooltip.bottom="$t('models.supplierServiceSupport.tooltip.totalCapacity')"
              ) {{ $t('.capacity.total', { count: supplierServiceSupport.totalCapacity }) }}

              span.hourly {{ $t('.capacity.hourly', { count: supplierServiceSupport.hourlyCapacity }) }}

          template(v-else)
            .disabled
              span {{ $t('.disabled') }}

          //- XXX: Desabilitado para outros tipos de serviço até a funcionalidade estar pronta
          app-button.show-button(
            theme="link",
            :to="serviceSupportRoute"
          )
            i.icon.far.fa-arrow-right

</template>


<script>

// Models
import models from "@/models"

// Components
import DestroyAction from "./destroy-action"
import NewServiceSupport from "../new"
import EditServiceSupport from "../edit"

const { SupplierServiceSupport } = models

export default {
  components: {
    DestroyAction,
    NewServiceSupport,
    EditServiceSupport
  },

  props: {
    loading:                { type: Boolean, default: null },
    supplierServiceSupport: { type: Object, required: true }
  },

  data() {
    return {
      i18nScope: "suppliers.service-supports.index.service-support-card",

      supplierId: this.$route.params.id,

      showPopover: false,

      showNewServiceSupportModal:  false,
      showEditServiceSupportModal: false
    }
  },

  computed: {
    popoverMenuAuthorization() {
      return this.isActive
        ? ["read", "update"].some(action => this.$can(action, "Supplier"))
        : this.$can("update", "Supplier")
    },

    name() {
      if (_.blank(this.supplierServiceSupport.serviceType)) return null

      return SupplierServiceSupport.$tEnum("serviceType", this.supplierServiceSupport.serviceType)
    },

    isActive() {
      return _.present(this.supplierServiceSupport.id)
    },

    serviceTypeSlug() {
      return this.supplierServiceSupport.typeAsParam
    },

    serviceSupportRoute() {
      if (this.supplierServiceSupport.serviceType === "Maintenance") {
        return {
          name:   "supplierServiceSupportMaintenanceVehicleModelGroups",
          params: { id: this.supplierId }
        }
      }

      return {
        path:   `service-supports/${this.serviceTypeSlug}/vehicle-model-groups`,
        params: {
          id: this.supplierId
        }
      }
    }
  }
}

</script>

<style lang="scss" scoped>

.maintenance-package-option {
  padding: 0 24px;
  min-height: 68px;
  border-top: 1px solid $light-gray-3;
  border-bottom: 1px solid $light-gray-3;
  cursor: pointer;

  &.active {
    color: $primary;
    background: $light-gray-2;
  }

  &.focus {
    background: $light-gray-2;
    color: $dark-primary-2;

    .remove-icon-content {
      background: $light-gray-3;
      color: $primary;
    }
  }
}

.maintenance-package-option + .maintenance-package-option {
  border-top: 1px solid transparent;
}

.id-tag {
  margin-right: 8px;
}

.content-column {
  flex-grow: 1;
}

.maintenance-package-name {
  font-size: 18px;
  font-weight: normal;
  color: $gray-3;
  word-break: normal;
  overflow-wrap: break-word;
  margin-bottom: 4px;
  display: block;
}

.maintenances-amount-column {
  flex-shrink: 0;
  margin-right: 16px;
  font-weight: normal;
  color: $gray-3;

  .product-icon {
    margin-right: 6px;
    font-size: 17px;
  }
}

.actions-column {
  min-width: 42px;
  margin-left: 12px;
}

.remove-icon-content {
  display: inline-block;
  width: 24px;
  height: 24px;
  line-height: 24px;
  text-align: center;
  border-radius: 50px;
  color: $gray-2;
  transition: all 100ms linear;
}

.price-column {
  flex-shrink: 0;
  display: block;
  padding-right: 24px;
}


.products-amount-column {
  flex-shrink: 0;
  font-weight: normal;
  color: $gray-3;

  .product-icon {
    margin-right: 6px;
    font-size: 17px;
  }
}
</style>


<template lang="pug">

  .maintenance-package-option.flex.vertical-center(:class="{ active, focus }")
    .content-column
      .maintenance-package-name
        id-tag.id-tag(:value="maintenancePackage.id")
        | {{ maintenancePackage.name }}

    .products-amount-column(
      v-if="!compact",
      v-tooltip.bottom="{ offset: '4', content: $t('.fields.productsCount.tooltip') }"
    )
      i.product-icon.fal.fa-cube
      label {{ maintenancePackage.productsCount }}

</template>


<script>

export default {
  name: "MaintenancePackageOption",

  props: {
    option:  { type: Object, required: true },
    active:  { type: Boolean, default: false },
    focus:   { type: Boolean, default: false },
    compact: { type: Boolean, default: false }
  },

  data() {
    return {
      i18nScope: "components.maintenance-package.maintenance-package-option"
    }
  },

  computed: {
    maintenancePackage() {
      return this.option.data
    }
  }
}

</script>

<template lang="pug">

  .vehicle-model-group-maintenance-tables-table
    index-table(
      :empty="empty",
      :sortedBy="sortedBy",
      :initializing="initializing",
      :loading="loading",
      :cols-count="5",
      :column-sizes="columnSizes"
    )

      template(#tableHeader)
        template(v-if="hasValidationError")
          span.error-message {{ errors[0] }}
        table-header(
          :loading="loading",
          @sort="param => $emit('sort', param)",
          @toggle-all="$emit('toggle-all', !allSelectedOnPage)",
          :sort-param="sortParam",
          :sort-order="sortOrder",
          :all-selected="allSelectedOnPage",
          :column-sizes="columnSizes",
          :empty="empty",
          data-testid="vehicleModelGroupsTableHeader"
        )

      template(#tableRows)
        table-row(
          v-for="vehicleModelGroup in vehicleModelGroups",
          :key="vehicleModelGroup.id",
          :vehicle-model-group="vehicleModelGroup",
          :loading="loading",
          :value="resourceValue(vehicleModelGroup.id)",
          @input="data => $emit('toggle-select', data)",
          :column-sizes="columnSizes",
          :data-testid="`vehicleModelGroupTableRow-${vehicleModelGroup.id}`"
        )

</template>


<script>

// Assets
import emptyIcon from "@/assets/images/icons/search/products/none-found.svg"

// Components
import TableRow from "./table-row.vue"
import TableHeader from "./table-header.vue"

export default {
  components: {
    TableRow,
    TableHeader
  },

  props: {
    vehicleModelGroups: { type: Array, required: true },
    initializing:       { type: Boolean, default: false },
    loading:            { type: Boolean, default: false },
    sortedBy:           { type: String, default: "" },
    value:              { type: Array, default: () => [] },
    errors:             { type: Array, default: () => [] }
  },

  data() {
    return {
      i18nScope: "ssuppliers.service-supports.service-vehicle-model-groups.new.vehicle-model-groups.table",

      emptyIcon
    }
  },

  computed: {
    empty() {
      return _.blank(this.vehicleModelGroups)
    },

    hasValidationError() {
      return _.present(this.errors)
    },

    allSelectedOnPage() {
      return !this.empty && this.vehicleModelGroups.every(group => this.value.includes(group.id))
    },

    sortParam() {
      return this.sortedBy.split(":")[0]
    },

    sortOrder() {
      return this.sortedBy.split(":")[1] || "asc"
    },

    columnSizes() {
      return "44px 67px 1fr 1fr 96px"
    }
  },

  methods: {
    resourceValue(id) {
      return this.value.includes(id)
    }
  }
}

</script>

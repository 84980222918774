<style lang="scss" scoped>

.quote-service-row {
  grid-template-columns: minmax(296px, 1fr) 40px 40px 50px 113px 122px 40px;
  gap: 8px;
  min-height: 40px;

  &.discarded {
    background-color: $light-gray-2;

    .name {
      color: $gray-2;
      text-decoration-line: line-through;
    }
  }

  .column {
    padding: 0 8px;
    height: 40px;
  }

  .name {
    font-family: $secondary-font;
    font-weight: 400;
    font-size: 14px;
  }

  .indicator.submitting {
    cursor: not-allowed;

    ::v-deep {
      .icon {
        color: $gray-3;
      }

      .data-indicator {
        background-color: $light-gray-4;
      }
    }
  }

  .value {
    font-family: $primary-monospace-font;
    font-weight: 400;
    font-size: 14px;
  }

  .action {
    height: 40px;

    &.submitting {
      .action-button {
        color: $gray-3;
        cursor: not-allowed;

        &.icon-container:hover {
          color: $gray-3;
          background-color: unset;
          cursor: not-allowed;
        }
      }
    }

    .action-button {
      height: 100%;
      width: 100%;

      &.icon-container:hover {
        width: 100%;
        background-color: $light-gray-2;
        border-radius: 50%;
        color: $orange;
        cursor: pointer;
      }
    }
  }
}

</style>


<template lang="pug">

  .quote-service-row.grid.vertical-start(:class="{ discarded }")
    .column.flex.vertical-center
      span.name {{ quoteService.service.name }}

    .column.flex.vertical-center.center
      template(v-if="hasData")
        extra-data-indicator.indicator(
          :count="quoteService.attachments.length",
          :class="{ submitting }",
          @click.native="showItemModal"
        )

      template(v-else)
        span -

    .field-column
      warranty-checkbox-field(
        v-model="quoteService.warranty",
        hide-value-text,
        name="quoteService[warranty]",
        :disabled="discarded",
        :errors="errors.warranty",
        :loading="submitting"
      )

    .field-column
      integer-field(
        v-model="quoteService.quantity",
        hide-label,
        hide-controls,
        name="quoteService[quantity]",
        :disabled="discarded",
        :errors="errors.quantity",
        :loading="submitting",
        :max="99",
        :min="1"
      )

    .field-column
      money-field(
        v-model="quoteService.price",
        hide-label,
        hide-controls,
        name="quoteService[price]",
        :disabled="discarded",
        :errors="errors.price",
        :loading="submitting",
        :max-value="99999.99",
        :show-currency="false"
      )

    .field-column
      money-field(
        v-model="quoteService.totalPrice",
        hide-label,
        hide-controls,
        readonly,
        name="quoteService[totalPrice]",
        :disabled="discarded",
        :errors="errors.totalPrice",
        :loading="submitting",
        :show-currency="false"
      )

    .action.flex.center.vertical-center(:class="{ submitting }")
      template(v-if="discarded")
        app-button.action-button(theme="link", @click="restore")
          i.icon.far.fa-arrow-rotate-right

      template(v-else)
        .icon-container.action-button.flex.center.vertical-center(@click="discard")
          i.icon.far.fa-times

    item-show(
      v-if="showItem",
      :item="quoteService",
      :name="quoteService.service.name",
      @close="showItem = false"
    )

</template>


<script>

// Components
import ItemShow from "./item-show.vue"
import WarrantyCheckboxField from "@/views/monitoring/quotes/_components/warranty-checkbox-field.vue"

export default {
  components: {
    ItemShow,
    WarrantyCheckboxField
  },

  props: {
    quoteService: { type: Object, required: true },
    submitting:   { type: Boolean, default: false },
    errors:       { type: Object, default: () => ({}) }
  },

  data() {
    return {
      i18nScope: "monitoring.quotes.reviews.quote-service-row",

      showItem: false
    }
  },

  computed: {
    discarded() {
      return _.present(this.quoteService?.discardedAt)
    },

    hasData() {
      return _.present(this.quoteService?.description) || _.present(this.quoteService?.attachments)
    }
  },

  methods: {
    showItemModal() {
      if (this.submitting) return

      this.showItem = true
    },

    discard() {
      if (this.submitting) return
      // eslint-disable-next-line vue/no-mutating-props
      this.quoteService.discardedAt = moment().toISOString()
    },

    restore() {
      if (this.submitting) return
      // eslint-disable-next-line vue/no-mutating-props
      this.quoteService.discardedAt = null
    }
  }
}

</script>

<style lang="scss" scoped>

.product-header {
  .icon {
    margin-right: 16px;
    color: $gray-3;
    width: 16px;
    text-align: center;
  }

  .discarded-icon {
    margin-right: 4px;
  }

  .tag {
    margin-left: 4px;
  }

  .name {
    font-family: $secondary-font;
    font-size: 16px;
    font-weight: 400;
  }

  .deal-product-content {
    margin-top: 8px;

    .arrow {
      color: $light-gray-4;
    }
  }
}

</style>


<template lang="pug">

  .product-header
    template(v-if="fetching")
      loading-lines(:lines="2")

    template(v-else)
      .product-content.flex.vertical-center
        product-type-icon.icon(
          :type="product.type",
          :size="16",
          weight="solid"
        )
        .flex.vertical-bottom
          discarded-icon.discarded-icon(v-if="product.$discarded")
          span.name(data-testid="productName") {{ product.name }}
          id-tag.tag(:value="product.id", data-testid="productId")
          sap-code-tag.tag(:value="product.sapId", data-testid="productSapId")

      .deal-product-content.flex.vertical-center
        i.icon.arrow.fal.fa-long-arrow-right
        i.icon.fas.fa-cars
        .flex.vertical-center
          span.name(
            data-testid="vehicleModelGroupName"
          ) {{ vehicleModelGroup.name }}
          id-tag.tag(
            :value="vehicleModelGroup.id",
            data-testid="vehicleModelGroupId"
          )

</template>


<script>

export default {
  props: {
    product:           { type: Object, default: () => ({}) },
    vehicleModelGroup: { type: Object, default: () => ({}) },
    fetching:          { type: Boolean, default: false }
  },

  data() {
    return {
      i18nScope: "suppliers.deals.products.components.product-header"
    }
  }
}

</script>

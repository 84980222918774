<!--
  <doc lang="markdown">
    Componente de uma linha para as listas em formato de tabela com ordenação, componente para ser utilizado em conjunto com
    service-vehicle-model-groups-table (`components/service-vehicle-model-groups/table.vue`), no slot `tableRows`.
    Esse componente deve ser extendido para uso, como descrito em [Computed]

    ## Props:
      supplierServiceVehicleModelGroup:   [Object]: Objeto da classe `SupplierServiceVehicleModelGroup`,

      loading:  [Boolean]: Informando se o carregamento foi concluido,
      columnSizes: [String], Valores dos tamanhos da colunas utilizadas em `grid-template-columns`

    ## Slots:
      action: Slot na última coluna de ações

    ## Computed:
    > Devem ser sobreescritos os computeds
    `serviceRoute` : Rota para a show de servicos em `service-vehicle-model-groups`
    `vehicleModelGroupRoute`: Rota para a show de grupos de veículo em `service-vehicle-model-groups`

    # Como utilizar:
      ```vue
        <template lang="pug">
          table($attrs)
            table-header($attrs)

            template(#tableRows)
              table-row($attrs)
        </template>

        <script>
        export default {
          computed: {
            serviceRoute() {
              return { name: 'supplierServiceSupportMechanicVehicleModelGroups' }
            },
            vehicleModelGroupRoute() {
              return { name: 'supplierServiceSupportMechanicVehicleModelGroups' }
            }
          }
        }
        </script>
      ```
  </doc>
-->


<style lang="scss" scoped>

.table-row {
  display: grid;
  grid-template-columns: var(--grid-columns);

  &.loading {
    .column {
      .id-tag {
        color: $gray-2;
      }

      &.actions-column {
        ::v-deep .movida-common__app-button {
          color: $gray-2;

          &:hover {
            color: $orange;
          }
        }
      }

      .text,
      .link ::v-deep .app-span {
        font-size: 14px;
        font-weight: 400;
        color: $gray-2;
      }
    }
  }

  .column {
    padding: 0 12px;
    border-bottom: 1px solid $light-gray-3;
    height: 56px;
    overflow: hidden;

    .id-tag {
      margin-left: 4px;
      font-size: 14px;
    }

    .service-description {
      padding-bottom: 2px;
      font-size: 12px;
      line-height: 12px;
      font-weight: 400;
      color: $gray-2;
    }

    &.vehicle-model-group-column {
      .vehicle-models-count {
        font-size: 12px;
        line-height: 12px;
        font-weight: 400;
        color: $gray-2;
        text-align: initial;
      }
    }

    .button {
      overflow: hidden;
    }

    .text,
    .link ::v-deep .app-span {
      font-family: $primary-font;
      font-weight: 400;
      font-size: 14px;
      color: $dark-gray;
      text-align: initial;
    }

    .link ::v-deep .app-span:hover {
      color: $orange;
    }

    .vehicle-solutions-count {
      margin-left: 4px;
    }
  }
}

</style>


<template lang="pug">

  .table-row(:class="{ loading }", :style="styles")
    .column.flex.vertical-center.center.column-direction
      service-type-icon(:type="serviceType")

    .column.flex.center.column-direction
      .flex.vertical-center
        app-span.text(:value="service.name", crop, data-testid="serviceName")
        id-tag.id-tag(:value="service.id", data-testid="serviceId")
        service-kind-icon(:kind="service.kind")

      app-span.service-description(
        crop,
        :value="service.description"
        v-tooltip.bottom="{ offset: 4, content: service.description }"
      )

    .column.vehicle-model-group-column.flex.center.column-direction
      .flex.vertical-center
        app-span.text(:value="vehicleModelGroup.name", crop, data-testid="vehicleModelGroupName")
        id-tag.id-tag(:value="vehicleModelGroup.id", data-testid="vehicleModelGroupId")

      app-span.vehicle-models-count(
        :value="$t('.vehicleModelsCount', { count: vehicleModelGroup.vehicleModelsCount })",
        data-testid="vehicleModelsCount"
      )

    .column.actions-column.flex.vertical-center
      i.fal.fa-lightbulb(v-tooltip.bottom="{ offset: 4, content: $t('.tooltip.vehicle-issue-solutions.label') }")
      span.vehicle-solutions-count {{ service.vehicleIssueSolutionsCount }}

    .column.actions-column.flex.vertical-center
      template(v-if="serviceWithCost")
        i.icon.fal.fa-fist-raised(v-tooltip.bottom="{ offset: 4, content: $t('.tooltip.labor-cost-linked.label') }")

    .column.actions-column.flex.vertical-center
      slot(name="actions")
        popover-menu(
          v-if="$can('update', 'Supplier')",
          :show.sync="showPopover",
          data-testid="popoverMenu"
        )
          destroy-action.destroy-button.popover-menu-button(
            :supplier-id="supplierId",
            :supplier-service-vehicle-model-group-id="supplierServiceVehicleModelGroup.id",
            :name="vehicleModelGroup.name",
            size="normal",
            border="square",
            @destroy="$emit('destroy')"
          )
            .icon-content
              i.icon.fal.fa-trash-alt
            span {{ $t('.actions.btn.destroy.label') }}

</template>


<script>

// Components
import DestroyAction from "./destroy-action"

export default {
  components: {
    DestroyAction
  },

  props: {
    supplierServiceVehicleModelGroup: { type: Object, required: true },
    loading:                          { type: Boolean, default: false },
    columnSizes:                      { type: String, required: true }
  },

  data() {
    return {
      i18nScope: "suppliers.service-supports.components.service-vehicle-model-groups.table-row",

      showPopover: false
    }
  },

  computed: {
    supplierId() {
      // XXX: O endpoint de `supplier-service-vehicle-model-groups` ainda não retorna o supplierId
      return this.$route.params.id
    },

    vehicleModelGroup() {
      return _.get(this.supplierServiceVehicleModelGroup, "vehicleModelGroup") || {}
    },

    service() {
      return _.get(this.supplierServiceVehicleModelGroup, "service") || {}
    },

    serviceType() {
      return _.get(this.service, "type")
    },

    serviceWithCost() {
      return _.get(this.service, "laborCostLinked")
    },

    styles() {
      return {
        "--grid-columns": this.columnSizes
      }
    }
  }
}

</script>

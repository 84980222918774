import Base from "./base"

class VehicleModelGroupMaintenanceTableSettings extends Base {
  static get $attrs() {
    return _.uniq([
      ...super.attrs,
      "kilometrageLimitStep"
    ])
  }

  static get constraints() {
    return {
      kilometrageLimitStep: {
        numericality: {
          greaterThan:       0,
          lessThanOrEqualTo: 100000,
          onlyInteger:       true
        },
        presence: true
      }
    }
  }
}

export default VehicleModelGroupMaintenanceTableSettings

// Base
import Base from "./Base"

// Models
import models from "@/models"
const { VehicleIssue } = models

class VehicleIssuesRequests extends Base {
  static get selector() {
    return "vehicleIssues"
  }

  all({ params = {}, options = {} } = {}) {
    return this.action({
      method:  "post",
      url:     "/vehicle_issues/search",
      params,
      options: {
        model: VehicleIssue,

        ...options
      }
    })
  }

  find({ vehicleIssueId, params = {}, options = {} } = {}) {
    this.verifyData("find", { vehicleIssueId })

    return this.action({
      method:  "get",
      url:     `/vehicle_issues/${vehicleIssueId}`,
      params,
      options: {
        model: VehicleIssue,

        ...options
      }
    })
  }
}

export default VehicleIssuesRequests

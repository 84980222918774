<!--

  Componente privado que representa um Nó na Árvode de OS.
  Utilizado pelo componente `./tree-node.vue`, que exibe a árvore completa da OS (principal/complementares).

  IMPORTANT: O uso fica "confuso" fora da árvore. Veja `./tree-node.vue` para o uso adequado.

  usage:
    tree-node(:value="node", :selected="node.rid == currentValue")

-->

<style lang="scss" scoped>

.tree-node {
  display: flex;
  flex-direction: row;
  align-items: center;  // vertical-align

  gap: 8px;
  padding: 4px 8px;

  border: 1px solid $light-gray-4;
  border-radius: 8px;

  cursor: pointer;

  &.selected {
    background: $light-purple;
  }

  > .icon-frame {
    display: flex;
    align-items: center;      // vertical-align
    justify-content: center;  // horizontal-align

    width: 20px;
    height: 20px;
  }

  .id-tag {
    > .hashtag,
    > .value {
      font-family: $primary-monospace-font;
      font-style:  normal;
      font-weight: 400;
      font-size:   14px;
      line-height: 150%;
    }

    > .hashtag {
      user-select: none;
    }
  }

  .subtext {
    font-family: $primary-font;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 150%;
    color: $dark-gray;
  }
}

</style>


<template lang="pug">

  router-link(:to="showServiceOrder").tree-node(:class="{ selected }")
    .icon-frame
      i.icon(:class="cssIconClass" v-tooltip="tooltipText")
    .content.flex-col
      .id-tag.flex-row.v-align-middle
        span.hashtag #
        span.value {{ id }}
      span.subtext {{ serviceKindText }}

</template>


<script>

import models from "@/models"


export default {
  components: {},
  emits:      [],

  props: {
    value:    { type: Object, required: true },  // { rid: string, remoteId: string, serviceKind: enum, state: enum }
    selected: { type: Boolean, default: false }
  },

  data() {
    return {
      i18nScope: "monitoring.service-orders.tree-node"
    }
  },

  computed: {
    node: {
      get()      { return this.value          },
      set(value) { this.$emit("input", value) }
    },

    id()          { return this.node.remoteId    },
    serviceKind() { return this.node.serviceKind },
    state()       { return this.node.state       },

    // TODO: está replicado em ./state-tag.vue, método `cssIconClassFor(state)`
    cssIconClass() {
      switch (this.state) {
        case "open":     return "fal fa-file-invoice-dollar"
        case "ongoing":  return (this.serviceKind == "purchase") ? "fal fa-truck" : "fal fa-wrench-simple"
        case "executed": return "fal fa-check"
        case "canceled": return "fal fa-times"
        default:         return "fal fa-question"
      }
    },

    serviceKindText() {
      return _.present(this.serviceKind)
        ? models.MovidaServiceOrder.$tEnum("serviceKind", this.serviceKind)
        : this.$t("placeholder.unavailable")
    },

    tooltipText() {
      return _.present(this.state)
        ? models.MovidaServiceOrder.$tEnum("state", this.state)
        : this.$t("placeholder.unavailable")
    },

    showServiceOrder() {
      return {
        name:   "monitoringServiceOrder",
        params: { serviceOrderId: this.node.rid }
      }
    }
  }
}

</script>

import Base from "./base"
import * as CPF from "@fnando/cpf"


class Person extends Base {

  static get attrs() {
    return _.uniq([
      ...super.attrs,
      "name",
      "cpf",
      "email"
    ])
  }

  static get constraints() {
    return {
      name: { presence: true },
      cpf:  { presence: true }
    }
  }


  // decorator methods
  /// ---

  get formattedCPF() {
    if (this.$blank("cpf")) return null

    return CPF.format(this.cpf)
  }

}


export default Person

<style lang="scss" scoped>

.vehicle-issue-option {
  display: grid;
  grid-template-columns: 75px 1fr 1fr 48px;
  height: 60px;

  &.active,
  &.focus {
    background-color: $light-gray-2;
  }

  &.focus {
    cursor: pointer;

    .column {
      &.actions-column {
        .remove-icon-content {
          background: $light-gray-3;
          color: $primary;
        }
      }

      .content {
        .name {
          color: $orange;
        }
      }
    }
  }

  .column {
    padding: 12px;
    overflow: hidden;

    .icon-container {
      --size: 20px;
      height: var(--size);
      width: var(--size);
      margin-right: 8px;
      --icon-color: #{$gray-2};

      .icon {
        color: var(--icon-color);
      }

      .vehicle-part-icon {
        mask-image: var(--svg-icon);
        mask-repeat: no-repeat;
        height: 16px;
        width: 16px;
        background-color: var(--icon-color);
      }
    }

    .content {
      overflow: hidden;
      font-weight: 400;

      .name {
        font-family: $secondary-font;
        font-size: 14px;
      }

      .description {
        font-size: 12px;
        color: $gray-3;
      }
    }

    &.vehicle-part-column {
      .content {
        .id {
          margin-left: 4px;
        }
      }
    }

    &.actions-column {
      .remove-icon-content {
        display: inline-block;
        width: 24px;
        height: 24px;
        line-height: 24px;
        text-align: center;
        border-radius: 50px;
        color: $gray-2;
        transition: all 100ms linear;
      }
    }
  }
}

</style>


<template lang="pug">

  .vehicle-issue-option(:class="{ focus, active }")
    .column.id-column.flex.vertical-center
      id-tag(:value="vehicleIssue.id")

    .column.vehicle-part-column.flex.vertical-center
      .icon-container.flex.center.vertical-center
        .vehicle-part-icon.icon(:style="vehiclePartIconStyle")
      .content
        .flex.vertical-center
          app-span.name(:value="vehiclePart.name", crop)
          id-tag.id(:value="vehiclePart.id")
        app-span.description(:value="vehiclePart.description", crop)

    .column.symptom-column.flex.vertical-center
      .icon-container.flex.center.vertical-center
        i.icon.fas.fa-engine-warning
      .content
        .flex.vertical-center
          app-span.name(:value="symptom.name", crop)
        app-span.description(:value="symptom.description", crop)

    .column.actions-column.flex.vertical-center
      .remove-icon-content(v-if="active")
        i.fal.fa-times

</template>


<script>

// Assets
import vehiclePartIcon from "@/assets/images/icons/sidebar/vehicle-part-icon-solid.svg"

export default {
  props: {
    vehicleIssue: { type: Object, default: () => ({}) },
    active:       { type: Boolean, default: false },
    focus:        { type: Boolean, default: false }
  },

  data() {
    return {
      i18nScope: "tickets.drafts.contents.services.components.vehicle-issue-option"
    }
  },

  computed: {
    vehiclePart() {
      return _.get(this.vehicleIssue, "vehiclePart") || {}
    },

    symptom() {
      return _.get(this.vehicleIssue, "symptom") || {}
    },

    vehiclePartIconStyle() {
      return {
        "--svg-icon": `url(${vehiclePartIcon})`
      }
    }
  }
}

</script>

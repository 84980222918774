import { i18n } from "utils.vue"
import Base from "./base"

class VehicleModelGroupMaintenanceTable extends Base {
  static get attrs() {
    return _.uniq([
      ...super.attrs,
      "name",
      "description",
      "kilometrageLimitTolerance",
      "timeLimitTolerance",
      "maintenancesCount",
      "kind"
    ])
  }

  static get relations() {
    return {
      vehicleModelGroupMaintenances: { type: "hasMany", model: "VehicleModelGroupMaintenance" },
      vehicleModelGroup:             { type: "belongsTo", model: "VehicleModelGroup" }
    }
  }

  static get enums() {
    return {
      kind: {
        dealership:   "dealership",
        multiservice: "multiservice"
      }
    }
  }

  static get constraints() {
    return {
      name:        { presence: true, type: "string", length: { maximum: 1000 } },
      description: { type: "string", length: { maximum: 10000 } },
      // maintenances: { presence: true },

      kilometrageLimitTolerance: {
        numericality: {
          greaterThan: 0
        }
      },

      timeLimitTolerance: {
        numericality: {
          greaterThan: 0
        }
      }
    }
  }

  get formattedKilometrageLimitTolerance() {
    if (_.blank(this.kilometrageLimitTolerance)) return null

    const kilometrage = i18n.l("number", this.kilometrageLimitTolerance, { precision: 0 })

    return i18n.t("models.vehicleModelGroupMaintenanceTable.formattedAttributes.kilometrageLimitTolerance", {
      kilometrage
    })
  }

  get formattedTimeLimitTolerance() {
    if (_.blank(this.timeLimitTolerance)) return null

    return i18n.t("models.vehicleModelGroupMaintenanceTable.formattedAttributes.timeLimitTolerance", {
      count: this.timeLimitTolerance
    })
  }
}

export default VehicleModelGroupMaintenanceTable

<style scoped lang="scss">

.supplier-row {
  display: grid;
  grid-template-columns: var(--grid-columns);

  &.loading {
    .column {
      &.id-column {
        .id-tag {
          color: $gray-2;
        }
      }

      &.actions-column {
        ::v-deep .movida-common__app-button {
          color: $gray-2;

          &:hover {
            color: $orange;
          }
        }
      }

      .text,
      .link ::v-deep .app-span {
        color: $gray-2;
      }
    }
  }

  .column {
    padding: 0 12px;
    border-bottom: 1px solid $light-gray-3;
    height: 48px;
    overflow: hidden;

    .cnpj {
      font-size: 14px;
      font-weight: 400;
      font-family: $primary-monospace-font;
      white-space: nowrap;
    }

    .ability {
      margin-right: 4px;
    }

    &.models-count-column {
      .text {
        font-family: $secondary-font;
      }

      .car-icon {
        margin-right: 8px;
        color: $gray-2;
      }
    }

    ::v-deep .movida-common__app-button {
      white-space: unset;
    }

    .button {
      overflow: hidden;
    }

    .text,
    .link ::v-deep .app-span {
      font-family: $primary-font;
      font-weight: 400;
      font-size: 14px;
      color: $dark-gray;
      text-align: initial;
    }

    .link ::v-deep .app-span:hover {
      color: $orange;
    }
  }
  .rabbot-row-enabled {
    background: #fad390;
  }
}

</style>


<template lang="pug">

  .supplier-row(:class="{ loading }", :style="styles")
    .column.id-column.flex.vertical-center(:class="{ 'rabbot-row-enabled': usePlatform }", :style="styles")
      id-tag.id-tag(
        :value="supplier.id",
        data-testid="supplierId"
      )
    .column.flex.vertical-center.center(:class="{ 'rabbot-row-enabled': usePlatform }", :style="styles")
      avatar(:size="40", :border="2", placeholder="fas fa-user-hard-hat")

    .column.flex.vertical-center(:class="{ 'rabbot-row-enabled': usePlatform }", :style="styles")
      supplier-block-preview-icon(
        :id="supplierBlockId",
        :state="supplierBlockState"
      )
      app-button.button(
        theme="link",
        :to="showRoute"
      )
        app-span.link(
          :value="supplier.tradingName",
          crop,
          data-testid="supplierTradingName"
        )

    .column.cnpj-column.flex.vertical-center(:class="{ 'rabbot-row-enabled': usePlatform }", :style="styles")
      span.cnpj(data-testid="supplierCnpj") {{ supplier.cnpj }}

    .column.classification-column.flex.vertical-center.center(:class="{ 'rabbot-row-enabled': usePlatform }", :style="styles")
      supplier-classification-badge(:value="supplier.classification")

    .column.address-column.flex.vertical-center(:class="{ 'rabbot-row-enabled': usePlatform }", :style="styles")
      supplier-hierarchy-icon(:value="supplier.hierarchy")
      app-span.text(
        :value="supplier.address.addressLine1",
        crop,
        data-testid="supplierAddress"
      )

    .column.ac-column.flex.vertical-center(:class="{ 'rabbot-row-enabled': usePlatform }", :style="styles")
      deal-preview-icon(
        :id="supplier.currentDealId",
        :expires-soon="supplier.currentDealExpiresSoon"
      )
    .column.typeSupplier.flex.vertical-center(:class="{ 'rabbot-row-enabled': usePlatform }", :style="styles")
          app-span.text(
            v-if="supplier.supplierFacilities == true  && supplier.internalFacilities == false",
            value="Externo",
            data-testid="typeSupplier"
          )
          app-span.text(
            v-else-if="supplier.internalFacilities == true && supplier.supplierFacilities == false",
            value="Interno",
            data-testid="typeSupplier"
          )
          app-span.text(
            v-else-if="supplier.internalFacilities == true && supplier.supplierFacilities == true",
            value="Ambos",
            data-testid="typeSupplier"
          )
          app-span.text(
            v-else,
            value="Não config.",
            data-testid="typeSupplier"
          )
    .column.actions-column.flex-vertical-center.flex(:class="{ 'rabbot-row-enabled': usePlatform }", :style="styles")
        popover-menu(
          v-if="popoverMenuAuthorization",
          :show.sync="showPopover",
          data-testid="popoverMenu"
        )
          slot(
            name="actions-menu",
            :supplier="supplier"
          )
            popover-menu-button(
              v-if="$can('read', 'Supplier')",
              icon="fal fa-eye",
              :to="showRoute",
              :text="$t('.actions.btn.show.label')",
              @close-menu="showPopover = false"
            )

            popover-menu-button(
              v-if="$can('update', 'Supplier')",
              icon="fal fa-pencil",
              :to="{ name: 'editSupplier', params: { id: supplier.id }}",
              :text="$t('.actions.btn.edit.label')",
              @close-menu="showPopover = false"
            )

            unblock-supplier.popover-menu-button(
              v-if="$can('update', 'Supplier') && supplier.blocked",
              :id="supplier.id",
              :name="supplier.name",
              @unblocked="$emit('modified')"
            )
              template(#link="{ props: { openUnblockModal } }")
                app-button.popover-menu-button.unblock-button(
                  theme="link",
                  border="square",
                  @click="[showPopover = false, openUnblockModal()]"
                )
                  .button-menu.flex.vertical-center.grow
                    .icon-content
                      i.icon.fal.fa-check-circle
                    span {{ $t('.actions.btn.unblock.label') }}

            block-supplier.popover-menu-button(
              v-else-if="$can('update', 'Supplier')",
              :supplier="supplier",
              @blocked="$emit('modified')"
            )
              template(#link="{ props: { openBlockForm } }")
                app-button.popover-menu-button.block-button(
                  theme="link",
                  border="square",
                  @click="[showPopover = false, openBlockForm()]"
                )
                  .button-menu.flex.vertical-center.grow
                    .icon-content
                      i.icon.fal.fa-times-circle
                    span {{ $t('.actions.btn.block.label') }}
</template>

<script>
// Components
import BlockSupplier from "../components/block-supplier"
import UnblockSupplier from "../components/unblock-supplier"
import SupplierBlockPreviewIcon from "../components/supplier-block-preview-icon.vue"
import DealPreviewIcon from "../components/deal-preview-icon.vue"

// import DisableAction from '../components/disable-action'
// import EnableAction from '../components/enable-action'

export default {
  components: {
    BlockSupplier,
    UnblockSupplier,
    SupplierBlockPreviewIcon,
    DealPreviewIcon
  //   DestroyAction,
  //   DisableAction,
  //   EnableAction
  },

  props: {
    supplier: { type: Object, default: () => ({}) },
    loading:  { type: Boolean, default: false },

    columnSizes: { type: String, required: true }
  },

  data() {
    return {
      i18nScope: "suppliers.index.supplier-row",

      showPopover: false
    }
  },

  computed: {
    popoverMenuAuthorization() {
      return ["read", "update"].some(action => this.$can(action, "Supplier"))
    },

    showRoute() {
      return {
        name:   "supplier",
        params: {
          id: this.supplier.id
        }
      }
    },

    supplierBlockId() {
      return this.supplier.currentBlockId || this.supplier.nextBlockId
    },

    supplierBlockState() {
      return this.supplier.currentBlockState || this.supplier.nextBlockState
    },

    styles() {
      return {
        "--grid-columns": this.columnSizes
      }
    },

    usePlatform() {
      return this.supplier.rabbotPlataform
    },
    useMovidaApp() {
      return this.supplier.movidaApp
    }
  }
}

</script>

<style scoped lang="scss">

.edit-product {
  padding-bottom: 24px;
}

.header {
  padding-bottom: 16px;
  border-bottom: 1px solid $light-gray-3;
}

.title {
  font-size: 30px;
  font-weight: 500;
  font-family: $secondary-font;
}

.product-id {
  font-size: 30px;
  font-weight: 400;
  font-family: $secondary-font;
  color: $gray-3;
  padding-left: 8px;
}

.type-section {
  border-bottom: 1px solid $light-gray-3;
  padding: 24px 0;
}

.subtitle {
  font-weight: bold;
  font-size: 20px;
  margin-bottom: 24px;
  font-family: $secondary-font;
  font-weight: 500;
}

.form-section {
  padding-top: 24px;
}

.empty {
  display: block;
}

.fields-content {
  margin-bottom: 24px;
}

.sap-input {
  width: 250px;
}

.id-tag {
  margin: 0 8px;
}

</style>


<template lang="pug">

  .edit-product
    loading-lines.header(v-if="fetching", :height="36", :max="50")
    header.header.flex.vertical-center(v-else)
      h1.title {{ $t('.title', { type: typeText }) }}
      id-tag.id-tag(:value="resource.id", size="big")

    form.form(ref="form", method="post", @submit.prevent="submit", data-test="form")

      section.type-section

        ui-row
          ui-col(:span="12")
            input-field.sap-input.float-right(
              name="product[sap_id]",
              :loading="submitting",
              :disabled="fetching",
              v-model="resource.sapId",
              :errors="errors.sapId",
              mask="4000000000",

              :placeholder="$t('.fields.sapId.placeholder')"
            )

      section.form-section

        h2.subtitle {{ $t('.form.subtitle') }}

        template(v-if="fetching")
          loading-lines(:lines="5")

        template(v-if="!fetching && fieldsComponent")

          component.fields-content(
            :is="fieldsComponent",
            :loading="submitting",
            :errors="errors",
            :resource="resource"
          )

          section.actions.flex.space-between.vertical-center
            app-button(
              :to="{ name: 'product', params: { id: productId } }",
              type="link",
              outline,
              theme="gray",
              :loading="submitting"
            )
              span {{ $t('btn.cancel') }}

            app-button(type="submit", :loading="submitting", data-test="submitButton")
              span {{ $t('btn.update.label') }}
              span(slot="loading") {{ $t('btn.update.loading') }}

</template>


<script>

// Mixins
import FetchMixin from "@/mixins/fetch-mixin"
import FormMixin from "@/mixins/form-mixin"

// View
import LoadedView from "@/views/loaded-view"

// Product forms
import PartFields from "./forms/part-fields"
import MotorOilFields from "./forms/motor-oil-fields"
import TireFields from "./forms/tire-fields"
import ProductFields from "./forms/product-fields"

// Models
import models from "@/models"
const { Product } = models

export default {
  name: "EditProduct",

  components: {
    PartFields,
    MotorOilFields,
    TireFields,
    ProductFields
  },

  extends: LoadedView,

  mixins: [FetchMixin, FormMixin],

  data() {
    return {
      i18nScope: "products.edit",
      routeName: "editProduct",

      Product,

      productId: null,

      resource: new Product()
    }
  },

  computed: {
    resourceType() {
      return _.get(this.resource, "type")
    },

    typeText() {
      return Product.$tEnum("type", this.resourceType)
    },

    allowedComponents() {
      return {
        Part:     "part-fields",
        MotorOil: "motor-oil-fields",
        Tire:     "tire-fields",
        Product:  "product-fields"
      }
    },

    fieldsComponent() {
      const productComponent = this.allowedComponents[this.resourceType]

      if (_.blank(productComponent)) return null

      return productComponent
    }
  },

  methods: {
    // @override Loaded view
    parseRoute() {
      this.productId = this.$params.asInteger(this.$route.params.id)
    },

    // @override Fetch mixin
    fetchRequest() {
      return this.$newSdk.products.find({ productId: this.productId })
    },

    // @override Fetch mixin
    onFetchSuccess({ data }) {
      // XXX: STI model class - procurando no mapa em `models` => `models["Tire"]`
      const ModelClass = models[data.type]

      this.resource = new ModelClass(data)

      this.initialSerializedAttributes = this.serializeAttributes()
    },

    // @override Form mixin
    serializeAttributes() {
      return this.resource.$serialize({ virtuals: true })
    },

    // @override Form mixin
    submitRequest() {
      return this.$newSdk.products.update({ productId: this.productId, params: this.serializeAttributes() })
    },

    // @override Form mixin
    onSubmitSuccess() {
      this.$notifications.info(
        this.$t(".notifications.submit.success", {
          name: this.resource.productName,
          type: this.typeText
        })
      )

      let { id } = this.resource
      this.$router.push({ name: "product", params: { id } })
    }
  }
}

</script>

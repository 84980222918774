import { i18n } from "utils.vue"
import Base from "./base"

class DealMaintenancePackage extends Base {
  static get attrs() {
    return _.uniq([
      ...super.attrs,
      "laborPrice",
      "maintenancePackageId",
      "vehicleModelGroupId",
      "dealId",

      "detached"
    ])
  }

  static get relations() {
    return {
      deal:               { type: "belongsTo", model: "Deal" },
      supplier:           { type: "hasOne", model: "Supplier" },
      maintenancePackage: { type: "belongsTo", model: "MaintenancePackage" },
      vehicleModelGroup:  { type: "belongsTo", model: "VehicleModelGroup" }
    }
  }

  static get constraints() {
    return {
      laborPrice:           { presence: true, numericality: { greaterThanOrEqualTo: 0 } },
      maintenancePackageId: { presence: true },
      vehicleModelGroupId:  { presence: true }
    }
  }

  get formattedPrice() {
    if (_.blank(this.laborPrice)) return null

    return i18n.l("currency", this.laborPrice, { unit: "R$" })
  }
}

export default DealMaintenancePackage

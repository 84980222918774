import Base from "../base"

import { BUSINESS_UNITS, RAC_BUSINESS_UNITS } from "@/enums/business-units"


class MovidaContract extends Base {

  static get attrs() {
    return _.uniq([
      ...super.attrs,
      "status",
      "businessUnit"
    ])
  }

  static get enums() {
    return {
      businessUnit: BUSINESS_UNITS.all
    }
  } // enums


  // predicates
  // ---
  // TODO: pensar, dada a variedade de BUSINESS_UNITS, como implementar predicates adequados.
  // get isRACPlus() {
  //   return RAC_BUSINESS_UNITS.has(this.businessUnit)
  // }

}


export default MovidaContract

import Base from "./Base"

class Auth extends Base {

  login({ params = {} } = {}) {
    return this.action({
      method: "post",
      url:    "/oauth/token",
      params: { grant_type: "password", ...params }
    })
  }

  logout() {
    return this.action({
      method: "delete",
      url:    "/oauth/token"
    })
  }
}

export default Auth

// Base
import Role from "./role"

// Models
import models from "@/models"


class MonitoringAssistantRole extends Role {
  static build(user, options = {}) {
    return this.define((can, cannot) => {

      can("manage", models.MovidaServiceOrder)
      can("manage", models.Quote)
      // can("manage", models.VehicleReceipt)

    })
  }
}


export default MonitoringAssistantRole

<style lang="scss" scoped>

.service-selected-option {
  .type-icon {
    margin: 0 8px 0 12px;
  }

  .name {
    font-family: $secondary-font;
    font-weight: 400;
    font-size: 14px;
  }
}

</style>


<template lang="pug">

  .service-selected-option.flex.vertical-center
    id-tag.id(:value="service.id")
    service-type-icon.type-icon(:type="service.type", :size="20")
    app-span.name(:value="service.name", crop)

</template>


<script>

export default {
  name: "ServiceSelectedOption",

  props: {
    service: { type: Object, default: () => ({}) }
  },

  data() {
    return {
      i18nScope: "components.service.service-selected-option"
    }
  }
}

</script>

<style lang="scss" scoped>

.last-tickets-preview {
  .upper-row,
  .results {
    margin-bottom: 8px;
  }

  .upper-row {
    .title,
    .all {
      font-family: $secondary-font;
      font-weight: 500;
    }

    .title {
      font-size: 18px;
    }

    .all {
      font-size: 14px;
      color: $dark-gray;

      &:hover {
        color: $orange;
      }

      .icon {
        margin-right: 4px;
      }
    }
  }

  .retry {
    margin-left: 4px;
  }

  .results {
    display: inline-block;
    font-weight: 400;
    font-size: 14px;
  }

  .table {
    background-color: $light-gray;
    border-radius: 8px;

    .service-order-row + .service-order-row {
      border-top: 1px solid $light-gray-3;
    }
  }
}

</style>


<template lang="pug">

  .last-tickets-preview
    .upper-row.flex.vertical-center.space-between
      h2.title {{ $t('.title') }}
      template(v-if="!empty")
        app-button.all(theme="link", @click="showModal = true")
          i.icon.far.fa-history
          span.text {{ $t('.all.label') }}

    template(v-if="fetching")
      loading-lines(:lines="4")

    template(v-else-if="erred")
      span.error-message {{ $t('.retry.message') }}
      app-button(theme="link", @click="fetch")
        span.retry {{ $t('.retry.link') }}

    template(v-else)
      span.results {{ $t('.results', { count: serviceOrders.length }) }}

      .table
        template(v-for="serviceOrder in serviceOrders")
          service-order-row.service-order-row(
            :key="serviceOrder.id",
            :service-order="serviceOrder",
            :loading="fetching",
            @select="selectServiceOrder"
          )

    last-tickets(
      v-if="showModal",
      :vehicle="vehicle",
      :service-order-id.sync="chosenServiceOrderId"
      @close="closeModal"
    )

</template>


<script>

// Mixins
import FetchMixin from "@/mixins/fetch-mixin"

// Components
import ServiceOrderRow from "./last-tickets-preview/service-order-row.vue"
import LastTickets from "./last-tickets-modal.vue"

export default {
  components: {
    ServiceOrderRow,
    LastTickets
  },

  mixins: [FetchMixin],

  props: {
    vehicle: { type: Object, required: true }
  },

  data() {
    return {
      i18nScope: "tickets.drafts.vehicles.components.last-tickets-preview",

      resource: [],

      showModal: false,

      chosenServiceOrderId: null
    }
  },

  computed: {
    serviceOrders: {
      get() { return this.resource },

      set(value) { this.resource = value }
    },

    empty() {
      return _.blank(this.resource)
    }
  },

  methods: {
    // @override Fetch mixin
    fetchRequest() {
      const params = { limit: 6 }

      return this.$newSdk.vehicles.serviceOrders.all({ vehicleId: this.vehicle.id, params })
    },

    // @override Fetch mixin
    onFetchSuccess({ data }) {
      // XXX: Limitando o numero exibido para N OS's
      this.resource = _.take(data, 6)
    },

    selectServiceOrder(serviceOrderId) {
      this.chosenServiceOrderId = serviceOrderId
      this.showModal = true
    },

    closeModal() {
      this.chosenServiceOrderId = null
      this.showModal = false
    }
  }
}

</script>

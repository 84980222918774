<template lang="pug">

  .narrow-attachment-card(:style="cssVars")
    .container(
      :class="{ uploaded }",
      @click="onShow"
    )
      .actions.flex.vertical-center.no-shrink(@click.stop="")
        .action.flex.center.vertical-center(
          v-if="canDownload",
          :class="{ downloading }",
          @click="onDownload"
        )
          i.icon.far.fa-download

        .action.flex.center.vertical-center(
          v-if="canEdit",
          @click="onEdit"
        )
          i.icon.far.fa-pencil

        .action.flex.center.vertical-center(
          v-if="canDelete",
          @click="onDestroy"
        )
          i.icon.far.fa-trash-alt

      .content.flex.vertical-center
        .icon-container.flex.center.vertical-center.no-shrink
          i.icon.fas(
            v-tooltip="Attachment.$tEnum('fileType', attachment.fileType)",
            :class="{ [attachment.icon]: true }"
          )

        .content-container.flex.vertical-center
          .info-container.flex.vertical-center.no-shrink
            template(v-if="computingChecksum")
              loading-lines.checking(:height="8", :min="100", :max="100")

            template(v-else-if="uploading")
              .progress-bar(:style="progressStyle")
                .progress

            template(v-else)
              app-span.name(crop, :value="attachment.name")

    quote-attachment-preview(
      v-if="persisted && ownPreview && showPreviewModal",
      :attachment="attachment",
      :editable="editable",
      :editing.sync="editing",
      :quote-id="quoteId",
      @close="onClosePreview",
      @download="onDownload"
    )

</template>


<script>

// Extends
import NarrowAttachmentCard from "./narrow-attachment-card.vue"

// Models
import models from "@/models"
const { Attachment } = models

export default {
  name: "NarrowQuoteAttachmentCard",

  extends: NarrowAttachmentCard,

  props: {
    quoteId: { type: [String, Number], required: true }
  },

  data() {
    return {
      Attachment
    }
  },

  methods: {
    destroyRequest() {
      throw new Error("NarrowQuoteAttachmentCard - #destroyRequest() - Not implemented yet")
    },

    downloadRequest() {
      return this.$newSdk.quotes.blobs.getDownloadURL({ quoteId: this.quoteId, blobId: this.attachment.blobId, params: { disposition: "attachment" } })
    }
  }
}

</script>

import AdminRole   from "./admin-role"
import AuditorRole from "./auditor-role"
import UserRole    from "./user-role"
import FleetAssistantRole        from "./fleet-assistant-role"
import MonitoringAssistantRole   from "./monitoring-assistant-role"
import MonitoringCoordinatorRole from "./monitoring-coordinator-role"


// map<roleName, roleClass>
const roles = [
  AdminRole,
  AuditorRole,
  FleetAssistantRole,
  MonitoringAssistantRole,
  MonitoringCoordinatorRole,
  UserRole
].reduce(
  (map, role) => {
    map[role.roleName] = role
    return map
  },
  {}
)


Object.freeze(roles)

export default roles

<style lang="scss" scoped>

.vehicle-model-group-maintenance-tables-table {
  .skeleton-table {
    margin-top: 16px;

    .skeleton-row + .skeleton-row {
      margin-top: 24px;
    }
  }

  .error-message {
    color: $error;
    font-size: 12px;
    line-height: 1;
  }

  .empty {
    margin-top: 24px;

    .message {
      font-style: italic;
      font-weight: 400;
    }

    .icon {
      user-select: none;
      margin-bottom: 4px;
      height: 88px;
      width: 88px;
    }
  }

  .list {
    margin-top: 16px;
  }
}

</style>


<template lang="pug">

  .vehicle-model-group-maintenance-tables-table
    template(v-if="initializing")
      .skeleton-table
        vehicle-model-group-maintenance-table-row-skeleton.skeleton-row(
          v-for="i in 5",
          :key="`vehicle-model-group-maintenance-tables-row-skeleton-${i}`"
        )

    template(v-else)
      template(v-if="hasValidationError")
        span.error-message {{ errors[0] }}
      vehicle-model-group-maintenance-tables-table-header(
        :loading="loading",
        @sort="param => $emit('sort', param)",
        @toggle-all="$emit('toggle-all', !allSelectedOnPage)",
        :sort-param="sortParam",
        :sort-order="sortOrder",
        :all-selected="allSelectedOnPage",
        :empty="empty",
        data-testid="vehicleModelGroupMaintenanceTablesTableHeader"
      )

      template(v-if="empty")
        .empty.flex.center
          .flex.column-direction.vertical-center
            img.icon(:src="emptyIcon")
            span.message {{ $t('index.empty.message') }}

      template(v-else)
        vehicle-model-group-maintenance-table-row(
          v-for="vehicleModelGroupMaintenanceTable in vehicleModelGroupMaintenanceTables",
          :key="vehicleModelGroupMaintenanceTable.id",
          :vehicle-model-group-maintenance-table="vehicleModelGroupMaintenanceTable",
          :loading="loading",
          :value="resourceValue(vehicleModelGroupMaintenanceTable.id)",
          @input="data => $emit('toggle-select', data)",
          :data-testid="`vehicleModelGroupMaintenanceTableRow-${vehicleModelGroupMaintenanceTable.id}`"
        )

</template>


<script>

// Assets
import emptyIcon from "@/assets/images/icons/search/products/none-found.svg"

// Components
import VehicleModelGroupMaintenanceTableRow from "./vehicle-model-group-maintenance-table-row.vue"
import VehicleModelGroupMaintenanceTableRowSkeleton from "./vehicle-model-group-maintenance-table-row-skeleton"
import VehicleModelGroupMaintenanceTablesTableHeader from "./vehicle-model-group-maintenance-tables-table-header.vue"

export default {
  components: {
    VehicleModelGroupMaintenanceTableRow,
    VehicleModelGroupMaintenanceTableRowSkeleton,
    VehicleModelGroupMaintenanceTablesTableHeader
  },

  props: {
    vehicleModelGroupMaintenanceTables: { type: Array, required: true },
    initializing:                       { type: Boolean, default: false },
    loading:                            { type: Boolean, default: false },
    sortedBy:                           { type: String, default: "" },
    value:                              { type: Array, default: () => [] },
    errors:                             { type: Array, default: () => [] }
  },

  data() {
    return {
      i18nScope: "suppliers.service-supports.maintenance.vehicle-model-groups.index.vehicle-model-group-maintenance-tables-table",

      emptyIcon
    }
  },

  computed: {
    empty() {
      return _.blank(this.vehicleModelGroupMaintenanceTables)
    },

    hasValidationError() {
      return _.present(this.errors)
    },

    allSelectedOnPage() {
      return !this.empty && this.vehicleModelGroupMaintenanceTables.every(table => this.value.includes(table.id))
    },

    sortParam() {
      return this.sortedBy.split(":")[0]
    },

    sortOrder() {
      return this.sortedBy.split(":")[1] || "asc"
    }
  },

  methods: {
    resourceValue(id) {
      return this.value.includes(id)
    }
  }
}

</script>

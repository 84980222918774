<template lang="pug">

  index-table.vehicles-table(
    :empty="empty",
    :initializing="initializing",
    :cols-count="6",
    :column-sizes="columnSizes"
  )
    template(#tableHeader)
      vehicles-table-header(
        :loading="loading",
        :sort-param="sortParam",
        :sort-order="sortOrder",
        :column-sizes="columnSizes",
        :request-query-params="requestQueryParams",
        @sort="param => $emit('sort', param)"
        data-testid="vehiclesTableHeader"
      )

    template(#tableRows)
      vehicle-row(
        v-for="vehicle in vehicles",
        :key="`vehicle-${vehicle.id}`",
        :vehicle="vehicle",
        :loading="loading",
        :column-sizes="columnSizes",
        :service-order-state-options-filter="serviceOrderStateOptionsFilter",
        data-testid="vehicleRow"
      )

</template>


<script>

// Assets
import emptyIcon from "@/assets/images/icons/search/products/none-found.svg"

// Components
import VehicleRow from "./vehicle-row.vue"
import VehiclesTableHeader from "./vehicles-table-header.vue"

export default {
  components: {
    VehicleRow,
    VehiclesTableHeader
  },

  props: {
    vehicles:                       { type: Array, required: true },
    initializing:                   { type: Boolean, default: false },
    loading:                        { type: Boolean, default: false },
    sortedBy:                       { type: String, default: "" },
    requestQueryParams:             { type: Object, default: () => ({}) },
    serviceOrderStateOptionsFilter: { type: Array, default: null }
  },

  data() {
    return {
      i18nScope: "vehicles.index.vehicles-table",

      emptyIcon
    }
  },

  computed: {
    empty() {
      return _.blank(this.vehicles)
    },

    columnSizes() {
      return "190px 99px 1fr 75px 1fr 64px"
    },

    sortParam() {
      return this.sortedBy.split(":")[0]
    },

    sortOrder() {
      return this.sortedBy.split(":")[1] || "asc"
    }
  }
}

</script>

<style lang="scss" scoped>

.edit {
  .back {
    font-size: 14px;
    margin-bottom: 12px;

    .icon {
      font-size: 12px;
      margin-right: 4px;
    }
  }

  .date {
    border: 1px solid $light-gray-3;
    border-radius: 4px;
    padding: 16px;
    width: 330px;
    height: 95px;
  }

  .pickup-service {
    margin-top: 16px;
    border: 1px solid $light-gray-3;
    border-radius: 4px;
    padding: 8px 16px;

    .icon-container {
      width: 24px;
      height: 24px;
      font-size: 16px;
      margin-right: 8px;
      color: $gray-3;
    }

    .value-text {
      color: $dark-gray;
      font-weight: 400;
    }
  }
}

</style>


<template lang="pug">

  .edit
    app-button.back(theme="link", @click="$emit('back')")
      i.icon.fas.fa-arrow-left
      span {{ $t('.back') }}

    template(v-if="fetching")
      loading-lines(:lines="5")

    template(v-else)
      .flex.space-between
        supplier-info-card(
          :service-order-supplier="serviceOrderSupplier",
          :show-badge="showBadge"
        )

        .right-pane
          supplier-available-date.date(:timestamp="availableDate")

          toggle-field.pickup-service(
            v-model="resource.pickupService",
            name="SupplierReservation[pickupService]",
            hide-label,
            :disabled="submitting",
            :errors="errors.pickupService"
          )
            template(#optionText="{ props: { inputId, disabled, error: hasErrors } }")
              label.checkbox-label.flex.vertical-center(
                :for="inputId",
                :class="{ disabled, error: hasErrors }"
              )
                .icon-container.flex.vertical-center.center
                  i.icon.fas.fa-people-arrows

                span.value-text {{ $t('.fields.pickupService.label') }}

</template>


<script>

// Mixins
import FetchMixin from "@/mixins/fetch-mixin"

// Components
import SupplierInfoCard from "../components/supplier-info-card.vue"
import SupplierAvailableDate from "../edit/supplier-available-date.vue"

export default {
  components: {
    SupplierInfoCard,
    SupplierAvailableDate
  },

  mixins: [FetchMixin],

  props: {
    serviceOrderId: { type: [String, Number], required: true },
    supplierId:     { type: [String, Number], required: true },
    showBadge:      { type: Boolean, required: true },
    startingAt:     { type: String, required: true },
    resource:       { type: Object, required: true },
    errors:         { type: Object, required: true },
    submitting:     { type: Boolean, default: false }
  },

  data() {
    return {
      i18nScope: "tickets.drafts.bookings.suppliers.edit",

      serviceOrderSupplier: {}
    }
  },

  computed: {
    availableDate() {
      return _.get(this.serviceOrderSupplier, "supplierAvailableAt.startAt")
    }
  },

  methods: {
    // @override Fetch mixin
    fetchRequest() {
      return this.$newSdk.serviceOrders.availableSuppliers.find({
        serviceOrderId: this.serviceOrderId,
        supplierId:     this.supplierId,

        params: { starting_at: this.startingAt }
      })
    },

    onFetchError(err) {
      if (!err.cancelled) {
        this.$emit("back")

        if (err.statusCode === 409) this.$notifications.error(this.$t(".notifications.fetch.conflict"))
        else {
          this.$err.log(err)
          this.$notifications.error(this.$t(".notifications.fetch.failure"))
        }
      }
    },

    // @override Fetch mixin
    onFetchSuccess({ data }) {
      this.serviceOrderSupplier = data

      this.$emit("load", this.serviceOrderSupplier)
    }
  }
}

</script>

<style scoped lang="scss">

.show {
  padding-bottom: 24px;
}

.header {
  padding: 24px 0;
  border-bottom: 1px solid $light-gray-3;
  margin-bottom: 24px;
}

.vehicle-model-name {
  font-family: $secondary-font;
  font-size: 30px;
  font-weight: 500;
  word-break: normal;
  overflow-wrap: break-word;
}

.id-tag {
  margin: 0 8px;
}

.btn + .btn {
  margin-left: 8px;
}

.icon {
  font-size: 16px;
  margin-right: 8px;
}

.section {
  padding-bottom: 24px;
  margin-bottom: 24px;
  border-bottom: 1px solid $light-gray-3;
}

.section-title {
  font-family: $secondary-font;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  padding-bottom: 24px;
}

.info-label + .info-label {
  margin-left: 40px;
}

.subtitle {
  white-space: normal;
  text-align: left;
}

.subsection {
  margin-bottom: 24px;

  .subtitle {
    display: block;
    margin-bottom: 8px;
  }
}

</style>


<template lang="pug">

  .show
    loading-lines(v-if="fetching", :lines="4")
    template(v-else)
      header.header.flex.space-between.vertical-center
        h2.vehicle-model-name(data-test="name")
          span {{ resource.displayName }}
          id-tag.id-tag(:value="resource.id", size="big")
          fipe-code-tag.fipe-tag(:value="fipeId", size="big")

        .actions.flex
          destroy-action.btn(
            :id="resource.id",
            :name="resource.displayName",
            @destroy="onDestroy",
            data-test="destroyAction"
          )
            i.fal.fa-trash-alt.icon
            span {{ $t('.btn.destroy') }}

          app-button.btn(
            v-if="$can('create', 'VehicleModel')",
            theme="neutral",
            size="small",
            :to="{ name: 'newVehicleModel', query: { dup: id }}"
          )
            i.fal.fa-clone.icon
            span {{ $t('.btn.duplicate') }}

          app-button.btn(
            v-if="$can('update', 'VehicleModel')",
            theme="neutral",
            size="small",
            :to="{ name: 'editVehicleModel', params: { id }}"
          )
            i.fal.fa-pencil.icon
            span {{ $t('.btn.edit') }}

      section.section.identification
        .flex
          info-label.info-label(
            :loading="fetching",
            :label="$t('models.vehicleModel.attributes.category')",
            :value="$t(`models.vehicleModel.enums.category.${resource.category}`)"
          )
          info-label.info-label(
            :loading="fetching",
            :label="$t('models.vehicleModel.attributes.type')",
            :value="$t(`models.vehicleModel.enums.typePowerSource.${resource.type}`)"
          )

      template(v-if="showCombustionFields")
        section.section.fuel-and-oil
          h2.section-title {{ $t('.sections.fuelAndOil.title') }}
          .flex
            info-label.info-label(
              :loading="fetching",
              :label="$t('.fields.fuel-and-oil.fuel.label')",
              :value="VehicleModelCombustion.$tEnum('fuel', resource.fuel)"
            )
            info-label.info-label(
              :loading="fetching",
              :label="$t('.fields.fuel-and-oil.fuelTankCapacity.label')",
              :value="resource.formattedFuelTankCapacity"
            )
            info-label.info-label(
              :loading="fetching",
              :label="$t('.fields.fuel-and-oil.motorOilCapacity.label')",
              :value="resource.formattedMotorOilCapacity"
            )

      template(v-if="showElectricFields")
        section.section.electric
          h2.section-title {{ $t('.sections.electric.title') }}
          .flex
            info-label.info-label(
              :loading="fetching",
              :label="$t('.fields.electric.electricEngineBatteryCapacity.label')",
              :value="resource.formattedElectricEngineBatteryCapacity"
            )

      section.section.tires(v-if="resource.tireSpecification")
        h2.section-title {{ $t('.sections.tires.title') }}
        .subsection
          span.subtitle {{ $t('.sections.tires.subsections.main') }}
          .flex
            info-label.info-label(
              :loading="fetching",
              :label="$t('.fields.tires.kind.label')",
              :value="$t(`.fields.tires.kind.value.${resource.$get('tireSpecification.kind')}`)"
            )
            info-label.info-label(
              :loading="fetching",
              :label="$t('.fields.tires.material.label')",
              :value="$t(`.fields.tires.material.value.${resource.$get('tireSpecification.material')}`)"
            )
            info-label.info-label(
              :loading="fetching",
              :label="$t('.fields.tires.sizes.label')",
              :value="resource.$get('tireSpecification.sizes').map(el => el.size)"
            )
            info-label.info-label(
              :loading="fetching",
              :label="$t('.fields.tires.loadIndices.label')",
              :value="resource.$get('tireSpecification.loadIndices').map(el => el.loadIndex)"
            )
            info-label.info-label(
              :loading="fetching",
              :label="$t('.fields.tires.speedRatings.label')",
              :value="resource.$get('tireSpecification.speedRatings').map(el => el.speedRating)"
            )

        .subsection(v-if="resource.spareTireSpecification")
          span.subtitle {{ $t('.sections.tires.subsections.spare') }}
          .flex
            info-label.info-label(
              :loading="fetching",
              :label="$t('.fields.tires.material.label')",
              :value="$t(`.fields.tires.material.value.${resource.$get('spareTireSpecification.material')}`)"
            )
            info-label.info-label(
              :loading="fetching",
              :label="$t('.fields.tires.sizes.label')",
              :value="resource.$get('spareTireSpecification.sizes').map(el => el.size)"
            )
            info-label.info-label(
              :loading="fetching",
              :label="$t('.fields.tires.loadIndices.label')",
              :value="resource.$get('spareTireSpecification.loadIndices').map(el => el.loadIndex)"
            )
            info-label.info-label(
              :loading="fetching",
              :label="$t('.fields.tires.speedRatings.label')",
              :value="resource.$get('spareTireSpecification.speedRatings').map(el => el.speedRating)"
            )

      section.footer.flex.space-between
        app-button(:to="{ name: 'vehicleModels' }", outline)
          i.far.fa-arrow-left.icon
          span {{ $t('.btn.back') }}

        app-button(
          v-if="$can('create', 'VehicleModel')",
          theme="link",
          :to="{ name: 'newVehicleModel' }"
        )
          span {{ $t('.btn.new') }}

    router-view(:vehicle-model="resource", :fetching="fetching")

</template>


<script>

// Mixins
import { mixins } from "movida-common.vue"

// Extends
import View from "@/views/view"

// Local Components
import DestroyAction from "./show/destroy-action"

// Models
import models from "@/models"

const { Fetchable } = mixins
const {
  VehicleModel,
  VehicleModelCombustion
} = models

export default {
  name: "VehicleModel",

  components: {
    DestroyAction
  },

  extends: View,

  mixins: [Fetchable],

  data() {
    return {
      i18nScope: "vehicle-models.show",
      model:     null,
      id:        this.$route.params.id,
      resource:  null,

      VehicleModelCombustion
    }
  },

  computed: {
    showCombustionFields() {
      return ["VehicleModels::Combustion", "VehicleModels::Hybrid"].includes(this.resource.type)
    },

    showElectricFields() {
      return ["VehicleModels::Electric", "VehicleModels::Hybrid"].includes(this.resource.type)
    },

    fipeId() {
      return this.resource.$get("mobiautoVersion.fipeId")
    }
  },

  methods: {
    onDestroy() {
      this.$router.push({ name: "vehicleModels" })
    },

    fetch() {
      return this.$sdk.vehicleModels.get({ id: this.id })
    },

    // @override Fetchable mixin
    onFetchSuccess() {
      this.resource = VehicleModel.build(this.resource)
    },

    onFetchError(err) {
      if (this.hasViewError(err)) {
        this.appError = err
      }

      this.$err.log(err)
      this.$notifications.error(this.$t(".notifications.fetch.failure"))
    }
  }
}

</script>

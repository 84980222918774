function Record(Class) {
  class RecordClass extends Class {
    static get attrs() {
      return _.uniq([
        ...super.attrs,
        "id",
        "rid",
        "createdAt",
        "updatedAt",

        // Dados de apoio
        "_destroy" // para remoção em listagens
      ])
    }

    get $newRecord() {
      return this.$blank("id")
    }

    get $persisted() {
      return this.$present("id")
    }

    get $markedForDestruction() {
      return this._destroy === true
    }

    get $id() {
      return _.present(this.id)
        ? this.id
        : this.rid
    }

    $markForDestruction() {
      this._destroy = true
    }

    $unmarkForDestruction() {
      this._destroy = undefined
    }

    // @override
    // adicionando `_destroy` aos dados
    $serialize(...args) {
      let serialized = super.$serialize(...args)

      // XXX: mover para um mixin NestedAssociations ou algo do tipo
      if (this.markedForDestruction) serialized._destroy = true
      if (_.present(this._index)) serialized._index = this._index

      return serialized
    }
  }

  return RecordClass
}

export default Record

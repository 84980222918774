<style lang="scss" scoped>

.email-fields {
  .action-icons {
    margin-left: 18px;
    width: 60px;
    margin-top: 10px;

    &.first {
      margin-top: 34px;
    }

    .icon {
      font-size: 20px;
      width: 20px;
      height: 20px;
    }

    .button + .button {
      margin-left: 20px;
    }

    .clear {
      color: $gray-2;

      &:hover {
        color: $black;
      }
    }
  }
}

</style>


<template lang="pug">

  .email-fields
    ui-row.flex.vertical-top
      .flex.grow
        ui-col(:span="6")
          input-field(
            name='contact[emails]',
            :hide-label="!isFirst",
            :errors="errors.value",
            :placeholder="$t('.fields.value.placeholder')",
            v-model="resource.value",
            data-test="emailField"
          )

        ui-col.kind-col(:span="6")
          email-kind-select-field(
            name='email[kind]',
            optional,
            :hide-label="!isFirst",
            :errors="errors.kind",
            v-model="resource.kind"
          )

      .action-icons(:class="{ first: isFirst }")
        app-button.button(
          theme="link",
          @click="$emit('clear')"
        )
          i.icon.clear.fal.fa-times-circle
        app-button.button(
          v-if="canAdd",
          theme="link"
          @click="$emit('add')"
        )
          i.icon.add.fal.fa-plus-circle

</template>


<script>

import EmailKindSelectField from "../components/email-kind-select-field"

export default {
  components: {
    EmailKindSelectField
  },

  props: {
    name:     { type: String, default: null },
    resource: { type: Object, required: true },
    isFirst:  { type: Boolean, default: false },
    canAdd:   { type: Boolean, default: false },
    errors:   { type: Object, default: () => ({}) }
  },

  data() {
    return {
      i18nScope: "suppliers.contacts.form-fields.email-fields"
    }
  }
}

</script>

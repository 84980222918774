<style scoped lang="scss">

.edit-maintenance-package {
  padding-bottom: 24px;
}

.btn {
  margin-bottom: 12px;
}

.header {
  padding-bottom: 16px;
  border-bottom: 1px solid $light-gray-3;
}

.title {
  font-size: 30px;
  font-weight: 500;
  font-family: $secondary-font;
}

.fields-content {
  margin-bottom: 24px;
}

.id-tag {
  margin: 0 8px;
}

</style>


<template lang="pug">

  .edit-maintenance-package
    header.header.flex.vertical-center
      h1.title {{ $t('.title') }}
      template(v-if="!fetching && resource")
        id-tag.id-tag(:value="resource.id", size="big")

    form(@submit.prevent="submit", method="post")

      form-fields.fields-content(
        :fetching="fetching",
        :submitting="submitting",
        :errors="errors",
        :resource="resource",
        data-test="formFields"
      )

      section.actions.flex.space-between.vertical-center
        app-button(
          @click="goBack",
          outline,
          :loading="submitting"
        )
          span {{ $t('btn.cancel') }}

        app-button(type="submit", :loading="submitting", data-test="submitButton")
          span {{ $t('btn.update.label') }}
          span(slot="loading") {{ $t('btn.update.loading') }}

</template>


<script>

// Mixins
import { mixins } from "movida-common.vue"

// Extends
import View from "@/views/view"

// Components
import FormFields from "./forms/form-fields"

// Models
import models from "@/models"

const { MaintenancePackage } = models
const { FormMixin, Fetchable } = mixins

export default {
  name: "EditMaintenancePackage",

  components: {
    FormFields
  },

  extends: View,

  mixins: [Fetchable, FormMixin],


  data() {
    return {
      i18nScope: "maintenance-packages.edit",

      id: this.$route.params.id,

      // Fetchable mixin
      resource: {},
      model:    null
    }
  },

  computed: {
    backRoute() {
      return { name: "maintenancePackage", params: { id: this.id } }
    }
  },

  methods: {
    // @override Form mixin
    submitRequest() {
      const productIds = this.resource.products.map(product => product.id)
      const params = { ...this.resource.$serialize(), product_ids: productIds }

      return this.$sdk.maintenancePackages.update({ id: this.id, params })
    },

    // @override Form mixin
    submitSuccess({ data: { id, name } }) {
      this.$notifications.info(this.$t(".notifications.submit.success", { name }))
      this.$router.push({ name: "maintenancePackage", params: { id } })
    },

    // @override Form mixin
    submitError(err) {
      this.$err.log(err)
      this.$notifications.error(this.$t(".notifications.submit.error"))
    },

    // @override Fetchable mixin
    fetch() {
      return this.$sdk.maintenancePackages.get({ id: this.id })
    },

    // @override Fetchable mixin
    onFetchSuccess(response) {
      const products = this.resource.products.map(product => new models[product.type](product))
      this.resource = new MaintenancePackage(this.resource)
      this.resource.products = products
    },

    // @override Fetchable mixin
    onFetchError(err) {
      // Dealing with appError from extension view.vue
      if (this.hasViewError(err)) this.appError = err

      this.$err.log(err)
      this.$notifications.error(this.$t(".notifications.fetch.failure"))
    }
  }
}

</script>

import Base from "../base"

class StoreSettings extends Base {
  static get attrs() {
    return _.uniq([
      ...super.attrs,
      "autoGenerateInternalMaintenance",
      "autoGenerateExternalMaintenance"
    ])
  }

  static get constraints() {
    return {
      autoGenerateInternalMaintenance: { type: "boolean" },
      autoGenerateExternalMaintenance: { type: "boolean" }
    }
  }
}

export default StoreSettings

import Vue from "vue"
import { i18n } from "utils.vue"
import Base from "./base"

class SupplierBlock extends Base {
  static get attrs() {
    return _.uniq([
      ...super.attrs,
      "author",
      "description",
      "startAt",
      "endAt",
      "author",
      "accountId",
      "supplierId",
      "state"
    ])
  }

  static get relations() {
    return {
      supplier: { type: "belongsTo", model: "Supplier" }
    }
  }

  static get enums() {
    return {
      state: {
        done:      "done",
        scheduled: "scheduled",
        ongoing:   "ongoing"
      }
    }
  }

  static get constraints() {
    return {
      startAt: { presence: true, type: "string" },
      endAt:   {
        type: "string",

        after(value, attrs) {
          return {
            field:   "startAt",
            message: i18n.t("models.supplierBlock.errors.endAt.after", { startAt: Vue.$standardTime(attrs.startAt) })
          }
        }
      },
      description: { presence: true, type: "string" }
    }
  }
}

export default SupplierBlock

import { i18n } from "utils.vue"
import Base from "./base"

class Contact extends Base {
  static get attrs() {
    return _.uniq([
      ...super.attrs,
      "name",
      "area",

      // Nested
      // TODO achar uma solução para parâmetros de construtor virtuais
      "tempId"
    ])
  }

  static get relations() {
    return {
      emails: { type: "hasMany", model: "Email" },
      phones: { type: "hasMany", model: "Phone" }
    }
  }

  static get constraints() {
    return {
      name:   { presence: true, type: "string", length: { maximum: 1000 } },
      emails: {
        presence(value, attrs) {
          if (_.blank(attrs.phones)) return { message: i18n.t("models.contact.errors.emailOrPhone") }
          return false
        }
      },
      phones: {
        presence(value, attrs) {
          if (_.blank(attrs.emails)) return { message: i18n.t("models.contact.errors.emailOrPhone") }
          return false
        }
      }
    }
  }

  get primaryEmail() {
    const emails = this.emails.filter(resource => !resource.$markedForDestruction)

    if (_.blank(emails)) return null

    return emails[0]
  }

  get primaryPhone() {
    const phones = this.phones.filter(resource => !resource.$markedForDestruction)
    if (_.blank(phones)) return null

    return phones[0]
  }
}

export default Contact

// Base
import BaseRequests from "./Base"

// Models
import models from "@/models"
const { Ticket } = models

class TicketsRequests extends BaseRequests {
  static get selector() {
    return "tickets"
  }

  all({ params = {}, options = {} } = {}) {
    return this.action({
      method:  "get",
      url:     "/tickets",
      params,
      options: {
        model: Ticket,

        ...options
      }
    })
  }
}

export default TicketsRequests

<!--
<doc lang="markdown">
Exibe uma placa de carro no padrão mercosul

_Props_: value

Como usar:
```pug
  license-plate(value="ABC1D23")
```
</doc>
-->

<style lang="scss" scoped>

.license-plate {
  width: 75px;
  height: 24px;
  background-color: $light-gray;
  border-radius: 4px;
  border: 1px solid $light-gray-4;
  overflow: hidden;

  &.small {
    width: 59px;
    height: 18px;

    .top {
      height: 2px;
    }

    .value {
      font-size: 12px;
      padding: 0 4px;
      line-height: 13px;
    }
  }

  &.loading {
    color: $light-gray-4;

    .top {
      background-color: $light-blue;
    }
  }

  .top {
    background-color: $dark-blue;
    height: 4px;
  }

  .value {
    font-family: $primary-monospace-font;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    padding: 0 8px;
  }
}

</style>


<template lang="pug">

  .license-plate.flex.column-direction(:class="{ loading, [size]: true }")
    .top.full-width.no-shrink
    span.value {{ value }}

</template>


<script>

export default {
  name: "LicensePlate",

  props: {
    value:   { type: String, required: true },
    size:    { type: String, default: "normal", validator: size => ["small", "normal"].includes(size) },
    loading: { type: Boolean, default: false }
  },

  data() {
    return {
      i18nScope: "components.license-plate"
    }
  }
}

</script>

<!--
<doc lang="markdown">
Exibe o código do vetor, prefixado por um ícone do vetor

_Props_: value, size, color

Como usar:
```pug
  vetor-code-tag
    span {{ "25061987" }}
```

Ou passando valor por prop:
```
  vetor-code-tag(:value="vetorCode")
```
</doc>
-->


<style lang="scss" scoped>

.vetor-code-tag {
  overflow: hidden;

  &.disabled {
    ::v-deep .app-tag-label {
      color: $gray-2;
      background-color: $light-gray-2;

      .outside {
        fill: $light-gray-4;
      }
    }
  }
}

</style>


<template lang="pug">

  code-tag.vetor-code-tag(:color="color", :size="size", :class="{ disabled }")
    template(#icon)
      vetor-icon

    slot
      app-span(crop, :value="value")

</template>


<script>

export default {
  name: "VetorCodeTag",

  props: {
    color:    { type: String, default: "gray" },
    value:    { type: [String, Number], default: null },
    size:     { type: String, default: "normal", validator: size => ["small", "normal", "big"].includes(size) },
    disabled: { type: Boolean, default: false }
  },

  data() {
    return {
      i18nScope: "components.vetor-code-tag"
    }
  }
}

</script>

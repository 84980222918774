<style scoped lang="scss">

.button-icon {
  font-size: 20px;
}

.remove-button {
  margin-left: 6px;
  color: $gray-2;

  &:hover {
    color: $gray-3;
  }
}

.day-label {
  min-width: 130px;
}

.time-field + .time-field {
  margin-left: 4px;
}

.time-field {
  width: 80px;
}

.button-content {
  width: 26px;
}

</style>


<template lang="pug">

  .flex
    time-field.time-field(
      :name="`${name}[startAt]`",
      hide-label,
      v-model="resource.startAt",
      placeholder="00:00",
      :disabled="disabled",
      :min-time="shiftLimit.startAt.min",
      :max-time="shiftLimit.startAt.max",
      :loading="fetching",
      :errors="errors.startAt"
    )

    time-field.time-field(
      :name="`${name}[endAt]`",
      hide-label,
      v-model="resource.endAt",
      placeholder="00:00",
      :min-time="shiftLimit.endAt.min",
      :max-time="shiftLimit.endAt.max",
      :disabled="disabled",
      :loading="fetching",
      :errors="errors.endAt"
    )

    template(v-if="!disabled")
      app-button.remove-button(
        v-if="!hideRemove",
        :disabled="loading",
        @click="$emit('remove')"
        type="button",
        theme="link"
      )
        i.fal.fa-times-circle.button-icon

    .button-content(v-else)

</template>


<script>

export default {
  props: {
    fetching:   { type: Boolean, default: false },
    loading:    { type: Boolean, default: false },
    errors:     { type: Object, default: () => ({}) },
    resource:   { type: Object, default: () => ({}) },
    name:       { type: String, default: null },
    hideRemove: { type: Boolean, default: false },
    disabled:   { type: Boolean, default: false }
  },

  data() {
    return {
      i18nScope: "suppliers.details.business-hour-fields"
    }
  },

  computed: {
    shiftLimit() {
      const { shift } = this.resource

      if (_.blank(shift)) {
        return {
          startAt: {
            min: null,
            max: null

          },
          endAt: {
            min: null,
            max: null
          }
        }
      }

      return {
        startAt: {
          min: this.defaultLimits[shift].startAt,
          max: this.defaultLimits[shift].endAt
        },
        endAt: {
          min: this.resource.startAt || this.defaultLimits[shift].startAt,
          max: this.defaultLimits[shift].endAt
        }
      }

    },

    defaultLimits() {
      return {
        morning: {
          startAt: "00:00",
          endAt:   "12:00"
        },

        afternoon: {
          startAt: "12:00",
          endAt:   "18:00"
        },

        evening: {
          startAt: "18:00",
          endAt:   "23:59"
        }
      }
    }
  }
}

</script>

<style scoped lang="scss">

.row + .row {
  padding-top: 24px;
}

.section {
  border-bottom: 1px solid $light-gray-3;
  padding-bottom: 24px;
}

.label {
  display: block;
  font-family: $secondary-font;
  font-weight: 500;
  font-size: 14px;
  padding-bottom: 4px;;
}

.name-preview  {
  .name {
    margin: 0;
    font-size: 24px;
    font-weight: normal;

    .unavailable {
      font-style: italic;
      color: $gray-3;
    }
  }
}

.kind-field {
  margin-top: 34px;
}

</style>


<template lang="pug">

  .tire-fields
    section.fields.section

      ui-row.row
        ui-col(:span="6")
          input-field(
            name="tire[name]",
            :loading="loading",
            v-model="resource.name",
            :errors="errors.name",
            :placeholder="$t('.fields.name.placeholder')",
            data-test="nameInput"
          )

        ui-col(:span="3")
          brand-select-field(
            v-model="resource.brandId"
            data-test="brandInput"
            :errors="errors.brand || errors.brandId"
            :loading="loading"
            name="tire[brand]"
            optional
            :placeholder="$t('.fields.brand.placeholder')"
          )

        ui-col(:span="3") &nbsp;

      ui-row.row
        ui-col(:span="3")
          tire-size-select-field(
            name="tire[tireSize]",
            :placeholder="$t('.fields.tireSize.placeholder')",
            :loading="loading",
            :errors="errors.tireSize",
            v-model="resource.tireSize",
            data-test="tireSizeInput"
          )

        ui-col(:span="3")
          tire-load-index-select-field(
            v-model="resource.tireLoadIndex"
            data-test="tireLoadIndexInput"
            :errors="errors.tireLoadIndex"
            :loading="loading"
            name="tire[tireLoadIndex]"
            optional
            :placeholder="$t('.fields.tireLoadIndex.placeholder')"
          )

        ui-col(:span="3")
          tire-speed-rating-select-field(
            v-model="resource.tireSpeedRating"
            data-test="tireSpeedRatingInput"
            :errors="errors.tireSpeedRating"
            :loading="loading"
            name="tire[tireSpeedRating]"
            optional
            :placeholder="$t('.fields.tireSpeedRating.placeholder')"
          )

        ui-col(:span="3")
          toggle-field.kind-field(
            name="tire[tireKind]",
            :value-text="Tire.$tEnum('tire-kind', 'runFlat')"
            hide-label,
            v-model="resource.runFlat",
            :errors="errors.tireKind",
            data-test="tireKindInput"
          )

</template>


<script>

// Models
import models from "@/models"

// Local Components
import BrandSelectField from "../components/brand-select-field.vue"

const { Tire } = models


export default {
  components: { BrandSelectField },

  props: {
    loading:  { type: Boolean, default: false },
    errors:   { type: Object, default: () => ({}) },
    resource: { type: Object, default: () => ({}) }
  },

  data() {
    return {
      i18nScope: "products.forms.tire-fields",

      Tire
    }
  },

  created() {
    if (_.blank(this.resource.tireKind)) {
      // eslint-disable-next-line vue/no-mutating-props
      this.resource.tireKind = Tire.$enums.tireKind.key("conventional")
    }
  }
}

</script>

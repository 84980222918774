<script>

import viewStore from "@/store/view"

export default {
  data() {
    return {
      appError:  null,
      // TODO: Exigir valor definido no componente e remover esse valor
      routeName: this.$route.name
    }
  },

  computed: {
    route() {
      return this.$router.resolve({ name: this.routeName }).route
    },

    backRoute() {
      return null
    }
  },

  watch: {
    appError() {
      viewStore.appError = this.appError
    }
  },

  methods: {
    goBack() {
      this.$goBack({ fallback: this.backRoute })
    },

    // Erros de renderização de paginas devem
    // ser tratados neste método
    hasViewError(err) {
      return _.get(err, "constructor.name") === "HttpError"
    }
  }
}

</script>

import Base from "../base"

class MovidaServiceOrderTicketMessage extends Base {
  static get attrs() {
    return _.uniq([
      ...super.attrs,
      "authorName",
      "message",
      "kind",
      "at"
    ])
  }
}

export default MovidaServiceOrderTicketMessage

import iconA from "./A.svg"
import iconB from "./B.svg"
import iconC from "./C.svg"
import iconCMM from "./CMM.svg"
import iconD from "./D.svg"
import iconND from "./ND.svg"

export default {
  iconA,
  iconB,
  iconC,
  iconCMM,
  iconD,
  iconND
}

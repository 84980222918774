<style lang="scss" scoped>

.show.message {
  padding: 24px;

  // > .loading {}

  > .erred {
    .retry {
      margin-left: 4px;
    }
  }


  > .content {

    > .header {
      .title {
        font-family: $secondary-font;
        font-weight: 500;
        font-size: 24px;
      }

      .actions {
        .btn {
          .icon {
            margin-right: 8px;
          }
        }
      }
    }

    > .body {
      margin: 16px 0 0 0;
    }
  } // .content
}


.diamond-icon {
  margin: 0 8px;
  font-size: 4px;
  color: $gray-3;
}


</style>


<template lang="pug">

  .show.message
    template(v-if="fetching")
      .loading
        loading-lines(:lines="6")

    template(v-else-if="erred")
      .erred.flex.center
        span {{ $t('.fetch-error.message') }}
        app-button.retry(theme="link", @click="fetch") {{ $t('.fetch-error.retry') }}

    template(v-else)
      .content
        .header.flex.space-between
          .flex.column-direction
            .title {{ MovidaServiceOrderSupplierMessage.$tEnum('motive', message.motive) }}
            .subtitle.flex.vertical-center
              span.author {{ message.authorName }}
              i.diamond-icon.fas.fa-diamond
              span.at {{ $l('time.formats.short', message.at) }}

          .actions.flex
            app-button.btn(
              v-if="$can('destroy', message)",
              theme="neutral",
              size="small",
              @click="destroy"
            )
              i.fal.fa-trash-alt.icon
              span {{ $t('.actions.destroy.btn.label') }}

        .body
          rich-text(:value="message.description")

</template>


<script>

// Mixins
import FetchMixin from "@/mixins/fetch-mixin"

// Models
import models from "@/models"
const { MovidaServiceOrderSupplierMessage } = models


export default {
  mixins: [FetchMixin],

  props: {
    messageId: { type: [String, Number], required: true }
  },

  data() {
    return {
      i18nScope: "monitoring.messages.show",

      resource: null,

      MovidaServiceOrderSupplierMessage,

      destroying: false
    }
  },

  computed: {
    message: {
      get()      { return this.resource  },
      set(value) { this.resource = value }
    }
  },

  watch: {
    messageId() {
      this.fetch()
    }
  },

  methods: {
    // @override Fetch mixin
    fetchRequest() {
      return this.$newSdk.monitoring.serviceOrders.messages
        .find({ messageId: this.messageId })
    },

    async destroy() {
      const confirmed = await this.$confirm({
        heading: this.$t(".actions.destroy.confirmation.title"),
        message: this.$t(".actions.destroy.confirmation.message"),

        actions: {
          confirm: this.$t(".actions.destroy.confirmation.confirm")
        }
      })

      if (!confirmed) return

      try {
        this.destroying = true
        await this.$newSdk.monitoring.serviceOrders.messages
          .destroy({ messageId: this.messageId })

        this.$notifications.info(this.$t(".notifications.destroy.success"))
        this.$emit("update")
      }
      catch (error) {
        this.$err.log(error)
        this.$notifications.error(this.$t(".notifications.destroy.failure"))
      }
      finally {
        this.destroying = false
      }
    }
  }
}

</script>

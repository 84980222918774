<style scoped lang="scss">

.actions {
  margin-bottom: 8px;
}

.icon {
  font-size: 16px;
  margin-right: 8px;
}

.info-label + .info-label {
  margin-top: 10px;
}

.column + .column {
  margin-left: 78px;
}

.section {
  margin-bottom: 24px;
  padding-bottom: 24px;
  border-bottom: 1px solid $light-gray-3;
}

label {
  display: block;
}

.title {
  font-family: $secondary-font;
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 16px;
}

.label-text {
  font-size: 16px;
  display: inline;
  font-weight: normal;
  margin-right: 8px;
}

.section-title {
  margin-bottom: 8px;
  font-family: $secondary-font;
  font-weight: normal;

  .icon {
    margin-right: 16px;
  }
}

.sectors-content {
  padding-left: 33px;
}

.address-content {
  margin-bottom: 12px;
}

</style>


<template lang="pug">

  .supplier-info
    .actions.flex.end
      app-button.btn(
        v-if="$can('update', supplier)"
        theme="neutral",
        size="small",
        :to="{ name: 'editSupplier', params: { id } }"
      )
        i.fal.fa-pencil.icon
        span {{ $t('.btn.edit') }}

    .flex.section
      ui-row
        ui-col(:span="4")
          info-label.info-label(
            :value="supplier.tradingName || $t('abbr.unavailable')",
            icon="fal fa-id-card",
            theme="secondary",
            :tooltip="{ offset: '4', content: Supplier.$tAttr('tradingName') }"
          )

          info-label.info-label(
            :value="supplier.name",
            icon="fal fa-id-card",
            theme="secondary",
            :tooltip="{ offset: '4', content: Supplier.$tAttr('name') }"
          )

          info-label.info-label(
            :value="supplier.cnpj",
            icon="fal fa-building"
            theme="secondary",
            :tooltip="{ offset: '4', content: Supplier.$tAttr('cnpj') }"
          )

        ui-col(:span="3")
          info-label.info-label(
            :value="supplier.sapId",
            theme="secondary",
            :tooltip="{ offset: '4', content: Supplier.$tAttr('sapId') }"
          )
            span(slot="icon")
              sap-icon(color="gray")

          info-label.info-label(
            icon="fal fa-file-alt",
            theme="secondary",
            :tooltip="{ offset: '4', content: Supplier.$tAttr('ie') }"
          )
            span.label-text {{ $t('.fields.ie') }}

            template(v-if="supplier.ie")
              span {{ supplier.ie }}
            span(v-else) {{ $t('.exempt') }}

          info-label.info-label(
            icon="fal fa-file-alt",
            theme="secondary",
            :tooltip="{ offset: '4', content: Supplier.$tAttr('im') }"
          )
            span.label-text {{ $t('.fields.im') }}

            template(v-if="supplier.im")
              span {{ supplier.im }}
            span(v-else) {{ $t('.exempt') }}

        ui-col(:span="4")
          .section-title.flex.vertical-center
            i.fal.fa-briefcase.icon
            label {{ $t('.title.sectors') }}
          .sectors-content
            sectors-list(
              :sectors="supplier.sectors",
              theme="show"
            )
              .global-empty-text.global-empty-color(slot="empty")
                span {{ $t('.fields.sectors.empty') }}

    .location.section
      label.title {{ $t('.title.location') }}

      template(v-if="hasAddress")
        .address-content
          label.address {{ address.addressLine1 }}
          label.complement(v-if="address.addressLine2") {{ address.addressLine2 }}

        info-label.info-label(
          :value="supplier.$get('address.latLng')",
          icon="fal fa-location",
          theme="secondary",
          :tooltip="{ offset: '4', content: $t('.fields.latlng.tooltip') }"
        )

      template(v-else)
        span.global-empty-color.global-empty-text {{ $t('.fields.address.empty') }}

    .section.service-order-contact-section
      label.title {{ $t('.title.serviceOrderContact') }}

      ui-row
        ui-col(:span="4")
          info-label.info-label(
            :value="supplier.serviceOrderContactName",
            icon="fal fa-user",
            theme="secondary",
            :tooltip="{ offset: '4', content: Supplier.$tAttr('serviceOrderContactName') }"
          )

          info-label.info-label(
            :value="supplier.serviceOrderEmail",
            icon="fal fa-envelope",
            theme="secondary",
            :tooltip="{ offset: '4', content: Supplier.$tAttr('serviceOrderEmail') }"
          )

    .contacts-section
      contacts-list(:value="supplier.contacts")

</template>


<script>

// Components
import ContactsList from "../contacts/index"
import SectorsList from "../components/sectors-list"

// Models
import models from "@/models"
const { Supplier } = models

export default {
  name: "SupplierDefaultTab",

  components: {
    ContactsList,
    SectorsList
  },

  props: {
    supplier: { type: Object, default: () => ({}) }
  },

  data() {
    return {
      i18nScope: "suppliers.default-tab.show",

      id: this.$route.params.id,

      Supplier
    }
  },

  computed: {
    hasSectors() {
      return _.present(this.supplier.sectors)
    },

    hasAddress() {
      return _.present(this.supplier.address)
    },

    address() {
      return this.supplier.$get("address") || {}
    }
  }
}

</script>

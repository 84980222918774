<style lang="scss" scoped>

.maintenance-package-option {
  padding: 0 12px;
  height: 56px;
  line-height: normal;

  cursor: pointer;

  * {
    cursor: pointer;
  }

  &.active {
    background: $light-gray-2;

    .name {
      color: $primary;
    }
  }

  &.focus {
    background: $light-gray-2;

    .name {
      color: $dark-primary-2;
    }

    .remove-icon-content {
      background: $light-gray-3;
      color: $primary;
    }
  }

  .container {
    overflow: hidden;

    .icon {
      font-size: 20px;
      margin-right: 8px;
    }

    .content {
      overflow: hidden;

      .top {
        overflow: hidden;

        .name {
          font-family: $secondary-font;
          font-weight: 400;
          font-size: 18px;
        }

        .tag {
          margin-left: 8px;
        }
      }

      .bottom {
        .description {
          font-size: 14px;
        }
      }
    }
  }

  .actions-column {
    min-width: 42px;
    margin-left: 12px;

    .remove-icon-content {
      display: inline-block;
      width: 24px;
      height: 24px;
      line-height: 24px;
      text-align: center;
      border-radius: 50px;
      color: $gray-2;
      transition: all 100ms linear;
    }
  }
}

</style>


<template lang="pug">

  .maintenance-package-option.flex.vertical-center(:class="{ focus, active }")
    .container.flex.grow.vertical-center
      i.icon.fal.fa-box
      .content
        .top.flex
          app-span.name(
            :value="maintenancePackage.name",
            crop
          )
          id-tag.tag(:value="maintenancePackage.id")

        .bottom
          template(v-if="maintenancePackage.description")
            app-span.description(
              :value="maintenancePackage.description",
              crop,
              tooltip-placement="bottom"
            )

    .actions-column.center(v-if="!hideActionColumn")
      .remove-icon-content(v-if="active")
        i.fal.fa-times

</template>


<script>

export default {
  props: {
    option:           { type: Object, required: true },
    active:           { type: Boolean, default: false },
    focus:            { type: Boolean, default: false },
    hideActionColumn: { type: Boolean, default: false }
  },

  data() {
    return {
      i18nScope: "suppliers.deals.maintenance-packages.components.maintenance-package-option"
    }
  },

  computed: {
    maintenancePackage() {
      return this.option.data
    }
  }
}

</script>

/**
 * Plugin para adicionar botões seletores de períodos pré-definidos ao rodapé do
 * Flatpickr
 *
 * @param param0 {object}
 * @param param0.presets {"past" | "future"} Conjunto de presets disponíveis
 *
 * @returns {(fp: object) => object}
 */
const flatpickrPresetRangesPlugin = ({ presets = "past" }) => {
  const createButton = (text, onClick) => {
    const button = document.createElement("button")
    button.className = "preset-range-button"
    button.innerText = text
    button.tabIndex = -1
    button.type = "button"
    button.addEventListener("click", onClick)

    return button
  }

  /* eslint-disable func-names */
  return function (fp) {
    const selectCurrentIntervalToDate = (interval) => {
      const startOfInterval = moment().startOf(interval)
      const today = moment().startOf("day")

      fp.setDate([startOfInterval.toDate(), today.toDate()], true)
    }

    const selectPastInterval = (intervals, interval) => {
      const intervalAgo = moment().subtract(intervals, interval).startOf("day")
      const today = moment().startOf("day")

      fp.setDate([intervalAgo.toDate(), today.toDate()], true)
    }

    const selectCurrentIntervalFromDate = (interval) => {
      const today = moment().startOf("day")
      const endOfInterval = moment().endOf(interval)

      fp.setDate([today.toDate(), endOfInterval.toDate()], true)
    }

    const selectFutureInterval = (intervals, interval) => {
      const today = moment().startOf("day")
      const endOfInterval = moment().add(intervals, interval).endOf("day")

      fp.setDate([today.toDate(), endOfInterval.toDate()], true)
    }

    const createRangeButtons = (footer) => {
      if (presets == "past") {
        footer.appendChild(createButton("Neste mês", () => selectCurrentIntervalToDate("month")))
        footer.appendChild(createButton("Nos últimos 3 meses", () => selectPastInterval(3, "month")))
        footer.appendChild(createButton("Neste ano", () => selectCurrentIntervalToDate("year")))
      } else {
        footer.appendChild(createButton("Neste mês", () => selectCurrentIntervalFromDate("month")))
        footer.appendChild(createButton("Nos próximos 3 meses", () => selectFutureInterval(3, "month")))
        footer.appendChild(createButton("Neste ano", () => selectCurrentIntervalFromDate("year")))
      }
    }

    return {
      onReady() {
        const rangesContainer = document.createDocumentFragment()

        const footer = document.createElement("footer")
        footer.className = "footer"

        createRangeButtons(footer)

        rangesContainer.appendChild(footer)

        fp.calendarContainer.appendChild(rangesContainer)
      }
    }
  }
}
export default flatpickrPresetRangesPlugin

// Base
import BaseRequests from "../../Base"

// Models
import models from "@/models"

const VehicleWithdrawal = models.Events.ServiceOrders.ServiceOrderVehicleWithdrawal


class VehicleWithdrawalRequests extends BaseRequests {
  static get selector() {
    return "monitoring.serviceOrders.vehicleWithdrawal"
  }

  create({ serviceOrderId, params = {}, options = {} } = {}) {
    this.verifyData("create", { serviceOrderId })

    return this.action({
      method:  "post",
      url:     `/monitoring/service_orders/${serviceOrderId}/vehicle_withdrawal`,
      params,
      options: {
        model: VehicleWithdrawal,

        ...options
      }
    })
  }

  find({ serviceOrderId, params = {}, options = {} } = {}) {
    this.verifyData("find", { serviceOrderId })

    return this.action({
      method:  "get",
      url:     `/monitoring/service_orders/${serviceOrderId}/vehicle_withdrawal`,
      params,
      options: {
        model: VehicleWithdrawal,

        ...options
      }
    })
  }
}


export default VehicleWithdrawalRequests

<style scoped lang="scss">

.new-product {
  padding-bottom: 24px;
}

.header {
  padding-bottom: 16px;
  border-bottom: 1px solid $light-gray-3;
}

.title {
  font-size: 30px;
  font-weight: 500;
  font-family: $secondary-font;
}

.subtitle {
  font-weight: bold;
  font-size: 20px;
  margin-bottom: 24px;
  font-family: $secondary-font;
  font-weight: 500;
}

.type-section {
  border-bottom: 1px solid $light-gray-3;
  padding: 24px 0;
}

.form-section {
  padding-top: 24px;
}

.empty-section {
  > .subtitle {
    margin-bottom: 4px;
  }
}

.empty {
  display: block;
  font-style: italic;
}

.fields-content {
  margin-bottom: 24px;
}

.type-select,
.sap-input {
  width: 250px;
}

.submit-actions {
  .button:last-child {
    margin-left: 16px;
  }
}

</style>


<template lang="pug">

  .new-product
    header.header
      h1.title {{ $t(`.title.${gender}`, { type }) }}

    section.type-section
      ui-row
        ui-col(:span="6")
          type-select-field.type-select.float-left(
            name="type",
            :label="$t('.fields.type.label')",
            :placeholder="$t('form.select.placeholder')",
            :value="typeOption",
            :options="typeOptions",
            :disabled="submitting",
            searchable,
            @select-type-option="onSelectTypeOption",
            data-test="typeSelect"
          )

        ui-col(:span="6")
          input-field.sap-input.float-right(
            name="product[sap_id]",
            v-model="resource.sapId",
            :errors="errors.sapId",
            mask="4000000000",
            :placeholder="$t('.fields.sapId.placeholder')"
          )

    section.form-section(:class="{ 'empty-section': emptyType }")
      h2.subtitle {{ $t('.form.subtitle') }}

      template(v-if="!emptyType")
        form.form(ref="form", method="post", @submit.prevent="submit", data-test="form")

          component.fields-content(
            :is="component",
            :loading="submitting",
            :errors="errors",
            :resource="resource",
            data-test="formFields"
          )

          section.actions.flex.space-between.vertical-center
            app-button(
              :to="{ name: 'products' }",
              type="link",
              outline,
              theme="gray",
              :loading="submitting"
            )
              span {{ $t('btn.cancel') }}

            .submit-actions
              app-button.button(
                type="submit",
                :loading="submitting"
                @click="clearAfterSubmit = true",
                outline,
                data-test="submitButton"
              )
                span {{ $t('.btn.submit-and-new.label') }}
                span(slot="loading") {{ $t('.btn.submit-and-new.loading') }}

              app-button.button(
                type="submit",
                :loading="submitting",
                data-test="submitButton"
              )
                span {{ $t('.btn.submit.label') }}
                span(slot="loading") {{ $t('.btn.submit.loading') }}

      template(v-else)
        span.empty(data-test="emptyContent") {{ $t('.formSection.empty') }}

</template>


<script>

// Mixins
import FormMixin from "@/mixins/form-mixin"

// View
import BaseView from "@/views/base-view"

// Forms
import PartFields from "./forms/part-fields"
import MotorOilFields from "./forms/motor-oil-fields"
import TireFields from "./forms/tire-fields"
import ProductFields from "./forms/product-fields"

// Components
import TypeSelectField from "./components/type-select-field.vue"

// Models
import models from "@/models"
const { Product } = models

export default {
  name: "NewProduct",

  components: {
    PartFields,
    MotorOilFields,
    TireFields,
    ProductFields,

    TypeSelectField
  },

  extends: BaseView,

  mixins: [FormMixin],

  data() {
    return {
      i18nScope: "products.new",
      routeName: "newProduct",

      typeOption: null,
      resource:   new Product(),

      clearAfterSubmit: false
    }
  },

  computed: {
    type() {
      const type = _.get(this.resource, "type") || Product.$enums.type.value("Product")
      return Product.$tEnum("type", type)
    },

    // XXX: criando alias temporário, pois `type` deveria ser o valor e não o texto traduzido.
    typeText() {
      return this.type
    },

    gender() {
      return _.get(this.resource, "constructor.genderInflection") || Product.genderInflection
    },

    emptyType() {
      return _.blank(this.typeOption)
    },

    component() {
      if (this.emptyType) return null

      return this.typeOption.component
    },

    typeOptions() {
      const types = Product.$enums.type.values

      return types.map(type => {
        let modelName = models[type] ? type : Product.$enums.type.value("Product")
        let component = `${_.kebabCase(type)}-fields`

        return {
          label: Product.$tEnum("type", type),
          value: type,
          modelName,
          component
        }
      })
    }
  },

  created() {
    this.initialSerializedAttributes = this.serializeAttributes()
  },

  methods: {
    onSelectTypeOption(option) {
      const base = { sapId: this.resource.sapId }

      if (_.blank(option)) {
        this.resource = new Product(base)
        this.typeOption = null
        return
      }

      const { modelName } = option

      if (modelName === Product.$enums.type.value("Product")) {
        this.resource = new Product({ ...base, type: modelName })
      }
      else {
        this.resource = new models[modelName](base)
      }

      this.typeOption = option
    },

    // @override Form mixin
    serializeAttributes() {
      return this.resource.$serialize({ virtuals: true })
    },

    // @override Form mixin
    submitRequest() {
      return this.$newSdk.products.create({ params: this.serializeAttributes() })
    },

    // @override Form mixin
    onSubmitSuccess(response) {
      this.$notifications.info(
        this.$t(".notifications.submit.success", {
          name: this.resource.productName,
          type: this.typeText
        })
      )

      if (this.clearAfterSubmit) {
        this.resource = {}
        this.onSelectTypeOption(null)
        this.clearAfterSubmit = false
      }
      else {
        let { id } = response.data
        this.$router.push({ name: "product", params: { id } })
      }
    }
  }
}

</script>

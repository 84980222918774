import { Model } from "@caiena/model"
import Base      from "./base"


// XXX: Fachada/STI para Person ou Organization
class Customer extends Base {

  static get types() {
    return ["Organization", "Person"]
  }

  static build(data) {
    // XXX: inferindo a partir da estrutura de dado!
    const type = _.has(data, "cpf") ? "Person" : "Organization"

    switch (type) {
      case "Organization": {
        const Organization = Model.$lookupModel("Organization")
        return new Organization(data)
      }
      case "Person": {
        const Person = Model.$lookupModel("Person")
        return new Person(data)
      }
      default:
        throw new TypeError(`unkown customer type ${JSON.stringify(type)}`)
    }
  }

}


export default Customer

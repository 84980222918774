// Base
import BaseRequests from "../Base"

// Models
import models from "@/models"
const { SupplierMaintenanceVehicleModelGroup } = models

class SupplierMaintenanceVehicleModelGroupsRequests extends BaseRequests {
  static get selector() {
    return "suppliers.maintenanceVehicleModelGroups"
  }

  all({ supplierId, params = {}, options = {} }) {
    if (_.blank(supplierId)) {
      throw new Error('NewSDK suppliers.maintenanceVehicleModelGroups - #all() - "supplierId" is a required prop')
    }

    return this.action({
      // Estamos usando POST pois o filtro por ids pode ser muito grande e estourar o limite da URL.
      method:  "post",
      url:     `/suppliers/${supplierId}/maintenance_vehicle_model_groups/search`,
      params,
      options: {
        model: SupplierMaintenanceVehicleModelGroup,

        ...options
      }
    })
  }
}

export default SupplierMaintenanceVehicleModelGroupsRequests

import Base from "./base"

class Address extends Base {

  static get attrs() {
    return _.uniq([
      ...super.attrs,
      "placeId",
      "cityId",
      "addressLine1",
      "addressLine2",
      "lat",
      "lng"
    ])
  }

  static get relations() {
    return {
      city: { type: "belongsTo", model: "City" }
    }
  }

  static get constraints() {
    return {
      placeId:      { presence: true, type: "string" },
      addressLine2: { type: "string" }
    }
  }


  get latLng() {
    return (_.present(this.lat) && _.present(this.lng))
      ? `(${this.lat}, ${this.lng})`
      : null
  }
}

export default Address

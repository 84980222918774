<!--
<doc lang="markdown">
Encapsula o componente `app-finder` para fornecer busca remota

_Props_:
fetch -> Função responsável por popular as opções. Deve retornar um array de opções. Assinatura: `async () => option[]`
onFetchComplete -> Função para carregar dados adicionais às opções. Recebe o array de opções e deve retornar um array com as informações adicionais. Assinatura: `async (option[]) => option[]`
value -> Opção ou opções atualmente selecionadas

## Slots
- option: Conteúdo de cada opção listada.
  props:
    - option: {{label: string, value: string} | string | number}

- selectedLabel: Conteúdo do `combobox` quando há seleção.
  props:
    - value: {{label: string, value: string} | string | number} Valor(es) selecionado(s).
    - displayValue: {string} Valor da seleção formatado, separado por vírgula.

Como usar:
```javascript
  methods: {
    async fetch() {
      // ...
      return options
    },
    async onFetchComplete(options) {
      // ...
      return modifiedOptions
    }
  }
```

```pug
  app-remote-finder(
    :fetch="fetch"
    :on-fetch-complete="onFetchComplete"
    :value="value"
    @input="$listeners.input"
  )
    template(#option="{ option }")
      span {{ option.label || option }}
```
</doc>
-->

<template lang="pug">
  app-finder(
    v-bind="forwardedProps"
    v-on="forwardedListeners"
    :options="options"
    searchable
    search-mode="external"
    :value="value"
    @open="onOpen"
    @search="onSearchChange"
  )
    template(
      v-if="'selectedLabel' in $slots || 'selectedLabel' in $scopedSlots"
      #selectedLabel="selectedLabel"
    )
      slot(name="selectedLabel" v-bind="selectedLabel")
    template(
      v-if="'option' in $slots || 'option' in $scopedSlots"
      #option="option"
    )
      slot(name="option" v-bind="option")
    template(#emptyOptions)
      em(v-if="loading") Carregando opções...
      em(v-else) Não há opções disponíveis
</template>

<script>
export default {
  name: "AppRemoteFinder",

  inheritAttrs: false,

  props: {
    autofetch:       { type: Boolean, default: false },
    debounce:        { type: [Boolean, Number, Object], default: false },
    fetch:           { type: Function, required: true },
    onFetchComplete: { type: Function, default: null },
    value:           { type: [String, Number, Object, Array, Boolean], default: null }
  },

  data() {
    return {
      loading:        false,
      options:        null,
      processingHook: false
    }
  },

  computed: {
    debounceOptions() {
      if (typeof this.debounce !== "object") return {}
      return _.omit(this.debounce, ["wait"])
    },
    debounceWait() {
      const defaultWait = 500
      if (this.debounce === false) return 0
      if (this.debounce === true) return defaultWait
      if (typeof this.debounce === "number") return this.debounce
      return _.get(this.debounce, "wait", defaultWait)
    },
    forwardedListeners() {
      return _.pick(this.$listeners, ["input"])
    },
    forwardedProps() {
      return _.omit(this.$attrs, ["options"])
    },
    onSearchChange() {
      return _.debounce(
        this._onSearchChange,
        this.debounceWait,
        this.debounceOptions
      )
    }
  },

  mounted() {
    if (this.autofetch || _.present(this.value)) this._onSearchChange()
  },

  methods: {
    async handleFetchCompleteHook() {
      if (this.onFetchComplete) {
        try {
          this.processingHook = true
          this.options = await this.onFetchComplete(this.options)
        }
        finally {
          this.processingHook = false
        }
      }
    },
    onOpen() {
      if (this.autofetch) return
      this._onSearchChange()
    },
    async _onSearchChange(query) {
      try {
        this.loading = true
        const options = await this.fetch(query)
        this.options = options
        this.handleFetchCompleteHook()
      }
      finally {
        this.loading = false
      }
    }
  }
}
</script>

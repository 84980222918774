// Ability
import { ability } from "@/modules/ability"

// Routes
import Monitoring   from "@/views/monitoring/index.vue"
import ServiceOrder from "@/views/monitoring/service-orders/show.vue"
import ReviewQuote  from "@/views/monitoring/quotes/reviews/edit.vue"
import EditQuote    from "@/views/monitoring/quotes/edit.vue"


export default [
  {
    path:      "/monitoring",
    name:      "monitoring",
    component: Monitoring,
    meta:      {
      auth:        true,
      permissions: [() => ability.can("index", "MovidaServiceOrder")]
    }
  },

  {
    path:      "/monitoring/service-orders/:serviceOrderId",
    name:      "monitoringServiceOrder",
    component: ServiceOrder,
    meta:      {
      auth:        true,
      permissions: [() => ability.can("read", "MovidaServiceOrder")]
    }
  },

  {
    path:      "/monitoring/quotes/:id/edit",
    name:      "editQuote",
    component: EditQuote,
    meta:      {
      auth:        true,
      permissions: [() => ability.can("update", "Quote")]
    }
  },

  {
    path:      "/monitoring/quotes/:id/review/edit",
    name:      "reviewQuote",
    component: ReviewQuote,
    meta:      {
      auth:        true,
      permissions: [() => ability.can("update", "Quote")]
    }
  }
]

<style lang="scss" scoped>

.attachment-card-header {
  height: 72px;
  background-color: var(--background);
  border-radius: 8px 24px 0 0;
  overflow: hidden;

  .corner {
    background-color: var(--accent);
    height: 24px;
    width: 24px;
    border-top-right-radius: 24px;
    border-bottom-left-radius: 8px;
  }

  .type {
    background-color: $white;
    margin-left: 16px;
    padding: 8px;
    border-radius: 4px;
  }
}

</style>


<template lang="pug">

  .attachment-card-header(:style="styles")
    .flex.end
      .corner
    .flex
      attachment-file-type.type(:attachment="attachment")

</template>


<script>

// Models
import models from "@/models"
const { Attachment } = models

export default {
  name: "AttachmentCardHeader",

  props: {
    attachment: { type: Object, default: null }
  },

  data() {
    return {
      i18nScope: "components.attachments.attachment-card-header",

      Attachment
    }
  },

  computed: {
    background() {
      switch (this.attachment.fileType) {
        case "audio": return "#F2E5F6"
        case "compressed": return "#DFDCE7"
        case "doc": return "#EAEAEA"
        case "email": return "#EAEAEA"
        case "image": return "#E7E1DC"
        case "pdf": return "#E7DCDC"
        case "spreadsheet": return "#E3E7DC"
        case "text": return "#EAEAEA"
        case "video": return "#DCDEE7"
        case "file": default: return "#EAEAEA"
      }
    },

    styles() {
      return {
        "--background": this.background,
        "--accent":     this.attachment.iconColor
      }
    }
  }
}

</script>

<template lang="pug">

  .attachment-card
    .container(:class="{ uploaded }")
      template(v-if="uploaded")
        attachment-card-header.header(
          :attachment="attachment",
          @download="download"
        )

      .content.flex.column-direction
        .name-container
          template(v-if="canDownload")
            app-span.name.download(
              :value="attachment.name",
              @click.native="showPreviewModal = true"
            )

          template(v-else)
            app-span.name(:value="attachment.name")
        app-span.size(:value="humanReadableSize")

        template(v-if="computingChecksum")
          loading-lines.checking(:height="8", :min="100", :max="100")

        template(v-else-if="uploading")
          .progress-bar(:style="progressStyle")
            .progress

        .actions.flex
          .action.flex.center.vertical-center(
            v-if="canDownload",
            :class="{ downloading }",
            @click="download"
          )
            i.icon.fas.fa-download
          .action.flex.center.vertical-center(
            v-if="canEdit",
            @click="editPreview"
          )
            i.icon.fas.fa-pencil
          .action.flex.center.vertical-center(v-if="canDelete", @click="onDestroy")
            i.icon.fas.fa-trash-alt

    .description(v-if="description")
      app-span(:value="description")

    quote-attachment-preview(
      v-if="showPreviewModal",
      v-bind="extraPreviewProps",
      :attachment="attachment",
      :editable="editable",
      :editing.sync="editing",
      :local-edit="localEdit",
      :quote-id="quoteId",
      @close="onClosePreview",
      @download="download",
      @update:attachment="value => $emit('update:attachment', value)"
    )

</template>


<script>

// Libs
import FileChecksum from "@/lib/checksum"

// Extends
import AttachmentCard from "./attachment-card.vue"

export default {
  name: "QuoteAttachmentCard",

  extends: AttachmentCard,

  props: {
    quoteId: { type: [String, Number], default: null }
  },

  methods: {
    async createBlob() {
      this.computingChecksum = true
      const checksum = await FileChecksum.create(this.fileToUpload)
      this.computingChecksum = false

      const response = await this.$newSdk.quotes.blobs.create({
        params: {
          blob: {
            filename:     this.fileToUpload.name,
            content_type: this.fileToUpload.type,
            byte_size:    this.fileToUpload.size,
            checksum
          }
        }
      })

      return response.data
    },

    downloadRequest() {
      return this.$newSdk.quotes.blobs.getDownloadURL({ quoteId: this.quoteId, blobId: this.attachment.blobId, params: { disposition: "attachment" } })
    }
  }
}

</script>

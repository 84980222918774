// Assets
import acura from "./acura.svg"
import alfaRomeo from "./alfa-romeo.svg"
import asia from "./asia.svg"
import astonMartin from "./aston-martin.svg"
import audi from "./audi.svg"
import bmw from "./bmw.svg"
import buggyBrm from "./buggy-brm.svg"
import buick from "./buick.svg"
import cadillac from "./cadillac.svg"
import chery from "./chery.svg"
import chevrolet from "./chevrolet.svg"
import chrysler from "./chrysler.svg"
import citroen from "./citroen.svg"
import cnAuto from "./cn-auto.svg"
import crosslander from "./crosslander.svg"
import daewoo from "./daewoo.svg"
import daihatsu from "./daihatsu.svg"
import dodge from "./dodge.svg"
import effa from "./effa.svg"
import ferrari from "./ferrari.svg"
import fiat from "./fiat.svg"
import ford from "./ford.svg"
import hafeiTowner from "./hafei-towner.svg"
import holden from "./holden.svg"
import honda from "./honda.svg"
import hyundai from "./hyundai.svg"
import infiniti from "./infiniti.svg"
import isuzu from "./isuzu.svg"
import iveco from "./iveco.svg"
import jaguar from "./jaguar.svg"
import jeep from "./jeep.svg"
import jinBei from "./jin-bei.svg"
import jpx from "./jpx.svg"
import kia from "./kia.svg"
import lamborghini from "./lamborghini.svg"
import landRover from "./land-rover.svg"
import lexus from "./lexus.svg"
import lotus from "./lotus.svg"
import maserati from "./maserati.svg"
import mazda from "./mazda.svg"
import mercedesBenz from "./mercedes-benz.svg"
import mercury from "./mercury.svg"
import mini from "./mini.svg"
import mitsubishi from "./mitsubishi.svg"
import nissan from "./nissan.svg"
import opel from "./opel.svg"
import peugeot from "./peugeot.svg"
import plymouth from "./plymouth.svg"
import pontiac from "./pontiac.svg"
import porsche from "./porsche.svg"
import renault from "./renault.svg"
import rollsRoyce from "./rolls-royce.svg"
import smart from "./smart.svg"
import subaru from "./subaru.svg"
import suzuki from "./suzuki.svg"
import tesla from "./tesla.svg"
import toyota from "./toyota.svg"
import troller from "./troller.svg"
import volkswagen from "./volkswagen.svg"
import volvo from "./volvo.svg"
import others from "./others.svg"

/* eslint-disable quote-props */
const icons = {
  "acura":         acura,
  "alfa romeo":    alfaRomeo,
  "asia":          asia,
  "aston martin":  astonMartin,
  "audi":          audi,
  "bmw":           bmw,
  "buggy brm":     buggyBrm,
  "buick":         buick,
  "cadillac":      cadillac,
  "chery":         chery,
  "chevrolet":     chevrolet,
  "chrysler":      chrysler,
  "citroen":       citroen,
  "cn auto":       cnAuto,
  "crosslander":   crosslander,
  "daewoo":        daewoo,
  "daihatsu":      daihatsu,
  "dodge":         dodge,
  "effa":          effa,
  "ferrari":       ferrari,
  "fiat":          fiat,
  "ford":          ford,
  "hafei towner":  hafeiTowner,
  "holden":        holden,
  "honda":         honda,
  "hyundai":       hyundai,
  "infiniti":      infiniti,
  "isuzu":         isuzu,
  "iveco":         iveco,
  "jaguar":        jaguar,
  "jeep":          jeep,
  "jin bei":       jinBei,
  "jpx":           jpx,
  "kia":           kia,
  "lamborghini":   lamborghini,
  "land rover":    landRover,
  "lexus":         lexus,
  "lotus":         lotus,
  "maserati":      maserati,
  "mazda":         mazda,
  "mercedes-benz": mercedesBenz,
  "mercury":       mercury,
  "mini":          mini,
  "mitsubishi":    mitsubishi,
  "nissan":        nissan,
  "opel":          opel,
  "peugeot":       peugeot,
  "plymouth":      plymouth,
  "pontiac":       pontiac,
  "porsche":       porsche,
  "renault":       renault,
  "rolls-royce":   rollsRoyce,
  "smart":         smart,
  "subaru":        subaru,
  "suzuki":        suzuki,
  "tesla":         tesla,
  "toyota":        toyota,
  "troller":       troller,
  "volkswagen":    volkswagen,
  "volvo":         volvo,
  "others":        others
}

export default icons

<style lang="scss" scoped>

.service-order-info {
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 8px 24px;

  .info-block {
    .icon-container {
      width: 16px;
      height: 16px;
      margin-right: 8px;

      .icon {
        font-size: 12px;
        color: $gray-3;
      }
    }

    .id,
    .text {
      font-size: 14px;
      font-weight: 400;
    }

    .text {
      font-family: $secondary-font;
    }

    .subtext {
      font-size: 12px;
      font-weight: 400;
      color: $gray-3;

      padding: 2px 0;
    }

    .diamond-icon {
      color: $gray-3;
      font-size: 4px;
      margin: 0 4px;
    }

    .info-content {
      overflow: hidden;
    }

    .license-plate,
    .business-unit-tag {
      margin-right: 8px;
    }
  }
}

</style>


<template lang="pug">

  .service-order-info.grid
    .info-block.flex
      .icon-container.flex.vertical-center.center.no-shrink
        i.icon.fas.fa-file-invoice-dollar
      .info-content.flex.column-direction
        //- span.id {{ id }}
        id-tag.id(:value="id")
        template(v-if="scheduledAt")
          .flex.vertical-center(v-tooltip="$l('date.formats.default', scheduledAt)")
            span.text {{ $fromNow(scheduledAt) }}
            i.diamond-icon.fas.fa-diamond
            span.text {{ scheduledAtTime }}
        template(v-else)
          .flex.vertical-center
            span.subtext.italic {{ $t(".fields.scheduledAt.blank") }}

    .info-block.flex
      .icon-container.flex.vertical-center.center.no-shrink
        i.icon.fas.fa-car-mirrors
      .info-content.flex.column-direction
        app-span.text(crop, :value="vehicleModelName")
        .info-content.flex.vertical-center
          license-plate.license-plate(:value="vehicleLicensePlate")
          template(v-if="vehicleCategory")
            vehicle-category-tag.vehicle-category(:value="vehicleCategory")

    .info-block.flex
      .icon-container.flex.vertical-center.center.no-shrink
        i.icon.fas.fa-user-hard-hat
      .info-content.flex.column-direction
        app-span.text(crop, :value="supplierName")
        app-span.subtext.address(crop, :value="supplierAddress")

    template(v-if="hasCustomer")
      .info-block.flex
        .icon-container.flex.vertical-center.center.no-shrink
          i.icon.fas.fa-user
        .info-content.flex.column-direction
          app-span.text(crop, :value="customer.name")
          business-unit-tag.business-unit-tag(:value="businessUnit")

    //- TODO: se não tiver cliente e tiver Loja RAC, exibí-la
    //- template(v-else-if="hasBranch")
    //-   .info-block.flex
    //-     .icon-container.flex.vertical-center.center.no-shrink
    //-       i.icon.fas.fa-user
    //-     .info-content.flex.column-direction
    //-       app-span.text(crop, :value="customer.name")
    //-       business-unit-tag.business-unit-tag(:value="businessUnit")


</template>


<script>

import VehicleCategoryTag from "@/views/monitoring/_components/vehicle-category-tag.vue"

// Models
import models from "@/models"
const { MovidaServiceOrder, MovidaVehicle, MovidaSupplier, Branch } = models


export default {
  components: {
    VehicleCategoryTag
  },

  emits: [],

  props: {
    serviceOrder: { type: MovidaServiceOrder, required: true },
    vehicle:      { type: MovidaVehicle,      required: true },
    supplier:     { type: MovidaSupplier,     required: true },
    customer:     { type: Object,             default: null  },  // XXX: é "polimórfico" entre Person e Organization!
    branch:       { type: Branch,             default: null  }
  },

  data() {
    return {
      i18nScope: "monitoring.service-order-info"
    }
  },

  computed: {
    id()           { return this.serviceOrder.remoteId     },
    businessUnit() { return this.serviceOrder.businessUnit },
    scheduledAt()  { return this.serviceOrder.scheduledAt  },

    vehicleCategory()     { return this.vehicle.category      },
    vehicleModelName()    { return this.vehicle.fullModelName },
    vehicleLicensePlate() { return this.vehicle.licensePlate  },

    supplierName()    { return this.supplier.tradingName   },
    supplierCNPJ()    { return this.supplier.formattedCNPJ },
    supplierAddress() { return this.supplier.simpleAddress },

    scheduledAtDay() {
      if (_.blank(this.scheduledAt)) return null

      return this.$fromNow(this.scheduledAt)
    },

    scheduledAtTime() {
      if (_.blank(this.scheduledAtDay)) return null

      return this.$l("time.formats.simple", this.scheduledAt)
    },

    hasCustomer() {
      return _.present(this.customer)
    }
  }
}

</script>

// Base
import BaseRequests from "../Base"

class StoreServiceOrdersRequests extends BaseRequests {
  static get selector() {
    return "stores.serviceOrders"
  }

  all({ storeId, params = {}, options = {} }) {
    this.verifyData("all", { storeId })

    return this.action({
      method:  "get",
      url:     `/stores/${storeId}/service_orders`,
      params,
      options: {
        model: null,

        ...options
      }
    })
  }
}

export default StoreServiceOrdersRequests

<style lang="scss" scoped>

.select-list {
  .supplier-load-error {
    margin-right: 4px;
  }

  .kind-tabs {
    margin-bottom: 24px;
  }

  .clear-filters {
    position: relative;

    .filters-button {
      position: absolute;
      margin-top: 10px;
    }
  }

  .list {
    margin-top: 50px;

    .select-actions {
      .action + .action {
        margin-left: 24px;
      }
    }

    .table {
      margin-top: 16px;
    }
  }

  .paginator-content {
    padding-top: 24px;

    &.loading {
      max-width: 400px;
      margin: 0 auto;
    }
  }
}

</style>


<template lang="pug">

  .select-list
      search-form.search(
        :loading="fetching",
        :params="parsedQueryParams",
        @search="search"
      )

      .clear-filters
        filters-button.filters-button(
          v-if="hasFilters",
          @clear="clearFilters"
        )

      .list
        results-indicator(
          :page="parsedQueryParams.page",
          :per-page="pagination.perPage",
          :total="pagination.total",
          :loading="fetching"
        )

        .flex.space-between.vertical-bottom
          .result-tabs.flex
            index-filter-tab(
              icon="fa-wrench",
              :label="$t('.filters.all.label')",
              :active="!showSelectedResources",
              :loading="initializing",
              :disabled="_fetching"
              @click="showSelectedResourcesFilter(false)"
            )

            index-filter-tab(
              icon="fa-check",
              :label="$t('.filters.selected.label', { count: totalSelected })",
              :active="showSelectedResources",
              :loading="initializing",
              :disabled="_fetching || (!hasSelectedOptions && !showSelectedResources)"
              @click="showSelectedResourcesFilter(true)"
            )

          .select-actions
            app-button.action(
              theme="link",
              :disabled="_fetching",
              @click="selectAll"
            ) {{ $t('.actions.selectAll.label') }}

            app-button.action(
              theme="link",
              :disabled="_fetching",
              @click="removeAll"
            ) {{ $t('.actions.removeAll.label') }}

        service-table.table(
          :services="services",
          :initializing="initializing",
          :loading="fetching",
          :sorted-by="parsedQueryParams.sort",
          :value="value",
          :errors="errors.serviceIds",
          @sort="onSortChange",
          @changed="fetch",
          @toggle-select="selectResource",
          @toggle-all="toggleAllOnPage")

      .paginator-content(v-if="fetching || showPaginator", :class="{ loading: fetching }")
        paginator(
          :loading="fetching",
          :per-page="pagination.perPage",
          :total="pagination.total",
          :value="parsedQueryParams.page",
          :disabled="fetching",
          @input="onPageChange",
          data-testid="paginator"
        )

</template>


<script>

// View
import IndexView from "@/views/index-view"

// Mixins
import FetchMixin from "@/mixins/fetch-mixin"

// Components
import SearchForm from "./search-form.vue"
import ServiceTable from "./table.vue"

export default {
  components: {
    SearchForm,
    ServiceTable
  },

  extends: IndexView,

  mixins: [FetchMixin],

  props: {
    value:       { type: Array, default: () => [] },
    errors:      { type: Object, default: () => ({}) },
    supplierId:  { type: [String, Number], required: true },
    serviceType: { type: String, required: true }
  },

  data() {
    return {
      i18nScope: "suppliers.service-supports.service-vehicle-model-groups.new.services.select-list",
      routeName: "newSupplierServiceSupportServiceVehicleModelGroups",

      fetchingIds: false,
      resource:    [],

      // Query Mixin data
      queryParamsStructure: {
        q:    "string",
        ids:  "array",
        page: "integer",
        sort: "string"
      }
    }
  },

  computed: {
    services: {
      get() { return this.resource },
      set(value) { this.resource = value }
    },

    totalSelected() {
      return this.value.length
    },

    total() {
      return this.resource.length
    },

    showSelectedResources() {
      return _.present(this.parsedQueryParams.ids)
    },

    hasSelectedOptions() {
      return _.present(this.value)
    },

    _fetching() {
      return this.fetching || this.fetchingIds
    }
  },

  methods: {
    parseRoute() {},

    showSelectedResourcesFilter(value) {
      if (this.showSelectedResources === value) return

      this.parsedQueryParams.ids = value ? this.value : []
      this.search()
    },

    async fetchAllIds() {
      this.fetchingIds = true

      try {
        const response = await this.$newSdk.services.all({
          params: {
            ...this.requestQueryParams,

            type:    this.serviceType,
            partial: "ids"
          }
        })

        return response.data
      }
      finally {
        this.fetchingIds = false
      }
    },

    async selectAll() {
      try {
        const ids = await this.fetchAllIds()

        this.$emit("input", _.union(ids, this.value))
      }
      catch (error) {
        this.$err.log(error)
      }
    },

    async removeAll() {
      try {
        const ids = await this.fetchAllIds()
        const newValue = _.remove([...this.value], id => !ids.includes(id))
        this.$emit("input", newValue)
      }
      catch (error) {
        this.$err.log(error)
      }
    },

    toggleAllOnPage(value) {
      this.resource.forEach(entry => {
        this.selectResource({ id: entry.id, value })
      })
    },

    selectResource({ id, value }) {
      let array = this.value

      const idIndex = array.findIndex(itemId => itemId === id)

      if (value && idIndex < 0) {
        array.push(id)
      }
      else if (!value && idIndex >= 0) {
        array.splice(idIndex, 1)
      }

      this.$emit("input", array)
    },

    // @override Fetch mixin
    fetchRequest() {
      return this.$newSdk.services.all({
        params: {
          ...this.requestQueryParams,

          type: this.serviceType
        }
      })
    }
  }
}

</script>

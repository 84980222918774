import Base from "./base"

class SpareTireSpecification extends Base {
  static get attrs() {
    return _.uniq([
      ...super.attrs,
      "vehicleModelId",
      "material"
    ])
  }

  static get enums() {
    return {
      material: {
        alloy: "alloy",
        steel: "steel"
      }
    }
  }

  static get relations() {
    return {
      sizes:        { type: "hasMany", model: "TireSize" },
      loadIndices:  { type: "hasMany", model: "TireLoadIndex" },
      speedRatings: { type: "hasMany", model: "TireSpeedRating" }
    }
  }

  static get constraints() {
    return {
      material: {
        presence: true
      }
    }
  }
}

export default SpareTireSpecification

<style lang="scss" scoped>

.error {
  display: block;
  text-align: center;
  padding: 4px 12px;
  font-size: 14px;
  font-style: italic;

  .link {
    margin-left: 5px;
    font-size: 14px;
    font-style: italic;
    font-weight: normal;
  }
}

.empty {
  display: block;
  text-align: center;
  padding: 4px 12px;
  font-size: 14px;
  font-style: italic;
}

</style>


<template lang="pug">

  .new-supplier-select-field
    select-field(
      :name="name",
      :label="label",
      :fetching="fetching",
      :disabled="disabled",
      :placeholder="placeholder",
      :optional="optional",

      option-as-value,
      :loading="loading || fetchingOption",
      :options="options",
      :default-filter="false",
      :errors="errors",
      searchable,

      :value="option",
      @input="onSelect",

      @search="onSearch",

      @close="onClose",
      @inputSearch="(newSearch) => search = newSearch"
    )
      template(#menuContentHeader)
        template(v-if="fetchError")
          label.error
            span {{ $t('.error.message') }}

      template(#menuContentFooter)
        template(v-if="!fetching && !fetchError && empty")
          label.empty {{ emptyMessage }}

      template(#fetching)
        li.option {{ $t('.loading') }}

      template(#selected="{ props: { selected } }")
        supplier-option(:option="selected", selected)

      template(#option="{ props: { option, index, focus, active } }")
        supplier-option(
          :option="option",
          :active="active",
          :focus="focus",
        )

</template>


<script>

// Mixins
import FieldMixin from "@/mixins/field"

// Models
import models from "@/models"
const { Supplier } = models

export default {
  mixins: [FieldMixin],

  props: {
    value:       { type: [Number, String, Object], default: null },
    dataAsValue: { type: Boolean, default: false }
  },

  data() {
    return {
      i18nScope: "suppliers.components.new-supplier-select-field",

      search:         "",
      options:        [],
      option:         null,
      fetchingOption: false,
      fetchError:     false,

      emptyMessage: null,

      // Async
      fetching: false,
      request:  null
    }
  },

  computed: {
    empty() {
      return this.options.length === 0 && this.fullCNPJ
    },

    fullCNPJ() {
      return _.present(this.search) && this.search.replace(/[^0-9]/g, "").length >= 14
    }
  },

  watch: {
    value() {
      if (this.value === _.get(this.option, "value")) return

      if (_.blank(this.value)) {
        this.option = null
        return
      }

      if (!this.dataAsValue) {
        this.fetchOption(this.value)
      }
      else {
        this.option = {
          value: this.value.cnpj,
          label: this.value.name,
          data:  this.value
        }
      }
    }
  },

  created() {
    if (_.blank(this.value)) return

    if (!this.dataAsValue) {
      this.fetchOption(this.value)
    }
    else {
      this.option = {
        value: this.value.cnpj,
        label: this.value.name,
        data:  this.value
      }
    }
  },

  methods: {
    async fetchOption(cnpj) {
      try {
        this.fetchingOption = true

        const response = await this.$sdk.suppliers.new({ params: { cnpj } })
        const normalizedData = _.camelizeKeys(response.data)

        this.option = {
          value: normalizedData.cnpj,
          label: normalizedData.name,
          data:  new Supplier(normalizedData)
        }
      }
      catch (err) {
        this.$err.log(err)
      }
      finally {
        this.fetchingOption = false
      }
    },

    onClose() {
      this.options = this.options.filter(option => option.value === _.get(this.option, "value"))
      this.fetchError = false
    },

    onSelect(option) {
      if (_.blank(option)) this.options = []
      this.option = option
      this.$emit("change-option", option)

      if (this.dataAsValue) {
        this.$emit("input", _.get(option, "data") || null)
      }
      else {
        // XXX precisamos enviar `null` ao invés de `undefined`
        // para que o Axios não desconsidere o parametro
        this.$emit("input", _.get(option, "value") || null)
      }
    },

    // Busca assincrona de _options_
    async _fetch() {
      let currentRequest

      try {
        if (this.request) this.request.cancel("Duplicated fetch")

        currentRequest = this.$sdk.suppliers.new({ params: { cnpj: this.search } })
        this.request = currentRequest

        this.fetching = true

        const response = await currentRequest
        let normalizedData = _.camelizeKeys(response.data)

        this.options = [{
          value: normalizedData.cnpj,
          label: normalizedData.name,
          data:  new Supplier(normalizedData)
        }]
      }

      catch (error) {
        if (_.get(error, "status") && [400, 404, 409].includes(error.status)) {
          this.options = []

          switch (error.status) {
            case 400:
              this.emptyMessage = this.$t(".empty.invalid"); break
            case 404:
            default:
              this.emptyMessage = this.$t(".empty.notFound"); break
            case 409:
              this.emptyMessage = this.$t(".empty.taken"); break
          }
        }
        else if (error.cancelled === false) {
          this.fetchError = true
          this.$err.log(error)
        }
      }

      finally {
        if (this.request === currentRequest) {
          this.request = null
          this.fetching = false
        }
      }
    },

    async onSearch() {
      this.fetchError = false

      await this.$nextTick()
      if (_.blank(this.search) || !this.fullCNPJ) {
        this.options = []
        return
      }

      this.fetching = true
      this._fetch()
    }
  }
}

</script>

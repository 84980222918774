<style lang="scss" scoped>

.edit-supplier-service-score-settings {
  .header {
    margin-bottom: 24px;

    .title {
      font-family: $secondary-font;
      font-size: 20px;
      font-weight: 500;
    }
  }

  .form {
    .fields {
      .field {
        &.coefficient {
          width: 180px;
        }

        &.strategy {
          width: 380px;
        }

        + .field {
          margin-top: 16px;
        }

        .checkbox-label {
          margin-right: 4px;
        }
      }
    }

    .footer {
      padding-top: 24px;
      margin-top: 24px;
      border-top: 1px solid $light-gray-3;
    }
  }
}

</style>


<template lang="pug">

  .edit-supplier-service-score-settings
    section.header
      h1.title {{ $t('.title') }}

    form.form(@submit.prevent="submit", method="post")
      section.fields
        money-field.coefficient.field(
          name="supplierServiceScoreSettings[priceCoefficient]",
          :label="$t('models.supplierServiceScoreSettings.attributes.priceCoefficient')",
          v-model="resource.priceCoefficient",
          :errors="errors.priceCoefficient",
          :loading="fetching",
          :disabled="submitting",
          :show-currency="false",
          data-testid="priceCoefficient"
        )

        money-field.coefficient.field(
          name="supplierServiceScoreSettings[timeCoefficient]",
          :label="$t('models.supplierServiceScoreSettings.attributes.timeCoefficient')",
          v-model="resource.timeCoefficient",
          :errors="errors.timeCoefficient",
          :loading="fetching",
          :disabled="submitting",
          :show-currency="false",
          data-testid="timeCoefficient"
        )

        money-field.coefficient.field(
          name="supplierServiceScoreSettings[distanceCoefficient]",
          :label="$t('models.supplierServiceScoreSettings.attributes.distanceCoefficient')",
          v-model="resource.distanceCoefficient",
          :errors="errors.distanceCoefficient",
          :loading="fetching",
          :disabled="submitting",
          :show-currency="false",
          data-testid="distanceCoefficient"
        )

        money-field.coefficient.field(
          name="supplierServiceScoreSettings[capacityCoefficient]",
          :label="$t('models.supplierServiceScoreSettings.attributes.capacityCoefficient')",
          v-model="resource.capacityCoefficient",
          :errors="errors.capacityCoefficient",
          :loading="fetching",
          :disabled="submitting",
          :show-currency="false",
          data-testid="capacityCoefficient"
        )

        radio-field.strategy.field(
          name="supplierServiceScoreSettings[distanceStrategy]",
          :label="$t('models.supplierServiceScoreSettings.attributes.distanceStrategy')",
          v-model="resource.distanceStrategy",
          :errors="errors.distanceStrategy",
          :options="distanceStrategyOptions",
          :loading="fetching",
          :disabled="submitting",
          orientation="vertical",
          data-testid="distanceStrategy"
        )

      section.footer.flex.space-between.vertical-center
        app-button(
          :to="showRoute",
          theme="gray",
          outline,
          :disabled="submitting"
        ) {{ $t('btn.cancel') }}

        .submit-actions
          app-button.button(
            type="submit",
            :loading="submitting"
          )
            span {{ $t('.btn.submit.label') }}
            span(slot="loading") {{ $t('.btn.submit.loading') }}

</template>


<script>

// Mixins
import { mixins } from "movida-common.vue"
import FetchMixin from "@/mixins/fetch-mixin"

// View
import LoadedView from "@/views/loaded-view"

// Models
import models from "@/models"
const { SupplierServiceScoreSettings } = models

const { FormMixin } = mixins

export default {
  name: "EditSupplierServiceScoreSettings",

  extends: LoadedView,

  mixins: [FetchMixin, FormMixin],

  data() {
    return {
      i18nScope: "settings.supplier-service-score.edit",
      routeName: "editSupplierServiceScoreSettings",

      resource: {},

      // @override Form Mixin
      dirtyable: true,

      SupplierServiceScoreSettings
    }
  },

  computed: {
    settings: {
      get() { return this.resource },

      set(value) { this.resource = value }
    },

    showRoute() {
      return { name: "supplierServiceScoreSettings" }
    },

    distanceStrategyOptions() {
      return SupplierServiceScoreSettings.$enums.distanceStrategy.values.map(value => ({
        value,
        label: SupplierServiceScoreSettings.$tEnum("distanceStrategy", value)
      }))
    }
  },

  methods: {
    parseRoute() {},

    // @override Fetch Mixin
    fetchRequest() {
      return this.$newSdk.settings.getSupplierServiceScoreSettings()
    },

    // @override Fetch Mixin
    onFetchSuccess({ data, headers }) {
      this.resource = data
      this.initialSerializedAttributes = this.serializeAttributes()
    },

    // @override Form mixin
    serializeAttributes() {
      return this.resource.$serialize()
    },

    // @override Form mixin
    submitRequest() {
      return this.$newSdk.settings.updateSupplierServiceScoreSettings({ params: this.serializeAttributes() })
    },

    // @override Form mixin
    submitSuccess() {
      this.$notifications.info(this.$t(".notifications.submit.success"))

      this.$router.push(this.showRoute)
    },

    // @override Form mixin
    submitError(err) {
      this.$err.log(err)
      this.$notifications.error(this.$t(".notifications.submit.error"))
    }
  }
}

</script>

const FetchMixin = {
  data() {
    return {
      initialized: false,
      // TODO: definir this.state para controlar o componente como uma máquina de estados
      // ref https://github.com/caiena/movida-gmf-desktop.vue/pull/819#discussion_r629721113
      erred:       false,

      fetching:   false,
      fetchError: {},

      autofetch: true,

      fetchRequestPromise: null
    }
  },

  computed: {
    // Carregamento inicial
    initializing() {
      return this.fetching && !this.initialized
    }
  },

  created() {
    if (this.autofetch) this.fetch()
  },

  beforeDestroy() {
    this.cancelRunningFetchRequest({ motive: "Componente destruído" })
  },

  methods: {
    cancelRunningFetchRequest({ motive = "Fetch duplicado" } = {}) {
      if (_.get(this.fetchRequestPromise, "cancel")) this.fetchRequestPromise.cancel(motive)
    },

    async fetch() {
      let currentRequestPromise

      try {
        this.cancelRunningFetchRequest()

        currentRequestPromise = this.fetchRequest()
        this.fetchRequestPromise = currentRequestPromise

        this.fetching = true

        const { data, headers } = await currentRequestPromise

        await this.onFetchSuccess({ data, headers })

        this.fetchError = null
        this.erred = false

        await this.afterFetchSuccess()
      }
      catch (err) {
        await this.onFetchError(err)
        await this.afterFetchError()
      }
      finally {
        await this.afterFetch()

        if (this.fetchRequestPromise === currentRequestPromise) {
          this.fetchRequestPromise = null
          this.fetching = false

          // Componente considerado inicializado na primeira requisição completa, independente do resultado
          this.initialized = true
        }
      }
    },

    fetchRequest() {
      throw new Error("Fetch Mixin - #fetchRequest() - Not implemented yet")
    },

    // Hooks
    onFetchSuccess({ data, headers }) {
      if (!!this.setPagination) this.setPagination(headers)

      this.resource = data
    },

    onFetchError(err) {
      const error = err.error || err

      if (error.cancelled) return

      this.fetchError = err
      this.erred = true

      // Caso seja uma _view_, comunica o erro trocando o componente pela tela de erro
      if (!!this.hasViewError && this.hasViewError(this.fetchError)) {
        this.appError = this.fetchError
      }

      // Caso não haja status é erro de rede
      if (_.blank(error.status)) {
        this.appError = this.fetchError
      }

      this.$err.log(error)
      this.$notifications.error(this.$t(".notifications.fetch.failure"))
    },

    afterFetchSuccess() {},

    afterFetchError() {},

    afterFetch() {}
  }
}

export default FetchMixin

<style lang="scss" scoped>

.vehicle-info-tag {
  width: 542px;
  height: 72px;
  background-color: $light-gray;
  border-radius: 8px;

  .manufacturer-icon-wrapper {
    padding: 12px;
  }

  .vehicle-info {
    .name,
    .year {
      font-family: $secondary-font;
      font-weight: 400;
      font-size: 16px;
    }

    .diamond-icon {
      --size: 6px;
      font-size: var(--size);
      width: var(--size);
      height: var(--size);
      color: $gray-3;
      margin: 10px;

      &.small {
        --size: 4px;
      }
    }

    .row {
      .vehicle-plate {
        padding: 2px 4px;
        margin-right: 8px;

        .value {
          font-weight: 400;
          font-size: 14px;
        }
      }
    }

    .info {
      margin-right: 8px;
      font-family: $secondary-font;
      font-weight: 400;
      color: $gray-4;

      .icon-container {
        height: 20px;
        width: 20px;
        margin-right: 2px;
        font-size: 14px;
        color: $gray-2;
      }

      .label {
        font-weight: 400;
        font-size: 14px;
      }

      .fipe {
        font-family: $primary-monospace-font;
      }
    }
  }
}

</style>


<template lang="pug">

  .vehicle-info-tag.flex.vertical-center
    .flex.center.vertical-center.manufacturer-icon-wrapper
      vehicle-manufacturer-icon(
        :value="manufacturer.name",
        :size="40"
      )

    .vehicle-info
      .row.flex.vertical-center
        app-span.name(:value="name")
        i.diamond-icon.fas.fa-diamond
        app-span.year(:value="vehicle.year")

      .row.flex.vertical-center
        vehicle-plate(:value="vehicle.licensePlate")

        template(v-if="extended")
          .info.flex.vertical-center
            .icon-container.flex.center.vertical-center
              i.icon.fas.fa-swatchbook
            app-span.label(crop :value="vehicle.colorName")

          .info.flex.vertical-center
            .icon-container.flex.center.vertical-center
              i.icon.fas.fa-tachometer-alt-average
            app-span.label(crop :value="kilometrage")

          .info.flex.vertical-center
            .icon-container.flex.center.vertical-center
              i.icon.fas.fa-gas-pump
            template(v-if="isElectric")
              span {{ vehicleModel.type }}
            template(v-else)
              template(v-if="isHybrid")
                span {{ vehicleModel.type }}
                i.diamond-icon.small.fas.fa-diamond
              app-span.label(:value="VehicleModelHybrid.$tEnum('fuel', vehicleModel.fuel)")

        template(v-else)
          .info.flex.vertical-center
            .icon-container.flex.center.vertical-center
              i.icon.fas.fa-tachometer-alt-average
            app-span.label(crop :value="kilometrage")

          .info.flex.vertical-center
            app-span.label.fipe(crop :value="fipe")

</template>


<script>

import VehicleInfoBanner from "@/views/tickets/drafts/components/vehicle-info-banner"

export default {
  name: "VehicleInfoTag",

  extends: VehicleInfoBanner,

  props: {
    extended: { type: Boolean, default: false }
  }
}

</script>

<style lang="scss" scoped>

.vehicle-issue-select-field {
  .error {
    display: block;
    text-align: center;
    padding: 4px 12px;
    font-size: 14px;
    font-style: italic;

    .link {
      margin-left: 5px;
      font-size: 14px;
      font-style: italic;
      font-weight: normal;
    }
  }

  .empty {
    display: block;
    text-align: center;
    padding: 4px 12px;
    font-size: 14px;
    font-style: italic;
  }

  ::v-deep .listed-option + .listed-option {
    border-top: 1px solid $light-gray-3;
  }
}

</style>


<template lang="pug">

  .vehicle-issue-select-field
    select-field(
      :name="name",
      :label="label",
      :fetching="fetching",
      :disabled="disabled",
      :optional="optional",
      :hide-label="hideLabel",

      option-as-value,
      :loading="loading || fetchingOption",
      :options="options",
      :default-filter="false",
      :errors="errors",
      searchable,

      :value="option",
      @input="onSelect",

      @search="onSearch",
      @open="_fetch",
      @close="onClose",
      @inputSearch="(newSearch) => search = newSearch"
    )
      template(#menuContentHeader)
        template(v-if="fetchError")
          label.error
            span {{ $t('.error.message') }}
            app-button.link(theme="link", @click="onSearch") {{ $t('.error.link') }}

      template(#menuContentFooter)
        template(v-if="!fetching && !fetchError && empty")
          label.empty {{ $t('.empty') }}

      template(#selected="{ props: { selected } }")
        vehicle-issue-selected-option(:vehicle-issue="selected.data")

      template(#option="{ props: { option, index, focus, active } }")
        vehicle-issue-option(
          :vehicle-issue="option.data"
          :active="active",
          :focus="focus"
        )

      template(#menuContentFooter)
        template(v-if="!fetching && !fetchError && empty")
          label.empty {{ $t('.empty') }}

</template>


<script>

// Extends
import AsyncSelect from "@/mixins/select/async"

// Mixins
import FieldMixin from "@/mixins/field"

// Components
import VehicleIssueOption from "./vehicle-issue-option.vue"
import VehicleIssueSelectedOption from "./vehicle-issue-selected-option.vue"

export default {
  components: {
    VehicleIssueOption,
    VehicleIssueSelectedOption
  },

  extends: AsyncSelect,

  mixins: [FieldMixin],

  props: {
    hideLabel: { type: Boolean, default: false }
  },

  data() {
    return {
      i18nScope: "tickets.drafts.contents.services.components.vehicle-issue-select-field",

      fetchingOption: false,
      fetchError:     false
    }
  },

  methods: {
    // @overrides extends AsyncSelect
    dataToOption(data) {
      return {
        value: data.id,
        label: data.id,
        data
      }
    },

    // @overrides extends AsyncSelect
    fetchRequest() {
      return this.$newSdk.vehicleIssues.all({ params: { q: this.search } })
    },

    // @overrides extends AsyncSelect
    onFetchSuccess({ data }) {
      const fetchedOptions = data.map(instance => (
        this.dataToOption(instance)
      ))

      this.options =  [...fetchedOptions]
    },

    // @overrides extends AsyncSelect
    onFetchError(error) {
      this.$err.log(error)
    }
  }
}

</script>

// Base
import BaseRequests from "../../Base"

// Models
import models from "@/models"

const { ServiceOrderPostponement } = models


class MonitoringServiceOrderPostponementRequests extends BaseRequests {
  static get selector() {
    return "monitoring.serviceOrders.postponement"
  }

  // serviceOrderId: id or rid
  create(serviceOrderId, { params = {}, options = {} } = {}) {
    this.verifyData("create", { serviceOrderId })

    return this.action({
      method:  "post",
      url:     `/monitoring/service_orders/${serviceOrderId}/postponement`,
      params,
      options: {
        model: ServiceOrderPostponement,

        ...options
      }
    })
  }


  // serviceOrderId: id or rid
  find(serviceOrderId, { params = {}, options = {} } = {}) {
    this.verifyData("find", { serviceOrderId })

    return this.action({
      method:  "get",
      url:     `/monitoring/service_orders/${serviceOrderId}/postponement`,
      params,
      options: {
        model: ServiceOrderPostponement,

        ...options
      }
    })
  }

}


export default MonitoringServiceOrderPostponementRequests

<style lang="scss" scoped>

.mobiauto-version-table {
  .skeleton-table {
    margin-top: 16px;

    .skeleton-row + .skeleton-row {
      margin-top: 24px;
    }
  }

  .empty {
    margin-top: 24px;

    .message {
      font-style: italic;
      font-weight: 400;
    }

    .icon {
      user-select: none;
      margin-bottom: 4px;
      height: 88px;
      width: 88px;
    }
  }

  .list {
    margin-top: 16px;
  }
}

</style>


<template lang="pug">

  .mobiauto-version-table
    template(v-if="initializing")
      .skeleton-table
        mobiauto-version-row-skeleton.skeleton-row(
          v-for="i in 5",
          :key="`mobiauto-version-row-skeleton-${i}`"
        )

    template(v-else)
      mobiauto-versions-table-header(
        :loading="loading",
        @sort="param => $emit('sort', param)"
        :sort-param="sortParam",
        :sort-order="sortOrder",
        data-testid="vehicleModelsTableHeader"
      )

      template(v-if="empty")
        .empty.flex.center
          .flex.column-direction.vertical-center
            img.icon(:src="emptyIcon")
            span.message {{ $t('index.empty.message') }}

      template(v-else)
        mobiauto-version-row(
          v-for="mobiautoVersion in mobiautoVersions",
          :key="mobiautoVersion.$get('vehicleModel.id')",
          :mobiautoVersion="mobiautoVersion",
          :loading="loading",
          @destroy="$emit('changed')",
          @disabled="$emit('changed')",
          @enabled="$emit('changed')",
          data-testid="mobiautoVersionRow"
        )

</template>


<script>

// Assets
import emptyIcon from "@/assets/images/icons/empty-list.svg"

// Components
import MobiautoVersionRow from "./mobiauto-version-row.vue"
import MobiautoVersionRowSkeleton from "./mobiauto-version-row-skeleton"
import MobiautoVersionsTableHeader from "./mobiauto-versions-table-header.vue"

export default {
  components: {
    MobiautoVersionRow,
    MobiautoVersionRowSkeleton,
    MobiautoVersionsTableHeader
  },

  props: {
    mobiautoVersions: { type: Array, required: true },
    initializing:     { type: Boolean, default: false },
    loading:          { type: Boolean, default: false },
    sortedBy:         { type: String, default: "" }
  },

  data() {
    return {
      i18nScope: "vehicle-models.index.mobiauto-versions-table",

      emptyIcon
    }
  },

  computed: {
    empty() {
      return _.blank(this.mobiautoVersions)
    },

    sortParam() {
      return this.sortedBy.split(":")[0]
    },

    sortOrder() {
      return this.sortedBy.split(":")[1] || "asc"
    }
  }
}

</script>

<style lang="scss" scoped>

.initials-tag {
  font-family: $secondary-font;
  font-weight: 400;
  font-size: 14px;
  padding: 3.5px 7px;
  border-radius: 80px;
  margin: 0 16px;


  // themes
  // ---

  &.blue {
    background-color: #DDF3FF;
    color: #0F6DC5;
  }

  &.brown {
    background-color: #EFE1DC;
    color: #6A4646;
  }

  &.gray {
    background-color: #E7E7E7;
    color: #464646
  }

  &.orange {
    background-color: #FFF0E2;
    color: #DB6B1A;
  }

  &.pink {
    background-color: #FFDDFC;
    color: #CB36A1;
  }

  &.purple {
    background-color: #ECDDFF;
    color: #8A36CC;
  }

  &.red {
    background-color: #FFEAEA;
    color: #B01A1A;
  }

  &.yellow {
    background-color: #FFF8D0;
    color: #C29800;
  }


}

</style>


<template lang="pug">

  span.initials-tag(v-tooltip.bottom="name",  :class="theme")
    | {{ initials }}

</template>


<script>

// @util
// hashes a string to an integer (positive)
function hashNumber(string) {
  /* eslint-disable no-bitwise */
  let hash = 0

  for (let i = 0; i < string.length; i++) {
    let code = string.charCodeAt(i)
    hash = ((hash << 5) - hash) + code
    hash &= hash // Convert to 32bit integer
  }

  return Math.abs(hash)
  /* eslint-enable no-bitwise */
}

const THEMES = [
  "blue",
  "brown",
  "gray",
  "orange",
  "pink",
  "purple",
  "red",
  "yellow"
]



export default {
  props: {
    name: { type: String, required: true },
    // XXX: use um `id` como :seed para "fixar" a cor das iniciais. (ex: `:seed="user.id"`)
    seed: { type: [Number, String], default: () => Math.floor(Math.random() * 100) }
  },

  data() {
    return {
      i18nScope: "monitoring.initials-tag"
    }
  },

  computed: {
    initials() {
      let names = this.name.replace(/[^\w\s]/g, "").replace(/\s+/g, " ").trim().split(" ")

      if (_.blank(names)) return "?"

      let firstName = names[0]
      let lastName  = names.length > 1 ? names.pop() : ""

      let initials = firstName.charAt(0)
      if (_.present(lastName)) initials += lastName.charAt(0)

      return initials.toLocaleUpperCase()
    },

    theme() {
      let index = hashNumber(`${this.name}${this.seed}`) % THEMES.length

      return THEMES[index]
    }
  }

}

</script>

<style lang="scss" scoped>

.vehicle-search-step {
  .header {
    margin-bottom: 24px;

    .title,
    .subtitle {
      font-family: $secondary-font;
      font-weight: 500;
    }

    .title {
      font-size: 38px;
    }

    .subtitle {
      font-size: 20px;
      margin-bottom: 8px;
    }
  }

  .section {
    & + .section {
      border-top: 1px solid $light-gray-3;
      padding-top: 24px;
      margin-top: 24px;
    }

    &.footer {
      .cancel {
        color: $gray-3;

        &:hover {
          color: $orange;
        }
      }

      .icon {
        margin-left: 8px;
      }
    }
  }
}

</style>


<template lang="pug">

  .vehicle-search-step
    .header
      h2.subtitle {{ $t('.subtitle') }}
      h1.title {{ $t('.title') }}

    form.form(ref="form", method="get", @submit.prevent="clearAndSubmit")
      .section.body
        vehicle-search-form(
          :search-errors="searchErrors",
          :vehicle="vehicle"
          :params="resource",
          :errors="errors",
          :loading="submitting"
        )

      .section.footer.flex.space-between
        app-button.cancel(
          theme="link",
          :to="{ name: 'tickets' }"
        ) {{ $t('.btn.cancel') }}

        app-button.search(
          :loading="submitting",
          @click="clearAndSubmit"
        )
          span {{ $t('.btn.search') }}
          i.icon.far.fa-search

</template>


<script>

// Mixins
import FormMixin from "@/mixins/form-mixin"

// Components
import VehicleSearchForm from "./vehicle-search-form.vue"

// Models
import models from "@/models"
const { Base } = models

class Search extends Base {
  static get attrs() {
    return ["q"]
  }

  static get constraints() {
    return {
      q: {
        presence: true,
        type:     "string",
        length:   { is: 7, message: "placa incompleta" }
      }
    }
  }
}

export default {
  components: {
    VehicleSearchForm
  },

  mixins: [FormMixin],

  props: {
    licensePlate: { type: String, default: null }
  },

  data() {
    return {
      i18nScope: "tickets.drafts.new.vehicle-search-step",

      guardFormOnLeave: false,
      resource:         new Search(),
      vehicle:          null,

      searchErrors: {
        vehicleNotFound: false,
        wrongState:      false,
        withoutModel:    false,
        forbidden:       false,
        notRac:          false
      }
    }
  },

  created() {
    if (_.present(this.licensePlate)) {
      this.resource.q = this.licensePlate

      this.clearAndSubmit()
    }
  },

  methods: {
    clearAndSubmit() {
      this.searchErrors = {
        vehicleNotFound: false,
        wrongState:      false,
        withoutModel:    false,
        forbidden:       false,
        notRac:          false
      }

      this.submit()
    },

    // @override Form mixin
    submitRequest() {
      return this.$newSdk.vehicles.find({ licensePlateOrId: this.resource.q })
    },

    // @override Form mixin
    onSubmitSuccess({ data: vehicle }) {
      this.vehicle = vehicle
      const validStates = ["corrective_maintenance", "damaged"]

      if (!validStates.includes(vehicle.state)) this.searchErrors.wrongState = true
      else if (!_.present(vehicle.vehicleModel)) this.searchErrors.withoutModel = true
      else if (!vehicle.currentStoreRac) this.searchErrors.notRac = true

      else this.$emit("input", vehicle)
    },

    // @override Form mixin
    onSubmitError(err) {
      const error = err.error || err

      if (error.cancelled) return

      this.submitError = error
      this.submitErred = true

      if (error.originalError) this.setFormErrors(error.originalError)

      if (error.status === 404) this.searchErrors.vehicleNotFound = true
      else if (error.status === 403) this.searchErrors.forbidden = true
      else {
        this.$notifications.error(this.$t(".notifications.submit.error"))

        this.$err.log(this.submitError)
      }
    }
  }
}

</script>

import Base from "./base"

class Sector extends Base {
  static get attrs() {
    return _.uniq([
      ...super.attrs,
      "name",
      "description"
    ])
  }

  static get relations() {
    return {
      suppliers: { type: "hasMany", model: "Supplier" }
    }
  }

  // static get enums() {}

  static get constraints() {
    return {
      name:        { presence: true, type: "string", length: { maximum: 1000 } },
      description: { type: "string", length: { maximum: 10000 } }
    }
  }

  get isDealership() {
    return _.canonic(this.name).includes("concessionaria")
  }
}

export default Sector

import { i18n } from "utils.vue"
import { v4 as uuid } from "uuid"

import Base from "./base"
import SupplierBusinessHour from "@/models/supplier-business-hour"

class UpdateBusinessHoursToDefaultService {
  constructor(businessHours) {
    this.businessHours = businessHours || []
  }

  generate() {
    const validBusinessHours = []

    for (const shift of Object.keys(this.shifts)) {
      const { startAt } = this.shifts[shift]
      const { endAt } = this.shifts[shift]

      for (const weekday of this.weekdays) {
        // Pega horário no turno e dia especifico, caso exista
        let currentBusinessHour = this.businessHours
          .filter(businessHour => businessHour.shift === shift)
          .filter(businessHour => businessHour.weekday === weekday)[0]

        // Atualiza ou cria businessHour
        if (currentBusinessHour) {
          currentBusinessHour.startAt = startAt
          currentBusinessHour.endAt = endAt
          currentBusinessHour._destroy = false
        }
        else {
          currentBusinessHour = this.createBusinessHour({
            startAt,
            endAt,
            shift,
            weekday
          })
        }

        validBusinessHours.push(currentBusinessHour)
      }
    }

    const removedBusinessHours = this.removeInvalidHours()

    return [...validBusinessHours, ...removedBusinessHours]
  }

  createBusinessHour(params) {
    const businessHour = new SupplierBusinessHour(params)
    businessHour.tempId = uuid()

    return businessHour
  }

  removeInvalidHours() {
    return this.invalidBusinessHours.map(businessHour => {
      businessHour._destroy = true
      return businessHour
    })
  }

  get invalidBusinessHours() {
    return this.businessHours.filter(
      businessHour => businessHour.shift === "evening"
        || ["saturday", "sunday"].includes(businessHour.weekday)
    )
  }

  get weekdays() {
    return ["monday", "tuesday", "wednesday", "thursday", "friday"]
  }

  get shifts() {
    return {
      morning:   { startAt: "08:00", endAt: "12:00" },
      afternoon: { startAt: "12:00", endAt: "17:00" }
    }
  }
}

class SupplierDetails extends Base {

  static get attrs() {
    return _.uniq([
      ...super.attrs,
      "rac",
      "racMensal",
      "racApp",
      "gtf",
      "seminovos",
      "premium",
      "zeroKm",
      "nationwideSupport",
      "roundTheClock",

      "internalFacilities",
      "supplierFacilities",

      "internalCustomer",
      "externalCustomer",
      "movidaApp",
      "pickupService",
      "rabbotPlataform",
      "schedulingNotice",
      "receivesTowedVehicles"
    ])
  }

  $beforeInit(...args) {
    if (args && args[0] && _.present(args[0].schedulingNotice)) {
      this.hasSchedulingNotice = true
    }
  }

  static get relations() {
    return {
      supportedCities: { type: "hasMany", model: "City" },
      city:            { type: "hasOne", model: "City" },
      businessHours:   { type: "hasMany", model: "SupplierBusinessHour" }
    }
  }

  static get virtuals() {
    return [
      "hasSchedulingNotice"
    ]
  }

  static get constraints() {
    return {
      schedulingNotice: {
        presence(value, attrs) {
          return !!attrs.hasSchedulingNotice
        }
      }
    }
  }

  get formattedSchedulingNotice() {
    if (_.blank(this.schedulingNotice)) return null

    return i18n.t("models.supplierDetails.formattedAttributes.schedulingNotice", {
      count: this.schedulingNotice
    })
  }

  setDefaultBusinessHours() {
    const updateBusinessHoursToDefaultService = new UpdateBusinessHoursToDefaultService(this.businessHours)
    this.businessHours = updateBusinessHoursToDefaultService.generate()
  }
}

export default SupplierDetails

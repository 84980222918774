<style scoped lang="scss">

.products {
  border: 1px solid $light-gray-3;
}

::v-deep .tag {
  margin-left: 8px;
}

</style>


<template lang="pug">

  .products
      product-row(
        v-for="product in collection",
        :key="product.id",
        :option="product",
        data-testid="productRow"
      )

</template>


<script>

// Local Components
import ProductRow from "./product-row"

// Models
import models from "@/models"

const { Product } = models

export default {
  name: "MaintenancePackageProducts",

  components: {
    ProductRow
  },

  props: {
    collection: { type: Array, default: () => [] }
  },

  data() {
    return {
      i18nScope: "suppliers.deals.maintenance-packages.maintenance-package.products.index",

      Product
    }
  },

  computed: {
    empty() {
      return _.blank(this.collection)
    }
  }
}

</script>

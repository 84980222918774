<style lang="scss" scoped>

.service-row {
  display: grid;
  grid-template-columns: var(--grid-columns);

  .column {
    padding: 12px;
    border-bottom: 1px solid $light-gray-3;
    overflow: hidden;

    &.total-price-column {
      flex-direction: column;
      align-items: flex-end;
    }

    .empty {
      font-family: $primary-monospace-font;
      font-weight: 400;
      font-size: 14px;
      color: $gray-2;
    }

    .label {
      font-family: $secondary-font;
      font-size: 14px;
      font-weight: 400;
    }

    .sap-id,
    .currency {
      font-family: $primary-monospace-font;
      font-weight: 400;
      font-size: 14px;
    }

    .discount {
      display: block;
      font-size: 12px;
      color: $gray-3;
      line-height: 16px;
    }
  }
}

</style>


<template lang="pug">

  .service-row(:style="styles")
    .column.type-column.flex.vertical-center
      app-span.sap-id(:value="service.sapId")

    .column.service-column.flex.column-direction.center
      app-span.label(:value="service.description")

    .column.quantity-column.flex.vertical-center.end
      app-span.label(:value="service.quantity")

    .column.price-column.flex.vertical-center.end
      app-span.currency(:value="price")

    .column.total-price-column.flex.vertical-center.end
        template(v-if="hasDiscount")
          app-span.currency.discount(:value="discount")
        app-span.currency(:value="totalPrice")

</template>


<script>

export default {
  props: {
    service:     { type: Object, required: true },
    columnSizes: { type: String, required: true }
  },

  data() {
    return {
      i18nScope: "service-orders.edit.services-table.service-row",

      showPopover: false
    }
  },

  computed: {
    styles() {
      return {
        "--grid-columns": this.columnSizes
      }
    },

    price() {
      return this.$i18n.l("currency", this.service.price, { unit: "R$" })
    },

    hasDiscount() {
      return this.service.discount > 0
    },

    discount() {
      return `- ${parseFloat(this.service.discount)}`
    },

    totalPrice() {
      return this.$i18n.l("currency", this.service.totalPrice, { unit: "R$" })
    }
  }
}

</script>

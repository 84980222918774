<style lang="scss" scoped>

.user-button {
  height: $navbar-height;
  background-color: $white;

  &:hover {
    background-color: $light-brand;
    ::v-deep .button .border {
      border-color: $orange;
    }
  }

  &:active {
    background-color: $light-brand-2;
  }
}

::v-deep .button {
  border-left: 1px solid $light-gray-4;
  border-radius: 0;
  height: $navbar-height;
  padding: 0 24px 0 16px;

  .lines {
    width: 124px;
    margin-left: 8px;
  }

  .name {
    color: $text;
    font-weight: bold;
  }

  &:hover {
    .name, .role {
      color: $primary;
    }
  }

  .info {
    text-align: left;
    margin-left: 8px;
  }

  .role {
    font-size: 14px;
    display: block;
    color: $text;
  }
}

::v-deep.user-modal {
  .user-info {
    text-align: center;
    margin-top: 16px;
  }

  .icon {
    margin-right: 8px;
  }

  .action-button {
    margin-top: 16px;
  }

  .role {
    font-size: 14px;
    display: block;
    color: $gray-2;
  }
}

</style>


<template lang="pug">

  .user-button.flex.vertical-center
    app-button.button(theme="link", :disabled="submitting", @click="showModal = true")
      .flex.space-between.vertical-center
        avatar(:size="40", :border="2")
        loading-lines.lines(v-if="submitting", :min="100", :max="100")
        .info(v-else)
          span.name {{ user.firstName }}

    transition(name="fade")
      app-modal(
        v-if="showModal"
        :width="385",
        :header="false",
        @close="showModal = false"
      )
        .user-modal.body
          .flex.center
            avatar(:size="168")
          .user-info
            label {{ user.name }}

          .actions
            app-button.action-button.full-width(
              theme="primary",
              outline,
              disabled
            )
              .flex.center.vertical-center
                i.fal.fa-cog.icon
                span {{ $t('.btn.settings') }}
            app-button.action-button.full-width(
              theme="primary",
              outline,
              :disabled="submitting",
              @click="logout"
            )
              .flex.center.vertical-center
                i.fal.fa-sign-out.icon
                span {{ $t('.btn.logout') }}

</template>


<script>

// Modules
import auth from "@/modules/auth"

export default {
  name: "UserButton",

  data() {
    return {
      i18nScope: "components.navbar.user-button",

      showModal:  false,
      submitting: false,

      user: auth.user
    }
  },

  methods: {
    async logout() {
      if (this.submitting) return

      this.submitting = true

      await this.$sdk.auth.logout().catch(() => {})
      this.$auth.logout()

      this.submitting = false
      this.showModal = false

      this.$router.push({ name: "login" })
    }
  }
}

</script>

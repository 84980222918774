// Base
import BaseRequests from "./Base"

// Models
import models from "@/models"
const { VehicleModelGroupMaintenanceTable } = models

class VehicleModelGroupMaintenanceTablesRequests extends BaseRequests {
  static get selector() {
    return "vehicleModelGroupMaintenanceTables"
  }

  all({ params = {}, options = {} } = {}) {
    return this.action({
      // Estamos usando POST pois o filtro por ids pode ser muito grande e estourar o limite da URL.
      method:  "post",
      url:     `/vehicle_model_group_maintenance_tables/search`,
      params,
      options: {
        model: VehicleModelGroupMaintenanceTable,

        ...options
      }
    })
  }
}

export default VehicleModelGroupMaintenanceTablesRequests

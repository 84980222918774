<style lang="scss" scoped>

.new-service-package {
  padding-bottom: 24px;

  .header {
    margin-bottom: 32px;
    padding-bottom: 16px;
    border-bottom: 1px solid $light-gray-3;

    .title {
      font-size: 30px;
      font-weight: 500;
      font-family: $secondary-font;
    }
  }

  .fields {
    margin-bottom: 24px;
    padding-bottom: 24px;
    border-bottom: 1px solid $light-gray-3;
  }
}

</style>


<template lang="pug">

  .new-service-package
    header.header
      h1.title {{ $t('.title') }}

    form.form(@submit.prevent="submit", method="post")
      form-fields.fields(
        :resource="resource",
        :submitting="submitting",
        :errors="errors"
      )

      section.flex.space-between.vertical-center
        app-button(
          :to="{ name: 'servicePackages' }",
          theme="gray",
          outline,
          :disabled="submitting"
        ) {{ $t('btn.cancel') }}

        app-button.button(
          type="submit",
          :loading="submitting",
          :disabled="submitting",
          data-test="submitButton"
        )
          span {{ $t('.btn.submit.label') }}
          span(slot="loading") {{ $t('.btn.submit.loading') }}

</template>


<script>

// Mixins
import { mixins } from "movida-common.vue"

// Components
import FormFields from "./form-fields"

// Extends
import View from "@/views/view"

// Models
import models from "@/models"

const { ServicePackage } = models
const { FormMixin } = mixins

export default {
  name: "NewServicePackage",

  components: {
    FormFields
  },

  extends: View,

  mixins: [FormMixin],

  data() {
    return {
      i18nScope: "service-packages.new",

      resource: new ServicePackage(),
      model:    "ServicePackage"
    }
  },

  computed: {
    services() {
      return this.resource.services
    },

    products() {
      return this.resource.products
    }
  },

  watch: {
    services() {
      this.resource.serviceIds = this.resource.services.map(service => service.id)
    },

    products() {
      this.resource.productIds = this.resource.products.map(product => product.id)
    }
  },

  methods: {
    // @override Form mixin
    submitRequest() {
      return this.$sdk.servicePackages.create({ params: this.resource.$serialize() })
    },

    // @override Form mixin
    submitSuccess({ data: { id } }) {
      this.$notifications.info(this.$t(".notifications.submit.success", { name: this.resource.name }))

      this.$router.push({ name: "servicePackage", params: { id } })
    },

    // @override Form mixin
    submitError(err) {
      this.$err.log(err)
      this.$notifications.error(this.$t(".notifications.submit.error"))
    }
  }
}

</script>

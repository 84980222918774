<style lang="scss" scoped>

.maintenance-table {
  padding: 24px;

  .header {
    margin-bottom: 24px;
  }

  .table-name {
    font-size: 20px;
    font-weight: 500;
    font-family: $secondary-font;
    word-break: normal;
    overflow-wrap: break-word;
  }

  .icon {
    font-size: 16px;
    margin-right: 8px;
  }

  .description-content {
    .description {
      font-size: 16px;
      font-weight: normal;
      margin: 0;
    }
  }

  .maintenances {
    font-family: $secondary-font;
    font-size: 20px;
    font-weight: 500;
    line-height: 24px;
    padding: 24px 0;
  }

  .table-content {
    padding-top: 24px;
  }

  .info {
    padding-bottom: 24px;
    border-bottom: 1px solid $light-gray-3;
  }

  .info-label + .info-label {
    margin-left: 40px;
  }
}

</style>


<template lang="pug">

  .maintenance-table
    template(v-if="fetching")
      loading-lines(:lines="5")
    template(v-else)
      header.header
        .flex.vertical-center
          .flex.vertical-center
            h2.table-name {{ resource.name }}

        section.description-content.preserve-whitespace(v-if="resource.description")
          p.description {{ resource.description }}

      section.info.flex.vertical-center
        info-label.info-label(
          :loading="fetching",
          :label="$t('.fields.kilometrageLimitTolerance.label')",
          :value="resource.formattedKilometrageLimitTolerance",
          :empty-text="$t('unregistered.feminine')",
        )
        info-label.info-label(
          :loading="fetching",
          :label="$t('.fields.timeLimitTolerance.label')",
          :value="resource.formattedTimeLimitTolerance",
          :empty-text="$t('unregistered.feminine')"
        )
        maintenance-table-kind-tag.info-label(:kind="resource.kind")

      .table-content
        maintenances-table(
          :empty="empty",
          :loading="fetching",
          :show-destroy-column="false",
        )
          maintenance-row(
            v-for="(maintenance, index) in resource.vehicleModelGroupMaintenances",
            :key="maintenance.id",
            :resource="maintenance",
            :order="index + 1"
          )

          .empty(slot="empty")
            span {{ $t('.empty') }}

</template>


<script>

// Mixins
import { mixins } from "movida-common.vue"

// View
import View from "@/views/view"

// Components
import MaintenancesTable from "@/views/vehicle-model-groups/maintenance-tables/components/maintenances-table"
import MaintenanceRow from "@/views/vehicle-model-groups/maintenance-tables/components/maintenance-row"

const { Fetchable } = mixins

export default {
  name: "SupplierServiceSupportMaintenanceVehicleModelGroupMaintenanceTable",

  components: {
    MaintenancesTable,
    MaintenanceRow
  },

  extends: View,

  mixins: [Fetchable],

  props: {
    vehicleModelGroupId: { type: [String, Number], required: true },
    maintenanceTableId:  { type: [String, Number], required: true }
  },

  data() {
    return {
      i18nScope: "suppliers.service-supports.maintenance.vehicle-model-groups.maintenance-table.show",

      resource: {},

      model: "VehicleModelGroupMaintenanceTable"
    }
  },

  computed: {
    empty() {
      return _.blank(this.resource, "vehicleModelGroupMaintenances")
    }
  },

  methods: {
    // @override Fetchable mixin
    fetch() {
      return this.$sdk.vehicleModelGroups.getMaintenanceTable({
        id:                 this.vehicleModelGroupId,
        maintenanceTableId: this.maintenanceTableId
      })
    },

    // @override Fetchable mixin
    // onFetchSuccess(response) {},

    // @override Fetchable mixin
    onFetchError(err) {
      if (this.hasViewError(err)) {
        this.appError = err
      }

      this.$err.log(err)
      this.$notifications.error(this.$t(".notifications.fetch.failure"))
    }
  }
}

</script>

const SdkPlugin = {
  installed: false,

  install(Vue, { sdkClient }) {
    if (SdkPlugin.installed) return
    SdkPlugin.installed = true

    Vue.$http = sdkClient.http
    Vue.prototype.$http = sdkClient.http

    Vue.$newSdk = sdkClient.requests
    Vue.prototype.$newSdk = sdkClient.requests
  }
}

export default SdkPlugin

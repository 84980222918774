<style scoped lang="scss">

.section-title{
  padding-bottom: 24px;
}

.title {
  font-family: $secondary-font;
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
}

.title-icon {
  margin-right: 8px;
  font-size: 24px;
}

.subtitle {
  font-size: 14px;
  font-weight: 500;
  font-family: $secondary-font;
  margin-bottom: 8px;
  display: block;
}

.row {
  margin-bottom: 8px;
}

.column {
  padding: 16px 0px;
}

.location{
  margin-top: 42px;
}

.list {
  margin-top: 24px;
}

.section{
  margin-bottom: 24px;
  border-top: 1px solid $light-gray-3;
}

</style>


<template lang="pug">

  .form-fields
    ui-row.row
      ui-col(:span="7")
        input-field.row(
          name="vehiclePart[name]",
          v-model="resource.name",
          :loading="submitting",
          :disabled="fetching",
          :errors="errors.name",
          :placeholder="$t('.fields.name.placeholder')",
          data-testid="nameInput"
        )

        textarea-field.column(
          name="vehiclePart[description]",
          v-model="resource.description",
          :loading="submitting",
          :disabled="fetching",
          :errors="errors.description",
          :placeholder="$t('.fields.description.placeholder')",
          optional,
          data-testid="descriptionInput"
        )
      ui-col(:span="5")
        label.subtitle {{ $t('.heading.position.label')}}

        ui-row.row
          ui-col(:span="4")
            checkbox-field(
              name="vehiclePart[frontPosition]",
              v-model="resource.frontPosition",
              :loading="submitting",
              :disabled="fetching",
              :errors="errors.frontPosition"
            )
          ui-col(:span="4")
            checkbox-field(
              name="vehiclePart[centralPosition]",
              v-model="resource.centralPosition",
              :loading="submitting",
              :disabled="fetching",
              :errors="errors.centralPosition"
            )
          ui-col(:span="4")
            checkbox-field(
              name="vehiclePart[rearPosition]",
              v-model="resource.rearPosition",
              :loading="submitting",
              :disabled="fetching",
              :errors="errors.rearPosition"
            )

        .location
          label.subtitle {{ $t('.heading.location.label')}}
          ui-row.row
            ui-col(:span="4")
              checkbox-field(
                name="vehiclePart[rightLocation]",
                v-model="resource.rightLocation",
                :loading="submitting",
                :disabled="fetching",
                :errors="errors.rightLocation"
              )
            ui-col(:span="4")
              checkbox-field(
                name="vehiclePart[internalLocation]",
                v-model="resource.internalLocation",
                :loading="submitting",
                :disabled="fetching",
                :errors="errors.internalLocation"
              )
            ui-col(:span="4")
              checkbox-field(
                name="vehiclePart[bottomLocation]",
                v-model="resource.bottomLocation",
                :loading="submitting",
                :disabled="fetching",
                :errors="errors.bottomLocation"
              )
          ui-row
            ui-col(:span="4")
              checkbox-field(
                name="vehiclePart[leftLocation]",
                v-model="resource.leftLocation",
                :loading="submitting",
                :disabled="fetching",
                :errors="errors.leftLocation"
              )
            ui-col(:span="4")
              checkbox-field(
                name="vehiclePart[externalLocation]",
                v-model="resource.externalLocation",
                :loading="submitting",
                :disabled="fetching",
                :errors="errors.externalLocation"
              )
            ui-col(:span="4")
              checkbox-field(
                name="vehiclePart[topLocation]",
                v-model="resource.topLocation",
                :loading="submitting",
                :disabled="fetching",
                :errors="errors.topLocation"
              )

    .section
    ui-row.row
      ui-col(:span="12")
        .section-title.flex.vertical-center
          i.title-icon.fal.fa-engine-warning
          h2.title {{ $t('.fields.symptoms.label') }}

        symptom-multiple-select-field(
          name="vehiclePart[symptoms]",
          v-model="symptoms",
          :placeholder="$t('.fields.symptoms.placeholder')",
          :loading="submitting",
          :disabled="fetching",
          :errors="errors.symptoms",
          icon-left="far fa-search-plus",
          hide-label
        )

    ui-row
      ui-col(:span="12")
        symptoms-list(
          :loading="fetching",
          :symptoms="resource.symptoms",
          :empty-text="$t('.fields.symptoms.empty')"
          destroyable,
          @destroy="onRemoveSymptom"
        )

</template>


<script>

import SymptomsList from "../components/symptoms-list"
import models from "@/models"

export default {
  components: {
    SymptomsList
  },

  props: {
    fetching:   { type: Boolean, default: false },
    submitting: { type: Boolean, default: false },
    errors:     { type: Object, default: () => ({}) },
    resource:   { type: Object, default: () => ({}) }
  },

  data() {
    return {
      i18nScope: "vehicle-parts.form.form-fields"
    }
  },

  computed: {
    hasSymptom() {
      return _.present(_.get(this.resource, "symptoms"))
    },

    symptoms: {
      get() {
        if (!this.hasSymptom) return []

        return this.resource.symptoms.map(symptom => ({
          value: symptom.id,
          label: symptom.name,
          data:  new models.Symptom(symptom)
        }))
      },

      set(symptoms) {
        // eslint-disable-next-line vue/no-mutating-props
        this.resource.symptoms = symptoms.map(symptom => symptom.data)
      }
    }
  },

  methods: {
    onRemoveSymptom(removedSymptom) {
      const symptoms = [...this.resource.symptoms]
      const symptomIndex = symptoms.findIndex(symptom => symptom.id === removedSymptom.id)

      if (symptomIndex < 0) return

      symptoms.splice(symptomIndex, 1)
      // eslint-disable-next-line vue/no-mutating-props
      this.resource.symptoms = symptoms
    }
  }
}

</script>

<!--
<doc lang="markdown">
Exibe situação (ativo, inativo), prefixado por um icone referente a situação

_Props_: value

Como usar:
```pug
  supplier-status-tag(:value="true")
```
</doc>
-->


<style lang="scss" scoped>

.supplier-status-tag {
  background: $gray-3;
  padding: 2px 8px;
  border-radius: 32px;
  font-family: $secondary-font;
  color: $white;
  height: 24px;
  font-weight: 500;
  user-select: none;

  &.active {
    background: $green;

    &.accent {
      background: $dark-green-2;
    }
  }

  .icon {
    margin-right: 4px;
  }
}

</style>


<template lang="pug">

  .supplier-status-tag.flex.vertical-center(
    v-tooltip.bottom="tooltip",
    :class="{ active: value, accent }"
  )
    i.icon(:class="icon")
    slot {{ text }}

</template>


<script>

export default {
  name: "SupplierStatusTag",

  props: {
    value:   { type: Boolean, default: false },
    tooltip: { type: String, default: null },
    accent:  { type: Boolean, default: false }
  },

  data() {
    return {
      i18nScope: "components.supplier.supplier-status-tag"
    }
  },


  computed: {
    color() {
      if (this.value) return "green"
      return "gray"
    },

    text() {
      if (this.value) return this.$t(".active")
      return this.$t(".inactive")
    },

    icon() {
      if (this.value) return "fas fa-check-circle"
      return "fas fa-minus-circle"
    }
  }
}

</script>

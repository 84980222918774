<style lang="scss" scoped>

.monitoring {
  .header {
    .title {
      font-size: 30px;
      font-weight: 500;
      font-family: $secondary-font;

      .icon {
        font-size: 24px;
        margin-right: 18px;
      }
    }
  }

  .separator {
    border: none;
    border-top: 1px solid $light-gray-3;
    margin: 24px 0;
  }

  .list {
    .table {
      margin-top: 16px;
    }
  }
}

</style>


<template>
  <div class="monitoring">
    <header class="header">
      <h1 class="title">
        <i class="icon fas fa-desktop"></i>
        {{ $t('.title') }}
      </h1>
    </header>

    <hr class="separator" />

    <search-form
        class="search"
        :has-filters="hasFilters"
        :loading="fetching"
        :params="parsedQueryParams"
        @clear="clearFilters"
        @quick-filter="onQuickFilter"
        @search="search"
    ></search-form>

    <hr class="separator" />

    <div class="list">
      <service-orders-table-toolbar
          :can-assign="$can('create', 'ServiceOrderAssignment')"
          :fetching="fetching"
          :has-selected="hasSelected"
          :initializing="initializing"
          :monitoring="parsedQueryParams.monitoring"
          :page="parsedQueryParams.page"
          :pagination="pagination"
          :sort="parsedQueryParams.sort"
          :timeframe="parsedQueryParams.timeframe"
          @click:assign="showAssignmentModal = true"
          @update:monitoring="(value) => onParamChange('monitoring', value)"
          @update:page="onPageChange"
          @update:sort="onSortChange"
          @update:timeframe="(value) => onParamChange('timeframe', value)"
      ></service-orders-table-toolbar>

      <service-orders-table
          class="table"
          :error="erred"
          :empty="empty"
          :initializing="initializing"
          :loading="fetching"
          :selected.sync="selected"
          :service-orders="serviceOrders"
          :sorted-by="parsedQueryParams.sort"
          @sort="onSortChange"
          @changed="fetch"
          @cancel="(serviceOrder) => openModal('service-order-cancellation', serviceOrder)"
          @create-vehicle-receipt="(serviceOrder) => openModal('vehicle-receipt', serviceOrder)"
          @execute="(serviceOrder) => openModal('service-order-execution', serviceOrder)"
          @postpone="(serviceOrder) => openModal('new-postponement', serviceOrder)"
          @reopen="(serviceOrder) => openModal('service-order-reopen', serviceOrder)"
          @resend-approval-notification="(serviceOrder) => openModal('resend-approval-notification', serviceOrder)"
          @show-messages="(serviceOrder) => openModal('messages', serviceOrder)"
          @withdraw-vehicle="(serviceOrder) => openModal('new-vehicle-withdrawal', serviceOrder)"
      ></service-orders-table>

      <div class="paginator-content" v-if="fetching || showPaginator">
        <service-orders-table-paginator
            :fetching="fetching"
            :pagination="pagination"
            :value="parsedQueryParams.page"
            @input="onPageChange"
        ></service-orders-table-paginator>
      </div>
    </div>

    <template v-if="showAssignmentModal">
      <assignment-modal
          :selected="selected"
          @change="fetch"
          @close="showAssignmentModal = false"
      ></assignment-modal>
    </template>

    <service-order-modals-container
        :current-modal="serviceOrderModals.current"
        :service-order="serviceOrderModals.serviceOrder"
        :show.sync="serviceOrderModals.show"
        @update="onServiceOrderModalsUpdate"
    ></service-order-modals-container>
  </div>
</template>


<script>

// View
import IndexView from "@/views/index-view"

// Mixins
import FetchMixin from "@/mixins/fetch-mixin"

// Components
import QuickFilters from "./_components/quick-filters.vue"
import ServiceOrderModalsContainer from "./_components/service-order-modals-container.vue"
import ServiceOrdersTable from "./_components/service-orders-table.vue"
import ServiceOrdersTablePaginator from "./_components/service-orders-table-paginator.vue"
import ServiceOrdersTableToolbar from "./_components/service-orders-table-toolbar.vue"
import SearchForm         from "./_components/search-form.vue"

import AssignmentModal           from "./_components/assignment-modal.vue"
import NewPostponementModal      from "./service-orders/postponements/new.vue"
import NewVehicleWithdrawalModal from "./vehicle-withdrawals/new.vue"


export default {
  name: "Monitoring",

  components: {
    QuickFilters,
    ServiceOrderModalsContainer,
    ServiceOrdersTable,
    ServiceOrdersTablePaginator,
    ServiceOrdersTableToolbar,
    SearchForm,
    AssignmentModal,
    NewPostponementModal,
    NewVehicleWithdrawalModal
  },

  extends: IndexView,

  mixins: [FetchMixin],

  data() {
    return {
      i18nScope: "monitoring.index",
      routeName: "monitoring",

      resource: [],

      // Query Mixin data => XXX: snake_case
      queryParamsStructure: {
        q:    "string",
        sort: "string",
        page: "integer",
        // per_page: "integer",

        timeframe: "integer",

        // filter-tag
        monitoring: "boolean",

        // filtros
        assignee_id:               "string",  // XXX: id or rid
        business_unit:             "array",
        service_kind:              "array",
        state:                     "string",  // XXX: era "array" (seleção múltipla),
        quote_issued:              "boolean",
        vehicle_stopped:           "boolean",
        vehicle_withdrawed:        "boolean",
        vehicle_category:          "array",
        region:                    "array",
        uf:                        "array",
        scheduled_from:            "string",  // date
        scheduled_until:           "string",  // datetime
        estimated_completed_from:  "string",  // date
        estimated_completed_until: "string"   // datetime
      },

      // @override IndexView => XXX: snake_case
      defaultQuery: {
        sort:          "id:desc",
        timeframe:     30,  // em dias
        monitoring:    true,
        business_unit: ["gtf", "zkm"]
      },

      // @override IndexView => XXX: camelCase!
      clearFiltersIgnoreList: [
        "timeframe",
        "monitoring",   // filter-tab
        "businessUnit"  // XXX: muito custoso buscar sem esse filtro!
      ],

      selected: [],

      // action
      showAssignmentModal: false,

      serviceOrderModals: {
        current:      null,
        serviceOrder: null,
        show:         false
      }
    }
  },

  computed: {
    serviceOrders: {
      get()    { return this.resource },
      set(val) { this.resource = val }
    },

    activeServiceOrder() {
      return this.serviceOrders.find(so => so.rid == this.activeRid)
    },

    empty() {
      return _.blank(this.serviceOrders)
    },

    hasSelected() {
      return _.present(this.selected)
    }
  },


  methods: {
    parseRoute() {},

    // @override Fetchable mixin
    fetchRequest() {
      return this.$newSdk.monitoring.serviceOrders
        .all({ params: this.requestQueryParams })
    },

    afterFetchSuccess() {
      this.selected = []
    },

    openModal(name, serviceOrder) {
      this.serviceOrderModals.current = name
      this.serviceOrderModals.serviceOrder = serviceOrder
      this.serviceOrderModals.show = true
    },

    // event listeners
    // ---
    onQuickFilter(filters) {
      this.parsedQueryParams = {
        ...this.getResetFilters(),
        ...filters
      }
      this.updateUrlQuery()
    },

    onServiceOrderModalsUpdate() {
      this.fetch()
    }
  }
}

</script>

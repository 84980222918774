<!--
<doc lang="markdown">
Exibe um código Fipe, prefixado por um <pre>Fipe</pre>

_Props_: value, size, color

Como usar:
```pug
  fipe-code-tag {{ "25061987" }}
```

Ou passando valor por prop:
```
  fipe-code-tag(:value="fipeCode")
```
</doc>
-->


<style lang="scss" scoped>

.fipe-pre {
  font-family: $primary-monospace-font;
  margin: 0 2px 0 0;
}

</style>


<template lang="pug">

  code-tag(:color="color", :size="size")
    template(#icon)
      pre.fipe-pre(:class="size") FIPE

    slot
      .fipe-pre(:class="size") {{ value }}

</template>


<script>

export default {
  name: "FipeCodeTag",

  props: {
    color: { type: String, default: "purple" },
    value: { type: [String, Number], default: null },
    size:  { type: String, default: "normal", validator: size => ["small", "normal", "big"].includes(size) }
  },

  data() {
    return {
      i18nScope: "vehicle-models.components.fipe-code-tag"
    }
  }
}

</script>

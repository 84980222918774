<style lang="scss" scoped>

.file-selector {
  .empty-card {
    border: 1px dashed $gray-3;
    border-radius: 8px;
    width: 212px;
    height: 140px;
    cursor: pointer;
    color: $gray-2;

    .icon {
      font-size: 20px;
    }

    &:hover {
      border-color: $orange;
      color: $orange;
    }

    &:active {
      border-width: 2px;
    }
  }

  &.dragover {
    .empty-card {
      background: $light-orange;
    }
  }
}

</style>


<template lang="pug">

  .file-selector(
    :class="{ 'dragover': isDragOver }"
    @drop="handleDrop"
    @dragenter="handleDragEnter"
    @dragleave="handleDragLeave"
    @dragover="handleDragOver"
  )
    slot(:props="{ isDragOver, selectFiles}")
      .empty-card.flex.center.vertical-center(@click="selectFiles")
        i.icon.far.fa-paperclip

</template>


<script>

// Libs
import { mimetypes, extensions as supportedExtensions } from "@/lib/supported-files"

export default {
  name: "FileSelector",

  props: {
    maxSize:      { type: Number, default: Number.MAX_SAFE_INTEGER },
    allowedTypes: { type: Array, default: () => mimetypes },
    extensions:   { type: Array, default: () => supportedExtensions },
    multiple:     { type: Boolean, default: false }
  },

  data() {
    return {
      i18nScope: "components.attachments.file-selector",
      input: null,
      isDragOver: false,
    }
  },

  computed: {
    limit() {
      return this.$asHumanSize(this.maxSize)
    }
  },

  beforeDestroy() {
    this.removeInputElement()
  },

  methods: {
    handleDragEnter() {
      this.isDragOver = true
    },

    handleDragLeave(event) {
      if (this.$el.contains(event.fromElement)) return
      this.isDragOver = false
    },

    handleDragOver(event) {
      event.preventDefault()
    },

    handleDrop(event) {
      event.preventDefault()

      const files = Array.from(event.dataTransfer.files)
        .filter((file) => !this.validateFile(file))

      this.isDragOver = false
      this.$emit(
        'selected-files',
        this.multiple
          ? files
          : files.length
            ? [files[0]]
            : []
      )
    },

    removeInputElement() {
      if (_.get(this.input, "parentNode")) this.input.parentNode.removeChild(this.input)
      this.input = null
    },

    validateFile({ size, type, name }) {
      const errors = []

      if (size > this.maxSize) errors.push(this.$t(".errors.size.max", { name, limit: this.limit }))
      if (size <= 0) errors.push(this.$t(".errors.size.min", { name }))
      if (_.present(this.allowedTypes) && !this.allowedTypes.includes(type)) errors.push(this.$t(".errors.type", { name }))

      errors.forEach(error => this.$notifications.error(error))

      return _.present(errors)
    },

    selectFiles() {
      this.removeInputElement()
      this.input = document.createElement("input")

      this.input.type = "file"
      this.input.accept = this.extensions.join(",")
      this.input.style.display = "none"
      this.input.dataset.testid = "uploadInput"
      if (this.multiple) this.input.multiple = "multiple"

      this.input.addEventListener("change", async () => {
        const files = [...this.input.files].filter(file => !this.validateFile(file))
        this.$emit("selected-files", files)

        this.removeInputElement()
      })

      document.body.appendChild(this.input)
      this.input.click()
    }
  }
}

</script>

<style scoped lang="scss">
</style>


<template lang="pug">

  .destroy-action(v-if="$can('destroy', 'Deal')")
    app-button(
      :disabled="disabled",
      :loading="deleting",
      @click="hidePopoverMenu(); showModal = true",
      theme="neutral",
      border="square",
      data-test="deleteButton"
    )
      slot

    app-modal(
      v-if="showModal",
      :close-button="!deleting",
      :heading="$t('.heading')",
      :width="540",
      :loading="deleting",
      footer,
      :confirm-label="$t('btn.destroy.label')"
      :confirm-label-loading="$t('btn.destroy.loading')",
      @close="onCloseModal",
      @cancel="onCloseModal",
      @confirm="destroy",
      data-test="confirmDeleteButton"
    )
      .body
        span.text {{ $t('.message', { id: dealId }) }}

</template>


<script>

export default {
  props: {
    supplierId: { type: [String, Number], required: true },
    dealId:     { type: [String, Number], required: true },
    disabled:   { type: Boolean, default: false }
  },

  data() {
    return {
      i18nScope: "suppliers.deals.components.destroy-action",

      deleting: false,

      showModal: false
    }
  },

  methods: {
    onCloseModal() {
      if (!this.deleting) {
        this.showModal = false
      }
    },

    hidePopoverMenu() {
      // XXX: usando $parent para conseguir fechar o "popover-menu"!
      this.$parent.$emit("hide")
    },

    async destroy() {
      try {
        this.deleting = true
        await this.$sdk.suppliers.destroyDeal({ id: this.supplierId, dealId: this.dealId })

        this.$notifications.info(this.$t(".notifications.destroy.success", { id: this.dealId }))
        this.$emit("destroy")
      }
      catch (error) {
        this.$err.log(error)
        this.$notifications.error(this.$t(".notifications.destroy.error"))
      }
      finally {
        this.deleting = false
        this.showModal = false
      }
    }
  }
}

</script>

<style lang="scss" scoped>
</style>


<template lang="pug">

  .search-form
    form(
      @submit.prevent="$emit('search')",
      method="get",
      data-test="form"
    )
      ui-row(
        vertical-align="bottom",
        :columns="24"
      )
        ui-col(:span="6")
          input-field(
            name="search[q]",
            v-model="params.q",
            :loading="loading",
            hide-label,
            :placeholder="$t('.fields.q.placeholder')",
            icon-left="far fa-search"
          )
        ui-col(:span="5")
          manufacturer-finder-field(
            v-model="params.manufacturerId"
            :disabled="loading"
            :label="$t('.fields.manufacturer.label')"
            :listbox-config="{ maxHeight: 350 }"
            name="search[manufacturer_id]"
            :placeholder="$t('.fields.manufacturer.placeholder')"
          )
        ui-col(:span="4")
          vehicle-model-category-finder-field(
            v-model="params.category",
            :disabled="loading",
            :label="$t('.fields.category.label')",
            :listbox-config="{ maxHeight: 350 }"
            name="search[category_id]",
            :placeholder="$t('.fields.category.placeholder')",
            searchable
          )

        ui-col(:span="2")
          input-field(
            name="search[starting_year]",
            :label="$t('.fields.startingYear.label')",
            :placeholder="$t('.fields.startingYear.placeholder')",
            :loading="loading",
            mask="#",
            :maxlength="4",
            v-model.number="params.startingYear"
          )

        ui-col(:span="4")
          vehicle-model-groups-finder-field(
            v-model="params.group",
            :disabled="loading",
            :label="$t('.fields.groups.label')",
            :listbox-config="{ maxHeight: 350 }"
            name="search[id]",
            :placeholder="$t('.fields.groups.placeholder')",
            searchable
          )

        ui-col(:span="3")
          app-button(
            type="submit",
            :loading="loading",
            full-width,
            outline
          )
            span {{ $t('btn.search.label') }}
            span(slot="loading") {{ $t('btn.search.loading') }}

</template>


<script>

// Components
import ManufacturerFinderField from "./manufacturer-finder-field"
import VehicleModelCategoryFinderField from "../components/vehicle-model-category-finder-field"
import VehicleModelGroupsFinderField from "../components/vehicle-model-groups-finder-field"

export default {
  components: {
    ManufacturerFinderField,
    VehicleModelCategoryFinderField,
    VehicleModelGroupsFinderField,
  },

  props: {
    loading: { type: Boolean, default: false },
    params:  { type: Object, default: () => ({}) }
  },

  data() {
    return {
      i18nScope: "vehicle-models.index.search-form"
    }
  }
}

</script>

import Base from "./base"

class Occurrence extends Base {
  static get attrs() {
    return _.uniq([
      ...super.attrs,
      "description",
      "comments",
      "kind",
      "status",
      "vehicleId"
    ])
  }

  static get enums() {
    return {
      kind: {
        casual_damage:                             "casual_damage",
        theft:                                     "theft",
        fire:                                      "fire",
        malfunction_mechanical_electrical_problem: "malfunction_mechanical_electrical_problem",
        flood_hail:                                "flood_hail",
        adhesive_film:                             "adhesive_film",
        glass_with_wdw_protection:                 "glass_with_wdw_protection",
        tire_with_wdw_protection:                  "tire_with_wdw_protection",
        damage:                                    "damage",
        misappropriation:                          "misappropriation",
        traffic_accident:                          "traffic_accident"
      },

      status: {
        closed:                 "closed",
        open:                   "open",
        charged_at_the_counter: "charged_at_the_counter",
        billed:                 "billed",
        billing_pending:        "billing_pending",
        canceled:               "canceled",
        partial_billing:        "partial_billing"
      }
    }
  }

  static get constraints() {
    return {
      vehicleId: { presence: true }
    }
  }
}

export default Occurrence

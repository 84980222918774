// Base
import BaseRequests from "../../Base"

class ReviewChangesRequestsRequests extends BaseRequests {
  static get selector() {
    return "quotes.reviews.changesRequests"
  }

  create({ quoteId, params = {}, options = {} } = {}) {
    this.verifyData("create", { quoteId })

    return this.action({
      method: "post",
      url:    `/quotes/${quoteId}/reviews/changes_request`,
      params,
      options
    })
  }
}

export default ReviewChangesRequestsRequests

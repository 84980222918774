<style scoped lang="scss">

.row + .row {
  margin-top: 24px;
}

.header {
  margin-bottom: 32px;
  padding-bottom: 16px;
  border-bottom: 1px solid $light-gray-3;
}

.title {
  font-size: 30px;
  font-weight: 500;
  font-family: $secondary-font;
}

.form,
.fields {
  margin-bottom: 24px;
}

.actions {
  margin-top: 24px;
  padding-top: 24px;
  border-top: 1px solid $light-gray-3;
}

</style>


<template lang="pug">

  .edit-vehicle-part
    header.header.flex.vertical-center
      h1.title {{ $t('.title') }}

    form.form(@submit.prevent="submit", method="post")

      form-fields.fields(
        :fetching="fetching",
        :submitting="submitting",
        :errors="errors",
        :resource="resource"
      )

      section.actions.flex.space-between.vertical-center.row
        app-button(
          :to="{ name: 'vehiclePart', params: { id } }",
          theme="gray",
          outline,
          :disabled="submitting"
        ) {{ $t('btn.cancel') }}

        app-button(
          type="submit",
          :loading="submitting",
          data-testid="submitButton"
        )
          span {{ $t('btn.update.label') }}
          span(slot="loading") {{ $t('btn.update.loading') }}

</template>


<script>

// Mixins
import { mixins } from "movida-common.vue"

// Extends
import View from "@/views/view"

// Local Components
import FormFields from "./form/form-fields.vue"

const { FormMixin, Fetchable } = mixins

export default {
  components: {
    FormFields
  },

  extends: View,

  mixins: [Fetchable, FormMixin],

  data() {
    return {
      i18nScope: "vehicle-parts.edit",

      id: this.$route.params.id,

      // Fetchable attributes
      model: "VehiclePart",

      // FormMixin attributes
      resource: {}
    }
  },

  methods: {
    // @override Form mixin
    submitRequest() {
      const symptomIds = this.resource.symptoms.map(symptom => symptom.id)
      const params = { ...this.resource.$serialize(), symptom_ids: symptomIds }

      return this.$sdk.vehicleParts.update({ id: this.id, params })
    },

    // @override Form mixin
    submitSuccess({ data: { id, name } }) {
      this.$notifications.info(this.$t(".notifications.submit.success", { name }))

      this.$router.push({ name: "vehiclePart", params: { id } })
    },

    // @override Form mixin
    submitError(err) {
      this.$err.log(err)
      this.$notifications.error(this.$t(".notifications.submit.error"))
    },

    // @override Fetchable mixin
    fetch() {
      return this.$sdk.vehicleParts.get({ id: this.id })
    },

    // @override Fetchable mixin
    // onFetchSuccess(response) {},

    // @override Fetchable mixin
    onFetchError(err) {
      // Dealing with appError from extension view.vue
      if (this.hasViewError(err)) this.appError = err

      this.$err.log(err)
      this.$notifications.error(this.$t(".notifications.fetch.failure"))
    }
  }
}

</script>

<style lang="scss" scoped>

.maintenance-package {
  .header {
    padding-bottom: 24px;

    .discarded-icon {
      margin-right: 8px;
    }

    .title {
      font-weight: bold;
      font-size: 20px;
      margin-bottom: 4px;
    }

    .description {
      font-size: 14px;
    }
  }

  .table-header {
    margin-bottom: 8px;

    .text {
      font-family: $secondary-font;
      color: $dark-gray;
      font-size: 16px;
      font-weight: 400;
    }

    .count {
      font-size: 14px;
    }
  }
}

</style>


<template lang="pug">

  .maintenance-package
    template(v-if="fetching")
      loading-lines(:lines="4")
    template(v-else)
      header.header
        .flex.vertical-baseline
          discarded-icon.discarded-icon(v-if="maintenancePackage.$discarded", size="big")
          h2.title {{ maintenancePackage.name }}
        span.description(v-if="maintenancePackage.description") {{ maintenancePackage.description }}

      .flex.vertical-center.space-between.table-header
        .flex.vertical-center
          span.text {{ $t('.table.title.label') }}

        span.count {{ $t('.products', { count: productsCount }) }}

      products(:collection="products")

</template>


<script>

// Mixins
import { mixins } from "movida-common.vue"

// Extends
import View from "@/views/view"

import Products from "./show/products/index"

// Models
import models from "@/models"

const { MaintenancePackage } = models
const { Fetchable } = mixins

export default {
  name: "SupplierDealMaintenancePackageMaintenancePackage",

  components: {
    Products
  },

  extends: View,

  mixins: [Fetchable],

  props: {
    maintenancePackage: { type: Object, required: true }
  },

  data() {
    return {
      i18nScope: "suppliers.deals.maintenance-packages.maintenance-package.show"
    }
  },

  computed: {
    products() {
      return _.get(this.resource, "products") || []
    },

    productsCount() {
      return this.products.length
    }
  },

  methods: {
    fetch() {
      return this.$sdk.maintenancePackages.get({ id: this.maintenancePackage.id })
    },

    // @override Fetchable mixin
    onFetchSuccess(response) {
      const products = this.resource.products.map(product => new models[product.type](product))
      this.resource = new MaintenancePackage(this.resource)
      this.resource.products = products
    },

    onFetchError(err) {
      if (this.hasViewError(err)) {
        this.appError = err
      }

      this.$err.log(err)
      this.$notifications.error(this.$t(".notifications.fetch.failure"))
    }
  }
}

</script>

<style lang="scss" scoped>

.products {
  .section-header {
    margin-bottom: 16px;

    .title {
      font-family: $secondary-font;
      font-weight: 500;
      font-size: 20px;
    }

    .subtitle {
      display: block;
      margin-top: 4px;
    }
  }

  .table {
    .count {
      margin-bottom: 8px;
      text-align: right;
    }

    .no-products {
      padding: 24px;
      border: 1px solid $light-gray-3;
      border-radius: 4px;
      color: $gray-3;
      font-size: 14px;

      .icon {
        user-select: none;
        margin-bottom: 8px;
        height: 78px;
        width: 89px;
      }
    }
  }
}

</style>


<template lang="pug">

  .products
    .section-header
      h2.title {{ $t('.title') }}

    .table
      .count(v-if="hasProducts && !fetching")
        span {{ $t('.count', { count: productsCount }) }}

      template(v-if="!hasProducts && !fetching")
        .no-products.flex.center.column-direction.vertical-center
          img.icon(:src="productPlaceholderIcon")
          span {{ $t('.empty') }}

      products-table(
        v-else,
        :loading="fetching"
      )
        service-package-product-row(
          v-for="product in products",
          :key="`service-package-product-row-${product.id}`",
          :product="product"
        )

</template>


<script>

// Components
import ServicePackageProductRow from "../components/service-package-product-row"

// Icons
import productPlaceholderIcon from "@/assets/images/placeholder-icons/product.svg"

export default {
  name: "ServicePackageProducts",

  components: {
    ServicePackageProductRow
  },

  props: {
    fetching: { type: Boolean, default: false },
    products: { type: Array, default: () => [] }
  },

  data() {
    return {
      i18nScope: "service-packages.products.show",

      productPlaceholderIcon
    }
  },

  computed: {
    hasProducts() {
      return _.present(this.products)
    },

    productsCount() {
      if (!this.hasProducts) return 0
      return this.products.length
    }
  }
}

</script>

<style scoped lang="scss">

.maintenance-package {
  padding-bottom: 24px;
}

.section {
  margin-top: 24px;
  padding-top: 24px;
  border-bottom: 1px solid $light-gray-3;
}

.header {
  padding-top: 18px;

  .discarded-icon {
    margin-right: 8px;
  }

  .name {
    font-size: 30px;
    font-weight: bold;
    word-break: normal;
    overflow-wrap: break-word;
  }

  .id-tag {
    margin: 0 8px;
  }
}

.btn + .btn {
  margin-left: 8px;
}

.icon {
  font-size: 16px;
  margin-right: 8px;
}

.description-content {
  margin: 8px 0 24px 0;

  .description {
    font-size: 16px;
    font-weight: normal;
    margin: 0;
  }
}

.products {
  margin-top: 24px;
  padding-top: 24px;

  .title {
    font-family: $secondary-font;
    font-size: 20px;
    font-weight: 500;
    line-height: 24px;
    padding-bottom: 24px;
  }

  .table {
    margin-bottom: 24px;
  }
}

.info-label + .info-label {
  margin-left: 40px;
}

</style>


<template lang="pug">

  .maintenance-package
    template(v-if="fetching")
      loading-lines(:lines="4")

    template(v-else)
      header.section.header
        .flex.space-between
          .title.flex.vertical-center
            discarded-icon.discarded-icon(v-if="resource.$discarded", size="big")
            h2.name(data-testid="name") {{ resource.name }}
            id-tag.id-tag(:value="resource.id", size="big")

          .actions.flex
            template(v-if="resource.$discarded")
              enable-action.btn(
                :id="id",
                :name="resource.name",
                @enabled="fetch",
                data-testid="enableAction"
              )
                i.icon.fal.fa-check-circle
                span {{ $t('.btn.enable') }}

            template(v-else)
              disable-action.btn(
                :id="id",
                :name="resource.name",
                @disabled="fetch",
                data-testid="disableAction"
              )
                i.icon.fal.fa-lock
                span {{ $t('.btn.disable') }}

            destroy-action.btn(
              :id="id",
              :name="resource.name",
              @destroy="onDestroy",
              data-test="destroyAction"
            )
              i.fal.fa-trash-alt.icon
              span {{ $t('.btn.destroy') }}

            app-button.btn(
              v-if="$can('update', 'MaintenancePackage')",
              theme="neutral",
              size="small",
              :to="{ name: 'editMaintenancePackage', params: { id }}"
            )
              i.fal.fa-pencil.icon
              span {{ $t('.btn.edit') }}

        section.description-content.preserve-whitespace(v-if="resource.description", data-testid="description")
          p.description {{ resource.description }}

      section.products
        h3.title {{ `${$t('.productTitle')} (${resource.productsCount})` }}

        template(v-if="!erred")
          products-table.table(
            :initializing="initializing",
            :loading="fetching",
            :products="products",
            :sorted-by="parsedQueryParams.sort",
            :show-actions="false",
            @sort="onSortChange",
            @changed="fetch"
          )

      section.footer.flex.space-between
        app-button(:to="{ name: 'maintenancePackages' }", outline)
          i.far.fa-arrow-left.icon
          span {{ $t('.btn.back') }}

        app-button(
          v-if="$can('create', 'MaintenancePackage')",
          theme="link",
          :to="{ name: 'newMaintenancePackage' }"
        )
          span {{ $t('.btn.new') }}

</template>


<script>

// Mixins
import FetchMixin from "@/mixins/fetch-mixin"

// Extends
import IndexView from "@/views/index-view"

// Components
import models from "@/models"
import DestroyAction from "./components/destroy-action"
import DisableAction from "./components/disable-action"
import EnableAction from "./components/enable-action"
import ProductsTable from "./show/products/products-table"

// Models

const { MaintenancePackage } = models

export default {
  name: "MaintenancePackage",

  components: {
    DestroyAction,
    DisableAction,
    EnableAction,
    ProductsTable
  },

  extends: IndexView,

  mixins: [FetchMixin],

  data() {
    return {
      i18nScope: "maintenance-packages.show",
      routeName: "maintenancePackage",

      model: null,

      id: this.$route.params.id,

      resource: {},

      // Query Mixin data
      queryParamsStructure: {
        q:    "string",
        sort: "string"
      }
    }
  },

  computed: {
    products: {
      get() { return this.resource.products },

      set(value) { this.resource.products = value }
    }
  },

  methods: {
    parseRoute() {},

    onDestroy() {
      this.$router.push({ name: "maintenancePackages" })
    },

    fetchRequest() {
      return this.$newSdk.maintenancePackages.find({ maintenancePackageId: this.id })
    },

    // @override Fetchable mixin
    onFetchSuccess({ data, headers }) {
      const products = data.products.map(product => new models[product.type](product))
      this.resource = new MaintenancePackage(data)
      this.resource.products = products

      this.setPagination(headers)
    }
  }
}

</script>

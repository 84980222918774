<style lang="scss" scoped>

.supplier-block-ongoing-preview {
  width: 261px;
  max-height: 212px;

  .header {
    .icon {
      font-size: 16px;
      margin-right: 8px;
    }

    .title {
      font-family: $primary-font;
      color: $dark-gray;
      font-size: 16px;
      font-weight: 400;
    }
  }

  .content {
    margin-top: 12px;

    .text {
      font-family: $primary-font;
      color: $dark-gray;
      font-size: 14px;
      font-weight: 300;
    }

    .description {
      margin-top: 8px;

      .text,
      .link ::v-deep .app-span {
        &.crop {
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 3;
          overflow: hidden;
          white-space: normal;
          text-overflow: unset;
        }
      }

      .no-description {
        font-family: $primary-font;
        color: $gray-2;
        font-style: italic;
      }
    }

    .action {
      margin-top: 8px;
    }
  }
}

</style>


<template lang="pug">

  .supplier-block-ongoing-preview
    .header.flex.vertical-center
      i.icon.fa.fa-times-circle
      h3.title {{ $t('.title.label') }}

    .content
      .author
        app-span.text(
          :value="$t('.author.label', { name: supplierBlock.author })"
          crop
        )

      .startAt
        app-span.text(
          :value="$t('.startAt.label', { startAt: $standardTime(supplierBlock.startAt) })"
          crop
        )

      .endAt
        template(v-if="hasEndAt")
          app-span.text(
            :value="$t('.endAt.label', { endAt: $standardTime(supplierBlock.endAt) })"
            crop
          )
        template(v-else)
          app-span -

      .description
        template(v-if="supplierBlock.description")
          app-span.text(
            :value="supplierBlock.description",
            crop
          )
        template(v-else)
          app-span.no-description {{ $t('.noDescription.label')}}

      //- XXX: Verificar uma forma de passar o dado de supplier sem ser via prop.
      //- .action
      //-   unblock-supplier(
      //-     :id="supplier.id",
      //-     :name="supplier.name"
      //-     @unblocked="$emit('modified')"
      //-   )
      //-     template(#link="{ props: { openUnblockModal } }")
      //-       app-button.popover-menu-button.unblock-button(
      //-         theme="link",
      //-         border="square",
      //-         @click="[showPopover = false, openUnblockModal()]"
      //-       )
      //-         .button-menu.flex.vertical-center.grow
      //-           .icon-content
      //-           span {{ $t('.btn.unblock.label') }}

</template>


<script>

// Components
// import UnblockSupplier from './unblock-supplier'

export default {
  // components: {
  //   UnblockSupplier
  // },

  props: {
    supplierBlock: { type: Object, required: true }
  },

  data() {
    return {
      i18nScope: "suppliers.components.supplier-block-ongoing-preview"
    }
  },

  computed: {
    hasEndAt() {
      return _.present(this.supplierBlock.endAt)
    }
  }
}

</script>


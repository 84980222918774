<template lang="pug">

  .view-context
    transition(name="fade", mode="out-in")
      template(v-if="inTicketsContext")
        tickets-header
      template(v-else)
        .empty

</template>


<script>

export default {
  name: "ViewContext",

  data() {
    return {
      i18nScope: "components.view-context",

      routeName: this.$route.name
    }
  },

  computed: {
    inTicketsContext() {
      return ["tickets", "serviceOrders"].includes(this.routeName)
    }
  },

  watch: {
    $route(to, from) {
      this.routeName = this.$route.name
    }
  }
}

</script>

<style lang="scss" scoped>

.new.vehicle-receipt {
  .header {
    padding: 16px 24px;
    border-bottom: 1px solid $light-gray-3;
  }
}

</style>


<template lang="pug">

  app-modal.new.vehicle-receipt(
    footer,
    :confirm-label="$t('.btn.confirm.label')",
    :confirm-label-loading="$t('.btn.confirm.label')",
    :heading="$t('.heading')",
    :loading="submitting",
    :width="566",
    @close="$emit('close')",
    @confirm="submit"
  )
    .content
      .header
        template(v-if="vehicleLoading")
          app-loading-lines(:lines="2", :min-width="60", :max-width="90", :height="30")

        template(v-else)
          service-order-info(
            :service-order="serviceOrder",
            :vehicle="vehicle",
            :supplier="serviceOrder.supplier",
            :customer="serviceOrder.customer",
            :branch="serviceOrder.branch"
          )

      form(ref="form", method="post", @submit.prevent="submit")
        form-fields(
          :resource="resource",
          :fetching="vehicleLoading",
          :submitting="submitting",
          :errors="errors"
        )

</template>


<script>

// Mixins
import FormMixin from "@/mixins/form-mixin"

// Components
import FormFields       from "./_components/form-fields.vue"
import ServiceOrderInfo from "@/views/monitoring/_components/service-order-info.vue"


// Models
import models from "@/models"
const { MovidaServiceOrder, VehicleReceipt } = models


export default {
  components: {
    FormFields,
    ServiceOrderInfo
  },

  emits: [],

  mixins: [FormMixin],  // TODO: FetchMixin e carregamento de dados

  props: {
    serviceOrder: { type: MovidaServiceOrder, required: true }
  },

  data() {
    return {
      i18nScope: "monitoring.vehicle-receipts.new",

      vehicle:  null,
      resource: new VehicleReceipt({
        towTransport:    false,
        ignitionProblem: false,
        at:              moment().toISOString(),
        currentKm:       this.serviceOrder.currentKm
      })
    }
  },

  computed: {
    vehicleLoading() {
      return _.blank(this.vehicle)
    }
  },

  created() {
    this.$newSdk.monitoring.vehicles.find(this.serviceOrder.vehicleId)
      .then(response => {
        this.vehicle = response.data // XX: já é MovidaVehicle pois o SDK trata isso

        // XXX: sobrecarregando valor do combustível, de acordo com o Veículo!
        this.resource.fuelLevel = this.vehicle.fuelLevel
      })
      .catch(err => {
        this.$notifications.error(this.$t(".notifications.fetch.vehicle.failure"))
      })
  },

  methods: {
    // @override Form mixin
    serializeAttributes() {
      return this.resource.$serialize()
    },

    // @override Form mixin
    submitRequest() {
      return this.$newSdk.monitoring.serviceOrders.vehicleReceipt
        .create({
          serviceOrderRid: this.serviceOrder.rid,
          params:          this.serializeAttributes()
        })
    },

    // @override Form mixin
    onSubmitSuccess(response) {
      // XXX: usando id do Vetor (remoteId) para ficar coerente com a listagem
      let successMessage = this.$t(".notifications.submit.success", { id: this.serviceOrder.remoteId })
      this.$notifications.info(successMessage)
    },

    // @override Form mixin
    afterSubmitSuccess() {
      this.$emit("update")
      this.$emit("close")
    }
  }
}

</script>

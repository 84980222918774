<style scoped lang="scss">

.tickets {
  .clear-filters {
    position: relative;

    .filters-button {
      position: absolute;
      margin-top: 10px;
    }
  }

  .list {
    margin-top: 50px;

    .table {
      margin-top: 16px;
    }
  }

  .paginator-content {
    padding-top: 24px;

    &.loading {
      max-width: 400px;
      margin: 0 auto;
    }
  }
}

</style>


<template lang="pug">

  .tickets
    supplier-tickets-header(:supplier-id="supplierId")

    search-form.search(
      :loading="fetching",
      :params="parsedQueryParams",
      @search="search"
    )

    .clear-filters
      filters-button.filters-button(
        v-if="hasFilters",
        @clear="clearFilters"
      )

    .list
      .flex.end.vertical-bottom
        results-indicator(
          :page="parsedQueryParams.page",
          :per-page="pagination.perPage",
          :total="pagination.total",
          :loading="fetching"
        )

      tickets-table.table(
        :tickets="tickets",
        :initializing="initializing",
        :loading="fetching",
        :sorted-by="parsedQueryParams.sort",
        @sort="onSortChange",
        @changed="fetch"
      )

    .paginator-content(v-if="fetching || showPaginator", :class="{ loading: fetching }")
      paginator(
        :loading="fetching",
        :per-page="pagination.perPage",
        :total="pagination.total",
        :value="parsedQueryParams.page",
        :disabled="fetching",
        @input="onPageChange",
        data-testid="paginator"
      )

</template>


<script>

// Models
import models from "@/models"

// View
import IndexView from "@/views/index-view"

// Mixins
import FetchMixin from "@/mixins/fetch-mixin"

// Local components
import SearchForm from "./index/search-form"
import TicketsTable from "./index/ticket-table"
import SupplierTicketsHeader from "../components/supplier-tickets-header.vue"

export default {
  name: "SupplierTickets",

  components: {
    TicketsTable,
    SearchForm,
    SupplierTicketsHeader
  },

  extends: IndexView,

  mixins: [FetchMixin],

  data() {
    return {
      i18nScope: "suppliers.tickets.index",
      routeName: "supplierTickets",

      supplierId: null,

      resource: [],

      // @requirement IndexView
      queryParamsStructure: {
        q:        "string",
        type:     "string",
        state:    "string",
        store_id: "integer",
        since:    "string",
        until:    "string",
        page:     "integer",
        sort:     "string"
      }
    }
  },

  computed: {
    tickets: {
      get() { return this.resource },

      set(value) { this.resource = value }
    }
  },

  methods: {
    parseRoute() {
      this.supplierId = this.$route.params.id
    },

    // @override Fetch Mixin
    fetchRequest() {
      return this.$newSdk.suppliers.tickets.all({ supplierId: this.supplierId, params: this.requestQueryParams })
    },

    onFetchSuccess({ data, headers }) {
      const ticketModels = {
        "Tickets::InternalMaintenance": "TicketInternalMaintenance",
        "Tickets::Maintenance":         "TicketExternalMaintenance",
        "Tickets::Repair":              "TicketRepair"
      }

      this.resource = data.map(ticket => {
        const modelName = ticketModels[ticket.type]
        return new models[modelName](ticket)
      })

      this.setPagination(headers)
    }
  }
}

</script>

<style lang="scss" scoped>

// XXX: hack para que o v-popover não reduza o tamanho dos filhos.
// sem isso, o campo select-field não vai ocupar 100% da coluna (pai).
::v-deep .v-popover > .trigger {
  width: 100%;
}

.reference-table {
  padding: 14px;

  .title {
    display: block;
    padding: 0px 12px;
    padding-bottom: 8px;

    margin: 0 12px;
    margin-bottom: 8px;
    border-bottom: 1px solid $light-gray-3;

    font-family: $secondary-font;
    font-size:   18px;
    font-weight: 500;
    color: $dark-gray;
  }

  .reference-table-data {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;

    width: 600px;
    max-height: 230px;

    padding: 4px 20px;

    font-size: 14px;

    .item {
      padding: 2px 4px;
    }

    .value {
      font-family: $primary-monospace-font;
      font-weight: 500;
      padding-right: 4px;
    }

    .label {
      font-family: $primary-font;
      font-weight: 300;
    }
  }
}

</style>


<template lang="pug">

  .tire-load-index-select-field
    //- XXX: essa é uma solução temporária.
    //- TODO: Criar um slot #info-popover nos `*-field`, para que seja possível customizar
    //- onde o popover fica ancorado. (no ícone de info-tooltip)
    v-popover(
      placement="top",
      trigger="manual",
      :open="showPopover",
      @hide="showPopover = false"
    )

      select-field(
        v-bind="$props",
        v-on="$listeners",
        :options="options"
        @info-click="showPopover = true"
      )

      template(#popover)
        tire-load-index-popover-content

</template>


<script>
import Vue from "vue"

import SelectField from "@/components/form/select-field"

import models from "@/models"
const { Tire } = models


export default {
  name: "TireLoadIndexSelectField",

  extends: SelectField,

  props: {
    info:       { type: Boolean, default: true }, // override
    searchable: { type: Boolean, default: true }  // override
  },

  data() {
    return {
      i18nScope:   "components.tire.tire-load-index-select-field",
      showPopover: false
    }
  },

  computed: {
    options() {
      // TODO: adicionar, à opção, o limite de peso
      // - recuperar de LoadIndices.kgFor(key)
      return Tire.$enums.tireLoadIndex.keys.map(key => ({
        value: key,
        label: key
      }))
    }
  },

  // Removendo props 'options' que está sendo reescrita como _computed_
  beforeCreate() {
    Vue.delete(this.$options.props, "options")
  }
}
</script>

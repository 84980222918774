import Base from "./base"

class User extends Base {
  static get attrs() {
    return _.uniq([
      ...super.attrs,
      "username",
      "name",
      "email",
      "storeIds"
    ])
  }

  get firstName() {
    if (_.blank(this.name)) return this.name
    return this.name.split(" ")[0]
  }

  get storeIds() {
    return this.$attrs.storeIds || []
  }
}

export default User

<style lang="scss" scoped>
.messages-modal-header {
  border-bottom: 1px solid $light-gray-3;
  display: flex;
  height: var(--messages-header-height);
  justify-content: space-between;
  padding: 16px 24px;
}

.info-container {
  display: flex;
  justify-content: space-between;
  margin-right: 24px;
}

.info {
  display: flex;

  & + .info {
    border-left: 1px solid $light-gray-3;
    margin-left: 24px;
    padding-left: 24px;
  }

  .icon-container {
    align-items: center;
    display: flex;
    height: 16px;
    justify-content: center;
    margin-right: 8px;
    width: 16px;

    .icon {
      font-size: 14px;
      color: $gray-3;
    }
  }

  .text,
  .address,
  .cost-center {
    font-weight: 400;
    font-size: 12px;
  }

  .text {
    font-family: $secondary-font;
    margin-bottom: 4px;
  }

  .address,
  .cost-center {
    color: $gray-3;
  }

  .diamond-icon {
    font-size: 4px;
    margin: 0 4px;
    color: $gray-3;
  }

  .state {
    margin-top: 4px;
    width: 94px;
  }
}

.popover-trigger-area {
  cursor: help;
}
</style>

<template lang="pug">
  header.messages-modal-header
    .info-container
      .info
        .icon-container
          i.icon.fal.fa-file-invoice-dollar
        .flex.column-direction
          id-tag(:value="serviceOrder.remoteId")
          service-order-state.state(:state="serviceOrder.state")

      .info(v-if="supplierInfo")
        v-popover(
          placement="bottom",
          trigger="manual",
          :open="showSupplierPopover",
          @hide="showSupplierPopover = false"
        )
          .popover-trigger-area.flex(@click="showSupplierPopover = true")
            .icon-container
              i.icon.fal.fa-user-helmet-safety
            .flex.column-direction
              span.text {{ supplierInfo.name }}
              span.address {{ supplierInfo.city }} - {{ supplierInfo.state }}
          template(#popover)
            supplier-popover(
              :show="showSupplierPopover",
              :supplier-rid="supplierInfo.rid"
            )

      .info(v-if="customerInfo")
        .icon-container
          i.icon.fal.fa-user
        .flex.column-direction
          span.text {{ customerInfo.name }}
          .flex.vertical-center
            business-unit-tag(size="small", :value="serviceOrder.businessUnit")
            template(v-if="customerInfo.costCenterCode")
              i.diamond-icon.fas.fa-diamond
              span.cost-center {{ customerInfo.costCenterCode }}

      .info
        .icon-container
          i.icon.fal.fa-car-mirrors
        .flex.column-direction
          app-span.text(crop, :value="vehicle.fullModelName")
          .flex.vertical-center
            license-plate(
              size="small",
              :value="vehicle.licensePlate"
            )

    app-button(@click="$emit('create')") {{ $t('.btn.create.label') }}
</template>

<script>
import ServiceOrderState from "../../_components/service-order-state.vue"
import SupplierPopover from "../../_components/supplier-popover.vue"

export default {
  components: {
    ServiceOrderState,
    SupplierPopover
  },

  props: {
    serviceOrder: { type: Object, required: true }
  },

  data() {
    return {
      i18nScope: "monitoring.messages.messages-modal-header",

      showSupplierPopover: false
    }
  },

  computed: {
    customerInfo() {
      if (_.blank(this.serviceOrder.customer)) return null
      return {
        name:           _.get(this.serviceOrder, "customer.name"),
        costCenterCode: _.get(this.serviceOrder, "customer.costCenterCode")
      }
    },

    supplierInfo() {
      if (_.isEmpty(this.serviceOrder.supplier)) return null
      return {
        name:  _.get(this.serviceOrder, "supplier.tradingName"),
        city:  _.get(this.serviceOrder, "supplier.addressCityName"),
        state: _.get(this.serviceOrder, "supplier.addressStateAbbr"),
        rid:   _.get(this.serviceOrder, "supplier.rid")
      }
    },

    vehicle() {
      return this.serviceOrder?.vehicle || {}
    }
  }
}
</script>

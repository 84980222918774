<style lang="scss" scoped>
.service-orders-table-paginator {
  background: $light-gray;
  gap: 24px;
  height: 48px;
}

::v-deep .paginator {
  background: transparent;

  .loading & {
    width: 100%;
    max-width: 400px;
  }

  .btn {
    background: transparent;
    margin: 0 8px;

    &:not(:first-child):not(:last-child) {
      font-weight: 500;
      height: 24px;
      width: 24px;
    }

    &.current {
      background: $light-gray-3;
    }
  }
}

.page-input {
  width: 90px;
}
</style>

<template lang="pug">
  .service-orders-table-paginator.flex.center.vertical-center(:class="{ loading: fetching }")
    paginator.paginator(
      data-testid="paginator"
      :disabled="fetching"
      :loading="fetching"
      :per-page="pagination.perPage"
      :total="pagination.total"
      :value="value"
      @input="(value) => $emit('input', value)"
    )
    page-input.page-input(
      :loading="fetching"
      :pagination="pagination"
      :value="value"
      @input="(value) => $emit('input', value)"
    )
</template>

<script>
import PageInput from "./page-input.vue"

export default {
  components: {
    PageInput
  },

  props: {
    fetching:   { type: Boolean, default: true },
    pagination: { type: Object, default: () => ({ perPage: 0, total: 0 }) },
    value:      { type: Number, default: 1 }
  },

  emits: ["input"]
}
</script>

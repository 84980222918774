<style lang="scss" scoped>

.new-maintenance-vehicle-model-group {
  // max-height: calc(100vh - 250px);
}

</style>


<template lang="pug">

  app-modal(
    :heading="$t('.heading')",
    :width="996",
    @close="goBack"
  )
    .body.new-maintenance-vehicle-model-group
      form(@submit.prevent="submit", method="post")
        vehicle-model-group-maintenance-tables-select-list(
          v-model="resource.maintenanceTableIds",
          :errors="errors",
          :supplier-id="_supplierId",
          @supplier-loaded="formLoaded = true"
        )

    template(#footer)
      .footer
        app-button(
          outline,
          theme="gray",
          @click="goBack",
        ) {{ $t('btn.cancel') }}

        app-button(
          @click="submit",
          :disabled="!formLoaded",
          data-testid="submitForm"
        )
          span {{ $t('.btn.add.label') }}

</template>


<script>

// Mixins
import { mixins } from "movida-common.vue"

// Extends
import View from "@/views/view"

// Models
import models from "@/models"

// Components
import VehicleModelGroupMaintenanceTablesSelectList from "./new/vehicle-model-group-maintenance-tables-select-list"

const { FormMixin } = mixins
const { Base } = models

class MaintenanceTablesBatch extends Base {
  static get attrs() {
    return _.uniq([
      ...super.attrs,
      "maintenanceTableIds"
    ])
  }
}

export default {
  name: "NewSupplierServiceSupportMaintenanceVehicleModelGroups",

  components: {
    VehicleModelGroupMaintenanceTablesSelectList
  },

  extends: View,

  mixins: [FormMixin],

  props: {
    backRouteQuery: { type: Object, default: () => ({}) }
  },

  data() {
    return {
      i18nScope: "suppliers.service-supports.maintenance.vehicle-model-groups.new",

      id: this.$route.params.id,

      formLoaded: false,

      resource: new MaintenanceTablesBatch({ maintenanceTableIds: [] })
    }
  },

  computed: {
    backRoute() {
      return {
        name:   "supplierServiceSupportMaintenanceVehicleModelGroups",
        params: { id: this.id },
        query:  this.backRouteQuery
      }
    },

    _supplierId() {
      return this.id
    }
  },

  methods: {
    // @override extends view.vue
    goBack() {
      // Voltando para rota pai ao fechar o modal
      this.$router.push(this.backRoute)
    },

    // @override Form mixin
    submitRequest() {
      const params = this.resource.$serialize()

      return this.$sdk.suppliers.batchCreateMaintenanceVehicleModelGroups({
        id: this.id,
        params
      })
    },

    // @override Form mixin
    submitSuccess(response) {
      this.$notifications.info(this.$t(".notifications.submit.success"))

      this.$emit("create")
      this.$router.push(this.backRoute)
    },

    // @override Form mixin
    submitError(err) {
      this.$err.log(err)

      this.$notifications.error(this.$t(".notifications.submit.error"))
    }
  }
}

</script>

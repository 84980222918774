/**
 * Mixin para lidar com downloads de anexos
 */

import AttachAction from "./attach-action"

export default {
  extends: AttachAction,

  methods: {
    async downloadAttachment(attachment) {
      const url = await this.getAttachmentDownloadUrl(attachment)

      if (!url) return

      const link = document.createElement("a")
      link.href = url
      link.download = attachment.name
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    },

    async getAttachmentDownloadUrl(attachment) {
      if (!attachment?.blobId) return null

      const requestParams = {
        ...{
          blobId: attachment.blobId,
          params: { disposition: "attachment" }
        },
        ...(this.type == "quote" ? { quoteId: this.quoteId } : {})
      }

      try {
        const response = await this.sdk.getDownloadURL(requestParams)

        return response.data.url
      }
      catch (error) {
        this.$err.log(error)
        this.$notifications.error(
          this.$t(".notifications.download.error", { name: attachment.name })
        )

        return null
      }
    }
  }
}

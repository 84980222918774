<!--
<doc lang="markdown">

Exibe um modal

Como usar:
```pug
app-modal(
  :width="width",
  :heading="heading",

  footer,
  :cancel-label="this.actions.cancel",
  :confirm-label="this.actions.confirm",
  @cancel="confirm(false)",
  @confirm="confirm(true)",
)
  .body
    span {{ message }}
```

_Props_:
header:                   Define se o cabeçalho padrão deve ser exibido                           [true]
footer:                   Define se o rodapé padrão deve ser exibido na ausência do slot "footer" [false]
loading:                  Informa ao componente quando o aplicativo está carregando os dados      [false]
closeButton:              Define a presença de um botão com ícone para fechar o modal             [false]
heading:                  Define o texto (e a presença) de título do cabeçalho                    ['']
transition:               Transição do modal                                                      ['modal']
clickOutsideClose:        Evita o fechamento do modal ao clicar fora dele                         [false]
fullHeight:               Define se o modal deve cobrir a tela inteira verticalmente              [false]
width:                    Largura do modal                                                        [null]
confirmButton:            Define se o rodapé padrão possui botão de confirmação                   [true]
confirmLabel:             Texto do botão de confirmação do rodapé                                 [i18n.t('btn.submit.label')]
confirmLabelLoading:      Texto do botão de confirmação do rodapé em estado de carregamento       [i18n.t('btn.submit.loading')]
cancelLabel:              Texto do botão de cancelamento do rodapé                                [i18n.t('btn.cancel')]
modalId:                  Id do modal                                                             [random value]

_Eventos_:
confirm: Ao clicar no botão de confirmação do rodapé padrão
cancel:  Ao clicar no botão de cancelamento do rodapé padrão
open:    Ao iniciar a criação do modal
opened:  Ao finalizar a criação do modal
close:   Ao iniciar a destruição do componente
closed:  Ao finalizar a destruição do componente

_Slots_:
default: O corpo do modal
header:  Cabeçalho com padrão
footer:  Rodapé com padrão

</doc>
-->

<style lang="scss" scoped>

.app-modal {
  $modal-z-index: 400 !default;

  $padding-y: 16px;
  $padding-x: 24px;


  position: fixed;
  z-index: $modal-z-index;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba($dark-gray, 0.7);
  display: table;
  transition: opacity 0.3s ease;

  .wrapper {
    content: table-cell;
    z-index: $modal-z-index + 1;
  }

  .frame {
    display: flex;
    flex-direction: column;

    position: relative;
    /*
      TODO definir largura do modal. Entender como reusar componente tanto
      para desktop quanto para phone.
      ex: width: 300px;
    */
    width: 94%;

    // Altura total menos margin
    max-height: calc(100vh - 50px); // era: - 40px);
    max-width:  calc(100vw - 50px); // era: - 40px);

    margin: 20px auto;
    margin-top: 40px;
    overflow: auto;
    background-color: $white;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba($black, 0.33);
    transition: all 0.3s ease, width 0ms;

    &.full-height {
      height: 100%;
    }

    .close-btn {
      z-index: $modal-z-index + 2;
      border: 0;
      outline: 0;
      padding: 0;
      margin: 0;
      position: absolute;
      right: $padding-x;
      top: $padding-y;
      width: 32px;
      height: 32px;
      font-size: 20px;
      color: $dark-gray;
      background-color: transparent;
      cursor: pointer;

      display: flex;
      align-items: center;
      justify-content: center;

      &:hover {
        color: $black;
      }
    }

    > .header,
    ::v-deep > .header {
      color: $primary;
      margin-top: 0;
      padding: $padding-y $padding-x;
      border-bottom: 1px solid $light-gray-3;

      .heading {
        font-size: 18px;
        font-weight: 500;
        color: $dark-gray;
        min-height: 32px;
        line-height: 32px;
        font-family: $secondary-font;
      }
    }

    .content {
      max-height: calc(100vh - 40px);
      overflow-y: auto;

      > .body,
      ::v-deep > .body {
        overflow: auto;
        flex-grow: 1;

        padding: $padding-y $padding-x;
      }
    }

    > .footer,
    ::v-deep > .footer {
      display: flex;
      justify-content: center;
      justify-content: space-between;

      padding: $padding-y $padding-x;

      border-top: 1px solid $light-gray-3;
    }
  } // .frame



  /*
    * The following styles are auto-applied to elements with
    * transition="modal" when their visibility is toggled
    * by Vue.js.
    *
    * You can easily play with the modal transition by editing
    * these styles.
    */
  .modal-enter {
    opacity: 0;
  }

  .modal-leave-active {
    opacity: 0;
  }

  .modal-enter .modal-container,
  .modal-leave-active .modal-container {
    transform: scale(1.1);
  }

} // .app-modal

</style>


<template lang="pug">

  portal(:to="portal")
    //- delegating class to "root" element (besides portal)
    //- ref: https://github.com/LinusBorg/portal-vue/issues/101#issuecomment-540431702
    .app-modal(:id="modalId", :class="$vnode.data.staticClass")
      transition(
        :name="transition",
        @before-enter="beforeEnter",
        @after-enter="afterEnter"
        appear
      )
        .wrapper
          .frame(
            v-click-outside="vClickOutsideConfig",
            :style="style",
            :class="{ 'full-height': fullHeight }"
          )
            button.close-btn(
              v-if="closeButton",
              type="button",
              @click="close",
            )
              i.fal.fa-times(aria-hidden="true")

            slot(name="header")
              header.header(v-if="header")
                h1.heading {{ heading }}

            .content
              slot
                //- use .body para herdar espaçamento padrão

            slot(name="footer")
              .footer.border(v-if="footer")
                app-button(
                  :key="`${modalId}-footer-cancel`",
                  outline,
                  theme="gray",
                  :loading="loading",
                  @click="cancel"
                ) {{ cancelLabel }}

                app-button(
                  v-if="confirmButton",
                  :key="`${modalId}-footer-confirm`",
                  :loading="loading",
                  @click="confirm"
                )
                  span {{ confirmLabel }}
                  span(slot="loading") {{ confirmLabelLoading }}

</template>


<script>

// XXX: Cópia do componente em `movida-common.vue`
// ref: https://github.com/caiena/movida-gmf/issues/1419

import { i18n, events } from "utils.vue"
import { v4 as uuid } from "uuid"

import { freezeScrolling } from "@/lib"


export default {
  name: "AppModal",

  props: {
    header:              { type: Boolean, default: true    },
    footer:              { type: Boolean, default: false   },
    heading:             { type: String,  default: ""      },
    loading:             { type: Boolean, default: false   },
    closeButton:         { type: Boolean, default: true    },
    transition:          { type: String,  default: "modal" },
    clickOutsideClose:   { type: Boolean, default: true    },
    fullHeight:          { type: Boolean, default: false   },
    width:               { type: Number,  default: null    },
    confirmButton:       { type: Boolean, default: true    },
    confirmLabel:        { type: String,  default: () => i18n.t("btn.submit.label") },
    confirmLabelLoading: { type: String,  default: () => i18n.t("btn.submit.loading") },
    cancelLabel:         { type: String,  default: () => i18n.t("btn.cancel") },
    modalId:             { type: String,  default: () => uuid() },
  },

  data() {
    return {
      uuid,
      portal: null,

      vClickOutsideConfig: {
        handler: this.onClickOutside,
        middleware(event) {
          return event.target.className !== "modal"
        },
        events: ["mousedown"]
      }
    }
  },

  computed: {
    hasDefaultSlot() {
      return _.present(this.$slots.default)
    },

    hasHeaderSlot() {
      return _.present(this.$slots.header)
    },

    hasFooterSlot() {
      return _.present(this.$slots.footer)
    },

    parentScrollableElem() {
      return document.body
    },

    style() {
      if (!this.width) return
      return {
        width:       `${this.width}px`,
        "max-width": "100vw"
      }
    }
  },

  // lifecycle hooks
  // --
  created() {
    const callback = portal => { this.portal = portal }

    events.$emit("create-portal", callback)
  },

  beforeDestroy() {
    // garante que, caso o modal não tenha sido fechado, o scroll seja "religado"
    this.cleanup()

    events.$emit("destroy-portal", this.portal)
  },

  destroyed() {
    this.$emit("closed")
  },

  methods: {
    onClickOutside() {
      if (this.clickOutsideClose) return

      this.close()
    },

    // transition hooks
    // --
    beforeEnter() {
      // Já tenta congelar o scroll aqui, pois usa `position: fixed` e isso causa um "flick"
      // nos dispositivos móveis caso seja feito apenas no afterEnter()
      this.stopScrolling()
      this.$emit("open")
    },

    afterEnter() {
      // XXX garantindo que o scroll esteja congelado.
      if (this.isScrolling()) this.stopScrolling()
      this.focus()
      this.$emit("opened")
    },
    // --

    focus() {
      let autofocusEl = this.$el.querySelector("[autofocus]")
      if (autofocusEl) autofocusEl.focus()
    },

    cancel() {
      this.$emit("cancel")
      this.close()
    },

    cleanup() {
      // XXX evita "religar" scroll caso exista um overlay na página!
      // Isso permite a abertura de modal "em cima" de overlay.
      if (document.body.querySelector(".overlay")) return
      this.startScrolling()
    },

    close() {
      if (this.loading) return

      this.$emit("close")
    },

    confirm() {
      if (this.loading) return

      this.$emit("confirm")
    },

    isScrolling() {
      return !freezeScrolling.isFrozen(this.parentScrollableElem)
    },

    // "religa" scroll no body (conteúdo abaixo do overlay).
    startScrolling() {
      freezeScrolling.release(this.parentScrollableElem)
    },

    // evita scroll no body (conteúdo abaixo do overlay) enquanto o overlay
    // estiver aberto.
    stopScrolling() {
      freezeScrolling.freeze(this.parentScrollableElem)
    }
  }
}

</script>

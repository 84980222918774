/**
 * Mixin base para os mixins que executam ações com anexos
 */

const TYPES = [
  "blob",
  "quote"
]

export default {
  props: {
    quoteId: { type: [String, Number], default: null },
    type:    {
      type:      String,
      default:   "blob",
      validator: (value) => TYPES.includes(value)
    }
  },

  computed: {
    sdk() {
      return {
        blob:  this.$newSdk.blobs,
        quote: this.$newSdk.quotes.blobs
      }[this.type]
    }
  }
}

<style lang="scss" scoped>
</style>


<template lang="pug">

  popover-menu(
    v-if="popoverMenuAuthorization",
    :show.sync="showPopover",
    data-testid="popoverMenu"
  )
    popover-menu-button(
      v-if="$can('read', deal)",
      icon="fal fa-eye",
      :to="{ name: 'supplierDeal', params: { id: supplierId, dealId: deal.id } }",
      :text="$t('.actions.btn.show')",
      @close-menu="showPopover = false"
    )
    template(v-if="deal.isDraft")
      popover-menu-button(
        v-if="$can('update', deal)",
        icon="fal fa-pencil",
        :to="{ name: 'editSupplierDeal', params: { id: supplierId, dealId: deal.id } }",
        :text="$t('.actions.btn.edit')",
        @close-menu="showPopover = false"
      )

      destroy-action.destroy-button.popover-menu-button(
        :supplier-id="supplierId",
        :deal-id="deal.id",
        @click.native="showPopover = false"
        @destroy="$emit('destroy')",
        data-test="destroyAction"
      )
        .icon-content
          i.icon.fal.fa-trash-alt
        span {{ $t('.actions.btn.destroy') }}

</template>


<script>

// Components
import DestroyAction from "../destroy-action"

export default {
  components: {
    DestroyAction
  },

  props: {
    supplierId: { type: [String, Number], required: true },
    deal:       { type: Object, default: () => ({}) }
  },

  data() {
    return {
      i18nScope: "suppliers.deals.components.deal-info-card",

      showPopover: false
    }
  },

  computed: {
    popoverMenuAuthorization() {
      return this.deal.isDraft
        ? ["read", "update", "destroy"].some(action => this.$can(action, this.deal))
        : this.$can("read", this.deal)
    }
  }
}

</script>

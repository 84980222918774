<style lang="scss" scoped>

.new-supplier {
  padding-bottom: 24px;
}

.row + .row {
  margin-top: 24px;
}

.header {
  margin-bottom: 32px;
  padding-bottom: 16px;
  border-bottom: 1px solid $light-gray-3;
}

.title {
  font-size: 30px;
  font-weight: 500;
  font-family: $secondary-font;
}

.fields {
  padding-bottom: 24px;
  border-bottom: 1px solid $light-gray-3;
  margin-top: 24px;
  padding-top: 24px;
  border-top: 1px solid $light-gray-3;
}

.actions {
  margin-top: 24px;
}

.submit-actions {
  .button + .button {
    margin-left: 16px;
  }
}

.section + .section {
  margin-top: 24px;
  padding-top: 24px;
  border-top: 1px solid $light-gray-3;
}

.title {
  font-weight: bold;
  font-size: 20px;
  margin-bottom: 4px;
  font-family: $secondary-font;
  font-weight: 500;
}

.subtitle {
  font-family: $primary-font;
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 22px;
  margin-bottom: 16px;
}

.hierarchy-radio {
  height: 100%;
}

</style>


<template lang="pug">

  .new-supplier
    header.header
      h1.title {{ $t('.title') }}

    form.form(@submit.prevent="submit", method="post")

      .section.hierarchy
        h2.title {{ $t('.sections.hierarchy.title') }}
        h3.subtitle {{ $t('.sections.hierarchy.subtitle') }}

        ui-row.row
          radio-field.hierarchy-radio(
            name="supplier[hierarchy]",
            :options="hierarchyOptions",
            :loading="submitting",
            v-model="resource.hierarchy",
            :errors="errors.hierarchy",
            hide-label
          )

        ui-row.row(v-if="isBranch")
          ui-col(:span="8")
            head-office-select-field(
              name="supplier[headOffice]",
              :label="$t('.form.fields.headOffice.label')"
              :placeholder="$t('.form.fields.headOffice.placeholder')"
              :loading="submitting",
              :errors="errors.headOfficeId",
              data-as-value,
              @change-option="changeHeadOffice",
              data-test="headOfficeInput"
            )

          ui-col(:span="2")

      .section.cnpj(v-if="showCnpj")
        h2.title {{ $t('.sections.cnpj.title') }}
        h3.subtitle {{ $t('.sections.cnpj.subtitle') }}

        ui-row.row
          ui-col(:span="8")
            new-supplier-select-field(
              name="supplier[cnpj]",
              :placeholder="$t('.form.fields.cnpj.placeholder')"
              :loading="submitting",
              :errors="errors.cnpj",
              data-as-value,
              @change-option="changeNewSupplier",
              data-test="cnpjInput"
            )

          ui-col(:span="2")

      form-fields.fields(
        v-if="showForm",
        :resource="resource",
        :submitting="submitting",
        :errors="errors"
      )

      .section.actions.flex.space-between.vertical-center
        app-button(
          :to="{ name: 'suppliers' }",
          theme="gray",
          outline,
          :disabled="submitting"
        ) {{ $t('btn.cancel') }}

        .submit-actions
          app-button.button(
            type="submit",
            :loading="submitting && redirectToDetails",
            :disabled="!showForm || (submitting && !redirectToDetails)",
            @click="redirectToDetails = true",
            outline,
            data-test="submitButton"
          )
            span {{ $t('.btn.submitAndContinue.label') }}
            span(slot="loading") {{ $t('.btn.submitAndContinue.loading') }}

          app-button.button(
            type="submit",
            :loading="submitting && !redirectToDetails",
            :disabled="!showForm || (submitting && redirectToDetails)",
            data-test="submitButton"
          )
            span {{ $t('.btn.submit.label') }}
            span(slot="loading") {{ $t('.btn.submit.loading') }}

</template>


<script>

// Mixins
import FormMixin from "@/mixins/form-mixin"

// View
import BaseView from "@/views/base-view"

// Components
import FormFields from "./form-fields"
import NewSupplierSelectField from "./components/new-supplier-select-field"
import HeadOfficeSelectField from "./components/head-office-select-field"

// Models
import models from "@/models"
const { Supplier, Address } = models

export default {
  name: "NewSupplier",

  components: {
    FormFields,
    NewSupplierSelectField,
    HeadOfficeSelectField
  },

  extends: BaseView,

  mixins: [FormMixin],

  data() {
    return {
      i18nScope: "suppliers.new",
      routeName: "newSupplier",

      resource: new Supplier({ address: new Address() }),

      redirectToDetails: false,

      nestedAttributes: {
        address: null,

        contacts: {
          emails: null,
          phones: null
        }
      }
    }
  },

  computed: {
    hierarchyOptions() {
      return Supplier.$enums.hierarchy.values.map(value => ({
        value,
        label: this.$t(`models.supplier.enums.hierarchy.${value}`)
      }))
    },

    isBranch() {
      return _.get(this.resource, "hierarchy") === "branch"
    },

    showCnpj() {
      return _.get(this.resource, "hierarchy") === "head" || _.present(this.resource.headOfficeId)
    },

    showForm() {
      return _.present(this.resource.cnpj)
    }
  },

  watch: {
    isBranch() {
      if (this.isBranch === false) {
        this.resource.headOfficeId = null
      }
      else {
        this.changeNewSupplier(null)
      }
    }
  },

  created() {
    this.initialSerializedAttributes = this.serializeAttributes()
  },

  methods: {
    changeHeadOffice(option) {
      this.resource.headOfficeId = _.get(option, "data.id")
      this.changeNewSupplier(null)
    },

    changeNewSupplier(option) {
      const newSupplier = _.get(option, "data")
      const cnpj = _.get(option, "value")

      if (_.blank(cnpj)) {
        this.resource = new Supplier({
          ..._.pick(this.resource, ["headOfficeId", "hierarchy"]),
          address: new Address()
        })
        return
      }

      this.resource = new Supplier({
        cnpj:    cnpj.replace(/[^0-9]/g, ""),
        address: new Address(),

        ..._.pick(this.resource, ["headOfficeId", "hierarchy"]),

        ..._.pick(newSupplier, [
          "name",
          "tradingName",
          "externalId",
          "ie",
          "im",
          "ieIsento",
          "imIsento",
          "sapId"
        ])
      })
    },

    // @override Form mixin
    serializeAttributes() {
      const sectorIds = this.resource.sectors.map(sector => sector.id)

      return {
        ...this.resource.$serialize({}, this.nestedAttributes),
        sector_ids: sectorIds
      }
    },

    // @override Form mixin
    submitRequest() {
      return this.$newSdk.suppliers.create({ params: this.serializeAttributes() })
    },

    onSubmitSuccess({ data: { id, name } }) {
      this.$notifications.info(this.$t(".notifications.submit.success", { name }))

      if (this.redirectToDetails) {
        this.$router.push({ name: "editSupplierDetails", params: { id } })
        return
      }

      this.$router.push({ name: "supplier", params: { id } })
    },

    onSubmitError(err) {
      const error = err.error || err

      if (error.cancelled) return

      this.submitError = error
      this.submitErred = true

      this.$err.log(this.submitError)
      this.$notifications.error(this.$t(".notifications.submit.error"))

      if (error.originalError) this.setFormErrors(error.originalError)

      this.redirectToDetails = false
    }
  }
}

</script>

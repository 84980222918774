// Base
import BaseRequests from "../Base"

// Models
import models from "@/models"
const { SupplierServiceVehicleModelGroup } = models

class SupplierServiceVehicleModelGroupsRequests extends BaseRequests {
  static get selector() {
    return "suppliers.serviceVehicleModelGroups"
  }

  all({
    supplierId, params = {}, options = {}
  }) {
    this.verifyData("all", { supplierId })

    return this.action({
      // Estamos usando POST pois o filtro por ids pode ser muito grande e estourar o limite da URL.
      method:  "post",
      url:     `/suppliers/${supplierId}/service_vehicle_model_groups/search`,
      params,
      options: {
        model: SupplierServiceVehicleModelGroup,

        ...options
      }
    })
  }

  find({
    supplierId, serviceVehicleModelGroupId, params = {}, options = {}
  }) {
    this.verifyData("find", { supplierId, serviceVehicleModelGroupId })

    return this.action({
      method:  "get",
      url:     `/suppliers/${supplierId}/service_vehicle_model_groups/${serviceVehicleModelGroupId}`,
      params,
      options: {
        model: SupplierServiceVehicleModelGroup,

        ...options
      }
    })
  }

  destroy({ supplierId, serviceVehicleModelGroupId }) {
    this.verifyData("destroy", { supplierId, serviceVehicleModelGroupId })

    return this.action({
      method: "delete",
      url:    `/suppliers/${supplierId}/service_vehicle_model_groups/${serviceVehicleModelGroupId}`
    })
  }
}

export default SupplierServiceVehicleModelGroupsRequests

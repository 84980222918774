import Auth from "./Auth"
import Brands from "./Brands"
import Cities from "./Cities"
import Deals from "./Deals"
import MaintenancePackages from "./MaintenancePackages"
import Maintenances from "./Maintenances"
import Manufacturers from "./Manufacturers"
import Products from "./Products"
import Sectors from "./Sectors"
import ServiceOrders from "./ServiceOrders"
import ServicePackages from "./ServicePackages"
import Services from "./Services"
import Stores from "./Stores"
import StoreSupplierMaintenanceVehicleModelGroups from "./StoreSupplierMaintenanceVehicleModelGroups"
import StoreSuppliers from "./StoreSuppliers"
import Suppliers from "./Suppliers"
import Symptoms from "./Symptoms"
import Tickets from "./Tickets"
import TicketTypes from "./TicketTypes"
import VehicleModelGroupMaintenanceTables from "./VehicleModelGroupMaintenanceTables"
import VehicleModelGroups from "./VehicleModelGroups"
import VehicleModels from "./VehicleModels"
import VehicleParts from "./VehicleParts"

export default {
  // authentication
  Auth,

  // resources
  Brands,
  Cities,
  Deals,
  MaintenancePackages,
  Maintenances,
  Manufacturers,
  Products,
  Sectors,
  ServiceOrders,
  ServicePackages,
  Services,
  Stores,
  StoreSupplierMaintenanceVehicleModelGroups,
  StoreSuppliers,
  Suppliers,
  Symptoms,
  Tickets,
  TicketTypes,
  VehicleModelGroupMaintenanceTables,
  VehicleModelGroups,
  VehicleModels,
  VehicleParts, 
}

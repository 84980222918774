<style lang="scss" scoped>
</style>


<template lang="pug">

  .search
    form(
      method="get",
      @submit.prevent="$emit('search')"
    )
      ui-row(
        :columns="24"
        vertical-align="bottom"
      )
        ui-col(:span="9")
          input-field(
            name="q",
            :loading="loading",
            :placeholder="$t('.fields.q.placeholder')",
            v-model="params.q",
            hide-label,
            icon-left="far fa-search"
          )

        ui-col(:span="12")
          vehicle-model-select-field(
            name="vehicleModelId",
            v-model="params.vehicleModelId"
            :loading="loading",
            :label="$t('.fields.vehicleModelId.label')",
            :placeholder="$t('.fields.vehicleModelId.placeholder')",
            option-name-only
          )

        ui-col(:span="3")
          app-button(
            type="submit",
            :loading="loading",
            full-width,
            outline
          )
            span {{ $t('btn.search.label') }}
            span(slot="loading") {{ $t('btn.search.loading') }}

</template>


<script>

export default {
  props: {
    loading: { type: Boolean, default: false },
    params:  { type: Object, default: () => ({}) }
  },

  data() {
    return {
      i18nScope: "vehicle-model-groups.index.search-form"
    }
  }
}

</script>

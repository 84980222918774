<!--
  <doc lang="markdown">
    Componente para campos textuais (`input`) em formulários que representem números de telefone.
    Mostra os dados com máscara, mas recebe e retorna apenas números.
    A máscara se "adequa" entre telefone fixo (10 dígitos) ou celular (11 dígitos) conforme o número
    de dígitos inputados no campo, sem contar com os dois dígitos de DDD.

    _Props_:
    type:        O tipo do campo textual [tel]
    placeholder: O placeholder [(00) 00000-0000]

    _Eventos_:
    Nenhum evento

    _Slots_:
    Nenhum slot

    Como usar:
    ```pug
      phone-field(
        name="user[phone]",
        v-model="user.phone",
        :placeholder="$t('.something')",
        :errors="errors.phone"
      )
    ```
  </doc>
-->


<style scoped lang="scss">

@import "@/assets/styles/field";

</style>


<template lang="pug">

  .phone-field
    label.label-content(v-if="!hideLabel", :for="inputId", :class="{ error: hasErrors, focus }")
      span {{ labelText }}
      span(v-if="optional") {{ $t('form.optional') }}
      i.info-icon.far.fa-info-circle(
        v-if="info",
        @click="$emit('info-click')"
        v-tooltip.bottom="tooltip"
      )

    .input-wrapper
      input.input-inner(
        :style="style",
        :class="{ loading, disabled, error: hasErrors, 'icon-left': iconLeft, 'icon-right': iconRight }",
        :id="inputId",
        :autocomplete="autocompleteValue",
        ref="input",
        :autofocus="autofocus",
        :disabled="disabled || loading",
        :readonly="readonly",
        :maxlength="maxlength",
        :name="name",
        :placeholder="placeholder",
        :type="type",
        :value="maskedValue",
        v-mask="phoneMask",
        @input="onInput",
        @focus="focus = true",
        @blur="focus = false"
      )

      template(v-if="suffix")
        span.suffix(ref="suffix") {{ suffix }}

      i.icon(
        v-if="iconLeft",
        :class="{ [iconLeft]: true, left: true, clickable: clickableIcon }",
        @click="$emit('left-icon-click')",
        v-tooltip.bottom="tooltipLeft"
      )

      i.icon(
        v-if="iconRight",
        :class="{ [iconRight]: true, right: true, clickable: clickableIcon }",
        @click="$emit('right-icon-click')",
        v-tooltip.bottom="tooltipRight"
      )

    template(v-if="hasMessageErrors")
      span.error-message {{ errors[0] }}

</template>


<script>

import { components } from "movida-common.vue"
const { InputField } = components

export default {
  name: "PhoneField",

  extends: InputField,

  props: {
    type:        { type: String, default: "tel" },
    placeholder: { type: String, default: "(00) 00000-0000" }
  },

  data() {
    return {
      maskedValue: this.value
    }
  },

  computed: {
    kind() {
      if (_.blank(this.value)) return
      return this.maskedValue.length === 15 ? "mobile" : "landline"
    },

    phoneMask() {
      switch (this.kind) {
        case "mobile": return "(00) 00000-0000"
        case "landline":
        default:
          return "(00) 0000-00009"
      }
    }
  },

  watch: {
    value() {
      if (this.value === null) this.maskedValue = ""
    }
  },

  methods: {
    onInput(event) {
      if (this.disabled) return

      let value = null
      // eslint-disable-next-line prefer-destructuring
      if (event.target.value !== "") value = event.target.value.replace(/\D/g, "")

      this.maskedValue = event.target.value

      this.$emit("input", value)
    }
  }
}

</script>

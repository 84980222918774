import Vue from "vue"

// Initializers. A ordem importa.
import "./globals"
import "./moment"
import "./go-back"
import "./google-analytics"
import "./recaptcha"
// TODO: migrar e remover
import "./sdk-client"
// TODO: renomear para sdk-client
import "./new-sdk-client"
import "./google-maps"
import "./vue"
import "./flatpickr"
import "./gtm"
import "./validators"
import "./trix"

import initializeAuth from "./auth"
import initializeAbilities from "./abilities"
import initializeI18n from "./i18n"
import initializeComponents from "./components"
import initializeStandardTime from "./standard-time"
import initializeConfirmationModal from "./confirmation-modal"

initializeAuth(Vue)
initializeAbilities(Vue)
initializeI18n(Vue)
initializeComponents(Vue)
initializeStandardTime(Vue)
initializeConfirmationModal(Vue)

// XXX: Para ambiente de desenvolvimento utilizando o chrome,
// ao carregar as libs do sentry gera vários logs de warning, por isso
// estamos importanto o sentry apenas em ambiente de produção.
// TODO: Analisar problema em versões futuras do chrome
async function production() {
  if (process.env.NODE_ENV !== "production") return
  await import("./sentry")
}

production()

<style lang="scss" scoped>

.option {
  height: 40px;
  padding: 0 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid $light-gray-3;
  cursor: pointer;

  .remove-icon-content {
    width: 24px;
    height: 24px;
    line-height: 24px;
    text-align: center;
    border-radius: 50px;
    color: $gray-2;
    transition: all 100ms linear;
  }

  &.active {
    color: $primary;
    background: $light-gray-2;
  }

  &.focus {
    background: $light-gray-2;
    color: $dark-primary-2;

    .remove-icon-content {
      background: $light-gray-3;
      color: $primary;
    }
  }
}

.create {
  font-size: 14px;
  font-weight: bold;
}

.error {
  display: block;
  text-align: center;
  padding: 4px 12px;
  font-size: 14px;
  font-style: italic;

  .link {
    margin-left: 5px;
    font-size: 14px;
    font-style: italic;
    font-weight: normal;
  }
}

.empty {
  display: block;
  text-align: center;
  padding: 12px;
  font-size: 14px;
  font-style: italic;
}

</style>


<template lang="pug">

  .brand-select-field
    select-field(
      :name="name",
      :label="label",
      :fetching="fetching",
      :disabled="disabled",
      :placeholder="placeholder",
      :optional="optional"

      option-as-value,
      :loading="loading || fetchingOption"
      :options="options",
      :default-filter="false",
      :errors="errors",
      searchable,

      :value="option",
      @input="onSelect",

      @search="onSearch",
      @open="_fetch",
      @close="onClose",
      @inputSearch="(newSearch) => search = newSearch"
    )
      template(#menuContentHeader)
        template(v-if="fetchError")
          label.error
            span {{ $t('.error.message') }}
            app-button.link(theme="link", @click="onSearch") {{ $t('.error.link') }}

      template(#fetching)
        li.option {{ $t('.loading') }}

      template(#option="{ props: { option, index, focus, active } }")
        .option(:class="{ focus, active, create: option.id === '__create' }")
          span {{ option.label }}
          .remove-icon-content(v-if="active && option.id !== '__create'")
            i.fal.fa-times

      template(#menuContentFooter)
        template(v-if="!fetching && !fetchError && empty")
          label.empty {{ $t('.empty') }}


    app-modal.new-modal(
      v-if="showNewModal",
      :heading="$t('.modalTitle')",
      :width="540",
      @close="showNewModal = false"
    )
      new-brand(
        :params="createParams",
        @create="onCreate",
        @cancel="showNewModal = false"
      )

</template>


<script>

// Local components
import NewBrand from "./brand-select/new.vue"

// Mixins
import FieldMixin from "@/mixins/field"
import AsyncSelect from "@/mixins/select/async"
import CreateSelectMixin from "@/mixins/select/create"

export default {
  components: {
    NewBrand
  },

  extends: AsyncSelect,

  mixins: [FieldMixin, CreateSelectMixin],

  data() {
    return {
      i18nScope: "products.components.brand-select-field",

      fetchingOption: false,
      fetchError:     false,

      // Data para criar novo recurso
      showNewModal: false,

      // data from extends AsyncSelect
      model: "Brand"
    }
  },

  computed: {
    createParams() {
      return {
        name: this.search
      }
    }
  },

  methods: {
    // @overrides extends AsyncSelect
    async fetchOption(id) {
      try {
        this.fetchingOption = true

        const response = await this.$sdk.brands.get({ id })
        const normalizedData = _.camelizeKeys(response.data)

        this.option = this.dataToOption(normalizedData)
      }
      catch (err) {
        this.$err.log(err)
      }
      finally {
        this.fetchingOption = false
      }
    },

    onCreate(brand) {
      let option = this.dataToOption(brand)

      this.showNewModal = false

      this.option = option
      this.$emit("change-option", option)
      this.$emit("input", brand.id)
    },

    // @overrides extends CreateSelectMixin
    onSelectCreate(option) {
      this.showNewModal = true
    },

    // @overrides extends AsyncSelect
    fetchRequest() {
      return this.$sdk.brands.list({ params: { q: this.search } })
    },

    // @overrides extends AsyncSelect
    onFetchSuccess(response, { normalizedData }) {
      const fetchedOptions = normalizedData.map(data => (
        this.dataToOption(data)
      ))

      this.options =  [...fetchedOptions]

      if (!(this.hideCreateOption || _.blank(this.search) || this.hasSearchTextAsOption(fetchedOptions))) {
        this.options.push(this.createOption)
      }
    },

    // @overrides extends AsyncSelect
    onFetchError(error) {
      this.$err.log(error)
    }
  }
}

</script>

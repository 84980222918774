<style lang="scss" scoped>
.loading {
  align-items: center;
  color: white;
  display: flex;
  font-size: 32px;
  height: 100%;
  justify-content: center;
}
</style>

<template lang="pug">
.pdf-viewer
  .loading(v-if="loading")
    i.fas.fa-spinner.fa-spin
  template(v-else)
    iframe(:src="pdfUri" width="100%" height="100%")
</template>

<script>
import axios from "axios"

export default {
  name: "PdfViewer",

  props: {
    axiosInstance: { type: Function, default: axios.create() },
    showToolbar:   { type: Boolean, defaut: false },
    src:           { type: String, required: true }
  },

  data() {
    return {
      error:      null,
      loading:    false,
      pdfBlobUrl: null
    }
  },

  computed: {
    pdfUri() {
      const { pdfBlobUrl } = this
      const toolbar = this.showToolbar ? "" : "#toolbar=0"

      return `${pdfBlobUrl}${toolbar}`
    }
  },

  mounted() {
    this.fetch()
  },

  beforeDestroy() {
    this.clearBlob()
  },

  methods: {
    async fetch() {
      this.loading = true

      this.clearBlob()

      try {
        const response = await this.axiosInstance.get(this.src, { responseType: "blob" })
        this.pdfBlobUrl = URL.createObjectURL(response.data)
      }
      catch (error) {
        this.error = error.message
      }
      finally {
        this.loading = false
      }
    },

    clearBlob() {
      if (this.pdfBlobUrl) {
        URL.revokeObjectURL(this.pdfBlobUrl)
        this.pdfBlobUrl = null
      }
    }
  }
}
</script>

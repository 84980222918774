// Base
import Role from "./role"


// Papel de "Auditor", com acesso de leitura total
class AuditorRole extends Role {
  static build(user, options = {}) {
    return this.define((can, cannot) => {
      // acesso de leitura total
      // XXX: usamos "index" e "read" separadamente
      can(["index", "read"], "all")
    })
  }
}


export default AuditorRole

<style scoped lang="scss">

.deal-products-table {
  .skeleton-table {
    margin-top: 16px;

    .skeleton-row + .skeleton-row {
      margin-top: 24px;
    }
  }

  .empty {
    margin-top: 24px;

    .message {
      font-style: italic;
      font-weight: 400;
    }

    .icon {
      user-select: none;
      margin-bottom: 4px;
      height: 88px;
      width: 88px;
    }
  }
}

</style>


<template lang="pug">

  .deal-products-table
    template(v-if="initializing")
      .skeleton-table
        deal-product-row-skeleton.skeleton-row(v-for="i in 5", :key="`deal-product-row-skeleton-${i}`")

    template(v-else)
      deal-products-table-header(
        :loading="loading",
        @sort="param => $emit('sort', param)"
        :sort-param="sortParam",
        :sort-order="sortOrder",
        data-testid="dealProductsTableHeader"
      )

      template(v-if="empty")
        .empty.flex.center
          .flex.column-direction.vertical-center
            img.icon(:src="emptyIcon")
            span.message {{ $t('index.empty.message') }}

      template(v-else)
        deal-product-row(
          v-for="dealProduct in dealProducts",
          :key="`dealProduct-${dealProduct.id}`",
          :deal-product="dealProduct",
          :editable="editable",
          :loading="loading",
          @destroy="$emit('destroy')",
          data-testid="dealProductRow"
        )

</template>


<script>

// Assets
import emptyIcon from "@/assets/images/icons/search/products/none-found.svg"

// Components
import DealProductRow from "./deal-product-row"
import DealProductRowSkeleton from "./deal-product-row-skeleton"
import DealProductsTableHeader from "./deal-products-table-header"

export default {
  components: {
    DealProductRow,
    DealProductRowSkeleton,
    DealProductsTableHeader
  },

  props: {
    dealProducts: { type: Array, required: true },
    editable:     { type: Boolean, default: false },
    initializing: { type: Boolean, default: false },
    loading:      { type: Boolean, default: false },
    sortedBy:     { type: String, default: "product_name:asc" }
  },

  data() {
    return {
      i18nScope: "suppliers.deals.products.index.deal-products-table",

      emptyIcon
    }
  },

  computed: {
    empty() {
      return _.blank(this.dealProducts)
    },

    sortParam() {
      return this.sortedBy.split(":")[0]
    },

    sortOrder() {
      return this.sortedBy.split(":")[1] || "asc"
    }
  }
}

</script>

<style scoped lang="scss">

.ticket-row {
  display: grid;
  grid-template-columns: 70px 40px 0.6fr 120px 1fr 180px 64px;

  &.loading {
    .column {
      &.id-column {
        .id-tag {
          color: $gray-2;
        }
      }

      &.actions-column {
        ::v-deep .movida-common__app-button {
          color: $gray-2;

          &:hover {
            color: $orange;
          }
        }
      }

      .text,
      .link ::v-deep .app-span {
        color: $gray-2;
      }
    }
  }

  .column {
    padding: 0 12px;
    border-bottom: 1px solid $light-gray-3;
    height: 48px;
    overflow: hidden;

    .icon-model {
      margin-right: 8px;
    }

    .diamond-icon {
      margin: 0 4px 0 4px;
      font-size: 4px;
      font-weight: 900;
      color: $gray-3;
    }

    .button {
      overflow: hidden;
    }

    .plate {
      background-color: $light-gray;
      border: 1px solid $light-gray-4;
      font-family: $primary-monospace-font;
      font-size: 14px;
      color: $dark-gray;
    }

    .initials {
      font-family: $secondary-font;
      font-weight: 500;
      font-size: 14px;
      color: $gray-3;
    }

    .text {
      font-weight: 400;
      font-size: 14px;
      color: $dark-gray;
      text-align: initial;
    }

    .tag {
      margin-left: 4px;
    }
  }
}

.ticket-row {
  .column.ticket-column {
    .link {
      &:hover {
        .text {
          color: $orange;
        }
      }
    }
  }
}

</style>


<template lang="pug">

  //- background-link.ticket-row(:class="{ loading }", :to="showRoute")
  .ticket-row(:class="{ loading }")
    .column.id-column.flex.vertical-center
      id-tag.id-tag(:value="ticket.id", data-testid="ticketId")

    .column.state-column.flex.vertical-center.center
      ticket-state-icon(:state="ticket.state", data-testid="ticketState")

    .column.ticket-column.flex.vertical-center
      app-button.link(theme="link", :to="defaultRoute")
        .content.flex
          app-span.text(:value="$t(`models.ticket.enums.type.${ticket.type}`)", crop)
          facilities-tag.text.tag(
            v-if="facilities",
            :value="facilities",
            data-testid="ticketFacilities"
          )

    .column.license-plate-column.flex.vertical-center
      vehicle-plate-tag.plate.space.text(data-testid="ticketLicensePlate") {{ ticket.vehicleLicensePlate }}

    .column.vehicle-column.flex.vertical-center(data-testid="vehicleColumn")
      vehicle-manufacturer-icon.icon-model(:value="manufacturer.name")
      .content.flex.vertical-center
        app-span.text(:value="model.name", crop, data-testid="vehicleModel")

        i.diamond-icon.fa.fa-diamond
        app-span.text(:value="version.name", data-testid="vehicleVersion")

        i.diamond-icon.fa.fa-diamond
        template(v-if="vehicleModel.year")
          app-span.text(:value="vehicleModel.year", data-testid="vehicleYear")
        template(v-else)
          app-span.text {{ $t('.columns.yearRange.label', { initial: version.initialYear, final: version.finalYear } )}}

    .column.creation-column.flex.vertical-center
      app-span.text(data-testid="ticketCreatedAt") {{ $standardTime(ticket.createdAt) }}

    .column.actions-column.flex.vertical-center.flex
      popover-menu(
        v-if="popoverMenuAuthorization",
        :show.sync="showPopover",
        data-testid="popoverMenu"
      )
        popover-menu-button(
          v-if="$can('read', ticket)",
          icon="fal fa-eye",
          :to="showRoute",
          :text="$t('.actions.btn.show.label')",
          @close-menu="showPopover = false"
        )

        popover-menu-button(
          v-if="$can('update', ticket) && isDraft",
          icon="fal fa-pencil",
          :to="editRoute",
          :text="$t('.actions.btn.edit.label')",
          @close-menu="showPopover = false"
        )

        destroy-ticket-action.destroy-button.popover-menu-button(
          v-if="isDraft",
          :ticket-id="ticket.id",
          :ticket-store-id="ticket.storeId",
          :loading="loading",
          @destroy="$emit('destroy')"
        )
          template(#default="{ props: { destroy, submitting } }")
            app-button(
              theme="neutral",
              size="normal",
              border="rounded",
              :disabled="submitting",
              :loading="loading",
              @click="destroy"
            )
              .icon-content
                i.icon.fal.fa-trash-alt
              span {{ $t('.actions.btn.destroy.label') }}

</template>


<script>

import TicketRow from "@/views/tickets/index/ticket-row.vue"

export default {
  extends: TicketRow,

  computed: {
    showRoute() {
      return {
        name:   "storeTicket",
        params: {
          id:       this.$params.asInteger(this.$route.params.id),
          ticketId: this.ticket.id
        }
      }
    }
  }
}

</script>

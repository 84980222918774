<style lang="scss" scoped>

.service-order-row {
  display: grid;
  grid-template-columns: 40px 185px 291px 38px;
  padding: 12px 0;

  .type,
  .id,
  .supplier-name {
    font-family: $secondary-font;
    font-weight: 400;
    font-size: 14px;
  }

  .unknown {
    font-style: italic;
  }

  .date,
  .address {
    font-weight: 300;
    font-size: 12px;
  }

  .service-order-info {
    .identifier {
      .id {
        margin: 0 4px;
      }
    }
  }
}

</style>


<template lang="pug">

  .service-order-row.flex
    .flex.vertical-center.center
      ticket-state-icon(:state="serviceOrder.state")

    .service-order-info
      .identifier.flex.vertical-center
        app-span.type(:value="kind" :class="{ unknown: kindUnknown }")
        app-span.id(:value="id")

      .date
        app-span(:value="$t('.createdAt', { date: $l('date.formats.default', serviceOrder.createdAt) })")

    .supplier
      .supplier-name
        app-span(:value="name", crop)
      .address
        app-span(:value="address", crop)

    .flex.vertical-center.center
      app-button(theme="link" @click="$emit('select', serviceOrder.id)")
        i.icon.far.fa-arrow-right

</template>


<script>

// Models
import models from "@/models"
const { VehicleServiceOrder } = models

export default {
  props: {
    serviceOrder: { type: Object, required: true }
  },

  data() {
    return {
      i18nScope: "tickets.drafts.vehicles.components.last-tickets-preview.service-order-row",

      VehicleServiceOrder
    }
  },

  computed: {
    id() {
      return `#${this.serviceOrder.id}`
    },

    supplier() {
      return _.get(this.serviceOrder, "supplier") || {}
    },

    name() {
      return this.supplier.tradingName
    },

    address() {
      return this.supplier.fullAddress
    },

    serviceKind() {
      return _.get(this.serviceOrder, "serviceKind")
    },

    kindUnknown() {
      return _.isNil(this.serviceKind)
    },

    kind() {
      // TODO: Ampliar `$tEnum` para cobrir valores nulos
      let kind = VehicleServiceOrder.$enums.serviceKind.all[this.serviceKind]

      return VehicleServiceOrder.$tEnum("serviceKind", kind)
    }
  }
}

</script>

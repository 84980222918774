<!--
<doc lang="markdown">
Exibe um cabeçalho de formulário estilo _wizard_, com etapas e sub-etapas

_Props_: structure, currentSectionIndex, currentStepIndex
Onde `structure` é uma lista no formato:
```js
[
  { name: 'Nome da primeira seção', steps: 2 },
  { name: 'Nome da segunda seção', steps: 1 }
]
```
E os valores de `index` iniciam em 1.

Como usar:
```pug
wizard-header(
  :structure="structure",
  :current-section-index="1",
  :current-step-index="2"
)
```
</doc>
-->

<style lang="scss" scoped>

.wizard-header {
  background-color: $light-gray;
  border-radius: 8px;
  padding: 16px;

  .section {
    font-size: 14px;
    font-family: $secondary-font;

    &:not(:last-of-type) {
      flex-grow: 1;
    }

    .status {
      width: 24px;
      height: 24px;
      border: 1px solid;
      border-radius: 50%;
      font-weight: 500;
      margin-right: 8px;

      .icon {
        font-size: 12px;
        color: $orange;
      }
    }

    .section-name {
      font-weight: 400;
    }

    .step-line {
      height: 2px;
      width: 100%;
      margin-right: 8px;

      &:first-of-type {
        margin-left: 8px;
      }

      &.done {
        background-color: $dark-orange-2;
      }

      &.active {
        background-color: $orange;
      }

      &.inactive {
        background-color: $light-gray-3;
      }
    }

    &.done {
      .status {
        border-color: $orange;
      }

      .section-name {
        color: $dark-gray;
      }
    }

    &.active {
      .status {
        color: $white;
        border-color: $dark-orange;
        background-color: $dark-orange;
      }

      .section-name {
        color: $orange;
      }
    }

    &.inactive {
      .status {
        color: $gray-2;
        border-color: $light-gray-4;
      }

      .section-name {
        color: $gray-2;
      }
    }
  }
}

</style>


<template lang="pug">

  .wizard-header.flex.vertical-center
    template(v-for="(section, sectionIndex) in sections")
      .section.flex.vertical-center(:key="`section-${sectionIndex}`", :class="section.status")
        .status.flex.center.vertical-center.no-shrink
          template(v-if="section.status === 'done'")
            i.icon.far.fa-check
          template(v-else)
            span {{ sectionIndex + 1 }}

        span.section-name.no-shrink {{ section.name }}

        .flex.full-width(v-if="section.steps")
          template(v-for="(step, stepIndex) in section.steps")
            .step-line(:key="`step-${stepIndex}`", :class="step")

</template>


<script>

export default {
  name: "WizardHeader",

  props: {
    structure:           { type: Array, required: true },
    currentSectionIndex: { type: Number, required: true },
    currentStepIndex:    { type: Number, default: null }
  },

  data() {
    return {
      i18nScope: "components.wizard-header"
    }
  },

  computed: {
    sections() {
      return this.structure.map((section, sectionIndex) => {
        let status = "active"
        if (sectionIndex + 1 < this.currentSectionIndex) status = "done"
        if (sectionIndex + 1 > this.currentSectionIndex) status = "inactive"

        let steps = Array(section.steps).fill(status)
        if (status === "active") {
          for (const stepIndex of steps.keys()) {
            if (stepIndex + 1 < this.currentStepIndex) steps[stepIndex] = "done"
            if (stepIndex + 1 > this.currentStepIndex) steps[stepIndex] = "inactive"
          }
        }

        return {
          ...section,

          status,
          steps
        }
      })
    }
  }
}

</script>

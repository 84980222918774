<style lang="scss" scoped>

.vehicle-model-groups-table {
  .skeleton-table {
    margin-top: 16px;

    .skeleton-row + .skeleton-row {
      margin-top: 24px;
    }
  }

  .empty {
    margin-top: 24px;

    .message {
      font-style: italic;
      font-weight: 400;
    }

    .icon {
      user-select: none;
      margin-bottom: 4px;
      height: 88px;
      width: 88px;
    }
  }

  .list {
    margin-top: 16px;
  }
}

</style>


<template lang="pug">

  .vehicle-model-groups-table
    template(v-if="initializing")
      .skeleton-table
        vehicle-model-group-row-skeleton.skeleton-row(
          v-for="i in 5",
          :key="`vehicle-model-group-row-skeleton-${i}`"
        )

    template(v-else)
      vehicle-model-groups-table-header(
        :loading="loading",
        @sort="param => $emit('sort', param)"
        :sort-param="sortParam",
        :sort-order="sortOrder",
        data-testid="vehicleModelGroupsTableHeader"
      )

      template(v-if="empty")
        .empty.flex.center
          .flex.column-direction.vertical-center
            img.icon(:src="emptyIcon")
            span.message {{ $t('index.empty.message') }}

      template(v-else)
        vehicle-model-group-row(
          v-for="vehicleModelGroup in vehicleModelGroups",
          :key="vehicleModelGroup.id",
          :vehicle-model-group="vehicleModelGroup",
          :loading="loading",
          @destroy="$emit('changed')",
          @disabled="$emit('changed')",
          @enabled="$emit('changed')",
          data-testid="vehicleModelGroupRow"
        )

</template>


<script>

// Assets
import emptyIcon from "@/assets/images/icons/vehicle-model-groups/empty.svg"

// Components
import VehicleModelGroupRow from "./vehicle-model-group-row.vue"
import VehicleModelGroupRowSkeleton from "./vehicle-model-group-row-skeleton"
import VehicleModelGroupsTableHeader from "./vehicle-model-groups-table-header"

export default {
  components: {
    VehicleModelGroupRow,
    VehicleModelGroupRowSkeleton,
    VehicleModelGroupsTableHeader
  },

  props: {
    vehicleModelGroups: { type: Array, required: true },
    initializing:       { type: Boolean, default: false },
    loading:            { type: Boolean, default: false },
    sortedBy:           { type: String, default: "" }
  },

  data() {
    return {
      i18nScope: "vehicle-model-groups.index.vehicle-model-groups-table",

      emptyIcon
    }
  },

  computed: {
    empty() {
      return _.blank(this.vehicleModelGroups)
    },

    sortParam() {
      return this.sortedBy.split(":")[0]
    },

    sortOrder() {
      return this.sortedBy.split(":")[1] || "asc"
    }
  }
}

</script>

import Base from "../base"

class MovidaServiceOrderService extends Base {
  static get attrs() {
    return _.uniq([
      ...super.attrs,
      "description",
      "quantity",
      "price",
      "totalPrice",
      "discount",
      "serviceType", // enum
      "warranty",    // boolean

      // virtual associations
      "service"  // { name: string, sapId: string }
    ])
  }

  static get enums() {
    return {
      serviceType: {
        maintenance: "maintenance",
        corrective:  "corrective",
        damage:      "damage"
      }
    }
  }

  get name() {
    return this.service?.name
  }

  get sapId() {
    return this.service?.sapId
  }
}

export default MovidaServiceOrderService

<style lang="scss" scoped>

.table-row {
  display: grid;
  grid-template-columns: var(--grid-columns);

  &.loading {
    .column {
      .id-tag {
        color: $gray-2;
      }

      &.actions-column {
        ::v-deep .movida-common__app-button {
          color: $gray-2;

          &:hover {
            color: $orange;
          }
        }
      }

    }
  }

  .column {
    padding: 0 12px;
    border-bottom: 1px solid $light-gray-3;
    height: 56px;
    overflow: hidden;

    .id-tag {
      margin-left: 4px;
    }

    .button {
      overflow: hidden;
    }

    .text {
      font-family: $primary-font;
      font-weight: 400;
      font-size: 14px;
      text-align: initial;
    }

    .link ::v-deep .app-span:hover {
      color: $orange;
    }

    .vehicle-solutions-count {
      margin-left: 4px;
    }
  }
}

</style>


<template lang="pug">

  .table-row(:class="{ loading }", :style="styles")

    .column.selection-column.flex.vertical-center.center.flex
      checkbox-field(
        :name="`vehicleModelGroup-${this.vehicleModelGroup.id}`",
        hide-value-text,
        :disabled="loading",
        :value="value",
        @input="(value) => $emit('input', { id: vehicleModelGroup.id, value })",
        data-testid="checkbox"
      )

    .column.flex.center.column-direction
      .flex.vertical-center.center
        id-tag.id-tag(
          :value="vehicleModelGroup.id",
          data-testid="vehicleModelGroupId"
        )

    .column.flex.center.column-direction
      .flex.vertical-center
        app-span.text(:value="vehicleModelGroup.name", crop, data-testid="vehicleModelGroupName")

    .column.flex.center.column-direction
      template(v-if="vehicleModelGroup.description")
        app-span.text(:value="vehicleModelGroup.description", crop, data-testid="vehicleModelGroupDescription")
      template(v-else)
        app-span.text(value="-")

    .column.actions-column.flex.vertical-center.center
      i.fal.fa-car
      span.vehicle-solutions-count(data-testid="vehicleModelsCount") {{ vehicleModelGroup.vehicleModelsCount }}

</template>


<script>

export default {
  props: {
    vehicleModelGroup: { type: Object, required: true },
    value:             { type: Boolean, default: false },

    loading: { type: Boolean, default: false },

    columnSizes: { type: String, required: true }
  },

  data() {
    return {
      i18nScope: "suppliers.service-supports.service-vehicle-model-groups.new.vehicle-model-groups.table-row"
    }
  },

  computed: {
    styles() {
      return {
        "--grid-columns": this.columnSizes
      }
    }
  }
}

</script>

/**
 * Base para os componentes de seção de ordem de serviço
 */

import ServiceOrderSectionLayout from "./service-order-section-layout.vue"
import models from "@/models"

const { MovidaServiceOrder } = models

export default {
  components: {
    ServiceOrderSectionLayout
  },

  props: {
    serviceOrder: { type: MovidaServiceOrder, required: true }
  }
}

<style lang="scss" scoped>

.edit-products {
  .section-header {
    margin-bottom: 16px;

    .title {
      font-family: $secondary-font;
      font-weight: 500;
      font-size: 20px;
    }

    .subtitle {
      display: block;
      margin-top: 4px;
    }
  }

  .table {
    margin-top: 16px;

    .count {
      margin-bottom: 8px;
      text-align: right;
    }

    .no-products {
      padding: 24px;
      border: 1px solid $light-gray-3;
      border-radius: 4px;
      color: $gray-3;
      font-size: 14px;

      .icon {
        user-select: none;
        margin-bottom: 8px;
        height: 78px;
        width: 89px;
      }
    }
  }
}

</style>


<template lang="pug">

  section.edit-products
    .section-header
      h2.title {{ $t('.title') }}
      span.subtitle {{ $t('.subtitle') }}

    service-package-product-multiple-select-field(
      name="servicePackage[products]",
      v-model="products",
      :placeholder="$t('.fields.products.placeholder')",
      :loading="fetching",
      :disabled="submitting",
      :errors="productErrors",
      @toggle-remove="onToggleRemove"
    )

    .table
      .count(v-if="hasProducts && !fetching")
        span {{ $t('.count', { count: productsCount }) }}

      template(v-if="!hasProducts && !fetching")
        .no-products.flex.center.column-direction.vertical-center
          img.icon(:src="productPlaceholderIcon")
          span {{ $t('.empty') }}

      products-table(
        v-else,
        :loading="fetching"
      )
        service-package-product-row(
          v-for="product in resource.products",
          edit,
          :key="`service-package-product-row-${product.id}`",
          :product="product",
          :disabled="submitting",
          :is-persisted="isPersisted(product.id)",
          @remove="onRemoveProduct",
          @undo-remove="onToggleRemove",
        )

</template>


<script>

// Models
import models from "@/models"

// Components
import ServicePackageProductRow from "../components/service-package-product-row"
import ServicePackageProductMultipleSelectField from "../components/service-package-product-multiple-select-field"

// Icons
import productPlaceholderIcon from "@/assets/images/placeholder-icons/product.svg"

export default {
  name: "EditServicePackageProducts",

  components: {
    ServicePackageProductRow,
    ServicePackageProductMultipleSelectField
  },

  props: {
    fetching:   { type: Boolean, default: false },
    submitting: { type: Boolean, default: false },
    errors:     { type: Object, default: () => ({}) },
    resource:   { type: Object, default: () => ({}) },
    initialIds: { type: Array, default: () => [] }
  },

  data() {
    return {
      i18nScope: "service-packages.products.edit",

      productPlaceholderIcon
    }
  },

  computed: {
    productErrors() {
      return [
        ...this.errors.productIds || [],
        ...this.errors.productOrService || []
      ]
    },

    hasProducts() {
      return _.present(this.resource.products)
    },

    productsCount() {
      if (!this.hasProducts) return 0
      return this.resource.products.length
    },

    products: {
      get() {
        if (_.blank(this.resource)) return []

        return this.resource.products.map(product => ({
          value: product.id,
          label: product.productName,
          data:  new models[product.type](product)
        }))
      },

      set(products) {
        // eslint-disable-next-line vue/no-mutating-props
        this.resource.products = products.map(product => product.data)
      }
    }
  },

  methods: {
    isPersisted(id) {
      return this.initialIds.includes(id)
    },

    onToggleRemove(productToToggle) {
      if (!this.isPersisted(productToToggle.id)) {
        this.onRemoveProduct(productToToggle)
        return
      }

      const products = [...this.resource.products]
      const productIndex = products.findIndex(product => product.id === productToToggle.id)

      if (productIndex < 0) return

      const product = products[productIndex]

      if (product.$markedForDestruction) product.$unmarkForDestruction()
      else product.$markForDestruction()

      // eslint-disable-next-line vue/no-mutating-props
      this.resource.products = products
    },

    onRemoveProduct(productToRemove) {
      const products = [...this.resource.products]
      const productIndex = products.findIndex(product => product.id === productToRemove.id)

      if (productIndex < 0) return

      if (this.isPersisted(productToRemove.id)) products[productIndex].$markForDestruction()
      else products.splice(productIndex, 1)

      // eslint-disable-next-line vue/no-mutating-props
      this.resource.products = products
    }
  }
}

</script>

<style lang="scss" scoped>

.vehicle-details-popover-content {
  width: 444px;
  padding: 16px 0;

  .row {
    margin-bottom: 16px;

    .info-box {
      flex: 1 1 0;
      padding-left: 16px;

      + .info-box {
        border-left: 1px solid $light-gray-3;
      }

      .label {
        font-family: $secondary-font;
        font-weight: 500;
        font-size: 14px;
        color: $dark-gray;
      }

      .value {
        font-weight: 300;
        font-size: 16px;
        color: $black;

        .empty {
          font-style: italic;
        }
      }
    }
  }

  .link {
    font-size: 14px;
    padding-left: 16px;

    .icon {
      font-size: 12px;
      margin-right: 4px;
    }
  }
}

</style>


<template lang="pug">

  .vehicle-details-popover-content
    .row.flex
      .info-box
        .label
          span {{ $t('.fipe.label') }}
        .value
          span {{ vehicle.fipeId }}
      .info-box
        .label
          span {{ $t('.category.label') }}
        .value
          span {{ VehicleModel.$tEnum('category', vehicleModel.category) }}
      .info-box
        .label
          span {{ $t('.type.label') }}
        .value
          span {{ VehicleModel.$tEnum('type', vehicleModel.type) }}

    .row.flex(v-if="!isElectric")
      .info-box
        .label
          span {{ $t('.fuel.label') }}
        .value
          span {{ VehicleModelHybrid.$tEnum('fuel', vehicleModel.fuel) }}
      .info-box
        .label
          span {{ $t('.fuel-tank-capacity.label') }}
        .value
          template(v-if="vehicleModel.fuelTankCapacity")
            span {{ fuelTankCapacity }}

          template(v-else)
            span.empty {{ $t('.not-available') }}

      .info-box
        .label
          span {{ $t('.motor-oil-capacity.label') }}
        .value
          template(v-if="vehicleModel.motorOilCapacity")
            span {{ motorOilCapacity }}

          template(v-else)
            span.empty {{ $t('.not-available') }}

    .row.flex(v-if="isElectric || isHybrid")
      .info-box
        .label
          span {{ $t('.electric-engine-battery-capacity.label') }}
        .value
          span {{ electricEngineBatteryCapacity }}

    app-button.link(theme="link", :to="{ name: 'vehicleModel', params: { id: vehicleModel.id } }")
      i.icon.fas.fa-external-link
      span {{ $t('.btn.show') }}

</template>


<script>

// Models
import models from "@/models"
const { VehicleModel, VehicleModelHybrid } = models

export default {
  props: {
    vehicle: { type: Object, required: true }
  },

  data() {
    return {
      i18nScope: "vehicles.show.vehicle-details-popover-content",

      VehicleModel,
      VehicleModelHybrid
    }
  },

  computed: {
    vehicleModel() {
      return _.get(this.vehicle, "vehicleModel") || {}
    },

    isElectric() {
      return this.vehicleModel.type === "VehicleModels::Electric"
    },

    isHybrid() {
      return this.vehicleModel.type === "VehicleModels::Hybrid"
    },

    hasFuelTankCapacity() {
      return _.present(this.vehicleModel.fuelTankCapacity)
    },

    fuelTankCapacity() {
      if (!this.hasFuelTankCapacity) return null

      const capacity = this.$asNumber(this.vehicleModel.fuelTankCapacity, { precision: 2 })

      return this.$t(".fuel-tank-capacity.value", { capacity })
    },

    hasMotorOilCapacity() {
      return _.present(this.vehicleModel.motorOilCapacity)
    },

    motorOilCapacity() {
      if (!this.hasMotorOilCapacity) return null

      const capacity = this.$asNumber(this.vehicleModel.motorOilCapacity, { precision: 2 })

      return this.$t(".motor-oil-capacity.value", { capacity })
    },

    hasElectricEngineBatteryCapacity() {
      return _.present(this.vehicleModel.electricEngineBattery)
    },

    electricEngineBatteryCapacity() {
      if (!this.hasElectricEngineBatteryCapacity) return null

      const capacity = this.$asNumber(this.vehicleModel.electricEngineBattery, { precision: 1 })

      return this.$t(".electric-engine-battery-capacity.value", { capacity })
    }
  }
}

</script>

<style lang="scss" scoped>

.business-unit-tag {
  .tag {
    padding: 2px 4px;
    font-family: $secondary-font;
    font-weight: 500;
    font-size: 12px;
    line-height: 150%;
    border-radius: 4px;

    // - default/unknown
    background-color: $light-gray-3;

    &.rac,
    &.rac_eventual,
    &.rac_mensal,
    &.mensal_flex {
      background-color: $light-orange-2;
    }

    &.zkm {
      background-color: $light-purple;
    }

    &.gtf {
      background-color: $light-info;
    }

    &.seminovos {
      // roxo do site https://www.seminovosmovida.com.br
      background-color: #61296D;
      color: $white;
    }

    // sizes
    // ---
    &.big,
    &.large {
      font-size: 18px;
      // height: 26px;
    }

    &.normal,
    &.medium {
      font-size: 14px;
      // height: 19px;
    }

    &.small {
      font-size: 12px;
      // height: 16px;
    }
  }
}

</style>


<template lang="pug">

  .business-unit-tag
    span.tag(:class="{ [value]: true, [size]: true }") {{ text }}

</template>


<script>

// Models
import { BUSINESS_UNITS } from "@/enums/business-units"

const SIZES = [
  "small",
  "medium", "normal",  // medium == normal (alias)
  "large", "big"       // large  == big    (alias)
]


export default {
  name: "BusinessUnitTag",

  props: {
    value: { type: String, required: true   },  // enum: BUSINESS_UNITS
    size:  { type: String, default: "small", validator: (size) => SIZES.includes(size) }
  },

  data() {
    return {
      i18nScope: "components.business-unit-tag"
    }
  },

  computed: {
    text() {
      return BUSINESS_UNITS.has(this.value)
        ? this.$t(`.businessUnit.${this.value}`)
        : this.$t("abbr.unavailable")
    }
  }
}

</script>

<script>

// Extends
import DataLoad from "@/views/suppliers/deals/products/data-loads/show"

export default {
  name: "SupplierDealMaintenancePackagesDataLoad",

  extends: DataLoad,

  data() {
    return {
      i18nScope: "suppliers.deals.maintenance-packages.data-loads.show"
    }
  },

  computed: {
    indexRoute() {
      return {
        name:   "supplierDealMaintenancePackagesDataLoads",
        params: { id: this.supplierId, dealId: this.dealId }
      }
    },

    backRoute() {
      return {
        name:   "supplierDealMaintenancePackages",
        params: { id: this.supplierId, dealId: this.dealId },
        query:  this.backRouteQuery
      }
    }
  },

  methods: {
    // @override Fetchable mixin
    fetch() {
      return this.$sdk.deals.getMaintenancePackagesDataLoad({
        id:         this.dealId,
        dataLoadId: this.dataLoadId
      })
    },

    parseErrors() {
      let errors = {}

      const { log } = this.resource

      if (this.hasReadErrors) {
        errors.read = []

        if (_.present(log.file)) errors.read.push(this.$t(".errors.read.file"))
        if (_.present(log.sheet)) errors.read.push(this.$t(".errors.read.sheet"))
      }

      if (this.hasParseErrors) {
        errors.parse = []

        if (_.present(log.header)) errors.parse.push(this.$t(".errors.parse.header"))
        if (_.present(log.rows)) errors.parse.push(this.$t(".errors.parse.rows"))

        const rowKeys = Object.keys(log).filter(key => key.startsWith("row"))
        const replicatedRowKeys = rowKeys.filter(key => log[key].find(entry => entry.error === "replicated"))
        const replicatedRows = replicatedRowKeys.map(key => this.getIndexFromString(key))
        if (_.present(replicatedRows)) {
          errors.parse.push(this.$t(".errors.parse.replicated", {
            ids: replicatedRowKeys.map(key => this.getIndexFromString(key)).join(", ")
          }))
        }
      }

      if (this.hasSaveErrors) {
        errors.save = []
        const dealMaintenancePackageKeys = Object.keys(log).filter(key => key.startsWith("deal_maintenance_packages"))
        const invalidMaintenancePackageKeys = dealMaintenancePackageKeys
          .filter(key => log[key].find(entry => entry.error === "invalid"))

        errors.save = invalidMaintenancePackageKeys.map(key => ({
          line:   this.getIndexFromString(key),
          errors: log[key][0].data.errors
        }))
      }

      this.errors = errors
    }
  }
}

</script>

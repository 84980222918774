<style lang="scss" scoped>

.badge {
  margin-right: 8px;
}

</style>


<template lang="pug">

  finder-field.supplier-classification-finder-field(
    v-bind="forwardedProps",
    v-on="$listeners",
    :label="$t('.label')"
    :options="options"
  )
    template(#selectedLabel="{ value }")
      .flex.vertical-center
        supplier-classification-badge.badge(disable-tooltip, :value="value")
        | {{ getLabel(value) }}
    template(#option="{ option }")
      .flex.vertical-center
        supplier-classification-badge.badge(disable-tooltip, :value="option")
        | {{ getLabel(option) }}

</template>


<script>

import models from "@/models"

const { Supplier } = models


export default {
  inheritAttrs: false,

  props: {
    includeNone: { type: Boolean, default: false }
  },

  data() {
    return {
      i18nScope: "suppliers.components.supplier-classification-finder-field"
    }
  },

  computed: {
    forwardedProps() {
      return _.omit(this.$attrs, ["label", "options"])
    },

    options() {
      let options = Supplier.$enums.classification.keys

      if (this.includeNone) options = [...options, "none"]

      return options
    }
  },

  methods: {
    getLabel(option) {
      switch (option) {
        case "CMM":  return this.$t(".options.cmm.label")
        case "none": return this.$t(".options.none.label")
        default:
          return this.$t(".options.default.label", { option })
      }
    }
  }
}

</script>

<style lang="scss" scoped>

.container {
  background-color: $light-gray-2;
  padding: 24px;
  border: 1px solid;
  border-radius: 4px;
}

.header {
  font-family: $secondary-font;
  font-weight: 500;

  .toggle {
    cursor: pointer;
    user-select: none;

    .icon {
      margin-left: 10px;
      font-size: 12px;
    }
  }
}

.body {
  overflow: hidden;
  transition:height 0.3s ease-out;
  height: 0;
}

.toggle-icon {
  transition: transform .2s ease;

  &.collapsed {
    transform: rotate(180deg);
  }
}

</style>


<template lang="pug">

  .collapse-card
    .container
      .header.flex.space-between
        .title
          slot(name="title")
        .toggle(@click="toggle")
          template(v-if="collapsed")
            span {{ $t('.open') }}
          template(v-else)
            span {{ $t('.close') }}
          i.icon.toggle-icon.fas.fa-chevron-up(:class="{ collapsed }")

      .body(ref="body")
        slot(name="body")

</template>


<script>

export default {
  data() {
    return {
      i18nScope: "suppliers.components.collapse-card",

      collapsed: true
    }
  },

  methods: {
    toggle() {
      if (this.collapsed) this.expand()
      else this.collapse()
    },

    async collapse() {
      const element = this.$refs.body
      const height = element.scrollHeight

      const elementTransition = element.style.transition
      element.style.transition = ""

      await this.$nextTick()

      element.style.height = `${height}px`
      element.style.transition = elementTransition

      await this.$nextTick()

      element.style.height = "0px"

      this.collapsed = true
    },

    expand() {
      const element = this.$refs.body
      const height = element.scrollHeight

      element.style.height = `${height}px`

      this.collapsed = false
    }
  }
}

</script>

<style lang="scss" scoped>

.show {
  .service-order-content {
    .service-order-title {
      margin-bottom: 24px;

      .unknown {
        font-style: italic;
      }

      .service-order-kind {
        font-family: $secondary-font;
        font-weight: 500;
        font-size: 30px;
        margin-left: 8px;
        margin-right: 8px;
      }

      .tag {
        margin-right: 16px;
      }
    }

    .half {
      width: 50%;

      .card {
        width: 100%;
        margin-bottom: 24px;
      }

      .info-label {
        margin-bottom: 24px;

        .info-icon {
          font-size: 12px;
          margin-left: 4px;
        }

        .label-icon {
          font-size: 20px;
          margin-right: 20px;
        }

        .sap-icon {
          margin-right: 16px;
        }

        .label {
          font-family: $secondary-font;
          font-weight: 500;
          font-size: 14px;
        }

        .value {
          font-size: 16px;
          font-weight: 300;
          line-height: 22px;
        }
      }
    }

    .services,
    .products {
      margin-bottom: 24px;
    }

    .total-price-row,
    .description-row {
      margin-bottom: 24px;

      .label {
        font-family: $secondary-font;
        font-weight: 500;
        font-size: 20px;
      }
    }

    .total-price-row {
      padding: 16px 12px;
      background-color: $light-gray-3;

      .currency {
        font-family: $primary-monospace-font;
      }
    }

    .description-row {
      .description-title {
        margin-bottom: 16px;
      }
    }
  }
}

</style>


<template lang="pug">

  .show
    template(v-if="fetching")
      loading-lines.lines(:lines="10" :min="20", :max="100")

    template(v-else-if="erred")
      span.error-message {{ $t('.retry.message') }}
      app-button(theme="link", @click="fetch")
        span.retry {{ $t('.retry.link') }}

    template(v-else)
      .service-order-content
        .flex.vertical-center.service-order-title
          service-order-state-icon.state-icon(:state="state", size="big")

          app-span.service-order-kind(:value="kind" :class="{ unknown: kindUnknown }")

          vetor-code-tag.tag(:value="serviceOrderId", size="big")
          sap-code-tag.tag(:value="serviceOrder.sapId" size="big")

        .service-order-body
          .flex
            .half
              info-label.info-label(
                :loading="fetching",
                data-testid="createdAt"
              )
                template(#label)
                    label.label {{ $t('.fields.createdAt.label') }}
                    i.info-icon.far.fa-info-circle(
                      v-tooltip.bottom="$t('.fields.createdAt.tooltip')"
                    )

                template(#icon)
                  i.icon.fal.fa-envelope-open-text.label-icon

                span.value {{ $standardTime(serviceOrder.createdAt) || emptyText }}

              info-label.info-label(
                :loading="fetching",
                data-testid="authorizedAt"
              )
                template(#label)
                    label.label {{ $t('.fields.authorizedAt.label') }}
                    i.info-icon.far.fa-info-circle(
                      v-tooltip.bottom="$t('.fields.authorizedAt.tooltip')"
                    )

                template(#icon)
                  i.icon.fal.fa-envelope-open-text.label-icon

                span.value {{ $standardTime(serviceOrder.authorizedAt) || emptyText }}

              info-label.info-label(
                :loading="fetching",
                data-testid="enteringAt"
              )
                template(#label)
                  label.label {{ $t('.fields.enteringAt.label') }}

                template(#icon)
                  i.icon.fal.fa-car-side.label-icon

                span.value {{ $standardTime(serviceOrder.enteringAt) || emptyText  }}

              info-label.info-label(
                :loading="fetching",
                data-testid="leavingAt"
              )
                template(#label)
                  label.label {{ $t('.fields.leavingAt.label') }}

                template(#icon)
                  i.icon.fal.fa-check-square.label-icon

                span.value {{ $standardTime(serviceOrder.leavingAt) || emptyText  }}

            .half
              supplier-info-tag.card(:supplier="supplier")
              vehicle-info-tag.card(:vehicle="vehicle")
              store-info-tag.card(:store="store")

          .services
            services-table(:services="services")

          .products
            products-table(:products="products")

          .total-price-row.flex.space-between.vertical-center
            app-span.label(:value="$t('.fields.totalPrice.label')")
            app-span.label.currency(:value="$l('currency', totalPrice)")

          .description-row
            .description-title
              app-span.label(:value="$t('.fields.description.label')")
            app-span.preserve-whitespace(:value="serviceOrder.description")

</template>


<script>

// Models
import models from "@/models"

// Mixins
import FetchMixin from "@/mixins/fetch-mixin"

// Components
import VehicleInfoTag from "@/views/service-orders/show/vehicle-info-tag"
import SupplierInfoTag from "@/views/service-orders/show/supplier-info-tag"
import StoreInfoTag from "@/views/service-orders/show/store-info-tag"
import ServicesTable from "@/views/service-orders/show/services-table.vue"
import ProductsTable from "@/views/service-orders/show/products-table.vue"

const { VehicleServiceOrder } = models

export default {
  name: "LastTicketsShow",

  components: {
    VehicleInfoTag,
    SupplierInfoTag,
    StoreInfoTag,
    ServicesTable,
    ProductsTable
  },

  mixins: [FetchMixin],

  props: {
    vehicleId:      { type: [Number, String], required: true },
    serviceOrderId: { type: [Number, String], required: true }
  },

  data() {
    return {
      i18nScope: "tickets.drafts.vehicles.components.last-tickets.show",

      resource: null,

      VehicleServiceOrder
    }
  },

  computed: {
    serviceOrder: {
      get() { return this.resource },
      set(value) { this.resource = value }
    },

    emptyText() {
      return this.$t("unregistered.masculine")
    },

    vehicle() {
      return _.get(this.serviceOrder, "vehicle")
    },

    supplier() {
      return _.get(this.serviceOrder, "supplier")
    },

    store() {
      return _.get(this.serviceOrder, "store")
    },

    serviceKind() {
      return _.get(this.serviceOrder, "serviceKind")
    },

    kindUnknown() {
      return _.isNil(this.serviceKind)
    },

    kind() {
      // TODO: Ampliar `$tEnum` para cobrir valores nulos
      let kind = VehicleServiceOrder.$enums.serviceKind.all[this.serviceKind]

      return VehicleServiceOrder.$tEnum("serviceKind", kind)
    },

    totalPrice() {
      return _.sumBy(this.serviceOrderItems, item => item.totalPrice)
    },

    state() {
      return _.get(this.serviceOrder, "state")
    },

    serviceOrderItems() {
      return _.get(this.serviceOrder, "serviceOrderItems")
    },

    services() {
      return this.serviceOrderItems.filter(service => service.kind === "service")
    },

    products() {
      return this.serviceOrderItems.filter(service => service.kind === "product")
    }
  },

  methods: {
    // @Override FetchMixin
    fetchRequest() {
      return this.$newSdk.vehicles.serviceOrders.find({ vehicleId: this.vehicleId, serviceOrderId: this.serviceOrderId })
    }
  }
}

</script>

<!--
<doc lang="markdown">
Exibe uma tag

_Props_: value, color, size

Como usar:
```pug
  app-tag(:value="value", size="big", color="blue")
```
</doc>
-->

<style lang="scss" scoped>

.app-tag {
  display: inline-block;
}

.app-tag-label {
  padding: 1px 8px;
  border-radius: 50px;
  font-weight: normal;
  text-align: center;
  white-space: nowrap;

  border: 1px solid transparent;

  // colors
  // ---
  &.purple {
    background: #F9E8FF;
    color: $purple;
  }

  &.green {
    background: $light-success;
    color: $dark-success-2;
  }

  &.aqua {
    background: $light-aqua;
    color: $dark-aqua;
  }

  &.blue {
    background: #E5F3FF;
    color: #1E71B8;
  }

  &.gray {
    background: $light-gray-3;
    color: $dark-gray;
  }

  &.simple-gray {
    color: $dark-gray;
  }

  &.white {
    background: $light-gray;
    color: $dark-gray;
    border: 1px solid $light-gray-4;
    font-weight: 300;
  }

  &.orange {
    background: $primary;
    color: $white;
  }

  &.orange-light {
    background: $light-orange;
    color: #333333;
    border: 1px solid $light-orange;
    font-weight: 300;
  }

  // states
  // ---
  &.disabled {
    background: $white;
    color: $light-gray-4;
    text-decoration-line: line-through;
    border: 1px solid $light-gray-4;
  }

  // sizes
  // ---
  &.big,
  &.large {
    font-size: 20px;
    height: 26px;
  }

  &.normal,
  &.medium {
    font-size: 14px;
    height: 19px;
  }

  &.small {
    font-size: 12px;
    height: 16px;
  }
}

</style>


<template lang="pug">

  .app-tag
    label.app-tag-label.flex.vertical-center(:class="{[size]: true, [color]: !disabled, disabled }")
      slot {{ value }}

</template>


<script>

const COLORS = [
  "purple",
  "green",
  "aqua",
  "blue",
  "gray",
  "simple-gray",
  "white",
  "orange",
  "orange-light"
]

const SIZES = [
  "small",
  "normal", "medium",  // normal == medium (alias)
  "big", "large"       // big == large (alias)
]


export default {
  name: "AppTag",

  props: {
    value: { type: [String, Number], default: null },

    color:    { type: String,  default: "purple", validator: (color) => COLORS.includes(color) },
    disabled: { type: Boolean, default: false },
    size:     { type: String,  default: "normal", validator: (size) => SIZES.includes(size) }
  },

  data() {
    return {
      i18nScope: "components.app-tag"
    }
  }
}

</script>

import Vue from "vue"
import { VueReCaptcha } from "vue-recaptcha-v3"
import { conf } from "utils.vue"

const siteKey = _.get(conf, "recaptcha.siteKey")

if (_.present(siteKey)) {
  Vue.use(VueReCaptcha, {
    siteKey,
    loaderOptions: {
      autoHideBadge: true
    }
  })
}

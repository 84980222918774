import Product from "./product"

class Part extends Product {
  static get attrs() {
    const attrs = super.attrs.filter(attr => attr !== "type")

    return attrs
  }

  static get enums() {
    return {}
  }

  static get virtuals() {
    return [
      "type"
    ]
  }

  static get genderInflection() {
    return "feminine"
  }

  // virtuals
  get type() {
    return "Part"
  }
}

export default Part

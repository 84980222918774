import models from "@/models"

const ModelsPlugin = {
  installed: false,

  install(Vue, options) {
    if (ModelsPlugin.installed) return
    ModelsPlugin.installed = true

    Vue.$models = models
    // add an instance method
    Vue.prototype.$models = models
  }
}

export default ModelsPlugin

<style lang="scss" scoped>

.edit-vehicle-model-group {
  padding-bottom: 24px;

  .header {
    margin-bottom: 32px;
    padding-bottom: 16px;
    border-bottom: 1px solid $light-gray-3;

    .title {
      font-size: 30px;
      font-weight: 500;
      font-family: $secondary-font;
    }

    .tag {
      margin-left: 8px;
    }
  }

  .fields {
    margin-bottom: 24px;
    padding-bottom: 24px;
    border-bottom: 1px solid $light-gray-3;
  }
}

</style>


<template lang="pug">

  .edit-vehicle-model-group
    header.header.flex.vertical-center
      h1.title {{ $t('.title') }}
      id-tag.tag(:value="resource.id", size="big")

    form.form(@submit.prevent="submit", method="post")
      form-fields.fields(
        :resource="resource",
        :submitting="submitting",
        :fetching="fetching",
        :errors="errors"
      )

      section.flex.space-between.vertical-center
        app-button(
          :to="{ name: 'vehicleModelGroup', params: { id } }",
          theme="gray",
          outline,
          :disabled="submitting"
        ) {{ $t('btn.cancel') }}

        app-button.button(
          type="submit",
          :loading="submitting || fetching",
          :disabled="submitting || fetching",
          data-testid="submitButton"
        )
          span {{ $t('.btn.submit.label') }}
          span(slot="loading") {{ $t('.btn.submit.loading') }}

</template>


<script>

// Mixins
import { mixins } from "movida-common.vue"

// Components
import FormFields from "./form-fields"

// Extends
import View from "@/views/view"

const { FormMixin, Fetchable } = mixins

export default {
  name: "EditVehicleModelGroup",

  components: {
    FormFields
  },

  extends: View,

  mixins: [FormMixin, Fetchable],

  data() {
    return {
      i18nScope: "vehicle-model-groups.edit",

      id: this.$route.params.id,

      // @override Fetchable mixin
      resource: {},
      model:    "VehicleModelGroup"
    }
  },

  methods: {
    // @override Fetchable mixin
    fetch() {
      return this.$sdk.vehicleModelGroups.get({ id: this.id })
    },

    // @override Fetchable mixin
    // onFetchSuccess(response) {},

    // @override Fetchable mixin
    onFetchError(err) {
      // Dealing with appError from extension view.vue
      if (this.hasViewError(err)) this.appError = err

      this.$err.log(err)
      this.$notifications.error(this.$t(".notifications.fetch.failure"))
    },

    // @override Form mixin
    submitRequest() {
      return this.$sdk.vehicleModelGroups.update({ id: this.id, params: this.resource.$serialize() })
    },

    // @override Form mixin
    submitSuccess(response) {
      this.$notifications.info(this.$t(".notifications.submit.success", { name: this.resource.name }))

      this.$router.push({ name: "vehicleModelGroup", params: { id: this.id } })
    },

    // @override Form mixin
    submitError(err) {
      this.$err.log(err)

      let code = "default"

      const errors = _.get(err, "error.originalError.response.data.errors.vehicle_model_id") || []
      if (errors.includes("taken")) code = "vehicleModelTaken"

      this.$notifications.error(this.$t(`.notifications.submit.error.${code}`))
    }
  }
}

</script>

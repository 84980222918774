<style scoped lang="scss">

.mobiauto-version-row {
  display: grid;
  grid-template-columns: 50px 150px 1fr 150px 110px 100px 200px 50px;

  &.loading {
    .column {
      &.id-column {
        .id-tag {
          color: $gray-2;
        }
      }

      &.actions-column {
        ::v-deep .movida-common__app-button {
          color: $gray-2;

          &:hover {
            color: $orange;
          }
        }
      }

      .text,
      .link ::v-deep .app-span {
        color: $gray-2;
      }
    }
  }

  .column {
    padding: 0 12px;
    border-bottom: 1px solid $light-gray-3;
    height: 48px;
    overflow: hidden;

    .alert-icon {
      font-size: 14px;
      color: $dark-yellow;
      font-weight: 300;
      margin-right: 4px;
    }

    .diamond-icon {
      margin: 0 4px 0 4px;
      font-size: 4px;
      font-weight: 900;
      color: $gray-3;
    }

    .discarded-icon {
      margin-right: 6px;
    }

    .icon-model {
      margin-right: 8px;
    }

    .text-secondary {
      font-family: $primary-monospace-font;
      font-size: 14px;
      font-weight: 400;
    }

    &.models-count-column {
      .text {
        font-family: $secondary-font;
      }

      .car-icon {
        margin-right: 8px;
        color: $gray-2;
      }
    }

    .text,
    .link ::v-deep .app-span {
      font-family: $primary-font;
      font-weight: 400;
      font-size: 14px;
      color: $dark-gray;
      text-align: initial;
    }

    .content:hover {
      .link,
      .diamond-icon {
        color: $orange;
      }
    }
  }
}

</style>


<template lang="pug">

  .mobiauto-version-row(
    :class="{ loading }"
    :style="{ 'background-color': (mobiautoVersion.emptyVehicleModelGroup && mobiautoVersion.afterAutomaticVehicleModelCreation) ? '#FFCFA3' : '' }"
    )
    .column.id-column.flex.vertical-center
      id-tag.id-tag(
        :value="vehicleModel.id",
        data-testid="vehicleModelId"
      )

    .column.brand-column.flex.vertical-center
      vehicle-manufacturer-icon.icon-model(:value="manufacturer.name")
      app-span.text(
        crop,
        :value="manufacturer.name",
        data-testid="vehicleModelManufacturer"
      )

    .column.flex.vertical-center
      //- discarded-icon.discarded-icon(v-if="mobiautoVersion.$discarded")
      app-button.button(
        theme="link",
        :to="vehicleModelRoute"
        :disabled="withouthModelAndCannotCreate"
      )
        .content.flex.vertical-center
          template(v-if="!vehicleModel.id")
            i.alert-icon.fal.fa-exclamation-triangle

          app-span.link(
            :value="mobiautoVersion.model.name",
            crop,
            data-testid="vehicleModelName"
          )

          i.diamond-icon.fa.fa-diamond
          app-span.link(data-testid="vehicleModelVersion") {{ mobiautoVersion.name }}

          i.diamond-icon.fa.fa-diamond
          template(v-if="vehicleModel.year")
            app-span.link(data-testid="vehicleModelYear") {{ vehicleModel.year }}
          template(v-else)
             app-span.text {{ $t('.yearRange.label', { initial: mobiautoVersion.initialYear, final: mobiautoVersion.finalYear } )}}

    .column.flex.vertical-center
      //- XXX: estamos instanciando VehicleModel não persistidos, para exibir MobiautoVersion que ainda
      //- não tenham sido registrados!
      template(v-if="vehicleModel.$persisted")

        template(v-if="hasElectricEngineSource")
          app-span.text(:value="$t(`models.vehicleModel.enums.typePowerSource.${vehicleModel.type}`)")

          i.diamond-icon.fa.fa-diamond(v-if="isHybrid")

        template(v-if="vehicleModel.fuel")
          app-span.text(
            data-testid="vehicleModelFuel",
            :value="$t(`models.vehicleModelCombustion.enums.fuel.${vehicleModel.fuel}`)"
          )

      template(v-else)
        app-span -

    .column.flex.vertical-center
      app-span.text-secondary(data-testid="vehicleModelFipe") {{ mobiautoVersion.fipeId }}

    .column.flex.vertical-center
      template(v-if="vehicleModel.category")
        app-span.text(
          data-testid="vehicleModelCategory"
          :value="$t(`models.vehicleModel.enums.category.${vehicleModel.category}`)"
        )
      template(v-else)
        app-span.text -
    
    .column.flex.vertical-center
      app-span.text-secondary(data-testid="vehicleGroup") {{ vehicleModelGroup }}


    template(v-if="!vehicleModel.id")
      .column.actions-column.flex.vertical-center.flex.center
        router-link(
          v-if="$can('create', 'VehicleModel')",
          :to="newRoute",
          data-testid="newButton"
        )
          i.icon.fal.fa-plus

    template(v-else)
      .column.actions-column.flex.vertical-center.flex
        popover-menu(
          v-if="popoverMenuAuthorization",
          :show.sync="showPopover",
          data-testid="popoverMenu"
        )
          popover-menu-button(
            v-if="$can('read', 'VehicleModel')",
            icon="fal fa-eye",
            :to="{ name: 'vehicleModel', params: { id: vehicleModel.id }}",
            :text="$t('.actions.btn.show.label')",
            @close-menu="showPopover = false"
          )

          popover-menu-button(
            v-if="$can('update', 'VehicleModel')",
            icon="fal fa-pencil",
            :to="{ name: 'editVehicleModel', params: { id: vehicleModel.id }}",
            :text="$t('.actions.btn.edit.label')",
            @close-menu="showPopover = false"
          )

          //- template(v-if="vehicleModel.$discarded")
          //-   enable-action.popover-menu-button(
          //-     :name="vehicleModel.name",
          //-     :id="vehicleModel.id"
          //-     size="normal",
          //-     border="square",
          //-     @click.native="showPopover = false"
          //-     @enabled="$emit('enabled')"
          //-   )
          //-     .icon-content
          //-       i.icon.fal.fa-check-circle
          //-     span {{ $t('.actions.btn.enable.label') }}

          //- template(v-else)
          //-   disable-action.popover-menu-button(
          //-     :name="vehicleModel.name",
          //-     :id="vehicleModel.id"
          //-     size="normal",
          //-     border="square",
          //-     @click.native="showPopover = false"
          //-     @disabled="$emit('disabled')"
          //-   )
          //-     .icon-content
          //-       i.icon.fal.fa-lock
          //-     span {{ $t('.actions.btn.disable.label') }}

          destroy-action.destroy-button.popover-menu-button(
            :id="mobiautoVersion.$get('vehicleModel.id')",
            :name="mobiautoVersion.name",
            :type="mobiautoVersion.type",
            size="normal",
            border="square",
            @click.native="showPopover = false"
            @destroy="$emit('destroy')",
            data-test="destroyAction"
          )
            .icon-content
              i.icon.fal.fa-trash-alt
            span {{ $t('.actions.btn.destroy.label') }}

</template>


<script>

// Local Components
import DestroyAction from "../show/destroy-action"
// import DisableAction from '../components/disable-action'
// import EnableAction from '../components/enable-action'

export default {
  components: {
    DestroyAction
    // DisableAction,
    // EnableAction
  },

  props: {
    mobiautoVersion: { type: Object, default: () => ({}) },
    loading:         { type: Boolean, default: false }
  },

  data() {
    return {
      i18nScope: "vehicle-models.index.mobiauto-version-row",

      showPopover: false
    }
  },

  computed: {
    popoverMenuAuthorization() {
      return ["read", "update", "destroy"].some(action => this.$can(action, "VehicleModel"))
    },

    isHybrid() {
      return this.vehicleModel.type === "VehicleModels::Hybrid"
    },

    hasElectricEngineSource() {
      return ["VehicleModels::Electric", "VehicleModels::Hybrid"].includes(this.vehicleModel.type)
    },

    withouthModelAndCannotCreate() {
      return _.blank(this.vehicleModel.id) && !this.$can(["create"], "VehicleModel")
    },

    vehicleModelRoute() {
      return _.blank(this.vehicleModel.id) ? this.newRoute : this.showRoute
    },

    showRoute() {
      return {
        name:   "vehicleModel",
        params: {
          id: this.vehicleModel.id
        }
      }
    },

    newRoute() {
      return {
        name:   "newVehicleModel",
        params: { mobiautoVersion: this.mobiautoVersion }
      }
    },

    manufacturer() {
      return _.get(this.mobiautoVersion, "model.manufacturer") || {}
    },

    vehicleModel() {
      return _.get(this.mobiautoVersion, "vehicleModel") || {}
    },

    vehicleModelGroup() {
      return  _.get(this.mobiautoVersion, "vehicleModelGroup.name") || '-'
    },

    emptyVehicleModelGroup() {      
      return  _.get(this.mobiautoVersion, "emptyVehicleModelGroup")
    },

    afterAutomaticVehicleModelCreation() {      
      return  _.get(this.mobiautoVersion, "afterAutomaticVehicleModelCreation")
    }
  }
}

</script>

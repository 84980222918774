<style lang="scss" scoped>
.service-orders-table-toolbar {
  align-items: center;
  display: flex;
  justify-content: space-between;

  .filters {
    gap: 8px;
  }

  .actions {
    gap: 8px;

    .separator {
      background: $light-gray-4;
      flex-grow: 0;
      flex-shrink: 0;
      height: 16px;
      width: 1px;
    }

    .assignment {
      font-size: 14px;
      font-weight: 400;
      padding: 8px 24px;
    }

    .results-indicator {
      color: $dark-gray;
      margin-left: auto;
    }
  }
}
.indice-key-timeframe{
  width: 110px;

}
</style>

<template lang="pug">
  .service-orders-table-toolbar
    .filters.flex.vertical-bottom
      //- icon="fa-check-circle",
      index-filter-tab(
        :label="$t('.filters.monitoring.label')",
        :active="monitoring",
        :loading="initializing",
        @click="$emit('update:monitoring', true)"
      )
      //- icon="fa-lock",
      index-filter-tab(
        :label="$t('.filters.closed.label')",
        :active="!monitoring",
        :loading="initializing",
        @click="$emit('update:monitoring', false)"
      )

    .actions.flex.vertical-center
      app-button.assignment(
        v-if="canAssign && !fetching",
        theme="link",
        :disabled="!hasSelected",
        @click="$emit('click:assign')"
      ) {{ $t('.assign') }}

      .separator

      finder-field.sort-field(
        :clearable="false",
        :disabled="fetching",
        :label="$t('.fields.sort.label')",
        name="sort",
        :options="sortOptions",
        :placeholder="$t('.fields.sort.placeholder')",
        theme="borderless"
        :value="sort",
        @input="(value) => $emit('update:sort', value)"
      )

      finder-field.timeframe-field(
        :clearable="false",
        :disabled="fetching",
        :label="$t('.fields.timeframe.label')",
        name="timeframe",
        :options="timeframeOptions",
        :placeholder="$t('.fields.timeframe.placeholder')",
        theme="borderless"
        :tooltip="{ content: $t('.fields.timeframe.tooltip'), placement: 'top' }",
        :value="timeframe",
        @input="(value) => $emit('update:timeframe', value)"
        class="indice-key-timeframe"
      )

      .separator

      results-indicator.results-indicator(
        :loading="fetching",
        :page="page",
        :per-page="pagination.perPage",
        :total="pagination.total"
      )

      page-navigator(
        :page="page",
        :pagination="pagination",
        @update:page="(value) => $emit('update:page', value)"
      )
</template>

<script>
import PageNavigator from "./page-navigator.vue"

export default {
  components: {
    PageNavigator
  },

  props: {
    canAssign:    { type: Boolean, default: false },
    fetching:     { type: Boolean, default: false },
    hasSelected:  { type: Boolean, default: false },
    initializing: { type: Boolean, default: false },
    monitoring:   { type: Boolean, default: true },
    page:         { type: Number,  default: 1 },
    pagination:   { type: Object,  default: () => ({ perPage: 0, total: 0 }) },
    sort:         { type: String,  default: "id:desc" },
    timeframe:    { type: Number,  default: 30 }
  },

  emits: [
    "update:monitoring",
    "update:page",
    "update:timeframe",
    "update:sort"
  ],

  data() {
    return {
      i18nScope: "monitoring.service-orders-table-toolbar"
    }
  },

  computed: {
    sortOptions() {
      // cada option é: { icon, label, value }
      return [{
        value: "id:desc",
        label: this.$t(".fields.sort.options.mostRecent.label")
      }, {
        value: "scheduled_at:desc",
        label: this.$t(".fields.sort.options.scheduledAt.label")
      }, {
        value: "estimated_completed_at:desc",
        label: this.$t(".fields.sort.options.estimatedCompletedAt.label")
      }, {
        value: "supplier_id:asc",
        label: this.$t(".fields.sort.options.supplier.label")
      }]
    },

    // valor de timeframe é inteiro, em dias.
    timeframeOptions() {
      // cada option é: { icon, label, value }
      return [{
        value: 14,
        label: this.$t(".fields.timeframe.options.14d.label")
      }, {
        value: 30,
        label: this.$t(".fields.timeframe.options.30d.label")
      }, {
        value: (3 * 30),
        label: this.$t(".fields.timeframe.options.3m.label")
      }, {
        value: (6 * 30),
        label: this.$t(".fields.timeframe.options.6m.label")
      }, {
        value: 365,
        label: this.$t(".fields.timeframe.options.1y.label")
      }, {
        value: (2 * 365),
        label: this.$t(".fields.timeframe.options.2y.label")
      }]
    }
  }
}
</script>

import Product from "./product"
import { LoadIndices, Sizes, SpeedRatings } from "./tire/enums"

class Tire extends Product {
  static get attrs() {
    const attrs = super.attrs.filter(attr => attr !== "type")

    return _.uniq([
      ...attrs,
      "tireSize",
      "tireLoadIndex",
      "tireSpeedRating",
      "tireKind"
    ])
  }

  static get enums() {
    return {
      tireKind: {
        conventional: "conventional",
        run_flat:     "runFlat"
      },

      tireLoadIndex:   LoadIndices.asEnum(),
      tireSize:        Sizes.asEnum(),
      tireSpeedRating: SpeedRatings.asEnum()
    }
  }

  static get constraints() {
    return {
      ...super.constraints,
      tireSize: { presence: true, type: "string" },
      tireKind: { presence: true, type: "string" }
    }
  }

  static get virtuals() {
    return [
      "type"
    ]
  }

  // virtuals
  get type() {
    return "Tire"
  }

  get runFlat() {
    return this.tireKind === Tire.$enums.tireKind.key("runFlat")
  }

  set runFlat(value) {
    this.tireKind = !!value ? Tire.$enums.tireKind.key("runFlat") : Tire.$enums.tireKind.key("conventional")
  }

  get productName() {
    let chunks = {}

    chunks.tireKind = this.runFlat ? `(${Tire.$tEnum("tire-kind", "runFlat")})` : ""
    chunks.tireSize = this.tireSize || ""
    chunks.tireLoadIndex   = this.tireLoadIndex || ""
    chunks.tireSpeedRating = this.tireSpeedRating || ""
    chunks.name            = this.name || ""

    if (_.every(chunks, _.blank)) return ""
    // {size} {load index}{speed rating} {name} ({kind})
    return _.trim(`${chunks.tireSize} ${chunks.tireLoadIndex}${chunks.tireSpeedRating} ${chunks.name} ${chunks.tireKind}`)
  }
}

export default Tire

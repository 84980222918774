<style lang="scss" scoped>

.vehicle-info-tag {
  background-color: $light-gray;
  height: 54px;
  border-radius: 8px;
  padding: 0 8px;

  .manufacturer-icon {
    margin-right: 10px;
  }

  .vehicle-info {
    .name,
    .year {
      font-family: $secondary-font;
      font-weight: 400;
      font-size: 16px;
    }

    .diamond-icon {
      --size: 6px;
      font-size: var(--size);
      color: $gray-3;
      width: var(--size);
      height: var(--size);
      margin: 0 10px;

      &.small {
        --size: 4px;
      }
    }

    .vehicle-plate {
      margin-right: 8px;
      font-family: $primary-monospace-font;
      font-weight: 400;
      font-size: 14px;
    }

    .info {
      margin-right: 8px;
      font-size: 14px;
      color: $gray-4;

      .icon-container {
        height: 20px;
        width: 20px;
        margin-right: 2px;
        font-size: 14px;
        color: $gray-2;
      }
    }
  }
}

</style>


<template lang="pug">

  .vehicle-info-tag.flex.vertical-center
    .flex.vertical-center
      vehicle-manufacturer-icon.manufacturer-icon(
        :value="manufacturer.name",
        :size="32"
      )

      .vehicle-info
        .row.flex.vertical-center
          app-span.name(:value="name")
          i.diamond-icon.fas.fa-diamond
          app-span.year(:value="vehicle.year")

        .row.flex.vertical-center
          app-span.vehicle-plate(:value="vehicle.licensePlate")

          .info.flex.vertical-center
            .icon-container.flex.center.vertical-center
              i.icon.fas.fa-swatchbook
            span {{ vehicle.colorName }}

          .info.flex.vertical-center
            .icon-container.flex.center.vertical-center
              i.icon.fas.fa-tachometer-alt-average
            span {{ kilometrage }}

          .info.flex.vertical-center
            .icon-container.flex.center.vertical-center
              i.icon.fas.fa-gas-pump
            template(v-if="isElectric")
              span {{ vehicleModel.type }}
            template(v-else)
              template(v-if="isHybrid")
                span {{ vehicleModel.type }}
                i.diamond-icon.small.fas.fa-diamond
              span {{ VehicleModelHybrid.$tEnum('fuel', vehicleModel.fuel) }}

</template>


<script>

import VehicleInfoBanner from "./vehicle-info-banner"

export default {
  extends: VehicleInfoBanner
}

</script>

<style scoped lang="scss">
</style>


<template lang="pug">

  .service-type-info-label
    template(v-if="loading")
      loading-lines(:min="60", :max="80")

    template(v-else)
      info-label(
        :label="label",
        :value="value"
      )
        template(slot="icon")
          service-type-icon(:type="type")

</template>


<script>

export default {
  props: {
    type:    { type: String, default: null },
    value:   { type: String, default: null },
    label:   { type: String, default: null },
    loading: { type: Boolean, default: false }
  },

  data() {
    return {
      i18nScope: "services.components.service-type-info-label"
    }
  }
}

</script>

<script>

// Extends
import TemplateDownloadButton from "@/views/suppliers/deals/products/index/template-download-button"

export default {
  extends: TemplateDownloadButton,

  computed: {
    fileName() {
      return `template-pacotes-revisao.xlsx`
    }
  },

  methods: {
    request() {
      return this.$http.get(`/deals/${this.dealId}/maintenance_packages/spreadsheet`, {
        responseType:       "blob",
        onDownloadProgress: this.onDownloadProgress
      })
    }
  }
}

</script>

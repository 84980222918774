<style scoped lang="scss">

.row {
  margin-bottom: 24px;
}

.title {
  font-size: 18px;
  font-weight: normal;
  color: $black;
  margin: 24px 0;
}

</style>


<template lang="pug">

  section
    h3.title {{ $t('.title') }}

    ui-row.row
      ui-col(:span="3")
        radio-field(
          name="vehicleModel[spareTireSpecification][material]",
          :options="spareTireSpecificationMaterialOptions",
          :disabled="submitting || fetching",
          orientation="vertical",
          :label="$t('.fields.material.label')",
          v-model="resource.material"
          :errors="errors.material",
        )

    ui-row.row
      ui-col(:span="4")
        tire-specification-size-nested-fields(
          name="vehicleModel[spareTireSpecification][sizes]",
          :minimum="1",
          v-model="resource.sizes",
          :fetching="fetching",
          :submitting="submitting",
          :disabled="fetching",
          :errors="errors",
        )

      ui-col(:span="4")
        tire-specification-load-index-nested-fields(
          name="vehicleModel[spareTireSpecification][loadIndices]",
          :minimum="1",
          v-model="resource.loadIndices",
          :fetching="fetching",
          :submitting="submitting",
          :disabled="fetching",
          :errors="errors",
        )

      ui-col(:span="4")
        tire-specification-speed-rating-nested-fields(
          name="vehicleModel[spareTireSpecification][speedRatings]",
          :minimum="1",
          v-model="resource.speedRatings",
          :fetching="fetching",
          :submitting="submitting",
          :disabled="fetching",
          :errors="errors",
        )

</template>


<script>

import TireSpecificationSizeNestedFields from "./tire-specification-size-nested-fields"
import TireSpecificationLoadIndexNestedFields from "./tire-specification-load-index-nested-fields"
import TireSpecificationSpeedRatingNestedFields from "./tire-specification-speed-rating-nested-fields"

import models from "@/models"
const { SpareTireSpecification } = models

export default {
  components: {
    TireSpecificationSizeNestedFields,
    TireSpecificationLoadIndexNestedFields,
    TireSpecificationSpeedRatingNestedFields
  },

  props: {
    fetching:   { type: Boolean, default: false },
    submitting: { type: Boolean, default: false },
    errors:     { type: Object, default: () => ({}) },
    resource:   { type: Object, default: () => ({}) }
  },

  data() {
    return {
      i18nScope: "vehicle-models.forms.spare-tire-specification-fields"
    }
  },

  computed: {
    spareTireSpecificationMaterialOptions() {
      return SpareTireSpecification.$enums.material.values.map(material => ({
        label: this.$t(`models.tireSpecification.enums.material.${material}`),
        value: material
      }))
    }
  }
}

</script>

<style lang="scss" scoped>

.index-filter-tab {
  .loading {
    width: 130px;
    margin-right: 12px;

    ::v-deep .shine-loading {
      border-radius: 32px;
    }
  }

  .button {
    padding: 8px 12px;
    border: 1px solid $light-gray-3;
    border-radius: 32px;
    font-family: $secondary-font;
    font-size: 14px;
    font-weight: 500;
    color: $dark-gray;
    cursor: pointer;

    &.active {
      background-color: $light-gray-2;
      border-color: $light-gray-2;
      color: $orange;
    }

    &.disabled {
      cursor: not-allowed;
      color: $gray-2;
    }

    &:hover:not(.disabled) {
      color: $orange;
    }

    .icon {
      margin-right: 8px;
    }
  }
}

</style>


<template lang="pug">

  .index-filter-tab
    template(v-if="loading")
      loading-lines.loading(:height="33", :min="100", :max="100")

    template(v-else)
      app-button.button(
        theme="link",
        :disabled="disabled",
        :class="{ active }",
        @click="$emit('click')"
      )
        i.icon(v-if="icon", :class="iconClass")
        span.label {{ label }}

</template>


<script>

export default {
  name: "IndexFilterTab",

  props: {
    icon:     { type: String, default: null },
    label:    { type: String, required: true },
    active:   { type: Boolean, default: false },
    loading:  { type: Boolean, default: false },
    disabled: { type: Boolean, default: false }
  },

  data() {
    return {
      i18nScope: "components.index-filter-tab"
    }
  },

  computed: {
    iconClass() {
      const weightClass = this.active ? "fas" : "fal"

      return {
        [this.icon]:   true,
        [weightClass]: true
      }
    }
  }
}

</script>

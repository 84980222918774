<style lang="scss" scoped>

.target-fields {
  .index {
    width: 24px;
    height: 24px;
    background-color: $light-gray-3;
    border-radius: 12px;
    margin-right: 8px;
    font-family: $secondary-font;
    font-weight: 500;
    font-size: 14px;
  }

  .action-icons {
    margin-left: 18px;
    width: 60px;

    &.first {
      margin-top: 34px;
    }

    .icon {
      font-size: 20px;
      width: 20px;
      height: 20px;
    }

    .button + .button {
      margin-left: 20px;
    }

    .clear {
      color: $gray-2;

      &:hover {
        color: $black;
      }
    }
  }
}

</style>


<template lang="pug">

  .target-fields
      ui-row.flex.vertical-center
        .flex.grow.vertical-center
          .index.flex.center.vertical-center.no-shrink
            span {{ index }}
          ui-col(:span="6")
            target-position-select-field(
              name='target[position]',
              :errors="errors.position",
              :options="positionOptions",
              :disabled="disabled",
              :loading="loading",
              v-model="resource.position",
              @input="$emit('change')",
              hide-label
            )

          ui-col.kind-col(:span="6")
            target-location-select-field(
              name='target[location]',
              :errors="errors.location",
              :options="locationOptions",
              :disabled="disabled",
              :loading="loading",
              v-model="resource.location",
              @input="$emit('change')",
              hide-label
            )

        .action-icons
          app-button.button(
            theme="link",
            :loading="loading",
            @click="clear"
          )
            i.icon.clear.fal.fa-times-circle

          app-button.button(
            v-if="canAdd",
            theme="link",
            :disabled="disabled",
            :loading="loading",
            @click="$emit('add')"
          )
            i.icon.add.fal.fa-plus-circle

</template>


<script>

// Components
import TargetPositionSelectField from "./target-position-select-field.vue"
import TargetLocationSelectField from "./target-location-select-field.vue"

// Models
import models from "@/models"
const { VehiclePartTarget } = models

export default {
  components: {
    TargetPositionSelectField,
    TargetLocationSelectField
  },

  props: {
    resource: { type: Object, required: true },
    index:    { type: Number, required: true },
    loading:  { type: Boolean, default: false },
    errors:   { type: Object, default: () => ({}) },
    canAdd:   { type: Boolean, default: false },
    options:  { type: Array, required: true },
    disabled: { type: Boolean, default: false }
  },

  data() {
    return {
      i18nScope: "tickets.drafts.contents.services.components.target-fields"
    }
  },

  computed: {
    positionOptions() {
      const options = this.resource.location
        ? this.options.filter(({ location }) => location === this.resource.location)
        : this.options

      return _.uniq(options.map(({ position }) => position)).map(value => ({
        value,
        label: VehiclePartTarget.$tEnum("position", value)
      }))
    },

    locationOptions() {
      const options = this.resource.position
        ? this.options.filter(({ position }) => position === this.resource.position)
        : this.options

      return _.uniq(options.map(({ location }) => location)).map(value => ({
        value,
        label: VehiclePartTarget.$tEnum("location", value)
      }))
    }
  },

  methods: {
    clear() {
      if (!this.loading) this.$emit("clear")
    },

    add() {
      if (!this.loading) this.$emit("add")
    }
  }
}

</script>

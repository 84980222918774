<style lang="scss" scoped>

.search-form {
  .form {
    width: 100%;

    .row + .row {
      margin-top: 8px;
    }
  }
}

</style>


<template lang="pug">

  .search-form
    form.form(
      @submit.prevent="$emit('search')",
      method="get",
      data-test="form"
    )
      ui-row.row(:columns="24", vertical-align="bottom")
        ui-col(:span="14")
          input-field(
            name="search[q]",
            v-model="params.q",
            :loading="loading",
            hide-label,
            :placeholder="$t('.fields.q.placeholder.full')",
            icon-left="far fa-search"
          )

        ui-col(:span="5")
          ticket-type-select-field(
            name="search[type]",
            :label="$t('.fields.type.label')",
            :placeholder="$t('.fields.type.placeholder')",
            :loading="loading",
            v-model="params.type",
            searchable,
            data-testid="typeSelect"
          )

        ui-col(:span="5")
          ticket-state-select-field(
            name="search[state]",
            :label="$t('.fields.state.label')",
            :placeholder="$t('.fields.state.placeholder')",
            :loading="loading",
            v-model="params.state",
            searchable,
            data-testid="stateSelect"
          )

      ui-row.row(vertical-align="bottom")
        ui-col(:span="7")
          store-select-field(
            name="search[store_id]",
            :label="$t('.fields.store.label')",
            :placeholder="$t('.fields.store.placeholder')",
            :loading="loading",
            v-model="params.storeId"
          )

        ui-col(:span="4")
          daterange-field(
            :disabled="loading"
            :label="$t('.fields.date-range.label')"
            :labels="{\
              start: $t('.fields.since.label'),\
              end: $t('.fields.until.label')\
            }"
            :placeholder="$t('.fields.date-range.placeholder')"
            :value="dateRange"
            @input="handleDateRangeInput"
          )

        ui-col(:span="1")
          app-button(
            type="submit",
            :loading="loading",
            full-width,
            outline
          )
            span {{ $t('btn.search.label') }}
            span(slot="loading") {{ $t('btn.search.loading') }}

</template>


<script>

// Components
import TicketTypeSelectField from "./ticket-type-select-field.vue"

export default {
  components: {
    TicketTypeSelectField
  },

  props: {
    loading: { type: Boolean, default: false },
    params:  { type: Object, default: () => ({}) }
  },

  data() {
    return {
      i18nScope: "tickets.index.search-form",
    }
  },
  computed: {
    dateRange() {
      return [this.params.since, this.params.until].filter(_.present)
    }
  },
  methods: {
    handleDateRangeInput([since, until]) {
      this.params.since = since
      this.params.until = until
    }
  }
}

</script>

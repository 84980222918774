const GoBackPlugin = {
  installed: false,

  install(Vue, { defaultRouteName, router }) {
    if (GoBackPlugin.installed) return
    GoBackPlugin.installed = true

    function goBack({ fallback = null } = {}) {
      const { referrer } = router

      if (referrer) {
        router.go(-1)
      }
      else {
        const route = _.present(fallback) ? fallback : defaultRouteName
        // https://github.com/vuejs/vue-router/issues/2881#issuecomment-520554378
        router.push(route, () => {})
      }
    }

    Vue.$goBack = goBack
    // add an instance method
    Vue.prototype.$goBack = goBack
  }
}


export default GoBackPlugin

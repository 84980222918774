import Base from "./base"


class ServiceOrderCancellation extends Base {
  static get attrs() {
    return _.uniq([
      ...super.attrs,

      "motive",
      "description",
      "authorId",
      "authorName",
      "cancelTicket"
    ])
  }

  static get enums() {
    return {
      motive: {
        no_show:             "no_show",
        movida_withdrawal:   "movida_withdrawal",
        customer_withdrawal: "customer_withdrawal",
        supplier_change:     "supplier_change"
      }
    }
  }

  static get constraints() {
    return {
      motive: { presence: true }
    }
  }
}


export default ServiceOrderCancellation

<style lang="scss" scoped>
.service-order-state {
  background-color: $light-gray-2;
  padding: 2px 4px;
  border-radius: 4px;
  font-family: $secondary-font;
  font-weight: 400;
  font-size: 12px;

  &.canceled {
    background-color: $error;
    color: $white;
  }

  &.vetor {
    background-color: $light-yellow;
    color: $dark-gray;
  }
}
</style>

<template lang="pug">
  .service-order-state.flex.center(:class="state")
    span.text-center {{ text }}
</template>

<script>
import models from "@/models"

const { MovidaServiceOrder } = models

export default {
  props: {
    state: {
      type:      String,
      required:  true,
      validator: (value) => MovidaServiceOrder.$enums.state.values.includes(value)
    }
  },

  computed: {
    text() {
      return MovidaServiceOrder.$tEnum("state", this.state)
    }
  }
}
</script>

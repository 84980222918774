<style scoped lang="scss">

.index {
  padding-bottom: 24px;

  .header {
    margin-bottom: 32px;
    padding-bottom: 16px;
    border-bottom: 1px solid $light-gray-3;

    .title-icon {
      margin-right: 16px;
      font-size: 30px;
    }

    .title-text {
      font-size: 30px;
      font-weight: normal;
      font-weight: 500;
      font-family: $secondary-font;
    }
  }

  .clear-filters {
    position: relative;

    .filters-button {
      position: absolute;
      margin-top: 10px;
    }
  }

  .list {
    margin-top: 50px;

    .table {
      margin-top: 16px;
    }
  }

  .paginator-content {
    padding-top: 24px;

    &.loading {
      max-width: 400px;
      margin: 0 auto;
    }
  }
}

</style>


<template lang="pug">

  .index
    header.header.flex.space-between
      .title.flex.vertical-center
        i.title-icon.fal.fa-cars
        h1.title-text {{ $t('.title') }}
      app-button(
        v-if="$can('create', 'VehicleModelGroup')",
        :to="newVehicleModelGroupRoute",
        data-testid="createVehicleModelGroup"
      ) {{ $t('.btn.new.label') }}

    search-form.search(
      :loading="fetching",
      :params="parsedQueryParams",
      @search="search"
    )

    .clear-filters
      filters-button.filters-button(
        v-if="hasFilters",
        @clear="clearFilters"
      )

    .list
      .flex.space-between.vertical-bottom
        .result-tabs.flex
          index-filter-tab(
            icon="fa-check-circle",
            :label="$t('.filters.enabled.label')",
            :active="!parsedQueryParams.discarded",
            :loading="initializing",
            @click="onDiscardedChange(false)"
          )
          index-filter-tab(
            icon="fa-lock",
            :label="$t('.filters.disabled.label')",
            :active="parsedQueryParams.discarded",
            :loading="initializing",
            @click="onDiscardedChange(true)"
          )

        results-indicator(
          :page="parsedQueryParams.page",
          :per-page="pagination.perPage",
          :total="pagination.total",
          :loading="fetching"
        )

      vehicle-model-groups-table.table(
        :vehicle-model-groups="vehicleModelGroups",
        :initializing="initializing",
        :loading="fetching",
        :sorted-by="parsedQueryParams.sort",
        @sort="onSortChange",
        @changed="fetch"
      )

    .paginator-content(v-if="fetching || showPaginator", :class="{ loading: fetching }")
      paginator(
        :loading="fetching",
        :per-page="pagination.perPage",
        :total="pagination.total",
        :value="parsedQueryParams.page",
        :disabled="fetching",
        @input="onPageChange",
        data-testid="paginator"
      )

</template>


<script>

// View
import IndexView from "@/views/index-view"

// Mixins
import FetchMixin from "@/mixins/fetch-mixin"

// Components
import VehicleModelGroupsTable from "./index/vehicle-model-groups-table"
import SearchForm from "./index/search-form"

export default {
  name: "VehicleModelGroups",

  components: {
    VehicleModelGroupsTable,
    SearchForm
  },

  extends: IndexView,

  mixins: [FetchMixin],

  data() {
    return {
      i18nScope: "vehicle-model-groups.index",
      routeName: "vehicleModelGroups",

      resource: [],

      // @requirement IndexView
      queryParamsStructure: {
        q:                "string",
        vehicle_model_id: "string",
        discarded:        "boolean",
        page:             "integer",
        sort:             "string"
      },

      // @override IndexView
      clearFiltersIgnoreList: ["discarded"]
    }
  },

  computed: {
    vehicleModelGroups: {
      get()    { return this.resource },
      set(val) { this.resource = val }
    },

    newVehicleModelGroupRoute() {
      return { name: "newVehicleModelGroup" }
    }
  },

  methods: {
    parseRoute() {},

    onDiscardedChange(discarded) {
      this.onParamChange("discarded", discarded)
    },

    // @override Fetch Mixin
    fetchRequest() {
      return this.$newSdk.vehicleModelGroups.list({ params: this.requestQueryParams })
    }
  }
}

</script>

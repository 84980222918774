// Base
import BaseRequests from "../../Base"

// Models
import models from "@/models"
const { ServiceOrderExecution } = models

class MonitoringServiceOrderExecutionRequests extends BaseRequests {
  static get selector() {
    return "monitoring.serviceOrders.execution"
  }

  create({ serviceOrderId, params = {}, options = {} } = {}) {
    this.verifyData("create", { serviceOrderId })

    return this.action({
      method:  "post",
      url:     `/monitoring/service_orders/${serviceOrderId}/execution`,
      params,
      options: {
        model: ServiceOrderExecution,

        ...options
      }
    })
  }

  find({ serviceOrderId, params = {}, options = {} } = {}) {
    this.verifyData("find", { serviceOrderId })

    return this.action({
      method:  "get",
      url:     `/monitoring/service_orders/${serviceOrderId}/execution`,
      params,
      options: {
        model: ServiceOrderExecution,

        ...options
      }
    })
  }
}

export default MonitoringServiceOrderExecutionRequests

<style lang="scss" scoped>

.service-packages {
  padding-bottom: 24px;

  .header {
    margin-bottom: 32px;
    padding-bottom: 16px;
    border-bottom: 1px solid $light-gray-3;

    .title-icon {
      margin-right: 16px;
      font-size: 30px;
    }

    .title-text {
      font-size: 30px;
      font-weight: normal;
      font-weight: 500;
      font-family: $secondary-font;
    }
  }

  .search {
    margin-bottom: 32px;
  }

  .link {
    margin-left: 4px;
  }

  .paginator-content {
    padding-top: 24px;

    &.loading {
      max-width: 400px;
      margin: 0 auto;
    }
  }
}

</style>


<template lang="pug">

  .service-packages
    header.header.flex.space-between
      .title.flex.vertical-center
        i.title-icon.product-icon.fal.fa-box-alt
        h1.title-text {{ $t('.title') }}
      app-button(
        v-if="$can('create', 'ServicePackage')",
        :to="{ name: 'newServicePackage' }",
        data-testid="createServicePackage"
      ) {{ $t('.btn.new') }}

    search-form.search(
      :params="params",
      @search="search"
    )

    app-list-header(
      v-if="showAppListHeader"

      :has-filters="hasFilters",
      :page="params.page",
      :pagination="pagination",
      :loading="fetching",

      @clear="clearFilters"
    )

    app-list(
      :collection="resource",
      :loading="fetching",
      :empty="empty",
      :empty-text="$t('.empty')",
      :error-text="$t('.error')",
      :error="hasFetchError"
    )
      template(slot="empty")
        span {{ $t('.empty') }}
        router-link.link(:to="{ name: 'newServicePackage' }") {{ $t('.link') }}

      service-package-row(
        v-for="servicePackage in resource",
        :key="`service-package-row-${servicePackage.id}`",
        :service-package="servicePackage",
        @destroy="_fetch"
      )

    .paginator-content(v-if="fetching || showPaginator", :class="{ loading: fetching }")
      paginator(
        :loading="fetching",
        :per-page="pagination.perPage",
        :total="pagination.total",
        @input="onChangePage",
        :value="params.page",
        :disabled="fetching"
      )

</template>


<script>

// Extends
import SearchableIndexView from "@/views/searchable-index-view"

// Local components
import ServicePackageRow from "./index/service-package-row"
import SearchForm from "./index/search-form"

export default {
  name: "ServicePackages",

  components: {
    ServicePackageRow,
    SearchForm
  },

  extends: SearchableIndexView,

  data() {
    return {
      i18nScope: "service-packages.index",

      // Query Mixin data
      parseQueryParams: {
        q:    "string",
        page: "integer"
      }
    }
  },

  methods: {
    fetch() {
      return this.$sdk.servicePackages.list({ params: this.httpParams })
    },

    // @override Fetchable mixin
    onFetchSuccess(response) {
      this.setPagination(response.headers)
    },

    // @override Fetchable mixin
    onFetchError(err) {
      // Dealing with appError from extension view.vue
      if (this.hasViewError(err)) this.appError = err

      this.$err.log(err)
      this.$notifications.error(this.$t(".notifications.fetch.failure"))
    }
  }
}

</script>

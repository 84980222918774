<script>

// TODO definir tabela como componente global
// Extends
import VehicleModelsList from "@/components/vehicle-models-list"

export default {
  extends: VehicleModelsList,

  props: {
    vehicleModelGroupId: { type: [String, Number], required: true }
  },

  data() {
    return {
      id: this.vehicleModelGroupId
    }
  },

  watch: {
    vehicleModelGroupId() {
      if (_.present(this.vehicleModelGroupId)) this._fetch()
    }
  },

  methods: {
    // @override Fetchable mixin
    fetch() {
      return this.$sdk.vehicleModelGroups.listVehicleModels({
        id:     this.id,
        params: this.httpParams
      })
    }
  }
}

</script>

// Base
import BaseRequests from "../Base"

// Models
import models from "@/models"
const { MovidaProduct } = models

class MonitoringProductRequests extends BaseRequests {
  static get selector() {
    return "monitoring.products"
  }

  all({ params = {}, options = {} } = {}) {
    return this.action({
      method:  "post",
      url:     `/monitoring/products/search`,
      params,
      options: {
        model: MovidaProduct,

        ...options
      }
    })
  }

  find({ productRid, params = {}, options = {} } = {}) {
    this.verifyData("find", { productRid })

    return this.action({
      method:  "get",
      url:     `/monitoring/products/${productRid}`,
      params,
      options: {
        model: MovidaProduct,

        ...options
      }
    })
  }
}

export default MonitoringProductRequests

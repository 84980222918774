// Ability
import { ability } from "@/modules/ability"

// Routes
import Settings from "@/views/settings/show.vue"
import SupplierServiceScoreSettings from "@/views/settings/supplier-service-score/show.vue"
import EditSupplierServiceScoreSettings from "@/views/settings/supplier-service-score/edit.vue"

export default [
  {
    path:      "/settings",
    name:      "settings",
    component: Settings,
    redirect:  "/settings/supplier-service-score",
    meta:      {
      auth:        true,
      permissions: [() => ability.can("read", "SupplierServiceScoreSettings")]
    },

    children: [
      {
        path:      "supplier-service-score",
        name:      "supplierServiceScoreSettings",
        component: SupplierServiceScoreSettings
      },
      {
        path:      "supplier-service-score/edit",
        name:      "editSupplierServiceScoreSettings",
        component: EditSupplierServiceScoreSettings,
        meta:      {
          permissions: [() => ability.can("update", "SupplierServiceScoreSettings")]
        }
      }
    ]
  }
]

<!--
<doc lang="markdown">

Exibe um ícone padronizado, com _tooltip_, mostrando que o recurso está "desabilitado" (descartado)

Como usar:
```pug
  discarded-icon(v-if="resource.$discarded")
```

</doc>
-->


<style lang="scss" scoped>

.discarded-icon {
  display: inline-block;
  color: $dark-error;

  &.small {
    font-size: 14px;
  }

  &.normal {
    font-size: 20px;
  }

  &.big {
    font-size: 26px;
  }
}

</style>


<template lang="pug">

  .discarded-icon(:class="size", v-tooltip="tooltipOptions")
    i.icon.fas.fa-lock

</template>


<script>

export default {
  name: "DiscardedIcon",

  props: {
    size: { type: String, default: "small", validator: size => ["small", "normal", "big"].includes(size) }
  },

  data() {
    return {
      i18nScope: "components.icons.discarded-icon"
    }
  },

  computed: {
    tooltipOptions() {
      return {
        content:   this.$t(".tooltip"),
        placement: "bottom",
        offset:    "4"
      }
    }
  }
}

</script>

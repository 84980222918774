.<style lang="scss" scoped>

.deal-maintenance-packages-table-header {
  display: grid;
  grid-template-columns: 1fr 1fr 130px 64px;
  background-color: $light-gray;
  height: 40px;
  border-bottom: 1px solid $light-gray-3;
  border-top: 1px solid $light-gray-3;

  &.loading {
    border-bottom: 2px solid $light-orange-2;
  }

  .column {
    font-size: 12px;
    padding: 0 12px;

    .icon {
      color: $gray-2;
      font-size: 14px;

      & + .text {
        margin-left: 8px;
      }
    }

    .text {
      font-family: $secondary-font;
      font-size: 14px;
      color: $dark-gray;
      font-weight: 400;
    }

    .loader-icon {
      font-size: 16px;
      color: $orange;
    }
  }
}

</style>


<template lang="pug">

  .deal-maintenance-packages-table-header(:class="{ loading }")
    sortable-table-header-column.column.flex.vertical-center(
      sort-param="maintenance_package_name",
      :sort-order="sortOrder",
      :active="isSortedBy('maintenance_package_name')",
      @sort="param => $emit('sort', param)"
    )
      span.text {{ $t('.columns.maintenance-package.label') }}

    sortable-table-header-column.column.flex.vertical-center(
      sort-param="vehicle_model_group_name",
      :sort-order="sortOrder",
      :active="isSortedBy('vehicle_model_group_name')",
      @sort="param => $emit('sort', param)"
    )
      i.icon.fas.fa-cars
      span.text {{ $t('.columns.vehicle-model-group.label') }}

    sortable-table-header-column.column.flex.vertical-center(
      sort-param="labor_price",
      :sort-order="sortOrder",
      :active="isSortedBy('labor_price')",
      @sort="param => $emit('sort', param)"
    )
      span.text {{ $t('.columns.labor-price.label') }}

    .column.flex.vertical-center.center
      template(v-if="loading")
        i.loader-icon.fas.fa-spin.fa-spinner

</template>


<script>

export default {
  props: {
    loading:   { type: Boolean, default: false },
    sortParam: { type: String, required: true },
    sortOrder: { type: String, required: true }
  },

  data() {
    return {
      i18nScope: "suppliers.deals.maintenance-packages.index.deal-maintenance-packages-table-header"
    }
  },

  methods: {
    isSortedBy(param) {
      return this.sortParam === param
    }
  }
}

</script>

<style scoped lang="scss">

.suppliers {
  padding-bottom: 24px;

  .header {
    margin-bottom: 32px;
    padding-bottom: 16px;
    border-bottom: 1px solid $light-gray-3;

    .title-icon {
      margin-right: 16px;
      font-size: 30px;
    }

    .title-text {
      font-size: 30px;
      font-weight: normal;
      font-weight: 500;
      font-family: $secondary-font;
    }
  }

  .clear-filters {
    position: relative;

    .filters-button {
      position: absolute;
      margin-top: 10px;
    }
  }

  .list {
    margin-top: 50px;

    .table {
      margin-top: 16px;
    }
  }

  .paginator-content {
    padding-top: 24px;

    &.loading {
      max-width: 400px;
      margin: 0 auto;
    }
  }
}

</style>


<template lang="pug">

  .suppliers
    header.header.flex.space-between
      .title.flex.vertical-center
        i.title-icon.supplier-icon.fal.fa-user-hard-hat
        h1.title-text {{ $t('.title') }}
      app-button(
        v-show="$can('create', 'Supplier')",
        :disabled="!$can('create', 'Supplier')"
        :to="{ name: 'newSupplier' }",
      ) {{ $t('.btn.new') }}

    search-form.search(
      :loading="fetching",
      :params="parsedQueryParams",
      @search="search"
    )

    .clear-filters
      filters-button.filters-button(
        v-if="hasFilters",
        @clear="clearFilters"
      )

    .list
      .flex.end.vertical-bottom
        results-indicator(
          :page="parsedQueryParams.page",
          :per-page="pagination.perPage",
          :total="pagination.total",
          :loading="fetching"
        )

      suppliers-table.table(
        :error="erred",
        :empty="empty",
        :initializing="initializing",
        :loading="fetching",
        :suppliers="suppliers",
        :sorted-by="parsedQueryParams.sort",
        @sort="onSortChange",
        @changed="fetch"
      )

    .paginator-content(v-if="fetching || showPaginator", :class="{ loading: fetching }")
      paginator(
        :loading="fetching",
        :per-page="pagination.perPage",
        :total="pagination.total",
        :value="parsedQueryParams.page",
        :disabled="fetching",
        @input="onPageChange",
        data-testid="paginator"
      )

</template>


<script>

// View
import IndexView from "@/views/index-view"

// Mixins
import FetchMixin from "@/mixins/fetch-mixin"

// Components
import SuppliersTable from "./index/suppliers-table"
import SearchForm from "./index/search-form"
import FiltersButton from "@/components/app-list-header/filters-button.vue";
import ResultsIndicator from "@/components/app-list-header/results-indicator.vue";
import Paginator from "@/components/paginator.vue";

export default {
  name: "Suppliers",

  components: {
    Paginator,
    ResultsIndicator,
    FiltersButton,
    SuppliersTable,
    SearchForm
  },

  extends: IndexView,

  mixins: [FetchMixin],

  data() {
    return {
      i18nScope: "suppliers.index",
      routeName: "suppliers",

      resource: [],

      // @override IndexView
      clearFiltersIgnoreList: ["discarded"],

      // Query Mixin data
      queryParamsStructure: {
        q:          "string",
        sort:       "string",
        page:       "integer",

        blocked:        "boolean",
        city_id:        "string",
        classification: "string",
        deal_state:     "string",
        discarded:      "boolean",
        sector_id:      "string",
        rabbot_plataform: "boolean",
      }
    }
  },

  computed: {
    suppliers: {
      get() { return this.resource },
      set(value) { this.resource = value }
    },

    empty() {
      return _.blank(this.suppliers)
    }
  },

  methods: {
    parseRoute() {},

    onDiscardedChange(discarded) {
      this.onParamChange("discarded", discarded)
    },

    // @override Fetchable mixin
    fetchRequest() {
      return this.$newSdk.suppliers.all({ params: this.requestQueryParams })
    }
  }
}

</script>

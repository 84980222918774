import validate from "validate.js"



function absence(value, options, key, attrs) {
  let opts = _.merge({}, this.options, options)

  if (_.blank(value)) return

  return opts.message || this.message || `cannot be present`
}


function after(value, options, key, attrs) {
  let opts = _.merge({}, this.options, options)

  if (_.blank(value)) return

  const target = moment(attrs[opts.field], opts.format)

  if (!moment(value, opts.format).isAfter(target)) {
    return opts.message || this.message || `must be after ${target.format()}`
  }

  // returning nothing means "is valid"
}


function before(value, options, key, attrs) {
  let opts = _.merge({}, this.options, options)

  if (_.blank(value)) return

  const target = moment(attrs[opts.field], opts.format)

  if (!moment(value, opts.format).isBefore(target)) {
    return opts.message || this.message || `must be before ${target.format()}`
  }

  // returning nothing means "is valid"
}


validate.validators.absence = absence
validate.validators.after   = after
validate.validators.before  = before

<style lang="scss" scoped>

.store {
  padding-bottom: 24px;
}

.page-content {
  margin-top: 24px;
}

</style>


<template lang="pug">

  .store
    loading-lines(v-if="fetching", :lines="4", :height="27")
    template(v-else)
      header.header
        store-info-card(:resource="resource", data-test="infoCard")

      .g-tab-navbar-content
        router-tab(
          :to="defaultRoute",
          :active="isDefaultTab"
        ) {{ $t('.tab.info') }}

        router-tab(
          :to="{ name: 'storeServiceSupports', params: { id } }"
        ) {{ $t('.tab.serviceSupports') }}

        router-tab(
          v-if="$can('index', 'Supplier')",
          :to="suppliersRoute",
          :active="isSupplierTab",
          data-testid="suppliersTab"
        ) {{ $t('.tab.suppliers') }}

        router-tab(
          v-if="$can('index', 'Ticket')",
          :to="ticketsRoute",
          :active="isTicketTab",
          data-testid="ticketsTab"
        ) {{ $t('.tab.tickets') }}

        router-tab(
          v-if="$can('update', 'Store')",
          :to="settingsRoute",
          :active="isSettingsTab"
          data-testid="settingsTab"
        ) {{ $t('.tab.settings') }}

      .page-content
        store-view-context
        router-view(
          :store="resource",
          @update="_fetch"
        )

</template>


<script>

// Mixins
import { mixins } from "movida-common.vue"

// Extends
import View from "@/views/view"

// Components
import StoreInfoCard from "./components/store-info-card.vue"
import StoreViewContext from "./components/store-view-context.vue"

const { Fetchable } = mixins

export default {
  name: "Store",

  components: {
    StoreInfoCard,
    StoreViewContext
  },

  extends: View,

  mixins: [Fetchable],

  data() {
    return {
      i18nScope: "stores.show",

      model:    "Store",
      id:       this.$route.params.id,
      resource: {}
    }
  },

  computed: {
    defaultRoute() {
      return { name: "store", params: { id: this.id } }
    },

    isDefaultTab() {
      const resolved = this.$router.resolve(this.defaultRoute)
      const path = resolved.href.replace(/\/$/, "")

      return [path, `${path}/edit`].includes(this.$route.path)
    },

    suppliersRoute() {
      return { name: "storeSuppliers", params: { id: this.id } }
    },

    isSupplierTab() {
      const resolved = this.$router.resolve(this.suppliersRoute)
      return this.$route.path.startsWith(resolved.href)
    },

    ticketsRoute() {
      return { name: "storeTickets", params: { id: this.id } }
    },

    serviceOrdersRoute() {
      return { name: "storeServiceOrders", params: { id: this.id } }
    },

    isTicketTab() {
      const ticketsPath = this.$router.resolve(this.ticketsRoute).href
      const serviceOrdersPath = this.$router.resolve(this.serviceOrdersRoute).href

      return this.$route.path.startsWith(ticketsPath) || this.$route.path.startsWith(serviceOrdersPath)
    },

    settingsRoute() {
      return { name: "storeSettings", params: { id: this.id } }
    },

    isSettingsTab() {
      const resolved = this.$router.resolve(this.settingsRoute)
      const path = resolved.href.replace(/\/$/, "")

      return [path, `${path}/edit`].includes(this.$route.path)
    }
  },

  methods: {
    fetch() {
      return this.$sdk.stores.get({ id: this.id })
    },

    // @override Fetchable mixin
    // onFetchSuccess(response) {},

    onFetchError(err) {
      if (this.hasViewError(err)) {
        this.appError = err
      }

      this.$err.log(err)
      this.$notifications.error(this.$t(".notifications.fetch.failure"))
    }
  }
}

</script>

<style scoped lang="scss">

table {
  width: 100%;
  border-spacing: 0;
  table-layout: fixed;
  min-width: 1040px;
}

th, td {
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 0 8px;
}

th {
  font-size: 14px;
  font-weight: 500;
  text-align: left;
  font-family: $secondary-font;
  color: $dark-gray;
  height: 48px;
  background: $light-gray-2;
}

.maintenance-column {
  width: 208px;
}

.empty,
.table-error {
  text-align: center;
  font-style: italic;
  padding: 32px 0;
  width: 100%;

  color: $gray-3;
}

.order-column {
  width: 44px;
}

.kilometrage-limit-column {
  width: 114px;
}

.time-limit-column {
  width: 109px;
}

.target-execution-time-column {
  width: 66px;
}

.free-charge-column,
.forbidden-in-internal-facilities-column,
.dealership-only-column {
  width: 52px;
}

.delete-column {
  width: 58px;
}

.order-column,
.free-charge-column,
.forbidden-in-internal-facilities-column,
.dealership-only-column {
  text-align: center;
}

.popover-content {
  padding: 8px;
}

.icon {
  font-size: 16px;
  color: $gray-2;
}

.maintenance-package {
  padding-left: 8px;
}

.svg-icon {
  background-color: $gray-2;
  height: 24px;
  width: 24px;
  mask-repeat: no-repeat;
  mask-position: center;

  &.free-of-charge {
    mask-image: var(--free-of-charge-icon);
  }

  &.forbidden-in-internal-facilities {
    mask-image: var(--forbidden-in-internal-facilities-icon);
  }

  &.dealership-only {
    mask-image: var(--dealership-only-icon);
  }
}

.diamond-icon {
  font-size: 8px;
  font-weight: 900;
  color: $gray-3;
  width: 8px;
  height: 8px;
}

</style>


<template lang="pug">

  .maintenances-table(:style="cssVars")
    table
      thead
        tr
          th.order-column #
          th.maintenance-column {{ $t('.tableHeader.maintenance.label') }}
          th.kilometrage-limit-column {{ $t('.tableHeader.kilometrageLimit.label') }}
          th.time-limit-column
            i.icon.fas.fa-calendar-minus(v-tooltip.bottom="$t('.tableHeader.timeLimit.tooltip')")
          th.target-execution-time-column
            i.icon.fas.fa-user-clock(v-tooltip.bottom="$t('.tableHeader.targetExecutionTime.tooltip')")
          th.maintenance-package-column
            i.icon.fas.fa-box
            app-span.maintenance-package {{ $t('.tableHeader.maintenancePackage.label') }}
          th.free-charge-column
            img.svg-icon.free-of-charge(v-tooltip.bottom="{ content: $t('.tableHeader.freeOfCharge.tooltip') }")
          th.forbidden-in-internal-facilities-column
            img.svg-icon.forbidden-in-internal-facilities(v-tooltip.bottom="{ content: $t('.tableHeader.forbiddenInInternalFacilities.tooltip') }")
          th.dealership-only-column
            img.svg-icon.dealership-only(v-tooltip.bottom="{ content: $t('.tableHeader.dealershipOnly.tooltip') }")
          th(v-if="showDestroyColumn").delete-column
            .flex.center
              i.diamond-icon.fas.fa-diamond

      tbody
        template(v-if="!loading && !error")
          slot

    template(v-if="loading")
      generic-row-loading.full-width(v-for="index in 4", :key="`loading-row-${index}`")

    template(v-else-if="error")
      .table-error
        slot(name="error")
          span {{ $t('.error') }}

    template(v-else-if="empty")
      slot(name="empty")
        .empty
          span {{ $t('.empty') }}

</template>


<script>

import freeOfChargeIcon from "@/assets/images/icons/free-of-charge/solid.svg"
import forbiddenInInternalFacilitiesIcon from "@/assets/images/icons/forbidden-in-internal-facilities/solid.svg"
import dealershipOnlyIcon from "@/assets/images/icons/dealership-only/solid.svg"

export default {
  props: {
    // flags
    error:             { type: Boolean, default: false },
    loading:           { type: Boolean, default: false },
    empty:             { type: Boolean, default: false },
    showDestroyColumn: { type: Boolean, default: true }
  },

  data() {
    return {
      i18nScope: "vehicle-model-groups.maintenance-tables.components.maintenances-table",

      freeOfChargeIcon,
      forbiddenInInternalFacilitiesIcon,
      dealershipOnlyIcon
    }
  },

  computed: {
    cssVars() {
      return {
        "--free-of-charge-icon":                   `url(${freeOfChargeIcon})`,
        "--forbidden-in-internal-facilities-icon": `url(${forbiddenInInternalFacilitiesIcon})`,
        "--dealership-only-icon":                  `url(${dealershipOnlyIcon})`
      }
    }
  }
}

</script>

<style lang="scss" scoped>

.app-button.action {
  color: $gray-2;
  font-size: 14px;
}

.header {

  .heading {
    font-size: 18px;
    font-weight: 500;
    height: 32px;
    line-height: 32px;
    font-family: $secondary-font;
  }

  .icon {
    color: $dark-gray;
    font-size: 20px;
  }
}

.icon {
  margin-right: 8px;
}

</style>


<template lang="pug">

  .unblock-supplier
    slot(name="link", :props="{ openUnblockModal: openModal }")
      app-button.app-button.action(
        theme="link",
        @click="openModal"
      )
        i.icon.far.fa-times-circle
        span {{ $t('.unblock') }}

    app-modal.unblock(
      v-if="showModal",
      :close-button="!submitting",
      :width="920",
      @close="closeModal",

      footer,
      :confirm-label="$t('.btn.submit.label')",
      :confirm-label-loading="$t('.btn.submit.loading')",
      @confirm="submit",
      :loading="submitting"
    )
      template(slot="header")
        header.header.flex.vertical-center
          i.icon.fas.fa-check-circle
          h1.heading {{ $t('.heading', { name }) }}

      .body
        span.text {{ $t('.message') }}

</template>


<script>

// Mixins
import { mixins } from "movida-common.vue"

const { FormMixin } = mixins

export default {
  mixins: [FormMixin],

  props: {
    id:   { type: [String, Number], required: true },
    name: { type: String, required: true }
  },

  data() {
    return {
      i18nScope: "suppliers.components.unblock-supplier",

      showModal: false
    }
  },

  methods: {
    openModal() {
      this.showModal = true
    },

    closeModal() {
      if (!this.submitting) {
        this.showModal = false
      }
    },

    submitRequest() {
      return this.$sdk.suppliers.unblock({ id: this.id })
    },

    async submitSuccess(response) {
      this.$notifications.info(this.$t(".notifications.submit.success", { name: this.name }))

      this.closeModal()
      this.$emit("unblocked")
    },

    async submitError(err) {
      this.$err.log(err)

      this.$notifications.error(this.$t(".notifications.submit.error"))
    }
  }
}

</script>

<style lang="scss" scoped>

.new.vehicle-withdrawal {
  .header {
    padding: 16px 24px;
    border-bottom: 1px solid $light-gray-3;
  }

  .body {
    padding: 16px 24px;
  }
}

</style>


<template lang="pug">

  app-modal.new.vehicle-withdrawal(
    footer,
    :confirm-label="$t('.btn.confirm.label')",
    :confirm-label-loading="$t('.btn.confirm.label')",
    :heading="$t('.heading')",
    :loading="submitting",
    :width="566",
    @close="onClose",
    @confirm="$submit"
  )
    .content
      .header
        template(v-if="loading")
          app-loading-lines(:lines="2", :min-width="60", :max-width="90", :height="30")

        template(v-else)
          service-order-info(
            :service-order="serviceOrder",
            :vehicle="serviceOrder.vehicle",
            :supplier="serviceOrder.supplier",
            :customer="serviceOrder.customer",
            :branch="serviceOrder.branch"
          )

      .body
        form.section(ref="form", method="post", @submit.prevent="$submit")
          form-fields(
            :errors="errors"
            :fetching="fetching",
            :resource="vehicleWithdrawal",
            :submitting="submitting",
            :terminate-all.sync="terminateAll"
          )

</template>


<script>

// Components
import FormFields       from "./_components/form-fields.vue"
import ServiceOrderInfo from "@/views/monitoring/_components/service-order-info.vue"

import $validate                     from "@/lib/validate"
import { setErrorsToHasManyIndexes } from "@/lib/parse-errors"

// Models
import models from "@/models"


export default {
  components: {
    FormFields,
    ServiceOrderInfo
  },

  emits: [
    "create",  // quando a criação do recurso é bem-sucedida
    "close"    // indica que o formulário pode ser fechado (útil para modal)
  ],

  mixins: [],

  props: {
    serviceOrder: { type: models.MovidaServiceOrder, required: true }
  },

  data() {
    return {
      i18nScope: "monitoring.vehicle-withdrawals.new",

      // flags
      initialized: false,
      fetching:    false,
      submitting:  false,

      vehicle:  null,
      resource: new models.Events.ServiceOrders.VehicleWithdrawal({
        at: moment().toISOString()
      }),

      terminateAll: false,

      errors: {}
    }
  },

  computed: {
    vehicleWithdrawal: {
      get()      { return this.resource  },
      set(value) { this.resource = value }
    },

    initializing() {
      return !this.initialized
    },

    loading() {
      return this.initializing || this.fetching || this.submitting
    }
  },

  created() {
    this.$initialize()
  },

  beforeDestroy() {
    if (this.loading) this.$cancel()
  },


  methods: {
    async $initialize() {
      await this.initialize()

      this.initialData = this.$serialize() // XXX: controla alteração de dados (#isDirty())
      this.initialized = true
    },

    async initialize() {
      this.$fetch()
    },

    async $cancel() {
      if (this.fetchPromise)  this.fetchPromise.cancel()
      if (this.submitPromise) this.submitPromise.cancel()

      await this.cancel()
    },

    async cancel() {},

    async $fetch() {
      this.fetching = true

      await this.fetch()

      this.fetching = false
    },

    async fetch() {},

    async validate() {
      let errors = await $validate(this.resource /* , nestedMap */)

      this.errors = errors
      return _.present(this.errors)
    },

    $serialize() {
      let attributes = this.resource.$serialize()

      attributes.terminate_all = this.terminateAll

      return attributes
    },

    async $submit() {
      if (this.submitting) {
        console.error("duplicate $submit")
        return
      }

      this.$notifications.clear()

      await this.validate()

      if (_.present(this.errors)) {
        this.$notifications.error(this.$t(".notifications.submit.error"))
        return
      }

      this.submitting = true

      await this.submit()

      this.submitting = false
    },

    async submit() {
      let params = this.$serialize()

      try {
        this.submitPromise = this.$newSdk.monitoring.serviceOrders.vehicleWithdrawal
          .create({
            serviceOrderId: this.serviceOrder.$id,
            params
          })

        // let response = await this.submitPromise
        await this.submitPromise

        this.$emit("create")

        let successMessage = this.$t(".notifications.submit.success", { id: this.serviceOrder.remoteId })
        this.$notifications.info(successMessage)

        this.$emit("close")
      }
      catch (err) {
        let error = err.error || err

        if (error.cancelled) return // XXX: cancelamento de requisição (axios)

        this.$err.log(error)
        this.$notifications.error(this.$t(".notifications.submit.error"))

        this.errors = {}
        this.errors.submit = [this.$t("errors.failure")]

        // transformando errors da requisição/servidor em erros no formulário
        if (error.originalError) {
          let errData = _.get(error.originalError, "response.data.errors") || {}
          let modelName = this.resource.constructor.name

          let errors = this.$i18n.errify(errData, { model: modelName })
          let errorsWithNested = setErrorsToHasManyIndexes(errors, this.resource)
          this.errors = _.merge(this.errors, errorsWithNested)
        }
      }
      finally {
        this.submitPromise = null
      }
    },

    // handler para evento de close do modal
    async onClose() {
      if (this.submitting) {
        this.$notifications.warn(this.$t("requests.running.warning"))
        return
      }

      if (!this.isDirty()) {
        this.$emit("close")
        return
      }

      // TODO: mover esses textos para outro namespace/chave (não mixins.form.confirmPageChange)
      let confirmed = await this.$confirm({
        heading: this.$t("mixins.form.confirmPageChange.heading"),
        message: this.$t("mixins.form.confirmPageChange.message"),

        actions: {
          confirm: this.$t("mixins.form.confirmPageChange.confirm"),
          cancel:  this.$t("mixins.form.confirmPageChange.cancel")
        }
      })

      if (confirmed) this.$emit("close")
    },

    isDirty() {
      let currentData = this.$serialize()
      return !_.isMatch(this.initialData, currentData)
    }
  }
}

</script>

<style scoped lang="scss">

.suppliers {
  padding-bottom: 24px;

  .header {
    margin-bottom: 32px;
    padding-bottom: 16px;
    border-bottom: 1px solid $light-gray-3;

    .title-icon {
      margin-right: 16px;
      font-size: 30px;
    }

    .title-text {
      font-size: 30px;
      font-weight: normal;
      font-weight: 500;
      font-family: $secondary-font;
    }
  }

  .clear-filters {
    position: relative;

    .filters-button {
      position: absolute;
      margin-top: 10px;
    }
  }

  .list {
    margin-top: 50px;

    .table {
      margin-top: 16px;
    }
  }

  .paginator-content {
    padding-top: 24px;

    &.loading {
      max-width: 400px;
      margin: 0 auto;
    }
  }

  .info {
    display: flex;
    align-items: center;
    margin-bottom: 30px;
    color: #555;

    i { margin-right: 20px; }
  }
}

</style>


<template lang="pug">

  .suppliers
    header.header.flex.space-between
      .title.flex.vertical-center
        i.title-icon.supplier-icon.fal.fa-wrench
        h1.title-text {{ $t('.model-groups.index.title') }}

    .info
      i.fas.fa-info-circle
      span {{ $t('.model-groups.index.description') }}

    search-form.search(
      :loading="fetching",
      :params="parsedQueryParams",
      @search="search"
      @associate="onAssociate"
      @disassociate="onDisassociate"
      @export="onExport"
    )

    .clear-filters
      filters-button.filters-button(
        v-if="hasFilters",
        @clear="clearFilters"
      )

    .list
      .flex.end.vertical-bottom
        results-indicator(
          :page="parsedQueryParams.page",
          :per-page="pagination.perPage",
          :total="pagination.total",
          :loading="fetching"
        )

      suppliers-table.table(
        :error="erred",
        :empty="empty",
        :initializing="initializing",
        :loading="fetching",
        :suppliers="suppliers",
        :sorted-by="parsedQueryParams.sort",
        @sort="onSortChange",
        @changed="fetch"
      )

    .paginator-content(v-if="fetching || showPaginator", :class="{ loading: fetching }")
      paginator(
        :loading="fetching",
        :per-page="pagination.perPage",
        :total="pagination.total",
        :value="parsedQueryParams.page",
        :disabled="fetching",
        @input="onPageChange",
        data-testid="paginator"
      )
    associate(
      :show="isShowingAssociate",
      :params="requestQueryParams", 
      @updated="fetch",
      @close="isShowingAssociate = false",
    )
    disassociate(
      :show="isShowingDisassociate",
      :params="requestQueryParams", 
      @updated="fetch",
      @close="isShowingDisassociate = false",
    )
    
</template>

<script>

// View
import IndexView from "@/views/index-view"

// Mixins
import FetchMixin from "@/mixins/fetch-mixin"

// Components
import SuppliersTable from "./index/suppliers-table"
import SearchForm from "./index/search-form"
import Associate from "./index/associate"
import Disassociate from "./index/disassociate"

export default {
  name: "ManageResourcesModelGroups",

  components: {
    SuppliersTable,
    SearchForm, 
    Associate, 
    Disassociate
  },

  extends: IndexView,

  mixins: [FetchMixin],

  data() {
    return {
      i18nScope: "manage-resources",
      routeName: "suppliers",

      resource: [],

      // Associate, desassociate
      isShowingAssociate: false,
      isShowingDisassociate: false,

      // @override IndexView
      clearFiltersIgnoreList: ["discarded"],

      // Query Mixin data
      queryParamsStructure: {
        sort:                 "string",
        page:                 "integer",
        blocked:              "boolean",
        q:                    "string", 
        document:             "string",
        city_id:              "string",
        classification:       "string",
        deal_state:           "string", 
        discarded:            "boolean",
        sector_id:            "string",
        service:             "string",
        model_group:          "string",
        associated:           "boolean",
      }
    }
  },

  computed: {
    suppliers: {
      get() { return this.resource },
      set(value) { this.resource = value }
    },

    empty() {
      return _.blank(this.suppliers)
    }
  },

  methods: {
    parseRoute() {},

    onDiscardedChange(discarded) {
      this.onParamChange("discarded", discarded)
    },

    // @override Fetchable mixin
    fetchRequest() {
      return this.$newSdk.suppliers.all({ params: this.requestQueryParams })
    },

    onAssociate() {
      this.isShowingAssociate = true
    }, 

    onDisassociate() {
      this.isShowingDisassociate = true
    }, 

    async onExport() {
      const { data, headers } = await this.$newSdk.suppliers.export({ params: this.requestQueryParams })

      // Cria um blob do objeto retornado
      const blob = new Blob([data], { type: headers['content-type']  + ';charset=utf-8'})

      // Cria um objeto URL para aplicar o retorno do CSV
      const url = URL.createObjectURL(blob)
      const link = document.createElement('a')
      link.href = url
      
      // Define o nome do arquivo com a data atual
      const date = new Date()
      link.download = `suppliers_${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}.csv`

      // Trigger click para iniciar o download
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    }
  }
}

</script>

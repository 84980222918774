import Base from "./base"

class DealDataLoad extends Base {
  static get attrs() {
    return _.uniq([
      ...super.attrs,
      "file",
      "fileName",
      "fileSize",
      "author",
      "replaceAll",
      "status",
      "mimeType",
      "dealId",
      "blobId",
      "log",
      "step"
    ])
  }

  static get enums() {
    return {
      status: {
        scheduled: "scheduled",
        running:   "running",
        done:      "done",
        error:     "error"
      },

      step: {
        read:  "read",
        parse: "parse",
        save:  "save"
      }
    }
  }

  static get constraints() {
    return {
      file:       { presence: true, type: "string" },
      replaceAll: { type: "boolean" }
    }
  }
}

export default DealDataLoad

import Base from "./base"

class IssuedMailDelivery extends Base {
  static get attrs() {
    return _.uniq([
      ...super.attrs,
      "status",
      "to",
      "sentAt"
    ])
  }

  static get enums() {
    return {
      status: {
        scheduled: "scheduled",
        running:   "running",
        done:      "done",
        error:     "error"
      }
    }
  }
}

export default IssuedMailDelivery

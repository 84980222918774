<style lang="scss" scoped>
.quick-filters {
  display: flex;
}
</style>

<template lang="pug">
  .quick-filters
    button-tooltip(
      :disabled="loading"
      :tooltip="stopsForTodayTooltip"
      @click="onClickStopsForToday"
    )
      template(#icon)
        i.fas.fa-car-garage
      span {{ $t(".stops-for-today.label") }}
    button-tooltip(
      :disabled="loading"
      :tooltip="noQuoteTooltip"
      @click="onClickNoQuote"
    )
      template(#icon)
        i.fas.fa-memo
      span {{ $t(".no-quote.label") }}
    button-tooltip(
      :disabled="loading"
      :tooltip="finishingTodayTooltip"
      @click="onClickFinishingToday"
    )
      template(#icon)
        i.fas.fa-square-check
      span {{ $t(".finishing-today.label") }}

</template>

<script>
import ButtonTooltip from "./quick-filter-button.vue"

export default {
  components: {
    ButtonTooltip
  },

  props: {
    loading: { type: Boolean, default: false }
  },

  data() {
    return {
      i18nScope: "monitoring.quick-filters"
    }
  },

  computed: {
    today() {
      return moment().format("L")
    },

    stopsForTodayTooltip() {
      return {
        title:       this.$t(".stops-for-today.tooltip.title"),
        description: this.$t(".stops-for-today.tooltip.description"),
        date:        this.today,
        filters:     {
          [this.$t(".filters.stops-from")]:
            this.$t(".filter-descriptions.greater-than-or-equal-date"),
          [this.$t(".filters.stops-until")]:
            this.$t(".filter-descriptions.lesser-than-or-equal-date"),
          [this.$t(".filters.state")]:
            this.$t(".filter-descriptions.open"),
          [this.$t(".filters.vehicle-stopped")]:
            this.$t(".filter-descriptions.no")
        }
      }
    },

    noQuoteTooltip() {
      return {
        title:       this.$t(".no-quote.tooltip.title"),
        description: this.$t(".no-quote.tooltip.description"),
        filters:     {
          [this.$t(".filters.quote-issued")]:
            this.$t(".filter-descriptions.no"),
          [this.$t(".filters.vehicle-stopped")]:
            this.$t(".filter-descriptions.yes")
        }
      }
    },

    finishingTodayTooltip() {
      return {
        title:       this.$t(".finishing-today.tooltip.title"),
        description: this.$t(".finishing-today.tooltip.description"),
        date:        this.today,
        filters:     {
          [this.$t(".filters.estimated-completed-from")]:
            this.$t(".filter-descriptions.greater-than-or-equal-date"),
          [this.$t(".filters.estimated-completed-until")]:
            this.$t(".filter-descriptions.lesser-than-or-equal-date"),
          [this.$t(".filters.state")]:
            this.$t(".filter-descriptions.open"),
          [this.$t(".filters.vehicle-withdrawed")]:
            this.$t(".filter-descriptions.no")
        }
      }
    }
  },

  methods: {
    onClickStopsForToday() {
      this.$emit("filter", {
        scheduled_from:  moment().startOf("day").toISOString(),
        scheduled_until: moment().endOf("day").toISOString(),
        state:           "open",
        vehicle_stopped: false
      })
    },

    onClickNoQuote() {
      this.$emit("filter", {
        quote_issued:    false,
        vehicle_stopped: true
      })
    },

    onClickFinishingToday() {
      this.$emit("filter", {
        estimated_completed_from:  moment().startOf("day").toISOString(),
        estimated_completed_until: moment().endOf("day").toISOString(),
        state:                     "ongoing",
        vehicle_withdrawed:        false
      })
    }
  }
}
</script>

<template lang="pug">
  attach-area-modal.service-orders-attach-area-modal(
    v-model="attachments"
    :modal-config="{ loading: loading }"
    @close="onClose"
    @confirm="onConfirm"
  )
</template>

<script>
export default {
  props: {
    serviceOrderId: { type: Number, required: true }
  },

  emits: ["close"],

  data() {
    return {
      attachments: [],

      loading: false,

      i18nScope: "monitoring.quotes.reviews.service-orders-attach-area-modal"
    }
  },

  computed: {
    sdk() {
      return this.$newSdk.monitoring.serviceOrders.attachments
    }
  },

  methods: {
    confirmClose() {
      return this.$confirm({
        heading: this.$t(".confirm-close-modal.heading"),
        message: this.$t(".confirm-close-modal.message"),

        actions: {
          confirm: this.$t(".confirm-close-modal.confirm"),
          cancel:  this.$t(".confirm-close-modal.cancel")
        }
      })
    },

    async onClose() {
      if (_.present(this.attachments) && !(await this.confirmClose())) return

      this.$emit("close")
    },

    onConfirm() {
      if (_.isEmpty(this.attachments)) return

      this.submit()
    },

    serialize() {
      return this.attachments.map((attachment) => attachment.$serialize())
    },

    async submit() {
      const data = this.serialize()

      try {
        await this.sdk.createBatch({
          serviceOrderId: this.serviceOrderId,
          params:         { attachments_attributes: data }
        })

        this.$notifications.info(this.$t(".notifications.submit.success"))

        this.$emit("close", true)
      }
      catch (error) {
        this.$err.log(error)
        this.$notifications.error(this.$t(".notifications.submit.error"))
      }
    }
  }
}
</script>

<style lang="scss">

// TODO: reformar! isso!
// está tentando sobrecarregar elementos do componente app-modal!
// não pode!
#attachments-carousel {
  .frame {
    background-color: transparent;
    box-shadow: unset;
  }

  .content {
    // Firefox, padrão
    scrollbar-width: none;
  }

  .content::-webkit-scrollbar {
    // Chrome
    width: 0;
    height: 0;
  }
}

</style>


<style lang="scss" scoped>

.attachments-carousel {
  .navigation {
    width: 48px;
    height: 48px;
    border-radius: 50%;
    background-color: $orange;
    color: $white;
    font-size: 16px;
    cursor: pointer;

    &.disabled {
      background-color: $light-gray-3;
      border: 1px solid $gray;
      color: $gray-3;
      cursor: not-allowed;
    }
  }

  .preview {
    background-color: $white;
    max-width: 1156px;
    border-radius: 8px;
    margin: 16px 32px 24px;
    height: 672px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);

    ::v-deep .preview-container,
    ::v-deep .data {
      height: 672px;
    }
  }

  .thumbnail {
    & + .thumbnail {
      margin-left: 16px;
    }
  }

  .pages {
    margin-top: 24px;

    .page {
      width: 23px;
      height: 24px;
      border-radius: 4px;
      background-color: $light-gray-3;
      font-family: $secondary-font;
      font-weight: 500;
      font-size: 14px;
      cursor: pointer;

      & + .page {
        margin-left: 16px;
      }

      &.selected {
        background-color: $light-orange;
        color: $orange;
      }
    }
  }
}

</style>


<template lang="pug">

  app-modal.modal(
    modal-id="attachments-carousel",
    :width="1348"
  )
    .attachments-carousel
      .flex.center.vertical-center
        .navigation.flex.center.vertical-center(
          :class="{ disabled: !hasPrevious }",
          @click="goToPrevious"
        )
          i.icon.far.fa-chevron-left

        attachment-preview-data.preview(
          ref="preview",
          :attachment="selectedAttachment",
          :editable="editable",
          :editing.sync="editing",
          :key="selectedAttachment.id",
          @close="$emit('close')",
          @download="onDownload"
        )

        .navigation.flex.center.vertical-center(
          :class="{ disabled: !hasNext }",
          @click="goToNext"
        )
          i.icon.far.fa-chevron-right

      .flex.center.vertical-center
        attachment-thumbnail.thumbnail(
          v-for="(attachment, index) in attachmentsInPage",
          :attachment="attachment",
          :key="`thumbnail-${attachment.id}`",
          :selected="attachment.id === selectedAttachment.id",
          @click.native="$emit('update:index', (currentPage - 1) * perPage + index )"
        )

      .pages.flex.center.vertical-center(v-if="pages > 1")
        template(v-for="page in pages")
          .page.flex.center.vertical-center(
            :class="{ selected: page === currentPage }",
            @click="currentPage = page"
          )
            span {{ page }}

</template>


<script>

export default {
  name: "AttachmentsCarousel",

  props: {
    attachments: { type: Array, required: true },
    index:       { type: Number, default: 0 },
    editable:    { type: Boolean, default: false },
    perPage:     { type: Number, default: 10 }
  },

  data() {
    return {
      i18nScope: "components.attachments.attachments-carousel",

      editing: false,

      currentPage: this.pageForIndex(this.index)
    }
  },

  computed: {
    length() {
      return this.attachments?.length
    },

    selectedAttachment() {
      return this.attachments?.[this.index]
    },

    hasNext() {
      return this.index < this.length - 1
    },

    hasPrevious() {
      return this.index > 0
    },

    pages() {
      return Math.ceil(this.length / this.perPage)
    },

    attachmentsInPage() {
      const start = (this.currentPage - 1) * this.perPage
      const end = this.currentPage * this.perPage

      return this.attachments.slice(start, end)
    }
  },

  methods: {
    beforeNavigation() {
      if (!this.editing) return true

      return this.$refs.preview.cancel()
    },

    pageForIndex(index) {
      return Math.floor(index / this.perPage) + 1
    },

    async goToNext() {
      if (!this.hasNext) return
      if (!await this.beforeNavigation()) return

      const newIndex = this.index + 1
      this.$emit("update:index", newIndex)
      this.currentPage = this.pageForIndex(newIndex)
    },

    async goToPrevious() {
      if (!this.hasPrevious) return
      if (!await this.beforeNavigation()) return

      const newIndex = this.index - 1
      this.$emit("update:index", newIndex)
      this.currentPage = this.pageForIndex(newIndex)
    },

    downloadRequest() {
      return this.$newSdk.blobs.getDownloadURL({ blobId: this.selectedAttachment.blobId, params: { disposition: "attachment" } })
    },

    async onDownload() {
      if (this.downloading) return

      this.downloading = true

      try {
        const { data: { url } } = await this.downloadRequest()

        const link = document.createElement("a")
        link.href = url
        link.setAttribute("download", this.selectedAttachment.fileName)

        document.body.appendChild(link)
        link.click()
        link.parentNode.removeChild(link)
      }
      catch (error) {
        console.error(error)
        this.$notifications.error(this.$t(".notifications.download.error", { name: this.selectedAttachment.name }))
      }
      finally {
        this.downloading = false
      }
    }
  }
}

</script>

<style scoped lang="scss">

.sidebar-icon {
  width: 24px;
  height: 24px;
  font-size: 16px;

  .custom-icon {
    mask-size: cover;
    background-color: $gray-3;

    &.stores {
      width: 24px;
      height: 24px;
    }

    &.vehicle-parts{
      width: 16px;
      height: 16px;
    }
  }
}

</style>


<template lang="pug">

  .sidebar-icon.flex.center.no-shrink.vertical-center(v-tooltip="tooltip")
    .custom-icon(v-if="custom", :style="customStyle", :class="iconClass")
    i(v-else, :class="iconClass")

</template>


<script>

// Icons
import stores from "@/assets/images/icons/sidebar/stores-icon.svg"
import storesSolid from "@/assets/images/icons/sidebar/stores-icon-solid.svg"
import vehiclePart from "@/assets/images/icons/sidebar/vehicle-part.svg"
import vehiclePartSolid from "@/assets/images/icons/sidebar/vehicle-part-icon-solid.svg"

export default {
  name: "SidebarIcon",

  props: {
    icon:    { type: String, default: null },
    active:  { type: Boolean, default: false },
    custom:  { type: Boolean, default: false },
    tooltip: { type: String, default: null }
  },

  computed: {
    file() {
      switch (this.icon) {
        case "stores": return this.active ? storesSolid : stores
        case "vehicle-parts": return this.active ? vehiclePartSolid : vehiclePart
        default: return null
      }
    },

    iconClass() {
      let type = this.active ? "fas" : "fal"

      return {
        [this.icon]: true,
        [type]:      true
      }
    },

    customStyle() {
      return {
        "mask-image": `url(${this.file})`
      }
    }
  }
}

</script>

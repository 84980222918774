<script>

// Extends
import NewDataLoad from "@/views/suppliers/deals/products/data-loads/new"

export default {
  name: "NewSupplierDealMaintenancePackagesDataLoad",

  extends: NewDataLoad,

  data() {
    return {
      i18nScope: "suppliers.deals.maintenance-packages.data-loads.new"
    }
  },

  computed: {
    backRoute() {
      return {
        name:   "supplierDealMaintenancePackages",
        params: { id: this.id, dealId: this.dealId },
        query:  this.backRouteQuery
      }
    }
  },

  methods: {
    // @override Form mixin
    submitRequest() {
      return this.$sdk.deals.createMaintenancePackagesDataLoad({ id: this.dealId, params: this.resource.$serialize() })
    },

    // @override Form mixin
    submitSuccess({ data }) {
      this.$notifications.info(this.$t(".notifications.submit.success"))

      this.$router.push({
        name:   "supplierDealMaintenancePackagesDataLoad",
        params: { id: this.id, dealId: this.dealId, dataLoadId: data.id },
        // XXX: Flag para identificar a origem da navegação
        query:  { new: true }
      })
    }
  }
}

</script>

<!-- <doc lang="markdown">
  Money field

  # Usage:

  Basic
  ----
  ```pug
  money-field(
    name="interaction[investment_amount]",
    v-model="interaction.investmentAmount",
  )
  ```
</doc> -->


<style scoped lang="scss">

.money-field {
  .label-content {
    margin-bottom: 8px;
    font-size: 14px;
    line-height: 17px;
    font-weight: 500;
    font-family: $secondary-font;
    color: $dark-gray;
    transition: color 0.1s cubic-bezier(.645,.045,.355,1);

    > * + * {
      padding-left: 4px;
    }

    &.focus {
      color: $orange;
    }

    &.error {
      color: $red;
    }

    .mandatory-icon {
      font-size: 4px;
      color: $orange;
    }

    .info-icon {
      font-size: 14px;
      cursor: pointer;
    }
  }

  .input-wrapper {
    position: relative;
    font-size: 16px;
    display: inline-block;
    width: 100%;

    .input-inner {
      font-family: $primary-monospace-font;
      appearance: none;
      background-color: $white;
      background-image: none;
      border-radius: 4px;
      border: 1px solid $gray;
      color: $gray-3;
      display: inline-block;
      font-size: inherit;
      height: 40px;
      line-height: 40px;
      outline: none;
      padding: 1px 13px;
      width: 100%;

      text-align: right;

      &:hover {
        border-color: $orange;
      }

      &:focus {
        outline: none;
        border-color: $orange;
        border-width: 2px;
        padding: 0 12px;
      }

      &::placeholder {
        font-family: $primary-monospace-font;
        font-weight: 300;
        color: $gray-2;
        opacity: 1;
      }

      &.disabled {
        background-color: $light-gray-3;
        border-color: $light-gray-4;
        color: $gray-2;
        cursor: not-allowed;
      }

      &.loading {
        background-color: $light-gray-3;
        border-color: $light-gray-4;
        color: $gray-3;
        cursor: progress;
      }

      &.readonly {
        background-color: $light-gray-2;
        border-width: 0;
        color: $dark-gray;
        cursor: unset;
      }

      &.error {
        border-color: $red;
      }
    }

    .suffix {
      font-family: $secondary-font;
      font-weight: 400;
      font-size: 14px;
      position: absolute;
      top: 12px;
      right: 0;
      display: block;
      padding: 0 12px;
      user-select: none;
      pointer-events: none;
    }
  }

  .error-message {
    display: block;
    color: $red;
    font-size: 12px;
    line-height: 1;
    padding-top: 4px;
    text-align: right;
  }
}

</style>


<template lang="pug">

  .money-field
    label.label-content.flex.vertical-center(
      v-if="!hideLabel",
      :class="{ error: hasErrors, focus }",
      :for="inputId",
    )
      span {{ labelText }}
      template(v-if="optional")
        span {{ $t('form.optional') }}

      template(v-else-if="mandatory")
        i.mandatory-icon.fas.fa-circle(v-tooltip.top="$t('form.mandatory.tooltip')")

      template(v-if="tooltip")
        i.info-icon.far.fa-info-circle(v-tooltip="tooltip")

    .input-wrapper
      input.input-inner(
        v-model="maskedValue",
        autocomplete="off",
        ref="input",
        :autofocus="autofocus",
        :class="{ loading, disabled, readonly, error: hasErrors }",
        :disabled="disabled || loading || readonly",
        :id="inputId",
        :maxlength="maxlength",
        :name="name",
        :placeholder="placeholder",
        :readonly="readonly",
        :style="inputInnerStyle",
        @blur="onBlur",
        @focus="onFocus",
        @keydown="changeInput",
        @paste="paste"
      )

      template(v-if="showCurrency")
        span.suffix(ref="suffix") {{ $t('number.currency.format.unit') }}

    template(v-if="hasMessageErrors")
      span.error-message {{ errors[0] }}

</template>


<script>

import Vue from "vue"

// Modules
import { i18n } from "utils.vue"

// Components
import { components } from "movida-common.vue"


export default {
  name: "MoneyField",

  extends: components.InputField,

  props: {
    value:        { type: Number, default: null },
    suffix:       { type: String, default: null },
    showCurrency: { type: Boolean, default: true },
    mandatory:    { type: Boolean, default: false },
    maxValue:     { type: Number, default: 1000000000 },
    tooltip:      { type: [String, Object], default: null },

    // @override Field Mixin
    placeholder: { type: String, default: () => i18n.t("components.core.form.money-field.placeholder") }
  },

  data() {
    return {
      i18nScope: "components.core.form.money-field",

      noDecimals: false,
      oneDecimal: false,

      isMounted: false
    }
  },

  computed: {
    maxlength() {
      return this.getMaskedValue(this.maxValue).length
    },

    inputInnerStyle() {
      if (!this.isMounted) return {}

      const style = {}

      if (this.$refs.suffix) {
        style.paddingRight = `${this.$refs.suffix.offsetWidth}px`
      }

      return style
    },

    delimiter() {
      return this.$t("number.currency.format.delimiter")
    },

    separator() {
      return this.$t("number.currency.format.separator")
    },

    maskedValue: {
      get() {
        if (_.blank(this.value)) return null

        return this.getMaskedValue(+this.value)
      },

      set(newVal) {
        let value = null
        let amount = newVal

        if (amount) {
          let delimiterRegex = new RegExp(`\\${this.delimiter}`, "g")
          let separatorRegex = new RegExp(`\\${this.separator}`, "g")

          value = amount.replace(delimiterRegex, "")
            .replace(separatorRegex, "")

          if (this.noDecimals) {
            value = `${value}00`
            this.noDecimals = false
          }
          else if (this.oneDecimal) {
            value = `${value}0`
            this.oneDecimal = false
          }

          if (value.length === 1) value = `0.0${value}`
          else if (value.length === 2) value = `0.${value}`
          else value = `${value.slice(0, value.length - 2)}.${value.slice(value.length - 2, value.length)}`

          value = Math.min(Number(value), this.maxValue)
        }

        this.$emit("input", value)
      }
    }
  },

  // Removendo prop 'maxlength' que está sendo reescrita como _computed_
  beforeCreate() {
    Vue.delete(this.$options.props, "maxlength")
  },

  mounted() {
    this.isMounted = true
  },

  methods: {
    getMaskedValue(value) {
      if (_.blank(value)) return null

      let maskedValue = value.toFixed(2).replace(".", this.separator)

      if (maskedValue.length > 6) {
        let integer = [...maskedValue].splice(0, maskedValue.length - 3)
        let maskedInteger = ""
        let lastIndex = integer.length - 1

        for (let i = lastIndex; i >= 0; i -= 1) {
          if (i === lastIndex) maskedInteger = integer[i]
          else if ((lastIndex - i) % 3 === 0) maskedInteger = `${integer[i]}${this.delimiter}${maskedInteger}`
          else maskedInteger = `${integer[i]}${maskedInteger}`
        }

        let cents = maskedValue.substring(maskedValue.length - 2, maskedValue.length)
        maskedValue = `${maskedInteger}${this.separator}${cents}`
      }

      return maskedValue
    },

    onFocus() {
      this.focus = true
      this.$emit("focus")
    },

    onBlur() {
      this.focus = false
      this.$emit("blur")
    },

    paste(event) {
      this.oneDecimal = false
      this.noDecimals = false

      let paste = (event.clipboardData || window.clipboardData).getData("text")
      let regex = new RegExp(/[^0-9.,]/)

      if (regex.test(paste)) {
        this.$notifications.error(this.$t(".notifications.invalidPaste", { value: paste }))
        event.preventDefault()
      }

      let centsRegex = new RegExp(/[,.]\d{2}$/)
      let oneDecimalRegex = new RegExp(/[,.]\d{1}$/)

      if (oneDecimalRegex.test(paste)) this.oneDecimal = true
      else if (!centsRegex.test(paste)) this.noDecimals = true
    },

    changeInput(event) {
      if (event.altKey || event.ctrlKey || event.metaKey) return

      if (/^[^0-9]$/.test(event.key)) event.preventDefault()
    }
  }
}

</script>

<style scoped>
.body {
  min-height: 200px;
  padding: 16px 24px;
}
</style>

<template lang="pug">
  app-modal.attach-area-modal(
    v-bind="modalConfig"
    :confirmLabel="$t('.confirm')"
    footer
    :heading="$t('.heading')"
    :width="716"
    @close="onClose"
    @confirm="onConfirm"
  )
    .body
      //- XXX: usando type="quote" pois é isso que define o endpoint de criação de blob (e também
      //- de url de download)
      //- como esse funcionamento não permite download, não é um problema agora.
      //- TODO: reformar, para ter endpoint de downloadUrl para monitoring.serviceOrders.blobs também!
      //- - é preciso estender o src/components/attach/attach-action.js para permitir isso!
      attach-area(
        v-bind="attachAreaConfig"
        list-layout="col-3"
        :value="value"
        @change="onChange",
        type="quote"
      )
</template>

<script>
export default {
  name: "AttachAreaModal",

  model: {
    event: "change"
  },

  props: {
    attachAreaConfig: { type: Object, default: null },
    modalConfig:      { type: Object, default: null },

    value: { type: Array, default: () => [] }
  },

  emits: ["change", "close", "confirm"],

  data() {
    return {
      i18nScope: "components.attach.attach-area-modal"
    }
  },

  methods: {
    onChange(value) {
      this.$emit("change", value)
    },

    onClose() {
      this.$emit("close")
    },

    onConfirm() {
      this.$emit("confirm")
    }
  }
}
</script>

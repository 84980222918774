<style lang="scss" scoped>

.service-support-card {
  width: 231px;
  height: 145px;
  border: 1px solid $light-gray-3;
  border-radius: 4px;
  padding: 24px;

  &.active {
    border-color: $dark-green;
  }

  .top {
    margin-bottom: 24px;

    ::v-deep .menu-button {
      height: 24px;
      padding: 4px;
    }

    .status-loading {
      width: 74px;
    }

    .action-loading {
      width: 24px;
    }
  }

  .bottom {
    .service-type-icon {
      width: 40px;
      height: 40px;
      margin-right: 16px;
    }

    .service-name {
      font-family: $secondary-font;
      font-weight: 500;
      font-size: 20px;
      overflow-wrap: anywhere;
    }
  }
}

</style>


<template lang="pug">

  .service-support-card.no-shrink.flex.column-direction(
    :class="{ active: isActive }",
    data-testid="serviceSupportCard"
  )
    .top.flex.space-between
      template(v-if="loading")
        loading-lines.status-loading(
          :height="24",
          :min="100",
          :max="100"
        )
      template(v-else)
        supplier-status-tag(:value="isActive")

      template(v-if="loading")
        loading-lines.action-loading(
          :height="24",
          :min="100",
          :max="100"
        )
      template(v-else)
        popover-menu.action-menu(
          v-if="$can('update', 'Store')"
          :show.sync="showPopover",
          data-testid="popoverMenu"
        )
          template(v-if="isActive")
            popover-menu-button(
              v-if="$can('update', 'Store')"
              icon="fal fa-minus-circle",
              :text="$t('.actions.btn.destroy.label')",
              @click="deactivate",
              @close-menu="showPopover = false",
              data-testid="deactivateButton"
            )

          template(v-else)
            popover-menu-button(
              v-if="$can('update', 'Store')"
              icon="fal fa-check",
              :text="$t('.actions.btn.activate.label')",
              @click="activate",
              @close-menu="showPopover = false",
              data-testid="activateButton"
            )

    .bottom.flex.grow
      template(v-if="loading")
        loading-lines.name-loading.full-width(
          :height="48",
          :min="100",
          :max="100"
        )
      template(v-else)
        service-type-icon.service-type-icon(
          :type="serviceSupport.serviceType"
        )
        .service-name
          span {{ name }}

</template>


<script>

// Models
import models from "@/models"

const { ServiceSupport } = models

export default {
  props: {
    fetching:       { type: Boolean, default: null },
    serviceSupport: { type: Object, required: true }
  },

  data() {
    return {
      i18nScope: "stores.service-supports.index.service-support-card",

      storeId: this.$route.params.id,

      submitting: false,

      showPopover: false
    }
  },

  computed: {
    loading() {
      return this.fetching || this.submitting
    },

    name() {
      if (_.blank(this.serviceSupport.serviceType)) return null

      return ServiceSupport.$tEnum("serviceType", this.serviceSupport.serviceType)
    },

    isActive() {
      return _.present(this.serviceSupport.id)
    }
  },

  methods: {
    async activate() {
      const params = { service_type: this.serviceSupport.serviceType }
      const request = this.$sdk.stores.createServiceSupport({ id: this.storeId, params })

      try {
        await this.submit(request)
        this.$notifications.info(this.$t(".notifications.submit.create.success", { name: this.name }))
      }
      catch (error) {
        this.$err.log(error)
        this.$notifications.error(this.$t(".notifications.submit.create.error"))
      }
    },

    async deactivate() {
      const request = this.$sdk.stores.destroyServiceSupport({
        id:              this.storeId,
        serviceTypeSlug: this.serviceSupport.typeAsParam
      })

      try {
        await this.submit(request)
        this.$notifications.info(this.$t(".notifications.submit.destroy.success", { name: this.name }))
      }
      catch (error) {
        this.$err.log(error)
        this.$notifications.error(this.$t(".notifications.submit.destroy.error"))
      }
    },

    async submit(request) {
      this.submitting = true

      this.$notifications.clear()

      try {
        await request
        this.$emit("change")
      }
      finally {
        this.submitting = false
      }
    }
  }
}

</script>

<style scoped lang="scss">

.section {
  padding: 16px 32px;

  + .section {
    border-top: 1px solid $light-gray-3;
  }

  &.loading {
    min-height: 155px;
  }
}

.global-price {
  margin-right: 24px;
  height: 40px;
  background-color: $light-gray-2;
}

.icon {
  margin-left: 12px;
  margin-right: 16px;
  font-size: 20px;
  color: $gray-2;
}

.text {
  font-family: $secondary-font;
  font-size: 16px;
  color: $dark-gray;
  font-weight: normal;
}

.origin-radio {
  width: 667px;
  margin-top: 16px;

  ::v-deep .movida-common__radio-label {
    font-weight: 400;
    font-size: 14px;
  }
}

</style>


<template lang="pug">

  app-modal(
    :width="996",
    :heading="$t('.title.label')",
    footer,
    :confirm-label="$t('.btn.update.label')",
    :confirm-button="editable",
    @close="goBack",
    @confirm="submit"
  )
    form.form(ref="form", method="post", @submit.prevent="submit")
      template(v-if="fetching")
        section.section.loading
          loading-lines(:lines="4")

      template(v-else)
        section.section
          product-header(
            :product="resource.product",
            :vehicle-model-group="resource.vehicleModelGroup",
            :fetching="fetching"
          )

          template(v-if="isPart")
            radio-field.origin-radio(
              name="dealProduct[partOrigin]",
              :options="DealProduct.originOptions",
              :loading="submitting",
              v-model="resource.partOrigin",
              :errors="errors.partOrigin",
              data-testid="optionsProductType"
              hide-label
            )

        section.section
          ui-row
            ui-col(:span="8")
              .global-price.flex.vertical-center
                i.icon.fal.fa-globe
                span.text {{ $t('.price.title.label') }}
            ui-col(:span="4")
              money-field(
                name="dealProduct[price]",
                v-model="resource.price",
                :loading="submitting",
                :label="$t('.fields.price.label')",
                :placeholder="$t('.fields.price.placeholder')",
                :errors="errors.price"
                hide-label,
                data-testid="priceInput"
              )

</template>


<script>

// Mixins
import { mixins } from "movida-common.vue"

// Models
import models from "@/models"

// Extends
import View from "@/views/view"

// Local Components
import PartOriginSelectField from "../components/part-origin-select-field"
import ProductHeader from "./components/product-header"

const { FormMixin, Fetchable } = mixins
const { DealProduct } = models

export default {
  name: "EditSupplierDealProduct",

  components: {
    PartOriginSelectField,
    ProductHeader
  },

  extends: View,

  mixins: [FormMixin, Fetchable],

  props: {
    backRouteQuery: { type: Object, default: () => ({}) },
    editable:       { type: Boolean, default: false }
  },

  data() {
    return {
      i18nScope: "suppliers.deals.products.edit",

      id:            this.$params.asInteger(this.$route.params.id),
      dealId:        this.$params.asInteger(this.$route.params.dealId),
      dealProductId: this.$params.asInteger(this.$route.params.dealProductId),

      model:    "DealProduct",
      resource: {},

      DealProduct
    }
  },

  computed: {
    backRoute() {
      return {
        name:   "supplierDealProducts",
        params: { id: this.id, dealId: this.dealId },
        query:  this.backRouteQuery
      }
    },

    isPart() {
      return _.get(this.resource, "product.type") === "Part"
    }
  },

  created() {
    if (!this.editable) {
      this.$router.replace({
        name:   "supplierDealProduct",
        params: { id: this.id, dealId: this.dealId, dealProductId: this.dealProductId }
      })
    }
  },

  methods: {
    // @override extends view.vue
    goBack() {
      // Voltando para rota pai ao fechar o modal
      if (_.get(this.$router.referrer, "name") === this.backRoute.name) {
        this.$goBack({ fallback: this.backRoute })
      }
      else {
        this.$router.push(this.backRoute)
      }
    },

    fetch() {
      return this.$sdk.deals.getProduct({
        id:        this.dealId,
        productId: this.dealProductId,
        params:    this.httpParams
      })
    },

    // @override Fetchable mixin
    onFetchError(err) {
      // Dealing with appError from extension view.vue
      if (this.hasViewError(err)) this.appError = err

      this.$err.log(err)
      this.$notifications.error(this.$t(".notifications.fetch.failure"))
    },

    // @override Form mixin
    submitRequest() {
      const params = this.resource.$serialize()
      return this.$sdk.deals.updateProduct({ id: this.dealId, productId: this.dealProductId, params })
    },

    // @override Form mixin
    submitSuccess(response) {
      this.$notifications.info(this.$t(".notifications.submit.success", { name: _.get(this.resource, "product.name") }))

      this.$emit("change")
      this.goBack()
    },

    // @override Form mixin
    submitError(err) {
      this.$err.log(err)
      this.$notifications.error(this.$t(".notifications.submit.error"))
    }
  }
}

</script>

<style lang="scss" scoped>

.index {
  .loading,
  .erred {
    padding: 24px;
  }

  .count {
    padding: 4px 16px;
    font-weight: 400;
    font-size: 12px;
    background-color: $light-gray;
    border-bottom: 1px solid $light-gray-3;
  }
}

</style>


<template lang="pug">

  .index
    template(v-if="fetching")
      .loading
        loading-lines(:lines="3")

    template(v-else-if="erred")
      .erred.flex.column-direction.vertical-center
        span {{ $t('.fetch-error.message') }}
        app-button.retry(theme="link", @click="fetch") {{ $t('.fetch-error.retry') }}

    template(v-else)
      .count.text-right
        span {{ $t('.count', { count: resource.length }) }}

      template(v-for="message in messages")
        message-row(
          :message="message",
          :selected="message.id === selectedMessageId",
          @click.native="$emit('select', message)",
          @keydown.native.enter.prevent="$emit('select', message)",
          @keydown.native.space.prevent="$emit('select', message)"
        )

</template>


<script>

// Mixins
import FetchMixin from "@/mixins/fetch-mixin"

// Components
import MessageRow from "./_components/message-row.vue"


export default {
  components: {
    MessageRow
  },

  mixins: [FetchMixin],

  props: {
    serviceOrderId:    { type: [Number, String], required: true },
    selectedMessageId: { type: [Number, String], default: null }
  },

  data() {
    return {
      i18nScope: "monitoring.messages.index",

      resource: null
    }
  },

  computed: {
    messages: {
      get()      { return this.resource  },
      set(value) { this.resource = value }
    },

    empty() {
      return _.blank(this.messages)
    }
  },

  methods: {
    // @override Fetch mixin
    fetchRequest() {
      return this.$newSdk.monitoring.serviceOrders.messages
        .all({ serviceOrderId: this.serviceOrderId })
    },

    // @override Fetch mixin
    afterFetchSuccess() {
      let firstMessage = _.blank(this.messages) ? null : this.messages[0]

      this.$emit("select", firstMessage)
    }
  }
}

</script>

<style scoped lang="scss">

.sidebar {
  $sidebar-width: 76px;
  width: $sidebar-width;
  min-width: $sidebar-width;
  white-space: nowrap;
  background-color: $light-gray;
  overflow-y: auto;
  overflow-x: hidden;
  border-right: 1px solid $light-gray-4;
  padding: 20px 0;
  transition: width .1s ease-in-out, min-width .1s ease-in-out;

  &.expanded {
    $sidebar-width: 236px;
    width: $sidebar-width;
    min-width: $sidebar-width;
  }

  .control,
  .separator-icon {
    height: 40px;
  }

  .control{
    cursor: pointer;
    color: $gray-2;

    &:hover {
      color: $orange;

      .gmf {
        ::v-deep .inside {
          fill: $orange;
        }
      }
    }

    .collapse-icon {
      margin: 0 8px 0 28px;
    }

    .gmf {
      width: 38px;

      ::v-deep .inside {
        fill: $gray-3;
      }
    }
  }

  .separator-icon {
    .icon-container {
      width: 24px;
      height: 24px;
      margin: 0 8px 0 28px;

      .icon {
        font-size: 12px;
        color: $light-gray-4;
      }
    }
  }

  .sections {
    height: 100%;

    .separator {
      font-weight: 700;
      font-size: 10px;
      text-transform: uppercase;
      color: $gray-2;
      margin-left: 28px;
      height: 40px;
    }
  }
}

</style>


<template lang="pug">

  aside.sidebar(:class="{ expanded }")
    .sections.flex.space-between.column-direction
      .top
        .control.flex.vertical-center(@click="toggleExpanded")
          template(v-if="expanded")
            sidebar-icon.collapse-icon(icon="fa-arrow-to-left")
            gmf-icon.gmf.no-shrink

          template(v-else)
            sidebar-icon.collapse-icon(icon="fa-arrow-to-right")

        sidebar-item(
          v-if="$can('index', 'MovidaServiceOrder')",
          icon="fa-desktop",
          :active="['monitoring']",
          :expanded="expanded",
          :label="$t('.sections.monitoring')",
          :to="{ name: 'monitoring' }"
        )

        sidebar-item(
          v-if="$can('index', 'Supplier')",
          icon="fa-user-hard-hat",
          :active="['suppliers']",
          :expanded="expanded",
          :label="$t('.sections.suppliers')",
          :to="{ name: 'suppliers' }"
        )

        sidebar-item(
          v-if="$can('index', 'Vehicle')",
          icon="fa-car",
          :active="['vehicles']",
          :expanded="expanded",
          :label="$t(`.sections.vehicles`)",
          :to="{ name: 'vehicles' }"
        )

        sidebar-item(
          v-if="$can('index', 'Store')",
          custom,
          icon="stores",
          :active="['stores']",
          :expanded="expanded",
          :label="$t('.sections.stores')",
          :to="{ name: 'stores' }"
        )

        sidebar-item(
          v-if="$can('index', 'Ticket') || $can('index', 'ServiceOrder')",
          icon="fa-user-headset",
          :active="['tickets', 'service-orders']",
          :expanded="expanded",
          :label="$t('.sections.tickets')",
          :to="{ name: 'tickets' }"
        )

        sidebar-item(
          v-if="$can('index', 'Product')",
          icon="fa-cube",
          :active="['products']",
          :expanded="expanded",
          :label="$t(`.sections.products`)",
          :to="{ name: 'products' }"
        )

        sidebar-item(
          v-if="$can('index', 'VehicleModel')",
          icon="fa-car",
          :active="['vehicle-models']",
          :expanded="expanded",
          :label="$t(`.sections.vehicleModels`)",
          :to="{ name: 'vehicleModels' }"
        )

        sidebar-item(
          v-if="$can('index', 'VehicleModelGroup')",
          icon="fa-cars",
          :active="['vehicle-model-groups']",
          :expanded="expanded",
          :label="$t(`.sections.vehicleModelGroups`)",
          :to="{ name: 'vehicleModelGroups' }"
        )

        template(v-if="showMaintenanceSection")
          template(v-if="expanded")
            span.separator.flex.vertical-center(v-if="showMaintenanceSection") {{ $t('.separators.maintenance') }}

          template(v-else)
            .separator-icon.flex.vertical-center
              .icon-container.flex.vertical-center.center
                i.icon.fas.fa-car-wrench(v-tooltip="$t('.separators.maintenance')")

        sidebar-item(
          v-if="$can('index', 'MaintenancePackage')",
          icon="fa-box",
          :active="['maintenance-packages']",
          :expanded="expanded",
          :label="$t(`.sections.maintenancePackages`)",
          :to="{ name: 'maintenancePackages' }"
        )

        template(v-if="showServicesSection")
          template(v-if="expanded")
            span.separator.flex.vertical-center(v-if="showServicesSection") {{ $t('.separators.services') }}

          template(v-else)
            .separator-icon.flex.vertical-center
              .icon-container.flex.vertical-center.center
                i.icon.fas.fa-wrench(v-tooltip="$t('.separators.services')")

        sidebar-item(
          v-if="$can('index', 'VehiclePart')",
          custom,
          icon="vehicle-parts",
          :active="['vehicle-parts']",
          :expanded="expanded",
          :label="$t(`.sections.diagnosticTree`)",
          :to="{ name: 'vehicleParts' }"
        )

        sidebar-item(
          v-if="$can('index', 'Service')",
          icon="fa-folder-tree",
          :active="['services']",
          :expanded="expanded",
          :label="$t(`.sections.services`)",
          :to="{ name: 'services' }"
        )

        sidebar-item(
          v-if="$can('index', 'ServicePackage')",
          icon="fa-box-alt",
          :active="['service-packages']",
          :expanded="expanded",
          :label="$t(`.sections.servicePackages`)",
          :to="{ name: 'servicePackages' }"
        )

        sidebar-item(
          v-if="$can('index', 'TicketType')",
          icon="fa-headset",
          :active="['ticket-types']",
          :expanded="expanded",
          :label="$t(`.sections.ticketTypes`)",
          :to="{ name: 'ticketTypes' }"
        )

        sidebar-item(
          v-if="$can('index', 'ManageResources')",
          icon="fa-wrench",
          :active="['manage-resources']",
          :expanded="expanded",
          :label="$t(`.sections.manageResources`)",
          :to="{ name: 'manageResources' }"
        )

      .bottom(v-if="['Settings', 'SupplierServiceScoreSettings'].every(model => $can('read', model))")
        sidebar-item(
          icon="fa-cog",
          :active="['settings']",
          :expanded="expanded",
          :label="$t(`.sections.settings`)",
          :to="{ name: 'settings' }"
        )

</template>


<script>

// Libs
import { store } from "utils.vue"

// Components
import SidebarItem from "./sidebar/sidebar-item"

export default {
  name: "Sidebar",

  components: {
    SidebarItem
  },

  data() {
    return {
      i18nScope: "components.sidebar",

      expanded: this.getExpanded()
    }
  },

  computed: {
    showMaintenanceSection() {
      return this.$can("index", "MaintenancePackage")
    },

    showServicesSection() {
      return ["VehiclePart", "Service", "ServicePackage", "TicketType"]
        .some(resource => this.$can("index", resource))
    }
  },

  methods: {
    getExpanded() {
      const value = store.get("sidebarExpanded")

      if (_.present(value)) return value

      store.set("sidebarExpanded", true)
      return true
    },

    toggleExpanded() {
      const value = !this.getExpanded()

      store.set("sidebarExpanded", value)
      this.expanded = value
    }
  }
}

</script>

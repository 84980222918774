<style lang="scss" scoped>

.tickets-header {
  .header {
    margin-bottom: 32px;

    .title-icon {
      margin-right: 16px;
      font-size: 30px;
    }

    .title-text {
      font-size: 30px;
      font-weight: normal;
      font-weight: 500;
      font-family: $secondary-font;
    }
  }
}

</style>


<template lang="pug">

  .tickets-header
    header.header
      .flex.space-between.vertical-center
        .title.flex.vertical-center
          i.title-icon.fal.fa-user-headset
          h1.title-text {{ $t('.title') }}

        app-button(
          v-if="$can('create', 'Ticket')",
          :to="{ name: 'newDraftTicket' }",
          data-testid="newDraftTicket"
        ) {{ $t('.btn.new') }}

      .g-tab-navbar-content
        router-tab(
          v-if="$can('index', 'Ticket')",
          :to="{ name: 'tickets' }",
          data-testid="tickets"
        ) {{ $t('.tab.tickets') }}

        router-tab(
          v-if="$can('index', 'ServiceOrder')",
          :to="{ name: 'serviceOrders' }",
          data-testid="serviceOrders"
        ) {{ $t('.tab.serviceOrders') }}

</template>


<script>

export default {
  name: "TicketsHeader",

  data() {
    return {
      i18nScope: "components.view-context.tickets-header"
    }
  }
}

</script>

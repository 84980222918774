// Ability
import { ability } from "@/modules/ability"

// Routes
import EditStoreDefaultTab from "@/views/stores/default-tab/edit.vue"
import EditStoreSettings from "@/views/stores/settings/edit.vue"
import NewStoreSupplier from "@/views/stores/suppliers/new.vue"
import Store from "@/views/stores/show.vue"
import StoreDefaultTab from "@/views/stores/default-tab/show.vue"
import Stores from "@/views/stores/index.vue"
import StoreServiceOrder from "@/views/stores/tickets/service-orders/show.vue"
import StoreServiceOrders from "@/views/stores/service-orders/index.vue"
import StoreServiceSupports from "@/views/stores/service-supports/index.vue"
import StoreSettings from "@/views/stores/settings/show.vue"
import StoreSuppliers from "@/views/stores/suppliers/index.vue"
import StoreSuppliersBase from "@/views/stores/suppliers/base.vue"
import StoreTicket from "@/views/stores/tickets/show.vue"
import StoreTickets from "@/views/stores/tickets/index.vue"

export default [
  {
    path:      "/stores/:id",
    component: Store,
    meta:      {
      auth:        true,
      permissions: [() => ability.can("read", "Store")]
    },

    children: [
      {
        path:      "",
        name:      "store",
        component: StoreDefaultTab
      },

      {
        path:      "edit",
        name:      "editStore",
        component: EditStoreDefaultTab,
        meta:      {
          permissions: [() => ability.can("update", "Store")]
        }
      },

      {
        path:      "service-supports",
        name:      "storeServiceSupports",
        component: StoreServiceSupports
      },

      {
        path:      "suppliers",
        component: StoreSuppliersBase,
        meta:      {
          permissions: [() => ability.can("index", "Supplier")]
        },

        children: [
          {
            path:      "",
            name:      "storeSuppliers",
            component: StoreSuppliers,

            children: [
              {
                path:      "new",
                name:      "newStoreSupplier",
                component: NewStoreSupplier,
                meta:      {
                  permissions: [() => ability.can("update", "Store")]
                }
              }
            ]
          }
        ]
      },

      {
        path:      "service-orders",
        name:      "storeServiceOrders",
        component: StoreServiceOrders,
        meta:      {
          permissions: [() => ability.can("index", "ServiceOrder")]
        }
      },

      {
        path:      "tickets",
        name:      "storeTickets",
        component: StoreTickets,
        meta:      {
          permissions: [() => ability.can("index", "Ticket")]
        }
      },

      {
        path:      "tickets/:ticketId",
        name:      "storeTicket",
        component: StoreTicket,
        meta:      {
          permissions: [() => ability.can("read", "Ticket")]
        }
      },

      {
        path:      "tickets/:ticketId/service-orders/:serviceOrderId",
        name:      "storeTicketServiceOrder",
        component: StoreServiceOrder,
        meta:      {
          permissions: [
            () => ability.can("read", "Ticket"),
            () => ability.can("read", "ServiceOrder")
          ]
        }
      },

      {
        path:      "settings",
        name:      "storeSettings",
        component: StoreSettings,
        meta:      {
          permissions: [() => ability.can("read", "StoreSettings")]
        }
      },

      {
        path:      "settings/edit",
        name:      "editStoreSettings",
        component: EditStoreSettings,
        meta:      {
          permissions: [() => ability.can("edit", "StoreSettings")]
        }
      }
    ]
  },

  {
    path:      "/stores",
    name:      "stores",
    component: Stores,
    meta:      {
      auth:        true,
      permissions: [() => ability.can("index", "Store")]
    }
  }
]

<style lang="scss" scoped>

.vehicle-popover {
  padding: 16px;
  width: 409px;

  .loading {
    width: 409
  }

  .erred {
    font-size: 16px;
  }

  .manufacturer-icon {
    margin-right: 16px;
  }

  .content {
    width: 329px;

    .header {
      margin-bottom: 8px;

      .titles {
        overflow: hidden;
        font-family: $secondary-font;

        .title {
          font-weight: 500;
          font-size: 20px;
        }

        .subtitle {
          font-weight: 400;
          font-size: 18px;
          color: $gray-3;
        }
      }

      .plate {
        margin-left: 4px;
      }
    }

    .info-list {

      > .info + .info {
        margin: 4px 0;
      }

      .category-tag {}

      .fipe-tag {
        ::v-deep label {
          padding-left: 0px;
        }
      }

      .icon-container {
        width: 14px;
        height: 14px;
        margin-right: 8px;

        .icon {
          font-size: 12px;
          color: $gray-3;
        }
      }

      .km {
        font-family: $primary-monospace-font;
        font-weight: 400;
        font-size: 14px;
      }

      .color {
        font-weight: 400;
        font-size: 14px;
      }
    }
  }
}

</style>


<template lang="pug">

.vehicle-popover
  template(v-if="fetching")
    .loading
      loading-lines(:lines="3")

  template(v-else-if="erred")
    .erred.flex.column-direction.vertical-center
      span.text-center {{ $t('.fetch-error.message') }}
      app-button.retry(theme="link", @click="fetch") {{ $t('.fetch-error.retry') }}

  template(v-else-if="hasResource")
    .flex
      vehicle-manufacturer-icon.manufacturer-icon(
        :value="vehicle.manufacturerName",
        :size="32"
      )

      .content.full-width
        .header.flex.space-between
          .titles.flex.column-direction
            app-span.title(crop, :value="vehicle.modelName")
            app-span.subtitle(crop, :value="vehicle.versionName")

          license-plate.plate.no-shrink(:value="vehicle.licensePlate")

        .info-list.flex.column-direction
          template(v-if="vehicle.category")
            .info.flex.vertical-center
              .icon-container.flex.center.vertical-center
                i.icon.fal.fa-diamond
              vehicle-category-tag.category-tag(:value="vehicle.category")

          .info.flex.vertical-center
            .icon-container.flex.center.vertical-center
              i.icon.fal.fa-diamond
            fipe-code-tag.fipe-tag(
              :value="vehicle.fipeId",
              color="none"
            )

          .info.flex.vertical-center
            .icon-container.flex.center.vertical-center
              i.icon.fas.fa-gauge-med
            span.km {{ kmText }}

          .info.flex.vertical-center
            .icon-container.flex.center.vertical-center
              i.icon.fas.fa-swatchbook
            span.color {{ vehicle.colorName }}

</template>


<script>

import VehicleCategoryTag from "@/views/monitoring/_components/vehicle-category-tag"


// Mixins
import FetchMixin from "@/mixins/fetch-mixin"

// Models
// import models from "@/models"
// const { MovidaVehicleModel } = models


export default {
  components: {
    VehicleCategoryTag
  },

  emits: [],

  mixins: [FetchMixin],

  props: {
    vehicleId: { type: [Number, String], required: true },
    show:      { type: Boolean, required: true }
  },

  data() {
    return {
      i18nScope: "monitoring.vehicle-popover",

      resource: {},

      autofetch: false
    }
  },

  computed: {
    vehicle: { // MovidaVehicle
      get()      { return this.resource },
      set(value) { this.resource = value }
    },

    kmText() {
      return this.vehicle.$present("km")
        ? `${this.$asNumber(this.vehicle.km, { precision: 0 })} km`
        : `km não disponível`
    },

    hasResource() {
      return _.present(this.resource)
    }
  },

  watch: {
    show: {
      immediate: true,
      handler() {
        if (this.show && _.blank(this.resource)) this.fetch()
      }
    }
  },

  methods: {
    // @override Fetchable mixin
    fetchRequest() {
      return this.$newSdk.monitoring.vehicles.find(this.vehicleId)
    }
  }
}

</script>

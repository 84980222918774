// Base
import BaseRequests from "./Base"

// Models
import models from "@/models"
const { VehiclePart } = models

class VehiclePartsRequests extends BaseRequests {
  static get selector() {
    return "vehicleParts"
  }

  all({ params = {}, options = {} } = {}) {
    return this.action({
      method:  "get",
      url:     "/vehicle_parts",
      params,
      options: {
        model: VehiclePart,

        ...options
      }
    })
  }

  destroy({ vehiclePartId }) {
    if (_.blank(vehiclePartId)) {
      throw new Error('SDK VehicleParts - #destroy() - "vehiclePartId" is a required prop')
    }

    return this.action({
      method:  "delete",
      url:     `/vehicle_parts/${vehiclePartId}`,
      options: {
        model: VehiclePart
      }
    })
  }
}

export default VehiclePartsRequests

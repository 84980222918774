<style lang="scss" scoped>

.new-supplier-service-support-service-vehicle-model-groups {
  .section {
    padding: 16px 24px;
  }

  .services-count {
    padding: 24px;
    border-bottom: 1px solid $light-gray-3;

    .related-services {
      margin: 0 16px;
    }
  }
}

.footer {
  .submit-label {
    margin-right: 8px;
  }
}

</style>


<template lang="pug">

  app-modal.new-supplier-service-support-service-vehicle-model-groups(
    full-height,
    :width="1156",
    :heading="heading",
    @close="goBack",
    data-test="confirmationModal"
  )
    form.form.flex.column-direction.grow(@submit.prevent="submit", method="post")
      template(v-if="!servicesSelected")
        service-select-list.section(
          v-model="resource.serviceIds",
          :errors="errors",
          :supplier-id="supplierId",
          :service-type="serviceType"
        )

      template(v-else)
        .services-count.flex.vertical-center
          i.icon.fas.fa-wrench
          span.related-services {{ $t('.servicesCount.label', { count: servicesCount }) }}
          app-button(@click="changeServicesSelected()", theme="link") {{ $t('.servicesCount.btn.label') }}

        .section
          vehicle-model-group-select-list(
            v-model="resource.vehicleModelGroupIds",
            :errors="errors",
            :supplier-id="supplierId",
            :services-count="servicesCount"
          )

    template(#footer)
      .footer
        app-button(
          outline,
          theme="gray",
          @click="goBack",
        ) {{ $t('btn.cancel') }}

        app-button(
          @click.prevent="changeOrSubmit",
          :disabled="noServicesSelected"
          data-testid="submitForm"
        )
          .flex.vertical-center
            span.submit-label {{ submitLabel }}
            i.fas(:class="submitIcon")

</template>


<script>

// Mixins
import { mixins } from "movida-common.vue"

// Extends
import BaseView from "@/views/base-view"

// Models
import models from "@/models"

// Components
import ServiceSelectList from "./new/services/select-list.vue"
import VehicleModelGroupSelectList from "./new/vehicle-model-groups/select-list.vue"

const { FormMixin } = mixins
const { Base, Service } = models

class ServiceVehicleModelGroupsBatch extends Base {
  static get attrs() {
    return _.uniq([
      ...super.attrs,

      "serviceIds",
      "vehicleModelGroupIds"
    ])
  }

  static get constraints() {
    return {
      serviceIds:           { presence: true },
      vehicleModelGroupIds: { presence: true }
    }
  }
}

export default {
  name: "NewSupplierServiceSupportServiceVehicleModelGroups",

  components: {
    ServiceSelectList,
    VehicleModelGroupSelectList
  },

  extends: BaseView,

  mixins: [FormMixin],

  data() {
    return {
      i18nScope: "suppliers.service-supports.service-vehicle-model-groups.new",
      routeName: "newSupplierServiceSupportServiceVehicleModelGroups",

      id: this.$route.params.id,

      resource: new ServiceVehicleModelGroupsBatch({ serviceIds: [], vehicleModelGroupIds: [] }),

      servicesSelected: false
    }
  },

  computed: {
    heading() {
      if (this.servicesSelected) return this.$t(".vehicle-model-groups.heading")

      return this.$t(".services.heading")
    },

    submitLabel() {
      if (this.servicesSelected) return this.$t(".btn.add.groups")

      return this.$t(".btn.add.services")
    },

    submitIcon() {
      if (this.servicesSelected) return "fa-check"

      return "fa-arrow-right"
    },

    supplierId() {
      return this.id
    },

    serviceTypeSlug() {
      return this.$route.params.type
    },

    serviceType() {
      return Service.parseParamType(this.serviceTypeSlug)
    },

    servicesCount() {
      return this.resource.serviceIds.length
    },

    noServicesSelected() {
      return _.blank(this.resource.serviceIds)
    },

    backRoute() {
      return {
        name:   "supplierServiceSupportServiceVehicleModelGroups",
        params: { id: this.supplierId, type: this.serviceTypeSlug }
      }
    }
  },

  methods: {
    changeOrSubmit() {
      if (this.servicesSelected) {
        this.submit()
      }
      else {
        this.changeServicesSelected()
      }
    },

    changeServicesSelected() {
      // Limpa o array de filtro por ids caso necessário
      if (_.present(this.$route.query)) this.$router.push({ query: null })

      this.servicesSelected = !this.servicesSelected
    },

    goBack() {
      this.$router.push(this.backRoute)
    },

    // @override Form mixin
    submitRequest() {
      const params = this.resource.$serialize()

      return this.$newSdk.suppliers.serviceVehicleModelGroups.batches.create({ supplierId: this.supplierId, params })
    },

    // @override Form mixin
    submitSuccess(response) {
      this.$notifications.info(this.$t(".notifications.submit.success", { count: this.resource.vehicleModelGroupIds.length }))

      this.$emit("change")
      this.$router.push(this.backRoute)
    },

    // @override Form mixin
    submitError(err) {
      this.$err.log(err)

      this.$notifications.error(this.$t(".notifications.submit.error"))
    }
  }
}

</script>

<style scoped lang="scss">

.field {
  margin-bottom: 24px;
}

.row + .row {
  margin-top: 16px;
}

.service-type-icon {
  width: 32px;
  height: 32px;
  user-select: none;
}

.content-text {
  margin-left: 16px;
  font-family: $secondary-font;
}

.service-name {
  font-weight: 500;
  font-size: 20px;
}

.service-description {
  font-weight: normal;
  font-size: 12px;
}

.type-section {
  padding-bottom: 24px;
}

</style>


<template lang="pug">

  app-modal(
    footer,
    :width="800",
    :heading="$t('.title')",
    :close-button="!submitting",
    :confirm-label="$t('.btn.create.label')",
    :confirm-label-loading="$t('.btn.create.loading')",
    @close="$emit('close')",
    @confirm="submit",
  )
    .body
      .type-section.flex.vertical-center
        service-type-icon(
          :type="resource.serviceType",
          hide-tooltip
        )
        .content-text.flex.column-direction
          span.service-name(
            data-test="serviceName"
          ) {{ SupplierServiceSupport.$tEnum('serviceType', resource.serviceType) }}
          span.service-description {{ $t(`.types.${resource.serviceType}.description`) }}

      form.form(@submit.prevent="submit", method="post", data-test="form")
        ui-row.row
          ui-col(:span="6")
            input-field(
              :name="`supplierServiceSupport[totalCapacity]`",
              v-model="resource.totalCapacity",
              :loading="submitting",
              :errors="errors.totalCapacity",
              info,
              :tooltip="$t('models.supplierServiceSupport.tooltip.totalCapacity')",
              data-testid="totalCapacityInput"
            )

          ui-col(:span="6")
            input-field(
              :name="`supplierServiceSupport[hourlyCapacity]`",
              v-model="resource.hourlyCapacity",
              :loading="submitting",
              :errors="errors.hourlyCapacity",
              data-testid="hourlyCapacityInput"
            )

</template>


<script>

// Mixins
import { mixins } from "movida-common.vue"

// Models
import models from "@/models"

const { FormMixin } = mixins
const { SupplierServiceSupport, Service } = models

export default {
  mixins: [FormMixin],

  props: {
    supplierId:      { type: [String, Number], required: true },
    serviceTypeSlug: { type: String, required: true }
  },

  data() {
    return {
      i18nScope: "suppliers.service-supports.new",

      resource: {},

      SupplierServiceSupport
    }
  },

  computed: {
    type() {
      return this.resource.serviceType
    },

    typeText() {
      if (_.blank(this.type)) return null

      return SupplierServiceSupport.$tEnum("serviceType", this.type)
    }
  },

  created() {
    this.initialize()
  },

  methods: {
    initialize() {
      const serviceType = Service.parseParamType(this.serviceTypeSlug)

      this.resource = new SupplierServiceSupport({
        serviceType
      })
    },

    // @override Form mixin
    serializeAttributes() {
      return this.resource.$serialize()
    },

    // @override Form mixin
    submitRequest() {
      return this.$sdk.suppliers.createServiceSupport({
        id:     this.supplierId,
        params: this.serializeAttributes()
      })
    },

    // @override Form mixin
    submitSuccess(response) {
      this.$notifications.info(this.$t(".notifications.submit.success", {
        type: this.typeText
      }))

      this.$emit("create")
      this.$emit("close")
    },

    // @override Form mixin
    submitError(err) {
      this.$err.log(err)

      this.$notifications.error(this.$t(".notifications.submit.error"))
    }
  }
}

</script>

import Base from "./base"
import * as CNPJ from "@fnando/cnpj"


class Organization extends Base {
  static get attrs() {
    return _.uniq([
      ...super.attrs,
      "cnpj",
      "name",
      "tradingName",
      "email",
      "phone",
      "cellphone",
      "costCenterCode"  // XXX: opcional. Apelas para clientes vetor/gtf.
    ])
  }

  static get constraints() {
    return {
      name: { presence: true },
      cnpj: { presence: true }
    }
  }


  // decorator methods
  /// ---

  get formattedCNPJ() {
    if (this.$blank("cnpj")) return null

    return CNPJ.format(this.cnpj)
  }

}


export default Organization

<style lang="scss" scoped>

.service-orders-table {
  .skeleton-table {
    margin-top: 16px;

    .skeleton-row + .skeleton-row {
      margin-top: 24px;
    }
  }

  .empty {
    margin-top: 24px;

    .message {
      font-style: italic;
      font-weight: 400;
    }

    .icon {
      user-select: none;
      margin-bottom: 4px;
      height: 88px;
      width: 88px;
    }
  }

  .list {
    margin-top: 16px;
  }
}

</style>


<template lang="pug">

  .service-orders-table
    template(v-if="initializing")
      .skeleton-table
        service-order-row-skeleton.skeleton-row(
          v-for="i in 5",
          :key="`service-orders-row-skeleton-${i}`"
        )

    template(v-else)
      service-orders-table-header(
        :loading="loading",
        @sort="param => $emit('sort', param)"
        :sort-param="sortParam",
        :sort-order="sortOrder",
        data-testid="serviceOrdersTableHeader"
      )

      template(v-if="empty")
        .empty.flex.center
          .flex.column-direction.vertical-center
            span.message {{ $t('index.empty.message') }}

      template(v-else)
        service-order-row(
          v-for="serviceOrder in serviceOrders",
          :key="serviceOrder.id",
          :service-order="serviceOrder",
          :loading="loading",
          :store-id="storeId",
          :ticket-id="ticketId",
          data-testid="serviceOrderRow"
        )

</template>


<script>

// Components
import ServiceOrderRow from "./service-order-row.vue"
import ServiceOrderRowSkeleton from "./service-order-row-skeleton.vue"
import ServiceOrdersTableHeader from "./service-orders-table-header.vue"

export default {
  components: {
    ServiceOrderRow,
    ServiceOrderRowSkeleton,
    ServiceOrdersTableHeader
  },

  props: {
    serviceOrders: { type: Array, required: true },
    initializing:  { type: Boolean, default: false },
    loading:       { type: Boolean, default: false },
    sortedBy:      { type: String, default: "" },
    storeId:       { type: [Number, String], required: true },
    ticketId:      { type: [Number, String], required: true }
  },

  data() {
    return {
      i18nScope: "stores.tickets.show.service-orders-table"
    }
  },

  computed: {
    empty() {
      return _.blank(this.serviceOrders)
    },

    sortParam() {
      return this.sortedBy.split(":")[0]
    },

    sortOrder() {
      return this.sortedBy.split(":")[1] || "asc"
    }
  }
}

</script>

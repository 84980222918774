<style lang="scss" scoped>

.supplier-maintenance-vehicle-model-group-row {
  display: grid;
  grid-template-columns: 1fr 1fr 64px;

  &.loading {
    .column {
      .id-tag {
        color: $gray-2;
      }

      &.actions-column {
        ::v-deep .movida-common__app-button {
          color: $gray-2;

          &:hover {
            color: $orange;
          }
        }
      }

      .text,
      .link ::v-deep .app-span {
        color: $gray-2;
      }
    }
  }

  .column {
    padding: 0 12px;
    border-bottom: 1px solid $light-gray-3;
    height: 56px;
    overflow: hidden;

    .id-tag {
      margin-left: 4px;
    }

    &.vehicle-model-group-column {
      .vehicle-models-count {
        font-size: 12px;
        line-height: 12px;
        font-weight: 400;
        color: $gray-2;
        text-align: initial;
      }
    }

    .button {
      overflow: hidden;
    }

    .text,
    .link ::v-deep .app-span {
      font-family: $primary-font;
      font-weight: 400;
      font-size: 14px;
      color: $dark-gray;
      text-align: initial;
    }

    .link ::v-deep .app-span:hover {
      color: $orange;
    }
  }
}

</style>


<template lang="pug">

  .supplier-maintenance-vehicle-model-group-row(:class="{ loading }")
    .column.vehicle-model-group-column.flex.center.column-direction
      .flex.vertical-center
        app-button.button(:to="vehicleModelsRoute", theme="link")
          app-span.link(:value="vehicleModelGroup.name", crop, data-testid="vehicleModelGroupName")
        id-tag.id-tag(:value="vehicleModelGroup.id", data-testid="vehicleModelGroupId")
      app-span.vehicle-models-count(
        :value="$t('.vehicleModelsCount', { count: vehicleModelGroup.vehicleModelsCount })",
        data-testid="vehicleModelsCount"
      )

    .column.maintenance-table-column.flex.center.column-direction
      .flex.vertical-center
        app-button.button(:to="maintenanceTableRoute", theme="link")
          app-span.link(:value="maintenanceTable.name", crop, data-testid="maintenanceTableName")
        id-tag.id-tag(
          :value="maintenanceTable.id",
          data-testid="maintenanceTableId"
        )
      maintenance-table-kind-tag(
        :kind="maintenanceTable.kind",
        :muted="loading",
        data-testid="maintenanceTableKind"
      )

    .column.actions-column.flex.vertical-center
      popover-menu(
        v-if="popoverMenuAuthorization",
        :show.sync="showPopover",
        data-testid="popoverMenu"
      )
        popover-menu-button(
          v-if="$can('read', 'Supplier')",
          icon="fal fa-eye",
          :to="vehicleModelsRoute",
          :text="$t('.actions.btn.show.label')",
          @close-menu="showPopover = false"
        )

        destroy-action.destroy-button.popover-menu-button(
          :supplier-id="supplierMaintenanceVehicleModelGroup.supplierId",
          :supplier-maintenance-vehicle-model-group-id="supplierMaintenanceVehicleModelGroup.id",
          :name="vehicleModelGroup.name",
          size="normal",
          border="square",
          @destroy="$emit('destroy')"
        )
          .icon-content
            i.icon.fal.fa-trash-alt
          span {{ $t('.actions.btn.destroy.label') }}

</template>


<script>

// Components
import DestroyAction from "./supplier-maintenance-vehicle-model-group-row/destroy-action"

export default {
  components: {
    DestroyAction
  },

  props: {
    supplierMaintenanceVehicleModelGroup: { type: Object, required: true },
    loading:                              { type: Boolean, default: false }
  },

  data() {
    return {
      i18nScope: "suppliers.service-supports.maintenance.vehicle-model-groups.index.supplier-maintenance-vehicle-model-group-row",

      showPopover: false
    }
  },

  computed: {
    popoverMenuAuthorization() {
      return ["read", "update"].some(action => this.$can(action, "Supplier"))
    },

    vehicleModelsRoute() {
      return {
        name:   "supplierServiceSupportMaintenanceVehicleModelGroupVehicleModels",
        params: { maintenanceVehicleModelGroupId: this.supplierMaintenanceVehicleModelGroup.id }
      }
    },

    maintenanceTableRoute() {
      return {
        name:   "supplierServiceSupportMaintenanceVehicleModelGroupMaintenanceTable",
        params: { maintenanceVehicleModelGroupId: this.supplierMaintenanceVehicleModelGroup.id }
      }
    },

    vehicleModelGroup() {
      return _.get(this.supplierMaintenanceVehicleModelGroup, "vehicleModelGroup") || {}
    },

    maintenanceTable() {
      return _.get(this.supplierMaintenanceVehicleModelGroup, "maintenanceTable") || {}
    },

    hasMaintenanceTable() {
      return _.present(this.maintenanceTable)
    }
  }
}

</script>

import Base from "./base"

class Manufacturer extends Base {
  static get attrs() {
    return _.uniq([
      ...super.attrs,
      "name"
    ])
  }

  static get constraints() {
    return {
      name: { presence: true, type: "string" }
    }
  }
}

export default Manufacturer

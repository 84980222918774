<style scoped lang="scss">

.footer {
  height: 40px;
  border-top: 1px solid $light-gray-3;
  background: $light-gray;

  .row {
    flex-grow: 1;
    padding: 0 16px;
    text-align: right;
    font-size: 14px;
    font-weight: normal;
    color: $dark-gray;
    height: 100%;
  }

  .total {
    width: 150px;
    border-left: 1px solid $light-gray-3;
    padding: 0 16px;
    font-size: 14px;
    font-weight: normal;
    color: $dark-gray;
    text-align: right;
    height: 100%;
  }
}

.subtotal-loading {
  width: 100px;
}

::v-deep .service-table {
  td {
    padding-top: 8px;
    padding-bottom: 8px;
  }

  #table-header-service-price
   {
    text-align: right;
  }
}

</style>


<template lang="pug">

  .services-table
    app-table.service-table(
      :headers="headers",
      :loading="fetching"
    )
      service-row(
        v-for="service in services",
        :key="`service-row-${service.id}`",
        :service="service"
      )

      .footer.flex.vertical-center.space-between(slot="footer")
        span.row.flex.vertical-center.end {{ $t('.subtotal') }}
        .total.flex.vertical-center.end
          loading-lines.subtotal-loading(v-if="fetching", :min="100", :max="100")
          span(v-else) {{ $l('currency', total) }}

</template>


<script>

import ServiceRow from "./service-row"

export default {
  components: {
    ServiceRow
  },

  props: {
    services: { type: Array, default: () => [] },
    fetching: { type: Boolean, default: false }
  },

  data() {
    return {
      i18nScope: "stores.tickets.service-orders.show.services-table"
    }
  },

  computed: {
    total() {
      return this.services.reduce((total, service) => (service.freeOfCharge ? total : total + service.price), 0)
    },

    headers() {
      return [
        this.$t(`.header.code`),
        this.$t(`.header.type`),
        this.$t(`.header.name`),
        { name: this.$t(`.header.price`), id: "table-header-service-price" }
      ]
    }
  }
}

</script>


import Base from "./base"

class City extends Base {
  static get attrs() {
    return _.uniq([
      ...super.attrs,
      "placeId",
      "name",
      "stateCode"
    ])
  }

  static get relations() {
    return {
      supplier: { type: "belongsTo", model: "SupplierDetails" }
    }
  }

  static get constraints() {
    return {
      placeId: { presence: true, type: "string", length: { maximum: 1000 } }
    }
  }
}

export default City

// Base
import BaseRequests from "../../Base"

// Models
import models from "@/models"
const { ServiceOrderCancellation } = models

class MonitoringServiceOrderCancellationRequests extends BaseRequests {
  static get selector() {
    return "monitoring.serviceOrders.cancellation"
  }

  create({ serviceOrderId, params = {}, options = {} } = {}) {
    this.verifyData("create", { serviceOrderId })

    return this.action({
      method:  "post",
      url:     `/monitoring/service_orders/${serviceOrderId}/cancellation`,
      params,
      options: {
        model: ServiceOrderCancellation,

        ...options
      }
    })
  }

  find({ serviceOrderId, params = {}, options = {} } = {}) {
    this.verifyData("find", { serviceOrderId })

    return this.action({
      method:  "get",
      url:     `/monitoring/service_orders/${serviceOrderId}/cancellation`,
      params,
      options: {
        model: ServiceOrderCancellation,

        ...options
      }
    })
  }
}

export default MonitoringServiceOrderCancellationRequests

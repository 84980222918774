function EnumOptions(ModelClass) {
  class EnumOptionsClass extends ModelClass {
    // lazy evaluated $enumOptions, using @caiena/enum to build a map with:
    // [{ key: enumKey, value: enumValue, text: i18n.t(enumWithValue) }, ...]
    static get $enumOptions() {
      // avoiding static property inheritance
      // @see http://thecodebarbarian.com/static-properties-in-javascript-with-inheritance.html
      if (!this.hasOwnProperty("$$enumOptions")) { // eslint-disable-line no-prototype-builtins
        this.$$enumOptions = _.reduce(
          this.$enums,
          (result, enumeration, enumName) => {
            // transform to Enum instance, if needed
            result[enumName] = _.map(enumeration.keys, key => {
              let value = enumeration.value(key)
              let text = this.$tEnum(enumName, key /* or value */)

              return { key, value, text }
            })

            return result
          },
          {}
        )
      }

      return this.$$enumOptions
    }
  }

  return EnumOptionsClass
}

export default EnumOptions

<style lang="scss" scoped>

.error {
  margin-top: 8px;
}

.add {
  margin-left: 16px;
}

</style>


<template lang="pug">

  app-modal.new-supplier-contact(
    :width="690",
    :heading="$t('.heading')"
    @close="$emit('close')"
  )
    form.form(@submit.prevent="submit", method="post")
      contact-form-fields(
        :resource="resource",
        :errors="errors"
      )

    template(#footer)
      .footer
        .container.full-width
          .toolbar.flex.space-between
            app-button(
              outline,
              theme="gray",
              @click="cancel"
            ) {{ $t('btn.cancel') }}

            .add-buttons
              app-button(@click="addAndNew", outline)
                span {{ $t('.btn.addAndNew') }}

              app-button.add(
                @click="addAndClose",
                data-test="addContact"
              )
                span {{ $t('.btn.add') }}

          .error.text-right(v-if="emailOrPhoneErrors")
            span.movida-common__error-message {{ $t('models.contact.errors.emailOrPhone') }}


</template>


<script>

// Libs
import { v4 as uuid } from "uuid"

// Components
import ContactFormFields from "./form-fields"

// Mixins
import Validatable from "@/mixins/validatable"

// Models
import models from "@/models"
const { Contact, Email, Phone } = models

export default {
  name: "NewSupplierContact",

  components: { ContactFormFields },

  mixins: [Validatable],

  data() {
    return {
      i18nScope: "suppliers.contacts.new",

      resource: new Contact(),

      nestedAttributes: {
        emails: null,
        phones: null
      },

      uuid
    }
  },

  computed: {
    emailOrPhoneErrors() {
      return _.get(this.errors, "emails[0]")
    }
  },

  methods: {
    cancel() {
      this.$emit("cancel")
      this.$emit("close")
    },

    reset() {
      this.errors = {}

      this.resource = new Contact({
        emails: [new Email()],
        phones: [new Phone()]
      })
    },

    async add() {
      const resource = new Contact(this.resource)
      resource.emails = resource.emails.filter(email => _.present(email.value) || _.present(email.kind))
      resource.phones = resource.phones.filter(phone => _.present(phone.value) || _.present(phone.kind))

      if (!await this.validate(resource)) return false

      this.$emit("add", resource)
      return true
    },

    async addAndNew() {
      if (!(await this.add())) return

      this.reset()
    },

    async addAndClose() {
      if (!(await this.add())) return

      this.$emit("close")
    }
  }
}

</script>

<style scoped lang="scss">

td {
  vertical-align: top;
  padding: 10px 4px;
  font-weight: normal;
}

.order {
  background-color: $light-gray-3;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  font-family: $secondary-font;
  margin-left: 8px;
  font-weight: 500;
}

.empty {
  color: $gray-3;
}

.free-of-charge,
.forbidden-in-internal-facilities,
.dealership-only {
  width: 24px;
  height: 24px;
  border-radius: 50%;
}

.free-of-charge {
  background-color: $light-success-2;
}

.forbidden-in-internal-facilities {
  background-color: $light-orange;
}

.dealership-only {
  background-color: $light-orange;
}

.maintenance-package {
  cursor: pointer;
}

.discarded-icon {
  margin-right: 4px;
}

</style>


<template lang="pug">

  tr(data-testid="maintenanceTableRow")
    td(data-testid="orderColumn")
      span.order.flex.vertical-center.center {{ order }}

    td(data-testid="maintenanceColumn")
      span {{ maintenance.name }}

    td(data-testid="kilometrageLimitColumn")
      template(v-if="resource.formattedKilometrageLimit") {{ resource.formattedKilometrageLimit }}
      span.empty(v-else) -

    td(data-testid="timeLimitColumn")
      template(v-if="resource.formattedTimeLimit") {{ resource.formattedTimeLimit }}
      span.empty(v-else) -

    td(data-testid="targetExecutionTimeColumn")
      template(v-if="formattedTargetExecutionTime") {{ formattedTargetExecutionTime }}
      spam.empty(v-else) -

    td(data-testid="maintenancePackageColumn")
      .flex
        discarded-icon.discarded-icon(v-if="resource.$get('maintenancePackage.$discarded')")
        maintenance-package-preview.maintenance-package(
          :name="maintenancePackage.name",
          :id="maintenancePackage.id"
        )

    td.center(data-testid="freeOfChargeColumn")
      template(v-if="resource.freeOfCharge")
        .flex.center
          .free-of-charge.flex.center.vertical-center
            img(
              :src="freeOfChargeIcon",
              v-tooltip.bottom="{ content: $t('.freeOfCharge.tooltip') }",
              data-testid="freeOfChargeIcon"
            )
      template(v-else)
        span.empty -

    td.center(data-testid="forbiddenInInternalFacilitiesColumn")
      template(v-if="resource.forbiddenInInternalFacilities")
        .flex.center
          .forbidden-in-internal-facilities.flex.center.vertical-center
            img(
              :src="forbiddenInInternalFacilitiesIcon",
              v-tooltip.bottom="{ content: $t('.forbiddenInInternalFacilities.tooltip') }",
              data-testid="forbiddenInInternalFacilitiesIcon"
            )
      template(v-else)
        span.empty -

    td.center(data-testid="dealershipOnlyColumn")
      template(v-if="resource.dealershipOnly")
        .flex.center
          .dealership-only.flex.center.vertical-center
            img(
              :src="dealershipOnlyIcon",
              v-tooltip.bottom="{ content: $t('.dealershipOnly.tooltip') }",
              data-testid="dealershipOnlyIcon"
            )
      template(v-else)
        span.empty -

</template>


<script>

// Assets
import freeOfChargeIcon from "@/assets/images/icons/free-of-charge/solid.svg"
import forbiddenInInternalFacilitiesIcon from "@/assets/images/icons/forbidden-in-internal-facilities/solid.svg"
import dealershipOnlyIcon from "@/assets/images/icons/dealership-only/solid.svg"

// Components
import MaintenancePackagePreview from "./maintenance-package-preview.vue"

export default {
  components: {
    MaintenancePackagePreview
  },

  props: {
    resource: { type: Object, default: () => ({}) },
    order:    { type: Number, required: true }
  },

  data() {
    return {
      i18nScope: "vehicle-model-groups.maintenance-tables.components.maintenance-row",

      freeOfChargeIcon,
      forbiddenInInternalFacilitiesIcon,
      dealershipOnlyIcon
    }
  },

  computed: {
    maintenance() {
      return _.get(this.resource, "maintenance") || {}
    },

    maintenancePackage() {
      return _.get(this.resource, "maintenancePackage") || {}
    },

    formattedTargetExecutionTime() {
      const { targetExecutionTime } = this.resource
      if (_.blank(targetExecutionTime)) return null

      return this.$t("count.hours-short", { count: targetExecutionTime })
    }
  }
}

</script>

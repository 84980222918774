<style lang="scss" scoped>

.download-button {
  .link {
    font-family: $secondary-font;
    font-weight: 500;
    font-size: 14px;
    color: $dark-gray;

    &:hover {
      color: $gray-3;
    }

    .icon {
      font-size: 12px;
      margin-right: 4px;
    }
  }
}

</style>


<template lang="pug">

  .download-button
    app-button.link(
      theme="link",
      @click="download"
    )
      i.icon.fas.fa-arrow-to-bottom
      span {{ $t('.download.label') }}

</template>


<script>

export default {
  props: {
    blobId: { type: [String, Number], required: true }
  },

  data() {
    return {
      i18nScope: "suppliers.deals.products.data-loads.show.download-button"
    }
  },

  methods: {
    async getDownloadURL() {
      const { data } = await this.$newSdk.blobs.getDownloadURL({ blobId: this.blobId })
      return data.url
    },

    async download() {
      try {
        const url = await this.getDownloadURL()

        const link = document.createElement("a")
        link.href = url
        link.download = true

        document.body.appendChild(link)
        link.click()
        link.parentNode.removeChild(link)
      }
      catch (err) {
        this.$err.log(err)
        this.$notifications.error(this.$t(".notifications.fetch.failure"))
      }
    }
  }
}

</script>

<style lang="scss" scoped>

.supplier-service-support-maintenance-vehicle-model-group {
  .lines {
    padding: 24px;
  }

  .header {
    padding: 24px 24px 0  24px;

    .discarded-icon {
      margin-right: 4px;
    }

    .title {
      font-weight: bold;
      font-size: 20px;
      margin-bottom: 4px;
    }
  }

  .body {
    overflow-y: auto;
  }
}

</style>


<template lang="pug">

  app-modal(
    :width="1156",
    :heading="$t('.title.label')",
    full-height,
    @close="goBack"
  )
    .supplier-service-support-maintenance-vehicle-model-group.full-height
      template(v-if="fetching")
        loading-lines.lines(:lines="4")
      template(v-else)
        .flex.column-direction.full-height
          header.header
            .flex.vertical-center
              discarded-icon.discarded-icon(v-if="resource.vehicleModelGroup.$discarded")
              h2.title {{ resource.vehicleModelGroup.name }}
            span.description(v-if="resource.vehicleModelGroup.description") {{ resource.vehicleModelGroup.description }}

            .g-tab-navbar-content.tabs
              router-tab(:to="vehicleModelsRoute") {{ $t('.tab.vehicleModels') }}
              router-tab(:to="maintenanceTableRoute") {{ $t('.tab.maintenanceTable') }}

          .section.body
            router-view(
              :vehicleModelGroupId="resource.vehicleModelGroupId"
              :maintenanceTableId="resource.maintenanceTableId"
            )

</template>


<script>

// Mixins
import { mixins } from "movida-common.vue"

// View
import View from "@/views/view"

const { Fetchable } = mixins

export default {
  name: "SupplierServiceSupportMaintenanceVehicleModelGroup",

  extends: View,

  mixins: [Fetchable],

  props: {
    backRouteQuery: { type: Object, default: () => ({}) }
  },

  data() {
    return {
      i18nScope: "suppliers.service-supports.maintenance.vehicle-model-groups.show",

      id:                             this.$params.asInteger(this.$route.params.id),
      maintenanceVehicleModelGroupId: this.$params.asInteger(this.$route.params.maintenanceVehicleModelGroupId),

      resource: {}
    }
  },

  computed: {
    backRoute() {
      return {
        name:   "supplierServiceSupportMaintenanceVehicleModelGroups",
        params: { id: this.id },
        query:  this.backRouteQuery
      }
    },

    vehicleModelsRoute() {
      return { name: "supplierServiceSupportMaintenanceVehicleModelGroupVehicleModels" }
    },

    maintenanceTableRoute() {
      return { name: "supplierServiceSupportMaintenanceVehicleModelGroupMaintenanceTable" }
    }
  },

  methods: {
    goBack() {
      this.$router.push(this.backRoute)
    },

    // @override Fetchable mixin
    fetch() {
      return this.$sdk.suppliers.getMaintenanceVehicleModelGroup({
        id:                             this.id,
        maintenanceVehicleModelGroupId: this.maintenanceVehicleModelGroupId
      })
    },

    // @override Fetchable mixin
    onFetchError(err) {
      // Dealing with appError from extension view.vue
      if (this.hasViewError(err)) this.appError = err

      this.$err.log(err)
      this.$notifications.error(this.$t(".notifications.fetch.failure"))
    }
  }
}

</script>

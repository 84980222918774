<style lang="scss" scoped>

::v-deep .container {
  border-color: $dark-warning;

  .header .title {
    color: $dark-warning
  }

  .description .movida-common__info-label-message {
    line-height: unset;
  }
}

.icon {
  margin-right: 8px;
}

.row {
  margin-top: 16px;
}

.info:not(:last-of-type) {
  margin-right: 72px;
}

.error-message {
  margin-right: 4px;
}

</style>


<template lang="pug">

  .supplier-blocked-collapse-card
    collapse-card(ref="collapseCard")
      template(slot="title")
        i.icon.fas.fa-times-circle
        span {{ $t('.blocked') }}

      template(slot="body")
        template(v-if="fetching")
          loading-lines.row

        template(v-else-if="erred")
          .row.flex(
            @submit.prevent="submit",
            method="post"
          )
            span.error-message {{ $t('.retry.message') }}
            app-button(theme="link", @click="fetch")
              span.retry {{ $t('.retry.link') }}

        template(v-else)
          ui-row.row
            info-label.info(
              :label="$t('models.supplierBlock.attributes.startAt')",
              :value="$standardTime(block.startAt)"
            )
            info-label.info(
              :label="$t('models.supplierBlock.attributes.endAt')",
              :value="$standardTime(block.endAt)",
              :empty-text="$t('unregistered.feminine')"
            )
            info-label.info(
              :label="$t('models.supplierBlock.attributes.author')",
              :value="block.author"
            )
          ui-row.row
            info-label.info.description(
              :label="$t('models.supplierBlock.attributes.description')",
              :value="block.description"
            )

</template>


<script>

// Mixins
import FetchMixin from "@/mixins/fetch-mixin"

// Components
import CollapseCard from "./collapse-card"

export default {
  components: {
    CollapseCard
  },

  mixins: [FetchMixin],

  props: {
    blockId: { type: [String, Number], required: true }
  },

  data() {
    return {
      i18nScope: "suppliers.components.supplier-blocked-collapse-card",

      resource: {}
    }
  },

  computed: {
    block: {
      get() { return this.resource },

      set(value) { this.resource = value }
    }
  },

  methods: {
    // @override FetchMixin
    fetchRequest() {
      return this.$newSdk.supplierBlocks.find({ supplierBlockId: this.blockId })
    },

    onFetchSuccess({ data }) {
      this.block = data

      if (this.initialized) {
        setTimeout(() => {
          this.$refs.collapseCard.expand()
        }, 100)
      }
    }
  }
}

</script>

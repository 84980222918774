import Base from "./Base"

class StoreSuppliers extends Base {
  get({ storeSupplierId, params = {} } = {}) {
    if (_.blank(storeSupplierId)) {
      throw new Error('SDK StoreSuppliers - #get() - "storeSupplierId" is a required prop')
    }

    return this.action({
      method: "get",
      url:    `/store_suppliers/${storeSupplierId}`,
      params
    })
  }

  destroy({ storeSupplierId, params = {} } = {}) {
    if (_.blank(storeSupplierId)) {
      throw new Error('SDK StoreSuppliers - #destroy() - "storeSupplierId" is a required prop')
    }

    return this.action({
      method: "delete",
      url:    `/store_suppliers/${storeSupplierId}`,
      params
    })
  }

  getServiceSupport({ storeSupplierId, serviceTypeSlug, params = {} } = {}) {
    if (_.blank(storeSupplierId)) {
      throw new Error(
        'SDK StoreSuppliers - #getServiceSupport() - "id" is a required prop'
      )
    }

    if (_.blank(serviceTypeSlug)) {
      throw new Error(
        'SDK StoreSuppliers - #getServiceSupport() - "serviceTypeSlug" is a required prop'
      )
    }

    return this.action({
      method: "get",
      url:    `/store_suppliers/${storeSupplierId}/service_supports/${serviceTypeSlug}`,
      params
    })
  }

  listServiceSupports({ storeSupplierId, params = {} } = {}) {
    if (_.blank(storeSupplierId)) {
      throw new Error('SDK StoreSuppliers - #listServiceSupports() - "storeSupplierId" is a required prop')
    }

    return this.action({
      method: "get",
      url:    `/store_suppliers/${storeSupplierId}/service_supports`,
      params
    })
  }

  createServiceSupport({ storeSupplierId, params = {} } = {}) {
    if (_.blank(storeSupplierId)) {
      throw new Error('SDK StoreSuppliers - #createServiceSupport() - "storeSupplierId" is a required prop')
    }

    return this.action({
      method: "post",
      url:    `/store_suppliers/${storeSupplierId}/service_supports`,
      params
    })
  }

  destroyServiceSupport({ storeSupplierId, serviceTypeSlug, params = {} } = {}) {
    if (_.blank(storeSupplierId)) {
      throw new Error('SDK StoreSuppliers - #destroyServiceSupport() - "storeSupplierId" is a required prop')
    }

    if (_.blank(serviceTypeSlug)) {
      throw new Error('SDK StoreSuppliers - #destroyServiceSupport() - "serviceTypeSlug" is a required prop')
    }

    return this.action({
      method: "delete",
      url:    `/store_suppliers/${storeSupplierId}/service_supports/${serviceTypeSlug}`,
      params
    })
  }

  listMaintenanceVehicleModelGroups({ storeSupplierId, params = {} } = {}) {
    if (_.blank(storeSupplierId)) {
      throw new Error('SDK StoreSuppliers - #listMaintenanceVehicleModelGroups() - "storeSupplierId" is a required prop')
    }

    return this.action({
      method: "get",
      url:    `/store_suppliers/${storeSupplierId}/maintenance_vehicle_model_groups`,
      params
    })
  }

  batchCreateMaintenanceVehicleModelGroups({ storeSupplierId, params = {} } = {}) {
    if (_.blank(storeSupplierId)) {
      throw new Error(
        'SDK StoreSuppliers - #batchCreateMaintenanceVehicleModelGroups() - "storeSupplierId" is a required prop'
      )
    }

    return this.action({
      method: "post",
      url:    `/store_suppliers/${storeSupplierId}/maintenance_vehicle_model_groups/batches`,
      params
    })
  }
}

export default StoreSuppliers

<style scoped lang="scss">

.actions {
  margin-bottom: 8px;
}

.icon {
  font-size: 16px;
  margin-right: 8px;
}

.nationwide-support,
.round-the-clock {
  .icon {
    margin-right: 16px;
  }
}

.column + .column {
  margin-left: 200px;
}

.section + .section {
  margin-top: 24px;
  padding-top: 24px;
  border-top: 1px solid $light-gray-3;
}

label {
  display: block;
}

.title {
  font-family: $secondary-font;
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 16px;
}

.item + .item {
  margin-top: 16px;
}

.icon {
  &.disabled {
    color: $light-gray-3;
  }
}

.city {
  margin-bottom: 8px;
}

.city-section {
  margin-bottom: 16px;
}

.subtitle {
  font-family: $secondary-font;
  font-weight: normal;
}

.facilities-tag {
  margin-left: 8px;
}

.shift-container {
  margin-top: 32px;
  margin-left: 166px;
  margin-bottom: 8px;

  .shift {
    min-width: 150px;
    font-weight: 500;
    font-size: 12px;
    font-family: $secondary-font;
  }

  .shift + .shift {
    margin-left: 24px;
  }
}

</style>


<template lang="pug">

  .supplier-details
    loading-lines(v-if="fetching", :lines="4")
    template(v-else)
      .actions.flex.end
        app-button.btn(
          v-if="$can('update', 'Supplier')",
          theme="neutral",
          size="small",
          :to="{ name: 'editSupplierDetails', params: { id: supplierId }}"
        )
          i.fal.fa-pencil.icon
          span {{ $t('.btn.edit') }}

      //- .section
      //-   label.title {{ $t('.title.organization') }}
      //-   ui-row
      //-     ui-col(:span="4")
      //-       check-item.item(:label="SupplierDetails.$tAttr('rac')", :checked="!resource.rac")
      //-         rac-icon(slot="icon", :color="iconColor(!resource.rac)")

      //-       check-item.item(:label="SupplierDetails.$tAttr('racMensal')", :checked="!resource.racMensal")
      //-         rac-mensal-icon(slot="icon", :color="iconColor(!resource.racMensal)")

      //-       check-item.item(:label="SupplierDetails.$tAttr('racApp')", :checked="!resource.racApp")
      //-         rac-app-icon(slot="icon", :color="iconColor(!resource.racApp)")

      //-       check-item.item(:label="SupplierDetails.$tAttr('gtf')", :checked="!resource.gtf")
      //-         gtf-icon(slot="icon", :color="iconColor(!resource.gtf)")

      //-     ui-col(:span="4")
      //-       check-item.item(:label="SupplierDetails.$tAttr('zeroKm')", :checked="!resource.zeroKm")
      //-         zero-km-icon(slot="icon", :color="iconColor(!resource.zeroKm)")

      //-       check-item.item(:label="SupplierDetails.$tAttr('premium')", :checked="!resource.premium")
      //-         premium-icon(slot="icon", :color="iconColor(!resource.premium)")

      //-       check-item.item(:label="SupplierDetails.$tAttr('seminovos')", :checked="!resource.seminovos")
      //-         seminovos-icon(slot="icon", :color="iconColor(!resource.seminovos)")


      .section
        label.title {{ $t('.title.location') }}

        ui-row
          ui-col(:span="4")
            .flex
              check-item.item(
                :label="SupplierDetails.$tAttr('internalFacilities')",
                :checked="!resource.internalFacilities"
              )
                i.icon.fal.fa-garage-car(slot="icon", :class="{ disabled: !resource.internalFacilities }")
              facilities-tag(:disabled="!resource.internalFacilities")

          ui-col(:span="4")
            .flex
              check-item.item(
                :label="SupplierDetails.$tAttr('supplierFacilities')",
                :checked="!resource.supplierFacilities"
              )
                i.icon.fal.fa-car-building(slot="icon", :class="{ disabled: !resource.supplierFacilities }")
              facilities-tag(:disabled="!resource.supplierFacilities", value="external")

      .section
        label.title {{ $t('.title.customers') }}

        ui-row
          ui-col(:span="4")
            check-item.item(
              :label="SupplierDetails.$tAttr('internalCustomer')",
              :checked="!resource.internalCustomer"
            )
              i.icon.fal.fa-id-card-alt(slot="icon", :class="{ disabled: !resource.internalCustomer }")

          ui-col(:span="4")
            check-item.item(
              :label="SupplierDetails.$tAttr('externalCustomer')",
              :checked="!resource.externalCustomer"
            )
              i.icon.fal.fa-users(slot="icon", :class="{ disabled: !resource.externalCustomer }")

      .section
        label.title {{ $t('.title.extras') }}

        ui-row(vertical-align="center")
          ui-col(:span="3")
            check-item.item(
              :label="SupplierDetails.$tAttr('receivesTowedVehicles')",
              :checked="!resource.receivesTowedVehicles",
              :tooltip="$t('.fields.receivesTowedVehicles.tooltip')"
            ) 
          ui-col(:span="3")
            check-item.item(
              :label="SupplierDetails.$tAttr('pickupService')",
              :checked="!resource.pickupService",
              :tooltip="$t('.fields.pickupService.tooltip')"
            )     
          ui-col(:span="3")
            info-label(
              :label="SupplierDetails.$tAttr('schedulingNotice')",
              :tooltip="{ offset: '4', content: $t('.fields.schedulingNotice.tooltip') }",
              :value="resource.formattedSchedulingNotice"
            )
          ui-col(:span="3")
            check-item.item(
             :label="SupplierDetails.$tAttr('rabbotPlataform')",
             :checked="!resource.rabbotPlataform",
             :tooltip="$t('.fields.rabbotPlataform.tooltip')"
            )
          ui-col(:span="3")
            check-item.item(
             :label="SupplierDetails.$tAttr('movidaApp')",
             :checked="!resource.movidaApp",
             :tooltip="$t('.fields.movidaApp.tooltip')"
            ) 

      .section
        label.title {{ $t('.title.cities') }}

        .city-section(v-if="resource.$present('city')")
          label.subtitle {{ $t('.section.cities.mainCity') }}
          label.city {{ `${resource.city.name}, ${resource.city.stateCode}` }}

        label.subtitle {{ $t('.section.cities.supportedCities') }}
        template(v-if="resource.nationwideSupport")
          .nationwide-support.flex.vertical-center
            i.fal.fa-flag.icon
            label {{ $t('.fields.nationwideSupport.label') }}

        template(v-else)

          template(v-if="hasSupportedCities")
            label.city(v-for="city in resource.supportedCities", :key="`supported-city-${city.id}`")
              | {{ `${city.name}, ${city.stateCode}` }}

          template(v-else)
            span.global-empty-color.global-empty-text {{ $t('.fields.supportedCities.empty') }}

      .section
        label.title {{ $t('.title.serviceScheudles') }}


        template(v-if="resource.roundTheClock")
          .round-the-clock.flex.vertical-center
            i.fal.fa-clock.icon
            label {{ $t('.fields.roundTheClock.label') }}

        template(v-else)
          .shift-container.flex
            span.shift.center(
              v-for="shift in ['morning', 'afternoon', 'evening']",
              :key="`title-${shift}`",
            ) {{ $t(`.shift.${shift}`) }}

          business-hours-row(
            v-for="weekday in weekdays",
            :key="`week-day-${weekday}`",
            :business-hours="resource.businessHours",
            :weekday="weekday"
          )

</template>


<script>

// Mixins
import FetchMixin from "@/mixins/fetch-mixin"

// View
import LoadedView from "@/views/loaded-view"

// Components
import BusinessHoursRow from "./business-hours-row"

// Models
import models from "@/models"
const { SupplierDetails, SupplierBusinessHour } = models

export default {
  components: {
    BusinessHoursRow
  },

  extends: LoadedView,

  mixins: [FetchMixin],

  data() {
    return {
      i18nScope: "suppliers.details.show",
      routeName: "supplierDetails",

      SupplierDetails,

      weekdays: SupplierBusinessHour.$enums.weekday.keys,

      supplierId: null,
      resource:   {}
    }
  },

  computed: {
    hasSupportedCities() {
      return _.present(this.resource.supportedCities)
    }
  },

  methods: {
    iconColor(checked) {
      return checked ? "disabled" : "gray"
    },

    // @override Loaded view
    parseRoute() {
      this.supplierId = this.$params.asInteger(this.$route.params.id)
    },

    // @override Fetch mixin
    fetchRequest() {
      return this.$newSdk.suppliers.details.find({ supplierId: this.supplierId })
    }
  }
}

</script>

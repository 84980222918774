<style lang="scss" scoped>

form {
  width: 100%;
}

</style>


<template lang="pug">

  .search-form
    form(
      @submit.prevent="$emit('search')",
      method="get",
      data-test="form"
    )
      ui-row(vertical-align="bottom")
        ui-col(:span="4")

          input-field(
            name="search[q]",
            v-model="params.q",
            :loading="loading",
            hide-label,
            :placeholder="$t('.fields.q.placeholder')",
            icon-left="far fa-search"
          )
        ui-col(:span="3")
          brand-select-field(
            name="search[brand_id]",
            :label="$t('.fields.brand.label')",
            :placeholder="$t('.fields.brand.placeholder')",
            :loading="loading",
            v-model="params.brandId",
            hide-create-option
          )
        ui-col(:span="3")
          type-select-field(
            name="search[type]",
            :label="$t('.fields.type.label')",
            :placeholder="$t('.fields.type.placeholder')",
            :value="typeOption",
            @select-type-option="onSelectTypeOption",
            :options="typeOptions",
            searchable,
            data-test="typeSelect"
          )

        ui-col(:span="2")
          app-button(
            type="submit",
            :loading="loading",
            full-width,
            outline
          )
            span {{ $t('btn.search.label') }}
            span(slot="loading") {{ $t('btn.search.loading') }}

</template>


<script>

// Components
import TypeSelectField from "../components/type-select-field.vue"
import BrandSelectField from "../components/brand-select-field.vue"

// Models
import models from "@/models"
const { Product } = models

export default {
  components: {
    TypeSelectField,
    BrandSelectField
  },

  props: {
    loading: { type: Boolean, default: false },
    params:  { type: Object, default: () => ({}) }
  },

  data() {
    return {
      i18nScope: "products.index.search-form"
    }
  },

  computed: {
    typeOption() {
      return this.typeOptions.find(option => option.value === this.params.type)
    },

    typeOptions() {
      const types = Product.$enums.type.values

      return types.map(type => ({
        label: Product.$tEnum("type", type),
        value: type
      }))
    }
  },

  methods: {
    onSelectTypeOption(option) {
      if (_.blank(option)) {
        // eslint-disable-next-line vue/no-mutating-props
        this.params.type = null
        return null
      }

      // eslint-disable-next-line vue/no-mutating-props
      this.params.type = option.value
    }
  }
}

</script>

import Ticket from "./ticket"

class TicketRepair extends Ticket {
  static get attrs() {
    const attrs = super.attrs.filter(attr => attr !== "type")

    return attrs
  }

  static get enums() {
    const enums = { ...super.enums }
    delete enums.type

    return enums
  }

  static get constraints() {
    return {
      ...super.constraints,

      vehicleKm: { presence: true }
      // XXX: fuelLevel por hora é opcional pois não é retornada pelo gmf-api
      // ref: https://github.com/caiena/movida-gmf/issues/1526
      // fuelLevel: { presence: true }
    }
  }

  static get virtuals() {
    return [
      "type"
    ]
  }

  // virtuals
  get type() {
    return "Tickets::Repair"
  }
}

export default TicketRepair

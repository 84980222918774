<style lang="scss" scoped>

.vehicle-model-group-maintenance-tables {
  .section-header {
    // 25px para compensar a falta de label no campo de busca textual
    margin-bottom: calc(16px + 25px);

    .title {
      font-family: $secondary-font;
      font-weight: 500;
      font-size: 20px;
    }
  }

  .search {
    margin-bottom: 32px;
    margin-right: 40px;
  }

  .actions {
    text-align: right;
  }

  .paginator-content {
    padding-top: 24px;

    &.loading {
      max-width: 400px;
      margin: 0 auto;
    }
  }

  .footer {
    margin-top: 24px;
    padding-top: 24px;
    border-top: 1px solid $light-gray-3;

    .icon {
      margin-right: 8px;
    }
  }
}

.row {
  border-bottom: 1px solid $light-gray-3;

  &:first-child {
    border-top: 1px solid $light-gray-3;
  }
}

.app-button + .app-button {
  margin-left: 24px;
}

</style>


<template lang="pug">

  .vehicle-model-group-maintenance-tables
    .section-header
      h2.title {{ $t('.title') }}

    .flex.space-between
      search-form.search.grow(
        :loading="fetching",
        :params="parsedQueryParams",
        @search="search"
      )

      .actions
        app-button.app-button(
          v-if="$can('update', 'VehicleModelGroup')"
          theme="link"
          @click="showMaintenanceTableSettingsModal = true"
        ) {{ $t(".btn.settings.label") }}

        app-button.app-button(
          v-if="$can('update', 'VehicleModelGroup')",
          theme="link",
          @click="showImportModal = true"
        ) {{ $t('.btn.import.label') }}

        app-button.app-button(
          v-if="$can('update', 'VehicleModelGroup')",
          theme="link",
          :to="{ name: 'newVehicleModelGroupMaintenanceTable' }"
        ) {{ $t('.btn.new.label') }}

    .clear-filters
      filters-button.filters-button(
        v-if="hasFilters",
        @clear="clearFilters"
      )

    .list
      .flex.end.vertical-bottom
        results-indicator(
          :page="parsedQueryParams.page",
          :per-page="pagination.perPage",
          :total="pagination.total",
          :loading="fetching"
        )

      vehicle-model-group-maintenance-tables-table.table(
        :error="erred",
        :empty="empty",
        :initializing="initializing",
        :loading="fetching",
        :vehicle-model-group-maintenance-tables="vehicleModelGroupMaintenanceTables",
        :sorted-by="parsedQueryParams.sort",
        @sort="onSortChange",
        @changed="fetch"
      )

    .paginator-content(v-if="fetching || showPaginator", :class="{ loading: fetching }")
      paginator(
        :loading="fetching",
        :per-page="pagination.perPage",
        :total="pagination.total",
        :value="parsedQueryParams.page",
        :disabled="fetching",
        @input="onPageChange",
        data-testid="paginator"
      )

    section.section.footer.flex.space-between
      app-button(:to="{ name: 'vehicleModelGroups' }", outline)
        i.far.fa-arrow-left.icon
        span {{ $t('.btn.footer.back.label') }}

      app-button(
        v-if="$can('create', 'VehicleModelGroup')",
        theme="link",
        :to="{ name: 'newVehicleModelGroup' }"
      )
        span {{ $t('.btn.footer.new.label') }}

    app-modal(
      v-if="showImportModal",
      full-height,
      :width="996",
      @close="showImportModal = false",
      data-test="importModal"
    )
      new-import(
        @close="showImportModal = false",
        @import="onImport"
      )

    maintenance-table-settings-modal(
      v-if="showMaintenanceTableSettingsModal"
      :vehicle-model-group="vehicleModelGroup"
      @close="showMaintenanceTableSettingsModal = false"
    )

</template>


<script>

// View
import IndexView from "@/views/index-view"

// Mixins
import FetchMixin from "@/mixins/fetch-mixin"

// Components
import MaintenanceTableSettingsModal from "./components/maintenance-table-settings-modal.vue"
import NewImport from "./import/new.vue"
import SearchForm from "./index/search-form.vue"
import VehicleModelGroupMaintenanceTablesTable from "./index/vehicle-model-group-maintenance-tables-table.vue"

export default {
  name: "VehicleModelGroupMaintenanceTables",

  components: {
    MaintenanceTableSettingsModal,
    NewImport,
    SearchForm,
    VehicleModelGroupMaintenanceTablesTable
  },

  extends: IndexView,

  mixins: [FetchMixin],

  props: {
    vehicleModelGroup: { type: Object, required: true }
  },

  data() {
    return {
      i18nScope: "vehicle-model-groups.maintenance-tables.index",
      routeName: "vehicleModelGroupMaintenanceTables",

      vehicleModelGroupId: this.$route.params.id,

      resource: [],

      showImportModal: false,

      showMaintenanceTableSettingsModal: false,

      // @override SearchableIndexView
      queryParamsStructure: {
        q:    "string",
        page: "integer",
        sort: "string"
      }
    }
  },

  computed: {
    vehicleModelGroupMaintenanceTables: {
      get() { return this.resource },
      set(value) { this.resource = value }
    },

    empty() {
      return _.blank(this.vehicleModelGroupMaintenanceTables)
    }
  },

  methods: {
    parseRoute() {
      this.vehicleModelGroupId = this.$route.params.id
    },

    onImport() {
      this.showImportModal = false
      this.fetch()
    },

    // @override Fetchable mixin
    fetchRequest() {
      return this.$newSdk.vehicleModelGroups.maintenanceTables.all({
        vehicleModelGroupId: this.vehicleModelGroupId,
        params:              this.requestQueryParams
      })
    }
  }
}

</script>

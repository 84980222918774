<style scoped lang="scss">

.products-table {
  border: 1px solid $light-gray-3;
  border-radius: 4px;
}

.empty,
.error {
  text-align: center;
  font-style: italic;
  padding: 32px 0;
  width: 100%;

  color: $gray-3;
}

.link {
  margin-left: 4px;
}

.table-edge {
  height: 8px;
  background: $light-gray;
}

</style>


<template lang="pug">

  .products-table

    slot(name="header")
    template(v-if="!hasHeader")
      .table-edge

    template(v-if="error")
      .error
        span {{ $t('.error') }}

    template(v-else-if="loading")
      product-row-loading(
        v-for="product in 4",
        :key="`product-loading-${product}`",
        data-test="productRowSkeleton"
      )

    template(v-else-if="empty")
      slot(name="empty")
        .empty
          span {{ $t('.empty') }}
          router-link.link(:to="{ name: 'newProduct' }") {{ $t('.link') }}

    template(v-else)
      slot

    slot(name="footer")
    template(v-if="!hasFooter")
      .table-edge

</template>


<script>
// Models
import models from "@/models"

const { Product } = models

export default {
  name: "ProductsTable",

  props: {
    // flags
    error:   { type: Boolean, default: false },
    loading: { type: Boolean, default: false },
    empty:   { type: Boolean, default: false }
  },

  data() {
    return {
      i18nScope: "components.product.products-table",
      Product
    }
  },

  computed: {
    hasHeader() {
      return _.has(this.$slots, "header[0]")
    },

    hasFooter() {
      return _.has(this.$slots, "footer[0]")
    }
  }
}

</script>

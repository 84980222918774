<style lang="scss" scoped>

svg {
  width: 32px;
  height: 14px;
}

.outside {
  fill: $orange;
}

.inside {
  fill: $white;
}

</style>


<template lang="pug">

  svg(
    viewBox="0 0 41 18",
    fill="none",
    xmlns="http://www.w3.org/2000/svg"
  )
    rect(
      y="0.5",
      width="41",
      height="17",
      rx="2",
      class="outside"
    )
    path(
      d=`M5.948 13.5C5.668 13.5 5.486 13.3693 5.402 13.108L2.406 4.12C2.39667
        4.092 2.392 4.05 2.392 3.994C2.392 3.91 2.42 3.84 2.476 3.784C2.532 3.728
        2.602 3.7 2.686 3.7H3.932C4.05333 3.7 4.15133 3.73267 4.226 3.798C4.30067
        3.86333 4.352 3.93333 4.38 4.008L6.718 11.176L9.07 4.008C9.08867 3.93333
        9.13533 3.86333 9.21 3.798C9.28467 3.73267 9.38267 3.7 9.504 3.7H10.764C10.8387
        3.7 10.904 3.728 10.96 3.784C11.0253 3.84 11.058 3.91 11.058 3.994L11.03
        4.12L8.048 13.108C7.95467 13.3693 7.77267 13.5 7.502 13.5H5.948ZM14.783
        13.64C13.7377 13.64 12.907 13.3413 12.291 12.744C11.6843 12.1467 11.3623
        11.2973 11.325 10.196L11.311 9.846C11.311 8.67933 11.6143 7.76 12.221
        7.088C12.837 6.416 13.6863 6.08 14.769 6.08C15.8797 6.08 16.7337 6.416 17.331
        7.088C17.9377 7.76 18.241 8.656 18.241 9.776V10.07C18.241 10.1633 18.2083 10.2427
        18.143 10.308C18.0777 10.3733 17.9937 10.406 17.891 10.406H13.243V10.518C13.2617 11.0407
        13.4017 11.4747 13.663 11.82C13.9337 12.156 14.3023 12.324 14.769 12.324C15.301 12.324
        15.735 12.1093 16.071 11.68C16.155 11.5773 16.2203 11.5167 16.267 11.498C16.3137 11.47
        16.393 11.456 16.505 11.456H17.737C17.821 11.456 17.891 11.4793 17.947 11.526C18.0123
        11.5727 18.045 11.638 18.045 11.722C18.045 11.946 17.9097 12.212 17.639 12.52C17.3777
        12.8187 16.9997 13.08 16.505 13.304C16.0103 13.528 15.4363 13.64 14.783 13.64ZM16.309
        9.216V9.188C16.309 8.63733 16.169 8.19867 15.889 7.872C15.6183 7.536 15.245 7.368
        14.769 7.368C14.293 7.368 13.9197 7.536 13.649 7.872C13.3783 8.19867 13.243 8.63733
        13.243 9.188V9.216H16.309ZM23.0034 13.5C22.1541 13.5 21.5147 13.2807 21.0854
        12.842C20.6561 12.394 20.4414 11.7407 20.4414 10.882V7.718H19.3354C19.2421
        7.718 19.1627 7.68533 19.0974 7.62C19.0321 7.55467 18.9994 7.47533 18.9994
        7.382V6.556C18.9994 6.46267 19.0321 6.38333 19.0974 6.318C19.1627 6.25267 19.2421
        6.22 19.3354 6.22H20.4414V3.896C20.4414 3.79333 20.4741 3.714 20.5394 3.658C20.6047
        3.59267 20.6841 3.56 20.7774 3.56H21.9394C22.0421 3.56 22.1214 3.59267 22.1774
        3.658C22.2427 3.714 22.2754 3.79333 22.2754 3.896V6.22H24.0254C24.1187 6.22 24.1981
        6.25267 24.2634 6.318C24.3287 6.38333 24.3614 6.46267 24.3614 6.556V7.382C24.3614
        7.47533 24.3287 7.55467 24.2634 7.62C24.1981 7.68533 24.1187 7.718 24.0254
        7.718H22.2754V10.728C22.2754 11.1293 22.3454 11.4327 22.4854 11.638C22.6254 11.8433 22.8541
        11.946 23.1714 11.946H24.1514C24.2541 11.946 24.3334 11.9787 24.3894 12.044C24.4547
        12.1 24.4874 12.1793 24.4874 12.282V13.164C24.4874 13.2573 24.4547 13.3367 24.3894
        13.402C24.3334 13.4673 24.2541 13.5 24.1514 13.5H23.0034ZM28.9627 13.64C27.8614
        13.64 27.012 13.36 26.4147 12.8C25.8174 12.24 25.4954 11.4653 25.4487 10.476L25.4347
        9.86L25.4487 9.244C25.4954 8.264 25.822 7.494 26.4287 6.934C27.0354 6.36467 27.88 6.08
        28.9627 6.08C30.036 6.08 30.876 6.36467 31.4827 6.934C32.0894 7.494 32.416 8.264
        32.4627 9.244C32.472 9.356 32.4767 9.56133 32.4767 9.86C32.4767 10.1587 32.472 10.364
        32.4627 10.476C32.416 11.4653 32.094 12.24 31.4967 12.8C30.8994 13.36 30.0547 13.64 28.9627
        13.64ZM28.9627 12.226C29.4574 12.226 29.8354 12.072 30.0967 11.764C30.3674 11.456 30.512
        11.0033 30.5307 10.406C30.54 10.3127 30.5447 10.1307 30.5447 9.86C30.5447 9.58933 30.54
        9.40733 30.5307 9.314C30.512 8.71667 30.3674 8.264 30.0967 7.956C29.826 7.63867 29.448
        7.48 28.9627 7.48C28.468 7.48 28.0854 7.63867 27.8147 7.956C27.544 8.264 27.3994 8.71667
        27.3807 9.314L27.3667 9.86L27.3807 10.406C27.3994 11.0033 27.544 11.456 27.8147
        11.764C28.0854 12.072 28.468 12.226 28.9627 12.226ZM34.3635 13.5C34.2702 13.5 34.1908 13.4673
        34.1255 13.402C34.0602 13.3367 34.0275 13.2573 34.0275 13.164V6.57C34.0275 6.46733
        34.0602 6.38333 34.1255 6.318C34.1908 6.25267 34.2702 6.22 34.3635 6.22H35.5115C35.6142
        6.22 35.6982 6.25267 35.7635 6.318C35.8288 6.38333 35.8615 6.46733 35.8615 6.57V7.144C36.3282
        6.528 37.0002 6.22 37.8775 6.22H38.4655C38.5682 6.22 38.6475 6.25267 38.7035 6.318C38.7688
        6.374 38.8015 6.45333 38.8015 6.556V7.578C38.8015 7.67133 38.7688 7.75067 38.7035
        7.816C38.6475 7.88133 38.5682 7.914 38.4655 7.914H37.3595C36.9208 7.914 36.5755
        8.04 36.3235 8.292C36.0715 8.544 35.9455 8.88933 35.9455 9.328V13.164C35.9455
        13.2573 35.9128 13.3367 35.8475 13.402C35.7822 13.4673 35.7028 13.5 35.6095 13.5H34.3635Z`,
      class="inside"
    )

</template>


<script>

export default {
  name: "VetorIcon"
}

</script>

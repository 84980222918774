<script>

// Extends
import AttachmentCard from "@/components/attachments/attachment-card.vue"

// Components
import AttachmentPreview from "./service-order-attachment-preview.vue"

export default {
  components: {
    AttachmentPreview
  },

  extends: AttachmentCard,

  props: {
    serviceOrderId: { type: [String, Number], default: null }
  },

  data() {
    return {
      destroying: false
    }
  },

  computed: {
    extraPreviewProps() {
      return {
        serviceOrderId: this.serviceOrderId
      }
    }
  },

  methods: {
    async destroyRequest() {
      return this.$newSdk.serviceOrders.drafts.attachments.destroy({
        serviceOrderId: this.serviceOrderId,
        attachmentId:   this.attachment.id
      })
    }
  }
}

</script>

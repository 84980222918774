import _ from "@caiena/lodash-ext"
import { Model } from "@caiena/model"

import Base from "./base"

import Address from "./address"
import Attachment from "./attachment"
import Branch from "./branch"
import Brand from "./brand"
import City from "./city"
import Contact from "./contact"
import Customer from "./customer"
import Deal from "./deal"
import DealDataLoad from "./deal-data-load"
import DealMaintenancePackage from "./deal-maintenance-package"
import DealProduct from "./deal-product"
import DealStateChangeEvent from "./deal-state-change-event"
import DraftTicketSupplierBooking from "./draft-ticket-supplier-booking"
import Email from "./email"
import IssuedMailDelivery from "./issued-mail-delivery"
import Maintenance from "./maintenance"
import MaintenancePackage from "./maintenance-package"
import Manufacturer from "./manufacturer"
import MobiautoVersion from "./mobiauto-version"
import MotorOil from "./motor-oil"
import Occurrence from "./occurrence"
import Organization from "./organization"
import Part from "./part"
import Person from "./person"
import Phone from "./phone"
import Product from "./product"
import Sector from "./sector"
import Service from "./service"
import ServiceOrderAssignment from "./service-order-assignment"
import ServiceOrderCancellation from "./service-order-cancellation"
import ServiceOrderDraftService from "./service-order-draft-service"
import ServiceOrderExecution from "./service-order-execution"
import ServiceOrderPostponement from "./service-order-postponement"
import ServiceOrderProduct from "./service-order-product"
import ServiceOrderReopening from "./service-order-reopening"
import ServiceOrderService from "./service-order-service"
import ServiceOrderSupplier from "./service-order-supplier"
import ServiceOrderSupplierAuthorizationMailDelivery from "./service-order-supplier-authorization-mail-delivery"
import ServicePackage from "./service-package"
import ServiceSupport from "./service-support"
import SignedFile from "./signed-file"
import Store from "./store"
import StoreSupplier from "./store-supplier"
import StoreSupplierMaintenanceVehicleModelGroup from "./store-supplier-maintenance-vehicle-model-group"
import Supplier from "./supplier"
import SupplierBlock from "./supplier-block"
import SupplierBusinessHour from "./supplier-business-hour"
import SupplierDetails from "./supplier-details"
import SupplierMaintenanceVehicleModelGroup from "./supplier-maintenance-vehicle-model-group"
import SupplierServiceSupport from "./supplier-service-support"
import SupplierServiceVehicleModelGroup from "./supplier-service-vehicle-model-group"
import Symptom from "./symptom"
import TicketType from "./ticket-type"
import TicketTypeService from "./ticket-type-service"
import Tire from "./tire"
import User from "./user"
import Vehicle from "./vehicle"
import VehicleIssue from "./vehicle-issue"
import VehicleIssueSolution from "./vehicle-issue-solution"
import VehicleModelGroup from "./vehicle-model-group"
import VehicleModelGroupMaintenance from "./vehicle-model-group-maintenance"
import VehicleModelGroupMaintenanceTable from "./vehicle-model-group-maintenance-table"
import VehicleModelGroupMaintenanceTableSettings from "./vehicle-model-group-maintenance-table-settings"
import VehiclePart from "./vehicle-part"
import VehiclePartTarget from "./vehicle-part-target"

import VehicleModel from "./vehicle-model"
import VehicleModelCombustion from "./vehicle-model-combustion"
import VehicleModelElectric from "./vehicle-model-electric"
import VehicleModelHybrid from "./vehicle-model-hybrid"
import VehicleReceipt from "./vehicle-receipt"
import VehicleModelGroupVehicleModel from "./vehicle-model-group-vehicle-model"

import SpareTireSpecification from "./spare-tire-specification"
import TireLoadIndex from "./tire-load-index"
import TireSize from "./tire-size"
import TireSpecification from "./tire-specification"
import TireSpeedRating from "./tire-speed-rating"

import StoreSettings from "./settings/store-settings"
import SupplierServiceScoreSettings from "./settings/supplier-service-score-settings"

import Ticket from "./ticket"
import TicketExternalMaintenance from "./ticket-external-maintenance"
import TicketInternalMaintenance from "./ticket-internal-maintenance"
import TicketRepair from "./ticket-repair"

import ServiceOrder from "./service-order"
import ServiceOrderExternalMaintenance from "./service-order-external-maintenance"
import ServiceOrderInternalMaintenance from "./service-order-internal-maintenance"
import ServiceOrderRepair from "./service-order-repair"

import VehicleServiceOrder from "./vehicle-service-order"

import Quote from "./quote"
import QuoteProduct from "./quote-product"
import QuoteService from "./quote-service"

import ReviewChangesRequest from "./review-changes-request"
import ReviewApproval from "./review-approval"

// Movida
import MovidaAssistant from "./movida/assistant"
import MovidaConsultant from "./movida/consultant"
import MovidaContact   from "./movida/contact"
import MovidaContract  from "./movida/contract"
import MovidaProduct   from "./movida/product"
import MovidaService   from "./movida/service"
import MovidaServiceOrder from "./movida/service-order"
import MovidaServiceOrderAttachment from "./movida/service-order-attachment"
import MovidaServiceOrderProduct from "./movida/service-order-product"
import MovidaServiceOrderService from "./movida/service-order-service"
import MovidaServiceOrderSupplierMessage from "./movida/service-order-supplier-message"
import MovidaServiceOrderTicketMessage from "./movida/service-order-ticket-message"
import MovidaSupplier from "./movida/supplier"
import MovidaVehicle from "./movida/vehicle"
import MovidaVehicleServiceOrderProduct from "./movida/vehicle-service-order-product"
import MovidaVehicleServiceOrderService from "./movida/vehicle-service-order-service"


// Events
import eventsModels from "./events"


const models = {
  Base,

  Events: eventsModels,

  Address,
  Attachment,
  Branch,
  Brand,
  City,
  Contact,
  Customer,
  Deal,
  DealDataLoad,
  DealMaintenancePackage,
  DealProduct,
  DealStateChangeEvent,
  DraftTicketSupplierBooking,
  Email,
  IssuedMailDelivery,
  Maintenance,
  MaintenancePackage,
  Manufacturer,
  MobiautoVersion,
  MotorOil,
  Occurrence,
  Organization,
  Part,
  Person,
  Phone,
  Product,
  Sector,
  Service,
  ServiceOrderAssignment,
  ServiceOrderDraftService,
  ServiceOrderExecution,
  ServiceOrderPostponement,
  ServiceOrderProduct,
  ServiceOrderReopening,
  ServiceOrderService,
  ServiceOrderSupplier,
  ServiceOrderSupplierAuthorizationMailDelivery,
  ServicePackage,
  ServiceSupport,
  SignedFile,
  Store,
  StoreSupplier,
  StoreSupplierMaintenanceVehicleModelGroup,
  Supplier,
  SupplierBlock,
  SupplierBusinessHour,
  SupplierDetails,
  SupplierMaintenanceVehicleModelGroup,
  SupplierServiceSupport,
  SupplierServiceVehicleModelGroup,
  Symptom,
  TicketType,
  TicketTypeService,
  Tire,
  User,
  Vehicle,
  VehicleIssue,
  VehicleIssueSolution,
  VehicleModelGroup,
  VehicleModelGroupMaintenance,
  VehicleModelGroupMaintenanceTable,
  VehicleModelGroupMaintenanceTableSettings,
  VehiclePart,
  VehiclePartTarget,
  VehicleReceipt,

  // VehicleModel
  VehicleModel,
  VehicleModelCombustion,
  VehicleModelElectric,
  VehicleModelHybrid,
  VehicleModelGroupVehicleModel,

  // Tire
  SpareTireSpecification,
  TireLoadIndex,
  TireSize,
  TireSpecification,
  TireSpeedRating,

  // Ticket
  Ticket,
  TicketExternalMaintenance,
  TicketInternalMaintenance,
  TicketRepair,

  // ServiceOrder
  ServiceOrder,
  ServiceOrderCancellation,
  ServiceOrderExternalMaintenance,
  ServiceOrderInternalMaintenance,
  ServiceOrderRepair,

  // Settings
  StoreSettings,
  SupplierServiceScoreSettings,

  // Vetor
  VehicleServiceOrder,

  Quote,
  QuoteProduct,
  QuoteService,

  ReviewChangesRequest,
  ReviewApproval,

  // Movida
  MovidaAssistant,
  MovidaConsultant,
  MovidaContact,
  MovidaContract,
  MovidaProduct,
  MovidaService,
  MovidaServiceOrder,
  MovidaServiceOrderAttachment,
  MovidaServiceOrderProduct,
  MovidaServiceOrderService,
  MovidaServiceOrderSupplierMessage,
  MovidaSupplier,
  MovidaVehicle,
  MovidaVehicleServiceOrderProduct,
  MovidaVehicleServiceOrderService,
  MovidaServiceOrderTicketMessage
}

// providing a lookup for relations
_.assign(Model, {
  $lookupModel(nameOrPath) {
    return _.get(models, nameOrPath)
  }
})


export default models

import { render, staticRenderFns } from "./service-order-row-skeleton.vue?vue&type=template&id=4f8f8efc&scoped=true&lang=pug&"
var script = {}
import style0 from "./service-order-row-skeleton.vue?vue&type=style&index=0&id=4f8f8efc&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4f8f8efc",
  null
  
)

export default component.exports
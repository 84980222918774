<style lang="scss" scoped>

//  .edit-supplier-deal-info {}

.header {
  margin: 24px 0;
}

.title {
  font-family: $secondary-font;
  font-size: 30px;
}

.section + .section {
  margin-top: 24px;
  padding-top: 24px;
  border-top: 1px solid $light-gray-3;
}

.row {
  margin-top: 24px;
}

</style>


<template lang="pug">

  .edit-supplier-deal-info
    form.form(ref="form", @submit.prevent="submit", method="post")
      header.header.flex.space-between
        .title.flex.vertical-center
          h2.title {{ $t('.title') }}
        app-button(type="submit", :loading="loading", theme="link") {{ $t('.btn.save') }}

      section.section.validity-section

        h3.section-title {{ $t('.sections.validity.title') }}

        ui-row.row
          ui-col(:span="3")
            date-field(
              data-format="YYYY-MM-DD",
              name="deal[startAt]",
              :label="$t('.fields.startAt.label')"
              v-model="resource.startAt",
              :loading="loading",
              :errors="errors.startAt",
              :placeholder="$t('.fields.startAt.placeholder')"
            )
          ui-col(:span="3")
            input-field(
              name="deal[duration]",
              :label="$t('.fields.duration.label')"
              v-model.number="resource.duration",
              :loading="loading",
              mask="00000",
              :errors="errors.duration",
              :placeholder="$t('.fields.duration.placeholder')",
              :suffix="$t('.fields.duration.suffix')"
            )

      section.section.labor-section
        h3.section-title {{ $t('.sections.labor.title') }}

        ui-row.row
          ui-col(:span="3")
            money-field(
              name="deal[mechanicLaborPrice]",
              :label="$t('.fields.mechanicLaborPrice.label')"
              v-model="resource.mechanicLaborPrice",
              :loading="loading",
              :errors="errors.mechanicLaborPrice",
              :placeholder="$t('.fields.mechanicLaborPrice.placeholder')",
              :suffix="$t('.fields.mechanicLaborPrice.suffix')"
            )

      section.section.actions-section.flex.space-between.vertical-center
        app-button(
          :to="{ name: 'supplierDeal', params: { id, dealId } }",
          outline,
          theme="gray",
          :loading="submitting",
        )
          span {{ $t('btn.cancel') }}

        app-button(
          type="submit",
          :loading="submitting",
          @click="exitAfterSubmit = true",
        )
          span {{ $t('.btn.saveAndExit.label') }}
          span(slot="loading") {{ $t('.btn.saveAndExit.loading') }}

</template>


<script>

// Mixins
import { mixins } from "movida-common.vue"

// Libs
import HttpError from "@/lib/http-error"

// Extends
import View from "@/views/view"

const { FormMixin } = mixins

export default {
  name: "EditSupplierDealInformation",

  extends: View,

  mixins: [FormMixin],

  props: {
    deal:     { type: Object, default: () => ({}) },
    fetching: { type: Boolean, default: false }
  },

  data() {
    return {
      i18nScope: "suppliers.deals.information.edit",

      id:     this.$params.asInteger(this.$route.params.id),
      dealId: this.$params.asInteger(this.$route.params.dealId),

      dirtyable: true,

      resource: {},

      startAt: null,

      exitAfterSubmit: false
    }
  },

  computed: {
    loading() {
      return this.submitting || this.fetching
    }
  },

  watch: {
    fetching() {
      if (this.fetching) return

      this.initialize()
    }
  },

  created() {
    if (this.fetching) return

    this.initialize()
  },

  methods: {
    initialize() {
      this.resource = this.deal
      this.initialSerializedAttributes = this.serializeAttributes()
    },

    // @override Form mixin
    serializeAttributes() {
      return this.resource.$serialize()
    },

    // @override Form mixin
    submitRequest() {
      return this.$sdk.suppliers.updateDeal({ id: this.id, dealId: this.deal.id, params: this.serializeAttributes() })
    },

    // @override Form mixin
    submitSuccess() {
      this.$notifications.info(this.$t(".notifications.submit.success"))

      // TODO Resolver isso no mixin
      if (this.exitAfterSubmit) {
        this.$router.push({ name: "supplierDeal", params: { id: this.id, dealId: this.dealId } })
      }
      else {
        this.$emit("update")
      }
    },

    // @override Form mixin
    submitError(err) {
      this.$err.log(err)
      this.$notifications.error(this.$t(".notifications.submit.error"))

      let originalError = err instanceof HttpError ? err.original.originalError : err.originalError

      let errData = _.get(originalError, "response.data.errors") || {}
      let errors = this.$i18n.errify(errData, { model: "Deal" })
      this.errors = _.camelizeKeys(errors)
    }
  }
}

</script>

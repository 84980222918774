<style scoped lang="scss">

.maintenance-packages-table {
  .skeleton-table {
    margin-top: 16px;

    .skeleton-row + .skeleton-row {
      margin-top: 24px;
    }
  }

  .empty {
    margin-top: 24px;

    .message {
      font-style: italic;
      font-weight: 400;
    }

    .icon {
      user-select: none;
      margin-bottom: 4px;
      height: 88px;
      width: 88px;
    }
  }
}

</style>


<template lang="pug">

  .maintenance-packages-table
    template(v-if="initializing")
      .skeleton-table
        deal-maintenance-package-row-skeleton.skeleton-row(v-for="i in 5", :key="`deal-maintenance-package-row-skeleton-${i}`")

    template(v-else)
      deal-maintenance-packages-table-header(
        :loading="loading",
        @sort="param => $emit('sort', param)"
        :sort-param="sortParam",
        :sort-order="sortOrder",
        data-testid="dealMaintenancePackagesTableHeader"
      )

      template(v-if="empty")
        .empty.flex.center
          .flex.column-direction.vertical-center
            img.icon(:src="emptyIcon")
            span.message {{ $t('index.empty.message') }}

      template(v-else)
        deal-maintenance-package-row(
          v-for="dealMaintenancePackage in dealMaintenancePackages",
          :key="`dealMaintenancePackage-${dealMaintenancePackage.id}`",
          :deal-maintenance-package="dealMaintenancePackage",
          :editable="editable",
          :loading="loading",
          @destroy="$emit('destroy')",
          data-testid="dealMaintenancePackageRow"
        )

</template>


<script>

// Assets
import emptyIcon from "@/assets/images/icons/search/products/none-found.svg"

// Components
import DealMaintenancePackageRow from "./deal-maintenance-package-row"
import DealMaintenancePackageRowSkeleton from "./deal-maintenance-package-row-skeleton"
import DealMaintenancePackagesTableHeader from "./deal-maintenance-packages-table-header"

export default {
  components: {
    DealMaintenancePackageRow,
    DealMaintenancePackageRowSkeleton,
    DealMaintenancePackagesTableHeader
  },

  props: {
    dealMaintenancePackages: { type: Array, required: true },
    editable:                { type: Boolean, default: false },
    initializing:            { type: Boolean, default: false },
    loading:                 { type: Boolean, default: false },
    sortedBy:                { type: String, default: "maintenance_package_name:asc" }
  },

  data() {
    return {
      i18nScope: "suppliers.deals.maintenance-packages.index.deal-maintenance-packages-table",

      emptyIcon
    }
  },

  computed: {
    empty() {
      return _.blank(this.dealMaintenancePackages)
    },

    sortParam() {
      return this.sortedBy.split(":")[0]
    },

    sortOrder() {
      return this.sortedBy.split(":")[1] || "asc"
    }
  }
}

</script>

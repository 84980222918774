<style lang="scss" scoped>
.service-order-associations-section {
  align-items: stretch;
  display: grid;
  gap: 80px;
  grid-template-columns: repeat(3, minmax(0, 1fr));

  .section {
    position: relative;

    &:not(:first-child)::before {
      border-left: 1px solid $light-gray-3;
      content: ' ';
      height: 100%;
      left: -40px;
      position: absolute;
    }

    ::v-deep .header {
      margin-bottom: 0 !important;
    }
  }
}
</style>

<template lang="pug">
  .service-order-associations-section
    service-order-section-layout.section
      template(#icon)
        vehicle-manufacturer-icon(:value="vehicleManufacturerName")
      template(#title) {{ vehicleModelName }}
      .vehicle-model
        license-plate(:value="vehicleLicensePlate")

    service-order-section-layout.section
      template(#icon)
        i.fas.fa-user-hard-hat
      template(#title) {{ supplierName }}
      span {{ supplierAddress }}

    service-order-section-layout.section
      template(#icon)
        i.fas.fa-user
      template(#title) {{ customerName }}
      business-unit-tag(:value="serviceOrder.businessUnit")
</template>

<script>
import ServiceOrderSection from "./service-order-section"

export default {
  extends: ServiceOrderSection,

  computed: {
    customerName() {
      const { customer } = this.serviceOrder

      if (!customer) return ""
      return customer.tradingName || customer.name
    },

    descriptionText() {
      const { description, ticketDescription } = this.serviceOrder

      return this.hasDescription
        ? ticketDescription || description
        : this.$t(".empty")
    },

    hasDescription() {
      return this.serviceOrder.$present("ticketDescription")
        || this.serviceOrder.$present("description")
    },

    supplierAddress() {
      return this.serviceOrder.supplier.simpleAddress
    },

    supplierName()    {
      return this.serviceOrder.supplier.tradingName
    },

    vehicleCategory()     {
      return this.serviceOrder.vehicle.category
    },

    vehicleLicensePlate() {
      return this.serviceOrder.vehicle.licensePlate
    },

    vehicleManufacturerName() {
      return this.serviceOrder.vehicle.manufacturerName
    },

    vehicleModelName()    {
      return this.serviceOrder.vehicle.versionName
    }
  }
}
</script>

<style lang="scss" scoped>

.store-multiple-select-field {
  &.selected {
    ::v-deep .placeholder {
      color: $dark-gray;
    }
  }

  .selected-items {
    padding: 8px;
    font-family: $secondary-font;
    font-weight: 400;
    font-size: 14px;
    border-bottom: 1px solid $light-gray-3;

    .link {
      font-size: 14px;
      font-weight: 400;
    }

    .icon {
      font-size: 12px;
      margin-right: 4px;
      margin-left: 8px;
    }
  }

  .error {
    display: block;
    text-align: center;
    padding: 4px 12px;
    font-size: 14px;
    font-style: italic;

    .link {
      margin-left: 5px;
      font-size: 14px;
      font-style: italic;
      font-weight: normal;
    }
  }

  .empty {
    display: block;
    text-align: center;
    padding: 4px 12px;
    font-size: 14px;
    font-style: italic;
  }

  .option {
    min-height: 40px;
    padding: 8px 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid $light-gray-3;
    cursor: pointer;

    .remove-icon-content {
      width: 24px;
      height: 24px;
      line-height: 24px;
      text-align: center;
      border-radius: 50px;
      color: $gray-2;
      transition: all 100ms linear;
    }

    &.active {
      color: $primary;
      background: $light-gray-2;
    }

    &.focus {
      background: $light-gray-2;
      color: $dark-primary-2;

      .remove-icon-content {
        background: $light-gray-3;
        color: $primary;
      }
    }
  }
}

</style>


<template lang="pug">

  .store-multiple-select-field(:class="{ selected: hasSelected }")
    select-field(
      :name="name",
      :label="label",
      :optional="optional",
      :hide-label="hideLabel",
      :fetching="fetching",

      :option-as-value="optionAsValue",
      :loading="loading || fetchingOption",

      :options="options",
      :disabled="disabled",
      :default-filter="false",
      :errors="errors",
      searchable,

      :placeholder="selectedItemsText"

      @input="onSelect",

      @search="onSearch",
      @open="_fetch",
      @close="onClose",
      @inputSearch="newSearch => search = newSearch"
    )
      template(#menuContentHeader)
        template(v-if="fetchError")
          label.error
            span {{ $t('.error.message') }}
            app-button.link(theme="link", @click="onSearch") {{ $t('.error.link') }}

        .selected-items.flex.vertical-center(v-else-if="hasSelected")
          app-span(crop, :value="selectedItemsText")
          app-button.link(theme="link", @click="onClear")
            i.icon.far.fa-times
            span {{ $t('.clear.label') }}

      template(#menuContentFooter)
        template(v-if="!fetching && !fetchError && empty")
          label.empty {{ $t('.empty') }}

      template(#fetching)
        li.option {{ $t('.loading') }}

      template(#option="{ props: { option, index, focus } }")
        .option(:class="{ focus, active: isActiveOption(option) }")
          span {{ option.label }}
          .remove-icon-content(v-if="isActiveOption(option)")
            i.fal.fa-times

</template>


<script>

// Mixins
import FieldMixin from "@/mixins/field"

export default {
  mixins: [FieldMixin],

  props: {
    value:         { type: Array, default: () => [] },
    optional:      { type: Boolean, default: false },
    hideLabel:     { type: Boolean, default: false },
    optionAsValue: { type: Boolean, default: false }
  },

  data() {
    return {
      i18nScope: "vehicles.index.store-multiple-select-field",

      search:         "",
      options:        [],
      fetchingOption: false,
      fetchError:     false,

      // Async
      fetching: false,
      request:  null
    }
  },

  computed: {
    selectedItemsText() {
      return this.$t(".placeholder", { count: this.value.length })
    },

    hasSelected() {
      return _.present(this.value)
    },

    empty() {
      return this.options.length === 0
    }
  },

  methods: {
    onSelect(option) {
      if (!option) return

      let selectedOptions = this.value
      const optionIndex = selectedOptions.findIndex(store => {
        if (this.optionAsValue) return store.value === option.value
        return store === option
      })

      if (optionIndex < 0) selectedOptions = [...selectedOptions, option]
      else selectedOptions.splice(optionIndex, 1)

      this.$emit("input", selectedOptions)
    },

    async onSearch() {
      this.fetching = true
      this.fetchError = false
      this.fetch()
    },

    isActiveOption(option) {
      return this.optionAsValue
        ? !!this.value.find(store => String(store.value) === String(option.value))
        : !!this.value.find(store => String(store) === String(option.value))
    },

    onClose() {
      this.options = []
    },

    onClear() {
      this.$emit("input", [])
    },

    // Busca assincrona de _options_
    async _fetch() {
      let currentRequest

      try {
        if (this.request) this.request.cancel("Duplicated fetch")

        currentRequest = this.$newSdk.stores.all({ params: { q: this.search } })
        this.request = currentRequest

        this.fetching = true

        const { data } = await currentRequest

        this.options = data.map(store => ({
          value: store.id,
          label: `${store.initials} - ${store.name}`,
          data:  store
        }))
      }
      catch (error) {
        this.fetchError = true
        this.$err.log(error)
      }
      finally {
        if (this.request === currentRequest) {
          this.request = null
          this.fetching = false
        }
      }
    },

    // Debounce para evitar sobrecarregar servidor com disparos de busca
    fetch: _.debounce(function debounce() {
      this._fetch()
    }, 500)
  }
}

</script>

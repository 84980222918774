<!--

  "Árvore de Ordens de Serviço": componente que, a partir de um +rid+ de uma OS, se autocarrega
  e exibe elementos para navegação entre as OSs correlacionadas (principal/complementares).

  A navegação é controlada internamente, sendo os nós âncoras com hyperlink.

  usage:
    tree(
      :service-order-rid="params.rid"
      :value="params.rid"
    )

-->

<style lang="scss" scoped>

.tree {
  padding: 16px;
  width:   400px;

  > .loading {
    width: 100%;
  }

  > .erred {
    font-size: 16px;
  }

  > .content {
    gap: 16px;

    > .icon-frame {
      width:  20px;
      height: 20px;

      display: flex;
      align-items: center;      // vertical-align
      justify-content: center;  // horizontal-align

      > .icon {
        font-weight: 900;
        font-size:   14px;
        line-height: 14px;
        color: $gray-3;
      }
    }
  }

  .empty {
    .empty-message {
      font-weight: 400;
      font-style: italic;
      font-size: 14px;
      color: $gray-3;
    }
  }
}


</style>


<template lang="pug">

  .tree
    template(v-if="loading")
      .loading
        app-loading-lines(:lines="1")

    template(v-else-if="erred")
      .erred.flex-col.v-align-center
        span.text-center {{ $t(".erred.message.text") }}
        app-button.retry(theme="link", @click="$fetch") {{ $t(".erred.retry.message") }}

    template(v-else)
      .content.flex-row.wrap.v-align-middle
        tree-node(
          :value="parent"
          :selected="parent.rid == serviceOrderRid"
        )

        template(v-if="_.present(children)")
          .icon-frame
            i.icon.fal.fa-arrow-right-long

          .icon-frame(v-tooltip="$t('.children.tooltip')")
            i.icon.fas.fa-rectangle-vertical-history

          template(v-for="child in children")
            tree-node(
              :value="child"
              :selected="child.rid == serviceOrderRid"
            )

</template>


<script>

import TreeNode from "./tree-node.vue"


export default {
  components: {
    TreeNode
  },

  emits: [],

  props: {
    serviceOrderRid: { type: String, required: true }, // reference serviceOrder
    value:           { type: String, required: true }  // currently selected value
  },

  data() {
    return {
      i18nScope: "monitoring.service-orders.tree",

      // flags
      initialized: false,
      erred:       false,
      fetching:    false,
      submitting:  false,

      errors:   {},
      resource: null
    }
  },

  computed: {
    tree: {
      get()      { return this.resource  },
      set(value) { this.resource = value }
    },

    initializing() {
      return !this.initialized
    },

    loading() {
      return this.initializing || this.fetching || this.submitting
    },


    parent()   { return this.tree?.parent   },
    children() { return this.tree?.children }
  },

  created() {
    this.$initialize()
  },

  beforeDestroy() {
    if (this.loading) this.$cancel()
  },

  methods: {
    async $initialize() {
      await this.initialize()

      this.initialized = true
    },

    async initialize() {
      await this.$fetch()
    },

    async $cancel() {
      if (this.fetchPromise) this.fetchPromise.cancel()

      await this.cancel()
    },

    async cancel() {},

    async $fetch() {
      if (this.fetching) await this.$cancel()

      this.fetching = true

      await this.fetch()

      this.fetching = false
    },

    async fetch() {
      try {
        this.fetchPromise = this.$newSdk.monitoring.serviceOrders.tree(this.serviceOrderRid)
        let response = await this.fetchPromise

        this.tree = response.data
      }
      catch (err) {
        let error = err.error || err

        // XXX: ignora se a request (axios) tiver sido cancelada
        if (!error.cancelled) return

        this.erred = true
        this.$err.log(err)
        this.$notifications.error(this.$t(".notifications.fetch.failure"))
      }
      finally {
        this.fetchPromise = null
      }
    }
  }
}

</script>

<style lang="scss" scoped>

.action {
  color: $gray-2;
  font-size: 14px;
}

.header {
  .heading {
    display: inline-block;

    font-size: 18px;
    font-weight: 500;
    height: 32px;
    line-height: 32px;
    font-family: $secondary-font;
  }

  .icon {
    color: $dark-gray;
    font-size: 20px;
  }
}


.body {
  .info:not(:last-of-type) {
    margin-right: 24px;
  }

  .section {
    padding: 16px 24px;
  }

  .section + .section {
    border-top: 1px solid $light-gray-3;
  }
}

.date {
  width: 280px;
}

.icon {
  margin-right: 8px;
}

.overlap-error {
  background-color: $light-warning;
  border-radius: 4px;
  margin-top: 24px;
  padding: 4px 8px;
  color: $dark-warning;
  font-size: 14px;

  .message {
    font-family: $secondary-font;
    font-weight: 500;
  }
}

</style>


<template lang="pug">

  .block-supplier
    slot(name="link", :props="{ openBlockForm: openForm }")
      app-button.action(
        theme="link",
        @click="openForm"
      )
        i.icon.far.fa-times-circle
        span {{ $t('.block') }}

    .modal(v-if="showModal")
      app-modal.block(
        v-if="!showConfirmation",
        :width="1200",
        footer,
        :confirm-label="$t('.btn.block')",
        @confirm="confirmSubmit"
        @close="closeModal",
      )
        template(slot="header")
          .header.flex.vertical-center
            i.icon.fas.fa-times-circle
            h1.heading {{ $t('.heading') }}

        form.form(
          ref="form",
          method="post",
          @submit.prevent="confirmSubmit"
        )
          .body
            section.section
              supplier-info-card.supplier-info-card(:resource="supplier")

            section.section
              ui-row
                info-label.info(
                  :label="$t('models.supplierBlock.attributes.author')",
                  :value="user.name"
                )
                datetime-field.info.date(
                  name="supplierBlock[startAt]"
                  :label="$t('models.supplierBlock.attributes.startAt')",
                  :placeholder="$t('.fields.startAt.placeholder')",
                  future-only,
                  :min-date="openTime",
                  v-model="resource.startAt",
                  :errors="errors.startAt"
                )
                datetime-field.info.date(
                  name="supplierBlock[endAt]"
                  :label="$t('models.supplierBlock.attributes.endAt')",
                  :placeholder="$t('.fields.endAt.placeholder')",
                  future-only,
                  :min-date="resource.startAt",
                  v-model="resource.endAt",
                  :errors="errors.endAt"
                )

            section.section
              textarea-field(
                name="supplierBlock[description]",
                :placeholder="$t('.fields.description.placeholder')",
                v-model="resource.description",
                :errors="errors.description"
              )

              .overlap-error.inline-flex.vertical-center(v-if="errors.base")
                i.icon.fas.fa-exclamation-triangle
                .message {{ $t('models.supplierBlock.errors.base.overlapsAnotherBlock') }}

      app-modal.confirmation(
        v-else,
        footer,
        :close-button="!submitting",
        :width="920",
        :heading="$t('.confirmation.heading', { name: supplier.name })",
        :loading="submitting",
        :confirm-label="$t('.confirmation.btn.submit.label')",
        :confirm-label-loading="$t('.confirmation.btn.submit.loading')",
        @close="closeModal",
        @cancel="showConfirmation = false",
        @confirm="submit"
      )
        .body
          span.text {{ $t('.confirmation.message') }}

</template>


<script>

// Mixins
import { mixins } from "movida-common.vue"

// Models
import models from "@/models"

const { FormMixin } = mixins
const { SupplierBlock } = models

export default {
  mixins: [FormMixin],

  props: {
    supplier: { type: Object, default: () => ({}) }
  },

  data() {
    return {
      i18nScope: "suppliers.components.block-supplier",

      showModal:        false,
      showConfirmation: false,

      openTime: null,

      resource: {},
      model:    "SupplierBlock",
      errors:   {},

      user: this.$auth.user
    }
  },

  methods: {
    openForm() {
      this.openTime = moment.utc().toISOString()

      this.resource = new SupplierBlock({
        startAt: this.openTime
      })

      this.showModal = true
    },

    closeModal() {
      if (!this.submitting) {
        this.showModal = false
        this.showConfirmation = false

        this.errors = {}
      }
    },

    async confirmSubmit() {
      if (!(await this.validate())) return

      this.showConfirmation = true
    },

    // @override Form mixin
    submitRequest() {
      return this.$sdk.suppliers.block({
        id: this.supplier.id,
        params: this.resource.$serialize()
      })
    },

    // @override Form mixin
    async submitSuccess(response) {
      this.$notifications.info(
        this.$t(".notifications.submit.success", {
          name: this.supplier.name
        }))

      this.closeModal()
      this.$emit("blocked")
    },

    // @override Form mixin
    async submitError(err) {
      this.showConfirmation = false
      this.$err.log(err)

      this.$notifications.error(this.$t(".notifications.submit.error"))
    }
  }
}

</script>

<style lang="scss" scoped>

.edit {
  .section {
    & + .section {
      border-top: 1px solid $light-gray-3;
      margin-top: 24px;
      padding-top: 24px;
    }

    &.header {
      .left {
        margin-bottom: 8px;

        .id-tag {
          margin: 0 8px;
        }

        .title,
        .subtitle {
          font-family: $secondary-font;
          font-weight: 500;
        }

        .subtitle {
          font-size: 20px;
        }

        .title {
          font-size: 38px;
        }
      }

      .services-badge {
        margin-right: 16px;
      }
    }

    &.footer {
      .confirm {
        margin-right: 18px;
      }

      .back {
        margin-right: 24px;

        .icon {
          margin-right: 8px;
        }
      }

      .next .icon {
        margin-left: 8px;
      }
    }
  }
}

</style>


<template lang="pug">

  .edit
    .section.header.flex.space-between
      .left
        .flex.vertical-center
          h2.subtitle {{ ServiceOrderRepair.$tEnum('kind', serviceOrder.kind) }}
          id-tag.id-tag(:value="ticket.id")
          construction-badge.no-shrink

        h1.title {{ $t('.title') }}

      .flex
        //- services-selected-badge.services-badge(:services-count="10")
        vehicle-info-tag.no-shrink(:vehicle="vehicle")

    .section.content
      transition(name="fade", mode="out-in")
        template(v-if="showSupplier")
          supplier-edit(
            :service-order-id="serviceOrder.id",
            :supplier-id="selectedSupplierId",
            :show-badge="selectedSupplierBest",
            :starting-at="startingAt",
            :resource="resource",
            :errors="errors",
            @back="backToList",
            @load="onLoadSelectedSupplier"
          )

        template(v-else)
          suppliers-index(
            :service-order-id="serviceOrder.id",
            :submitting="submitting",
            @show="onSelectSupplier"
          )

    .section.footer.flex.space-between
      .cancel-actions.flex.vertical-center
        app-button.back(
          outline,
          theme="gray",
          :loading="submitting",
          :to="{ name: 'editDraftTicketContent', params: { id: ticket.id } }"
        )
          i.icon.far.fa-arrow-left
          span {{ $t('.btn.back') }}

        destroy-ticket-action(
          :ticket-id="ticket.id",
          :ticket-store-id="serviceOrder.storeId",
          :loading="submitting",
          @destroy="onDestroy"
        )

      .flex
        app-button.confirm(
          :loading="submitting",
          theme='link'
          @click="redirectToTickets"
        )
          span {{ $t('.btn.confirmAndExit') }}

        transition(name="fade", mode="out-in")
          app-button.next(
            v-if="hasResource",
            :loading="submitting",
            @click="submit"
          )
            span {{ $t('.btn.next') }}
            i.icon.far.fa-arrow-right

</template>


<script>

// View
import LoadedView from "@/views/loaded-view"

// Mixins
import FormMixin from "@/mixins/form-mixin"

// Components
import ConstructionBadge from "../components/construction-badge.vue"
import DestroyTicketAction from "../components/destroy-ticket-action.vue"
import VehicleInfoTag from "../components/vehicle-info-tag.vue"
import ServicesSelectedBadge from "./edit/services-selected-badge.vue"
import SuppliersIndex from "./suppliers/index.vue"
import SupplierEdit from "./suppliers/edit.vue"
import SupplierInfoCard from "./components/supplier-info-card.vue"

// Models
import models from "@/models"
const { ServiceOrderRepair, Base } = models

class SupplierReservation extends Base {
  static get attrs() {
    return _.uniq([
      ...super.attrs,
      "supplierId",
      "estimatedStartAt",
      "estimatedCompletedAt",
      "pickupService"
    ])
  }

  static get constraints() {
    return {
      supplierId:           { presence: true },
      estimatedStartAt:     { presence: true },
      estimatedCompletedAt: { presence: true }
    }
  }
}

export default {
  components: {
    ConstructionBadge,
    DestroyTicketAction,
    VehicleInfoTag,
    ServicesSelectedBadge,
    SuppliersIndex,
    SupplierEdit,
    SupplierInfoCard
  },

  extends: LoadedView,

  mixins: [FormMixin],

  props: {
    ticket: { type: Object, required: true }
  },

  data() {
    return {
      i18nScope: "tickets.drafts.bookings.edit",
      routeName: "editDraftTicketBooking",

      resource: new SupplierReservation(),

      guardFormOnLeave: false,

      ServiceOrderRepair,

      showSupplier:         false,
      selectedSupplierId:   null,
      selectedSupplierBest: null,
      startingAt:           moment().toISOString()
    }
  },

  computed: {
    vehicle() {
      return _.get(this.ticket, "vehicle")
    },

    serviceOrder() {
      return _.get(this.ticket, "serviceOrders[0]")
    },

    hasResource() {
      return _.present(_.get(this.resource, "supplierId"))
    }
  },

  methods: {
    parseRoute() {},

    onDestroy() {
      this.redirectToTickets()
    },

    onLoadSelectedSupplier(serviceOrderSupplier) {
      const { supplierId, supplierAvailableAt, supplier } = serviceOrderSupplier

      this.resource = new SupplierReservation({
        supplierId,
        estimatedStartAt:     supplierAvailableAt.startAt,
        estimatedCompletedAt: supplierAvailableAt.endAt,
        pickupService:        supplier.pickupService
      })

      this.initialSerializedAttributes = this.serializeAttributes()
      this.guardFormOnLeave = true
    },

    onSelectSupplier({ serviceOrderSupplier, startingAt, showBadge }) {
      this.selectedSupplierId = serviceOrderSupplier.supplierId
      this.startingAt = startingAt
      this.selectedSupplierBest = showBadge
      this.showSupplier = true
    },

    backToList() {
      this.showSupplier = false
      this.resource = new SupplierReservation()
    },

    // @override Form mixin
    serializeAttributes() {
      const serviceOrdersAttributes = [this.resource.$serialize()]

      return {
        service_orders_attributes: serviceOrdersAttributes
      }
    },

    // @override Form mixin
    submitRequest() {
      return this.$newSdk.tickets.drafts.bookings.update({ ticketId: this.ticket.id, params: this.serializeAttributes() })
    },

    onSubmitError(err) {
      const error = err.error || err

      if (error.cancelled) return

      this.submitError = error
      this.submitErred = true

      if (err.statusCode === 422) this.$notifications.error(this.$t(".notifications.submit.conflict"))
      else {
        this.$err.log(this.submitError)
        this.$notifications.error(this.$t(".notifications.submit.error"))
      }

      if (error.originalError) this.setFormErrors(error.originalError)
    },

    // @override Form mixin
    onSubmitSuccess(response) {
      this.$emit("reload")

      this.$router.push({ name: "newDraftTicketConfirmation", params: { id: this.ticket.id } })
    },

    redirectToTickets() {
      this.$router.push({ name: "tickets" })
    }
  }
}

</script>

<style lang="scss" scoped>

.new {
  padding: 16px;

  .title {
    font-family: $secondary-font;
    font-weight: 500;
    font-size: 18px;
  }

  .subtitle {
    font-family: $primary-font;
    font-weight: 300;
    font-size: 14px;
  }

  .field,
  .footer {
    margin-top: 16px;
  }

  .description {
    ::v-deep .input-wrapper {
      display: flex;
      flex-grow: 1;
    }
  }

  .cancel {
    font-family: $secondary-font;
    font-weight: 400;
    font-size: 14px;
    color: $dark-gray;

    &:hover {
      color: $orange;
    }

    &:active,
    &:focus {
      color: $dark-orange-2;
    }
  }
}

</style>


<template lang="pug">

  .new.flex.column-direction
    h2.title {{ $t('.title') }}
    h3.subtitle {{ $t('.subtitle', { author }) }}

    motive-select-field.field(
      v-model="resource.motive",
      mandatory,
      name="message[motive]",
      :errors="errors.motive",
      :label="MovidaServiceOrderSupplierMessage.$tAttr('motive')"
      :loading="submitting",
      data-testid="motiveInput"
    )

    rich-textarea-field.field.description.flex.column-direction.grow(
      v-model="resource.description",
      mandatory,
      name="message[description]",
      :loading="submitting",
      :errors="errors.description",
      :label="MovidaServiceOrderSupplierMessage.$tAttr('description')",
      :placeholder="$t('.fields.description.placeholder')",
      data-testid="descriptionInput"
    )

    footer.footer.flex.vertical-center.space-between
      app-button.cancel(
        theme="link",
        @click="onClose"
      ) {{ $t('.btn.cancel.label') }}

      app-button(@click="submit") {{ $t('.btn.submit.label') }}

</template>


<script>

// Mixins
import FormMixin from "@/mixins/form-mixin"

// Modules
import auth from "@/modules/auth"

// Components
import MotiveSelectField from "./_components/motive-select-field.vue"

// Models
import models from "@/models"
const { MovidaServiceOrderSupplierMessage } = models


export default {
  components: {
    MotiveSelectField
  },

  mixins: [FormMixin],

  props: {
    serviceOrder: { type: Object, required: true }
  },

  data() {
    return {
      i18nScope: "monitoring.messages.new",

      resource: new MovidaServiceOrderSupplierMessage({
        at: moment().toISOString()
      }),

      MovidaServiceOrderSupplierMessage,

      user: auth.user
    }
  },

  computed: {
    author() {
      return this.user?.name
    }
  },

  created() {
    this.initialSerializedAttributes = this.serializeAttributes()
  },

  methods: {
    async onClose() {
      const isDirty = this.checkDirty()

      if (!isDirty || await this.askDirtyLeaveConfirmation()) this.$emit("close")
    },

    // @override Form mixin
    serializeAttributes() {
      return this.resource.$serialize()
    },

    // @override Form mixin
    submitRequest() {
      return this.$newSdk.monitoring.serviceOrders.messages.create({
        serviceOrderId: this.serviceOrder.$id,
        params:         this.serializeAttributes()
      })
    },

    // @override Form mixin
    onSubmitSuccess(response) {
      // XXX: usando id do Vetor (remoteId) para ficar coerente com a listagem
      let successMessage = this.$t(".notifications.submit.success", { id: this.serviceOrder.remoteId })
      this.$notifications.info(successMessage)

      this.$emit("close")
    },

    // @override Form mixin
    afterSubmitSuccess() {
      this.$emit("change")
      this.$emit("close")
    }
  }
}

</script>

<style lang="scss" scoped>

.actions {
  margin-bottom: 8px;
}

.icon {
  font-size: 16px;
  margin-right: 8px;
}

.section + .section {
  margin-top: 24px;
  padding-top: 24px;
  border-top: 1px solid $light-gray-3;
}

.title {
  font-family: $secondary-font;
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 16px;
}

.info-label + .info-label {
  margin-top: 10px;
}

.address-content {
  margin-bottom: 12px;
}

</style>


<template lang="pug">

  .store-info
    .actions.flex.end
      app-button.btn(
        v-if="$can('update', store)",
        :to="{ name: 'editStore', params: { id } }",
        theme="neutral",
        size="small",
        data-testid="editStore"
      )
        i.fal.fa-pencil.icon
        span {{ $t('.btn.edit') }}

    .flex.section.info-section
      ui-row
        ui-col(:span="4")
          info-label.info-label(
            :value="store.cnpj",
            theme="secondary",
            :tooltip="{ offset: '4', content: Store.$tAttr('cnpj') }",
            data-testid="cnpj"
          )
            span(slot="icon")
              img.custom(:src="storesIcon")

          info-label.info-label(
            :value="store.sapId",
            theme="secondary",
            :tooltip="{ offset: '4', content: Store.$tAttr('sapId') }",
            data-testid="sapId"
          )
            span(slot="icon")
              sap-icon(color="gray")

        ui-col(:span="4")
          info-label.info-label(
            :value="store.email",
            icon="fal fa-envelope",
            theme="secondary",
            :empty-text="$t('.fields.email.empty')",
            :tooltip="{ offset: '4', content: Store.$tAttr('email') }",
            data-testid="email"
          )

          info-label.info-label(
            :value="store.phone",
            icon="fal fa-phone",
            theme="secondary",
            :empty-text="$t('.fields.phone.empty')",
            :tooltip="{ offset: '4', content: Store.$tAttr('phone') }",
            data-testid="phone"
          )

    .section
      h2.title {{ $t('.sections.manager.title') }}

      info-label.info-label(
        :value="store.fleetManagerName",
        icon="fal fa-user",
        theme="secondary",
        :tooltip="{ offset: '4', content: Store.$tAttr('fleetManagerName') }"
      )
      info-label.info-label(
        :value="store.fleetManagerEmail",
        icon="fal fa-envelope",
        theme="secondary",
        :tooltip="{ offset: '4', content: Store.$tAttr('fleetManagerEmail') }"
      )

    .section(v-if="hasLatLng")
      h2.title {{ $t('.sections.location.title') }}

      .address-content
        label.address {{ address.addressLine1 }}
        label.complement(v-if="address.addressLine2") {{ address.addressLine2 }}

      info-label.info-label(
        :value="latLng",
        icon="fal fa-location",
        theme="secondary",
        :tooltip="{ offset: '4', content: $t('.fields.latlng.tooltip') }",
        data-testid="latLng"
      )

</template>


<script>

// Icons
import storesIcon from "@/assets/images/icons/sidebar/stores-icon.svg"

// Models
import models from "@/models"
const { Store } = models

export default {
  name: "StoreDefaultTab",

  props: {
    store: { type: Object, default: () => ({}) }
  },

  data() {
    return {
      i18nScope: "stores.default-tab.show",

      id: this.$route.params.id,

      Store,
      storesIcon
    }
  },

  computed: {
    address() {
      return this.store.$get("address") || {}
    },

    hasLatLng() {
      return _.present(this.store.lat) && _.present(this.store.lng)
    },

    latLng() {
      return `(${this.store.lat}, ${this.store.lng})`
    }
  }
}

</script>

import VTooltip from "v-tooltip"

import { components as utilsComponents }  from "utils.vue"
import { components as commonComponents } from "movida-common.vue"

import globalComponents from "@/components"


const { Notifications, Loading } = utilsComponents

const commonComponentsArray = Object.keys(commonComponents).map(key => commonComponents[key])
const components = [...commonComponentsArray, ...globalComponents]


export default function initialize(Vue) {
  // 3rd Party components
  Vue.use(VTooltip, {
    // XXX: Para os tooltips ficarem sempre visíveis, independente do container, como no caso da sidebar
    // ref https://github.com/Akryum/floating-vue/issues/192#issuecomment-514328339
    defaultBoundariesElement: "window"
  })

  _.forEach(components, component => {
    Vue.component(component.name, component)
  })

  Vue.component(Notifications.name, Notifications)
  Vue.component(Loading.name, Loading)
}

import { render, staticRenderFns } from "./vehicle-model-group-row-skeleton.vue?vue&type=template&id=21017f13&scoped=true&lang=pug&"
var script = {}
import style0 from "./vehicle-model-group-row-skeleton.vue?vue&type=style&index=0&id=21017f13&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "21017f13",
  null
  
)

export default component.exports
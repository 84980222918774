import Base from "./base"

class StoreSupplier extends Base {
  static get attrs() {
    return _.uniq([
      ...super.attrs,
      "storeId",
      "supplierId"
    ])
  }

  static get relations() {
    return {
      store:    { type: "belongsTo", model: "Store" },
      supplier: { type: "belongsTo", model: "Supplier" }
    }
  }
}

export default StoreSupplier

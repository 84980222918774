<style lang="scss" scoped>

.actions {
  .header-button {
    z-index: 402;
    border: 0;
    outline: 0;
    padding: 0;
    margin: 0;
    position: absolute;
    top: 32px;
    width: 16px;
    height: 16px;
    font-size: 16px;
    color: $dark-gray;
    background-color: transparent;
    cursor: pointer;

    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
      color: $black;
    }

    &.edit {
      right: 69px;
    }

    &.destroy {
      right: calc(110px);
    }
  }
}

.info-label {
  + .info-label {
    margin-top: 28px;
  }

  ::v-deep .movida-common__info-label-content {
    align-items: flex-start;
  }

  .icon {
    color: $dark-gray;
    font-size: 20px;
    width: 20px;
    text-align: center;
    margin-right: 16px;
  }

  .diamond-icon {
    margin: 0 8px;
    font-size: 4px;
    color: $gray-3;
  }

  .info {
    font-weight: 400;
  }

  .kind {
    font-size: 14px;
    color: $gray-3;
  }

  .copy-icon {
    margin-left: 8px;
    font-size: 14px;
    color: $gray-2;
  }

  .contact-value + .contact-value {
    margin-top: 16px;
  }
}

header.header {
  padding-top: 16px;
  padding-bottom: 16px;

  .title {
    font-family: $secondary-font;
    font-weight: 400;
    font-size: 20px;
    max-width: 540px;

    &.edit {
      max-width: 470px;
    }

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
  }

  .avatar {
    margin-right: 16px;
  }
}

</style>


<template lang="pug">

  app-modal.supplier-contact(
    :width="690",
    @close="$emit('close')"
  )
    template(#header)
      .actions(v-if="edit")
        app-button.header-button.edit(theme="link", @click="editContact")
          i.fal.fa-pencil(aria-hidden="true")

        app-button.header-button.destroy(theme="link", @click="destroyContact")
          i.fal.fa-trash-alt(aria-hidden="true")

      header.header.flex.vertical-center
        avatar.avatar
        h1.title(:class="{ edit }") {{ contact.name }}

    .body
      info-label.info-label.area
        template(#icon)
          i.icon.fal.fa-briefcase

        template(#default, v-if="hasArea")
          .flex.vertical-center
            span.info {{ contact.area }}
            i.diamond-icon.fa.fa-diamond.flex.center.vertical-center
            span.kind {{ $t('.area') }}

      info-label.info-label.emails
        template(#icon)
          i.icon.fal.fa-envelope

        template(#default)
          .flex.vertical-center.contact-value(
            v-for="email in emails",
            :key="`contact-email-${email.value}`"
          )
            span.info {{ email.value }}
            copy-icon.copy-icon(:copy-value="email.value")
            template(v-if="hasKind(email)")
              i.diamond-icon.fa.fa-diamond.flex.center.vertical-center
              span.kind {{ Email.$tEnum('kind', email.kind) }}

      info-label.info-label.phones
        template(#icon)
          i.icon.fal.fa-phone

        template(#default)
          .flex.vertical-center.contact-value(
            v-for="phone in phones",
            :key="`contact-phone-${phone.value}`"
          )
            span.info {{ phone.formattedValue }}
            template(v-if="hasKind(phone)")
              i.diamond-icon.fa.fa-diamond.flex.center.vertical-center
              span.kind {{ Phone.$tEnum('kind', phone.kind) }}

</template>


<script>

// Models
import models from "@/models"
const { Email, Phone } = models

export default {
  name: "SupplierContact",

  props: {
    contact: { type: Object, required: true },
    edit:    { type: Boolean, default: false },
    errors:  { type: Object, default: () => ({}) }
  },

  data() {
    return {
      i18nScope: "suppliers.contacts.show",

      Email,
      Phone
    }
  },

  computed: {
    hasArea() {
      return _.present(this.contact.area)
    },

    phones() {
      return this.contact.phones.filter(resource => !resource.$markedForDestruction)
    },

    emails() {
      return this.contact.emails.filter(resource => !resource.$markedForDestruction)
    }
  },

  methods: {
    hasKind(emailOrPhone) {
      return _.present(emailOrPhone.kind)
    },

    editContact() {
      this.$emit("close")
      this.$emit("edit")
    },

    destroyContact() {
      this.$emit("close")
      this.$emit("destroy")
    }
  }
}

</script>

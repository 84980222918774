// Ability
import { ability } from "@/modules/ability"

// Routes
import NewMaintenancePackage from "@/views/maintenance-packages/new.vue"
import MaintenancePackage from "@/views/maintenance-packages/show.vue"
import EditMaintenancePackage from "@/views/maintenance-packages/edit.vue"
import MaintenancePackages from "@/views/maintenance-packages/index.vue"

export default [
  {
    path:      "/maintenance-packages/new",
    name:      "newMaintenancePackage",
    component: NewMaintenancePackage,
    meta:      {
      auth:        true,
      permissions: [() => ability.can("create", "MaintenancePackage")]
    }
  },

  {
    path:      "/maintenance-packages/:id",
    name:      "maintenancePackage",
    component: MaintenancePackage,
    meta:      {
      auth:        true,
      permissions: [() => ability.can("read", "MaintenancePackage")]
    }
  },

  {
    path:      "/maintenance-packages/:id/edit",
    name:      "editMaintenancePackage",
    component: EditMaintenancePackage,
    meta:      {
      auth:        true,
      permissions: [() => ability.can("update", "MaintenancePackage")]
    }
  },

  {
    path:      "/maintenance-packages",
    name:      "maintenancePackages",
    component: MaintenancePackages,
    meta:      {
      auth:        true,
      permissions: [() => ability.can("index", "MaintenancePackage")]
    }
  }
]


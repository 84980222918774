<style lang="scss" scoped>

.table-header {
  display: grid;
  grid-template-columns: var(--grid-columns);

  background-color: $light-gray;
  height: 40px;
  border-bottom: 1px solid $light-gray-3;
  border-top: 1px solid $light-gray-3;

  &.loading {
    border-bottom: 2px solid $light-orange-2;
  }

  .unsortable {
    padding: 0 12px;
  }

  .column {
    font-size: 12px;

    .icon {
      font-size: 16px;
      color: $gray-2;
    }

    .diamond-icon {
      font-size: 8px;
      font-weight: 900;
      color: $gray-3;
      width: 8px;
      height: 8px;
    }

    .text {
      font-family: $secondary-font;
      font-size: 14px;
      color: $dark-gray;
      font-weight: 400;
    }

    .loader-icon {
      font-size: 16px;
      color: $orange;
    }
  }
}

</style>


<template lang="pug">

  .table-header(:class="{ loading }", :style="styles")

    .column.flex.unsortable.vertical-center.center
      checkbox-field(
        name="toggleAll",
        hide-value-text,
        :disabled="loading || empty",
        :value="allSelected",
        @input="$emit('toggle-all')",
        data-testid="checkbox"
      )

    .column.flex.unsortable.vertical-center.center(data-testid="idColumn")
      span.text #

    .column.flex.unsortable.vertical-center
      i.icon.fas.fa-wrench

    .column.flex.unsortable.vertical-center(data-testid="nameColumn")
      span.text {{ $t('.columns.service.label') }}

    .column.flex.unsortable.vertical-center(data-testid="vehicleIssueSolutionColumn")
      i.icon.fas.fa-lightbulb(v-tooltip.bottom="{ offset: 4, content: $t('.tooltip.vehicle-issue-solutions.label') }")

    .column.flex.unsortable.vertical-center(data-testid="laborCostLinkedColumn")
      i.icon.fas.fa-fist-raised(v-tooltip.bottom="{ offset: 4, content: $t('.tooltip.labor-cost-linked.label') }")

</template>


<script>

export default {
  props: {
    loading:   { type: Boolean, default: false },
    empty:     { type: Boolean, default: false },
    sortParam: { type: String, required: true },
    sortOrder: { type: String, required: true },

    allSelected: { type: Boolean, default: false },

    columnSizes: { type: String, required: true }
  },

  data() {
    return {
      i18nScope: "suppliers.service-supports.service-vehicle-model-groups.new.services.table-header"
    }
  },


  computed: {
    styles() {
      return {
        "--grid-columns": this.columnSizes
      }
    }
  },

  methods: {
    isSortedBy(param) {
      return this.sortParam === param
    }
  }
}

</script>

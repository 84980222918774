<style scoped lang="scss">

@import "@/assets/styles/field";

.icon {
  color: $gray-2;
}

.clear {
  position: absolute;

  height: 100%;
  width: 40px;
  top: 0;
  right: 0;

  font-size: 18px;
  background: transparent;

  outline: 0;
  border: 0;

  cursor: pointer;
  color: $gray-2;

  &:hover {
    color: $gray-3;
  }
}

.input-wrapper.disabled {
  .input-inner {
    background-color: $light-gray-3;
    border-color: $light-gray-4;
    color: $gray-3;
    cursor: not-allowed;
  }

  .icon {
    cursor: not-allowed;
  }
}

.initialize-error {
  font-size: 12px;
  margin-top: 4px;

  span {
    margin-right: 4px;
  }
}

</style>


<template lang="pug">

  .google-autocomplete

    .input-wrapper(:class="{ disabled: error || fetchingGoogleMaps }")
      i.icon.fas.fa-map-marker-alt.left

      input.input-inner.icon-left(
        ref="input",
        :class="{ loading, disabled, error: inputError || error }",
        type="text",
        v-model="text",
        @keyup.esc="clear",
        :placeholder="placeholder",
        @keypress.enter.prevent="",
        :disabled="loading || disabled || error || fetchingGoogleMaps",
        @focus="$emit('focus')",
        @blur="onBlur"
      )

      template(v-if="!disabled && !loading && hasText")
        button.clear(type="button", @click="clear(true)")
          i.fas.fa-times

    .initialize-error(v-if="error")
      span {{ $t('.notifications.initializeError.message') }}
      a(@click="reload()") {{ $t('.notifications.initializeError.reload') }}

</template>


<script>

// https://developers.google.com/maps/documentation/javascript/place-data-fields
const DEFAULT_FIELDS = [
  // 'address_component',
  // 'adr_address',
  // 'business_status',
  // 'formatted_address',
  // 'geometry/viewport',
  // 'geometry/location',
  // 'icon',
  "name",
  // 'photos',
  "place_id"
  // 'plus_code',
  // 'type',
  // 'url',
  // 'utc_offset',
  // 'vicinity'
]

// https://developers.google.com/maps/documentation/javascript/places-autocomplete#add-autocomplete
const DEFAULT_TYPES = [
  "geocode",
  // 'address'
  "establishment"
  // '(regions)',
  // '(cities)'
]

const DEFAULT_COMPONENT_RESTRICTIONS = {
  country: "BR"
}

export default {
  name: "GoogleAutocomplete",

  props: {
    value:                 { type: [String, Object], default: null },
    componentRestrictions: { type: Object, default: () => (DEFAULT_COMPONENT_RESTRICTIONS) },
    types:                 { type: Array, default: () => DEFAULT_TYPES },
    disabled:              { type: Boolean, default: false },
    loading:               { type: Boolean, default: false },
    inputError:            { type: Boolean, default: false },
    fields:                { type: Array, default: () => DEFAULT_FIELDS },
    placeholder:           { type: String, default: null }
  },

  data() {
    return {
      i18nScope:          "components.google-autocomplete",
      error:              false,
      placesAutocomplete: null,
      text:               null,
      fetchingGoogleMaps: false
    }
  },

  computed: {
    hasText() {
      return !!this.text
    }
  },

  watch: {
    value() {
      if (_.blank(this.value)) this.text = null
    }
  },

  mounted() {
    this.initializeGoogleMaps()

    if (_.present(this.value)) {
      this.text = this.value.formatted_address
    }
  },

  beforeDestroy() {
    this.destroyGoogleMaps()
  },

  methods: {
    onBlur() {
      this.$emit("blur")
      this.text = _.get(this.value, "formatted_address")
    },

    destroyGoogleMaps() {
      let node = document.querySelector(".pac-container")
      if (node && node.parentNode) node.parentNode.removeChild(node)
    },

    async initializeGoogleMaps() {
      this.fetchingGoogleMaps = true

      try {
        const google = await this.$googleMaps.load({ language: "pt-BR" })
        if (!_.get(this, "$refs.input")) return

        this.placesAutocomplete = new google.maps.places.Autocomplete(this.$refs.input, {
          fields:                this.fields,
          types:                 this.types,
          componentRestrictions: this.componentRestrictions
        })

        this.placesAutocomplete.addListener("place_changed", () => {
          let googlePlace = this.placesAutocomplete.getPlace()

          this.text = null
          this.onChange(googlePlace)
        })
      }
      catch (error) {
        this.$err.log(error)
      }
      finally {

        this.fetchingGoogleMaps = false
      }
    },

    clear(focus = false) {
      if (this.disabled || this.loading || !this.hasText) return

      this.text = ""
      this.onChange(null)
    },

    onChange(googlePlace) {
      if (!googlePlace) return this.$emit("input", null)
      this.$emit("input", googlePlace)

      // Necessário para corrigir valor automatico gerado pelo autocomplete
      setTimeout(() => {
        this.text = _.get(this.value, "formatted_address")
      }, 0)
    },

    reload() {
      this.destroyGoogleMaps()
      this.initializeGoogleMaps()
    }
  }
}

</script>

sa<style scoped lang="scss">

.vehicle-row {
  display: grid;
  grid-template-columns: var(--grid-columns);

  &.loading {
    .column {
      &.store-column .initials,
      &.vehicle-column .vehicle-info .bottom .text,
      &.license-plate-column ::v-deep .value,
      &.service-order-column ::v-deep .app-span,
      &.service-order-column .service-order .icon-container .icon,
      &.actions-column ::v-deep .movida-common__app-button,
      .text,
      .link ::v-deep .app-span {
        color: $gray-2;
      }

      &.actions-column {
        ::v-deep .movida-common__app-button:hover {
          color: $orange;
        }
      }
    }
  }

  .column {
    padding: 0 12px;
    border-bottom: 1px solid $light-gray-3;
    height: 64px;
    overflow: hidden;

    .text {
      font-weight: 400;
      font-size: 14px;
      color: $dark-gray;
      text-align: initial;
    }

    .calendar {
      .icon,
      .text {
        font-size: 12px;
      }

      .text {
        font-family: $primary-font;
      }

      .icon {
        color: $gray-2;
        margin-right: 6px;
      }
    }

    .diamond-icon {
      margin: 0 4px 0 4px;
      font-size: 4px;
      font-weight: 900;
      color: $gray-3;
    }

    &.vehicle-column {
      font-family: $secondary-font;

      .manufacturer-icon {
        margin-right: 8px;
      }

      .vehicle-info {
        .bottom .text {
          color: $gray-3;
        }

        &:hover {
          cursor: pointer;

          .row .text-container * {
            color: $orange;
          }
        }
      }
    }

    &.store-column {
      .initials {
        font-family: $secondary-font;
        font-weight: 500;
        font-size: 14px;
        color: $gray-3;
      }
    }

    &.service-order-column {
      .empty {
        font-size: 14px;
        font-style: italic;
        color: $gray-2;
      }

      .service-order {
        font-family: $secondary-font;
        font-weight: 400;

        .state {
          .unknown {
            font-style: italic;
          }
        }

        .supplier {
          font-size: 12px;
          color: $gray-3;
        }

        .icon {
          color: $gray-3;
        }

        .icon-container {
          height: 16px;
          width: 16px;

          .icon {
            font-size: 12px;
          }
        }

        .service-order-state,
        .icon-container {
          margin-right: 4px;
        }
      }
    }

    .state,
    .vehicle-info,
    .service-order,
    .text-container {
      overflow: hidden;
    }
  }
}

</style>


<template lang="pug">

  .vehicle-row(:class="{ loading }", :style="styles")
    .column.state-column.flex.vertical-center
      .state
        app-span.text(crop, :value="vehicleState")
        .calendar(v-if="hasMovement")
          i.icon.fal.fa-calendar-alt
          app-span.text(
            v-tooltip.bottom="{ offset: '4', content: $standardTime(vehicle.lastMovementAt) }",
           :value="$fromNow(vehicle.lastMovementAt)"
          )

    .column.license-plate-column.flex.vertical-center.center
      vehicle-plate(:value="vehicle.licensePlate")

    .column.vehicle-column.flex.vertical-center
      vehicle-manufacturer-icon.manufacturer-icon(:value="manufacturer.name")
      router-link.vehicle-info(:to="showRoute")
        .row.top.flex
          .flex.vertical-center.text-container
            template(v-if="hasVehicleModel")
              app-span.text(crop, :value="model.name", data-testid="vehicleModelName")
            template(v-else)
              app-span.text(crop, :value="vehicle.fullModelName", data-testid="vehicleModelName")
            i.diamond-icon.fa.fa-diamond
            app-span.text(data-testid="vehicleYear") {{ vehicle.year }}

        .row.bottom.flex
          .flex.vertical-center.text-container
            template(v-if="hasVehicleModel")
              app-span.text(crop, :value="version.name", data-testid="vehicleModelVersion")
              i.diamond-icon.fa.fa-diamond
            app-span.text(crop, :value="vehicle.colorName", data-testid="vehicleColor")

    .column.store-column.flex.vertical-center
      app-span.initials(
        v-tooltip.bottom="{ offset: '4', content: currentStore.name }",
        :value="currentStore.initials"
      )

    .column.service-order-column.flex.vertical-center
      template(v-if="!hasRelevantServiceOrder")
        app-span.empty(:value="$t('.service-order.empty')")

      template(v-else)
        .service-order
          .state.flex.vertical-center
            service-order-state-icon.icon.service-order-state(:state="lastServiceOrder.state", size="small")
            app-span(crop, :value="kind", :class="{ unknown: kindUnknown }")
            i.diamond-icon.fa.fa-diamond
            .calendar.no-shrink
              i.icon.fal.fa-calendar-alt
              app-span.text(
                v-tooltip.bottom="{ offset: '4', content: $standardTime(lastServiceOrder.createdAt) }",
                :value="$fromNow(lastServiceOrder.createdAt)"
              )

          .supplier.flex.vertical-center
            .icon-container.flex.center.vertical-center
              i.icon.fas.fa-user-hard-hat
            app-span(:value="supplier.tradingName")

    .column.actions-column.flex.vertical-center.flex
      popover-menu(
        v-if="popoverMenuAuthorization",
        :show.sync="showPopover",
        data-testid="popoverMenu"
      )
        popover-menu-button(
          v-if="$can('read', vehicle)",
          icon="fal fa-eye",
          :text="$t('.actions.btn.show.label')",
          :to="showRoute",
          @close-menu="showPopover = false"
        )

</template>


<script>

// Models
import models from "@/models"
const { Vehicle, VehicleServiceOrder } = models

export default {
  props: {
    vehicle:     { type: Object, required: true },
    loading:     { type: Boolean, default: false },
    columnSizes: { type: String, required: true },

    serviceOrderStateOptionsFilter: { type: Array, default: null }
  },

  data() {
    return {
      i18nScope: "vehicles.index.vehicle-row",

      showPopover: false
    }
  },

  computed: {
    popoverMenuAuthorization() {
      return this.$can("read", this.vehicle)
    },

    showRoute() {
      return { name: "vehicle", params: { id: this.vehicle.id } }
    },

    vehicleModel() {
      return _.get(this.vehicle, "vehicleModel") || {}
    },

    hasVehicleModel() {
      return _.present(this.vehicleModel)
    },

    version() {
      return _.get(this.vehicleModel, "mobiautoVersion") || {}
    },

    model() {
      return _.get(this.version, "model") || {}
    },

    manufacturer() {
      return _.get(this.model, "manufacturer") || {}
    },

    currentStore() {
      return _.get(this.vehicle, "currentStore") || {}
    },

    hasMovement() {
      return _.present(this.vehicle.lastMovementAt)
    },

    lastServiceOrder() {
      return _.get(this.vehicle, "lastServiceOrder") || {}
    },

    hasServiceOrder() {
      return _.present(_.deleteBlanks(this.lastServiceOrder))
    },

    hasRelevantServiceOrder() {
      if (!this.hasServiceOrder) return false

      if (_.present(this.serviceOrderStateOptionsFilter)) return this.serviceOrderStateOptionsFilter.includes(this.lastServiceOrder.state)

      return true
    },

    supplier() {
      return _.get(this.lastServiceOrder, "supplier") || {}
    },

    vehicleState() {
      return _.present(this.vehicle.state)
        ? Vehicle.$tEnum("state", this.vehicle.state)
        : this.$t(".unknown.label")
    },

    serviceKind() {
      return _.get(this.lastServiceOrder, "serviceKind")
    },

    kindUnknown() {
      return _.isNil(this.serviceKind)
    },

    kind() {
      // TODO: Ampliar `$tEnum` para cobrir valores nulos
      let kind = VehicleServiceOrder.$enums.serviceKind.all[this.serviceKind]

      return VehicleServiceOrder.$tEnum("serviceKind", kind)
    },

    styles() {
      return {
        "--grid-columns": this.columnSizes
      }
    }
  }
}

</script>

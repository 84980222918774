// Base
import BaseRequests from "../../Base"

class TicketsDraftsPublicationsRequests extends BaseRequests {
  static get selector() {
    return "tickets.drafts.publications"
  }

  create({ ticketId, params = {}, options = {} }) {
    this.verifyData("create", { ticketId })

    return this.action({
      method: "post",
      url:    `/tickets/${ticketId}/draft/publication`,
      params,
      options
    })
  }
}

export default TicketsDraftsPublicationsRequests

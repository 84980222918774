// Base
import Base from "@/modules/new-sdk/requests/Base.js"

class SupplierServiceOrdersRequests extends Base {
  static get selector() {
    return "suppliers.serviceVehicleModelGroups.batches"
  }

  create({ supplierId, params = {}, options = {} }) {
    this.verifyData("create", { supplierId })

    return this.action({
      method:  "post",
      url:     `/suppliers/${supplierId}/service_vehicle_model_groups/batches`,
      params,
      options: {
        model: null,

        ...options
      }
    })
  }

  destroy({ supplierId, params = {}, options = {} }) {
    this.verifyData("destroy", { supplierId })

    return this.action({
      method: "delete",
      url:    `/suppliers/${supplierId}/service_vehicle_model_groups/batches`,
      params,
      options
    })
  }
}

export default SupplierServiceOrdersRequests

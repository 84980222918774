import Base from "./Base"

// Models
import models from "@/models"
const { SignedFile } = models

class Blobs extends Base {
  static get selector() {
    return "blobs"
  }

  create({ params = {}, options = {} } = {}) {
    return this.action({
      method:  "post",
      url:     "/blobs",
      params,
      options: {
        raw:   true,
        model: SignedFile,

        ...options
      }
    })
  }

  getDownloadURL({ blobId, params = {}, options = {} } = {}) {
    this.verifyData("getDownloadURL", { blobId })

    return this.action({
      method:  "get",
      url:     `/blobs/${blobId}/download_url`,
      params,
      options: {
        headers: {
          "Cache-Control": "no-cache"
        },

        ...options
      }
    })
  }
}

export default Blobs

<style lang="scss" scoped>

.supplier-maintenance-vehicle-model-group-row {
  display: grid;
  grid-template-columns: 44px 1fr 1fr;

  &.loading {
    .column {
      .id-tag {
        color: $gray-2;
      }

      .text,
      .link ::v-deep .app-span {
        color: $gray-2;
      }
    }
  }

  .column {
    padding: 0 12px;
    border-bottom: 1px solid $light-gray-3;
    height: 56px;
    overflow: hidden;

    .id-tag {
      margin-left: 4px;
    }

    &.vehicle-model-group-column {
      .vehicle-models-count {
        font-size: 12px;
        line-height: 12px;
        font-weight: 400;
        color: $gray-2;
        text-align: initial;
      }
    }

    .button {
      overflow: hidden;
    }

    .text,
    .link ::v-deep .app-span {
      font-family: $primary-font;
      font-weight: 400;
      font-size: 14px;
      color: $dark-gray;
      text-align: initial;
    }

    .link ::v-deep .app-span:hover {
      color: $orange;
    }
  }
}

</style>


<template lang="pug">

  .supplier-maintenance-vehicle-model-group-row(:class="{ loading }")
    .column.selection-column.flex.vertical-center.flex
      checkbox-field(
        :name="`vehicleModelGroupMaintenanceTable-${vehicleModelGroupMaintenanceTable.id}`",
        hide-value-text,
        :disabled="loading",
        :value="value",
        @input="(value) => $emit('input', { id: vehicleModelGroupMaintenanceTable.id, value })",
        data-testid="checkbox"
      )

    .column.vehicle-model-group-column.flex.center.column-direction
      .flex.vertical-center
        app-span.text(:value="vehicleModelGroup.name", crop, data-testid="vehicleModelGroupName")
        id-tag.id-tag(:value="vehicleModelGroup.id", data-testid="vehicleModelGroupId")
      app-span.vehicle-models-count(:value="$t('.vehicleModelsCount', { count: vehicleModelGroup.vehicleModelsCount })")

    .column.maintenance-table-column.flex.center.column-direction
      .flex.vertical-center
        app-span.text(:value="vehicleModelGroupMaintenanceTable.name", crop, data-testid="maintenanceTableName")
        id-tag.id-tag(
          :value="vehicleModelGroupMaintenanceTable.id",
          data-testid="maintenanceTableId"
        )
      maintenance-table-kind-tag(:kind="vehicleModelGroupMaintenanceTable.kind", :muted="loading")

</template>


<script>

export default {
  props: {
    vehicleModelGroupMaintenanceTable: { type: Object, required: true },
    loading:                           { type: Boolean, default: false },
    value:                             { type: Boolean, default: false }
  },

  data() {
    return {
      i18nScope: "suppliers.service-supports.maintenance.vehicle-model-groups.new.vehicle-model-group-maintenance-table-row",

      showPopover: false
    }
  },

  computed: {
    vehicleModelGroup() {
      return _.get(this.vehicleModelGroupMaintenanceTable, "vehicleModelGroup") || {}
    }
  }
}

</script>

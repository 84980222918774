/**
 * Base para componentes de listagem de anexos
 */

import {
  categoryNameFor,
  getCategoryNameForExtension,
  mimetypes
} from "@/lib/supported-files"
import models from "@/models"
const { Attachment } = models

export default {
  props: {
    attachment: { type: Attachment, default: null },

    progress: { type: Object, default: null },

    disableAbort:    { type: Boolean, default: false },
    disableDelete:   { type: Boolean, default: false },
    disableDownload: { type: Boolean, default: false },
    disableEdit:     { type: Boolean, default: false },
    disableView:     { type: Boolean, default: false }
  },

  emits: ["abort", "delete", "download", "edit", "view"],

  computed: {
    canAbort() {
      return !this.disableAbort && this.isLoading
    },

    canDelete() {
      return !this.disableDelete && this.isUploaded
    },

    canDownload() {
      return !this.disableDownload && _.present(this.attachment?.blobId)
    },

    canEdit() {
      return !this.disableEdit && _.present(this.attachment?.id)
    },

    canView() {
      return !this.disableView && _.present(this.attachment?.blobId)
    },

    fileCategory() {
      if (!this.attachment) return "file"
      if (this.attachment.category) return this.attachment.category
      if (mimetypes.includes(this.attachment.fileContentType)) return categoryNameFor(this.attachment.fileContentType)

      return getCategoryNameForExtension(this.fileExtension)
    },

    fileExtension() {
      if (!this.attachment) return ""

      const result = /\.(\w+)$/.exec(this.attachment.fileName)

      return result[1]
    },

    fileName() {
      if (!this.attachment) return "novo_arquivo"
      return this.attachment.name || "sem_nome"
    },

    fileSize() {
      if (!this.attachment) return "0 byte"
      return this.$asHumanSize(this.attachment.fileSize)
    },

    isProgressIndeterminate() {
      return (
        _.get(this.progress, "indeterminate", true)
        || _.blank(this.progress?.loaded)
      )
    },

    isLoading() {
      return _.get(this.progress, "loading", false)
    },

    isUploaded() {
      return _.present(this.attachment?.blobId) || _.present(this.attachment?.file)
    },

    progressRate() {
      if (this.progress?.indeterminate) return 0

      const loaded = this.progress?.loaded || 0
      const total = this.attachment?.fileSize || 0

      return total && (loaded / total * 100)
    },

    progressSize() {
      if (!this.progress) return "0 byte"
      return this.$asHumanSize(this.progress.loaded)
    }
  },

  methods: {
    onAbort() {
      this.$emit("abort", this.attachment)
    },

    onDelete() {
      this.$emit("delete", this.attachment)
    },

    onDownload() {
      this.$emit("download", this.attachment)
    },

    onEdit() {
      this.$emit("edit", this.attachment)
    },

    onView() {
      this.$emit("view", this.attachment)
    }
  }
}

<style lang="scss" scoped>

.search-form {
  width: 100%;

  .row, .row-bottom {
    align-items: end;
    padding-bottom: 12px;
    display: grid;
    gap: 16px;

    &:not(:last-child) {
      margin-bottom: 8px;
    }

    &:nth-child(2) {
      grid-template-columns: 1fr;
    }

    &:nth-child(3) {
      grid-template-columns: 1fr 1fr;
    }

    &:nth-child(4) {
      grid-template-columns: 3fr 1fr 1fr 1fr 1fr;
    }

    grid-template-columns: 3fr 2fr;
  }

  .submit {
    min-width: unset;
    padding: 0;
  }

  .hint {
    display: block;
    color: #666;
    margin-top: -12px;

    i {
      margin-right: 10px;
    }
  }
}
</style>


<template lang="pug">

    form.search-form(
      @submit.prevent="$emit('search')"
      method="get"
      data-test="form"
    )

      .row
        city-select-field(
          v-model="params.cityId"
          :label="$t('.fields.city.label')"
          :loading="loading"
          name="search[city_id]"
          :placeholder="$t('.fields.city.placeholder')"
        )

        deal-state-select-field(
          v-model="params.dealState"
          :label="$t('.fields.dealState.label')"
          :loading="loading"
          name="search[deal_state]"
          :placeholder="$t('.fields.dealState.placeholder')"
        )

        supplier-classification-finder-field(
          v-model="params.classification"
          :disabled="loading"
          :include-none="true"
          :listbox-config="{ align: 'right', width: 302 }"
          name="search[classification]"
          :placeholder="$t('.fields.classification.placeholder')"
        )

        finder-field.blocked-field(
          v-model="params.blocked",
          name="search[blocked]",
          :disabled="loading",
          :label="$t('.fields.blocked.label')",
          :options="blockedOptions",
          :placeholder="$t('.fields.blocked.placeholder')",
          :listbox-config="{ align: 'right', width: 230 }"
        )

      .row
        textarea-field(
          v-model="params.q", 
          :label="$t('.fields.document.label')", 
          :placeholder="$t('.fields.document.placeholder')", 
          name="search[q]", 
          :loading="loading"
          optional
        )
        small.hint 
          i.fas.fa-info-circle
          span Você pode buscar por mais de 1 CNPJ adicionando cada CNPJ em uma nova linha.

      .row
        vehicle-model-group-select-field(
          name="search[model_group]"
          v-model="params.modelGroup"
          :label="$t('.fields.vehicleModelGroup.label')"
          :placeholder="$t('.fields.vehicleModelGroup.placeholder')"
          :disabled="loading"
        )
        service-select-type-field(
          :disabled="loading"
          :showMaintenance="true"
          name="search[service]"
          v-model="params.service"
          label="Serviço para associar" 
          placeholder="Escolha o serviço a ser associado..."
        )

      .row
        finder-field.blocked-field(
          v-model="params.associated",
          name="search[associated]",
          :disabled="loading",
          :label="$t('.fields.associated.label')",
          :options="associatedOptions",
          :placeholder="$t('.fields.associated.placeholder')",
          :listbox-config="{ align: 'left', width: 230 }"
        )

        app-button(
          :loading="loading"
          :disabled="this.params.service == null || this.params.modelGroup == null"
          outline
          type="submit"
        )
          span Filtrar

        app-button(
          :loading="loading"
          outline
          type="submit"
          @click="$emit('export')"
        )
          span Exportar

        app-button(
          :loading="loading"
          :disabled="this.params.service == null || this.params.modelGroup == null"
          @click="$emit('associate')"
        )
          span Associar
        
        app-button(
          :loading="loading"
          :disabled="this.params.service == null || this.params.modelGroup == null"
          @click="$emit('disassociate')"
        )
          span Desassociar

</template>


<script>

import SupplierClassificationFinderField from "@/views/suppliers/components/supplier-classification-finder-field.vue"
import ServiceSelectTypeField from "@/components/service/service-type-select-field.vue"

export default {
  components: {
    SupplierClassificationFinderField, 
    ServiceSelectTypeField
  },

  props: {
    loading: { type: Boolean, default: false },
    params:  { type: Object, default: () => ({}) }
  },

  data() {
    return {
      i18nScope: "suppliers.index.search-form"
    }
  },

  computed: {

    associatedOptions() {
      return [{
        value: false,
        label: this.$t(".fields.associated.options.false.label")
      }, {
        value: true,
        label: this.$t(".fields.associated.options.true.label")
      }]
    }, 

    blockedOptions() {
      // cada option é: { icon, label, value }
      return [{
        value: false,
        label: this.$t(".fields.blocked.options.false.label")
      }, {
        value: true,
        label: this.$t(".fields.blocked.options.true.label")
      }]
    },
  }
}

</script>

<!--
# ServiceOderSectionLayout
Layout para seções de ordem de serviço, com slots referentes a cada parte da
seção.

## Props
- empty: {Boolean} Indica que não há dados a serem exibidos, exibe o slot `empty`
  ao invés do slot `default`

## Slots
- icon
- title
- info: informações adicionais no cabeçalho
- empty
- default

## Uso
```pug
service-order-section-layout(:empty="!hasDescription")
  template(#icon)
    i.fas.fa-user-headset
  template(#title) {{ $t(".title") }}
  template(#empty) {{ $t(".empty") }}
  .whitespace {{ descriptionText }}
```

-->

<style lang="scss" scoped>
.service-order-section-layout {
  color: $dark-gray;
  display: flex;
  font-family: $primary-font;
  font-size: 14px;
  font-weight: 400;
  gap: 16px;
  line-height: 1.5;
}

.icon {
  align-items: center;
  color: $gray-3;
  display: flex;
  flex-shrink: 0;
  font-size: 16px;
  height: 24px;
  justify-content: center;
  width: 24px;
}

.content {
  flex-grow: 1;
}

.header {
  align-items: baseline;
  display: flex;
  margin-bottom: 10px;
  min-height: 24px;

  .title {
    font-family: $secondary-font;
    font-size: 16px;
    font-weight: 500;
    line-height: 18px;
  }

  .separator {
    align-self: center;
    margin: 0 8px;
  }
}

.empty {
  background: $light-gray;
  border-radius: 4px;
  color: $gray-3;
  font-size: 12px;
  font-style: italic;
  padding: 8px;
}
</style>

<template lang="pug">
  section.service-order-section-layout
    .icon
      slot(name="icon")

    .content
      header.header(v-if="hasTitleSlot")
        h3.title
          slot(name="title")
        diamond-separator.separator(v-if="hasInfoSlot")
        slot(name="info")
      .main(v-if="!empty")
        slot
      .empty(v-else)
        slot(name="empty")
</template>

<script>
import DiamondSeparator from "./diamond-separator.vue"

export default {
  components: {
    DiamondSeparator
  },

  props: {
    empty: { type: Boolean, default: false }
  },

  computed: {
    hasInfoSlot() {
      return "info" in this.$slots
    },

    hasTitleSlot() {
      return "title" in this.$slots
    }
  }
}
</script>

<!--
# AttachArea
Seção de anexos, com dropzone, listagem e gerenciamento de requisições.

Funciona com `v-model` para anexos.
-->

<style lang="scss" scoped>
.attachments {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  gap: 24px;
  margin-top: 24px;
  padding: 0;
}

.attachment {
  list-style: none;
}

// List layouts
.attachments {
  &.col-3 {
    display: grid;
    gap: 24px;
    grid-template-columns: repeat(3, 1fr);

    .attachment{
      .card {
        width: 100%;
      }
    }
  }
}
</style>

<template lang="pug">
  .attach-area
    drop-zone(
      v-bind="dropzoneConfig"
      :accept="(allowedTypes || []).join()"
      multiple
      @input="onFileInput")

    ul.attachments(:class="listClasses")
      li.attachment(
        v-for="attachment in attachments"
        :key="attachment.name"
      )
        attach-card.card(
          :attachment="attachment"
          @delete="onAttachmentDelete"
          @download="onAttachmentDownload"
          @edit="onAttachmentEdit"
          @view="onAttachmentView"
        )
      li.attachment(
        v-for="{ abort, attachment, id, state, loaded } in uploadQueue"
        :key="id"
      )
        attach-card.card(
          :attachment="attachment"
          :disable-abort="!abort"
          :progress="{\
            loading: true,\
            indeterminate: state < ATTACH_STATE.UPLOADING,\
            loaded\
          }"
          @abort="() => onAttachmentAbort(id)"
        )
    template(v-if="type == 'blob'")
      attachment-preview(
        v-if="previewModal.show"
        :attachment="previewModal.attachment"
        :editable="!disableEdit"
        :editing.sync="previewModal.editing"
        :local-edit="enableLocalEdit"
        @close="onPreviewClose"
        @download="onPreviewDownload"
        @update:attachment="onPreviewUpdate"
      )
    template(v-if="type == 'quote'")
      quote-attachment-preview(
        v-if="previewModal.show"
        :attachment="previewModal.attachment"
        :editable="!disableEdit"
        :editing.sync="previewModal.editing"
        :local-edit="enableLocalEdit"
        :quote-id="quoteId"
        @close="onPreviewClose"
        @download="onPreviewDownload"
        @update:attachment="onPreviewUpdate"
      )
</template>

<script>

import AttachDownload from "./attach-download"
import AttachUpload from "./attach-upload"

const LAYOUTS = [
  "wrap",
  "col-3"
]

export default {
  name: "AttachArea",

  mixins: [
    AttachDownload,
    AttachUpload
  ],

  model: {
    event: "change"
  },

  props: {
    // FROM MIXINS:
    // allowedTypes
    // maxSize
    // quoteId
    // type
    disableEdit:     { type: Boolean, default: false },
    dropzoneConfig:  { type: Object, default: null },
    enableLocalEdit: { type: Boolean, default: true },
    listLayout:      {
      type:      String,
      default:   "wrap",
      validator: (value) => LAYOUTS.includes(value)
    },
    value: { type: Array, default: () => [] }
  },

  emits: ["change"],

  data() {
    return {
      editing: false,

      i18nScope: "components.attach.attach-area",

      previewModal: {
        attachment: null,
        editing:    false,
        show:       false
      }
    }
  },

  computed: {
    attachments() {
      return this.value.filter((attachment) => !attachment._destroy)
    },

    listClasses() {
      return {
        [this.listLayout]: LAYOUTS.includes(this.listLayout)
      }
    }
  },

  methods: {
    onAttachmentAbort(id) {
      this.abortAttachmentUpload(id)
    },

    onAttachmentDelete(attachment) {
      if (!(_.present(attachment.blobId) || _.present(attachment.file))) return

      if (attachment.$newRecord) {
        this.$emit(
          "change",
          this.value.filter((_attachment) => _attachment !== attachment)
        )

        return
      }

      attachment.$markForDestruction()
    },

    onAttachmentDownload(attachment) {
      this.downloadAttachment(attachment)
    },

    onAttachmentEdit(attachment) {
      this.previewModal.attachment = attachment
      this.previewModal.editing = true
      this.previewModal.show = true
    },

    onAttachmentView(attachment) {
      this.previewModal.attachment = attachment
      this.previewModal.show = true
    },

    onFileInput(files) {
      files.forEach(this.addAttachmentToQueue)
    },

    onPreviewClose() {
      this.previewModal.attachment = null
      this.previewModal.editing = false
      this.previewModal.show = false
    },

    onPreviewDownload() {
      const { attachment } = this.previewModal
      this.downloadAttachment(attachment)
    },

    onPreviewUpdate(attachment) {
      const attachments = this.value.map(
        (_attachment) => (attachment.id == _attachment.id ? attachment : _attachment)
      )

      this.$emit("change", attachments)
    }
  }
}
</script>

import Base from "./base"

class TireSpecification extends Base {
  static get attrs() {
    return _.uniq([
      ...super.attrs,
      "vehicleModelId",
      "material",
      "kind"
    ])
  }

  static get enums() {
    return {
      material: {
        alloy: "alloy",
        steel: "steel"
      },

      kind: {
        conventional: "conventional",
        run_flat:     "runFlat"
      }
    }
  }

  static get relations() {
    return {
      sizes:        { type: "hasMany", model: "TireSize" },
      loadIndices:  { type: "hasMany", model: "TireLoadIndex" },
      speedRatings: { type: "hasMany", model: "TireSpeedRating" }
    }
  }

  static get constraints() {
    return {
      kind: {
        presence: true
      },
      material: {
        presence: true
      }
    }
  }
}

export default TireSpecification

import { routerFactory } from "utils.vue"

import auth from "@/modules/auth"
import routes from "./routes"

const router = routerFactory.init(routes, {
  afterLoginRoute: "suppliers",
  auth
})

// ref: https://github.com/vuejs/vue-router/issues/2881#issuecomment-520554378
// - https://stackoverflow.com/a/65326844
// - https://gist.github.com/eyedean/ce6ab6a5108a1bd19ace64382144b5b0
const originalPush = router.push
const Router = router.constructor

router.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject) {
    return originalPush.call(router, location, onResolve, onReject)
  }

  return originalPush.call(router, location)
    .catch(err => {
      // TODO: é possível filtrar o tipo da falha: redirected, cancelled, duplicated, aborted.
      // if (Router.isNavigationFailure(err, Router.NavigationFailureType.redirected)) {
      if (Router.isNavigationFailure(err)) {
        // resolve err
        return err
      }

      // rethrow error
      return Promise.reject(err)
    })
}

// authorization hook
router.beforeEach((to, from, next) => {
  // a rota e toda a hierarquia de aninhamento conforme o arquivo de rotas
  const permissions = to.matched.map(record => _.get(record.meta, "permissions"))
    // cada rota pode conter várias verificações
    .flat()
    // ignora rotas sem permissões explícitas
    .filter(permissionFunction => !_.isNil(permissionFunction))
    .map(permissionFunction => permissionFunction())

  const permitted = permissions.every(permission => permission === true)
  if (!permitted) return next({ name: "forbidden" })

  return next()
})

export default router

// Base
import BaseRequests from "../Base"

// Models
import models from "@/models"
const { MovidaService } = models

class MonitoringServiceRequests extends BaseRequests {
  static get selector() {
    return "monitoring.services"
  }

  all({ params = {}, options = {} } = {}) {
    return this.action({
      method:  "post",
      url:     `/monitoring/services/search`,
      params,
      options: {
        model: MovidaService,

        ...options
      }
    })
  }

  find({ serviceRid, params = {}, options = {} } = {}) {
    this.verifyData("find", { serviceRid })

    return this.action({
      method:  "get",
      url:     `/monitoring/services/${serviceRid}`,
      params,
      options: {
        model: MovidaService,

        ...options
      }
    })
  }
}

export default MonitoringServiceRequests

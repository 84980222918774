<style lang="scss" scoped>

.app-label {
  &.crop {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
  }
}

</style>


<template lang="pug">

  label.app-label(
    ref="textElement",
    :class="{ crop }",
    v-tooltip="tooltipOptions",
    v-observe-visibility="handleCropping"
  )
    slot
      | {{ value }}

</template>


<script>

import AppSpan from "./app-span"

export default {
  name: "AppLabel",

  extends: AppSpan,

  data() {
    return {
      i18nScope: "components.app-label"
    }
  }
}

</script>

<style lang="scss" scoped>

svg {
  width: 24px;
  height: 11px;
  fill: $gray-3;

  &.big {
    width: 40px;
    height: 15px;
  }
}

.outside {
  fill: $dark-blue;
}

.inside {
  fill: $white;
}

</style>


<template lang="pug">

  svg(
    :class="size"
    viewBox="0 0 24 11",
    fill="none",
    xmlns="http://www.w3.org/2000/svg"
  )
    path(
      d=`M0 10C0 10.5523 0.447715 11 1 11H17.9856C18.3673 11
        18.7157 10.7828 18.8836 10.4401L23.2943 1.44007C23.6199
        0.775662 23.1362 0 22.3963 0H1C0.447716 0 0 0.447715 0 1V10Z`,
      :class="{ outside: !disabled }"
    )
    path(
      fill-rule="evenodd",
      clip-rule="evenodd",
      d=`M16.6763 5.29471H16.1328V3.54077H16.6763C17.402 3.54077
        17.9797 3.75173 17.9797 4.40653C17.9797 5.08277 17.402
        5.29471 16.6763 5.29471ZM10.826 6.54862C10.5383 6.54862
        10.2684 6.50229 10.0352 6.42491L10.8181 4.24634H10.8349L11.6016
        6.43082C11.3706 6.50377 11.1078 6.54862 10.8268 6.54862H10.826ZM16.5322
        2.11583H14.0639V7.29436L11.9075 2.11583H9.77012L7.92771
        6.44536C7.73302 5.35411 6.45177 4.97631 5.44454 4.69462C4.78059
        4.50609 4.07391 4.22909 4.08033 3.92226C4.08592 3.67138 4.45993
        3.43874 5.19762 3.47349C5.69481 3.49567 6.13335 3.53116 7.00399
        3.90255L7.86151 2.58457C7.06712 2.22648 5.96631 2.00173 5.06578
        2H5.06019C4.00966 2 3.13427 2.30214 2.59127 2.79675C2.2139 3.14276
        2.00972 3.58045 2.0005 4.06767C1.98653 4.73504 2.26502 5.2087 2.84796
        5.58749C3.34124 5.90639 3.97056 6.11167 4.52641 6.26546C5.21214
        6.45201 5.7719 6.61491 5.76548 6.96215C5.75989 7.08858 5.70654
        7.20712 5.60291 7.30077C5.43225 7.45726 5.16997 7.51517 4.80713
        7.52232C4.1077 7.53464 3.589 7.43853 2.76277 7.00775L2.0005 8.34421C2.82422
        8.75799 3.79933 9 4.79456 9H4.92332C5.78922 8.98521 6.48864 8.76711
        7.04757 8.37083L7.13807 8.30108L6.89115 8.88664H9.13271L9.50923
        7.87646C9.90307 7.99402 10.3517 8.06006 10.8265 8.06006C11.2899
        8.06006 11.727 7.99845 12.1139 7.88607L12.4759
        8.88664H16.1333V6.79433H16.9314C18.8587 6.79433 20 5.92881
        20 4.47627C20 2.8596 18.8917 2.11805 16.5319 2.11805`,
      class="inside"
    )

</template>


<script>

export default {
  name: "SapIcon",

  props: {
    disabled: { type: Boolean, default: false },
    size:     { type: String, default: "normal" }
  }
}

</script>

<template>
	<div v-if="show" class="movida-modal">
		<div class="backdrop">
			<div class="container">
				
				<div class="close">
					<a href="#" @click="$emit('close')"><i class="far fa-times"></i></a>
				</div>
				
				<h3>Desassociar serviços</h3>
				<p>Tem certeza que deseja <strong>desassociar</strong> o serviço aos fornecedores listados?</p>
				<hr>

				<div class="mb-4" v-if="params.service == 'Maintenance'">
					<finder-field
						v-model="kind"
						name="kind"
						:label="'Qual o tipo de tabela de revisão?'"
						:options="serviceOptions"
						:placeholder="'Por favor, selecione...'"
						:listbox-config="{ align: 'left', width: 230 }"
					>
					</finder-field>
				</div>
				
				<div class="flex vertical-center">
					<app-button :disabled="loading" @click="confirm">
						Confirmar
					</app-button>
					
					<div class="ml-2" v-if="loading">
						<i class="loader-icon fas fa-spin fa-spinner"></i>
						<span>Desassociando serviços...</span>
					</div>
				</div>
				
				
			</div>
		</div>
	</div>
</template>

<script>

export default {
	
	props: {
		show: { type: Boolean, default: false }, 
		params: { type: Object, default: () => ({}) }
	}, 
	
	data() {
		return {
			loading: false, 
			i18nScope: "manage-resources.model-groups.index.disassociate", 
			serviceType: null, 
			kind: null
		}
	},
	
	methods: {
		confirm() {
			
			this.loading = true

			if (this.params.service == 'Maintenance' && !this.kind) {
				this.$notifications.error('Por favor, selecione o tipo de tabela de revisão.')
				this.loading = false
				return
			}
			
			// mescla o formulário com os parâmetros enviados
			let payload = Object.assign({}, this.params, {
				sync: 'disassociate'
			})

			if (this.params.service == 'Maintenance') {
				payload = Object.assign({}, payload, {
					kind: this.kind
				})
			}
			
			// realiza a requisição e trata o erro
			this.$newSdk.ManageResources.modelGroupSync({ params: payload }).then((response) => {
				this.$emit('updated')
				this.$notifications.info(response.data.message)
			}).catch((response) => {
				this.$notifications.error(response.error.originalError.response.data.message)
			}).finally(() => {
				this.loading = false
				this.$emit('close')
			})
		}
	}, 
	
	computed: {
		isShowing() {
			return this.show
		}, 
		serviceOptions() {
			return [
				{ value: 'multiservice', label: 'Tabela multimarca' },
				{ value: 'dealership', label: 'Tabela concessionária' }
			]
		}
	}
}
</script>

<style lang="scss">
.movida-modal {
	
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 995;

	.mb-4 {
		margin-bottom: 30px;
	}
	
	.backdrop {
		position: fixed;
		display: flex;
		align-items: center;
		justify-content: center;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background: rgba(0, 0, 0, .8);
	}
	
	.container {
		position: relative;
		padding: 30px;
		width: 50%;
		min-width: 300px;
		min-height: 100px;
		height: auto;
		background: #fff;
		border-radius: 4px;
		box-shadow: rgba(0, 0, 0, .85) 0 0 10px;
		box-sizing: border-box;
		
		hr {
			border: none;
			border-bottom: #eee 1px solid;
			display: block;
			margin: 25px 0;
		}
		
		.close {
			position: absolute;
			top: 10px;
			right: 10px;
			width: 20px;
			height: 20px;
			cursor: pointer;
			color: #333;
			background-size: cover;
		}
		
		.form-row {
			margin-bottom: 15px;
		}
		
		.loader-icon {
			margin: 0 20px;
		}
	}
}
</style>
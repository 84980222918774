<style lang="scss" scoped>

.search-form {
  form {
    width: 100%;
  }

  .row + .row {
    margin-top: 8px;
  }
}

</style>


<template lang="pug">

  .search-form
    form(
      @submit.prevent="$emit('search')",
      method="get",
      data-testid="form"
    )
      ui-row.row(vertical-align="bottom")
        ui-col(:span="9")
          input-field(
            name="search[q]",
            v-model="params.q",
            :loading="loading",
            hide-label,
            :placeholder="$t('.fields.q.placeholder')",
            icon-left="far fa-search"
          )

        ui-col(:span="3")
          vehicle-state-multiple-select-field(
            v-model="params.state",
            name="search[state]",
            hide-create-option,
            :label="$t('.fields.state.label')",
            :loading="loading",
            :placeholder="$t('.fields.state.placeholder')",
            :options-filter="vehicleStateOptionsFilter"
          )

      ui-row.row(vertical-align="bottom")
        ui-col(:span="7")
          store-multiple-select-field(
            v-model="params.currentStoreId",
            name="search[currentStoreId]",
            hide-create-option,
            :label="$t('.fields.currentStoreId.label')",
            :loading="loading",
            :placeholder="$t('.fields.currentStoreId.placeholder')"
          )

        ui-col(:span="3")
          service-order-state-multiple-select-field(
            v-model="params.serviceOrderState",
            name="search[serviceOrderState]",
            hide-create-option,
            :label="$t('.fields.serviceOrderState.label')",
            :loading="loading",
            :placeholder="$t('.fields.serviceOrderState.placeholder')",
            :options-filter="serviceOrderStateOptionsFilter"
          )

        ui-col(:span="2")
          app-button(
            type="submit",
            :loading="loading",
            full-width,
            outline
          )
            span {{ $t('btn.search.label') }}
            span(slot="loading") {{ $t('btn.search.loading') }}

</template>


<script>

// Components
import VehicleStateMultipleSelectField from "./vehicle-state-multiple-select-field.vue"
import ServiceOrderStateMultipleSelectField from "./service-order-state-multiple-select-field.vue"
import StoreMultipleSelectField from "./store-multiple-select-field.vue"

export default {
  components: {
    VehicleStateMultipleSelectField,
    ServiceOrderStateMultipleSelectField,
    StoreMultipleSelectField
  },

  props: {
    loading:                        { type: Boolean, default: false },
    params:                         { type: Object, default: () => ({}) },
    serviceOrderStateOptionsFilter: { type: Array, default: null }
  },

  data() {
    return {
      i18nScope: "vehicles.index.search-form"
    }
  },

  computed: {
    vehicleStateOptionsFilter() {
      return [
        "filling_up",
        "washing",
        "ready_for_rental",
        "rented",
        "transferring",
        "preventive_maintenance",
        "corrective_maintenance",
        "damaged"
      ]
    }
  }
}

</script>

// Base
import BaseRequests from "../../Base"

// Models
import models from "@/models"
const { MovidaVehicleServiceOrderProduct } = models

class MonitoringVehicleServiceOrderProductsRequests extends BaseRequests {
  static get selector() {
    return "monitoring.vehicles.serviceOrderProducts"
  }

  all({ vehicleId, params = {}, options = {} } = {}) {
    this.verifyData("all", { vehicleId })

    return this.action({
      method:  "post",
      url:     `/monitoring/vehicles/${vehicleId}/service_order_products/search`,
      params,
      options: {
        model: MovidaVehicleServiceOrderProduct,

        ...options
      }
    })
  }
}

export default MonitoringVehicleServiceOrderProductsRequests

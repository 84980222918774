<style lang="scss" scoped>

.service-package-row {
  padding: 0 24px;
  min-height: 68px;
  border-top: 1px solid $light-gray-3;
  border-bottom: 1px solid $light-gray-3;
  height: 70px;

  & + .service-package-row {
    border-top: 1px solid transparent;
  }

  .id-column {
    width: 60px;
    margin-right: 24px;
  }

  .content-column {
    overflow: hidden;

    .name,
    .description {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .name {
      .link {
        font-family: $secondary-font;
        font-size: 18px;
        font-weight: 400;
        color: $dark-gray;

        &:hover {
          color: $primary;
        }
      }
    }
  }

  .counts-column {
    margin-left: 24px;
    margin-right: 16px;

    .count {
      & + .count {
        margin-left: 24px;
      }

      .count-icon {
        margin-right: 4px;
      }
    }
  }
}

</style>


<template lang="pug">

  .service-package-row.flex.vertical-center
    .id-column.no-shrink
      id-tag(:value="servicePackage.id")

    .content-column.grow
      .name
        app-button.link(
          theme="link",
          :to="showRoute"
        ) {{ servicePackage.name }}
      .description(
        v-if="servicePackage.description",
        data-testid="description"
      )
        span {{ servicePackage.description }}

    .counts-column.flex
      .count.flex.vertical-center(
        v-tooltip.bottom="{ offset: '4', content: $t('.fields.servicesCount.tooltip') }",
        data-testid="servicesCount"
      )
        i.count-icon.fal.fa-cars
        label {{ servicePackage.servicesCount }}
      .count.flex.vertical-center(
        v-tooltip.bottom="{ offset: '4', content: $t('.fields.productsCount.tooltip') }",
        data-testid="productsCount"
      )
        i.count-icon.fal.fa-wrench
        label {{ servicePackage.productsCount }}

    .actions-column
      popover-menu(
        v-if="popoverMenuAuthorization",
        :show.sync="showPopover",
        data-testid="popoverMenu"
      )
        popover-menu-button(
          v-if="$can('read', 'ServicePackage')",
          icon="fal fa-eye",
          :to="{ name: 'servicePackage', params: { id: servicePackage.id }}",
          :text="$t('.actions.btn.show')",
          @close-menu="showPopover = false"
        )
        popover-menu-button(
          v-if="$can('update', 'ServicePackage')",
          icon="fal fa-pencil",
          :to="{ name: 'editServicePackage', params: { id: servicePackage.id }}",
          :text="$t('.actions.btn.edit')",
          @close-menu="showPopover = false"
        )
        destroy-action.destroy-button.popover-menu-button(
          :id="servicePackage.id",
          :name="servicePackage.name",
          size="normal",
          border="square",
          @click.native="showPopover = false"
          @destroy="$emit('destroy')"
        )
          .icon-content
            i.icon.fal.fa-trash-alt
          span {{ $t('.actions.btn.destroy') }}

</template>


<script>

import DestroyAction from "../components/destroy-action"

export default {
  components: {
    DestroyAction
  },

  props: {
    servicePackage: { type: Object, default: () => ({}) }
  },

  data() {
    return {
      i18nScope: "service-packages.index.service-package-row",

      showPopover: false
    }
  },

  computed: {
    popoverMenuAuthorization() {
      return ["read", "update", "destroy"].some(action => this.$can(action, "ServicePackage"))
    },

    showRoute() {
      if (!this.$can("read", "ServicePackage")) return null

      return { name: "servicePackage", params: { id: this.servicePackage.id } }
    }
  }
}

</script>

<!--

  view: monitoring/service-orders#show
  url: /monitoring/service-orders/:serviceOrderId (rid)

  Exibição de uma Ordem de Serviço, para a área de Monitoramento, permitindo consulta a informações
  que auxiliem no acompanhamento da Ordem de Serviço até que seja encerrada.

-->


<style lang="scss" scoped>

.show.service-order {
  > .navigation {
    margin-bottom: 8px;

    .btn-back {
      font-size: 14px;

      .icon {
        margin-right: 4px;
      }
    }
  }

  > .header {
    display: flex;
    flex-direction: row;
    margin-bottom: 26px;

    > .content {
      display: flex;
      flex-grow: 1;
      flex-direction: column;

      > .heading {
        display: flex;
        flex-direction: row;
        align-items: baseline; // or center

        .title {
          font-family: $secondary-font;
          font-style: normal;
          font-weight: 500;
          font-size: 30px;
          line-height: 150%;

          color: $dark-gray;
        }
      }

      > .description {
        display: flex;
        flex-direction: row;
        align-items: center;

        gap: 8px;

        .dot {
          color: $gray;
          font-size: 4px;
          line-height: 4px;
        }

        .service-kind {
          font-family: $secondary-font;
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 150%;

          color: $dark-gray;
        }
      }
    } // > .content

    > .toolbar {
      display: flex;
      flex-direction: row;
      align-items: flex-start;

      gap: 16px;

      .assignment {
        display: flex;
        flex-direction: row;
        gap: 8px;

        > .initials-tag {
          // @override
          margin: 0;
        }

        .label,
        .value {
          font-family: $secondary-font;
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 150%;
          color: $dark-gray;
        }

        .label {
          font-size: 12px;
        }
      }
    } // .toolbar

  } // > .header


  > .body {
    display: flex;
    flex-direction: column;
    gap: 32px;

    .section {
      position: relative;

      &:not(:first-child)::before {
        border-top: 1px solid $light-gray-3;
        content: ' ';
        margin-left: 40px;
        position: absolute;
        top: -16px;
        width: calc(100% - 40px);
      }
    }
  }
}


.waves-separator {
  background: url("~@/assets/images/waves-orange.png"); // waves-orange-light.png
  border-radius: 4px;
  height: 12px;
  margin-bottom: 16px;
}

.separator,
.h-separator {
  border-bottom: 1px solid $light-gray-3;
}

.v-separator {
  border-right: 1px solid $light-gray-4;
}

// XXX: sobrecarga de cor de título da lista de anexos (reuso de componente)
.attachments-list {
  ::v-deep .subsection-header > .icon-container {
    height: 24px !important;
    margin-right: 16px !important;
    width: 24px !important;
  }

  ::v-deep .subsection-title {
    color: $dark-gray !important;
  }

  ::v-deep .refresh-button {
    align-items: baseline;

    .icon-container {
      margin-right: 4px;

      .icon {
        font-size: 12px;
      }
    }
  }

  ::v-deep .count,
  ::v-deep .refresh-text {
    color: $dark-gray !important;
    font-family: $primary-font !important;
    font-size: 14px !important;
  }
  ::v-deep .refresh-text:hover {
    color: $primary !important;
  }

  ::v-deep .attachments {
    margin-left: 40px;
  }

  ::v-deep .empty {
    margin-left: 40px;
    text-align: initial !important;
  }
}

</style>


<template lang="pug">

  .show.service-order
    template(v-if="loading")
      app-loading-lines(
        :lines="2"
        :min-width="60"
        :max-width="90"
        :height="30"
      )

    //- Em caso de erro, é redirecionado para as páginas de erro
    //- template(v-else-if="erred")
    //-   span err!

    template(v-else)
      .navigation
        app-button.btn-back(theme="link", @click="goBack")
          i.icon.fas.fa-arrow-left
          span {{ $t(".navigation.buttons.back.label") }}

      .header
        .content
          .heading
            h1.title {{ $t(".title.label") }}
            code-tag.id(size="big", color="simple-gray") {{ serviceOrder.remoteId }}
            business-unit-tag(size="big", :value="serviceOrder.businessUnit")
          .description
            state-tag(:value="serviceOrder.state", kind="service", icon-theme="solid")
            i.dot.fas.fa-diamond
            span.service-kind {{ serviceKindText }}

        .toolbar
          service-order-toolbar.actions(
            :disabled="creatingQuoteDraft"
            :service-order="serviceOrder"
            @cancel="() => openModal('service-order-cancellation')"
            @create-vehicle-receipt="() => openModal('vehicle-receipt')"
            @edit-quote="onEditQuote"
            @execute="() => openModal('service-order-execution')"
            @postpone="() => openModal('new-postponement')"
            @reopen="() => openModal('service-order-reopen')"
            @resend-approval-notification="() => openModal('resend-approval-notification')"
            @show-messages="() => openModal('messages')"
            @show-ticket-messages="() => openModal('ticket-messages')"
            @withdraw-vehicle="() => openModal('new-vehicle-withdrawal')"
          )

          template(v-if="hasAssignment")
            .v-separator(:style="{ height: '40px' }")
            .assignment.flex-row.v-align-middle
              initials-tag.initials-tag(:name="assigneeName", :seed="assigneeRid")
              .flex-col
                label.label {{ $t(".toolbar.assignment.assignee.label") }}
                span.value {{ assignment.assigneeSimpleName }}

      .waves-separator

      .body
        .section
          service-order-associations-section(:service-order="serviceOrder")

        .section
          service-order-intro-section(:rid="params.id", :service-order="serviceOrder")

        .section
          service-order-info-section(:service-order="serviceOrder")

        .section
          service-order-services-section(:service-order="serviceOrder")

        .section
          service-order-products-section(:service-order="serviceOrder")

        .section
          service-order-cost-summary-section(:service-order="serviceOrder")

        .section
          service-order-quote-summary-section(:service-order="serviceOrder")

        .section.attachments
          //- XXX: precisa de .attachments-list para sobrecarregar cor do título
          service-order-attachments-list.attachments-list(
            :service-order-id="serviceOrder.id",
            icon="fa-paperclip"
          )

    service-order-modals-container(
      :current-modal="serviceOrderModals.current"
      :service-order="serviceOrder"
      :show.sync="serviceOrderModals.show"
      @update="onServiceOrderUpdate"
    )

</template>


<script>

import { events } from "utils.vue"

import InitialsTag from "@/views/monitoring/_components/initials-tag.vue"

import ServiceOrderAssociationsSection from "./_components/service-order-associations-section.vue"
import ServiceOrderCostSummarySection from "./_components/service-order-cost-summary-section.vue"
import ServiceOrderInfoSection from "./_components/service-order-info-section.vue"
import ServiceOrderIntroSection from "./_components/service-order-intro-section.vue"
import ServiceOrderModalsContainer from "../_components/service-order-modals-container.vue"
import ServiceOrderProductsSection from "./_components/service-order-products-section.vue"
import ServiceOrderQuoteSummarySection from "./_components/service-order-quote-summary-section.vue"
import ServiceOrderServicesSection from "./_components/service-order-services-section.vue"
import ServiceOrderToolbar from "./_components/service-order-toolbar.vue"
import StateTag from "./_components/state-tag.vue"

import ServiceOrderAttachmentsList from "@/views/monitoring/quotes/reviews/_components/service-order-attachments.vue"

import models from "@/models"


export default {
  components: {
    InitialsTag,

    ServiceOrderAssociationsSection,
    ServiceOrderAttachmentsList,
    ServiceOrderCostSummarySection,
    ServiceOrderInfoSection,
    ServiceOrderIntroSection,
    ServiceOrderModalsContainer,
    ServiceOrderProductsSection,
    ServiceOrderQuoteSummarySection,
    ServiceOrderServicesSection,
    ServiceOrderToolbar,
    StateTag
  },

  emits: [],

  mixins: [],

  // XXX: view não tem prop. faz parsing da url.
  // props: {},

  data() {
    return {
      i18nScope: "monitoring.service-orders.show",

      // flags
      initialized: false,
      erred:       false,
      fetching:    false,
      submitting:  false,

      creatingQuoteDraft: false,

      resource: null, // será carregado

      errors: {},

      // @implements View
      params: {},

      serviceOrderModals: {
        current: null,
        show:    false
      }
    }
  },

  computed: {
    // alias
    serviceOrder: {
      get()      { return this.resource  },
      set(value) { this.resource = value }
    },

    initializing() { return !this.initialized },
    loading()      { return this.initializing || this.fetching || this.submitting },

    // @implements View
    route() {
      let routeName = "monitoringServiceOrder"
      return this.$router.resolve({ name: routeName }).route
    },

    // @implements View
    backRoute() {
      return { name: "monitoring" }
    },

    hasAssignment()  { return this.serviceOrder.$present("assignment")     },
    hasQuote()       { return this.serviceOrder.$present("quoteState")     },

    assignment()   { return this.serviceOrder.assignment },
    assigneeName() { return this.assignment?.assigneeName },
    assigneeRid()  { return this.assignment?.assigneeRid },

    serviceKindText() {
      return models.MovidaServiceOrder.$tEnum("serviceKind", this.serviceOrder.serviceKind)
    }
  },


  watch: {
    // Responsável pelo funcionamento do recarregamento de dados em troca de rotas
    // @implements View
    $route(to, from) {
      // Se vem de outra rota (view) para essa, então ignore
      // - O ciclo de vida dos componentes vai levar isso em conta
      if (from.name !== to.name && to.name === this.route.name) return

      // Se está navegando para alguma rota aninhada, como um modal ou aba, ignore
      // - Está partindo dessa rota/view para uma aninhada ou é navegação interna de rota aninhada
      const currentPath = this.$route.path
      if (currentPath !== this.route.path && currentPath.startsWith(this.route.path)) return

      // Senão, reinicialize a view
      this.$initialize()
    }
  },


  created() {
    this.$initialize()
  },

  beforeDestroy() {
    if (this.loading) this.$cancel()
  },


  methods: {
    async $initialize() {
      this.initialized = false

      await this.initialize()

      this.initialized = true
    },

    async initialize() {
      // route params parsing
      this.params.id = _.parse(this.$route.params.serviceOrderId, "string") // TODO: id or rid

      await this.$fetch()
    },

    async $cancel() {
      if (this.fetchPromise)  this.fetchPromise.cancel()
      if (this.submitPromise) this.submitPromise.cancel()

      await this.cancel()
    },

    async cancel() {},

    async $fetch() {
      this.fetching = true

      await this.fetch()

      this.fetching = false
    },

    async fetch() {
      try {
        this.fetchPromise = this.fetchServiceOrder()
        await this.fetchPromise

        if (!this.serviceOrder.quoteId) return

        this.fetchPromise = this.fetchQuote()
        await this.fetchPromise
      }
      catch (err) {
        // XXX: já deveria ter sido tratado exclusivamente em cada fetch*
        this.$err.log(err)
      }
      finally {
        this.fetchPromise = null
      }
    },

    async fetchQuote() {
      const { quoteId } = this.serviceOrder

      try {
        const response = await this.$newSdk.quotes.find({ quoteId })

        this.serviceOrder = new models.MovidaServiceOrder({
          ...this.serviceOrder,
          quote: response.data
        })
      }
      catch (err) {
        const error = err.error || err

        if (error.cancelled) return

        this.erred = true
        this.$err.log(err)
        this.$notifications.error(this.$t(".notifications.fetch.failure"))

        const errName = err.status || "error"
        events.$emit("view:error", errName)
      }
    },


    async fetchServiceOrder() {
      try {
        let response = await this.$newSdk.monitoring.serviceOrders.find(this.params.id)

        this.serviceOrder = response.data
      }
      catch (err) {
        let error = err.error || err

        // ignora cancelamento programático (axios)
        if (error.cancelled) return

        this.erred = true
        this.$err.log(err)
        this.$notifications.error(this.$t(".notifications.fetch.failure"))

        // se precisar falhar a view toda:
        let errName = err.status || "error"
        events.$emit("view:error", errName)
      }
    },

    // @implements View
    goBack() {
      this.$goBack({ fallback: this.backRoute })
    },

    openModal(name) {
      this.serviceOrderModals.current = name
      this.serviceOrderModals.show = true
    },

    async onEditQuote() {
      try {
        const id = this.hasQuote
          ? this.serviceOrder.quoteId
          : await this.createQuoteDraft()

        this.editQuote(id)
      }
      catch (error) {
        this.$err.log(error)
        this.$notifications.error(this.$t(".notifications.createQuoteDraft.failure"))
      }
    },

    async createQuoteDraft() {
      try {
        this.creatingQuoteDraft = true
        const { data } = await this.$newSdk.monitoring.serviceOrders.quotes.createDraft(
          { serviceOrderId: this.serviceOrder.id }
        )
        return data.id
      }
      finally {
        this.creatingQuoteDraft = false
      }
    },

    editQuote(id) {
      this.$router.push({ name: "editQuote", params: { id } })
    },

    onServiceOrderUpdate() {
      this.$fetch()
    }
  }
}

</script>

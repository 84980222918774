<style lang="scss" scoped>

.error {
  display: block;
  text-align: center;
  padding: 4px 12px;
  font-size: 14px;
  font-style: italic;

  .link {
    margin-left: 5px;
    font-size: 14px;
    font-style: italic;
    font-weight: normal;
  }
}

.empty {
  display: block;
  text-align: center;
  padding: 4px 12px;
  font-size: 14px;
  font-style: italic;
}

</style>


<template lang="pug">

  .maintenance-package-select-field
    select-field(
      :name="name",
      :label="label",
      :fetching="fetching",
      :disabled="disabled",
      :placeholder="placeholder",
      :optional="optional",

      :hide-label="hideLabel",
      option-as-value,
      :loading="loading || fetchingOption",
      :options="options",
      :default-filter="false",
      :errors="errors",
      searchable,

      :value="maintenancePackageOption",

      :menu-width="menuWidth",
      :menu-growth-side="menuGrowthSide",

      @input="onSelect",
      @search="onSearch",
      @open="_fetch",
      @close="onClose",
      @inputSearch="(newSearch) => search = newSearch"
    )
      template(#menuContentHeader)
        template(v-if="fetchError")
          label.error
            span {{ $t('.error.message') }}
            app-button.link(theme="link", @click="onSearch") {{ $t('.error.link') }}

      template(#menuContentFooter)
        template(v-if="!fetching && !fetchError && empty")
          label.empty {{ $t('.empty') }}

      template(#fetching)
        li.option {{ $t('.loading') }}

      template(#selected="{ props: { selected } }")
        slot(name="selected", :props="{ selected }")

      template(#option="{ props: { option, index, focus, active } }")
        slot(name="option", :props="{ option, index, focus, active }")
          maintenance-package-option(
            :option="option",
            :active="active",
            :focus="focus",
            :compact="compact"
          )

</template>


<script>

// Mixins
import FieldMixin from "@/mixins/field"

// Models
import models from "@/models"

export default {
  name: "MaintenancePackageSelectField",

  mixins: [FieldMixin],

  props: {
    value:          { type: [String, Number], default: null },
    dataAsValue:    { type: Boolean, default: false },
    compact:        { type: Boolean, default: false },
    menuWidth:      { type: Number, default: null },
    menuGrowthSide: { type: String, default: "right", validator: side => ["left", "right"].includes(side) }
  },

  data() {
    return {
      i18nScope: "components.maintenance-package.maintenance-package-select-field",

      search:         "",
      options:        [],
      fetchingOption: false,
      fetchError:     false,

      maintenancePackageOption: null,

      // Async
      fetching: false,
      request:  null
    }
  },

  computed: {
    empty() {
      return this.options.length === 0
    },

    // TODO: refatorar e usar um nome comum: selectedOption
    // workaround: usando computed como alias
    selectedOption() {
      return this.maintenancePackageOption
    }
  },

  watch: {
    value() {
      if (this.value === _.get(this.maintenancePackageOption, "value")) return

      if (_.blank(this.value)) {
        this.maintenancePackageOption = null
        return
      }

      if (!this.dataAsValue) {
        this.fetchOption(this.value)
      }
      else {
        this.maintenancePackageOption = {
          value: this.value.id,
          label: this.value.name,
          data:  this.value
        }
      }
    }
  },

  created() {
    if (_.blank(this.value)) return

    if (!this.dataAsValue) {
      this.fetchOption(this.value)
    }
    else {
      this.maintenancePackageOption = {
        value: this.value.id,
        label: this.value.name,
        data:  this.value
      }
    }
  },

  methods: {
    async fetchOption(id) {
      try {
        this.fetchingOption = true

        const response = await this.$sdk.maintenancePackages.get({ id })
        const normalizedData = _.camelizeKeys(response.data)

        this.maintenancePackageOption = {
          value: normalizedData.id,
          label: normalizedData.name,
          data:  new models.MaintenancePackage(normalizedData)
        }

      }
      catch (err) {
        if (!err.cancelled) this.fetchError = true
        this.$err.log(err)
      }
      finally {
        this.fetchingOption = false
      }
    },

    onClose() {
      this.options = []
    },

    onSelect(option) {
      this.maintenancePackageOption = option
      this.$emit("change-option", option)


      if (this.dataAsValue) {
        this.$emit("input", _.get(option, "data") || null)

      }
      else {
        // XXX precisamos enviar `null` ao invés de `undefined`
        // para que o Axios não desconsidere o parametro
        this.$emit("input", _.get(option, "value") || null)
      }
    },


    // Busca assincrona de _options_
    async _fetch() {
      let currentRequest

      try {
        if (this.request) this.request.cancel("Duplicated fetch")

        currentRequest = this.$sdk.maintenancePackages.list({ params: { q: this.search } })
        this.request = currentRequest

        this.fetching = true

        const response = await currentRequest
        let normalizedData = _.camelizeKeys(response.data)

        this.options = normalizedData.map(data => {
          const maintenancePackage = new models.MaintenancePackage(data)
          return {
            value: data.id,
            label: maintenancePackage.name,
            data:  maintenancePackage
          }
        })
      }

      catch (error) {
        if (!error.cancelled) this.fetchError = true
        this.$err.log(error)
      }

      finally {
        if (this.request === currentRequest) {
          this.request = null
          this.fetching = false
        }
      }
    },

    // Debounce para evitar sobrecarregar servidor com disparos de busca
    fetch: _.debounce(function debounce() {
      this._fetch()
    }, 500),

    onSearch() {
      this.fetching = true
      this.fetchError = false
      this.fetch()
    }
  }
}

</script>

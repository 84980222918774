<script>

import DownloadButton from "./download-button"

export default {
  extends: DownloadButton,

  data() {
    return {
      i18nScope: "suppliers.deals.products.index.current-spreadsheet-download-button"
    }
  },

  computed: {
    fileName() {
      return `produtos-acordo-${this.dealId}.xlsx`
    },

    icon() {
      return "fa-file-alt"
    }
  },

  methods: {
    request() {
      return this.$http.post(`/deals/${this.dealId}/products/spreadsheet`, {}, {
        responseType:       "blob",
        onDownloadProgress: this.onDownloadProgress
      })
    }
  }
}

</script>

import Base from "./base"


class VehicleReceipt extends Base {

  static get attrs() {
    return _.uniq([
      ...super.attrs,
      "at",
      "towTransport",
      "ignitionProblem",
      "fuelLevel",
      "currentKm",
      "description",
      "serviceOrderId",

      "authorId",
      "authorName"
    ])
  }

  static get constraints() {
    return {
      at:              { presence: true },
      towTransport:    { presence: true },
      ignitionProblem: { presence: true },
      fuelLevel:       { presence: true },
      currentKm:       { presence: true }
    }
  }

}


export default VehicleReceipt

<template lang="pug">

  .products-table
    template(v-if="loading")
      .skeleton-table
        product-row-skeleton.skeleton-row(
          v-for="i in 5",
          :key="`products-row-skeleton-${i}`"
        )

    template(v-else)
      products-table-header(
        :loading="loading",
        @sort="param => $emit('sort', param)"
        :sort-param="sortParam",
        :sort-order="sortOrder",
        data-testid="productsTableHeader"
      )

      template(v-if="empty")
        .empty.flex.center
          .flex.column-direction.vertical-center
            img.icon(:src="emptyIcon")
            span.message {{ $t('index.empty.message') }}

      template(v-else)
        product-row(
          v-for="product in products",
          :key="product.id",
          :product="product",
          :loading="loading",
          :show-actions="showActions"
          @remove="val => $emit('remove', val)",
          data-testid="productRow"
        )

</template>



<script>

import ProductsTable from "@/views/products/index/products-table.vue"
import ProductsTableHeader from "./products-table-header.vue"
import ProductRow from "./product-row.vue"

export default {
  components: {
    ProductRow,
    ProductsTableHeader
  },

  extends: ProductsTable
}

</script>

import Base from "../base"

class MovidaConsultant extends Base {
  static get attrs() {
    return _.uniq([
      ...super.attrs,
      "name",
      "username",
      "email"
    ])
  }
}

export default MovidaConsultant

<style scoped lang="scss">

.edit-vehicle-model {
  padding-bottom: 24px;
}

.btn {
  margin-bottom: 12px;
}

.header {
  padding-bottom: 16px;
  border-bottom: 1px solid $light-gray-3;
}

.title {
  font-size: 30px;
  font-weight: 500;
  font-family: $secondary-font;
}

.fields-content {
  margin-bottom: 24px;
}

.id-tag {
  margin: 0 8px;
}

</style>


<template lang="pug">

  .edit-vehicle-model
    header.header.flex.vertical-center
      h1.title {{ $t('.title') }}
      id-tag.id-tag(:value="resource.id", size="big")

    form(@submit.prevent="submit", method="post")
      form-fields.fields-content(
        :fetching="fetching",
        :submitting="submitting",
        :errors="errors",
        :resource="resource",
        @select-type="onSelectTypeOption",
        edit,
        data-test="formFields"
      )

      section.actions.flex.space-between.vertical-center
        app-button(:to="{ name: 'vehicleModel', params: { id } }", theme="gray", outline, :disabled="submitting")
          span {{ $t('btn.cancel') }}

        app-button(type="submit", :loading="submitting", data-test="submitButton")
          span {{ $t('btn.update.label') }}
          span(slot="loading") {{ $t('btn.update.loading') }}

</template>


<script>

// import { v4 as uuid } from 'uuid'

// Mixins
import { mixins } from "movida-common.vue"

// Extends
import View from "@/views/view"

// Components
import FormFields from "./forms/form-fields"
import VehicleModelTypeSelectField from "./components/vehicle-model-type-select-field.vue"

// Models
import models from "@/models"

const { FormMixin, Fetchable } = mixins

const {
  // SpareTireSpecification,
  // TireSpecification,
  // TireSize,
  // TireSpeedRating,
  // TireLoadIndex,
  VehicleModel
} = models

export default {
  name: "EditVehicleModel",

  components: {
    FormFields,
    VehicleModelTypeSelectField
  },

  extends: View,

  mixins: [Fetchable, FormMixin],

  data() {
    return {
      i18nScope: "vehicle-models.edit",

      id: this.$route.params.id,

      // Fetchable mixin
      resource: {},

      model: null,

      name: null,

      nestedAttributes: {
        tireSpecification: {
          sizes:        null,
          loadIndices:  null,
          speedRatings: null
        },

        spareTireSpecification: {
          sizes:        null,
          loadIndices:  null,
          speedRatings: null
        }
      }
    }
  },

  watch: {
    // - XXX: tornando pneu specs opcional
    // ref: https://github.com/caiena/movida-gmf/issues/1191
    // resource: {
    //   deep: true,
    //   handler(newResource, oldResource) {
    //     if (_.blank(newResource) || _.blank(oldResource)) return

    //     let kind = newResource.$get('tireSpecification.kind')

    //     if (kind !== 'run_flat') {
    //       this.resource.spareTireSpecification.$unmarkForDestruction()
    //     } else {
    //       this.resource.spareTireSpecification.$markForDestruction()
    //     }
    //   }
    // }
  },

  methods: {
    buildResource(type, attrs = {}) {
      return VehicleModel.build({
        ...attrs,

        type
      })
    },

    onSelectTypeOption(option) {
      if (option === this.resource.type) return

      this.errors = {}

      if (_.blank(option)) {
        delete this.resource.type
        this.resource = this.buildResource(null, this.resource)
        return
      }

      this.resource = this.buildResource(option, this.resource)
    },

    submitRequest() {
      const params = this.serialize(this.resource, this.nestedAttributes)
      return this.$sdk.vehicleModels.update({ id: this.id, params })
    },

    submitSuccess({ data }) {
      this.$notifications.info(this.$t(".notifications.submit.success", { name: this.resource.displayName }))
      this.$router.push({ name: "vehicleModel", params: { id: data.id } })
    },

    submitError(err) {
      this.$err.log(err)
      this.$notifications.error(this.$t(".notifications.submit.error"))
    },

    // @override Fetchable mixin
    fetch() {
      return this.$sdk.vehicleModels.get({ id: this.id })
    },

    // @override Fetchable mixin
    onFetchSuccess() {
      this.resource = VehicleModel.build(this.resource)

      // - XXX: tornando pneu specs opcional
      // ref: https://github.com/caiena/movida-gmf/issues/1191
      // if (_.blank(this.resource.tireSpecification)) {
      //   const tireSize = new TireSize()
      //   tireSize.tempId = uuid()
      //   const loadIndex = new TireLoadIndex()
      //   loadIndex.tempId = uuid()
      //   const speedRating = new TireSpeedRating()
      //   speedRating.tempId = uuid()

      //   this.resource.tireSpecification = new TireSpecification({
      //     sizes:        [tireSize],
      //     speedRatings: [speedRating],
      //     loadIndices:  [loadIndex]
      //   })
      // }

      // if (_.blank(this.resource.spareTireSpecification)) {
      //   this.resource.spareTireSpecification = new SpareTireSpecification()
      //   this.resource.spareTireSpecification._destroy = true
      // }
    },

    // @override Fetchable mixin
    onFetchError(err) {
      // Dealing with appError from extension view.vue
      if (this.hasViewError(err)) this.appError = err

      this.$err.log(err)
      this.$notifications.error(this.$t(".notifications.fetch.failure"))
    }
  }
}

</script>

<style lang="scss" scoped>

.vehicle-issue-row {
  display: grid;
  grid-template-columns: 1fr 70px;

  .column {
    padding: 0 12px;
    border-bottom: 1px solid $light-gray-3;
    height: 56px;
    overflow: hidden;

    &.content-column {
      overflow: hidden;

      .vehicle-part,
      .symptom {
        font-family: $secondary-font;
        font-size: 18px;
        font-weight: 400;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .icon {
        margin-right: 8px;
      }

      .diamond-icon {
        margin: 0 8px;
        font-size: 4px;
        color: $gray-3;
      }
    }
  }
}

</style>


<template lang="pug">

  .vehicle-issue-row

    .column.content-column.flex.vertical-center
      img.icon(:src="vehiclePartIcon")
      span.vehicle-part {{ vehiclePartName }}

      i.diamond-icon.fa.fa-diamond.flex.center.vertical-center

      i.icon.fal.fa-engine-warning
      span.symptom {{ symptomName }}

    .column.actions-column
      slot(name="actions")

</template>


<script>

// Assets
import vehiclePartIcon from "@/assets/images/icons/sidebar/vehicle-part.svg"

export default {
  name: "VehicleIssueRow",

  props: {
    vehicleIssue: { type: Object, default: () => {} }
  },

  data() {
    return {
      i18nScope: "services.vehicle-issues.components.vehicle-issue-row",
      vehiclePartIcon
    }
  },

  computed: {
    vehiclePartName() {
      return _.get(this.vehicleIssue, "vehiclePart.name")
    },

    symptomName() {
      return _.get(this.vehicleIssue, "symptom.name")
    }
  }
}

</script>

<style lang="scss" scoped>

.edit {
  .section {
    & + .section {
      border-top: 1px solid $light-gray-3;
      margin-top: 24px;
      padding-top: 24px;
    }

    &.header {
      .left {
        margin-bottom: 8px;

        .id-tag {
          margin: 0 8px;
        }

        .title,
        .subtitle {
          font-family: $secondary-font;
          font-weight: 500;
        }

        .subtitle {
          font-size: 20px;
        }

        .title {
          font-size: 38px;
        }
      }
    }

    &.services-list {
      .supplier-selected {
        font-size: 14px;
        font-weight: 400;
      }
    }

    ::v-deep .empty {
      padding: 12px;
    }

    &.footer {
      .confirm {
        margin-right: 18px;
      }

      .back {
        margin-right: 24px;

        .icon {
          margin-right: 8px;
        }
      }

      .next .icon {
        margin-left: 8px;
      }
    }
  }
}

</style>


<template lang="pug">

  .edit
    form(@submit.prevent="submit", method="post")
      .section.header.flex.space-between
        .left
          .flex.vertical-center
            h2.subtitle {{ ServiceOrderRepair.$tEnum('kind', serviceOrder.kind) }}
            id-tag.id-tag(:value="ticket.id")
            construction-badge

          h1.title {{ $t('.title') }}

        vehicle-info-tag.no-shrink(:vehicle="vehicle")

      .section.services-list
        services-index(
          :service-order-id="serviceOrder.id",
          :submitting="submitting",
          :editable="!hasSupplier",
          @status="onServicesChange"
        )
          template(#header="{ props: { servicesCount, fetching: servicesFetching, erred: servicesErred, openNewServiceModal } }")
            .header
              .flex.space-between.vertical-center
                h2.title {{ $t('.services.title') }}
                template(v-if="!hasSupplier")
                  app-button.add(
                    v-if="!servicesFetching && !servicesErred"
                    theme="link",
                    @click="openNewServiceModal"
                  )
                    i.icon.far.fa-plus
                    span {{ $t('.services.btn.add.label') }}

                template(v-else)
                  span.supplier-selected {{ $t('.services.supplier-selected') }}

              .count
                span(v-if="!servicesFetching && !servicesErred") {{ $t('.services.servicesCount', { count: servicesCount }) }}

      .section.attachment-list
        attachments-list(
          :service-order-id="serviceOrder.id",
          :submitting="submitting",
          editableList,
          editableAttachments
        )

      .section.description-section
        textarea-field(
          name="serviceOrder[description]",
          v-model="serviceOrder.description",
          :disabled="submitting",
          :errors="errors.description",
          :placeholder="$t('.fields.description.placeholder')"
          optional
        )

      .section.footer.flex.space-between
        .cancel-actions.flex.vertical-center
          app-button.back(
            outline,
            theme="gray",
            :loading="submitting",
            :to="{ name: 'editDraftTicket', params: { id: ticket.id } }"
          )
            i.icon.far.fa-arrow-left
            span {{ $t('.btn.back') }}

          destroy-ticket-action(
            :ticket-id="ticket.id",
            :ticket-store-id="serviceOrder.storeId",
            :loading="submitting",
            @destroy="onDestroy"
          )

        .flex
          app-button.confirm(
            :loading="submitting",
            theme='link'
            @click="submitAndExit"
          )
            span {{ $t('.btn.confirmAndExit') }}

          app-button.next(
            :loading="submitting",
            @click="submit"
          )
            template(v-if="hasServices")
              span {{ $t('.btn.next') }}
              i.icon.far.fa-arrow-right

            template(v-else)
              span {{ $t('.btn.update') }}

</template>


<script>

// View
import LoadedView from "@/views/loaded-view"

// Mixins
import FormMixin from "@/mixins/form-mixin"

// Components
import ConstructionBadge from "../components/construction-badge.vue"
import DestroyTicketAction from "../components/destroy-ticket-action.vue"
import VehicleInfoTag from "../components/vehicle-info-tag.vue"
import AttachmentsList from "./_components/attachments-list.vue"
import ServicesIndex from "./services/index.vue"

// Models
import models from "@/models"
const { ServiceOrderRepair } = models

export default {
  name: "EditDraftTicketContent",

  components: {
    ConstructionBadge,
    DestroyTicketAction,
    VehicleInfoTag,
    AttachmentsList,
    ServicesIndex
  },

  extends: LoadedView,

  mixins: [FormMixin],

  props: {
    ticket: { type: Object, required: true }
  },

  data() {
    return {
      i18nScope: "tickets.drafts.contents.edit",
      routeName: "editDraftTicketContent",

      resource: new ServiceOrderRepair(this.ticket.serviceOrders[0]),

      guardFormOnLeave: false,
      confirmAndExit:   false,

      ServiceOrderRepair,

      hasServices: false
    }
  },

  computed: {
    vehicle() {
      return _.get(this.ticket, "vehicle")
    },

    serviceOrder: {
      get() { return this.resource },

      set(value) { this.resource = value }
    },

    hasSupplier() {
      return _.present(this.serviceOrder.supplier)
    }
  },

  created() {
    this.initialSerializedAttributes = this.serializeAttributes()
    this.guardFormOnLeave = true
  },

  methods: {
    parseRoute() {},

    onDestroy() {
      this.$router.push({ name: "tickets" })
    },

    onServicesChange(hasServices) {
      this.hasServices = hasServices
    },

    // @override Form mixin
    serializeAttributes() {
      return this.resource.$serialize()
    },

    // @override Form mixin
    submitRequest() {
      return this.$newSdk.serviceOrders.drafts.update({ serviceOrderId: this.serviceOrder.id, params: this.serializeAttributes() })
    },

    // @override Form mixin
    onSubmitSuccess(response) {
      if (this.confirmAndExit) this.$router.push({ name: "tickets" })

      else {
        this.$emit("reload")

        if (!this.hasServices) this.$notifications.info(this.$t(".notifications.submit.success", { id: this.serviceOrder.id }))
        else if (this.hasSupplier) this.$router.push({ name: "draftTicketBooking", params: { id: this.ticket.id } })
        else this.$router.push({ name: "editDraftTicketBooking", params: { id: this.ticket.id } })
      }
    },

    submitAndExit() {
      this.confirmAndExit = true
      this.submit()
    }
  }
}

</script>

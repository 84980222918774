<template lang="pug">

  .mobiauto-version-table-header(:class="{ loading }")
    .column.unsortable.flex.vertical-center
      span.text #

    .column.unsortable.flex.vertical-center
      span.text {{ $t('.columns.brand.label') }}

    .column.unsortable.flex.vertical-center
      span.text {{ $t('.columns.model.label') }}

    .column.unsortable.flex.vertical-center
      span.text {{ $t('.columns.type.label') }}

    .column.unsortable.flex.vertical-center
      span.text {{ $t('.columns.fipe.label') }}

    .column.unsortable.flex.vertical-center
      span.text {{ $t('.columns.category.label') }}

    .column.flex.vertical-center.center
      template(v-if="loading")
        i.loader-icon.fas.fa-spin.fa-spinner

</template>


<script>

import MobiautoVersionsTableHeader from "@/views/vehicle-models/index/mobiauto-versions-table-header.vue"

export default {
  // TODO: Remover herança, não é o mesmo modelo!
  extends: MobiautoVersionsTableHeader
}

</script>

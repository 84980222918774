<style lang="scss" scoped>

.confirmation-wizard-section {
  .section {
    & + .section {
      border-top: 1px solid $light-gray-3;
      margin-top: 24px;
      padding-top: 24px;
    }

    &.header {
      margin-bottom: 8px;

      .id-tag {
        margin: 0 8px;
      }

      .title,
      .subtitle {
        font-family: $secondary-font;
        font-weight: 500;
      }

      .subtitle {
        font-size: 20px;
      }

      .title {
        font-size: 38px;
      }
    }

    .section-header {
      margin-bottom: 16px;

      .section-title {
        font-family: $secondary-font;
        font-weight: 500;
        font-size: 20px;
      }

      .section-subtitle,
      .edit,
      .edit .link {
        font-size: 14px;
        font-weight: 400;
      }

      .edit {
        .link {
          margin-left: 4px;
        }
      }
    }

    &.store-section,
    &.date-section {
      .icon-container {
        width: 24px;
        height: 24px;
        margin-right: 16px;

        &.store-icon {
          background-color: $purple;
        }

        .icon {
          font-size: 20px;
          color: $purple;
        }
      }
    }

    &.store-section,
    &.supplier-section {
      .text-title {
        margin-bottom: 4px;
      }
    }

    &.description-section {
      .description {
        font-weight: 400;
      }
    }

    .text-title {
      font-family: $secondary-font;
      font-weight: 400;
      font-size: 18px;
    }

    .diamond-icon {
      color: $gray-3;
      font-size: 4px;
      margin: 0 4px;
    }

    .empty-section {
      max-height: 238px;

      .illustration {
        --size: 48px;
        width: var(--size);
        height: var(--size);
      }

      .empty-message {
        font-weight: 300;
        font-size: 14px;
        font-style: italic;
        margin-top: 8px;
      }
    }

    .pickup-service {
      margin-top: 16px;

      .icon-container {
        width: 24px;
        height: 24px;
        margin-right: 8px;
      }

      .description-label {
        margin-left: 32px;
      }
    }
  }

  .footer {
    background-color: $light-gray;
    padding: 16px 24px;
    border-radius: 8px;
    margin-top: 24px;

    .back {
      margin-right: 24px;

      .icon {
        margin-right: 8px;
      }
    }

    .next .icon {
      margin-left: 8px;
    }
  }
}

.confirmation-modal {
  height: 255px;

  .icon-container {
    width: 64px;
    height: 64px;
    border-radius: 50%;
    background-color: $light-green;

    &.loading {
      background-color: $light-gray;

      .icon {
        color: $orange;
      }
    }

    .icon {
      font-size: 36px;
      color: $dark-green;

      &.spin {
        animation: fa-spin 0.6s infinite linear;
        cursor: wait;
        line-height: 0.75em;
      }
    }
  }

  .success-title {
    font-family: $secondary-font;
    font-weight: 500;
    font-size: 38px;
    margin: 16px 0;
  }

  .success-text {
    font-weight: 400;
    font-size: 20px;
  }
}

</style>


<template lang="pug">

  .confirmation-wizard-section
    .section.header
      .flex.vertical-center
        h2.subtitle {{ ServiceOrderRepair.$tEnum('kind', serviceOrder.kind) }}
        id-tag.id-tag(:value="ticket.id")
        construction-badge

      h1.title {{ $t('.title') }}

    .section.vehicle-section
      .section-header
        .flex.vertical-center.space-between
          h3.section-title {{ $t('.sections.vehicle.title') }}
          .edit
            span {{ $t('.edit') }}
            app-button.link(theme="link", :to="editTicketVehicleRoute") {{ $t('.wizard-sections.vehicle.title') }}

      vehicle-info-banner(:vehicle="vehicle")

    .section.store-section
      .section-header
        h3.section-title {{ $t('.sections.store.title') }}

      .flex
        .icon-container.store-icon.flex.center.vertical-center(:style="storeIconStyle")

        .content
          span.text-title {{ storeIdentifier }}
          .flex.vertical-center
            span {{ storeAddress.addressLine1 }}
            i.diamond-icon.fas.fa-diamond
            span {{ storeAddressCityIdentifier }}

    .section.date-section
      .section-header
        .flex.vertical-center.space-between
          h3.section-title {{ $t('.sections.date.title') }}
          .edit
            span {{ $t('.edit') }}
            app-button.link(theme="link", :to="ticketBookingRoute") {{ $t('.wizard-sections.supplier.title') }}

      .flex.vertical-center
        .icon-container.flex.center.vertical-center
          i.icon.fas.fa-calendar-alt

        span.text-title {{ confirmationDate }}

    .section.supplier-section
      .section-header
        .flex.vertical-center.space-between
          h3.section-title {{ $t('.sections.supplier.title') }}
          .edit
            span {{ $t('.edit') }}
            app-button.link(theme="link", :to="ticketBookingRoute") {{ $t('.wizard-sections.supplier.title') }}

      span.text-title {{ supplier.tradingName }}
      .flex.vertical-center
        span {{ supplierAddress.addressLine1 }}

      .pickup-service
        .flex.vertical-center
          .icon-container.flex.vertical-center.center
            i.icon.fa-people-arrows(:class="pickupService ? 'fas' : 'fal'")
          check-item.title(
            :label="$t('models.supplier.attributes.pickupService')",
            :checked="!pickupService",
            :tooltip="$t('.sections.supplier.pickupService.tooltip')"
          )

        template(v-if="pickupService")
          .description-label
            app-span.description(:value="$t('.sections.supplier.pickupService.description')")

    .section.services-section
      services-index(:service-order-id="serviceOrder.id")
        template(#header="{ props: { servicesCount } }")
          .section-header
            .flex.vertical-center.space-between
              h3.section-title {{ $t('.sections.services.title') }}
              .edit
                span {{ $t('.edit') }}
                app-button.link(theme="link", :to="editTicketContentRoute") {{ $t('.wizard-sections.services.title') }}

            .span.section-subtitle {{ $t('.sections.services.subtitle', { count: servicesCount }) }}

    .section.attachments-section
      service-order-attachments-index(:service-order-id="serviceOrder.id")
        template(#empty)
          .empty-section.flex.column-direction.center.vertical-center
            img.illustration(:src="paperclipIllustration")
            app-span.empty-message(:value="$t('.sections.attachments.empty.message')")

        template(#header="{ props: { attachmentsCount } }")
          .section-header
            .flex.vertical-center.space-between
              h3.section-title {{ $t('.sections.attachments.title') }}
              .edit
                span {{ $t('.edit') }}
                app-button.link(theme="link", :to="editTicketContentRoute") {{ $t('.wizard-sections.services.title') }}

            .span.section-subtitle {{ $t('.sections.attachments.subtitle', { count: attachmentsCount }) }}

    .section.description-section
      .section-header
        .flex.vertical-center.space-between
          h3.section-title {{ $t('.sections.description.title') }}
          .edit
            span {{ $t('.edit') }}
            app-button.link(theme="link", :to="editTicketContentRoute") {{ $t('.wizard-sections.services.title') }}

      template(v-if="serviceOrder.description")
        span.description {{ serviceOrder.description }}

      template(v-else)
        .empty-section.flex.column-direction.center.vertical-center
          img.illustration(:src="messageIllustration")
          app-span.empty-message(:value="$t('.sections.description.empty.message')")

    .footer.flex.space-between
      .cancel-actions.flex.vertical-center
        app-button.back(
          outline,
          theme="gray",
          :loading="submitting",
          :to="ticketBookingRoute"
        )
          i.icon.far.fa-arrow-left
          span {{ $t('.btn.back') }}

        destroy-ticket-action(
          :ticket-id="serviceOrder.ticketId",
          :ticket-store-id="serviceOrder.storeId",
          :loading="submitting",
          @destroy="onDestroy"
        )

      app-button.next(
        :loading="submitting",
        @click="confirm"
      )
        span {{ $t('.btn.next') }}
        i.icon.far.fa-check

    app-modal(
      v-if="showConfirmationModal",
      :close-button="isConfirmed",
      :width="672",
      @close="onCloseConfirmation"
    )
      .confirmation-modal.flex.vertical-center.center
        template(v-if="isConfirmed")
          .flex.column-direction.vertical-center
            .icon-container.flex.center.vertical-center
              i.icon.fas.fa-check
            span.success-title {{ $t('.confirmationModal.title') }}
            span.success-text {{ $t('.confirmationModal.message') }}

        template(v-else)
          .icon-container.loading.flex.center.vertical-center
            i.icon.fas.fa-circle-notch.spin

</template>


<script>

// Assets
import storesSolid from "@/assets/images/icons/sidebar/stores-icon-solid.svg"
import paperclipIllustration from "@/assets/images/illustrations/paperclip.svg"
import messageIllustration from "@/assets/images/illustrations/message.svg"

// View
import LoadedView from "@/views/loaded-view"

// Mixins
import FormMixin from "@/mixins/form-mixin"

// Components
import ConstructionBadge from "../components/construction-badge.vue"
import DestroyTicketAction from "../components/destroy-ticket-action.vue"
import VehicleInfoBanner from "../components/vehicle-info-banner.vue"
import ServiceOrderAttachmentsIndex from "../contents/attachments/index.vue"
import ServicesIndex from "../contents/services/index.vue"

// Models
import models from "@/models"
const { ServiceOrderRepair } = models

export default {
  components: {
    ConstructionBadge,
    DestroyTicketAction,
    VehicleInfoBanner,
    ServiceOrderAttachmentsIndex,
    ServicesIndex
  },

  extends: LoadedView,

  mixins: [FormMixin],

  props: {
    ticket: { type: Object, required: true }
  },

  data() {
    return {
      i18nScope: "tickets.drafts.confirmations.new",
      routeName: "newDraftTicketConfirmation",

      ServiceOrderRepair,

      paperclipIllustration,
      messageIllustration,

      guardFormOnLeave: false,

      showConfirmationModal: false,
      isConfirmed:           false
    }
  },

  computed: {
    vehicle() {
      return _.get(this.ticket, "vehicle")
    },

    serviceOrder() {
      return _.get(this.ticket, "serviceOrders[0]")
    },

    store() {
      return _.get(this.serviceOrder, "store") || {}
    },

    storeAddress() {
      return _.get(this.store, "address") || {}
    },

    storeAddressCity() {
      return _.get(this.storeAddress, "city") || {}
    },

    supplier() {
      return _.get(this.serviceOrder, "supplier") || {}
    },

    supplierAddress() {
      return _.get(this.supplier, "address") || {}
    },

    pickupService() {
      return _.get(this.serviceOrder, "pickupService")
    },

    storeIconStyle() {
      return {
        "mask-image": `url(${storesSolid})`
      }
    },

    storeIdentifier() {
      const { initials, name } = this.store

      return `${initials} - ${name}`
    },

    storeAddressCityIdentifier() {
      const { name, stateCode } = this.storeAddressCity

      return `${name}, ${stateCode}`
    },

    timestamp() {
      return this.serviceOrder.estimatedStartAt
    },

    diffInDays() {
      const today = moment.utc().startOf("day")
      const availableAt = moment(this.timestamp).startOf("day")

      return availableAt.diff(today, "days")
    },

    confirmationDate() {
      return `${this.$t(".sections.date.diffInDays", { count: this.diffInDays })}${this.weekday}, ${this.dateLabel}`
    },

    weekday() {
      return this.$l("date.formats.weekDay", this.timestamp)
    },

    dateLabel() {
      return this.$standardTime(this.timestamp, "short")
    },

    editTicketVehicleRoute() {
      return { name: "editDraftTicketVehicle", params: { id: this.ticket.id } }
    },

    ticketBookingRoute() {
      return { name: "draftTicketBooking", params: { id: this.ticket.id } }
    },

    editTicketContentRoute() {
      return { name: "editDraftTicketContent", params: { id: this.ticket.id } }
    }
  },

  created() {
    // Impede a confirmação de OS incompleta
    if (_.blank(this.supplier)) this.$router.replace(this.editTicketContentRoute)
  },

  methods: {
    parseRoute() {},

    onDestroy() {
      this.$router.push({ name: "tickets" })
    },

    confirm() {
      this.showConfirmationModal = true
      this.submit()
    },

    // @override Form mixin
    submitRequest() {
      return this.$newSdk.tickets.drafts.publications.create({ ticketId: this.ticket.id })
    },

    // @override Form mixin
    onSubmitSuccess(response) {
      this.isConfirmed = true
    },

    // @override Form mixin
    onSubmitError(err) {
      const error = err.error || err

      this.submitError = error
      this.submitErred = true

      this.$err.log(this.submitError)
      this.$notifications.error(this.$t(".notifications.submit.error"))

      if (error.originalError) this.setFormErrors(error.originalError)

      this.showConfirmationModal = false
    },

    onCloseConfirmation() {
      this.showConfirmationModal = false
      this.$router.push({ name: "storeTicket", params: { id: this.store.id, ticketId: this.ticket.id } })
    }
  }
}

</script>

<style lang="scss" scoped>

.best-supplier-badge {
  display: flex;
  align-items: center;
  background: $orange;
  border-radius: 32px;
  width: 127px;
  height: 22px;
  padding: 0 8px 0 8px;

  ::v-deep .inside {
    fill: $white;
  }

  .icon {
    margin-right: 4px;
    width: 26px;
    height: 8px;
  }

  .text {
    font-family: $secondary-font;
    font-size: 12px;
    font-weight: 400;
    color: $white;
  }
}

</style>


<template lang="pug">

  .best-supplier-badge
    gmf-icon.icon
    span.text {{ $t('.label') }}

</template>


<script>

export default {
  data() {
    return {
      i18nScope: "tickets.drafts.bookings.edit.best-supplier-badge"
    }
  }
}

</script>

import Base from "../base"

class MovidaServiceOrderSupplierMessage extends Base {
  static get attrs() {
    return _.uniq([
      ...super.attrs,
      "at",
      "authorName",
      "authorId",
      "description",
      "motive"
    ])
  }

  static get enums() {
    return {
      motive: {
        vehicle_arrival:                      "vehicle_arrival",
        quote_related:                        "quote_related",
        approval_request_to_maintenance_team: "approval_request_to_maintenance_team",
        approval_request_to_customer_team:    "approval_request_to_customer_team",
        parts_delivery:                       "parts_delivery",
        delay_alert:                          "delay_alert",
        execution:                            "execution",
        vehicle_departure:                    "vehicle_departure",
        rework_alert:                         "rework_alert",
        cash_payment_alert:                   "cash_payment_alert",
        repetition_alert:                     "repetition_alert"
      }
    }
  }

  static get constraints() {
    return {
      description: { presence: true },
      motive:      { presence: true }
    }
  }
}

export default MovidaServiceOrderSupplierMessage

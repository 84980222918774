<style scoped lang="scss">

.new-service {
  padding-bottom: 24px;
}

.header {
  margin-bottom: 32px;
  padding-bottom: 16px;
  border-bottom: 1px solid $light-gray-3;
}

.title {
  font-size: 30px;
  font-weight: 500;
  font-family: $secondary-font;
}

.submit-actions {
  .button + .button {
    margin-left: 16px;
  }
}

</style>


<template lang="pug">

  .new-service
    header.header
      h1.title {{ $t('.title') }}

    form.form(@submit.prevent="submit", method="post")

      form-fields(
        :resource="resource",
        :submitting="submitting",
        :errors="errors"
      )

      section.flex.space-between.vertical-center
        app-button(
          :to="{ name: 'services' }",
          theme="gray",
          outline,
          :disabled="submitting"
        ) {{ $t('btn.cancel') }}


        .submit-actions
          app-button.button(
            type="submit",
            :loading="submitting && clearAfterSubmit",
            :disabled="submitting && !clearAfterSubmit",
            @click="clearAfterSubmit = true",
            outline,
            data-test="submitButton"
          )
            span {{ $t('.btn.submitAndNew.label') }}
            span(slot="loading") {{ $t('.btn.submitAndNew.loading') }}

          app-button.button(
            type="submit",
            :loading="submitting && !clearAfterSubmit",
            :disabled="submitting && clearAfterSubmit",
            data-test="submitButton"
          )
            span {{ $t('.btn.submit.label') }}
            span(slot="loading") {{ $t('.btn.submit.loading') }}

</template>


<script>

// Mixins
import { mixins } from "movida-common.vue"

// Local Components
import FormFields from "./form-fields"

// Extends
import View from "@/views/view"

// Models
import models from "@/models"

const { FormMixin } = mixins
const { Service } = models

export default {
  name: "NewService",

  components: {
    FormFields
  },

  extends: View,

  mixins: [FormMixin],

  data() {
    let resource = new Service()
    resource.vehicleIssueIds = []  // XXX: garantindo que prop exista para ser reativa

    return {
      i18nScope: "services.new",

      resource,
      model: "Service",

      clearAfterSubmit: false
    }
  },

  methods: {
    // @override Form mixin
    submitRequest() {
      const params = {
        ...this.resource.$serialize(),

        vehicle_issue_ids: this.resource.vehicleIssueIds
      }

      return this.$sdk.services.create({ params })
    },

    submitSuccess({ data: { id, name } }) {
      this.$notifications.info(this.$t(".notifications.submit.success", { name }))

      // TODO Resolver isso no mixin
      if (this.clearAfterSubmit) {
        this.resource = new Service()
        this.clearAfterSubmit = false
        return
      }

      this.$router.push({ name: "service", params: { id } })
    },

    submitError(err) {
      this.clearAfterSubmit = false
      this.$err.log(err)
      this.$notifications.error(this.$t(".notifications.submit.error"))
    }
  }
}

</script>

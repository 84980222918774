import statusCodes from "./status-codes"

class HttpError {
  constructor(error) {
    this.error = error
  }

  get original() {
    return this.error
  }

  get statusCode() {
    return this.error.status
  }

  get status() {
    return statusCodes[this.error.status]
  }
}

export default HttpError

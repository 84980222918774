<style lang="scss" scoped>

.vehicle-issue-solution-select-field {
  .error {
    display: block;
    text-align: center;
    padding: 4px 12px;
    font-size: 14px;
    font-style: italic;

    .link {
      margin-left: 5px;
      font-size: 14px;
      font-style: italic;
      font-weight: normal;
    }
  }

  .empty {
    display: block;
    text-align: center;
    padding: 4px 12px;
    font-size: 14px;
    font-style: italic;
  }

  ::v-deep .listed-option + .listed-option {
    border-top: 1px solid $light-gray-3;
  }
}

</style>


<template lang="pug">

  .vehicle-issue-solution-select-field
    select-field(
      :name="name",
      :label="label",
      :fetching="fetching",
      :disabled="disabled || !hasVehicleIssue",
      :optional="optional",
      :hide-label="hideLabel",

      option-as-value,
      :loading="loading || fetchingOption",
      :options="options",
      :default-filter="false",
      :errors="errors",
      searchable,

      :value="option",
      @input="onSelect",

      @search="onSearch",
      @open="_fetch",
      @close="onClose",
      @inputSearch="(newSearch) => search = newSearch"
    )
      template(#menuContentHeader)
        template(v-if="fetchError")
          label.error
            span {{ $t('.error.message') }}
            app-button.link(theme="link", @click="onSearch") {{ $t('.error.link') }}

      template(#menuContentFooter)
        template(v-if="!fetching && !fetchError && empty")
          label.empty {{ $t('.empty') }}

      template(#selected="{ props: { selected } }")
        service-selected-option(:service="selected.data.service")

      template(#option="{ props: { option, index, focus, active } }")
        service-option(
          :service="option.data.service"
          :active="active",
          :focus="focus"
        )

      template(#menuContentFooter)
        template(v-if="!fetching && !fetchError && empty")
          label.empty {{ $t('.empty') }}

</template>


<script>

// Extends
import AsyncSelect from "@/mixins/select/async"

// Mixins
import FieldMixin from "@/mixins/field"

export default {
  extends: AsyncSelect,

  mixins: [FieldMixin],

  props: {
    hideLabel:      { type: Boolean, default: false },
    vehicleIssueId: { type: [String, Number], default: null }
  },

  data() {
    return {
      i18nScope: "tickets.drafts.contents.services.components.vehicle-issue-solution-select-field",

      fetchingOption: false,
      fetchError:     false
    }
  },

  computed: {
    hasVehicleIssue() {
      return _.present(this.vehicleIssueId)
    }
  },

  methods: {
    // @overrides extends AsyncSelect
    dataToOption(data) {
      return {
        value: data.id,
        label: data.id,
        data
      }
    },

    // @overrides extends AsyncSelect
    async fetchOption(vehicleIssueSolutionId) {
      try {
        this.fetchingOption = true

        const { data } = await this.$newSdk.vehicleIssues.solutions.find({ vehicleIssueSolutionId })

        this.option = this.dataToOption(data)
        this.$emit("option-loaded", this.option)
      }
      catch (err) {
        this.$err.log(err)
      }
      finally {
        this.fetchingOption = false
      }
    },

    // @overrides extends AsyncSelect
    fetchRequest() {
      return this.$newSdk.vehicleIssues.solutions.all({ vehicleIssueId: this.vehicleIssueId, params: { q: this.search } })
    },

    // @overrides extends AsyncSelect
    onFetchSuccess({ data }) {
      const fetchedOptions = data.map(instance => (
        this.dataToOption(instance)
      ))

      this.options =  [...fetchedOptions]
    },

    // @overrides extends AsyncSelect
    onFetchError(error) {
      this.$err.log(error)
    }
  }
}

</script>

<style lang="scss" scoped>

.attachment-preview-data {
  overflow-x: hidden;

  .preview-container {
    width: 826px;
    height: 760px;

    background-color: $light-gray-3;
    transition: background-color .1s ease-in;

    &.preview-error {
      .message {
        font-style: italic;
        margin: 8px 0;
      }

      .reload {
        font-size: 14px;
      }
    }

    &.preview-error,
    &.preview-loading {
      .icon {
        font-size: 32px;

        &.spin {
          animation: fa-spin 0.6s infinite linear;
          cursor: wait;
        }
      }
    }

    &.preview {
      background-color: $dark-gray;
      overflow: hidden;

      .image-preview {
        max-width: 826px;
        max-height: 760px;
      }

      .video-preview,
      .audio-preview {
        width: 100%;
        height: 100%;
      }

      .pdf-preview {
        width: 826px;
        height: 760px;
      }
    }

    &.no-preview {
      .message {
        font-style: italic;
      }
    }

    .file-icon {
      color: var(--icon-color);
      font-size: 32px;
      margin-bottom: 8px;

      &.audio-player-icon {
        cursor: pointer;
      }
    }
  }

  .data {
    width: 330px;
    height: 760px;
    overflow: hidden;

    .top {
      padding: 24px;

      .actions {
        .action {
          width: 24px;
          height: 24px;

          & + .action {
            margin-left: 16px;
          }

          .icon {
            cursor: pointer;
          }
        }
      }

      .header {
        margin-top: 24px;

        .name-field {
          ::v-deep .input-inner {
            max-height: 300px;
          }
        }

        .name {
          font-size: 18px;
          font-weight: 400;
          margin-bottom: 8px;
          word-break: break-all;
        }

        .type {
          ::v-deep {
            .icon {
              margin-right: 4px;
            }

            .type {
              color: $gray-3;
            }
          }
        }

        .diamond-icon {
          font-size: 4px;
          color: $gray-2;
          margin: 0 8px;
        }

        .size {
          font-family: $secondary-font;
          font-weight: 400;
          font-size: 12px;
          color: $gray-3;
        }
      }
    }

    .description {
      overflow: auto;
      padding: 0 24px;

      &.overflowing {
        box-shadow: inset 0px 4px 8px rgba(0, 0, 0, 0.1);
      }

      .empty {
        font-weight: 400;
        color: $gray-3;
        font-style: italic;
      }

      .description-field {
        height: 100%;

        ::v-deep .input-wrapper {
          height: 100%;

          .input-inner {
            resize: none;
            height: calc(100% - 2px);
          }
        }
      }

      .description-content {
        white-space: break-spaces;
      }
    }

    .footer {
      margin: 24px;
    }
  }
}

</style>


<template lang="pug">

  .attachment-preview-data.flex
    template(v-if="erred")
      .preview-container.preview-error.flex.vertical-center.center.column-direction.no-shrink
        template(v-if="fetching")
          i.icon.fas.fa-spinner-third.spin
        template(v-else)
          i.icon.fas.fa-times

        span.message {{ $t('.fetch-erred.message') }}
        app-button.reload(theme="link", :loading="fetching", @click="fetch") {{ $t('.fetch-erred.retry') }}

    template(v-else-if="fetching")
      .preview-container.preview-loading.flex.vertical-center.center.column-direction.no-shrink
        i.icon.fas.fa-spinner-third.spin

    template(v-else-if="hasPreview")
      .preview-container.preview.flex.vertical-center.center.no-shrink
        template(v-if="previewURL")
          template(v-if="attachment.fileType === 'image'")
            img.image-preview(v-show="showPreview", :src="previewURL")

          template(v-else-if="attachment.fileType === 'video'")
            video.video-preview(
              v-show="showPreview",
              :src="previewURL",
              controls,
              autoplay
            )

          template(v-else-if="attachment.fileType === 'audio'")
            .audio-preview.flex.column-direction.space-between
              .flex.vertical-center.center.grow
                i.file-icon.audio-player-icon.fas(
                  :style="styles",
                  :class="{ [attachment.icon]: true }",
                  @click="toggleAudio"
                )
              audio(
                v-show="showPreview",
                :src="previewURL"
                controls,
                autoplay,
                ref="audioPlayer"
              )

          template(v-else-if="attachment.fileType === 'pdf'")
            pdf-viewer.pdf-preview(:src="previewURL")

    template(v-else)
      .preview-container.no-preview.flex.vertical-center.center.column-direction.no-shrink
        i.file-icon.fas(:class="{ [attachment.icon]: true }", :style="styles")
        span.message {{ $t('.no-preview') }}

    .data.grow.flex.column-direction.no-shrink
      .top.no-shrink
        .actions.flex.vertical-center.end
          .action.flex.center.vertical-center
            i.icon.far.fa-download(@click="$emit('download')")
          .action.flex.center.vertical-center(v-if="editable && !editing")
            i.icon.far.fa-pencil(@click="$emit('update:editing', true)")
          .action.flex.center.vertical-center
            i.icon.far.fa-times(@click="onClose")

        .header
          template(v-if="editable && editing")
            textarea-field.name-field(
              name="attachment[name]",
              v-model="resource.name",
              :loading="submitting",
              :errors="errors.name",
              :placeholder="$t('.fields.name.placeholder')",
              :label="$t('.fields.name.label')",
              data-testid="nameInput"
            )

          template(v-else)
            app-span.name(:value="attachment.name")
            .flex.vertical-center
              attachment-file-type.type(:attachment="attachment")
              i.diamond-icon.fas.fa-diamond
              app-span.size(:value="$asHumanSize(attachment.fileSize)")

      template(v-if="editable && editing")
        .description.grow
          textarea-field.description-field(
            name="attachment[description]",
            v-model="resource.description",
            :loading="submitting",
            :errors="errors.description",
            :placeholder="$t('.fields.description.placeholder')",
            hide-label,
            data-testid="descriptionInput"
          )

        .footer.flex.space-between
          app-button(
            outline,
            theme="gray",
            :loading="submitting",
            @click="cancel"
          ) {{ $t('btn.cancel') }}

          app-button(
            @click="checkAndSubmit",
            :loading="submitting"
          ) {{ $t('btn.confirm') }}

      template(v-else)
        .description(
          :class="{ overflowing: descriptionOverflows }",
          ref="descriptionElement"
        )
          template(v-if="attachment.description")
            app-span.description-content(:value="attachment.description")

          template(v-else)
            span.empty {{ $t('.no-description') }}

</template>


<script>

// Mixins
import FetchMixin from "@/mixins/fetch-mixin"
import FormMixin from "@/mixins/form-mixin"

// Models
import models from "@/models"
const { Attachment } = models

export default {
  name: "AttachmentPreviewData",

  mixins: [FetchMixin, FormMixin],

  props: {
    attachment: { type: Object, required: true },
    editing:    { type: Boolean, default: false },
    editable:   { type: Boolean, default: false },
    localEdit:  { type: Boolean, default: false }
  },

  data() {
    return {
      i18nScope: "components.attachments.attachment-preview-data",

      previewURL:  null,
      showPreview: false,

      autofetch: false,

      descriptionOverflows: false,

      resource: new Attachment(this.attachment),

      updated: false
    }
  },

  computed: {
    hasPreview() {
      return ["image", "video", "audio", "pdf"].includes(this.attachment.fileType)
    },

    styles() {
      return {
        "--icon-color": this.attachment.iconColor
      }
    }
  },

  created() {
    this.handleDescriptionOverflow()

    window.addEventListener("resize", this.handleDescriptionOverflow)

    this.initialSerializedAttributes = this.serializeAttributes()

    if (this.hasPreview) this.fetch()
  },

  destroyed() {
    window.removeEventListener("resize", this.handleDescriptionOverflow)
  },

  methods: {
    async handleDescriptionOverflow() {
      // XXX necessário para atualizar o layout antes de pegar a referência
      await this.$nextTick()

      const { descriptionElement } = this.$refs
      if (_.blank(descriptionElement)) return

      this.descriptionOverflows = descriptionElement.scrollHeight !== descriptionElement.clientHeight
    },

    toggleAudio() {
      const { audioPlayer } = this.$refs

      if (_.blank(audioPlayer)) return

      if (audioPlayer.paused) audioPlayer.play()
      else audioPlayer.pause()
    },

    async onClose() {
      const isDirty = this.checkDirty()

      if (!isDirty || await this.askDirtyLeaveConfirmation()) this.$emit("close")
    },

    // @override Fetch mixin
    fetchRequest() {
      return this.$newSdk.blobs.getDownloadURL({ blobId: this.attachment.blobId })
    },

    // @override Fetch mixin
    onFetchSuccess({ data }) {
      this.previewURL = data.url

      setTimeout(() => {
        this.showPreview = true
      }, 100)
    },

    async cancel() {
      if (!this.editable) return false

      const isDirty = this.checkDirty()

      if (!isDirty || await this.askDirtyLeaveConfirmation()) {
        this.$emit("update:editing", false)
        this.resource = new Attachment(this.attachment)

        return true
      }

      return false
    },

    checkAndSubmit() {
      if (!this.editable) return

      if (this.localEdit) {
        this.$emit("update:attachment", this.resource)
        this.$emit("update:editing", false)
        this.initialSerializedAttributes = this.serializeAttributes()

        this.onClose()
      }
      else this.submit()
    },

    // @override Form mixin
    serializeAttributes() {
      return this.resource.$serialize()
    },

    // @override Form mixin
    submitRequest() {
      return this.$newSdk.attachments.update({ attachmentId: this.attachment.id, params: this.serializeAttributes() })
    },

    // @override Form mixin
    onSubmitSuccess({ data }) {
      this.$notifications.info(this.$t(".notifications.submit.success"))

      /* eslint-disable vue/no-mutating-props */
      this.attachment.name = this.resource.name
      this.attachment.description = this.resource.description
      this.attachment.fileName = data.fileName
      /* eslint-enable vue/no-mutating-props */

      this.initialSerializedAttributes = this.serializeAttributes()

      this.$emit("update:editing", false)
      this.updated = true

      this.handleDescriptionOverflow()
    }
  }
}

</script>

<style lang="scss" scoped>

@import "@/assets/styles/field";

.suffix {
  position: absolute;
  font-size: 14px;
  font-weight: normal;
  color: $gray-3;
  top: 12px;
  right: 0;
  display: block;
  padding: 0 8px;
  user-select: none;
  pointer-events: none;
}

</style>


<template lang="pug">

  .input-field
    label.label-content(v-if="!hideLabel", :for="inputId", :class="{ error: hasErrors, focus }")
      span {{ labelText }}
      span(v-if="optional") {{ $t('form.optional') }}
      i.info-icon.far.fa-info-circle(v-if="info", @click="$emit('info-click')")

    app-flatpickr(
      :id="inputId",
      :disabled="disabled",
      :loading="loading",
      :error="hasErrors",
      :placeholder="placeholder",
      date-format="H:i",
      enable-time,
      hide-icon,
      hide-clear-button,
      :allow-input="false",
      :min-time="minTime",
      :max-time="maxTime",
      no-calendar,
      :value="value",
      @input="onInput",
    )

    template(v-if="hasErrors")
      span.error-message {{ errors[0] }}

</template>


<script>

import FieldMixin from "@/mixins/field"

export default {
  name: "TimeField",

  mixins: [FieldMixin],

  props: {
    minTime: { type: String, default: null },
    maxTime: { type: String, default: null }
  },

  data() {
    return {
      focus: false
    }
  },

  methods: {
    onInput(value) {
      if (this.disabled) return

      this.$emit("input", value)
    }
  }
}

</script>

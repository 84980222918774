<style lang="scss" scoped>

.id-tag {
  margin-left: 8px;
}

.info + .info {
  margin-left: 24px;
}

.nullable {
  min-width: 107px;
}

</style>


<template lang="pug">

  .content.flex.vertical-center
    info-label.info.nullable(
      :label="$t('models.deal.attributes.startAt')",
      :value="formattedStartAt"
    )

    info-label.info.nullable(
      :label="$t('models.deal.attributes.duration')",
      :value="duration"
    )

    template(v-if="deal.fromAmendment")
      info-label.info(:label="this.$t('.amendmentFrom')")
        .flex.vertical-center
          span.message {{ $t('.deal') }}
          id-tag.id-tag(
            :value="deal.parentId",
            data-testid="parentId"
          )

    template(v-if="deal.isOngoing")
      info-label.info(
        :label="$t('.validUntil', { date: formattedExpirationDate })",
        :value="$t('.expiresIn', { count: expirationMonthsDifference })"
      )

    template(v-if="deal.isAmended")
      info-label.info(
        :label="$t('.amendedAt', { date: formattedStateChangeDate })",
        :value="$t('.amendedWith', { count: stateChangeMonthsDifference })"
      )

    template(v-else-if="deal.isCanceled")
      info-label.info(
        :label="$t('.canceledAt', { date: formattedStateChangeDate })",
        :value="$t('.canceledWith', { count: stateChangeMonthsDifference })"
      )

    template(v-if="deal.isDone")
      info-label.info(
        :label="$t('.expiredAt', { date: formattedExpirationDate })",
        :value="$t('.expiredSince', { count: -expirationMonthsDifference })"
      )

</template>


<script>

export default {
  props: {
    deal: { type: Object, default: () => ({}) }
  },

  data() {
    return {
      i18nScope: "suppliers.deals.components.deal-info-card"
    }
  },

  computed: {
    duration() {
      if (!_.get(this.deal, "duration")) return null
      return this.$t("models.deal.formattedAttributes.duration", { count: this.deal.duration })
    },

    startAt() {
      if (_.blank(this.deal.startAt)) return null
      return moment(this.deal.startAt, "YYYY-MM-DD")
    },

    formattedStartAt() {
      if (_.blank(this.deal.startAt)) return null
      return this.startAt.format("L")
    },

    formattedExpirationDate() {
      if (!this.deal.hasExpirationData) return null
      return this.deal.expirationDate.format("LL")
    },

    lastStateChangeEvent() {
      return _.get(this.deal, "lastStateChangeEvent")
    },

    formattedStateChangeDate() {
      if (_.blank(this.lastStateChangeEvent)) return null

      return moment(this.lastStateChangeEvent.createdAt).format("LL")
    },

    stateChangeMonthsDifference() {
      if (_.blank(this.lastStateChangeEvent)) return null

      return this.deal.expirationDate.diff(moment(this.lastStateChangeEvent.createdAt), "months")
    },

    expirationMonthsDifference() {
      if (!this.deal.hasExpirationData) return null

      return this.deal.expirationDate.diff(moment(), "months")
    }
  }
}

</script>

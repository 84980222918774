<style lang="scss" scoped>

.store-settings {
  .header {
    margin-top: 32px;
    margin-bottom: 24px;

    .title {
      font-family: $secondary-font;
      font-size: 30px;
      font-weight: 500;
    }

    .actions {
      .icon {
        font-size: 16px;
        margin-right: 8px;
      }
    }
  }
}

</style>


<template lang="pug">

  .store-settings
    section.header
      .flex.space-between
        h1.title {{ $t('.title') }}

        .actions
          app-button.btn(:to="{ name: 'editStoreSettings', params: { id: storeId } }", theme="neutral", size="small")
            i.fal.fa-pencil.icon
            span {{ $t('.btn.edit') }}

    section.content
      template(v-if="fetching")
        loading-lines(:lines="2", role="progressbar")

      template(v-else)
        check-item(
          :label="StoreSettings.$tAttr('autoGenerateInternalMaintenance')",
          :checked="!settings.autoGenerateInternalMaintenance",
          :tooltip="$t('.fields.autoGenerateInternalMaintenance.tooltip')"
        )
        check-item(
          :label="StoreSettings.$tAttr('autoGenerateExternalMaintenance')",
          :checked="!settings.autoGenerateExternalMaintenance",
          :tooltip="$t('.fields.autoGenerateExternalMaintenance.tooltip')"
        )

</template>


<script>

// View
import LoadedView from "@/views/loaded-view"

// Mixins
import FetchMixin from "@/mixins/fetch-mixin"

// Models
import models from "@/models"
const { StoreSettings } = models

export default {
  name: "StoreSettings",

  extends: LoadedView,

  mixins: [FetchMixin],

  data() {
    return {
      i18nScope: "stores.settings.show",
      routeName: "storeSettings",

      storeId: null,

      resource: {},

      StoreSettings
    }
  },

  computed: {
    settings: {
      get() { return this.resource },

      set(value) { this.resource = value }
    }
  },

  methods: {
    parseRoute() {
      this.storeId = this.$params.asInteger(this.$route.params.id)
    },

    // @override Fetch Mixin
    fetchRequest() {
      return this.$newSdk.stores.settings.find({ storeId: this.storeId })
    }
  }
}

</script>

import Base from "./Base"

class VehicleModelGroups extends Base {
  create({ params = {} } = {}) {
    return this.action({
      method: "post",
      url:    "/vehicle_model_groups",
      params
    })
  }

  list({ params = {} } = {}) {
    return this.action({
      // Estamos usando POST pois o filtro por ids pode ser muito grande e estourar o limite da URL.
      method: "post",
      url:    `/vehicle_model_groups/search`,
      params
    })
  }

  get({ id, params = {} }) {
    if (_.blank(id)) {
      throw new Error('SDK VehicleModelGroups - #get() - "id" is a required prop')
    }

    return this.action({
      method: "get",
      url:    `/vehicle_model_groups/${id}`,
      params
    })
  }

  update({ id, params = {} } = {}) {
    if (_.blank(id)) {
      throw new Error('SDK VehicleModelGroups - #update() - "id" is a required prop')
    }

    return this.action({
      method: "patch",
      url:    `/vehicle_model_groups/${id}`,
      params
    })
  }

  destroy({ id }) {
    if (_.blank(id)) {
      throw new Error('SDK VehicleModelGroups - #destroy() - "id" is a required prop')
    }

    return this.action({
      method: "delete",
      url:    `/vehicle_model_groups/${id}`
    })
  }

  listVehicleModels({ id, params = {} } = {}) {
    if (_.blank(id)) {
      throw new Error('SDK VehicleModelGroups - #listVehicleModels() - "id" is a required prop')
    }

    return this.action({
      method: "get",
      url:    `/vehicle_model_groups/${id}/vehicle_models`,
      params
    })
  }

  listMaintenanceTables({ id, params = {} } = {}) {
    if (_.blank(id)) {
      throw new Error('SDK VehicleModelGroups - #listMaintenanceTables() - "id" is a required prop')
    }

    return this.action({
      method: "get",
      url:    `/vehicle_model_groups/${id}/maintenance_tables`,
      params
    })
  }

  createMaintenanceTable({ id, params = {} } = {}) {
    if (_.blank(id)) {
      throw new Error('SDK VehicleModelGroups - #createMaintenanceTable() - "id" is a required prop')
    }

    return this.action({
      method: "post",
      url:    `/vehicle_model_groups/${id}/maintenance_tables`,
      params
    })
  }

  importMaintenanceTables({ id, params = {} } = {}) {
    if (_.blank(id)) {
      throw new Error('SDK VehicleModelGroups - #importMaintenanceTables() - "id" is a required prop')
    }

    return this.action({
      method: "post",
      url:    `/vehicle_model_groups/${id}/maintenance_tables/imports`,
      params
    })
  }

  updateMaintenanceTable({ id, maintenanceTableId, params = {} } = {}) {
    if (_.blank(id)) {
      throw new Error('SDK VehicleModelGroups - #updateMaintenanceTable() - "id" is a required prop')
    }

    if (_.blank(maintenanceTableId)) {
      throw new Error('SDK VehicleModelGroups - #updateMaintenanceTable() - "maintenanceTableId" is a required prop')
    }

    return this.action({
      method: "patch",
      url:    `/vehicle_model_groups/${id}/maintenance_tables/${maintenanceTableId}`,
      params
    })
  }

  getMaintenanceTable({ id, maintenanceTableId, params = {} } = {}) {
    if (_.blank(id)) {
      throw new Error('SDK VehicleModelGroups - #updateMaintenanceTable() - "id" is a required prop')
    }

    if (_.blank(maintenanceTableId)) {
      throw new Error('SDK VehicleModelGroups - #updateMaintenanceTable() - "maintenanceTableId" is a required prop')
    }

    return this.action({
      method: "get",
      url:    `/vehicle_model_groups/${id}/maintenance_tables/${maintenanceTableId}`,
      params
    })
  }

  destroyMaintenanceTable({ id, maintenanceTableId }) {
    if (_.blank(id)) {
      throw new Error('SDK VehicleModelGroups - #destroyMaintenanceTable() - "id" is a required prop')
    }

    if (_.blank(maintenanceTableId)) {
      throw new Error('SDK VehicleModelGroups - #destroyMaintenanceTable() - "maintenanceTableId" is a required prop')
    }

    return this.action({
      method: "delete",
      url:    `/vehicle_model_groups/${id}/maintenance_tables/${maintenanceTableId}`
    })
  }
}

export default VehicleModelGroups

<style lang="scss" scoped>

.error {
  display: block;
  text-align: center;
  padding: 4px 12px;
  font-size: 14px;
  font-style: italic;

  .link {
    margin-left: 5px;
    font-size: 14px;
    font-style: italic;
    font-weight: normal;
  }
}

.empty {
  display: block;
  text-align: center;
  padding: 4px 12px;
  font-size: 14px;
  font-style: italic;
}

.content-column {
  overflow: hidden;
  padding-right: 12px;
  font-size: 14px;
  font-weight: 400;

  .diamond-icon {
    font-size: 4px;
    font-weight: 900;
    color: $gray-3;
    width: 4px;
    height: 4px;
    margin: 4px;
  }
}

</style>


<template lang="pug">

  .vehicle-model-select-field
    select-field(
      :name="name",
      :label="label",
      :fetching="fetching",
      :disabled="disabled",
      :placeholder="placeholder",
      :optional="optional",

      option-as-value,
      :loading="loading || fetchingOption",
      :options="options",
      :default-filter="false",
      :errors="errors",
      searchable,

      :value="option",

      :menu-width="menuWidth",
      :menu-growth-side="menuGrowthSide",

      @input="onSelect",
      @search="onSearch",
      @open="_fetch",
      @close="onClose",
      @inputSearch="(newSearch) => search = newSearch"
    )
      template(#menuContentHeader)
        template(v-if="fetchError")
          label.error
            span {{ $t('.error.message') }}
            app-button.link(theme="link", @click="onSearch") {{ $t('.error.link') }}

      template(#menuContentFooter)
        template(v-if="!fetching && !fetchError && empty")
          label.empty {{ $t('.empty') }}

      template(#fetching)
        li.option {{ $t('.loading') }}

      template(#selected="{ props: { selected } }")
        template(v-if="optionNameOnly")
          .content-column.flex.grow.vertical-center
            app-span(:value="selectedOptionLabel(selected)", crop)

            template(v-if="vehicleYear")
              i.diamond-icon.fal.fa-diamond
              app-span(:value="vehicleYear")

      template(#option="{ props: { option, index, focus, active } }")
        vehicle-model-option(
          :vehicle-model="option.data",
          :active="active",
          :focus="focus"
        )

      template(#menuContentFooter)
        template(v-if="!fetching && !fetchError && empty")
          label.empty {{ $t('.empty') }}

</template>


<script>

// Mixins
import FieldMixin from "@/mixins/field"
import AsyncSelect from "@/mixins/select/async"

// Models
import models from "@/models"
const { VehicleModel } = models

export default {
  name: "VehicleModelSelectField",

  extends: AsyncSelect,

  mixins: [FieldMixin],

  props: {
    excludeIds:     { type: Array, default: () => [] },
    optionNameOnly: { type: Boolean, default: false },
    menuWidth:      { type: Number, default: null },
    menuGrowthSide: { type: String, default: "right", validator: side => ["left", "right"].includes(side) }
  },

  data() {
    return {
      i18nScope: "components.vehicle-model.vehicle-model-select-field",

      model: null,

      fetchingOption: false,
      fetchError:     false
    }
  },

  computed: {
    vehicleYear() {
      return this.selectedOption.data.year
    }
  },

  methods: {
    selectedOptionLabel(selectedOption) {
      const version = _.get(selectedOption, "data.mobiautoVersion")

      if (_.blank(version)) return

      return version.optionText
    },

    // @overrides extends AsyncSelect
    async fetchOption(id) {
      try {
        this.fetchingOption = true

        const response = await this.$sdk.vehicleModels.get({ id })
        const normalizedData = _.camelizeKeys(response.data)

        this.option = this.dataToOption(VehicleModel.build(normalizedData))
      }
      catch (err) {
        this.$err.log(err)
      }
      finally {
        this.fetchingOption = false
      }
    },

    // @overrides extends AsyncSelect
    fetchRequest() {
      return this.$sdk.vehicleModels.list({
        params: { q: this.search }
      })
    },

    // @overrides extends AsyncSelect
    onFetchSuccess(response, { normalizedData }) {
      const fetchedOptions = normalizedData
        .filter(data => !this.excludeIds.includes(data.id))
        .map(data => this.dataToOption(VehicleModel.build(data)))

      this.options =  [...fetchedOptions]
    },

    // @overrides extends AsyncSelect
    onFetchError(error) {
      this.$err.log(error)
    }
  }
}

</script>

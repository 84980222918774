<style lang="scss" scoped>

.filters-button {
  color: $dark-gray;
  display: block;
}

.icon {
  font-size: 14px;
  margin-right: 4px;
}

.filter-text {
  font-size: 14px;
  font-weight: 400;
}

</style>


<template lang="pug">

  app-button.filters-button(
    theme="link",
    :loading="loading",
    @click="$emit('clear')"
  )
    .flex.vertical-center
      i.icon.fal.fa-times
      span.filter-text {{ $t('.message') }}

</template>


<script>

export default {
  name: "FiltersButton",

  props: {
    loading: { type: Boolean, default: false }
  },

  data() {
    return {
      i18nScope: "components.filters-button"
    }
  }
}

</script>

<!--
<doc lang="markdown">
Componente com GoogleAutocomplete para endereço
Com `value` sendo `place_id`

_Props_:
value: `place_id`
initial-text: Texto inicial caso exista um `place_id` inicil
_porps_ de um `field`

Como usar:
```pug
  address-place-field(
    name="resource[address_place_id]"
    v-model="resource.placeId",
    :initial-text="resource.addressLine",
    hide-label,
  )
```
</doc>
-->


<style lang="scss" scoped>

@import "@/assets/styles/field";

</style>


<template lang="pug">

  .input-field
    label.label-content(v-if="!hideLabel", :for="inputId", :class="{ error: hasErrors, focus }")
      span {{ labelText }}
      span(v-if="optional") {{ $t('form.optional') }}
      i.info-icon.far.fa-info-circle(v-if="info", @click="$emit('info-click')")

    .input-wrapper
      google-autocomplete.google-autocomplete(
        :id="inputId",
        :disabled="disabled || loading",
        :input-error="hasErrors",
        :readonly="readonly",
        :name="name",
        :placeholder="placeholder",
        ref="input",
        :fields="['place_id', 'formatted_address']",
        :class="{ loading, disabled, error: hasErrors }",
        :value="googlePlace",
        @input="onChange",
        @focus="focus = true",
        @blur="focus = false"
      )

    span.error-message(v-for="error in errors", :key="error") {{ error }}

</template>


<script>

import FieldMixin from "@/mixins/field"

export default {
  name: "AddressPlaceField",

  mixins: [FieldMixin],

  props: {
    initialText: { type: String, default: null }
  },

  data() {
    return {
      googlePlace: null,
      focus:       false
    }
  },

  computed: {
    showAutocomplete() {
      return this.autocomplete ? "" : "off"
    },

    serializedValue() {
      if (_.blank(this.value)) return null

      return {
        place_id: this.value
      }
    }
  },

  watch: {
    value() {
      if (this.value === _.get(this.googlePlace, "place_id")) return

      if (_.blank(this.value)) {
        this.googlePlace = null
        return
      }

      this.googlePlace = {
        place_id: this.value
      }
    }
  },

  created() {
    if (this.value && this.initialText) {
      this.googlePlace = { place_id: this.value, formatted_address: this.initialText }
    }
  },

  methods: {
    onChange(place) {
      this.googlePlace = place
      this.$emit("input", _.get(place, "place_id") || null)
    }
  }
}

</script>

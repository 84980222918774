<style scoped lang="scss">

.vehicle-issues {
  .header {
    margin-bottom: 16px;

    .title-icon {
      margin-right: 14px;
    }

    .title-text {
      font-size: 20px;
      font-weight: 500;
      font-family: $secondary-font;
    }
  }

  .filters {
    margin: 12px 0;

    .results-indicator {
      margin-left: auto;
    }
  }

  .paginator-content {
    padding-top: 24px;

    &.loading {
      max-width: 400px;
      margin: 0 auto;
    }
  }

  .content-wrapper {
    min-height: 64px;

    .list {
      border: 1px solid $light-gray-3;
      border-radius: 8px;
      max-height: 560px;
      overflow-y: scroll;
    }

    .empty {
      .empty-icon {
        width: 64px;
        height: 64px;
      }

      .empty-message {
        margin-top: 8px;
        font-style: italic;
      }
    }
  }
}

</style>


<template lang="pug">

  .vehicle-issues
    header.header
      .title.flex.vertical-center
        i.title-icon.far.fa-lightbulb
        span.title-text {{ $t('.title') }}

    template(v-if="hasIssues")
      search-form.search(
        :loading="fetching",
        :params="parsedQueryParams",
        @search="search",
        data-testid="searchForm"
      )


      .filters.flex.space-between.vertical-bottom
        filters-button.filters-button(
          v-if="hasFilters",
          @clear="clearFilters",
          data-testid="clearFilters"
        )
        results-indicator.results-indicator(
          :page="parsedQueryParams.page",
          :per-page="pagination.perPage",
          :total="pagination.total",
          :loading="fetching",
          data-testid="results"
        )

    .content-wrapper
      template(v-if="fetching")
        loading-lines(:lines="4" :min="100", :max="100")

      template(v-else-if="erred")
        span {{ $t('.fetch-error.message') }}
        app-button.retry(theme="link", @click="fetch") {{ $t('.fetch-error.retry.label') }}

      template(v-else-if="empty")
        .empty.flex.column-direction.vertical-center
          span.empty-message {{ emptyMessage }}

      template(v-else)
        .list
          vehicle-issue-row(
            v-for="vehicleIssue in vehicleIssues",
            :key="`vehicle-issue-${vehicleIssue.id}`",
            :vehicle-issue="vehicleIssue",
            data-testid="vehicleIssueRow"
          )

        .paginator-content(v-if="fetching || showPaginator", :class="{ loading: fetching }")
          paginator(
            :loading="fetching",
            :per-page="pagination.perPage",
            :total="pagination.total",
            :value="parsedQueryParams.page",
            :disabled="fetching",
            @input="onPageChange",
            data-testid="paginator"
          )

</template>


<script>

// View
import IndexView from "@/views/index-view"

// Mixins
import FetchMixin from "@/mixins/fetch-mixin"

// Components
import SearchForm from "@/views/services/components/vehicle-issues/search-form.vue"
import VehicleIssueRow from "@/views/services/vehicle-issues/components/vehicle-issue-row"

export default {
  name: "VehicleIssues",

  components: {
    SearchForm,
    VehicleIssueRow
  },

  extends: IndexView,

  mixins: [FetchMixin],

  props: {
    service: { type: Object, required: true }
  },

  data() {
    return {
      i18nScope: "services.vehicle-issues.index",
      routeName: "serviceVehicleIssues",

      resource: [],

      // @requirement IndexView
      queryParamsStructure: {
        q:    "string",
        page: "integer",
        sort: "string"
      }
    }
  },

  computed: {
    vehicleIssues: {
      get() { return this.resource },

      set(value) { this.resource = value }
    },

    empty() {
      return _.blank(this.vehicleIssues)
    },

    serviceId() {
      return _.get(this.service, "id")
    },

    hasIssues() {
      const solutionsCount = _.get(this.service, "vehicleIssueSolutionsCount")

      return solutionsCount > 0
    },

    searched() {
      return _.present(this.parsedQueryParams.q)
    },

    emptyMessage() {
      if (this.searched && this.hasIssues) return this.$t("index.empty.message")

      return this.$t(".empty.message")
    }
  },

  methods: {
    parseRoute() {},

    // @override Fetch Mixin
    fetchRequest() {
      return this.$newSdk.services.vehicleIssues.all({ serviceId: this.serviceId, params: this.requestQueryParams })
    }
  }
}

</script>

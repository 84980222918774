<!--
<doc lang="markdown">
Componente de abas com rotas.
Verifica se a rota é ativa através do `$route.name`, `$route.hash` além de poder passar
por _props_ `alias` e `active`

**
  NOTA: Para o comportamento adequado estamos utilizando o estilo global
  para barra de abas em `assets/styles/_router-tab.scss`
**

_Props_:
`to`: Rota a ser redirecionado
`label`: Label a ser exibida (pode ser usado `slot` no lugar)
`alias`: Algum nome alternativo para manter a rota ativa
`active`: Controlador para garantir situação de aba ativa

Como usar:
```pug
  router-tab(
    :to="{ name: 'supplier', params: { id }}",
    alias="rota2"
  ) Minha label
```

Usando `active`

```pug
  router-tab(
    :to="{ name: 'supplier', params: { id }}",
    active
  ) Sempre ativa
```

</doc>
-->


<style scoped lang="scss">

.router-tab {
  $border-width: 4px;

  position: relative;
  font-family: $secondary-font;
  font-weight: 500;
  font-size: 16px;
  color: $gray-4;
  border: $border-width solid transparent;
  padding: 16px 0;
  cursor: pointer;
  user-select: none;

  &::after {
    content: "";
    position: absolute;
    left: -$border-width;
    bottom: -$border-width;
    width: calc(100% + #{$border-width * 2});
    display: block;
    height: $border-width;
    border-radius: 4px 4px 0 0;
  }

  &.error {
    color: $error;

    &.router-link-active,
    &.router-link-exact-active {
      color: $error;

      &::after {
        background-color: $error;
      }
    }
  }

  .error-badge {
    position: absolute;
    top: 12px;
    height: 5px;
    width: 5px;
    border-radius: 50px;
    background: $error;
  }

  &:hover {
    color: $primary;
  }

  &.router-link-active,
  &.router-link-exact-active {
    color: $primary;

    &::after {
      background-color: $primary;
    }
  }

  &.disabled {
    color: $light-gray-4;
    cursor: not-allowed;

    &.router-link-active,
    &.router-link-exact-active {
      &::after {
        background-color: $light-gray-4;
      }
    }
  }
}

.router-tab + .router-tab {
  margin-left: 32px;
}

</style>


<template lang="pug">

  router-link.router-tab(
    :to="to",
    :class="{ 'router-link-exact-active': activeClass, error, disabled }",
    :event="disabled ? '' : 'click'",
    exact
  )
    slot
      | {{ label }}
    span.error-badge(v-if="error")

</template>


<script>

export default {
  name: "RouterTab",

  props: {
    to:       { type: Object, default: () => ({}) },
    label:    { type: String, default: null },
    alias:    { type: String, default: null },
    active:   { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    error:    { type: Boolean, default: false }
  },

  data() {
    return {
      i18nScope: "components.router-tab"
    }
  },

  computed: {
    aliasRoute() {
      const currentRouteName = this.$route.name

      return currentRouteName === this.alias || currentRouteName === this.to.name
    },

    isSameRoute() {
      let isActiveHash = this.to.hash === this.$route.hash

      if (!this.to.name) return isActiveHash
      return this.to.name === this.$route.name && isActiveHash
    },

    activeClass() {
      return this.aliasRoute || this.isSameRoute || this.active
    }
  }
}

</script>

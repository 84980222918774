import Base from "./Base"

class Suppliers extends Base {
  list({ params = {} } = {}) {
    return this.action({
      method: "get",
      url:    "/suppliers",
      params
    })
  }

  get({ id, params = {} } = {}) {
    if (_.blank(id)) {
      throw new Error('SDK Suppliers - #get() - "id" is a required prop')
    }

    return this.action({
      method: "get",
      url:    `/suppliers/${id}`,
      params
    })
  }

  new({ params = {} } = {}) {
    if (_.blank(params.cnpj)) {
      throw new Error(
        'SDK Suppliers - #new() - "params.cnpj" is a required prop'
      )
    }

    return this.action({
      method: "get",
      url:    `/suppliers/new`,
      params
    })
  }

  destroy({ id }) {
    if (_.blank(id)) {
      throw new Error('SDK Suppliers - #destroy() - "id" is a required prop')
    }

    return this.action({
      method: "delete",
      url:    `/suppliers/${id}`
    })
  }

  listBlocks({ id, params = {} } = {}) {
    if (_.blank(id)) {
      throw new Error(
        'SDK Suppliers - #listBlocks() - "id" is a required prop'
      )
    }

    return this.action({
      method: "get",
      url:    `/suppliers/${id}/blocks`,
      params
    })
  }

  getBlock({ id, blockId, params = {} } = {}) {
    if (_.blank(id)) {
      throw new Error('SDK Suppliers - #getBlock() - "id" is a required prop')
    }

    if (_.blank(blockId)) {
      throw new Error(
        'SDK Suppliers - #getBlock() - "blockId" is a required prop'
      )
    }

    return this.action({
      method: "get",
      url:    `/suppliers/${id}/blocks/${blockId}`,
      params
    })
  }

  block({ id, params = {} } = {}) {
    if (_.blank(id)) {
      throw new Error('SDK Suppliers - #block() - "id" is a required prop')
    }

    return this.action({
      method: "post",
      url:    `/suppliers/${id}/blocks`,
      params
    })
  }

  updateBlock({ id, blockId, params = {} } = {}) {
    if (_.blank(id)) {
      throw new Error(
        'SDK Suppliers - #updateBlock() - "id" is a required prop'
      )
    }

    if (_.blank(blockId)) {
      throw new Error(
        'SDK Suppliers - #updateBlock() - "blockId" is a required prop'
      )
    }

    return this.action({
      method: "patch",
      url:    `/suppliers/${id}/blocks/${blockId}`,
      params
    })
  }

  destroyBlock({ id, blockId } = {}) {
    if (_.blank(id)) {
      throw new Error(
        'SDK Suppliers - #destroyBlock() - "id" is a required prop'
      )
    }

    if (_.blank(blockId)) {
      throw new Error(
        'SDK Suppliers - #destroyBlock() - "blockId" is a required prop'
      )
    }

    return this.action({
      method: "delete",
      url:    `/suppliers/${id}/blocks/${blockId}`
    })
  }

  unblock({ id, params = {} } = {}) {
    if (_.blank(id)) {
      throw new Error('SDK Suppliers - #unblock() - "id" is a required prop')
    }

    return this.action({
      method: "patch",
      url:    `/suppliers/${id}/unblock`,
      params
    })
  }

  listSupportFlags({ id, params = {} } = {}) {
    if (_.blank(id)) {
      throw new Error(
        'SDK Suppliers - #listSupportFlags() - "id" is a required prop'
      )
    }

    return this.action({
      method: "get",
      url:    `/suppliers/${id}/support_flags`,
      params
    })
  }

  createDeal({ id, params = {} } = {}) {
    if (_.blank(id)) {
      throw new Error(
        'SDK Suppliers - #createDeal() - "id" is a required prop'
      )
    }

    return this.action({
      method: "post",
      url:    `/suppliers/${id}/deals`,
      params
    })
  }

  listDeals({ id, params = {} } = {}) {
    if (_.blank(id)) {
      throw new Error('SDK Suppliers - #listDeals() - "id" is a required prop')
    }

    return this.action({
      method: "get",
      url:    `/suppliers/${id}/deals`,
      params
    })
  }

  getDeal({ id, dealId, params = {} } = {}) {
    if (_.blank(id)) {
      throw new Error('SDK Suppliers - #getDeal() - "id" is a required prop')
    }

    if (_.blank(dealId)) {
      throw new Error(
        'SDK Suppliers - #getDeal() - "dealId" is a required prop'
      )
    }

    return this.action({
      method: "get",
      url:    `/suppliers/${id}/deals/${dealId}`,
      params
    })
  }

  updateDeal({ id, dealId, params = {} } = {}) {
    if (_.blank(id)) {
      throw new Error(
        'SDK Suppliers - #updateDeal() - "id" is a required prop'
      )
    }

    if (_.blank(dealId)) {
      throw new Error(
        'SDK Suppliers - #updateDeal() - "dealId" is a required prop'
      )
    }

    return this.action({
      method: "patch",
      url:    `/suppliers/${id}/deals/${dealId}`,
      params
    })
  }

  destroyDeal({ id, dealId } = {}) {
    if (_.blank(id)) {
      throw new Error(
        'SDK Suppliers - #destroyDeal() - "id" is a required prop'
      )
    }

    if (_.blank(dealId)) {
      throw new Error(
        'SDK Suppliers - #destroyDeal() - "dealId" is a required prop'
      )
    }

    return this.action({
      method: "delete",
      url:    `/suppliers/${id}/deals/${dealId}`
    })
  }

  listDealMaintenancePackages({ id, dealId, params = {} } = {}) {
    if (_.blank(id)) {
      throw new Error(
        'SDK Suppliers - #listDealMaintenancePackages() - "id" is a required prop'
      )
    }

    if (_.blank(dealId)) {
      throw new Error(
        'SDK Suppliers - #listDealMaintenancePackages() - "dealId" is a required prop'
      )
    }

    return this.action({
      method: "get",
      url:    `/suppliers/${id}/deals/${dealId}/maintenance_packages`,
      params
    })
  }

  updateDealMaintenancePackages({ id, dealId, params = {} } = {}) {
    if (_.blank(id)) {
      throw new Error(
        'SDK Suppliers - #updateDealMaintenancePackages() - "id" is a required prop'
      )
    }

    if (_.blank(dealId)) {
      throw new Error(
        'SDK Suppliers - #updateDealMaintenancePackages() - "dealId" is a required prop'
      )
    }

    return this.action({
      method: "patch",
      url:    `/suppliers/${id}/deals/${dealId}/maintenance_packages`,
      params
    })
  }

  listDealProducts({ id, dealId, params = {} } = {}) {
    if (_.blank(id)) {
      throw new Error(
        'SDK Suppliers - #listDealProducts() - "id" is a required prop'
      )
    }

    if (_.blank(dealId)) {
      throw new Error(
        'SDK Suppliers - #listDealProducts() - "dealId" is a required prop'
      )
    }

    return this.action({
      method: "get",
      url:    `/suppliers/${id}/deals/${dealId}/products`,
      params
    })
  }

  updateDealProducts({ id, dealId, params = {} } = {}) {
    if (_.blank(id)) {
      throw new Error(
        'SDK Suppliers - #updateDealProducts() - "id" is a required prop'
      )
    }

    if (_.blank(dealId)) {
      throw new Error(
        'SDK Suppliers - #updateDealProducts() - "dealId" is a required prop'
      )
    }

    return this.action({
      method: "patch",
      url:    `/suppliers/${id}/deals/${dealId}/products`,
      params
    })
  }

  publishDeal({ id, dealId, params = {} } = {}) {
    if (_.blank(id)) {
      throw new Error(
        'SDK Suppliers - #publishDeal() - "id" is a required prop'
      )
    }

    if (_.blank(dealId)) {
      throw new Error(
        'SDK Suppliers - #publishDeal() - "dealId" is a required prop'
      )
    }

    return this.action({
      method: "post",
      url:    `/suppliers/${id}/deals/${dealId}/publication`,
      params
    })
  }

  publishAmendmentDeal({ id, dealId, params = {} } = {}) {
    if (_.blank(id)) {
      throw new Error(
        'SDK Suppliers - #publishDeal() - "id" is a required prop'
      )
    }

    if (_.blank(dealId)) {
      throw new Error(
        'SDK Suppliers - #publishDeal() - "dealId" is a required prop'
      )
    }

    return this.action({
      method: "post",
      url:    `/suppliers/${id}/deal_amendments/${dealId}/publication`,
      params
    })
  }

  amendDeal({ dealId, params = {} } = {}) {
    if (_.blank(dealId)) {
      throw new Error(
        'SDK Suppliers - #amendDeal() - "dealId" is a required prop'
      )
    }

    return this.action({
      method: "post",
      url:    `/deals/${dealId}/amendments`,
      params
    })
  }

  listServiceSupports({ id, params = {} } = {}) {
    if (_.blank(id)) {
      throw new Error(
        'SDK Suppliers - #listServiceSupports() - "id" is a required prop'
      )
    }

    return this.action({
      method: "get",
      url:    `/suppliers/${id}/service_supports`,
      params
    })
  }

  createServiceSupport({ id, params = {} } = {}) {
    if (_.blank(id)) {
      throw new Error(
        'SDK Suppliers - #createServiceSupport() - "id" is a required prop'
      )
    }

    return this.action({
      method: "post",
      url:    `/suppliers/${id}/service_supports`,
      params
    })
  }

  getServiceSupport({ id, serviceTypeSlug, params = {} } = {}) {
    if (_.blank(id)) {
      throw new Error(
        'SDK Suppliers - #getServiceSupport() - "id" is a required prop'
      )
    }

    if (_.blank(serviceTypeSlug)) {
      throw new Error(
        'SDK Suppliers - #getServiceSupport() - "serviceTypeSlug" is a required prop'
      )
    }

    return this.action({
      method: "get",
      url:    `/suppliers/${id}/service_supports/${serviceTypeSlug}`,
      params
    })
  }

  updateServiceSupport({ id, serviceTypeSlug, params = {} } = {}) {
    if (_.blank(id)) {
      throw new Error(
        'SDK Suppliers - #updateServiceSupport() - "id" is a required prop'
      )
    }

    if (_.blank(serviceTypeSlug)) {
      throw new Error(
        'SDK Suppliers - #updateServiceSupport() - "serviceTypeSlug" is a required prop'
      )
    }

    return this.action({
      method: "patch",
      url:    `/suppliers/${id}/service_supports/${serviceTypeSlug}`,
      params
    })
  }

  destroyServiceSupport({ id, serviceTypeSlug, params = {} } = {}) {
    if (_.blank(id)) {
      throw new Error(
        'SDK Suppliers - #destroyServiceSupport() - "id" is a required prop'
      )
    }

    if (_.blank(serviceTypeSlug)) {
      throw new Error(
        'SDK Suppliers - #destroyServiceSupport() - "serviceTypeSlug" is a required prop'
      )
    }

    return this.action({
      method: "delete",
      url:    `/suppliers/${id}/service_supports/${serviceTypeSlug}`,
      params
    })
  }

  destroyMaintenanceVehicleModelGroup({ id, supplierMaintenanceVehicleModelGroupId, params = {} } = {}) {
    if (_.blank(id)) {
      throw new Error(
        'SDK Suppliers - #destroyMaintenanceVehicleModelGroup() - "id" is a required prop'
      )
    }

    if (_.blank(supplierMaintenanceVehicleModelGroupId)) {
      throw new Error(
        'SDK Suppliers - #destroyMaintenanceVehicleModelGroup() - "supplierMaintenanceVehicleModelGroupId" is a required prop'
      )
    }

    return this.action({
      method: "delete",
      url:    `/suppliers/${id}/maintenance_vehicle_model_groups/${supplierMaintenanceVehicleModelGroupId}`,
      params
    })
  }

  batchCreateMaintenanceVehicleModelGroups({ id, params = {} } = {}) {
    if (_.blank(id)) {
      throw new Error(
        'SDK Suppliers - #batchCreateMaintenanceVehicleModelGroups() - "id" is a required prop'
      )
    }

    return this.action({
      method: "post",
      url:    `/suppliers/${id}/maintenance_vehicle_model_groups/batches`,
      params
    })
  }

  getServices({ id, params = {} } = {}) {
    if (_.blank(id)) {
      throw new Error(
        'SDK Suppliers - #getServices() - "id" is a required prop'
      )
    }

    return this.action({
      method: "get",
      url:    `/suppliers/${id}/services`,
      params
    })
  }

  getMaintenanceVehicleModelGroup({ id, maintenanceVehicleModelGroupId, params = {} } = {}) {
    if (_.blank(id)) {
      throw new Error(
        'SDK Suppliers - #getMaintenanceVehicleModelGroups() - "id" is a required prop'
      )
    }

    return this.action({
      method: "get",
      url:    `/suppliers/${id}/maintenance_vehicle_model_groups/${maintenanceVehicleModelGroupId}`,
      params
    })
  }
}

export default Suppliers

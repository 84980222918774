<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.2188 16.125L16.4375 13.75C16.0938 12.7188 15.125 12 14.0625 12H8.90625C7.84375 12 6.875 12.7188 6.53125 13.75L5.75 16.125C4.75 16.4375 4 17.375 4 18.5V22.5C4 23.3438 4.65625 24 5.5 24H6C6.8125 24 7.5 23.3438 7.5 22.5V22H15.5V22.5C15.5 23.3438 16.1562 24 17 24H17.5C18.3125 24 19 23.3438 19 22.5V18.5C19 17.375 18.2188 16.4375 17.2188 16.125ZM7.5 14.0625C7.6875 13.4375 8.25 13 8.90625 13H14.0625C14.7188 13 15.2812 13.4375 15.4688 14.0625L16.125 16H6.84375L7.5 14.0625ZM6.5 22.5C6.5 22.7812 6.25 23 6 23H5.5C5.21875 23 5 22.7812 5 22.5V22H6.5V22.5ZM18 22.5C18 22.7812 17.75 23 17.5 23H17C16.7188 23 16.5 22.7812 16.5 22.5V22H18V22.5ZM18 20V21H5V18.5C5 17.6875 5.65625 17 6.5 17H16.5C17.3125 17 18 17.6875 18 18.5V20ZM7.25 17.75C6.4375 17.75 5.875 18.2812 5.875 19C5.875 19.75 6.4375 20.25 7.25 20.25H7.5625C8.8125 20.25 9 19.625 9 19.25C9 18.5 8 17.75 7.25 17.75ZM7.5625 19.5C7.46875 19.5 7.34375 19.5 7.25 19.5C6.875 19.5 6.625 19.3125 6.625 19C6.625 18.7188 6.875 18.5 7.25 18.5C7.65625 18.5 8.25 18.9688 8.25 19.25C8.25 19.5 7.90625 19.5 7.5625 19.5ZM15.7188 17.75C14.9688 17.75 14 18.5 14 19.25C14 19.625 14.1562 20.2812 15.4062 20.2812L15.7188 20.25C16.5312 20.25 17.125 19.75 17.125 19C17.125 18.2812 16.5312 17.75 15.7188 17.75ZM15.7188 19.5C15.625 19.5 15.5 19.5 15.4062 19.5C15.0625 19.5 14.75 19.5 14.75 19.25C14.75 18.9688 15.3125 18.5 15.7188 18.5C16.0938 18.5 16.375 18.7188 16.375 19C16.375 19.3125 16.0938 19.5 15.7188 19.5Z"
      :fill="colorHash"
    />
    <path
      d="M8 2H15C15 3.10457 14.1046 4 13 4H10C8.89543 4 8 3.10457 8 2Z"
      :fill="colorHash"
    />
    <path
      d="M16 1H7C5.89543 1 5 1.89543 5 3V13C5 13.5523 4.55228 14 4 14V3C4 1.34315 5.34315 0 7 0H16C17.6569 0 19 1.34315 19 3V14C18.4477 14 18 13.5523 18 13V3C18 1.89543 17.1046 1 16 1Z"
      :fill="colorHash"
    />
  </svg>
</template>


<script>

import SupplierIconBase from "./supplier-icon-base"

export default {
  name: "RacAppIcon",

  extends: SupplierIconBase
}

</script>

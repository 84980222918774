import _ from "@caiena/lodash-ext"
import Http from "./Http"
import requests from "./requests"

class SdkClient {
  constructor(options) {
    this.http = new Http(options)

    this.requests = Object.keys(requests).reduce((allRequests, className) => {
      allRequests[_.camelCase(className)] = new requests[className](this.http)
      return allRequests
    }, {})
  }

  setLocale(locale) {
    this.http.setData({ locale })
  }

  setAuthorizationToken(token) {
    this.http.setData({ authorizationToken: token })
  }

  addInterceptorRequest(func, errorFunc) {
    this.http.router.interceptors.request.use(func, errorFunc)
  }

  addInterceptorResponse(func, errorFunc) {
    this.http.router.interceptors.response.use(func, errorFunc)
  }
}

export default SdkClient

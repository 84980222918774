<style lang="scss" scoped>

.no-resource-registered {
  padding: 24px;
  border: 1px solid $light-gray-3;
  border-radius: 4px;
  color: $gray-3;
  font-size: 14px;
}

.no-resource-registered-icon {
  user-select: none;
  margin-bottom: 8px;
  height: 78px;
  width: 89px;
}

</style>


<template lang="pug">

  .maintenance-list-field
    template(v-if="!fetching && empty")
      .no-resource-registered.flex.center.column-direction.vertical-center
        img.no-resource-registered-icon(:src="maintenancePlaceholderIcon")
        span {{ $t('.empty.message') }}

    app-list(
      v-else,
      :loading="fetching",
      :error-text="$t('.error.message')",
      :error="hasFetchError"
    )
      maintenance-table-row-checkbox-field(
        v-for="maintenanceTable in resource",
        :key="maintenanceTable.id",
        :value="maintenanceTableValue(maintenanceTable.id)",
        @input="selectMaintenanceTable"

        :maintenance-table="maintenanceTable",

        data-testid="maintenanceTableRow"
      )

</template>


<script>

// Mixins
import { mixins } from "movida-common.vue"

// Components
import MaintenanceTableRowCheckboxField from "./maintenance-table-row-checkbox-field"

// Assets
import maintenancePlaceholderIcon from "@/assets/images/placeholder-icons/maintenance.svg"

const { Fetchable } = mixins

export default {

  components: {
    MaintenanceTableRowCheckboxField
  },

  mixins: [Fetchable],

  props: {
    vehicleModelGroupId: { type: [Number, String], required: true },
    value:               { type: Array, default: () => [] },
    error:               { type: String, default: null }
  },

  data() {
    return {
      i18nScope: "vehicle-model-groups.maintenance-tables.import.maintenance-tables-list-field",

      autofetch: false,

      resource: [],

      model: "VehicleModelGroupMaintenanceTable",

      maintenancePlaceholderIcon
    }
  },

  computed: {
    hasSelectedOptions() {
      return _.present(this.value)
    },

    empty() {
      return _.blank(this.resource)
    }
  },

  watch: {
    vehicleModelGroupId() {
      if (_.blank(this.vehicleModelGroupId)) return
      this._fetch()
    }
  },


  created() {
    if (_.blank(this.vehicleModelGroupId)) return
    this._fetch()

  },

  methods: {
    selectMaintenanceTable({ id, value: checkboxValue }) {
      let array = [...this.value]

      const hasId = array.findIndex(itemId => itemId === id)

      if (checkboxValue && hasId < 0) {
        array.push(id)
      }
      else if (!checkboxValue && hasId >= 0) {
        array.splice(hasId, 1)
      }

      this.$emit("input", array)
    },

    maintenanceTableValue(id) {
      return this.value.includes(id)
    },

    fetch() {
      return this.$sdk.vehicleModelGroups.listMaintenanceTables({
        id: this.vehicleModelGroupId
      })
    },

    // @override Fetchable mixin
    // onFetchSuccess(response) {},

    // @override Fetchable mixin
    onFetchError(err) {
      this.$err.log(err)
      this.$notifications.error(this.$t(".notifications.fetch.failure"))
    }
  }
}

</script>

<style scoped lang="scss">

.ui-row + .ui-row {
  margin-top: 24px;
}

.label {
  display: block;
  font-family: $secondary-font;
  font-weight: 500;
  font-size: 14px;
  padding-bottom: 4px;;
}

.fields {
  border-bottom: 1px solid $light-gray-3;
  padding-bottom: 24px;
}

.product-preview  {
  border-bottom: 1px solid $light-gray-3;
  padding: 24px 0;
}

.product {
  margin: 0;
  font-size: 24px;
  font-weight: normal;
}

</style>


<template lang="pug">

  .part-fields
    section.fields
      ui-row.ui-row
        ui-col(:span="6")
          input-field(
            name="part[name]",
            :loading="loading",
            v-model="resource.name",
            :errors="errors.name",
            :placeholder="$t('.fields.name.placeholder')",
            data-test="nameInput"
          )

        ui-col(:span="3")
          brand-select-field(
            name="part[brand]",
            :loading="loading",
            v-model="resource.brandId",
            :placeholder="$t('.fields.brand.placeholder')",
            :errors="errors.brand",
            optional
          )

</template>


<script>

// Local Components
import BrandSelectField from "../components/brand-select-field.vue"

export default {
  components: {
    BrandSelectField
  },

  props: {
    loading:  { type: Boolean, default: false },
    errors:   { type: Object, default: () => ({}) },
    resource: { type: Object, default: () => ({}) }
  },

  data() {
    return {
      i18nScope: "products.forms.part-fields"
    }
  }
}

</script>

// Base
import BaseRequests from "./Base"

// Models
import models from "@/models"
const { ManageResource } = models

class ManageResourcesRequests extends BaseRequests {
  static get selector() {
    return "ManageResources"
  }

  modelGroupSync({ params = {}, options = {} } = {}) {
    return this.action({
      method:  "post",
      url:     "/manage_resource/model_group/sync",
      params,
      options: {
        model: ManageResource,
        ...options
      }
    })
  }
}

export default ManageResourcesRequests

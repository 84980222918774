<style lang="scss" scoped>

.service-order-popover-content {
  // width: 409px;

  > .header {
    padding: 16px;
    border-bottom: 1px solid $light-gray-3;

    .title {
      color: $dark-gray;
      font-family: $secondary-font;
      font-size: 18px;
      font-weight: 500;
    }

    .id-tag {
      color: $gray-3;
      margin-left: 8px;

      ::v-deep .content {
        font-size: 16px !important;
      }
    }

    .complementary-state {
      margin-left: auto;
    }
  }

  > .body {
    .separator {
      padding: 16px;

      > .icon {
        font-size: 14px;
        color: $gray-2;
      }
    }

    .section {
      padding: 16px;

      & + .section {
        border-top: 1px solid $light-gray-3;
      }
    }

    .tree {
      overflow-x: auto;
    }

    .list {
      > .header {
        padding-bottom: 8px;
      }

      > .body {
        > .item {
          padding: 4px 0;

          > .label {
            font-family: $secondary-font;
            font-size:   12px;
            font-weight: 400;
            line-height: 150%;

            color: $gray-3;
          }

          > .value {
            font-family: $secondary-font;
            font-size:   14px;
            font-weight: 400;
            line-height: 150%;

            color: $dark-gray;
          }
        }
      }
    } // .list
  } // .body

  .provider-icon,
  .gmf-icon {
    // width: 44px;

    > .badge {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      padding: 0px 4px;

      width: 51px;
      height: 24px;

      /* Brand/Light brand 2 */

      background: #FFCFA3;
      border-radius: 4px;

      /* Inside auto layout */

      flex: none;
      order: 0;
      flex-grow: 0;

      /* Secondary/Paragraph/Medium 16px */

      font-family: 'Rubik';
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 150%;
      /* identical to box height, or 24px */

      /* Monochrome/Dark Grey */
      color: #333333;
    }

  }

  .gmf-icon {
    > .badge {
      background: white;
      color: $orange;
    }
  }


}

</style>


<template lang="pug">

.service-order-popover-content
  .header.flex.vertical-center
    span.title {{ $t(".header.title.label") }}
    id-tag.id-tag {{ serviceOrder.remoteId }}
    complementary-state.complementary-state(:service-order="serviceOrder")
    //- vetor-code-tag.id-tag {{ serviceOrder.remoteId }}

  .body
    tree.tree.section(
      v-if="open"
      :service-order-rid="serviceOrder.rid"
      :value="serviceOrder.rid"
    )
    .section.flex.center
      //- TODO: tratar provider
      .list.provider.vetor
        .header
          //- vetor-icon.provider-icon
          span.provider-icon.vetor
            .badge
              span Vetor
        .body
          .item.state.flex.column-direction
            label.label {{ $t(".fields.state.label") }}
            span.value  {{ serviceOrder.rawStateLabel }}

          .item.service-kind.flex.column-direction
            label.label {{ $t(".fields.serviceKind.label") }}
            span.value  {{ serviceOrder.rawServiceKindLabel }}

      .separator.flex.vertical-center
        i.icon.fal.fa-arrow-right

      .list.gmf
        .header
          //- gmf-icon.gmf-icon
          span.gmf-icon
            .badge
              span GMF
        .body
          .item.state.flex.column-direction
            label.label {{ $t(".fields.state.label") }}
            span.value  {{ $models.MovidaServiceOrder.$tEnum("state", serviceOrder.state) }}

          .item.service-kind.flex.column-direction
            label.label {{ $t(".fields.serviceKind.label") }}
            span.value  {{ $models.MovidaServiceOrder.$tEnum("serviceKind", serviceOrder.serviceKind) }}

</template>


<script>
import ComplementaryState from "./complementary-state.vue"
import Tree from "../service-orders/_components/tree.vue"

// Models
import models from "@/models"
const { MovidaServiceOrder } = models


export default {
  components: {
    ComplementaryState,
    Tree
  },

  emits: [],

  props: {
    open:         { type: Boolean, default: true },
    serviceOrder: { type: MovidaServiceOrder, required: true }
  },

  data() {
    return {
      i18nScope: "monitoring.service-order-popover-content"
    }
  }

}

</script>

<style lang="scss" scoped>

.edit.vehicle-receipt {
  .header {
    padding: 16px 24px;
    border-bottom: 1px solid $light-gray-3;
  }
}

</style>


<template lang="pug">

  app-modal.edit.vehicle-receipt(
    footer,
    :confirm-label="$t('.btn.confirm.label')",
    :confirm-label-loading="$t('.btn.confirm.label')",
    :heading="$t('.heading')",
    :loading="fetching || submitting",
    :width="566",
    @close="$emit('close')",
    @confirm="submit"
  )
    .content
      .header
        service-order-info(
          :service-order="serviceOrder",
          :vehicle="serviceOrder.vehicle",
          :supplier="serviceOrder.supplier",
          :customer="serviceOrder.customer",
          :branch="serviceOrder.branch"
        )

      template(v-if="fetching")
        app-loading-lines(:lines="4", :min-width="60", :max-width="90", :height="30")

      template(v-else)
        form(ref="form", method="post", @submit.prevent="submit")
          form-fields(
            :resource="resource",
            :fetching="fetching",
            :submitting="submitting",
            :errors="errors"
          )

</template>


<script>

// Mixins
import FetchMixin from "@/mixins/fetch-mixin"
import FormMixin  from "@/mixins/form-mixin"

// Components
import FormFields       from "./_components/form-fields.vue"
import ServiceOrderInfo from "@/views/monitoring/_components/service-order-info.vue"

// Models
import models from "@/models"
// eslint-disable-next-line no-unused-vars
const { MovidaServiceOrder, VehicleReceipt } = models


export default {
  components: {
    FormFields,
    ServiceOrderInfo
  },

  emits: [],

  mixins: [FetchMixin, FormMixin],

  props: {
    serviceOrder: { type: MovidaServiceOrder, required: true }
  },

  data() {
    return {
      i18nScope: "monitoring.vehicle-receipts.edit",

      resource: null
    }
  },

  methods: {
    // @override Fetch mixin
    fetchRequest() {
      return this.$newSdk.monitoring.serviceOrders.vehicleReceipt
        .find({ serviceOrderRid: this.serviceOrder.rid })
    },

    // @override Form mixin
    serializeAttributes() {
      return this.resource.$serialize()
    },

    // @override Form mixin
    submitRequest() {
      return this.$newSdk.monitoring.serviceOrders.vehicleReceipt
        .update({
          serviceOrderRid: this.serviceOrder.rid,
          params:          this.serializeAttributes()
        })
    },

    // @override Form mixin
    onSubmitSuccess(response) {
      // XXX: usando id do Vetor (remoteId) para ficar coerente com a listagem
      let successMessage = this.$t(".notifications.submit.success", { id: this.serviceOrder.remoteId })
      this.$notifications.info(successMessage)
    },

    // @override Form mixin
    afterSubmitSuccess() {
      this.$emit("update")
      this.$emit("close")
    }
  }
}

</script>

import Base from "./base"
import TicketType from "./ticket-type"

class TicketTypeService extends Base {
  static get attrs() {
    return _.uniq([
      ...super.attrs,
      "serviceId",
      "ticketType",

      // Empresas atendidas
      "rac",
      "racMensal",
      "racApp",
      "gtf",
      "seminovos",
      "premium",
      "zeroKm"
    ])
  }

  static get enums() {
    return {
      ticketType: TicketType.$enums.type
    }
  }

  static get relations() {
    return {
      service: { type: "belongsTo", model: "Service" }
    }
  }
}

export default TicketTypeService

<style scoped lang="scss">

.sector-empty {
  text-align: center;
  font-size: 14px;
  padding: 8px 0;
}

.sectors-row {
  border: 1px solid $light-gray-3;
  border-bottom: none;
  padding: 8px 24px;
  font-size: 16px;
  font-weight: normal;

  &:first-child {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }

  &:last-child {
    border-bottom: 1px solid $light-gray-3;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
  }
}

.content {
  width: 100%;
  overflow: hidden;
}

.name {
  display: block;
}

.description {
  display: block;
  font-size: 12px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 300;
}

.show {
  .sectors-row {
    border: none;
    padding: 0;
    margin-bottom: 16px;
  }

  .name {
    font-weight: 300;
  }
}

.actions-column {
  flex-shrink: 0;
}

</style>


<template lang="pug">

  .sectors-list(:class="{ [theme]: true }")
    loading-lines(v-if="loading", :lines="4")
    template(v-else-if="!hasSectors")
      slot(name="empty")
        .sector-empty.global-empty-text.global-empty-color.text.center
          label {{ emptyText }}
    template(v-else)
      .sectors-row.flex.space-between.vertical-center(
        v-for="sector in sectors",
        :key="`sector-${sector.id}`"
      )
        .content
          label.name(:data-test="`sector-${sector.id}`") {{ sector.name }}
          label.description {{ sector.description }}


        .actions-column(v-if="destroyable")
          app-button(theme="neutral", size="small", @click="$emit('destroy', sector)")
            i.fal.fa-trash-alt

</template>


<script>

export default {
  props: {
    loading:     { type: Boolean, default: false },
    destroyable: { type: Boolean, default: false },
    sectors:     { type: Array, default: () => [] },
    emptyText:   { type: String, default: null },
    theme:       { type: String, default: "default" }
  },

  data() {
    return {
      i18nScope: "suppliers.components.sectors-list"
    }
  },

  computed: {
    hasSectors() {
      return _.present(this.sectors)
    }
  }
}

</script>

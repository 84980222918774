export default {
  name: "BaseIcon",

  props: {
    color: { type: String, default: null }
  },

  computed: {
    defaultColor() {
      return "#333333"
    },

    colorHash() {
      switch (this.color) {
        case "disabled":
          return "#D4D4D4"
        case "blue":
          return "#0077DC"
        case "gray":
          return "#333333"
        default:
          return this.defaultColor
      }
    }
  }
}

<style lang="scss" scoped>

.service-order-product-row {
  grid-template-columns: minmax(172px, 1fr) 42px 101px 101px;
  min-height: 32px;
  border-top: 1px solid $light-gray-4;
  padding: 8px 0;

  .column {
    padding: 0 8px;
  }

  .name {
    font-family: $secondary-font;
    font-weight: 400;
    font-size: 14px;
  }

  .part-origin {
    font-family: $secondary-font;
    font-weight: 400;
    font-size: 12px;
    color: $gray-3;
  }

  .value {
    font-family: $primary-monospace-font;
    font-weight: 400;
    font-size: 14px;
  }
}

</style>


<template lang="pug">

  .service-order-product-row.grid.vertical-center
    .column.flex.column-direction
      span.name {{ serviceOrderProduct.name }}
      span.part-origin {{ MovidaServiceOrderProduct.$tEnum("partOrigin", serviceOrderProduct.partOrigin) }}

    .column.text-right
      span.value {{ serviceOrderProduct.quantity }}

    .column.text-right
      span.value {{ $asCurrency(serviceOrderProduct.price, { unit: "" }) }}

    .column.text-right
      span.value {{ $asCurrency(serviceOrderProduct.totalPrice, { unit: "" }) }}

</template>


<script>

// Models
import models from "@/models"
const { MovidaServiceOrderProduct } = models


export default {
  props: {
    serviceOrderProduct: { type: Object, required: true }
  },

  data() {
    return {
      i18nScope: "monitoring.quotes.reviews.service-order-product-row",

      MovidaServiceOrderProduct
    }
  }
}

</script>

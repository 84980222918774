<style scoped lang="scss">

.compact {
  .photo-content {
    margin-right: 8px;
  }

  .name {
    font-size: 18px;
  }
}

.photo-content {
  margin-right: 20px;
  margin-left: 4px;
  text-align: center;
}

.loading-lines + .loading-lines {
  margin-top: 4px;
}

.address {
  margin-right: 8px;
  font-weight: 300;
}

.name {
  font-size: 30px;
  font-weight: normal;
  font-family: $secondary-font;
  margin-bottom: 4px;
}

.hierarchy {
  margin-left: 8px;
}

.content {
  overflow: hidden;
  flex-grow: 1;
}

.diamond-icon {
  margin: 0 8px;
  font-size: 4px;
  color: $gray-3;
}

.address,
.head-office-name,
.name {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
}

.classification-badge {
  margin-left: 8px;
}

.cnpj {
  font-size: 16px;
  font-weight: 300;
  font-family: $primary-monospace-font;
  white-space: nowrap;
}

.secondary-content {
  font-weight: normal;
}

.head-office {
  margin-top: 4px;
  font-size: 14px;
}

.small {
  font-size: 14px;
}

.data {
  overflow: hidden;
}

.actions {
  margin-left: 12px;
}

</style>


<template lang="pug">

  .supplier-info-card.flex.vertical-center(:class="{ compact }")
    .photo-content
      avatar(placeholder="fas fa-user-hard-hat", :size="avatarSize")

    .content(v-if="loading")
      loading-lines(:height="18")
      loading-lines(:height="18")

    .content.flex(v-else)
      .data.grow
        .name-content.flex.vertical-center
          span.name {{ resource.tradingName || resource.name }}
          supplier-classification-badge.classification-badge(:value="resource.classification")

          template(v-if="!compact")
            template(v-if="resource.sapId")
              i.diamond-icon.spacer.fa.fa-diamond.flex.center.vertical-center
              sap-code-tag(:value="resource.sapId")

            i.diamond-icon.spacer.fa.fa-diamond.flex.center.vertical-center

            hierarchy-code-tag(:value="resource.hierarchy")

        .secondary-content.flex
          template(v-if="!compact")
            span.cnpj {{ resource.cnpj }}
            i.diamond-icon.spacer.fa.fa-diamond.flex.center.vertical-center

          span.address {{ addressLine1 }}

        template(v-if="!hideHeadOffice && resource.headOffice")
          .head-office.flex.vertical-center
            span.head-office-name {{ resource.headOffice.name }}
            i.diamond-icon.spacer.fa.fa-diamond.flex.center.vertical-center
            span.small.cnpj {{ resource.headOffice.cnpj }}
            hierarchy-code-tag.hierarchy(value="head")

      .actions(v-if="hasActionsSlot")
        slot(name="actions")

</template>


<script>

export default {
  name: "SupplierInfoCard",

  props: {
    avatarSize:     { type: Number, default: 80 },
    resource:       { type: Object, default: () => ({}) },
    hideHeadOffice: { type: Boolean, default: false },
    variant:        { type: String, default: "default" },
    loading:        { type: Boolean, default: false }
  },

  data() {
    return {
      i18nScope: "components.supplier.supplier-info-card"
    }
  },

  computed: {
    addressLine1() {
      return _.get(this.resource, "address.addressLine1")
    },

    compact() {
      return this.variant === "compact"
    },

    hasActionsSlot() {
      return _.present(this.$slots.actions)
    }
  }
}

</script>

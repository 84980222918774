import Base from "./base"

class SupplierBusinessHour extends Base {

  static get attrs() {
    return _.uniq([
      ...super.attrs,
      "startAt",
      "endAt",
      "weekday",
      "shift"
    ])
  }

  static get relations() {
    return {
      supplierDetails: { type: "belongsTo", model: "Supplier" }
    }
  }

  static get enums() {
    return {
      weekday: {
        monday:    "monday",
        tuesday:   "tuesday",
        wednesday: "wednesday",
        thursday:  "thursday",
        friday:    "friday",
        saturday:  "saturday",
        sunday:    "sunday"
      },

      shift: {
        morning:   "morning",
        afternoon: "afternoon",
        evening:   "evening"
      }
    }
  }

  $afterInit(...args) {
    // default value
    if (this.$blank("shift") && this.$present("startAt")) {
      const hourStr = this.startAt.split(":")[0]
      const hour = parseInt(hourStr, 10)

      if (hour >= 0 && hour < 12) {
        this.shift = "morning"

      }
      else if (hour >= 12 && hour < 18) {
        this.shift = "afternoon"

      }
      else if (hour >= 18 && hour <= 23) {
        this.shift = "evening"
      }
    }
  }

}

export default SupplierBusinessHour

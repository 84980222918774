<style scoped lang="scss">

.product-type-icon {
  --icon-color: #{$gray-3};

  color: var(--icon-color);
  font-size: var(--size);
  text-align: center;

  .icon {
    font-weight: var(--weight);
  }

  ::v-deep .product-part-icon .icon {
    --color: var(--icon-color);
  }
}

</style>


<template lang="pug">

  .product-type-icon.flex.vertical-center.center(:style="cssVars")
    template(v-if="iconType.isComponent")
      component.icon-component.flex.center(
        :is="iconType.content",
        :size="size",
        :weight="weight",
        v-tooltip.bottom="tooltipOptions"
      )
    template(v-else)
      i.icon(v-tooltip.bottom="tooltipOptions", :class="iconType.content")

</template>


<script>

// Models
import models from "@/models"

const { Product } = models

export default {
  name: "ProductTypeIcon",

  props: {
    type:   { type: String, default: null },
    size:   { type: Number, default: 24 },
    weight: { type: String, default: "light", validator: weight => ["light", "solid"].includes(weight) }
  },

  computed: {
    weightValue() {
      switch (this.weight) {
        case "solid":
          return 900
        case "light":
        default:
          return 300
      }
    },

    cssVars() {
      return {
        "--size":   `${this.size}px`,
        "--weight": this.weightValue
      }
    },

    iconType() {
      switch (this.type) {
        case "Part": return { isComponent: true, content: "product-part-icon" }
        case "MotorOil": return { isComponent: false, content: "fa fa-oil-can" }
        case "Tire": return { isComponent: false, content: "fa fa-tire" }
        default: return { isComponent: false, content: "fa fa-cube" }
      }
    },

    tooltipOptions() {
      return {
        content: this.typeText,
        offset:  "4"
      }
    },

    typeText() {
      return Product.$tEnum("type", this.type)
    }
  }
}

</script>

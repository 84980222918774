import Base from "./Base"

class VehicleParts extends Base {
  create({ params = {} } = {}) {
    return this.action({
      method: "post",
      url:    "/vehicle_parts",
      params
    })
  }

  list({ params = {} } = {}) {
    return this.action({
      method: "get",
      url:    "/vehicle_parts",
      params
    })
  }

  get({ id, params = {} }) {
    if (_.blank(id)) {
      throw new Error('SDK VehicleParts - #get() - "id" is a required prop')
    }

    return this.action({
      method: "get",
      url:    `/vehicle_parts/${id}`,
      params
    })
  }

  destroy({ id }) {
    if (_.blank(id)) {
      throw new Error('SDK VehicleParts - #destroy() - "id" is a required prop')
    }

    return this.action({
      method: "delete",
      url:    `/vehicle_parts/${id}`
    })
  }

  update({ id, params = {} } = {}) {
    if (_.blank(id)) {
      throw new Error('SDK VehicleParts - #update() - "id" is a required prop')
    }

    return this.action({
      method: "patch",
      url:    `/vehicle_parts/${id}`,
      params
    })
  }
}

export default VehicleParts

<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M19 15H18.5L15.0938 10.7812C14.75 10.3438 14.0625 10 13.5312 10H13H10H7.34375C6.53125 10 5.78125 10.5 5.46875 11.2812L4 15C2.875 15 2 15.9062 2 17V20.5C2 20.7812 2.21875 21 2.5 21H4C4 22.6875 5.3125 24 7 24C8.65625 24 10 22.6875 10 21H13H14C14 22.6875 15.3125 24 17 24C18.6562 24 20 22.6875 20 21H21.5C21.75 21 22 20.7812 22 20.5V18C22 16.3438 20.6562 15 19 15ZM13.5312 11H13V15H17.2188L14.3125 11.4062C14.125 11.1562 13.8438 11 13.5312 11ZM7.34375 11C6.9375 11 6.5625 11.25 6.40625 11.6562L5.0625 15H10V11H7.34375ZM5 21C5 22.125 5.875 23 7 23C8.09375 23 9 22.125 9 21C9 19.9062 8.09375 19 7 19C5.875 19 5 19.9062 5 21ZM15 21C15 22.125 15.875 23 17 23C18.0938 23 19 22.125 19 21C19 19.9062 18.0938 19 17 19C15.875 19 15 19.9062 15 21ZM19.8125 20H21V18C21 16.9062 20.0938 16 19 16H13V20H14.1562C14.5938 18.8438 15.6875 18 17 18C18.2812 18 19.375 18.8438 19.8125 20ZM7 18C8.28125 18 9.375 18.8438 9.8125 20H10V16H4C3.4375 16 3 16.4688 3 17V20H4.15625C4.59375 18.8438 5.6875 18 7 18ZM12 11H11V20H12V11Z"
      :fill="colorHash"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M14 4C14 3.44772 13.5523 3 13 3H10C9.44772 3 9 3.44772 9 4V6C9 6.55228 9.44772 7 10 7H13C13.5523 7 14 6.55228 14 6V4ZM13 4H10V6H13V4Z"
      :fill="colorHash"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M2 2.886C2 1.48959 3.39474 0.523029 4.70225 1.01334L10 3V4L4.32687 2.03786C3.67733 1.81321 3 2.29564 3 2.98293V7.00707C3 7.69719 3.6825 8.17987 4.33319 7.94992L10 5.94737V7L4.70225 8.98666C3.39474 9.47697 2 8.51041 2 7.114V2.886Z"
      :fill="colorHash"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M21 7.114C21 8.51041 19.6053 9.47697 18.2978 8.98666L13 7L13 6L18.6731 7.96214C19.3227 8.18679 20 7.70436 20 7.01706L20 2.99293C20 2.30281 19.3175 1.82013 18.6668 2.05007L13 4.05263L13 3L18.2978 1.01334C19.6053 0.523029 21 1.48959 21 2.886L21 7.114Z"
      :fill="colorHash"
    />
    <rect
      x="5"
      y="4"
      width="3"
      height="1"
      :fill="colorHash"
    />
    <rect
      x="18"
      y="5"
      width="3"
      height="1"
      transform="rotate(-180 18 5)"
      :fill="colorHash"
    />
  </svg>
</template>


<script>

import SupplierIconBase from "./supplier-icon-base"

export default {
  name: "SeminovosIcon",

  extends: SupplierIconBase
}

</script>

<style scoped lang="scss">

tr {
  &:first-child {

    td {
      padding-top: 12px;
    }
  }
}

td {
  vertical-align: top;
  padding: 4px;
}

.index {
  margin-top: 8px;
  display: block;
  background-color: $light-gray-3;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  font-family: $secondary-font;
  margin-left: 8px;
  font-weight: 500;
  line-height: 26px;
  text-align: center;
}

.toggle-field {
  display: inline-block;
  padding-top: 4px;

  ::v-deep .checkbox-input {
    width: 44px;
    height: 32px;
    border-radius: 32px;

    &:not(:checked) {
      border: 1px solid $light-gray-3;
    }
  }
}

.fields-content {
  display: flex;

  .kilometrage-limit {
    width: 110px;
    padding-right: 4px;
  }

  .time-limit {
    width: 105px;
    padding-left: 4px;
  }
}

.error-message {
  display: block;
  color: $error;
  font-size: 12px;
  line-height: 1;
  padding-top: 4px;
}

.remove-button {
  width: 32px;
  height: 32px;
  border: 1px solid $light-gray-3;
  border-radius: 50%;
  cursor: pointer;
  margin: 4px auto 0;

  .remove-icon {
    color: $error;
  }
}

</style>


<template lang="pug">

  tr
    td
      span.index {{ index }}

    td.maintenance-column
      maintenance-select-field(
        name="vehicleModelGroupMaintenance[maintenanceId]",
        v-model="resource.maintenanceId",
        :loading="loading",
        :errors="maintenanceErrors",
        hide-label
      )

    td(colspan="2")
      .fields-content
        input-field.kilometrage-limit(
          :name="`vehicleModelGroupMaintenance[kilometrageLimit][${index}]`",
          hide-label,
          :loading="loading",
          mask="#",
          v-model.number="resource.kilometrageLimit",
          :error="!!kilometrageOrTimeLimit",
          :errors="kilometrageLimitError",
          :suffix="$t('.kilometrageLimit.suffix')",
          data-testid="kilometrageLimitInput"
        )

        input-field.time-limit(
          :name="`vehicleModelGroupMaintenance[timeLimit][${index}]`",
          hide-label,
          :loading="loading",
          mask="#",
          v-model.number="resource.timeLimit",
          :error="!!kilometrageOrTimeLimit",
          :errors="timeLimitError",
          :suffix="$t('.timeLimit.suffix')",
          data-testid="timeLimitInput"
        )

      span.error-message(
        v-if="kilometrageOrTimeLimit"
        data-testid="kilometrageLimitOrTimeLimitError"
      ) {{ kilometrageOrTimeLimit }}

    td
      input-field(
        :name="`vehicleModelGroupMaintenance[targetExecutionTime][${index}]`",
        hide-label,
        :loading="loading",
        mask="#",
        v-model.number="resource.targetExecutionTime",
        :errors="errors.targetExecutionTime",
        :suffix="$t('.targetExecutionTime.suffix')"
      )

    td
      maintenance-package-select-field(
        v-model="resource.maintenancePackageId",
        name="vehicleModelGroupMaintenance[maintenancePackageId]",
        compact,
        hide-label,
        menu-growth-side="left",
        :loading="loading",
        :errors="errors.maintenancePackageId",
        :placeholder="$t('.maintenancePackageId.placeholder')",
        :menu-width="800"
      )

    td.center
      free-of-charge-toggle-field.toggle-field(
        :name="`vehicleModelGroupMaintenance[freeOfCharge][${index}]`",
        hide-label,
        hide-value-text,
        :loading="loading",
        v-model="resource.freeOfCharge",
        :errors="errors.freeOfCharge"
      )

    td.center
      forbidden-in-internal-facilities-toggle-field.toggle-field(
        :name="`vehicleModelGroupMaintenance[forbiddenInInternalFacilities][${index}]`",
        hide-label,
        hide-value-text,
        :loading="loading",
        v-model="resource.forbiddenInInternalFacilities",
        :errors="errors.forbiddenInInternalFacilities"
      )

    td.center
      dealership-only-toggle-field.toggle-field(
        :name="`vehicleModelGroupMaintenance[dealershipOnly][${index}]`",
        hide-label,
        hide-value-text,
        :loading="loading",
        v-model="resource.dealershipOnly",
        :errors="errors.dealershipOnly"
      )

    td.center
      .remove-button.flex.center.vertical-center(@click="$emit('remove')")
        i.remove-icon.fal.fa-times

</template>


<script>

import MaintenanceSelectField from "../components/maintenance-select-field"
import forbiddenInInternalFacilitiesToggleField from "./maintenance-fields/forbidden-in-internal-facilities-toggle-field"
import FreeOfChargeToggleField from "./maintenance-fields/free-of-charge-toggle-field"
import DealershipOnlyToggleField from "./maintenance-fields/dealership-only-toggle-field"

export default {
  components: {
    MaintenanceSelectField,
    forbiddenInInternalFacilitiesToggleField,
    FreeOfChargeToggleField,
    DealershipOnlyToggleField
  },

  props: {
    resource: { type: Object, required: true },
    index:    { type: Number, required: true },
    loading:  { type: Boolean, default: false },
    errors:   { type: Object, default: () => ({}) }
  },

  data() {
    return {
      i18nScope: "vehicle-model-groups.maintenance-tables.form.maintenance-fields"
    }
  },

  computed: {
    maintenanceErrors() {
      return _.compact(
        _.concat(this.errors.maintenanceId, this.errors.maintenance)
      )
    },

    kilometrageLimitError() {
      if (!!this.kilometrageOrTimeLimit) {
        return null
      }

      return this.errors.kilometrageLimit
    },

    timeLimitError() {
      if (!!this.kilometrageOrTimeLimit) {
        return null
      }

      return this.errors.timeLimit
    },

    kilometrageOrTimeLimit() {
      if (!this.errors.kilometrageOrTimeLimit) return

      return this.$t(".kilometrageOrTimeLimit.error")
    }
  }
}

</script>

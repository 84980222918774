<template lang="pug">

  .vehicle-models
    search-form.search(
      :loading="fetching",
      :params="parsedQueryParams",
      @search="search"
    )

    .clear-filters
      filters-button.filters-button(
        v-if="hasFilters",
        @clear="clearFilters"
      )

    .list
      .flex.space-between.vertical-bottom
        .result-tabs.flex

        results-indicator(
          :page="parsedQueryParams.page",
          :per-page="pagination.perPage",
          :total="pagination.total",
          :loading="fetching"
        )

      mobiauto-versions-table.table(
        :error="erred",
        :initializing="initializing",
        :loading="fetching",
        :mobiauto-versions="mobiautoVersions",
        :sorted-by="parsedQueryParams.sort",
        @sort="onSortChange",
        @changed="fetch"
      )

    .paginator-content(v-if="fetching || showPaginator", :class="{ loading: fetching }")
      paginator(
        :loading="fetching",
        :per-page="pagination.perPage",
        :total="pagination.total",
        :value="parsedQueryParams.page",
        :disabled="fetching",
        @input="onPageChange",
        data-testid="paginator"
      )

</template>


<script>

import VehicleModelsList from "@/views/vehicle-models/index.vue"
import MobiautoVersionsTable from "./mobiauto-versions-table.vue"

// Models
import models from "@/models"
const { VehicleModel } = models

export default {
  components: {
    MobiautoVersionsTable
  },

  // TODO: remover herança, são modelos diferentes!
  extends: VehicleModelsList,

  methods: {
    // @override Fetchable mixin
    fetchRequest() {
      return this.$newSdk.vehicleModelGroups.listVehicleModels({
        vehicleModelGroupId: this.$params.asInteger(this.$route.params.id),
        params:              this.requestQueryParams
      })
    },

    // @override Fetch mixin
    onFetchSuccess({ data, headers }) {
      this.setPagination(headers)

      this.resource = data.map(vehicleModel => VehicleModel.build(vehicleModel))
    }
  }
}

</script>

// Base
import BaseRequests from "./Base"

// Models
import models from "@/models"
const { Supplier, Deal } = models

class SuppliersRequests extends BaseRequests {
  static get selector() {
    return "suppliers"
  }

  find({ supplierId, params = {}, options = {} } = {}) {
    this.verifyData("find", { supplierId })

    return this.action({
      method:  "get",
      url:     `/suppliers/${supplierId}`,
      params,
      options: {
        model: Supplier,

        ...options
      }
    })
  }

  create({ params = {}, options = {} } = {}) {
    return this.action({
      method: "post",
      url:    "/suppliers",
      params,
      options
    })
  }

  update({ supplierId, params = {}, options = {} } = {}) {
    this.verifyData("update", { supplierId })

    return this.action({
      method:  "patch",
      url:     `/suppliers/${supplierId}`,
      params,
      options: {
        model: Supplier,

        ...options
      }
    })
  }

  all({ params = {}, options = {} } = {}) {
    return this.action({
      method:  "get",
      url:     "/suppliers",
      params,
      options: {
        model: Supplier,

        ...options
      }
    })
  }

  export({ params = {}, options = {} } = {}) {
    return this.action({
      method:  "get",
      url:     "/suppliers/export",
      params,
      options: {
        model: Supplier,
        ...options
      }
    })
  }

  findDeal({
    supplierId,
    dealId,
    params = {},
    options = {}
  } = {}) {
    this.verifyData("findDeal", { supplierId, dealId })

    return this.action({
      method:  "get",
      url:     `/suppliers/${supplierId}/deals/${dealId}`,
      params,
      options: {
        model: Deal,

        ...options
      }
    })
  }

  listDeals({ supplierId, params = {}, options = {} } = {}) {
    this.verifyData("listDeals", { supplierId })

    return this.action({
      method:  "get",
      url:     `/suppliers/${supplierId}/deals`,
      params,
      options: {
        model: Deal,

        ...options
      }
    })
  }
}

export default SuppliersRequests

<style lang="scss" scoped>

.fields {
  grid-template-columns: var(--grid-columns);
  gap: 8px;

  .action-container {
    height: 40px;
    border-radius: 50%;
    cursor: pointer;

    &.attachment {
      color: $orange;

      ::v-deep .extra-data-indicator .icon {
        color: $orange;
      }

      &:active,
      &:focus {
        background-color: $light-gray-4;

        ::v-deep .extra-data-indicator .icon {
          color: $dark-orange-2;
        }
      }

      &.disabled {
        background-color: transparent;

        .extra-data-indicator {
          cursor: not-allowed;

          ::v-deep .icon {
            color: $gray-3;
          }
        }
      }
    }

    &:hover {
      background-color: $light-gray-3;
      color: $orange;
    }

    &:active,
    &:focus {
      background-color: $light-gray-4;
      color: $dark-orange-2;
    }

    &.disabled {
      background-color: transparent;
      color: $gray-3;
      cursor: not-allowed;
    }
  }

  .add-container {
    height: 40px;
    border-radius: 50%;
    cursor: pointer;
    border: 1px solid $orange;
    color: $orange;

    &:hover {
      color: $dark-orange;
      border-color: $dark-orange;
    }

    &:active,
    &:focus {
      color: $dark-orange-2;
      border-color: $dark-orange-2;
    }

    &.disabled {
      color: $gray-3;
      border-color: $gray-3;
      cursor: not-allowed;
    }
  }
}

</style>


<template lang="pug">

.provided-product-fields.fields.grid.vertical-start(:style="cssVars")
  product-select-field.item-selector(
    :quote-id="quoteId",
    :product-rid="productRid",
    v-model="quoteProduct.productId",
    hide-label,
    mandatory,
    name="quoteProduct[productId]",
    :disabled="submitting",
    :errors="errors.productId"
  )

  warranty-checkbox-field(
    v-model="quoteProduct.warranty",
    hide-value-text,
    name="quoteProduct[warranty]",
    :disabled="submitting",
    :errors="errors.warranty"
  )

  integer-field(
    v-model="quoteProduct.quantity",
    hide-controls,
    hide-label,
    mandatory,
    name="quoteProduct[quantity]",
    :disabled="submitting",
    :errors="errors.quantity",
    :max="999",
    :min="0"
  )

  .action-container.attachment.flex.vertical-center.center(
    tabindex="0",
    :class="{ disabled: submitting }",
    @click="onShow",
    @keyup.space.prevent.stop="onShow",
    @keyup.enter.prevent.stop="onShow"
  )
    template(v-if="hasAdditionalData")
      extra-data-indicator(:count="attachmentsCount")

    template(v-else)
      i.icon.far.fa-paperclip

    quote-item(
      v-if="showAttachmentsModal",
      v-model="quoteItem"
      model="QuoteProduct",
      @close="showAttachmentsModal = false"
    )

  .action-container.flex.vertical-center.center(
    tabindex="0",
    :class="{ disabled: submitting }",
    @click="onDestroy",
    @keyup.space.prevent.stop="onDestroy",
    @keyup.enter.prevent.stop="onDestroy"
  )
    i.icon.far.fa-times

  template(v-if="showAddButton")
    .add-container.flex.vertical-center.center(
      tabindex="0",
      :class="{ disabled: submitting }",
      @click="onAdd",
      @keyup.space.prevent.stop="onAdd",
      @keyup.enter.prevent.stop="onAdd"
    )
      i.icon.far.fa-plus

</template>


<script>

// Components
import ProductSelectField    from "./product-select-field.vue"
import QuoteItem             from "./quote-item.vue"
import WarrantyCheckboxField from "./warranty-checkbox-field.vue"


export default {
  components: {
    ProductSelectField,
    QuoteItem,
    WarrantyCheckboxField
  },

  props: {
    quoteProduct:  { type: Object, required: true },
    submitting:    { type: Boolean, default: false },
    errors:        { type: Object, default: () => ({}) },
    columnSizes:   { type: String, required: true },
    showAddButton: { type: Boolean, default: false }
  },

  data() {
    return {
      i18nScope: "monitoring.quotes.provided-product-fields",

      showAttachmentsModal: false
    }
  },

  computed: {
    quoteItem: {
      get()    { return this.quoteProduct },
      set(val) { this.$emit("update", val) }
    },

    attachmentsCount() {
      return this.quoteProduct?.attachments
        ?.filter(attachment => !attachment.$markedForDestruction)
        .length
    },

    hasAdditionalData() {
      return _.present(this.quoteProduct?.description) || _.present(this.quoteProduct?.attachments)
    },

    quoteId() {
      return this.quoteProduct?.quoteId
    },

    productRid() {
      return this.quoteProduct?.product?.rid
    },

    cssVars() {
      return {
        "--grid-columns": this.columnSizes
      }
    }
  },

  methods: {
    onShow() {
      if (this.submitting) return

      this.showAttachmentsModal = true
    },

    onDestroy() {
      if (this.submitting) return

      this.$emit("destroy")
    },

    onAdd() {
      if (this.submitting) return

      this.$emit("add")
    }
  }
}

</script>

<style scoped lang="scss">

.header {
  margin-bottom: 24px;
}

.title {
  font-size: 20px;
  font-weight: 500;
  font-family: $secondary-font;
}

.actions {
  margin-top: 24px;
  padding-top: 24px;
  border-top: 1px solid $light-gray-3;
}

.tag {
  margin-left: 8px;
}

.back {
  font-family: $secondary-font;
  font-weight: 400;
  font-size: 14px;
  margin-bottom: 16px;

  &::v-deep.link {
    color: $gray-2;

    &:hover {
      color: $gray-3;
    }
  }

  .icon {
    margin-right: 8px;
    font-size: 12px;
  }
}

</style>


<template lang="pug">

  .vehicle-model-group-maintenance-table
    app-button.back(
      theme="link",
      :to="{ name: 'vehicleModelGroupMaintenanceTables' }",
      :disabled="submitting"
    )
      .flex.vertical-center
        i.icon.fas.fa-arrow-left
        span {{ $t('.back.label') }}

    header.header.flex.vertical-center
      h1.title {{ $t('.title') }}
      id-tag.tag(:value="resource.id", size="big")

    form.form(@submit.prevent="submit", method="post")

      form-fields.fields(
        :fetching="fetching",
        :submitting="submitting",
        :errors="errors",
        :resource="resource"
      )

      section.actions.flex.space-between.vertical-center
        app-button(
          :to="{ name: 'vehicleModelGroupMaintenanceTable', params: { id, maintenanceTableId } }",
          theme="gray",
          outline,
          :disabled="submitting"
        ) {{ $t('btn.cancel') }}

        app-button(
          type="submit",
          :loading="submitting",
          data-testid="submitButton"
        )
          span {{ $t('btn.update.label') }}
          span(slot="loading") {{ $t('btn.update.loading') }}

</template>


<script>

// Mixins
import { mixins } from "movida-common.vue"

// Extends
import View from "@/views/view"

// Local Components
import FormFields from "./form/form-fields.vue"

const { FormMixin, Fetchable } = mixins

export default {
  name: "EditVehicleModelGroupMaintenanceTable",

  components: {
    FormFields
  },

  extends: View,

  mixins: [Fetchable, FormMixin],

  data() {
    return {
      i18nScope: "vehicle-model-groups.maintenance-tables.edit",

      id:                 this.$route.params.id,
      maintenanceTableId: this.$route.params.maintenanceTableId,

      // Fetchable attributes
      model: "VehicleModelGroupMaintenanceTable",

      // FormMixin attributes
      resource: {},

      nestedAttributes: {
        vehicleModelGroupMaintenances: false
      }
    }
  },

  methods: {
    // @override Form mixin
    submitRequest() {
      const params = this.serialize(this.resource, this.nestedAttributes)

      return this.$sdk.vehicleModelGroups.updateMaintenanceTable({
        id:                 this.id,
        maintenanceTableId: this.maintenanceTableId,
        params
      })
    },

    // @override Form mixin
    submitSuccess({ data: { id, name } }) {
      this.$notifications.info(this.$t(".notifications.submit.success", { name }))
      this.$router.push({
        name:   "vehicleModelGroupMaintenanceTable",
        params: {
          id:                 this.id,
          maintenanceTableId: this.maintenanceTableId
        }
      })
    },

    // @override Form mixin
    submitError(err) {
      this.$err.log(err)
      this.$notifications.error(this.$t(".notifications.submit.error"))
    },

    // @override Fetchable mixin
    fetch() {
      return this.$sdk.vehicleModelGroups.getMaintenanceTable({
        id:                 this.id,
        maintenanceTableId: this.maintenanceTableId
      })
    },

    // @override Fetchable mixin
    // onFetchSuccess(response) {},

    // @override Fetchable mixin
    onFetchError(err) {
      // Dealing with appError from extension view.vue
      if (this.hasViewError(err)) this.appError = err

      this.$err.log(err)
      this.$notifications.error(this.$t(".notifications.fetch.failure"))
    }
  }
}

</script>

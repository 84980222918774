<style lang="scss" scoped>

.form {
  width: 100%;
  gap: 8px;

  .row {
    gap: 16px;

    &.first {
      grid-template-columns: minmax(0, 1fr) max-content 40px;
    }

    &.second {
      grid-template-columns: repeat(4, minmax(0, 1fr));
    }

    &.third {
      grid-template-columns: repeat(2, minmax(0, 2fr)) repeat(2, minmax(0, 1fr))
    }

    &.fourth {
      grid-template-columns: repeat(4, minmax(0, 1fr));
    }
  }

  .submit-button {
    min-width: revert;
  }

  .collapse-group {
    gap: 8px;
    max-height: 0;
    overflow: hidden;
    transition: max-height 200ms ease-in-out;

    &.show {
      max-height: 140px;
      overflow: visible;
    }
  }

  .clear-filters {
    display: flex;

    .collapse-button {
      color: $dark-gray;
      display: block;
      margin-left: auto;

      .icon {
        font-size: 14px;
        margin-right: 4px;
        transition: transform 200ms ease-in-out;
      }

      .text {
        font-size: 14px;
        font-weight: 400;
      }

      &.active {
        .icon {
          transform: rotate(180deg);
        }
      }
    }
  }
}

</style>


<template lang="pug">

  .search-form
    form.form.grid(
      @submit.prevent="$emit('search')",
      method="get",
      data-test="form"
    )
      .row.first.grid.vertical-bottom
        clearable-input-field.field.q(
          v-model="params.q"
          :disabled="loading"
          :label="$t('.fields.q.label')"
          name="search[q]"
          :placeholder="$t('.fields.q.placeholder')"
          :tooltip="$t('.fields.q.tooltip')"
          type="text"
        )
          template(#iconLeft)
            i.far.fa-search

        quick-filters(:loading="loading", @filter="onQuickFilter")

        app-button.submit-button(
          type="submit",
          :loading="loading",
          outline
        )
          i.icon.far.fa-search


      .row.second.grid.vertical-bottom
        finder-field.field.state(
          v-model="params.state",
          :disabled="loading",
          :label="$t('.fields.state.label')",
          :listbox-config="{ width: 240 }",
          name="search[state]",
          :options="serviceOrderStateOptions",
          :placeholder="$t('.fields.state.placeholder')"
        )

        business-unit-multiple-select-field.field.business-unit(
          name="search[businessUnit]",
          v-model="params.businessUnit",
          :label="$t('.fields.businessUnit.label')",
          :placeholder="$t('.fields.businessUnit.placeholder')",
          :loading="loading"
        )

        vehicle-category-finder-field.field.vehicle-category(
          v-model="params.vehicleCategory",
          :disabled="loading",
          :label="$t('.fields.vehicleCategory.label')",
          multiple,
          name="search[vehicleCategory]",
          :placeholder="$t('.fields.vehicleCategory.placeholder')",
          searchable
        )

        assistant-select-field.field.assignee-id(
          name="search[assigneeId]",
          v-model="params.assigneeId",
          :menu-width="300",
          :label="$t('.fields.assigneeId.label')",
          :loading="loading"
        )

      .collapse-group.grid(:class="{ show: showCollapseGroup }")
        .row.third.grid.vertical-bottom
          daterange-field(
            :disabled="loading"
            :label="$t('.fields.scheduled.label')"
            :labels="{\
              start: $t('.fields.scheduled.label-from'),\
              end: $t('.fields.scheduled.label-until')\
            }"
            :placeholder="$t('.fields.scheduled.placeholder')"
            presets="future"
            :tooltip="$t('.fields.scheduled.tooltip')"
            :value="scheduledRange"
            @input="onScheduledRangeInput"
          )

          daterange-field(
            :disabled="loading"
            :label="$t('.fields.estimatedCompleted.label')"
            :labels="{\
              start: $t('.fields.estimatedCompleted.label-from'),\
              end: $t('.fields.estimatedCompleted.label-until')\
            }"
            :placeholder="$t('.fields.estimatedCompleted.placeholder')"
            presets="future"
            :tooltip="$t('.fields.estimatedCompleted.tooltip')"
            :value="estimatedCompletedRange"
            @input="onEstimatedCompletedRangeInput"
          )

          geo-region-finder-field.field.region(
            v-model="params.region",
            :disabled="loading",
            :label="$t('.fields.region.label')",
            name="search[region]",
            multiple,
            :placeholder="$t('.fields.region.placeholder')",
            searchable
          )

          geo-state-finder-field.field.uf(
            v-model="params.uf",
            :disabled="loading",
            :label="$t('.fields.uf.label')",
            :listbox-config="{ maxHeight: 300 }"
            multiple,
            name="search[uf]",
            :placeholder="$t('.fields.uf.placeholder')",
            searchable
          )


        .row.fourth.grid.vertical-bottom
          finder-field.field.service-kind(
            v-model="params.serviceKind",
            :disabled="loading",
            :label="$t('.fields.serviceKind.label')",
            :listbox-config="{ maxHeight: 300, width: 240 }",
            multiple,
            name="search[serviceKind]",
            :options="serviceKindOptions",
            :placeholder="$t('placeholder.select')",
            searchable
          )

          finder-field.field.vehicle-stopped(
            v-model="params.vehicleStopped",
            :disabled="loading",
            :label="$t('.fields.vehicleStopped.label')",
            name="search[vehicleStopped]",
            :options="vehicleStoppedOptions",
            :placeholder="$t('placeholder.select')"
          )

          finder-field.field.quote-issued(
            v-model="params.quoteIssued",
            :disabled="loading",
            :label="$t('.fields.quoteIssued.label')",
            name="search[quoteIssued]",
            :options="quoteIssuedOptions",
            :placeholder="$t('placeholder.select')",
          )

          finder-field.field.vehicle-withdrawed(
            v-model="params.vehicleWithdrawed",
            :disabled="loading",
            :label="$t('.fields.vehicleWithdrawed.label')",
            name="search[vehicleWithdrawed]",
            :options="vehicleWithdrawedOptions"
            :placeholder="$t('placeholder.select')",
          )


      .clear-filters
        filters-button.filters-button(
          v-if="hasFilters",
          @clear="clearFilters"
        )

        app-button.collapse-button(
          :class="{ active: showCollapseGroup }"
          theme="link"
          @click="toggleCollapseGroup"
        )
          .flex.vertical-center
            i.icon.fal.fa-chevron-down
            span.text(v-if="showCollapseGroup") {{ $t(".collapse-button.active.label") }}
            span.text(v-else) {{ $t(".collapse-button.collapsed.label") }}
</template>


<script>

import AssistantSelectField                 from "@/views/monitoring/_components/assistant-select-field"
import BusinessUnitMultipleSelectField      from "@/views/monitoring/_components/business-unit-multiple-select-field"
import ClearableInputField                  from "@/views/monitoring/_components/clearable-input-field"
import GeoRegionFinderField                 from "@/views/monitoring/_components/geo-region-finder-field"
import GeoStateFinderField                  from "@/views/monitoring/_components/geo-state-finder-field"
import QuickFilters                         from "./quick-filters"
import ServiceOrderStateMultipleSelectField from "@/views/monitoring/_components/service-order-state-multiple-select-field"
import VehicleCategoryFinderField           from "@/views/monitoring/_components/vehicle-category-finder-field"

import models from "@/models"
const { MovidaServiceOrder } = models


export default {

  components: {
    AssistantSelectField,
    BusinessUnitMultipleSelectField,
    ClearableInputField,
    GeoRegionFinderField,
    GeoStateFinderField,
    QuickFilters,
    ServiceOrderStateMultipleSelectField,
    VehicleCategoryFinderField
  },

  emits: [
    "clear",
    "quick-filter",
    "search"  // evento de "submit" do formulário de busca
  ],

  props: {
    hasFilters: { type: Boolean, default: false },
    loading:    { type: Boolean, default: false },
    params:     { type: Object, default: () => ({}) }
  },

  data() {
    return {
      i18nScope: "monitoring.search-form",

      showCollapseGroup: false
    }
  },

  computed: {
    serviceOrderStateOptions() {
      return MovidaServiceOrder.$enums.state.values.map(state => ({
        label: MovidaServiceOrder.$tEnum("state", state),
        value: state
      }))
    },

    scheduledRange() {
      const { scheduledFrom, scheduledUntil } = this.params
      return [scheduledFrom, scheduledUntil].filter(_.present)
    },

    estimatedCompletedRange() {
      const { estimatedCompletedFrom, estimatedCompletedUntil } = this.params
      return [estimatedCompletedFrom, estimatedCompletedUntil].filter(_.present)
    },

    serviceKindOptions() {
      return MovidaServiceOrder.$enums.serviceKind.values.map(serviceKind => ({
        label: MovidaServiceOrder.$tEnum("serviceKind", serviceKind),
        value: serviceKind
      }))
    },

    quoteIssuedOptions() {
      return [{
        label: this.$t("yes"),
        value: true
      }, {
        label: this.$t("no"),
        value: false
      }]
    },

    vehicleStoppedOptions() {
      return [{
        label: this.$t("yes"),
        value: true
      }, {
        label: this.$t("no"),
        value: false
      }]
    },

    vehicleWithdrawedOptions() {
      return [{
        label: this.$t("yes"),
        value: true
      }, {
        label: this.$t("no"),
        value: false
      }]
    }
  },

  methods: {
    clearFilters() {
      this.$emit("clear")
    },

    onScheduledRangeInput([start, end]) {
      this.params.scheduledFrom = start
      this.params.scheduledUntil = end
    },

    onEstimatedCompletedRangeInput([start, end]) {
      this.params.estimatedCompletedFrom = start
      this.params.estimatedCompletedUntil = end
    },

    onQuickFilter(payload) {
      this.$emit("quick-filter", payload)
    },

    toggleCollapseGroup() {
      this.showCollapseGroup = !this.showCollapseGroup
    }
  }
}

</script>

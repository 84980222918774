<style lang="scss" scoped>

.contact-row {
  height: 60px;
  border-top: 1px solid $light-gray-3;

  &.error {
    border: 1px solid $error;
  }

  .initial {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    color: $white;
    font-size: 14px;
    font-family: $secondary-font;
    font-weight: 400;
    margin: 0 16px;
  }

  .info {
    margin-right: 16px;
  }

  .name {
    font-family: $secondary-font;
    font-weight: 500;
  }

  .name,
  .email,
  .phone,
  .area {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
  }

  .area,
  .kind {
    font-weight: 300;
  }

  .kind {
    font-size: 12px;
    color: $gray-3;
  }

  .icon,
  .copy-icon {
    font-size: 14px;
    color: $gray-2;
  }

  .icon {
    margin-right: 8px;
  }

  .copy-icon,
  .count {
    margin-left: 8px;
  }

  .diamond-icon {
    margin: 0 8px;
    font-size: 4px;
    color: $gray-3;
  }

  .count {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: $light-info;
    font-family: $secondary-font;
    font-weight: 400;
    font-size: 12px;
  }

  .destroyed {
    background-color: $light-gray-2;
    text-align: center;
    font-family: $secondary-font;
    font-weight: 400;

    .undo {
      font-weight: normal;
      margin-left: 8px;
    }
  }
}

</style>


<template lang="pug">

  tr.contact-row(:class="{ error: hasErrors }")
    template(v-if="markedForDestruction")
      td.destroyed(colspan="4")
        span {{ $t('.destroyed', { name: contact.name }) }}
        app-button.undo(
          theme="link",
          @click="contact.$unmarkForDestruction()"
        ) {{ $t('.undo') }}

    template(v-else)
      td
        .flex.vertical-center.info
          .initial.flex.center.vertical-center.no-shrink(:style="style") {{ firstLetter }}
          span.name(data-test="contactRowName") {{ contact.name }}
          template(v-if="hasArea")
            i.diamond-icon.fa.fa-diamond.flex.center.vertical-center
            i.icon.fal.fa-briefcase
            span.area {{ contact.area }}

      td
        .flex.vertical-center.info
          template(v-if="!hasEmail")
            span -

          template(v-else)
            span.email(data-test="contactRowEmail") {{ contact.primaryEmail.value }}
            copy-icon.copy-icon(:copy-value="contact.primaryEmail.value")
            template(v-if="hasEmailKind")
              i.diamond-icon.fa.fa-diamond.flex.center.vertical-center
              span.kind {{ Email.$tEnum('kind', contact.primaryEmail.kind) }}
            template(v-if="emailsCount > 1")
              .count.flex.center.vertical-center.no-shrink {{ emailsCount }}

      td
        .flex.vertical-center.info
          template(v-if="!hasPhone")
            span -
          template(v-else)
            span.phone(data-test="contactRowPhone") {{ contact.primaryPhone.formattedValue }}
            template(v-if="hasPhoneKind")
              i.diamond-icon.fa.fa-diamond.flex.center.vertical-center
              span.kind {{ Phone.$tEnum('kind', contact.primaryPhone.kind) }}
            template(v-if="phonesCount > 1")
              .count.flex.center.vertical-center.no-shrink {{ phonesCount }}

      td
        supplier-contact(
          v-if="showContactModal",
          :contact="contact",
          :errors="errors",
          @close="showContactModal = false",
          @edit="showEditContactModal = true",
          @destroy="$emit('destroy')",
          :edit="edit"
        )
        edit-supplier-contact(
          v-if="edit && showEditContactModal",
          :contact="contact",
          :server-errors="errors",
          @close="showEditContactModal = false"
        )
        popover-menu(
          :loading="submitting"
          :show.sync="showPopover",
          data-test="contactRowActions"
        )
          popover-menu-button(
            icon="fal fa-eye",
            :text="$t('.actions.btn.show')",
            @close-menu="showPopover = false",
            @click="showContactModal = true"
          )
          template(v-if="edit")
            popover-menu-button(
              icon="fal fa-pencil",
              :text="$t('.actions.btn.edit')",
              @close-menu="showPopover = false",
              @click="showEditContactModal = true",
              data-test="contactRowEditAction"
            )
            popover-menu-button(
              icon="fal fa-trash-alt",
              :text="$t('.actions.btn.remove')",
              @close-menu="showPopover = false",
              @click="$emit('destroy')",
              data-test="contactRowRemoveAction"
            )

</template>


<script>

// Components
import SupplierContact from "../show"
import EditSupplierContact from "../edit"

// Models
import models from "@/models"
const { Email, Phone } = models

const COLORS = [
  "#3FA2F7",
  "#4A8D8D",
  "#5C9561",
  "#5D8752",
  "#6CC04F",
  "#755BBE",
  "#A651BB",
  "#A99C57",
  "#D05757",
  "#D48A46"
]

export default {
  components: {
    SupplierContact,
    EditSupplierContact
  },

  props: {
    contact:    { type: Object, required: true },
    submitting: { type: Boolean, default: false },
    errors:     { type: Object, default: () => ({}) },
    edit:       { type: Boolean, default: false }
  },

  data() {
    return {
      i18nScope: "suppliers.contacts.index.contact-row",

      showPopover: false,

      showContactModal:     false,
      showEditContactModal: false,

      Email,
      Phone
    }
  },

  computed: {
    hasArea() {
      return _.present(this.contact.area)
    },

    hasErrors() {
      return _.present(this.errors)
    },

    markedForDestruction() {
      return this.contact._destroy === true
    },

    firstLetter() {
      return this.contact.name.charAt(0)
    },

    color() {
      return _.sample(COLORS)
    },

    style() {
      return {
        backgroundColor: this.color
      }
    },

    hasEmail() {
      return _.present(this.contact.primaryEmail)
    },

    hasEmailKind() {
      return _.present(_.get(this.contact.primaryEmail, "kind"))
    },

    emailsCount() {
      return this.contact.emails.filter(resource => !resource.$markedForDestruction).length
    },

    hasPhone() {
      return _.present(this.contact.primaryPhone)
    },

    hasPhoneKind() {
      return _.present(_.get(this.contact.primaryPhone, "kind"))
    },

    phonesCount() {
      return this.contact.phones.filter(resource => !resource.$markedForDestruction).length
    }
  }
}

</script>

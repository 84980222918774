<!--
<doc lang="markdown">
Exibe um menu do tipo "breadcrumbs"

_Props_: links e loading
Nenhuma _prop_ é obrigatória

Como usar:
```pug
breadcrumbs(
  :links="links"
  :loading="loading"
)
```

Onde _links_ é um array que segue o padrão:
```json
[
  {
    "text": "texto do link",
    "to":   { "name": "rota do vue-router", "params": {} }
  }
]
```
</doc>
-->

<style lang="scss" scoped>

.link {
  font-family: $secondary-font;
  font-weight: 400;
  color: $gray-2;
  font-size: 14px;

  &:last-child {
    color: $dark-gray;
  }

  &:hover {
    color: $primary;
  }
}

.link:hover {
  color: $primary;
}

.breadcrumb {
  display: inline;
}

.separator {
  user-select: none;
  color: $gray-2;
  font-size: 12px;
  font-weight: 900;
  margin: 0 16px;
}

</style>


<template lang="pug">

  .breadcrumbs
    loading-lines(v-if="loading", :height="23")
    template(v-else)
      .breadcrumb(
        v-for="(link, index) in links",
        :key="`breadcrumb-${uuid()}`"
      )
        router-link.link(:to="link.to", role="link") {{ link.text }}
        i.icon.separator.fa.fa-chevron-right(v-if="showSeparator(index)")

</template>


<script>

import { v4 as uuid } from "uuid"

export default {
  name: "Breadcrumbs",

  props: {
    links:   { type: Array, default: () => [] },
    loading: { type: Boolean, default: false }
  },

  data() {
    return {
      i18nScope: "components.breadcrumbs",

      // XXX Nesse caso a permanência da :key não é necessária. Essa solução recalcula o valor em mudanças.
      uuid
    }
  },

  methods: {
    showSeparator(index) {
      return index < (this.links.length - 1)
    }
  }
}

</script>

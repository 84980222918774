<style scoped lang="scss">

.vehicle-models-select-list{
  .search {
    margin-bottom: 12px;
  }

  .filters {
    padding-bottom: 12px;

    .results-indicator {
      margin-left: auto;
    }
  }

  .paginator-content {
    margin: 16px auto;

    &.loading {
      max-width: 400px;
    }
  }

  .error-message {
    display: block;
    color: $error;
    font-size: 12px;
    line-height: 1;
    padding-bottom: 4px;
  }
}

</style>


<template lang="pug">

  .vehicle-models-select-list
    search-form.search(
      :params="params",
      @search="search",
      :loading="fetching || loading"
    )

    .filters.flex.space-between.vertical-center
      filters-button.filters-button(
        v-if="hasFilters",
        @clear="clearFilters",
        data-testid="clearFilters"
      )

      results-indicator.results-indicator(
        :page="params.page",
        :per-page="pagination.perPage",
        :total="pagination.total",
        :loading="fetching",
        data-testid="results"
      )

    index-table(
      :initializing="initializing",
      :empty="empty",
      :cols-count="5",
      column-sizes="1fr 1fr 1fr 1fr 1fr"
    )
      template(#tableHeader)
        table-header(
          :selected-count="totalSelected",
          :filter-selected="filterSelected",
          :all-selected="allSelectedOnPage",
          :loading="fetching",
          :has-selected-options="hasSelectedOptions",
          @filterSelected="filterSelectedIds",
          @selectAll="selectAll",
          @removeAll="removeAll",
          @selectAllOnPage="selectAllOnPage"
        )

      template(#tableEmpty)

      template(#tableRows)
        vehicle-issue-row(
          v-for="vehicleIssue in vehicleIssues",
          :key="`vehicle-issue-${vehicleIssue.id}`",
          :vehicle-issue="vehicleIssue",
          :selected="isIssueSelected(vehicleIssue.id)"
          :loading="fetching",
          :data-testid="`vehicleIssue-${vehicleIssue.id}`",
          @select="selectIssue"
        )

    .paginator-content(v-if="fetching || showPaginator", :class="{ loading: fetching }")
      paginator(
        :loading="fetching",
        :per-page="pagination.perPage",
        :total="pagination.total",
        @input="onPageChange",
        :value="params.page",
        data-test="paginator"
      )

</template>


<script>

// Mixins
import FetchMixin from "@/mixins/fetch-mixin"

// Components
import SearchForm from "./search-form.vue"
import TableHeader from "./table-header.vue"
import VehicleIssueRow from "./vehicle-issue-row.vue"

// Extends
import IndexComponent from "@/components/index-component"

export default {
  components: {
    SearchForm,
    TableHeader,
    VehicleIssueRow
  },

  extends: IndexComponent,

  mixins: [FetchMixin],

  props: {
    value: { type: Array, default: () => [] },

    serviceId: { type: [Number, String], default: null },

    loading: { type: Boolean, default: false },
    error:   { type: String, default: null }
  },

  data() {
    return {
      i18nScope: "services.components.vehicle-issues.select-list",

      resource: [],

      filterSelected:         false,
      clearFiltersIgnoreList: ["ids"],

      // XXX: inicializando params.ids para ficar reativo
      params: {
        ids: []
      }
    }
  },

  computed: {
    vehicleIssues: {
      get() { return this.resource },
      set(value) { this.resource = value }
    },

    // Controlando a prop value diretamente.
    vehicleIssueIds: {
      get() { return this.value },
      set(value) { this.$emit("input", value) }
    },

    hasValidationError() {
      return _.present(this.error)
    },

    totalSelected() {
      return this.vehicleIssueIds.length
    },

    empty() {
      return _.blank(this.vehicleIssues)
    },

    allSelectedOnPage() {
      return !this.empty && this.vehicleIssues.every(issue => this.vehicleIssueIds.includes(issue.id))
    },

    hasSelectedOptions() {
      return _.present(this.vehicleIssueIds)
    },

    edit() {
      return _.present(this.serviceId)
    }
  },

  created() {
    // XXX: uso também se dá formulário de criação, onde this.serviceId é null
    if (_.present(this.serviceId)) {
      this.fetchExistingIds()
    }
  },

  methods: {
    // @override Fetch Mixin
    fetchRequest() {
      return this.$newSdk.vehicleIssues.all({ params: this.requestParams })
    },

    async fetchExistingIds() {
      try {
        const { data } = await this.$newSdk.services.vehicleIssues.all({
          serviceId: this.serviceId,
          params:    { partial: "ids" }
        })

        this.vehicleIssueIds = data
      }
      catch (error) {
        this.$err.log(error)
      }
    },

    isIssueSelected(id) {
      return this.vehicleIssueIds.includes(id)
    },

    async fetchAllIds() {
      try {
        const response = await this.$newSdk.vehicleIssues.all({
          params: {
            ...this.requestParams,
            partial: "ids"
          }
        })

        return response.data
      }
      catch (error) {
        this.$err.log(error)
      }
    },

    async selectAll() {
      try {
        const ids = await this.fetchAllIds()

        this.vehicleIssueIds = _.union(ids, this.vehicleIssueIds)
      }
      catch (error) {
        this.$err.log(error)
      }
    },

    selectAllOnPage(value) {
      this.vehicleIssues.forEach(issue => this.selectIssue({ id: issue.id, value }))
    },

    removeAll() {
      this.vehicleIssueIds = []
    },

    selectIssue({ id, value }) {
      if (value && !this.isIssueSelected(id)) {
        this.vehicleIssueIds.push(id)
      }
      else if (!value && this.isIssueSelected(id)) {
        const index = this.vehicleIssueIds.indexOf(id)

        this.vehicleIssueIds.splice(index, 1)
      }

    },

    // @param value Boolean - true/false, indicando se deve filtrar ou não
    filterSelectedIds(value) {
      this.params.ids = value ? this.vehicleIssueIds : []
      this.filterSelected = value

      this.search()
    }
  }
}

</script>

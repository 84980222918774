<style lang="scss" scoped>

.store-service-supports {
  .header {
    margin-top: 32px;
    margin-bottom: 24px;

    .title {
      font-family: $secondary-font;
      font-size: 30px;
      font-weight: 500;
    }
  }

  .cards {
    margin-bottom: -24px;

    .card {
      margin-right: 24px;
      margin-bottom: 24px;
    }
  }
}

</style>


<template lang="pug">

  .store-service-supports
    header.header
      h1.title {{ $t('.title') }}
      span.subtitle {{ $t('.subtitle') }}

    .cards.flex.wrap
      template(v-for="serviceSupport in serviceSupports")
        service-support-card.card(
          :key="`service-support-card-${serviceSupport.serviceType}`",
          :fetching="fetching",
          :service-support="serviceSupport",
          @change="fetch",
          data-testid="serviceSupportCard"
        )

</template>


<script>

// View
import LoadedView from "@/views/loaded-view"

// Mixins
import FetchMixin from "@/mixins/fetch-mixin"

// Models
import models from "@/models"

// Components
import ServiceSupportCard from "./index/service-support-card"

const { ServiceSupport } = models

// XXX: Apenas o serviço do tipo "Revisão" está habilitado
const ENABLED_TYPES = ["Maintenance"]

export default {
  name: "StoreServicesSupports",

  components: {
    ServiceSupportCard
  },

  extends: LoadedView,

  mixins: [FetchMixin],

  data() {
    return {
      i18nScope: "stores.service-supports.index",
      routeName: "storeServiceSupports",
      storeId:   this.$route.params.id,

      // @override Fetch mixin
      resource: ServiceSupport.$enums.serviceType.keys
        .filter(type => ENABLED_TYPES.includes(type))
        .map(type => new ServiceSupport({ serviceType: type }))
    }
  },

  computed: {
    serviceSupports: {
      get() { return this.resource },
      set(value) { this.resource = value }
    }
  },

  methods: {
    // @override LoadedView
    parseRoute() {
      this.storeId = this.$route.params.id
    },

    // @override FetchMixin
    fetchRequest() {
      return this.$newSdk.stores.serviceSupports.all({ storeId: this.storeId })
    },

    // @override FetchMixin
    onFetchSuccess({ data }) {
      const enabledServices = ServiceSupport.$enums.serviceType.keys
        .filter(type => ENABLED_TYPES.includes(type))
        .map(type => ({ serviceType: type }))

      // XXX: Apenas o serviço do tipo "Revisão" está habilitado
      this.resource = _({}).merge(
        _.keyBy(enabledServices, "serviceType"),
        _.keyBy(data, "serviceType")
      )
        .values()
        .value()
        .map(service => new ServiceSupport(service))
    },

    // @override Fetchable mixin
    onFetchError(err) {
      // Dealing with appError from extension view.vue
      if (this.hasViewError(err)) this.appError = err

      this.$err.log(err)
      this.$notifications.error(this.$t(".notifications.fetch.failure"))
    }
  }
}

</script>

import { render, staticRenderFns } from "./search-form.vue?vue&type=template&id=01f644a1&scoped=true&lang=pug&"
import script from "./search-form.vue?vue&type=script&lang=js&"
export * from "./search-form.vue?vue&type=script&lang=js&"
import style0 from "./search-form.vue?vue&type=style&index=0&id=01f644a1&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "01f644a1",
  null
  
)

export default component.exports
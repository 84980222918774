import { render, staticRenderFns } from "./city-select-field.vue?vue&type=template&id=f4d5e996&scoped=true&lang=pug&"
import script from "./city-select-field.vue?vue&type=script&lang=js&"
export * from "./city-select-field.vue?vue&type=script&lang=js&"
import style0 from "./city-select-field.vue?vue&type=style&index=0&id=f4d5e996&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f4d5e996",
  null
  
)

export default component.exports
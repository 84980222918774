import { i18n } from "utils.vue"
import Service from "./service"

class Maintenance extends Service {
  static get attrs() {
    const attrs = super.attrs.filter(attr => attr !== "type")

    return _.uniq([
      ...attrs,
      "distanceLimit",
      "timeLimit",
      "targetExecutionTime",
      "freeOfCharge",
      "externalLimit"
    ])
  }

  static get enums() {
    return {}
  }

  static get constraints() {
    // XXX: Refazendo as restrições sem herdar da classe pai (Service)
    return {
      name: { presence: true, type: "string", length: { maximum: 1000 } },

      distanceOrTimeLimit: {
        presence(value, attrs) {
          // # se a flag external_limit esta preenchida, então não existe validação de presença
          // # para distance ou time_limit
          if (attrs.externalLimit) return false

          return _.blank(attrs.distanceLimit) && _.blank(attrs.timeLimit)
        }
      },

      distanceLimit: {
        presence(value, attrs) { return !attrs.externalLimit && _.blank(attrs.timeLimit) },
        numericality: {
          greaterThan: 0
        }
      },

      timeLimit: {
        presence(value, attrs) { return !attrs.externalLimit && _.blank(attrs.distanceLimit) },
        numericality: {
          greaterThan: 0
        }
      },

      targetExecutionTime: {
        numericality: {
          greaterThan: 0
        }
      }
    }
  }

  static get virtuals() {
    return [
      "type"
    ]
  }

  // virtuals
  get type() {
    return "Maintenance"
  }

  get formattedDistanceLimit() {
    if (_.blank(this.distanceLimit)) return null

    const distance = i18n.l("number", this.distanceLimit, { precision: 0 })

    return i18n.t("models.maintenance.formattedAttributes.distanceLimit", {
      distance
    })
  }

  get formattedTimeLimit() {
    if (_.blank(this.timeLimit)) return null

    return i18n.t("models.maintenance.formattedAttributes.timeLimit", {
      count: this.timeLimit
    })
  }

  get formattedTargetExecutionTime() {
    if (_.blank(this.targetExecutionTime)) return null

    return i18n.t("models.maintenance.formattedAttributes.targetExecutionTime", {
      count: this.targetExecutionTime
    })
  }
}

export default Maintenance

import Base from "./Base"

class Products extends Base {

  list({ params = {} } = {}) {
    return this.action({
      method: "get",
      url:    "/products",
      params
    })
  }

  get({ id, params = {} }) {
    if (_.blank(id)) {
      throw new Error('SDK Products - #get() - "id" is a required prop')
    }

    return this.action({
      method: "get",
      url:    `/products/${id}`,
      params
    })
  }

  destroy({ id, params = {} } = {}) {
    if (_.blank(id)) {
      throw new Error('SDK Products - #destroy() - "id" is a required prop')
    }

    return this.action({
      method: "delete",
      url:    `/products/${id}`,
      params
    })
  }
}

export default Products

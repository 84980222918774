// Ability
import { ability } from "@/modules/ability"

// Routes
import NewServicePackage from "@/views/service-packages/new.vue"
import ServicePackage from "@/views/service-packages/show.vue"
import EditServicePackage from "@/views/service-packages/edit.vue"
import ServicePackages from "@/views/service-packages/index.vue"

export default [
  {
    path:      "/service-packages/new",
    name:      "newServicePackage",
    component: NewServicePackage,
    meta:      {
      auth:        true,
      permissions: [() => ability.can("create", "ServicePackage")]
    }
  },

  {
    path:      "/service-packages/:id",
    name:      "servicePackage",
    component: ServicePackage,
    meta:      {
      auth:        true,
      permissions: [() => ability.can("read", "ServicePackage")]
    }
  },

  {
    path:      "/service-packages/:id/edit",
    name:      "editServicePackage",
    component: EditServicePackage,
    meta:      {
      auth:        true,
      permissions: [() => ability.can("update", "ServicePackage")]
    }
  },

  {
    path:      "/service-packages",
    name:      "servicePackages",
    component: ServicePackages,
    meta:      {
      auth:        true,
      permissions: [() => ability.can("index", "ServicePackage")]
    }
  }
]


import ability from "./ability"

const AbilityPlugin = {
  installed: false,

  install(Vue) {
    if (AbilityPlugin.installed) return
    AbilityPlugin.installed = true

    Vue.$ability = ability
    Vue.$can = ability.can.bind(ability)
    Vue.$cannot = ability.cannot.bind(ability)

    Vue.prototype.$ability = ability
    Vue.prototype.$can = ability.can.bind(ability)
    Vue.prototype.$cannot = ability.cannot.bind(ability)
  }
}

export default AbilityPlugin

<style lang="scss" scoped>

</style>


<template lang="pug">

    router-view(:store="store")

</template>


<script>

export default {
  name: "StoreSuppliersBase",

  props: {
    store: { type: Object, default: () => ({}) }
  }
}

</script>

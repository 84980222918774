import Base from "./base"

class ServiceOrderProduct extends Base {
  static get attrs() {
    return _.uniq([
      ...super.attrs,
      "brand",
      "brandId",
      "freeOfCharge",
      "name",
      "partOrigin",
      "price",
      "quantity",
      "sapId",
      "total",
      "type"
    ])
  }

  static get relations() {
    return {
      brand:        { type: "belongsTo", model: "Brand" },
      orderService: { type: "belongsTo", model: "ServiceOrder" }
    }
  }

  static get enums() {
    return {
      type: {
        MotorOil: "MotorOil",
        Tire:     "Tire",
        Part:     "Part",
        Product:  "Product"
      }
    }
  }
}

export default ServiceOrderProduct

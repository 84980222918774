<style lang="scss" scoped>

.table-row {
  display: grid;
  grid-template-columns: var(--grid-columns);

  &.loading {
    .column {
      .id-tag {
        color: $gray-2;
      }

      &.actions-column {
        ::v-deep .movida-common__app-button {
          color: $gray-2;

          &:hover {
            color: $orange;
          }
        }
      }

      .text,
      .link ::v-deep .app-span {
        color: $gray-2;
      }
    }
  }

  .column {
    padding: 0 12px;
    border-bottom: 1px solid $light-gray-3;
    height: 56px;
    overflow: hidden;

    .id-tag {
      margin-left: 4px;
    }

    .text {
      font-weight: 400;
      font-size: 14px;
    }

    .service-description {
      padding-bottom: 2px;
      font-size: 12px;
      line-height: 12px;
      font-weight: 400;
      color: $gray-2;
    }

    .vehicle-solutions-count {
      margin-left: 4px;
    }
  }
}

</style>


<template lang="pug">

  .table-row(:class="{ loading }", :style="styles")
    .column.selection-column.flex.vertical-center.center.flex
      checkbox-field(
        :name="`service-${service.id}`",
        hide-value-text,
        :disabled="loading",
        :value="value",
        @input="(value) => $emit('input', { id: service.id, value })",
        data-testid="checkbox"
      )

    .column.flex.center.column-direction
      .flex.vertical-center
        id-tag.id-tag(
          :value="service.id",
          data-testid="serviceId"
        )

    .column.flex.center.column-direction.center
      service-type-icon(:type="serviceType")

    .column.flex.center.column-direction
      .flex.vertical-center
        app-span.text(:value="service.name", crop, data-testid="serviceName")
        service-kind-icon(:kind="service.kind")

      app-span.service-description(
        crop,
        :value="service.description"
        v-tooltip.bottom="{ offset: 4, content: service.description }"
        data-testid="serviceDescription"
      )

    .column.actions-column.flex.vertical-center
      i.fal.fa-lightbulb(v-tooltip.bottom="{ offset: 4, content: $t('.tooltip.vehicle-issue-solutions.label') }")
      span.vehicle-solutions-count(data-testid="vehicleIssueSolutionsCount") {{ service.vehicleIssueSolutionsCount }}

    .column.actions-column.flex.vertical-center
      template(v-if="serviceWithCost")
        i.icon.fal.fa-fist-raised(v-tooltip.bottom="{ offset: 4, content: $t('.tooltip.labor-cost-linked.label') }")

</template>


<script>

export default {
  props: {
    service: { type: Object, required: true },
    value:   { type: Boolean, default: false },

    loading: { type: Boolean, default: false },

    columnSizes: { type: String, required: true }
  },

  data() {
    return {
      i18nScope: "suppliers.service-supports.service-vehicle-model-groups.new.services.table-row",

      showPopover: false
    }
  },

  computed: {
    serviceType() {
      return _.get(this.service, "type")
    },

    serviceWithCost() {
      return _.get(this.service, "laborCostLinked")
    },

    styles() {
      return {
        "--grid-columns": this.columnSizes
      }
    }
  }
}

</script>

<template lang="pug">

  app-modal.new(
    :width="1156",
    :heading="$t('.modal.heading')",
    @close="onClose"
  )
    form.form(@submit.prevent="processAndSubmit", method="post")
      form-fields(
        :resource="resource",
        :submitting="submitting",
        :errors.sync="errors"
      )

    template(#footer)
      .footer
        app-button(
          outline,
          theme="gray",
          @click="onClose"
        ) {{ $t('btn.cancel') }}

        app-button(
          :loading="submitting",
          :disabled="!hasService",
          @click="processAndSubmit"
        )
          span {{ $t('.modal.btn.submit.label') }}


</template>


<script>

// Libs
import { v4 as uuid } from "uuid"

// Components
import FormFields from "./components/form-fields.vue"

// Mixins
import FormMixin from "@/mixins/form-mixin"

// Models
import models from "@/models"
const { ServiceOrderDraftService } = models

export default {
  components: {
    FormFields
  },

  mixins: [FormMixin],

  props: {
    serviceOrderId: { type: [String, Number], required: true }
  },

  data() {
    return {
      i18nScope: "tickets.drafts.contents.services.new",

      uuid,

      resource: new ServiceOrderDraftService({
        quantity: 1
      }),

      danglingTarget: null,

      nestedAttributes: {
        targets:     null,
        attachments: null
      }
    }
  },

  computed: {
    hasService() {
      return _.present(this.resource.vehicleIssueSolutionId)
    }
  },

  created() {
    this.initialSerializedAttributes = this.serializeAttributes()
  },

  methods: {
    // Remove o target vazio para realizar validação e comparação
    clearResource() {
      if (_.present(this.resource.targets)) {
        const [lastTarget] = this.resource.targets.slice(-1)
        if (lastTarget.empty) this.danglingTarget = this.resource.targets.pop()
      }
    },

    restoreResource() {
      if (this.danglingTarget) this.resource.targets.push(this.danglingTarget)
      this.danglingTarget = null
    },

    async onClose() {
      const isDirty = this.checkDirty()

      if (!isDirty || await this.askDirtyLeaveConfirmation()) this.$emit("close")
    },

    processAndSubmit() {
      this.clearResource()
      this.submit()
    },

    // @override Form mixin
    afterSubmitStart() {
      this.restoreResource()
    },

    // @override Form mixin
    serializeAttributes() {
      const targetsAttributes = this.resource.targets.map(target => target.$serialize())
      const attachmentsAttributes = this.resource.attachments.map(attachment => attachment.$serialize())

      return {
        ...this.resource.$serialize(),

        targets_attributes:     targetsAttributes,
        attachments_attributes: attachmentsAttributes
      }
    },

    // @override Form mixin
    submitRequest() {
      return this.$newSdk.serviceOrders.drafts.services.create({ serviceOrderId: this.serviceOrderId, params: this.serializeAttributes() })
    },

    // @override Form mixin
    onSubmitSuccess(response) {
      this.$notifications.info(this.$t(".notifications.submit.success"))
      this.$emit("create")
      this.$emit("close")
    }
  }
}

</script>

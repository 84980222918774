<style scoped lang="scss">
.mobiauto-version-row {
  padding: 12px 0;
  min-height: 70px;
}

.mobiauto-version-row + .mobiauto-version-row {
  border-top: 1px solid $light-gray-3;
}

.icon-column {
  padding-left: 24px;
  width: 72px;
}

.content-column {
  display: block;
  min-width: 500px;
  flex-grow: 1;
}

.price-column {
  display: block;
  min-width: 100px;
}

</style>

<template lang="pug">
  .mobiauto-version-row.flex.vertical-center
    .icon-column
      loading-lines(:min="60", :max="60", :height="30")
    .content-column
      loading-lines

    .price-column
      loading-lines(:min="80", :max="80")

</template>

<script>
export default {
  name: "MobiautoVersionRowLoading"
}
</script>

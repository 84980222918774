import { mixin } from "@caiena/model"

import Base from "./base"
import Discardable from "./mixins/discardable"

class MaintenancePackage extends mixin(Base, [Discardable]) {
  static get attrs() {
    return _.uniq([
      ...super.attrs,
      "name",
      "description",

      "productsCount"
    ])
  }

  static get relations() {
    return {
      products: { type: "hasMany", model: "Product" }
    }
  }

  static get constraints() {
    return {
      name:        { type: "string", presence: true, length: { maximum: 1000 } },
      description: { type: "string", length: { maximum: 10000 } },
      products:    { presence: true }
    }
  }

  static get virtuals() {
    return [
      "productIds"
    ]
  }
}

export default MaintenancePackage

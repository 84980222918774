<template lang="pug">
  service-order-section-layout.service-order-info-section(:empty="!hasDescription")
    template(#icon)
      i.fas.fa-user-headset
    template(#title) {{ $t(".title") }}
    template(#empty) {{ $t(".empty") }}
    .whitespace {{ descriptionText }}
</template>

<script>
import ServiceOrderSection from "./service-order-section"

export default {
  extends: ServiceOrderSection,

  computed: {
    descriptionText() {
      const { description, ticketDescription } = this.serviceOrder

      return this.hasDescription
        ? ticketDescription || description
        : this.$t(".empty")
    },

    i18nScope() {
      return "monitoring.service-orders.service-order-info-section"
    },

    hasDescription() {
      return this.serviceOrder.$present("ticketDescription")
        || this.serviceOrder.$present("description")
    }
  }
}
</script>

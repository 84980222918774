<template lang="pug">

  .search-form
    form(
      method="get",
      @submit.prevent="$emit('search')"
    )
      ui-row(
        vertical-align="bottom",
        :columns="12"
      )
        ui-col(:span="9")
          input-field(
            name="q",
            :loading="loading",
            :placeholder="$t('.fields.q.placeholder')",
            v-model="params.q",
            hide-label,
            icon-left="far fa-search"
          )

        ui-col(:span="3")
          app-button(
            type="submit",
            :loading="loading",
            full-width,
            outline
          )
            span {{ $t('btn.search.label') }}
            span(slot="loading") {{ $t('btn.search.loading') }}

</template>


<script>

export default {
  props: {
    loading: { type: Boolean, default: false },
    params:  { type: Object, default: () => ({}) }
  },

  data() {
    return {
      i18nScope: "suppliers.service-supports.service-vehicle-model-groups.new.services.search-form"
    }
  }
}

</script>

// Base
import BaseRequests from "../Base"

// Models
// import models from '@/models'
// const { Ticket } = models

class StoreTicketsRequests extends BaseRequests {
  static get selector() {
    return "stores.tickets"
  }

  find({
    storeId,
    ticketId,
    params = {},
    options = {}
  } = {}) {
    if (_.blank(storeId)) {
      throw new Error('NewSDK stores.tickets - #find() - "storeId" is a required prop')
    }

    if (_.blank(ticketId)) {
      throw new Error('NewSDK stores.tickets - #find() - "ticketId" is a required prop')
    }

    return this.action({
      method:  "get",
      url:     `/stores/${storeId}/tickets/${ticketId}`,
      params,
      options: {
        // XXX: Ticket possui vehicleModel, que possui classes herdeiras. Deve ser corrigido diretamente em model.js
        // model: Ticket,

        ...options
      }
    })
  }

  all({ storeId, params = {}, options = {} }) {
    if (_.blank(storeId)) {
      throw new Error('SDK stores.tickets - #list() - "storeId" is a required prop')
    }

    return this.action({
      method:  "get",
      url:     `/stores/${storeId}/tickets`,
      params,
      options: {
        model: null,

        ...options
      }
    })
  }
}

export default StoreTicketsRequests

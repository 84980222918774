// Base
import BaseRequests from "../Base"

class SupplierServiceOrdersRequests extends BaseRequests {
  static get selector() {
    return "suppliers.serviceOrders"
  }

  all({ supplierId, params = {}, options = {} }) {
    this.verifyData("all", { supplierId })

    return this.action({
      method:  "get",
      url:     `/suppliers/${supplierId}/service_orders`,
      params,
      options: {
        model: null,

        ...options
      }
    })
  }
}

export default SupplierServiceOrdersRequests

<style lang="scss" scoped>

.edit-store-settings {
  .header {
    margin-top: 32px;
    margin-bottom: 24px;

    .title {
      font-family: $secondary-font;
      font-size: 30px;
      font-weight: 500;
    }
  }

  .fields {
    .field {
      + .field {
        margin-top: 16px;
      }

      .checkbox-label {
        margin-right: 4px;
      }
    }
  }

  .footer {
    padding-top: 24px;
    margin-top: 24px;
    border-top: 1px solid $light-gray-3;
  }
}

</style>


<template lang="pug">

  .edit-store-settings
    section.header
      h1.title {{ $t('.title') }}

    template(v-if="fetching")
      loading-lines(:lines="2", role="progressbar")

    template(v-else)
      form.form(@submit.prevent="submit", method="post")
        section.fields
          toggle-field.field(
            name="storeSettings[autoGenerateInternalMaintenance]",
            :value-text="StoreSettings.$tAttr('autoGenerateInternalMaintenance')"
            hide-label,
            v-model="resource.autoGenerateInternalMaintenance",
            :errors="errors.autoGenerateInternalMaintenance",
            data-testid="autoGenerateInternalMaintenanceToggle"
          )
            template(#optionText="{ props: { inputId, disabled, error: hasErrors } }")
              label.checkbox-label(
                :for="inputId",
                :class="{ disabled, error: hasErrors }"
              )
                span {{ StoreSettings.$tAttr('autoGenerateInternalMaintenance') }}
              i.info-icon.far.fa-info-circle(
                v-tooltip.bottom="{offset: '4', content: $t('.fields.autoGenerateInternalMaintenance.tooltip') }"
              )

          toggle-field.field(
            name="storeSettings[autoGenerateExternalMaintenance]",
            :value-text="StoreSettings.$tAttr('autoGenerateExternalMaintenance')"
            hide-label,
            v-model="resource.autoGenerateExternalMaintenance",
            :errors="errors.autoGenerateExternalMaintenance",
            data-testid="autoGenerateExternalMaintenanceToggle",
          )
            template(#optionText="{ props: { inputId, disabled, error: hasErrors } }")
              label.checkbox-label(
                :for="inputId",
                :class="{ disabled, error: hasErrors }"
              )
                span {{ StoreSettings.$tAttr('autoGenerateExternalMaintenance') }}
              i.info-icon.far.fa-info-circle(
                v-tooltip.bottom="{offset: '4', content: $t('.fields.autoGenerateExternalMaintenance.tooltip') }"
              )

        section.footer.flex.space-between.vertical-center
          app-button(
            :to="showRoute",
            theme="gray",
            outline,
            :disabled="submitting"
          ) {{ $t('btn.cancel') }}

          .submit-actions
            app-button.button(
              type="submit",
              :loading="submitting"
            )
              span {{ $t('.btn.submit.label') }}
              span(slot="loading") {{ $t('.btn.submit.loading') }}

</template>


<script>


// Mixins
import { mixins } from "movida-common.vue"
import FetchMixin from "@/mixins/fetch-mixin"

// View
import LoadedView from "@/views/loaded-view"

// Models
import models from "@/models"
const { StoreSettings } = models

const { FormMixin } = mixins

export default {
  name: "EditStoreSettings",

  extends: LoadedView,

  mixins: [FetchMixin, FormMixin],

  data() {
    return {
      i18nScope: "stores.settings.edit",
      routeName: "editStoreSettings",

      storeId: null,

      resource: {},

      // @override Form Mixin
      dirtyable: true,

      StoreSettings
    }
  },

  computed: {
    settings: {
      get() { return this.resource },

      set(value) { this.resource = value }
    },

    showRoute() {
      return { name: "storeSettings", params: { id: this.storeId } }
    }
  },

  methods: {
    parseRoute() {
      this.storeId = this.$params.asInteger(this.$route.params.id)
    },

    // @override Fetch Mixin
    fetchRequest() {
      return this.$newSdk.stores.settings.find({ storeId: this.storeId })
    },

    // @override Fetch Mixin
    onFetchSuccess({ data, headers }) {
      this.resource = data
      this.initialSerializedAttributes = this.serializeAttributes()
    },

    // @override Form mixin
    serializeAttributes() {
      return this.resource.$serialize()
    },

    // @override Form mixin
    submitRequest() {
      return this.$newSdk.stores.settings.update({ storeId: this.storeId, params: this.serializeAttributes() })
    },

    // @override Form mixin
    submitSuccess() {
      this.$notifications.info(this.$t(".notifications.submit.success"))

      this.$router.push(this.showRoute)
    },

    // @override Form mixin
    submitError(err) {
      this.$err.log(err)
      this.$notifications.error(this.$t(".notifications.submit.error"))
    }
  }
}

</script>

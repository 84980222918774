<style lang="scss" scoped>

.count {
  margin-bottom: 24px;

  .icon-container {
    width: 16px;
    height: 16px;
    margin-right: 4px;

    .icon {
      color: $gray-3;
      font-size: 14px;
    }
  }
}

.assignee.open {
  margin-bottom: 275px;
}

.toggle {
  margin-top: 24px;

  .icon-container {
    width: 24px;
    height: 24px;

    .icon {
      font-size: 14px;
    }
  }
}

</style>


<template lang="pug">

  app-modal.assignment(
    footer,
    :confirm-label="$t('.btn.confirm.label')",
    :confirm-label-loading="$t('.btn.confirm.label')",
    :heading="$t('.heading')",
    :loading="submitting",
    :width="566",
    @close="onClose",
    @confirm="submit"
  )
    .body
      .count.flex.vertical-center
        .icon-container.flex.center.vertical-center
          i.icon.fas.fa-check

        span {{ $t('.count', { count: selected.length }) }}

      assistant-select-field.assignee(
        v-model="resource.assigneeRid",
        mandatory,
        name="assigneeRid",
        :class="{ open: selectFieldOpen }",
        :disabled="submitting || removeAssignee",
        :errors="errors.assigneeRid",
        :label="$t('.fields.assignee.label')",
        :placeholder="$t('.fields.assignee.placeholder')",
        @close="selectFieldOpen = false",
        @open="selectFieldOpen = true"
      )

      extended-toggle-field.toggle(
        v-model="removeAssignee",
        name="removeAssignee",
        :disabled="submitting",
      )
        template(#label)
          .icon-container.flex.center.vertical-center
            i.icon.fas.fa-user-large-slash
          app-span(crop, :value="$t('.fields.remove-assignee.label')")

</template>


<script>

// Mixins
import FormMixin from "@/mixins/form-mixin"

// Components
import AssistantSelectField from "./assistant-select-field.vue"

// Models
import Base from "@/models/base"


class AssignmentBatch extends Base {
  static get attrs() {
    return [
      "assigneeRid",
      "serviceOrderRids"
    ]
  }

  static get constraints() {
    return {
      // XXX: não dá pra validar, pois há um switch que troca o valor para "none" (para desatribuir)
      // assigneeRid:      { presence: true },
      serviceOrderRids: { presence: true }
    }
  }

}


export default {
  components: {
    AssistantSelectField
  },

  mixins: [FormMixin],

  props: {
    selected: { type: Array, required: true }
  },

  data() {
    return {
      i18nScope: "monitoring.assignment-modal",

      selectFieldOpen: false,

      resource: new AssignmentBatch({
        serviceOrderRids: this.selected
      }),

      removeAssignee: false
    }
  },

  created() {
    this.initialSerializedAttributes = this.serializeAttributes()
  },

  methods: {
    // @override Form mixin
    serializeAttributes() {
      const attrs = this.resource.$serialize()

      // XXX: sobrecarregando para "none", valor especial que remove atribuições.
      // atenção pois usa snake_case, uma vez que os dados já estão serializados!
      if (this.removeAssignee) attrs.assignee_rid = "none"

      return attrs
    },

    async onClose() {
      const isDirty = this.checkDirty()

      if (!isDirty || await this.askDirtyLeaveConfirmation()) this.$emit("close")
    },

    // @override Form mixin
    submitRequest() {
      let params = this.serializeAttributes()

      return this.$newSdk.monitoring.serviceOrderAssignments
        .create({ params })
    },

    // @override Form mixin
    afterSubmitSuccess() {
      this.$emit("change")
      this.$emit("close")
    }
  }
}

</script>

<style scoped lang="scss">

.login {
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  max-width: unset!important;
}

.card {
  padding: 32px;
  background: $white;
  width: 400px;
  border-radius: 4px;
  box-shadow: 0px 12px 24px rgba(0, 0, 0, 0.15);
  position: relative;

  display: inline-flex;
  flex-direction: column;
  align-items: center;

  .stamp {
    position: absolute;
    top: -80px;
    left: 200px;
  }
}

.logo {
  top: -95px;
  width: 112px;
}

.welcome {
  font-family: $secondary-font;
  font-weight: 500;
  font-size: 38px;
  color: $dark-gray;
  top: -43px;
  text-align: center;
  width: 500px;
}

.form {
  width: 100%;
}

.username {
  margin-bottom: 16px;
}

.icon {
  margin-right: 8px;
}

.reset {
  margin-top: 16px;
  font-family: $secondary-font;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: $gray-3;
}

.arrow-up {
  $border-length: 19px;
  top: 0;
  width: 0;
  height: 0;
  border-left: $border-length solid transparent;
  border-right: $border-length solid transparent;
  border-bottom: $border-length solid $white;
}

.vertical-absolute {
  position: absolute;
  transform: translateY(-100%);
}

.error-message {
  font-size: 14px;
  color: $error;
  margin-top: 20px;
  font-weight: normal;
  text-align: center;
  background: #ffefef;
  padding: 8px;
  border-radius: 4px;
}

.fields {
  margin-bottom: 40px;

  &.error {
    margin-bottom: 20px;
  }
}

</style>


<template lang="pug">

  .login
    .card
      .arrow-up.vertical-absolute
      app-stage-stamp.stamp
      img.logo.vertical-absolute(:src="logo")
      h1.welcome.vertical-absolute {{ $t('.welcome') }}
      form.form(
        ref="form",
        method="post",
        action="/login",
        @submit.prevent="verifyRecaptchaAndSubmit"
      )
        .fields(:class="{ error }")
          input-field.username(
            autocomplete="username",
            id="username",
            name="login[username]",
            v-model="resource.username",
            :disabled="submitting || fetching",
            :error="hasError",
            :errors="errors.username",
            :placeholder="$t('.fields.username.placeholder')"
          )

          input-field.password(
            autocomplete="current-password",
            id="password",
            name="login[password]",
            :type="passwordType",
            v-model="resource.password",
            :disabled="submitting || fetching",
            :error="hasError",
            :errors="errors.password",
            :icon-right="passwordIcon",
            clickable-icon,
            @right-icon-click="togglePassword",
            :placeholder="$t('.fields.password.placeholder')"
          )

          span.error-message.flex(v-if="hasError")
            .icon.error
              i.fas.fa-times-circle
            | {{ error }}
        app-button.button(type="submit", full-width, :loading="submitting || fetching")
          i.far.fa-sign-in.icon
          span {{ $t('.btn.submit') }}

</template>


<script>

// Libs
import { events, conf } from "utils.vue"

// Mixins
import FetchMixin from "@/mixins/fetch-mixin"
import FormMixin from "@/mixins/form-mixin"

// Assets
import logo from "@/assets/images/logo.svg"

// Extends
import View from "@/views/view"

// Models
import Base from "@/models/base"

const RECAPTCHA_SITE_KEY = _.get(conf, "recaptcha.siteKey")

export class Login extends Base {
  static get attrs() {
    return ["username", "password", "recaptchaToken"]
  }

  static get constraints() {
    return {
      username: { presence: true },
      password: { presence: true },

      recaptchaToken(value, attrs, attrName, options, constraints) {
        if (_.blank(RECAPTCHA_SITE_KEY)) return {}

        return { presence: true }
      }
    }
  }
}

export default {
  extends: View,

  mixins: [FormMixin, FetchMixin],

  data() {
    return {
      i18nScope: "login",

      resource: new Login(),
      error:    "",

      passwordType: "password",
      logo,

      retries: 0,

      guardFormOnLeave: false,

      authData:  null,
      autofetch: false
    }
  },

  computed: {
    afterLoginRoute() {
      let redirect = _.get(this.$route, "query.redirect")
      return _.present(redirect) ? { path: redirect } : { path: "/" }
    },

    passwordIcon() {
      return this.passwordType === "password" ? "fal fa-eye-slash" : "fal fa-eye"
    },

    hasError() {
      return _.present(this.error)
    },

    recaptchaEnabled() {
      // XXX: não dá pra usar _.present(function)!
      return this.$recaptchaLoaded !== undefined
    }
  },

  created() {
    if (this.recaptchaEnabled) this.showRecaptchaBadge()
  },

  beforeDestroy() {
    events.$off(["ability:build"])
  },

  destroyed() {
    if (this.recaptchaEnabled) this.hideRecaptchaBadge()
  },

  methods: {
    async showRecaptchaBadge() {
      await this.$recaptchaLoaded()
      if (this.$recaptchaInstance) this.$recaptchaInstance.showBadge()
    },

    async hideRecaptchaBadge() {
      await this.$recaptchaLoaded()
      if (this.$recaptchaInstance) this.$recaptchaInstance.hideBadge()
    },

    togglePassword() {
      this.passwordType = this.passwordType === "password" ? "text" : "password"
    },

    async verifyRecaptchaAndSubmit() {
      this.error = ""

      if (this.recaptchaEnabled) {
        try {
          await this.$recaptchaLoaded()
          this.resource.recaptchaToken = await this.$recaptcha("login")
        }
        catch {
          const message = this.$t(".notifications.submit.errors.server")
          this.error = message
          this.$notifications.error(message)
          return
        }
      }

      this.submit()
    },

    submitRequest() {
      return this.$sdk.auth.login({ params: _.snakeizeKeys(this.resource.$serialize()) })
    },

    onSubmitSuccess(response) {
      const { data } = response

      this.authData = {
        token:     data.access_token,
        scope:     data.scope,
        expiresAt: data.expires_at
      }

      this.$notifications.clear()

      this.fetch()
    },

    setErrorMessage(err) {
      let errorKey = _.get(err, "original.originalError.response.data.error")

      let label

      switch (errorKey) {
        case "invalid_recaptcha":
          label = "server"; break

        case "invalid_client":
          label = "client.authorization"; break

        default: break
      }

      if (_.blank(label)) {
        if (err.statusCode >= 500) label = "server"

        else {
          switch (err.statusCode) {
            case 401:
            case 403:
              label = "client.authorization"; break

            default:
              label = "client.authentication"; break
          }
        }
      }

      this.error = this.$t(`.notifications.submit.errors.${label}`)
    },

    onSubmitError(err) {
      this.$err.log(err)

      this.setErrorMessage(err)

      this.$notifications.error(this.error)
    },

    // @override Fetch mixin
    fetchRequest() {
      return this.$newSdk.user.me({ token: this.authData.token })
    },

    // @override Fetch mixin
    async onFetchError(err) {
      if (this.retries > 2) {
        this.$err.log(err)
        this.$notifications.error(this.$t(".notifications.fetch.failure"))

        this.retries = 0
      }

      else {
        this.retries += 1
        await this.$nextTick()

        this.fetch()
      }
    },

    // @override Fetch mixin
    onFetchSuccess({ data }) {
      // aguarda a construção das permissões
      events.$once("ability:build", async () => {
        this.$router.replace(this.afterLoginRoute)
      })

      this.$auth.login({
        ...this.authData,

        user: data
      })
    }
  }
}

</script>

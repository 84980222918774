import Base from "./Base"

class Symptoms extends Base {

  create({ params = {} } = {}) {
    return this.action({
      method: "post",
      url:    "/symptoms",
      params
    })
  }

  get({ id, params = {} }) {
    if (_.blank(id)) {
      throw new Error('SDK Symptoms - #get() - "id" is a required prop')
    }

    return this.action({
      method: "get",
      url:    `/symptoms/${id}`,
      params
    })
  }

  list({ params = {} } = {}) {
    return this.action({
      method: "get",
      url:    "/symptoms",
      params
    })
  }
}

export default Symptoms

<style lang="scss" scoped>

.edit-services {
  .section-header {
    margin-bottom: 16px;

    .title {
      font-family: $secondary-font;
      font-weight: 500;
      font-size: 20px;
    }

    .subtitle {
      display: block;
      margin-top: 4px;
    }
  }

  .table {
    margin-top: 16px;

    .count {
      margin-bottom: 8px;
      text-align: right;
    }

    .no-services {
      padding: 24px;
      border: 1px solid $light-gray-3;
      border-radius: 4px;
      color: $gray-3;
      font-size: 14px;

      .icon {
        user-select: none;
        margin-bottom: 8px;
        height: 78px;
        width: 89px;
      }
    }
  }
}

</style>


<template lang="pug">

  section.edit-services
    .section-header
      h2.title {{ $t('.title') }}
      span.subtitle {{ $t('.subtitle') }}

    service-multiple-select-field(
      name="servicePackage[services]",
      v-model="services",
      :placeholder="$t('.fields.services.placeholder')",
      :loading="fetching",
      :disabled="submitting",
      :errors="serviceErrors",
      @toggle-remove="onToggleRemove"
    )

    .table
      .count(v-if="hasServices && !fetching")
        span {{ $t('.count', { count: servicesCount }) }}

      template(v-if="!hasServices && !fetching")
        .no-services.flex.center.column-direction.vertical-center
          //- img.icon(:src="servicePlaceholderIcon")
          span {{ $t('.empty') }}

      app-list(
        v-else,
        :loading="fetching"
      )
        service-row(
          v-for="service in resource.services",
          edit,
          :key="`service-row-${service.id}`",
          :service="service",
          :disabled="submitting",
          :initial-ids="initialIds",
          @remove="onRemoveService",
          @undo-remove="onToggleRemove",
        )

</template>


<script>

// Components
import ServiceRow from "../components/service-row"
import ServiceMultipleSelectField from "../components/service-multiple-select-field"

// Icons
// import servicePlaceholderIcon from '@/assets/images/placeholder-icons/service.svg'

// Models
import models from "@/models"
const { Service } = models

export default {
  name: "EditServicePackageServices",

  components: {
    ServiceRow,
    ServiceMultipleSelectField
  },

  props: {
    fetching:   { type: Boolean, default: false },
    submitting: { type: Boolean, default: false },
    errors:     { type: Object, default: () => ({}) },
    resource:   { type: Object, default: () => ({}) },
    initialIds: { type: Array, default: () => [] }
  },

  data() {
    return {
      i18nScope: "service-packages.services.edit"

      // servicePlaceholderIcon
    }
  },

  computed: {
    serviceErrors() {
      return [
        ...this.errors.serviceIds || [],
        ...this.errors.productOrService || []
      ]
    },

    hasServices() {
      return _.present(this.resource.services)
    },

    servicesCount() {
      if (!this.hasServices) return 0
      return this.resource.services.length
    },

    services: {
      get() {
        if (_.blank(this.resource)) return []

        return this.resource.services.map(service => ({
          value: service.id,
          label: service.serviceName,
          data:  new Service(service)
        }))
      },

      set(services) {
        // eslint-disable-next-line vue/no-mutating-props
        this.resource.services = services.map(service => service.data)
      }
    }
  },

  methods: {
    isPersisted(id) {
      return this.initialIds.includes(id)
    },

    onToggleRemove(serviceToToggle) {
      if (!this.isPersisted(serviceToToggle.id)) {
        this.onRemoveService(serviceToToggle)
        return
      }

      const services = [...this.resource.services]
      const serviceIndex = services.findIndex(service => service.id === serviceToToggle.id)

      if (serviceIndex < 0) return

      const service = services[serviceIndex]

      if (service.$markedForDestruction) service.$unmarkForDestruction()
      else service.$markForDestruction()

      // eslint-disable-next-line vue/no-mutating-props
      this.resource.services = services
    },

    onRemoveService(serviceToRemove) {
      const services = [...this.resource.services]
      const serviceIndex = services.findIndex(service => service.id === serviceToRemove.id)

      if (serviceIndex < 0) return

      if (this.isPersisted(serviceToRemove.id)) services[serviceIndex].$markForDestruction()
      else services.splice(serviceIndex, 1)

      // eslint-disable-next-line vue/no-mutating-props
      this.resource.services = services
    }
  }
}

</script>

// Base
import BaseRequests from "./Base"

// Models
import models from "@/models"
const { MaintenancePackage } = models

class MaintenancePackagesRequests extends BaseRequests {
  static get selector() {
    return "maintenancePackages"
  }

  find({ maintenancePackageId, params = {}, options = {} } = {}) {
    if (_.blank(maintenancePackageId)) {
      throw new Error('NewSDK maintenancePackages - #find() - "maintenancePackageId" is a required prop')
    }

    return this.action({
      method:  "get",
      url:     `/maintenance_packages/${maintenancePackageId}`,
      params,
      options: {
        model: MaintenancePackage,

        ...options
      }
    })
  }

  list({ params = {}, options = {} } = {}) {
    return this.action({
      method:  "get",
      url:     "/maintenance_packages",
      params,
      options: {
        model: MaintenancePackage,

        ...options
      }
    })
  }

  enable({ maintenancePackageId, params = {}, options = {} } = {}) {
    if (_.blank(maintenancePackageId)) {
      throw new Error('NewSDK maintenancePackages - #enable() - "maintenancePackageId" is a required prop')
    }

    return this.action({
      method: "delete",
      url:    `/maintenance_packages/${maintenancePackageId}/discard`,
      params,
      options
    })
  }

  disable({ maintenancePackageId, params = {}, options = {} } = {}) {
    if (_.blank(maintenancePackageId)) {
      throw new Error('NewSDK maintenancePackages - #disable() - "maintenancePackageId" is a required prop')
    }

    return this.action({
      method: "post",
      url:    `/maintenance_packages/${maintenancePackageId}/discard`,
      params,
      options
    })
  }
}

export default MaintenancePackagesRequests

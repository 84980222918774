<style lang="scss" scoped>

.service-order-execution-modal {
  .section {
    padding: 16px 24px;
  }

  .field {
    margin-bottom: 16px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .description {
    ::v-deep .input-inner {
      height: 110px;
    }
  }
  .text-form-campo{
    ::v-deep .input-inner {
      height: 110px;
    }
  }
}

.includes-vehicle-withdrawal {
  ::v-deep .checkbox-label {
    font-weight: 400!important;
    line-height: 1.5;
  }
}

.border {
  border-top: 1px solid $light-gray-3;
}

</style>


<template lang="pug">

  app-modal(
    footer,
    :confirmLabel="$t('.btn.confirm.label')",
    :confirmLabelLoading="$t('.btn.confirm.label')",
    :heading="$t('.heading')",
    :loading="submitting",
    :width="566",
    @close="onClose",
    @confirm="submit"
  )
    .service-order-execution-modal
      .section
        service-order-info(
          :service-order="serviceOrder",
          :vehicle="serviceOrder.vehicle",
          :supplier="serviceOrder.supplier",
          :customer="serviceOrder.customer",
          :branch="serviceOrder.branch"
        )

      form(ref="form", method="post", @submit.prevent="submit")
        .section.border
          datetime-field.field.at(
            mandatory,
            :max-date="now",
            v-model="execution.at",
            name="serviceOrderExecution[at]",
            :disabled="submitting",
            :errors="errors.at"
          )

          textarea-field.field.description(
            v-model="execution.description",
            name="serviceOrderExecution[description]",
            :disabled="submitting",
            :errors="errors.description",
            :placeholder="$t('.fields.description.placeholder')"
          )
          
        .section.border

          checkbox-field.field.includes-vehicle-withdrawal(
            v-model="includesVehicleWithdrawal",
            name="includesVehicleWithdrawal",
            hide-label,
            :value-text="$t('.fields.includesVehicleWithdrawal.text')",

            :loading="submitting",
            :disabled="submitting",

            :errors="errors.laborCostLinked"
          )

          checkbox-field.field.finalizeAllServiceOrders(
            v-model="finalizeAllServiceOrders",
            name="finalizeAllServiceOrders",
            hide-label,
            :value-text="$t('.fields.finalizeAllServiceOrders.text')",
            :loading="submitting",
            :disabled="submitting",
            :errors="errors.laborCostLinked"
          )

          
          template(v-if="includesVehicleWithdrawal")
            vehicle-withdrawal-form-fields(
              :errors="errors.vehicleWithdrawal"
              :fetching="false",
              :resource="vehicleWithdrawal",
              :submitting="submitting",
              :terminate-all.sync="terminateAll"
            )

           


</template>


<script>

// Mixins
import FormMixin from "@/mixins/form-mixin"

// Components
import ServiceOrderInfo            from "@/views/monitoring/_components/service-order-info.vue"
import VehicleWithdrawalFormFields from "@/views/monitoring/vehicle-withdrawals/_components/form-fields.vue"

import $validate from "@/lib/validate"

// Models
import models from "@/models"
// eslint-disable-next-line no-unused-vars
const { MovidaServiceOrder, ServiceOrderExecution } = models


export default {
  components: {
    ServiceOrderInfo,
    VehicleWithdrawalFormFields,
  },

  mixins: [FormMixin],

  props: {
    serviceOrder: { type: MovidaServiceOrder, required: true }
  },

  data() {
    if(!this.serviceOrder.vehicleStopped){
      this.$notifications.error("Preencha a etapa de parada na oficina")
      return false
    }
    return {
      i18nScope: "monitoring.service-order-execution-modal",

      now: moment().toISOString(),

      resource: new ServiceOrderExecution({
        // XXX: usando a data de "previsão de conclusão" como default:
        // ref: https://github.com/caiena/movida-gmf/issues/2012
        at: this.serviceOrder.estimatedCompletedAt
      }),

      includesVehicleWithdrawal: false,
      finalizeAllServiceOrders: false,
      vehicleWithdrawal:         null,
      terminateAll:              false
    }
  },
  

  computed: {
    execution: {
      get()      { return this.resource },
      set(value) { this.resource = value }
    }
  },

  watch: {
   
    finalizeAllServiceOrders(newValue){

      if(newValue){
        this.finalizeAllServiceOrders = true
      }else{
        this.finalizeAllServiceOrders = false
      }

    },
    includesVehicleWithdrawal(newValue, oldValue) {
      if (newValue) {
        this.vehicleWithdrawal = new models.Events.ServiceOrders.VehicleWithdrawal({
          // XXX: usando a data de "previsão de conclusão" como default:
          // ref: https://github.com/caiena/movida-gmf/issues/2012
          at: this.serviceOrder.estimatedCompletedAt
        })
      }
      else {
        this.vehicleWithdrawal = null
      }
    }
  },



  created() {
    this.initialSerializedAttributes = this.serializeAttributes()
  },

  methods: {
    // @override Form mixin
    async validate() {
      let errors = await $validate(this.execution /* , nestedMap */)

      // extra validations
      if (_.present(this.vehicleWithdrawal)) {
        let vehicleWithdrawalErrors = await $validate(this.vehicleWithdrawal /* , nestedMap */)

        if (_.present(vehicleWithdrawalErrors)) {
          errors.vehicleWithdrawal = vehicleWithdrawalErrors
        }
      }

      return errors
    },

    // @override Form mixin
    serializeAttributes() {
      // XXX: juntando VehicleWithdrawal, se houver
      let attributes = this.resource.$serialize()

      if(this.finalizeAllServiceOrders){
        attributes.finalizeAllServiceOrders_atributes = true
      }
  
      if (this.includesVehicleWithdrawal) {
        // XXX: aqui tem que ser snake_case, pois quem converte camelCase => snake_case é o $serialize()
        attributes.vehicle_withdrawal_attributes = this.vehicleWithdrawal.$serialize()
        attributes.vehicle_withdrawal_attributes.terminate_all = this.terminateAll
      }

      return attributes
    },

    // @override Form mixin
    submitRequest() {
      let params = this.serializeAttributes()

      return this.$newSdk.monitoring.serviceOrders.execution
        .create({
          serviceOrderId: this.serviceOrder.rid,
          params
        })
    },

    // @override Form mixin
    onSubmitSuccess(response) {
      // XXX: usando id do Vetor (remoteId) para ficar coerente com a listagem
      let successMessage = this.$t(".notifications.submit.success", { id: this.serviceOrder.remoteId })
      this.$notifications.info(successMessage)

      this.$emit("update")
      this.$emit("close")
    },

    // @override Form mixin
    afterSubmitSuccess() {
      this.$emit("update")
      this.$emit("close")
    },

    // XXX: como não é rota/view, precisa implementar manualmente esse check
    async onClose() {
      const isDirty = this.checkDirty()

      if (!isDirty || await this.askDirtyLeaveConfirmation()) this.$emit("close")
    }
  }
}

</script>

<script>

import AttachmentPreview from "@/components/attachments/attachment-preview.vue"

export default {
  extends: AttachmentPreview,

  props: {
    serviceOrderServiceId: { type: [String, Number], required: true }
  },

  methods: {
    // @override Form mixin
    submitRequest() {
      return this.$newSdk.serviceOrders.drafts.services.attachments.update({
        serviceOrderServiceId: this.serviceOrderServiceId,
        attachmentId:          this.attachment.id,
        params:                this.serializeAttributes()
      })
    }
  }
}

</script>

// Converte estrutura de erros de model.js (que utiliza validate.js),
// para estrutura de dados utilizada para exibição de erros
function parseModelJsErrors(originalErrors) {
  return _.reduce(
    originalErrors,
    (errors, attrErrors, attrName) => {
      errors[attrName] = attrErrors.map(err => {
        if (typeof err.message === "string") return err.message

        if (Array.isArray(err.message)) return err.message[0]

        return err.message[Object.keys(err.message)[0]]
      })
      return errors
    },
    {}
  )
}

export { parseModelJsErrors }

<style lang="scss" scoped>

.services {
  .section-header {
    margin-bottom: 16px;

    .title {
      font-family: $secondary-font;
      font-weight: 500;
      font-size: 20px;
    }

    .subtitle {
      display: block;
      margin-top: 4px;
    }
  }

  .table {
    .count {
      margin-bottom: 8px;
      text-align: right;
    }

    .no-services {
      padding: 24px;
      border: 1px solid $light-gray-3;
      border-radius: 4px;
      color: $gray-3;
      font-size: 14px;

      .icon {
        user-select: none;
        margin-bottom: 8px;
        height: 78px;
        width: 89px;
      }
    }
  }
}

</style>


<template lang="pug">

  .services
    .section-header
      h2.title {{ $t('.title') }}

    .table
      .count(v-if="hasServices && !fetching")
        span {{ $t('.count', { count: servicesCount }) }}

      template(v-if="!hasServices && !fetching")
        .no-services.flex.center.column-direction.vertical-center
          //- img.icon(:src="servicePlaceholderIcon")
          span {{ $t('.empty') }}

      app-list(
        v-else,
        :loading="fetching"
      )
        service-row(
          v-for="service in services",
          :key="`service-row-${service.id}`",
          :service="service"
        )

</template>


<script>

// Components
import ServiceRow from "../components/service-row"

// Icons
// import servicePlaceholderIcon from '@/assets/images/placeholder-icons/service.svg'

export default {
  name: "ServicePackageServices",

  components: {
    ServiceRow
  },

  props: {
    fetching: { type: Boolean, default: false },
    services: { type: Array, default: () => [] }
  },

  data() {
    return {
      i18nScope: "service-packages.services.show"

      // servicePlaceholderIcon
    }
  },

  computed: {
    hasServices() {
      return _.present(this.services)
    },

    servicesCount() {
      if (!this.hasServices) return 0
      return this.services.length
    }
  }
}

</script>

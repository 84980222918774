import Base from "./Base"

class ServicePackages extends Base {
  create({ params = {} } = {}) {
    return this.action({
      method: "post",
      url:    "/service_packages",
      params
    })
  }

  list({ params = {} } = {}) {
    return this.action({
      method: "get",
      url:    "/service_packages",
      params
    })
  }

  get({ id, params = {} }) {
    if (_.blank(id)) {
      throw new Error('SDK ServicePackages - #get() - "id" is a required prop')
    }

    return this.action({
      method: "get",
      url:    `/service_packages/${id}`,
      params
    })
  }

  destroy({ id }) {
    if (_.blank(id)) {
      throw new Error('SDK ServicePackages - #destroy() - "id" is a required prop')
    }

    return this.action({
      method: "delete",
      url:    `/service_packages/${id}`
    })
  }

  update({ id, params = {} } = {}) {
    if (_.blank(id)) {
      throw new Error('SDK ServicePackages - #update() - "id" is a required prop')
    }

    return this.action({
      method: "patch",
      url:    `/service_packages/${id}`,
      params
    })
  }
}

export default ServicePackages

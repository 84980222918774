import Base from "./Base"

class Maintenances extends Base {

  create({ params = {} } = {}) {
    return this.action({
      method: "post",
      url:    "/maintenances",
      params
    })
  }

  list({ params = {} } = {}) {
    return this.action({
      method: "get",
      url:    "/maintenances",
      params
    })
  }

  get({ id, params = {} }) {
    if (_.blank(id)) {
      throw new Error('SDK Maintenances - #get() - "id" is a required prop')
    }

    return this.action({
      method: "get",
      url:    `/maintenances/${id}`,
      params
    })
  }
}

export default Maintenances

import Base from "@/models/base"


class VehicleWithdrawal extends Base {
  static get modelName() { return "Events.ServiceOrders.VehicleWithdrawal" }

  static get attrs() {
    return _.uniq([
      ...super.attrs,

      "at",
      "description",
      "authorId",
      "authorName"
    ])
  }

  static get constraints() {
    return {
      at(value, attrs, attrName, options, constraints) {
        // NOTE: attrs is the model instance! You can call methods too.
        return {
          presence: true,
          datetime: {
            latest: moment().toISOString(),
            message: "não pode ser no futuro"  // TODO: i18n
          }
        }
      },
      description: { presence: true }
    }
  }

}


export default VehicleWithdrawal

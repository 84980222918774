const categories = [
  // ref https://github.com/caiena/movida-gmf-api.rb/blob/dev/config/initializers/active_storage.rb#L39-L104

  {
    name:  "text",
    types: [
      { mimetype: "text/plain", extension: ".txt" },
      { mimetype: "text/csv", extension: ".csv" },
      { mimetype: "application/rtf", extension: ".rtf" }
    ]
  },
  {
    name:  "pdf",
    types: [{ mimetype: "application/pdf", extension: ".pdf" }]
  },
  {
    name:  "doc",
    types: [
      { mimetype: "application/msword", extension: ".doc" },
      { mimetype: "application/vnd.openxmlformats-officedocument.wordprocessingml.document", extension: ".docx" },
      { mimetype: "application/x-abiword", extension: ".abw" }
    ]
  },
  {
    name:  "spreadsheet",
    types: [
      { mimetype: "application/vnd.ms-excel", extension: ".xls" },
      { mimetype: "application/vnd.oasis.opendocument.spreadsheet", extension: ".ods" },
      { mimetype: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", extension: ".xlsx" }
    ]
  },
  {
    name:  "email",
    types: [
      { mimetype: "message/rfc822", extension: ".eml" },
      { mimetype: "application/vnd.sealed.eml", extension: ".seml" },
      { mimetype: "application/vnd.ms-outlook", extension: ".msg" }
    ]
  },
  {
    name:  "image",
    types: [
      { mimetype: "image/bmp", extension: ".bmp" },
      { mimetype: "image/gif", extension: ".gif" },
      { mimetype: "image/jpeg", extension: ".jpg" },
      { mimetype: "image/jpeg", extension: ".jpeg" },
      { mimetype: "image/png", extension: ".png" },
      { mimetype: "image/tiff", extension: ".tiff" },
      { mimetype: "image/tiff", extension: ".tif" },
      { mimetype: "image/webp", extension: ".webp" }
    ]
  },
  {
    name:  "video",
    types: [
      { mimetype: "video/3gpp", extension: ".3gp" },
      { mimetype: "video/3gpp2", extension: ".3g2" },
      { mimetype: "video/mp4", extension: ".mp4" },
      { mimetype: "video/mpeg", extension: ".mpg" },
      { mimetype: "video/mpeg", extension: ".mpeg" },
      { mimetype: "video/ogg", extension: ".ogv" },
      { mimetype: "video/quicktime", extension: ".mov" },
      { mimetype: "video/webm", extension: ".webm" },
      { mimetype: "video/x-flv", extension: ".flv" },
      { mimetype: "video/x-ms-wmv", extension: ".wmv" },
      { mimetype: "video/x-msvideo", extension: ".avi" }
    ]
  },
  {
    name:  "audio",
    types: [
      { mimetype: "audio/mpeg", extension: ".mp3" },
      { mimetype: "audio/x-wav", extension: ".wav" },
      { mimetype: "audio/ogg", extension: ".ogg" },
      { mimetype: "audio/opus", extension: ".opus" },
      { mimetype: "audio/mp4", extension: ".m4a" },
      { mimetype: "audio/aac", extension: ".aac" },
      { mimetype: "audio/x-aac", extension: ".aac" }
    ]
  },
  {
    name:  "compressed",
    types: [
      { mimetype: "application/zip", extension: ".zip" },
      { mimetype: "application/x-zip-compressed", extension: ".zip" },
      { mimetype: "application/rar", extension: ".rar" },
      { mimetype: "application/x-rar-compressed", extension: ".rar" },
      { mimetype: "application/x-tar", extension: ".tar" },
      { mimetype: "application/gzip", extension: ".gz" },
      { mimetype: "application/x-gzip", extension: ".gz" },
      { mimetype: "application/x-7z-compressed", extension: ".7z" }
    ]
  },
  {
    name:  "file",
    types: [{ mimetype: "image/svg+xml", extension: ".svg" }]
  }
]

const mimetypes = _.uniq(categories.map(({ types }) => types.map(({ mimetype }) => mimetype)).flat())

const extensions = _.uniq(categories.map(({ types }) => types.map(({ extension }) => extension)).flat())

function categoryNameFor(mimetype) {
  const category = categories.find(({ types }) => types.find(type => type.mimetype === mimetype))

  if (_.blank(category)) return "file"
  return category.name
}

function getCategoryNameForExtension(extension) {
  const _extension = extension.toLowerCase()

  const category = categories.find(
    ({ types }) => types.some((type) => type.extension.endsWith(_extension))
  )

  return category?.name || "file"
}

function getMimeTypeForExtension(extension) {
  const _extension = extension.toLowerCase()

  const type = categories
    .reduce((previous, current) => [...previous, ...current.types], [])
    .find(({ extension: ext }) => ext.endsWith(_extension))

  return type?.mimetype || "application/octet-stream"
}

export {
  categories,
  mimetypes,
  extensions,

  categoryNameFor,
  getCategoryNameForExtension,
  getMimeTypeForExtension
}

<style scoped lang="scss">

.external-maintenance {
  .section {
    padding: 24px 0;

    &:first-of-type {
      padding-top: 0;
    }

    &:last-of-type {
      padding-bottom: 0;
    }

    & + .section {
      border-top: 1px solid $light-gray-3;
    }

    .breadcrumbs {
      margin-bottom: 32px;
    }

    .name {
      margin-bottom: 4px;

      .state-icon {
        margin-right: 8px;
      }

      .title {
        font-family: $secondary-font;
        font-weight: 500;
        font-size: 30px;
      }

      .tag {
        margin-left: 8px;
      }
    }

    .section-title {
      font-family: $secondary-font;
      font-weight: 400;
      font-size: 18px;
      margin-bottom: 16px;
    }

    .divisor {
      margin: 0 8px;
      font-size: 4px;
      color: $gray-3;
    }
  }
}

</style>


<template lang="pug">

  .external-maintenance
    .section
      breadcrumbs.breadcrumbs(
        :loading="loading",
        :links="breadcrumbs"
      )

      .name.flex.vertical-center
        ticket-state-icon.state-icon(:state="ticket.state", size="big")
        h1.title {{ $t('.title') }}
        id-tag.tag(:value="ticket.id", size="big")
        facilities-tag.tag(
          value="external",
          size="big",
          v-tooltip.bottom="$t('.facilities.tooltip')",
          data-testid="facilitiesTag"
        )

      .flex.vertical-center
        span {{ $t('.createdAt', { date: $standardTime(ticket.createdAt) } ) }}
        i.fas.fa-diamond.divisor
        span {{ $t('date.timezone') }}

    .section
      service-order-vehicle-card(
        :vehicle-license-plate="ticket.vehicleLicensePlate",
        :vehicle-km="ticket.vehicleKm",
        :vehicle="ticket.vehicle",
        :loading="loading",
        data-testid="vehicleSection"
      )

    .section
      h3.section-title {{ $t('.sections.serviceOrders') }}

      service-orders-table.table(
        :service-orders="ticket.serviceOrders",
        :sorted-by="sort",
        :store-id="storeId",
        :ticket-id="ticket.id"
        @sort="onSortChange",
        data-testid="serviceOrdersTable"
      )

</template>


<script>

import ServiceOrderVehicleCard from "@/views/stores/tickets/components/service-order-vehicle-card.vue"
import ServiceOrdersTable from "./service-orders-table.vue"

export default {
  components: {
    ServiceOrderVehicleCard,
    ServiceOrdersTable
  },

  props: {
    loading: { type: Boolean, default: false },
    ticket:  { type: Object, required: true },
    storeId: { type: [Number, String], required: true }
  },

  data() {
    return {
      i18nScope: "stores.tickets.show.external-maintenance",

      // XXX: Por enquanto só há uma OS por atendimento, então a busca ordenada não está implementada
      sort: ""
    }
  },

  computed: {
    breadcrumbs() {
      return [
        {
          text: this.$t(".breadcrumbLinks.tickets"),
          to:   { name: "storeTickets", params: { id: this.storeId } }
        },
        {
          text: this.$t(".breadcrumbLinks.ticket", { id: this.ticket.id }),
          to:   { name: "storeTicket", params: { id: this.storeId, ticketId: this.ticket.id } }
        }
      ]
    }
  },

  methods: {
    onSortChange(sort) {
      this.sort = sort
    }
  }
}

</script>

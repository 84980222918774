// Permite a manipulação de dados aninhados, fornecendo métodos auxiliares para criar/remover
// além de tratar as _indexes_ de cada recurso, garantindo fácil acesso para os dados e possíveis erros gerados.
//
// Métodos:
// `add` - Para adicionar novo recurso se baseando no modelo passado em `this.model`
// `remove` - Remove analisando se é um novo recurso ou se será necessário passar `_destroy` para o servidor
// `keyFor` - Retorna `id` para recursos que existem ou `tempId` para novos recursos
//
// Computed:
// `nestedErrors` - Retorna um objeto de erros com as `keys` com o valor de `id`/`tempId` que pode ser acessado através de `keyFor`
//
import { v4 as uuid } from "uuid"
import models from "@/models"

export default {
  props: {
    value:   { type: Array, default: () => [] },
    minimum: { type: Number, default: null }
  },

  data() {
    return {
      // Necessário para funcionamento da reatividade
      collection: this.value
    }
  },

  watch: {
    // Necessário para funcionamento da reatividade
    value() {
      this.collection = [...this.value]
    }
  },

  mounted() {
    this.setMinimumResources()

    if (_.blank(this.model)) {
      throw new Error("Vue mixin Nested - variable: model - Not implemented yet")
    }
    if (_.blank(this.nestedResource)) {
      throw new Error("Vue mixin Nested - variable: nestedResource - Not implemented yet")
    }
  },

  computed: {
    nestedErrors() {
      return this.errors[`${this.nestedResource}Attributes`] || {}
    },

    empty() {
      return _.blank(this.collection)
    },

    hasErrors() {
      return _.present(this.errors)
    }
  },

  methods: {
    setMinimumResources() {
      if (this.minimum && this.value.length < this.minimum) {
        let resources = []
        let resource
        let initialParams = _.isFunction(this.initialParamsForNewResource)
          ? this.initialParamsForNewResource()
          : null

        let numberOfResources = this.minimum - this.value.length
        for (let i = 0; i < numberOfResources; i++) {
          resource = new models[this.model](initialParams)
          resource.tempId = uuid()
          resources.push(resource)
        }

        const nestedResource = [...this.value, ...resources]

        this.$emit("input", nestedResource)
      }
    },

    removeById(id) {
      if (this.minimum && this.value.length <= this.minimum) return

      // Necessário pois o Vue não identifica mudanças de um array dentro de um objeto
      const nestedResource = [...this.value]

      let index = this.collection.findIndex(el => el.id === id || el.tempId === id)
      let item = this.collection[index]

      if (item.$newRecord) {
        nestedResource.splice(index, 1)
      }
      else {
        item.$markForDestruction()
      }

      this.$emit("input", nestedResource)
    },

    remove(index) {
      if (this.minimum && this.value.length <= this.minimum) return

      // Necessário pois o Vue não identifica mudanças de um array dentro de um objeto
      const nestedResource = [...this.value]

      let item = this.collection[index]
      if (item.$newRecord) {
        nestedResource.splice(index, 1)
      }
      else {
        item.$markForDestruction()
      }

      this.$emit("input", nestedResource)
    },

    add() {
      let initialParams = _.isFunction(this.initialParamsForNewResource)
        ? this.initialParamsForNewResource()
        : null

      const resource = new models[this.model](initialParams)

      this.addFromData(resource)
    },

    addFromData(resource) {
      resource.tempId = uuid()

      const nestedResource = [...this.value, resource]

      this.$emit("input", nestedResource)
    },

    keyFor(resource) {
      return resource.id || resource.tempId
    }
  }
}

// Base
import Role from "./role"

class AdminRole extends Role {
  static build(user, options = {}) {
    return this.define((can, cannot) => {
      can("manage", "all")
    })
  }
}

export default AdminRole

<style scoped lang="scss">

.section {
  padding-bottom: 24px;
  margin-bottom: 24px;
  border-bottom: 1px solid $light-gray-3;
}

.title {
  font-weight: bold;
  font-size: 20px;
  margin-bottom: 4px;
  font-family: $secondary-font;
  font-weight: 500;
}

.subtitle {
  font-family: $primary-font;
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 22px;
  margin-bottom: 16px;
}

.header {
  margin-bottom: 24px;
}

.checkbox-field + .checkbox-field {
  margin-left: 42px;
}

.city-option {
  min-height: 50px;
  padding: 8px;
  border: 1px solid $light-gray-3;
  border-bottom: none;

  &.disabled {
    background: $light-gray-3;
    color: $gray-3;

    .icon {
      color: $gray-3;
    }
  }

  &:first-child {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }

  &:last-child {
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    border-bottom: 1px solid $light-gray-3;
  }

  .icon {
    font-size: 16px;
    color: $gray-2;
    margin-right: 12px;
  }
}

.nationwide-support-field {
  margin-bottom: 24px;
}

.supplier {
  margin-bottom: 24px;
}

.city-section {
  margin-bottom: 24px;
}

.city-subtitle {
  display: block;
  font-family: $secondary-font;
  font-weight: normal;
}

.supported-subtitle {
  margin-bottom: 16px;
}

.facilities-tag {
  margin-left: 8px;
}

.flag-icon {
  margin-right: 8px;
}

.company-section {
  .row + .row {
    margin-top: 24px;
  }

  .checkbox-field {
    width: 165px;
    flex-shrink: 0;
  }
}

.business-hours-actions {
  margin-bottom: 16px;
}

.shift-container {
  margin-top: 32px;
  margin-left: 124px;
  margin-bottom: 8px;

  .shift {
    min-width: 191px;
    font-weight: 500;
    font-size: 12px;
    font-family: $secondary-font;
  }

  .shift + .shift {
    margin-left: 24px;
  }
}

.info-icon {
  margin-left: 8px;
}

.scheduling-notice {
  width: 150px;
}

.extra-section {
  min-height: 56px;
}

.default-business-hour-btn {
  margin-left: 16px;

  .fa-info-circle {
    margin-left: 8px;
  }
}

</style>


<template lang="pug">

  .edit-supplier-details

    form(@submit.prevent="submit", method="post")
      //- section.section.company-section
      //-   header.header
      //-     h2.title {{ $t('.sections.company.title') }}
      //-     h3.subtitle {{ $t('.sections.company.subtitle') }}

      //-   .row.flex
      //-     checkbox-field.checkbox-field(
      //-       name="supplier[rac]",
      //-       v-model="resource.rac",
      //-       :loading="submitting",
      //-       :disabled="fetching",
      //-       :errors="errors.rac"
      //-     )
      //-       template(#optionText="{ props: { inputId, disabled } }")
      //-         label.checkbox-label.flex.vertical-center(:for="inputId", :class="{ disabled }")
      //-           rac-icon.flag-icon
      //-           span {{ $t('models.supplier.attributes.rac') }}

      //-     checkbox-field.checkbox-field(
      //-       name="supplier[racApp]",
      //-       v-model="resource.racApp",
      //-       :loading="submitting",
      //-       :disabled="fetching",
      //-       :errors="errors.racApp"
      //-     )
      //-       template(#optionText="{ props: { inputId, disabled } }")
      //-         label.checkbox-label.flex.vertical-center(:for="inputId", :class="{ disabled }")
      //-           rac-app-icon.flag-icon
      //-           span {{ $t('models.supplier.attributes.racApp') }}

      //-     checkbox-field.checkbox-field(
      //-       name="supplier[seminovos]",
      //-       v-model="resource.seminovos",
      //-       :loading="submitting",
      //-       :disabled="fetching",
      //-       :errors="errors.seminovos"
      //-     )
      //-       template(#optionText="{ props: { inputId, disabled } }")
      //-         label.checkbox-label.flex.vertical-center(:for="inputId", :class="{ disabled }")
      //-           seminovos-icon.flag-icon
      //-           span {{ $t('models.supplier.attributes.seminovos') }}

      //-     checkbox-field.checkbox-field(
      //-       name="supplier[zeroKm]",
      //-       v-model="resource.zeroKm",
      //-       :loading="submitting",
      //-       :disabled="fetching",
      //-       :errors="errors.zeroKm"
      //-     )
      //-       template(#optionText="{ props: { inputId, disabled } }")
      //-         label.checkbox-label.flex.vertical-center(:for="inputId", :class="{ disabled }")
      //-           zero-km-icon.flag-icon
      //-           span {{ $t('models.supplier.attributes.zeroKm') }}

      //-   .row.flex
      //-     checkbox-field.checkbox-field(
      //-       name="supplier[racMensal]",
      //-       v-model="resource.racMensal",
      //-       :loading="submitting",
      //-       :disabled="fetching",
      //-       :errors="errors.racMensal"
      //-     )
      //-       template(#optionText="{ props: { inputId, disabled } }")
      //-         label.checkbox-label.flex.vertical-center(:for="inputId", :class="{ disabled }")
      //-           rac-mensal-icon.flag-icon
      //-           span {{ $t('models.supplier.attributes.racMensal') }}

      //-     checkbox-field.checkbox-field(
      //-       name="supplier[gtf]",
      //-       v-model="resource.gtf",
      //-       :loading="submitting",
      //-       :disabled="fetching",
      //-       :errors="errors.gtf"
      //-     )
      //-       template(#optionText="{ props: { inputId, disabled } }")
      //-         label.checkbox-label.flex.vertical-center(:for="inputId", :class="{ disabled }")
      //-           gtf-icon.flag-icon
      //-           span {{ $t('models.supplier.attributes.gtf') }}

      //-     checkbox-field.checkbox-field(
      //-       name="supplier[premium]",
      //-       v-model="resource.premium",
      //-       :loading="submitting",
      //-       :disabled="fetching",
      //-       :errors="errors.premium"
      //-     )
      //-       template(#optionText="{ props: { inputId, disabled } }")
      //-         label.checkbox-label.flex.vertical-center(:for="inputId", :class="{ disabled }")
      //-           premium-icon.flag-icon
      //-           span {{ $t('models.supplier.attributes.premium') }}

      section.section
        header.header
          h2.title {{ $t('.sections.location.title') }}
          h3.subtitle {{ $t('.sections.location.subtitle') }}

        .flex
          checkbox-field.checkbox-field(
            name="supplier[internalFacilities]",
            v-model="resource.internalFacilities",
            :loading="submitting",
            :disabled="fetching",
            :errors="errors.internalFacilities"
          )
            template(#optionText="{ props: { inputId, disabled } }")
              label.checkbox-label.flex.vertical-center(:for="inputId", :class="{ disabled }")
                i.flag-icon.fal.fa-garage-car
                span {{ $t('models.supplier.attributes.internalFacilities') }}
                facilities-tag

          checkbox-field.checkbox-field(
            name="supplier[supplierFacilities]",
            v-model="resource.supplierFacilities",
            :loading="submitting",
            :disabled="fetching",
            :errors="errors.supplierFacilities"
          )
            template(#optionText="{ props: { inputId, disabled } }")
              label.checkbox-label.flex.vertical-center(:for="inputId", :class="{ disabled }")
                i.flag-icon.fal.fa-car-building
                span {{ $t('models.supplier.attributes.supplierFacilities') }}
                facilities-tag(value="external")

      section.section
        header.header
          h2.title {{ $t('.sections.customer.title') }}
          h3.subtitle {{ $t('.sections.customer.subtitle') }}

        .flex
          checkbox-field.checkbox-field(
            name="supplier[internalCustomer]",
            v-model="resource.internalCustomer",
            :loading="submitting",
            :disabled="fetching",
            :errors="errors.internalCustomer"
          )
            template(#optionText="{ props: { inputId, disabled } }")
              label.checkbox-label.flex.vertical-center(:for="inputId", :class="{ disabled }")
                i.flag-icon.fal.fa-id-card-alt
                span {{ $t('models.supplier.attributes.internalCustomer') }}

          checkbox-field.checkbox-field(
            name="supplier[externalCustomer]",
            v-model="resource.externalCustomer",
            :loading="submitting",
            :disabled="fetching",
            :errors="errors.externalCustomer"
          )
            template(#optionText="{ props: { inputId, disabled, error } }")
              label.checkbox-label.flex.vertical-center(
                :for="inputId",
                :class="{ disabled, loading: submitting, error }"
              )
                i.flag-icon.fal.fa-users
                span {{ $t('models.supplier.attributes.externalCustomer') }}

      section.section
        header.header
          h2.title {{ $t('.sections.extras.title') }}
          h3.subtitle {{ $t('.sections.extras.subtitle') }}

        .flex.vertical-center.extra-section
          checkbox-field.checkbox-field(
            name="supplier[receivesTowedVehicles]",
            v-model="resource.receivesTowedVehicles",
            :loading="submitting",
            :disabled="fetching",
            :errors="errors.receivesTowedVehicles"
          )
            template(#optionText="{ props: { inputId, disabled, error } }")
              label.checkbox-label(
                :for="inputId",
                :class="{ disabled, loading: submitting, error }"
              )
                span {{ $t('models.supplier.attributes.receivesTowedVehicles') }}
                i.info-icon.far.fa-info-circle(
                  v-tooltip.bottom="tooltipOptions($t('.fields.receivesTowedVehicles.tooltip'))"
                )

          checkbox-field.checkbox-field(
            name="supplier[pickupService]",
            v-model="resource.pickupService",
            :loading="submitting",
            :disabled="fetching",
            :errors="errors.pickupService"
          )
            template(#optionText="{ props: { inputId, disabled, error } }")
              label.checkbox-label(
                :for="inputId",
                :class="{ disabled, loading: submitting, error }"
              )
                span {{ $t('models.supplier.attributes.pickupService') }}
                i.info-icon.far.fa-info-circle(
                  v-tooltip.bottom="tooltipOptions($t('.fields.pickupService.tooltip'))"
                )
         
          toggle-field.checkbox-field(
            name="supplier[hasSchedulingNotice]",
            @input="onToggleHasSchedulingNotice",
            :value="hasSchedulingNotice",
            :loading="submitting",
            :disabled="fetching"
          )
            template(#optionText="{ props: { inputId, disabled, error } }")
              label.checkbox-label(
                :for="inputId",
                :class="{ disabled, loading: submitting, error }"
              )
                span {{ $t('models.supplier.attributes.schedulingNotice') }}
                i.info-icon.far.fa-info-circle(
                  v-tooltip.bottom="tooltipOptions($t('.fields.schedulingNotice.tooltip'))"
                )

          template(v-if="hasSchedulingNotice")
            input-field.scheduling-notice(
              name="supplier[schedulingNotice]",
              :loading="submitting",
              :disabled="fetching",
              hide-label,
              v-model.number="resource.schedulingNotice",
              mask="#",
              :errors="this.errors.schedulingNotice"
              :suffix="$t('.fields.schedulingNotice.suffix')"
              )
          checkbox-field.checkbox-field(
            name="supplier[rabbotPlataform]",
            v-model="resource.rabbotPlataform",
            :loading="submitting",
            :disabled="fetching",
            :errors="errors.rabbotPlataform"
          )
            template(#optionText="{ props: { inputId, disabled, error } }")
              label.checkbox-label(
                :for="inputId",
                :class="{ disabled, loading: submitting, error }"
              )
                span {{ $t('models.supplier.attributes.rabbotPlataform') }}
                i.info-icon.far.fa-info-circle(
                  v-tooltip.bottom="tooltipOptions($t('.fields.rabbotPlataform.tooltip'))"
                )
          checkbox-field.checkbox-field(
            name="supplier[movidaApp]",
            v-model="resource.movidaApp",
            :loading="submitting",
            :disabled="fetching",
            :errors="errors.movidaApp"
          )
            template(#optionText="{ props: { inputId, disabled, error } }")
              label.checkbox-label(
                :for="inputId",
                :class="{ disabled, loading: submitting, error }"
              )
                span {{ $t('models.supplier.attributes.movidaApp') }}
                i.info-icon.far.fa-info-circle(
                  v-tooltip.bottom="tooltipOptions($t('.fields.movidaApp.tooltip'))"
                )
  
         
      section.section
        header.header
          h2.title {{ $t('.sections.region.title') }}
          h3.subtitle {{ $t('.sections.region.subtitle') }}

        .city-section(v-if="resource.$present('city')")
          label.city-subtitle {{ $t('.sections.region.mainCity') }}
          label.city {{ `${resource.city.name}, ${resource.city.stateCode}` }}

        label.city-subtitle.supported-subtitle {{ $t('.sections.region.supportedCities') }}

        .fields
          toggle-field.nationwide-support-field(
            name="supplier[nationwideSupport]",
            v-model="resource.nationwideSupport",
            :loading="submitting",
            :disabled="fetching",
            :errors="errors.nationwideSupport"
          )

          //- TODO Criar componente city-multiple-select-field
          google-autocomplete.google-autocomplete(
            ref="googleAutocomplete",
            :disabled="submitting || fetching || resource.nationwideSupport",
            :placeholder="$t('.fields.cities.placeholder')",
            :value="googleSearch",
            @input="onSelectCity",
            clear-after-select,
            :fields="['address_components', 'place_id', 'name']",
            :types="['(cities)']"
          )

          .list
            .city-option.flex.vertical-center.space-between(
              v-for="(city, index) in supportedCities",
              :key="`cities-${index}`",
              :class="{ disabled: cannotEditCities }"
            )
              .content
                i.icon.fas.fa-map-marker-alt
                label.name {{ `${city.name}, ${city.stateCode}` }}
              app-button(v-if="!cannotEditCities", theme="neutral", size="small", @click="removeCity(city)")
                i.fal.fa-trash-alt

      section.section
        header.header
          h2.title {{ $t('.sections.openingHours.title') }}
          h3.subtitle {{ $t('.sections.openingHours.subtitle') }}


        .flex.vertical-center.business-hours-actions
          toggle-field.toggle-field(
            name="supplier[open]",
            hide-label,
            :value-text="$t('.fields.allOpen.label')"
            :loading="fetching",
            v-model="resource.roundTheClock",
            :errors="errors.roundTheClock"
          )

          app-button.default-business-hour-btn(
            theme="link",
            :loading="fetching",
            @click="confirmDefaultBusinessHours"
          )
            span(v-tooltip.bottom=`{
              content: $t('.defaultBusinessHours.tooltip'),
              offset:  '4'
            }`)
              | {{ $t('.defaultBusinessHours.btn') }}
              i.fal.fa-info-circle

        template(v-if="fetching")
          loading-lines(v-for="line in 7", :key="`loading-business-schedule-line-${line}`")

        template(v-else)
          .shift-container.flex
            span.shift.center(
              v-for="shift in ['morning', 'afternoon', 'evening']",
              :key="`title-${shift}`",
            ) {{ $t(`.shift.${shift}`) }}

          business-hour-nested-fields(
            v-for="weekday in weekdays",
            :key="`week-day-${weekday}`",
            :value="resource.businessHours",
            @input="changeBusinessHour",
            :disabled="resource.roundTheClock",
            name="supplier[businessHours]",
            :errors="errors",
            :weekday="weekday"
          )

      section.actions.flex.space-between.vertical-center
        app-button(
          :to="{ name: 'supplierDetails', params: { id: supplierId } }",
          outline,
          theme="gray",
          :loading="submitting"
        )
          span {{ $t('btn.cancel') }}

        app-button(type="submit", :loading="submitting", @click="submit" data-test="submitButton")
          span {{ $t('btn.update.label') }}
          span(slot="loading") {{ $t('btn.update.loading') }}

</template>


<script>

// Mixins
import FetchMixin from "@/mixins/fetch-mixin"
import FormMixin from "@/mixins/form-mixin"

// View
import LoadedView from "@/views/loaded-view"

// Components
import BusinessHourNestedFields from "./business-hour-nested-fields"

// Models
import models from "@/models"
const { City, SupplierDetails, SupplierBusinessHour } = models

const STATES = ["AC", "AL", "AP", "AM", "BA", "CE", "DF", "ES", "GO", "MA", "PA", "MT", "MS", "MG", "PA", "PB", "PR", "PE", "PI", "RJ", "RN", "RS", "RO", "RR", "SC", "SP", "SE", "TO"]

export default {
  name: "EditSupplierDetails",

  components: { BusinessHourNestedFields },

  extends: LoadedView,

  mixins: [FetchMixin, FormMixin],

  props: {
    supplier: { type: Object, default: () => ({}) }
  },

  data() {
    return {
      i18nScope: "suppliers.details.edit",
      routeName: "editSupplierDetails",

      supplierId: null,

      googleSearch: null,
      weekdays:     SupplierBusinessHour.$enums.weekday.keys,

      // Fetchable mixin
      resource:        new SupplierDetails(),
      supportedCities: [],

      hasSchedulingNotice: false,

      nestedAttributes: {
        businessHours: null
      }
    }
  },

  computed: {
    cannotEditCities() {
      return this.submitting || this.fetching || this.resource.nationwideSupport
    }
  },

  methods: {
    // @override Loaded view
    parseRoute() {
      this.supplierId = this.$params.asInteger(this.$route.params.id)
    },

    async confirmDefaultBusinessHours() {
      const isConfirmed = await this.$confirm({
        heading: this.$t(".defaultBusinessHours.title"),
        message: this.$t(".defaultBusinessHours.message"),

        actions: {
          confirm: this.$t(".defaultBusinessHours.confirm")
        }
      })

      if (!isConfirmed) return

      this.resource.roundTheClock = false
      this.resource.setDefaultBusinessHours()
    },

    onToggleHasSchedulingNotice(value) {
      if (!value) {
        this.resource.schedulingNotice = null
      }

      this.resource.hasSchedulingNotice = value
      this.hasSchedulingNotice = value
    },

    tooltipOptions(text) {
      return {
        content: text,
        offset:  "4",
        classes: ["preserve-whitespace"]
      }
    },

    changeBusinessHour(newBusinessHours) {
      this.resource.businessHours = newBusinessHours
      this.$forceUpdate()
    },

    onSelectCity(place) {
      if (_.blank(place)) return

      const addressComponents = _.get(place, "address_components") || []
      let stateCode = ""

      for (let addr of addressComponents) {
        if (STATES.includes(addr.short_name)) {
          stateCode = addr.short_name
          break
        }
      }

      let selectedOptions = [...this.supportedCities]
      const optionIndex = selectedOptions.findIndex(city => city.placeId === place.place_id)

      if (optionIndex >= 0) return

      const supportedCities = [...selectedOptions, new City({
        placeId: place.place_id,
        name:    place.name,
        stateCode
      })]

      this.supportedCities = supportedCities.sort((a, b) => {
        if (a.stateCode > b.stateCode) return 1
        if (a.stateCode < b.stateCode) return -1

        if (a.name > b.name) return 1
        if (a.name < b.name) return -1

        return 0
      })

      this.$refs.googleAutocomplete.onChange(null)
    },

    removeCity(removedCity) {
      const supportedCities = [...this.supportedCities]
      const cityIndex = supportedCities.findIndex(city => city.placeId === removedCity.placeId)

      if (cityIndex < 0) return

      supportedCities.splice(cityIndex, 1)
      this.supportedCities = supportedCities
    },

    // @override Fetch mixin
    fetchRequest() {
      return this.$newSdk.suppliers.details.find({ supplierId: this.supplierId })
    },

    // @override Fetch mixin
    onFetchSuccess({ data, headers }) {
      this.resource = data

      this.supportedCities = this.resource.supportedCities
      this.hasSchedulingNotice = this.resource.hasSchedulingNotice
      this.initialSerializedAttributes = this.serializeAttributes()
    },

    // @override Form mixin
    serializeAttributes() {
      const supportedCityPlaceIds = this.supportedCities.map(({ placeId }) => placeId)

      return {
        ...this.resource.$serialize({}, this.nestedAttributes),
        supported_city_place_ids: supportedCityPlaceIds
      }
    },

    // @override Form mixin
    submitRequest() {
      return this.$newSdk.suppliers.details.update({ supplierId: this.supplierId, params: this.serializeAttributes() })
    },

    // @override Form mixin
    onSubmitSuccess(response) {
      this.$notifications.info(this.$t(".notifications.submit.success", { name: this.supplier.name }))
      this.$router.push({ name: "supplierDetails", params: { id: this.supplierId } })
    }
  }
}

</script>

<!--

  Componente para categorias de Veículo "Movida" (~Vetor).
  São categorias associadas individualmente a cada veículo.

  Confira a enumeração em src/enums/vehicles.js => CATEGORIES

-->

<style lang="scss" scoped>

.vehicle-category-tag {
  font-family: $secondary-font;
  font-weight: 500;
  font-size: 12px;
  line-height: 150%;
  color: $dark-gray;

  padding: 2px 4px;
  border-bottom: 1px solid;
  border-radius: 4px;


  // themes
  // ---

  &.operational {
    background: $light-gray-3;
    border-bottom-color: $light-gray-4;
  }

  &.premium {
    background: linear-gradient(180deg, #FFF4D1 40.1%, #ECD172 100%);
    border-bottom-color: #AB8C50;
  }

  &.super_vip {
    background: linear-gradient(180deg, #F6F6F6 0%, #C4CFD9 100%);
    border-bottom-color: #1A4F7D;
  }

  &.vip {
    background: linear-gradient(180deg, #FFEED9 0%, #FFC690 100%);
    border-bottom-color: #AE4D34;
  }

  &.other {
    background: linear-gradient(180deg, #F6F6F6 0%, #D4D4D4 100%);
  }

}

</style>


<template lang="pug">

  span.vehicle-category-tag(:class="theme")
    | {{ text }}

</template>


<script>

import { CATEGORIES } from "@/enums/vehicles"


export default {
  props: {
    value: { type: String, required: true }
  },

  data() {
    return {
      i18nScope: "monitoring.vehicle-category-tag"
    }
  },

  computed: {
    theme() {
      return CATEGORIES.key(this.value) || "other"
    },

    text() {
      return this.$t(`enums.vehicles.CATEGORIES.${this.value}`, {
        defaultValue: this.$t(".other.label")
      }).toLowerCase()
    }
  }

}

</script>

import Base from "./base"

class VehicleIssueSolution extends Base {
  static get attrs() {
    return _.uniq([
      ...super.attrs,
      "serviceId",
      "vehicleIssueId"
    ])
  }

  static get relations() {
    return {
      service:      { type: "belongsTo", model: "Service" },
      vehicleIssue: { type: "belongsTo", model: "VehicleIssue" }
    }
  }

  static get constraints() {
    return {
      serviceId: {
        presence: true
      }
    }
  }
}

export default VehicleIssueSolution

import Base from "../base"


class MovidaProduct extends Base {
  static get attrs() {
    return _.uniq([
      ...super.attrs,

      "remoteId",
      "sapId",
      "name",
      "discarded"
    ])
  }
}


export default MovidaProduct

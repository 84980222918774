import { render, staticRenderFns } from "./sortable-table-header-column.vue?vue&type=template&id=6a34cbc2&scoped=true&lang=pug&"
import script from "./sortable-table-header-column.vue?vue&type=script&lang=js&"
export * from "./sortable-table-header-column.vue?vue&type=script&lang=js&"
import style0 from "./sortable-table-header-column.vue?vue&type=style&index=0&id=6a34cbc2&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6a34cbc2",
  null
  
)

export default component.exports
import Base from "./Base"

class Tickets extends Base {

  list({ params = {} } = {}) {
    return this.action({
      method: "get",
      url:    "/tickets",
      params
    })
  }

}

export default Tickets

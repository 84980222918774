<style lang="scss" scoped>

.index-table-skeleton {
  .row {
    display: grid;
    grid-template-columns: var(--grid-columns);

    & + .row {
      margin-top: 24px;
    }

    .column + .column {
      margin-left: 24px
    }
  }
}

</style>


<template lang="pug">

  .index-table-skeleton(:style="styles")
    .row(v-for="row in rowsCount", :key="`index-table-skeleton-row-${row}`")
      slot(name="cols")
        .column(v-for="col in colsCount", :key="`index-table-skeleton-col-${row}-${col}`")
          loading-lines(:min="100", :max="100", :height="20")

</template>


<script>

export default {
  name: "IndexTableSkeleton",

  props: {
    rowsCount:   { type: Number, default: 5 },
    colsCount:   { type: Number, required: true },
    columnSizes: { type: String, required: true }
  },

  computed: {
    styles() {
      return {
        "--grid-columns": this.columnSizes
      }
    }
  }
}

</script>

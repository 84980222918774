<!--
<doc lang="markdown">
  Componente para seletor de datas com horário, utilizando a lib Flatpickr (https://flatpickr.js.org).
  Veja um exemplo de uso em client/application/operator/tools/properties/visit_requests/new.vue.
  template:
  ```
  //- - name é o atributo HTML mesmo. Útil para envio de form.
  //- - v-model terá o valor do input - valor textual (String)
  datetimepicker(name="at", v-model="lAt")
  ```
  Para ter o valor canônico da data - que, caso contrário, será localizada
  (ex: 'DD/MM/YYYY') - use uma _computed property_:
  ```
  computed: {
    // Data `at` 'localizada', para exibição no input do Flatpickr.
    // Assim, teremos `at` com o valor ISO8601 ('YYYY-MM-DD'), e `lAt`
    // com o valor localizado ('DD/MM/YYYY').
    lAt: {
      get() {
        if (!this.at) return null
        return this.$l('time.formats.micro', this.at)
      },
      set(value) {
        let date = moment(value, 'L').toDate()
        this.at = this.$l('date.formats.iso', date)
      }
    }
  }
  ```
</doc>
-->

<style lang="scss" scoped>

$height: 40px;

.datepickr-wrapper {
  position: relative;
  font-size: 16px;
  display: inline-block;
  width: 100%;
}

.datepickr-inner {
  font-family: $primary-font;
  appearance: none;
  background-color: $white;
  background-image: none;
  border-radius: 4px;
  border: 1px solid $gray;
  color: $gray-3;
  display: inline-block;
  font-size: inherit;
  height: $height;
  line-height: $height;
  outline: none;
  padding: 1px 13px;

  width: 100%;

  &.icon-right {
    padding-right: 40px;

    &:focus {
      padding-right: 39px;
    }
  }

  &.icon-left {
    padding-left: 40px;

    &:focus {
      padding-left: 39px;
    }
  }

  &:hover {
    border-color: $orange;
  }

  &:focus {
    outline: none;
    border-color: $orange;
    border-width: 2px;
    padding: 0 12px;
  }

  &::placeholder {
    font-weight: 300;
    color: $gray-2;
    opacity: 1;
  }
}

// Disabled
.datepickr-inner.disabled {
  background-color: $light-gray-3;
  border-color: $light-gray-4;
  color: $gray-2;
  cursor: not-allowed;
}

// Loading
.datepickr-inner.loading {
  background-color: $light-gray-3;
  border-color: $light-gray-4;
  color: $gray-3;
  cursor: progress;
}

.icon {
  position: absolute;
  font-size: 16px;
  top: calc(#{$height + 2px} / 2);
  transform: translateY(-50%);
  color: $gray-4;

  right: 12px;
}

.clear-button {
  position: absolute;
  appearance: none;
  border: none;
  background: transparent;
  cursor: pointer;
  width: 24px;
  height: 38px;
  right: 36px;
  top: calc(50% - 19px);
  font-size: 16px;
  padding: 0;
  text-align: center;
  transition: transform .2s ease;
  color: $dark-gray;

  .remove-icon-content {
    width: 24px;
    height: 24px;
    line-height: 26px;
    border-radius: 50px;
    transition: all 100ms linear;
  }

  &.open {
    right: 35px;
    height: 36px;
    top: calc(50% - 18px);
  }

  &:hover {
    color: $primary;

    .remove-icon-content {
      background: $light-gray-3;
    }
  }
}

// Disabled
.datepickr-inner.disabled {
  background-color: $light-gray-3;
  border-color: $light-gray-4;
  color: $gray-2;
  cursor: not-allowed;
}

// Loading
.datepickr-inner.loading {
  background-color: $light-gray-3;
  border-color: $light-gray-4;
  color: $gray-3;
  cursor: progress;
}

// Error
.datepickr-inner.error {
  border-color: $error;
}

</style>


<template lang="pug">

  .app-datepicker
    .datepickr-wrapper
      input.datepickr-inner(
        ref="input",
        type="tel",
        :class="{ loading, disabled, error }",
        :name="name",
        :value="value",
        autocomplete="off",
        :autofocus="autofocus",
        :disabled="disabled || loading",
        :placeholder="placeholder",
        @change="onChange"
      )
      template(v-if="!hideIcon")
        i.icon.far.fa-calendar-alt

      template(v-if="!hideClearButton")
        button.clear-button.flex.center.vertical-center(
          v-if="!blank && !disabled && !loading",
          tabindex="-1",
          type="button",
          @click.stop="clear"
        )
          .remove-icon-content
            i.far.fa-times

</template>


<script>

import Flatpickr from "flatpickr"

export default {
  name: "AppFlatpickr",

  props: {
    // opções de controle do input
    autofocus:       { type: Boolean, default: false  },
    disabled:        { type: Boolean, default: false  },
    error:           { type: Boolean, default: false  },
    loading:         { type: Boolean, default: false  },
    placeholder:     { type: String,  default: null   },
    value:           { type: String,  default: null   },
    name:            { type: String,  default: null   },
    enableTime:      { type: Boolean, default: false  },
    noCalendar:      { type: Boolean, default: false  },
    allowInput:      { type: Boolean, default: true   },
    time24h:         { type: Boolean, default: true   },
    hideIcon:        { type: Boolean, default: false  },
    hideClearButton: { type: Boolean, default: false  },
    minTime:         { type: String,  default: null   },
    maxTime:         { type: String,  default: null   },
    futureOnly:      { type: Boolean, default: false  },
    minDate:         { type: String,  default: null   },
    maxDate:         { type: String,  default: null   },

    // padrão do flatpickr
    defaultHour: { type: Number, default: 12 },

    // define se o datetimepicker deve ficar "dentro de um modal", garantindo
    // a criação dos <div> corretamente (opção "appendTo" do Kalendar)
    modal: { type: Boolean, default: false },

    // Flatpickr options. Veja mais: https://flatpickr.js.org/options/
    // dateFormat @ https:// flatpickr.js.org/options/ - https://flatpickr.js.org/formatting/
    // dateFormat: { type: String, default: () => app.i18n.t('time.formats.micro') },
    dateFormat: { type: String, default: "d/m/Y" },

    // appendTo: { type: String, default: ? }
    mode: { type: String, default: "single" }
  },

  data() {
    return {
      // instância do Flatpickr
      flatpickr: null,

      listeners: [],
      lastVal:   this.value
    }
  },

  computed: {
    flatpickrOptions() {
      // Flatpickr options. Veja mais: https://flatpickr.js.org/options/
      let options = {
        defaultDate: this.value,
        mode:        this.mode,
        dateFormat:  this.dateFormat,
        // clickOpens: true, // default value
        allowInput:  this.allowInput,
        time_24hr:   this.time24h,
        noCalendar:  this.noCalendar,
        enableTime:  this.enableTime,
        minTime:     this.minTime,
        maxTime:     this.maxTime,
        defaultHour: this.defaultHour,
        minDate:     this.minDate,
        maxDate:     this.maxDate,
        disable:     []

        // plugins:     []
      }

      if (this.futureOnly) {
        options.disable.push(date => new Date(date).setHours(0, 0, 0, 0) < new Date().setHours(0, 0, 0, 0))
      }

      // indica que o datetimepicker está dentro de um modal
      if (this.modal) {
        // XXX classe padrão do componente modal - components/app-modal.vue
        options.appendTo = this.$el.closest(".modal-container")
      }

      return options
    },

    blank() {
      return _.blank(this.value)
    }
  },

  watch: {
    minTime() {
      if (_.blank(this.flatpickr)) return

      this.flatpickr.config.minTime = this.minTime
    },

    maxTime() {
      if (_.blank(this.flatpickr)) return

      this.flatpickr.config.maxTime = this.maxTime
    },

    minDate() {
      if (_.blank(this.flatpickr)) return

      this.flatpickr.config.minDate = this.minDate
    },

    maxDate() {
      if (_.blank(this.flatpickr)) return

      this.flatpickr.config.maxDate = this.maxDate
    }
  },

  mounted() {
    let vm = this
    this.flatpickr = new Flatpickr(
      this.$refs.input,
      Object.assign(this.flatpickrOptions, {
        // hooks @ https://flatpickr.js.org/events/
        // ensuring time picker is always visible
        // @see https://github.com/flatpickr/flatpickr/issues/1047#issuecomment-334319201
        onReady() {
          this.showTimeInput = true
        },

        onOpen(selectedDates, dateStr, instance) {
          if (_.present(this.value)) return

          // Necessário e funciona apenas na presença de campo de hora
          if (!instance.timeContainer) return

          // Ao abrir um input de flatpickr, caso o campo de hora seja o campo com autofocus,
          // precisamos aplicar o código abaixo para garantir o comportamento adequado ao atribuir um valor
          // ver mais em https://github.com/caiena/movida-gmf/issues/615
          setTimeout(() => {
            const { activeElement } = window.document
            const hourInputElement = instance.timeContainer.querySelector(".flatpickr-hour")

            if (activeElement === hourInputElement) {
              instance.timeContainer.querySelector(".flatpickr-hour").blur()
              instance.timeContainer.querySelector(".flatpickr-hour").focus()
            }
            else if (!!hourInputElement) {
              instance.timeContainer.querySelector(".flatpickr-hour").focus()
              instance.timeContainer.querySelector(".flatpickr-hour").blur()
            }
          }, 100)
        },

        onChange(selectedDates, dateStr, instance) {
          // XXX flag para evitar duplo-processamento do evento @change, uma vez que liberamos
          // a edição do input manualmente (input-teclado)
          vm.__supressChange = true
          // selectedDates - array of Date objects
          // dateStr - the current input showing string (defined by dateFormat and other options)
          // instance - current flatpickr instance (also available, in this component, as this.flatpickr)
          vm.$emit("input", dateStr)
        },

        // onOpen(selectedDates, dateStr, instance) {},
        // onClose(selectedDates, dateStr, instance) {}
        onValueUpdate(selectedDates, dateStr, instance) {
          // XXX emit em onValueUpdate para recalcular value quando um campo é atualizado
          // por modificação externa, como uma mudança em minTime ou minDate
          if (dateStr !== vm.value) vm.$emit("input", dateStr)
        }
      })
    )
  },

  beforeDestroy() {
    if (this.flatpickr) this.flatpickr.destroy()
  },

  methods: {
    clear() {
      this.flatpickr.clear()
    },

    close() {
      this.flatpickr.close()
    },

    open() {
      this.flatpickr.open()
    },

    onChange(evt) {
      // @see https://github.com/flatpickr/flatpickr/issues/828
      if (this.__supressChange) {
        this.__supressChange = false
        return
      }

      if (this.noCalendar) return

      let { value } = evt.target
      if (value === this.__lastVal) return

      this.__lastVal = value
      let date = Flatpickr.parseDate(value, this.dateFormat)

      // XXX: commenting the "if" below to allow an "empty input" to represent `null`
      // if (date) {
      this.flatpickr.setDate(date, true /* trigger change events */)
      // }
    }
  }
}

</script>

<style lang="scss" scoped>

.index {
  .clear-filters {
    position: relative;

    .filters-button {
      position: absolute;
      margin-top: 10px;
    }
  }

  .list {
    margin-top: 50px;

    .table {
      margin-top: 16px;
    }
  }

  .paginator-content {
    padding-top: 24px;

    &.loading {
      max-width: 400px;
      margin: 0 auto;
    }
  }
}

</style>


<template lang="pug">

  .index
    service-support-info(:supplier-id="supplierId")

    .actions-bar
      ui-row
        ui-col(:span="7")
          search-form.search(
            :loading="fetching",
            :params="parsedQueryParams",
            @search="search"
          )

        ui-col.flex.end.vertical-start(:span="5")
          app-button(
            v-if="$can('update', 'Supplier')",
            theme="link",
            :to="batchAddRoute"
          ) {{ $t('.btn.add.label') }}

    .clear-filters
      filters-button.filters-button(
        v-if="hasFilters",
        @clear="clearFilters"
      )

    .list
      .flex.end.vertical-bottom
        results-indicator(
          :page="parsedQueryParams.page",
          :per-page="pagination.perPage",
          :total="pagination.total",
          :loading="fetching"
        )

      vehicle-model-groups-table.table(
        :error="erred",
        :empty="empty",
        :initializing="initializing",
        :loading="fetching",
        :supplier-maintenance-vehicle-model-groups="supplierMaintenanceVehicleModelGroups",
        :sorted-by="parsedQueryParams.sort",
        @sort="onSortChange",
        @changed="fetch"
      )

    .paginator-content(v-if="fetching || showPaginator", :class="{ loading: fetching }")
      paginator(
        :loading="fetching",
        :per-page="pagination.perPage",
        :total="pagination.total",
        :value="parsedQueryParams.page",
        :disabled="fetching",
        @input="onPageChange",
        data-testid="paginator"
      )

    router-view(
      @create="fetch",
      :back-route-query="currentRouteQuery"
    )

</template>


<script>

// View
import IndexView from "@/views/index-view"

// Mixins
import FetchMixin from "@/mixins/fetch-mixin"

// Components
import ServiceSupportInfo from "./index/service-support-info"
import SearchForm from "./index/search-form"
import VehicleModelGroupsTable from "./index/supplier-maintenance-vehicle-model-groups-table.vue"

export default {
  name: "SupplierServiceSupportMaintenanceVehicleModelGroups",

  components: {
    ServiceSupportInfo,
    SearchForm,
    VehicleModelGroupsTable
  },

  extends: IndexView,

  mixins: [FetchMixin],

  data() {
    return {
      i18nScope: "suppliers.service-supports.maintenance.vehicle-model-groups.index",
      routeName: "supplierServiceSupportMaintenanceVehicleModelGroups",

      supplierId: this.$route.params.id,

      resource: [],

      // Query Mixin data
      queryParamsStructure: {
        q:    "string",
        page: "integer",
        sort: "string"
      }
    }
  },

  computed: {
    supplierMaintenanceVehicleModelGroups: {
      get() { return this.resource },
      set(value) { this.resource = value }
    },

    empty() {
      return _.blank(this.supplierMaintenanceVehicleModelGroups)
    },

    batchAddRoute() {
      return { name: "newSupplierServiceSupportMaintenanceVehicleModelGroups", params: { id: this.supplierId } }
    }
  },

  methods: {
    parseRoute() {
      this.supplierId = this.$route.params.id
    },

    // @override Fetchable mixin
    fetchRequest() {
      return this.$newSdk.suppliers.maintenanceVehicleModelGroups.all({ supplierId: this.supplierId, params: this.requestQueryParams })
    }
  }
}

</script>

<style lang="scss" scoped>

.index {
  .header {
    margin-bottom: 16px;
  }

  .add {
    font-size: 14px;

    .icon {
      margin-right: 4px;
    }
  }

  .retry {
    margin-left: 4px;
  }

  .empty {
    background-color: $light-gray;
    border-radius: 8px;
    padding: 24px 0;

    .illustration {
      --size: 48px;
      width: var(--size);
      height: var(--size);
    }

    .empty-message {
      font-family: $secondary-font;
      font-weight: 400;
      font-size: 14px;
      margin: 8px 0;
      color: $gray-3;
    }
  }

  .attachments {
    margin: -24px 0 0 -24px;

    .attachment-card,
    .empty-card {
      margin-left: 24px;
      margin-top: 24px;
    }
  }
}

</style>


<template lang="pug">

  .index
    .header.flex.end(v-if="!empty")
      file-selector.flex(
        v-if="editableList",
        multiple,
        :max-size="maxSize",
        @selected-files="uploadFiles"
      )
        template(#default="{ props: { selectFiles } }")
          app-button.add(
            theme="link",
            :disabled="loading",
            @click="selectFiles"
          )
            i.icon.far.fa-paperclip
            span {{ $t('.btn.add.label') }}

    template(v-if="fetching")
      loading-lines(:lines="4")

    template(v-else-if="erred")
      span {{ $t('.fetch-error.message') }}
      app-button.retry(theme="link", @click="fetch") {{ $t('.fetch-error.retry.label') }}

    template(v-else-if="empty")
      slot(name="empty")
        .empty.flex.column-direction.center.vertical-center
          img.illustration(:src="paperclipIllustration")
          app-span.empty-message(:value="$t('.empty.message')")
          file-selector.flex(
            v-if="editableList",
            multiple,
            :max-size="maxSize",
            @selected-files="uploadFiles"
          )
            template(#default="{ props: { selectFiles } }")
              app-button.add(
                theme="link",
                :disabled="loading",
                @click="selectFiles"
              )
                i.icon.far.fa-paperclip
                span {{ $t('.btn.add.label') }}

    .attachments.flex.wrap(v-else)
      template(v-for="attachment in _attachments")
        service-order-service-attachment-card(
          :attachment="attachment",
          :deletable="editableList && !submitting",
          :editable="editableAttachments && !submitting",
          :service-order-service-id="serviceOrderServiceId",
          @uploaded="signedId => onAttachmentUploaded(attachment, signedId)",
          @destroy="removeAttachment(attachment)"
        )

      file-selector.empty-card(
        v-if="editableList && !loading",
        multiple,
        :max-size="maxSize",
        @selected-files="uploadFiles"
      )

</template>


<script>

// Assets
import paperclipIllustration from "@/assets/images/illustrations/paperclip.svg"

// Extends
import ServiceOrderAttachmentsIndex from "../../attachments/index.vue"

// Components
import ServiceOrderServiceAttachmentCard from "./service-order-service-attachment-card.vue"

export default {
  components: {
    ServiceOrderServiceAttachmentCard
  },

  extends: ServiceOrderAttachmentsIndex,

  props: {
    serviceOrderServiceId: { type: [String, Number], default: null }
  },

  data() {
    return {
      i18nScope: "tickets.drafts.contents.services.attachments.index",

      paperclipIllustration
    }
  },

  methods: {
    // @override Fetchable mixin
    fetchRequest() {
      return this.$newSdk.serviceOrders.drafts.services.attachments.all({ serviceOrderServiceId: this.serviceOrderServiceId })
    },

    async createRequest(attachment) {
      return this.$newSdk.serviceOrders.drafts.services.attachments.create({
        serviceOrderServiceId: this.serviceOrderServiceId,
        params:                _.pick(attachment, ["file"])
      })
    }
  }
}

</script>

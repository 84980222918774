<script>

// Extends
import SupplierDealMaintenancePackageVehicleModelGroup from "@/views/suppliers/deals/maintenance-packages/vehicle-model-group/show.vue"

export default {
  name: "SupplierDealProductVehicleModelGroup",

  extends: SupplierDealMaintenancePackageVehicleModelGroup,

  data() {
    return {
      i18nScope: "suppliers.deals.products.vehicle-model-group"
    }
  }
}

</script>

<style lang="scss" scoped>
.label {
  font-family: $secondary-font;
}

.summary {
  align-items: stretch;
  display: flex;
  gap: 40px;
}

.summary-item {
  flex-grow: 1;
}

.separator {
  border-left: 1px solid $light-gray-3;
}

.value {
  em {
    font-weight: 300;
  }
}
</style>

<template lang="pug">
  service-order-section-layout.service-order-quote-summary-section
    template(#icon)
      i.far.fa-info
    .summary
      .summary-item
        .label {{ $t(".issued-at.label") }}
        .value
          template(v-if="hasIssuedAt") {{ issuedAt }}
          em(v-else) {{ $t("placeholder.unavailable") }}

      .separator

      .summary-item
        .label {{ $t(".target-execution-time.label") }}
        .value
          template(v-if="hasTargetExecutionTime") {{ targetExecutionTime }}
          em(v-else) {{ $t("placeholder.unavailable") }}

      .separator

      .summary-item
        .label {{ $t(".approval-group.label") }}
        .value
          template(v-if="hasApprovalGroup") {{ approvalGroup }}
          em(v-else) {{ $t("placeholder.unavailable") }}

      .separator

      .summary-item
        .label {{ $t(".consultant-name.label") }}
        .value
          template(v-if="hasConsultantName") {{ consultantName }}
          em(v-else) {{ $t("placeholder.unavailable") }}
</template>

<script>
import ServiceOrderSection from "./service-order-section"

export default {
  extends: ServiceOrderSection,

  computed: {
    approvalGroup() {
      if (!this.hasApprovalGroup) return ""

      const { approvalGroup } = this.serviceOrder.quote

      return this.$t(`enums.service-orders.VETOR_APPROVAL_GROUPS.${approvalGroup}`)
    },

    consultantName() {
      return this.serviceOrder.consultantName
    },

    hasApprovalGroup() {
      return this.serviceOrder.quote?.$present("approvalGroup") || false
    },

    hasConsultantName() {
      return this.serviceOrder.$present("consultantName")
    },

    hasIssuedAt() {
      return this.serviceOrder.$present("issuedAt")
    },

    hasTargetExecutionTime() {
      return this.serviceOrder.quote?.$present("targetExecutionTime") || false
    },

    i18nScope() {
      return "monitoring.service-orders.service-order-quote-summary-section"
    },

    issuedAt() {
      return this.$l("time.formats.short", this.serviceOrder.issuedAt)
    },

    targetExecutionTime() {
      if (!this.hasTargetExecutionTime) return ""

      const count = this.serviceOrder.quote.targetExecutionTime

      return this.$t("count.hours", { count })
    }
  }
}
</script>


<style scoped lang="scss">

.vehicle-part-show {
  padding-bottom: 24px;
}

.header {
  padding-top: 24px;
}

.title {
  font-family: $secondary-font;
  font-size: 30px;
  font-weight: bold;
  word-break: normal;
  overflow-wrap: break-word;
}

.column + .column {
  margin-left: 8px;
}

.id-tag {
  margin: 0 8px;
}

.subtitle-icon {
  margin-right: 16px;
  font-size: 24px;
}

.list {
  padding-top: 24px;
}

.symptoms{
  font-family: $secondary-font;
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
}

.parts-tag.app-tag {
  margin-bottom: 8px;
  font-family: $secondary-font;
  font-size: 16px;
  display: flex;

  ::v-deep .app-tag-label{
    padding: 8px 16px;
  }
}

.btn + .btn {
  margin-left: 8px;
}

.icon {
  font-size: 16px;
  margin-right: 8px;
}

.description-content {
  margin-top: 8px;

  .description {
    font-size: 16px;
    font-weight: normal;
    margin: 0;
  }
}

.subtitle {
  font-family: $secondary-font;
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  padding: 24px 0;
}

.section {
  padding-bottom: 24px;
  border-bottom: 1px solid $light-gray-3;
}

.margin-bottom{
  margin-bottom: 8px;
}

.footer {
  margin-top: 24px;
}
</style>


<template lang="pug">

  .vehicle-part-show
    loading-lines(v-if="fetching", :lines="4", data-testid="loading")
    template(v-else)
      .section
        header.header.flex.space-between.vertical-center
          h2.title
            span {{ resource.name }}
            id-tag.id-tag(:value="resource.id", size="big")
          .actions.flex
            destroy-action.btn(
              :vehiclePartId="id",
              :name="resource.name",
              @destroy="onDestroy",
              data-test="destroyAction"
            )
              i.fal.fa-trash-alt.icon
              span {{ $t('.btn.destroy') }}

            app-button.btn(
              v-if="$can('update', 'VehiclePart')",
              theme="neutral",
              size="small",
              :to="{ name: 'editVehiclePart', params: { id }}"
            )
              i.fal.fa-pencil.icon
              span {{ $t('.btn.edit') }}

        section.description-content.preserve-whitespace(v-if="resource.description")
          p.description {{ resource.description }}

      section.section
        ui-row
          ui-col(:span="6")
            h3.subtitle {{ $t('.position.label')}}
            .flex
              .column
                app-tag.parts-tag(
                  color="orange-light",
                  :value="$t('.position.tag.front')",
                  :disabled="!resource.frontPosition"
                )
              .column
                app-tag.parts-tag(
                  color="orange-light",
                  :value="$t('.position.tag.central')",
                  :disabled="!resource.centralPosition"
                )
              .column
                app-tag.parts-tag(
                  color="orange-light",
                  :value="$t('.position.tag.rear')",
                  :disabled="!resource.rearPosition"
                )

          ui-col(:span="6")
            h3.subtitle {{ $t('.location.label')}}
            .flex
              .column.flex.column-direction
                app-tag.parts-tag(
                  color="orange-light",
                  :value="$t('.location.tag.right')",
                  :disabled="!resource.rightLocation"
                )
                app-tag.parts-tag(
                  color="orange-light",
                  :value="$t('.location.tag.left')",
                  :disabled="!resource.leftLocation"
                )

              .column.flex.column-direction
                app-tag.parts-tag(
                  color="orange-light",
                  :value="$t('.location.tag.internal')",
                  :disabled="!resource.internalLocation"
                )
                app-tag.parts-tag(
                  color="orange-light",
                  :value="$t('.location.tag.external')",
                  :disabled="!resource.externalLocation"
                )

              .column.flex.column-direction
                app-tag.parts-tag(
                  color="orange-light",
                  :value="$t('.location.tag.bottom')",
                  :disabled="!resource.bottomLocation"
                )
                app-tag.parts-tag(
                  color="orange-light",
                  :value="$t('.location.tag.top')",
                  :disabled="!resource.topLocation"
                )

      .section
        .flex.vertical-center.list
          i.subtitle-icon.fal.fa-engine-warning
          h3.symptoms {{ $t('.symptomsHeading') }}

        symptoms-list(
          :loading="fetching",
          :symptoms="resource.symptoms",
          :empty-text="$t('.fields.symptoms.empty')"
        )

      section.footer.flex.space-between
        app-button(:to="{ name: 'vehicleParts' }", outline)
          i.far.fa-arrow-left.icon
          span {{ $t('.btn.back') }}

        app-button(
          v-if="$can('create', 'VehiclePart')",
          theme="link",
          :to="{ name: 'newVehiclePart' }"
        )
          span {{ $t('.btn.new') }}

</template>


<script>

// Mixins
import { mixins } from "movida-common.vue"

// Components
import SymptomsList from "./components/symptoms-list.vue"
import DestroyAction from "./components/destroy-action.vue"

// Extends
import View from "@/views/view"

const { Fetchable } = mixins

export default {
  name: "VehiclePart",

  components: {
    SymptomsList,
    DestroyAction
  },

  extends: View,

  mixins: [Fetchable],

  data() {
    return {
      i18nScope: "vehicle-parts.show",

      id: this.$route.params.id,

      // @override Fetchable mixin
      model: "VehiclePart"
    }
  },

  methods: {
    onDestroy() {
      this.$router.push({ name: "vehicleParts" })
    },

    // @override Fetchable mixin
    fetch() {
      return this.$sdk.vehicleParts.get({ id: this.id })
    },

    // @override Fetchable mixin
    // onFetchSuccess(response) {},

    // @override Fetchable mixin
    onFetchError(err) {
      if (this.hasViewError(err)) {
        this.appError = err
      }

      this.$err.log(err)
      this.$notifications.error(this.$t(".notifications.fetch.failure"))
    }
  }
}

</script>

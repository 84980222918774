<style lang="scss" scoped>

.messages-modal {
  --messages-body-height:   510px;
  --messages-header-height: 73px;
  --messages-index-width:   330px;
  --message-form-width:     400px;

  .body {
    height: var(--messages-body-height);
    position: relative;

    .index {
      width: var(--messages-index-width);
      overflow-x: hidden;
      overflow-y: auto;

      border-right: 1px solid $light-gray-3;
    }

    .show {
      background-color: $light-gray;
      width: 100%;

      &.overlayed {
        width: calc(1156px - var(--messages-index-width) - var(--message-form-width));
        overflow-x: auto;
      }

      &.empty {
        position: relative;

        > .message {
          position: absolute;
          top: 20%;
          left: 50%; /* position the left edge of the element at the middle of the parent */

          transform: translateX(-50%);
          font-style: italic;
        }
      }
    }

    .new {
      width: var(--message-form-width);
      box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
      background-color: $white;
      height: calc(var(--messages-header-height) + var(--messages-body-height));
      position: absolute;
      right: 0;
      top: calc(0px - var(--messages-header-height));
    }
  }
}

</style>


<template lang="pug">

  app-modal(
    :close-button="!showNewMessageForm",
    :heading="$t('.heading')",
    :width="1156",
    @close="onClose"
  )
    .messages-modal
      messages-modal-header(
        :service-order="serviceOrder"
        @create="showNewMessageForm = true"
      )

      .body.flex
        messages.index.no-shrink(
          ref="messagesIndex",
          :service-order-id="serviceOrder.$id",
          :selected-message-id="selectedMessageId",
          @select="message => selectedMessage = message"
        )

        template(v-if="selectedMessage")
          message.show(
            :class="{ overlayed: showNewMessageForm }",
            :message-id="selectedMessage.id",
            @update="refresh"
          )

        template(v-else)
          .show.empty
            span.message.italic {{ $t(".empty.message") }}

        new-message.new(
          v-if="showNewMessageForm",
          :service-order="serviceOrder",
          @change="onNewMessage",
          @close="showNewMessageForm = false"
        )

</template>


<script>

// Components
import Message from "./show.vue"
import Messages from "./index.vue"
import MessagesModalHeader from "./_components/messages-modal-header.vue"
import NewMessage from "./new.vue"

export default {
  components: {
    Message,
    Messages,
    MessagesModalHeader,
    NewMessage
  },

  mixins: [],

  props: {
    serviceOrder: { type: Object, required: true }
  },

  data() {
    return {
      i18nScope: "monitoring.messages.messages-modal",

      selectedMessage: null,

      showNewMessageForm: false
    }
  },

  computed: {
    selectedMessageId() {
      return _.present(this.selectedMessage) ? this.selectedMessage.id : null
    }
  },

  methods: {
    refresh() {
      this.$refs.messagesIndex.fetch()
    },

    onNewMessage() {
      this.$refs.messagesIndex.fetch()
    },

    onClose() {
      this.$emit("close")
      this.$emit("update")
    }
  }
}

</script>

<style lang="scss" scoped>

.occurrence-option {
  font-size: 14px;
  padding: 12px;

  &.active,
  &.focus {
    background-color: $light-gray-2;
  }

  &.focus {
    cursor: pointer;

    .actions-column {
      .remove-icon-content {
        background: $light-gray-3;
        color: $primary;
      }
    }
  }

  .actions-column {
    min-width: 42px;
    margin-left: 12px;

    .remove-icon-content {
      display: inline-block;
      width: 24px;
      height: 24px;
      line-height: 24px;
      text-align: center;
      border-radius: 50px;
      color: $gray-2;
      transition: all 100ms linear;
    }
  }
}

</style>


<template lang="pug">

  .occurrence-option.flex.vertical-center(:class="{ focus, active }")
    .content-column.flex.grow
      occurrence-info(:occurrence="occurrence")

    .actions-column.center
      .remove-icon-content(v-if="active")
        i.fal.fa-times

</template>


<script>

import OccurrenceInfo from "../occurrence-info.vue"

export default {
  components: {
    OccurrenceInfo
  },

  props: {
    occurrence: { type: Object, required: true },
    active:     { type: Boolean, default: false },
    focus:      { type: Boolean, default: false }
  },

  data() {
    return {
      i18nScope: "tickets.drafts.vehicles.components.occurrence-select-field.occurrence-option"
    }
  }
}

</script>

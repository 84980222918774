import SparkMD5 from "spark-md5"

class FileChecksum {
  static create(file) {
    const instance = new FileChecksum(file)
    return instance.create()
  }

  constructor(file) {
    this.file = file
    this.chunkSize = 1048576 // 1MB
    this.chunkCount = Math.ceil(this.file.size / this.chunkSize)
    this.chunkIndex = 0
  }

  create() {
    const promise = new Promise((resolve, reject) => {
      this.md5Buffer = new SparkMD5.ArrayBuffer()
      this.fileReader = new FileReader()
      this.fileReader.addEventListener("load", event => this.fileReaderDidLoad(event, resolve))
      this.fileReader.addEventListener("error", event => this.fileReaderDidError(event, reject))
      this.readNextChunk()
    })

    return promise
  }

  fileReaderDidLoad(event, resolve) {
    this.md5Buffer.append(event.target.result)

    if (this.chunkIndex < this.chunkCount || (this.chunkIndex === 0 && this.chunkCount === 0)) {
      this.readNextChunk()
    }
    else {
      const binaryDigest = this.md5Buffer.end(true)
      const base64digest = btoa(binaryDigest)
      resolve(base64digest)
    }
  }

  fileReaderDidError(event, reject) {
    reject(`Error reading ${this.file.name}`)
  }

  readNextChunk() {
    const start = this.chunkIndex * this.chunkSize
    const end = Math.min(start + this.chunkSize, this.file.size)
    const bytes = this.file.slice(start, end)
    this.fileReader.readAsArrayBuffer(bytes)
    this.chunkIndex++
  }
}

export default FileChecksum

import isObject from "./is-object"

// Ordena todos os arrays dentro de uma estrutura pela representação textual para comparações
function deepSort(value) {
  let sortedValue = value

  if (Array.isArray(value)) {
    for (let index = 0; index < value.length; index++) value[index] = deepSort(value[index])
    sortedValue = _.sortBy(value, element => JSON.stringify(element))
  }

  else if (isObject(value)) {
    sortedValue = {}
    for (const key of Object.keys(value)) sortedValue[key] = deepSort(value[key])
  }

  return sortedValue
}

export default deepSort

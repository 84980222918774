// Ability
import { ability } from "@/modules/ability"

// Routes
import Tickets from "@/views/tickets/index.vue"

// Drafts
import NewDraftTicket from "@/views/tickets/drafts/new.vue"
import EditDraftTicket from "@/views/tickets/drafts/edit.vue"

// Vehicle
import EditDraftTicketVehicle from "@/views/tickets/drafts/vehicles/edit.vue"
// Content
import EditDraftTicketContent from "@/views/tickets/drafts/contents/edit.vue"
// Booking
import DraftTicketBooking from "@/views/tickets/drafts/bookings/show.vue"
import EditDraftTicketBooking from "@/views/tickets/drafts/bookings/edit.vue"
// Confirmation
import newDraftTicketConfirmation from "@/views/tickets/drafts/confirmations/new.vue"

export default [
  {
    path:      "/tickets/draft/new",
    name:      "newDraftTicket",
    component: NewDraftTicket,
    meta:      {
      auth:        true,
      permissions: [() => ability.can("create", "Ticket")]
    }
  },

  {
    path:      "/tickets/:id/draft/edit",
    name:      "editDraftTicket",
    component: EditDraftTicket,
    redirect:  { name: "editDraftTicketVehicle" },
    meta:      {
      auth:        true,
      permissions: [() => ability.can("update", "Ticket")]
    },

    children: [
      {
        path:      "",
        name:      "editDraftTicketVehicle",
        component: EditDraftTicketVehicle
      },

      {
        path:      "/tickets/:id/draft/content/edit",
        name:      "editDraftTicketContent",
        component: EditDraftTicketContent,
        meta:      {
          auth:        true,
          permissions: [() => ability.can("update", "Ticket")]
        }
      },

      {
        path:      "/tickets/:id/draft/booking",
        name:      "draftTicketBooking",
        component: DraftTicketBooking,
        meta:      {
          auth:        true,
          permissions: [() => ability.can("update", "Ticket")]
        }
      },

      {
        path:      "/tickets/:id/draft/booking/edit",
        name:      "editDraftTicketBooking",
        component: EditDraftTicketBooking,
        meta:      {
          auth:        true,
          permissions: [() => ability.can("update", "Ticket")]
        }
      },

      {
        path:      "/tickets/:id/draft/confirmation/new",
        name:      "newDraftTicketConfirmation",
        component: newDraftTicketConfirmation,
        meta:      {
          auth:        true,
          permissions: [() => ability.can("update", "Ticket")]
        }
      }
    ]
  },

  {
    path:      "/tickets",
    name:      "tickets",
    component: Tickets,
    meta:      {
      auth:        true,
      permissions: [() => ability.can("index", "Ticket")]
    }
  }
]

<style scoped lang="scss">

.footer {
  height: 40px;
  border: 1px solid $light-gray-3;
  background: $light-gray;

  .row {
    flex-grow: 1;
    padding: 0 16px;
    text-align: right;
    font-size: 16px;
    font-weight: bold;
    color: $dark-gray;
    text-transform: uppercase;
    height: 100%;

    .icon {
      margin-right: 8px;
    }
  }

  .total {
    width: 150px;
    border-left: 1px solid $light-gray-3;
    padding: 0 16px;
    font-size: 16px;
    font-weight: bold;
    color: $green;
    text-align: right;
    height: 100%;
  }
}

</style>


<template lang="pug">

  .footer.flex.vertical-center.space-between(slot="footer")
    span.row.flex.vertical-center
      i.icon.fas.fa-file-invoice-dollar
      | {{ $t('.total') }}
    .total.flex.vertical-center.end
      loading-lines.subtotal-loading(v-if="loading", :min="100", :max="100")
      span(v-else) {{ $l('currency', total) }}

</template>


<script>

export default {
  props: {
    total:   { type: Number, default: 0 },
    loading: { type: Boolean, default: false }
  },

  data() {
    return {
      i18nScope: "stores.tickets.service-orders.show.total-row"
    }
  }
}

</script>

// Libs
import HttpError from "@/lib/http-error"
import normalize from "@/lib/normalize"


class BaseRequests {
  constructor(http) {
    this.http = http
  }

  action({
    method,
    url,
    params = {},
    options: {
      model = null,
      raw = false,
      headers = {},
      token = null
    } = {}
  } = {}) {
    const source = this.http.CancelToken.source()

    const config = {
      method,
      url,
      headers,

      // Parâmetros de query devem ser passados como "params" e dados de _body_ como "data"
      // ref https://github.com/axios/axios#request-config
      [["get", "delete"].includes(method) ? "params" : "data"]: params,

      cancelToken: source.token
    }

    if (_.present(token)) config.headers[this.http.data.authorizationHeader] = `Bearer ${token}`

    const request = this.http.router(config)
      .then(response => ({
        ...response,
        data: normalize(response.data, { camelize: !raw, constructor: model })
      }))
      .catch(err => {
        const error = {
          originalError: err,
          status:        _.get(err, "response.status"),
          cancelled:     this.http.isCancel(err)
        }

        return Promise.reject(error.status ? new HttpError(error) : error)
      })

    request.cancel = source.cancel

    return request
  }

  verifyData(method, options) {
    Object.keys(options).forEach(param => {
      if (_.blank(options[param])) {
        throw new Error(`NewSDK ${this.constructor.name} - #${method}() - "${param}" is a required prop`)
      }
    })
  }

}


export default BaseRequests

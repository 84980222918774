<style lang="scss" scoped>

.destroy-supplier-service-support-service-vehicle-model-groups {
  .form-body {
    padding: 24px 32px;
  }
}

</style>


<template lang="pug">

  app-modal(
    full-height
    footer,
    :width="1156",
    :heading="$t('.modal.heading')",
    :confirm-label="$t('btn.destroy.label')",
    :loading="submitting",
    @close="goBack",
    @confirm="submit"
  )
    .destroy-supplier-service-support-service-vehicle-model-groups.flex.column-direction.full-height
      form.form.flex.column-direction.grow(@submit.prevent="submit", method="post")
        .form-body
            service-vehicle-model-group-select-list(
              v-model="resource.ids",
              :errors="errors",
              :supplier-id="supplierId",
              :service-type="serviceType"
            )

</template>


<script>

// Mixins
import FormMixin from "@/mixins/form-mixin"

// Extends
import BaseView from "@/views/base-view"

// Components
import ServiceVehicleModelGroupSelectList from "./destroy/select-list.vue"

// Models
import models from "@/models"
const { Base, Service } = models

class ServiceVehicleModelGroupsBatchDestroy extends Base {
  static get attrs() {
    return ["ids"]
  }

  static get constraints() {
    return {
      ids: { presence: true }
    }
  }
}

export default {
  name: "DestroySupplierServiceSupportServiceVehicleModelGroups",

  components: {
    ServiceVehicleModelGroupSelectList
  },

  extends: BaseView,

  mixins: [FormMixin],

  data() {
    return {
      i18nScope: "suppliers.service-supports.service-vehicle-model-groups.destroy",
      routeName: "destroySupplierServiceSupportServiceVehicleModelGroups",

      supplierId:      this.$route.params.id,
      serviceTypeSlug: this.$route.params.type,

      resource: new ServiceVehicleModelGroupsBatchDestroy({ ids: [] })
    }
  },

  computed: {
    serviceType() {
      return Service.parseParamType(this.serviceTypeSlug)
    },

    empty() {
      return _.blank(this.resource.ids)
    },

    backRoute() {
      return {
        name:   "supplierServiceSupportServiceVehicleModelGroups",
        params: { id: this.supplierId, type: this.serviceTypeSlug }
      }
    }
  },

  created() {
    this.initialSerializedAttributes = this.serializeAttributes()
  },

  methods: {
    goBack() {
      this.$router.push(this.backRoute)
    },

    // @override Form mixin
    serializeAttributes() {
      return this.resource.$serialize()
    },

    // @override Form mixin
    submitRequest() {
      const params = this.resource.$serialize()

      return this.$newSdk.suppliers.serviceVehicleModelGroups.batches.destroy({ supplierId: this.supplierId, params })
    },

    // @override Form mixin
    onSubmitSuccess(response) {
      this.$notifications.info(this.$t(".notifications.submit.success", { count: this.resource.ids.length }))

      this.$emit("change")
      this.$router.push(this.backRoute)
    }
  }
}

</script>

<style lang="scss" scoped>

.service-row {
  display: grid;
  grid-template-columns: var(--grid-columns);

  .column {
    padding: 12px;
    border-bottom: 1px solid $light-gray-3;
    overflow: hidden;

    .id-tag {
      margin-left: 4px;
    }

    .empty {
      font-family: $primary-monospace-font;
      font-weight: 400;
      font-size: 14px;
      color: $gray-2;
    }

    &.service-column {
      font-weight: 400;

      .name {
        font-family: $secondary-font;
        font-size: 14px;
        display: inline;
      }

      .description {
        font-size: 12px;
        color: $gray-3;
      }
    }

    &.attachments-column {
      .icon {
        color: $gray-2;
        margin-right: 4px;
      }
    }

    &.attachments-column,
    &.quantity-column {
      font-weight: 400;
      font-size: 14px;
      font-family: $primary-monospace-font;
    }

    &.parts-column {
      .issue {
        line-height: 14px;

        .part,
        .symptom {
          font-family: $secondary-font;
          font-weight: 400;
          font-size: 14px;
        }

        .diamond-icon {
          position: relative;
          bottom: 3px;
        }
      }

      .location,
      .position {
        font-size: 12px;
        color: $gray-3;
        font-weight: 400;
      }

      .diamond-icon {
        font-size: 4px;
        font-weight: 900;
        color: $gray-3;
        width: 4px;
        height: 4px;
        margin: 4px;
      }
    }
  }
}

</style>


<template lang="pug">

  .service-row(:style="styles")
    .column.type-column.flex.center.vertical-center
      service-type-icon(:type="service.type")

    .column.service-column.flex.column-direction.center
      .name-row
        app-span.name(:value="service.name")
        id-tag.id-tag(:value="service.id")
      app-span.description(:value="service.description", crop)

    .column.quantity-column.flex.vertical-center.end
      app-span(:value="serviceOrderService.quantity")

    .column.parts-column.flex.center.column-direction
      .issue
        app-span.part(:value="vehiclePart.name")
        i.diamond-icon.fal.fa-diamond
        app-span.symptom(:value="symptom.name")

      template(v-for="target in targets")
        .flex.vertical-center.wrap
          app-span.position(:value="VehiclePartTarget.$tEnum('position', target.position)")
          i.diamond-icon.fal.fa-diamond
          app-span.position(:value="VehiclePartTarget.$tEnum('location', target.location)")

    .column.attachments-column.flex.vertical-center
      template(v-if="hasAttachments")
        .attachments
          i.icon.far.fa-paperclip
          app-span.count(:value="serviceOrderService.attachmentsCount")
      template(v-else)
        span.empty -

    .column.actions-column.flex.vertical-center
      popover-menu(:show.sync="showPopover", :loading="deleting || loading")
        popover-menu-button(
          icon="fal fa-eye",
          :text="$t('.actions.btn.show.label')",
          :disabled="loading",
          @click="showServiceModal = true",
          @close-menu="showPopover = false"
        )
        popover-menu-button(
          v-if="editable",
          icon="fal fa-pencil",
          :text="$t('.actions.btn.edit.label')",
          :disabled="loading",
          @click="showEditServiceModal = true",
          @close-menu="showPopover = false"
        )
        popover-menu-button(
          v-if="editable",
          icon="fal fa-trash-alt",
          :text="$t('.actions.btn.destroy.label')",
          :disabled="loading",
          @close-menu="showPopover = false",
          @click="destroy"
        )

    show-service(
      v-if="!loading && showServiceModal",
      :service-order-id="serviceOrderId",
      :service-order-service-id="serviceOrderService.id",
      :editable="editable",
      @close="showServiceModal = false",
      @edit="showServiceModal = false; showEditServiceModal = true"
    )

    edit-service(
      v-if="editable && !loading && showEditServiceModal",
      :service-order-id="serviceOrderId",
      :service-order-service-id="serviceOrderService.id",
      @close="showEditServiceModal = false",
      @update="$emit('update')"
    )

</template>


<script>

// Components
import ShowService from "../show.vue"
import EditService from "../edit.vue"

// Models
import models from "@/models"
const { VehiclePartTarget } = models

export default {
  components: {
    ShowService,
    EditService
  },

  props: {
    serviceOrderService: { type: Object, required: true },
    serviceOrderId:      { type: [String, Number], required: true },
    loading:             { type: Boolean, default: false },
    columnSizes:         { type: String, required: true },
    editable:            { type: Boolean, default: false }
  },

  data() {
    return {
      i18nScope: "tickets.drafts.contents.services.index.service-row",

      VehiclePartTarget,

      showPopover: false,

      showServiceModal:     false,
      showEditServiceModal: false,

      deleting: false
    }
  },

  computed: {
    styles() {
      return {
        "--grid-columns": this.columnSizes
      }
    },

    hasAttachments() {
      return this.serviceOrderService.attachmentsCount > 0
    },

    vehicleIssueSolution() {
      return _.get(this.serviceOrderService, "vehicleIssueSolution") || {}
    },

    service() {
      return _.get(this.vehicleIssueSolution, "service") || {}
    },

    vehicleIssue() {
      return _.get(this.vehicleIssueSolution, "vehicleIssue") || {}
    },

    vehiclePart() {
      return _.get(this.vehicleIssue, "vehiclePart") || {}
    },

    symptom() {
      return _.get(this.vehicleIssue, "symptom") || {}
    },

    targets() {
      return _.get(this.serviceOrderService, "targets") || []
    }
  },

  methods: {
    async destroy() {
      const isConfirmed = await this.$confirm({
        heading: this.$t(".destroyConfirmation.title"),
        message: this.$t(".destroyConfirmation.message", { name: this.service.name }),

        actions: {
          confirm: this.$t(".destroyConfirmation.confirm")
        }
      })

      if (!isConfirmed) return

      try {
        this.deleting = true
        await this.$newSdk.serviceOrders.drafts.services.destroy({ serviceOrderId: this.serviceOrderId, serviceOrderServiceId: this.serviceOrderService.id })

        this.$notifications.info(this.$t(".notifications.destroy.success", { name: this.service.name }))
        this.$emit("update")
      }
      catch (error) {
        this.$err.log(error)
        this.$notifications.error(this.$t(".notifications.destroy.error"))
      }
      finally {
        this.deleting = false
      }
    }
  }
}

</script>

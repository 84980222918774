<style scoped lang="scss">

.service-row {
  border-top: 1px solid $light-gray-3;

  .service-row-container {
    height: 69px;

    &.destroyed {
      background-color: $light-gray-2;
      font-family: $secondary-font;
      font-weight: 400;

      .undo {
        font-weight: normal;
        margin-left: 8px;
      }
    }

    &:hover {
      background-color: $light-gray;
    }

    &.disabled {
      background-color: $light-gray-2;
      cursor: not-allowed;
    }

    .side-label {
      width: 8px;
      height: 100%;
      background-color: $light-gray-2;

      &.active {
        background-color: $success;
      }
    }

    .spacer {
      width: 48px;
      height: 100%;

      .arrow-button {
        &.movida-common__app-button.link {
          color: $gray-3;

          &:hover {
            color: $orange;
            border-radius: 50%;
            background-color: $light-gray-3;
          }
        }

        .tree-icon {
          cursor: pointer;
          font-weight: 900;
          width: 20px;
          height: 20px;
          font-size: 14px;
          transition: transform .1s ease;

          &.leaf {
            font-size: 16px;
            font-weight: 300;
            cursor: unset;
          }

          &.open {
            transform: rotate(90deg);
          }
        }
      }

      .status-icon {
        width: 20px;
        height: 20px;
        font-size: 20px;
        color: $dark-success;
      }
    }

    .content-column {
      overflow: hidden;

      .content {
        overflow: hidden;
        margin-left: 12px;
        margin-right: 24px;

        .service-name {
          font-size: 16px;
          font-weight: normal;
          color: $dark-gray;
          word-break: normal;
          overflow-wrap: break-word;
          margin-bottom: 4px;
          display: block;

          &.active:hover {
            color: $primary;
          }
        }

        .description {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          display: block;

          font-size: 12px;
        }
      }
    }

    .right-side {
      .added {
        color: $success;
        font-size: 14px;
        font-weight: 400;

        span {
          font-style: italic;
          margin-left: 4px;
        }
      }

      .actions {
        margin: 0 26px;

        .remove-button {
          font-size: 16px;

          &:hover {
            background-color: $light-gray-3;
            transition: 0s;
          }
        }
      }
    }
  }
}

</style>


<template lang="pug">

  .service-row
    template(v-if="isMarkedForDestruction")
      .service-row-container.destroyed.flex.vertical-center.center
        span {{ $t('.destroyed', { name: service.name }) }}
        app-button.undo(
          theme="link",
          @click="$emit('undo-remove', service)"
        ) {{ $t('.undo') }}

    template(v-else)
      .service-row-container.flex.vertical-center(
        :class="{ disabled }"
        :data-testid="`service-${service.id}`"
      )
        .side-label.no-shrink.active

        .spacer.flex.center.vertical-center.no-shrink
          i.status-icon.active.fas.fa-check-circle

        .spacer.flex.center.vertical-center.no-shrink
          service-type-icon.service-type-icon(:type="service.type")

        .content-column.flex.vertical-center.grow
          .content
            .flex.vertical-center
              span.service-name {{ service.name }}

              service-kind-icon(:kind="service.kind")

            .details.flex.vertical-center(v-if="service.description")
              span.description {{ service.description }}

        .right-side.flex.vertical-center.no-shrink(v-if="edit")
          .added(v-if="!isPersisted")
            i.icon.far.fa-check
            span {{ $t('.added') }}

          .actions.flex.vertical-center
            app-button.remove-button(
              theme="neutral",
              size="small",
              :disabled="disabled",
              @click="$emit('remove', service)",
              data-testid="removeService"
            )
              i.fal.fa-trash-alt

</template>


<script>

export default {
  props: {
    service:    { type: Object, default: () => ({}) },
    depth:      { type: Number, default: 0 },
    disabled:   { type: Boolean, default: false },
    initialIds: { type: Array, default: () => [] },
    edit:       { type: Boolean, default: false }
  },

  data() {
    return {
      i18nScope: "service-packages.components.service-row"
    }
  },

  computed: {
    isMarkedForDestruction() {
      return this.service._destroy === true
    },

    isPersisted() {
      return this.initialIds.includes(this.service.id)
    }
  }
}

</script>

<style lang="scss" scoped>

.contact-row-loading {
  height: 60px;
  border-top: 1px solid $light-gray-3;

  td {
    &:first-of-type .loading-lines {
      margin-left: 16px;
    }

    &:last-of-type .loading-lines {
      margin-right: 16px;
    }
  }
}

</style>


<template lang="pug">

  tr.contact-row-loading
    td
      loading-lines.loading-lines

    td
      loading-lines.loading-lines

    td
      loading-lines.loading-lines

    td
      loading-lines.loading-lines(:min="100", :max="100")

</template>


<script>

export default {}

</script>

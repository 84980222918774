<style lang="scss" scoped>

.new-supplier-deal-products-data-load {
  .body {
    .form {
      .replace-icon {
        width: 56px;
        height: 56px;
        margin-bottom: 16px;
      }

      .fields {
        margin-top: 24px;

        .checkbox-label {
          margin-right: 4px;

          .replace-all {
            font-family: $secondary-font;
            font-weight: 500;
            font-size: 14px;
          }
        }
      }
    }

    .alert {
      .icon {
        font-size: 14px;
      }
    }
  }
}

</style>


<template lang="pug">

  app-modal.new-supplier-deal-products-data-load(
    :close-button="!confirming",
    :heading="heading",
    :width="modalWidth",
    @close="goBack"
  )
    .body
      form.form.flex.column-direction.grow(@submit.prevent="submit", method="post")
        template(v-if="confirming")
          .flex.center.vertical-center.column-direction
            img.replace-icon(:src="replaceIcon")
            span {{ $t('.replace.message') }}

        template(v-else)
          upload-area(
            v-model="resource.file",
            :file.sync="fileData",
          )

          .fields
            checkbox-field(
              name="replaceAll",
              v-model="resource.replaceAll",
              :loading="submitting",
              :errors="errors.replaceAll"
            )
              template(#optionText="{ props: { inputId, disabled, error: hasErrors } }")
                label.checkbox-label(
                  :for="inputId",
                  :class="{ disabled, loading: submitting, error: hasErrors }"
                )
                  span.replace-all {{ $t('.fields.replaceAll.label') }}

                .alert(v-tooltip.bottom="{ offset: '4', content: $t('.fields.replaceAll.tooltip') }")
                  i.icon.fal.fa-info-circle


    template(#footer)
      .footer
        app-button(
          outline,
          theme="gray",
          @click="goBack",
        ) {{ $t('btn.cancel') }}

        app-button(
          data-testid="submitForm",
          @click="verifyAndSubmit",
          :disabled="!uploaded"
        )
          span {{ $t('.btn.import.label') }}


</template>


<script>

// Mixins
import { mixins } from "movida-common.vue"

// Models
import models from "@/models"

// Extends
import View from "@/views/view"

// Components
import UploadArea from "./new/upload-area"

// Assets
import replaceIcon from "@/assets/images/icons/replace.svg"

const { FormMixin } = mixins
const { DealDataLoad } = models

export default {
  name: "NewSupplierDealProductsDataLoad",

  components: {
    UploadArea
  },

  extends: View,

  mixins: [FormMixin],

  props: {
    backRouteQuery: { type: Object, default: () => ({}) }
  },

  data() {
    return {
      i18nScope: "suppliers.deals.products.data-loads.new",

      id:     this.$params.asInteger(this.$route.params.id),
      dealId: this.$params.asInteger(this.$route.params.dealId),

      replaceIcon,

      resource: new DealDataLoad(),
      fileData: {},

      confirming: false
    }
  },

  computed: {
    modalWidth() {
      return this.confirming ? 520 : 996
    },

    backRoute() {
      return {
        name:   "supplierDealProducts",
        params: { id: this.id, dealId: this.dealId },
        query:  this.backRouteQuery
      }
    },

    heading() {
      if (this.confirming) return null
      return this.$t(".heading")
    },

    uploaded() {
      return _.present(this.resource.file)
    }
  },

  methods: {
    // @override extends view.vue
    goBack() {
      if (this.confirming) this.confirming = false
      // Voltando para rota pai ao fechar o modal
      else this.$goBack({ fallback: this.backRoute })
    },

    verifyAndSubmit() {
      if (this.confirming || !this.resource.replaceAll) this.submit()
      else this.confirming = true
    },

    // @override Form mixin
    submitRequest() {
      return this.$sdk.deals.createProductsDataLoad({ id: this.dealId, params: this.resource.$serialize() })
    },

    // @override Form mixin
    submitSuccess({ data }) {
      this.$notifications.info(this.$t(".notifications.submit.success"))

      this.$router.push({
        name:   "supplierDealProductsDataLoad",
        params: { id: this.id, dealId: this.dealId, dataLoadId: data.id },
        // XXX: Flag para identificar a origem da navegação
        query:  { new: true }
      })
    },

    // @override Form mixin
    submitError(err) {
      this.$err.log(err)
      this.$notifications.error(this.$t(".notifications.submit.error"))

      this.confirming = false
    }
  }
}

</script>

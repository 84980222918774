.<style lang="scss" scoped>

.supplier-maintenance-vehicle-model-groups-table-header {
  display: grid;
  grid-template-columns: 80px 2fr 2fr 1fr 64px;
  background-color: $light-gray;
  height: 40px;
  border-bottom: 1px solid $light-gray-3;
  border-top: 1px solid $light-gray-3;

  &.loading {
    border-bottom: 2px solid $light-orange-2;
  }

  .unsortable {
    padding: 0 12px;
  }

  .column {
    font-size: 12px;

    .icon {
      font-size: 16px;
      color: $gray-2;
      margin-right: 8px;
    }

    .diamond-icon {
      font-size: 8px;
      font-weight: 900;
      color: $gray-3;
      width: 8px;
      height: 8px;
    }

    .text {
      font-family: $secondary-font;
      font-size: 14px;
      color: $dark-gray;
      font-weight: 400;
    }

    .loader-icon {
      font-size: 16px;
      color: $orange;
    }
  }
}

</style>


<template lang="pug">

  .supplier-maintenance-vehicle-model-groups-table-header(:class="{ loading }")
    .column.unsortable.flex.vertical-center(data-testid="idColumn")
      span.text #

    .column.flex.unsortable.vertical-center(data-testid="nameColumn")
      span.text {{ $t('.columns.name.label') }}

    .column.flex.unsortable.vertical-center(data-testid="descriptionColumn")
      span.text {{ $t('.columns.description.label') }}

    .column.unsortable.flex.vertical-center(data-testid="kindColumn")

    .column.flex.unsortable.vertical-center.center
      template(v-if="loading")
        i.loader-icon.fas.fa-spin.fa-spinner
      template(v-else)
        i.diamond-icon.fas.fa-diamond

</template>


<script>

export default {
  props: {
    loading:   { type: Boolean, default: false },
    sortParam: { type: String, required: true },
    sortOrder: { type: String, required: true }
  },

  data() {
    return {
      i18nScope: "vehicle-model-groups.maintenance-tables.index.vehicle-model-group-maintenance-tables-table-header"
    }
  },

  methods: {
    isSortedBy(param) {
      return this.sortParam === param
    }
  }
}

</script>

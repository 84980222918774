<style lang="scss" scoped>

.show {

  .issue-solution {
    position: relative;
    // padding: 16px 24px;
    border-bottom: 1px solid $light-gray-3;

    .edit-button {
      position: absolute;
      top: 8px;
      right: 24px;
    }
  }

  .error,
  .loading,
  .section {
    padding: 16px 24px;
  }

  .error {
    .icon {
      font-size: 18px;

      &.spin {
        animation: fa-spin 0.6s infinite linear;
        cursor: wait;
      }
    }

    .message {
      font-style: italic;
      margin: 8px 0;
    }

    .reload {
      font-size: 14px;
    }
  }

  .section {
    & + .section {
      border-top: 1px solid $light-gray-3;
    }

    .empty-section {
      height: 238px;

      .illustration {
        --size: 48px;
        width: var(--size);
        height: var(--size);
      }

      .empty-message {
        font-weight: 300;
        font-size: 14px;
        font-style: italic;
        margin-top: 8px;
      }
    }
  }
}

</style>


<template lang="pug">

  app-modal(
    :width="1156",
    :heading="$t('.modal.heading')",
    @close="$emit('close')"
  )
    .show
      template(v-if="erred")
        .error.flex.vertical-center.center.column-direction.no-shrink
          template(v-if="fetching")
            i.icon.fas.fa-spinner-third.spin
          template(v-else)
            i.icon.fas.fa-times

          span.message {{ $t('.fetch-erred.message') }}
          app-button.reload(theme="link", :loading="fetching", @click="fetch") {{ $t('.fetch-erred.retry') }}

      template(v-else-if="fetching")
        loading-lines.loading(:lines="5")

      template(v-else)
        .issue-solution
          issue-solution-card(
            :vehicle-issue-solution-id="resource.vehicleIssueSolutionId",
            :targets="resource.targets"
          )

          app-button.edit-button(
            v-if="editable",
            type="button",
            theme="neutral",
            @click="$emit('edit')"
          )
            i.fal.fa-pencil

        .section.attachments
          service-attachments-index(:service-order-service-id="resource.id")
            template(#empty)
              .empty-section.flex.column-direction.center.vertical-center
                img.illustration(:src="paperclipIllustration")
                app-span.empty-message(:value="$t('.sections.attachments.empty.message')")

        .section.description
          template(v-if="resource.description")
            app-span(:value="resource.description")

          template(v-else)
            .empty-section.flex.column-direction.center.vertical-center
              img.illustration(:src="messageIllustration")
              app-span.empty-message(:value="$t('.sections.description.empty.message')")

</template>


<script>

// Components
import ServiceAttachmentsIndex from "./attachments/index.vue"
import IssueSolutionCard from "./show/issue-solution-card.vue"

// Assets
import paperclipIllustration from "@/assets/images/illustrations/paperclip.svg"
import messageIllustration from "@/assets/images/illustrations/message.svg"

// Mixins
import FetchMixin from "@/mixins/fetch-mixin"

export default {
  components: {
    ServiceAttachmentsIndex,
    IssueSolutionCard
  },

  mixins: [FetchMixin],

  props: {
    serviceOrderId:        { type: [String, Number], required: true },
    serviceOrderServiceId: { type: [String, Number], required: true },
    editable:              { type: Boolean, default: false }
  },

  data() {
    return {
      i18nScope: "tickets.drafts.contents.services.show",

      resource: {},

      paperclipIllustration,
      messageIllustration
    }
  },

  methods: {
    // @override Fetch mixin
    fetchRequest() {
      return this.$newSdk.serviceOrders.drafts.services.find({ serviceOrderId: this.serviceOrderId, serviceOrderServiceId: this.serviceOrderServiceId })
    }
  }
}

</script>

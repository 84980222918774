import Attachments from "./Attachments"
import Blobs from "./Blobs"
import Deals from "./Deals"
import ServicePackages from "./ServicePackages"
import MaintenancePackages from "./MaintenancePackages"
import Products from "./Products"
import VehicleModelGroupMaintenanceTables from "./VehicleModelGroupMaintenanceTables"
import VehicleModels from "./VehicleModels"
import VehicleIssues from "./VehicleIssues"
import VehicleIssuesSolutions from "./vehicle-issues/Solutions"
import VehicleParts from "./VehicleParts"

import Tickets from "./Tickets"
import TicketDrafts from "./tickets/Drafts"
import TicketDraftsBookings from "./tickets/drafts/Bookings"
import TicketDraftsPublications from "./tickets/drafts/Publications"

import VehicleModelGroups from "./VehicleModelGroups"
import VehicleModelGroupsMaintenanceTables from "./vehicle-model-groups/MaintenanceTables"
import VehicleModelGroupsMaintenanceTableSettings from "./vehicle-model-groups/MaintenanceTableSettings"
import VehicleModelGroupsSuppliers from "./vehicle-model-groups/Suppliers"
import VehicleOccurrences from "./vehicles/Occurrences"
import Vehicles from "./Vehicles"
import VehicleServiceOrders from "./vehicles/ServiceOrders"

import Settings from "./Settings"

import SupplierBlocks from "./SupplierBlocks"
import SupplierDetails from "./suppliers/Details"
import SupplierMaintenanceVehicleModelGroups from "./suppliers/MaintenanceVehicleModelGroups"
import SupplierServiceVehicleModelGroups from "./suppliers/ServiceVehicleModelGroups"
import SupplierServiceVehicleModelGroupBatches from "./suppliers/service-vehicle-model-groups/Batches"
import SupplierServiceSupports from "./suppliers/ServiceSupports"
import Suppliers from "./Suppliers"
import SupplierServiceOrders from "./suppliers/ServiceOrders"
import SupplierTickets from "./suppliers/Tickets"

import Stores from "./Stores"
import StoreServiceOrders from "./stores/ServiceOrders"
import StoreServiceSupports from "./stores/ServiceSupports"
import StoreSettings from "./stores/StoreSettings"
import StoreTickets from "./stores/Tickets"
import StoreSuppliers from "./stores/Suppliers"

import ServiceOrders from "./ServiceOrders"
import ServiceOrderDrafts from "./service-orders/Drafts"
import ServiceOrderDraftsAttachments from "./service-orders/drafts/Attachments"
import ServiceOrderDraftsAvailableSuppliers from "./service-orders/drafts/AvailableSuppliers"
import ServiceOrderDraftsServices from "./service-orders/drafts/Services"
import ServiceOrderDraftsServiceAttachments from "./service-orders/drafts/services/Attachments"

import Services from "./Services"
import ServiceVehicleIssues from "./services/VehicleIssues"

import Quotes from "./Quotes"
import QuoteBlobs from "./quotes/Blobs"
import ReviewsApprovals from "./quotes/reviews/Approvals"
import ReviewsChangesRequests from "./quotes/reviews/ChangesRequests"

import MonitoringAssistants from "./monitoring/Assistants"
import MonitoringConsultants from "./monitoring/Consultants"
import MonitoringProducts   from "./monitoring/Products"
import MonitoringServiceOrders from "./monitoring/ServiceOrders"
import MonitoringServiceOrdersAssignments from "./monitoring/ServiceOrderAssignments"
import MonitoringServiceOrdersAttachments from "./monitoring/service-orders/Attachments"
import MonitoringServiceOrdersQuotes from "./monitoring/service-orders/Quotes"
import MonitoringServiceOrdersSupplierMessages from "./monitoring/service-orders/Messages"
import MonitoringServiceOrdersTicketMessages from "./monitoring/service-orders/TicketMessages"
import MonitoringServices  from "./monitoring/Services"
import MonitoringSuppliers from "./monitoring/Suppliers"
import MonitoringVehicles  from "./monitoring/Vehicles"
import MonitoringVehicleServiceOrderProducts from "./monitoring/vehicles/ServiceOrderProducts"
import MonitoringVehicleServiceOrderServices from "./monitoring/vehicles/ServiceOrderServices"
// - events
import MonitoringServiceOrdersCancellation      from "./monitoring/service-orders/Cancellation"
import MonitoringServiceOrdersExecution         from "./monitoring/service-orders/Execution"
import MonitoringServiceOrdersReopenings        from "./monitoring/service-orders/Reopenings"
import MonitoringServiceOrdersPostponement      from "./monitoring/service-orders/Postponement"
import MonitoringServiceOrdersSupplierAuthorizationMailDeliveries from "./monitoring/service-orders/SupplierAuthorizationMailDeliveries"
import MonitoringServiceOrdersVehicleReceipt    from "./monitoring/service-orders/VehicleReceipt"
import MonitoringServiceOrdersVehicleWithdrawal from "./monitoring/service-orders/VehicleWithdrawal"

import User from "./User"

import ManageResource from "./ManageResource"

export default {
  // Resources
  Attachments,
  Blobs,

  Deals,

  Services,
  ServiceVehicleIssues,

  ServicePackages,
  SupplierServiceSupports,
  Products,

  VehicleModelGroupMaintenanceTables,
  VehicleModels,
  VehicleParts,
  VehicleIssues,
  VehicleIssuesSolutions,
  MaintenancePackages,

  // Tickets
  Tickets,
  TicketDrafts,
  TicketDraftsBookings,
  TicketDraftsPublications,

  // Vehicle
  VehicleOccurrences,
  Vehicles,
  VehicleServiceOrders,

  // VehicleModelGroups
  VehicleModelGroups,
  VehicleModelGroupsMaintenanceTables,
  VehicleModelGroupsMaintenanceTableSettings,
  VehicleModelGroupsSuppliers,

  // Settings
  Settings,

  // Suppliers
  SupplierBlocks,
  SupplierDetails,
  SupplierMaintenanceVehicleModelGroups,
  SupplierServiceVehicleModelGroups,
  SupplierServiceVehicleModelGroupBatches,
  Suppliers,
  SupplierServiceOrders,
  SupplierTickets,

  // Stores
  Stores,
  StoreServiceOrders,
  StoreServiceSupports,
  StoreSettings,
  StoreTickets,
  StoreSuppliers,

  // ServiceOrders
  ServiceOrders,
  ServiceOrderDrafts,
  ServiceOrderDraftsAttachments,
  ServiceOrderDraftsAvailableSuppliers,
  ServiceOrderDraftsServices,
  ServiceOrderDraftsServiceAttachments,

  Quotes,
  QuoteBlobs,
  ReviewsApprovals,
  ReviewsChangesRequests,

  // Monitoring
  MonitoringAssistants,
  MonitoringConsultants,
  MonitoringProducts,
  MonitoringServiceOrders,
  MonitoringServiceOrdersAssignments,
  MonitoringServiceOrdersAttachments,

  MonitoringServiceOrdersQuotes,
  MonitoringServices,
  MonitoringSuppliers,
  MonitoringVehicles,
  MonitoringVehicleServiceOrderProducts,
  MonitoringVehicleServiceOrderServices,
  // - events
  MonitoringServiceOrdersCancellation,
  MonitoringServiceOrdersExecution,
  MonitoringServiceOrdersPostponement,
  MonitoringServiceOrdersReopenings,
  MonitoringServiceOrdersSupplierAuthorizationMailDeliveries,
  MonitoringServiceOrdersSupplierMessages,
  MonitoringServiceOrdersTicketMessages,
  MonitoringServiceOrdersVehicleReceipt,
  MonitoringServiceOrdersVehicleWithdrawal,

  User, 

  ManageResource
}

<!--
<doc lang="markdown">
Campo de _input_ de data e hora (opcional)
Como usar:
```pug
date-field(
  v-model="resource.at",
  name="model[at]",
  :disabled="submitting || fetching",
  :errors="errors.at",
  :label="$t('.fields.at.label')",
  :placeholder="$t('.fields.at.placeholder')",
  data-testid="atInput"
)
```
</doc>
-->

<style lang="scss" scoped>

.date-field {
  .label-content {
    margin-bottom: 8px;
    font-size: 14px;
    line-height: 17px;
    font-weight: 500;
    font-family: $secondary-font;
    color: $dark-gray;
    transition: color 0.1s cubic-bezier(.645,.045,.355,1);

    > * + * {
      padding-left: 4px;
    }

    &.focus {
      color: $orange;
    }

    &.error {
      color: $red;
    }

    .mandatory-icon {
      font-size: 4px;
      color: $orange;
    }

    .info-icon {
      font-size: 14px;
      cursor: pointer;
    }
  }

  .error-message {
    display: block;
    color: $red;
    font-size: 12px;
    line-height: 1;
    padding-top: 4px;
  }
}

</style>


<template lang="pug">

  .date-field
    label.label-content.flex.vertical-center(
      v-if="!hideLabel",
      :class="{ error: hasErrors, focus }",
      :for="inputId",
    )
      span {{ labelText }}
      template(v-if="optional")
        span {{ $t('form.optional') }}

      template(v-else-if="mandatory")
        i.mandatory-icon.fas.fa-circle(
          v-tooltip.top="$t('form.mandatory.tooltip')"
        )

      template(v-if="tooltip")
        i.info-icon.far.fa-info-circle(v-tooltip="tooltip")

    app-flatpickr(
      v-model="dateValue",
      :allow-input="true",
      :date-format="_displayFormat",
      :disabled="disabled",
      :enable-time="false",
      :error="hasErrors",
      :id="inputId",
      :loading="loading",
      :mask="mask",
      :max-date="maxDate",
      :maxlength="10",
      :min-date="minDate",
      :modal="modal",
      :future-only="futureOnly",
      :placeholder="_placeholder",
      @blur="onBlur",
      @focus="onFocus"
    )

    template(v-if="hasMessageErrors")
      span.error-message {{ errors[0] }}

</template>


<script>

// 3rd Party
import Flatpickr from "flatpickr"

// Mixins
import FieldMixin from "@/mixins/field"

export default {
  name: "DateField",

  mixins: [FieldMixin],

  props: {
    // XXX: como this.value é um timestamp, define que é o "fim do dia" (23:59:59)
    endOfDay: { type: Boolean, default: false },

    // XXX: permite customizar formato do this.value. ex: "YYYY-MM-DD"
    dataFormat: { type: String,  default: null },

    // _displayFormat @ https://flatpickr.js.org/options/ - '%d/%m/%Y às %H:%M'
    displayFormat: { type: String,  default: null }, // default: "d/m/Y"

    maxDate:    { type: String,  default: null  },  // data limite para seleção no flatpickr
    minDate:    { type: String,  default: null  },  // data limite para seleção no flatpickr
    futureOnly: { type: Boolean, default: false },  // permite apenas seleção de data futura


    tooltip:     { type: [String, Object], default: null  },
    modal:       { type: Boolean, default: false },
    placeholder: { type: String,  default: null  }
  },

  data() {
    return {
      i18nScope: "components.form.date-field"
    }
  },

  computed: {
    _displayFormat() {
      if (_.present(this.displayFormat)) return this.displayFormat

      return "d/m/Y"
    },

    // formato do dado (via moment.js) a ser retornado em this.value.
    // default: iso8601
    _dataFormat() {
      if (_.present(this.dataFormat)) return this.dataFormat

      return null // XXX: null aqui indica ISO8061
    },

    _placeholder() {
      if (_.present(this.placeholder)) return this.placeholder

      return this.$t(".placeholder.date")
    },

    mask() {
      return "##/##/####"
    },

    // datetime 'localizado', para exibição no input, mas "retornando" (emit:input) valor em
    // string ISO8601 (~'YYYY-MM-DDTHH:mmz').
    dateValue: {
      // o get retorna o dado em formato localizado - ex: DD/MM/YYYY às HH:mm para pt-BR - uma
      // vez que é usado para exibição no input
      get() {
        if (_.blank(this.value)) return null

        // // Removendo UTC para evitar mudança de dia
        // let withoutUtc = moment.utc(this.value, this._dataFormat).format(this._dataFormat)
        // let date = moment(withoutUtc).toDate()

        let date = moment(this.value, this._dataFormat).toDate()

        return Flatpickr.formatDate(date, this._displayFormat)
      },

      // o set define o dado em formato localizado - ex: DD/MM/YYYY às HH:mm para pt-BR - mas
      // "emite" (@input) o dado em formato canônico (iso8601 YYYY-MM-DDTHH:mmZ)
      set(value) {
        // value pode ser `null` caso o usuário "limpe" o <input>
        if (_.blank(value)) return this.$emit("input", null)

        let date = Flatpickr.parseDate(value, this._displayFormat)

        // let dateAsISO08601 = moment.utc(date, this._dataFormat)
        let dateAsISO08601 = moment(date, this._dataFormat)

        if (this.endOfDay) dateAsISO08601 = dateAsISO08601.endOf("day")

        dateAsISO08601 = dateAsISO08601.format(this._dataFormat)

        this.$emit("input", dateAsISO08601)
      }
    }
  }
}

</script>

<style lang="scss" scoped></style>


<template lang="pug">

  .deal-state-change-icon
    template(v-if="svg")
      img(:src="icon")
    template(v-else)
      i.icon(:class="icon")

</template>


<script>

// Icons
import draft from "@/assets/images/icons/deal-draft-state/light.svg"
import ongoing from "@/assets/images/icons/deal-ongoing-state/light.svg"

export default {
  props: {
    state: { type: String, required: true }
  },

  data() {
    return {
      i18nScope: "suppliers.deals.information.show.deal-state-change-icon"
    }
  },

  computed: {
    svg() {
      return ["draft", "ongoing"].includes(this.state)
    },

    icon() {
      switch (this.state) {
        case "draft":
          return draft
        case "ongoing":
          return ongoing
        case "done":
          return "fas fa-file-alt"
        case "amended":
          return "fas fa-file-alt"
        case "canceled":
          return "fas fa-file-times"
        default:
          return null
      }
    }
  }
}

</script>

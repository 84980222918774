<style scoped lang="scss">

.day-row {
  padding: 8px;
  min-height: 60px;
  border: 1px solid $light-gray-3;
  border-bottom-width: 0px;

  &:first-child {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }

  &:last-child {
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    border-bottom-width: 1px;
  }
}

.day-label {
  min-width: 124px;
}

.business-hour {
  min-width: 191px;
}
.button-icon {
  font-size: 20px;
}

.day-checkbox {
  margin-right: 8px;
}

.business-hour + .business-hour {
  margin-left: 24px;
}

.business-hour + .add-btn {
  margin-left: 24px;
}

.btn-label {
  display: block;
  margin-left: 4px;
}

</style>


<template lang="pug">

  .day-row.flex.vertical-center

    span.day-label {{ $t(`.weekdays.${weekday}`) }}

    .business-hour.flex(
      v-for="(resource, key) in businessHoursByShift",
      :key="`business-hour-${key}`",
    )

      template(v-if="resource")
        business-hour-fields.field(
          :name="`${name}[${keyFor(resource)}]`",
          :loading="submitting",
          :disabled="disabled",
          :resource="resource",
          :errors="nestedErrors[keyFor(resource)]",
          @remove="removeById(keyFor(resource))"
        )

      template(v-else)
        app-button.add-btn(
          theme="link",
          @click="add(key)",
          :disabled="submitting || fetching || disabled"
        )
          i.fal.fa-plus-circle.button-icon
          span.btn-label {{ $t('.btn.add') }}

</template>


<script>

import { v4 as uuid } from "uuid"

// Mixins
import NestedFormMixin from "@/mixins/nested-form"

// Local Components
import BusinessHourFields from "./business-hour-fields"

// models
import models from "@/models"
const { SupplierBusinessHour } = models

export default {
  components: {
    BusinessHourFields
  },

  mixins: [NestedFormMixin],

  props: {
    weekday:    { type: String, required: true },
    errors:     { type: Object, default: () => ({}) },
    fetching:   { type: Boolean, default: false },
    submitting: { type: Boolean, default: false },
    disabled:   { type: Boolean, default: false },
    name:       { type: String, required: true },
    maximum:    { type: Number, default: 0 }
  },

  data() {
    return {
      i18nScope:      "suppliers.details.business-hour-nested-fields",
      model:          "SupplierBusinessHour",
      nestedResource: "businessHours",

      active: false
    }
  },

  computed: {
    empty() {
      return this.businessHoursByShift.length === 0
    },

    businessHoursByShift() {
      if (_.blank(this.collection)) return { morning: null, afternoon: null, evening: null }

      return this.collection.reduce((businessHours, businessHour) => {
        if (businessHour.weekday !== this.weekday || businessHour.$markedForDestruction) return businessHours

        businessHours[businessHour.shift] = businessHour

        return businessHours
      }, { morning: null, afternoon: null, evening: null })
    }
  },

  methods: {
    // @override @/mixins/nested-form#add
    add(shift) {
      const resource = new SupplierBusinessHour({ weekday: this.weekday, shift })
      resource.tempId = uuid()

      const nestedResource = [...this.value, resource]

      this.$emit("input", nestedResource)
    }
  }
}

</script>

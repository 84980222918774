<style scoped lang="scss">

  .code-col {
    width: 130px;
  }

  .type-icon {
    margin-right: 8px;
    height: 18px;
  }

  .type-text {
    font-size: 14px;
    font-weight: normal;
  }

  .type-col {
    width: 250px;
  }

  .price-col {
    text-align: right;
    width: 150px;
  }

  .price  {
    &.free {
      text-decoration-line: line-through;
      color: $gray-2;
    }
  }

  .diamond-icon {
    margin: 0 8px;
    font-size: 4px;
    color: $gray-3;
  }

</style>


<template lang="pug">

  tr.service-row.app-table-row-light
    td.code-col {{ service.sapId || '-' }}

    td.type-col
      .flex
        template(v-if="service.type")
          service-type-icon.type-icon(:type="service.type")
          span {{ $t(`models.serviceOrderService.enums.type.${service.type}`) }}
        template(v-else)
          span -
    td.name-col
      .flex
        span {{ service.name }}
        template(v-if="hasMaintenanceLimit")
          i.diamond-icon.spacer.fa.fa-diamond.flex.center.vertical-center
          span {{ manintenanceLimit }}

    td.price-col
      span.price(:class="{ free: service.freeOfCharge }") {{ $l('currency', service.price) }}

</template>


<script>

export default {

  props: {
    service: { type: Object, default: () => ({}) }
  },

  data() {
    return {
      i18nScope: "stores.tickets.service-orders.show.service-row"
    }
  },

  computed: {
    hasMaintenanceLimit() {
      const hasTimeLimit = this.service.$present("timeLimit")
      const hasDistanceLimit = this.service.$present("distanceLimit")

      return hasTimeLimit || hasDistanceLimit
    },

    manintenanceLimit() {
      const hasTimeLimit = this.service.$present("timeLimit")
      const hasDistanceLimit = this.service.$present("distanceLimit")

      if (hasTimeLimit && hasDistanceLimit) {
        return this.$t(".fields.maintenanceLimit.label", {
          time:     this.service.formattedTimeLimit,
          distance: this.service.formattedDistanceLimit
        })
      }

      return this.service.formattedTimeLimit || this.service.formattedDistanceLimit

    },

    dealProduct() {
      return this.service.dealProduct || {}
    },

    hasPartOrigin() {
      const partOrigin = _.get(this.service, "dealProduct.partOrigin")

      return !!partOrigin
    }
  }
}

</script>


<style lang="scss" scoped>
.intro {
  display: flex;
  gap: 16px;
}

.section {
  background: $light-gray-2;
  border-radius: 8px;
  flex-shrink: 0;

  &.tree {
    flex-grow: 1;
  }

  &.timeline {
    padding: 16px;
  }
}
</style>

<template lang="pug">
  service-order-section-layout.service-order-intro-section
    template(#icon)
      i.fas.fa-list-tree
    .intro
      .section.tree
        tree(
          :service-order-rid="rid"
          :value="rid"
        )
      .section.timeline
        timeline(:service-order="serviceOrder")
</template>

<script>
import ServiceOrderSection from "./service-order-section"
import Timeline from "./timeline.vue"
import Tree     from "./tree.vue"

export default {
  components: {
    Timeline,
    Tree
  },

  extends: ServiceOrderSection,

  props: {
    rid: { type: String, required: true }
  }
}
</script>

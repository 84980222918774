<!--
<doc lang="markdown">
Exibe um código Hierarchy, prefixado por um <pre>Hierarchy</pre>

_Props_: value, size, color

Como usar:
```
  hierarchy-code-tag(:value="hierarchyCode")
```
</doc>
-->


<style lang="scss" scoped>

.tag {
  font-family: $secondary-font;
}

</style>


<template lang="pug">

  app-tag.tag(:color="color", :size="size")
    slot {{ valueText }}

</template>


<script>

export default {
  name: "HierarchyCodeTag",

  props: {
    // XXX: é pré-definida a partir de `value`.
    // color: { type: String, default: 'blue' },
    value: { type: [String, Number], default: null },
    size:  { type: String, default: "normal", validator: size => ["small", "normal", "big"].includes(size) }
  },

  data() {
    return {
      i18nScope: "components.supplier.hierarchy-code-tag"
    }
  },

  computed: {
    valueText() {
      return this.$t(`models.supplier.enums.hierarchy.${this.value}`, {
        defaultValue: this.$t("abbr.unavailable")
      }).toLowerCase()
    },

    color() {
      switch (this.value) {
        case "head":
          return "blue"

        case "branch":
        default:
          return "aqua"
      }
    }
  }

}

</script>

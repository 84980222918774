import Base from "./base"

class DirectUpload extends Base {
  static get attrs() {
    return [
      "url",
      "headers"
    ]
  }
}

class SignedFile extends Base {
  static get attrs() {
    return [
      "signed_id"
    ]
  }

  static get relations() {
    return {
      direct_upload: { type: "belongsTo", model: DirectUpload }
    }
  }
}

export default SignedFile

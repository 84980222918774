<style lang="scss" scoped>

.show {
  .section {
    & + .section {
      border-top: 1px solid $light-gray-3;
      margin-top: 24px;
      padding-top: 24px;
    }

    &.header {
      .left {
        margin-bottom: 8px;

        .id-tag {
          margin: 0 8px;
        }

        .title,
        .subtitle {
          font-family: $secondary-font;
          font-weight: 500;
        }

        .subtitle {
          font-size: 20px;
        }

        .title {
          font-size: 38px;
        }
      }

      .services-badge {
        margin-right: 16px;
      }
    }

    &.content {
      .back {
        font-size: 14px;
        margin-bottom: 12px;

        .icon {
          font-size: 12px;
          margin-right: 4px;
        }
      }

      .date {
        border: 1px solid $light-gray-3;
        box-sizing: border-box;
        border-radius: 4px;
        padding: 16px;
        width: 330px;
        height: 95px;
      }

      .pickup-service {
        margin-top: 16px;
        border: 1px solid $light-gray-3;
        border-radius: 4px;
        padding: 8px 16px;

        ::v-deep {
          .checkbox-input, .checkbox-label {
            cursor: unset;
          }
        }

        .icon-container {
          width: 24px;
          height: 24px;
          font-size: 16px;
          margin-right: 8px;
          color: $gray-3;
        }

        .value-text {
          color: $dark-gray;
          font-weight: 400;
        }
      }
    }

    &.footer {
      .confirm {
        margin-right: 18px;
      }

      .back {
        margin-right: 24px;

        .icon {
          margin-right: 8px;
        }
      }

      .next .icon {
        margin-left: 8px;
      }
    }
  }
}

</style>


<template lang="pug">

  .show
    .section.header.flex.space-between
      .left
        .flex.vertical-center
          h2.subtitle {{ ServiceOrderRepair.$tEnum('kind', serviceOrder.kind) }}
          id-tag.id-tag(:value="ticket.id")
          construction-badge.no-shrink

        h1.title {{ $t('.title') }}

      .flex
        //- services-selected-badge.services-badge(:services-count="10")
        vehicle-info-tag.no-shrink(:vehicle="vehicle")

    .section.content
      app-button.back(
        theme="link",
        :to="{ name: 'editDraftTicketBooking', params: { id: this.ticket.id } }"
      )
        i.icon.fas.fa-arrow-left
        span {{ $t('.btn.edit') }}

      template(v-if="fetching")
        loading-lines(:lines="5")

      template(v-else)
        .flex.space-between
          reserved-supplier-info-card(:service-order-supplier="serviceOrderSupplier")

          .right-pane
            reserved-supplier-date.date(:timestamp="estimatedStartAt")

            toggle-field.pickup-service(
              name="SupplierReservation[pickupService]",
              hide-label,
              disabled,
              :value="pickupService"
            )
              template(#optionText="{ props: { inputId, disabled, error: hasErrors } }")
                label.checkbox-label.flex.vertical-center(
                  :for="inputId",
                  :class="{ disabled, error: hasErrors }"
                )
                  .icon-container.flex.vertical-center.center
                    i.icon.fas.fa-people-arrows

                  span.value-text {{ $t('.fields.pickupService.label') }}

    .section.footer.flex.space-between
      .cancel-actions.flex.vertical-center
        app-button.back(
          outline,
          theme="gray",
          :to="{ name: 'editDraftTicketContent', params: { id: ticket.id } }"
        )
          i.icon.far.fa-arrow-left
          span {{ $t('.btn.back') }}

        destroy-ticket-action(
          :ticket-id="ticket.id",
          :ticket-store-id="serviceOrder.storeId",
          @destroy="onDestroy"
        )

      .flex
        app-button.confirm(
          theme='link',
          :to="{ name: 'tickets' }"
        )
          span {{ $t('.btn.confirmAndExit') }}

        app-button.next(:to="{ name: 'newDraftTicketConfirmation', params: { id: ticket.id } }")
          span {{ $t('.btn.next') }}
          i.icon.far.fa-arrow-right

</template>


<script>

// Mixins
import FetchMixin from "@/mixins/fetch-mixin"

// View
import LoadedView from "@/views/loaded-view"

// Components
import ConstructionBadge from "../components/construction-badge.vue"
import DestroyTicketAction from "../components/destroy-ticket-action.vue"
import VehicleInfoTag from "../components/vehicle-info-tag.vue"
import ServicesSelectedBadge from "./edit/services-selected-badge.vue"
import ReservedSupplierInfoCard from "./show/reserved-supplier-info-card.vue"
import ReservedSupplierDate from "./show/reserved-supplier-date.vue"

// Models
import models from "@/models"
const { ServiceOrderRepair } = models

export default {
  components: {
    ConstructionBadge,
    DestroyTicketAction,
    VehicleInfoTag,
    ServicesSelectedBadge,
    ReservedSupplierInfoCard,
    ReservedSupplierDate
  },

  extends: LoadedView,

  mixins: [FetchMixin],

  props: {
    ticket: { type: Object, required: true }
  },

  data() {
    return {
      i18nScope: "tickets.drafts.bookings.show",
      routeName: "draftTicketBooking",

      resource: {},

      ServiceOrderRepair
    }
  },

  computed: {
    serviceOrderSupplier: {
      get() { return this.resource },

      set(value) { this.resource = value }
    },

    vehicle() {
      return _.get(this.ticket, "vehicle")
    },

    serviceOrder() {
      return _.get(this.ticket, "serviceOrders[0]")
    },

    estimatedStartAt() {
      return _.get(this.serviceOrderSupplier, "bookingHistory.estimatedStartAt")
    },

    pickupService() {
      return _.get(this.serviceOrderSupplier, "bookingHistory.pickupService")
    }
  },

  methods: {
    parseRoute() {},

    // @override Fetch Mixin
    fetchRequest() {
      return this.$newSdk.tickets.drafts.bookings.find({ ticketId: this.ticket.id })
    },

    onDestroy() {
      this.$router.push({ name: "tickets" })
    }
  }
}

</script>

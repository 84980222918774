<style lang="scss" scoped>

.targets-nested-fields {
  .label {
    font-family: $secondary-font;
    font-weight: 500;
    font-size: 14px;
    margin-left: 32px;
    margin-bottom: 8px;
  }

  .fields + .fields {
    margin-top: 4px;
  }
}

</style>


<template lang="pug">

  .targets-nested-fields
    .label(v-if="!empty")
      span {{ $t('.label') }}

    target-fields.fields(
      v-for="(resource, index) in collection",
      :key="`target-${keyFor(resource)}`",
      v-show="!resource.$markedForDestruction",
      :index="displayIndexFor(resource)",
      :loading="submitting",
      :resource="resource",
      :disabled="displayIndexFor(resource) < visibleResourcesCount",
      :errors="nestedErrors[keyFor(resource)]",
      :can-add="canAdd(index)",
      :options="optionsFor(resource)",
      @clear="clear(index, resource)",
      @change="updateOptions(index, resource)",
      @add="add"
    )

</template>


<script>

// Mixins
import NestedFormMixin from "@/mixins/nested-form"

// Components
import TargetFields from "./target-fields"

// Models
import models from "@/models"
const { VehiclePartTarget } = models

export default {
  components: {
    TargetFields
  },

  mixins: [NestedFormMixin],

  props: {
    vehiclePart: { type: Object, required: true },
    errors:      { type: Object, default: () => ({}) },
    submitting:  { type: Boolean, default: false }
  },

  data() {
    return {
      i18nScope: "tickets.drafts.contents.services.components.targets-nested-fields",

      // Atributos do NestedForm
      model:          "VehiclePartTarget",
      nestedResource: "targets",

      options: this.buildOptions()
    }
  },

  computed: {
    nonDestroyedCollection() {
      return this.collection.filter(resource => !resource.$markedForDestruction)
    },

    visibleResourcesCount() {
      return this.nonDestroyedCollection.length
    },

    hasAvailableOptions() {
      return this.options.filter(option => _.blank(option.id)).length > 0
    },

    empty() {
      return _.blank(this.collection)
    }
  },

  watch: {
    vehiclePart() {
      this.options = this.buildOptions()

      this.setMinimumResources()
    }
  },

  methods: {
    displayIndexFor(resource) {
      const key = _.present(resource.id) ? { id: resource.id } : { tempId: resource.tempId }

      return _.findIndex(this.nonDestroyedCollection, key) + 1
    },

    isFilled(index) {
      const entry = this.collection[index]
      return _.present(entry.position) && _.present(entry.location)
    },

    canAdd(index) {
      const isLast = index === this.collection.length - 1
      return this.hasAvailableOptions && isLast && this.isFilled(index)
    },

    async clear(index, resource) {
      if (_.present(resource)) {
        const id = this.keyFor(resource)

        const previousOption = _.find(this.options, { id })
        if (previousOption) delete previousOption.id
      }

      const entry = this.collection[index]
      if (this.visibleResourcesCount === 1) {
        entry.location = null
        entry.position = null
      }
      else this.remove(index)

      return this.$nextTick()
    },

    buildOptions() {
      const positions = VehiclePartTarget.$enums.position.values.filter(position => this.vehiclePart[`${position}Position`])
      const locations = VehiclePartTarget.$enums.location.values.filter(location => this.vehiclePart[`${location}Location`])

      const options = positions
        .map(position => locations
          .map(location => ({
            position,
            location,
            id: _.get(_.find(this.value, { location, position }), "id")
          })))
        .flat()

      return options
    },

    optionsFor(resource) {
      return this.options.filter(option => _.blank(option.id) || option.id === this.keyFor(resource))
    },

    updateOptions(index, resource) {
      const id = this.keyFor(resource)

      // se desmarca do par anterior, se estiver marcado
      const previousOption = _.find(this.options, { id })
      if (previousOption) delete previousOption.id

      // se marca no novo par, caso esteja preenchido
      const { position, location } = resource
      if (this.isFilled(index)) _.find(this.options, { position, location }).id = id

      this.options = [...this.options]
    }
  }
}

</script>

import Base from "./base"
import models from "@/models"

class Ticket extends Base {
  static get attrs() {
    return _.uniq([
      ...super.attrs,
      "storeId",
      "externalVehicleId",
      "vehicleModelId",
      "type",
      "state",
      "vehicleKm",
      "vehicleLicensePlate",
      "fuelLevel",
      "serviceOrdersCount"
    ])
  }

  static get relations() {
    return {
      store:         { type: "belongsTo", model: "Store" },
      // XXX: Show retorna `vehicle` porque precisamos da cor do veículo
      vehicle:       { type: "belongsTo", model: "Vehicle" },
      // XXX: Index retorna vehicleModel direto por otimização
      vehicleModel:  { type: "belongsTo", model: "VehicleModel" },
      serviceOrders: { type: "hasMany", model: "ServiceOrder" }
    }
  }

  static get enums() {
    return {
      type: {
        "Tickets::InternalMaintenance": "Tickets::InternalMaintenance",
        "Tickets::Maintenance":         "Tickets::Maintenance",
        "Tickets::Repair":              "Tickets::Repair"
      },

      state: {
        draft:    "draft",
        open:     "open",
        ongoing:  "ongoing",
        executed: "executed",
        // XXX: o estado "done" não é usado ainda
        // done:     'done',
        canceled: "canceled"
      }
    }
  }

  static get constraints() {
    return {
      type: { presence: true }
    }
  }

  static get classNames() {
    return [
      "Ticket",
      "TicketInternalMaintenance",
      "TicketExternalMaintenance",
      "TicketRepair"
    ]
  }

  static build(data) {
    switch (_.get(data, "type")) {
      case "Tickets::InternalMaintenance":
        return new models.TicketInternalMaintenance(data)
      case "Tickets::Maintenance":
        return new models.TicketExternalMaintenance(data)
      case "Tickets::Repair":
        return new models.TicketRepair(data)
      default:
        return new Ticket(data)
    }
  }
}

export default Ticket

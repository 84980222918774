// Base
import BaseRequests from "../Base"

class VehicleModelGroupSuppliersRequests extends BaseRequests {
  static get selector() {
    return "vehicleModelGroups.suppliers"
  }

  all({ vehicleModelGroupId, params = {}, options = {} } = {}) {
    if (_.blank(vehicleModelGroupId)) {
      throw new Error('NewSDK vehicleModelGroups.suppliers - #all() - "vehicleModelGroupId" is a required prop')
    }

    return this.action({
      method: "get",
      url:    `/vehicle_model_groups/${vehicleModelGroupId}/suppliers`,
      params,
      options
    })
  }
}

export default VehicleModelGroupSuppliersRequests

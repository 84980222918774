<style lang="scss" scoped>

.service-order-info {
  background-color: $light-gray;
  border-radius: 8px;
  padding: 16px;

  .icon-container {
    margin-right: 8px;
  }

  .light-text {
    font-weight: 300;
    font-size: 14px;
  }

  .diamond-icon {
    font-size: 4px;
    color: $gray-3;
    margin: 0 8px;
  }

  .header {
    .title {
      font-family: $secondary-font;
      font-weight: 500;
      font-size: 20px;
      margin-right: 8px;
    }

    .icon-container {
      width: 14px;
      height: 14px;

      .icon {
        font-size: 12px;
        color: $purple;
      }
    }

    .light-text + .light-text {
      margin-left: 8px;
    }

    .content-toggle {
      color: $dark-gray;
      font-size: 14px;

      &:hover {
        color: $orange;
      }

      .icon {
        font-size: 12px;
        margin-right: 4px;
      }
    }
  }

  .data-container {
    margin-top: 16px;
    padding-top: 16px;
    border-top: 1px solid $light-gray-4;

    .data {
      padding: 8px 16px 16px 16px;
      gap: 24px;

      .row {
        grid-template-columns: 1fr 1fr;
        gap: 24px;

        + .row {
          .section {
            padding-top: 24px;
            border-top: 1px solid $light-gray-4;
          }
        }
      }

      .section {
        .section-header {
          margin-bottom: 8px;
        }

        .icon-container {
          width: 16px;
          height: 16px;

          .icon {
            font-size: 14px;
          }
        }

        .section-title {
          font-family: $secondary-font;
          font-weight: 500;
          font-size: 16px;
        }

        .count {
          font-family: $secondary-font;
          font-weight: 400;
          font-size: 16px;
          color: $gray-3;
        }

        .empty-section {
          background-color: $light-gray-3;
          padding: 8px;
          border-radius: 4px;
          font-style: italic;
          font-weight: 300;
          font-size: 12px;
          text-align: center;
        }

        .resources-table {
          border: 1px solid $light-gray-4;
          border-radius: 4px;

          .table-row {
            grid-template-columns: 1fr 55px;
            height: 40px;

            & + .table-row {
              border-top: 1px solid $light-gray-4;
            }
          }

          .column-header {
            font-family: $secondary-font;
            font-weight: 400;
            font-size: 12px;
          }

          .column {
            padding: 0 8px;
          }

          .name,
          .part-origin {
            font-family: $secondary-font;
            font-weight: 400;
            font-size: 14px;
          }

          .quantity {
            font-family: $primary-monospace-font;
            font-weight: 400;
            font-size: 14px;
          }

          .diamond-icon {
            margin: 0 4px;
          }

          .part-origin {
            color: $gray-3;
          }
        }

        .service-order-attachments {
          ::v-deep .subsection-title {
            color: $dark-gray;
          }
        }
      }
    }
  }
}

</style>


<template lang="pug">

.service-order-info
  .header
    .flex.vertical-center
      h2.title {{ $t('.title') }}
      id-tag.id(:value="serviceOrder.remoteId")

    .flex.space-between
      .flex.vertical-center
        .icon-container.flex.vertical-center.center
          i.icon.fas.fa-car
        template(v-if="scheduledAtDifference > 0")
          span.light-text {{ $t('.scheduled-date.future', { days: scheduledAtDifference }) }}
        template(v-if="scheduledAtDifference < 0")
          span.light-text {{ $t('.scheduled-date.past', { days: -scheduledAtDifference }) }}
        template(v-else)
          span.light-text {{ $t('.scheduled-date.today') }}
        span.light-text {{ $l(`time.formats.short`, serviceOrder.scheduledAt) }}

      template(v-if="collapsible")
        app-button(theme="link", @click="toggleContent")
          .content-toggle.flex.vertical-center
            template(v-if="open")
              i.icon.fas.fa-chevron-up
              span {{ $t('.collapse.close') }}

            template(v-else)
              i.icon.fas.fa-chevron-down
              span {{ $t('.collapse.open') }}

  .data-container(v-show="open || !collapsible")
    .data.grid
      .row.grid
        .section.services
          .section-header.flex.vertical-center
            .icon-container.flex.center.vertical-center
              i.icon.far.fa-wrench
            h3.section-title {{ $t('.services.title') }}
            i.diamond-icon.fas.fa-diamond
            span.count {{ $t('.services.count', { count: services.length }) }}

          template(v-if="!hasServices")
            .empty-section
              span {{ $t('.services.empty') }}

          template(v-else)
            .resources-table
              .grid.table-row.column-header.vertical-center
                span.column {{ $t('.services.table.name') }}
                span.column {{ $t('.services.table.quantity') }}

              template(v-for="service in services")
                .grid.table-row.vertical-center
                  span.column.name {{ service.name }}
                  span.column.quantity {{ service.quantity }}

        .section.products
          .section-header.flex.vertical-center
            .icon-container.flex.center.vertical-center
              i.icon.far.fa-cube
            h3.section-title {{ $t('.products.title') }}
            i.diamond-icon.fas.fa-diamond
            span.count {{ $t('.products.count', { count: products.length }) }}

          template(v-if="!hasProducts")
            .empty-section
              span {{ $t('.products.empty') }}

          template(v-else)
            .resources-table
              .grid.table-row.column-header.vertical-center
                span.column {{ $t('.products.table.name') }}
                span.column {{ $t('.products.table.quantity') }}

              template(v-for="product in products")
                .grid.table-row.vertical-center
                  .column.flex.vertical-center
                    span.name {{ product.name }}
                    i.diamond-icon.fas.fa-diamond
                    span.part-origin {{ MovidaServiceOrderProduct.$tEnum('partOrigin', product.partOrigin) }}
                  span.column.quantity {{ product.quantity }}

      .row.grid
        .section.description
          .section-header.flex.vertical-center
            .icon-container.flex.center.vertical-center
              i.icon.far.fa-message-lines
            h3.section-title {{ $t('.description.title') }}

          template(v-if="!hasDescription")
            .empty-section
              span {{ $t('.description.empty') }}

          template(v-else)
            span.light-text.preserve-whitespace
              | {{ serviceOrder.ticketDescription || serviceOrder.description }}

        .section.attachments
          service-order-attachments.service-order-attachments(
            icon="fa-paperclip",
            :service-order-id="serviceOrder.id"
          )

</template>


<script>

// Components
import ServiceOrderAttachments from "../reviews/_components/service-order-attachments.vue"

// Models
import models from "@/models"
const { MovidaServiceOrderProduct } = models


export default {
  components: {
    ServiceOrderAttachments
  },

  props: {
    serviceOrder: { type: Object, required: true },
    collapsible:  { type: Boolean, default: true }
  },

  data() {
    return {
      i18nScope: "monitoring.quotes.service-order-info",

      open: false,

      attachments: null,

      MovidaServiceOrderProduct
    }
  },

  computed: {
    hasServices() {
      return _.present(this.serviceOrder?.serviceOrderServices)
    },

    services() {
      if (!this.hasServices) return []

      return this.serviceOrder.serviceOrderServices
    },

    hasProducts() {
      return _.present(this.serviceOrder?.serviceOrderProducts)
    },

    products() {
      if (!this.hasProducts) return []

      return this.serviceOrder.serviceOrderProducts
    },

    hasDescription() {
      return _.present(this.serviceOrder?.description)
    },

    hasAttachments() {
      return _.present(this.attachments)
    },

    scheduledAtDifference() {
      return moment(this.serviceOrder.scheduledAt).diff(moment(), "days")
    }
  },

  methods: {
    toggleContent() {
      this.open = !this.open
    }
  }
}

</script>

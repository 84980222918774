<style lang="scss" scoped>

.server-error {
  min-height: 100%;
  width: 100%;
  background: $white;
}

.img {
  margin-top: 10vh;
  margin-bottom: 32px;
  width: 70%;

  @media only screen and (min-width: 1600px) {
    width: 1200px;
  }
}

.button-content {
  display: block;
  width: 200px;
  padding-bottom: 20px;

  .app-button + .app-button {
    margin-top: 16px;
  }
}

</style>


<template lang="pug">

  .server-error.flex.vertical-center.column-direction(data-test="server-error")
    img.img(:src="illustration")
    .button-content
      app-button.app-button(
        v-if="!isServerErrorPath",
        size="small",
        full-width,
        @click="refresh"
      ) {{ $t('.btn.reload') }}
      app-button.app-button(
        outline,
        size="small",
        full-width,
        @click="goBack",
      ) {{ $t('.btn.goBack') }}

</template>


<script>

// Assets
import illustration from "@/assets/images/500.png"

export default {
  name: "ServerError",

  data() {
    return {
      i18nScope: "components.server-error",

      illustration
    }
  },

  computed: {
    // Quando um erro interno acontece, a rota acessada permanece, sendo diferente de 'serverError'
    // Caso o usuário acesse diretamente `/500` a rota será 'serverError'
    isServerErrorPath() {
      return this.$route.name === "serverError"
    }
  },

  methods: {
    refresh() {
      if (this.isServerErrorPath) return

      window.location.reload(true)
    },

    goBack() {
      this.$goBack()
    }
  }
}

</script>

<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.7188 16.125L16.9375 13.75C16.5938 12.7188 15.625 12 14.5625 12H9.40625C8.34375 12 7.375 12.7188 7.03125 13.75L6.25 16.125C5.25 16.4375 4.5 17.375 4.5 18.5V22.5C4.5 23.3438 5.15625 24 6 24H6.5C7.3125 24 8 23.3438 8 22.5V22H16V22.5C16 23.3438 16.6562 24 17.5 24H18C18.8125 24 19.5 23.3438 19.5 22.5V18.5C19.5 17.375 18.7188 16.4375 17.7188 16.125ZM8 14.0625C8.1875 13.4375 8.75 13 9.40625 13H14.5625C15.2188 13 15.7812 13.4375 15.9688 14.0625L16.625 16H7.34375L8 14.0625ZM7 22.5C7 22.7812 6.75 23 6.5 23H6C5.71875 23 5.5 22.7812 5.5 22.5V22H7V22.5ZM18.5 22.5C18.5 22.7812 18.25 23 18 23H17.5C17.2188 23 17 22.7812 17 22.5V22H18.5V22.5ZM18.5 20V21H5.5V18.5C5.5 17.6875 6.15625 17 7 17H17C17.8125 17 18.5 17.6875 18.5 18.5V20ZM7.75 17.75C6.9375 17.75 6.375 18.2812 6.375 19C6.375 19.75 6.9375 20.25 7.75 20.25H8.0625C9.3125 20.25 9.5 19.625 9.5 19.25C9.5 18.5 8.5 17.75 7.75 17.75ZM8.0625 19.5C7.96875 19.5 7.84375 19.5 7.75 19.5C7.375 19.5 7.125 19.3125 7.125 19C7.125 18.7188 7.375 18.5 7.75 18.5C8.15625 18.5 8.75 18.9688 8.75 19.25C8.75 19.5 8.40625 19.5 8.0625 19.5ZM16.2188 17.75C15.4688 17.75 14.5 18.5 14.5 19.25C14.5 19.625 14.6562 20.2812 15.9062 20.2812L16.2188 20.25C17.0312 20.25 17.625 19.75 17.625 19C17.625 18.2812 17.0312 17.75 16.2188 17.75ZM16.2188 19.5C16.125 19.5 16 19.5 15.9062 19.5C15.5625 19.5 15.25 19.5 15.25 19.25C15.25 18.9688 15.8125 18.5 16.2188 18.5C16.5938 18.5 16.875 18.7188 16.875 19C16.875 19.3125 16.5938 19.5 16.2188 19.5Z"
      :fill="colorHash"
    />
    <path
      d="M16 10C17.625 10 19 11.375 19 13V14V15C19.5312 15 20 14.5625 20 14V13C20 10.8125 18.1875 9 16 9H8C5.78125 9 4 10.8125 4 13V14C4 14.5625 4.4375 15 5 15V14V13C5 11.375 6.34375 10 8 10H16Z"
      :fill="colorHash"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M9 4C9 5.65685 10.3431 7 12 7C13.6569 7 15 5.65685 15 4C15 2.34315 13.6569 1 12 1C10.3431 1 9 2.34315 9 4ZM12 0C9.79086 0 8 1.79086 8 4C8 6.20914 9.79086 8 12 8C14.2091 8 16 6.20914 16 4C16 1.79086 14.2091 0 12 0Z"
      :fill="colorHash"
    />
  </svg>
</template>


<script>

import SupplierIconBase from "./supplier-icon-base"

export default {
  name: "RacIcon",

  extends: SupplierIconBase
}

</script>

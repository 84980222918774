// Base
import BaseRequests from "./Base"

// Models
import models from "@/models"
const { ServicePackage } = models

class ServicePackagesRequests extends BaseRequests {
  static get selector() {
    return "servicePackages"
  }

  list({ params = {}, options = {} } = {}) {
    return this.action({
      method:  "get",
      url:     "/service_packages",
      params,
      options: {
        model: ServicePackage,

        ...options
      }
    })
  }

  create({ params = {}, options = {} } = {}) {
    return this.action({
      method:  "post",
      url:     `/service_packages`,
      params,
      options: {
        model: ServicePackage,

        ...options
      }
    })
  }

  find({ servicePackageId, params = {}, options = {} } = {}) {
    if (_.blank(servicePackageId)) {
      throw new Error('NewSDK ServicePackages - #find() - "servicePackageId" is a required prop')
    }

    return this.action({
      method:  "get",
      url:     `/service_packages/${servicePackageId}`,
      params,
      options: {
        model: ServicePackage,

        ...options
      }
    })
  }

  update({ servicePackageId, params = {}, options = {} } = {}) {
    if (_.blank(servicePackageId)) {
      throw new Error('NewSDK ServicePackages - #update() - "servicePackageId" is a required prop')
    }

    return this.action({
      method:  "patch",
      url:     `/service_packages/${servicePackageId}`,
      params,
      options: {
        model: ServicePackage,

        ...options
      }
    })
  }

  destroy({ servicePackageId }) {
    if (_.blank(servicePackageId)) {
      throw new Error('NewSDK ServicePackages - #destroy() - "servicePackageId" is a required prop')
    }

    return this.action({
      method: "delete",
      url:    `/service_packages/${servicePackageId}`
    })
  }
}

export default ServicePackagesRequests

<style lang="scss" scoped>

.menu-wrapper {
  position: relative;
}

.menu-content {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  max-height: 200px;
  overflow-y: auto;
  background: white;
  margin: 0;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  border: 1px solid $gray;
  border-top: none;
  z-index: 1000;

  padding: 0;
}

li {
  list-style: none;

  .option {
    height: 40px;
    padding: 0 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid $light-gray-3;
    cursor: pointer;

    &.active {
      color: $primary;
      background: $light-gray-2;
    }

    &.focus {
      background: $light-gray-2;
      color: $dark-primary-2;
    }
  }
}

.input-wrapper {
  cursor: pointer;
  position: relative;
  appearance: none;
  background-color: $white;
  background-image: none;
  border-radius: 4px;
  border: 1px solid $gray;
  border-style:solid;
  box-sizing: border-box;
  color: $gray-3;
  display: inline-block;
  font-size: inherit;
  height: 40px;
  line-height: 40px;
  outline: none;
  padding: 0 12px;
  transition: border-color 0.2s cubic-bezier(.645,.045,.355,1);
  width: 100%;
  text-align: left;

  input {
    width: 100%;
    border: none;
    background: transparent;
  }

  &:hover {
    border-color: $orange;
  }

  &.open {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  &:focus,
  &.focus {
    border-style:solid;
    outline: none;
    border-color: $orange;
    border-width: 1px;
  }

  &.disabled {
    background-color: $light-gray-3;
    border-color: $light-gray-4;
    color: $gray-3;
    cursor: not-allowed;

    .value {
      color: $gray-2;
    }
  }
}

.placeholder {
  font-weight: 300;
  color: $gray;
}

.arrow-icon {
  position: absolute;
  width: 40px;
  height: 38px;
  right: 1px;
  top: 1px;
  padding: 4px 8px;
  text-align: center;
  transition: transform .2s ease;

  &.open {
    transform: rotate(180deg);
  }

  &:before {
    position: relative;
    right: 0;
    top: 24%;
    color: $gray-2;
    margin-top: 4px;
    border-style: solid;
    border-width: 5px 5px 0;
    border-color: $gray-3 transparent transparent;
    content: "";
  }
}

</style>


<template lang="pug">

  .app-select(v-click-outside="closeMenu")
    .select-field
      .input-wrapper(@click="toggleMenu", :class="{ focus }")
        input.input(
          type="text",
          ref="input",
          autocomplete="disabled",

          @input="onSearch",
          :value="search",

          :maxlength="maxlength",

          @focus="focus = true",

          @keyup.enter="onSelectByEnter",
          @keydown.enter.prevent="",
          @keyup.esc="onKeyEsc",
          @keyup.up="onKeyUp",
          @keyup.down="onKeyDown",

          @keydown.up="e => e.preventDefault()"
          @keydown.down="e => e.preventDefault()"
        )
        .arrow-icon(:class="{ open: isOpen }")

    .menu-wrapper
      ul.menu-content(
        v-if="isOpen",
        ref="optionsContent",
        @mouseleave="focusedOption = null"
      )
        template(v-if="fetching")
          li.option Carregando...

        template(v-else)
          li(
            :class="defaultOptionClass(option, index)",
            v-for="(option, index) in options$", :key="option.value",
            @mousemove="focusedOption = index",
            :aria-select-option="`option-${index}`",
            @click="onSelectByClick(option)",
          )
            slot(name="option", :props="optionProps(option, index)")
              | {{ option.label }}

</template>


<script>

export default {
  name: "AppSelect",

  props: {
    options:       { type: [Array], default: () => [] },
    value:         { type: [String, Number, Object, Array], default: null },  // XXX: Array para seleção múltipla
    optionAsValue: { type: Boolean, default: false },
    disabled:      { type: Boolean, default: false },
    fetching:      { type: Boolean, default: false },
    defaultFilter: { type: Boolean, default: true  },
    searchable:    { type: Boolean, deafult: false },
    limit:         { type: Number,  default: null  },
    maxlength:     { type: Number,  default: null  },
    closeOnSelect: { type: Boolean, default: true  }
  },

  data() {
    return {
      i18nScope: "components.app-select",

      isOpen:        false,
      search:        null,
      focusedOption: null,
      focus:         false
    }
  },

  computed: {
    // Necessário caso algum componente queira mudar o comportamento
    // Ex: select-field precisa usar disable como `return this.disabled || this.loading`
    _disabled() {
      return this.disabled
    },

    selectedOption() {
      if (_.blank(this.value)) return null

      if (this.optionAsValue) return this.value

      return this.options.find(option => option.value === this.value)
    },

    valueLabel() {
      if (_.blank(this.value)) return null

      if (this.optionAsValue) return this.value.label

      const selectedOption = this.options.find(option => option.value === this.value)

      return _.get(selectedOption, "label")
    },

    parsedOptions() {
      if (_.blank(this.options) || this.options[0] instanceof Object) return this.options

      return this.options.map(option => ({ label: option, value: option }))
    },

    defaultFilterOptions() {
      if (_.blank(this.search)) return this.parsedOptions
      return this.parsedOptions.filter(({ label }) => {
        // XXX Estamos usando `normalize` e a regex para a busca sem comparação de acentos
        // https://stackoverflow.com/a/37511463
        const formattedOption = String(label).toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "")
        const formattedSearch = this.search.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "")

        return formattedOption.indexOf(formattedSearch) >= 0
      })
    },

    options$() {
      let options = (this.defaultFilter) ? this.defaultFilterOptions : this.options
      const { length } = options

      if (this.limit && length > this.limit) {
        let limitedOptions = [...options]
        limitedOptions.splice(this.limit, length - 1)

        return limitedOptions
      }

      return options
    },

    hasOptionSlot() {
      return !!this.$scopedSlots.option
    }
  },

  watch: {
    search() {
      this.$emit("inputSearch", this.search)
    },

    focusedOption() {
      let content = this.$refs.optionsContent

      if (!content) return

      let optionElement = content.querySelector(`[aria-select-option="option-${this.focusedOption}"]`)
      if (_.blank(optionElement)) return

      optionElement.scrollIntoView({ behavior: "smooth", block: "nearest", inline: "nearest" })
    }
  },

  methods: {
    clear() {
      if (this._disabled) return

      this.$emit("input", null)
    },

    isActiveOption(option) {
      if (this.optionAsValue) {
        return _.get(this.value, "value") === option.value
      }

      return this.value === option.value
    },

    optionProps(option, index) {
      return {
        option,
        index,
        focus:  this.focusedOption === index,
        active: this.isActiveOption(option)
      }
    },

    defaultOptionClass(option, index) {
      if (this.hasOptionSlot) return {}

      return {
        option: true,
        focus:  this.focusedOption === index,
        active: this.isActiveOption(option)
      }
    },

    onSearch(event) {
      if (!this.searchable) return

      if (!this.isOpen) this.openMenu()

      this.search = event.target.value
      this.focusedOption = null

      this.$emit("search", this.search)
    },

    toggleMenu() {
      if (this.isOpen) {
        this.closeMenu()
        return
      }

      this.openMenu()
    },

    async openMenu() {
      if (this._disabled) return

      // Quem fecha o input é o onBlur
      if (this.isOpen) return

      this.isOpen = true
      this.$emit("open")

      await this.$nextTick()
      this.$refs.input.focus()
    },

    closeMenu({ focus = true } = {}) {
      if (focus && _.present(this.$refs.button)) this.$refs.button.focus()

      this.search = null

      this.isOpen = false
      this.focusedOption = null

      this.$emit("close")
    },

    onClickOutside() {
      this.closeMenu({ focus: false })
    },

    onSelectByClick(option) {
      if (this._disabled) return

      if (_.blank(this.value) || this.value.value !== option.value) {
        const value = this.optionAsValue ? option : option.value
        this.$emit("input", value)
      }
      else {
        this.$emit("input", null)
      }

      if (this.closeOnSelect) this.closeMenu()
    },

    onSelectByEnter() {
      if (this._disabled) return

      if (_.blank(this.focusedOption)) {
        this.closeMenu()
        return
      }

      const option = this.options$[this.focusedOption]

      const value = this.optionAsValue ? option : option.value
      this.$emit("input", value)

      if (this.closeOnSelect) this.closeMenu()
    },

    onKeyEsc() {
      this.closeMenu()
    },

    onKeyUp() {
      let totalIndexPosition = this.options$.length - 1

      this.focusedOption = (this.focusedOption === 0 || _.blank(this.focusedOption))
        ? totalIndexPosition
        : this.focusedOption - 1
      return false
    },

    onKeyDown(e) {
      if (!this.isOpen) this.openMenu()
      let totalIndexPosition = this.options$.length - 1

      this.focusedOption = (this.focusedOption === totalIndexPosition || _.blank(this.focusedOption))
        ? 0
        : this.focusedOption + 1
      return false
    }
  }
}

</script>

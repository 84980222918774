<style lang="scss" scoped>

.assistant-select-field {
  .row {
    min-height: 40px;
    padding: 8px 12px;
  }

  .option {
    border-bottom: 1px solid $light-gray-3;
    cursor: pointer;

    .remove-icon-content {
      width: 24px;
      height: 24px;
      line-height: 24px;
      text-align: center;
      border-radius: 50px;
      color: $gray-2;
      transition: all 100ms linear;
    }

    &.active {
      color: $primary;
      background: $light-gray-2;
    }

    &.focus {
      background: $light-gray-2;
      color: $dark-primary-2;

      .remove-icon-content {
        background: $light-gray-3;
        color: $primary;
      }
    }
  }

  .error,
  .empty {
    padding: 4px 12px;
    font-size: 14px;
    font-style: italic;
  }

  .error {
    .link {
      margin-left: 5px;
      font-size: 14px;
      font-style: italic;
      font-weight: normal;
    }
  }
}

</style>


<template lang="pug">

  select-field.assistant-select-field(
    :name="name",
    :label="label",
    :hide-label="hideLabel",
    :fetching="fetching",
    :disabled="disabled",
    :placeholder="placeholder",
    :optional="optional",
    :mandatory="mandatory",
    :menu-width="menuWidth",
    :menu-growth-side="menuGrowthSide",
    :errors="errors",

    searchable,
    option-as-value,
    :loading="loading || fetchingOption",
    :options="options",
    :default-filter="false",

    :value="selectedOption",
    @input="onSelect",

    @open="onOpen",
    @close="onClose",
    @inputSearch="updateSearch",
    @search="onSearch"
  )
    template(#menuContentHeader)
      template(v-if="fetchError")
        .error.row.flex.vertical-center.center
          span {{ $t('.error.message') }}
          app-button.link(theme="link", @click="onSearch") {{ $t('.error.link') }}

    template(#menuContentFooter)
      template(v-if="!fetching && !fetchError && empty")
        label.empty.row.flex.vertical-center.center {{ $t('.empty') }}

    template(#fetching)
      li.row.flex.vertical-center {{ $t('.loading') }}

    template(#option="{ props: { option, index, focus, active } }")
      .option.row.flex.vertical-center.space-between(:class="{ focus, active }")
        app-span(crop, :value="option.label")
        .remove-icon-content(v-if="active")
          i.fal.fa-times

</template>


<script>

import { i18n } from "utils.vue"

// Mixins
import FieldMixin  from "@/mixins/field"
import AsyncSelect from "@/mixins/select/async"


export default {
  name: "AssistantSelectField",

  extends: AsyncSelect,
  mixins:  [FieldMixin],

  props: {
    // TODO: reformar esse AsyncSelect, reaproveitando código do app-select!
    // XXX: copiando props do select-field, para repassar
    menuWidth:      { type: Number, default: null },
    menuGrowthSide: { type: String, default: "right", validator: side => ["left", "right"].includes(side) },


    // @override Field Mixin
    placeholder: { type: String, default: i18n.t("form.select.placeholder") }
  },

  data() {
    return {
      i18nScope: "monitoring.assistant-select-field",

      fetchingOption: false,
      fetchError:     false,

      // @override AsyncSelect Mixin
      optionAttributeMapping: {
        value: "rid",
        label: "name"
      }
    }
  },

  computed: {
    noneOption() {
      return {
        data:  null,  // seria um MovidaAssistant
        label: this.$t(".options.none.label"),
        value: "none"  // XXX: special value que define "ninguém"
      }
    }
  },

  methods: {
    // @override AsyncSelect Mixin
    async fetchOption(rid) {
      try {
        this.fetchingOption = true

        const { data } = await this.$newSdk.monitoring.assistants.find({ assistantRid: rid })

        this.option = this.dataToOption(data)
      }
      catch (err) {
        console.error(err)
      }
      finally {
        this.fetchingOption = false
      }
    },

    // @override AsyncSelect Mixin
    fetchRequest() {
      return this.$newSdk.monitoring.assistants.all({ params: { q: this.search } })
    },

    // @hook
    afterFetch() {
      if (this.fetchError) return

      // adicionando opção fixa "Ninguém" no topo
      this.options = [this.noneOption, ...this.options]
    }
  }
}

</script>

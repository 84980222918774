<style lang="scss" scoped>

.vehicle-card {
  background-color: $light-gray;
  border-radius: 8px;
  padding: 16px;

  .manufacturer {
    margin-right: 4px;
  }

  .name {
    font-family: $secondary-font;
    font-weight: 500;
    font-size: 16px;

    .fragment + .fragment {
      margin-left: 8px;
    }
  }

  .version {
    font-family: $secondary-font;
    font-weight: 500;
    font-size: 14px;
    color: $gray-3;
  }
}

</style>


<template lang="pug">

.vehicle-card
  .flex.vertical-center.space-between
    .left.flex.vertical-center
      .info
        .flex.vertical-center
          vehicle-manufacturer-icon.manufacturer(
            :size="20",
            :value="vehicle.manufacturerName"
          )
          h2.name.flex
            span.fragment {{ vehicle.manufacturerName }}
            span.fragment {{ vehicle.modelName }}
            span.fragment {{ vehicle.manufacturingYear }}

        span.version {{ vehicle.versionName }}

    license-plate(:value="vehicle.licensePlate")

</template>


<script>

export default {
  props: {
    vehicle: { type: Object, required: true }
  },

  data() {
    return {
      i18nScope: "monitoring.quotes.vehicle-card"
    }
  }
}

</script>

<style lang="scss" scoped>

svg {
  &.medium {
    width: 24px;
    height: 11px;
  }
}

.inside {
  fill: var(--gmf-icon-fill);
}

</style>

<template lang="pug">

  svg(
    :class="size"
    viewBox='0 0 26 8'
    fill='none'
    xmlns='http://www.w3.org/2000/svg',
    :style="cssVars"
  )
    path(
      d=`M8.27744 3.95568C8.27744 4.53924 8.17538 5.07849 7.97128 5.57341C7.76718 6.06833 7.48749
        6.49677 7.1322 6.85873C6.77691 7.2133 6.34981 7.494 5.8509 7.70083C5.35954 7.90028 4.82661
        8 4.25211 8C3.65492 8 3.09553 7.89658 2.57394 7.68975C2.05991 7.48292 1.61013 7.20222 1.22461
        6.84765C0.846642 6.48569 0.548049 6.06094 0.32883 5.57341C0.10961 5.08587 0 4.5614 0 4C0 3.4386
        0.10961 2.91413 0.32883 2.42659C0.548049 1.93906 0.846642 1.51801 1.22461 1.16344C1.60257
        0.801477 2.04857 0.517082 2.5626 0.310249C3.07664 0.103416 3.62846 0 4.21809 0C4.89087 0 5.52585
        0.136657 6.12303 0.409972C6.72022 0.683287 7.20779 1.05263 7.58576 1.51801L6.21375 2.72576C5.95673
        2.40813 5.65814 2.16805 5.31797 2.00554C4.9778 1.83564 4.61117 1.75069 4.21809 1.75069C3.9006
        1.75069 3.60579 1.80979 3.33365 1.92798C3.06152 2.03878 2.82718 2.19391 2.63064 2.39335C2.44165
        2.5928 2.29047 2.83287 2.17708 3.11357C2.06369 3.38689 2.00699 3.68236 2.00699 4C2.00699 4.32502
        2.06369 4.62788 2.17708 4.90859C2.29803 5.1819 2.45677 5.42198 2.65331 5.62881C2.85742 5.82825
        3.09931 5.98707 3.37901 6.10526C3.6587 6.21607 3.96107 6.27147 4.28612 6.27147C4.73968 6.27147 5.13655
        6.16066 5.47671 5.93906C5.82444 5.71006 6.055 5.41459 6.16839 5.05263H4.02533V3.40166H8.27744V3.95568Z`
      class='inside'
    )

    path(
      d=`M13.7076 7.3241L11.0997 2.57064V7.87812H9.30813V0.121883H11.7687L13.7303 3.77839L15.692
        0.121883H18.1525V7.87812H16.3156V2.5928L13.7076 7.3241Z`
      class='inside'
    )

    path(
      d=`M19.4733 7.87812V0.121883H25.7778V1.77285H21.4236V3.22438H24.3264V4.81994H21.4236V7.87812H19.4733Z`
      class='inside'
    )

</template>


<script>

export default {
  name: "GmfIcon",

  props: {
    color: { type: String, default: "#F47920" },
    size:  { type: String, default: null }  // small, medium, large
  },

  computed: {
    cssVars() {
      return {
        "--gmf-icon-fill": this.color
      }
    }
  }
}

</script>

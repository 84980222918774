<style lang="scss" scoped>

.service-package {
  padding-bottom: 24px;

  .header {
    padding: 18px 0 0 0;

    .service-package-name {
      font-size: 30px;
      font-weight: bold;
      word-break: normal;
      overflow-wrap: break-word;
    }

    .id-tag {
      margin: 0 8px;
    }

    .actions {
      .btn + .btn {
        margin-left: 8px;
      }

      .icon {
        font-size: 16px;
        margin-right: 8px;
      }
    }
  }

  .section + .section {
    margin-top: 24px;
    padding-top: 24px;
    border-top: 1px solid $light-gray-3;
  }

  .description {
    font-size: 16px;
    font-weight: normal;
    margin: 0;
  }

  .list + .list {
    margin-top: 24px;
  }

  .footer {
    .icon {
      margin-right: 8px;
    }
  }
}

</style>


<template lang="pug">

  .service-package
    loading-lines(
      v-if="fetching",
      :lines="4"
    )

    template(v-else)
      header.section.header.flex.space-between.vertical-center
        .flex.vertical-center
          h2.service-package-name {{ resource.name }}
          id-tag.id-tag(:value="resource.id", size="big")

        .actions.flex
          destroy-action.btn(
            :id="resource.id",
            :name="resource.name",
            @destroy="onDestroy",
            data-test="destroyAction"
          )
            i.fal.fa-trash-alt.icon
            span {{ $t('.btn.destroy') }}

          app-button.btn(
            v-if="$can('update', 'ServicePackage')",
            theme="neutral",
            size="small",
            :to="{ name: 'editServicePackage', params: { id }}"
          )
            i.fal.fa-pencil.icon
            span {{ $t('.btn.edit') }}

      section.section.description-content.preserve-whitespace(v-if="resource.description")
        p.description {{ resource.description }}

      section.section.lists
        services.list(
          :services="resource.services",
          :fetching="fetching"
        )
        products.list(
          :products="resource.products",
          :fetching="fetching"
        )

      section.section.footer.flex.space-between
        app-button(:to="{ name: 'servicePackages' }", outline)
          i.far.fa-arrow-left.icon
          span {{ $t('.btn.back') }}

        app-button(
          v-if="$can('create', 'ServicePackage')",
          theme="link",
          :to="{ name: 'newServicePackage' }"
        )
          span {{ $t('.btn.new') }}

</template>


<script>

// Mixins
import { mixins } from "movida-common.vue"

// Extends
import View from "@/views/view"

// Local Components
import DestroyAction from "./components/destroy-action"
import Services from "./services/show"
import Products from "./products/show"

const { Fetchable } = mixins

export default {
  name: "ServicePackage",

  components: {
    DestroyAction,
    Services,
    Products
  },

  extends: View,

  mixins: [Fetchable],

  data() {
    return {
      i18nScope: "service-packages.show",
      model:     "ServicePackage",
      id:        this.$route.params.id,
      resource:  {}
    }
  },

  methods: {
    onDestroy() {
      this.$router.push({ name: "servicePackages" })
    },

    // @override Fetchable mixin
    fetch() {
      return this.$sdk.servicePackages.get({ id: this.id })
    },

    // @override Fetchable mixin
    // onFetchSuccess(response) {},

    // @override Fetchable mixin
    onFetchError(err) {
      if (this.hasViewError(err)) {
        this.appError = err
      }

      this.$err.log(err)
      this.$notifications.error(this.$t(".notifications.fetch.failure"))
    }
  }
}

</script>

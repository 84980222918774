<style scoped lang="scss">

.field {
  margin-bottom: 24px;
}

.row + .row {
  margin-top: 16px;
}

.service-type-icon {
  width: 32px;
  height: 32px;
  user-select: none;
}

.content-text {
  margin-left: 16px;
  font-family: $secondary-font;
}

.service-name {
  font-weight: 500;
  font-size: 20px;
}

.service-description {
  font-weight: normal;
  font-size: 12px;
}

.type-section {
  padding-bottom: 24px;
}

.type-loading {
  padding-bottom: 24px;
}

</style>


<template lang="pug">

  app-modal(
    :width="800",
    :heading="$t('.title')",
    :close-button="!submitting",
    footer,
    :confirm-label="$t('.btn.update.label')",
    :confirm-label-loading="$t('.btn.update.loading')",
    @close="$emit('close')",
    @confirm="submit"
  )
    .body
      template(v-if="fetching")
        loading-lines.type-loading(:height="38", :min="30", :max="70", data-test="typeLoading")
      template(v-else)
        .type-section.flex.vertical-center
          service-type-icon(
            :type="type",
            hide-tooltip
          )
          .content-text.flex.column-direction
            span.service-name(data-test="typeName") {{ typeText }}
            span.service-description {{ $t(`.types.${type}.description`) }}

      form.form(@submit.prevent="submit", method="post", data-test="form")
        ui-row.row
          ui-col(:span="6")
            input-field(
              :name="`supplierServiceSupport[totalCapacity]`",
              v-model="resource.totalCapacity",
              :loading="submitting",
              :disabled="fetching",
              :errors="errors.totalCapacity",
              info,
              :tooltip="$t('models.supplierServiceSupport.tooltip.totalCapacity')",
              data-testid="totalCapacityInput"
            )

          ui-col(:span="6")
            input-field(
              :name="`supplierServiceSupport[hourlyCapacity]`",
              v-model="resource.hourlyCapacity",
              :loading="submitting",
              :disabled="fetching",
              :errors="errors.hourlyCapacity",
              data-testid="hourlyCapacityInput"
            )

</template>


<script>

// Mixins
import { mixins } from "movida-common.vue"

// Models
import models from "@/models"

const { FormMixin, Fetchable } = mixins
const { SupplierServiceSupport } = models

export default {
  mixins: [FormMixin, Fetchable],

  props: {
    supplierId:      { type: [String, Number], required: true },
    serviceTypeSlug: { type: String, required: true }
  },

  data() {
    return {
      i18nScope: "suppliers.service-supports.edit",

      resource: {},
      model:    "SupplierServiceSupport"
    }
  },

  computed: {
    type() {
      return this.resource.serviceType
    },

    typeText() {
      if (_.blank(this.type)) return null

      return SupplierServiceSupport.$tEnum("serviceType", this.type)
    }
  },


  methods: {
    // @override Fetchable mixin
    fetch() {
      return this.$sdk.suppliers.getServiceSupport({
        id:              this.supplierId,
        serviceTypeSlug: this.serviceTypeSlug
      })
    },

    // @override Fetchable mixin
    // onFetchSuccess(response) {},

    // @override Fetchable mixin
    onFetchError(err) {
      this.$err.log(err)
      this.$notifications.error(this.$t(".notifications.fetch.failure", {
        type: this.typeText
      }))
    },

    // @override Form mixin
    serializeAttributes() {
      return this.resource.$serialize()
    },

    // @override Form mixin
    submitRequest() {
      return this.$sdk.suppliers.updateServiceSupport({
        id:              this.supplierId,
        serviceTypeSlug: this.serviceTypeSlug,
        params:          this.serializeAttributes()
      })
    },

    // @override Form mixin
    submitSuccess(response) {
      this.$notifications.info(this.$t(".notifications.submit.success", {
        type: this.typeText
      }))

      this.$emit("update")
      this.$emit("close")
    },

    // @override Form mixin
    submitError(err) {
      this.$err.log(err)

      this.$notifications.error(this.$t(".notifications.submit.error", {
        type: this.typeText
      }))
    }
  }
}

</script>

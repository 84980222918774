// Base
import BaseRequests from "./Base"

class VehicleModelsRequests extends BaseRequests {
  static get selector() {
    return "vehicleModels"
  }

  allMobiautoVersionsWithModel({ params = {}, options = {} } = {}) {
    return this.action({
      method:  "get",
      url:     "/mobiauto/versions_with_model",
      params,
      options: {
        model: null,

        ...options
      }
    })
  }
}

export default VehicleModelsRequests

<style lang="scss" scoped>

.service-order-service-row {
  grid-template-columns: minmax(172px, 1fr) 42px 101px 101px;
  min-height: 32px;
  border-top: 1px solid $light-gray-4;
  padding: 8px 0;

  .column {
    padding: 0 8px;
  }

  .name {
    font-family: $secondary-font;
    font-weight: 400;
    font-size: 14px;
  }

  .value {
    font-family: $primary-monospace-font;
    font-weight: 400;
    font-size: 14px;
  }
}

</style>


<template lang="pug">

  .service-order-service-row.grid.vertical-center
    .column
      span.name {{ serviceOrderService.name }}

    .column.text-right
      span.value {{ serviceOrderService.quantity }}

    .column.text-right
      span.value {{ $asCurrency(serviceOrderService.price, { unit: "" }) }}

    .column.text-right
      span.value {{ $asCurrency(serviceOrderService.totalPrice, { unit: "" }) }}

</template>


<script>

export default {
  props: {
    serviceOrderService: { type: Object, required: true }
  },

  data() {
    return {
      i18nScope: "monitoring.quotes.reviews.service-order-service-row"
    }
  }
}

</script>

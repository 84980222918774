<style lang="scss" scoped>

// .form-fields {
//
// }

.field {
  margin-bottom: 16px;

  &:last-child {
    margin-bottom: 0;
  }
}

.terminate-all {
  ::v-deep .checkbox-label {
    font-weight: 400!important;
    line-height: 1.5;
  }
}
</style>


<template lang="pug">

  .form-fields
    datetime-field.field.at(
      mandatory,
      :max-date="now",
      v-model="resource.at",
      name="vehicleWithdrawal[at]",
      :label="$t('.fields.at.label')",

      :disabled="submitting",
      :errors="errors.at",
      :loading="fetching",
    )

    textarea-field.field.description(
      v-model="resource.description",
      mandatory,
      name="vehicleWithdrawal[description]",
      :label="$t('.fields.description.label')",
      :placeholder="$t('.fields.description.placeholder')",

      :disabled="submitting",
      :loading="fetching",

      :errors="errors.description",
    )

    checkbox-field.field.terminate-all(
      hide-label
      :disabled="submitting"
      :loading="fetching"
      name="terminate_all"
      :value="terminateAll"
      :value-text="$t('.fields.terminateAll.text')"
      @input="onTerminateAllInput"
    )

</template>


<script>

import models from "@/models"
const VehicleWithdrawal = models.Events.ServiceOrders.VehicleWithdrawal


export default {
  emits: ["update:terminate-all"],

  props: {
    errors:       { type: Object, default: () => ({}) },
    fetching:     { type: Boolean, default: false },
    resource:     { type: VehicleWithdrawal, required: true },
    submitting:   { type: Boolean, default: false },
    terminateAll: { type: Boolean, default: false }
  },

  data() {
    return {
      i18nScope: "monitoring.vehicle-withdrawals.form-fields",

      now: moment().toISOString()
    }
  },

  methods: {
    onTerminateAllInput(value) {
      this.$emit("update:terminate-all", value)
    }
  }
}

</script>

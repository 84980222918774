import { i18n } from "utils.vue"
import Base from "./base"

class VehicleModelGroupMaintenance extends Base {
  static get attrs() {
    return _.uniq([
      ...super.attrs,
      "kilometrageLimit",
      "timeLimit",
      "targetExecutionTime",
      "freeOfCharge",
      "forbiddenInInternalFacilities",
      "dealershipOnly",
      "maintenanceTableId",
      "maintenancePackageId",
      "maintenanceId"
    ])
  }

  static get relations() {
    return {
      maintenanceTable: {
        type:  "belongsTo",
        model: "VehicleModelGroupMaintenanceTable"
      },

      maintenancePackage: {
        type:  "belongsTo",
        model: "MaintenancePackage"
      },

      maintenance: {
        type:  "belongsTo",
        model: "Maintenance"
      }
    }
  }

  static get constraints() {
    // XXX: Refazendo as restrições sem herdar da classe pai (Service)
    return {
      maintenancePackageId: { presence: true },
      maintenanceId:        { presence: true },

      kilometrageOrTimeLimit: {
        presence(value, attrs) {
          return _.blank(attrs.kilometrageLimit) && _.blank(attrs.timeLimit)
        }
      },

      kilometrageLimit: {
        presence(value, attrs) { return _.blank(attrs.timeLimit) },
        numericality: {
          greaterThan: 0
        }
      },

      timeLimit: {
        presence(value, attrs) { return _.blank(attrs.kilometrageLimit) },
        numericality: {
          greaterThan: 0
        }
      },

      targetExecutionTime: {
        numericality: {
          greaterThan: 0
        }
      }
    }
  }

  get formattedKilometrageLimit() {
    if (_.blank(this.kilometrageLimit)) return null

    const distance = i18n.l("number", this.kilometrageLimit, { precision: 0 })

    return i18n.t("models.vehicleModelGroupMaintenance.formattedAttributes.kilometrageLimit", {
      distance
    })
  }

  get formattedTimeLimit() {
    if (_.blank(this.timeLimit)) return null

    return i18n.t("count.months", { count: this.timeLimit })
  }

  get formattedTargetExecutionTime() {
    if (_.blank(this.targetExecutionTime)) return null

    return i18n.t("count.hours", { count: this.targetExecutionTime })
  }
}

export default VehicleModelGroupMaintenance

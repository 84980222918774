<!--
<doc lang="markdown">

Exibe um ícone padronizado, com _tooltip_, mostrando o estado do atendimento

Como usar:
```pug
  ticket-state-icon(:state="ticket.state")
```

</doc>
-->


<style lang="scss" scoped>

.ticket-state-icon {
  display: inline-block;
  color: $gray-2;

  &.normal {
    font-size: 16px;
    width: 16px;
    height: 16px;
    line-height: 16px;
  }

  &.big {
    font-size: 24px;
    width: 24px;
    height: 24px;
    line-height: 24px;
  }

  .icon {
    &.open {
      color: $gray-3;
    }

    &.ongoing {
      color: $orange;
    }

    &.executed {
      color: $success;
    }

    &.canceled {
      color: $error;
    }
  }
}

</style>


<template lang="pug">

  .ticket-state-icon(:class="size", v-tooltip="tooltipOptions")
    i.icon.fas(
      :class="{ [state]: true, [stateIcon]: true }",
      data-testid="ticketStateIcon"
    )

</template>


<script>

// Models
import models from "@/models"
const { Ticket } = models

export default {
  name: "TicketStateIcon",

  props: {
    state: { type: String, required: true },
    size:  { type: String, default: "normal", validator: size => ["normal", "big"].includes(size) }
  },

  data() {
    return {
      i18nScope: "components.icons.ticket-state-icon"
    }
  },

  computed: {
    stateIcon() {
      switch (this.state) {
        case "open":
          return "fa-envelope-open-text"
        case "ongoing":
          return "fa-file-import"
        case "executed":
          return "fa-check-circle"
        case "canceled":
          return "fa-times-circle"
        case "draft":
          return "fa-hard-hat"
        // XXX: o estado "done" não é usado ainda
        // case 'done':
        default:
          return "fa-question"
      }
    },

    tooltipOptions() {
      return {
        content:   Ticket.$tEnum("state", this.state),
        placement: "bottom",
        offset:    "4"
      }
    }
  }
}

</script>

<style lang="scss" scoped>

.index {
  .header {
    margin-bottom: 32px;
    padding-bottom: 16px;
    border-bottom: 1px solid $light-gray-3;

    .title-icon {
      margin-right: 16px;
      font-size: 30px;
    }

    .title-text {
      font-size: 30px;
      font-weight: normal;
      font-weight: 500;
      font-family: $secondary-font;
    }
  }

  .clear-filters {
    position: relative;

    .filters-button {
      position: absolute;
      margin-top: 10px;
    }
  }

  .list {
    margin-top: 50px;

    .table {
      margin-top: 16px;
    }
  }

  .paginator-content {
    padding-top: 24px;

    &.loading {
      max-width: 400px;
      margin: 0 auto;
    }
  }
}

</style>


<template lang="pug">

  .index
    header.header.flex.space-between.vertical-center
      .title.flex.vertical-center
        i.title-icon.fal.fa-car
        h1.title-text {{ $t('.title') }}

    search-form.search(
      :loading="fetching",
      :params="parsedQueryParams",
      :service-order-state-options-filter="serviceOrderStateOptionsFilter",
      @search="search"
    )

    .clear-filters
      filters-button.filters-button(
        v-if="hasFilters",
        @clear="clearFilters"
      )

    .list
      .flex.end.vertical-bottom
        results-indicator(
          :page="parsedQueryParams.page",
          :per-page="pagination.perPage",
          :total="pagination.total",
          :loading="fetching"
        )

      vehicles-table.table(
        :vehicles="vehicles",
        :initializing="initializing",
        :loading="fetching",
        :sorted-by="parsedQueryParams.sort",
        :request-query-params="requestQueryParams",
        :service-order-state-options-filter="serviceOrderStateOptionsFilter",
        @sort="onSortChange",
        @changed="fetch"
      )

    .paginator-content(v-if="fetching || showPaginator", :class="{ loading: fetching }")
      paginator(
        :loading="fetching",
        :per-page="pagination.perPage",
        :total="pagination.total",
        :value="parsedQueryParams.page",
        :disabled="fetching",
        @input="onPageChange",
        data-testid="paginator"
      )

</template>


<script>

// Components
import SearchForm from "./index/search-form.vue"
import VehiclesTable from "./index/vehicles-table.vue"

// Mixins
import FetchMixin from "@/mixins/fetch-mixin"

// Extends
import IndexView from "@/views/index-view.vue"

export default {
  components: {
    SearchForm,
    VehiclesTable
  },

  extends: IndexView,

  mixins: [FetchMixin],

  props: {},

  data() {
    return {
      i18nScope: "vehicles.index",
      routeName: "vehicles",

      resource: [],

      // Query Mixin data
      queryParamsStructure: {
        q:                   "string",
        state:               "array",
        current_store_id:    { type: "array", options: { arrayType: "integer" } },
        service_order_state: "array",
        page:                "integer",
        sort:                "string"
      },

      // @override Index View
      defaultQuery: {
        sort: "last_movement_at:desc"
      },

      // XXX: Apenas "aberta" e "emitida" por enquanto
      serviceOrderStateOptionsFilter: ["open", "ongoing"]
    }
  },

  computed: {
    vehicles: {
      get() { return this.resource },

      set(value) { this.resource = value }
    },

    empty() {
      return _.blank(this.vehicles)
    }
  },

  methods: {
    // @override Index View
    parseRoute() {},

    // @override Fetch Mixin
    fetchRequest() {
      return this.$newSdk.vehicles.all({ params: this.requestQueryParams })
    }
  }
}

</script>

<style lang="scss" scoped>

.quote-provided-products-table {
  gap: 8px;

  .empty {
    background-color: $light-gray-3;
    padding: 8px;
    border-radius: 4px;
    text-align: center;
    font-weight: 300;
    font-size: 12px;
    font-style: italic;
  }

  .header {
    grid-template-columns: minmax(296px, 1fr) 40px 50px 40px;
    gap: 8px;
    height: 32px;

    .column {
      padding: 0 8px;
      font-family: $secondary-font;
      font-weight: 400;
      font-size: 12px;
      color: $gray-3;

      .icon {
        font-size: 14px;
      }

      .diamond-icon {
        color: $gray-3;
        font-size: 8px;
      }
    }
  }
}

</style>


<template lang="pug">

.quote-provided-products-table.grid
  template(v-if="empty")
    .empty {{ emptyText }}

  template(v-else)
    .header.grid.vertical-center
      .column
        span {{ $t('.header.name') }}
      .column.text-center
        i.icon.far.fa-paperclip
      .column.text-right
        span {{ $t('.header.quantity') }}
      .column.text-center
        i.diamond-icon.fas.fa-diamond

    slot(name="rows")

</template>


<script>

export default {
  props: {
    empty:     { type: Boolean, default: false },
    emptyText: { type: String, required: true }
  },

  data() {
    return {
      i18nScope: "monitoring.quotes.reviews.quote-provided-products-table"
    }
  }
}

</script>

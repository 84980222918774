<style lang="scss" scoped>

.timeline-popover-ongoing {
  padding: 16px;
  width:   400px;

  max-height: 500px;
  overflow-y: auto;

  > .loading {
    width: 100%;
  }

  > .erred {
    font-size: 16px;
  }

  > .content {
    .section + .section {
      margin-top: 16px;
    }

    .title {
      font-family: $secondary-font;
      font-size: 16px;
      font-weight: 500;
      color: $dark-gray;
    }

    .info {
      margin-top: 8px;
    }

    > .separator {
      margin-top: 12px;
      border-top: 1px solid $light-gray-3;
      padding-top: 12px;
    }
  }

  .empty {
    .empty-message {
      font-weight: 400;
      font-style: italic;
      font-size: 14px;
      color: $gray-3;
    }
  }
}


.icon-container {
  width: 16px;
  height: 16px;
  margin-right: 8px;

  .icon {
    font-size: 12px;
    color: $gray-3;

    &.warning {
      color: $dark-warning;
    }
  }

  &.main {
    width: 24px;
    height: 24px;
    margin-right: 16px;

    .icon {
      font-size: 20px;
      color: $purple;

      &.warning {
        color: $dark-warning;
      }
    }

    &.empty {
      .icon {
        color: $gray-2;
      }
    }
  }
}

.estimated-completed {
  .icon-container {
    .icon {
      color: $gray-4;
    }
  }

  .details {
    display: flex;
    align-items: center;
  }
}
.diamond-icon {
  color: $gray;
  font-size: 4px;
  margin: 0 4px;
}

.postponements {
  .timestamps {
    margin-top: 4px !important;
  }

  .from {
    color: $dark-gray;
    text-decoration: line-through;
  }

  .arrow {
    padding-left: 4px;
    padding-right: 4px;
  }

  .to {
    color: $dark-success;
  }
}

</style>


<template lang="pug">

  .timeline-popover-ongoing
    template(v-if="fetching")
      .loading
        loading-lines(:lines="3")

    template(v-else-if="erred")
      .erred.flex.column-direction.vertical-center
        span.text-center {{ $t(".fetch-error.message") }}
        app-button.retry(theme="link", @click="$fetch") {{ $t(".fetch-error.retry") }}

    template(v-else-if="authorized")
      .content.flex.column-direction
        .header.section.flex
          .icon-container.main.flex.center.vertical-center
            i.icon.fas.fa-file-check

          .data.full-width.flex.column-direction
            span.title {{ $t(".title", { at: $fromNow(serviceOrder.authorizedAt) }) }}
            span {{ $l("time.formats.short", serviceOrder.authorizedAt) }}

        template(v-if="serviceOrder.estimatedCompletedAt")
          .estimated-completed.section.flex
            .icon-container.main.flex.center.vertical-center
              i.icon.fal.fa-check-square

            .data.full-width.flex.column-direction
              span.title {{ $t(".sections.estimatedCompleted.title") }}
              .details
                span {{ estimatedWeekday }}
                i.diamond-icon.fas.fa-diamond
                span {{ $l("time.formats.short", serviceOrder.estimatedCompletedAt) }}

        template(v-if="hasPostponements")
          .separator

          .postponements.section.flex.column-direction
            .header.flex
              .icon-container.main.flex.center.vertical-center
                i.icon.fas.fa-clock.warning

              .data.full-width.flex.column-direction
                span.title {{ postponementsTitleText }}

                template(v-for="postponement in postponements")
                  .info.flex.vertical-baseline
                    .icon-container.flex.center.vertical-center
                      i.icon.fas.fa-pencil
                    span {{ postponementTitleTextFor(postponement) }}

                  .info.flex.vertical-baseline.timestamps
                    .icon-container.flex.center.vertical-center
                      | &nbsp;
                    span.text
                      span.from {{ !!postponement.from ? $l("time.formats.short", postponement.from) : $t("abbr.unavailable") }}
                      i.arrow.fa.fal.fa-arrow-right
                      span.to {{ $l("time.formats.short", postponement.to) }}

                  .info.flex.vertical-baseline(style="padding-left: 22px")
                    .icon-container.flex.center.vertical-center(v-tooltip="$t('.fields.postponements.description.tooltip')")
                      i.icon.fal.fa-message-lines
                    span.preserve-whitespace(:class="{ italic: !postponementHasDescription(postponement) }") {{ postponementDescriptionTextFor(postponement) }}


    template(v-else)
      .empty.section.flex.vertical-center
        .icon-container.main.empty.flex.center.vertical-center
          i.icon.fas.fa-file-check

        .content.full-width
          span.empty-message {{ $t(".empty") }}

</template>


<script>


export default {
  components: {},
  emits:      [],

  props: {
    serviceOrderRid: { type: [Number, String], required: true },
    show:            { type: Boolean, required: true }
  },

  data() {
    return {
      i18nScope: "monitoring.timeline-popover-ongoing",

      // flags
      initialized: false,
      erred:       false,
      fetching:    false,
      submitting:  false,

      resource:      null,  // serviceORder
      postponements: null,

      errors: {}
    }
  },

  computed: {
    serviceOrder: {
      get()      { return this.resource  },
      set(value) { this.resource = value }
    },

    initializing() {
      return !this.initialized
    },

    loading() {
      return this.initializing || this.fetching || this.submitting
    },

    authorized() {
      return this.serviceOrder?.authorized
    },

    hasPostponements() {
      return _.present(this.postponements)
    },

    postponementsTitleText() {
      if (!this.hasPostponements) return null

      return this.$t(".sections.postponements.title", {
        count: this.postponements.length
      })
    },

    estimatedWeekday() {
      if (_.isEmpty(this.serviceOrder?.estimatedCompletedAt)) return ""
      return _.capitalize(moment(this.serviceOrder).format("dddd"))
    }
  },

  watch: {
    show: {
      immediate: true,
      handler() {
        if (this.show) this.$fetch()
      }
    }
  },

  created() {
    this.$initialize()
  },

  beforeDestroy() {
    if (this.loading) this.$cancel()
  },

  methods: {
    async $initialize() {
      await this.initialize()

      this.initialized = true
    },

    async initialize() {},

    async $cancel() {
      if (this.fetchPromise) this.fetchPromise.cancel()

      await this.cancel()
    },

    async cancel() {},

    async $fetch() {
      if (this.fetching) await this.$cancel()

      this.erred    = false
      this.fetching = true

      await this.fetch()

      this.fetching = false
    },

    async fetch() {
      await this.fetchServiceOrder()
      await this.fetchPostponements()
    },

    async fetchServiceOrder() {
      try {
        this.fetchPromise = this.$newSdk.monitoring.serviceOrders
          .find(this.serviceOrderRid)
        let response = await this.fetchPromise

        this.serviceOrder = response.data
      }
      catch (err) {
        let error = err.error || err

        // XXX: Pode não haver dados registrados
        if (error.cancelled || error.status === 404) return

        this.erred = true
        this.$err.log(err)
        this.$notifications.error(this.$t(".notifications.fetch.failure"))
      }
      finally {
        this.fetchPromise = null
      }
    },

    async fetchPostponements() {
      try {
        this.fetchPromise = this.$newSdk.monitoring.serviceOrders.postponements(this.serviceOrderRid)
        let response = await this.fetchPromise

        this.postponements = response.data
      }
      catch (err) {
        let error = err.error || err

        // XXX: Pode não haver dados registrados
        if (error.cancelled || error.status === 404) return

        this.erred = true
        this.$err.log(err)
        this.$notifications.error(this.$t(".notifications.fetch.failure"))
      }
      finally {
        this.fetchPromise = null
      }
    },

    // utils
    simpleNameFor(fullname) {
      if (_.blank(fullname)) return null

      let names = fullname.replace(/[^\w\s]/g, "").replace(/\s+/g, " ").trim().split(" ")

      if (_.blank(names)) return "?"

      let firstName = names[0]
      let lastName  = names.length > 1 ? names.pop() : ""

      return `${firstName} ${lastName}`.trim()
    },

    postponementHasDescription(postponement) {
      return _.present(postponement?.description)
    },

    postponementTitleTextFor(postponement) {
      return this.$t(".fields.postponements.title", {
        at:     this.$l("time.formats.short", postponement.createdAt),
        author: this.simpleNameFor(postponement.authorName)
      })
    },

    postponementDescriptionTextFor(postponement) {
      return this.postponementHasDescription(postponement)
        ? postponement.description
        : this.$t(".fields.postponements.description.empty")
    }
  }
}

</script>

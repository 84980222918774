import Base from "./base"

class ServiceOrderReopening extends Base {
  static get attrs() {
    return _.uniq([
      ...super.attrs,
      "description"
    ])
  }

  static get constraints() {
    return {
      description: { presence: true }
    }
  }
}

export default ServiceOrderReopening

const SdkPlugin = {
  installed: false,

  install(Vue, { sdkClient }) {
    if (SdkPlugin.installed) return
    SdkPlugin.installed = true

    Vue.$sdk = sdkClient.requests
    Vue.prototype.$sdk = sdkClient.requests
  }
}

export default SdkPlugin

.<style lang="scss" scoped>

.mobiauto-version-table-header {
  display: grid;
  grid-template-columns: 30px 150px 2fr 150px 110px 100px 220px 50px;
  background-color: $light-gray;
  height: 40px;
  border-bottom: 1px solid $light-gray-3;
  border-top: 1px solid $light-gray-3;

  &.loading {
    border-bottom: 2px solid $light-orange-2;
  }

  .unsortable {
    padding: 0 12px;
  }

  .column {
    font-size: 12px;
    height: 40px;

    .icon {
      font-size: 16px;
      color: $gray-2;
      margin-left: 4px;
    }

    .text {
      font-family: $secondary-font;
      font-size: 14px;
      color: $dark-gray;
      font-weight: 400;
    }

    .loader-icon {
      font-size: 16px;
      color: $orange;
    }
  }
}

</style>


<template lang="pug">

  .mobiauto-version-table-header(:class="{ loading }")
    .column(data-testid="idColumn")
      sortable-table-header-column(
        sort-param="id",
        :sort-order="sortOrder",
        :active="isSortedBy('id')",
        @sort="param => $emit('sort', param)"
      )
        span.text #

    .column(data-testid="manufacturerNameColumn")
      sortable-table-header-column(
        sort-param="manufacturer_name",
        :sort-order="sortOrder",
        :active="isSortedBy('manufacturer_name')",
        @sort="param => $emit('sort', param)"
      )
        span.text {{ $t('.columns.brand.label') }}

    .column(data-testid="modelColumn")
      sortable-table-header-column(
        sort-param="model",
        :sort-order="sortOrder",
        :active="isSortedBy('model')",
        @sort="param => $emit('sort', param)"
      )
        span.text {{ $t('.columns.model.label') }}

    .column.unsortable.flex.vertical-center(data-testid="typeColumn")
      span.text {{ $t('.columns.type.label') }}

    .column(data-testid="fipeColumn")
      sortable-table-header-column(
        sort-param="fipe",
        :sort-order="sortOrder",
        :active="isSortedBy('fipe')",
        @sort="param => $emit('sort', param)"
      )
        span.text {{ $t('.columns.fipe.label') }}

    .column(data-testid="categoryColumn")
      sortable-table-header-column(
        sort-param="category",
        :sort-order="sortOrder",
        :active="isSortedBy('category')",
        @sort="param => $emit('sort', param)"
      )
        span.text {{ $t('.columns.category.label') }}

    .column(data-testid="grupoColumn")
      sortable-table-header-column(
        sort-param="grupo",
        :sort-order="sortOrder",
        :active="isSortedBy('grupo')",
        @sort="param => $emit('sort', param)"
      )
        span.text {{ $t('.columns.grupo.label') }}

    .column.flex.vertical-center.center
      template(v-if="loading")
        i.loader-icon.fas.fa-spin.fa-spinner

</template>


<script>

export default {
  props: {
    loading:   { type: Boolean, default: false },
    sortParam: { type: String, required: true },
    sortOrder: { type: String, required: true }
  },

  data() {
    return {
      i18nScope: "vehicle-models.index.mobiauto-versions-table-header"
    }
  },

  methods: {
    isSortedBy(param) {
      return this.sortParam === param
    }
  }
}

</script>

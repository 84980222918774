<style scoped lang="scss">

.section {
  padding: 16px 32px;
  border-bottom: 1px solid $light-gray-3;
}

.header {
  font-family: $secondary-font;
  font-size: 20px;
  border-bottom: 1px solid $light-gray-3;
}

.body {
  border-bottom: unset;
}

.select-field + .select-field {
  margin-top: 24px;
}

.icon {
  margin-right: 16px;
  font-size: 20px;
  color: $gray-2;
}

.icon-edit {
  font-size: 18px;
  color: $dark-gray;
}

.text {
  font-size: 16px;
  color: $dark-gray;
  font-weight: normal;
}

::v-deep .product-row:last-child {
  border: unset;

  .deal-product-row:last-child {
    .content-deal-product {
      border: unset;
    }
  }
}

.edit-button {
  margin-right: 40px;
}

</style>


<template lang="pug">

  app-modal(
    :width="1156",
    :heading="$t('.title.label')",
    full-height,
    @close="goBack"
  )
    template(#header)
      header.header.flex.vertical-center.space-between
        h1.title {{ $t('.title.label') }}

        app-button.edit-button(
          v-if="editable",
          :to="editRoute",
          theme="neutral",
          size="small",
          outline,
          data-testid="editButton"
        )
          i.fal.fa-pencil.icon-edit

    section.section
      product-header(
        :product="resource.product",
        :vehicle-model-group="resource.vehicleModelGroup",
        :fetching="fetching"
        data-testid="productHeader"
      )

    section.section(v-if="isPart")
      span.text {{ $t(`models.dealProduct.enums.partOrigin.${resource.partOrigin}`) }}

    section.section
      template(v-if="fetching")
        loading-lines
      template(v-else)
        .flex.vertical-center.space-between
          .flex.vertical-center
            i.icon.fal.fa-globe
            span.text {{ $t('.price.title.label') }}

          label.text {{ resource.formattedPrice }}

    section.section.body
      router-view(
        v-if="!fetching && !hasFetchError",
        :vehicle-model-group="resource.vehicleModelGroup"
      )

</template>


<script>

// Mixins
import { mixins } from "movida-common.vue"

// Extends
import View from "@/views/view"

// Local Components
import ProductHeader from "./components/product-header"

const { Fetchable } = mixins

export default {
  name: "SupplierDealProduct",

  components: {
    ProductHeader
  },

  extends: View,

  mixins: [Fetchable],

  props: {
    backRouteQuery: { type: Object, default: () => ({}) },
    editable:       { type: Boolean, default: false }
  },

  data() {
    return {
      i18nScope: "suppliers.deals.products.show",

      id:            this.$params.asInteger(this.$route.params.id),
      dealId:        this.$params.asInteger(this.$route.params.dealId),
      dealProductId: this.$params.asInteger(this.$route.params.dealProductId),

      model:    "DealProduct",
      resource: {}
    }
  },

  computed: {
    backRoute() {
      return {
        name:   "supplierDealProducts",
        params: { id: this.id, dealId: this.dealId },
        query:  this.backRouteQuery
      }
    },

    editRoute() {
      return {
        name:   "editSupplierDealProduct",
        params: {
          id:            this.id,
          dealId:        this.dealId,
          dealProductId: this.dealProductId
        }
      }
    },

    isPart() {
      return _.get(this.resource, "product.type") === "Part"
    }
  },

  methods: {
    // @override extends view.vue
    goBack() {
      // Voltando para rota pai ao fechar o modal
      if (_.get(this.$router.referrer, "name") === this.backRoute.name) {
        this.$goBack({ fallback: this.backRoute })
      }
      else {
        this.$router.push(this.backRoute)
      }
    },

    fetch() {
      return this.$sdk.deals.getProduct({
        id:        this.dealId,
        productId: this.dealProductId,
        params:    this.httpParams
      })
    },

    // @override Fetchable mixin
    onFetchError(err) {
      // Dealing with appError from extension view.vue
      if (this.hasViewError(err)) this.appError = err

      this.$err.log(err)
      this.$notifications.error(this.$t(".notifications.fetch.failure"))
    }
  }
}

</script>

<style lang="scss" scoped>

@import "@/assets/styles/field";

.label-content {
  display: flex;
  align-items: center;

  .mandatory-icon {
    font-size: 4px;
    color: $orange;
  }
}

.input-inner {
  resize: vertical;
  height: initial;
  line-height: initial;
  padding-top: 8px;
  padding-bottom: 8px;
  min-height: 80px;

  &:focus {
    padding: 7px 12px;
  }
}

</style>


<template lang="pug">

  .input-field
    label.label-content(v-if="!hideLabel", :for="inputId", :class="{ error: hasErrors, focus }")
      span {{ labelText }}
      span(v-if="optional") {{ $t('form.optional') }}
      template(v-else-if="mandatory")
        i.mandatory-icon.fas.fa-circle(
          v-tooltip.top="$t('form.mandatory.tooltip')"
        )
      i.info-icon.far.fa-info-circle(v-if="info", @click="$emit('info-click')")

    .input-wrapper
      textarea.input-inner(
        :class="{ loading, disabled, error: hasErrors }",
        :id="inputId",
        :autocomplete="showAutocomplete",
        ref="input",
        :autofocus="autofocus",
        :disabled="disabled || loading",
        :readonly="readonly",
        :name="name",
        :placeholder="placeholder",
        :value="value",
        @input="$emit('input', $event.target.value)",
        @focus="focus = true",
        @blur="focus = false",
        :rows="rows"
      )

    span.error-message(v-for="error in errors", :key="error") {{ error }}

</template>


<script>

// TODO: Modificar corretamente para uma textarea, agora está uma cópia de input-field
import FieldMixin from "@/mixins/field"

export default {
  name: "TextareaField",

  mixins: [FieldMixin],

  props: {
    clickableIcon: { type: Boolean, default: false },
    rows:          { type: String, default: "2" }
  },

  data() {
    return {
      focus: false
    }
  },

  computed: {
    showAutocomplete() {
      return this.autocomplete ? "" : "off"
    }
  }
}

</script>

<script>

import ProductMultipleSelectField from "@/components/product/select-field/product-multiple-select-field"

export default {
  extends: ProductMultipleSelectField,

  methods: {
    onSelect(option) {
      if (!option) return

      let selectedOptions = this.value
      const optionIndex = selectedOptions.findIndex(product => product.value === option.value)

      if (optionIndex < 0) {
        selectedOptions = [...selectedOptions, option]
        this.$emit("input", selectedOptions)
      }
      else this.$emit("toggle-remove", option.data)
    }
  }
}

</script>

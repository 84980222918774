import Base from "./base"

// Libs
import { categoryNameFor } from "@/lib/supported-files"

class Attachment extends Base {
  static get attrs() {
    return _.uniq([
      ...super.attrs,
      "name",
      "description",
      "blobId",
      "recordId",
      "recordType",
      "fileContentType",
      "fileName",
      "fileSize",
      "file"
    ])
  }

  get fileType() {
    if (_.blank(this.fileContentType)) return null

    return categoryNameFor(this.fileContentType)
  }

  // TODO: remover quando migrar completamente para os novos componentes de anexos
  get icon() {
    switch (this.fileType) {
      case "audio": return "fa-volume"
      case "compressed": return "fa-file-archive"
      case "doc": return "fa-file"
      case "email": return "fa-envelope"
      case "image": return "fa-image"
      case "pdf": return "fa-file-pdf"
      case "spreadsheet": return "fa-file-excel"
      case "text": return "fa-file"
      case "video": return "fa-video"
      case "file": default: return "fa-file"
    }
  }

  // TODO: remover quando migrar completamente para os novos componentes de anexos
  get iconColor() {
    switch (this.fileType) {
      case "audio": return "#C291D3"
      case "compressed": return "#7D61CE"
      case "doc": return "#959595"
      case "email": return "#959595"
      case "image": return "#CE9C61"
      case "pdf": return "#AD6767"
      case "spreadsheet": return "#93AD67"
      case "text": return "#959595"
      case "video": return "#677BAD"
      case "file": default: return "#959595"
    }
  }
}

export default Attachment

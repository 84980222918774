import Enum from "@caiena/enum"


// vehicle enums
// ---

// i18n @ enums.vehicles.CATEGORIES.${key}
const CATEGORIES = new Enum({
  operational: "operational",  // Operacional
  super_vip:   "super_vip",    // Super Vip
  vip:         "vip",          // Vip
  premium:     "premium"       // Premium
})


// i18n @ enums.vehicles.STATES.${key}
const STATES = new Enum({
  setup:                  "setup",
  filling_up:             "filling_up",
  washing:                "washing",
  ready_for_rental:       "ready_for_rental",
  rented:                 "rented",
  transferring:           "transferring",
  preventive_maintenance: "preventive_maintenance",
  corrective_maintenance: "corrective_maintenance",
  damaged:                "damaged",
  operational_problems:   "operational_problems",
  withdrawal:             "withdrawal",
  preparation:            "preparation",
  for_sale:               "for_sale",
  dealership_delivered:   "dealership_delivered",
  operational:            "operational",
  reserved:               "reserved",
  reserved_for_customer:  "reserved_for_customer",
  backup:                 "backup",
  asset_loss:             "asset_loss",
  sold:                   "sold"
})


export { CATEGORIES, STATES }

<style scoped lang="scss">

.service-order-repair {
  .header {
    border-bottom: 1px solid $light-gray-4;
    padding-bottom: 32px;
    margin-bottom: 32px;

    .context {
      margin-bottom: 8px;

      .edit-button {
        .icon {
          margin-right: 8px;
        }
      }
    }

    .sap-sync {
      margin-bottom: 8px;
    }

    .title-content {
      margin-bottom: 4px;

      .state-icon {
        margin-right: 8px;
      }

      .title {
        font-size: 30px;
        font-weight: 500;
        font-family: $secondary-font;
      }

      .tags {
        .tag {
          margin-left: 8px;
        }
      }
    }
  }

  .section {
    padding-top: 24px;
    margin-bottom: 24px;
    border-top: 1px solid $light-gray-3;
    .section-header {
      font-family: $secondary-font;
      margin-bottom: 16px;

      .section-title {
        font-size: 24px;
        font-weight: 500;
        margin-bottom: 12px;
      }

      .section-subtitle {
        font-size: 14px;
        color: #666;
      }
    }
  }

  .diamond-icon {
    margin: 0 8px;
    font-size: 4px;
    color: $gray-3;
  }

  .column {
    width: calc(50% - 8px);
    margin-right: 8px;

    + .column {
      margin-right: 0px;
      margin-left: 8px;
    }

    .tag {
      width: 100%;
      margin-top: 24px;
    }
  }

  .info {
    margin-bottom: 24px;

    .info-label + .info-label {
      margin-top: 24px;
    }

    .info-label {
      .pickup-service-tag {
        background: $light-gray-3;
        border-radius: 32px;
        font-family: $secondary-font;
        margin-top: 4px;
        padding: 4px 8px;
        width: 121px;
        height: 30px;

        .icon {
          margin-right: 4px;
          font-size: 14px;
          color: $gray-3;
        }

        .label {
          font-weight: 400;
        }
      }

      ::v-deep {
        .movida-common__info-label-content {
          align-items: flex-start;
        }

        .movida-common__info-label-info-content {
          width: 100%;
        }
      }

      .icon {
        color: $dark-gray;
        font-size: 20px;
        width: 20px;
        text-align: center;
        margin-right: 16px;
      }

      .facilities {
        margin-right: 8px;
      }
    }
  }

  .relations {
    margin-top: 24px;

    .relation {
      border: 1px solid $light-gray-3;
      border-radius: 4px;
      padding: 16px;
      height: 100%;
    }
  }

  .free-of-charge {
    .free-tag {
      margin-right: 8px;
    }
  }

  .note-section {
    .note-icon {
      margin-right: 8px;
    }

    .note {
      padding: 12px;
      font-size: 16px;
      font-weight: 300;
      white-space: pre;
    }
  }
}

</style>


<template lang="pug">

  .service-order-repair
    header.header
      .context.flex.space-between
        breadcrumbs.breadcrumbs(:links="breadcrumbLinks")

        template(v-if="isDraft")
          app-button.edit-button(
            theme="neutral",
            size="small",
            :to="editRoute"
          )
            i.fal.fa-pencil.icon
            span {{ $t('.btn.edit') }}

        template(v-else)
          download-button(:service-order="serviceOrder")

      .title-content.flex.vertical-baseline
        service-order-state-icon.state-icon(:state="serviceOrder.state", size="big")
        h1.title {{ ServiceOrderRepair.$tEnum('kind', this.kind) }}
        .tags.flex.vertical-bottom.grow
          id-tag.tag(
            :value="serviceOrder.code",
            size="big",
            without-icon,
            v-tooltip.bottom="$t('.fields.code.tooltip')"
          )

          vetor-code-tag.tag(
            v-if="serviceOrder.externalId",
            :value="serviceOrder.externalId",
            size="big",
            v-tooltip.bottom="$t('.fields.externalId.tooltip')"
          )

          sap-code-tag.sap.tag(
            :value="serviceOrder.sapId",
            size="big",
            v-tooltip.bottom="$t('.fields.sapId.tooltip')"
          )

      .flex.vertical-center.row
        span {{ $t('.createdAt', { date: $standardTime(serviceOrder.createdAt) }) }}
        i.fas.fa-diamond.diamond-icon
        span {{ $t('date.timezone') }}

    .info.flex
      .column
        info-label.info-label(
          :loading="fetching",
          :label="$t('.fields.estimatedStartAt.label')"
          :empty-text="$t('unregistered.feminine')",
          data-testid="estimatedStartAt"
        )
          template(#icon)
            i.icon.fal.fa-car-side

          template(#default v-if="serviceOrder.estimatedStartAt")
            span {{ $standardTime(serviceOrder.estimatedStartAt) }}
            .flex.vertical-center
              facilities-tag.facilities(value="external")
              span {{ $t('.fields.facilities.external') }}

        info-label.info-label(
          :loading="fetching",
          :label="$t('.fields.pickupService.label')"
          data-testid="pickupService"
        )
          template(#icon)
            i.icon.fal.fa-arrow-square-right

          template(#default)
            .flex.vertical-center
              template(v-if="serviceOrder.pickupService")
                span {{ $t('.fields.pickupService.supplier') }}

              template(v-else)
                span {{ $t('.fields.pickupService.store') }}

            .pickup-service-tag.flex.center.vertical-center
              i.icon.fal.fa-people-arrows(:class="serviceOrder.pickupService ? 'fas' : 'fal'")
              check-item.title(
                :label="$t('.fields.pickupService.text')",
                :checked="!serviceOrder.pickupService"
              )

        info-label.info-label(
          :loading="fetching",
          :label="$t('.fields.supplierNotification.label')"
          data-testid="supplierNotification"
        )
          template(#icon)
            i.icon.fal.fa-envelope

          template(
            #default,
            v-if="supplier"
          )
            span.row {{ supplier.serviceOrderContactName }}

            diagnostic-request-email-history(
              :service-order-id="serviceOrderId",
              :default-email-address="supplier.serviceOrderEmail",
              data-testid="supplierEmailHistory"
            )

        info-label.info-label(
          :loading="fetching",
          :label="$t('.fields.fleetManagerNotification.label')"
          data-testid="fleetManagerNotification"
        )
          template(#icon)
            i.icon.fal.fa-envelope
          template(#default)
            .flex.vertical-center
              span.row {{ store.fleetManagerName }}

            diagnostic-request-fleet-manager-email-history(
              :service-order-id="serviceOrderId",
              :default-email-address="store.fleetManagerEmail",
              data-testid="fleetManagerEmailHistory"
            )

      .column
        supplier-info-banner.tag(
          v-if="supplier"
          :supplier="supplier"
        )

        vehicle-info-tag.tag(
          extended,
          :vehicle="vehicle"
        )

    .section.services-section
      services-index(:service-order-id="serviceOrder.id")
        template(#header="{ props: { servicesCount } }")
          .section-header
            .flex.vertical-center.space-between
              h3.section-title {{ $t('.sections.services.title') }}

            .span.section-subtitle {{ $t('.sections.services.subtitle', { count: servicesCount }) }}

    .section.attachments-section
      service-order-attachments-index(:service-order-id="serviceOrder.id")
        template(#empty)
          .empty-section.flex.column-direction.center.vertical-center
            img.illustration(:src="paperclipIllustration")
            app-span.empty-message(:value="$t('.sections.attachments.empty.message')")

        template(#header)
          .section-header
            .flex.vertical-center.space-between
              h3.section-title {{ $t('.sections.attachments.title') }}

            .span.section-subtitle {{ $t('.sections.attachments.subtitle', { count: attachments.length }) }}

    .section.description-section
      .section-header
        .flex.vertical-center.space-between
          h3.section-title {{ $t('.sections.description.title') }}

        span.description {{ serviceOrder.description }}

</template>


<script>

// Assets
import paperclipIllustration from "@/assets/images/illustrations/paperclip.svg"

// Components
import DownloadButton from "../download-button"
import DiagnosticRequestFleetManagerEmailHistory from "@/views/stores/tickets/service-orders/show/diagnostic-request-fleet-manager-email-history.vue"
import DiagnosticRequestEmailHistory from "@/views/stores/tickets/service-orders/show/diagnostic-request-email-history"
import SupplierEmailHistory from "../supplier-email-history"
import VehicleInfoTag from "@/views/service-orders/show/vehicle-info-tag"
import SupplierInfoBanner from "@/views/service-orders/show/supplier-info-banner.vue"
// import StoreInfoTag from '@/views/service-orders/show/store-info-tag'
import ServiceOrderAttachmentsIndex from "@/views/tickets/drafts/contents/attachments/index.vue"
import ServicesIndex from "@/views/tickets/drafts//contents/services/index.vue"

// Models
import models from "@/models"
const { ServiceOrderRepair } = models

export default {
  name: "RepairServiceOrder",

  components: {
    DownloadButton,
    DiagnosticRequestFleetManagerEmailHistory,
    DiagnosticRequestEmailHistory,
    SupplierEmailHistory,

    VehicleInfoTag,
    SupplierInfoBanner,
    // StoreInfoTag,
    ServiceOrderAttachmentsIndex,
    ServicesIndex
  },

  props: {
    resource: { type: Object, default: () => {} },
    fetching: { type: Boolean, default: false }
  },

  data() {
    return {
      i18nScope: "stores.tickets.service-orders.show.service-order-show.repair",

      id:             this.$route.params.id,
      ticketId:       this.$route.params.ticketId,
      serviceOrderId: this.$route.params.serviceOrderId,
      ServiceOrderRepair,
      paperclipIllustration
    }
  },

  computed: {
    serviceOrder: { get() { return this.resource } },

    supplier() {
      return _.get(this.serviceOrder, "supplier")
    },

    store() {
      return _.get(this.serviceOrder, "store")
    },

    vehicle() {
      return _.get(this.serviceOrder, "vehicle")
    },

    kind() {
      return _.get(this.serviceOrder, "kind")
    },

    attachments() {
      return _.get(this.serviceOrder, "attachments")
    },

    isDraft() {
      return this.serviceOrder.state === "draft"
    },

    needsSAPSync() {
      return _.blank(this.serviceOrder.sapId) && this.serviceOrder.hasCost
    },

    issuedAtTooltipOptions() {
      return {
        content: this.$t(".fields.issuedAt.tooltip"),
        offset:  "4",
        classes: ["preserve-whitespace"]
      }
    },

    hasFreeOfCharge() {
      if (_.present(this.resource.products)) {
        if (this.resource.products.some(product => product.freeOfCharge)) return true
      }

      if (_.present(this.resource.services)) {
        if (this.resource.services.some(service => service.freeOfCharge)) return true
      }

      return false
    },

    breadcrumbLinks() {
      return [
        {
          to:   { name: "storeTickets", params: { id: this.id } },
          text: this.$t(".breadcrumbLinks.tickets")
        },
        {
          to:   { name: "storeTicket", params: { id: this.id, ticketId: this.ticketId } },
          text: this.$t(".breadcrumbLinks.ticket", { id: this.ticketId })
        },
        {
          to: {
            name:   "storeTicketServiceOrder",
            params: {
              id:             this.id,
              ticketId:       this.ticketId,
              serviceOrderId: this.serviceOrderId
            }
          },
          text: this.$t(".breadcrumbLinks.serviceOrder", { id: this.serviceOrderId })
        }
      ]
    },

    editRoute() {
      return { name: "editServiceOrder", params: { id: this.serviceOrderId } }
    },

    emptyText() {
      return this.$t("unregistered.masculine")
    }
  }
}

</script>

<template lang="pug">

  .edit
    ticket-draft-wizard-header

    template(v-if="fetching")
      loading-lines(:lines="6")

    template(v-else)
      router-view(
        :ticket="resource",
        @reload="fetch"
      )

</template>


<script>

// View
import LoadedView from "@/views/loaded-view"

// Mixins
import FetchMixin from "@/mixins/fetch-mixin"

// Models
import models from "@/models"
const { ServiceOrder, TicketRepair } = models

export default {
  name: "EditDraftTicket",

  components: {},

  extends: LoadedView,

  mixins: [FetchMixin],

  data() {
    return {
      i18nScope: "tickets.drafts.edit",
      routeName: "editDraftTicket",

      ticketId: null,

      resource: new TicketRepair({ serviceOrders: [] })
    }
  },

  methods: {
    // @override Loaded view
    parseRoute() {
      this.ticketId = this.$params.asInteger(this.$route.params.id)
    },

    // @override FetchMixin
    fetchRequest() {
      return this.$newSdk.tickets.drafts.find({ ticketId: this.ticketId })
    },

    // @override FetchMixin
    onFetchSuccess({ data }) {
      data.serviceOrders = data.serviceOrders.map(serviceOrder => ServiceOrder.build(serviceOrder))

      this.resource = new TicketRepair(data)
    }
  }
}

</script>

// Base
import BaseRequests from "../Base"

class ServiceOrdersDraftsRequests extends BaseRequests {
  static get selector() {
    return "serviceOrders.drafts"
  }

  update({ serviceOrderId, params = {}, options = {} }) {
    this.verifyData("update", { serviceOrderId })

    return this.action({
      method:  "patch",
      url:     `/service_orders/${serviceOrderId}/draft`,
      params,
      options: {
        model: null,

        ...options
      }
    })
  }
}

export default ServiceOrdersDraftsRequests

<style scoped lang="scss">

#viewport {
  width: 100%;
  height: 100vh;
  position: relative;

  &.outdated {
    height: calc(100vh - #{$outdated-banner-height});
  }
}

::v-deep .app-progress {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 999;
  background-color: $primary;

  & .indeterminate {
    background-color: $light-primary-2;
  }
}

.layout {
  height: 100%;
}

</style>


<template lang="pug">

  #app
    loading.app-progress
    notifications
    app-outdated-banner(:show="outdated", @update="refresh")

    #viewport(:class="{ outdated }")
      component.layout(
        :is="layoutTemplate",
        :authenticated="authenticated",
        :transition="transition",
        @refresh="refresh",
      )

    app-modal(
      v-if="showUpdateModal",
      :heading="$t('updateVersion.title')",
      :width="540",
      :confirm-label="$t('btn.update.label')",
      footer,
      @close="cancelUpdate",
      @cancel="cancelUpdate",
      @confirm="refresh",
    )
      .body
        span.text {{ $t("updateVersion.text") }}

    template(v-for="portal in portals")
      portal-target(
        :name="portal",
        :key="`portal-${portal}`",
        :transition="fadeTransition",
        :data-testid="`portal-target-${portal}`"
      )

    portal(:to="currentPortal")
      span.empty

</template>


<script>

// Libs
import { events } from "utils.vue"

// Layouts
import DefaultLayout from "@/views/_layouts/default"
import BlankLayout from "@/views/_layouts/blank"

// Components
import NotFound from "@/components/not-found"

const DEFAULT_ROUTE_TRANSITION_NAME = "fade"
const DEFAULT_ROUTE_TRANSITION_MODE = "out-in"

export default {
  name: "App",

  components: { BlankLayout, DefaultLayout, NotFound },

  data() {
    return {
      authenticated: this.$auth.isAuthenticated(),

      showUpdateModal: false,
      outdated:        false,

      transition: {
        name: DEFAULT_ROUTE_TRANSITION_NAME,
        mode: DEFAULT_ROUTE_TRANSITION_MODE
      },

      portals: ["portal-0"],

      portalsCount: 0
    }
  },

  computed: {
    layoutTemplate() {
      const layout = _.get(this.$route, "meta.layout") || "default"

      return `${layout}-layout`
    },

    fadeTransition() {
      return {
        functional: true,
        render(h, context) {
          return h("transition", { props: { name: "fade", mode: "out-in" } }, context.children)
        }
      }
    },

    currentPortal() {
      return `portal-${this.portalsCount}`
    }
  },

  watch: {
    $route(to, from) {
      // transition between root routes
      this.transition = {
        name: _.get(to, "meta.transition.name") || DEFAULT_ROUTE_TRANSITION_NAME,
        mode: _.get(to, "meta.transition.mode") || DEFAULT_ROUTE_TRANSITION_MODE
      }
    }
  },

  created() {
    events.$on("app:outdated", () => {
      this.showUpdateModal = true
    })

    // Mantém sempre um portal disponível
    events.$on("create-portal", async callback => {
      callback(this.currentPortal)

      await this.$nextTick()

      const nextPortal = `portal-${++this.portalsCount}`
      this.portals.push(nextPortal)
    })

    events.$on("destroy-portal", portal => {
      this.portals = this.portals.filter(value => value !== portal)
    })
  },

  beforeDestroy() {
    events.$off([
      "app:outdated",
      "auth:login",
      "auth:logout",
      "auth:expired"
    ])
  },

  mounted() {
    events.$on(["auth:logout", "auth:expired"], () => {
      const { fullPath } = this.$route
      if (fullPath.includes("/login") || fullPath.includes("redirect=")) return

      this.$router.push({
        name:  "login",
        query: { redirect: fullPath }
      })
    })

    events.$on(["auth:login", "auth:logout", "auth:expired"], () => {
      this.authenticated = this.$auth.isAuthenticated()
    })
  },

  methods: {
    refresh() {
      window.location.reload(true)
    },

    cancelUpdate() {
      this.showUpdateModal = false
      this.outdated = true
    }
  }
}

</script>

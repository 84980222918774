<style lang="scss">

trix-toolbar {
  background-color: $light-gray;

  .trix-button {
    padding: 0 4px;
    border-bottom: 0;

    &:not(:first-child) {
      border-left: 0;
    }
  }

  .trix-dialog {
    top: unset;
    bottom: 26.5px;
    border-top: 0;
  }

  .trix-button-row {
    padding: 0 0 1px 1px;
  }

  .trix-button--icon {
    width: 40px;
  }

  .trix-button-group {
    margin: 0;
    border: none;

    &:not(:first-child) {
      margin-left: 0;
    }
  }

  [data-trix-action="decreaseNestingLevel"],
  [data-trix-action="increaseNestingLevel"],
  [data-trix-button-group="history-tools"],
  [data-trix-button-group="file-tools"] {
    display: none;
  }

  &.history {
    [data-trix-button-group="history-tools"] {
      display: flex;
    }
  }

  &.focus {
    .trix-button-row {
      padding: 0;
    }
  }

  &.disabled {
    pointer-events: none;
  }
}

trix-editor {
  border: 0;
  padding: 8px 13px;
  border-radius: 0;

  &:focus {
    padding: 7px 12px;
  }

  &.disabled {
    background-color: $light-gray-3;
    cursor: not-allowed;
  }
}

</style>


<style lang="scss" scoped>

.rich-textarea-field {
  .label-content {
    font-size: 14px;
    font-weight: 500;
    font-family: $secondary-font;
    margin-bottom: 8px;

    > * + * {
      padding-left: 4px;
    }

    &.focus {
      color: $orange;
    }

    &.error {
      color: $red;
    }

    .mandatory-icon {
      font-size: 4px;
      color: $orange;
    }
  }

  .input {
    border: 1px solid $gray;
    border-radius: 4px;
    overflow: hidden;
    height: 100%;

    &:hover {
      border-color: $orange;
    }

    &:focus-within {
      border-color: $orange;
      border-width: 2px;
    }

    &.error {
      border-color: $error;
    }

    .trix-content {
      height: 100%;
    }
  }

  .error-message {
    display: block;
    color: $red;
    font-size: 12px;
    line-height: 1;
    padding-top: 4px;
  }
}

</style>


<template lang="pug">

  .rich-textarea-field
    label.label-content.flex.vertical-center(
      v-if="!hideLabel",
      :class="{ error: hasErrors, focus }",
      :for="editorId"
    )
      app-span(crop, :value="label")
      template(v-if="optional")
        span {{ $t("form.optional") }}

      template(v-else-if="mandatory")
        i.mandatory-icon.fas.fa-circle(v-tooltip.top="$t('form.mandatory.tooltip')")

    .input.flex.column-direction(:class="{ error: hasErrors }")
      trix-editor.trix-content(
        ref="editor",
        :class="{ disabled: editorDisabled }",
        :id="editorId",
        :input="inputId",
        :placeholder="placeholder",
        :toolbar="toolbarId",
        @trix-blur="onBlur",
        @trix-change="handleContentChange",
        @trix-file-accept.prevent="",
        @trix-focus="onFocus"
      )

      trix-toolbar(
        :class="{ history, focus, disabled: editorDisabled }",
        :id="toolbarId"
      )

      input(
        name="content",
        type="hidden",
        :id="inputId",
        :value="value"
      )

    template(v-if="hasMessageErrors")
      span.error-message {{ errors[0] }}

</template>


<script>

// 3rd Party
import { v4 as uuid } from "uuid"

// Mixins
import FieldMixin from "@/mixins/field"

export default {
  name: "RichTextareaField",

  mixins: [FieldMixin],

  props: {
    history: { type: Boolean, default: false }
  },

  data() {
    return {
      i18nScope: "components.form.rich-textarea-field",

      editorId:  uuid(),
      toolbarId: uuid()
    }
  },

  computed: {
    editorDisabled() {
      return this.disabled || this.loading
    }
  },

  watch: {
    editorDisabled: {
      immediate: true,
      async handler() {
        await this.$nextTick()

        if (!this.$refs?.editor) return

        this.$refs.editor.contentEditable = !this.editorDisabled
      }
    }
  },

  methods: {
    handleContentChange(event) {
      this.editorContent = event.srcElement ? event.srcElement.value : event.target.value
      this.$emit("input", this.editorContent)
    }
  }
}

</script>

<style lang="scss" scoped>
.form {
  align-items: center;
  background: white;
  border: 1px solid $gray;
  border-radius: 4px;
  display: flex;
  gap: 8px;
  padding: 4px 12px;

  &:hover,
  &:focus-within {
    border-color: $primary;
  }

  &:focus-within {
    outline: 2px solid $primary;
    outline-offset: -2px;
  }
}

.input {
  background: transparent;
  border: none;
  color: $gray-3;
  flex-grow: 1;
  font-family: $primary-font;
  font-size: 16px;
  font-weight: 400;
  height: 22px;
  width: 3ch;
  -moz-appearance: textfield;

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &::placeholder {
    color: $gray-2;
    font-weight: 300;
  }
}

.btn {
  background: transparent;
  border: none;
  color: $dark-gray;
  cursor: pointer;
  height: 20px;
  width: 20px;

  &:hover {
    color: $primary;
  }

  &:active {
    color: $dark-primary-2;
  }

  &:disabled {
    color: $gray;
    cursor: not-allowed;
  }
}
</style>

<template lang="pug">
  .page-input
    template(v-if="loading")
      loading-lines(:height="32")
    template(v-else)
      form.form(@click.self="$refs.input.focus()", @submit.prevent="onSubmit")
        input.input(
          v-bind="$attrs"
          v-model="page"
          :max="max"
          min="1"
          ref="input"
          type="number"
          :placeholder="value"
        )
        button.btn.center.vertical-center(tabindex="-1", type="submit")
          i.icon.far.fa-chevron-right
</template>

<script>
export default {
  props: {
    loading:    { type: Boolean, default: false },
    pagination: { type: Object, default: () => ({ perPage: 0, total: 0 }) },
    value:      { type: Number, default: 1 }
  },

  emits: ["input"],

  data() {
    return {
      page: ""
    }
  },

  computed: {
    max() {
      const { total, perPage } = this.pagination

      if (!(total && perPage)) return 1

      return Math.ceil(total / perPage)
    }
  },

  watch: {
    value() {
      this.page = ""
    }
  },

  methods: {
    onSubmit() {
      if (_.blank(this.page)) return

      this.$emit("input", Number(this.page))
    }
  }
}
</script>

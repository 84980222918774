<!--
  <doc lang="markdown">
    Tabela para listas de serviços para grupo de veículos que um fornecedor presta
    Devemos sobreescrever o slot tableRows, passando as linhas da tabela

    ## Props:
      initializing: [Boolean]: Indica que o componente está iniciando
      loading:      [Boolean]: Indica o carregamento da lista
      empty:        [Boolean]: Indica se a lista está vazia, para controlar a exibir o o slot de lista vazia
      sortedBy:     [String]:  Parâmetro e direção de ordenação, ex: "id:asc",

      columnSizes: [String], Valores dos tamanhos da colunas utilizadas em `grid-template-columns`

    ## Slots:
      tableHeader: Slot do cabeçalho da lista
      tableEmpty:  Slot da lista vazia
      tableRows:   Slot das linhas da lista

    Como utilizar:
    ```pug
      table($attrs)
        template(#tableRows)
    ```
  </doc>
-->


<template lang="pug">

  index-table(
    :empty="empty",
    :sortedBy="sortedBy",
    :initializing="initializing",
    :loading="loading",
    :cols-count="6",
    :column-sizes="columnSizes"
  )

    template(#tableHeader)
      table-header(
        :loading="loading",
        @sort="param => $emit('sort', param)"
        :sort-param="sortParam",
        :sort-order="sortOrder",
        :column-sizes="columnSizes",
        data-testid="serviceVehicleModelGroupsTableHeader"
      )

    template(#tableEmpty)
      .empty.flex.center
        .flex.column-direction.vertical-center
          img.icon(:src="emptyIcon")
          span.message {{ $t('.empty.message') }}

    template(#tableRows)
      slot(name="rows")


</template>


<script>

// Assets
import emptyIcon from "@/assets/images/icons/tools.svg"

// Components
import TableHeader from "./table-header.vue"

export default {
  name: "ServiceVehicleModelGroupTable",

  components: {
    TableHeader
  },

  props: {
    initializing: { type: Boolean, default: false },
    loading:      { type: Boolean, default: false },
    empty:        { type: Boolean, default: false },
    sortedBy:     { type: String, default: "" },

    columnSizes: { type: String, required: true }
  },

  data() {
    return {
      i18nScope: "suppliers.service-supports.components.service-vehicle-model-groups.table",

      emptyIcon
    }
  },

  computed: {
    sortParam() {
      return this.sortedBy.split(":")[0]
    },

    sortOrder() {
      return this.sortedBy.split(":")[1] || "asc"
    }
  }
}

</script>

<style scoped lang="scss">

.symptom-empty {
  text-align: center;
  font-size: 14px;
  padding: 20px 0;
}

.symptoms-row {
  font-family: $secondary-font;
  border: 1px solid $light-gray-3;
  border-bottom: none;
  padding: 8px 24px;
  font-size: 16px;
  font-weight: normal;
  min-height: 64px;

  &:first-child {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }

  &:last-child {
    border-bottom: 1px solid $light-gray-3;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
  }
}

.name {
  display: block;
  font-size: 18px;
}

.description {
  font-family: $primary-font;
  display: block;
  font-size: 12px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 300;
}

.icon {
  margin-right: 24px;
  font-size: 20px;
}

.results {
  font-size: 14px;
  font-weight: 300;
  color: $gray-3;
  margin: 24px 0px 4px 0px;
}


</style>


<template lang="pug">

  .symptoms-list
    loading-lines(v-if="loading", :lines="4")
    template(v-else-if="!hasSymptoms")
      .symptom-empty.global-empty-text.global-empty-color.text.center
        label {{ emptyText }}
    template(v-else)
      .results.flex
          span {{ $t('.count', { count: symptomsCount } )}}
      .symptoms-row.flex.space-between.vertical-center(
        v-for="symptom in symptoms",
        :key="`symptom-${symptom.id}`",
        data-testid="symptomRow"
      )
        .content.flex.vertical-center
          .icon-content.flex.center.vertical-center
            i.icon.fal.fa-engine-warning
          .content-column
            label.name {{ symptom.name }}
            label.description {{ symptom.description }}


        .actions-column(v-if="destroyable")
          app-button(theme="neutral", size="small", @click="$emit('destroy', symptom)")
            i.fal.fa-trash-alt

</template>


<script>

export default {
  props: {
    loading:     { type: Boolean, default: false },
    destroyable: { type: Boolean, default: false },
    symptoms:    { type: Array, default: () => [] },
    emptyText:   { type: String, default: null }
  },

  data() {
    return {
      i18nScope: "vehicle-parts.components.symptoms-list"
    }
  },

  computed: {
    hasSymptoms() {
      return _.present(this.symptoms)
    },

    symptomsCount() {
      return this.symptoms.length
    }
  }
}

</script>

.<style lang="scss" scoped>

.supplier-maintenance-vehicle-model-groups-table-header {
  display: grid;
  grid-template-columns: 1fr 1fr 64px;
  background-color: $light-gray;
  height: 40px;
  border-bottom: 1px solid $light-gray-3;
  border-top: 1px solid $light-gray-3;

  &.loading {
    border-bottom: 2px solid $light-orange-2;
  }

  .unsortable {
    padding: 0 12px;
  }

  .column {
    font-size: 12px;

    .icon {
      font-size: 16px;
      color: $gray-2;
      margin-right: 8px;
    }

    .diamond-icon {
      font-size: 8px;
      font-weight: 900;
      color: $gray-3;
      width: 8px;
      height: 8px;
    }

    .text {
      font-family: $secondary-font;
      font-size: 14px;
      color: $dark-gray;
      font-weight: 400;
    }

    .loader-icon {
      font-size: 16px;
      color: $orange;
    }
  }
}

</style>


<template lang="pug">

  .supplier-maintenance-vehicle-model-groups-table-header(:class="{ loading }")
    .column.flex.unsortable.vertical-center(data-testid="vehicleModelGroupColumn")
      i.icon.fas.fa-cars
      span.text {{ $t('.columns.vehicleModelGroup.label') }}

    //- .column(data-testid="vehicleModelGroupColumn")
    //-   sortable-table-header-column(
    //-     sort-param="vehicle_model_group",
    //-     :sort-order="sortOrder",
    //-     :active="isSortedBy('vehicle_model_group')",
    //-     @sort="param => $emit('sort', param)"
    //-   )
    //-     i.icon.fas.fa-cars
    //-     span.text {{ $t('.columns.vehicleModelGroup.label') }}

    .column.flex.unsortable.vertical-center(data-testid="maintenanceTableColumn")
      i.icon.fas.fa-table
      span.text {{ $t('.columns.maintenanceTable.label') }}

    //- .column(data-testid="maintenanceTableColumn")
    //-   sortable-table-header-column(
    //-     sort-param="maintenance_table",
    //-     :sort-order="sortOrder",
    //-     :active="isSortedBy('maintenance_table')",
    //-     @sort="param => $emit('sort', param)"
    //-   )
    //-     i.icon.fas.fa-table
    //-     span.text {{ $t('.columns.maintenanceTable.label') }}

    .column.flex.unsortable.vertical-center.center
      template(v-if="loading")
        i.loader-icon.fas.fa-spin.fa-spinner
      template(v-else)
        i.diamond-icon.fas.fa-diamond

</template>


<script>

export default {
  props: {
    loading:   { type: Boolean, default: false },
    sortParam: { type: String, required: true },
    sortOrder: { type: String, required: true }
  },

  data() {
    return {
      i18nScope: "suppliers.service-supports.maintenance.vehicle-model-groups.index.supplier-maintenance-vehicle-model-groups-table-header"
    }
  },

  methods: {
    isSortedBy(param) {
      return this.sortParam === param
    }
  }
}

</script>

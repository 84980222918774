<style scoped lang="scss">

.new-vehicle-model {
  padding-bottom: 24px;
}

.btn {
  margin-bottom: 12px;
}

.submit-actions {
  .button + .button {
    margin-left: 16px;
  }
}

.header {
  padding-bottom: 16px;
  border-bottom: 1px solid $light-gray-3;
}

.title {
  font-size: 30px;
  font-weight: 500;
  font-family: $secondary-font;
}

.fields-content {
  margin-bottom: 24px;
}

</style>


<template lang="pug">

  .new-vehicle-model
    header.header
      h1.title {{ $t('.title') }}

    form(@submit.prevent="submit", method="post")
      form-fields.fields-content(
        :fetching="fetching",
        :submitting="submitting",
        :errors="errors",
        :resource="resource",
        @select-type="onSelectTypeOption",
        data-test="formFields"
      )

      section.actions.flex.space-between.vertical-center
        app-button(:to="{ name: 'vehicleModels' }", theme="gray", outline, :disabled="submitting")
          span {{ $t('btn.cancel') }}

        .submit-actions
          app-button.button(
            type="submit",
            outline,
            :disabled="!hasType",
            :loading="submitting",
            @click="clearAfterSubmit = true",
          )
            span {{ $t('.btn.submit-and-new.label') }}
            span(slot="loading") {{ $t('.btn.submit-and-new.loading') }}

          app-button.button(
            type="submit",
            :loading="submitting",
            :disabled="!hasType"
          )
            span {{ $t('.btn.create.label') }}
            span(slot="loading") {{ $t('.btn.create.loading') }}

</template>


<script>

// Mixins
import { mixins } from "movida-common.vue"

// Libs
import omitDeep from "@/lib/omit-deep"

// Extends
import View from "@/views/view"

// Components
import FormFields from "./forms/form-fields"

// Models
import models from "@/models"

const { FormMixin, Fetchable } = mixins
const { VehicleModel } = models

// function specEmptyAttrs() {
//   return {
//     sizes:        [{}],
//     loadIndices:  [{}],
//     speedRatings: [{}]
//   }
// }

export default {
  name: "NewVehicleModel",

  components: {
    FormFields
  },

  extends: View,

  mixins: [Fetchable, FormMixin],

  props: {
    mobiautoVersion: { type: Object, default: null }
  },

  data() {
    return {
      i18nScope: "vehicle-models.new",

      resource: this.buildResource(null, { mobiautoVersion: this.mobiautoVersion }),

      autofetch:  false,
      copyFromId: this.$route.query.dup,

      name: null,

      nestedAttributes: {
        tireSpecification: {
          sizes:        null,
          loadIndices:  null,
          speedRatings: null
        },

        spareTireSpecification: {
          sizes:        null,
          loadIndices:  null,
          speedRatings: null
        }
      },

      clearAfterSubmit: false
    }
  },

  computed: {
    hasType() {
      return _.present(this.resource.type)
    }
  },

  watch: {
    // - XXX: tornando pneu specs opcional
    // ref: https://github.com/caiena/movida-gmf/issues/1191
    // resource: {
    //   deep: true,
    //   handler(newResource, oldResource) {
    //     if (_.blank(newResource) || _.blank(oldResource)) return

    //     let kind = newResource.$get('tireSpecification.kind')

    //     if (kind !== 'run_flat') {
    //       this.resource.spareTireSpecification.$unmarkForDestruction()
    //     } else {
    //       this.resource.spareTireSpecification.$markForDestruction()
    //     }
    //   }
    // }
  },

  created() {
    if (_.present(this.copyFromId)) {
      this._fetch()
      return
    }

    if (!this.mobiautoVersion) return

    this.mobiautoVersionOption = {
      value: this.mobiautoVersion.id,
      label: this.mobiautoVersion.displayName,
      data:  this.mobiautoVersion
    }
  },

  methods: {
    buildResource(type, attrs = {}) {
      return VehicleModel.build({
        // - XXX: tornando pneu specs opcional
        // ref: https://github.com/caiena/movida-gmf/issues/1191
        // tireSpecification:      new TireSpecification(specEmptyAttrs()),
        // spareTireSpecification: new SpareTireSpecification(specEmptyAttrs()),
        ...attrs,

        type
      })
    },

    onSelectTypeOption(option) {
      if (_.blank(option)) {
        delete this.resource.type
        this.resource = this.buildResource(null, this.resource)
        this.errors = {}
        return
      }

      this.resource = this.buildResource(option, this.resource)
    },

    submitRequest() {
      const params = this.serialize(this.resource, this.nestedAttributes)
      return this.$sdk.vehicleModels.create({ versionId: this.resource.mobiautoVersionId, params })
    },

    submitSuccess({ data }) {
      this.$notifications.info(this.$t(".notifications.submit.success", { name: this.resource.displayName }))

      if (this.clearAfterSubmit) {
        this.resource =  this.buildResource()
        this.clearAfterSubmit = false
      }
      else {
        this.$router.push({ name: "vehicleModel", params: { id: data.id } })
      }
    },

    submitError(err) {
      this.$err.log(err)
      this.$notifications.error(this.$t(".notifications.submit.error"))
    },

    // @override Fetchable mixin
    fetch() {
      return this.$sdk.vehicleModels.get({ id: this.copyFromId })
    },

    // @override Fetchable mixin
    onFetchSuccess() {
      const data = omitDeep(this.resource, ["id"], { except: ["mobiautoVersion"] })

      this.resource = VehicleModel.build(data)

      // - XXX: tornando pneu specs opcional
      // ref: https://github.com/caiena/movida-gmf/issues/1191
      // // XXX: corrigindo dados vazios persistidos no banco da API
      // if (_.blank(this.resource.tireSpecification)) {
      //   this.resource.tireSpecification = new TireSpecification(specEmptyAttrs())
      // }

      // // XXX: aqui, como é opcional, deixamos criados mas marcado para ser destruído, para que seus fields, se forem renderizados, apareçam:
      // // - há uma reação na troca do tipo de pneu para renderizar os
      // //   fields de "estepe" - no `watch => resource` desse componente!
      // if (_.blank(this.resource.spareTireSpecification)) {
      //   this.resource.spareTireSpecification = new SpareTireSpecification(specEmptyAttrs())
      //   this.resource.spareTireSpecification.$markForDestruction()
      // }

      this.mobiautoVersionOption = {
        value: this.resource.mobiautoVersion.id,
        label: this.resource.mobiautoVersion.displayName,
        data:  this.resource.mobiautoVersion
      }
    },

    // @override Fetchable mixin
    onFetchError(err) {
      // Dealing with appError from extension view.vue
      if (this.hasViewError(err)) this.appError = err

      this.$err.log(err)
      this.$notifications.error(this.$t(".notifications.fetch.failure"))
    }
  }
}

</script>

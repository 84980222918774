// Base
import BaseRequests from "./Base"

// Models
import models from "@/models"
const { VehicleModelGroup } = models

class VehicleModelGroupsRequests extends BaseRequests {
  static get selector() {
    return "vehicleModelGroups"
  }

  list({ params = {}, options = {} } = {}) {
    return this.action({
      // Estamos usando POST pois o filtro por ids pode ser muito grande e estourar o limite da URL.
      method:  "post",
      url:     `/vehicle_model_groups/search`,
      params,
      options: {
        model: VehicleModelGroup,

        ...options
      }
    })
  }

  listVehicleModels({ vehicleModelGroupId, params = {}, options = {} } = {}) {
    if (_.blank(vehicleModelGroupId)) {
      throw new Error('SDK VehicleModelGroups - #listVehicleModels() - "vehicleModelGroupId" is a required prop')
    }

    return this.action({
      method:  "get",
      url:     `/vehicle_model_groups/${vehicleModelGroupId}/vehicle_models`,
      params,
      options: {
        model: null,

        ...options
      }
    })
  }

  enable({ vehicleModelGroupId, params = {}, options = {} } = {}) {
    if (_.blank(vehicleModelGroupId)) {
      throw new Error('NewSDK vehicleModelGroups - #enable() - "vehicleModelGroupId" is a required prop')
    }

    return this.action({
      method: "delete",
      url:    `/vehicle_model_groups/${vehicleModelGroupId}/discard`,
      params,
      options
    })
  }

  disable({ vehicleModelGroupId, params = {}, options = {} } = {}) {
    if (_.blank(vehicleModelGroupId)) {
      throw new Error('NewSDK vehicleModelGroups - #disable() - "vehicleModelGroupId" is a required prop')
    }

    return this.action({
      method: "post",
      url:    `/vehicle_model_groups/${vehicleModelGroupId}/discard`,
      params,
      options
    })
  }
}

export default VehicleModelGroupsRequests

import Base from "./base"

class ServiceOrderDraftService extends Base {
  static get attrs() {
    return _.uniq([
      ...super.attrs,
      "serviceId",
      "quantity",
      "description",
      "attachmentsCount",
      "serviceOrderId",
      "vehicleIssueSolutionId"
    ])
  }

  static get relations() {
    return {
      service:              { type: "hasOne", model: "Service" },
      vehicleIssueSolution: { type: "hasOne", model: "VehicleIssueSolution" },
      targets:              { type: "hasMany", model: "VehiclePartTarget" },
      attachments:          { type: "hasMany", model: "Attachment" }
    }
  }

  static get constraints() {
    return {
      vehicleIssueSolutionId: { presence: true },
      quantity:               { presence: true, numericality: { greaterThan: 0 } }
    }
  }
}

export default ServiceOrderDraftService

.<style lang="scss" scoped>

.deal-products-table-header {
  display: grid;
  grid-template-columns: 40px 1fr 1fr 140px 112px 64px;
  background-color: $light-gray;
  height: 40px;
  border-bottom: 1px solid $light-gray-3;
  border-top: 1px solid $light-gray-3;

  &.loading {
    border-bottom: 2px solid $light-orange-2;
  }

  .unsortable {
    padding: 0 12px;
  }

  .column {
    font-size: 12px;

    .icon {
      color: $gray-2;
      font-size: 14px;

      & + .text {
        margin-left: 8px;
      }
    }

    .text {
      font-family: $secondary-font;
      font-size: 14px;
      color: $dark-gray;
      font-weight: 400;
    }

    .loader-icon {
      font-size: 16px;
      color: $orange;
    }
  }
}

</style>


<template lang="pug">

  .deal-products-table-header(:class="{ loading }")
    .column.unsortable.flex.vertical-center.center(data-testid="iconColumn")
      i.icon.fas.fa-cube

    .column(data-testid="productNameColumn")
      sortable-table-header-column(
        sort-param="product_name",
        :sort-order="sortOrder",
        :active="isSortedBy('product_name')",
        @sort="param => $emit('sort', param)"
      )
        span.text {{ $t('.columns.product.label') }}

    .column(data-testid="vehicleModelGroupName")
      sortable-table-header-column(
        sort-param="vehicle_model_group_name",
        :sort-order="sortOrder",
        :active="isSortedBy('vehicle_model_group_name')",
        @sort="param => $emit('sort', param)"
      )
        i.icon.fas.fa-cars
        span.text {{ $t('.columns.vehicle-model-group.label') }}

    .column(data-testid="partOrigin")
      sortable-table-header-column(
        sort-param="part_origin",
        :sort-order="sortOrder",
        :active="isSortedBy('part_origin')",
        @sort="param => $emit('sort', param)"
      )
        span.text {{ $t('.columns.part-origin.label') }}

    .column(data-testid="price")
      sortable-table-header-column(
        sort-param="price",
        :sort-order="sortOrder",
        :active="isSortedBy('price')",
        @sort="param => $emit('sort', param)"
      )
        span.text {{ $t('.columns.price.label') }}

    .column.unsortable.flex.vertical-center.center
      template(v-if="loading")
        i.loader-icon.fas.fa-spin.fa-spinner

</template>


<script>

export default {
  props: {
    loading:   { type: Boolean, default: false },
    sortParam: { type: String, required: true },
    sortOrder: { type: String, required: true }
  },

  data() {
    return {
      i18nScope: "suppliers.deals.products.index.deal-products-table-header"
    }
  },

  methods: {
    isSortedBy(param) {
      return this.sortParam === param
    }
  }
}

</script>

import { render, staticRenderFns } from "./product-row-skeleton.vue?vue&type=template&id=68da9996&scoped=true&lang=pug&"
var script = {}
import style0 from "./product-row-skeleton.vue?vue&type=style&index=0&id=68da9996&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "68da9996",
  null
  
)

export default component.exports
// Base
import BaseRequests from "../Base"

// Models
import models from "@/models"
const { Occurrence } = models

class OccurrenceRequests extends BaseRequests {
  static get selector() {
    return "vehicles.occurrences"
  }

  all({ vehicleId, params = {}, options = {} } = {}) {
    this.verifyData("all", { vehicleId })

    return this.action({
      method:  "get",
      url:     `/vetor/vehicles/${vehicleId}/occurrences`,
      params,
      options: {
        model: Occurrence,

        ...options
      }
    })
  }
}

export default OccurrenceRequests

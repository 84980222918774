// Base
import BaseRequests from "../Base"

// Models
import models from "@/models"
const { StoreSettings } = models

class StoreSettingsRequests extends BaseRequests {
  static get selector() {
    return "stores.settings"
  }

  find({ storeId, params = {}, options = {} } = {}) {
    this.verifyData("find", { storeId })

    return this.action({
      method:  "get",
      url:     `/stores/${storeId}/settings`,
      params,
      options: {
        model: StoreSettings,

        ...options
      }
    })
  }

  update({ storeId, params = {}, options = {} } = {}) {
    this.verifyData("update", { storeId })

    return this.action({
      method:  "patch",
      url:     `/stores/${storeId}/settings`,
      params,
      options: {
        model: StoreSettings,

        ...options
      }
    })
  }
}

export default StoreSettingsRequests

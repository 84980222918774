<!--
<doc lang="markdown">
Exibe a data de atendimento de um fornecedor a partir de um timestamp padrão iso8601

  props:
   timestamp: [String]: Timestamp em formato iso8601, ex: '2022-01-05T13:23:00Z'
  slots:
    header:  Slot no header com icone e descrição
    default: Slot default no corpo do componente

Como usar:

```pug

  supplier-available-date(:timestamp="moment.utc().toISOString()")

```
</doc>
-->

<style lang="scss" scoped>

.supplier-available-date {
  font-family: $secondary-font;
  font-weight: 400;

  .header {
    .header-icon {
      color: $purple;
      margin-right: 8px;
    }

    .header-label {
      font-size: 14px;
      line-height: 17px;
      color: $gray-3;
    }
  }

  .weekday-and-time {
    .weekday,
    .datetime {
      display: block;
      margin-top: 4px;
      font-size: 16px;
      line-height: 19px;
      color: $dark-gray;
    }
  }
}

</style>


<template lang="pug">

  .supplier-available-date
    slot(name="header")
      .header
        i.icon.fas.fa-calendar-alt.header-icon
        span.header-label {{ $t('.date') }}
    slot
      .weekday-and-time
        span.weekday {{ weekdayTitle }}
        span.datetime {{ dateLabel }}

</template>


<script>

export default {
  props: {
    timestamp: { type: String, required: true }
  },

  data() {
    return {
      i18nScope: "tickets.drafts.bookings.edit.supplier-available-date",

      parsedTimeStamp: moment(this.timestamp)
    }
  },

  computed: {
    diffInDays() {
      const today = moment.utc().startOf("day")
      const availableAt = this.parsedTimeStamp.startOf("day")

      return availableAt.diff(today, "days")
    },

    weekdayTitle() {
      return `${this.$t(".diffInDays", { count: this.diffInDays })} ${this.weekday}`
    },

    weekday() {
      return this.$l("date.formats.weekDay", this.timestamp)
    },

    dateLabel() {
      return this.$standardTime(this.timestamp, "short")
    }
  }
}

</script>


import Base from "./base"

class StoreSupplierMaintenanceVehicleModelGroup extends Base {
  static get attrs() {
    return _.uniq([
      ...super.attrs,
      "storeSupplierId",
      "supplierMaintenanceVehicleModelGroupId",
      "vehicleModelGroupId",
      "maintenanceTableId"
    ])
  }

  static get relations() {
    return {
      maintenanceTable:  { type: "hasOne", model: "VehicleModelGroupMaintenanceTable" },
      vehicleModelGroup: { type: "hasOne", model: "VehicleModelGroup" }
    }
  }
}

export default StoreSupplierMaintenanceVehicleModelGroup

import Vue from "vue"
import { events } from "utils.vue"
import { version } from "../package"

import "./boot"
import "./registerServiceWorker"

import App from "./app.vue"
import router from "@/modules/router"

window.VERSION = version

Vue.config.productionTip = false

window.vm = new Vue({
  router,
  render: h => h(App)
}).$mount("#app")

events.$emit("app:init", { router })

window.$events = events

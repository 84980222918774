import Base from "./base"

class ReviewChangesRequest extends Base {
  static get attrs() {
    return _.uniq([
      ...super.attrs,

      "motive",
      "description"
    ])
  }

  static get enums() {
    return {
      motive: {
        insufficient_evidence: "insufficient_evidence",
        incompatible_item:     "incompatible_item",
        technical_report:      "technical_report",
        report:                "report"
      }
    }
  }

  static get constraints() {
    return {
      motive: { presence: true }
    }
  }
}

export default ReviewChangesRequest

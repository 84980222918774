<style lang="scss" scoped>
</style>


<template lang="pug">

  select-field(
    v-bind="$props",
    v-on="$listeners",
    :options="options"
  )

</template>


<script>
import Vue from "vue"

import SelectField from "@/components/form/select-field"

import models from "@/models"
const { Tire } = models


export default {
  name: "TireSizeSelectField",

  extends: SelectField,

  props: {
    // Mudando comportamento para default ser true
    searchable: { type: Boolean, default: true }
  },

  data() {
    return {
      i18nScope: "components.tire.tire-size-select-field"
    }
  },

  computed: {
    options() {
      return Tire.$enums.tireSize.values.map(value => ({ value, label: value }))
    }
  },

  // Removendo props 'options' que está sendo reescrita como _computed_
  beforeCreate() {
    Vue.delete(this.$options.props, "options")
  }
}

</script>

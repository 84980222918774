.<style lang="scss" scoped>

.products-table-header {
  display: grid;
  grid-template-columns: 70px 70px 1fr 1fr 64px;
  background-color: $light-gray;
  height: 40px;
  border-bottom: 1px solid $light-gray-3;
  border-top: 1px solid $light-gray-3;

  &.loading {
    border-bottom: 2px solid $light-orange-2;
  }

  .id-column {
    margin-left: 8px;
  }

  .unsortable {
    padding: 0 12px;
  }

  .column {
    font-size: 12px;

    ::v-deep .sap-icon {
      .outside {
        fill: $gray-2;
      }
    }

    .sap-icon {
      margin-right: 4px;
    }

    .icon {
      font-size: 16px;
      color: $gray-2;
      margin-left: 4px;
    }

    .text {
      font-family: $secondary-font;
      font-size: 14px;
      color: $dark-gray;
      font-weight: 400;
    }

    .loader-icon {
      font-size: 16px;
      color: $orange;
    }
  }
}

</style>


<template lang="pug">

  .products-table-header(:class="{ loading }")
    .column(data-testid="idColumn")
      sortable-table-header-column(
        sort-param="id",
        :sort-order="sortOrder",
        :active="isSortedBy('id')",
        @sort="param => $emit('sort', param)"
      )
        span.text #

    .column.flex.unsortable.vertical-center(data-testid="iconColumn")
      i.icon.fas.fa-cube

    .column(data-testid="nameColumn")
      sortable-table-header-column(
        sort-param="name",
        :sort-order="sortOrder",
        :active="isSortedBy('name')",
        @sort="param => $emit('sort', param)"
      )
        span.text {{ $t('.columns.name.label') }}

    .column.flex.unsortable.vertical-center(data-testid="sapColumn")
      sap-icon.sap-icon(color="gray")
      span.text {{ $t('.columns.sap.label') }}

    .column.flex.unsortable.vertical-center.center
      template(v-if="loading")
        i.loader-icon.fas.fa-spin.fa-spinner

</template>


<script>

export default {
  props: {
    loading:   { type: Boolean, default: false },
    sortParam: { type: String, required: true },
    sortOrder: { type: String, required: true }
  },

  data() {
    return {
      i18nScope: "products.index.products-table-header"
    }
  },

  methods: {
    isSortedBy(param) {
      return this.sortParam === param
    }
  }
}

</script>

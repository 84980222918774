<template lang="pug">
  service-order-section-layout.service-order-products-section(:empty="isEmpty")
    template(#icon)
      i.fas.fa-cube
    template(#title) {{ $t(".title") }}
    template(v-if="!isEmpty", #info)
      span.count {{ $t(".count", { count: itemsCount }) }}
    template(#empty) {{ $t(".empty") }}
    service-order-products-list(:items="serviceOrder.serviceOrderProducts")
</template>

<script>
import ServiceOrderSection from "./service-order-section"
import ServiceOrderProductsList from "./service-order-products-list.vue"

export default {
  components: {
    ServiceOrderProductsList
  },

  extends: ServiceOrderSection,

  computed: {
    i18nScope() {
      return "monitoring.service-orders.service-order-products-section"
    },

    isEmpty() {
      return _.blank(this.serviceOrder.serviceOrderProducts)
    },

    itemsCount() {
      return this.serviceOrder.serviceOrderProducts?.length || 0
    }
  }
}
</script>

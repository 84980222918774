<style scoped lang="scss">
.button-content {
  width: 56px;
  text-align: right;
}

.tire-size-fields {
  margin-bottom: 24px;
}

.full {
  flex-grow: 1;
}
</style>


<template lang="pug">
  .tire-size-fields.flex.verical-center
    tire-size-select-field.full(
      :name="name",
      v-model="resource.size",
      :loading="loading",
      hide-label,
      :placeholder="$t('.placeholder')",
      :errors="errors.size"
    )

    .button-content
      app-button.btn(
        v-if="!hideRemove",
        theme="neutral",
        :disabled="loading",
        @click="$emit('remove')"
      )
        i.fal.fa-trash-alt

</template>


<script>

export default {
  props: {
    resource:   { type: Object, required: true },
    loading:    { type: Boolean, default: false },
    name:       { type: String, default: null },
    hideRemove: { type: Boolean, default: false },
    errors:     { type: Object, default: () => ({}) }
  },

  data() {
    return {
      i18nScope: "vehicle-models.forms.tire-size-fields"
    }
  }
}

</script>

<style scoped lang="scss">

.services {
  padding-bottom: 24px;

  .header {
    margin-bottom: 32px;
    padding-bottom: 16px;
    border-bottom: 1px solid $light-gray-3;

    .title-icon {
      margin-right: 16px;
      font-size: 30px;
    }

    .title-text {
      font-size: 30px;
      font-weight: normal;
      font-weight: 500;
      font-family: $secondary-font;
    }
  }

  .clear-filters {
    position: relative;

    .filters-button {
      position: absolute;
      margin-top: 10px;
    }
  }

  .list {
    margin-top: 50px;

    .table {
      margin-top: 16px;
    }
  }

  .paginator-content {
    padding-top: 24px;

    &.loading {
      max-width: 400px;
      margin: 0 auto;
    }
  }
}

</style>


<template lang="pug">

  .services
    header.header.flex.space-between
      .title.flex.vertical-center
        i.title-icon.product-icon.fal.fa-folder-tree
        h1.title-text {{ $t('.title') }}
      app-button(
        v-if="$can('create', 'Service')",
        :to="newServiceRoute",
        data-testid="createService"
      ) {{ $t('.btn.new') }}

    search-form.search(
      :loading="fetching",
      :params="parsedQueryParams",
      @search="search"
    )

    .clear-filters
      filters-button.filters-button(
        v-if="hasFilters",
        @clear="clearFilters"
      )

    .list
      .flex.space-between.vertical-bottom
        .result-tabs.flex
        results-indicator(
          :page="parsedQueryParams.page",
          :per-page="pagination.perPage",
          :total="pagination.total",
          :loading="fetching"
        )

      services-table.table(
        :services="services",
        :initializing="initializing",
        :loading="fetching",
        :sorted-by="parsedQueryParams.sort",
        @sort="onSortChange",
        @changed="fetch"
      )

    .paginator-content(v-if="fetching || showPaginator", :class="{ loading: fetching }")
      paginator(
        :loading="fetching",
        :per-page="pagination.perPage",
        :total="pagination.total",
        :value="parsedQueryParams.page",
        :disabled="fetching",
        @input="onPageChange",
        data-testid="paginator"
      )

</template>


<script>

// View
import IndexView from "@/views/index-view"

// Mixins
import FetchMixin from "@/mixins/fetch-mixin"

// Components
import ServicesTable from "./index/services-table"
import SearchForm from "./index/search-form"

export default {
  name: "Services",

  components: {
    ServicesTable,
    SearchForm
  },

  extends: IndexView,

  mixins: [FetchMixin],

  data() {
    return {
      i18nScope: "services.index",
      routeName: "services",

      resource: [],

      // @requirement IndexView
      queryParamsStructure: {
        q:    "string",
        type: "string",
        page: "integer"
      }
    }
  },

  computed: {
    services: {
      get() { return this.resource },

      set(value) { this.resource = value }
    },

    newServiceRoute() {
      return { name: "newService" }
    }
  },

  methods: {
    parseRoute() {},

    // @override Fetch Mixin
    fetchRequest() {
      return this.$newSdk.services.all({ params: this.requestQueryParams })
    }
  }
}

</script>

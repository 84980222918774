<style lang="scss" scoped>

.new-vehicle-model-group {
  padding-bottom: 24px;

  .header {
    margin-bottom: 32px;
    padding-bottom: 16px;
    border-bottom: 1px solid $light-gray-3;

    .title {
      font-size: 30px;
      font-weight: 500;
      font-family: $secondary-font;
    }
  }

  .fields {
    margin-bottom: 24px;
    padding-bottom: 24px;
    border-bottom: 1px solid $light-gray-3;
  }
}

</style>


<template lang="pug">

  .new-vehicle-model-group
    header.header
      h1.title {{ $t('.title') }}

    form.form(@submit.prevent="submit", method="post")
      form-fields.fields(
        :resource="resource",
        :submitting="submitting",
        :errors="errors"
      )

      section.flex.space-between.vertical-center
        app-button(
          :to="{ name: 'vehicleModelGroups' }",
          theme="gray",
          outline,
          :disabled="submitting"
        ) {{ $t('btn.cancel') }}

        app-button.button(
          type="submit",
          :loading="submitting",
          :disabled="submitting",
          data-testid="submitButton"
        )
          span {{ $t('.btn.submit.label') }}
          span(slot="loading") {{ $t('.btn.submit.loading') }}

</template>


<script>

// Mixins
import { mixins } from "movida-common.vue"

// Components
import FormFields from "./form-fields"

// Extends
import View from "@/views/view"

// Models
import models from "@/models"

const { VehicleModelGroup } = models
const { FormMixin } = mixins

export default {
  name: "NewVehicleModelGroup",

  components: {
    FormFields
  },

  extends: View,

  mixins: [FormMixin],

  data() {
    return {
      i18nScope: "vehicle-model-groups.new",

      resource: new VehicleModelGroup(),
      model:    "VehicleModelGroup"
    }
  },

  methods: {
    // @override Form mixin
    submitRequest() {
      return this.$sdk.vehicleModelGroups.create({ params: this.resource.$serialize() })
    },

    // @override Form mixin
    submitSuccess({ data: { id } }) {
      this.$notifications.info(this.$t(".notifications.submit.success", { name: this.resource.name }))

      this.$router.push({ name: "vehicleModelGroup", params: { id } })
    },

    // @override Form mixin
    submitError(err) {
      this.$err.log(err)

      let code = "default"

      const errors = _.get(err, "error.originalError.response.data.errors.vehicle_models") || []
      if (errors.includes("restrict")) code = "vehicleModelTaken"

      this.$notifications.error(this.$t(`.notifications.submit.error.${code}`))
    }
  }
}

</script>

import * as CNPJ from "@fnando/cnpj"
import Organization from "../organization"


class MovidaSupplier extends Organization {

  static get relations() {
    return {
      contacts: { type: "hasMany", model: "MovidaContact" }
    }
  }


  static get attrs() {
    return _.uniq([
      ...super.attrs,

      "remoteId",
      "cnpj",
      "name",
      "tradingName",

      "email",
      "cellphone",
      "phone",

      "addressCityName",
      "addressStateAbbr",
      "addressLine1",
      "addressLine2",
      "addressNumber",
      "addressNeighborhood",
      "internalFacilities",
      "supplierFacilities",
      "internal_facilities",
      "supplier_facilities",
      "rabbotPlataform",
      "movidaApp"
    ])
  }


  // decorators
  // ---
  // TODO: remover, substituindo por simpleAddress
  get address() {
    return this.simpleAddress
  }

  get simpleAddress() {
    if (["addressCityName", "addressStateAbbr"].every(attr => _.blank(this[attr]))) return null

    return _.trim(`${this.addressCityName} - ${this.addressStateAbbr}`)
  }

  get fullAddress() {
    let fullAddress = _.trim(this.addressLine1)

    if (this.$present("addressNumber")) fullAddress += `, ${_.trim(this.addressNumber)}`
    if (this.$present("addressLine2")) fullAddress += `, ${_.trim(this.addressLine2)}`
    if (this.$present("addressNeighborhood")) fullAddress += ` - ${_.trim(this.addressNeighborhood)}`
    if (this.$present("simpleAddress")) fullAddress += `, ${this.simpleAddress}`

    return fullAddress
  }

  get formattedCNPJ() {
    if (this.$blank("cnpj")) return null

    return CNPJ.format(this.cnpj)
  }
}


export default MovidaSupplier

<style scoped lang="scss">

.form {
  overflow: auto;
}


.modal-header {
  color: $primary;
  margin-top: 0;
  margin-bottom: 24px;
}

.heading {
  font-size: 18px;
  font-weight: 500;
  color: $dark-gray;
  height: 32px;
  line-height: 32px;
  font-family: $secondary-font;
  margin-left: 24px;
  margin-top: 24px;

  .icon {
    margin-right: 8px;
  }
}

.subtitle {
  margin-bottom: 4px;
  font-size: 18px;
  font-weight: 500;
  color: $dark-gray;
  font-family: $secondary-font;

  &.error {
    color: $error;
  }
}

.header-divider {
  height: 1px;
  background: $light-gray-3;
  margin-top: 16px;
}

.section {
  padding: 0 24px;
}

.actions {
  border-top: 1px solid $light-gray-3;
  padding: 24px;
}

.group-field {
  border-bottom: 1px solid $light-gray-3;
  padding-bottom: 24px;
  margin-bottom: 24px;
}

.error-message {
  display: block;
  color: $error;
  font-size: 14px;
  line-height: 1;
  padding-top: 4px;
  margin-bottom: 24px;
}

.maintenance-table {
  margin-top: 24px;
}

.maintenance-table-kind {
  border-top: 1px solid $light-gray-3;
  padding: 16px 24px;
}

.empty {
  text-align: center;
  font-style: italic;
  padding: 32px 0;
  width: 100%;

  color: $gray-3;
}

</style>


<template lang="pug">

  .new-import.flex.column-direction.full-height
    header.modal-header.no-shrink
      h1.heading
        i.icon.fal.fa-table
        span {{ $t('.heading.import') }}
      .header-divider

    form.form.flex.column-direction.grow(@submit.prevent="submit", method="post")
      section.group-field.section
        vehicle-model-group-select-field(
          name="import[vehicleModelGroupId]",
          :label="$t('.fields.vehicleModelGroup.label')",
          :placeholder="$t('.fields.vehicleModelGroup.placeholder')",
          @input="onSelectVehicleModelGroup",
          :value="resource.vehicleModelGroupId",
          :errors="errors.vehicleModelGroupId",
          :loading="submitting"
        )

      section.section.grow
        h2.subtitle(
          :class="{ error: hasVehicleModelGroupMaintenanceTableErrors }"
        ) {{ $t('.heading.maintenanceTable') }}

        //- TODO: retornar tratamento de erro adequado
        //- span.error-message(
        //-   v-for="(error, index) in errors.vehicleModelGroupMaintenanceTable",
        //-   :key="`maintenance-table-ids-error-${index}`"
        //- ) {{ error }}
        //-
        //- por enquanto vamos deixar uma mensagem fixa de "não é válido"!
        template(v-if="hasVehicleModelGroupMaintenanceTableErrors")
          span.error-message {{ importErrorText }}

        template(v-if="hasVehicleModelGroupMaintenanceTableIdsErrors")
          span.error-message {{ errors.vehicleModelGroupMaintenanceTableIds[0] }}

        template(v-if="hasVehicleModelGroupId")
          maintenance-tables-list-field.maintenance-table(
            :vehicle-model-group-id="resource.vehicleModelGroupId",
            v-model="resource.vehicleModelGroupMaintenanceTableIds"
          )

        template(v-else)
          .empty
            span {{ $t('.empty.message') }}

      template(v-if="hasOneMaintenanceTableId")
        section.maintenance-table-kind
          ui-row
            ui-col(:span="4")
              maintenance-table-kind-select-field(
                optional,
                name="import[kind]",
                :label="$t('.fields.kind.label')",
                :placeholder="$t('.fields.kind.placeholder')",
                :errors="errors.kind",
                v-model="resource.kind"
              )

      .section.actions.flex.space-between.vertical-center.no-shrink
        app-button(
          @click="$emit('close')",
          theme="gray",
          outline,
          :disabled="submitting"
        ) {{ $t('btn.cancel') }}

        .submit-actions
          app-button.button(
            type="submit",
            :disabled="submitting",
            :loading="submitting",
            data-testid="submitButton"
          )
            span {{ $t('.btn.submit.label') }}
            span(slot="loading") {{ $t('.btn.submit.loading') }}

</template>


<script>

// Mixins
import { mixins } from "movida-common.vue"

// Extends
import View from "@/views/view"

// Models
import models from "@/models"

// Components
import MaintenanceTablesListField      from "./maintenance-tables-list-field"
import MaintenanceTableKindSelectField from "./maintenance-table-kind-select-field"

const { FormMixin } = mixins
const { Base, VehicleModelGroupMaintenanceTable } = models

class Import extends Base {
  static get attrs() {
    return [
      "vehicleModelGroupId",
      "vehicleModelGroupMaintenanceTableIds",
      "kind"
    ]
  }

  static get constraints() {
    return {
      vehicleModelGroupMaintenanceTableIds: { presence: true },
      vehicleModelGroupId:                  { presence: true }
    }
  }

  static get enums() {
    return {
      kind: VehicleModelGroupMaintenanceTable.$enums.kind
    }
  }
}

export default {
  name: "NewVehicleModelGroupMaintenanceTableImport",

  components: {
    MaintenanceTablesListField,
    MaintenanceTableKindSelectField
  },

  extends: View,
  mixins:  [FormMixin],

  props: {
    backRouteQuery: { type: Object, default: () => ({}) }
  },

  data() {
    return {
      i18nScope: "vehicle-model-groups.maintenance-tables.import.new",
      id:        this.$route.params.id,

      vehicleModelGroupMaintenanceTableIds: [],

      resource: new Import()
    }
  },

  computed: {
    maintenanceTableIds() {
      return this.resource.vehicleModelGroupMaintenanceTableIds
    },

    hasVehicleModelGroupMaintenanceTableErrors() {
      return _.present(this.errors.vehicleModelGroupMaintenanceTable)
    },

    hasVehicleModelGroupMaintenanceTableIdsErrors() {
      return _.present(this.errors.vehicleModelGroupMaintenanceTableIds)
    },

    // XXX: implementando temporariamente uma mensagem de erro fixa.
    // TODO: ajustar o mixins/form.js para expor o erro/request/response do axios,
    // - ref: https://gist.github.com/fgilio/230ccd514e9381fafa51608fcf137253
    importErrorText() {
      return this.$i18n.t("errors.invalid")
    },

    hasVehicleModelGroupId() {
      return _.present(this.resource.vehicleModelGroupId)
    },

    hasOneMaintenanceTableId() {
      return _.size(this.maintenanceTableIds) === 1
    }
  },

  watch: {
    maintenanceTableIds() {
      if (!this.hasOneMaintenanceTableId) {
        this.resource.kind = null
      }
    }
  },

  methods: {
    onSelectVehicleModelGroup(value) {
      this.resource.vehicleModelGroupId = value

      // Ao trocar de grupo de modelo precisamos zerar as manutenções
      this.resource.vehicleModelGroupMaintenanceTableIds = []
      this.errors = {}
    },

    // @override Form mixin
    submitRequest() {
      const params = this.resource.$serialize()

      return this.$sdk.vehicleModelGroups.importMaintenanceTables({
        id: this.id,
        params
      })
    },

    // @override Form mixin
    submitSuccess(response) {
      this.$notifications.info(this.$t(".notifications.submit.success"))

      this.$emit("import")
    },

    // @override Form mixin
    submitError(err) {
      // XXX: seria possível acessar a response do axios com:
      // - err.error.originalError.response

      this.$err.log(err)
      this.$notifications.error(this.$t(".notifications.submit.error"))
    }
  }
}

</script>

import Base from "../base"

class SupplierServiceScoreSettings extends Base {
  static get attrs() {
    return _.uniq([
      ...super.attrs,
      "priceCoefficient",
      "timeCoefficient",
      "distanceCoefficient",
      "capacityCoefficient",
      "distanceStrategy"
    ])
  }

  static get enums() {
    return {
      distanceStrategy: {
        geographic: "geographic",
        driving:    "driving"
      }
    }
  }

  static get constraints() {
    return {
      priceCoefficient:    { type: "number", presence: true },
      timeCoefficient:     { type: "number", presence: true },
      distanceCoefficient: { type: "number", presence: true },
      capacityCoefficient: { type: "number", presence: true },
      distanceStrategy:    { type: "string", presence: true }
    }
  }
}

export default SupplierServiceScoreSettings

<style lang="scss" scoped>

.search-form {
  width: 100%;

  .row {
    align-items: end;
    display: grid;
    gap: 8px;

    &:not(:last-child) {
      margin-bottom: 8px;
    }

    // Columns
    &:nth-child(1) {
      grid-template-columns: 3fr 2fr;
    }
    &:nth-child(2) {
      grid-template-columns: minmax(0, 5fr) repeat(3, minmax(0, 2fr)) 40px;
    }
  }

  .submit {
    min-width: unset;
    padding: 0;
    width: 40px;
  }
}
</style>


<template lang="pug">

    form.search-form(
      @submit.prevent="$emit('search')"
      method="get"
      data-test="form"
    )
      .row
        input-field(
          v-model="params.q"
          hide-label
          icon-left="far fa-search"
          :loading="loading"
          :placeholder="$t('.fields.q.placeholder')"
          name="search[q]"
        )

        sector-select-field(
          v-model="params.sectorId"
          :label="$t('.fields.sector.label')"
          :loading="loading"
          :placeholder="$t('.fields.sector.placeholder')"
          name="search[sector_id]"
        )

      .row
        city-select-field(
          v-model="params.cityId"
          :label="$t('.fields.city.label')"
          :loading="loading"
          name="search[city_id]"
          :placeholder="$t('.fields.city.placeholder')"
        )

        deal-state-select-field(
          v-model="params.dealState"
          :label="$t('.fields.dealState.label')"
          :loading="loading"
          name="search[deal_state]"
          :placeholder="$t('.fields.dealState.placeholder')"
        )

        supplier-classification-finder-field(
          v-model="params.classification"
          :disabled="loading"
          :include-none="true"
          :listbox-config="{ align: 'right', width: 250 }"
          name="search[classification]"
          :placeholder="$t('.fields.classification.placeholder')"
        )

        finder-field.blocked-field(
          v-model="params.blocked",
          name="search[blocked]",

          :label="$t('.fields.blocked.label')",
          :options="blockedOptions",
          :placeholder="$t('.fields.blocked.placeholder')",
          :listbox-config="{ align: 'right', width: 200 }"
        )
        .row 
        finder-field.rabbotPlataform-field(
          v-model="params.rabbotPlataform",
          name="search[rabbot_plataform]",
          :disabled="loading",
          :label="$t('.fields.rabbotPlataform.label')",
          :options="rabbotPlataformOptions",
          :placeholder="$t('.fields.rabbotPlataform.placeholder')"
        )

        app-button.submit(
          :loading="loading"
          outline
          type="submit"
        )
          i.icon.far.fa-search

</template>


<script>

import SupplierClassificationFinderField from "../components/supplier-classification-finder-field.vue"
import VehicleModelGroupsModelFinderField
  from "@/views/vehicle-models/components/vehicle-model-groups-model-finder-field.vue";

export default {
  components: {
    SupplierClassificationFinderField,
    VehicleModelGroupsModelFinderField
  },

  props: {
    loading: { type: Boolean, default: false },
    params:  { type: Object, default: () => ({}) }
  },

  data() {
    return {
      i18nScope: "suppliers.index.search-form"
    }
  },

  computed: {
    blockedOptions() {
      // cada option é: { icon, label, value }
      return [{
        value: false,
        label: this.$t(".fields.blocked.options.false.label")
      }, {
        value: true,
        label: this.$t(".fields.blocked.options.true.label")
      }]
    },
    rabbotPlataformOptions() {
      // cada option é: { icon, label, value }
      return [{
        value: true,
        label: this.$t(".fields.rabbotPlataform.options.true.label")
      }, {
        value: false,
        label: this.$t(".fields.rabbotPlataform.options.false.label")
      }]
    },
  }, 
}

</script>

<script>

import Vue from "vue"
import SelectField from "@/components/form/select-field"

import models from "@/models"
const { ReviewChangesRequest } = models

export default {
  // TODO: remover nome. somente componentes globais precisam de nome.
  // name: "ChangesRequestsMotiveSelectField",

  extends: SelectField,

  props: {
    // Mudando comportamento para default ser true
    searchable: { type: Boolean, default: true }
  },

  data() {
    return {
      i18nScope: "monitoring.quotes.reviews.changes-requests.motive-select-field"
    }
  },

  computed: {
    options() {
      return ReviewChangesRequest.$enums.motive.values
        .map(value => ({
          value,
          label: ReviewChangesRequest.$tEnum("motive", value)
        }))
    }
  },

  // Removendo props 'options' que está sendo reescrita como _computed_
  beforeCreate() {
    Vue.delete(this.$options.props, "options")
  }
}

</script>

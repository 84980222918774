<style scoped lang="scss">

.product-row {
  display: grid;
  grid-template-columns: 70px 70px 1fr 1fr 64px;

  &.loading {
    .column {
      &.id-column {
        .id-tag {
          color: $gray-2;
        }
      }
      &.sap-column {
        ::v-deep.outside {
          fill: $gray-2;
        }
      }
      &.actions-column {
        ::v-deep .movida-common__app-button {
          color: $gray-2;
          &:hover {
            color: $orange;
          }
        }
      }
      .text,
      .link ::v-deep .app-span {
        color: $gray-2;
      }
    }
  }

  .column {
    padding: 0 12px;
    border-bottom: 1px solid $light-gray-3;
    height: 48px;
    overflow: hidden;

    .discarded-icon {
      margin-right: 6px;
    }

    &.models-count-column {
      .text {
        font-family: $secondary-font;
      }
      .car-icon {
        margin-right: 8px;
        color: $gray-2;
      }
    }

    ::v-deep .movida-common__app-button {
      white-space: unset;
    }

    .button {
      overflow: hidden;
    }

    .sap-tag {
      font-family: $primary-monospace-font;
      font-weight: 400;
      font-size: 14px;
      color: $dark-gray;
      text-align: initial;
    }

    .text,
    .link ::v-deep .app-span {
      font-family: $primary-font;
      font-weight: 400;
      font-size: 14px;
      color: $dark-gray;
      text-align: initial;
    }

    .link ::v-deep .app-span:hover {
      color: $orange;
    }
  }
}

</style>


<template lang="pug">

  .product-row(:class="{ loading }")
    .column.id-column.flex.vertical-center
      id-tag.id-tag(
        :value="product.id",
        data-testid="productId"
      )

    .column.flex.vertical-center
      product-type-icon.icon(:type="product.type", :size="24")

    .column.flex.vertical-center
      discarded-icon.discarded-icon(v-if="product.$discarded")

      app-button.button(
        theme="link",
        :to="showRoute"
      )
        app-span.link(
          :value="product.name",
          crop,
          data-testid="productName"
        )
    .column.sap-column.flex.vertical-center
      sap-icon
      span.sap-tag(data-testid="productSapId") {{ product.sapId }}

    .column.actions-column.flex.vertical-center.flex
      app-button(theme="neutral", size="small", @click="$emit('remove', product)")
        i.fal.fa-trash-alt

</template>


<script>

import models from "@/models"
const { Product } = models

export default {
  props: {
    product:     { type: Object, default: () => ({}) },
    loading:     { type: Boolean, default: false },
    showActions: { type: Boolean, default: true }
  },

  data() {
    return {
      i18nScope: "products.index.products-row",

      Product
    }
  },

  computed: {
    showRoute() {
      return {
        name:   "product",
        params: {
          id: this.product.id
        }
      }
    }
  }
}

</script>

// Base
import models from "@/models"
import BaseRequests from "./Base"

// Models
const { DealProduct, DealMaintenancePackage, Deal } = models

class DealsRequests extends BaseRequests {
  static get selector() {
    return "deals"
  }

  listStateChanges({ dealId, params = {}, options = {} } = {}) {
    if (_.blank(dealId)) {
      throw new Error('NewSDK deals - #listStateChanges() - "dealId" is a required prop')
    }

    return this.action({
      method: "get",
      url:    `/deals/${dealId}/state_changes`,
      params,
      options
    })
  }

  listProducts({ dealId, params = {}, options = {} } = {}) {
    if (_.blank(dealId)) {
      throw new Error('NewSDK deals - #listProducts() - "dealId" is a required prop')
    }

    return this.action({
      method:  "get",
      url:     `/deals/${dealId}/products`,
      params,
      options: {
        model: DealProduct,

        ...options
      }
    })
  }

  listMaintenancePackages({ dealId, params = {}, options = {} } = {}) {
    if (_.blank(dealId)) {
      throw new Error(
        'NewSDK deals - #listMaintenancePackages() - "dealId" is a required prop'
      )
    }

    return this.action({
      method:  "get",
      url:     `/deals/${dealId}/maintenance_packages`,
      params,
      options: {
        model: DealMaintenancePackage,

        ...options
      }
    })
  }

  find({ dealId, params = {}, options = {} } = {}) {
    if (_.blank(dealId)) {
      throw new Error('NewSDK deals - #find() - "id" is a required prop')
    }
    return this.action({
      method:  "get",
      url:     `/deals/${dealId}`,
      params,
      options: {
        model: Deal,

        ...options
      }
    })
  }
}

export default DealsRequests

<style lang="scss" scoped>

.services {

  .title {
    margin-bottom: 16px;

    .icon-container {
      width: 16px;
      height: 16px;
      margin-right: 8px;

      .icon {
        font-size: 14px;
      }
    }

    .diamond-icon {
      color: $gray-3;
      font-size: 4px;
      margin: 0 8px;
    }

    .count {
      font-family: $secondary-font;
      font-weight: 400;
      color: $gray-3;
    }
  }

  .table {
    gap: 8px;

    .header {
      grid-template-columns: var(--grid-columns);
      gap: 8px;
      font-family: $secondary-font;
      font-weight: 400;
      font-size: 14px;
      color: $gray-3;

      .icon {
        font-size: 14px;
        color: $gray-2;
      }

      .diamond-spacer {
        font-size: 8px;
        color: $gray-2;
      }
    }

    .footer {
      grid-template-columns: 1fr 136px;
      gap: 8px;
      height: 40px;

      .subtotal {
        background-color: $light-gray-2;
        border-radius: 4px;
        padding: 0 12px;
        font-family: $primary-monospace-font;
        font-weight: 400;
        font-size: 14px;
      }
    }
  }
}

</style>


<template lang="pug">

  .services(:style="cssVars")
    .title.flex.vertical-center
      .icon-container.flex.center.vertical-center
        i.icon.far.fa-wrench
      h3.heading {{ $t('.heading') }}
      i.diamond-icon.fas.fa-diamond
      span.count {{ $t('.items-count', { count }) }}

    .table.grid
      .header.grid.vertical-center
        span {{ $t('.columns.service') }}
        .flex.center.vertical-center
          i.icon.far.fa-shield-check(v-tooltip="$t('.columns.warranty')")
        span.text-right {{ $t('.columns.quantity') }}
        span.text-right {{ $t('.columns.price') }}
        span.text-right {{ $t('.columns.totalPrice') }}
        i.diamond-spacer.text-center.far.fa-diamond
        i.diamond-spacer.text-center.far.fa-diamond
        i.diamond-spacer.text-center.far.fa-diamond

      template(v-for="(quoteService, index) in quoteServices")
        service-fields(
          :column-sizes="columnSizes",
          :errors="errorsFor(quoteService)",
          :key="quoteService.id || quoteService.tempId",
          :quote-service="quoteService",
          :show-add-button="index === quoteServices.length - 1",
          :submitting="submitting",
          @add="addItem",
          @destroy="destroy(quoteService)",
          @update="updated => update(quoteService, updated)"
        )

      .footer.grid
        .subtotal.flex.space-between.vertical-center
          span {{ $t('.subtotal') }}
          span {{ formattedSubtotal }}

</template>


<script>

// 3rd Party
import { v4 as uuid } from "uuid"

// Components
import ServiceFields from "./service-fields.vue"

// Models
import models from "@/models"
const { QuoteService } = models


export default {
  components: {
    ServiceFields
  },

  props: {
    items:      { type: Array, required: true },
    submitting: { type: Boolean, default: false },
    errors:     { type: Object, required: true },
    subtotal:   { type: Number, default: null },
    quoteId:    { type: [String, Number], required: true }
  },

  data() {
    return {
      i18nScope: "monitoring.quotes.services",

      columnSizes: "1fr 40px 55px 123px 123px 40px 40px 40px"
    }
  },

  computed: {
    quoteServices() {
      return this.items.filter(item => !item.$markedForDestruction)
    },

    count() {
      return this.quoteServices.filter(item => !this.isItemEmpty(item)).length
    },

    cssVars() {
      return {
        "--grid-columns": this.columnSizes
      }
    },

    formattedSubtotal() {
      const total = _.present(this.subtotal) ? this.subtotal : 0

      return this.$asCurrency(total)
    }
  },

  created() {
    if (this.items?.length === 0) this.addItem()
  },

  methods: {
    isItemEmpty(item) {
      if (_.present(item.id)) return false

      return _.blank(_.deleteBlanks(_.omit(item, ["tempId", "provided", "warranty", "quoteId"])))
    },

    addItem() {
      if (this.submitting) return

      this.$emit("add-item", new QuoteService({ tempId: uuid(), quoteId: this.quoteId }))
    },

    errorsFor(resource) {
      const key = resource.id || resource.tempId
      return this.errors?.[key] || {}
    },

    getItemIndex({ id, tempId }) {
      return _.present(id)
        ? this.items.findIndex(item => item.id === id)
        : this.items.findIndex(item => item.tempId === tempId)
    },

    destroy({ id, tempId }) {
      const index = this.getItemIndex({ id, tempId })
      if (index === -1) return

      const items = [...this.items]
      let item = items[index]

      if (item.$newRecord) items.splice(index, 1)
      else item.$markForDestruction()

      // XXX: Sempre deixar um elemento vazio
      if (items.filter(entry => !entry.$markedForDestruction).length === 0) {
        items.push(new QuoteService({ tempId: uuid() }))
      }

      this.$emit("update:items", items)
    },

    update({ id, tempId }, updated) {
      const index = this.getItemIndex({ id, tempId })
      if (index === -1) return

      const items = [...this.items]
      items[index] = updated

      this.$emit("update:items", items)
    }
  }
}

</script>

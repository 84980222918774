<script>

import DownloadButton from "./download-button"

export default {
  extends: DownloadButton,

  data() {
    return {
      i18nScope: "suppliers.deals.products.index.template-download-button"
    }
  },

  computed: {
    fileName() {
      return `template-produtos.xlsx`
    },

    icon() {
      return "fa-file"
    }
  },

  methods: {
    request() {
      return this.$http.get(`/deals/${this.dealId}/products/spreadsheet`, {
        responseType:       "blob",
        onDownloadProgress: this.onDownloadProgress
      })
    }
  }
}

</script>

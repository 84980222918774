<style lang="scss" scoped>

.edit {
  .back,
  .cancel {
    .icon {
      margin-right: 4px;
    }
  }

  .back {
    color: $dark-gray;
    font-family: $secondary-font;
    font-weight: 500;
    font-size: 14px;

    &:hover {
      color: $orange;
    }

    .icon {
      font-size: 12px;
    }
  }

  .load {
    margin-top: 16px;
  }

  .title {
    font-family: $secondary-font;
    font-weight: 500;
    font-size: 30px;
    margin-top: 16px;
  }

  .cards {
    margin-top: 16px;
    grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
    gap: 24px;
  }

  .history {
    margin-top: 16px;
  }

  .service-order {
    margin-top: 24px;
  }

  .section {
    padding-top: 24px;
    margin-top: 24px;
    border-top: 1px solid $light-gray-4;
  }

  .attachments {
    > .label {
      display: block;
      margin-bottom: 8px;
      font-size: 14px;
      line-height: 17px;
      font-weight: 500;
      font-family: "Rubik", Helvetica, Arial, sans-serif;
      color: #333333;
    }

    .error-message {
      color: $dark-red;
      font-size: 12px;
    }
  } // .attachments


  .fields {
    padding-bottom: 30px;

    .form-title {
      font-family: $secondary-font;
      font-weight: 500;
      font-size: 24px;
    }

    .waves-separator {
      margin-top: 8px;
      background: var(--purple-waves-background);
      height: 16px;
      border-radius: 4px;
    }

    .summary,
    .form-fields,
    .extra {
      margin-top: 40px;
    }

    .summary {
      padding: 16px;
      background: var(--purple-light-waves-background), $light-gray;
      border-radius: 8px;

      .label {
        font-family: $secondary-font;
        font-weight: 400;
        font-size: 12px;
      }

      .subtotal + .subtotal {
        margin-left: 24px;
      }

      .price {
        font-family: $primary-monospace-font;
        font-size: 12px;
        font-weight: 400;

        &.total-price {
          font-weight: 500;
          font-size: 16px;
        }
      }
    }

    .extra {
      .section-title {
        margin-bottom: 16px;

        .icon-container {
          width: 16px;
          height: 16px;
          margin-right: 8px;

          .icon {
            font-size: 14px;
          }
        }
      }

      .fields {
        grid-template-columns: repeat(3, minmax(150px, 1fr));
        gap: 8px;

        //- XXX: gtf tem 4 colunas
        &.gtf {
          grid-template-columns: repeat(4, minmax(150px, 1fr));
        }

        .note {
          font-style: italic;
          font-weight: 300;
          font-size: 12px;
          margin-top: 4px;
          text-align: right;
        }
      }
    }
  }

  .footer {
    .button {
      & + .button {
        margin-left: 24px;
      }
    }

    .save {
      font-size: 14px;
      color: $dark-gray;

      &:hover {
        color: $orange;
      }
    }

    .submit {
      .icon {
        margin-left: 8px;
      }
    }
  }
}

</style>


<template lang="pug">

  .edit(:style="cssVars")
    app-button.back(theme="link", @click="goBack")
      i.icon.fas.fa-arrow-left
      span {{ $t('.back') }}

    template(v-if="fetching")
      loading-lines.load(:lines="6")

    template(v-else)
      h2.title {{ $t('.title') }}

      .cards.grid
        .vehicle
          vehicle-card(:vehicle="vehicle")

        .supplier
          supplier-card(:supplier="supplier")

      vehicle-history.history(:vehicle-id="vehicle.id")

      service-order-info.service-order(:service-order="serviceOrder")

      .section.fields
        h3.form-title {{ $t('.title') }}

        .waves-separator

        services.form-fields(
          :errors="errors.quoteServicesAttributes || errors.quoteServices || {}",
          :items.sync="quote.quoteServices",
          :quote-id="quoteId",
          :submitting="submitting",
          :subtotal="servicesTotalPrice",
          @add-item="addService"
        )

        products.form-fields(
          :errors="errors.quoteProductsAttributes || errors.quoteProducts || {}",
          :items.sync="quote.quoteProducts",
          :quote-id="quoteId",
          :submitting="submitting",
          :subtotal="productsTotalPrice",
          @add-item="addProduct"
        )

        .summary.flex.space-between.vertical-center
          //- XXX: apenas para domain rac+
          //- (e para gtf+, usamos a computed quoteTotalPrice em serializeAttributes(), antes da xhr)
          template(v-if="quote.domain == 'rac'")
            .flex
              money-field(
                v-model="quote.total",
                mandatory,
                name="quote[total]",
                :disabled="submitting",
                :errors="errors.total",
                :label="$t('.fields.total.label')",
                :placeholder="$t('.fields.total.placeholder')",
                :max-value="999999.99",
                :show-currency="false"
              )

          .subtotals.flex.vertical-center
            .subtotal.services.flex.column-direction
              span.label {{ $t('.summary.services-subtotal') }}
              span.price {{ $asCurrency(servicesTotalPrice) }}

            .subtotal.products.flex.column-direction
              span.label {{ $t('.summary.products-subtotal') }}
              span.price {{ $asCurrency(productsTotalPrice) }}

          .total
            .flex.column-direction
              span.label.text-right {{ $t('.summary.total') }}
              span.price.total-price {{ $asCurrency(quoteTotalPrice) }}

        provided-products.form-fields(
          :errors="errors.quoteProductsAttributes || errors.quoteProducts || {}",
          :items.sync="quote.quoteProducts",
          :quote-id="quoteId",
          :submitting="submitting",
          @add-item="addProvidedProduct"
        )

        .extra
          .section-title.flex.vertical-center
            .icon-container.flex.center.vertical-center
              i.icon.far.fa-info-circle
            h3.heading {{ $t('.fields.title') }}

          .fields.grid(:class="quote.domain")
            .cell
              datetime-field.field.issued-at(
                  mandatory,
                  :max-date="issuedAtMaxDate",
                  v-model="quote.issuedAt",
                  name="quote[issuedAt]",
                  :disabled="submitting",
                  :errors="errors.issuedAt"
                )

            .cell
              integer-field(
                v-model="quote.targetExecutionTime",
                hide-controls,
                name="quote[targetExecutionTime]",
                :disabled="submitting",
                :errors="errors.targetExecutionTime",
                :label="$t('.fields.target-execution-time.label')",
                :max="99999",
                :min="0",
                :suffix="$t('.fields.target-execution-time.suffix')"
              )
              span.note {{ $t('.fields.target-execution-time.note') }}

            .cell
              consultant-finder-field.consultant(
                v-model="quote.consultantRid"
                :disabled="fetching"
                :domain="quote.domain"
                :errors="errors.consultantRid"
                :label="$t('.fields.consultant.label')"
                :listbox-config="{ maxHeight: 300 }"
                name="quote[consultantRid]"
              )

            //- XXX: apenas para domain gtf+
            template(v-if="quote.domain == 'gtf'")
              .cell
                select-field.approval-group-field(
                  mandatory,
                  :options="approvalGroupOptions",
                  v-model="quote.approvalGroup",
                  name="quote[approvalGroup]",
                  :errors="errors.approvalGroup",
                  :loading="fetching",
                  :tooltip="{ content: $t('.fields.approvalGroup.tooltip'), placement: 'top' }",
                )


          .fields
            textarea-field(
              v-model="quote.description",
              name="quote[description]",
              :disabled="submitting",
              :errors="errors.description",
              :label="$t('.fields.description.label')",
              :placeholder="$t('.fields.description.placeholder')"
            )

          .attachments
            label.label Anexos

            attach-area(
              v-model="quote.attachments",
              :dropzone-config="{\
                buttonLabel: $t('.btn.attachments.add.label'),\
                message: $t('.fields.attachments.message')\
              }"
              :quote-id="quoteId",
              type="quote"
            )

      .section
        .footer.flex.space-between
          .left
            app-button.button.cancel(
              outline,
              theme="gray",
              :disabled="submitting",
              :loading="fetching",
              @click="goBack"
            )
              i.icon.far.fa-arrow-left
              span {{ $t('.btn.cancel.label') }}

            app-button.button.save(
              theme="link",
              :disabled="submitting",
              :loading="fetching",
              @click="save"
            )
              span {{ $t('.btn.save.label') }}

          .right
            app-button.button.submit(
              :disabled="submitting",
              :loading="fetching",
              @click="openApprovalModal"
            )
              span {{ $t('.btn.submit.label') }}
              i.icon.fas.fa-paper-plane

    new-quote-review-approval(
      v-if="showApprovalModal",
      :quote="quote",
      @change="redirect",
      @close="showApprovalModal = false"
    )

</template>


<script>

import { lib as movidaCommonLib } from "movida-common.vue"

// Assets
import purpleWaves      from "@/assets/images/waves-purple.png"
import purpleLightWaves from "@/assets/images/waves-purple-light.png"

// View
import LoadedView from "@/views/loaded-view"

// Mixins
import FetchMixin from "@/mixins/fetch-mixin"
import FormMixin  from "@/mixins/form-mixin"

// Components
import ConsultantFinderField from "./_components/consultant-finder-field.vue"
import SupplierCard     from "./_components/supplier-card.vue"
import VehicleCard      from "./_components/vehicle-card.vue"
import VehicleHistory   from "./_components/vehicle-history.vue"
import Services         from "./_components/services.vue"
import Products         from "./_components/products.vue"
import ProvidedProducts from "./_components/provided-products.vue"
import ServiceOrderInfo from "./_components/service-order-info.vue"

import NewQuoteReviewApproval from "@/views/monitoring/quotes/reviews/approvals/new.vue"

import { VETOR_APPROVAL_GROUPS } from "@/enums/service-orders"


// Models
const parseModelJsErrors = movidaCommonLib.ParseErrors.parseModelJsErrors


export default {
  name: "EditQuote",

  components: {
    ConsultantFinderField,
    SupplierCard,
    VehicleCard,
    VehicleHistory,
    Services,
    Products,
    ProvidedProducts,
    NewQuoteReviewApproval,
    ServiceOrderInfo
  },

  extends: LoadedView,

  mixins: [FetchMixin, FormMixin],

  data() {
    return {
      i18nScope: "monitoring.quotes.edit",
      routeName: "editQuote",

      // XXX: adicionando 15min para minimizar diferença entre "abrir" e "preencher" formulário
      issuedAtMaxDate: moment().add(15, "minutes").toISOString(),

      quoteId: null,

      resource: {},

      showApprovalModal: false,

      nestedAttributes: {
        attachments: null,

        quoteServices: {
          attachments: null
        },
        quoteProducts: {
          attachments: null
        }
      }
    }
  },

  computed: {
    backRoute() {
      return {
        name: "monitoring"
      }
    },

    hasAttachments() {
      return _.present(this.quote.attachments)
    },

    quote: {
      get()    { return this.resource },
      set(val) { this.resource = val  }
    },

    serviceOrder() {
      return this.quote?.serviceOrder
    },

    vehicle() {
      return this.serviceOrder?.vehicle
    },

    supplier() {
      return this.serviceOrder?.supplier
    },

    providedQuoteProducts() {
      return this.quote?.quoteProducts?.filter(quoteProduct => quoteProduct.provided)
    },

    unprovidedQuoteProducts() {
      return this.quote?.quoteProducts?.filter(quoteProduct => !quoteProduct.provided)
    },

    servicesTotalPrice() {
      return this.getTotalPrice(this.quote?.quoteServices)
    },

    productsTotalPrice() {
      return this.getTotalPrice(this.quote?.quoteProducts)
    },

    quoteTotalPrice() {
      return this.servicesTotalPrice + this.productsTotalPrice
    },


    approvalGroupOptions() {
      // cada option é: { icon, label, value }
      let approvalGroups = VETOR_APPROVAL_GROUPS.keys

      return approvalGroups.map(group => ({
        label: this.$t(`enums.service-orders.VETOR_APPROVAL_GROUPS.${group}`),
        value: group
      }))
    },

    hasAttachmentsError() {
      return _.present(this.errors?.attachments)
    },

    cssVars() {
      return {
        "--purple-waves-background":       `url(${purpleWaves})`,
        "--purple-light-waves-background": `url(${purpleLightWaves})`
      }
    }
  },


  methods: {
    goBack() {
      this.$goBack({ fallback: this.backRoute })
    },

    // @override Loaded view
    parseRoute() {
      this.quoteId = this.$params.asInteger(this.$route.params.id)
    },

    addProduct(product) {
      // XXX: só com push a reatividade não funciona! é preciso investigar melhor...
      // this.quote.quoteProducts.push(product)
      this.quote.quoteProducts = [...this.quote.quoteProducts, product]
    },

    addProvidedProduct(providedProduct) {
      // XXX: só com push a reatividade não funciona! é preciso investigar melhor...
      // this.quote.quoteProducts.push(providedProduct)
      this.quote.quoteProducts = [...this.quote.quoteProducts, providedProduct]
    },

    addService(service) {
      // XXX: só com push a reatividade não funciona! é preciso investigar melhor...
      // this.quote.quoteServices.push(service)
      this.quote.quoteServices = [...this.quote.quoteServices, service]
    },

    getTotalPrice(list) {
      return list
        ?.filter(item => !item.$markedForDestruction)
        ?.map(item => item.totalPrice)
        ?.filter(totalPrice => totalPrice != null)
        ?.reduce((accumulated, current) => accumulated + current, 0) || 0
    },

    isNestedQuoteProductBlank(quoteProduct) {
      if (_.present(quoteProduct.id)) return false

      // XXX: attrs a serem desconsiderados pois são gerados automaticamente no nested
      const autoGeneratedAttrs = ["tempId", "quoteId", "provided", "warranty"]

      return _.chain(quoteProduct)
        .omit(autoGeneratedAttrs)
        .deleteBlanks()
        .blank()
        .value()
    },

    isNestedQuoteServiceBlank(quoteService) {
      if (_.present(quoteService.id)) return false

      // XXX: attrs a serem desconsiderados pois são gerados automaticamente no nested
      const autoGeneratedAttrs = ["tempId", "quoteId", "warranty"]

      return _.chain(quoteService)
        .omit(autoGeneratedAttrs)
        .deleteBlanks()
        .blank()
        .value()
    },

    async redirect() {
      await this.$nextTick()

      if (this.$router.referrer) {
        this.$router.push(this.$router.referrer)
      }
      else {
        this.$router.push(this.backRoute)
      }
    },

    // @override For mixin
    // XXX: ignorando blank nested
    // TODO: tornar recursivo e extensível esse ignorar blank nested
    async validate() {
      if (_.blank(this.resource) || this.resource.$markedForDestruction) return {}

      let errors = {}
      await this.resource.$validate()
      errors = _.merge(errors, parseModelJsErrors(this.resource.$errors))

      for (let quoteService of this.resource.quoteServices) {
        if (this.isNestedQuoteServiceBlank(quoteService)) {
          // XXX: destrói o dado na validação!
          // quoteService.$markForDestruction()
          continue
        }

        await quoteService.$validate()
        if (_.present(quoteService.$errors)) {
          // XXX: isso é um id, e não um index. É assim
          let nestedIdx = quoteService.id || quoteService.tempId
          let nestedErrors = parseModelJsErrors(quoteService.$errors)

          // XXX: definido como Objeto, para índices numéricos não virarem Array (lodash)
          _.setWith(errors, `quoteServicesAttributes.${nestedIdx}`, nestedErrors, Object)
        }
      } // quoteServices


      for (let quoteProduct of this.resource.quoteProducts) {
        if (this.isNestedQuoteProductBlank(quoteProduct)) {
          // XXX: destrói o dado na validação!
          // quoteProduct.$markForDestruction()
          continue
        }

        await quoteProduct.$validate()
        if (_.present(quoteProduct.$errors)) {
          let nestedIdx = quoteProduct.id || quoteProduct.tempId
          let nestedErrors = parseModelJsErrors(quoteProduct.$errors)

          // XXX: definido como Objeto, para índices numéricos não virarem Array (lodash)
          _.setWith(errors, `quoteProductsAttributes.${nestedIdx}`, nestedErrors, Object)
        }
      } // quoteProducts

      return _.deleteBlanks(errors)
    },

    // @override Form mixin
    // Aqui, além de tratar os "nested" na serialização, vamos **remover** do dado enviado os
    // "blank nested".
    serializeAttributes() {
      let data = this.resource.$serialize({}, this.nestedAttributes)

      // XXX: definindo "total" para OSs do domínio gtf+, onde não há interação na UI/formulário!
      if (this.resource.domain == "gtf") {
        data.total = this.quoteTotalPrice
      }

      // XXX: usando props em camelCase para reaproveitar funções utilitárias `isNested*Blank`
      let attrs = _.camelizeKeys(data)

      // removendo "blanks" dos serializados
      let blankQuoteServices = _.filter(attrs.quoteServicesAttributes, this.isNestedQuoteServiceBlank)
      _.each(blankQuoteServices, (blankQuoteService) => {
        _.pull(attrs.quoteServicesAttributes, blankQuoteService)
      })

      let blankQuoteProducts = _.filter(attrs.quoteProductsAttributes, this.isNestedQuoteProductBlank)
      _.each(blankQuoteProducts, (blankQuoteProduct) => {
        _.pull(attrs.quoteProductsAttributes, blankQuoteProduct)
      })


      // e agora reformamos de volta para snake_case para enviar à API
      return _.snakeizeKeys(attrs)
    },


    // @override FetchMixin
    fetchRequest() {
      return this.$newSdk.quotes.find({ quoteId: this.quoteId })
    },

    // @override Fetch mixin
    onFetchSuccess({ data }) {
      // XXX: ignorando dados $discarded (remoção lógica).
      // eles só são tratados na ferramenta de Revisão de Orçamento!
      data.quoteProducts = data.quoteProducts.filter(item => item.$kept)
      data.quoteServices = data.quoteServices.filter(item => item.$kept)

      this.resource = data
      this.initialSerializedAttributes = this.serializeAttributes()
    },

    // @override Form mixin
    submitRequest() {
      let params = this.serializeAttributes()

      return this.$newSdk.quotes.update({ quoteId: this.quoteId, params })
    },

    // @override Form mixin
    onSubmitSuccess(response) {
      // XXX: usando id do Vetor (remoteId) para ficar coerente com a listagem
      let successMessage = this.$t(".notifications.submit.success", { id: this.quote.serviceOrder.remoteId })
      this.$notifications.info(successMessage)
    },

    // @override Form mixin
    afterSubmitSuccess({ approve }) {
      if (approve) this.showApprovalModal = true
      else this.fetch()
    },


    save() {
      this.submit({ approve: false })
    },

    openApprovalModal() {
      this.submit({ approve: true })
    }

  } // methods

}

</script>

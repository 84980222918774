import Base from "./base"

class ServiceOrderSupplier extends Base {
  static get attrs() {
    return _.uniq([
      ...super.attrs,
      "supplierId",
      "elapsedTime",
      "capacity",
      "price",
      "distance",
      "score",
      "supplierAvailableAt",
      "pickupService",
      "rabbotPlataform"
    ])
  }

  static get relations() {
    return {
      supplier: { type: "belongsTo", model: "Supplier" }
    }
  }
}

export default ServiceOrderSupplier

<style lang="scss" scoped>

.service-option {
  cursor: pointer;

  min-height: initial;
  line-height: initial;

  &.selected {
    padding: 0;

    .service-option-container {
      border: none;
    }
  }

  &.active {
    color: $primary;
    background: $light-gray-2;
  }

  &.focus {
    background: $light-gray-2;
    color: $dark-primary-2;

    .service-name {
      color: $primary;
    }

    .remove-icon-content {
      background: $light-gray-3;
      color: $primary;
    }
  }
}

.remove-icon-content {
  margin-left: 12px;
  width: 24px;
  height: 24px;
  line-height: 24px;
  text-align: center;
  border-radius: 50px;
  color: $gray-2;
  transition: all 100ms linear;
  flex-shrink: 0;
}

.service-option-container {
  padding: 0 24px;
  min-height: 68px;
  border-top: 1px solid $light-gray-3;
  border-bottom: 1px solid $light-gray-3;
}

.service-option-container + .service-option-container {
  border-top: 1px solid transparent;
}

.content-column {
  flex-grow: 1;
  overflow: hidden;
}

.content {
  overflow: hidden;
  margin-right: 12px;
  padding: 8px 0;
}

.description {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
}

.service-name {
  font-size: 18px;
  font-weight: normal;
  color: $gray-3;
  word-break: normal;
  overflow-wrap: break-word;
  margin-bottom: 4px;
  display: block;

  &:hover {
    color: $primary;
  }
}

.tree-icon {
  padding: 14px;
  cursor: pointer;
  font-weight: 900;
  width: 20px;
  height: 20px;
  margin-right: 14px;
  font-size: 14px;
  transition: transform .1s ease;

  &.leaf {
    font-size: 16px;
    font-weight: 300;
    cursor: unset;
  }

  &.open {
    transform: rotate(90deg);
  }
}

.fetching {
  color: $gray-3;
  cursor: progress;
}

.price-column {
  flex-shrink: 0;
  display: block;
  font-size: 14px;
  font-weight: normal;
  color: $dark-gray;
  height: 100%;

  .target-icon {
    font-size: 18px;
    margin-right: 8px;
    color: $success;
  }
}

.icon-column {
  margin-right: 12px;
}

.details > span {
  font-size: 12px;
}


</style>


<template lang="pug">

  .service-option(:class="{ active, focus, selected }")
    .service-option-container.flex.vertical-center
      .icon-column.flex.center.vertical-center
        service-type-icon(:type="service.type")

      .content-column.flex.vertical-center
        .content
          .upper-row.flex.vertical-center
            label.section.service-name {{ service.name }}

            service-kind-icon(:kind="service.kind")
          .details.flex.vertical-center(v-if="service.description")
            span.description {{ service.description }}

      .remove-icon-content(v-if="active")
        i.fal.fa-times

</template>


<script>

export default {
  name: "ServiceOption",

  props: {
    option:   { type: Object, required: true },
    active:   { type: Boolean, default: false },
    focus:    { type: Boolean, default: false },
    selected: { type: Boolean, default: false }
  },

  data() {
    return {
      i18nScope:   "service-packages.components.service-option",
      showPopover: false
    }
  },

  computed: {
    service() {
      return this.option.data
    }
  }
}

</script>

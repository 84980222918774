import Base from "./Base"

class ServiceOrders extends Base {
  getConfirmationByToken({ token, params = {} }) {
    if (_.blank(token)) {
      throw new Error('SDK ServiceOrders - #getConfirmationByToken() - "token" is a required prop')
    }

    return this.action({
      method: "get",
      url:    `/service_orders/${token}/confirmation`,
      params
    })
  }

  confirm({ token, params = {} }) {
    if (_.blank(token)) {
      throw new Error('SDK ServiceOrders - #confirm() - "token" is a required prop')
    }

    return this.action({
      method: "post",
      url:    `/service_orders/${token}/confirmation`,
      params
    })
  }

  lastSAPSync({ id, params = {} } = {}) {
    if (_.blank(id)) {
      throw new Error('SDK ServiceOrders - #lastSAPSync() - "id" is a required prop')
    }

    return this.action({
      method: "get",
      url:    `/service_orders/${id}/sap_sync`,
      params
    })
  }

  syncSAP({ id, params = {} } = {}) {
    if (_.blank(id)) {
      throw new Error('SDK ServiceOrders - #syncSAP() - "id" is a required prop')
    }

    return this.action({
      method: "post",
      url:    `/service_orders/${id}/sap_sync`,
      params
    })
  }
}

export default ServiceOrders

<style lang="scss" scoped>
.section {
  padding: 16px 24px;

  + .section {
    border-top: 1px solid $light-gray-3;
  }
}

.footer {
  display: flex;
  justify-content: space-between;
}
</style>

<template lang="pug">
  app-modal(
    :heading="$t('.heading')",
    :loading="submitting",
    :width="716",
    @close="onClose"
  )
    .reopen
      .section
        service-order-info(
          :branch="branch"
          :customer="customer"
          :service-order="serviceOrder"
          :supplier="supplier"
          :vehicle="vehicle"
        )

      .section
        textarea-field(
          v-model="reopening.description"
          :label="$t('.fields.description.label')"
          mandatory
          name="reopening[description]"
          :placeholder="$t('.fields.description.placeholder')"
        )

    template(#footer)
      .footer
        app-button(
          outline
          theme="gray"
          @click="onClose"
        ) {{ $t(".buttons.return.label") }}

        app-button(
          :disabled="!hasDescription"
          @click="submit"
        ) {{ $t(".buttons.confirm.label") }}
</template>

<script>
import models from "@/models"
import FormMixin from "@/mixins/form-mixin"

import ServiceOrderInfo from "./service-order-info.vue"

const { ServiceOrderReopening } = models

export default {
  components: {
    ServiceOrderInfo
  },

  mixins: [FormMixin],

  props: {
    serviceOrder: { type: Object, required: true }
  },

  data() {
    return {
      i18nScope: "monitoring.service-order-reopen-modal",

      // @Override FormMixin
      resource: new ServiceOrderReopening()
    }
  },

  computed: {
    branch() {
      return this.serviceOrder.branch
    },

    customer() {
      return this.serviceOrder.customer
    },

    hasDescription() {
      return _.present(this.reopening.description)
    },

    reopening: {
      get()      { return this.resource },
      set(value) { this.resource = value }
    },

    supplier() {
      return this.serviceOrder.supplier
    },

    vehicle() {
      return this.serviceOrder.vehicle
    }
  },

  mounted() {
    this.initialSerializedAttributes = this.serializeAttributes()
  },

  methods: {
    // @Override FormMixin
    afterSubmitSuccess() {
      this.$emit("update")
      this.$emit("close")
    },

    async onClose() {
      const isDirty = this.checkDirty()

      if (!isDirty || await this.askDirtyLeaveConfirmation()) this.$emit("close")
    },

    // @Override FormMixin
    serializeAttributes() {
      return this.resource.$serialize()
    },

    // @Override FormMixin
    submitRequest() {
      const params = this.serializeAttributes()

      return this.$newSdk.monitoring.serviceOrders.reopenings.create({
        serviceOrderId: this.serviceOrder.$id,
        params
      })
    }
  }
}
</script>

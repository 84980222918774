import Base from "../base"


class MovidaService extends Base {
  static get attrs() {
    return _.uniq([
      ...super.attrs,

      "sapId",
      "remoteId",
      "name",
      "discarded"
    ])
  }
}


export default MovidaService

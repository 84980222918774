<style scoped lang="scss">

.subtitle {
  font-weight: bold;
  font-size: 20px;
  font-family: $secondary-font;
  font-weight: 500;
}

.section-message {
  display: block;
  font-size: 16px;
  margin-bottom: 16px;
  line-height: 22px;
}

.section {
  border-bottom: 1px solid $light-gray-3;
  padding: 24px 0;
}

.row:not(:first-of-type) {
  padding-top: 24px;
}

</style>


<template lang="pug">

  .form-fields
    section.identification.section
      h2.subtitle {{ $t('.sections.identification.subtitle') }}
      span.section-message {{ $t('.sections.identification.message') }}

      ui-row.row
        ui-col(:span="9")
          mobiauto-version-select-field(
            name="vehicleModel[mobiautoVersion]",
            v-model="resource.mobiautoVersion",
            data-as-value,
            :loading="submitting",
            :disabled="edit || fetching",
            :errors="errors.mobiautoVersionId",
            :placeholder="$t('.fields.mobiautoVersion.placeholder')"
          )

      ui-row.row
        ui-col(:span="3")
          select-field(
            name="vehicleModel[year]",
            option-as-value,
            :loading="submitting",
            :disabled="fetching || !mobiautoVersion",
            :placeholder="$t('.fields.year.placeholder')",
            :errors="errors.year",
            v-model="yearOption",
            :options="yearOptions",
            searchable
          )
        ui-col(:span="3")
          vehicle-model-category-select-field(
            name="vehicleModel[category]",
            :loading="submitting",
            :disabled="fetching",
            :placeholder="$t('.fields.category.placeholder')",
            :errors="errors.category",
            v-model="resource.category",
            searchable
          )
        ui-col(:span="3")
          vehicle-model-type-select-field(
            name="vehicleModel[type]",
            :loading="submitting",
            :disabled="fetching",
            :placeholder="$t('.fields.type.placeholder')",
            :errors="errors.type",
            :value="resource.type",
            @input="option => $emit('select-type', option)",
            searchable
          )

    template(v-if="showCombustionFields")
      combustion-form-fields.fuel-and-oil.section(
        :fetching="fetching",
        :submitting="submitting",
        :errors="errors",
        :resource="resource"
      )

    template(v-if="showElectricFields")
      electric-form-fields.electric.section(
        :fetching="fetching",
        :submitting="submitting",
        :errors="errors",
        :resource="resource"
      )

    //- XXX: tornando pneu specs opcional
    //- ref: https://github.com/caiena/movida-fornecedores/issues/1191
    //- estamos removendo do formulário por ora!
    //- section.tire-specs.section
    //-   h2.subtitle {{ $t('.sections.tire.subtitle') }}
    //-   span.section-message {{ $t('.sections.tire.message') }}

    //-   tire-specification-fields(
    //-     :resource="resource.tireSpecification",
    //-     :fetching="fetching",
    //-     :submitting="submitting",
    //-     :errors="errors.tireSpecificationAttributes"
    //-   )

    //-   spare-tire-specification-fields(
    //-     v-if="showSpareTireSpecification",
    //-     :resource="resource.spareTireSpecification",
    //-     :fetching="fetching",
    //-     :submitting="submitting",
    //-     :errors="errors.spareTireSpecificationAttributes"
    //-   )

</template>


<script>

// Form Fields
import CombustionFormFields from "./form-fields/combustion-form-fields.vue"
import ElectricFormFields from "./form-fields/electric-form-fields.vue"

// Local Components
import MobiautoVersionSelectField from "./form-fields/mobiauto-version-select-field.vue"
import TireSpecificationFields from "./tire-specification-fields.vue"
import SpareTireSpecificationFields from "./spare-tire-specification-fields.vue"
import vehicleModelCategorySelectField from "../components/vehicle-model-category-select-field.vue"
import VehicleModelTypeSelectField from "../components/vehicle-model-type-select-field.vue"

export default {
  components: {
    CombustionFormFields,
    ElectricFormFields,

    MobiautoVersionSelectField,
    TireSpecificationFields,
    SpareTireSpecificationFields,
    vehicleModelCategorySelectField,
    VehicleModelTypeSelectField
  },

  props: {
    fetching:   { type: Boolean, default: false },
    submitting: { type: Boolean, default: false },
    errors:     { type: Object, default: () => ({}) },
    resource:   { type: Object, default: () => ({}) },
    edit:       { type: Boolean, default: false }
  },

  data() {
    return {
      i18nScope: "vehicle-models.forms.form-fields",

      yearOption: null
    }
  },

  computed: {
    showCombustionFields() {
      return ["VehicleModels::Combustion", "VehicleModels::Hybrid"].includes(this.resource.type)
    },

    showElectricFields() {
      return ["VehicleModels::Electric", "VehicleModels::Hybrid"].includes(this.resource.type)
    },

    yearOptions() {
      if (!this.mobiautoVersion) return []

      const initialYear = this.mobiautoVersion.initialYear || 1900
      const finalYear = this.mobiautoVersion.finalYear || new Date().getFullYear() + 5

      let options = []
      for (let i = initialYear; i <= finalYear; i++) {
        options.push({
          label: i,
          value: i
        })
      }

      if (options.length === 1) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties, prefer-destructuring
        this.yearOption = options[0]
      }

      return options
    },

    mobiautoVersion() {
      return this.resource.mobiautoVersion || null
    },

    showSpareTireSpecification() {
      let tireKind = _.get(this.resource, "tireSpecification.kind")

      return _.present(tireKind) && (tireKind !== "run_flat")
    }
  },

  watch: {
    fetching() {
      if (this.fetching === false) {
        this.yearOption = {
          label: this.resource.year,
          value: this.resource.year
        }
      }
    },

    "resource.year": function year(value) {
      if (_.blank(value)) this.yearOption = null
    },

    yearOption() {
      if (this.resource.year !== _.get(this.yearOption, "value")) {
        // eslint-disable-next-line vue/no-mutating-props
        this.resource.year = _.get(this.yearOption, "value")
      }
    }
  }
}

</script>

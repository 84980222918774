<style lang="scss" scoped>

.supplier-service-score-settings {
  .header {
    margin-bottom: 24px;

    .title {
      font-family: $secondary-font;
      font-size: 20px;
      font-weight: 500;
    }

    .actions {
      .icon {
        font-size: 16px;
        margin-right: 8px;
      }
    }
  }

  .content {
    .info {
      ::v-deep .movida-common__info-label-loading {
        width: 300px;
      }

      + .info {
        margin-top: 16px;
      }
    }
  }
}

</style>


<template lang="pug">

  .supplier-service-score-settings
    section.header
      .flex.space-between
        h1.title {{ $t('.title') }}

        .actions
          app-button.btn(
            v-if="$can('update', 'SupplierServiceScoreSettings')",
            theme="neutral",
            size="small",
            :to="{ name: 'editSupplierServiceScoreSettings' }"
          )
            i.fal.fa-pencil.icon
            span {{ $t('.btn.edit') }}

    section.content
      info-label.info(
        :label="$t('models.supplierServiceScoreSettings.attributes.priceCoefficient')",
        :value="settings.priceCoefficient",
        :loading="fetching",
        data-testid="priceCoefficient"
      )
      info-label.info(
        :label="$t('models.supplierServiceScoreSettings.attributes.timeCoefficient')",
        :value="settings.timeCoefficient",
        :loading="fetching",
        data-testid="timeCoefficient"
      )
      info-label.info(
        :label="$t('models.supplierServiceScoreSettings.attributes.distanceCoefficient')",
        :value="settings.distanceCoefficient",
        :loading="fetching",
        data-testid="distanceCoefficient"
      )
      info-label.info(
        :label="$t('models.supplierServiceScoreSettings.attributes.capacityCoefficient')",
        :value="settings.capacityCoefficient",
        :loading="fetching",
        data-testid="capacityCoefficient"
      )
      info-label.info(
        :label="$t('models.supplierServiceScoreSettings.attributes.distanceStrategy')",
        :value="SupplierServiceScoreSettings.$tEnum('distanceStrategy', settings.distanceStrategy)",
        :loading="fetching",
        data-testid="distanceStrategy"
      )

</template>


<script>

// View
import LoadedView from "@/views/loaded-view"

// Mixins
import FetchMixin from "@/mixins/fetch-mixin"

// Models
import models from "@/models"
const { SupplierServiceScoreSettings } = models

export default {
  name: "SupplierServiceScoreSettings",

  extends: LoadedView,

  mixins: [FetchMixin],

  data() {
    return {
      i18nScope: "settings.supplier-service-score.show",
      routeName: "supplierServiceScoreSettings",

      resource: {},

      SupplierServiceScoreSettings
    }
  },

  computed: {
    settings: {
      get() { return this.resource },

      set(value) { this.resource = value }
    }
  },

  methods: {
    parseRoute() {},

    // @override Fetch Mixin
    fetchRequest() {
      return this.$newSdk.settings.getSupplierServiceScoreSettings()
    }
  }
}

</script>

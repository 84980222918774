<!--
<doc lang="markdown">
Base para _views_ do tipo _index_.

Define valores e comportamentos básicos ligados a paginação, além de leitura e atualização
de parâmetros de query na URL e enviados ao servidor

Como usar:
```pug
  // View
  import IndexView from '@/views/index-view'

  ...

  extends: IndexView
```
</doc>
-->

<script>

// Libs
import parseLinkHeaders from "parse-link-header"

// Parent
import LoadedView from "./loaded-view"

export default {
  extends: LoadedView,

  data() {
    return {
      // Mantém dados de paginação: página atual, resultados por página e total de resultados
      pagination: {},

      // Declara quais são os parâmetros utilizados na página e os seus tipos. Deve ser declarado no componente
      // queryParamsStructure: {},

      // Objeto de trabalho contendo os parâmetros de URL após _parsing_. Atualizações devem ser feitas nesse objeto
      parsedQueryParams: {},

      // Mantém a query atual para utilização em links de páginas aninhadas que voltem à essa página
      currentRouteQuery: this.$route.query,

      // Lista de parâmetros de query que devem ser ignorados no mecanismo de limpeza de filtros, e.g. filtros por abas
      clearFiltersIgnoreList: [],

      // Valores padrão de query, e.g. ordenação
      defaultQuery: {}
    }
  },

  computed: {
    hasFilters() {
      const filterList = Object.keys(_.omit(this.queryParamsStructure, ["page", "sort", ...this.clearFiltersIgnoreList]))
      const currentParams = _.pick(this.$route.query, filterList)

      return _.present(currentParams)
    },

    showAppListHeader() {
      return this.hasFilters || !this.empty
    },

    showPaginator() {
      if (!this.pagination) return false

      return this.pagination.total > this.pagination.perPage
    },

    // Retorna os parâmetros de busca em formato canônico snake_case, como é utilizado no backend e na URL
    requestQueryParams() {
      return this.$params.toRouteParams(this.parsedQueryParams)
    }
  },

  created() {
    if (_.present(this.defaultQuery)) {
      const query = { ...this.defaultQuery, ...this.$route.query }
      if (!_.isEqual(query, this.$route.query)) this.$router.replace({ query })
    }

    if (!this.queryParamsStructure) throw new Error('Index View - #created() - "queryParamsStructure" is required')

    this.parseQuery()
  },

  methods: {
    parseRouteAndFetch() {
      this.parseRoute()
      this.parseQuery()
      this.fetch()
    },

    // Realiza a leitura de parâmetros de query
    parseQuery() {
      let parsedParams = this.$params.parse(this.$route.query, this.queryParamsStructure)

      this.parsedQueryParams = _.merge(...Object.keys(parsedParams).map(param => ({
        [_.camelCase(param)]: parsedParams[param]
      })))
    },

    search() {
      // XXX Ao realizar uma nova busca, precisamos voltar para a primeira página
      // passando undefined para causar detecção no Vue
      this.parsedQueryParams.page = undefined

      this.updateUrlQuery()
    },

    onPageChange(page) {
      this.onParamChange("page", page)
    },

    onSortChange(sort) {
      this.onParamChange("sort", sort)
    },

    onParamChange(param, value) {
      this.parsedQueryParams.page = undefined
      this.parsedQueryParams[param] = value

      this.updateUrlQuery()
    },

    updateUrlQuery() {

      let parsedParams = this.$params.parse(this.$route.query, this.queryParamsStructure)
      const currentQuery = this.$params.toRouteParams(parsedParams)
      const differentPath = !_.isEqual(currentQuery, this.requestQueryParams)

      if (differentPath) {
        const routerParams = { hash: this.$route.hash, query: this.requestQueryParams }
        this.$router.push(routerParams)
        this.currentRouteQuery = this.$route.query
      }
      else this.fetch()
    },

    getResetFilters() {
      return _.deleteBlanks(
        _.pick(this.parsedQueryParams, ["sort", ...this.clearFiltersIgnoreList])
      )
    },

    clearFilters() {
      this.parsedQueryParams = this.getResetFilters()
      this.updateUrlQuery()
    },

    setPagination({ link, "x-total": total, "x-per-page": perPage }) {
      let pagination = _.present(link) ? parseLinkHeaders(link) : {}
      pagination.total = this.$params.asInteger(total)
      pagination.perPage = this.$params.asInteger(perPage)

      if (_.blank(pagination.total) || _.blank(pagination.perPage)) return

      this.pagination = {
        ...pagination,
        first: this.$params.asInteger(_.get(pagination, "first.page")),
        prev:  this.$params.asInteger(_.get(pagination, "prev.page")),
        next:  this.$params.asInteger(_.get(pagination, "next.page")),
        last:  this.$params.asInteger(_.get(pagination, "last.page"))
      }
    }
  }
}

</script>

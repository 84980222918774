<style lang="scss" scoped>

.timeline-popover-open {
  padding: 16px;
  width: 340px;

  &.large {
    width: 500px;
  }

  > .loading {
    width: 100%;
  }

  > .erred {
    font-size: 16px;
  }

  > .content {
    font-size: 14px;
    line-height: 150%;
    font-family: $secondary-font;

    .title {
      font-size: 16px;
      font-weight: 500;
      color: $dark-gray;
    }

    .subtitle {
      font-family: $primary-font;
      font-size: 14px;
      font-weight: 300;
      color: $dark-gray;
    }

    .info {
      margin-top: 8px;
    }
  }

  .empty {
    .empty-message {
      font-weight: 400;
      font-style: italic;
      font-size: 14px;
      color: $gray-3;
    }
  }
}


.icon-container {
  width: 16px;
  height: 16px;
  margin-right: 8px;

  .icon {
    font-size: 12px;
    color: $gray-3;

    &.warning {
      color: $dark-warning;
    }
  }

  &.main {
    width: 24px;
    height: 24px;
    margin-right: 16px;

    .icon {
      font-size: 20px;
      color: $purple;
    }

    &.empty {
      .icon {
        color: $gray-2;
      }
    }
  }
}

</style>


<template lang="pug">

  .timeline-popover-open(:class="{ large: hasDescription }")
    template(v-if="fetching")
      .loading
        loading-lines(:lines="3")

    template(v-else-if="erred")
      .erred.flex.column-direction.vertical-center
        span.text-center {{ $t(".fetch-error.message") }}
        app-button.retry(theme="link", @click="$fetch") {{ $t(".fetch-error.retry") }}

    template(v-else-if="hasResource")
      .content.flex
        .icon-container.main.flex.center.vertical-center
          i.icon.fas.fa-file-invoice-dollar

        .data.full-width.flex.column-direction
          span.title {{ $t(".title", { at: $fromNow(serviceOrder.issuedAt) }) }}
          span.subtitle {{ $l("time.formats.short", serviceOrder.issuedAt) }}

          //- icon + label
          .info.flex.vertical-baseline
            .icon-container.flex.center.vertical-center
              i.icon.fas.fa-clock
            span {{ scheduledAtText }}

          .info.flex.vertical-baseline
            .icon-container.flex.center.vertical-center(v-tooltip="$t('.fields.description.tooltip')")
              i.icon.fas.fa-message-lines
            span.preserve-whitespace(:class="{ italic: !hasDescription }") {{ descriptionText }}

    template(v-else)
      .empty.flex.vertical-center
        .icon-container.main.empty.flex.center.vertical-center
          i.icon.fas.fa-file-invoice-dollar

        .content.full-width
          span.empty-message {{ $t(".empty") }}

</template>


<script>

// Mixins
import FetchMixin from "@/mixins/fetch-mixin"


export default {
  components: {},

  mixins: [FetchMixin],

  props: {
    serviceOrderRid: { type: [Number, String], required: true },
    show:            { type: Boolean, required: true }
  },

  data() {
    return {
      i18nScope: "monitoring.timeline-popover-open",
      resource:  {},
      autofetch: false
    }
  },

  computed: {
    serviceOrder: {
      get()      { return this.resource },
      set(value) { this.resource = value }
    },

    hasResource() {
      return _.present(this.resource)
    },

    hasDescription() {
      return this.serviceOrder && (
        _.present(this.serviceOrder.ticketDescription)
        || _.present(this.serviceOrder.description)
      )
    },

    descriptionText() {
      const { description, ticketDescription } = this.serviceOrder

      return this.hasDescription
        ? ticketDescription || description
        : this.$t(".fields.description.empty")
    },

    scheduledAtText() {
      return this.serviceOrder.$present("scheduledAt")
        ? this.$t(".fields.scheduledAt.text", { at: this.$l("time.formats.short", this.serviceOrder.scheduledAt) })
        : this.$t(".fields.scheduledAt.empty")
    }
  },

  watch: {
    show: {
      immediate: true,
      handler() {
        if (this.show && _.blank(this.resource)) this.fetch()
      }
    }
  },

  methods: {
    // @override Fetchable mixin
    fetchRequest() {
      return this.$newSdk.monitoring.serviceOrders
        .find(this.serviceOrderRid)
    },

    // @override Fetchable mixin
    onFetchError(err) {
      const error = err.error || err

      // XXX: Pode não haver dados registrados
      if (error.cancelled || error.status === 404) return

      this.fetchError =  err
      this.erred = true

      this.$err.log(err)
      this.$notifications.error(this.$t(".notifications.fetch.failure"))
    }
  }
}

</script>

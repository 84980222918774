<style lang="scss" scoped>

.vehicle-issue-row {
  display: grid;
  grid-template-columns: 70px 1fr 1fr;

  &.loading {
    .column {
      color: $gray-2;

      ::v-deep.id-tag {
        color: $gray-2;
      }
    }
  }

  .column {
    padding: 0 12px;
    border-bottom: 1px solid $light-gray-3;
    height: 56px;
    overflow: hidden;

    &.content-column {
      overflow: hidden;

      .title {
        font-family: $secondary-font;
        font-size: 14px;
        font-weight: 400;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .id-tag {
        margin-left: 4px;
        font-size: 14px;
      }

      .description {
        .label {
          font-weight: 400;
          font-size: 12px;
          color: $gray-3;
        }
      }

      .icon {
        margin-right: 8px;
      }

      .vehicle-part-icon {
        mask-image: var(--svg-icon);
        mask-repeat: no-repeat;
        height: 16px;
        width: 16px;
        background-color: $dark-gray;

        &.loading {
          background-color: $gray-2;
        }
      }
    }
  }
}

</style>


<template lang="pug">

  .vehicle-issue-row(:class="{ loading }")
    .column.checkbox-column.flex.vertical-center
      checkbox-field(
        :name="`vehicleIsue-${vehicleIssue.id}`",
        :disabled="loading",
        hide-value-text,
        :value="selected",
        @input="(value) => $emit('select', { id: vehicleIssue.id, value })"
      )

    .column.content-column.flex.vertical-center
      .icon-wrapper
        .vehicle-part-icon.icon(
          :style="vehiclePartIconStyle"
          :class="{ loading }"
        )
      .content
        span.title {{ vehiclePartName }}
        id-tag.id-tag(:value="vehiclePart.id")

        .description
          span.label  {{ vehiclePart.description }}

    .column.content-column.flex.vertical-center
      .icon-wrapper
        i.icon.fal.fa-engine-warning
      .content
        span.title {{ symptomName }}
        .description
          span.label {{ symptom.description }}

</template>


<script>

// Extends
import VehicleIssueRow from "@/views/services/vehicle-issues/components/vehicle-issue-row.vue"

export default {
  name: "VehicleIssueRow",

  extends: VehicleIssueRow,

  props: {
    vehicleIssue: { type: Object, default: () => {} },
    selected:     { type: Boolean, default: false },
    loading:      { type: Boolean, default: false }
  },

  data() {
    return {
      i18nScope: "services.components.vehicle-issues.vehicle-issue-row"
    }
  },

  computed: {
    vehiclePart() {
      return _.get(this.vehicleIssue, "vehiclePart")
    },

    symptom() {
      return _.get(this.vehicleIssue, "symptom")
    },

    vehiclePartIconStyle() {
      return {
        "--svg-icon": `url(${this.vehiclePartIcon})`
      }
    }
  }
}

</script>

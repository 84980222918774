<template lang="pug">

  app-modal(:width="1156")
    attachment-preview-data(
      :attachment="attachment",
      :editable="editable",
      :editing="editing",
      :local-edit="localEdit",
      @close="$emit('close')",
      @download="$emit('download')",
      @update:attachment="value => $emit('update:attachment', value)",
      @update:editing="value => $emit('update:editing', value)"
    )

</template>


<script>

export default {
  name: "AttachmentPreview",

  props: {
    attachment: { type: Object, required: true },
    editing:    { type: Boolean, default: false },
    editable:   { type: Boolean, default: false },
    localEdit:  { type: Boolean, default: false }
  },

  data() {
    return {
      i18nScope: "components.attachments.attachment-preview"
    }
  }
}

</script>

<style scoped lang="scss">

.header {
  margin-bottom: 32px;
  padding-bottom: 16px;
  border-bottom: 1px solid $light-gray-3;
}

.title-icon {
  margin-right: 16px;
  font-size: 30px;
}

.title-text {
  font-size: 30px;
  font-weight: normal;
  font-weight: 500;
  font-family: $secondary-font;
}

.search {
  margin-bottom: 28px;
}

.paginator-content {
  padding-top: 24px;
  margin: 0 auto;
  &.loading {
    max-width: 400px;
  }
}

.list-info {
  margin-bottom: 16px;
  padding: 0 4px;
}

.selected-info {
  border: 1px solid $light-purple-2;
  padding: 8px 16px;
  border-radius: 4px;
  margin-bottom: 16px;
}

.empty-selected {
  color: $gray-2;
  font-style: italic;
}

.list-header {
  padding: 12px 16px;

  .secondary-button {
    color: $gray-2;
    font-weight: normal;

    &.active {
      color: $dark-gray;
    }

    &:hover {
      color: $primary;
    }

    &:active {
      color: $dark-primary-2;
    }

    .secondary-text {
      font-size: 14px;
    }

    .icon-container {
      width: 20px;
      height: 20px;
      margin-right: 12px;

      .icon {
        font-size: 16px;
      }
    }
  }
}

.secondary-button + .secondary-button {
  margin-left: 24px;
}

.action + .action {
  margin-left: 16px;
}

.error-message {
  display: block;
  color: $error;
  font-size: 12px;
  line-height: 1;
  padding-bottom: 4px;
}

</style>


<template lang="pug">

  .vehicle-models-select-list
    search-form.search(
      :params="params",
      @search="search",
      :loading="_fetching || loading"
    )

    app-list-header.list-info(
      v-if="showAppListHeader"

      :has-filters="hasFilters",
      :page="params.page",
      :pagination="pagination",
      :loading="fetching",

      @clear="clearFilters"
    )

    .selected-info
      .flex.space-between.vertical-center
        label.total-selected(
          :class="{ 'empty-selected': !totalSelected }"
        ) {{ $t('.selectedInfo', { count: totalSelected }) }}

        .actions
          app-button.action(
            @click="toggleAllOnPage(false)",
            theme="link",
            size="small",
            :disabled="_fetching || loading",
          ) {{ $t('.btn.removeAllOnPage') }}

          app-button.action(
            @click="removeAll",
            theme="link",
            size="small",
            :disabled="_fetching || loading",
          ) {{ $t('.btn.removeAll') }}

          app-button.action(
            @click="toggleAllOnPage(true)",
            theme="link",
            size="small",
            :disabled="_fetching || loading",
          ) {{ $t('.btn.selectAllOnPage') }}

          app-button.action(
            @click="selectAll",
            theme="link",
            size="small",
            :disabled="_fetching || loading",
          ) {{ $t('.btn.selectAll') }}

    span.error-message(v-if="hasValidationError") {{ error }}

    app-list(
      :collection="resource",
      :loading="fetching",
      :empty="empty",
      :empty-text="$t('.empty')",
      :error-text="$t('.error')",
      :error="hasFetchError",
      :validation-error="hasValidationError",
      :content-height="tableHeight"
    )
      .list-header(slot="header")
        app-button.secondary-button(
          @click="showSelectedModelsFilter(false)",
          theme="link",
          size="small",
          :class="{ active: !showSelectedModels }",
          :disabled="_fetching || loading",
        )
          .icon-container.flex.center
            i.icon.fa-cars(:class="iconWeightClass(!showSelectedModels)")
          span {{ $t('.btn.allModels') }}

        app-button.secondary-button(
          @click="showSelectedModelsFilter(true)",
          theme="link",
          size="small",
          :class="{ active: showSelectedModels }",
          :disabled="_fetching || loading || !hasSelectedOptions",
        )
          .icon-container.flex.center
            i.icon.fa-car(:class="iconWeightClass(showSelectedModels)")
          span {{ $t('.btn.selectedModels') }}


      template(slot="loadingRow")
        mobiauto-version-row-loading(
          v-for="version in 4",
          :key="`mobiauto-version-row-loading-${version}`"
        )

      vehicle-models-select-list-row-fields(
        v-for="(vehicleModel, index) in resource",
        :key="`mobiauto-version-${vehicleModel.id}-${index}`",
        :vehicle-model="vehicleModel",
        :disabled="_fetching || loading",
        :loading="loading",
        :value="vehicleModelValue(vehicleModel.id)",
        :data-testid="`vehicleModel-${vehicleModel.id}`",
        @input="selectVehicleModel"
      )

    .paginator-content(v-if="fetching || showPaginator", :class="{ loading: fetching }")
      paginator(
        :loading="fetching",
        :per-page="pagination.perPage",
        :total="pagination.total",
        @input="onChangePage",
        :value="params.page",
        :disabled="fetchingIds || loading",
        data-test="paginator"
      )

</template>


<script>

// Mixins
import { mixins } from "movida-common.vue"

// Components
import SearchForm from "@/views/vehicle-models/index/search-form"

// Models
import models from "@/models"
const { VehicleModel } = models

const { Fetchable, Paginatable, UrlQuery } = mixins

export default {
  name: "VehicleModelsSelectList",

  components: {
    SearchForm
  },

  mixins: [Fetchable, Paginatable, UrlQuery],

  props: {
    value:   { type: Array, default: () => [] },
    loading: { type: Boolean, default: false },
    error:   { type: String, default: null }
  },

  data() {
    return {
      i18nScope: "components.vehicle-models-select-list",

      fetchingIds:        false,
      allModelsFilters:   false,
      showSelectedModels: false,

      // Fetchable mixin data
      resource: [],

      model:     null,
      autofetch: false,
      fetching:  false,

      // Query Mixin data
      parseQueryParams: {
        q:               "string",
        manufacturer_id: "integer",
        category:        "string",
        starting_year:   "integer",
        group_id:        "integer",
        page:            "integer",
        ids:             "array"
      }
    }
  },

  computed: {
    hasValidationError() {
      return _.present(this.error)
    },

    tableHeight() {
      return 500
    },

    totalSelected() {
      return this.value.length
    },

    showPaginator() {
      if (!this.pagination) return false
      return this.pagination.total > this.pagination.perPage
    },

    showAppListHeader() {
      return this.fetching || this.hasFilters || !this.empty
    },

    empty() {
      return _.blank(this.resource)
    },

    hasSelectedOptions() {
      return _.present(this.value)
    },

    hasFilters() {
      const filterList = Object.keys(_.omit(this.parseQueryParams, "page"))
      const currentParams = _.pick(this.$route.query, filterList)

      return _.present(currentParams)
    },

    _fetching() {
      return this.fetching || this.fetchingIds
    }
  },

  watch: {
    $route(to, from) {
      this.reload(to, from)
    }
  },

  created() {
    // TODO Extrair para Query mixin e entender
    // como executar antes do _fetch()
    this.parseAndFetch()
  },

  methods: {
    reload(to, from) {
      this.parseAndFetch()
    },

    iconWeightClass(active) {
      return active ? "fas" : "fal"
    },

    showSelectedModelsFilter(value) {
      this.params.ids = value ? this.value : []

      this.showSelectedModels = value
      this.search()
    },

    async fetchAllIds() {
      this.fetchingIds = true
      try {
        const response = await this.$sdk.vehicleModels.list({ params: { ...this.httpParams, partial: "ids" } })
        return response.data
      }

      finally {
        this.fetchingIds = false
      }
    },

    async selectAll() {
      try {
        const ids = await this.fetchAllIds()
        this.$emit("input", _.union(ids, this.value))
      }
      catch (error) {
        this.$err.log(error)
      }
    },

    async removeAll() {
      try {
        const ids = await this.fetchAllIds()
        const newValue = _.remove([...this.value], id => !ids.includes(id))
        this.$emit("input", newValue)
      }
      catch (error) {
        this.$err.log(error)
      }
    },

    toggleAllOnPage(value) {
      this.resource.forEach(vehicleModel => {
        this.selectVehicleModel({ id: vehicleModel.id, value })
      })
    },

    selectVehicleModel({ id, value }) {
      let array = this.value

      const hasId = array.findIndex(itemId => itemId === id)

      if (value && hasId < 0) {
        array.push(id)
      }
      else if (!value && hasId >= 0) {
        array.splice(hasId, 1)
      }

      this.$emit("input", array)
    },

    vehicleModelValue(id) {
      return this.value.includes(id)
    },

    clearFilters() {
      this.showSelectedModels = false
      this.$router.push({ name: this.routeName })
    },

    parseAndFetch() {
      this.parseRoute()
      this._fetch()
    },

    updateOrFetch() {
      this.updateUrlQuery()
      if (!this.queryChanged) {
        this._fetch()
      }
    },

    onChangePage(page) {
      this.params.page = page
      this.updateOrFetch()
    },

    search() {
      // XXX Ao realizar uma nova busca, precisamos voltar para a primeira página
      // XXX passando undefined para causar detecção no Vue
      this.params.page = undefined
      this.updateOrFetch()
    },

    fetch() {
      return this.$sdk.vehicleModels.list({ params: this.httpParams })
    },

    // @override Fetchable mixin
    onFetchSuccess(response) {
      this.setPagination(response.headers)

      this.resource = this.resource.map(data => VehicleModel.build(data))
    },

    // @override Fetchable mixin
    onFetchError(err) {
      this.$err.log(err)
      this.$notifications.error(this.$t(".notifications.fetch.failure"))
    }
  }
}

</script>

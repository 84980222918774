<template lang="pug">

  .edit
    form.form(ref="form", method="post", @submit.prevent="submit")
      ticket-details-step(
        :vehicle="vehicle",
        :resource="ticket",
        :errors="errors",
        edit,
        @submit="submit"
      )

</template>


<script>

// View
import BaseView from "@/views/base-view"

// Mixins
import FormMixin from "@/mixins/form-mixin"

// Components
import TicketDetailsStep from "./components/ticket-details-step.vue"

export default {
  name: "EditDraftTicketVehicle",

  components: {
    TicketDetailsStep
  },

  extends: BaseView,

  mixins: [FormMixin],

  props: {
    ticket: { type: Object, required: true }
  },

  data() {
    return {
      i18nScope: "tickets.drafts.vehicles.edit",
      routeName: "editDraftTicketVehicle",

      nestedAttributes: {
        serviceOrders: null
      }
    }
  },

  computed: {
    vehicle() {
      return _.get(this.ticket, "vehicle") || {}
    }
  },

  created() {
    this.initialSerializedAttributes = this.serializeAttributes()
  },

  methods: {
    // @override Form mixin
    serializeAttributes() {
      const serviceOrdersAttributes = this.ticket.serviceOrders.map(serviceOrder => serviceOrder.$serialize({ virtuals: true }))

      return {
        ...this.ticket.$serialize({ virtuals: true }),

        service_orders_attributes: serviceOrdersAttributes
      }
    },

    // @override Form mixin
    submitRequest() {
      return this.$newSdk.tickets.drafts.update({ ticketId: this.ticket.id, params: this.serializeAttributes() })
    },

    // @override Form mixin
    onSubmitSuccess({ data }) {
      this.$router.push({ name: "editDraftTicketContent", params: { id: this.ticket.id } })
    }
  }
}

</script>

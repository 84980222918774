// Base
import BaseRequests from "./Base"

// Models
import models from "@/models"
const { Store } = models

class StoresRequests extends BaseRequests {
  static get selector() {
    return "stores"
  }

  all({ params = {}, options = {} } = {}) {
    return this.action({
      method:  "get",
      url:     "/stores",
      params,
      options: {
        model: Store,

        ...options
      }
    })
  }

  find({ storeId, params = {}, options = {} } = {}) {
    this.verifyData("find", { storeId })

    return this.action({
      method:  "get",
      url:     `/stores/${storeId}`,
      params,
      options: {
        model: Store,

        ...options
      }
    })
  }
}

export default StoresRequests

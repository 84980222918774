const STATUS_CODES = {
  // Request errors
  400: "badRequest",
  401: "unauthorized",
  403: "forbidden",
  404: "notFound",
  405: "methodNotAllowed",
  406: "notAcceptable",
  407: "proxyAuthenticationRequired",
  408: "requestTimeout",
  409: "conflict",
  410: "gone",
  411: "lengthRequired",
  412: "preconditionFailed",
  413: "payloadTooLarge",
  414: "uriTooLong",
  415: "unsupportedMediaType",
  416: "requestedRangeNotSatisfiable",
  417: "expectationFailed",
  418: "imATeapot",
  421: "misdirectedRequest",
  422: "unprocessableEntity",
  424: "failedDependency",
  425: "tooEarly",
  426: "upgradeRequired",
  428: "preconditionRequired",
  429: "tooManyRequests",
  431: "requestHeaderFiledsTooLarge",
  451: "unavailableForLegalReasons",

  // Server errors
  500: "internalServer",
  501: "notImplemented",
  502: "badGateway",
  503: "serviceUnavailable",
  504: "gatewayTimeout",
  505: "httpVersionNotSupported",
  506: "variantAlsoNegotiates",
  507: "insufficientStorage",
  508: "loopDetected",
  510: "notExtended",
  511: "networkAuthenticationRequired"
}

export default STATUS_CODES

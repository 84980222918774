<style lang="scss" scoped>

.issue-solution-card {
  .error {
    .icon {
      font-size: 18px;

      &.spin {
        animation: fa-spin 0.6s infinite linear;
        cursor: wait;
      }
    }

    .message {
      font-style: italic;
      margin: 8px 0;
    }

    .reload {
      font-size: 14px;
    }
  }

  .card {
    background-color: $light-gray;
    border-radius: 8px;
    padding: 16px;

    .type-icon {
      margin-right: 16px;
    }

    .top {
      .name {
        font-family: $secondary-font;
        font-weight: 400;
        font-size: 20px;
        margin-right: 8px;
      }

      .description,
      .issues {
        font-size: 14px;
        color: $gray-3;
      }

      .issues {
        margin: 16px 0;
      }
    }

    .issue-row {
      font-family: $secondary-font;
      font-weight: 400;
      font-size: 18px;

      & + .issue-row {
        margin-top: 16px;
      }

      .index {
        width: 24px;
        height: 24px;
        font-size: 14px;
        font-family: $secondary-font;
        font-weight: 500;
        color: $gray-3;
        background-color: $white;
        border: 1px solid $light-gray-4;
        border-radius: 12px;
        margin-right: 16px;
      }

      .icon-container {
        --size: 24px;
        height: var(--size);
        width: var(--size);
        margin-right: 4px;
        --icon-color: #{$gray-2};

        &.symptom-icon {
          margin-left: 8px;
        }

        &.arrow-icon {
          margin: 0 8px;

          .icon {
            color: $dark-blue;
          }
        }

        .icon {
          color: var(--icon-color);
        }

        .vehicle-part-icon {
          mask-image: var(--svg-icon);
          mask-repeat: no-repeat;
          height: 16px;
          width: 16px;
          background-color: var(--icon-color);
        }
      }

      .diamond-icon {
        font-size: 4px;
        color: $gray-2;
        margin: 0 4px;
      }
    }
  }
}

</style>


<template lang="pug">

  .issue-solution-card
    template(v-if="erred")
      .error.flex.vertical-center.center.column-direction.no-shrink
        template(v-if="fetching")
          i.icon.fas.fa-spinner-third.spin
        template(v-else)
          i.icon.fas.fa-times

        span.message {{ $t('.fetch-erred.message') }}
        app-button.reload(theme="link", :loading="fetching", @click="fetch") {{ $t('.fetch-erred.retry') }}

    template(v-else-if="fetching")
      loading-lines.loading(:lines="5")

    template(v-else)
      .card
        .top.flex
          service-type-icon.type-icon(:type="service.type")

          .flex.column-direction
            .flex.vertical-center
              span.name {{ service.name }}
              id-tag(:value="service.id")

            template(v-if="service.description")
              span.description {{ service.description }}

            template(v-if="hasTargets")
              span.issues {{ $t('.issues') }}

        template(v-if="hasTargets")
          template(v-for="(target, index) in targets")
            .issue-row.flex.vertical-center(:key="`target-${index}`")
              .index.flex.center.vertical-center
                span {{ index + 1 }}

              .icon-container.flex.center.vertical-center
                .vehicle-part-icon.icon(:style="vehiclePartIconStyle")
              span {{ vehiclePart.name }}

              .icon-container.symptom-icon.flex.center.vertical-center
                i.icon.fas.fa-engine-warning
              span {{ symptom.name }}

              .icon-container.arrow-icon.flex.center.vertical-center
                i.icon.far.fa-arrow-right
              span {{ VehiclePartTarget.$tEnum('position', target.position) }}
              i.diamond-icon.fas.fa-diamond
              span {{ VehiclePartTarget.$tEnum('location', target.location) }}

</template>


<script>

// Assets
import vehiclePartIcon from "@/assets/images/icons/sidebar/vehicle-part-icon-solid.svg"

// Mixins
import FetchMixin from "@/mixins/fetch-mixin"

// Models
import models from "@/models"

const { VehiclePartTarget } = models

export default {
  mixins: [FetchMixin],

  props: {
    vehicleIssueSolutionId: { type: [String, Number], required: true },
    targets:                { type: Array, default: () => [] }
  },

  data() {
    return {
      i18nScope: "tickets.drafts.contents.services.show.issue-solution-card",

      resource: {},

      vehiclePartIcon,

      VehiclePartTarget
    }
  },

  computed: {
    hasTargets() {
      return _.present(this.targets)
    },

    service() {
      return _.get(this.resource, "service")
    },

    vehicleIssue() {
      return _.get(this.resource, "vehicleIssue")
    },

    vehiclePart() {
      return _.get(this.vehicleIssue, "vehiclePart") || {}
    },

    symptom() {
      return _.get(this.vehicleIssue, "symptom") || {}
    },

    vehiclePartIconStyle() {
      return {
        "--svg-icon": `url(${vehiclePartIcon})`
      }
    }
  },

  methods: {
    // @override Fetch mixin
    fetchRequest() {
      return this.$newSdk.vehicleIssues.solutions.find({ vehicleIssueSolutionId: this.vehicleIssueSolutionId })
    }
  }
}

</script>

<!--
# QuickFilterButton
Botão com ícone e tooltip

## Props
- tooltip: {string|object} texto do tooltip

## Slots
- icon
- default

## Uso
```pug
  quick-filter-button(@click="onClick" tooltip="Click to run action")
    template(#icon)
      i.fal.fa-square-check
    span Click me!
```
-->

<style lang="scss" scoped>
.quick-filter-button {
  --radius: 4px;
  align-items: center;
  background: transparent;
  border: 1px solid $light-gray-4;
  color: $dark-gray;
  cursor: pointer;
  display: flex;
  font-family: $secondary-font;
  font-size: 14px;
  font-weight: 500;
  height: 40px;
  line-height: 1.5;
  padding: 10px 18px;

  &:not(:first-child) {
    border-left: none;
  }

  &:first-child {
    border-bottom-left-radius: var(--radius);
    border-top-left-radius: var(--radius);
  }

  &:last-child {
    border-bottom-right-radius: var(--radius);
    border-top-right-radius: var(--radius);
  }

  &:focus,
  &:hover,
  &:active {
    border-color: $primary;
    color: $primary;
    outline: 1px solid $primary;
    outline-offset: -1px;
  }

  &:active {
    color: $dark-primary-2;
  }

  &:disabled {
    border-color: transparent;
    color: $gray;
    cursor: not-allowed;
  }

  .icon {
    font-size: 16px;
    margin-right: 8px;
  }

  .quick-filter-tooltip {
    margin-left: 8px;
  }
}
</style>

<template lang="pug">
  button(v-on="$listeners", type="button").quick-filter-button
    template(v-if="'icon' in $slots")
      span.icon
        slot(name="icon")
    slot
    template(v-if="tooltip")
      quick-filter-tooltip(v-bind="tooltip")
</template>

<script>
import QuickFilterTooltip from "./quick-filter-tooltip.vue"

export default {
  components: {
    QuickFilterTooltip
  },

  props: {
    tooltip: { type: [String, Object], default: null }
  }
}
</script>

<template lang="pug">

  index-table.suppliers-table(
    :empty="empty",
    :initializing="initializing",
    :cols-count="7",
    :column-sizes="columnSizes"
  )
    template(#tableHeader)
      suppliers-table-header(
        :loading="loading",
        @sort="param => $emit('sort', param)"
        :sort-param="sortParam",
        :sort-order="sortOrder",
        :column-sizes="columnSizes",
        data-testid="suppliersTableHeader"
      )

    template(#tableRows)
      supplier-row(
        v-for="supplier in suppliers"
        :key="supplier.id",
        :supplier="supplier",
        :loading="loading",
        :column-sizes="columnSizes"
        @destroy="$emit('changed')",
        @disabled="$emit('changed')",
        @enabled="$emit('changed')",
        @modified="$emit('changed')",
        @change="$emit('changed')",
        data-testid="supplierRow"
      )
        template(v-slot:actions-menu="{ supplier }")
          popover-menu-button(
            v-if="$can('read', 'Supplier')",
            icon="fal fa-eye",
            :to="{ name: 'supplier', params: { id: supplier.id }}",
            :text="$t('.actions.btn.show.label')",
            @close-menu="showPopover = false"
          )

          destroy-action.destroy-button.popover-menu-button(
            :store-supplier-id="supplier.storeSupplierId",
            :store-name="storeName"
            :supplier-name="supplier.name",
            @destroy="$emit('destroy')",
            data-test="destroyAction"
          )
            .icon-content
              i.icon.fal.fa-trash-alt
            span {{ $t('.actions.btn.destroy.label') }}

</template>


<script>

// Components
import SupplierRow from "./supplier-row.vue"
import SuppliersTable from "@/views/suppliers/index/suppliers-table.vue"
import DestroyAction from "../components/destroy-action.vue"

export default {
  components: {
    SupplierRow,
    DestroyAction
  },

  extends: SuppliersTable,

  props: { storeName: { type: String, required: true } },

  data() {
    return {
      i18nScope: "stores.suppliers.index.suppliers-table"
    }
  }
}

</script>

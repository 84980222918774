// Base
import BaseRequests from "../Base"

// Models
import models from "@/models"
const { VehicleIssueSolution } = models

class SolutionsRequests extends BaseRequests {
  static get selector() {
    return "vehicleIssues.solutions"
  }

  all({ vehicleIssueId, params = {}, options = {} } = {}) {
    this.verifyData("all", { vehicleIssueId })

    return this.action({
      method:  "get",
      url:     `/vehicle_issues/${vehicleIssueId}/vehicle_issue_solutions`,
      params,
      options: {
        model: VehicleIssueSolution,

        ...options
      }
    })
  }

  find({ vehicleIssueSolutionId, params = {}, options = {} } = {}) {
    this.verifyData("find", { vehicleIssueSolutionId })

    return this.action({
      method:  "get",
      url:     `/vehicle_issue_solutions/${vehicleIssueSolutionId}`,
      params,
      options: {
        model: VehicleIssueSolution,

        ...options
      }
    })
  }
}

export default SolutionsRequests

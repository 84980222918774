<style lang="scss" scoped>
.drop-zone {
  background-color: $light-gray;
  height: 97px;
  border-radius: 8px;
  border: 2px dashed $light-gray-4;

  .message {
    font-family: $secondary-font;
    font-size: 14px;
    font-weight: 400;
    color: $gray-3;
  }

  .button {
    font-size: 14px;
    margin-top: 8px;

    .icon {
      margin-right: 4px;
    }
  }

  &.dragover {
    border-color: $primary;
    background: $light-orange;

    .message {
      color: $primary;
      font-size: 16px;
      text-transform: uppercase;
    }
  }
}

.input {
  display: none;
}
</style>

<template lang="pug">
  .drop-zone.flex.column-direction.center.vertical-center(
    :class="{ 'dragover': isDragOver }"
    @drop="onDrop"
    @dragenter="onDragEnter"
    @dragleave="onDragLeave"
    @dragover="onDragOver"
  )
    span.message {{ isDragOver ? $t('.dragover-message') : message || $t('.message') }}
    app-button.button(theme="link", @click="onButtonClick")
      i.icon.far.fa-paperclip
      span {{ buttonLabel || $t('.btn.add.label') }}
    input.input(
      :accept="accept"
      :multiple="multiple"
      :name="name"
      ref="input"
      type="file"
      @change="onInputChange"
    )
</template>

<script>
export default {
  name: "DropZone",

  props: {
    accept:      { type: String, default: null },
    buttonLabel: { type: String, default: null },
    message:     { type: String, default: null },
    multiple:    { type: Boolean, default: false },
    name:        { type: String, default: null }
  },

  emits: ["input"],

  data() {
    return {
      i18nScope: "components.attach.drop-zone",

      isDragOver: false
    }
  },

  methods: {
    onButtonClick() {
      this.$refs.input.click()
    },

    onDragEnter() {
      this.isDragOver = true
    },

    onDragLeave(event) {
      if (this.$el.contains(event.fromElement)) return
      this.isDragOver = false
    },

    onDragOver(event) {
      event.preventDefault()
    },

    onDrop(event) {
      event.preventDefault()

      this.isDragOver = false

      const files = Array.from(event.dataTransfer.files)
      this.$emit("input", files)
    },

    onInputChange(event) {
      const files = Array.from(event.target.files)
      this.$emit("input", files)
    }
  }
}
</script>

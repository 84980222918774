<template lang="pug">

  index-table.suppliers-table(
    :empty="empty",
    :initializing="initializing",
    :cols-count="7",
    :column-sizes="columnSizes"
  )
    template(#tableHeader)
      suppliers-table-header(
        :loading="loading",
        @sort="param => $emit('sort', param)"
        :sort-param="sortParam",
        :sort-order="sortOrder",
        :column-sizes="columnSizes",
        data-testid="suppliersTableHeader"
      )

    template(#tableRows)
      supplier-row(
        v-for="supplier in suppliers"
        :key="supplier.id",
        :supplier="supplier",
        :loading="loading",
        :column-sizes="columnSizes"
        @destroy="$emit('changed')",
        @disabled="$emit('changed')",
        @enabled="$emit('changed')",
        @modified="$emit('changed')",
        @change="$emit('changed')",
        data-testid="supplierRow"
      )

</template>


<script>

// Assets
import emptyIcon from "@/assets/images/icons/search/products/none-found.svg"

// Components
import SupplierRow from "./supplier-row.vue"
import SuppliersTableHeader from "./suppliers-table-header.vue"

export default {
  components: {
    SupplierRow,
    SuppliersTableHeader
  },

  props: {
    suppliers:    { type: Array, required: true },
    initializing: { type: Boolean, default: false },
    loading:      { type: Boolean, default: false },
    sortedBy:     { type: String, default: "" }
  },

  data() {
    return {
      i18nScope: "suppliers.index.suppliers-table",

      emptyIcon
    }
  },

  computed: {
    empty() {
      return _.blank(this.suppliers)
    },

    columnSizes() {
      return "70px 70px 1fr 180px 70px 1fr 64px 64px"
    },

    sortParam() {
      return this.sortedBy.split(":")[0]
    },

    sortOrder() {
      return this.sortedBy.split(":")[1] || "asc"
    }
  }
}

</script>
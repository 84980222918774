import Base from "./base"
import Service from "./service"

class ServiceSupport extends Base {
  static typeToParam(type) {
    const typeWithoutPrefix = Service.typeWithoutPrefix(type)

    return _.kebabCase(typeWithoutPrefix)
  }

  static get attrs() {
    return _.uniq([
      ...super.attrs,
      "serviceType"
    ])
  }

  static get enums() {
    return {
      serviceType: Service.enums.type
    }
  }

  get typeAsParam() {
    return Service.typeToParam(this.serviceType)
  }
}

export default ServiceSupport

import { render, staticRenderFns } from "./suppliers-table-header.vue?vue&type=template&id=8a5def10&scoped=true&lang=pug&"
import script from "./suppliers-table-header.vue?vue&type=script&lang=js&"
export * from "./suppliers-table-header.vue?vue&type=script&lang=js&"
import style0 from "./suppliers-table-header.vue?vue&type=style&index=0&id=8a5def10&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8a5def10",
  null
  
)

export default component.exports
<template lang="pug">

  select-field(
    v-bind="$props",
    v-on="$listeners",
    :options="options"
  )

</template>


<script>

// Vue
import Vue from "vue"

// Extends
import SelectField from "@/components/form/select-field"

// Models
import models from "@/models"
const { ServiceOrder } = models


export default {
  name: "ServiceOrderStateSelectField",

  extends: SelectField,

  data() {
    return {
      i18nScope: "components.service-order.service-order-state-select-field"
    }
  },

  computed: {
    options() {
      return ServiceOrder.$enums.state.values.map(state => ({
        label: ServiceOrder.$tEnum("state", state),
        value: state
      }))
    }
  },

  // Removendo props 'options' que está sendo reescrita como _computed_
  beforeCreate() {
    Vue.delete(this.$options.props, "options")
  }
}

</script>

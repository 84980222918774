<template lang="pug">
  .service-order-modals-container
    messages-modal(
      v-if="isModalActive('messages')"
      :service-order="serviceOrder"
      @close="onModalClose"
      @update="onModalUpdate"
    )

    new-postponement-modal(
      v-if="isModalActive('new-postponement')"
      :service-order="serviceOrder"
      @close="onModalClose"
      @create="onModalCreate"
    )

    new-vehicle-withdrawal-modal(
      v-if="isModalActive('new-vehicle-withdrawal')"
      :service-order="serviceOrder"
      @close="onModalClose"
      @create="onModalCreate"
    )

    resend-approval-notification(
      v-if="isModalActive('resend-approval-notification')"
      :service-order="serviceOrder"
      @close="onModalClose"
    )

    service-order-cancellation-modal(
      v-if="isModalActive('service-order-cancellation')"
      :service-order="serviceOrder"
      @close="onModalClose"
      @update="onModalUpdate"
    )

    service-order-reopen-modal(
      v-if="isModalActive('service-order-reopen')"
      :service-order="serviceOrder"
      @close="onModalClose"
      @update="onModalUpdate"
    )

    service-order-execution-modal(
      v-if="isModalActive('service-order-execution')"
      :service-order="serviceOrder"
      @close="onModalClose"
      @update="onModalUpdate"
    )

    ticket-messages-modal(
      v-if="isModalActive('ticket-messages')"
      :service-order="serviceOrder"
      @close="onModalClose"
    )

    template(v-if="isModalActive('vehicle-receipt')")
      edit-vehicle-receipt-modal(
        v-if="hasVehicleReceipt"
        :service-order="serviceOrder"
        @close="onModalClose"
        @update="onModalUpdate"
      )

      new-vehicle-receipt-modal(
        v-else
        :service-order="serviceOrder"
        @close="onModalClose"
        @update="onModalUpdate"
      )
</template>

<script>
import EditVehicleReceiptModal from "@/views/monitoring/vehicle-receipts/edit.vue"
import MessagesModal from "@/views/monitoring/messages/messages-modal.vue"
import NewPostponementModal from "@/views/monitoring/service-orders/postponements/new.vue"
import NewVehicleReceiptModal from "@/views/monitoring/vehicle-receipts/new.vue"
import NewVehicleWithdrawalModal from "@/views/monitoring/vehicle-withdrawals/new.vue"
import ResendApprovalNotification from "../service-orders/supplier-authorization-mail-deliveries/new.vue"
import ServiceOrderCancellationModal from "./service-order-cancellation-modal.vue"
import ServiceOrderExecutionModal from "./service-order-execution-modal.vue"
import ServiceOrderReopenModal from "./service-order-reopen-modal.vue"
import TicketMessagesModal from "./ticket-messages-modal.vue"

import models from "@/models"
const { MovidaServiceOrder } = models

const MODALS = [
  "messages",
  "new-postponement",
  "new-vehicle-withdrawal",
  "resend-approval-notification",
  "service-order-cancellation",
  "service-order-execution",
  "service-order-reopen",
  "ticket-messages",
  "vehicle-receipt"
]

export default {
  components: {
    EditVehicleReceiptModal,
    MessagesModal,
    NewPostponementModal,
    NewVehicleReceiptModal,
    NewVehicleWithdrawalModal,
    ResendApprovalNotification,
    ServiceOrderCancellationModal,
    ServiceOrderExecutionModal,
    ServiceOrderReopenModal,
    TicketMessagesModal
  },

  props: {
    currentModal: {
      type:      String,
      default:   null,
      validator: (value) => _.isNil(value) || MODALS.includes(value)
    },
    serviceOrder: { type: MovidaServiceOrder, default: null },
    show:         { type: Boolean, default: false }
  },

  emits: ["update", "update:show"],

  computed: {
    hasVehicleReceipt() {
      return this.serviceOrder?.$present("enteringAt") || false
    }
  },

  methods: {
    isModalActive(name) {
      return this.show && this.serviceOrder && this.currentModal == name
    },

    onModalClose() {
      this.$emit("update:show", false)
    },

    onModalCreate() {
      this.$emit("update")
    },

    onModalUpdate() {
      this.$emit("update")
    }
  }
}
</script>

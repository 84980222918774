import { render, staticRenderFns } from "./labor-cost-linked-indicator.vue?vue&type=template&id=57ff17de&scoped=true&lang=pug&"
import script from "./labor-cost-linked-indicator.vue?vue&type=script&lang=js&"
export * from "./labor-cost-linked-indicator.vue?vue&type=script&lang=js&"
import style0 from "./labor-cost-linked-indicator.vue?vue&type=style&index=0&id=57ff17de&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "57ff17de",
  null
  
)

export default component.exports
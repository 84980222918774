<style lang="scss" scoped>

.help-text {
  font-size: 12px;
  color: $gray-3;
  line-height: 1;
  display: block;
  overflow: hidden;
  padding: 4px 8px;
}

.error {
  display: block;
  text-align: center;
  padding: 4px 12px;
  font-size: 14px;
  font-style: italic;

  .link {
    margin-left: 5px;
    font-size: 14px;
    font-style: italic;
    font-weight: normal;
  }
}

.empty {
  display: block;
  text-align: center;
  padding: 4px 12px;
  font-size: 14px;
  font-style: italic;
}

</style>


<template lang="pug">

  .product-select-field
    select-field(
      :name="name",
      :label="label",
      :fetching="fetching",
      :placeholder="placeholder",
      :optional="optional",

      option-as-value,
      :loading="loading || fetchingOption",
      :options="options",
      :disabled="disabled",
      :default-filter="false",
      :errors="errors",
      searchable,

      @input="onSelect",

      @search="onSearch",
      @open="_fetch",
      @close="onClose",
      @inputSearch="(newSearch) => search = newSearch"
    )
      template(#menuContentHeader)
        template(v-if="fetchError")
          label.error
            span {{ $t('.error.message') }}
            app-button.link(theme="link", @click="onSearch") {{ $t('.error.link') }}


      template(#menuContentFooter)
        template(v-if="!fetching && !fetchError && empty")
          label.empty {{ $t('.empty') }}

      template(#fetching)
        li.option {{ $t('.loading') }}

      template(#option="{ props: { option, index, focus, active } }")
        slot(name="option", :props="{ option, index, focus, active }")
          product-option(
            :option="option",
            :active="isActiveOption(option)",
            :focus="focus",
          )

</template>


<script>

// Mixins
import FieldMixin from "@/mixins/field"

// Local components
import ProductOption from "./product-option.vue"

// Models
import models from "@/models"

export default {
  name: "ProductMultipleSelectField",

  components: {
    ProductOption
  },

  mixins: [FieldMixin],

  props: {
    value:    { type: [Array], default: () => [] },
    optional: { type: Boolean, default: false }
  },

  data() {
    return {
      i18nScope: "components.product.select-field.product-select-field",

      search:         "",
      options:        [],
      fetchingOption: false,
      fetchError:     false,

      // Async
      fetching: false,
      request:  null
    }
  },

  computed: {
    empty() {
      return this.options.length === 0
    }
  },

  methods: {
    isActiveOption(option) {
      const hasOption = this.value.find(product => product.value === option.value)
      return !!hasOption
    },

    onClose() {
      this.options = []
    },

    onSelect(option) {
      if (!option) return

      let selectedOptions = this.value
      const optionIndex = selectedOptions.findIndex(product => product.value === option.value)

      if (optionIndex < 0) {
        selectedOptions = [...selectedOptions, option]
      }
      else {
        selectedOptions.splice(optionIndex, 1)
      }

      this.$emit("input", selectedOptions)
    },

    // Busca assincrona de _options_
    async _fetch() {
      let currentRequest

      try {
        if (this.request) this.request.cancel("Duplicated fetch")

        currentRequest = this.$sdk.products.list({ params: { q: this.search } })
        this.request = currentRequest

        this.fetching = true

        const response = await currentRequest
        let normalizedData = _.camelizeKeys(response.data)

        this.options = normalizedData.map(data => {
          const product = new models[data.type]({ ...data })
          return {
            value: data.id,
            label: product.productName,
            data:  product
          }
        })
      }

      catch (error) {
        this.fetchError = true
        this.$err.log(error)
      }

      finally {
        if (this.request === currentRequest) {
          this.request = null
          this.fetching = false
        }
      }
    },

    // Debounce para evitar sobrecarregar servidor com disparos de busca
    fetch: _.debounce(function debounce() {
      this._fetch()
    }, 500),

    onSearch() {
      this.fetching = true
      this.fetchError = false
      this.fetch()
    }
  }
}

</script>

// Ability
import { ability } from "@/modules/ability"

// Routes
import ServiceOrders from "@/views/service-orders/index.vue"

export default [
  {
    path:      "/service-orders",
    name:      "serviceOrders",
    component: ServiceOrders,
    meta:      {
      auth:        true,
      permissions: [() => ability.can("index", "ServiceOrder")]
    }
  }
]

<style lang="scss" scoped>

.supplier-contacts {
  .title {
    font-weight: bold;
    font-size: 20px;
    margin-bottom: 16px;
    font-family: $secondary-font;
    font-weight: 500;
  }

  .app-list-header {
    margin-top: 8px;
  }

  .empty {
    border: 1px solid $light-gray-3;
    border-radius: 4px;
    padding: 20px;

    &.no-filters {
      margin-top: 41px;
    }

    img {
      width: 92px;
      height: 86px;
    }

    .message {
      font-size: 14px;
      margin-top: 14px;
      color: $gray-3;
    }
  }

  table {
    width: 100%;
    table-layout: fixed;
    border-spacing: 0;
    border: 1px solid $light-gray-3;
    border-collapse: collapse;
    // border-radius: 4px; // XXX não funciona com border-collapse: collapse

    table tr:last-child td:first-child {
      border-bottom-left-radius: 10px;
    }

    table tr:last-child td:last-child {
      border-bottom-right-radius: 10px;
    }

    th {
      font-size: 14px;
      font-weight: 500;
      font-family: $secondary-font;
      color: $dark-gray;
      height: 48px;
      background: $light-gray-2;

      .label > *:not(:last-child) {
        margin-right: 8px;
      }
    }

    .icon {
      font-size: 16px;
    }

    .name-column .label {
      margin-left: 16px;
    }

    .email-column {
      width: 390px;
    }

    .phone-column {
      width: 260px;
    }

    .action-column {
      width: 48px;
    }
  }
}

</style>


<template lang="pug">

  .supplier-contacts
    h2.title {{ $t('.title') }}

    form(
      @submit.prevent,
      method="get",
      data-test="form"
    )
      ui-row.row
        ui-col(:span="6")
          input-field(
            name="contacts-search",
            hide-label,
            v-model="q",
            icon-left="far fa-search",
            :loading="submitting",
            :disabled="fetching",
            :placeholder="$t('.search.placeholder')"
          )
        ui-col(
          v-if="edit",
          :span="6"
        )
          .flex.end
            app-button(
              :disabled="fetching || submitting",
              @click="showNewContactModal = true",
              data-test="newContactButton"
            ) {{ $t('.btn.create') }}
          new-contact(
            v-if="showNewContactModal",
            @close="showNewContactModal = false",
            @add="addFromData"
          )

      app-list-header.app-list-header(
        v-if="showAppListHeader"

        :has-filters="hasFilters",
        :page="1",
        :pagination="pagination",
        :loading="fetching",
        :hide-results="emptyFiltered",

        @clear="q = null"
      )

      template(v-if="!fetching && (empty || emptyFiltered)")
        .empty.flex.column-direction.center.vertical-center(:class="{ 'no-filters': !hasFilters }")
          img(:src="emptyList")
          span.message(v-if="empty") {{ $t('.empty') }}
          span.message(v-else) {{ $t('.emptyFiltered', { query: q }) }}

      template(v-else)
        table(:width="100")
          thead
            tr
              th.name-column
                .label.flex.vertical-center
                  i.icon.fal.fa-user
                  span {{ $t('.tableHeader.name.label') }}
                  i.icon.fal.fa-briefcase
                  span {{ $t('.tableHeader.area.label') }}
              th.email-column
                .label.flex.vertical-center
                  i.icon.fal.fa-envelope
                  span {{ $t('.tableHeader.email.label') }}
              th.phone-column
                .label.flex.vertical-center
                  i.icon.fal.fa-phone
                  span {{ $t('.tableHeader.phone.label') }}
              th.action-column
          tbody
            template(v-if="fetching")
              contact-row-loading(
                v-for="index in 3",
                :key="`contact-row-loading-${index}`"
              )

            template(v-else)
              contact-row(
                v-for="(contact, index) in filteredContacts"
                :key="`contact-${index}`",
                :contact="contact",
                :submitting="submitting",
                :errors="nestedErrors[keyFor(contact)]",
                :edit="edit",
                @destroy="remove(index)"
              )

</template>


<script>

// Mixins
import NestedFormMixin from "@/mixins/nested-form"

// Icons
import emptyList from "@/assets/images/icons/empty-list.svg"

// Components
import ContactRow from "./index/contact-row"
import ContactRowLoading from "./index/contact-row-loading"
import NewContact from "./new"

export default {
  name: "SupplierContacts",

  components: {
    ContactRow,
    ContactRowLoading,
    NewContact
  },

  mixins: [NestedFormMixin],

  props: {
    fetching:   { type: Boolean, default: false },
    submitting: { type: Boolean, default: false },
    errors:     { type: Object, default: () => ({}) },
    edit:       { type: Boolean, default: false }
  },

  data() {
    return {
      i18nScope: "suppliers.contacts.index",

      q: null,

      // NestedFormMixin
      model:          "Contact",
      nestedResource: "contacts",

      showNewContactModal: false,

      emptyList
    }
  },

  computed: {
    filteredContacts() {
      return this.collection.filter(contact => {
        const searchText = this.normalize(this.q)

        const { name, area } = contact
        const emails = contact.emails.map(email => email.value).join("")
        const phones = contact.phones.map(phone => phone.value).join("")
        const contactData = this.normalize(`${name}${area}${emails}${phones}`)

        return contactData.includes(searchText)
      })
    },

    pagination() {
      return {
        perPage: this.filteredContacts.length,
        total:   this.collection.length
      }
    },

    showAppListHeader() {
      return this.fetching || this.hasFilters || (!this.empty && !this.emptyFiltered)
    },

    empty() {
      return _.blank(this.collection)
    },

    emptyFiltered() {
      return _.blank(this.filteredContacts)
    },

    hasFilters() {
      return _.present(this.q)
    }
  },

  methods: {
    normalize(string) {
      return _.deburr((string || "").toLowerCase())
    }
  }
}

</script>

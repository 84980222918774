<template>
  <div class="movida-card">
    <slot></slot>
  </div>
</template>

<script>

export default {
  name: "MovidaCard",
}
</script>

<style lang="scss" scoped>

.movida-card {
  border: 1px solid #eaeaea;
  border-radius: 4px;
}

</style>
<style lang="scss" scoped>
.page-navigator {
  gap: 8px;
}

.action {
  align-items: center;
  background: transparent;
  border: none;
  border-radius: 4px;
  color: $dark-gray;
  cursor: pointer;
  display: flex;
  font-size: 16px;
  height: 40px;
  justify-content: center;
  width: 40px;

  &:hover {
    color: $primary;
  }

  &:active {
    color: $dark-primary-2;
  }

  &:focus {
    outline: 2px solid $primary;
    outline-offset: -2px;
  }

  &:disabled {
    color: $gray;
    cursor: not-allowed;
  }
}
</style>

<template lang="pug">
  .page-navigator.flex
    button.action.first(
      v-tooltip="$t('.first.tooltip')"
      :disabled="isFirstPage"
      @click="onFirstClick"
    )
      i.far.fa-chevrons-left

    button.action.previous(
      :disabled="isFirstPage"
      v-tooltip="$t('.previous.tooltip')"
      @click="onPreviousClick"
    )
      i.far.fa-chevron-left

    button.action.next(
      :disabled="isLastPage"
      v-tooltip="$t('.next.tooltip')"
      @click="onNextClick"
    )
      i.far.fa-chevron-right
</template>

<script>
export default {
  props: {
    page:       { type: Number, default: 1 },
    pagination: { type: Object, default: () => ({ perPage: 0, total: 0 }) }
  },

  emits: ["update:page"],

  data() {
    return {
      i18nScope: "monitoring.page-navigator"
    }
  },

  computed: {
    clampedPage() {
      return _.clamp(this.page, 1, this.totalPages)
    },

    isFirstPage() {
      return this.clampedPage <= 1
    },

    isLastPage() {
      return this.clampedPage >= this.totalPages
    },

    totalPages() {
      const { total, perPage } = this.pagination

      // XXX: total é zero se algum desses for 0, null ou undefined
      if (!(total && perPage)) return 0

      return Math.ceil(total / perPage)
    }
  },

  methods: {
    onFirstClick() {
      this.$emit("update:page", 1)
    },

    onPreviousClick() {
      if (this.isFirstPage) return
      this.$emit("update:page", this.clampedPage - 1)
    },

    onNextClick() {
      if (this.isLastPage) return
      this.$emit("update:page", this.clampedPage + 1)
    }
  }
}
</script>

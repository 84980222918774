<style lang="scss" scoped>

.option {
  height: 40px;
  padding: 0 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid $light-gray-3;
  cursor: pointer;

  .remove-icon-content {
    width: 24px;
    height: 24px;
    line-height: 24px;
    text-align: center;
    border-radius: 50px;
    color: $gray-2;
    transition: all 100ms linear;
  }

  &.active {
    color: $primary;
    background: $light-gray-2;
  }

  &.focus {
    background: $light-gray-2;
    color: $dark-primary-2;

    .remove-icon-content {
      background: $light-gray-3;
      color: $primary;
    }
  }
}

::v-deep .select-field > .input-button.disabled {
  .icon,
  .value {
    color: $gray-2;
  }
}

.icon {
  padding-right: 4px;
  font-size: 14px;
  width: 32px;
  margin-right: 8px;
}

</style>


<template lang="pug">

  .type-select-field
    select-field(
      :name="name",
      :label="label",
      :placeholder="placeholder",
      :value="value",
      option-as-value,
      :disabled="disabled",
      @input="$emit('select-type-option', $event)",
      :options="options",
      searchable,
      data-test="typeSelect"
    )
      template(#selected)
        template(v-if="value")
          .flex.vertical-center
            product-type-icon.icon(:type="value.value")
            span.value {{ value.label }}
        template(v-else)
          span.placeholder {{ placeholder }}

      template(#option="{ props: { option, index, focus, active } }")
        .option(:class="{ focus, active }")
          .flex.vertical-center
            product-type-icon.icon(:type="option.value")
            span {{ option.label }}
          .remove-icon-content(v-if="active")
            i.far.fa-times

</template>


<script>

import FieldMixin from "@/mixins/field"

export default {
  mixins: [FieldMixin],

  props: {
    value:   { type: Object, default: null },
    options: { type: Array, default: () => [] }
  }
}

</script>

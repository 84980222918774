<style lang="scss" scoped>

.store-info-tag {
  border-radius: 8px;
  padding: 12px;
  background-color: $light-gray;

  .avatar {
    margin-right: 14px;
  }

  .store-name {
    .diamond-icon {
      margin: 0 4px;
      font-size: 4px;
      color: $gray-3;
    }
  }

  .row {
    margin-bottom: 6px;
    font-size: 14px;

    .title {
      font-family: $secondary-font;
      font-weight: 400;
      font-size: 16px;
    }

    .icon {
      color: $gray-2;
      margin-right: 7px;
    }

    .copy-icon {
      margin-left: 4px;
    }
  }
}

</style>


<template lang="pug">

  .store-info-tag.flex.vertical-center
    .flex.center.vertical-center
      avatar.avatar

    .vehicle-info.vertical-center
      .row.flex.vertical-center.store-name
        app-span.title(:value="store.initials")
        i.diamond-icon.fa.fa-diamond.flex.center.vertical-center
        app-span.title(:value="store.name")

      template(v-if="extended")
        .row.flex.vertical-center
          i.icon.fas.fa-user
          app-span.label(:value="fleetManagerName")

        .row.flex.vertical-center
          i.icon.fas.fa-envelope
          app-span.label(:value="fleetManagerEmail")
          copy-icon.copy-icon(:copy-value="fleetManagerEmail")

</template>


<script>

// Icons
import storesIcon from "@/assets/images/icons/sidebar/stores-icon.svg"

export default {
  name: "SupplierInfoTag",

  props: {
    store: { type: Object, required: true },

    extended: { type: Boolean, default: false }
  },

  data() {
    return {
      storesIcon
    }
  },

  computed: {
    fleetManagerEmail() {
      return _.get(this.store, "fleetManagerEmail")
    },

    fleetManagerName() {
      return _.get(this.store, "fleetManagerName")
    }
  }
}

</script>

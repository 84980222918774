import Base from "./Base"

class Manufacturers extends Base {

  list({ params = {} } = {}) {
    return this.action({
      method: "get",
      url:    "/mobiauto/manufacturers",
      params
    })
  }

  get({ id, params = {} }) {
    if (_.blank(id)) {
      throw new Error('SDK Manufacturers - #get() - "id" is a required prop')
    }

    return this.action({
      method: "get",
      url:    `/mobiauto/manufacturers/${id}`,
      params
    })
  }
}

export default Manufacturers

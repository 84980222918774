<style scoped lang="scss">
</style>


<template lang="pug">

  .destroy-action(v-if="$can('destroy', 'Product')")
    app-button.button(
      :disabled="disabled",
      :loading="deleting",
      @click="hidePopoverMenu(); showModal = true",
      theme="neutral",
      :border="border",
      :size="size",
      data-testid="deleteButton"
    )
      slot

    app-modal(
      v-if="showModal",
      :close-button="!deleting",
      :heading="$t('.heading')",
      :width="540",
      :loading="deleting",
      footer,
      :confirm-label="$t('btn.destroy.label')"
      :confirm-label-loading="$t('btn.destroy.loading')",
      @close="onCloseModal",
      @cancel="onCloseModal",
      @confirm="destroy",
      data-testid="destroyModal"
    )
      .body
        span.message {{ confirmationText }}

</template>


<script>

import models from "@/models"
const { Product } = models

export default {
  props: {
    id:       { type: [Number, String], required: true },
    size:     { type: String, default: "small" },
    border:   { type: String, default: "rounded" },
    name:     { type: String, required: true },
    type:     { type: String, required: true },
    // flags
    disabled: { type: Boolean, default: false },
    loading:  { type: Boolean, default: false }
  },

  data() {
    return {
      i18nScope: "products.show.destroy-action",

      showModal: false,
      deleting:  false
    }
  },

  computed: {
    confirmationText() {
      return this.$t(".message", {
        name: this.name,
        type: this.typeText.toLowerCase()
      })
    },

    typeText() {
      return Product.$tEnum("type", this.type)
    }
  },

  methods: {
    onCloseModal() {
      if (this.deleting) return

      this.showModal = false
    },

    hidePopoverMenu() {
      // XXX: usando $parent para conseguir fechar o "popover-menu"!
      this.$parent.$emit("hide")
    },

    async destroy() {
      this.deleting = true

      try {
        await this.$sdk.products.destroy({ id: this.id })

        this.$notifications.info(
          this.$t(".notifications.destroy.success", {
            type: this.typeText,
            name: this.name
          })
        )
        this.$emit("destroy")
      }
      catch (error) {
        this.$err.log(error)

        const errorResponse = _.get(error.originalError, "response") || {}

        if (errorResponse.status === 422) {
          const errorData = _.get(errorResponse, "data.errors") || {}
          const errors = this.$i18n.errify(errorData)

          const relations = Object.keys(errors).map(key => {
            const modelName = _.camelCase(key)
            return this.$t(`models.${modelName}`)
          })

          const relationsText = this.$toSentence(relations)

          this.$notifications.error(
            this.$t(".notifications.destroy.error.restrict", {
              type:      this.typeText,
              name:      this.name,
              relations: relationsText
            })
          )
        }
        else {
          this.$notifications.error(
            this.$t(".notifications.destroy.error.generic", {
              type: this.typeText,
              name: this.name
            })
          )
        }
      }
      finally {
        this.deleting = false
        this.showModal = false
      }
    }
  }
}

</script>

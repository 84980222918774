<style scoped lang="scss">

.row {
  margin-bottom: 24px;
}

.toggle {
  margin-top: 34px;
}

.actions {
  border-top: 1px solid $light-gray-3;
}

.form {
  .body {
    padding: 16px 24px;
  }

  .footer {
    padding: 16px 24px;
    border-top: 1px solid $light-gray-3;
  }
}


</style>


<template lang="pug">

  .maintenance-batch-form
    form.form(@submit.prevent="submit", method="post")
      .body
        ui-row.row
          ui-col(:span="3")
            input-field.field(
              name="batch[revisions_total]",
              :label="$t('.fields.revisionsTotal.label')",
              mask="#",
              v-model="resource.revisionsTotal",
              :errors="errors.revisionsTotal",
              :placeholder="$t('.fields.revisionsTotal.placeholder')",
              :suffix="$t('.fields.revisionsTotal.suffix')"
            )

          ui-col(:span="3")
            input-field(
              name="batch[target_execution_time]",
              :label="$t('.fields.targetExecutionTime.label')",
              :placeholder="$t('.fields.targetExecutionTime.placeholder')",
              mask="#",
              v-model="resource.targetExecutionTime",
              :errors="errors.targetExecutionTime",
              :suffix="$t('.fields.targetExecutionTime.suffix')"
            )

          ui-col(:span="3")
            toggle-field.toggle(
              name="batch[free_of_charge]",
              :value-text="$t('.fields.freeOfCharge.optionText')",
              hide-label,
              v-model="resource.freeOfCharge",
              :errors="errors.freeOfCharge"
            )

        ui-row.row
          ui-col(:span="3")
            input-field(
              name="batch[kilometrage_limit]",
              :label="$t('.fields.kilometrageLimit.label')",
              :placeholder="$t('.fields.kilometrageLimit.placeholder')",
              mask="#",
              v-model="resource.kilometrageLimit",
              :errors="errors.kilometrageLimit",
              :suffix="$t('.fields.kilometrageLimit.suffix')"
            )

          ui-col(:span="3")
            input-field(
              name="batch[time_limit]",
              :label="$t('.fields.timeLimit.label')",
              :placeholder="$t('.fields.timeLimit.placeholder')",
              v-model="resource.timeLimit",
              mask="#",
              :errors="errors.timeLimit",
              :suffix="$t('.fields.timeLimit.suffix')"
            )

          ui-col(:span="6")
            toggle-field.toggle(
              name="batch[forbidden_in_internal_facilities]",
              :value-text="$t('.fields.forbiddenInInternalFacilities.optionText')",
              hide-label,
              v-model="resource.forbiddenInInternalFacilities",
              :errors="errors.forbiddenInInternalFacilities"
            )

      .footer.toolbar.flex.space-between
        app-button(
          @click="$emit('close')",
          theme="gray",
          outline,
        ) {{ $t('btn.cancel') }}

        app-button(
          type="submit",
        ) {{ $t('.btn.create.label') }}

</template>


<script>

import { v4 as uuid } from "uuid"
import models from "@/models"
import Base from "@/models/base"
import { lib as movidaCommonLib } from "movida-common.vue"


const { parseModelJsErrors } = movidaCommonLib.ParseErrors

const { VehicleModelGroupMaintenance } = models

class BatchMaintenance extends Base {
  static get attrs() {
    return [
      "revisionsTotal",
      "kilometrageLimit",
      "timeLimit",
      "targetExecutionTime",
      "freeOfCharge",
      "forbiddenInInternalFacilities"
    ]
  }

  static get constraints() {
    return {
      revisionsTotal: {
        presence:     true,
        numericality: {
          onlyInteger: true,
          greaterThan: 0
        }
      },

      kilometrageLimit: {
        presence(value, attrs) { return _.blank(attrs.timeLimit) },
        numericality: {
          onlyInteger: true,
          greaterThan: 0
        }
      },

      timeLimit: {
        presence(value, attrs) { return _.blank(attrs.kilometrageLimit) },
        numericality: {
          onlyInteger: true,
          greaterThan: 0
        }
      }
    }
  }
}

export default {
  props: {
    initialKilometrageLimit: { type: Number, default: 0 },
    initialTimeLimit:        { type: Number, default: 0 }
  },

  data() {
    return {
      i18nScope: "vehicle-model-groups.maintenance-tables.form.maintenance-batch-form",

      resource: new BatchMaintenance(),
      errors:   {}
    }
  },

  methods: {
    async validate() {
      if (!(await this.resource.$validate())) {
        this.errors = parseModelJsErrors(this.resource.$errors)

        return false
      }
      return true
    },

    async submit() {
      const isValid = await this.validate()
      if (!isValid) return

      const total = parseFloat(this.resource.revisionsTotal)
      const kilometrageLimit = parseFloat(this.resource.kilometrageLimit)
      const timeLimit = parseFloat(this.resource.timeLimit)

      let maintenances = []

      for (let i = 1; i <= total; i++) {
        let maintenanceTimeLimit = timeLimit ? this.initialTimeLimit + timeLimit * i : undefined
        let maintenanceKilometrageLimit = kilometrageLimit ? this.initialKilometrageLimit + kilometrageLimit * i : undefined

        let maintenance = new VehicleModelGroupMaintenance({
          kilometrageLimit:              maintenanceKilometrageLimit,
          timeLimit:                     maintenanceTimeLimit,
          forbiddenInInternalFacilities: this.resource.forbiddenInInternalFacilities,
          targetExecutionTime:           this.resource.targetExecutionTime,
          freeOfCharge:                  this.resource.freeOfCharge
        })

        maintenance.tempId = uuid()
        maintenances.push(maintenance)
      }

      this.$emit("create", maintenances)
      this.$emit("close")
    }
  }
}

</script>

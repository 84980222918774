<style scoped lang="scss">
</style>


<template lang="pug">

  .destroy-action(v-if="$can('update', 'Store')")
    app-button(
      :disabled="disabled",
      :loading="deleting",
      :theme="theme",
      :border="border",
      @click="hidePopoverMenu(); showModal = true",
      data-test="deleteButton"
    )
      slot

    app-modal(
      v-if="showModal",
      :close-button="!deleting",
      :heading="$t('.heading')",
      :width="540",
      :loading="deleting",
      footer,
      :confirm-label="$t('btn.destroy.label')"
      :confirm-label-loading="$t('btn.destroy.loading')",
      @close="onCloseModal",
      @cancel="onCloseModal",
      @confirm="destroy",
      data-test="confirmDeleteButton"
    )
      .body
        span.text {{ $t('.message', names) }}

</template>


<script>

export default {
  props: {
    storeSupplierId: { type: [String, Number], required: true },
    supplierName:    { type: String, required: true },
    storeName:       { type: String, required: true },
    disabled:        { type: Boolean, default: false },
    theme:           { type: String, default: "neutral" },
    border:          { type: String, default: "square" }
  },

  data() {
    return {
      i18nScope: "stores.suppliers.components.destroy-action",

      deleting: false,

      showModal: false
    }
  },

  computed: {
    names() {
      return {
        store:    this.storeName,
        supplier: this.supplierName
      }
    }
  },

  methods: {
    hidePopoverMenu() {
      // XXX: usando $parent para conseguir fechar o "popover-menu"!
      this.$parent.$emit("hide")
    },

    onCloseModal() {
      if (!this.deleting) {
        this.showModal = false
      }
    },

    async destroy() {
      try {
        this.deleting = true
        await this.$sdk.storeSuppliers.destroy({ storeSupplierId: this.storeSupplierId })

        this.$notifications.info(this.$t(".notifications.destroy.success", this.names))
        this.$emit("destroy")
      }
      catch (error) {
        this.$err.log(error)
        this.$notifications.error(this.$t(".notifications.destroy.error", this.names))
      }
      finally {
        this.deleting = false
        this.showModal = false
      }
    }
  }
}

</script>

import { render, staticRenderFns } from "./vehicle-info-tag.vue?vue&type=template&id=54f5d304&scoped=true&lang=pug&"
import script from "./vehicle-info-tag.vue?vue&type=script&lang=js&"
export * from "./vehicle-info-tag.vue?vue&type=script&lang=js&"
import style0 from "./vehicle-info-tag.vue?vue&type=style&index=0&id=54f5d304&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "54f5d304",
  null
  
)

export default component.exports
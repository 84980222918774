import { i18n } from "utils.vue"

import Base from "./base"

class Phone extends Base {
  static get attrs() {
    return _.uniq([
      ...super.attrs,
      "value",
      "kind",
      "ddi",

      // Nested
      // TODO achar uma solução para parâmetros de construtor virtuais
      "tempId"
    ])
  }

  static get enums() {
    return {
      kind: { home: "home", work: "work", other: "other" }
    }
  }

  static get constraints() {
    return {
      value: {
        presence: true,
        type:     "string",
        length:   {
          minimum: 10,
          maximum: 11,
          message: i18n.t("models.phone.errors.value")
        }
      }
    }
  }

  get formattedValue() {
    if (_.blank(this.value)) return

    const ddd = this.value.substring(0, 2)
    // (xx) xxxx-xxxx ou (xx) xxxxx-xxxx
    const separatorIndex = this.value.length === 11 ? 7 : 6
    const firstSection = this.value.substring(2, separatorIndex)
    const secondSection = this.value.substring(separatorIndex)

    return `(${ddd}) ${firstSection}-${secondSection}`
  }
}

export default Phone

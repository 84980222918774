<style lang="scss" scoped>

.destroy-ticket-action {
  .destroy {
    color: $gray-3;

    &:hover {
      color: $orange;
    }
  }
}

</style>


<template lang="pug">

  .destroy-ticket-action(v-if="$can('destroy', minimumTicket)")
    slot(:props="{ destroy, submitting }")
      app-button.destroy(
        theme="link",
        :disabled="submitting",
        :loading="loading",
        @click="destroy"
      ) {{ $t('.btn.label') }}

</template>


<script>

// Mixins
import FormMixin from "@/mixins/form-mixin"

// Models
import models from "@/models"
const { Ticket } = models

export default {
  mixins: [FormMixin],

  props: {
    ticketId:      { type: [String, Number], required: true },
    ticketStoreId: { type: [String, Number], required: true },
    loading:       { type: Boolean, default: false }
  },

  data() {
    return {
      i18nScope: "tickets.drafts.components.destroy-ticket-action",

      guardFormOnLeave: false,

      minimumTicket: new Ticket({ id: this.ticketId, storeId: this.ticketStoreId })
    }
  },

  methods: {
    hidePopoverMenu() {
      // XXX: usando $parent para conseguir fechar o "popover-menu"!
      this.$parent.$emit("hide")
    },

    async destroy() {
      this.hidePopoverMenu()

      const isConfirmed = await this.$confirm({
        heading: this.$t(".confirmation.heading"),
        message: this.$t(".confirmation.message"),

        actions: {
          confirm: this.$t(".confirmation.confirm")
        }
      })

      if (isConfirmed) this.submit()
    },

    // @override Form mixin
    submitRequest() {
      return this.$newSdk.tickets.drafts.destroy({ ticketId: this.ticketId })
    },

    // @override Form mixin
    onSubmitSuccess() {
      this.$notifications.info(this.$t(".notifications.submit.success"))

      this.$emit("destroy")
    },

    // @override Form mixin
    onSubmitError(error) {
      if (error.statusCode === 404) this.$router.push({ name: "tickets" })

      else {
        this.$err.log(error)
        this.$notifications.error(this.$t(".notifications.submit.error"))
      }
    }
  }
}

</script>

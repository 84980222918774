<style lang="scss" scoped>
.ticket-message {
  align-items: stretch;
  color: $dark-gray;
  display: flex;
  font-family: $primary-font;
  font-size: 14px;
  gap: 32px;
  line-height: 1.5;

  > * {
    padding: 16px 0;
  }
}

.timeline {
  display: flex;
  flex-shrink: 0;
  justify-content: center;
  padding: 0;
  position: relative;
  width: 24px;

  .icon {
    background: white;
    box-shadow: 0 0 0 6px white;
    color: $gray-3;
    font-size: 20px;
    height: 20px;
    margin-top: 22px;
    position: relative;

    .customer-interaction & {
      color:$light-brand-alt-3;
    }
  }

  &::before {
    background: $gray;
    content: ' ';
    display: block;
    height: 100%;
    position: absolute;
    width: 1px;
  }
}

.datetime-info {
  flex-shrink: 0;

  .time-ago {
    font-weight: 400;
  }
}

.message {
  flex-grow: 1;
}

.author-info {
  align-items: center;
  display: flex;
  margin-bottom: 8px;

  .author {
    font-weight: 400;
  }

  .separator {
    color: $gray;
    font-size: 4px;
    margin: 0 4px;
  }

  .customer-interaction-label {
    color: $brand-alt;
    font-style: italic;
  }
}

.message-content {
  background: $light-gray-2;
  border-radius: 8px;
  font-weight: 400;
  padding: 16px;

  .customer-interaction & {
    background: $light-brand-alt;
  }
}
</style>

<template lang="pug">
  .ticket-message(:class="{ 'customer-interaction': isCustomerInteraction }")
    .timeline
      i.icon.fas.fa-user(v-if="isCustomerInteraction")
      i.icon.fas.fa-user-headset(v-else)

    .datetime-info
      .time-ago
        span {{ $fromNow(ticketMessage.at) }}
      .date
        span {{ date }}
      .time
        span {{ time }}

    .message
      .author-info
        span {{ $t(".registeredBy") }}&nbsp;
        span.author {{ ticketMessage.authorName }}
        template(v-if="isCustomerInteraction")
          i.separator.fas.fa-diamond
          span.customer-interaction-label {{ $t(".custormerInteraction") }}
      .message-content {{ ticketMessage.message }}
</template>

<script>
import models from "@/models"

const { MovidaServiceOrderTicketMessage } = models

export default {
  props: {
    ticketMessage: { type: MovidaServiceOrderTicketMessage, required: true }
  },

  data() {
    return {
      i18nScope: "monitoring.ticket-message"
    }
  },

  computed: {
    date() {
      return moment(this.ticketMessage.at).format("L")
    },

    isCustomerInteraction() {
      return this.ticketMessage.kind == "customer"
    },

    time() {
      return moment(this.ticketMessage.at).format("LTS")
    }
  }
}
</script>

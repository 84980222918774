import Base from "../../Base"
import models from "@/models"

const { ServiceOrderSupplierAuthorizationMailDelivery } = models

class SupplierAuthorizationMailDeliveries extends Base {
  static get selector() {
    return "monitoring.serviceOrders.supplierAuthorizationMailDeliveries"
  }

  create({ serviceOrderId, params = {}, options = {} } = {}) {
    this.verifyData("create", { serviceOrderId })

    return this.action({
      method:  "post",
      url:     `/monitoring/service_orders/${serviceOrderId}/supplier_authorization_mail_deliveries`,
      params,
      options: {
        model: ServiceOrderSupplierAuthorizationMailDelivery,

        ...options
      }
    })
  }
}

export default SupplierAuthorizationMailDeliveries

/**
 * Mixin para lidar com uploads de anexos
 */

import axios from "axios"
import { getMimeTypeForExtension, mimetypes } from "@/lib/supported-files"
import FileChecksum from "@/lib/checksum"
import models from "@/models"

import AttachAction from "./attach-action"

const { Attachment } = models
const { CancelToken } = axios

const ATTACH_STATE = {
  AWAITING:      1,
  CREATING_BLOB: 2,
  UPLOADING:     3,
  COMPLETE:      4,
  FAIL:          5
}

export default {
  extends: AttachAction,

  props: {
    allowedTypes: { type: Array, default: () => mimetypes },
    maxSize:      { type: Number, default: Number.MAX_SAFE_INTEGER }
  },

  data() {
    return {
      uploadQueue: []
    }
  },

  computed: {
    ATTACH_STATE: () => ATTACH_STATE
  },

  beforeDestroy() {
    this.uploadQueue
      .filter(({ abort, state }) => state == ATTACH_STATE.UPLOADING && abort)
      .forEach(({ abort }) => abort())
  },

  methods: {
    addAttachmentToQueue(file) {
      if (!this.validateFile(file)) return

      const queueItem = {
        abort:      null,
        attachment: this.createAttachment(file),
        file,
        id:         _.uniqueId(),
        loaded:     0,
        state:      ATTACH_STATE.AWAITING
      }

      this.handleQueueItem(queueItem)
      this.uploadQueue.push(queueItem)
    },

    abortAttachmentUpload(id) {
      const { abort } = this.uploadQueue.find((item) => item.id == id)

      if (!abort) return
      abort()

      this.uploadQueue = this.uploadQueue.filter((item) => item.id != id)
    },

    createAttachment(file) {
      return new Attachment({
        fileContentType: this.getContentType(file),
        name:            file.name,
        fileSize:        file.size
      })
    },

    async createBlob(file) {
      const checksum = await FileChecksum.create(file)

      const response = await this.sdk.create({
        params: {
          blob: {
            filename:     file.name,
            content_type: this.getContentType(file),
            byte_size:    file.size,
            checksum
          }
        }
      })

      return response.data
    },

    getContentType(file) {
      return file.type || this.getContentTypeByFileName(file.name)
    },

    getContentTypeByFileName(name) {
      const result = /\.(\w+)$/.exec(name)
      const extension = _.present(result) ? result[1] : "bin"  // XXX: special value "bin" para virar "octet-stream" (binário)

      return getMimeTypeForExtension(extension)
    },

    async handleQueueItem(item) {
      try {
        item.state = ATTACH_STATE.CREATING_BLOB
        const blob = await this.createBlob(item.file)

        item.state = ATTACH_STATE.UPLOADING
        await this.uploadBlob(blob, item)

        item.state = ATTACH_STATE.COMPLETE
        item.attachment.file = blob.signed_id

        this.$emit("change", [...this.value, item.attachment])
      }
      catch (error) {
        item.state = ATTACH_STATE.FAIL

        this.$err.log(error)
        this.$notifications.error(this.$t(".notifications.upload.error", { name: item.file.name }))
      }
      finally {
        this.uploadQueue = this.uploadQueue.filter((queueItem) => queueItem !== item)
      }
    },

    async readFile(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.addEventListener("load", () => resolve(reader.result))
        reader.addEventListener("error", () => reject(new Error(`Error reading ${file.name}`)))

        reader.readAsArrayBuffer(file)
      })
    },

    async uploadBlob(blob, item) {
      const data = await this.readFile(item.file)

      const source = CancelToken.source()

      item.abort = source.cancel

      await axios.put(blob.direct_upload.url, data, {
        cancelToken: source.token,

        headers: _.omit(blob.direct_upload.headers, "Content-Disposition"),

        transformRequest: [
          (requestData, requestHeaders) => {
            delete requestHeaders.put["Content-Type"]
            return requestData
          }
        ],

        onUploadProgress: ({ loaded }) => { item.loaded = loaded }
      })
    },

    validateFile(file) {
      const rules = [
        (_file) => _file.size > this.maxSize
          && this.$t(".errors.size.max", { name: _file.name, limit: this.$asHumanSize(this.maxSize) }),

        (_file) => _file.size <= 0
          && this.$t(".errors.size.min", { name: _file.name }),

        (_file) => (
          _.present(this.allowedTypes) && !this.allowedTypes.includes(this.getContentType(_file))
          && this.$t(".errors.type", { name: _file.name })
        )
      ]

      const errors = rules
        .map((rule) => rule(file))
        .filter(_.identity)

      errors.forEach(error => this.$notifications.error(error))

      return _.blank(errors)
    }
  }
}

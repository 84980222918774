import Ticket from "./ticket"

class TicketExternalMaintenance extends Ticket {
  static get attrs() {
    const attrs = super.attrs.filter(attr => attr !== "type")

    return attrs
  }

  static get enums() {
    const enums = { ...super.enums }
    delete enums.type

    return enums
  }

  static get virtuals() {
    return [
      "type"
    ]
  }

  // virtuals
  get type() {
    return "Tickets::Maintenance"
  }
}

export default TicketExternalMaintenance

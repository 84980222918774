<style scoped lang="scss">

.service-order-row {
  display: grid;
  grid-template-columns: var(--grid-columns);

  &.loading {
    .column {
      &.id-column {
        .id-tag {
          color: $gray-2;
        }
      }

      &.actions-column {
        ::v-deep .movida-common__app-button {
          color: $gray-2;

          &:hover {
            color: $orange;
          }
        }
      }

      .text,
      &.supplier-column {
        color: $gray-2;
      }
    }
  }

  .column {
    padding: 0 12px;
    border-bottom: 1px solid $light-gray-3;
    height: 56px;
    overflow: hidden;

    &.supplier-column {
      text-align: initial;
      font-weight: 400;

      .supplier-name {
        font-family: $secondary-font;
        font-size: 14px;
      }

      .address {
        font-size: 12px;
      }
    }

    .text,
    .link {
      font-family: $primary-font;
      font-weight: 400;
      font-size: 14px;
      color: $dark-gray;
      text-align: initial;
    }

    .link:hover {
      color: $orange;
    }

    .unknown {
      font-style: italic;
    }
  }
}

</style>


<template lang="pug">

  .service-order-row(:class="{ loading }", :style="styles")
    .column.id-column.flex.vertical-center
      vetor-code-tag.id-tag(:value="serviceOrder.id")

    .column.state-column.flex.vertical-center.center
      ticket-state-icon(:state="serviceOrder.state")

    .column.ticket-column.flex.vertical-center
      app-button(theme="link" @click="$emit('select')")
        app-span.link(:value="kind", :class="{ unknown: kindUnknown }")

    .column.supplier-column.flex.column-direction.center
      .supplier-name
        app-span(:value="name", crop)
      .address
        app-span(:value="address", crop)

    .column.created-at-column.flex.vertical-center
      app-span.text(:value="$standardTime(serviceOrder.createdAt)")

    .column.actions-column.flex.vertical-center
      app-button(theme="link" @click="$emit('select')")
        i.icon.far.fa-arrow-right

</template>


<script>

// Models
import models from "@/models"
const { VehicleServiceOrder } = models

export default {
  props: {
    serviceOrder: { type: Object, default: () => ({}) },
    loading:      { type: Boolean, default: false },

    columnSizes: { type: String, required: true }
  },

  data() {
    return {
      i18nScope: "tickets.drafts.vehicles.components.last-tickets.service-order-row",

      VehicleServiceOrder
    }
  },

  computed: {
    styles() {
      return {
        "--grid-columns": this.columnSizes
      }
    },

    supplier() {
      return _.get(this.serviceOrder, "supplier") || {}
    },

    name() {
      return this.supplier.tradingName
    },

    address() {
      return this.supplier.fullAddress
    },

    serviceKind() {
      return _.get(this.serviceOrder, "serviceKind")
    },

    kindUnknown() {
      return _.isNil(this.serviceKind)
    },

    kind() {
      // TODO: Ampliar `$tEnum` para cobrir valores nulos
      let kind = VehicleServiceOrder.$enums.serviceKind.all[this.serviceKind]

      return VehicleServiceOrder.$tEnum("serviceKind", kind)
    }
  }
}

</script>

<template lang="pug">

  .store-view-context
    template(v-if="inTicketsContext")
      store-tickets-header

</template>


<script>

// Components
import StoreTicketsHeader from "./store-tickets-header.vue"

export default {
  components: {
    StoreTicketsHeader
  },

  data() {
    return {
      i18nScope: "stores.components.store-view-context",

      routeName: this.$route.name
    }
  },

  computed: {
    inTicketsContext() {
      return ["storeTickets", "storeServiceOrders"].includes(this.routeName)
    }
  },

  watch: {
    $route(to, from) {
      this.routeName = this.$route.name
    }
  }
}

</script>

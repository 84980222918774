<style lang="scss" scoped>

.error {
  display: block;
  text-align: center;
  padding: 4px 12px;
  font-size: 14px;
  font-style: italic;

  .link {
    margin-left: 5px;
    font-size: 14px;
    font-style: italic;
    font-weight: normal;
  }
}

.empty {
  display: block;
  text-align: center;
  padding: 4px 12px;
  font-size: 14px;
  font-style: italic;
}

</style>


<template lang="pug">

  .mobiauto-version-select-field
    select-field(
      :name="name",
      :label="label",
      :fetching="fetching",
      :disabled="disabled",
      :placeholder="placeholder",
      :optional="optional",

      option-as-value,
      :loading="loading || fetchingOption",
      :options="options",
      :default-filter="false",
      :errors="errors",
      searchable,

      :value="option",
      @input="onSelect",

      @search="onSearch",
      @open="_fetch",
      @close="onClose",
      @inputSearch="(newSearch) => search = newSearch"
    )

      template(#menuContentHeader)
        template(v-if="fetchError")
          label.error
            span {{ $t('.error.message') }}
            app-button.link(theme="link", @click="onSearch") {{ $t('.error.link') }}


      template(#menuContentFooter)
        template(v-if="!fetching && !fetchError && empty")
          label.empty {{ $t('.empty') }}

      template(#fetching)
        li.option {{ $t('.loading') }}

      template(#option="{ props: { option, index, focus, active } }")
        mobiauto-version-option(
          :option="option",
          :active="active",
          :focus="focus",
        )

</template>


<script>

// Mixins
import FieldMixin from "@/mixins/field"

// Local components
import MobiautoVersionOption from "./mobiauto-version-option.vue"

// Models
import models from "@/models"

export default {
  components: {
    MobiautoVersionOption
  },

  mixins: [FieldMixin],

  props: {
    value:       { type: [Number, String, Object], default: null },
    dataAsValue: { type: Boolean, default: false }
  },

  data() {
    return {
      i18nScope: "vehicle-models.forms.form-fields.mobiauto-version-select-field",

      search:         "",
      options:        [],
      option:         null,
      fetchingOption: false,
      fetchError:     false,

      // Async
      fetching: false,
      request:  null
    }
  },

  computed: {
    empty() {
      return this.options.length === 0
    },

    selectedOptionLabel() {
      if (_.blank(this.value)) return

      return `${this.value.manufacturerName} ${this.value.modelName} ${this.value.name}`
    }
  },

  watch: {
    value() {
      if (this.value === _.get(this.option, "value")) return

      if (_.blank(this.value)) {
        this.option = null
        return
      }

      if (!this.dataAsValue) {
        this.fetchOption(this.value)
      }
      else {
        this.option = {
          value: this.value.id,
          label: this.selectedOptionLabel,
          data:  this.value
        }
      }
    }
  },

  created() {
    if (_.blank(this.value)) return

    if (!this.dataAsValue) {
      this.fetchOption(this.value)
    }
    else {
      this.option = {
        value: this.value.id,
        label: this.selectedOptionLabel,
        data:  this.value
      }
    }
  },

  methods: {
    onClose() {
      this.options = []
    },

    onSelect(option) {
      this.option = option
      this.$emit("change-option", option)

      if (this.dataAsValue) {
        this.$emit("input", _.get(option, "data") || null)
      }
      else {
        // XXX precisamos enviar `null` ao invés de `undefined`
        // para que o Axios não desconsidere o parametro
        this.$emit("input", _.get(option, "value") || null)
      }
    },

    // Busca assincrona de _options_
    async _fetch() {
      let currentRequest

      try {
        if (this.request) this.request.cancel("Duplicated fetch")

        currentRequest = this.$sdk.vehicleModels.mobiautoList({ params: { q: this.search } })
        this.request = currentRequest

        this.fetching = true

        const response = await currentRequest
        let normalizedData = _.camelizeKeys(response.data)

        this.options = normalizedData.map(data => {
          const mobiautoVersion = new models.MobiautoVersion(data)
          return {
            value: data.id,
            label: mobiautoVersion.displayName,
            data:  mobiautoVersion
          }
        })
      }

      catch (error) {
        if (!error.cancelled) this.fetchError = true
        this.$err.log(error)
      }

      finally {
        if (this.request === currentRequest) {
          this.request = null
          this.fetching = false
        }
      }
    },

    // Debounce para evitar sobrecarregar servidor com disparos de busca
    fetch: _.debounce(function debounce() {
      this._fetch()
    }, 500),

    onSearch() {
      this.fetching = true
      this.fetchError = false
      this.fetch()
    }
  }
}

</script>

<style scoped lang="scss">

.info-label + .info-label {
  margin-left: 40px;
}

.target-icon {
  color: $success;
  font-size: 24px;
}

.type-icon {
  margin-right: 8px;
}

.section + .section {
  margin-top: 24px;
}

.type-icon {
  margin-right: 8px;
}

</style>


<template lang="pug">

  .tire
    section.section.flex.vertical-center
      info-label.info-label(
        :loading="loading",
        :value="Product.$tEnum('type', resource.type)"
      )
        template(slot="icon")
          product-type-icon.type-icon(:type="resource.type")

    section.section.flex.vertical-center
      info-label.info-label(
        :loading="loading",
        :label="$t('.fields.brand.label')",
        :value="resource.$get('brand.name')"
      )

      info-label.info-label(
        :loading="loading",
        :label="$t('.fields.tire-size.label')",
        :value="resource.tireSize"
      )

      info-label.info-label(
        :loading="loading",
        :label="$t('.fields.tire-load-index.label')",
        :value="resource.tireLoadIndex"
      )

      info-label.info-label(
        :loading="loading",
        :label="$t('.fields.tire-speed-rating.label')",
        :value="resource.tireSpeedRating"
      )

      info-label.info-label(
        :loading="loading",
        :label="$t('.fields.tire-kind.label')",
        :value="Tire.$tEnum('tire-kind', Tire.$enums.tireKind.value(resource.tireKind))"
      )

</template>


<script>

// Models
import models from "@/models"
const { Product, Tire } = models

export default {
  props: {
    loading:  { type: Boolean, default: false },
    resource: { type: Object, default: () => ({}) }
  },

  data() {
    return {
      i18nScope: "products.show.products.tire",

      Product,
      Tire
    }
  }
}

</script>

// Base
import BaseRequests from "../../Base"


class ApprovalsRequests extends BaseRequests {
  static get selector() {
    return "quotes.reviews.approvals"
  }

  create({ quoteId, params = {}, options = {} } = {}) {
    this.verifyData("create", { quoteId })

    return this.action({
      method: "post",
      url:    `/quotes/${quoteId}/reviews/approval`,
      params,
      options
    })
  }
}


export default ApprovalsRequests

<style lang="scss" scoped>

.edit {
  .loading {
    padding: 16px 24px;
  }
}

</style>


<template lang="pug">

  app-modal.edit(
    :width="1156",
    :heading="$t('.modal.heading')",
    @close="onClose"
  )
    .content
      template(v-if="fetching")
        loading-lines.loading(:lines="5")

      template(v-else)
        form.form(@submit.prevent="processAndSubmit", method="post")
          form-fields(
            :resource="resource",
            :submitting="submitting",
            :errors.sync="errors",
            edit
          )

    template(#footer)
      .footer
        app-button(
          outline,
          theme="gray",
          @click="onClose"
        ) {{ $t('btn.cancel') }}

        app-button(
          :loading="submitting",
          :disabled="fetching || !hasService",
          @click="processAndSubmit"
        )
          span {{ $t('.modal.btn.submit.label') }}

</template>


<script>

// Libs
import { v4 as uuid } from "uuid"

// Components
import FormFields from "./components/form-fields.vue"

// Mixins
import FetchMixin from "@/mixins/fetch-mixin"
import FormMixin from "@/mixins/form-mixin"

export default {
  components: {
    FormFields
  },

  mixins: [FetchMixin, FormMixin],

  props: {
    serviceOrderId:        { type: [String, Number], required: true },
    serviceOrderServiceId: { type: [String, Number], required: true }
  },

  data() {
    return {
      i18nScope: "tickets.drafts.contents.services.edit",

      uuid,

      resource: {},

      guardFormOnLeave: false,

      danglingTarget: null,

      nestedAttributes: {
        targets:     null,
        attachments: null
      }
    }
  },

  computed: {
    hasService() {
      return _.present(this.resource.vehicleIssueSolutionId)
    }
  },

  methods: {
    // @override Fetch mixin
    fetchRequest() {
      return this.$newSdk.serviceOrders.drafts.services.find({ serviceOrderId: this.serviceOrderId, serviceOrderServiceId: this.serviceOrderServiceId })
    },

    // @override Fetch mixin
    onFetchSuccess({ data }) {
      this.resource = data

      this.initialSerializedAttributes = this.serializeAttributes()
      this.guardFormOnLeave = true
    },

    // Remove o target vazio para realizar validação e comparação
    clearResource() {
      if (_.present(this.resource.targets)) {
        const [lastTarget] = this.resource.targets.slice(-1)
        if (lastTarget.empty) this.danglingTarget = this.resource.targets.pop()
      }
    },

    restoreResource() {
      if (this.danglingTarget) this.resource.targets.push(this.danglingTarget)
      this.danglingTarget = null
    },

    async onClose() {
      this.clearResource()
      const isDirty = this.checkDirty()
      this.restoreResource()

      if (!isDirty || await this.askDirtyLeaveConfirmation()) {
        this.$emit("update")
        this.$emit("close")
      }
    },

    processAndSubmit() {
      this.clearResource()
      this.submit()
    },

    // @override Form mixin
    afterSubmitStart() {
      this.restoreResource()
    },

    // @override Form mixin
    serializeAttributes() {
      const targetsAttributes = this.resource.targets.map(target => target.$serialize())
      const attachmentsAttributes = this.resource.attachments.map(attachment => attachment.$serialize())

      return {
        ...this.resource.$serialize(),

        targets_attributes:     targetsAttributes,
        attachments_attributes: attachmentsAttributes
      }
    },

    // @override Form mixin
    submitRequest() {
      return this.$newSdk.serviceOrders.drafts.services.update({
        serviceOrderId:        this.serviceOrderId,
        serviceOrderServiceId: this.resource.id,
        params:                this.serializeAttributes()
      })
    },

    // @override Form mixin
    onSubmitSuccess(response) {
      this.$notifications.info(this.$t(".notifications.submit.success"))
      this.$emit("update")
      this.$emit("close")
    }
  }
}

</script>

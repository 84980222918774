<style scoped lang="scss">

.service-supports {
  .header {
    margin-top: 24px;
    margin-bottom: 8px;

    .title-text {
      font-size: 30px;
      font-weight: normal;
      font-weight: 500;
      font-family: $secondary-font;
    }
  }

  .cards {
    .card {
      margin-right: 24px;
      margin-top: 24px;
    }
  }
}

</style>


<template lang="pug">

  .service-supports
    header.header.flex.space-between
      .title.flex.vertical-center
        h1.title-text {{ $t('.title') }}

    .cards.flex.wrap

      template(v-for="supplierServiceSupport in resource")
        service-support-card.card(
          :key="`supplier-service-support-card-${supplierServiceSupport.typeAsParam}`",
          :loading="fetching",
          :supplier-service-support="supplierServiceSupport",
          @change="fetch",
          :data-testid="`supplier-service-support-card-${supplierServiceSupport.typeAsParam}`"
        )

    router-view(
      @create="fetch",
      @update="fetch"
    )

</template>


<script>

// View
import LoadedView from "@/views/loaded-view"

// Mixins
import FetchMixin from "@/mixins/fetch-mixin"

// Models
import models from "@/models"

// Local Components
import ServiceSupportCard from "./components/service-support-card"


const { SupplierServiceSupport } = models

export default {
  name: "SupplierServiceSupports",

  components: {
    ServiceSupportCard
  },

  extends: LoadedView,

  mixins: [FetchMixin],

  data() {
    return {
      i18nScope: "suppliers.service-supports.index",
      routeName: "supplierServiceSupports",

      supplierId: this.$route.params.id,

      resource: SupplierServiceSupport.$enums.serviceType.keys.map(type => new SupplierServiceSupport({ serviceType: type }))
    }
  },

  methods: {
    // @override LoadedView
    parseRoute() {},

    // @override FetchMixin
    fetchRequest() {
      return this.$newSdk.suppliers.serviceSupports.all({ supplierId: this.supplierId })
    },

    // @override FetchMixin
    onFetchSuccess({ data }) {
      this.resource = data

      const base = SupplierServiceSupport.$enums.serviceType.keys.map(type => ({ serviceType: type }))

      this.resource = _({}).merge(
        _.keyBy(base, "serviceType"),
        _.keyBy(this.resource, "serviceType")
      )
        .values()
        .value()
        .map(service => new SupplierServiceSupport(service))
    }
  }
}

</script>

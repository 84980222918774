/**
 * Plugin para ajustar largura do Flatpickr com padding
 *
 * @param {object} param0
 * @param {number} param0.padding Padding em px
 * @returns {(fp: object) => object}
 */
const flatpickrWidthPaddingPlugin = ({ padding }) => {
  const config = { padding: padding || 16 }

  let triggerTimeout

  /* eslint-disable func-names */
  return function (fp) {
    const setCalendarWidth = () => {
      if (_.isNil(fp.calendarContainer)) return

      const styleWidth = fp.calendarContainer.style.width
      if (_.isNil(styleWidth)) return

      /* eslint-disable radix */
      const width = parseInt(styleWidth)
      fp.calendarContainer.style.width = `${width + config.padding * 2}px`

      triggerTimeout = null
    }

    return {
      onReady() {
        triggerTimeout = setTimeout(setCalendarWidth, 0)
      },

      onDestroy() {
        if (!triggerTimeout) return
        clearTimeout(triggerTimeout)
      }
    }
  }
}
export default flatpickrWidthPaddingPlugin

<style scoped lang="scss">

.header {
  padding-bottom: 16px;
}

.icon {
  margin-right: 8px;
}

.error-message {
  display: block;
  color: $error;
  font-size: 12px;
  line-height: 1;
  padding-top: 4px;
}

.actions {
  .btn + .btn {
    margin-left: 32px;
  }
}

.count {
  font-size: 14px;
  color: $gray-3;
}

</style>


<template lang="pug">

  .maintenance-list

    header.header
      .flex.space-between.vertical-center
        .count
          span {{ $t('.count', { count: value.length }) }}

        .actions
          app-button.btn(
            theme="link",
            @click="add",
            data-testid="addButton"
          )
            i.icon.far.fa-plus
            span {{ $t('.btn.add') }}

          app-button.btn(theme="link", @click="openBatchModal = true")
            i.icon.far.fa-plus-circle
            span {{ $t('.btn.batch') }}

      span.error-message(
        v-for="error in maintenanceListErrors",
        :key="`maintenance-error-${error}`"
      ) {{ error }}

    maintenances-table(
      :error="error",
      :empty="empty",
      :loading="fetching"
    )
      maintenance-fields(
        v-for="(resource, index) in collection",
        :key="`maintenance-item-${keyFor(resource)}`",
        v-show="!resource.$markedForDestruction",
        :index="index + 1",
        :loading="submitting",
        :resource="resource",
        :errors="nestedErrors[keyFor(resource)]",
        @remove="remove(index)",
        data-testid="maintenanceRow"
      )

      .table-error(slot="error")
        span {{ $t('.error') }}

      .empty(slot="empty")
        span {{ $t('.empty') }}

    app-modal(
      v-if="openBatchModal",
      :heading="$t('.batchModal.heading')",
      :width="970",
      @close="openBatchModal = false"
    )
      maintenance-batch-form(
        :initial-index="currentIndex",
        :initial-kilometrage-limit="highestKilometrageLimit",
        :initial-time-limit="highestTimeLimit",
        @create="onBatchMaintenances",
        @close="openBatchModal = false"
      )

</template>


<script>

// Mixins
import NestedFormMixin from "@/mixins/nested-form"

// Local Components
import MaintenanceBatchForm from "./maintenance-batch-form"
import MaintenanceFields from "./maintenance-fields"
import MaintenancesTable from "../components/maintenances-table"

export default {
  components: {
    MaintenanceBatchForm,
    MaintenanceFields,
    MaintenancesTable
  },

  mixins: [NestedFormMixin],

  props: {
    errors:     { type: Object, default: () => ({}) },
    error:      { type: Boolean, default: false },
    fetching:   { type: Boolean, default: false },
    submitting: { type: Boolean, default: false }
  },

  data() {
    return {
      i18nScope: "vehicle-model-groups.maintenance-tables.form.maintenances-nested-fields",

      model:          "VehicleModelGroupMaintenance",
      nestedResource: "vehicleModelGroupMaintenances",

      openBatchModal: false
    }
  },

  computed: {
    // @override NestedFormMixin
    empty() {
      const filteredCollection = this.collection.filter(
        resource => !resource.$markedForDestruction
      )
      return _.blank(filteredCollection)
    },

    maintenanceListErrors() {
      return {
        ...this.errors.vehicleModelGroupMaintenances,
        ...this.errors.supplierMaintenances
      }
    },

    currentIndex() {
      return this.collection.length
    },

    highestKilometrageLimit() {
      const filteredKilometrageLimits = this.collection.filter(resource => /^[0-9]+$/.test(resource.kilometrageLimit))
      const kilometrageLimits = filteredKilometrageLimits.map(resource => parseFloat(resource.kilometrageLimit, 10))

      if (_.blank(kilometrageLimits)) return 0

      return Math.max(...kilometrageLimits)
    },

    highestTimeLimit() {
      const filteredTimeLimits = this.collection.filter(resource => /^[0-9]+$/.test(resource.timeLimit))
      const timeLimits = filteredTimeLimits.map(resource => parseFloat(resource.timeLimit, 10))

      if (_.blank(timeLimits)) return 0

      return Math.max(...timeLimits)
    }
  },

  methods: {
    initialParamsForNewResource() {
      const index = this.value.length + 1

      return { name: this.$t(".fields.name.value", { index }) }
    },

    onBatchMaintenances(maintenances) {
      const nestedResource = [...this.value, ...maintenances]
      this.$emit("input", nestedResource)
    }
  }
}

</script>

// Base
import BaseRequests from "./Base"

// Models
import models from "@/models"
const { IssuedMailDelivery, ServiceOrder } = models

class ServiceOrdersRequests extends BaseRequests {
  static get selector() {
    return "serviceOrders"
  }

  all({ params = {}, options = {} } = {}) {
    return this.action({
      method:  "get",
      url:     "/service_orders",
      params,
      options: {
        model: ServiceOrder,

        ...options
      }
    })
  }

  allSupplierIssuedMailDeliveries({ serviceOrderId, params = {}, options = {} }) {
    this.verifyData("allSupplierIssuedMailDeliveries", { serviceOrderId })

    return this.action({
      method:  "get",
      url:     `/service_orders/${serviceOrderId}/issued_mail_deliveries`,
      params,
      options: {
        model: IssuedMailDelivery,

        ...options
      }
    })
  }

  resendSupplierEmail({ serviceOrderId, params = {}, options = {} }) {
    this.verifyData("resendSupplierEmail", { serviceOrderId })

    return this.action({
      method: "post",
      url:    `/service_orders/${serviceOrderId}/issued_mail_deliveries`,
      params,
      options
    })
  }

  allFleetManagerIssuedMailDeliveries({ serviceOrderId, params = {}, options = {} }) {
    this.verifyData("resendSupplierEmail", { serviceOrderId })
    if (_.blank(serviceOrderId)) {
      throw new Error('NewSDK ServiceOrders - #allFleetManagerIssuedMailDeliveries() - "serviceOrderId" is a required prop')
    }

    return this.action({
      method:  "get",
      url:     `/service_orders/${serviceOrderId}/fleet_manager_issued_mail_deliveries`,
      params,
      options: {
        model: IssuedMailDelivery,

        ...options
      }
    })
  }

  resendFleetManagerEmail({ serviceOrderId, params = {}, options = {} }) {
    this.verifyData("resendFleetManagerEmail", { serviceOrderId })
    if (_.blank(serviceOrderId)) {
      throw new Error('NewSDK ServiceOrders - #resendFleetManagerEmail() - "serviceOrderId" is a required prop')
    }

    return this.action({
      method: "post",
      url:    `/service_orders/${serviceOrderId}/fleet_manager_issued_mail_deliveries`,
      params,
      options
    })
  }

  allExecutedMailDeliveries({ serviceOrderId, params = {}, options = {} }) {
    this.verifyData("allExecutedMailDeliveries", { serviceOrderId })

    return this.action({
      method:  "get",
      url:     `/service_orders/${serviceOrderId}/executed_mail_deliveries`,
      params,
      options: {
        model: IssuedMailDelivery,

        ...options
      }
    })
  }

  resendExecutedEmail({ serviceOrderId, params = {}, options = {} }) {
    this.verifyData("resendExecutedEmail", { serviceOrderId })

    return this.action({
      method: "post",
      url:    `/service_orders/${serviceOrderId}/executed_mail_deliveries`,
      params,
      options
    })
  }

  resendDiagnosticRequestEmail({ serviceOrderId, params = {}, options = {} }) {
    this.verifyData("resendDiagnosticRequestEmail", { serviceOrderId })

    return this.action({
      method: "post",
      url:    `/service_orders/${serviceOrderId}/diagnostic_request_mail_deliveries`,
      params,
      options
    })
  }

  allDiagnosticRequestMailDeliveries({ serviceOrderId, params = {}, options = {} }) {
    this.verifyData("allDiagnosticRequestMailDeliveries", { serviceOrderId })

    return this.action({
      method:  "get",
      url:     `/service_orders/${serviceOrderId}/diagnostic_request_mail_deliveries`,
      params,
      options: {
        model: IssuedMailDelivery,

        ...options
      }
    })
  }

  resendDiagnosticRequestFleetManagerEmail({ serviceOrderId, params = {}, options = {} }) {
    this.verifyData("resendDiagnosticRequestFleetManagerEmail", { serviceOrderId })

    return this.action({
      method: "post",
      url:    `/service_orders/${serviceOrderId}/fleet_manager_diagnostic_request_mail_deliveries`,
      params,
      options
    })
  }

  allDiagnosticRequestFleetManagerIssuedMailDeliveries({ serviceOrderId, params = {}, options = {} }) {
    this.verifyData("allDiagnosticRequestFleetManagerIssuedMailDeliveries", { serviceOrderId })

    return this.action({
      method:  "get",
      url:     `/service_orders/${serviceOrderId}/fleet_manager_diagnostic_request_mail_deliveries`,
      params,
      options: {
        model: IssuedMailDelivery,

        ...options
      }
    })
  }
}

export default ServiceOrdersRequests

<script>

// Extends
import AttachmentCard from "@/components/attachments/attachment-card.vue"

// Components
import AttachmentPreview from "./service-order-service-attachment-preview.vue"

export default {
  components: {
    AttachmentPreview
  },

  extends: AttachmentCard,

  props: {
    serviceOrderServiceId: { type: [String, Number], default: null }
  },

  data() {
    return {
      destroying: false
    }
  },

  computed: {
    extraPreviewProps() {
      return {
        serviceOrderServiceId: this.serviceOrderServiceId
      }
    }
  },

  methods: {
    async destroyRequest() {
      return this.$newSdk.serviceOrders.drafts.services.attachments.destroy({
        serviceOrderServiceId: this.serviceOrderServiceId,
        attachmentId:          this.attachment.id
      })
    }
  }
}

</script>

// Base
import BaseRequests from "./Base"

// Models
import models from "@/models"
const { Vehicle } = models

class VehicleRequests extends BaseRequests {
  static get selector() {
    return "vehicles"
  }

  all({ params = {}, options = {} } = {}) {
    return this.action({
      method:  "post",
      url:     `/vetor/vehicles/search`,
      params,
      options: {
        model: Vehicle,

        ...options
      }
    })
  }

  find({ licensePlateOrId, params = {}, options = {} } = {}) {
    this.verifyData("find", { licensePlateOrId })

    return this.action({
      method:  "get",
      url:     `/vetor/vehicles/${licensePlateOrId}`,
      params,
      options: {
        model: Vehicle,

        ...options
      }
    })
  }
}

export default VehicleRequests

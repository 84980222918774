<style lang="scss" scoped>
.maintenance-table-settings-modal {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px 24px;
}

.input-fields {
  --h-gap: 24px;
  --cols: 2;
  display: flex;
  gap: 16px var(--h-gap);
  flex-wrap: wrap;

  > .field {
    width: calc((100% - (var(--h-gap) * (var(--cols) - 1))) / var(--cols));
  }

  .field.required ::v-deep {
    label span::after,
    .label::after {
      background: $primary;
      border-radius: 2px;
      content: ' ';
      display: inline-block;
      height: 4px;
      margin: 2px 4px;
      width: 4px;
    }
  }
}

.alert {
  align-items: flex-start;
  background: $light-gray;
  border-radius: 8px;
  color: $dark-gray;
  display: flex;
  font-family: $primary-font;
  font-weight: 400;
  font-size: 14px;
  gap: 16px;
  line-height: 1.5;
  padding: 16px;

  .icon {
    flex-shrink: 0;
    font-size: 16px;
    width: 16px;
  }

  .message {
    flex-grow: 1;
  }

  // ALERT THEMES
  &.alert-info {
    background: $light-info;

    .icon {
      color: $info;
    }
  }
}

</style>

<template lang="pug">
  app-modal(
    :confirm-label="$t('.btn.confirm.label')"
    :confirm-label-loading="$t('.btn.confirm.label')"
    footer
    :heading="$t('.heading')"
    :loading="submitting"
    :width="690"
    @close="onClose"
    @confirm="submit"
  )
    form.maintenance-table-settings-modal(@submit.prevent="submit")
      .section.input-fields
        input-field.field.required(
          v-model="resource.kilometrageLimitStep"
          :errors="errors.kilometrageLimitStep"
          :label="$t('.fields.kilometrageLimitStep.label')"
          :loading="submitting"
          :placeholder="$t('.fields.kilometrageLimitStep.placeholder')"
          name="maintenanceTableSettings[kilometrageLimitStep]"
        )

      .alert.alert-info
        .icon.fas.fa-info-circle
        .message {{ $t(".kilometrage-limit-step-change-alert.message") }}
</template>

<script>
import models from "@/models"
import $validate from "@/lib/validate"

const { VehicleModelGroup, VehicleModelGroupMaintenanceTableSettings } = models

export default {
  props: {
    vehicleModelGroup: { type: VehicleModelGroup, required: true }
  },

  emits: ["close"],

  data() {
    return {
      errors: {},

      i18nScope: "vehicle-model-groups.maintenance-tables.maintenance-table-settings-modal",

      initialData: null,

      resource: new VehicleModelGroupMaintenanceTableSettings(),

      submitRequest: null,

      submitting: false
    }
  },

  computed: {
    maintenanceTableSettings: {
      get()      { return this.resource },
      set(value) { this.resource = value }
    }
  },

  mounted() {
    this.initialData = this.serialize()
  },

  methods: {
    cancel() {
      if (this.submitRequest) {
        this.submitRequest.cancel()
        this.submitRequest = null
      }
    },

    checkDirty() {
      const currentData = this.serialize()

      return !_.isMatch(this.initialData, currentData)
    },

    async launchConfirmDialog() {
      const confirmed = await this.$confirm({
        heading: this.$t("mixins.form.confirmPageChange.heading"),
        message: this.$t("mixins.form.confirmPageChange.message"),

        actions: {
          confirm: this.$t("mixins.form.confirmPageChange.confirm"),
          cancel:  this.$t("mixins.form.confirmPageChange.cancel")
        }
      })

      if (confirmed) this.$emit("close")
    },

    notifyError() {
      this.$notifications.error(this.$t(".notifications.submit.error"))
    },

    notifySubmitting() {
      this.$notifications.warn(this.$t("requests.running.warning"))
    },

    notifySuccess({ to }) {
      this.$notifications.info(this.$t(".notifications.submit.success"))
    },

    notifyValidationError() {
      this.$notifications.error(this.$t(".notifications.validation.error"))
    },

    onClose() {
      if (this.submitting) {
        this.notifySubmitting()
        return
      }

      if (this.checkDirty()) {
        this.launchConfirmDialog()
        return
      }

      this.$emit("close")
    },

    serialize() {
      return this.resource.$serialize()
    },

    async submit() {
      let request

      try {
        this.submitting = true

        if (!(await this.validate())) {
          this.notifyValidationError()
          return
        }

        const params = this.serialize()

        this.cancel()

        request = this.$newSdk.vehicleModelGroups.maintenanceTableSettings.update({
          vehicleModelGroupId: this.vehicleModelGroup.$id,
          params
        })

        this.submitRequest = request

        await request

        this.notifySuccess(params)

        this.$emit("close")
      }
      catch (error) {
        if (error.cancelled) return

        this.notifyError()
      }
      finally {
        this.submitting = false
        if (request === this.submitRequest) this.submitRequest = null
      }
    },

    async validate() {
      const errors = await $validate(this.resource)

      this.errors = errors
      return !_.present(errors)
    }
  }
}
</script>

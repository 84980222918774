<style lang="scss" scoped>

.extra-data-indicator {
  cursor: pointer;
  position: relative;

  .icon {
    color: $orange;
  }

  .data-indicator {
    width: 17px;
    height: 14px;
    background-color: $error;
    position: absolute;
    padding: 0 2px;
    border: 1px solid $white;
    border-radius: 24px;
    top: -4px;
    right: -8px;

    .count {
      font-family: $primary-monospace-font;
      font-weight: 700;
      font-size: 10px;
      color: $white;
      user-select: none;
    }
  }
}

</style>


<template lang="pug">

.extra-data-indicator
  i.icon.far.fa-paperclip
  .data-indicator.flex.center.vertical-center
    span.count(v-if="count > 0") {{ count }}

</template>


<script>

export default {
  name: "ExtraDataIndicator",

  props: {
    count: { type: Number, required: true }
  },

  data() {
    return {
      i18nScope: "components.extra-data-indicator"
    }
  }
}

</script>

// XXX: Deprecated. Use lib/validate.js
// TODO: Refatorar componentes que usam esse arquivo para excluí-lo

import Vue from "vue"
import { lib as movidaCommonLib } from "movida-common.vue"


const { parseModelJsErrors } = movidaCommonLib.ParseErrors

async function _validate(resource, nested) {
  let errors = {}

  if (resource._destroy) return errors

  await resource.$validate()
  errors = parseModelJsErrors(resource.$errors)

  if (_.present(nested)) {
    for (const [attribute, value] of Object.entries(nested)) {
      const nestedAttribute = resource[attribute]

      if (Array.isArray(nestedAttribute)) {
        Vue.set(errors, `${attribute}Attributes`, {})

        for (const nestedResource of nestedAttribute) {
          // XXX Não podemos usar o index, pois isso impacta na `key` do `v-for`
          let position = nestedResource.id || nestedResource.tempId
          Vue.set(
            errors[`${attribute}Attributes`],
            position,
            await _validate(nestedResource, value)
          )
        }
      }
      else if (nestedAttribute) {
        Vue.set(errors, `${attribute}Attributes`, await _validate(nestedAttribute, value))
      }
    }
  }
  return errors
}

const Validatable = {
  data() {
    return {
      errors: {}
    }
  },

  methods: {
    async validate(resource) {
      const hasResource = _.present(resource)
      const hasValidate = typeof _.get(resource, "$validate") === "function"

      if (!hasResource || !hasValidate) return true

      this.errors = await _validate(resource, this.nestedAttributes)

      return _.blank(_.deleteBlanks(this.errors))
    }
  }
}

export default Validatable

<style lang="scss" scoped>

.app-outdated-banner {
  height: $outdated-banner-height;
  background-color: $orange;
  color: $white;
  font-weight: 400;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}


.app-outdated-banner-enter-active {
  animation: app-outdated-banner-height .5s;
}

.app-outdated-banner-leave-active {
  animation: app-outdated-banner-height .5s reverse;
}

@keyframes app-outdated-banner-height {
  0% {
    height: 0;
  }

  100% {
    height: $outdated-banner-height;
  }
}


</style>


<template lang="pug">

  transition(name="app-outdated-banner", appear)
    .app-outdated-banner(v-if="show", @click="$emit('update')")
      span {{ $t(".message") }}

</template>


<script>

export default {
  name: "AppOutdatedBanner",

  props: {
    show: { type: Boolean, default: false }
  },

  data() {
    return {
      i18nScope: "components.app-outdated-banner"
    }
  }
}

</script>

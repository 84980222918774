<style lang="scss" scoped>

.template-download-button {
  user-select: none;

  .download-area {
    padding: 16px;
    border: 1px solid $light-gray-3;
    border-radius: 16px;
    cursor: pointer;

    &.disabled {
      cursor: not-allowed;
      background-color: $light-gray;
    }

    .circle {
      width: 72px;
      height: 72px;
      background-color: $light-gray-2;
      border-radius: 50%;

      .icon {
        font-size: 36px;
        font-weight: 100;
        color: $gray-3;
      };
    }

    .name {
      margin-top: 8px;
      font-family: $secondary-font;
      font-weight: 400;
    }
  }

  .progress-content {
    margin-top: 24px;
    width: 100%;

    .progress-bar {
      height: 8px;
      background-color: $light-gray-3;
      border-radius: 4px;
      margin-right: 8px;
      width: var(--progress);
    }

    .percentage {
      font-family: $secondary-font;
      font-size: 12px;
      font-weight: 400;
      width: 30px;
      text-align: right;
    }
  }
}

</style>


<template lang="pug">

  .template-download-button(:style="cssVars")
    .download-area.flex.column-direction.vertical-center(
      :class="{ disabled }",
      @click="download"
    )
      .circle.flex.vertical-center.center
        i.icon.fal(:class="{ [icon]: true }")
      .name
        span {{ $t('.label') }}
    .progress-content.flex.vertical-center.space-between(v-if="downloading")
      .progress-bar
      span.percentage {{ `${percentage}%` }}

</template>


<script>

export default {
  props: {
    dealId:   { type: [String, Number], required: true },
    disabled: { type: Boolean, default: false }
  },

  data() {
    return {
      i18nScope: "suppliers.deals.products.index.download-button",

      downloading: false,

      progress: {
        loaded: 0,
        total:  0
      }
    }
  },

  computed: {
    // eslint-disable-next-line vue/return-in-computed-property
    fileName() {
      throw new Error("DownloadButton - #fileName - Not implemented yet")
    },

    // eslint-disable-next-line vue/return-in-computed-property
    icon() {
      throw new Error("DownloadButton - #icon - Not implemented yet")
    },

    percentage() {
      const { total, loaded } = this.progress

      // Evita divisão por 0
      if (!total) return 0

      let value = (loaded / total) * 100
      return parseInt(value, 10)
    },

    cssVars() {
      return {
        "--progress": `${this.percentage}%`
      }
    }
  },

  watch: {
    downloading() {
      this.$emit("downloading", this.downloading)
    }
  },

  methods: {
    async download() {
      if (this.disabled) return

      try {
        this.downloading = true

        const { data } = await this.request()

        this.startDownload(data)
      }
      catch (err) {
        this.$err.log(err)
        this.$notifications.error(this.$t(".notifications.fetch.failure"))
      }
      finally {
        this.downloading = false

        this.progress = {
          loaded: 0,
          total:  0
        }
      }
    },

    request() {
      throw new Error("DownloadButton - #request() - Not implemented yet")
    },

    onDownloadProgress({ loaded, total }) {
      this.progress = { loaded, total }
    },

    startDownload(data) {
      const url = window.URL.createObjectURL(new Blob([data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      }))
      const link = document.createElement("a")

      link.href = url
      link.setAttribute("download", this.fileName)

      document.body.appendChild(link)
      link.click()
      link.parentNode.removeChild(link)
    }
  }
}

</script>

import Base from "../base"

class MovidaServiceOrderAttachment extends Base {
  static get attrs() {
    return _.uniq([
      ...super.attrs,
      "fileName",
      "name",
      "category"
    ])
  }

  static get enums() {
    return {
      category: {
        audio:        "audio",
        compressed:   "compressed",
        doc:          "doc",
        email:        "email",
        image:        "image",
        pdf:          "pdf",
        spreadsheet:  "spreadsheet",
        html:         "html",
        text:         "text",
        video:        "video",
        presentation: "presentation",
        file:         "file"
      }
    }
  }

  get icon() {
    switch (this.category) {
      case "audio": return "fa-volume"
      case "compressed": return "fa-file-archive"
      case "doc": return "fa-file"
      case "email": return "fa-envelope"
      case "image": return "fa-image"
      case "pdf": return "fa-file-pdf"
      case "spreadsheet": return "fa-file-excel"
      case "html":
      case "text": return "fa-file"
      case "video": return "fa-video"
      case "presentation": return "fa-presentation"
      case "file": default: return "fa-file"
    }
  }

  get color() {
    switch (this.category) {
      case "audio": return "#C291D3"
      case "compressed": return "#7D61CE"
      case "doc": return "#959595"
      case "email": return "#959595"
      case "image": return "#CE9C61"
      case "pdf": return "#AD6767"
      case "spreadsheet": return "#93AD67"
      case "html":
      case "text": return "#959595"
      case "video": return "#677BAD"
      case "presentation": return "#F04420"
      case "file": default: return "#959595"
    }
  }
}

export default MovidaServiceOrderAttachment

import BaseRequests from "../../Base"
import models from "@/models"


const { MovidaServiceOrderTicketMessage } = models

class MonitoringServiceOrderTicketMessageRequests extends BaseRequests {
  static get selector() {
    return "monitoring.serviceOrders.ticketMessages"
  }

  all({ serviceOrderId, params = {}, options = {} } = {}) {
    this.verifyData("all", { serviceOrderId })

    return this.action({
      method:  "get",
      url:     `/monitoring/service_orders/${serviceOrderId}/ticket_messages`,
      params,
      options: {
        model: MovidaServiceOrderTicketMessage,

        ...options
      }
    })
  }
}

export default MonitoringServiceOrderTicketMessageRequests

import { mixin } from "@caiena/model"

import Base from "./base"
import Discardable from "./mixins/discardable"


class QuoteService extends mixin(Base, [Discardable]) {
  static get attrs() {
    return _.uniq([
      ...super.attrs,

      "description",
      "quantity",
      "price",
      "serviceId",
      "quoteId",
      "warranty"
    ])
  }

  static get relations() {
    return {
      attachments: { type: "hasMany", model: "Attachment" },
      service:     { type: "belongsTo", model: "MovidaService" }
    }
  }

  static get constraints() {
    return {
      serviceId: { presence: true },
      quantity:  { presence: true },

      price: {
        presence(value, attrs) {
          return !attrs.warranty
        },

        absence(value, attrs) {
          return !!attrs.warranty
        }
      }
    }
  }

  static get virtuals() {
    return [
      "totalPrice"
    ]
  }

  get totalPrice() {
    return _.present(this.quantity) && _.present(this.price)
      ? this.quantity * this.price
      : null
  }
}


export default QuoteService

.<style lang="scss" scoped>

.vehicle-model-groups-table-header {
  display: grid;
  grid-template-columns: 70px 1fr 1fr 120px 64px;
  background-color: $light-gray;
  height: 40px;
  border-bottom: 1px solid $light-gray-3;
  border-top: 1px solid $light-gray-3;

  &.loading {
    border-bottom: 2px solid $light-orange-2;
  }

  .unsortable {
    padding: 0 12px;
  }

  .column {
    font-size: 12px;

    .text {
      font-family: $secondary-font;
      font-size: 14px;
      color: $dark-gray;
      font-weight: 400;
    }

    .loader-icon {
      font-size: 16px;
      color: $orange;
    }
  }
}

</style>


<template lang="pug">

  .vehicle-model-groups-table-header(:class="{ loading }")
    .column(data-testid="idColumn")
      sortable-table-header-column(
        sort-param="id",
        :sort-order="sortOrder",
        :active="isSortedBy('id')",
        @sort="param => $emit('sort', param)"
      )
        span.text #

    .column(data-testid="nameColumn")
      sortable-table-header-column(
        sort-param="name",
        :sort-order="sortOrder",
        :active="isSortedBy('name')",
        @sort="param => $emit('sort', param)"
      )
        span.text {{ $t('.columns.name.label') }}

    .column.unsortable.flex.vertical-center(data-testid="descriptionColumn")
      span.text {{ $t('.columns.description.label') }}

    .column(data-testid="vehicleModelGroupsCountColumn")
      sortable-table-header-column(
        sort-param="vehicle_models_count",
        :sort-order="sortOrder",
        :active="isSortedBy('vehicle_models_count')",
        @sort="param => $emit('sort', param)"
      )
        span.text {{ $t('.columns.vehicle-models-count.label') }}

    .column.flex.vertical-center.center
      template(v-if="loading")
        i.loader-icon.fas.fa-spin.fa-spinner

</template>


<script>

export default {
  props: {
    loading:   { type: Boolean, default: false },
    sortParam: { type: String, required: true },
    sortOrder: { type: String, required: true }
  },

  data() {
    return {
      i18nScope: "vehicle-model-groups.index.vehicle-model-groups-table-header"
    }
  },

  methods: {
    isSortedBy(param) {
      return this.sortParam === param
    }
  }
}

</script>

<style scoped lang="scss">

.day-row {
  padding: 8px;
  min-height: 60px;
  border: 1px solid $light-gray-3;
  border-bottom-width: 0px;

  &:first-child {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }

  &:last-child {
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    border-bottom-width: 1px;
  }
}

.day-label {
  min-width: 154px;
}

.button-icon {
  font-size: 20px;
}

.day-checkbox {
  margin-right: 8px;
}

.field + .field {
  margin-left: 24px;
}

.field + .add-btn {
  margin-left: 24px;
}

.btn-label {
  display: block;
  margin-left: 4px;
}

.time {
  font-weight: 500;
}
.time + .time {
  margin-left: 42px;
}

.business-hour {
  min-width: 150px;
  justify-content: center;
}

.business-hour + .business-hour {
  margin-left: 24px;
}

.business-hour + .add-btn {
  margin-left: 24px;
}

.diamond-icon {
  margin: 0 4px;
  font-size: 4px;
  color: $gray-3;
}

</style>


<template lang="pug">

  .day-row.flex.vertical-center

    span.day-label {{ $t(`.weekdays.${weekday}`) }}

    .business-hour.flex(
      v-for="(resource, key) in businessHoursByShift",
      :key="`business-hour-${weekday}-${key}`",
    )

      template(v-if="resource")
        .time.flex
          span.start-at {{ resource.startAt }}
          i.diamond-icon.spacer.fa.fa-diamond.flex.center.vertical-center
          span.end-at {{ resource.endAt }}

      template(v-else)
        span.global-empty-text.global-empty-color {{ $t('.empty') }}

</template>


<script>

export default {
  props: {
    businessHours: { type: Array, default: () => [] },
    weekday:       { type: String, required: true }
  },

  data() {
    return {
      i18nScope: "suppliers.details.business-hours-row"
    }
  },

  computed: {
    businessHoursByShift() {
      if (_.blank(this.businessHours)) return { morning: null, afternoon: null, evening: null }

      return this.businessHours.reduce((businessHours, businessHour) => {
        if (businessHour.weekday !== this.weekday || businessHour.$markedForDestruction) return businessHours

        businessHours[businessHour.shift] = businessHour

        return businessHours
      }, { morning: null, afternoon: null, evening: null })
    }
  }
}

</script>

<style lang="scss" scoped>

.sortable-table-header-column {
  cursor: pointer;
  height: 100%;
  width: 100%;
  padding: 0 12px;

  &:hover {
    background-color: $light-gray-3;
  }

  .sort-icon {
    font-size: 18px;
    color: $orange;
    margin-left: 8px;
  }
}

</style>


<template lang="pug">

  .sortable-table-header-column.flex.vertical-center(@click="sort", data-testid="sortableTableHeader")
    slot
    template(v-if="active")
      i.sort-icon.fas(:class="icon")

</template>


<script>

export default {
  name: "SortableTableHeaderColumn",

  props: {
    sortParam: { type: String, required: true },
    sortOrder: { type: String, required: true },
    active:    { type: Boolean, default: false }
  },

  data() {
    return {
      i18nScope: "components.sortable-table-header-column"
    }
  },

  computed: {
    icon() {
      let direction = this.sortOrder === "asc" ? "down" : "up"
      return `fa-long-arrow-${direction}`
    }
  },

  methods: {
    sort() {
      let order

      if (!this.active) order = "asc"
      else order = this.sortOrder === "asc" ? "desc" : "asc"

      this.$emit("sort", `${this.sortParam}:${order}`)
    }
  }
}

</script>

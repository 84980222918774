<style lang="scss" scoped>

.item-show {
  .section {
    padding: 16px 24px;

    & + .section {
      border-top: 1px solid $light-gray-4;
    }

    .section-header {
      margin-bottom: 8px;

      .icon-container {
        width: 16px;
        height: 16px;
        margin-right: 8px;

        .icon {
          font-size: 14px;
        }
      }

      .section-title {
        font-family: $secondary-font;
        font-weight: 500;
        font-size: 16px;
      }

      .diamond-icon {
        font-size: 4px;
        color: $gray-3;
        margin: 0 8px;
      }

      .count {
        font-family: $secondary-font;
        font-weight: 400;
        color: $gray-3;
      }
    }

    .attachments {
      grid-template-columns: minmax(322px, 1fr) minmax(322px, 1fr);
      gap: 8px 24px;
    }

    .empty {
      background-color: $light-gray-2;
      height: 32px;
      border-radius: 4px;

      .message {
        font-size: 12px;
        font-style: italic;
      }
    }

    .description {
      font-size: 14px;
    }
  }
}

</style>


<template lang="pug">

app-modal(
  :heading="name",
  :width="716",
  @close="onClose"
)
  .item-show
    .section
      .section-header.flex.vertical-center
        .icon-container.flex.vertical-center.center
          i.icon.far.fa-paperclip
        h3.section-title {{ $t(".attachments.label") }}
        template(v-if="hasAttachments")
        i.diamond-icon.fas.fa-diamond
        span.count {{ $t(".attachments.count", { count: item.attachments.length }) }}

      .attachments.grid(v-if="hasAttachments")
        template(v-for="attachment in item.attachments")
          narrow-quote-attachment-card(
            :attachment="attachment",
            :editable="false",
            :deletable="false",
            :quote-id="item.quoteId"
          )

      template(v-else)
        .empty.flex.center.vertical-center
          span.message {{ $t(".attachments.empty") }}

    .section
      .section-header.flex.vertical-center
        .icon-container.flex.vertical-center.center
          i.icon.far.fa-message-lines
        h3.section-title {{ $t(".description.label") }}

      template(v-if="hasDescription")
        span.description.preserve-whitespace {{ item.description }}

      template(v-else)
        .empty.flex.center.vertical-center
          span.message {{ $t(".description.empty") }}

</template>


<script>

export default {
  props: {
    item: { type: Object, required: true },
    name: { type: String, required: true }
  },

  data() {
    return {
      i18nScope: "monitoring.quotes.reviews.item-show"
    }
  },

  computed: {
    hasAttachments() {
      return _.present(this.item?.attachments)
    },

    hasDescription() {
      return _.present(this.item?.description)
    }
  },

  methods: {
    onClose() {
      this.$emit("close")
    }
  }
}

</script>

<style scoped lang="scss">

.service-row {
  display: grid;
  grid-template-columns: 70px 60px 1fr 80px 80px 64px;

  &.loading {
    .column {
      &.id-column {
        .id-tag {
          color: $gray-2;
        }
      }

      &.sap-column {
        ::v-deep.outside {
          fill: $gray-2;
        }
      }

      &.actions-column {
        ::v-deep .movida-common__app-button {
          color: $gray-2;

          &:hover {
            color: $orange;
          }
        }
      }

      .text,
      .link ::v-deep .app-span {
        color: $gray-2;
      }
    }
  }

  .column {
    padding: 0 12px;
    border-bottom: 1px solid $light-gray-3;
    height: 48px;
    overflow: hidden;

    .content {
      width: 100%;
      overflow: hidden;
    }

    .labor-cost-linked {
      width: 18px;
      height: 18px;
    }

    &.models-count-column {
      .text {
        font-family: $secondary-font;
      }

      .car-icon {
        margin-right: 8px;
        color: $gray-2;
      }
    }

    ::v-deep .movida-common__app-button {
      white-space: unset;
    }

    .button {
      overflow: hidden;
    }

    .description {
      font-family: $primary-font;
      font-weight: 400;
      font-size: 12px;
      color: $gray-2;
      text-align: initial;
    }

    .text,
    .link ::v-deep .app-span {
      font-family: $primary-font;
      font-weight: 400;
      font-size: 14px;
      color: $dark-gray;
      text-align: initial;
    }

    .link ::v-deep .app-span:hover {
      color: $orange;
    }
  }
}

</style>


<template lang="pug">

  .service-row(:class="{ loading }")
    .column.id-column.flex.vertical-center
      id-tag.id-tag(
        :value="service.id",
        data-testid="serviceId"
      )

    .column.flex.vertical-center
      service-type-icon(:type="service.type")

    .column.flex.vertical-center
      .content.column-direction
        app-button.button(
          theme="link",
          :to="showRoute"
        )
          app-span.link(
            :value="service.name",
            crop,
            data-testid="serviceName"
          )
        template(v-if="service.description")
          app-span.description(
            :value="service.description",
            crop,
            data-testid="serviceDescription"
          )

    .column.sap-column.flex.vertical-center
      vehicle-issues-preview(
        :vehicle-issue-solutions-count="service.vehicleIssueSolutionsCount"
        :service-id="service.id"
      )

    .column.sap-column.flex.vertical-center
      labor-cost-linked-indicator.labor-cost-linked(
        v-if="service.laborCostLinked",
        compact,
        data-testid="laborCostLinkedIndicator"
      )

    .column.actions-column.flex.vertical-center.flex
      popover-menu(
        v-if="popoverMenuAuthorization",
        :show.sync="showPopover",
        data-testid="popoverMenu"
      )
        popover-menu-button(
          v-if="$can('read', 'Service')",
          icon="fal fa-eye",
          :to="showRoute",
          :text="$t('.actions.btn.show.label')",
          @close-menu="showPopover = false"
        )
        popover-menu-button(
          v-if="$can('update', 'Service')",
          icon="fal fa-pencil",
          :to="{ name: 'editService', params: { id: service.id }}",
          :text="$t('.actions.btn.edit.label')",
          @close-menu="showPopover = false"
        )
        destroy-action.destroy-button.popover-menu-button(
          :id="service.id",
          :name="service.name",
          size="normal",
          border="square",
          @click.native="showPopover = false"
          @destroy="$emit('destroy')",
          data-test="destroyAction"
        )
          .icon-content
            i.icon.fal.fa-trash-alt
          span {{ $t('.actions.btn.destroy.label') }}

</template>


<script>

// Local Components
import DestroyAction from "../show/destroy-action"
import LaborCostLinkedIndicator from "../components/labor-cost-linked-indicator"
import VehicleIssuesPreview from "../components/vehicle-issues-preview.vue"

export default {
  components: {
    DestroyAction,
    LaborCostLinkedIndicator,
    VehicleIssuesPreview
  },

  props: {
    service:     { type: Object, default: () => ({}) },
    loading:     { type: Boolean, default: false },
    showActions: { type: Boolean, default: true }
  },

  data() {
    return {
      i18nScope: "services.index.service-row",

      showPopover: false
    }
  },

  computed: {
    popoverMenuAuthorization() {
      return ["read", "update", "destroy"].some(action => this.$can(action, "Service"))
    },

    showRoute() {
      if (!this.$can("read", "Service")) return null

      return {
        name:   "service",
        params: { id: this.service.id }
      }
    }
  }
}

</script>

<style lang="scss" scoped>

.construction-badge {
  border: 1px solid $dark-warning;
  border-radius: 32px;
  padding: 4px 8px;
  color: $dark-gray;
  font-family: $secondary-font;
  font-size: 12px;
  font-weight: 400;
  display: inline-block;

  .icon {
    color: $gray-3;
    margin-right: 8px;
  }
}

</style>


<template lang="pug">

  .construction-badge
    i.icon.fas.fa-hard-hat
    span {{ $t('.message') }}

</template>


<script>

export default {
  data() {
    return {
      i18nScope: "tickets.drafts.components.construction-badge"
    }
  }
}

</script>

import Base from "./Base"

class Stores extends Base {
  list({ params = {} } = {}) {
    return this.action({
      method: "get",
      url:    "/stores",
      params
    })
  }

  get({ id, params = {} }) {
    if (_.blank(id)) {
      throw new Error('SDK Stores - #get() - "id" is a required prop')
    }

    return this.action({
      method: "get",
      url:    `/stores/${id}`,
      params
    })
  }

  update({ id, params = {} } = {}) {
    if (_.blank(id)) {
      throw new Error('SDK Stores - #update() - "id" is a required prop')
    }

    return this.action({
      method: "patch",
      url:    `/stores/${id}`,
      params
    })
  }

  listServiceSupports({ id, params = {} }) {
    if (_.blank(id)) {
      throw new Error('SDK Stores - #listServiceSupports() - "id" is a required prop')
    }

    return this.action({
      method: "get",
      url:    `/stores/${id}/service_supports`,
      params
    })
  }

  createServiceSupport({ id, params = {} } = {}) {
    if (_.blank(id)) {
      throw new Error('SDK Stores - #createServiceSupport() - "id" is a required prop')
    }

    return this.action({
      method: "post",
      url:    `/stores/${id}/service_supports`,
      params
    })
  }

  destroyServiceSupport({ id, serviceTypeSlug, params = {} } = {}) {
    if (_.blank(id)) {
      throw new Error('SDK Stores - #destroyServiceSupport() - "id" is a required prop')
    }

    if (_.blank(serviceTypeSlug)) {
      throw new Error('SDK Stores - #destroyServiceSupport() - "serviceTypeSlug" is a required prop')
    }

    return this.action({
      method: "delete",
      url:    `/stores/${id}/service_supports/${serviceTypeSlug}`,
      params
    })
  }

  listAvailableSuppliers({ id, params = {} }) {
    if (_.blank(id)) {
      throw new Error('SDK Stores - #listAvailableSuppliers() - "id" is a required prop')
    }

    return this.action({
      method: "get",
      url:    `/stores/${id}/available_suppliers`,
      params
    })
  }

  createSupplier({ id, supplierId, params = {} } = {}) {
    if (_.blank(id)) {
      throw new Error('SDK Suppliers - #createSupplier() - "id" is a required prop')
    }
    if (_.blank(supplierId)) {
      throw new Error('SDK Suppliers - #createSupplier() - "supplierId" is a required prop')
    }

    return this.action({
      method: "post",
      url:    `/stores/${id}/suppliers`,
      params: { ...params, supplier_id: supplierId }
    })
  }

  listTickets({ id, params = {} }) {
    if (_.blank(id)) {
      throw new Error('SDK Stores - #listTickets() - "id" is a required prop')
    }

    return this.action({
      method: "get",
      url:    `/stores/${id}/tickets`,
      params
    })
  }

  getTicket({ id, ticketId, params = {} }) {
    if (_.blank(id)) {
      throw new Error('SDK Stores - #getTicket() - "id" is a required prop')
    }

    if (_.blank(ticketId)) {
      throw new Error('SDK Stores - #getTicket() - "ticketId" is a required prop')
    }

    return this.action({
      method: "get",
      url:    `/stores/${id}/tickets/${ticketId}`,
      params
    })
  }

  getTicketServiceOrder({
    id, ticketId, serviceOrderId, params = {}
  }) {
    if (_.blank(id)) {
      throw new Error('SDK Stores - #getTicketServiceOrder() - "id" is a required prop')
    }

    if (_.blank(ticketId)) {
      throw new Error('SDK Stores - #getTicketServiceOrder() - "ticketId" is a required prop')
    }

    if (_.blank(serviceOrderId)) {
      throw new Error('SDK Stores - #getTicketServiceOrder() - "serviceOrderId" is a required prop')
    }

    return this.action({
      method: "get",
      url:    `/stores/${id}/tickets/${ticketId}/service_orders/${serviceOrderId}`,
      params
    })
  }
}

export default Stores

<style lang="scss" scoped>

.vehicle-model-group-select-list {
  .supplier-load-error {
    margin-right: 4px;
  }

  .kind-tabs {
    margin-bottom: 24px;
  }

  .clear-filters {
    position: relative;

    .filters-button {
      position: absolute;
      margin-top: 10px;
    }
  }

  .list {
    margin-top: 50px;

    .select-actions {
      .action + .action {
        margin-left: 24px;
      }
    }

    .table {
      margin-top: 16px;
    }
  }

  .paginator-content {
    padding-top: 24px;

    &.loading {
      max-width: 400px;
      margin: 0 auto;
    }
  }
}

</style>


<template lang="pug">

  .vehicle-model-group-select-list
    template(v-if="!hasSupplier")
      template(v-if="supplierLoadError")
        span.supplier-load-error {{ $t('.supplier-error.message') }}
        app-button(theme="link", @click="fetchSupplier") {{ $t('.supplier-error.retry') }}

      template(v-else)
        loading-lines(:lines="6")

    template(v-else)
      .kind-tabs.flex.center
        table-kind-tab-group(@select="onSelectKind", :selected="kind")

      search-form.search(
        :loading="fetching",
        :params="parsedQueryParams",
        @search="search"
      )

      .clear-filters
        filters-button.filters-button(
          v-if="hasFilters",
          @clear="clearFilters"
        )

      .list
        results-indicator(
          :page="parsedQueryParams.page",
          :per-page="pagination.perPage",
          :total="pagination.total",
          :loading="fetching"
        )
        .flex.space-between.vertical-bottom
          .result-tabs.flex
            index-filter-tab(
              icon="fa-cars",
              :label="$t('.filters.all.label')",
              :active="!showSelectedResources",
              :loading="initializing",
              :disabled="_fetching"
              @click="showSelectedResourcesFilter(false)"
            )
            index-filter-tab(
              icon="fa-check",
              :label="$t('.filters.selected.label', { count: totalSelected })",
              :active="showSelectedResources",
              :loading="initializing",
              :disabled="_fetching || (!hasSelectedOptions && !showSelectedResources)"
              @click="showSelectedResourcesFilter(true)"
            )

          .select-actions
            app-button.action(
              theme="link",
              :disabled="_fetching",
              @click="selectAll"
            ) {{ $t('.actions.selectAll.label') }}
            app-button.action(
              theme="link",
              :disabled="_fetching",
              @click="removeAll"
            ) {{ $t('.actions.removeAll.label') }}

        vehicle-model-group-maintenance-tables-table.table(
          :vehicle-model-group-maintenance-tables="vehicleModelGroupMaintenanceTables",
          :initializing="initializing",
          :loading="fetching",
          :sorted-by="parsedQueryParams.sort",
          :value="value",
          :errors="errors.maintenanceTableIds",
          @sort="onSortChange",
          @toggle-select="selectResource",
          @toggle-all="toggleAllOnPage"
        )

      .paginator-content(v-if="fetching || showPaginator", :class="{ loading: fetching }")
        paginator(
          :loading="fetching",
          :per-page="pagination.perPage",
          :total="pagination.total",
          :value="parsedQueryParams.page",
          :disabled="fetching",
          @input="onPageChange",
          data-testid="paginator"
        )

</template>


<script>

// View
import IndexView from "@/views/index-view"

// Mixins
import FetchMixin from "@/mixins/fetch-mixin"

// Components
import SearchForm from "./search-form.vue"
import TableKindTabGroup from "./table-kind-tab-group.vue"
import VehicleModelGroupMaintenanceTablesTable from "./vehicle-model-group-maintenance-tables-table.vue"

export default {
  components: {
    SearchForm,
    TableKindTabGroup,
    VehicleModelGroupMaintenanceTablesTable
  },

  extends: IndexView,

  mixins: [FetchMixin],

  props: {
    value:      { type: Array, default: () => [] },
    errors:     { type: Object, default: () => ({}) },
    supplierId: { type: [String, Number], required: true }
  },

  data() {
    return {
      i18nScope: "suppliers.service-supports.maintenance.vehicle-model-groups.new.vehicle-model-group-maintenance-tables-select-list",
      routeName: "newSupplierServiceSupportMaintenanceVehicleModelGroups",

      fetchingIds: false,
      kind:        null,
      resource:    [],

      // Query Mixin data
      queryParamsStructure: {
        q:                "string",
        vehicle_model_id: "string",
        ids:              "array",
        page:             "integer",
        sort:             "string"
      },

      autofetch: false,

      fetchingSupplier:  false,
      supplierLoadError: false,
      supplier:          null,

      // @override IndexView
      clearFiltersIgnoreList: ["ids"]
    }
  },

  computed: {
    vehicleModelGroupMaintenanceTables: {
      get() { return this.resource },
      set(value) { this.resource = value }
    },

    hasSupplier() {
      return _.present(this.supplier)
    },

    totalSelected() {
      return this.value.length
    },

    showSelectedResources() {
      return _.present(this.parsedQueryParams.ids)
    },

    hasSelectedOptions() {
      return _.present(this.value)
    },

    _fetching() {
      return this.fetching || this.fetchingIds
    }
  },

  created() {
    this.fetchSupplier()
  },

  methods: {
    parseRoute() {},

    async onSelectKind(kind) {
      if (kind === this.kind) return

      const isConfirmed = await this.$confirm({
        heading: this.$t("mixins.form.confirmPageChange.heading"),
        message: this.$t("mixins.form.confirmPageChange.message"),

        actions: {
          confirm: this.$t("mixins.form.confirmPageChange.confirm"),
          cancel:  this.$t("mixins.form.confirmPageChange.cancel")
        }
      }).catch(err => {
        console.error(err)
      })

      if (!isConfirmed) return

      this.$emit("input", [])
      this.kind = kind
      this.parsedQueryParams.ids = []
      this.search()
    },

    showSelectedResourcesFilter(value) {
      if (this.showSelectedResources === value) return

      this.parsedQueryParams.ids = value ? this.value : []
      this.search()
    },

    async fetchSupplier() {
      this.fetchingSupplier = true
      this.supplierLoadError = false

      try {
        const response = await this.$newSdk.suppliers.find({ supplierId: this.supplierId })

        const supplier = response.data
        this.kind = supplier.hasDealershipSector ? "dealership" : "multiservice"

        this.fetch()
        this.supplier = supplier

        this.$emit("supplier-loaded")
      }
      catch (error) {
        this.supplierLoadError = true
      }
      finally {
        this.fetchingSupplier = false
      }
    },

    async fetchAllIds() {
      this.fetchingIds = true

      try {
        const response = await this.$newSdk.vehicleModelGroupMaintenanceTables.all({
          params: {
            ...this.requestQueryParams,

            kind:    this.kind,
            partial: "ids"
          }
        })

        return response.data
      }
      finally {
        this.fetchingIds = false
      }
    },

    async selectAll() {
      try {
        const ids = await this.fetchAllIds()
        this.$emit("input", _.union(ids, this.value))
      }
      catch (error) {
        this.$err.log(error)
      }
    },

    async removeAll() {
      try {
        const ids = await this.fetchAllIds()
        const newValue = _.remove([...this.value], id => !ids.includes(id))
        this.$emit("input", newValue)
      }
      catch (error) {
        this.$err.log(error)
      }
    },

    toggleAllOnPage(value) {
      this.resource.forEach(entry => {
        this.selectResource({ id: entry.id, value })
      })
    },

    selectResource({ id, value }) {
      let array = this.value

      const idIndex = array.findIndex(itemId => itemId === id)

      if (value && idIndex < 0) {
        array.push(id)
      }
      else if (!value && idIndex >= 0) {
        array.splice(idIndex, 1)
      }

      this.$emit("input", array)
    },

    // @override Fetchable mixin
    fetchRequest() {
      return this.$newSdk.vehicleModelGroupMaintenanceTables.all({
        params: {
          ...this.requestQueryParams,

          kind: this.kind
        }
      })
    }
  }
}

</script>

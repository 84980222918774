<style lang="scss" scoped>

.service-select-field {
  .row {
    min-height: 40px;
    padding: 8px 12px;
  }

  .option {
    border-bottom: 1px solid $light-gray-3;
    cursor: pointer;

    .remove-icon-content {
      width: 24px;
      height: 24px;
      line-height: 24px;
      text-align: center;
      border-radius: 50px;
      color: $gray-2;
      transition: all 100ms linear;
    }

    &.active {
      color: $primary;
      background: $light-gray-2;
    }

    &.focus {
      background: $light-gray-2;
      color: $dark-primary-2;

      .remove-icon-content {
        background: $light-gray-3;
        color: $primary;
      }
    }
  }

  .error,
  .empty {
    padding: 4px 12px;
    font-size: 14px;
    font-style: italic;
  }

  .error {
    .link {
      margin-left: 5px;
      font-size: 14px;
      font-style: italic;
      font-weight: normal;
    }
  }
}

</style>


<template lang="pug">

  select-field.service-select-field(
    option-as-value,
    searchable,
    :default-filter="false",
    :disabled="disabled",
    :errors="errors",
    :fetching="fetching",
    :hide-label="hideLabel",
    :label="label",
    :loading="loading || fetchingOption",
    :mandatory="mandatory",
    :name="name",
    :optional="optional",
    :options="options",
    :value="selectedOption",
    @close="onClose",
    @input="onSelect",
    @inputSearch="updateSearch",
    @open="_fetch",
    @search="onSearch"
  )
    template(#menuContentHeader)
      template(v-if="fetchError")
        .error.row.flex.vertical-center.center
          span {{ $t('.error.message') }}
          app-button.link(theme="link", @click="onSearch") {{ $t('.error.link') }}

    template(#menuContentFooter)
      template(v-if="!fetching && !fetchError && empty")
        label.empty.row.flex.vertical-center.center {{ $t('.empty') }}

    template(#fetching)
      li.row.flex.vertical-center {{ $t('.loading') }}

    template(#option="{ props: { option, index, focus, active } }")
      .option.row.flex.vertical-center.space-between(:class="{ focus, active }")
        span {{ option.label }}
        .remove-icon-content(v-if="active")
          i.fal.fa-times

</template>


<script>

// Mixins
import FieldMixin from "@/mixins/field"
import AsyncMixin from "@/mixins/select/async"


export default {
  mixins: [FieldMixin, AsyncMixin],

  props: {
    quoteId:    { type: [Number, String], required: true },
    serviceRid: { type: String, default: null }
  },

  data() {
    return {
      i18nScope: "monitoring.quotes.service-select-field",

      fetchingOption: false
    }
  },

  methods: {
    // @override AsyncSelect Mixin
    async fetchOption(id) {
      try {
        this.fetchingOption = true

        // XXX: endpoint exige rid, então ignoramos o id
        const { data } = await this.$newSdk.monitoring.services.find({ serviceRid: this.serviceRid })

        this.option = this.dataToOption(data)
      }
      catch (err) {
        console.error(err)
      }
      finally {
        this.fetchingOption = false
      }
    },

    // @override AsyncSelect Mixin
    fetchRequest() {
      let params = { q: this.search }

      return this.$newSdk.quotes
        .availableServices(this.quoteId, { params })
    }
  }
}

</script>

<style lang="scss" scoped>

.service-order-row {
  display: grid;
  grid-template-columns: 130px 40px 129px 99px 1fr 93px 124px 100px 64px;

  &.loading {
    .column {
      color: $gray-2;

      &.actions-column {
        ::v-deep .movida-common__app-button {
          color: $gray-2;

          &:hover {
            color: $orange;
          }
        }
      }

      .text,
      .id-tag,
      .store,
      .link ::v-deep .app-span,
      .link ::v-deep .code .app-span {
        color: $gray-2;
      }

      .link:hover,
      .code:hover ::v-deep .app-span {
        color: $orange;
      }
    }
  }

  .column {
    padding: 0 12px;
    border-bottom: 1px solid $light-gray-3;
    height: 48px;
    overflow: hidden;

    &.type-column {
      .button {
        overflow: hidden;
      }

      .code-row {
        width: 100%;
      }

      .type-row {
        width: 100%;

        .icon {
          font-size: 12px;
          margin-left: 4px;
        }
      }
    }

    &.supplier-column {
      .supplier {
        width: 100%;
      }
    }

    &.store-column {
      font-family: $secondary-font;
      font-weight: 500;
      font-size: 14px;
      color: $gray-3;
    }

    &.total-column {
      .text {
        font-family: $secondary-font;
      }
    }

    .text,
    .link ::v-deep .app-span {
      font-family: $primary-font;
      font-weight: 400;
      font-size: 14px;
      text-align: initial;
      color: $dark-gray;
    }

    .link ::v-deep .code .app-span {
      color: $purple;
    }

    .content:hover {
      .link,
      .facilities,
      .code ::v-deep .app-span{
        color: $orange;
      }
    }
  }
}

</style>


<template lang="pug">

  .service-order-row(:class="{ loading }")
    .column.id-column.flex.column-direction.center
      .id(data-testid="serviceOrderId")
        id-tag.id-tag(:value="serviceOrder.id")
      .vetor(
        v-if="!isDraft",
        data-testid="serviceOrderVetorCode"
      )
        vetor-code-tag(:value="serviceOrder.externalId", :disabled="loading", size="small")

    .column.state-column.flex.vertical-center.center(data-testid="serviceOrderState")
      service-order-state-icon(:state="serviceOrder.state")

    .column.type-column.flex.column-direction.vertical-start(data-testid="serviceOrderType")
      .content
        .type-row.flex.vertical-center
          app-button.button(theme="link", :to="defaultRoute")
            app-span.link(:value="$t(`.fields.type.${serviceOrder.type}`)", crop)
          .facilities.no-shrink(
            v-if="!isDraft",
            v-tooltip.bottom="{ offset: 4, content: $t(`.facilities.${serviceOrder.facilities}.tooltip`) }"
          )
            i.icon.far(:class="iconClass", data-testid="facilitiesIcon")
        .code-row.flex
          app-button.button(theme="link", :to="defaultRoute")
            id-tag.code(:value="serviceOrder.code", crop)

    .column.license-plate-column.flex.vertical-center(data-testid="serviceOrderLicensePlate")
      vehicle-plate-tag(:value="vehicle.licensePlate")

    .column.supplier-column.flex.vertical-center(data-testid="serviceOrderSupplier")
      .supplier
        app-span.text(:value="supplier.tradingName", crop)

    .column.store-column.flex.vertical-center(data-testid="serviceOrderStoreInitials")
      app-span.store(:value="store.initials", v-tooltip.bottom="store.name")

    .column.total-column.flex.vertical-center(data-testid="serviceOrderTotal")
      app-span.text(:value="$asCurrency(serviceOrder.total)")

    .column.created-at-column.flex.vertical-center(data-testid="serviceOrderCreatedAt")
      app-span.text(:value="$standardTime(serviceOrder.createdAt)")

    .column.actions-column.flex.vertical-center.flex
      popover-menu(
        v-if="popoverMenuAuthorization",
        :show.sync="showPopover",
        data-testid="popoverMenu"
      )
        popover-menu-button(
          v-if="$can('read', serviceOrder)",
          icon="fal fa-eye",
          :to="showRoute",
          :text="$t('.actions.btn.show.label')",
          @close-menu="showPopover = false"
        )

        popover-menu-button(
          v-if="$can('update', serviceOrder) && isDraft",
          icon="fal fa-pencil",
          :to="editRoute",
          :text="$t('.actions.btn.edit.label')",
          @close-menu="showPopover = false"
        )

</template>


<script>

export default {
  props: {
    serviceOrder: { type: Object, default: () => ({}) },
    loading:      { type: Boolean, default: false }
  },

  data() {
    return {
      i18nScope:   "service-orders.index.service-order-row",
      showPopover: false
    }
  },

  computed: {
    popoverMenuAuthorization() {
      return this.isDraft
        ? ["read", "update"].some(action => this.$can(action, this.serviceOrder))
        : this.$can("read", this.serviceOrder)
    },

    isDraft() {
      return this.serviceOrder.state === "draft"
    },

    supplier() {
      return _.get(this.serviceOrder, "supplier") || {}
    },

    store() {
      return _.get(this.serviceOrder, "store") || {}
    },

    vehicle() {
      return _.get(this.serviceOrder, "vehicle") || {}
    },

    supplierAddress() {
      return _.get(this.supplier, "address.addressLine1")
    },

    iconClass() {
      const facilities = _.get(this.serviceOrder, "facilities")

      switch (facilities) {
        case "external":
          return "fa-external-link-square-alt"
        case "internal":
          return "fa-house"
        default:
          return null
      }
    },

    showRoute() {
      return {
        name:   "storeTicketServiceOrder",
        params: {
          id:             this.store.id,
          ticketId:       this.serviceOrder.ticketId,
          serviceOrderId: this.serviceOrder.id
        }
      }
    },

    editRoute() {
      return {
        name:   "editDraftTicketVehicle",
        params: { id: this.serviceOrder.ticketId }
      }
    },

    defaultRoute() {
      return this.isDraft
        ? this.editRoute
        : this.showRoute
    }
  }
}

</script>

<style lang="scss" scoped>

.narrow-attachment-card {
  .container {
    background-color: $white;
    border-radius: 4px;
    overflow: hidden;
    height: 32px;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25);
    cursor: pointer;
    position: relative;

    &.uploaded {
      &:hover {
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);

        .name {
          color: $orange;
        }

        .actions {
          visibility: visible;
        }
      }
    }

    .actions {
      height: 24px;
      background: $light-gray-2;
      padding: 4px 8px;
      position: absolute;
      right: 8px;
      top: 4px;
      border-radius: 4px;
      visibility: hidden;
      cursor: default;
      z-index: 1;

      .action {
        cursor: pointer;

        &:hover {
          color: $orange;
        }

        & + .action {
          margin-left: 16px;
        }

        &.downloading {
          cursor: progress;
          color: $gray-2;
        }
      }
    }

    .content {
      height: 32px;
      padding: 0 8px;
      font-family: $secondary-font;
      font-weight: 400;

      .icon-container {
        width: 20px;
        height: 20px;
        margin-right: 8px;

        .icon {
          color: var(--attachment-color);
        }
      }

      .content-container {
        height: 32px;
        position: relative;
        width: 100%;
        overflow: hidden;

        .info-container {
          height: 32px;
          width: 100%;

          .name {
            font-size: 14px;
            font-weight: 400;
            overflow: hidden;
          }

          .checking {
            width: calc(100% - 8px);
          }

          .progress-bar {
            width: calc(100% - 8px);
            height: 8px;
            background-color: $light-gray-4;
            border-radius: 4px;
            overflow: hidden;
            margin-top: 4px;

            .progress {
              width: var(--progress);
              height: 100%;
              background-color: $orange;
            }
          }
        }
      }
    }
  }
}

</style>


<template lang="pug">

  .narrow-attachment-card(:style="cssVars")
    .container(
      :class="{ uploaded }",
      @click="onShow"
    )
      .actions.flex.vertical-center.no-shrink(@click.stop="")
        .action.flex.center.vertical-center(
          v-if="canDownload",
          :class="{ downloading }",
          @click="onDownload"
        )
          i.icon.far.fa-download

        .action.flex.center.vertical-center(
          v-if="canEdit",
          @click="onEdit"
        )
          i.icon.far.fa-pencil

        .action.flex.center.vertical-center(
          v-if="canDelete",
          @click="onDestroy"
        )
          i.icon.far.fa-trash-alt

      .content.flex.vertical-center
        .icon-container.flex.center.vertical-center.no-shrink
          i.icon.fas(
            v-tooltip="Attachment.$tEnum('fileType', attachment.fileType)",
            :class="{ [attachment.icon]: true }"
          )

        .content-container.flex.vertical-center
          .info-container.flex.vertical-center.no-shrink
            template(v-if="computingChecksum")
              loading-lines.checking(:height="8", :min="100", :max="100")

            template(v-else-if="uploading")
              .progress-bar(:style="progressStyle")
                .progress

            template(v-else)
              app-span.name(crop, :value="attachment.name")

    attachment-preview(
      v-if="persisted && ownPreview && showPreviewModal",
      :attachment="attachment",
      :editable="editable",
      :editing.sync="editing",
      @close="onClosePreview",
      @download="onDownload"
    )

</template>


<script>

// 3rd Party
import axios from "axios"

// Libs
import FileChecksum from "@/lib/checksum"

// Models
import models from "@/models"
const { Attachment } = models

export default {
  name: "NarrowAttachmentCard",

  props: {
    attachment:         { type: Object, default: null },
    downloadable:       { type: Boolean, default: true },
    editable:           { type: Boolean, default: true },
    deletable:          { type: Boolean, default: true },
    loading:            { type: Boolean, default: false },
    markForDestruction: { type: Boolean, default: true },
    ownPreview:         { type: Boolean, default: true }
  },

  data() {
    return {
      i18nScope: "components.attachments.narrow-attachment-card",

      uploading:   false,
      downloading: false,

      progress: null,

      computingChecksum: false,

      showPreviewModal: false,
      editing:          false,

      Attachment
    }
  },

  computed: {
    cssVars() {
      return {
        "--attachment-color": this.attachment.iconColor
      }
    },

    fileToUpload() {
      return _.get(this.attachment, "fileToUpload")
    },

    persisted() {
      return _.present(this.attachment.blobId)
    },

    uploaded() {
      return this.persisted || _.present(this.attachment.file)
    },

    canDownload() {
      return this.persisted && this.downloadable && !this.loading
    },

    canEdit() {
      return this.persisted && this.editable && !this.loading
    },

    canDelete() {
      return this.uploaded && this.deletable && !this.loading
    },

    percentage() {
      if (_.blank(this.progress)) return 0

      const { total, loaded } = this.progress

      // Evita divisão por 0
      if (!total) return 0

      let value = (loaded / total) * 100
      return parseInt(value, 10)
    },

    progressStyle() {
      return {
        "--progress": `${this.percentage}%`
      }
    }
  },

  created() {
    if (!this.fileToUpload) return

    this.progress = {
      loaded: 0,
      total:  this.attachment.fileSize
    }

    this.upload()
  },

  methods: {
    async onDestroy() {
      if (!this.canDelete) return

      if (this.attachment.$newRecord) return this.$emit("destroy")

      if (this.markForDestruction) {
        this.attachment.$markForDestruction()
        return this.$emit("softDestroy")
      }

      try {
        this.destroying = true

        await this.destroyRequest()

        this.$notifications.info(this.$t(".notifications.destroy.success"))

        this.$emit("destroy")
      }
      catch (error) {
        console.error(error)
        this.$notifications.error(this.$t(".notifications.destroy.error"))
      }
      finally {
        this.destroying = true
      }
    },

    destroyRequest() {
      return this.$sdk.attachments.destroy({ attachmentId: this.attachment.id })
    },

    onClosePreview() {
      this.showPreviewModal = false
      this.editing = false
    },

    onShow() {
      if (!this.canDownload) return

      if (!this.ownPreview) this.$emit("preview")
      else this.showPreviewModal = true
    },

    onEdit() {
      if (!this.canEdit) return

      if (!this.ownPreview) this.$emit("edit")
      else {
        this.editing = true
        this.showPreviewModal = true
      }
    },

    async createBlob() {
      this.computingChecksum = true
      const checksum = await FileChecksum.create(this.fileToUpload)
      this.computingChecksum = false

      const response = await this.$newSdk.blobs.create({
        params: {
          blob: {
            filename:     this.fileToUpload.name,
            content_type: this.fileToUpload.type,
            byte_size:    this.fileToUpload.size,
            checksum
          }
        }
      })

      return response.data
    },

    readFile() {
      return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.addEventListener("load", () => resolve(reader.result))
        // eslint-disable-next-line prefer-promise-reject-errors
        reader.addEventListener("error", () => reject(`Error reading ${this.fileToUpload.name}`))

        reader.readAsArrayBuffer(this.fileToUpload)
      })
    },

    async upload() {
      this.progress = {
        loaded: 0,
        total:  this.attachment.fileSize
      }

      this.uploading = true

      try {
        const blob = await this.createBlob()
        const data = await this.readFile()

        await axios.put(blob.direct_upload.url, data, {
          // XXX: Removendo header "Content-Disposition" para permitir controlar na hora do download, dado
          // que um valor enviado aqui não pode ser sobrescrito depois.
          headers: _.omit(blob.direct_upload.headers, "Content-Disposition"),

          transformRequest: [(requestData, requestHeaders) => {
            delete requestHeaders.put["Content-Type"]
            return requestData
          }],

          onUploadProgress: ({ loaded, total }) => {
            this.progress = { loaded, total }
          }
        })

        this.$emit("uploaded", blob.signed_id)
      }
      catch (error) {
        console.error(error)
        this.$notifications.error(this.$t(".notifications.upload.error", { name: this.fileToUpload.name }))
        this.$emit("destroy")
      }
      finally {
        this.uploading = false
      }
    },

    downloadRequest() {
      return this.$newSdk.blobs.getDownloadURL({ blobId: this.attachment.blobId, params: { disposition: "attachment" } })
    },

    async onDownload() {
      if (!this.canDownload || this.downloading) return

      this.downloading = true

      try {
        const { data: { url } } = await this.downloadRequest()

        const link = document.createElement("a")
        link.href = url
        link.setAttribute("download", this.attachment.fileName)

        document.body.appendChild(link)
        link.click()
        link.parentNode.removeChild(link)
      }
      catch (error) {
        console.error(error)
        this.$notifications.error(this.$t(".notifications.download.error", { name: this.attachment.name }))
      }
      finally {
        this.downloading = false
      }
    }
  }
}

</script>

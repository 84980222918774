import Base from "./base"

class ReviewApproval extends Base {
  static get attrs() {
    return _.uniq([
      ...super.attrs,

      "description"
    ])
  }
}

export default ReviewApproval

<style lang="scss" scoped>

.popover-preview-loading{
  width: var(--width);
  max-width: 100vw;

  .content {
    margin-top: 12px;

    ::v-deep .loading-line + .loading-line {
      margin-top: 4px;
    }
  }
}

</style>


<template lang="pug">

  .popover-preview-loading(:style="cssVars")
    .header.vertical-center
      loading-lines(:min="100", :max="100", :height="20")

    .content
      loading-lines(:min="100", :max="100", :height="20", :lines="4")

</template>


<script>

export default {
  props: { width: { type: Number, required: true } },

  computed: {
    cssVars() {
      return {
        "--width": `${this.width}px`
      }
    }
  }
}

</script>

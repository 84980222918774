<style scoped lang="scss">

.paginator {
  background-color: $white;
}

.loading {
  cursor: progress;
}

.disabled {
  cursor: not-allowed;
  color: $light-gray-4;

  .btn {
    cursor: not-allowed;

    &.current {
      background-color: $light-gray-3
    }

    &:not(:disabled) {
      cursor: not-allowed;
    }

    &.ellipsis {
      color: $light-gray-4;
    }
  }
}

.btn {
  $paginator-gutter: 12px;

  border: none;
  font-family: $secondary-font;
  font-size: 14px;
  line-height: 19px;
  color: $dark-gray;
  background-color: $white;
  padding: 4px;
  margin: 0 $paginator-gutter;
  min-width: 25px;
  position: relative;

  &:not(:disabled) {
    cursor: pointer;

    .navigation {
      color: $primary;
    }
  }

  &:disabled:not(.ellipsis) {
    color: $gray-2;
  }

  &:first-child {
    margin: 0 $paginator-gutter 0 0;
  }

  &:last-child {
    margin: 0 0 0 $paginator-gutter;
  }

  &.current {
    border-radius: 4px;
    background-color: #FFF0E2;
    color: $primary;
  }

  &:focus {
    color: $light-primary-2;
  }
}

.navigation {
  .next {
    margin-right: 4px;
  }

  .previous {
    margin-left: 4px;
  }

  .icon {
    font-size: 12px;
    font-weight: 700;
  }
}

</style>


<template lang="pug">

  .paginator
    .loading(v-if="loading")
      loading-lines(:min="100", :max="100")

    .content.center(
      v-else-if="showSinglePage",
      :class="{ disabled }"
    )
      button.btn(
        v-if="showNavigation",
        type="button",
        @click="onPreviousPage",
        :disabled="!hasPreviousPage || disabled"
      )
        .navigation.flex.vertical-center
          i.icon.far.fa-chevron-left
          span.previous {{ $t(".previous") }}

      template(v-if="pages <= block")
        template(v-for="i in pages")
          button.btn(
            type="button"
            :class="{ current: value === i }",
            :disabled="disabled",
            @click="onChangePage(i)"
          ) {{ i }}

      template(v-else)
        template(v-if="currentIsOnLeft")
          template(v-for="i in pages")
            button.btn(
              type="button",
              :class="{ current: value === i }",
              v-if="i <= block - 2",
              :key="i",
              @click="onChangePage(i)",
              :disabled="disabled"
            ) {{ i }}

          button.btn.ellipsis(type="button", disabled) ...
          button.btn(
            type="button",
            @click="onChangePage(pages)",
            :disabled="disabled"
          ) {{ pages }}

        template(v-if="currentIsOnRight")
          button.btn(
            type="button",
            @click="onChangePage(first)",
            :disabled="disabled"
          ) {{ first }}
          button.btn.ellipsis(type="button", disabled) ...
          template(v-for="i in pages")
            button.btn(
              :class="{ current: value === i }",
              type="button",
              v-if="i > pages - block + 2",
              :key="i",
              @click="onChangePage(i)",
              :disabled="disabled"
            ) {{ i }}

        template(v-if="currentIsOnCenter")
          button.btn(
            type="button",
            @click="onChangePage(first)",
            :disabled="disabled"
          ) {{ first }}
          button.btn.ellipsis(type="button", disabled) ...

          button.btn(
            type="button",
            v-for="i in leftValues",
            :key="i",
            @click="onChangePage(i)",
            :disabled="disabled"
          ) {{ i }}
          button.btn.current(
            type="button",
            :disabled="disabled"
          ) {{ value }}
          button.btn(
            type="button",
            v-for="i in rightValues",
            :key="i",
            @click="onChangePage(i)",
            :disabled="disabled"
          ) {{ i }}

          button.btn.ellipsis(type="button", disabled) ...
          button.btn(
            type="button",
            @click="onChangePage(pages)",
            :disabled="disabled"
          ) {{ pages }}

      button.btn(
        v-if="showNavigation"
        type="button",
        @click="onNextPage",
        :disabled="!hasNextPage || disabled",
      )
        .navigation.flex.vertical-center
          span.next {{ $t(".next") }}
          i.icon.far.fa-chevron-right

</template>


<script>

export default {
  name: "Paginator",

  props: {
    value:          { type: Number, default: 1 },
    perPage:        { type: Number, default: 0 },
    total:          { type: Number, default: 0 },
    block:          { type: Number, default: 5 },
    hideSinglePage: { type: Boolean, default: true },
    showNavigation: { type: Boolean, default: true },
    loading:        { type: Boolean, default: false },
    disabled:       { type: Boolean, default: false }
  },

  data() {
    return {
      i18nScope: "components.paginator",
      first:     1
    }
  },

  computed: {
    showSinglePage() {
      return !(this.pages <= 1 && this.hideSinglePage)
    },

    center() {
      return (this.block + 1) / 2
    },

    neighborsLength() {
      return (this.block - 4 - 1) / 2
    },

    leftValues() {
      return Array.from({ length: this.neighborsLength }, (val, key) => key + this.value - this.neighborsLength)
    },

    rightValues() {
      return Array.from({ length: this.neighborsLength }, (val, key) => key + this.value + 1)
    },

    currentIsOnLeft() {
      return this.value <= this.center
    },

    currentIsOnRight() {
      return this.value > this.pages - this.center
    },

    currentIsOnCenter() {
      return !this.currentIsOnLeft && !this.currentIsOnRight
    },

    hasPreviousPage() {
      return this.value > this.first
    },

    hasNextPage() {
      return this.value < this.pages
    },

    pages() {
      let numberOfPages = Number(this.total) / Number(this.perPage)
      let mod = this.total % this.perPage

      return Math.floor(mod === 0 ? numberOfPages : numberOfPages + 1)
    }
  },

  methods: {
    onChangePage(val) {
      if (!this.disabled && this.value !== val) {
        this.$emit("input", val)
      }
    },

    onPreviousPage() {
      if (this.hasPreviousPage) {
        this.onChangePage(Math.min(this.value - 1, this.pages))
      }
    },

    onNextPage() {
      if (this.hasNextPage) {
        this.onChangePage(Math.max(this.value + 1, this.first))
      }
    }
  }
}

</script>

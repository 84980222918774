// Ability
import { ability } from "@/modules/ability"

// Routes
import NewVehicleModel from "@/views/vehicle-models/new.vue"
import VehicleModel from "@/views/vehicle-models/show.vue"
import EditVehicleModel from "@/views/vehicle-models/edit.vue"
import VehicleModels from "@/views/vehicle-models/index.vue"

export default [
  {
    path:      "/vehicle-models/new",
    name:      "newVehicleModel",
    // Para passar mobiautoVersion da index sem precisar de endpoint
    props:     true,
    component: NewVehicleModel,
    meta:      {
      auth:        true,
      permissions: [() => ability.can("create", "VehicleModel")]
    }
  },

  {
    path:      "/vehicle-models/:id",
    name:      "vehicleModel",
    component: VehicleModel,
    meta:      {
      auth:        true,
      permissions: [() => ability.can("read", "VehicleModel")]
    }
  },

  {
    path:      "/vehicle-models/:id/edit",
    name:      "editVehicleModel",
    component: EditVehicleModel,
    meta:      {
      auth:        true,
      permissions: [() => ability.can("update", "VehicleModel")]
    }
  },


  {
    path:      "/vehicle-models",
    name:      "vehicleModels",
    component: VehicleModels,
    meta:      {
      auth:        true,
      permissions: [() => ability.can("index", "VehicleModel")]
    }
  }
]

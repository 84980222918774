<style scoped lang="scss">

.suppliers {
  .section-header {
    margin-bottom: 16px;

    .title {
      font-family: $secondary-font;
      font-weight: 500;
      font-size: 20px;
    }
  }

  .clear-filters {
    position: relative;

    .filters-button {
      position: absolute;
      margin-top: 10px;
    }
  }

  .list {
    margin-top: 50px;

    .table {
      margin-top: 16px;
    }
  }

  .paginator-content {
    padding-top: 24px;

    &.loading {
      max-width: 400px;
      margin: 0 auto;
    }
  }
}

</style>


<template lang="pug">

  .suppliers
    .section-header
      h2.title {{ $t('.title') }}

    search-form.search(
      :loading="fetching",
      :params="parsedQueryParams",
      @search="search"
    )

    .clear-filters
      filters-button.filters-button(
        v-if="hasFilters",
        @clear="clearFilters"
      )

    .list
      .flex.end.vertical-bottom
        results-indicator(
          :page="parsedQueryParams.page",
          :per-page="pagination.perPage",
          :total="pagination.total",
          :loading="fetching"
        )

      suppliers-table.table(
        :error="erred",
        :empty="empty",
        :initializing="initializing",
        :loading="fetching",
        :suppliers="suppliers",
        :sorted-by="parsedQueryParams.sort",
        @sort="onSortChange",
        @changed="fetch"
      )

    .paginator-content(v-if="fetching || showPaginator", :class="{ loading: fetching }")
      paginator(
        :loading="fetching",
        :per-page="pagination.perPage",
        :total="pagination.total",
        :value="parsedQueryParams.page",
        :disabled="fetching",
        @input="onPageChange",
        data-testid="paginator"
      )

</template>


<script>

// View
import IndexView from "@/views/index-view"

// Mixins
import FetchMixin from "@/mixins/fetch-mixin"

// Local components
import SuppliersTable from "./index/suppliers-table"
import SearchForm from "./index/search-form"

export default {
  name: "VehicleModelGroupSuppliers",

  components: {
    SuppliersTable,
    SearchForm
  },

  extends: IndexView,

  mixins: [FetchMixin],

  data() {
    return {
      i18nScope: "vehicle-model-groups.suppliers.index",
      routeName: "vehicleModelGroupSuppliers",

      vehicleModelGroupId: null,

      resource: [],

      // @override IndexView
      clearFiltersIgnoreList: ["discarded"],

      // Query Mixin data
      queryParamsStructure: {
        q:         "string",
        city_id:   "string",
        sector_id: "string",
        page:      "integer",
        sort:      "string"
      }
    }
  },

  computed: {
    suppliers: {
      get() { return this.resource },
      set(value) { this.resource = value }
    },

    empty() {
      return _.blank(this.suppliers)
    }
  },

  methods: {
    parseRoute() {
      this.vehicleModelGroupId = this.$params.asInteger(this.$route.params.id)
    },

    // @override Fetchable mixin
    fetchRequest() {
      return this.$newSdk.vehicleModelGroups.suppliers.all({ vehicleModelGroupId: this.vehicleModelGroupId, params: this.requestQueryParams })
    }
  }
}

</script>

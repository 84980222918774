// Base
import BaseRequests from "../../Base"

// Models
import models from "@/models"
const { VehicleReceipt } = models

class MonitoringServiceOrderVehicleReceiptRequests extends BaseRequests {
  static get selector() {
    return "monitoring.serviceOrders.vehicleReceipt"
  }

  find({ serviceOrderRid, params = {}, options = {} } = {}) {
    this.verifyData("find", { serviceOrderRid })

    return this.action({
      method:  "get",
      url:     `/monitoring/service_orders/${serviceOrderRid}/vehicle_receipt`,
      params,
      options: {
        model: VehicleReceipt,

        ...options
      }
    })
  }

  create({ serviceOrderRid, params = {}, options = {} } = {}) {
    this.verifyData("create", { serviceOrderRid })

    return this.action({
      method:  "post",
      url:     `/monitoring/service_orders/${serviceOrderRid}/vehicle_receipt`,
      params,
      options: {
        model: VehicleReceipt,

        ...options
      }
    })
  }

  update({ serviceOrderRid, params = {}, options = {} } = {}) {
    this.verifyData("update", { serviceOrderRid })

    return this.action({
      method:  "patch",
      url:     `/monitoring/service_orders/${serviceOrderRid}/vehicle_receipt`,
      params,
      options: {
        model: VehicleReceipt,

        ...options
      }
    })
  }
}

export default MonitoringServiceOrderVehicleReceiptRequests

// Base
import BaseRequests from "../Base"

// Models
import models from "@/models"
const { MovidaServiceOrder, ServiceOrderPostponement } = models


class MonitoringServiceOrderRequests extends BaseRequests {

  static get selector() { return "monitoring.serviceOrders" }


  all({ params = {}, options = {} } = {}) {
    return this.action({
      method:  "post",
      url:     `/monitoring/service_orders/search`,
      params,
      options: {
        model: MovidaServiceOrder,

        ...options
      }
    })
  }


  find(idOrRid, { params = {}, options = {} } = {}) {
    if (_.blank(idOrRid)) throw new TypeError("id is a required argument")

    return this.action({
      method:  "get",
      url:     `/monitoring/service_orders/${idOrRid}`,
      params,
      options: {
        model: MovidaServiceOrder,

        ...options
      }
    })
  }


  // id or rid
  postponements(serviceOrderId, { params = {}, options = {} } = {}) {
    this.verifyData("postponements", { serviceOrderId })

    return this.action({
      method:  "get",
      url:     `/monitoring/service_orders/${serviceOrderId}/postponements`,
      params,
      options: {
        model: ServiceOrderPostponement,

        ...options
      }
    })
  }


  // Recupera a "árvore" de uma OS.
  // é id or rid, mas prefira rid para não haver confusão.
  //
  // item: {
  //   provider: string,
  //   remote_id: string,
  //   rid: string,
  //   parent_provider: string,
  //   parent_remote_id: string,
  //   parent_rid: string,
  //   state: enum,
  //   type: enum
  // }
  //
  // response.data = {
  //   parent: item
  //   children: [item, ...]
  // }
  tree(serviceOrderRid, { params = {}, options = {} } = {}) {
    this.verifyData("tree", { serviceOrderRid })

    return this.action({
      method: "get",
      url:    `/monitoring/service_orders/${serviceOrderRid}/tree`,
      params,
      options
    })
  }

}


export default MonitoringServiceOrderRequests

<style scoped lang="scss">

.vehicle-model-row {
  padding: 12px;
  cursor: pointer;
  border-top: 1px solid $light-gray-3;
  border-bottom: 1px solid $light-gray-3;
  line-height: initial;

  * {
    cursor: pointer;
  }

  & + .vehicle-model-row {
    border-top: 1px solid transparent;
  }

  .content-column {
    overflow: hidden;
    padding-right: 12px;
    font-size: 14px;
    font-weight: 400;

    .diamond-icon {
      font-size: 4px;
      font-weight: 900;
      color: $gray-3;
      width: 4px;
      height: 4px;
      margin: 4px;
    }
  }

  .actions-column {
    min-width: 42px;
    margin-left: 12px;

    .remove-icon-content {
      display: inline-block;
      width: 24px;
      height: 24px;
      line-height: 24px;
      text-align: center;
      border-radius: 50px;
      color: $gray-2;
      transition: all 100ms linear;
    }
  }

  &.active {
    color: $primary;
    background: $light-gray-2;
  }

  &.focus {
    background: $light-gray-2;
    color: $dark-primary-2;

    .actions-column {
      .remove-icon-content {
        background: $light-gray-3;
        color: $primary;
      }
    }
  }
}

</style>


<template lang="pug">

  .vehicle-model-row.flex.vertical-center(:class="{ focus, active }")
    .content-column.flex.grow.vertical-center
      vehicle-manufacturer-icon(:value="manufacturer.name")
      i.diamond-icon.fal.fa-diamond
      app-span(:value="model.name", crop)
      i.diamond-icon.fal.fa-diamond
      app-span(:value="mobiautoVersion.name", crop)
      i.diamond-icon.fal.fa-diamond

      template(v-if="vehicleModel.year")
        app-span(:value="vehicleModel.year")
      template(v-else)
        app-span(:value="$t('.yearRange', { initial: mobiautoVersion.initialYear, final: mobiautoVersion.finalYear } )")

      i.diamond-icon.fal.fa-diamond
      fipe-code-tag(:value="mobiautoVersion.fipeId")

    .actions-column.center
      .remove-icon-content(v-if="active")
        i.fal.fa-times

</template>


<script>

import models from "@/models"
const { MobiautoVersion } = models

export default {
  name: "VehicleModelOption",

  props: {
    vehicleModel: { type: Object, default: () => ({}) },
    active:       { type: Boolean, default: false },
    focus:        { type: Boolean, default: false }
  },

  data() {
    return {
      i18nScope: "components.vehicle-model.vehicle-model-option"
    }
  },

  computed: {
    mobiautoVersion() {
      return this.vehicleModel.mobiautoVersion || new MobiautoVersion()
    },

    model() {
      return _.get(this.mobiautoVersion, "model") || {}
    },

    manufacturer() {
      return _.get(this.model, "manufacturer") || {}
    }
  }
}

</script>

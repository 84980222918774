<style scoped lang="scss">

.section {
  padding: 16px 32px;

  + .section {
    border-top: 1px solid $light-gray-3;
  }

  &.loading {
    min-height: 151px;
  }
}

.global-price {
  margin-right: 24px;
  height: 40px;
  background-color: $light-gray-2;
}

.icon {
  margin-left: 12px;
  margin-right: 16px;
  font-size: 20px;
  color: $gray-2;
}

.text {
  font-family: $secondary-font;
  font-size: 16px;
  color: $dark-gray;
  font-weight: normal;
}

</style>


<template lang="pug">

  app-modal(
    :width="996",
    :heading="$t('.title.label')",
    footer,
    :confirm-label="$t('.btn.update.label')",
    :confirm-button="editable",
    @close="goBack",
    @confirm="submit"
  )
    form.form(ref="form", method="post", @submit.prevent="submit")
      template(v-if="fetching")
        section.section.loading
          loading-lines(:lines="4")

      template(v-else)
        section.section
          maintenance-package-header(
            :resource="resource"
            :fetching="fetching"
          )

        section.section
          ui-row
            ui-col(:span="8")
              .global-price.flex.vertical-center
                i.icon.fal.fa-globe
                span.text {{ $t('.laborPrice.title.label') }}
            ui-col(:span="4")
              money-field(
                name="dealMaintenancePackage[laborPrice]",
                v-model="resource.laborPrice",
                :loading="submitting",
                hide-label,
                :placeholder="$t('.fields.laborPrice.placeholder')",
                :errors="errors.laborPrice"
              )

</template>


<script>

// Mixins
import { mixins } from "movida-common.vue"

// Models
import models from "@/models"

// Extends
import View from "@/views/view"

// Components
import MaintenancePackageHeader from "./components/maintenance-package-header"

const { FormMixin, Fetchable } = mixins
const { DealMaintenancePackage } = models

export default {
  name: "EditSupplierDealMaintenancePackage",

  components: {
    MaintenancePackageHeader
  },

  extends: View,

  mixins: [FormMixin, Fetchable],

  props: {
    backRouteQuery: { type: Object, default: () => ({}) },
    editable:       { type: Boolean, default: false }
  },

  data() {
    return {
      i18nScope: "suppliers.deals.maintenance-packages.edit",

      dirtyable: false, // XXX: dirty manager e modal não combinam!

      id:                   this.$params.asInteger(this.$route.params.id),
      dealId:               this.$params.asInteger(this.$route.params.dealId),
      maintenancePackageId: this.$params.asInteger(this.$route.params.maintenancePackageId),

      model:    "DealMaintenancePackage",
      resource: new DealMaintenancePackage({ dealId: this.$route.params.dealId }),

      DealMaintenancePackage
    }
  },

  computed: {
    backRoute() {
      return {
        name:   "supplierDealMaintenancePackages",
        params: { id: this.id, dealId: this.dealId },
        query:  this.backRouteQuery
      }
    },

    maintenancePackageName() {
      return _.get(
        this.resource,
        "maintenancePackage.name"
      )
    },

    maintenancePackageErrors() {
      return _.compact(
        _.concat(
          this.errors.maintenancePackageId,
          this.errors.maintenancePackage
        )
      )
    },

    vehicleModelGroupName() {
      return _.get(
        this.$refs.vehicleModelGroupSelectField,
        "selectedOption.label"
      )
    },

    vehicleModelGroupErrors() {
      return _.compact(
        _.concat(
          this.errors.vehicleModelGroupId,
          this.errors.vehicleModelGroup
        )
      )
    }
  },

  created() {
    if (!this.editable) {
      this.$router.replace({
        name:   "supplierDealMaintenancePackage",
        params: { id: this.id, dealId: this.dealId, maintenancePackageId: this.maintenancePackageId }
      })
    }
  },

  methods: {
    // @override extends view.vue
    goBack() {
      // Voltando para rota pai ao fechar o modal
      if (_.get(this.$router.referrer, "name") === this.backRoute.name) {
        this.$goBack({ fallback: this.backRoute })
      }
      else {
        this.$router.push(this.backRoute)
      }
    },

    fetch() {
      return this.$sdk.deals.getMaintenancePackage({
        id:                   this.dealId,
        maintenancePackageId: this.maintenancePackageId
      })
    },

    // @override Fetchable mixin
    onFetchError(err) {
      // Dealing with appError from extension view.vue
      if (this.hasViewError(err)) this.appError = err

      this.$err.log(err)
      this.$notifications.error(this.$t(".notifications.fetch.failure"))
    },

    // @override Form mixin
    submitRequest() {
      const params = this.resource.$serialize()
      return this.$sdk.deals.updateMaintenancePackage({
        id:                   this.dealId,
        maintenancePackageId: this.maintenancePackageId,
        params
      })
    },

    // @override Form mixin
    submitSuccess(response) {
      // TODO: evoluir para mostrar ${nomePacote} e ${nomeGrupo}
      let message = this.$t(".notifications.submit.success", {
        name: this.maintenancePackageName
      })

      this.$notifications.info(message)

      this.$emit("change")
      this.$router.push(this.backRoute)
    },

    // @override Form mixin
    submitError(err) {
      this.$err.log(err)
      this.$notifications.error(this.$t(".notifications.submit.error"))
    }
  }
}

</script>

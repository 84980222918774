<style scoped lang="scss">

.new-maintenance-package {
  padding-bottom: 24px;
}

.btn {
  margin-bottom: 12px;
}

.header {
  padding-bottom: 16px;
  border-bottom: 1px solid $light-gray-3;
}

.title {
  font-size: 30px;
  font-weight: 500;
  font-family: $secondary-font;
}

.fields-content {
  margin-bottom: 24px;
}

</style>


<template lang="pug">

  .new-maintenance-package
    header.header
      h1.title {{ $t('.title') }}

    form(@submit.prevent="submit", method="post")

      form-fields.fields-content(
        :loading="submitting",
        :errors="errors",
        :resource="resource",
        data-test="formFields"
      )

      section.actions.flex.space-between.vertical-center
        app-button(:to="{ name: 'maintenancePackages' }", theme="gray", outline, :disabled="submitting")
          span {{ $t('btn.cancel') }}

        app-button(type="submit", :loading="submitting", data-test="submitButton")
          span {{ $t('.btn.create.label') }}
          span(slot="loading") {{ $t('.btn.create.loading') }}

</template>


<script>

// Mixins
import { mixins } from "movida-common.vue"

// Extends
import View from "@/views/view"

// Components
import FormFields from "./forms/form-fields"

// Models
import models from "@/models"

const { FormMixin } = mixins
const { MaintenancePackage } = models

export default {
  name: "NewMaintenancePackage",

  components: {
    FormFields
  },

  extends: View,

  mixins: [FormMixin],

  data() {
    return {
      i18nScope: "maintenance-packages.new",

      resource: new MaintenancePackage(),
      model:    "MaintenancePackage"
    }
  },

  methods: {
    submitRequest() {
      const productIds = this.resource.products.map(product => product.id)
      const params = { ...this.resource.$serialize(), product_ids: productIds }

      return this.$sdk.maintenancePackages.create({ params })
    },

    submitSuccess({ data: { id, name } }) {
      this.$notifications.info(this.$t(".notifications.submit.success", { name }))
      this.$router.push({ name: "maintenancePackage", params: { id } })
    },

    submitError(err) {
      this.$err.log(err)
      this.$notifications.error(this.$t(".notifications.submit.error"))
    }
  }
}

</script>

// Base
import BaseRequests from "../../../Base"

// Models
import models from "@/models"
const { Attachment } = models

class ServiceOrderServiceAttachmentsRequests extends BaseRequests {
  static get selector() {
    return "serviceOrders.drafts.services.attachments"
  }

  all({ serviceOrderServiceId, params = {}, options = {} } = {}) {
    this.verifyData("all", { serviceOrderServiceId })

    return this.action({
      method:  "get",
      url:     `/service_order_services/${serviceOrderServiceId}/draft/attachments`,
      params,
      options: {
        model: Attachment,

        ...options
      }
    })
  }

  find({
    serviceOrderServiceId,
    attachmentId,
    params = {},
    options = {}
  } = {}) {
    this.verifyData("find", { serviceOrderServiceId, attachmentId })

    return this.action({
      method:  "get",
      url:     `/service_order_services/${serviceOrderServiceId}/draft/attachments/${attachmentId}`,
      params,
      options: {
        model: Attachment,

        ...options
      }
    })
  }

  create({ serviceOrderServiceId, params = {}, options = {} } = {}) {
    this.verifyData("create", { serviceOrderServiceId })

    return this.action({
      method: "post",
      url:    `/service_order_services/${serviceOrderServiceId}/draft/attachments`,
      params,
      options
    })
  }

  update({
    serviceOrderServiceId,
    attachmentId,
    params = {},
    options = {}
  } = {}) {
    this.verifyData("update", { serviceOrderServiceId, attachmentId })

    return this.action({
      method: "patch",
      url:    `/service_order_services/${serviceOrderServiceId}/draft/attachments/${attachmentId}`,
      params,
      options
    })
  }

  destroy({
    serviceOrderServiceId,
    attachmentId,
    params = {},
    options = {}
  }) {
    this.verifyData("destroy", { serviceOrderServiceId, attachmentId })

    return this.action({
      method: "delete",
      url:    `/service_order_services/${serviceOrderServiceId}/draft/attachments/${attachmentId}`,
      params,
      options
    })
  }
}

export default ServiceOrderServiceAttachmentsRequests

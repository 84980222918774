<style lang="scss" scoped>

svg {
  width: 24px;
  flex-shrink: 0;
}

</style>


<script>

import IconBase from "../icon-base"

export default {
  name: "SupplierIconBase",

  extends: IconBase
}

</script>

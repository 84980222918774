<style lang="scss" scoped>

.form-fields {
  .row + .row {
    padding-top: 24px;
  }

  .identification-section {
    border-bottom: 1px solid $light-gray-3;
    padding-bottom: 24px;
    margin-bottom: 24px;
  }

  .section-header {
    margin-bottom: 16px;

    .title {
      font-family: $secondary-font;
      font-weight: 500;
      font-size: 20px;
    }
  }
}

</style>


<template lang="pug">

  .form-fields
    section.identification-section
      ui-row.row
        ui-col(:span="6")
          input-field(
            name="vehicleModelGroup[name]",
            v-model="resource.name",
            :loading="fetching || submitting",
            :errors="errors.name",
            :placeholder="$t('.fields.name.placeholder')"
          )

      ui-row.row
        ui-col(:span="6")
          textarea-field(
            name="vehicleModelGroup[description]",
            v-model="resource.description",
            :loading="fetching || submitting",
            :errors="errors.description",
            :placeholder="$t('.fields.description.placeholder')",
            optional
          )

    section.models-section
      .section-header
        h2.title {{ $t('.sections.vehicle-models.title') }}

      vehicle-models-select-list(
        v-model="resource.vehicleModelIds",
        :loading="fetching || submitting",
        :error="vehicleModelIdsError"
      )

</template>


<script>

export default {
  props: {
    fetching:   { type: Boolean, default: false },
    submitting: { type: Boolean, default: false },
    errors:     { type: Object, default: () => ({}) },
    resource:   { type: Object, default: () => ({}) }
  },

  data() {
    return {
      i18nScope: "vehicle-model-groups.form-fields"
    }
  },

  computed: {
    vehicleModelIdsError() {
      return _.get(this.errors, "vehicleModelIds[0]")
    }
  }
}

</script>

<style lang="scss" scoped>

.service-order-attachments {
  .retry {
    margin-left: 4px;
  }

  .subsection-header {
    margin-bottom: 8px;

    span.refresh-button {
      cursor: pointer;

      &:hover {
        i, span {
          color: $dark-orange;
        }
      }
    }

    .icon-container {
      width: 16px;
      height: 16px;
      margin-right: 8px;

      .icon {
        font-size: 14px;
      }
    }

    .subsection-title {
      font-size: 16px;
      font-family: $secondary-font;
      font-weight: 500;
      color: $gray-3;
    }

    .diamond-icon {
      font-size: 4px;
      color: $gray-3;
      margin: 0 8px;
    }

    .count, 
    .refresh-text {
      font-family: $secondary-font;
      font-weight: 400;
      font-size: 16px;
      color: $gray-3;
    }

    .add-attachment {
      font-size: 14px;
      margin-left: auto;
    }
  }

  .attachments {
    grid-template-columns: minmax(196px, 1fr) minmax(196px, 1fr);
    gap: 8px 24px;
  }

  .empty {
    background-color: $light-gray-3;
    padding: 8px;
    border-radius: 4px;
    text-align: center;
    font-weight: 300;
    font-size: 12px;
    font-style: italic;
  }
}

</style>


<template lang="pug">

.service-order-attachments
  template(v-if="fetching")
    loading-lines(:lines="4")

  template(v-else-if="erred")
    .flex.center.vertical-center
      span {{ $t(".fetch-error.message") }}
      app-button.retry(theme="link", @click="fetch") {{ $t(".fetch-error.retry.label") }}

  template(v-else)
    .subsection-header.flex.vertical-center
      .icon-container.flex.center.vertical-center
        i.icon.far(:class="{ [icon]: true }")
      h2.subsection-title {{ $t(".title") }}

      i.diamond-icon.fas.fa-diamond

      span.count {{ $t(".count", { count: attachments.length }) }}

      i.diamond-icon.fas.fa-diamond

      span.flex.refresh-button(@click="fetch")
        .icon-container.flex.center.vertical-center
          i.icon.far.fa-refresh
        span.refresh-text {{ $t(".refresh-list") }}

      app-button.add-attachment(
        theme="link"
        @click="onAddAttachmentButtonClick"
      )
        i.icon.far.fa-plus
        span {{ $t(".add-attachments") }}

    template(v-if="!empty")
      .attachments.grid
        narrow-service-order-attachment-card(
          v-for="(attachment, index) in attachments",
          :attachment="attachment",
          :deletable="false",
          :editable="false",
          :key="`service-order-attachment-${attachment.id}`",
          :loading="submitting",
          :own-preview="false",
          :service-order-id="serviceOrderId"
        )

    template(v-else)
      .empty {{ $t(".empty") }}

  service-orders-attach-area-modal(
    v-if="showAttachAreaModal"
    :service-order-id="serviceOrderId"
    @close="onAttachAreaModalClose"
  )
</template>


<script>

// Mixins
import FetchMixin from "@/mixins/fetch-mixin"
import ServiceOrdersAttachAreaModal from "./service-orders-attach-area-modal.vue"


export default {
  components: {
    ServiceOrdersAttachAreaModal
  },

  mixins: [FetchMixin],

  props: {
    serviceOrderId: { type: [String, Number], required: true },
    submitting:     { type: Boolean, default: false },
    icon:           { type: String, default: "fa-message-lines" }
  },

  data() {
    return {
      i18nScope: "monitoring.quotes.reviews.service-order-attachments",

      resource: [],

      showAttachAreaModal: false
    }
  },

  computed: {
    attachments() { return this.resource },

    empty() {
      return _.blank(this.attachments)
    }
  },

  methods: {
    // @override Fetch mixin
    fetchRequest() {
      return this.$newSdk.monitoring.serviceOrders.attachments
        .all({
          serviceOrderId: this.serviceOrderId
        })
    },

    onAddAttachmentButtonClick() {
      if (this.submitting) return

      this.showAttachAreaModal = true
    },

    onAttachAreaModalClose(hasSubmitted) {
      this.showAttachAreaModal = false

      // XXX: aguarda 5s antes de atualizar a lista de anexos para dar algum tempo para processar
      if (hasSubmitted) {
        this.fetching = true
        _.delay(this.fetch, 5000)
      }
    }
  }
}

</script>

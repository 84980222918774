<template lang="pug">

    index-table(
      :empty="empty",
      :sortedBy="sortedBy",
      :initializing="initializing",
      :loading="loading",
      :cols-count="6",
      :column-sizes="columnSizes"
    )

      template(#tableHeader)
        table-header(
          :loading="loading",
          @sort="param => $emit('sort', param)",
          @toggle-all="$emit('toggle-all', !allSelectedOnPage)",
          :sort-param="sortParam",
          :sort-order="sortOrder",
          :all-selected="allSelectedOnPage",
          :empty="empty",
          :column-sizes="columnSizes",
          data-testid="serviceTableHeader"
        )

      template(#tableRows)
        table-row(
          v-for="service in services",
          :key="service.id",
          :service="service",
          :loading="loading",
          :column-sizes="columnSizes"
          :value="resourceValue(service.id)",
          @input="data => $emit('toggle-select', data)",
          :data-testid="`serviceTableRow-${service.id}`"
        )

</template>


<script>

// Components
import TableRow from "./table-row"
import TableHeader from "./table-header"

export default {
  components: {
    TableRow,
    TableHeader
  },

  props: {
    services:     { type: Array, required: true },
    initializing: { type: Boolean, default: false },
    loading:      { type: Boolean, default: false },
    sortedBy:     { type: String, default: "" },
    value:        { type: Array, default: () => [] },
    errors:       { type: Array, default: () => [] }
  },

  data() {
    return {
      i18nScope: "suppliers.service-supports.service-vehicle-model-groups.new.services.table"
    }
  },

  computed: {
    empty() {
      return _.blank(this.services)
    },

    hasValidationError() {
      return _.present(this.errors)
    },

    allSelectedOnPage() {
      return !this.empty && this.services.every(table => this.value.includes(table.id))
    },

    sortParam() {
      return this.sortedBy.split(":")[0]
    },

    sortOrder() {
      return this.sortedBy.split(":")[1] || "asc"
    },

    columnSizes() {
      return "34px 67px 48px 1fr 96px 48px"
    }
  },

  methods: {
    resourceValue(id) {
      return this.value.includes(id)
    }
  }
}

</script>

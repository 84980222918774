import { render, staticRenderFns } from "./ticket-row-skeleton.vue?vue&type=template&id=537f8266&scoped=true&lang=pug&"
var script = {}
import style0 from "./ticket-row-skeleton.vue?vue&type=style&index=0&id=537f8266&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "537f8266",
  null
  
)

export default component.exports
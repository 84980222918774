<style lang="scss" scoped>

.fields,
.hierarchy {
  margin-bottom: 24px;
  padding-bottom: 24px;
  border-bottom: 1px solid $light-gray-3;
}

.submit-actions {
  .button + .button {
    margin-left: 16px;
  }
}

.id-tag {
  margin: 0 8px;
}

.supplier-info {
  border: unset;
}

.loading-lines {
  margin-bottom: 16px;
}

.section + .section {
  margin-top: 24px;
  padding-top: 24px;
  border-top: 1px solid $light-gray-3;
}

</style>


<template lang="pug">

  .edit-supplier-default

    form.form(@submit.prevent="submit", method="post")
      .section.hierarchy(v-if="isBranch")
        ui-row.row
          ui-col(:span="8")
            head-office-select-field(
              name="supplier[headOffice]",
              :label="$t('.form.fields.headOffice.label')"
              :placeholder="$t('.form.fields.headOffice.placeholder')"
              :loading="submitting",
              :errors="errors.headOfficeId",
              data-as-value,
              :value="resource.headOffice",
              @change-option="changeHeadOffice",
              data-test="headOfficeInput"
            )

      form-fields.fields(
        edit,
        :resource="resource",
        :submitting="submitting",
        :errors="errors",
        @change-new-supplier-option="changeNewSupplier",
        @change-head-office-option="changeHeadOffice"
      )

      section.flex.space-between.vertical-center
        app-button(
          :to="{ name: 'supplier', params: { id } }",
          theme="gray",
          outline,
          :disabled="submitting"
        ) {{ $t('btn.cancel') }}

        .submit-actions
          app-button.button(
            type="submit",
            :loading="submitting && redirectToDetails",
            :disabled="submitting && !redirectToDetails",
            @click="redirectToDetails = true",
            outline,
            data-test="submitButton"
          )
            span {{ $t('.btn.submitAndContinue.label') }}
            span(slot="loading") {{ $t('.btn.submitAndContinue.loading') }}

          app-button.button(
            type="submit",
            :loading="submitting && !redirectToDetails",
            :disabled="submitting && redirectToDetails",
            data-test="submitButton"
          )
            span {{ $t('.btn.submit.label') }}
            span(slot="loading") {{ $t('.btn.submit.loading') }}

</template>


<script>

// Mixins
import FormMixin from "@/mixins/form-mixin"

// Local Components
import FormFields from "../form-fields"
import HeadOfficeSelectField from "../components/head-office-select-field"

// Models
import models from "@/models"
const { Supplier, Address } = models

export default {
  name: "EditSupplierDefaultTab",

  components: {
    FormFields,
    HeadOfficeSelectField
  },

  mixins: [FormMixin],

  props: {
    supplier: { type: Object, default: () => ({}) }
  },

  data() {
    return {
      i18nScope: "suppliers.default-tab.edit",

      id: this.$route.params.id,

      resource: {},

      redirectToDetails: false,

      nestedAttributes: {
        address: null,

        contacts: {
          emails: null,
          phones: null
        }
      }
    }
  },

  computed: {
    isBranch() {
      return _.get(this.resource, "hierarchy") === "branch"
    }
  },

  created() {
    this.initialize()
  },

  methods: {
    initialize() {
      this.resource = new Supplier(this.supplier)
      this.initialSerializedAttributes = this.serializeAttributes()
    },

    changeHeadOffice(option) {
      this.resource.headOfficeId = _.get(option, "data.id")
    },

    changeNewSupplier(option) {
      this.newSupplier = _.get(option, "data")
      const cnpj = _.get(option, "value")

      if (_.blank(cnpj)) {
        this.resource = new Supplier({ address: new Address() })
        return
      }

      this.resource = new Supplier({
        cnpj:    cnpj.replace(/[^0-9]/g, ""),
        address: new Address(),

        ..._.pick(this.resource, ["headOfficeId", "hierarchy"]),

        ..._.pick(this.newSupplier, [
          "name",
          "tradingName",
          "externalId",
          "ie",
          "im",
          "ieIsento",
          "imIsento",
          "sapId"
        ])
      })
    },

    // @override Form mixin
    serializeAttributes() {
      const sectorIds = this.resource.sectors.map(sector => sector.id)

      return {
        ...this.resource.$serialize({}, this.nestedAttributes),
        sector_ids: sectorIds
      }
    },

    // @override Form mixin
    submitRequest() {
      return this.$newSdk.suppliers.update({ supplierId: this.id, params: this.serializeAttributes() })
    },

    // @override Form mixin
    onSubmitSuccess({ data: { id, name } }) {
      this.$notifications.info(this.$t(".notifications.submit.success", { name }))

      this.$emit("change")

      if (this.redirectToDetails) return this.$router.push({ name: "editSupplierDetails", params: { id } })

      this.$router.push({ name: "supplier", params: { id } })
    },

    // @override Form mixin
    onSubmitError(err) {
      const error = err.error || err

      if (error.cancelled) return

      this.submitError = error
      this.submitErred = true

      this.$err.log(this.submitError)
      this.$notifications.error(this.$t(".notifications.submit.error"))

      if (error.originalError) this.setFormErrors(error.originalError)

      this.redirectToDetails = false
    }
  }
}

</script>

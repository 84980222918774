import { mixin } from "@caiena/model"

import Base from "./base"
import Discardable from "./mixins/discardable"


class QuoteProduct extends mixin(Base, [Discardable]) {
  static get attrs() {
    return _.uniq([
      ...super.attrs,

      "description",
      "quantity",
      "price",
      "partOrigin",
      "productId",
      "quoteId",
      "attachmentsCount",
      "provided",
      "warranty"
    ])
  }

  static get relations() {
    return {
      attachments: { type: "hasMany", model: "Attachment" },
      product:     { type: "belongsTo", model: "MovidaProduct" }
    }
  }

  static get enums() {
    return {
      partOrigin: {
        original:    "original",
        alternative: "alternative",
        reuse:       "reuse"
      }
    }
  }

  static get constraints() {
    return {
      productId: { presence: true },
      quantity:  { presence: true },

      price: {
        presence(value, attrs) {
          return !attrs.provided && !attrs.warranty
        },

        absence(value, attrs) {
          return !!attrs.warranty
        }
      },
      partOrigin: {
        presence(value, attrs) {
          return !attrs.provided
        }
      }
    }
  }

  static get virtuals() {
    return [
      "totalPrice"
    ]
  }

  get totalPrice() {
    return _.present(this.quantity) && _.present(this.price)
      ? this.quantity * this.price
      : null
  }
}


export default QuoteProduct

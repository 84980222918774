<style lang="scss" scoped>

.vehicle-issue-row {
  padding: 0 24px;
  min-height: 68px;
  border-top: 1px solid $light-gray-3;
  border-bottom: 1px solid $light-gray-3;
  height: 70px;

  & + .vehicle-issue-row {
    border-top: 1px solid transparent;
  }

  .id-column {
    width: 60px;
    margin-right: 24px;
  }

  .content-column {
    overflow: hidden;

    .vehicle-part,
    .symptom {
      font-family: $secondary-font;
      font-size: 18px;
      font-weight: 400;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .icon {
      margin-right: 8px;
    }

    .diamond-icon {
      margin: 0 8px;
      font-size: 4px;
      color: $gray-3;
    }
  }
}

</style>


<template lang="pug">

  .vehicle-issue-row.flex.vertical-center
    .id-column.no-shrink
      id-tag(:value="vehicleIssue.id")

    .content-column.grow.flex.vertical-center
      img.icon(:src="vehiclePartIcon")
      span.vehicle-part {{ vehiclePart }}

      i.diamond-icon.fa.fa-diamond.flex.center.vertical-center

      i.icon.fal.fa-engine-warning
      span.symptom {{ symptom }}

    .actions-column
      slot(name="actions")

</template>


<script>

import vehiclePartIcon from "@/assets/images/icons/sidebar/vehicle-part.svg"

export default {
  props: {
    vehicleIssue: { type: Object, default: () => ({}) }
  },

  data() {
    return {
      i18nScope: "services.components.vehicle-issue-row",

      showPopover: false,

      vehiclePartIcon
    }
  },

  computed: {
    vehiclePart() {
      return _.get(this.vehicleIssue, "vehiclePart.name")
    },

    symptom() {
      return _.get(this.vehicleIssue, "symptom.name")
    }
  }
}

</script>

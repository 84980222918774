<template>
  <component :is="tag" class="movida-button movida-common__app-button app-button menu-button rounded flex vertical-center">
      <i v-if="icon != null" :class="[icon, 'movida-button-icon']"></i>
      <div v-if="this.$slots.default" :class="{ 'ml-3' : icon != null }">
          <slot />
      </div>
  </component>
</template>

<script>
export default {
  name: 'MovidaButton', 
  props: {
      icon: {
          type: String,
          default: null
      }, 
      tag: {
          type: String,
          default: 'button'
      }, 
      primary: {
          type: Boolean,
          default: false
      }, 
      danger: {
          type: Boolean,
          default: false
      }, 
      size: {
          type: String,
          default: 'md'
      },
  }
};
</script>

<style>
.movida-button:hover {
  color: #fff;
  background: #F47920;
}
.movida-button-icon {
  margin-right: 10px;
}
</style>


// Libs
import { events } from "utils.vue"

// Modules
import auth from "@/modules/auth"

// Roles
import roles from "./roles"


class Ability {
  constructor() {
    this.roles = []
  }

  init() {
    events.$on("auth:login", () => this.build())
    events.$on(["auth:logout", "auth:expired"], () => this.clear())

    this.build()
  }

  build({ user = auth.user, roleNames = auth.roles, options = {} } = {}) {
    this.roles = roleNames
      .map(roleName => roles[roleName])
      .filter(role => !!role)
      .map(role => role.build(user, options))

    events.$emit("ability:build")
  }

  clear() {
    this.roles = []
  }

  can(...args) {
    return this.roles.some(role => role.can(...args))
  }

  cannot(...args) {
    return this.roles.every(role => role.cannot(...args))
  }
}

const instance = new Ability()

export default instance

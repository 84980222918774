import Base from "./Base"

class Sectors extends Base {
  create({ params = {} } = {}) {
    return this.action({
      method: "post",
      url:    "/sectors",
      params
    })
  }

  list({ params = {} } = {}) {
    return this.action({
      method: "get",
      url:    "/sectors",
      params
    })
  }

  get({ id, params = {} }) {
    if (_.blank(id)) {
      throw new Error('SDK Sectors - #get() - "id" is a required prop')
    }

    return this.action({
      method: "get",
      url:    `/sectors/${id}`,
      params
    })
  }
}

export default Sectors

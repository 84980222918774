import { Model, mixin } from "@caiena/model"
import EnumOptions from "./mixins/enum_options"
import Record from "./mixins/record"


class Base extends mixin(Model, [EnumOptions, Record]) {
  static get attrs() {
    return _.uniq([
      ...super.attrs,

      // Dados de apoio
      "tempId"  // id temporário para formulários aninhados
    ])
  }

  $serialize(options, nestedAttributes) {
    let serialized = super.$serialize(options)

    if (_.present(nestedAttributes)) {
      _.forEach(nestedAttributes, (nested, attrName) => {
        let nestedParams
        const nestedResource = this[attrName]

        if (_.present(nestedResource)) {
          if (Array.isArray(nestedResource)) {
            nestedParams = nestedResource.map(resource => resource.$serialize(options, nested))
          }
          else {
            nestedParams = this[attrName].$serialize(options, nested)
          }

          serialized[`${attrName}_attributes`] = nestedParams
        }
      })
    }

    return _.snakeizeKeys(serialized)
  }
}


export default Base

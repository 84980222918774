<style scoped lang="scss">

.product {
  padding-bottom: 24px;
}

.section + .section {
  margin-top: 24px;
  padding-top: 24px;
  border-top: 1px solid $light-gray-3;
}

.header {
  padding-top: 18px;

  .discarded-icon {
    margin-right: 8px;
  }

  .name {
    font-size: 30px;
    font-weight: bold;
    word-break: normal;
    overflow-wrap: break-word;
  }

  .tags {
    margin-left: 8px;

    .id-tag {
      margin-top: 4px;
    }

    * + * {
      margin-left: 8px;
    }
  }
}


.btn + .btn {
  margin-left: 8px;
}

.icon {
  font-size: 16px;
  margin-right: 8px;
}

</style>


<template lang="pug">

  .product
    template(v-if="fetching")
      loading-lines(:lines="4")

    template(v-else)
      header.section.header
        .flex.space-between
          .title.flex.vertical-center
            discarded-icon.discarded-icon(v-if="resource.$discarded", size="big")
            h2.name(data-testid="productName") {{ resource.type == "Tire" ? resource.name : resource.productName }}
            .tags
              id-tag.id-tag(:value="resource.id", size="big", data-testid="productId")
              sap-code-tag(:value="resource.sapId", size="big", data-testid="productSapId")

          .actions.flex
            template(v-if="resource.$discarded")
              enable-action.btn(
                :id="productId",
                :name="resource.name",
                @enabled="fetch",
                data-testid="enableAction"
              )
                i.icon.fal.fa-check-circle
                span {{ $t('.btn.enable.label') }}

            template(v-else)
              disable-action.btn(
                :id="productId",
                :name="resource.name",
                @disabled="fetch",
                data-testid="disableAction"
              )
                i.icon.fal.fa-lock
                span {{ $t('.btn.disable.label') }}

            destroy-action.btn(
              :id="resource.id",
              :name="resource.name",
              :type="resource.type",
              @destroy="onDestroy",
              data-test="destroyAction"
            )
              i.fal.fa-trash-alt.icon
              span {{ $t('.btn.destroy.label') }}

            app-button.btn(
              v-if="$can('update', 'Product')",
              theme="neutral",
              size="small",
              :to="{ name: 'editProduct', params: { id: productId }}"
            )
              i.fal.fa-pencil.icon
              span {{ $t('.btn.edit.label') }}

      section.section.content
        component(
          :is="componentType",
          :loading="fetching",
          :resource="resource",
        )

      section.section.footer.flex.space-between
        app-button(:to="{ name: 'products' }", outline)
          i.far.fa-arrow-left.icon
          span {{ $t('.btn.back.label') }}

        app-button(
          v-if="$can('create', 'Product')",
          theme="link",
          :to="{ name: 'newProduct' }"
        )
          span {{ $t('.btn.new.label') }}

</template>


<script>

// Models
import models from "@/models"

// View
import LoadedView from "@/views/loaded-view"

// Mixins
import FetchMixin from "@/mixins/fetch-mixin"

// Components
import DisableAction from "./components/disable-action"
import EnableAction from "./components/enable-action"
import DestroyAction from "./show/destroy-action"

// Partials
import Part from "./show/products/part"
import MotorOil from "./show/products/motor-oil"
import Tire from "./show/products/tire"
import Product from "./show/products/product"

export default {
  name: "Product",

  components: {
    Part,
    MotorOil,
    Tire,
    Product,

    DisableAction,
    EnableAction,
    DestroyAction
  },

  extends: LoadedView,

  mixins: [FetchMixin],

  data() {
    return {
      i18nScope: "products.show",
      routeName: "product",

      productId: null,

      // FetchMixin
      resource: {}
    }
  },

  computed: {
    allowedComponents() {
      return {
        Part,
        MotorOil,
        Tire,
        Product
      }
    },

    componentType() {
      const { type } = this.resource

      return this.allowedComponents[type]
    }
  },

  methods: {
    parseRoute() {
      this.productId = this.$params.asInteger(this.$route.params.id)
    },

    onDestroy() {
      this.$router.push({ name: "products" })
    },

    fetchRequest() {
      return this.$newSdk.products.find({ productId: this.productId })
    },

    // @override FetchMixin
    onFetchSuccess({ data }) {
      this.resource = new models[data.type](data)
    }
  }
}

</script>

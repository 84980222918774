<style lang="scss" scoped>

.service-timeline {

  > .timeline {
    padding: 8px 0;

    .stage {
      position: relative;

      border-radius: 8px;
      border: 1px solid $light-gray-3;

      width:  26px;
      height: 26px;

      cursor: pointer;
      background: white;
      color: $gray;

      .icon {
        font-weight: 400;  // font-awesome outline
        font-size: 14px;
        color: $gray;

        &.stacked {
          --stacked-icon-size: 12px;
          position: absolute;
          right: calc(-1 * var(--stacked-icon-size) / 3);
          top:   calc(-1 * var(--stacked-icon-size) / 3);
          font-size: var(--stacked-icon-size) !important;
          border-radius: var(--stacked-icon-size);
          padding: 2px;
          background: white;

          &.warning {
            color: $dark-yellow;
          }
        }
      }

      &.past {
        border: 1px solid $light-purple-3;
        color: $purple;

        .icon {
          font-weight: 900;  // font-awesome solid
          color: $purple;
        }

        &:hover {
          border-color: $purple;
        }
      }

      &.current {
        border: 1px solid $light-purple;

        width:  40px;
        height: 40px;

        background: $purple;

        .icon {
          font-weight: 900;  // font-awesome solid
          font-size: 16px;
          color: $white;
        }

        &:hover {
          border-color: $light-purple-2;
        }

        &:active {
          background: $dark-purple;
        }
      }

      &.warning {
        background: $dark-yellow;

        .icon {
          font-weight: 900; // font-awesome solid
          color: $white;
        }

        &:active {
          // TODO: $dark-yellow-2
          background: $dark-yellow;
        }
      }

      &.canceled {
        background: $gray-2;

        .icon {
          font-weight: 900; // font-awesome solid
          color: $white;
        }

        &:active {
          background: $gray-4;
        }
      }
    } // .stage

    .connector {
      width: 16px;
      border-top: 1px solid $light-gray-3;

      &.past,
      &.current {
        border-color: $light-purple-3;
      }
    }
  } // .timeline


  > .description {
  } // .description

  .text {
    font-family: $secondary-font;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 150%;

    vertical-align: center;

    &.label {
      color: $brand-alt;
    }

    &.tip {
      color: $dark-gray;
    }

    &.canceled {
      color: $dark-gray;
      padding-left: 6px;
    }
  }

}

</style>


<template lang="pug">

  .service-timeline.flex.column-direction

    template(v-if="canceled")
      .timeline.flex.vertical-center
        v-popover.popover(
          placement="bottom",
          trigger="manual",
          :open="popoverFlagFor('canceled')",
          @hide="hidePopoverFor('canceled')"
        )
          .stage.canceled.flex.center.vertical-center(
            @click="showPopoverFor('canceled')"
          )
            i.icon.fa.fa-times

          template(#popover)
            component(
              :is="popoverComponentFor('canceled')",
              :service-order-rid="serviceOrder.rid",
              :quote-id="serviceOrder.quoteId",
              :show="popoverFlagFor('canceled')"
            )

        span.text.label.canceled
          | {{ $t(".stages.canceled.description.label") }}
          |
          template(v-if="serviceOrder.$present('canceledAt')")
            span.timestamp(v-tooltip="$l('time.formats.short', serviceOrder.canceledAt)")
              | {{ $fromNow(serviceOrder.canceledAt) }}

    //- se não estiver cancelada, renderiza a timeline
    template(v-else)

      .timeline.flex.vertical-center

        //- stage == "open"
        //----
        template(v-if="stageAvailable('open')")
          v-popover.popover(
            placement="bottom",
            trigger="manual",
            :open="popoverFlagFor('open')",
            @hide="hidePopoverFor('open')"
          )
            .stage.open.flex.center.vertical-center(
              :class="cssClassFor('open')",
              @click="showPopoverFor('open')"
            )
              i.icon.fa(:class="cssIconClassFor('open')")

            template(#popover)
              component(
                :is="popoverComponentFor('open')",
                :service-order-rid="serviceOrder.rid",
                :quote-id="serviceOrder.quoteId",
                :show="popoverFlagFor('open')"
              )

        //- stage == "vehicle_stopped"
        //----
        template(v-if="stageAvailable('vehicle_stopped')")
          .connector(:class="cssClassFor('vehicle_stopped')")

          v-popover.popover(
            placement="bottom",
            trigger="manual",
            :open="popoverFlagFor('vehicle_stopped')",
            @hide="hidePopoverFor('vehicle_stopped')"
          )
            .stage.vehicle_stopped.flex.center.vertical-center(
              :class="cssClassFor('vehicle_stopped')",
              @click="showPopoverFor('vehicle_stopped')"
            )
              i.icon.fa(:class="cssIconClassFor('vehicle_stopped')")

            template(#popover)
              component(
                :is="popoverComponentFor('vehicle_stopped')",
                :service-order-rid="serviceOrder.rid",
                :quote-id="serviceOrder.quoteId",
                :show="popoverFlagFor('vehicle_stopped')"
              )

        //- stage == "quote_issued"
        //----
        template(v-if="stageAvailable('quote_issued')")
          .connector(:class="cssClassFor('quote_issued')")

          v-popover.popover(
            placement="bottom",
            trigger="manual",
            :open="popoverFlagFor('quote_issued')",
            @hide="hidePopoverFor('quote_issued')"
          )
            .stage.quote_issued.flex.center.vertical-center(
              :class="cssClassFor('quote_issued')",
              @click="showPopoverFor('quote_issued')"
            )
              i.icon.fa(:class="cssIconClassFor('quote_issued')")

            template(#popover)
              component(
                :is="popoverComponentFor('quote_issued')",
                :service-order-rid="serviceOrder.rid",
                :quote-id="serviceOrder.quoteId",
                :show="popoverFlagFor('quote_issued')"
              )


        //- stage == "ongoing"
        //----
        template(v-if="stageAvailable('ongoing')")
          .connector(:class="cssClassFor('ongoing')")

          v-popover.popover(
            placement="bottom",
            trigger="manual",
            :open="popoverFlagFor('ongoing')",
            @hide="hidePopoverFor('ongoing')"
          )
            .stage.ongoing.flex.center.vertical-center(
              :class="cssClassFor('ongoing')",
              @click="showPopoverFor('ongoing')"
            )
              i.icon.fa(:class="cssIconClassFor('ongoing')")
              template(v-if="serviceOrder.postponementsCount > 0")
                i.icon.stacked.warning.fa.fa-clock

            template(#popover)
              component(
                :is="popoverComponentFor('ongoing')",
                :service-order-rid="serviceOrder.rid",
                :quote-id="serviceOrder.quoteId",
                :show="popoverFlagFor('ongoing')"
              )


        //- stage == "executed"
        //----
        template(v-if="stageAvailable('executed')")
          .connector(:class="cssClassFor('executed')")

          v-popover.popover(
            placement="bottom",
            trigger="manual",
            :open="popoverFlagFor('executed')",
            @hide="hidePopoverFor('executed')"
          )
            .stage.executed.flex.center.vertical-center(
              :class="cssClassFor('executed')",
              @click="showPopoverFor('executed')"
            )
              i.icon.fa(:class="cssIconClassFor('executed')")

            template(#popover)
              component(
                :is="popoverComponentFor('executed')",
                :service-order-rid="serviceOrder.rid",
                :quote-id="serviceOrder.quoteId",
                :show="popoverFlagFor('executed')"
              )


        //- stage == "vehicle_withdrawed"
        //----
        template(v-if="stageAvailable('vehicle_withdrawed')")
          .connector(:class="cssClassFor('vehicle_withdrawed')")

          v-popover.popover(
            placement="bottom",
            trigger="manual",
            :open="popoverFlagFor('vehicle_withdrawed')",
            @hide="hidePopoverFor('vehicle_withdrawed')"
          )
            .stage.vehicle_withdrawed.flex.center.vertical-center(
              :class="cssClassFor('vehicle_withdrawed')",
              @click="showPopoverFor('vehicle_withdrawed')"
            )
              i.icon.fa(:class="cssIconClassFor('vehicle_withdrawed')")

            template(#popover)
              component(
                :is="popoverComponentFor('vehicle_withdrawed')",
                :service-order-rid="serviceOrder.rid",
                :quote-id="serviceOrder.quoteId",
                :show="popoverFlagFor('vehicle_withdrawed')"
              )

      //- [end] .timeline


      //- "description", abaixo da timeline
      //- tem variação para cada estado!
      .description.flex.column-direction

        //- stage == "open"
        //----
        template(v-if="currentStage == 'open'")
          span.text.label
            | {{ $t(".stages.open.description.label") }}
            |
            span.timestamp(v-tooltip="$l('time.formats.short', serviceOrder.issuedAt)")
              | {{ $fromNow(serviceOrder.issuedAt) }}

          span.text.tip
            | {{ $t(".stages.open.description.tip") }}
            |
            span.timestamp(v-tooltip="$l('time.formats.short', serviceOrder.scheduledAt)")
              | {{ $fromNow(serviceOrder.scheduledAt) }}


        //- stage == "vehicle_stopped"
        //----
        template(v-else-if="currentStage == 'vehicle_stopped'")
          span.text.label
            | {{ $t(".stages.vehicle_stopped.description.label") }}
            |
            span.timestamp(v-tooltip="$l('time.formats.short', serviceOrder.enteringAt)")
              | {{ $fromNow(serviceOrder.enteringAt) }}

          span.text.tip
            | {{ $t(".stages.vehicle_stopped.description.tip") }}


        //- stage == "quote_issued"
        //----
        template(v-else-if="currentStage == 'quote_issued'")
          span.text.label
            | {{ $t(".stages.quote_issued.description.label") }}
            |
            span.timestamp(v-tooltip="$l('time.formats.short', serviceOrder.quoteIssuedAt)")
              | {{ $fromNow(serviceOrder.quoteIssuedAt) }}

          span.text.tip
            | {{ $t(".stages.quote_issued.description.tip") }}


        //- stage == "ongoing"
        //----
        template(v-else-if="currentStage == 'ongoing'")

          template(v-if="vehicleStopped")
            span.text.label
              | {{ $t(".stages.ongoing.description.label") }}
              |
              span.timestamp(v-tooltip="$l('time.formats.short', ongoingAt)")
                | {{ $fromNow(ongoingAt) }}

            span.text.tip
              | {{ $t(".stages.ongoing.description.tip") }}
              |
              span.timestamp(v-tooltip="$l('time.formats.short', serviceOrder.estimatedCompletedAt)")
                | {{ $fromNow(serviceOrder.estimatedCompletedAt) }}

          //- senão, ainda aguarda a Parada do Veículo
          template(v-else)
            span.text.label
              | {{ $t(".stages.ongoing.awaitingVehicleStop.description.label") }}
              |
              span.timestamp(v-tooltip="$l('time.formats.short', serviceOrder.authorizedAt)")
                | {{ $fromNow(serviceOrder.authorizedAt) }}

            span.text.tip
              | {{ $t(".stages.ongoing.awaitingVehicleStop.description.tip") }}
              |
              span.timestamp(v-tooltip="$l('time.formats.short', serviceOrder.scheduledAt)")
                | {{ serviceOrder.$present("scheduledAt") ? $fromNow(serviceOrder.scheduledAt) : $t("abbr.unavailable") }}



        //- stage == "executed"
        //----
        template(v-else-if="currentStage == 'executed'")
          span.text.label
            | {{ $t(".stages.executed.description.label") }}
            |
            span.timestamp(v-tooltip="$l('time.formats.short', serviceOrder.completedAt)")
              | {{ $fromNow(serviceOrder.completedAt) }}

          span.text.tip
            | {{ $t(".stages.executed.description.tip") }}


        //- stage == "vehicle_withdrawed"
        //----
        template(v-else-if="currentStage == 'vehicle_withdrawed'")
          span.text.label
            | {{ $t(".stages.vehicle_withdrawed.description.label") }}
            |
            span.timestamp(v-tooltip="$l('time.formats.short', serviceOrder.leavingAt)")
              | {{ $fromNow(serviceOrder.leavingAt) }}

      //- [end] .description

</template>


<script>

// Components
import PopoverCancellation      from "./timeline-popover-cancellation.vue"
import PopoverExecuted          from "./timeline-popover-executed.vue"
import PopoverOpen              from "./timeline-popover-open.vue"
import PopoverOngoing           from "./timeline-popover-ongoing.vue"
import PopoverQuoteIssued       from "./timeline-popover-quote-issued.vue"
import PopoverVehicleStopped    from "./timeline-popover-vehicle-stopped.vue"
import PopoverVehicleWithdrawed from "./timeline-popover-vehicle-withdrawed.vue"


// Models
import models from "@/models"
const { MovidaServiceOrder } = models


export default {
  components: {
    PopoverOpen,
    PopoverVehicleStopped,
    PopoverQuoteIssued,

    PopoverCancellation,
    PopoverExecuted,
    PopoverOngoing,
    PopoverVehicleWithdrawed
  },

  props: {
    serviceOrder: { type: MovidaServiceOrder, required: true }
  },

  data() {
    return {
      i18nScope: "monitoring.service-timeline",

      showPopoverCancellation:      false,
      showPopoverExecuted:          false,
      showPopoverOngoing:           false,
      showPopoverOpen:              false,
      showPopoverQuoteIssued:       false,
      showPopoverVehicleStopped:    false,
      showPopoverVehicleWithdrawed: false
    }
  },

  computed: {
    stages() {
      // em ordem!
      return [
        "open",
        "vehicle_stopped",
        "quote_issued",
        "ongoing",
        "executed",
        "vehicle_withdrawed"
      ]
    },

    currentStage() {
      let state = this.serviceOrder.state

      if (state == "open") {
        if (this.vehicleStopped && !this.quoteIssued) return "vehicle_stopped"
        if (this.quoteIssued) return "quote_issued"
      }

      if (state == "executed") {
        if (this.vehicleWithdrawed) return "vehicle_withdrawed"
      }

      return state
    },

    canceled() {
      return this.serviceOrder.state == "canceled"
    },

    quoteIssued() {
      return this.serviceOrder.quoteIssued
    },

    vehicleStopped() {
      return this.serviceOrder.vehicleStopped
    },

    vehicleWithdrawed() {
      return this.serviceOrder.vehicleWithdrawed
    },

    ongoingAt() {
      let moments = [moment(this.serviceOrder.enteringAt), moment(this.serviceOrder.authorizedAt)]
      return moment.max(moments).toISOString() // mantendo como isostring, como no Model
    }
  },

  methods: {
    cssClassFor(stage) {
      let classes = {
        past:    this.isPastStage(stage),
        current: this.isCurrentStage(stage)
      }

      if (stage == "ongoing" && this.isCurrentStage(stage) && !this.vehicleStopped) {
        classes.warning = true
      }

      return classes
    },

    cssIconClassFor(stage) {
      switch (stage) {
        case "open":               return "fa-file-invoice-dollar"
        case "vehicle_stopped":    return "fa-car-garage"
        case "quote_issued":       return "fa-memo"
        case "ongoing":            return "fa-wrench-simple"
        case "executed":           return "fa-check"
        case "vehicle_withdrawed": return "fa-car-side"
        default: return null
      }
    },

    isPastStage(stage) {
      switch (stage) {
        case "vehicle_stopped": {
          return this.currentStage != stage && this.vehicleStopped
        }

        case "quote_issued": {
          return this.currentStage != stage && this.quoteIssued
        }

        default: return this.stages.indexOf(stage) < this.stages.indexOf(this.currentStage)
      }
    },

    isCurrentStage(stage) {
      return this.currentStage == stage
    },

    stageAvailable(stage) {
      return true
    },

    popoverFlagNameFor(stage) {
      switch (stage) {
        case "open":               return "showPopoverOpen"
        case "vehicle_stopped":    return "showPopoverVehicleStopped"
        case "quote_issued":       return "showPopoverQuoteIssued"
        case "ongoing":            return "showPopoverOngoing"
        case "executed":           return "showPopoverExecuted"
        case "vehicle_withdrawed": return "showPopoverVehicleWithdrawed"
        case "canceled":           return "showPopoverCancellation"
        default: return null
      }
    },

    popoverFlagFor(stage) {
      const flagName = this.popoverFlagNameFor(stage)

      return this[flagName]
    },

    hidePopoverFor(stage) {
      const flagName = this.popoverFlagNameFor(stage)

      this[flagName] = false
    },

    showPopoverFor(stage) {
      const flagName = this.popoverFlagNameFor(stage)

      this[flagName] = true
    },

    popoverComponentFor(stage) {
      switch (stage) {
        case "open":               return PopoverOpen
        case "vehicle_stopped":    return PopoverVehicleStopped
        case "quote_issued":       return PopoverQuoteIssued
        case "ongoing":            return PopoverOngoing
        case "executed":           return PopoverExecuted
        case "vehicle_withdrawed": return PopoverVehicleWithdrawed
        case "canceled":           return PopoverCancellation
        default: return null
      }
    }
  }
}

</script>

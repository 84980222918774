<style lang="scss" scoped>

.new-store-supplier {
  min-height: 500px;

  .search {
    margin-bottom: 28px;
  }

  .paginator-content {
    padding-top: 24px;

    &.loading {
      max-width: 400px;
      margin: 0 auto;
    }
  }
}

</style>


<template lang="pug">

  app-modal(
    :heading="$t('.heading')",
    :width="1080",
    @close="goBack"
  )
    .new-store-supplier.body
      search-form.search(
        :params="params",
        @search="search"
      )

      app-list-header(
        v-if="showAppListHeader"

        :has-filters="hasFilters",
        :page="params.page",
        :pagination="pagination",
        :loading="fetching",

        @clear="clearFilters"
      )

      app-list(
        :collection="resource",
        :loading="fetching",
        :empty="empty",
        :empty-text="$t('.empty')",
        :error-text="$t('.error')",
        :error="hasFetchError"
      )
        base-supplier-row.suplier-row(
          v-for="(supplier, index) in resource",
          :key="`available-supplier-${supplier.id}-${index}`",
          :supplier="supplier",
          hide-hierarchy,
          hide-link-name,
          data-test="supplierRow"
        )
          .action(slot="actions")
            app-button(
              theme="link",
              @click="addSupplier(supplier)",
              :disabled="submitting"
            ) {{ $t('.add') }}

      .paginator-content(v-if="fetching || showPaginator", :class="{ loading: fetching }")
        paginator(
          :loading="fetching",
          :per-page="pagination.perPage",
          :total="pagination.total",
          @input="onChangePage",
          :value="params.page",
          :disabled="fetching",
          data-test="paginator"
        )

</template>


<script>

// Extends
import SearchableIndexView from "@/views/searchable-index-view"

// Local components
import SupplierRow from "@/views/suppliers/components/supplier-row"
import SearchForm from "./new/search-form"

export default {
  name: "NewStoreSupplier",

  components: {
    SupplierRow,
    SearchForm
  },

  extends: SearchableIndexView,

  props: {
    backRouteQuery: { type: Object, default: () => ({}) }
  },


  data() {
    return {
      i18nScope: "stores.suppliers.new",

      id: this.$route.params.id,

      // @override Fetchable mixin
      model: "Supplier",

      submitting: false,
      request:    null,

      // Query Mixin data
      parseQueryParams: {
        q:    "string",
        page: "integer"
      }
    }
  },

  computed: {
    backRoute() {
      return {
        name:   "storeSuppliers",
        params: { id: this.id },
        query:  this.backRouteQuery
      }
    }
  },

  methods: {
    // @override SearchableIndexView
    goBack() {
      // Voltando para rota pai ao fechar o modal
      this.$router.push(this.backRoute)
    },

    async addSupplier(supplier) {
      if (!this.submitting) {
        this.$notifications.clear()
        this.submitting = true
        this.errors = {}

        let currentRequest

        try {
          if (this.request) this.request.cancel("Duplicated submit")

          currentRequest = this.$sdk.stores.createSupplier({ id: this.id, supplierId: supplier.id })
          this.request = currentRequest

          await currentRequest
          this.$notifications.info(this.$t(".notifications.submit.success", { name: supplier.name }))

          this.$emit("changed")
          this.goBack()
        }
        catch (err) {
          if (!err.cancelled) {
            this.$err.log(err)
            this.$notifications.error(this.$t(".notifications.submit.error"))
          }
        }
        finally {
          if (this.request === currentRequest) {
            this.request = null
            this.submitting = false
          }
        }
      }
    },

    // @override Fetchable mixin
    fetch() {
      return this.$sdk.stores.listAvailableSuppliers({ id: this.id, params: this.httpParams })
    },

    // @override Fetchable mixin
    onFetchSuccess(response) {
      this.setPagination(response.headers)
    },

    // @override Fetchable mixin
    onFetchError(err) {
      // Dealing with appError from extension view.vue
      if (this.hasViewError(err)) this.appError = err

      this.$err.log(err)
      this.$notifications.error(this.$t(".notifications.fetch.failure"))
    }
  }
}

</script>

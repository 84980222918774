<style lang="scss" scoped>
</style>


<template lang="pug">

  select-field(
    v-bind="$props",
    v-on="$listeners",
    :options="options"
  )

</template>


<script>

import Vue from "vue"

import SelectField from "@/components/form/select-field"
import models from "@/models"

const { VehicleModelCombustion } = models

export default {
  extends: SelectField,

  props: {
    // Mudando comportamento para default ser true
    searchable: { type: Boolean, default: true }
  },

  computed: {
    options() {
      return VehicleModelCombustion.$enums.fuel.values.map(value => ({
        value,
        label: VehicleModelCombustion.$tEnum("fuel", value)
      }))
    }
  },

  // Removendo props 'options' que está sendo reescrita como _computed_
  beforeCreate() {
    Vue.delete(this.$options.props, "options")
  }
}

</script>

<style scoped lang="scss">

.products {
  .search {
    margin-bottom: 32px;
  }

  .buttons {
    margin-bottom: 16px;

    .action-popover-loader {
      width: 200px;

      + .action-popover-loader {
        margin-left: 24px;
      }
    }

    ::v-deep .movida-common__app-button{
      margin-left: 40px;
    }
  }

  .button {
    font-size: 14px;

    &.product {
      .icon {
        margin-right: 4px;
        font-size: 14px;
      }
    }

    &.spreadsheets {
      .icon {
        margin-left: 4px;
        font-size: 12px;
      }
    }
  }

  .paginator-content {
    padding-top: 24px;

    &.loading {
      max-width: 400px;
      margin: 0 auto;
    }
  }
}

</style>


<template lang="pug">

  .products
    search-form.search(
      :loading="fetching",
      :params="parsedQueryParams",
      @search="search"
    )

    .buttons.flex.end
      template(v-if="initializing")
        loading-lines.action-popover-loader(:min="100", :max="100", role="progress")

      template(v-else)
        popover-menu(
          v-if="popoverMenuAuthorization",
          :show.sync="showSpreadsheetPopover",
          data-testid="spreadsheetPopoverMenu"
        )
          template(slot="presentation")
            app-button.button.spreadsheets(
              theme="link",
              @click="showSpreadsheetPopover = true"
            )
              span {{ $t('.btn.import.label') }}
              i.icon.fas.fa-chevron-down

          popover-menu-button(
            v-if="$can('update', 'Deal') && editable",
            icon="fal fa-file-upload",
            :to="{ name: 'newSupplierDealProductsDataLoad', params: { id: supplierId, dealId } }",
            :text="$t('.btn.import.actions.upload.label')",
            @close-menu="showSpreadsheetPopover = false",
            data-testid="uploadAction"
          )

          popover-menu-button(
            v-if="$can('read', 'Deal')",
            icon="fal fa-history",
            :to="{ name: 'supplierDealProductsDataLoads', params: { id: supplierId, dealId } }",
            :text="$t('.btn.import.actions.history.label')",
            @close-menu="showSpreadsheetPopover = false"
          )

          app-button.popover-menu-button(
            v-if="$can('read', 'Deal')",
            theme="link",
            border="square",
            @click="showDownloadModal = true"
          )
            .button-menu.flex.vertical-center.full-width(@click="showSpreadsheetPopover = false")
              .icon-content
                i.icon.fal.fa-arrow-to-bottom
              span {{ $t('.btn.import.actions.download.label') }}

        spreadsheet-download-modal(
          v-if="showDownloadModal",
          :deal-id="dealId",
          @close="showDownloadModal = false"
        )

      template(v-if="initializing")
        loading-lines.action-popover-loader(:min="100", :max="100", role="progress")

      template(v-else)
        app-button.button.product(
          v-if="$can('update', 'Deal') && editable",
          theme="link",
          :to="newProductRoute",
          data-testid="newProduct"
        )
          i.icon.far.fa-plus
          span {{ $t('.btn.add.label') }}

    app-list-header(
      v-if="showAppListHeader"

      :has-filters="hasFilters",
      :page="parsedQueryParams.page",
      :pagination="pagination",
      :loading="initializing",

      @clear="clearFilters"
    )

    deal-products-table(
      :deal-products="dealProducts",
      :initializing="initializing"
      :loading="fetching",
      :editable="editable",
      :sorted-by="parsedQueryParams.sort",
      @sort="onSortChange"
      @destroy="fetch"
    )

    .paginator-content(v-if="fetching || showPaginator", :class="{ loading: fetching }")
      paginator(
        :loading="fetching",
        :per-page="pagination.perPage",
        :total="pagination.total",
        :value="parsedQueryParams.page",
        :disabled="fetching",
        @input="onPageChange",
        data-testid="paginator"
      )

    router-view(
      :back-route-query="currentRouteQuery",
      :editable="editable"
      @change="fetch"
    )

</template>


<script>

// View
import IndexView from "@/views/index-view"

// Mixins
import FetchMixin from "@/mixins/fetch-mixin"

// Components
import SearchForm from "./index/search-form"
import DealProductsTable from "./index/deal-products-table"
import SpreadsheetDownloadModal from "./index/spreadsheet-download-modal"

export default {
  name: "SupplierDealProducts",

  components: {
    SearchForm,
    DealProductsTable,
    SpreadsheetDownloadModal
  },

  extends: IndexView,

  mixins: [FetchMixin],

  props: {
    editable: { type: Boolean, default: false }
  },

  data() {
    return {
      i18nScope: "suppliers.deals.products.index",
      routeName: "supplierDealProducts",

      supplierId: null,
      dealId:     null,

      resource: [],

      // @requirement IndexView
      queryParamsStructure: {
        q:                      "string",
        vehicle_model_group_id: "integer",
        page:                   "integer",
        sort:                   "string"
      },

      showSpreadsheetPopover: false,
      showDownloadModal:      false
    }
  },

  computed: {
    dealProducts: {
      get() { return this.resource },

      set(value) { this.resource = value }
    },

    popoverMenuAuthorization() {
      return ["read", "update"].some(action => this.$can(action, "Deal"))
    },

    newProductRoute() {
      return {
        name:   "newSupplierDealProduct",
        params: {
          id:     this.supplierId,
          dealId: this.dealId
        }
      }
    }
  },

  methods: {
    parseRoute() {
      this.supplierId = this.$params.asInteger(this.$route.params.id)
      this.dealId = this.$params.asInteger(this.$route.params.dealId)
    },

    // @override Fetch Mixin
    fetchRequest() {
      return this.$newSdk.deals.listProducts({
        dealId: this.dealId,
        params: this.requestQueryParams
      })
    }
  }
}

</script>

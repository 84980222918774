<style lang="scss" scoped>

.product-option {
  display: grid;
  grid-template-columns: 70px 70px 1fr 1fr 60px;
  border-bottom: 1px solid $light-gray-3;

  .column {
    padding: 18px 12px;
    height: 48px;
    overflow: hidden;

    .icon {
      min-width: 32px;
      margin-right: 8px;
    }

    .product-info {
      overflow: hidden;

      .product-name {
        font-size: 14px;
        font-weight: 400;
        color: $gray-3;
        word-break: normal;
        overflow-wrap: break-word;
        margin-bottom: 4px;
        display: block;

        &.active {
          color: $orange;
        }

        &.focus {
          color: $light-orange;
        }
      }
    }

    .sap-tag {
      font-family: $primary-monospace-font;
      font-weight: 400;
      font-size: 14px;
      color: $dark-gray;
      text-align: initial;
    }

    .actions-column {
      min-width: 42px;
      margin-left: 12px;

      .remove-icon-content {
        display: inline-block;
        width: 24px;
        height: 24px;
        line-height: 24px;
        text-align: center;
        border-radius: 50px;
        color: $gray-2;
        transition: all 100ms linear;
      }
    }
  }

  cursor: pointer;
  * {
    cursor: pointer;
  }

  &.active {
    color: $orange;
    background: $light-orange;
  }

  &.focus {
    background: $light-orange;
    color: $orange;

    .remove-icon-content {
      background: $light-gray-3;
      color: $orange;
    }
  }
}

</style>


<template lang="pug">

  .product-option.flex.vertical-center(:class="{ focus, active }")
    .column.flex.vertical-center
      id-tag.tag(:value="product.id")

    .column.flex.vertical-center
      product-type-icon.icon(:type="product.type")

    .column.flex.vertical-center.product-info.grow
      app-span.product-name(
        :value="product.productName",
        crop
      )

    .column.flex.vertical-center
      sap-icon
      span.sap-tag(data-testid="productSapId") {{ product.sapId }}

    .column.flex.vertical-center
      .actions-column.center
        .remove-icon-content(v-if="active")
          i.fal.fa-times

</template>


<script>

export default {
  name: "ProductOption",

  props: {
    option: { type: Object, required: true },
    active: { type: Boolean, default: false },
    focus:  { type: Boolean, default: false }
  },

  data() {
    return {
      i18nScope: "components.product-option"
    }
  },

  computed: {
    product() {
      return this.option.data
    }
  }
}

</script>

<!--
<doc lang="markdown">
Exibe informações de busca

_Props_: hasFilters. page, pagination, loading

Como usar:
```pug
  app-list-header(
    :has-filters="hasFilters",
    :page="params.page",
    :pagination="pagination",
    :loading="fetching",

    @clear="clearFilters"
  )
```
</doc>
-->

<style lang="scss" scoped>

.app-list-header {
  padding-top: 8px;
  padding-bottom: 8px;
}

.results-indicator {
  text-align: right;
  flex-grow: 1;
}

</style>


<template lang="pug">

  .app-list-header.flex.space-between
    filters-button(
      v-if="hasFilters",
      :loading="loading && disableFiltersOnLoad",
      @clear="$emit('clear')"
    )

    results-indicator(
      :page="page",
      :per-page="pagination.perPage",
      :total="pagination.total",
      :loading="loading",
      :hide="hideResults"
    )

</template>


<script>

export default {
  name: "AppListHeader",

  props: {
    hasFilters:           { type: Boolean, default: false },
    page:                 { type: Number, default: 1 },
    pagination:           { type: Object, required: true },
    loading:              { type: Boolean, default: false },
    hideResults:          { type: Boolean, default: false },
    disableFiltersOnLoad: { type: Boolean, default: false }
  },

  data() {
    return {
      i18nScope: "components.app-list-header"
    }
  }
}

</script>

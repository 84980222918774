<style scoped lang="scss">
</style>

<template lang="pug">

  .service-order
    template(v-if="fetching")
      loading-lines(:lines="4")

    template(v-else)
      component(
        :is="typedComponent",
        :resource="resource",
        :fetching="fetching",
        @synced="_fetch",
        @resend-error="_fetch"
      )

</template>


<script>

// Mixins
import { mixins } from "movida-common.vue"

// Extends
import View from "@/views/view"

// Components
import InternalMaintenance from "./show/service-order-show/internal-maintenance.vue"
import ExternalMaintenance from "./show/service-order-show/external-maintenance.vue"
import Repair from "./show/service-order-show/repair.vue"

// Models
import models from "@/models"
const { VehicleModel } = models

const { Fetchable } = mixins

export default {
  name: "StoreTicketServiceOrder",

  components: {
    InternalMaintenance,
    ExternalMaintenance,
    Repair
  },

  extends: View,

  mixins: [Fetchable],

  data() {
    return {
      i18nScope: "stores.tickets.service-orders.show",

      storeId:        this.$params.asInteger(this.$route.params.id),
      ticketId:       this.$params.asInteger(this.$route.params.ticketId),
      serviceOrderId: this.$params.asInteger(this.$route.params.serviceOrderId),

      // Fetchable mixin
      resource: {},
      model:    null
    }
  },

  computed: {
    allowedComponents() {
      return {
        "ServiceOrders::InternalMaintenance": InternalMaintenance,
        "ServiceOrders::Maintenance":         ExternalMaintenance,
        "ServiceOrders::Repair":              Repair
      }
    },

    typedComponent() {
      const { type } = this.resource
      return this.allowedComponents[type]
    }
  },

  methods: {
    fetch() {
      return this.$sdk.stores.getTicketServiceOrder({
        id:             this.storeId,
        ticketId:       this.ticketId,
        serviceOrderId: this.serviceOrderId
      })
    },

    // @override Fetchable mixin
    onFetchSuccess(response) {
      const { data } = response
      const camelizedData = _.camelizeKeys(data)

      const types = {
        "ServiceOrders::InternalMaintenance": "ServiceOrderInternalMaintenance",
        "ServiceOrders::Maintenance":         "ServiceOrderExternalMaintenance",
        "ServiceOrders::Repair":              "ServiceOrderRepair"
      }

      const type = types[camelizedData.type]

      const { vehicleModel } = camelizedData.vehicle
      camelizedData.vehicle.vehicleModel = VehicleModel.build(vehicleModel)

      this.resource = new models[type](camelizedData)
    },

    // @override Fetchable mixin
    onFetchError(err) {
      // Dealing with appError from extension view.vue
      if (this.hasViewError(err)) this.appError = err

      this.$err.log(err)
      this.$notifications.error(this.$t(".notifications.fetch.failure"))
    }
  }
}

</script>

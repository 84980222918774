<template lang="pug">

  select-field.fuel-level-select-field(
    v-bind="selectFieldProps",
    v-model="_value",
    v-on="$listeners",
    :options="options"
  )

</template>


<script>

// Mixins
import FieldMixin from "@/mixins/field"


export default {
  mixins: [FieldMixin],

  data() {
    return {
      i18nScope: "monitoring.vehicle-receipts.fuel-level-select-field"
    }
  },

  computed: {
    _value: {
      get() { return this.value },

      set(newValue) { this.$emit("input", newValue) }
    },

    selectFieldProps() {
      return _.deleteBlanks(this.$props)
    },

    options() {
      return [...Array(9).keys()].map(level => ({
        label: `${level}/8`,
        value: level
      }))
    }
  }
}

</script>

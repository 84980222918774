<style scoped lang="scss">

.supplier-show {
  padding-bottom: 24px;
}

.supplier-info-card {
  border: 1px solid $light-gray-3;
  border-radius: 4px;
  padding: 16px;
  margin-bottom: 16px;
  height: 115px;
}

.page-content {
  margin-top: 24px;
}

.status-card {
  margin-bottom: 24px;
}

</style>


<template lang="pug">

  .supplier-show
    loading-lines(v-if="fetching", :lines="4")
    template(v-else)
      header.header
        template(v-if="resource.blocked")
          supplier-blocked-collapse-card.status-card(:block-id="resource.currentBlockId")

        supplier-info-card.supplier-info-card(:resource="resource")
          .actions(slot="actions")
            template(v-if="resource.blocked")
              unblock-supplier(
                v-if="$can('update', resource)",
                :id="id",
                :name="resource.name",
                @unblocked="_fetch"
              )
            template(v-else)
              block-supplier(
                v-if="$can('update', resource)",
                :supplier="resource",
                @blocked="_fetch"
              )
            supplier-block-schedule(
              :supplier="resource",
              @destroy="_fetch"
            )

      .g-tab-navbar-content(v-if="!hideTabs")
        router-tab(
          :to="defaultRoute",
          :active="isDefaultTab"
        ) {{ $t('.tab.info') }}

        router-tab(
          :to="detailsRoute",
          :active="isDetailsTab"
        ) {{ $t('.tab.details') }}

        router-tab(
          :to="serviceSupportsRoute",
          :active="isServiceSupportTab"
        ) {{ $t('.tab.services') }}

        router-tab(
          v-if="$can('index', 'Deal')",
          :to="{ name: 'supplierDeals', params: { id } }"
        ) {{ $t('.tab.deals') }}

        router-tab(
          v-if="$can('index', 'Ticket')",
          :to="ticketsRoute",
          :active="isTicketTab",
          data-testid="ticketsTab"
        ) {{ $t('.tab.tickets') }}

      .page-content
        router-view(
          :supplier="resource",
          @change="_fetch"
        )

</template>


<script>

// Mixins
import { mixins } from "movida-common.vue"

// Extends
import View from "@/views/view"

// Local Components
import BlockSupplier from "./components/block-supplier"
import SupplierBlockedCollapseCard from "./components/supplier-blocked-collapse-card"
import UnblockSupplier from "./components/unblock-supplier"

const { Fetchable } = mixins

export default {
  name: "Supplier",

  components: {
    BlockSupplier,
    SupplierBlockedCollapseCard,
    UnblockSupplier
  },

  extends: View,

  mixins: [Fetchable],

  data() {
    return {
      i18nScope: "suppliers.show",

      model:    "Supplier",
      id:       this.$route.params.id,
      resource: {}
    }
  },

  computed: {
    hideTabs() {
      return _.get(this.$route, "meta.hideSupplierTabs") || false
    },

    defaultRoute() {
      return { name: "supplier", params: { id: this.id } }
    },

    isDefaultTab() {
      const resolved = this.$router.resolve(this.defaultRoute)
      const path = resolved.href.replace(/\/$/, "")

      return [path, `${path}/edit`].includes(this.$route.path)
    },

    detailsRoute() {
      return { name: "supplierDetails", params: { id: this.id } }
    },

    isDetailsTab() {
      const resolved = this.$router.resolve(this.detailsRoute)
      return this.$route.path.startsWith(resolved.href)
    },

    serviceSupportsRoute() {
      return { name: "supplierServiceSupports", params: { id: this.id } }
    },

    isServiceSupportTab() {
      const resolved = this.$router.resolve(this.serviceSupportsRoute)
      return this.$route.path.startsWith(resolved.href)
    },

    ticketsRoute() {
      return { name: "supplierTickets", params: { id: this.id } }
    },

    serviceOrdersRoute() {
      return { name: "supplierServiceOrders", params: { id: this.id } }
    },

    isTicketTab() {
      const ticketsPath = this.$router.resolve(this.ticketsRoute).href
      const serviceOrdersPath = this.$router.resolve(this.serviceOrdersRoute).href

      return this.$route.path.startsWith(ticketsPath) || this.$route.path.startsWith(serviceOrdersPath)
    }
  },

  methods: {
    onDestroy() {
      this.$router.push({ name: "suppliers" })
    },

    fetch() {
      return this.$sdk.suppliers.get({ id: this.id })
    },

    // @override Fetchable mixin
    // onFetchSuccess(response) {},

    onFetchError(err) {
      if (this.hasViewError(err)) {
        this.appError = err
      }

      this.$err.log(err)
      this.$notifications.error(this.$t(".notifications.fetch.failure"))
    }
  }
}

</script>

<script>

import TicketsTable from "@/views/tickets/index/tickets-table.vue"
import TicketRow from "./ticket-row.vue"
import TicketRowSkeleton from "./ticket-row-skeleton"
import TicketsTableHeader from "./tickets-table-header.vue"

export default {
  components: {
    TicketRow,
    TicketRowSkeleton,
    TicketsTableHeader
  },

  extends: TicketsTable
}

</script>

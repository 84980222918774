// Base
import BaseRequests from "../Base"

// Models
import models from "@/models"
const { StoreSupplier } = models

class StoresSuppliersRequests extends BaseRequests {
  static get selector() {
    return "stores.suppliers"
  }

  all({ storeId, params = {}, options = {} }) {
    this.verifyData("all", { storeId })

    return this.action({
      method:  "get",
      url:     `/stores/${storeId}/suppliers`,
      params,
      options: {
        model: StoreSupplier,

        ...options
      }
    })
  }
}

export default StoresSuppliersRequests

import auth from "./auth"

const AuthPlugin = {
  installed: false,

  install(Vue) {
    if (AuthPlugin.installed) return
    AuthPlugin.installed = true

    Vue.$auth = auth
    Vue.prototype.$auth = auth
  }
}

export default AuthPlugin

<style lang="scss" scoped>

.show {
  padding-bottom: 24px;

  .header {
    padding-top: 18px;

    .discarded-icon {
      margin-right: 8px;
    }

    .name {
      font-size: 30px;
      font-weight: bold;
      word-break: normal;
      overflow-wrap: break-word;
    }

    .id-tag {
      margin: 0 8px;
    }

    .actions {
      .btn + .btn {
        margin-left: 8px;
      }

      .icon {
        font-size: 16px;
        margin-right: 8px;
      }
    }

    .description {
      display: block;
      padding-top: 8px;
    }
  }

  .tabs {
    margin-bottom: 24px;
  }

  .section + .section {
    margin-top: 24px;
    padding-top: 24px;
    border-top: 1px solid $light-gray-3;
  }
}

</style>


<template lang="pug">

  .show
    loading-lines(
      v-if="fetching",
      :lines="4"
    )

    template(v-else)
      header.section.header
        .flex.space-between
          .title.flex.vertical-center
            discarded-icon.discarded-icon(v-if="resource.$discarded", size="big")
            h2.name {{ resource.name }}
            id-tag.id-tag(:value="resource.id", size="big")

          .actions.flex(v-if="!hideActions")
            template(v-if="resource.$discarded")
              enable-action.btn(
                :id="id",
                :name="resource.name",
                @enabled="_fetch",
                data-testid="enableAction"
              )
                i.icon.fal.fa-check-circle
                span {{ $t('.btn.enable') }}

            template(v-else)
              disable-action.btn(
                :id="id",
                :name="resource.name",
                @disabled="_fetch",
                data-testid="disableAction"
              )
                i.icon.fal.fa-lock
                span {{ $t('.btn.disable') }}

            destroy-action.btn(
              :id="id",
              :name="resource.name",
              @destroy="onDestroy",
              data-test="destroyAction"
            )
              i.fal.fa-trash-alt.icon
              span {{ $t('.btn.destroy') }}

            app-button.btn(
              v-if="$can('update', 'VehicleModelGroup')",
              theme="neutral",
              size="small",
              :to="{ name: 'editVehicleModelGroup', params: { id }}"
            )
              i.fal.fa-pencil.icon
              span {{ $t('.btn.edit') }}

        span.description(v-if="resource.description") {{ resource.description }}

      .g-tab-navbar-content.tabs(v-if="!hideTabs")
        router-tab(:to="{ name: 'vehicleModelGroup', params: { id } }") {{ $t('.tab.vehicleModels') }}
        router-tab(
          :to="maintenanceTablesLink",
          :active="isMaintenanceTableTab"
        ) {{ $t('.tab.maintenanceTables') }}
        router-tab(
          :to="suppliersRoute",
          :active="isSuppliersTab"
        ) {{ $t('.tab.suppliers') }}

      .section.page-content
        router-view(:vehicleModelGroup="resource")

</template>


<script>

// Mixins
import { mixins } from "movida-common.vue"

// Extends
import View from "@/views/view"

// Components
import DestroyAction from "./components/destroy-action"
import DisableAction from "./components/disable-action"
import EnableAction from "./components/enable-action"

const { Fetchable } = mixins

export default {
  name: "VehicleModelGroup",

  components: {
    DestroyAction,
    DisableAction,
    EnableAction
  },

  extends: View,

  mixins: [Fetchable],

  data() {
    return {
      i18nScope: "vehicle-model-groups.show",

      id: this.$route.params.id,

      // @override Fetchable mixin
      model:    "VehicleModelGroup",
      resource: {}
    }
  },

  computed: {
    maintenanceTablesLink() {
      return { name: "vehicleModelGroupMaintenanceTables", params: { id: this.id } }
    },

    suppliersRoute() {
      return { name: "vehicleModelGroupSuppliers", params: { id: this.id } }
    },

    isSuppliersTab() {
      const resolved = this.$router.resolve(this.suppliersRoute)
      return this.$route.path.startsWith(resolved.href)
    },

    isMaintenanceTableTab() {
      const resolved = this.$router.resolve(this.maintenanceTablesLink)
      return this.$route.path.startsWith(resolved.href)
    },

    hideTabs() {
      return _.get(this.$route, "meta.hideVehicleModelGroupTabs") || false
    },

    hideActions() {
      return _.get(this.$route, "meta.hideVehicleModelGroupActions") || false
    }
  },

  methods: {
    onDestroy() {
      this.$router.push({ name: "vehicleModelGroups" })
    },

    // @override Fetchable mixin
    fetch() {
      return this.$sdk.vehicleModelGroups.get({ id: this.id })
    },

    // @override Fetchable mixin
    // onFetchSuccess(response) {},

    // @override Fetchable mixin
    onFetchError(err) {
      // Dealing with appError from extension view.vue
      if (this.hasViewError(err)) this.appError = err

      this.$err.log(err)
      this.$notifications.error(this.$t(".notifications.fetch.failure"))
    }
  }
}

</script>

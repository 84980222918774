import Base from "./base"

class Symptom extends Base {
  static get attrs() {
    return _.uniq([
      ...super.attrs,
      "name",
      "description"
    ])
  }

  static get relations() {
    return {
      services: { type: "hasMany", model: "Service" }
    }
  }

  // static get enums() {}

  static get constraints() {
    return {
      name:        { presence: true, type: "string", length: { maximum: 1000 } },
      description: { type: "string", length: { maximum: 10000 } }
    }
  }
}

export default Symptom

<style lang="scss" scoped>

.tab-content {
  line-height: 58px;
  margin-bottom: 10px;
  border-top: 1px solid $light-gray-3;
  border-bottom: 1px solid $light-gray-3;

  .tab {
    $border-width: 4px;

    position: relative;
    font-family: $secondary-font;
    font-weight: 500;
    font-size: 16px;
    color: $gray-4;
    border: $border-width solid transparent;
    padding: 16px 0;
    cursor: pointer;
    user-select: none;

    &::after {
      content: '';
      position: absolute;
      left: -$border-width;
      bottom: -$border-width;
      width: calc(100% + #{$border-width * 2});
      display: block;
      height: $border-width;
      border-radius: 4px 4px 0 0;
    }

    &:hover {
      color: $primary;
    }

    &.router-link-active,
    &.active {
      color: $primary;

      &::after {
        background-color: $primary;
      }
    }
  }

  .tab + .tab {
    margin-left: 32px;
  }
}

.header {
  padding-top: 14px;
  margin-bottom: 30px;
}

.title {
  font-size: 30px;
  font-weight: normal;
  font-weight: 500;
  font-family: $secondary-font;
  margin-right: 8px;
}

.route-switch-message {
  margin-bottom: 20px;
}

</style>


<template lang="pug">

  .edit-supplier-deal
    header.header.flex.space-between
      .flex.vertical-center
        h1.title {{ $t('.title') }}
        id-tag {{ dealId }}

    .tab-content
      router-link.tab(
        :to="{ name: 'editSupplierDealInformation', params: { id, dealId }}",
        exact,
      ) {{ $t('.tab.info') }}

      router-link.tab(
        :to="{ name: 'supplierDealProducts', params: { id, dealId }}",
      ) {{ $t('.tab.products') }}

      router-link.tab(
        :to="{ name: 'supplierDealMaintenancePackages', params: { id, dealId }}",
      ) {{ $t('.tab.maintenancePackages') }}

    .page-content
      router-view(
        :deal="resource",
        :fetching="fetching",
        @dirty="value => this.isDirty = value",
        @update="_fetch"
      )

</template>


<script>

// Mixins
import { mixins } from "movida-common.vue"

// Extends
import View from "@/views/view"

const { Fetchable } = mixins

export default {
  name: "EditSupplierDeal",

  extends: View,

  mixins: [Fetchable],

  props: {
    supplier: { type: Object, default: () => ({}) }
  },

  data() {
    return {
      i18nScope: "suppliers.deals.edit",

      id:     this.$params.asInteger(this.$route.params.id),
      dealId: this.$params.asInteger(this.$route.params.dealId),

      targetRoute: null,

      showRouteSwitchConfirmationModal: false,

      model:    "Deal",
      resource: {}
    }
  },

  methods: {
    // @override Fetchable mixin
    fetch() {
      return this.$sdk.suppliers.getDeal({ id: this.id, dealId: this.dealId })
    },

    // @override Fetchable mixin
    // onFetchSuccess(response) {},

    // @override Fetchable mixin
    onFetchError(err) {
      if (this.hasViewError(err)) {
        this.appError = err
      }

      this.$err.log(err)
      this.$notifications.error(this.$t(".notifications.fetch.failure"))
    }
  }
}

</script>

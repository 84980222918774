import Base from "./base"


// Atribuição de uma OS a um Responsável (usuário/conta de sistema)
class ServiceOrderAssignment extends Base {

  static get relations() {
    return {
      serviceOrder: { type: "belongsTo", model: "MovidaServiceOrder" }
    }
  }

  static get attrs() {
    return _.uniq([
      ...super.attrs,

      "assigneeRid",
      "assigneeName"
    ])
  }

  static get constraints() {
    return {
      assigneeRid: { presence: true }
    }
  }


  // utils
  get assigneeSimpleName() {
    if (_.blank(this.assigneeName)) return null

    let names = this.assigneeName.replace(/[^\w\s]/g, "").replace(/\s+/g, " ").trim().split(" ")

    if (_.blank(names)) return "?"

    let firstName = names[0]
    let lastName  = names.length > 1 ? names.pop() : ""

    return `${firstName} ${lastName}`.trim()
  }
}


export default ServiceOrderAssignment

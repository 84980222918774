<style lang="scss" scoped>

.message-row {
  cursor: pointer;
  border-bottom: 1px solid $light-gray-3;

  &:hover,
  &.selected {
    background-color: $light-gray;
  }

  &:active,
  &:focus {
    background-color: $light-gray-2;
  }

  .initials {
    font-family: $secondary-font;
    font-weight: 400;
    font-size: 14px;
    padding: 3.5px 7px;
    background-color: $light-purple;
    border-radius: 80px;
    color: $purple;
    margin: 0 16px;
  }

  .message {
    overflow: hidden;
    padding: 16px 12px 16px 0;
  }

  .header {
    .motive {
      font-family: $secondary-font;
      font-weight: 400;
      font-size: 14px;
    }

    .timestamp {
      margin-left: 8px;
      font-weight: 400;
      font-size: 11px;
    }
  }

  .body {
    font-size: 12px;
    font-weight: 300;
    margin-top: 4px;
  }
}

</style>


<template lang="pug">

  .message-row(
    :class="{ selected }",
    :tabindex="0"
  )
    .flex.vertical-center
      initials-tag(:name="message.authorName")

      .message
        .header.flex.vertical-baseline
          span.motive {{ MovidaServiceOrderSupplierMessage.$tEnum('motive', message.motive) }}
          span.timestamp(v-tooltip="timestamp") {{ timestampFromNow }}

        app-span.body(
          crop,
          :crop-tooltip-options="{ content: message.description }",
          :value="text"
        )

</template>


<script>

// Components
import InitialsTag from "@/views/monitoring/_components/initials-tag.vue"

// Models
import models from "@/models"
const { MovidaServiceOrderSupplierMessage } = models


export default {
  components: {
    InitialsTag
  },

  props: {
    message:  { type: Object, required: true },
    selected: { type: Boolean, default: false }
  },

  data() {
    return {
      i18nScope: "monitoring.messages.message-row",

      MovidaServiceOrderSupplierMessage
    }
  },

  computed: {
    text() {
      let content = this.message.description
      let doc = new DOMParser().parseFromString(content, "text/html")

      return doc.body.textContent || ""

      // return this.message.description.replaceAll(/<[^>]*>/g, " ").replaceAll(/\s\s+/g, " ")
    },

    timestamp() {
      // return this.$l("datetime.formats.default", this.message.at)
      // return this.$l("date.formats.default", this.message.at)
      // return this.$l("time.formats.short", this.message.at)
      return this.$l("time.formats.short", this.message.at)
    },

    timestampFromNow() {
      return this.$fromNow(this.message.at)
    }
  }
}

</script>

<style lang="scss" scoped>

.first-column {
  padding: 0 16px;
}

.maintenance-table-row-checkbox-field {
  border-bottom: 1px solid $light-gray-3;

  &:first-child {
    border-top: 1px solid $light-gray-3;
  }
}

.vehicle-model-group-maintenance-table-row.row {
  padding-left: 0;

  ::v-deep.id-column {
    margin-right: 8px;
  }
}

.info {
  overflow: hidden;
}

</style>


<template lang="pug">

  .maintenance-table-row-checkbox-field.flex.vertical-center
    .first-column
      checkbox-field(
        :name="`maintenanceTable_${maintenanceTable.id}`",
        hide-value-text,
        :disabled="disabled",
        :loading="loading",
        :value="value",
        @input="(value) => $emit('input', { id: maintenanceTable.id, value })"
      )

    .info.grow
      maintenance-table-row.row(:maintenance-table="maintenanceTable")

</template>


<script>

// Components
import MaintenanceTableRow from "./vehicle-model-group-maintenance-table-row.vue"

export default {

  components: {
    MaintenanceTableRow
  },

  props: {
    maintenanceTable: { type: Object, required: true },
    value:            { type: Boolean, default: false },
    disabled:         { type: Boolean, default: false },
    loading:          { type: Boolean, default: false }
  },

  data() {
    return {
      i18nScope: "vehicle-model-groups.maintenance-tables.import.maintenance-table-row-checkbox-field"
    }
  }
}

</script>

import _ from "@caiena/lodash-ext"

class Base {
  constructor(http) {
    this.http = http
  }

  action({ method, url, params = {} } = {}) {
    const source = this.http.CancelToken.source()
    const request = this.http.router({
      method,
      url,

      // Query params need to be passed as "params" and request body as "data"
      // @see https://github.com/axios/axios#request-config
      [["get", "delete"].includes(method) ? "params" : "data"]: params,

      cancelToken: source.token
    })
      .catch(err => Promise.reject({ // eslint-disable-line prefer-promise-reject-errors
        originalError: err,
        status:        _.get(err, "response.status"),
        cancelled:     this.http.isCancel(err)
      }))

    request.cancel = source.cancel

    return request
  }
}

export default Base

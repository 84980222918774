<script>

// Extends
import CurrentSpreadsheetDownloadButton from "@/views/suppliers/deals/products/index/current-spreadsheet-download-button"

export default {
  extends: CurrentSpreadsheetDownloadButton,

  computed: {
    fileName() {
      return `pacotes-revisao-acordo-${this.dealId}.xlsx`
    }
  },

  methods: {
    request() {
      return this.$http.post(`/deals/${this.dealId}/maintenance_packages/spreadsheet`, {}, {
        responseType:       "blob",
        onDownloadProgress: this.onDownloadProgress
      })
    }
  }
}

</script>

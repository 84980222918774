<style scoped lang="scss">

.background-link {
  cursor: pointer;
}

</style>


<template lang="pug">

  router-link.background-link(
    :to="to",
    draggable="false",
    event="",
    @mousedown.native.left="onMouseDown",
    @click.native.stop="onClick"
  )
    slot

</template>


<script>

export default {
  name: "BackgroundLink",

  props: {
    dragLimit: { type: Number, default: 5 },
    to:        { type: Object, required: true }
  },

  data() {
    return {
      i18nScope: "components.background-link",

      downX: null,
      downY: null
    }
  },

  computed: {},

  methods: {
    onClick(clickEvent) {
      const moved = Math.abs(clickEvent.x - this.downX) >= this.dragLimit || Math.abs(clickEvent.y - this.downY) >= this.dragLimit
      if (!moved) this.$router.push(this.to)
    },

    onMouseDown(downEvent) {
      this.downX = downEvent.x
      this.downY = downEvent.y
    }
  }
}

</script>

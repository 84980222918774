<style scoped lang="scss">

.product-row {
  display: grid;
  grid-template-columns: 70px 70px 1fr 1fr 64px;

  &.loading {
    .column {
      &.id-column {
        .id-tag {
          color: $gray-2;
        }
      }

      &.sap-column {
        ::v-deep.outside {
          fill: $gray-2;
        }
      }

      &.actions-column {
        ::v-deep .movida-common__app-button {
          color: $gray-2;

          &:hover {
            color: $orange;
          }
        }
      }

      .text,
      .link ::v-deep .app-span {
        color: $gray-2;
      }
    }
  }

  .column {
    padding: 0 12px;
    border-bottom: 1px solid $light-gray-3;
    height: 48px;
    overflow: hidden;

    .discarded-icon {
      margin-right: 6px;
    }

    &.models-count-column {
      .text {
        font-family: $secondary-font;
      }

      .car-icon {
        margin-right: 8px;
        color: $gray-2;
      }
    }

    ::v-deep .movida-common__app-button {
      white-space: unset;
    }

    .button {
      overflow: hidden;
    }

    .text,
    .link ::v-deep .app-span {
      font-family: $primary-font;
      font-weight: 400;
      font-size: 14px;
      color: $dark-gray;
      text-align: initial;
    }

    .link ::v-deep .app-span:hover {
      color: $orange;
    }
  }
}

</style>


<template lang="pug">

  .product-row(:class="{ loading }")
    .column.id-column.flex.vertical-center
      id-tag.id-tag(
        :value="product.id",
        data-testid="productId"
      )

    .column.flex.vertical-center
      product-type-icon.icon(:type="product.type", :size="24")

    .column.flex.vertical-center
      discarded-icon.discarded-icon(v-if="product.$discarded")

      app-button.button(
        v-if="$can('read', product)"
        theme="link",
        :to="showRoute"
      )
        app-span.link(
          crop,
          :value="product.name",
          data-testid="productName"
        )

      template(v-else)
        app-span.text(
          crop,
          :value="product.name",
          data-testid="productName"
        )

    .column.sap-column.flex.vertical-center
      sap-icon
      span.text(data-testid="productSapId") {{ product.sapId }}

    .column.actions-column.flex.vertical-center.flex
      popover-menu(
        v-if="popoverMenuAuthorization && showActions",
        :show.sync="showPopover",
        data-testid="popoverMenu"
      )
        popover-menu-button(
          v-if="$can('read', product)",
          icon="fal fa-eye",
          :to="{ name: 'product', params: { id: product.id }}",
          :text="$t('.actions.btn.show.label')",
          @close-menu="showPopover = false"
        )

        popover-menu-button(
          v-if="$can('update', product)",
          icon="fal fa-pencil",
          :to="{ name: 'editProduct', params: { id: product.id }}",
          :text="$t('.actions.btn.edit.label')",
          @close-menu="showPopover = false"
        )

        template(v-if="product.$discarded")
          enable-action.popover-menu-button(
            v-if="$can('destroy', product)",
            size="normal",
            border="square",
            :name="product.name",
            :id="product.id",
            @click.native="showPopover = false",
            @enabled="$emit('enabled')"
          )
            .icon-content
              i.icon.fal.fa-check-circle
            span {{ $t('.actions.btn.enable.label') }}

        template(v-else)
          disable-action.popover-menu-button(
            v-if="$can('destroy', product)",
            size="normal",
            border="square",
            :name="product.name",
            :id="product.id",
            @click.native="showPopover = false",
            @disabled="$emit('disabled')"
          )
            .icon-content
              i.icon.fal.fa-lock
            span {{ $t('.actions.btn.disable.label') }}

        destroy-action.destroy-button.popover-menu-button(
          size="normal",
          border="square",
          :id="product.id",
          :name="product.productName",
          :type="product.type",
          @click.native="showPopover = false"
          @destroy="$emit('destroy')",
          data-test="destroyAction"
        )
          .icon-content
            i.icon.fal.fa-trash-alt
          span {{ $t('.actions.btn.destroy.label') }}

</template>


<script>

// Local Components
import DestroyAction from "../show/destroy-action"
import DisableAction from "../components/disable-action"
import EnableAction from "../components/enable-action"

export default {
  components: {
    DestroyAction,
    DisableAction,
    EnableAction
  },

  props: {
    product:     { type: Object, default: () => ({}) },
    loading:     { type: Boolean, default: false },
    showActions: { type: Boolean, default: true }
  },

  data() {
    return {
      i18nScope: "products.index.products-row",

      showPopover: false
    }
  },

  computed: {
    popoverMenuAuthorization() {
      return ["read", "update", "destroy"].some(action => this.$can(action, this.product)) || this.$can("destroy", "Product")
    },

    showRoute() {
      return { name: "product", params: { id: this.product.id } }
    }
  }
}

</script>

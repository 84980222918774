<style lang="scss" scoped>

.new-changes-request {
  padding: 18px 24px;

  .ui-row {
    padding-top: 16px;
  }

  .actions {
    padding-top: 16px;
  }
}

::v-deep .options-wrapper {
  height: 120px;
  overflow-y: scroll;
}

.confirmation-modal {
  height: 255px;

  .icon-container {
    width: 64px;
    height: 64px;
    border-radius: 50%;
    background-color: $light-green;

    &.loading {
      background-color: $light-gray;

      .icon {
        color: $orange;
      }
    }

    .icon {
      font-size: 36px;
      color: $dark-green;

      &.spin {
        animation: fa-spin 0.6s infinite linear;
        cursor: wait;
        line-height: 0.75em;
      }
    }
  }

  .text {
    font-family: $secondary-font;
    font-weight: 500;
    font-size: 38px;
    margin: 16px 0;
  }
}

</style>


<template lang="pug">

  .new
    app-modal(
      v-if="!showResultModal",
      footer,
      :confirm-label-loading="$t('.btn.add.loading')",
      :confirm-label="$t('.btn.add.label')",
      :heading="$t('.title')",
      :width="720",
      @cancel="onClose",
      @close="onClose",
      @confirm="submit"
    )
      .new-changes-request
        form.form(ref="form", method="post", @submit.prevent="submit")
          section.fields.section
            ui-row.ui-row
              ui-col(:span="6")
                motive-select-field.motive-select-field(
                  v-model="resource.motive",
                  name="reviewChangesRequest[motive]",
                  :errors="errors.motive"
                )

            ui-row.ui-row
              ui-col(:span="12")
                textarea-field(
                  v-model="resource.description",
                  name="reviewChangesRequest[description]",
                  optional,
                  :errors="errors.description",
                  :loading="submitting"
                )

    app-modal(
      v-if="showResultModal",
      :close-button="isSubmitted",
      :width="672",
      @close="onClose"
    )
      .confirmation-modal.flex.vertical-center.center
        template(v-if="isSubmitted")
          .flex.column-direction.vertical-center
            .icon-container.flex.center.vertical-center
              i.icon.fas.fa-check
            span.text {{ $t('.notifications.submit.success') }}

        template(v-else)
          .icon-container.loading.flex.center.vertical-center
            i.icon.fas.fa-circle-notch.spin

</template>


<script>

// Mixins
import { mixins } from "movida-common.vue"

// Models
import models from "@/models"

// Components
import MotiveSelectField from "./_components/motive-select-field"

const { FormMixin } = mixins

const { ReviewChangesRequest } = models


export default {
  components: {
    MotiveSelectField
  },

  mixins: [FormMixin],

  props: {
    quoteId: { type: [String, Number], required: true }
  },

  data() {
    return {
      i18nScope: "monitoring.quotes.reviews.changes-requests.new",

      resource: new ReviewChangesRequest(),

      showResultModal: false,
      isSubmitted:     false
    }
  },

  methods: {
    onClose() {
      this.$emit("close")
    },

    // @override Form mixin
    submitRequest() {
      const params = this.resource.$serialize()
      this.showResultModal = true

      return this.$newSdk.quotes.reviews.changesRequests.create({ quoteId: this.quoteId, params })
    },

    // @override Form mixin
    submitSuccess(response) {
      this.isSubmitted = true

      this.$emit("change")
    },

    // @override Form mixin
    submitError(err) {
      this.$err.log(err)
      this.showResultModal = false

      this.$notifications.error(this.$t(".notifications.submit.error"))
    }
  }
}

</script>

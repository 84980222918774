import Base from "../base"

import { CATEGORIES, STATES } from "@/enums/vehicles"


class MovidaVehicle extends Base {

  static get attrs() {
    return _.uniq([
      ...super.attrs,

      "state",     // enum TODO
      "category",  // enum TODO

      "fipeId",
      "licensePlate",
      "manufacturingYear",
      "fuel",       // enum TODO
      "fuelLevel",  // integer: 0..8 (oitavos)
      "km",         // integer

      "manufacturerName",
      "modelName",
      "versionName",
      "colorName"
    ])
  }

  static get enums() {
    return {
      category: CATEGORIES.all,
      state:    STATES.all
    }
  }



  // decorators
  // ---

  get fullModelName() {
    return _.trim(`${this.modelName} ${this.versionName}`)
  }

}

export default MovidaVehicle

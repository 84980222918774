<style scoped lang="scss">

.service-type-icon {
  color: $gray-3;
  width: var(--size);
  height: var(--size);
  text-align: center;
}

</style>


<template lang="pug">

  img.service-type-icon(
    :src="icon",
    v-tooltip.bottom="tooltipOptions",
    :style="cssVars"
  )

</template>


<script>

// Icons
import assistance from "@/assets/images/icons/service-types/assistance.svg"
import autoBodyAndPaint from "@/assets/images/icons/service-types/autoBodyAndPaint.svg"
import documentation from "@/assets/images/icons/service-types/documentation.svg"
import glass from "@/assets/images/icons/service-types/glass.svg"
import maintenance from "@/assets/images/icons/service-types/maintenance.svg"
import mechanic from "@/assets/images/icons/service-types/mechanic.svg"
import setup from "@/assets/images/icons/service-types/setup.svg"
import tireAndWheels from "@/assets/images/icons/service-types/tireAndWheels.svg"
import withdrawal from "@/assets/images/icons/service-types/withdrawal.svg"
import armoring from "@/assets/images/icons/service-types/armoring.svg"
import locksmith from "@/assets/images/icons/service-types/locksmith.svg"
import electric from "@/assets/images/icons/service-types/electric.svg"
import transport from "@/assets/images/icons/service-types/transport.svg"
import tapestry from "@/assets/images/icons/service-types/tapestry.svg"

// Models
import models from "@/models"

const { Service } = models

export default {
  name: "ServiceTypeIcon",

  props: {
    type:        { type: String, default: null },
    hideTooltip: { type: Boolean, default: false },
    size:        { type: Number, default: 24 }
  },

  computed: {
    cssVars() {
      return {
        "--size": `${this.size}px`
      }
    },

    icon() {
      switch (this.type) {
        case "Maintenance": return maintenance
        case "Services::Assistance": return assistance
        case "Services::AutoBodyAndPaint": return autoBodyAndPaint
        case "Services::Documentation": return documentation
        case "Services::Glass": return glass
        case "Services::Mechanic": return mechanic
        case "Services::Setup": return setup
        case "Services::TiresAndWheels": return tireAndWheels
        case "Services::Withdrawal": return withdrawal
        case "Services::Armoring": return armoring
        case "Services::Locksmith": return locksmith
        case "Services::Electric": return electric
        case "Services::Transport": return transport
        case "Services::Tapestry": return tapestry
        default: return assistance
      }
    },

    tooltipOptions() {
      if (this.hideTooltip) return {}

      return {
        content: this.typeText,
        offset:  "4"
      }
    },

    typeText() {
      return Service.$tEnum("type", this.type)
    }
  }
}

</script>

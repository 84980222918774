<template>
  <div class="flex vertical-center space-between">
    <div class="movida-list flex vertical-center">
      <img v-if="svg != null" :src="svg" class="main-icon">
      <div class="content grow">
        <div class="flex column-direction">
          <div class="top">
            <router-link :to="to" class="movida-common__app-button link rounded normal-size">
              <div class="content center">
                <span class="title">{{ title }}</span>
              </div>
            </router-link>
          </div>
          <span class="description">
            <slot></slot>
          </span>
        </div>
      </div>
    </div>
    <div class="actions">
      <slot name="actions"></slot>
      
    </div>
  </div>
</template>

<script>

export default {
  name: "MovidaList",

  props: {
    title: {
      type: String,
      required: true
    }, 
    svg: {
      type: String,
      default: null
    }, 
    to: {
      type: String,
      default: "#"
    }
  }, 

  data() {
    return {
      i18nScope: "movida-list",
      showPopover: false
    }
  }
}
</script>

<style lang="scss" scoped>

.movida-list {
  padding: 24px;
}

.main-icon {
  margin-right: 24px;
}

.title {
  font-family: $secondary-font;
  font-weight: 400;
  font-size: 18px;
  color: $dark-gray;

  &:hover {
    color: $primary;
  }
}

.actions {
  margin: 0 24px;
}

.description {
  font-size: 14px;
}

</style>
// Base
import BaseRequests from "../../Base"

// Models
import models from "@/models"
const { DraftTicketSupplierBooking } = models

class TicketsDraftsBookingsRequests extends BaseRequests {
  static get selector() {
    return "tickets.drafts.bookings"
  }

  find({ ticketId, params = {}, options = {} }) {
    this.verifyData("find", { ticketId })

    return this.action({
      method:  "get",
      url:     `/tickets/${ticketId}/draft/booking`,
      params,
      options: {
        model: DraftTicketSupplierBooking,

        ...options
      }
    })
  }

  update({ ticketId, params = {}, options = {} }) {
    this.verifyData("update", { ticketId })

    return this.action({
      method: "patch",
      url:    `/tickets/${ticketId}/draft/booking`,
      params,
      options
    })
  }
}

export default TicketsDraftsBookingsRequests

<style lang="scss" scoped>

.vehicle-search-form {
  background-color: $light-gray;
  border-radius: 8px;
  padding: 32px 0 16px 0;

  .car-icon {
    height: 100%;
    margin-bottom: 8px;
  }

  .message {
    font-family: $secondary-font;
    font-weight: 400;
    color: $gray-3;
  }

  .search-field {
    width: 448px;
    margin-top: 16px;

    ::v-deep input:not(:placeholder-shown) {
      text-transform: uppercase;
    }
  }

  .error {
    margin-top: 16px;

    .not-found-icon {
      margin-right: 8px;
    }
    .message {
      font-size: 16px;
      color: $error;
    }
  }
}

</style>


<template lang="pug">

  .vehicle-search-form.flex.vertical-center.column-direction
    img.car-icon(:src="carIcon")
    span.message {{ $t('.find.label') }}

    search-field.search-field(
      name="search[q]",
      v-model="params.q",
      :loading="loading",
      :placeholder="$t('.fields.q.placeholder')",
      :maxlength="7",
      mask="SSS0A00",
      icon-left="far fa-search",
      :errors="errors.q"
    )

    .error.flex.vertical-center
      template(v-if="hasError")
        img.not-found-icon(:src="errorIcon")

      template(v-if="searchErrors.vehicleNotFound")
        span.message {{ $t('.not-found.label') }}

      template(v-else-if="searchErrors.forbidden")
        span.message {{ $t('.forbidden.label') }}

      template(v-else-if="searchErrors.wrongState")
        span.message {{ $t('.wrong-state.label') }}

      template(v-else-if="searchErrors.withoutModel")
        span.message {{ $t('.without-model.label', { fipeId }) }}

      template(v-else-if="searchErrors.notRac")
        span.message {{ $t('.not-rac.label') }}

</template>


<script>

// Assets
import carIcon from "@/assets/images/illustrations/car-1.svg"
import errorIcon from "@/assets/images/illustrations/not-found.svg"

export default {
  props: {
    loading: { type: Boolean, default: false },
    params:  { type: Object, default: () => {} },
    vehicle: { type: Object, default: () => {} },
    errors:  { type: Object, default: () => {} },

    searchErrors: { type: Object, required: true }
  },

  data() {
    return {
      i18nScope: "tickets.drafts.new.vehicle-search-form",

      carIcon,
      errorIcon
    }
  },

  computed: {
    fipeId() {
      return _.get(this.vehicle, "fipeId")
    },

    hasError() {
      return Object.keys(this.searchErrors)
        .map(key => this.searchErrors[key])
        .some(value => Boolean(value))
    }
  }
}

</script>

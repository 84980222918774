<style scoped lang="scss">

.header {
  margin-bottom: 24px;
}

.table-name {
  font-size: 20px;
  font-weight: 500;
  font-family: $secondary-font;
  word-break: normal;
  overflow-wrap: break-word;
}

.id-tag {
  margin-left: 16px;
}

.btn + .btn {
  margin-left: 8px;
}

.icon {
  font-size: 16px;
  margin-right: 8px;
}

.description-content {
  .description {
    font-size: 16px;
    font-weight: normal;
    margin: 0;
  }
}

.maintenances {
  font-family: $secondary-font;
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  padding: 24px 0;
}

.table-content {
  padding-top: 24px;
  margin-bottom: 24px;
}

.info {
  padding-bottom: 24px;
  border-bottom: 1px solid $light-gray-3;
}

.info-label + .info-label {
  margin-left: 40px;
}

.warning {
  color: $dark-warning;
}

.back {
  font-family: $secondary-font;
  font-weight: 400;
  font-size: 14px;
  margin-bottom: 16px;

  &::v-deep.link {
    color: $gray-2;

    &:hover {
      color: $gray-3;
    }
  }

  .icon {
    margin-right: 8px;
    font-size: 12px;
  }
}

.tag {
  margin-left: 8px;
}

.footer {
  margin-top: 24px;
  padding-top: 24px;
  border-top: 1px solid $light-gray-3;
}

</style>


<template lang="pug">

  .maintenance-table-show
    loading-lines(v-if="fetching", :lines="4", data-testid="loading")

    template(v-else)
      app-button.back(
        theme="link",
        :to="{ name: 'vehicleModelGroupMaintenanceTables' }",
        :disabled="activating"
      )
        .flex.vertical-center
          i.icon.fas.fa-arrow-left
          span {{ $t('.back.label') }}

      header.header
        .flex.space-between.vertical-center
          .flex.vertical-center
            h2.table-name {{ resource.name }}
            id-tag.tag(:value="resource.id", size="big")

          .actions.flex
            destroy-action.btn(
              :vehicle-model-group-id="id",
              :maintenance-table-id="maintenanceTableId",
              :name="resource.name",
              :disabled="activating",
              @destroy="onDestroy",
              data-test="destroyAction"
            )
              i.fal.fa-trash-alt.icon
              span {{ $t('.btn.destroy.label') }}

            app-button.btn(
              v-if="$can('update', 'VehicleModelGroup')",
              theme="neutral",
              size="small",
              :to="{ name: 'editVehicleModelGroupMaintenanceTable', params: { id }}",
              :disabled="activating"
            )
              i.fal.fa-pencil.icon
              span {{ $t('.btn.edit.label') }}

        section.description-content.preserve-whitespace(v-if="resource.description", data-test="description")
          p.description {{ resource.description }}

      section.info.flex.vertical-start
        info-label.info-label(
          :loading="fetching",
          :label="$t('.fields.kilometrageLimitTolerance.label')",
          :value="resource.formattedKilometrageLimitTolerance",
          :empty-text="$t('unregistered.feminine')",
        )
        info-label.info-label(
          :loading="fetching",
          :label="$t('.fields.timeLimitTolerance.label')",
          :value="resource.formattedTimeLimitTolerance",
          :empty-text="$t('unregistered.feminine')"
        )
        maintenance-table-kind-tag.info-label(:kind="resource.kind")

      .table-content
        maintenances-table(
          :empty="empty",
          :show-destroy-column="false",
          :loading="fetching"
        )
          maintenance-row(
            v-for="(maintenance, index) in resource.vehicleModelGroupMaintenances",
            :key="maintenance.id",
            :resource="maintenance",
            :order="index + 1",
            data-testid="maintenanceRow"
          )

          .empty(slot="empty")
            span {{ $t('.empty') }}

      section.footer.flex.space-between
        app-button(
          :to="{ name: 'vehicleModelGroupMaintenanceTables' }",
          outline,
          :disabled="activating"
        )
          i.far.fa-arrow-left.icon
          span {{ $t('.btn.back.label') }}

        app-button(
          v-if="$can('update', 'VehicleModelGroup')",
          theme="link"
          :to="{ name: 'newVehicleModelGroupMaintenanceTable' }",
          :disabled="activating",
        )
          span {{ $t('.btn.new.label') }}

</template>


<script>

// Extends
import { mixins } from "movida-common.vue"
import View from "@/views/view"

// Local Components
import DestroyAction from "./components/destroy-action"
import MaintenancesTable from "./components/maintenances-table"
import MaintenanceRow from "./components/maintenance-row"

export default {
  name: "MaintenanceTable",

  components: {
    DestroyAction,
    MaintenancesTable,
    MaintenanceRow
  },

  extends: View,

  mixins: [mixins.Fetchable],

  data() {
    return {
      i18nScope: "vehicle-model-groups.maintenance-tables.show",

      id:                 this.$route.params.id,
      maintenanceTableId: this.$route.params.maintenanceTableId,

      activating: false,

      // @override Fetchable mixin
      model: "VehicleModelGroupMaintenanceTable"
    }
  },

  computed: {
    empty() {
      return _.blank(this.resource, "vehicleModelGroupMaintenances")
    }
  },

  methods: {
    onDestroy() {
      this.$router.push({ name: "vehicleModelGroupMaintenanceTables" })
    },

    // @override Fetchable mixin
    fetch() {
      return this.$sdk.vehicleModelGroups.getMaintenanceTable({
        id:                 this.id,
        maintenanceTableId: this.maintenanceTableId
      })
    },

    // @override Fetchable mixin
    // onFetchSuccess(response) {},

    // @override Fetchable mixin
    onFetchError(err) {
      if (this.hasViewError(err)) {
        this.appError = err
      }

      this.$err.log(err)
      this.$notifications.error(this.$t(".notifications.fetch.failure"))
    }
  }
}

</script>

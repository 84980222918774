// Base
import BaseRequests from "../../Base"

// Models
import models from "@/models"
const { MovidaServiceOrderSupplierMessage } = models

class MonitoringServiceOrderSupplierMessageRequests extends BaseRequests {
  static get selector() {
    return "monitoring.serviceOrders.messages"
  }

  all({ serviceOrderId, params = {}, options = {} } = {}) {
    this.verifyData("all", { serviceOrderId })

    return this.action({
      method:  "post",
      url:     `/monitoring/service_orders/${serviceOrderId}/service_order_messages/search`,
      params,
      options: {
        model: MovidaServiceOrderSupplierMessage,

        ...options
      }
    })
  }

  create({ serviceOrderId, params = {}, options = {} } = {}) {
    this.verifyData("create", { serviceOrderId })

    return this.action({
      method:  "post",
      url:     `/monitoring/service_orders/${serviceOrderId}/service_order_messages`,
      params,
      options: {
        model: MovidaServiceOrderSupplierMessage,

        ...options
      }
    })
  }

  find({ messageId, params = {}, options = {} } = {}) {
    this.verifyData("find", { messageId })

    return this.action({
      method:  "get",
      url:     `/monitoring/service_order_messages/${messageId}`,
      params,
      options: {
        model: MovidaServiceOrderSupplierMessage,

        ...options
      }
    })
  }

  update({ messageId, params = {}, options = {} } = {}) {
    this.verifyData("update", { messageId })

    return this.action({
      method:  "patch",
      url:     `/monitoring/service_order_messages/${messageId}`,
      params,
      options: {
        model: MovidaServiceOrderSupplierMessage,

        ...options
      }
    })
  }

  destroy({ messageId, params = {}, options = {} } = {}) {
    this.verifyData("destroy", { messageId })

    return this.action({
      method: "delete",
      url:    `/monitoring/service_order_messages/${messageId}`,
      params,
      options
    })
  }
}

export default MonitoringServiceOrderSupplierMessageRequests

<template>
    <header class="header flex space-between vertical-center">
        <div class="title flex vertical-center">
            <i v-if="icon != null" :class="['title-icon', 'product-icon', 'fal', icon]"></i>
            <h1 class="title-text">{{ title }}</h1>
        </div>
    </header>
</template>

<script>

export default {
  name: "MovidaHeader",

  props: {
    title: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
      required: false,
      default: null,
    },
  }
}
</script>

<style lang="scss" scoped>
.header {
  margin-bottom: 32px;
  padding-bottom: 16px;
  border-bottom: 1px solid $light-gray-3;
}

.title-icon {
  margin-right: 16px;
  font-size: 30px;
}

.title-text {
  font-size: 30px;
  font-weight: normal;
  font-weight: 500;
  font-family: $secondary-font;
}
</style>

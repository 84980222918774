<style lang="scss" scoped>

.results-indicator {
  font-size: 14px;
  font-weight: 300;
  color: $gray-3;
}

.loading {
  width: 200px
}

</style>


<template lang="pug">

  .results-indicator.flex.end
    loading-lines.loading(v-if="loading", :min="100", :max="100")
    template(v-else)
      span(v-if="show") {{ text }}

</template>


<script>

export default {
  name: "ResultsIndicator",

  props: {
    loading: { type: Boolean, default: false },
    page:    { type: Number, default: 1 },
    perPage: { type: Number, default: 0 },
    total:   { type: Number, default: 0 },
    hide:    { type: Boolean, default: false }
  },

  data() {
    return {
      i18nScope: "components.results-indicator"
    }
  },

  computed: {
    first() {
      return (this.page - 1) * this.perPage + 1
    },

    last() {
      return Math.min(this.page * this.perPage, this.total)
    },

    show() {
      return !this.hide && this.total > 0 && this.first <= this.total
    },

    text() {
      return this.$t('.message', {
        first: this.first,
        last:  this.last,
        total: this.totalText
      })
    },

    totalText() {
      return this.$asNumber(this.total, { precision: 0 })
    }
  }
}

</script>

<style scoped lang="scss">

.maintenance-packages {
  padding-bottom: 24px;

  .header {
    margin-bottom: 32px;
    padding-bottom: 16px;
    border-bottom: 1px solid $light-gray-3;

    .title-icon {
      margin-right: 16px;
      font-size: 30px;
    }

    .title-text {
      font-size: 30px;
      font-weight: normal;
      font-weight: 500;
      font-family: $secondary-font;
    }
  }

  .clear-filters {
    position: relative;

    .filters-button {
      position: absolute;
      margin-top: 10px;
    }
  }

  .list {
    margin-top: 50px;

    .table {
      margin-top: 16px;
    }
  }

  .paginator-content {
    padding-top: 24px;

    &.loading {
      max-width: 400px;
      margin: 0 auto;
    }
  }
}

</style>


<template lang="pug">

  .maintenance-packages
    header.header.flex.space-between
      .title.flex.vertical-center
        i.title-icon.product-icon.fal.fa-box
        h1.title-text {{ $t('.title') }}
      app-button(
        v-if="$can('create', 'MaintenancePackage')",
        :to="newMaintenancePackageRoute",
        data-testid="createMaintenancePackage"
      ) {{ $t('.btn.new') }}

    search-form.search(
      :loading="fetching",
      :params="parsedQueryParams",
      @search="search"
    )

    .clear-filters
      filters-button.filters-button(
        v-if="hasFilters",
        @clear="clearFilters"
      )

    .list
      .flex.space-between.vertical-bottom
        .result-tabs.flex
          index-filter-tab(
            icon="fa-check-circle",
            :label="$t('.filters.enabled.label')",
            :active="!parsedQueryParams.discarded",
            :loading="initializing",
            @click="onDiscardedChange(false)"
          )
          index-filter-tab(
            icon="fa-lock",
            :label="$t('.filters.disabled.label')",
            :active="parsedQueryParams.discarded",
            :loading="initializing",
            @click="onDiscardedChange(true)"
          )

        results-indicator(
          :page="parsedQueryParams.page",
          :per-page="pagination.perPage",
          :total="pagination.total",
          :loading="fetching"
        )

      maintenance-packages-table.table(
        :maintenance-packages="maintenancePackages",
        :initializing="initializing",
        :loading="fetching",
        :sorted-by="parsedQueryParams.sort",
        @sort="onSortChange",
        @changed="fetch"
      )

    .paginator-content(v-if="fetching || showPaginator", :class="{ loading: fetching }")
      paginator(
        :loading="fetching",
        :per-page="pagination.perPage",
        :total="pagination.total",
        :value="parsedQueryParams.page",
        :disabled="fetching",
        @input="onPageChange",
        data-testid="paginator"
      )

</template>


<script>

// View
import IndexView from "@/views/index-view"

// Mixins
import FetchMixin from "@/mixins/fetch-mixin"

// Components
import MaintenancePackagesTable from "./index/maintenance-packages-table"
import SearchForm from "./index/search-form"

export default {
  name: "MaintenancePackages",

  components: {
    MaintenancePackagesTable,
    SearchForm
  },

  extends: IndexView,

  mixins: [FetchMixin],

  data() {
    return {
      i18nScope: "maintenance-packages.index",
      routeName: "maintenancePackages",

      resource: [],

      // @requirement IndexView
      queryParamsStructure: {
        q:         "string",
        discarded: "boolean",
        page:      "integer",
        sort:      "string"
      },

      // @override IndexView
      clearFiltersIgnoreList: ["discarded"]
    }
  },

  computed: {
    maintenancePackages: {
      get() { return this.resource },

      set(value) { this.resource = value }
    },

    newMaintenancePackageRoute() {
      return { name: "newMaintenancePackage" }
    }
  },

  methods: {
    parseRoute() {},

    onDiscardedChange(discarded) {
      this.onParamChange("discarded", discarded)
    },

    // @override Fetchable mixin
    fetchRequest() {
      return this.$newSdk.maintenancePackages.list({ params: this.requestQueryParams })
    }
  }
}

</script>

import Base from "./Base"

class StoreSupplierMaintenanceVehicleModelGroups extends Base {
  get({ storeSupplierMaintenanceVehicleModelGroupId, params = {} } = {}) {
    if (_.blank(storeSupplierMaintenanceVehicleModelGroupId)) {
      throw new Error('SDK StoreSupplierMaintenanceVehicleModelGroups - #get() - "storeSupplierMaintenanceVehicleModelGroupId" is a required prop')
    }

    return this.action({
      method: "get",
      url:    `/store_supplier_maintenance_vehicle_model_groups/${storeSupplierMaintenanceVehicleModelGroupId}`,
      params
    })
  }

  destroy({ storeSupplierMaintenanceVehicleModelGroupId, serviceTypeSlug, params = {} } = {}) {
    if (_.blank(storeSupplierMaintenanceVehicleModelGroupId)) {
      throw new Error('SDK StoreSupplierMaintenanceVehicleModelGroups - #destroy() - "storeSupplierMaintenanceVehicleModelGroupId" is a required prop')
    }

    return this.action({
      method: "delete",
      url:    `/store_supplier_maintenance_vehicle_model_groups/${storeSupplierMaintenanceVehicleModelGroupId}`,
      params
    })
  }
}

export default StoreSupplierMaintenanceVehicleModelGroups

<style lang="scss" scoped>

.vehicle-model-group-default-tab {
  .section-header {
    margin-bottom: 16px;

    .title {
      font-family: $secondary-font;
      font-weight: 500;
      font-size: 20px;
    }
  }

  .footer {
    margin-top: 24px;

    .icon {
      margin-right: 8px;
    }
  }
}

</style>


<template lang="pug">

  .vehicle-model-group-default-tab
    section.section
      .section-header
        h2.title {{ $t('.title') }}

      vehicle-models-list(:has-checkbox="false")

    section.section.footer.flex.space-between
      app-button(:to="{ name: 'vehicleModelGroups' }", outline)
        i.far.fa-arrow-left.icon
        span {{ $t('.btn.footer.back.label') }}

      app-button(
        v-if="$can('create', 'VehicleModelGroup')"
        theme="link",
        :to="{ name: 'newVehicleModelGroup' }",
        data-testid="newVehicleModelGroup"
      )
        span {{ $t('.btn.footer.new.label') }}

</template>


<script>

// Components
import VehicleModelsList from "./show/vehicle-models-list"

export default {
  name: "VehicleModelGroupDefaultTab",

  components: {
    VehicleModelsList
  },

  props: {
    vehicleModelGroup: { type: Object, required: true }
  },

  data() {
    return {
      i18nScope: "vehicle-model-groups.default-tab.show"
    }
  }
}

</script>

function Discardable(Class) {
  class DiscardableClass extends Class {
    static get attrs() {
      return _.uniq([
        ...super.attrs,
        "discardedAt"
      ])
    }

    get $discarded() {
      return this.$present("discardedAt")
    }

    get $kept() {
      return !this.$discarded
    }
  }

  return DiscardableClass
}

export default Discardable

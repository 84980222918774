// Base
import BaseRequests from "../Base"

// Models
import models from "@/models"
const { MovidaVehicle } = models


class MonitoringVehicleRequests extends BaseRequests {
  static get selector() {
    return "monitoring.vehicles"
  }

  find(vehicleId, { params = {}, options = {} } = {}) {
    this.verifyData("find", { vehicleId })

    return this.action({
      method:  "get",
      url:     `/monitoring/vehicles/${vehicleId}`,
      params,
      options: {
        model: MovidaVehicle,

        ...options
      }
    })
  }
}

export default MonitoringVehicleRequests

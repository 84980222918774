<script>

import Vue from "vue"
import SelectField from "@/components/form/select-field"

export default {
  name: "DealStateSelectField",

  extends: SelectField,

  props: {
    // Mudando comportamento para default ser true
    searchable: { type: Boolean, default: true }
  },

  data() {
    return {
      i18nScope: "components.deal-state-select-field"
    }
  },

  computed: {
    options() {
      // XXX: Os valores aceitos seguem mapeamento customizado
      return ["ongoing", "expired"].map(value => ({
        value,
        label: this.$t(`.dealStates.${value}`)
      }))
    }
  },

  // Removendo props 'options' que está sendo reescrita como _computed_
  beforeCreate() {
    Vue.delete(this.$options.props, "options")
  }
}

</script>

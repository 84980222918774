import { mixin } from "@caiena/model"

import Base from "./base"
import Discardable from "./mixins/discardable"

class VehicleModelGroup extends mixin(Base, [Discardable]) {
  static get attrs() {
    return _.uniq([
      ...super.attrs,
      "name",
      "description",

      "vehicleModelIds",
      "vehicleModelsCount"
    ])
  }

  static build(data) {
    return new VehicleModelGroup(data)
  }

  static get relations() {
    return {
      vehicleModels:    { type: "hasMany", model: "VehicleModel" },
      maintenanceTable: { type: "hasOne", model: "VehicleModelGroupMaintenanceTable" }
    }
  }

  static get constraints() {
    return {
      name:            { presence: true, type: "string", length: { maximum: 1000 } },
      vehicleModelIds: { presence: true }
    }
  }
}

export default VehicleModelGroup

<style lang="scss" scoped>

.id-tag {
  margin-left: 8px;
}

.app-button.deal-title {
  font-family: $secondary-font;
  font-weight: normal;
  font-size: 24px;
  color: $dark-gray;

  &:hover {
    color: $orange;
  }
}

</style>


<template lang="pug">

  .flex.vertical-center(data-testid="dealTitle")
    app-button.app-button.deal-title(
      :to="{ name: 'supplierDeal', params: { id: deal.supplierId, dealId: deal.id } }",
      theme="link"
    ) {{ $t('.deal') }}
    id-tag.id-tag(size="big") {{ deal.id }}

</template>


<script>

export default {
  props: {
    deal: { type: Object, required: true }
  },

  data() {
    return {
      i18nScope: "suppliers.deals.components.deal-info-card"
    }
  }
}

</script>

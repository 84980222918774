// Base
import BaseRequests from "../Base"

class VehicleIssuesRequests extends BaseRequests {
  static get selector() {
    return "services.vehicleIssues"
  }

  all({ serviceId, params = {}, options = {} }) {
    this.verifyData("all", { serviceId })

    return this.action({
      method:  "get",
      url:     `/services/${serviceId}/vehicle_issues`,
      params,
      options: {
        model: null,

        ...options
      }
    })
  }
}

export default VehicleIssuesRequests

<template lang="pug">

select-field.part-origin-select-field(
  v-model="_value",
  v-bind="selectFieldProps",
  :options="options"
)

</template>


<script>

// Mixins
import FieldMixin from "@/mixins/field"

// Models
import models from "@/models"
const { QuoteProduct } = models


export default {
  mixins: [FieldMixin],

  data() {
    return {
      i18nScope: "monitoring.quotes.part-origin-select-field"
    }
  },

  computed: {
    _value: {
      get()    { return this.value },
      set(val) { this.$emit("input", val) }
    },

    selectFieldProps() {
      return _.deleteBlanks(this.$props)
    },

    options() {
      return QuoteProduct.$enums.partOrigin.values.map(partOrigin => ({
        label: QuoteProduct.$tEnum("partOrigin", partOrigin),
        value: partOrigin
      }))
    }
  }
}

</script>

<style lang="scss" scoped>

.index-table {
  .skeleton-table {
    margin-top: 16px;
  }

  .empty {
    margin-top: 24px;

    .message {
      font-style: italic;
      font-weight: 400;
    }

    .icon {
      user-select: none;
      margin-bottom: 4px;
      height: 88px;
      width: 88px;
    }
  }

  .list {
    margin-top: 16px;
  }
}

</style>


<template lang="pug">

  .index-table
    template(v-if="initializing")
      .skeleton-table
        slot(name="skeletonTable")
          index-table-skeleton(
            :cols-count="colsCount",
            :column-sizes="columnSizes"
          )

    template(v-else)
      slot(name="tableHeader")

      template(v-if="empty")
        slot(name="tableEmpty")
          .empty.flex.center
            .flex.column-direction.vertical-center
              img.icon(:src="emptyIcon")
              span.message {{ $t('index.empty.message') }}

      template(v-else)
        .rows
          slot(name="tableRows")

</template>


<script>

// Assets
import emptyIcon from "@/assets/images/icons/search/products/none-found.svg"

// Components
import IndexTableSkeleton from "./index-table-skeleton.vue"

export default {
  name: "IndexTable",

  components: {
    IndexTableSkeleton
  },

  props: {
    empty:        { type: Boolean, required: true },
    initializing: { type: Boolean, default: false },

    colsCount:   { type: Number, required: true },
    columnSizes: { type: String, required: true }
  },

  data() {
    return {
      i18nScope: "components.index-table.index-table",
      emptyIcon
    }
  }
}

</script>

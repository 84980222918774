<!--
<doc lang="markdown">
Exibe um código sap, prefixado por um <pre>sap</pre>

_Props_: value, size, color

Como usar:
```pug
  sap-code-tag
    span {{ "25061987" }}
```

Ou passando valor por prop:
```
  sap-code-tag(:value="sapCode")
```
</doc>
-->


<style lang="scss" scoped>

::v-deep .app-tag-label {
  font-family: $primary-monospace-font;
  font-weight: 400;
}

</style>


<template lang="pug">

  code-tag(:color="color", :size="size", v-tooltip.bottom="$t('.tooltip')", :disabled="disabled")
    //- XXX: não há ícone na font-awesome
    //- request: https://github.com/FortAwesome/Font-Awesome/issues/4882
    template(#icon)
      sap-icon(:disabled="disabled", :size="size")

    .flex.vertical-center
      slot(:size="size") {{ value || $t('abbr.unavailable') }}

</template>


<script>

export default {
  name: "SapCodeTag",

  props: {
    color:    { type: String, default: "gray" },
    value:    { type: [String, Number], default: null },
    size:     { type: String, default: "normal", validator: size => ["small", "normal", "big"].includes(size) },
    disabled: { type: Boolean, default: false }
  },

  data() {
    return {
      i18nScope: "components.sap-code-tag"
    }
  }
}

</script>

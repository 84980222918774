<!--
# FinderField

Campo completo de seletor, com busca interna ou externa, label e mensagens de
erro.

## Props
- clearable?: {boolean} habilita botão para limpar o campo.
- debounce?: {boolean | number | object} Arguments for debounce. Ver
  https://lodash.com/docs/4.17.15#debounce
  - debounce.leading?: {boolean}
  - debounce.maxWait?: {number}
  - debounce.trailing?: {boolean}
  - debounce.wait?: {number}
- disabled?: {boolean} {boolean} desabilita entrada.
- errors?: {array<string>}
- fetch?: {async (string) => array<{label: string, value: string} | number | string>}
- label?: {string}
- listboxConfig?: {object} configurações da listbox.
  - listboxConfig.align? {'left' | 'right'} lado que a listbox se alinhará com o
    combobox.
  - listboxConfig.maxHeight? {number} altura máxima em px da listbox.
  - listboxConfig.width? {number} largura em px da listbox.
- multiple?: {boolean} habilitação seleção múltipla.
- name?: {string} nome do campo do formulário.
- options: {array<{label: string, value: string} | string | number>} opções
  disponíveis.
- placeholder?: {string} texto a se exibir quando não há seleção.
- searchable?: {boolean} habilita busca.
- theme?: {'default' | 'borderless'} tema visual do componente.
- value: {array | number | object | string} valor selecionado.

## Emits
- input: {array | number | object | string} houve mudança na seleção. O valor
  emitido corresponde à exata estrutura da opção, assim como fornecido na prop
  `options`.

## Slots
- option: Conteúdo de cada opção listada.
  props:
    - option: {{label: string, value: string} | string | number}

- selectedLabel: Conteúdo do `combobox` quando há seleção.
  props:
    - value: {{label: string, value: string} | string | number} Valor(es) selecionado(s).
    - displayValue: {string} Valor da seleção formatado, separado por vírgula.

## Uso
```javascript
  // ...
  data() {
    return {
      loading: false,
      params: {
        manufacturerId: null,
      },
    }
  },
  methods: {
    async fetch(query) {
      // ...
      return options
    }
  }
  // ...
```
```pug
finder-field(
  v-model="params.manufacturerId"
  :disabled="loading"
  debounce
  :fetch="fetch"
  :label="$t('.fields.category.label')",
  :listboxConfig="{ maxHeight: 350 }"
  name="search[category_id]",
  :placeholder="$t('.fields.category.placeholder')",
  searchable
)
```
-->

<style lang="scss" scoped>
.label {
  color: $dark-gray;
  font-family: $secondary-font;
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  margin-bottom: 8px;
}
.finder-field:focus-within .label {
  color: $primary;
}
.has-error .label {
  color: $error;
}

.error-message {
  color: $error;
  font-size: 12px;
  line-height: 1;
  margin-top: 4px;
}
</style>

<template lang="pug">
  .finder-field(:class="{ 'has-error': hasError }")
    label
      .label(v-if="showLabel") {{ label }}
      template(v-if="isRemote")
        app-remote-finder(
          v-bind="forwardedProps"
          v-on="forwardedListeners"
          :error="hasError"
          :fetch="fetch"
          :on-fetch-complete="onFetchComplete"
          :theme="theme"
        )
          template(
            v-if="'selectedLabel' in $slots || 'selectedLabel' in $scopedSlots"
            #selectedLabel="selectedLabel"
          )
            slot(name="selectedLabel" v-bind="selectedLabel")
          template(
            v-if="'option' in $slots || 'option' in $scopedSlots"
            #option="option"
          )
            slot(name="option" v-bind="option")

      template(v-else)
        app-finder(
          v-bind="forwardedProps"
          v-on="forwardedListeners"
          :error="hasError"
          :searchable="searchable"
          :theme="theme"
        )
          template(
            v-if="'selectedLabel' in $slots || 'selectedLabel' in $scopedSlots"
            #selectedLabel="selectedLabel"
          )
            slot(name="selectedLabel" v-bind="selectedLabel")
          template(
            v-if="'option' in $slots || 'option' in $scopedSlots"
            #option="option"
          )
            slot(name="option" v-bind="option")

    .error-message(v-for="error in errors" :key="error") {{ error }}
</template>

<script>
export default {
  name: "FinderField",

  inheritAttrs: false,

  props: {
    errors:          { type: Array, default: () => [] },
    fetch:           { type: Function, default: null },
    label:           { type: String, required: true },
    onFetchComplete: { type: Function, default: null },
    searchable:      { type: Boolean, default: false },
    theme:           { type: String, default: "default" }
  },

  computed: {
    forwardedListeners() {
      return _.pick(this.$listeners, ["input"])
    },

    forwardedProps() {
      return _.omit(
        this.$attrs,
        this.isRemote ? ["options"] : ["debounce"]
      )
    },

    hasError() {
      return !_.isEmpty(this.errors)
    },

    isRemote() {
      return this.searchable && this.fetch
    },

    showLabel() {
      return ["default"].includes(this.theme)
    }
  }
}
</script>

<style lang="scss" scoped>

.vehicle-issue-selected-option {
  .icon-container {
    --size: 16px;
    --icon-color: #{$gray-2};
    height: var(--size);
    width: var(--size);
    margin: 0 8px 0 12px;

    .icon {
      font-size: 14px;
      color: var(--icon-color);
    }

    .vehicle-part-icon {
      mask-image: var(--svg-icon);
      mask-repeat: no-repeat;
      mask-size: cover;
      height: 14px;
      width: 14px;
      background-color: var(--icon-color);
    }
  }

  .name {
    font-family: $secondary-font;
    font-weight: 400;
    font-size: 14px;
  }
}

</style>


<template lang="pug">

  .vehicle-issue-selected-option.flex.vertical-center
    id-tag.id(:value="vehicleIssue.id")
    .icon-container.flex.vertical-center
      .vehicle-part-icon.icon(:style="vehiclePartIconStyle")
    app-span.name(:value="vehiclePart.name", crop)
    .icon-container.flex.vertical-center
      i.icon.fal.fa-engine-warning
    app-span.name(:value="symptom.name", crop)

</template>


<script>

// Assets
import vehiclePartIcon from "@/assets/images/icons/sidebar/vehicle-part.svg"

export default {
  props: {
    vehicleIssue: { type: Object, default: () => ({}) }
  },

  data() {
    return {
      i18nScope: "tickets.drafts.contents.services.components.vehicle-issue-selected-option"
    }
  },

  computed: {
    vehiclePart() {
      return _.get(this.vehicleIssue, "vehiclePart") || {}
    },

    symptom() {
      return _.get(this.vehicleIssue, "symptom") || {}
    },

    vehiclePartIconStyle() {
      return {
        "--svg-icon": `url(${vehiclePartIcon})`
      }
    }
  }
}

</script>

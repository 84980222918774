<style scoped lang="scss">

.section {
  padding: 24px;
}

.fields {
  border-bottom: 1px solid $light-gray-3;
}

.ui-row + .ui-row {
  margin-top: 24px;
}

</style>


<template lang="pug">

  .new-symptom
    form.form(ref="form", method="post", @submit.prevent="submit")

      section.fields.section
        ui-row.ui-row
          ui-col(:span="12")
            input-field(
              name="symptom[name]",
              :loading="submitting",
              v-model="resource.name",
              :errors="errors.name",
              data-testid="nameInput"
            )

        ui-row.ui-row
          ui-col(:span="12")
            textarea-field(
              name="symptom[description]",
              :loading="submitting",
              v-model="resource.description",
              :errors="errors.description",
              optional,
              data-testid="descriptionInput"
            )

      section.actions.flex.space-between.vertical-center.section
        app-button(
          type="button",
          outline,
          :loading="submitting",
          @click="$emit('cancel')"
        )
          span {{ $t('btn.cancel') }}

        app-button(
          type="submit",
          :loading="submitting",
          data-testid="submitButton"
        )
          span {{ $t('btn.add.label') }}
          span(slot="loading") {{ $t('btn.add.loading') }}

</template>


<script>

import { mixins } from "movida-common.vue"

// Models
import models from "@/models"

const { Symptom } = models
const { FormMixin } = mixins

export default {
  name: "SymptomSelectFieldNew",

  mixins: [FormMixin],

  props: {
    attributes: { type: Object, default: () => ({}) }
  },

  data() {
    return {
      i18nScope: "components.symptom.select-field.new",

      resource: null
    }
  },

  created() {
    this.resource = new Symptom(this.attributes)
  },

  methods: {
    // @override Form mixin
    submitRequest() {
      return this.$sdk.symptoms.create({ params: this.resource.$serialize() })
    },

    // @override Form mixin
    submitSuccess(response) {
      const successMessage = this.$t(".notifications.submit.success", { name: this.resource.name })
      this.$notifications.info(successMessage)

      let resource = new Symptom(response.data)
      this.$emit("create", resource)
    },

    // @override Form mixin
    submitError(err) {
      this.$err.log(err)
      this.$notifications.error(this.$t(".notifications.submit.error"))
    }
  }
}

</script>

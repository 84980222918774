// Ability
import { ability } from "@/modules/ability"

// Routes
import NewProduct from "@/views/products/new.vue"
import Product from "@/views/products/show.vue"
import EditProduct from "@/views/products/edit.vue"
import Products from "@/views/products/index.vue"

export default [
  {
    path:      "/products/new",
    name:      "newProduct",
    component: NewProduct,
    meta:      {
      auth:        true,
      permissions: [() => ability.can("create", "Product")]
    }
  },

  {
    path:      "/products/:id",
    name:      "product",
    component: Product,
    meta:      {
      auth:        true,
      permissions: [() => ability.can("read", "Product")]
    }
  },

  {
    path:      "/products/:id/edit",
    name:      "editProduct",
    component: EditProduct,
    meta:      {
      auth:        true,
      permissions: [() => ability.can("update", "Product")]
    }
  },

  {
    path:      "/products",
    name:      "products",
    component: Products,
    meta:      {
      auth:        true,
      permissions: [() => ability.can("index", "Product")]
    }
  }
]

<style scoped lang="scss">

.section {
  padding: 16px 32px;
  border-bottom: 1px solid $light-gray-3;
}

.body {
  min-height: 540px;
  height: 540px;
}

.selected-option {
  padding-right: 0;
}

.global-price {
  margin-right: 24px;
  height: 40px;
  background-color: $light-gray-2;
}

.icon {
  margin-left: 12px;
  margin-right: 16px;
  font-size: 20px;
  color: $gray-2;
}

.text {
  font-family: $secondary-font;
  font-size: 16px;
  color: $dark-gray;
  font-weight: normal;
}

.option {
  border-bottom: 1px solid $light-gray-3;
}

.product-select-field {
  ::v-deep .options-wrapper {
    max-height: 350px;
  }
}

.origin-radio {
  width: 667px;
  margin-top: 16px;

  ::v-deep .movida-common__radio-label {
    font-weight: 400;
    font-size: 14px;
  }
}

</style>


<template lang="pug">

  app-modal(
    :width="996",
    :heading="$t('.title.label')",
    footer,
    :confirm-label="$t('.btn.create.label')",
    :confirm-button="editable",
    @close="goBack",
    @confirm="submit"
  )
    .body
      section.section
        product-select-field(
          name="dealProduct[productId]",
          v-model="resource.productId",
          :label="$t('.fields.product.label')",
          :placeholder="$t('.fields.product.placeholder')",
          :errors="errors.productId",
          :loading="submitting",
          @change-option="onSelectProduct"
        )

        template(v-if="isPart")
          radio-field.origin-radio(
            name="dealProduct[partOrigin]",
            :options="DealProduct.originOptions",
            :loading="submitting",
            v-model="resource.partOrigin",
            :errors="errors.partOrigin",
            hide-label,
            data-testid="originRadio"
          )

      section.section
        vehicle-model-group-select-field(
          name="dealProduct[vehicleModelGroupId]",
          v-model="resource.vehicleModelGroupId",
          :label="$t('.fields.vehicleModelGroup.label')",
          :placeholder="$t('.fields.vehicleModelGroup.placeholder')",
          :errors="errors.vehicleModelGroupId"
        )
          template(#option="{ props: { option, index, focus, active } }")
            vehicle-model-group-option.option(
              :option="option",
              :active="active",
              :focus="focus"
            )
          template(#selected="{ props: { selected } }")
            vehicle-model-group-option.selected-option(
              :option="selected",
              hide-action-column
            )

      section.section
        ui-row
          ui-col(:span="8")
            .global-price.flex.vertical-center
              i.icon.fal.fa-globe
              span.text {{ $t('.price.title.label') }}
          ui-col(:span="4")
            money-field(
              name="dealProduct[price]",
              v-model="resource.price",
              :loading="submitting",
              :label="$t('.fields.price.label')",
              :placeholder="$t('.fields.price.placeholder')",
              :errors="errors.price"
              hide-label,
              data-testid="priceInput"
            )

</template>


<script>

// Mixins
import { mixins } from "movida-common.vue"

// Models
import models from "@/models"

// Components
import PartOriginSelectField from "../components/part-origin-select-field"
import VehicleModelGroupOption from "./components/vehicle-model-group-option"

const { DealProduct } = models
const { FormMixin } = mixins

export default {
  name: "NewSupplierDealProduct",

  components: {
    PartOriginSelectField,
    VehicleModelGroupOption
  },

  mixins: [FormMixin],

  props: {
    editable: { type: Boolean, default: false }
  },

  data() {
    return {
      i18nScope: "suppliers.deals.products.new",

      id:     this.$params.asInteger(this.$route.params.id),
      dealId: this.$params.asInteger(this.$route.params.dealId),

      resource: new DealProduct(),

      DealProduct
    }
  },

  computed: {
    backRoute() {
      return {
        name:   "supplierDealProducts",
        params: { id: this.id, dealId: this.dealId }
      }
    },

    isPart() {
      return this.resource.$get("product.type") === "Part"
    }
  },

  created() {
    if (!this.editable) {
      this.$router.replace({
        name:   "supplierDealProducts",
        params: { id: this.id, dealId: this.dealId }
      })
    }
  },

  methods: {
    // @override extends view.vue
    goBack() {
      // Voltando para rota pai ao fechar o modal
      if (_.get(this.$router.referrer, "name") === this.backRoute.name) {
        this.$goBack({ fallback: this.backRoute })
      }
      else {
        this.$router.push(this.backRoute)
      }
    },

    // @override Form mixin
    submitRequest() {
      const params = this.resource.$serialize()
      return this.$sdk.deals.createProduct({ id: this.dealId, params })
    },

    // @override Form mixin
    submitSuccess(response) {
      let message = this.$t(".notifications.submit.success", {
        name: _.get(this.resource, "product.name")
      })
      this.$notifications.info(message)

      this.$emit("change")
      this.goBack()
    },

    // @override Form mixin
    submitError(err) {
      this.$err.log(err)
      this.$notifications.error(this.$t(".notifications.submit.error"))
    },

    onSelectProduct(productOption) {
      const product = _.get(productOption, "data")

      if (_.blank(product)) {
        this.resource.product = null
        this.resource.productId = null
        return
      }

      this.resource.product = product
      this.resource.productId = product.id
    }
  }
}

</script>

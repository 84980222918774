<style scoped lang="scss">

.service-order-row {
  display: grid;
  grid-template-columns: var(--grid-columns);
  padding: 8px 0;
  border-bottom: 1px solid $light-gray-3;
  min-height: 64px;

  &.loading {
    .column {
      .text,
      .empty,
      .service-order,
      .small-text,
      .icon-container .icon {
        color: $light-gray-4;
      }

      .icon-container.big.action {
        cursor: progress;

        &:hover,
        &:active,
        &:focus {
          background-color: unset;

          .icon {
            color: $light-gray-4;
          }
        }

        .icon {
          color: $light-gray-4;
        }
      }
    }
  }

  .column {
    padding: 0 12px;
    overflow: hidden;

    .icon-container {
      position: relative;

      &.big {
        width:  40px;
        height: 40px;

        &.action {
          cursor: pointer;

          &:hover {
            background-color: $light-gray-2;
            border-radius: 4px;

            .icon {
              color: $dark-orange;
            }
          }

          &:active,
          &:focus {
            background-color: $light-gray-3;
            border-radius: 4px;

            .icon {
              color: $dark-orange-2;
            }
          }

          .icon {
            color: $orange;
          }

          &.loading {
            cursor: progress;

            &:hover,
            &:active,
            &:focus {
              background-color: unset;

              .icon {
                color: $light-gray-4;
              }
            }

            .icon {
              color: $light-gray-4;
            }
          }
        }
      }

      .icon {
        color: $gray-2;
      }

      .empty-icon {
        font-size: 12px;
      }

      .at-supplier,
      .issued {
        color: $purple;
      }

      .changes {
        color: $dark-warning;
      }

      .reviewed {
        color: $info;
      }
    }

    &.service-order-column {
      > * + * {
        margin-top: 4px;
      }
    }

    .id {
      font-family: $primary-monospace-font;
      font-weight: 400;
      font-size: 14px;
    }

    .icon.complementary {
      color: $gray-2;
      margin-right: 6px;
      font-size: 12px;
    }

    .text {
      font-family: $secondary-font;
      font-weight: 400;
      font-size: 14px;

      &.word-break {
        word-break: normal;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }
    }

    .state {
      margin-top: 4px;
      width: 85px;
    }

    &.vehicle-column {
      .tags {
        margin-top: 6px;
      }

      .license-plate {
      }

      .vehicle-category-tag {
        margin-top: 4px;
      }
    }

    .diamond-icon {
      font-size: 4px;
      color: $gray-3;
      margin: 0 4px;
    }

    .empty {
      font-weight: 400;
      font-style: italic;
      font-size: 14px;
      color: $gray-3;
    }

    .small-text {
      font-size: 12px;
      font-weight: 400;
      color: $gray-3;

      &.word-break {
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }
    }

    .address {
      margin-top: 4px;
    }
  }

  .service-order-column,
  .vehicle-column,
  .supplier-column {
    cursor: pointer;
  }

  .counter-badge {
    position: absolute;
    top:   2px;
    right: 2px;

    background-color: $purple;
    padding: 2px 4px;
    border: 1px solid $white;
    border-radius: 6px;

    font-family: $secondary-font;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 100%;

    color: $white;
  }

}

</style>


<template lang="pug">

  .service-order-row(:class="{ loading }", :style="styles")
    template(v-if="canSelectRow")
      .column.select-column.flex.center
        checkbox-field(
          v-model="isSelected",
          hide-value-text,
          name="selected",
          :loading="loading"
        )

    //- coluna :service-order
    v-popover(
      placement="bottom",
      trigger="manual",
      :open="showServiceOrderPopover",
      @hide="showServiceOrderPopover = false"
    )
      .column.service-order-column.flex.column-direction(@click="showServiceOrderPopover = true")
        .flex.vertical-center
          template(v-if="serviceOrder.isComplementary")
            i.icon.complementary.fas.fa-rectangle-vertical-history(v-tooltip="$t('.icons.complementary.tooltip')")
          span.id {{ $t(".columns.service-order.id", { id: serviceOrder.remoteId }) }}

        //- app-span.text-center(crop, :value="MovidaServiceOrder.$tEnum('serviceKind', serviceOrder.serviceKind)")
        span.service-kind.small-text {{ MovidaServiceOrder.$tEnum("serviceKind", serviceOrder.serviceKind) }}

        service-order-state.state(:state="serviceOrder.state")


      template(#popover)
        service-order-popover-content(:open="showServiceOrderPopover", :service-order="serviceOrder")


    v-popover(
      placement="bottom",
      trigger="manual",
      :open="showVehiclePopover",
      @hide="showVehiclePopover = false"
    )
      .column.vehicle-column.flex.column-direction(@click="showVehiclePopover = true")

        span.text.word-break {{ vehicle.modelName }}

        .flex.column-direction.tags
          .flex
            license-plate.license-plate(
              size="small",
              :loading="loading",
              :value="vehicle.licensePlate"
            )

          template(v-if="vehicle.category")
            .flex
              vehicle-category-tag.vehicle-category-tag(:value="vehicle.category")

      template(#popover)
        vehicle-popover(
          :show="showVehiclePopover",
          :vehicle-id="vehicle.id"
        )

    v-popover(
      placement="bottom",
      trigger="manual",
      :open="showSupplierPopover",
      @hide="showSupplierPopover = false"
    )
      .column.supplier-column.flex.column-direction.center(@click="showSupplierPopover = true")
        span.text.word-break {{ supplier.tradingName }}
        span.address.small-text.word-break {{ supplier.simpleAddress }}

      template(#popover)
        supplier-popover(
          :show="showSupplierPopover",
          :supplier-rid="supplier.rid"
        )

    .column.customer-column.flex.center
      template(v-if="hasCustomer")
        .flex.column-direction.full-width
          //- app-span.text(:value="customer.name", crop)
          span.text.word-break {{ customer.name }}
          .flex.vertical-center
            business-unit-tag.business-unit-tag(:value="serviceOrder.businessUnit")
            template(v-if="customer.costCenterCode")
              i.diamond-icon.fas.fa-diamond
              span.small-text {{ customer.costCenterCode }}

      template(v-else-if="hasBranch")
        .flex.column-direction.full-width
          //- app-span.text(:value="branchName", crop)
          span.text.word-break {{ branchName }}
          business-unit-tag.business-unit-tag(:value="branch.businessUnit")

      template(v-else)
        span.empty {{ $t("abbr.unavailable") }}


    .column.messages-column.flex.vertical-center.center
      .icon-container.big.flex.center.vertical-center(
        :class="{ action: messagesActionEnabled }",
        :tabindex="0",
        @click="() => onAction('show-messages')",
        @keydown.enter.prevent="() => onAction('show-messages')",
        @keydown.space.prevent="() => onAction('show-messages')"
      )
        i.icon.far.fa-message-pen
        template(v-if="hasMessages")
          .counter-badge
            span.count {{ messagesCountText }}

    .column.assignment-column.flex.vertical-center.center
      template(v-if="hasAssignment")
        initials-tag(:name="assignment.assigneeName", :seed="assignment.assigneeRid")

      template(v-else)
        .icon-container.big.flex.center.vertical-center
          i.icon.fal.fa-user-large-slash

    .column.timeline-column.flex.vertical-center
      //- TODO: aplicar timeline dependendo do tipo da OS!
      template(v-if="serviceOrder.serviceKind == 'purchase'")
        purchase-timeline(:service-order="serviceOrder")
      template(v-else)
        service-timeline(:service-order="serviceOrder")

    .column.actions-column.flex.vertical-center.center
      template(v-if="actionsEnabled")
        service-order-actions(
          :service-order="serviceOrder"
          @show="showServiceOrder"
          @messages="() => onAction('show-messages')"
          @vehicle-receipt="() => onAction('create-vehicle-receipt')"
          @edit-quote="editQuote"
          @review-quote="reviewQuote"
          @service-execution="() => onAction('execute')"
          @withdraw-vehicle="() => onAction('withdraw-vehicle')"
          @postpone="() => onAction('postpone')"
          @cancel="() => onAction('cancel')"
          @reopen="() => onAction('reopen')"
          @resend-approval-notification="() => onAction('resend-approval-notification')"
        )
</template>

<script>
// Components
import ServiceOrderPopoverContent from "./service-order-popover-content.vue"
import VehiclePopover             from "./vehicle-popover.vue"
import SupplierPopover            from "./supplier-popover.vue"

import InitialsTag         from "@/views/monitoring/_components/initials-tag.vue"
import ServiceTimeline     from "./service-timeline.vue"
import PurchaseTimeline    from "./purchase-timeline.vue"
import ServiceOrderActions from "./service-order-actions.vue"
import VehicleCategoryTag  from "@/views/monitoring/_components/vehicle-category-tag.vue"
import ServiceOrderState   from "./service-order-state.vue"


// Models
import models from "@/models"
const { MovidaServiceOrder } = models


export default {
  components: {
    ServiceOrderPopoverContent,
    SupplierPopover,
    VehiclePopover,

    InitialsTag,
    ServiceTimeline,
    PurchaseTimeline,
    ServiceOrderActions,
    VehicleCategoryTag,
    ServiceOrderState
  },

  props: {
    serviceOrder: { type: Object,  default: () => ({}) },  // TODO: type: MovidaServiceOrder
    loading:      { type: Boolean, default: false },
    columnSizes:  { type: String,  required: true },
    selected:     { type: Boolean, required: true }
  },

  emits: [
    "cancel",
    "create-vehicle-receipt",
    "execute",
    "postpone",
    "reopen",
    "resend-approval-notification",
    "show-messages",
    "withdraw-vehicle"
  ],

  data() {
    return {
      i18nScope: "monitoring.service-order-row",

      showServiceOrderPopover: false,
      showVehiclePopover:      false,
      showSupplierPopover:     false,

      MovidaServiceOrder
    }
  },

  computed: {
    isSelected: {
      get()      { return this.selected },
      set(value) { this.$emit("select", value) }
    },

    isCanceled() {
      return this.serviceOrder?.state === "canceled"
    },

    hasAssignment() {
      return _.present(this.assignment)
    },

    hasBranch() {
      return this.serviceOrder.$present("branch")
    },

    hasCustomer() {
      return _.present(this.customer)
    },

    actionsEnabled() {
      return true
    },

    messagesActionEnabled() {
      return !this.isCanceled
    },

    hasMessages() {
      return this.serviceOrder.messagesCount > 0
    },

    messagesCountText() {
      return this.serviceOrder.messagesCount > 99
        ? "99+"
        : `${this.serviceOrder.messagesCount}`
    },

    assignment() {
      return this.serviceOrder?.assignment
    },

    branch() {
      return this.serviceOrder?.branch
    },

    branchName() {
      return _.trim(`${this.branch.initials} - ${this.branch.name}`)
    },

    customer() {
      return this.serviceOrder?.customer || {}
    },

    vehicle() {
      return this.serviceOrder?.vehicle || {}
    },

    supplier() {
      return this.serviceOrder?.supplier || {}
    },

    styles() {
      return {
        "--grid-columns": this.columnSizes
      }
    },

    canSelectRow() {
      return this.$can("create", "ServiceOrderAssignment")
    }
  },


  methods: {
    editQuote() {
      if (this.loading) return

      this.$router.push({ name: "editQuote", params: { id: this.serviceOrder?.quote?.id } })
    },

    reviewQuote() {
      if (this.loading) return

      this.$router.push({ name: "reviewQuote", params: { id: this.serviceOrder?.quote?.id } })
    },

    showServiceOrder() {
      if (this.loading) return

      this.$router.push({
        name:   "monitoringServiceOrder",
        params: {
          // XXX: tem que ser rid. fonte remota desconhecida, com id próprio. (ex: vetor)
          serviceOrderId: this.serviceOrder.rid
        }
      })
    },

    onAction(name) {
      this.$emit(name, this.serviceOrder)
    }
  }
}

</script>

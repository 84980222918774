<style lang="scss" scoped>

.new.postponement {
  .section {
    padding: 16px 24px;

    & + .section {
      border-top: 1px solid $light-gray-3;
    }
  }

  .row {
    padding: 4px;
  }

  .diamond-icon {
    color: $gray-2;
    font-size: 6px;
    padding: 0 8px;
    vertical-align: middle;
  }

  .icon {
    margin-right: 12px;
    line-height: 24px;
  }

  .check-square {
    margin-top: 7px;
  }

  .capital {
    text-transform: capitalize;
  }

  .estimative {
    margin-bottom: 25px;
    border-bottom: 1px solid $light-gray-3;
  }

  .final {
     margin-bottom: 12px;
  }

  .description {
    margin-top: 16px;

    ::v-deep .input-inner {
      height: 110px;
    }
  }
}

</style>


<template lang="pug">

  app-modal.new.postponement(
    :width="566",
    :heading="$t('.heading')",
    footer,
    :confirm-label="$t('.btn.confirm.label')",
    :confirm-label-loading="$t('.btn.confirm.label')",
    :loading="submitting",
    @close="onClose",
    @confirm="submit"
  )
    .content
      .section
        service-order-info(
          :service-order="serviceOrder",
          :vehicle="serviceOrder.vehicle",
          :supplier="serviceOrder.supplier",
          :customer="serviceOrder.customer",
          :branch="serviceOrder.branch"
        )

      .section
        form(
          method="post",
          ref="form",
          @submit.prevent="submit"
        )
          template(v-if="serviceOrder.estimatedCompletedAt")
            .flex.row.estimative
              .col.check-square
                i.icon.fal.fa-check-square
              .col
                .row
                  span.title.bold {{ $t(".fields.estimatedCompletedAt.label") }}
                .row.final
                  span.title.capital {{ $l("date.formats.weekDay", this.serviceOrder.estimatedCompletedAt) }}
                  i.diamond-icon.fas.fa-diamond
                  span.text {{ $l('time.formats.short', serviceOrder.estimatedCompletedAt) }}

          template(v-else)
            .flex.row.estimative
              .col
                i.icon.fal.fa-times-square
              .col.final
                div.title.italic {{ $t(".fields.estimatedCompletedAt.empty") }}

          datetime-field(
            name="postponement[to]",
            :label="$t('.fields.to.label')"
            mandatory,
            future-only,
            v-model="postponement.to",
            :disabled="submitting",
            :errors="errors.to"
          )

          textarea-field.description(
            name="postponement[description]",
            :label="$t('.fields.description.label')",
            :placeholder="$t('.fields.description.placeholder')",
            v-model="postponement.description",
            :disabled="submitting",
            :errors="errors.description"
          )

</template>


<script>

// Mixins
import FormMixin from "@/mixins/form-mixin"

// Components
import ServiceOrderInfo from "@/views/monitoring/_components/service-order-info.vue"


// Models
import models from "@/models"
const { MovidaServiceOrder, ServiceOrderPostponement } = models


export default {
  components: {
    ServiceOrderInfo
  },

  mixins: [FormMixin],

  props: {
    serviceOrder: { type: MovidaServiceOrder, required: true }
  },

  data() {
    return {
      i18nScope: "monitoring.service-orders.postponements.new",

      resource: new ServiceOrderPostponement()
    }
  },

  computed: {
    postponement: {
      get()    { return this.resource },
      set(val) { this.resoure = val }
    },

    serviceOrderRid() { return this.serviceOrder.rid }
  },

  created() {
    this.initialSerializedAttributes = this.serializeAttributes()
  },

  methods: {
    // @override Form mixin
    serializeAttributes() {
      return this.resource.$serialize()
    },

    // @override Form mixin
    submitRequest() {
      let params = this.serializeAttributes()

      return this.$newSdk.monitoring.serviceOrders.postponement
        .create(this.serviceOrderRid, { params })
    },

    // @override Form mixin
    onSubmitSuccess(response) {
      // XXX: usando id do Vetor (remoteId) para ficar coerente com a listagem
      let successMessage = this.$t(".notifications.submit.success", { id: this.serviceOrder.remoteId })
      this.$notifications.info(successMessage)
    },


    // @override Form mixin
    afterSubmitSuccess() {
      this.$emit("create")
      this.$emit("close")
    },

    // XXX: como não é rota/view, precisa implementar manualmente esse check
    async onClose() {
      const isDirty = this.checkDirty()

      if (!isDirty || await this.askDirtyLeaveConfirmation()) this.$emit("close")
    }
  }
}

</script>

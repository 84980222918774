<!--
<doc lang="markdown">
Base para _views_ que dependem de dados externos.

Define comportamentos básicos de recarregamento de dados por conta de navegação.

Como usar:
```pug
  // View
  import LoadedView from '@/views/loaded-view'

  ...

  extends: LoadedView
```
</doc>
-->

<script>

// Parent
import BaseView from "./base-view"

export default {
  extends: BaseView,

  watch: {
    // Responsável pelo funcionamento do recarregamento de dados em troca de rotas
    $route(to, from) {
      // Se vem de outra rota (view) para essa, então ignore
      // - O ciclo de vida dos componentes vai levar isso em conta
      if (from.name !== to.name && to.name === this.routeName) return

      // Se está navegando para alguma rota aninhada, como um modal ou aba, ignore
      // - Está partindo dessa rota/view para uma aninhada ou é navegação interna de rota aninhada
      const currentPath = this.$route.path
      if (currentPath !== this.path && currentPath.startsWith(this.path)) return

      // Senão, recarregue: houve troca de parâmetros (query ou ids) na rota (view) atual
      this.parseRouteAndFetch()

      // e recompute o valor de `path`, que pode ter mudado por conta de parâmetros de URL
      this.computePath()
    }
  },

  created() {
    if (this._fetch) throw new Error("Loaded View - #created() - Incompatible with Fetchable mixin")

    // Popula os dados de rota imediatamente
    this.parseRoute()
  },

  methods: {
    parseRouteAndFetch() {
      this.parseRoute()
      this.fetch()
    },

    // Realiza a leitura de identificadores da rota
    parseRoute() {
      // exemplo:
      // this.supplierId = this.$params.asInteger(this.$route.params.id)
      throw new Error("Loaded View - #parseRoute() - Must be implemented")
    },

    fetch() {
      throw new Error("Loaded View - #fetch() - Must be implemented")
    }
  }
}

</script>

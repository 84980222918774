.<style lang="scss" scoped>

.suppliers-table-header {
  display: grid;
  grid-template-columns: var(--grid-columns);

  background-color: $light-gray;
  height: 40px;
  border-bottom: 1px solid $light-gray-3;
  border-top: 1px solid $light-gray-3;

  &.loading {
    border-bottom: 2px solid $light-orange-2;
  }

  .unsortable {
    padding: 0 12px;
  }

  .column {
    font-size: 12px;

    .icon {
      font-size: 16px;
      color: $gray-2;
    }

    .text {
      font-family: $secondary-font;
      font-size: 14px;
      color: $dark-gray;
      font-weight: 400;
    }

    .loader-icon {
      font-size: 16px;
      color: $orange;
    }
  }
}

</style>


<template lang="pug">

  .suppliers-table-header(:class="{ loading }", :style="styles")
    .column(data-testid="idColumn")
      sortable-table-header-column(
        sort-param="id",
        :sort-order="sortOrder",
        :active="isSortedBy('id')",
        @sort="param => $emit('sort', param)"
      )
        span.text #

    .column.photo-content.unsortable.flex.vertical-center.center(data-testid="photoColumn")
      i.icon.fas.fa-user-hard-hat

    .column(data-testid="tradingNameColumn")
      sortable-table-header-column(
        sort-param="trading_name",
        :sort-order="sortOrder",
        :active="isSortedBy('trading_name')",
        @sort="param => $emit('sort', param)"
      )
        span.text {{ $t('.columns.tradingName.label') }}

    .column(data-testid="cnpjColumn")
      sortable-table-header-column.column(
        sort-param="cnpj",
        :sort-order="sortOrder",
        :active="isSortedBy('cnpj')",
        @sort="param => $emit('sort', param)"
      )
        span.text {{ $t('.columns.cnpj.label') }}

    .column.classification.unsortable.flex.vertical-center.center
      i.icon.fas.fa-certificate

    .column.unsortable.flex.vertical-center(data-testid="addressColumn")
      span.text {{ $t('.columns.address.label') }}

    .column.unsortable.flex.vertical-center(data-testid="dealColumn")
      span.text {{ $t('.columns.deal.label') }}

    .column.flex.unsortable.vertical-center.center
      template(v-if="loading")
        i.loader-icon.fas.fa-spin.fa-spinner

</template>


<script>

export default {
  props: {
    loading:   { type: Boolean, default: false },
    sortParam: { type: String, required: true },
    sortOrder: { type: String, required: true },

    columnSizes: { type: String, required: true }
  },

  data() {
    return {
      i18nScope: "suppliers.index.suppliers-table-header"
    }
  },

  computed: {
    styles() {
      return {
        "--grid-columns": this.columnSizes
      }
    }
  },

  methods: {
    isSortedBy(param) {
      return this.sortParam === param
    }
  }
}

</script>
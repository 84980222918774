<style lang="scss" scoped>

.services {
  padding-bottom: 24px;

  .header {
    margin-bottom: 32px;
    padding-bottom: 16px;
    border-bottom: 1px solid $light-gray-3;

    .title-icon {
      margin-right: 24px;
    }

    .title-text {
      font-size: 30px;
      font-weight: normal;
      font-weight: 500;
      font-family: $secondary-font;
    }
  }

  .search {
    margin-bottom: 32px;
  }

  .paginator-content {
    padding-top: 24px;

    &.loading {
      max-width: 400px;
      margin: 0 auto;
    }
  }
}

</style>


<template lang="pug">

  .services
    header.header.flex.space-between.vertical-center
      .title.flex.vertical-center
        img.title-icon(:src="ticketType.icon")
        h1.title-text {{ ticketType.name }}

    search-form.search(
      :params="params",
      @search="search"
    )

    app-list-header(
      v-if="showAppListHeader"

      :has-filters="hasFilters",
      :page="params.page",
      :pagination="pagination",
      :loading="fetching",

      @clear="clearFilters"
    )

    app-list(
      :loading="fetching",
      :empty="empty",
      :empty-text="$t('.empty')",
      :error-text="$t('.error')",
      :error="hasFetchError"
    )
      service-row(
        v-for="(service, index) in resource",
        @destroy="updateDeletedService(service)"
        @create="updateCreatedService($event, service)"
        :key="`service-${service.id}-${index}`",
        :service="service"
      )

    .paginator-content(v-if="fetching || showPaginator", :class="{ loading: fetching }")
      paginator(
        :loading="fetching",
        :per-page="pagination.perPage",
        :total="pagination.total",
        @input="onChangePage",
        :value="params.page",
        :disabled="fetching",
        data-test="paginator"
      )

    router-view(:backRouteQuery="currentRouteQuery")

</template>


<script>

// Extends
import SearchableIndexView from "@/views/searchable-index-view"

// Local components
import SearchForm from "./index/search-form"
import ServiceRow from "./index/service-row"

// Models
import models from "@/models"

const { TicketType, TicketTypeService } = models

export default {
  name: "TicketTypeServices",

  components: {
    SearchForm,
    ServiceRow
  },

  extends: SearchableIndexView,

  data() {
    return {
      i18nScope: "ticket-types.services.index",

      ticketType: {},

      model: "Service",

      // Query Mixin data
      parseQueryParams: {
        q:    "string",
        page: "integer",
        type: "string",
        active: "boolean"
      }
    }
  },

  computed: {
    type() {
      return TicketType.parseParamType(this.$route.params.type)
    }
  },

  created() {
    this.ticketType = new TicketType({ type: this.type })
  },

  methods: {
    // @override SearchableIndexView
    onRouteChange(to, from) {
      // se vem de outra rota (view) para essa, então ignore
      // - o ciclo de vida dos componentes vai levar isso em conta
      if (from.name !== to.name && to.name === this.routeName) return

      // se está navegando para alguma rota aninhada, ignore
      // - está partindo dessa rota/view para uma aninhada
      if (this.$route.path !== this.route.path && this.$route.path.startsWith(this.route.path)) return

      // senão, recarregue: houve troca de parâmetros na rota (view) atual!
      // TODO: revisar esse comportamento. aparentemente ele deveria ser fixo
      // no SearchableIndexView
      this.parseAndFetch()
    },

    iconWeightClass(active) {
      return active ? "fas" : "fal"
    },

    // @override Fetchable mixin
    fetch() {
      return this.$sdk.ticketTypes.listServices({
        params: { ...this.httpParams, ticket_type: this.$route.params.type }
      })
    },

    // @override Fetchable mixin
    onFetchSuccess({ headers }) {
      this.setPagination(headers)
    },

    // @override Fetchable mixin
    onFetchError(err) {
      // Dealing with appError from extension view.vue
      if (this.hasViewError(err)) this.appError = err

      this.$err.log(err)
      this.$notifications.error(this.$t(".notifications.fetch.failure"))
    },

    updateDeletedService(service) {
      service.ticketTypeService = null
    },

    updateCreatedService(ticketTypeService, service) {
      service.ticketTypeService = new TicketTypeService(ticketTypeService)
    }
  }
}

</script>

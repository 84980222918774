<style lang="scss" scoped>

.loading-lines {
  width: 320px;
}

.email {
  cursor: pointer;
  height: 32px;
  padding: 0 8px;
  border-radius: 5px;
  width: max-content;

  &:hover {
    color: $primary;
    background-color: $light-gray-2;
  }

  .status {
    font-size: 12px;
    margin-right: 8px;
  }

  .chevron {
    font-size: 10px;
    margin-left: 8px;
  }
}

.popover-content {
  min-width: 555px;

  .row,
  .link {
    font-size: 14px;
  }

  .row {
    height: 40px;
    padding: 0 16px;
    font-weight: 300;

    .icon {
      font-size: 16px;
      height: 16px;
      width: 16px;
      margin-right: 16px;
    }
  }

  .email-address {
    font-weight: 500;
    margin-left: 4px;
    margin-right: 16px;
  }

  .row + .row,
  .sent-emails {
    border-top: 1px solid $light-gray-3;
  }
}

.unregistered {
  font-style: italic;
}

.loading-lines {
  cursor: progress;
}

.icon {
  color: $gray-2;

  &.success {
    color: $dark-success;
  }

  &.error {
    color: $error;
  }

  &.erred {
    color: $warning;
  }
}

.loading {
  height: 40px;
  padding: 0 16px;
}

.loading + .loading {
  border-top: 1px solid $light-gray-3;
}

.email-history {
  height: 32px;
}

::v-deep .trigger {
  width: 100%;
}

.sent-emails {
  max-height: 400px;
  overflow: auto;
}

</style>


<template lang="pug">

  .email-history
    v-popover(
      placement="bottom",
      :open="showPopover",
      @hide="closePopover",
      trigger="manual"
    )
      loading-lines.email-loading(
        v-if="fetching",
        :min="80",
        :max="100",
        :height="32"
      )
      .email.flex.vertical-center(
        v-else,
        @click="togglePopover",
        data-testid="emailHistoryPopoverToggle"
      )
        template(v-if="erred")
          i.icon.fas.fa-exclamation-triangle.status.erred
          span {{ $t('.erred') }}

        template(v-else-if="empty")
          i.icon.fas.fa-envelope.status
          span.unregistered {{ $t('.notRegistered') }}

        template(v-else-if="!hasNonFailed")
          i.icon.fas.fa-times-circle.status.error
          span {{ $t('.failed') }}

        template(v-else-if="lastNonFailed.status === 'done'")
          i.icon.fas.fa-check-circle.status.success
          span {{ $t('.sentFor', { email: lastNonFailed.to }) }}

        template(v-else)
          i.icon.fas.fa-exclamation-circle.status
          span {{ $t('.sendingFor', { email: lastNonFailed.to }) }}

        i.fas.fa-chevron-down.chevron

      template(slot="popover")
        template(v-if="fetching")
          .loading.flex.vertical-center(v-for="line in 3")
            loading-lines(
              v-if="fetching",
              :min="80",
              :max="100"
            )

        template(v-else)
          .popover-content(data-testid="emailHistoryPopoverContent")
            .row.flex.vertical-center
              i.icon.fal.fa-envelope
              .flex.space-between.full-width
                span(v-if="!empty") {{ $t('.popover.sentEmails') }}
                span.unregistered(v-else) {{ $t('.notRegistered') }}
                app-button.link(
                  v-if="canResend",
                  theme="link",
                  @click="openModal"
                ) {{ $t('.resendEmail') }}

            .sent-emails
              .row.flex.vertical-center(
                v-for="(entry, index) in history",
                :key="`email-history-entry-${index}`"
              )
                i.icon.fas(:class="iconClassFor(entry)")
                span {{ $t(`.popover.sent.${entry.status}`) }}
                span.email-address {{ entry.to }}
                app-timestamp(:timestamp="entry.sentAt || entry.updatedAt")

    app-modal(
      v-if="showModal && canResend",
      :heading="$t('.resendEmail')",
      :close-button="!submitting",
      :width="610",
      @close="closeModal",
      footer,
      @confirm="submit",
      @cancel="closeModal",
      :loading="submitting"
    )
      form.body.form(@submit.prevent="submit", method="post")
        input-field(
          name="emailForm[to]",
          :label="$t('.popover.fields.to.label')",
          :placeholder="$t('.popover.fields.to.placeholder')",
          v-model="resource.to"
          :errors="errors.to"
        )

</template>


<script>

// Mixins
import { mixins } from "movida-common.vue"
import FetchMixin from "@/mixins/fetch-mixin"

// Models
import models from "@/models"

const { FormMixin } = mixins
const { Base } = models

class EmailForm extends Base {
  static get attrs() {
    return _.uniq([
      ...super.attrs,
      "to"
    ])
  }

  static get constraints() {
    return {
      to: { presence: true, email: true }
    }
  }
}

export default {
  mixins: [FetchMixin, FormMixin],

  props: {
    defaultEmailAddress: { type: String, default: null },
    canResend:           { type: Boolean, default: true }
  },

  data() {
    return {
      i18nScope: "stores.tickets.service-orders.show.email-history",

      showPopover: false,
      showModal:   false,

      fetching: false,
      history:  [],

      resource: new EmailForm()
    }
  },

  computed: {
    empty() {
      return this.history.length === 0
    },

    nonFailedEmails() {
      return this.history.filter(entry => entry.status !== "error")
    },

    hasNonFailed() {
      return this.nonFailedEmails.length > 0
    },

    lastNonFailed() {
      return _.head(
        [...this.nonFailedEmails].sort((first, second) => moment(second.sentAt).diff(first.sentAt))
      )
    }
  },

  methods: {
    togglePopover() {
      this.showPopover ? this.closePopover() : this.openPopover()
    },

    async openPopover() {
      await this.fetch()
      if (!this.erred) this.showPopover = true
    },

    closePopover() {
      this.showPopover = false
    },

    openModal() {
      this.showPopover = false

      this.resource.to = this.defaultEmailAddress
      this.errors = {}

      this.showModal = true
    },

    closeModal() {
      this.showModal = false
    },

    iconClassFor(entry) {
      switch (entry.status) {
        case "scheduled":
        case "running":
        default:
          return "fa-exclamation-circle"
        case "done":
          return "fa-check-circle success"
        case "error":
          return "fa-times-circle error"
      }
    },

    onFetchSuccess({ data = [], headers }) {
      this.history = data
    },

    fetchRequest() {
      // Deve ser implementado em componentes herdeiros
      return {}
    },

    // @override Form mixin
    submitRequest() {
      throw new Error("EmailHistory - #submitRequest() - Must be implemented")
    },

    // @override Form mixin
    submitSuccess(response) {
      this.$notifications.info(this.$t(".notifications.submit.success", { email: this.resource.to }))

      this.fetch()

      this.closeModal()
    },

    // @override Form mixin
    submitError(err) {
      this.$err.log(err)
      this.$notifications.error(this.$t(".notifications.submit.error"))

      this.$emit("resend-error")
    }
  }
}

</script>

<style lang="scss" scoped>

.products-table {
  .empty {
    background-color: $light-gray;
    border-radius: 8px;
    padding: 24px 0;

    .empty-message {
      font-family: $secondary-font;
      font-weight: 400;
      font-size: 14px;
      margin-top: 8px;
      color: $gray-3;
    }
  }

  .attachments {
    margin: -24px 0 0 -24px;

    .attachment-card,
    .empty-card {
      margin-left: 24px;
      margin-top: 24px;
    }

    .empty-card {
      border: 1px dashed $gray-3;
      border-radius: 8px;
      width: 212px;
      height: 140px;
      cursor: pointer;

      .icon {
        color: $gray-2;
        font-size: 20px;
      }
    }
  }
}

</style>


<template lang="pug">

  .products-table
    index-table.products-table(
      :empty="empty",
      :cols-count="6",
      :column-sizes="columnSizes"
    )

      template(#tableHeader)
        table-header(:column-sizes="columnSizes")

      template(#tableRows)
        product-row(
          v-for="(product, index) in products"
          :key="product.id",
          :product="product",
          :loading="loading",
          :column-sizes="columnSizes"
        )

        total-price-row(:total-price="totalPrice" :message="$t('.fields.totalPrice')")

</template>


<script>

// Components
import TableHeader from "./products-table/table-header.vue"
import ProductRow from "./products-table/product-row.vue"
import TotalPriceRow from "./total-price-row.vue"

export default {
  name: "ProductsTable",

  components: {
    TableHeader,
    ProductRow,
    TotalPriceRow
  },

  props: {
    products: { type: Array, required: true },

    loading: { type: Boolean, default: false }
  },

  data() {
    return {
      i18nScope: "service-orders.show.products-table"
    }
  },

  computed: {
    empty() {
      return _.blank(this.products)
    },

    columnSizes() {
      return "128px 1fr 1fr 58px 212px 212px"
    },

    totalPrice() {
      const total = _.sumBy(this.products, product => product.totalPrice)

      return total
    }
  }
}

</script>

import { i18n } from "utils.vue"
import Base from "./base"

class ServicePackage extends Base {
  static get attrs() {
    return _.uniq([
      ...super.attrs,
      "name",
      "description",

      "servicesCount",
      "productsCount",

      "serviceIds",
      "productIds"
    ])
  }

  static get relations() {
    return {
      services: { type: "hasMany", model: "Service" },
      products: { type: "hasMany", model: "Product" }
    }
  }

  static get constraints() {
    return {
      name: { presence: true, type: "string", length: { maximum: 1000 } },

      serviceIds: {
        presence(value, attrs) {
          if (_.blank(attrs.productIds)) {
            return { message: i18n.t("models.servicePackage.errors.productOrService.invalid") }
          }
          return false
        }
      },

      productIds: {
        presence(value, attrs) {
          if (_.blank(attrs.serviceIds)) {
            return { message: i18n.t("models.servicePackage.errors.productOrService.invalid") }
          }
          return false
        }
      }
    }
  }
}

export default ServicePackage

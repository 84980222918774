// Libs
import $validate from "@/lib/validate"
import deepSort  from "@/lib/deep-sort"

import { setErrorsToHasManyIndexes } from "@/lib/parse-errors"


const FormMixin = {
  data() {
    return {
      submitting:       false,
      errors:           {},
      nestedAttributes: null,
      resource:         null,

      submitRequestPromise: null,
      submitErred:          false,
      submitError:          {},
      submitted:            false,

      // Informa se o usuário deve ser avisado ao sair da página com dados alterados
      guardFormOnLeave:            true,
      initialSerializedAttributes: null
    }
  },

  async beforeRouteLeave(to, from, next) {
    next(await this.canLeaveForm(to, from))
  },

  methods: {
    serializeAttributes() {
      throw new Error("Form Mixin - #serializeAttributes() - Not implemented yet")
    },

    cancelRunningSubmitRequest({ motive = "Submit duplicado" } = {}) {
      if (_.get(this.submitRequestPromise, "cancel")) this.submitRequestPromise.cancel(motive)
    },

    //
    async validate() {
      return $validate(this.resource, this.nestedAttributes)
    },

    async submit(options) {
      // @hook
      await this.beforeSubmit(options)

      // this.errors = await validate(this.resource, this.nestedAttributes)
      this.errors = await this.validate()

      // @hook
      await this.afterValidation()

      if (_.present(this.errors)) {
        return
      }

      this.$notifications.clear()

      let currentRequestPromise

      try {
        this.cancelRunningSubmitRequest()

        currentRequestPromise = this.submitRequest()
        this.submitRequestPromise = currentRequestPromise

        this.submitting = true

        // @hook
        await this.afterSubmitStart(options)

        const response = await currentRequestPromise

        this.submitted = true

        // @hook
        await this.onSubmitSuccess(response, options)

        this.submitError = {}
        this.submitErred = false

        // @hook
        await this.afterSubmitSuccess(options)
      }
      catch (err) {
        // @hook
        await this.onSubmitError(err, options)
        // @hook
        await this.afterSubmitError(options)
      }
      finally {
        // @hook
        await this.afterSubmit(options)

        if (this.submitRequestPromise === currentRequestPromise) {
          this.submitRequestPromise = null
          this.submitting = false
        }
      }
    },

    submitRequest() {
      throw new Error("Form Mixin - #submitRequest() - Not implemented yet")
    },

    // Hooks
    // ---

    // @hook
    beforeSubmit() {},

    // @hook
    afterValidation() {
      if (_.present(this.errors)) {
        this.$notifications.error(this.$t(".notifications.submit.error"))
      }
    },

    // @hook
    afterSubmitStart() {},

    // @hook
    onSubmitSuccess(response) {
      this.$notifications.info(this.$t(".notifications.submit.success"))
    },

    // @hook
    onSubmitError(err) {
      const error = err.error || err

      if (error.cancelled) return

      this.submitError = error
      this.submitErred = true

      this.$err.log(this.submitError)
      this.$notifications.error(this.$t(".notifications.submit.error"))

      if (error.originalError) this.setFormErrors(error.originalError)
    },

    // @hook
    afterSubmitSuccess() {},

    // @hook
    afterSubmitError() {},

    // @hook
    afterSubmit() {},

    setFormErrors(httpError) {
      if (_.blank(this.resource)) return

      const errData = _.get(httpError, "response.data.errors") || {}
      const model = this.resource.constructor.name
      const errors = this.$i18n.errify(errData, { model })

      this.errors = setErrorsToHasManyIndexes(errors, this.resource)
    },

    // Analisa se formulário foi modificado baseado no estado inicial e final
    // dos dados serializados em `serializedAttributes` e `initialSerializedAttributes`
    checkDirty() {
      if (this.initialSerializedAttributes === null) throw new Error("Form Mixin - #initialSerializedAttributes - Not implemented yet")

      const serializedAttributes = this.serializeAttributes()
      return !_.isEqual(deepSort(serializedAttributes), deepSort(this.initialSerializedAttributes))
    },

    askDirtyLeaveConfirmation() {
      return this.$confirm({
        heading: this.$t("mixins.form.confirmPageChange.heading"),
        message: this.$t("mixins.form.confirmPageChange.message"),

        actions: {
          confirm: this.$t("mixins.form.confirmPageChange.confirm"),
          cancel:  this.$t("mixins.form.confirmPageChange.cancel")
        }
      })
    },

    canLeaveForm(to, from) {
      if (
        !this.guardFormOnLeave
        || to.name === from.name
        || this.submitted        // Após envio dos dados com sucesso
        || this.fetching         // Durante carregamento
        || this.erred            // Após falha de carregamento
      ) return true

      const dirty = this.checkDirty()

      if (!dirty) return true

      return this.askDirtyLeaveConfirmation()
    }
  }
}


export default FormMixin

// TODO: aplicar webpack loader para glob import @ https://www.npmjs.com/package/glob-import-loader

// https://webpack.js.org/guides/dependency-management/#requirecontext
// const modelsContext = require.context(".", false, /\.js/)
// const models = modelsContext.keys().map(filePath => modelsContext(filePath).default) // array of classes

// const map = {}

// function importAll(r) {
//   r.keys().forEach((key) => (cache[key] = r(key)))
// }

// importAll(require.context(".", false, /\.js$/))

import serviceOrdersModels from "./service-orders"


const map = {
  ServiceOrders: serviceOrdersModels
}


export default map

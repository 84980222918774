// Base
import BaseRequests from "../../Base"

// Models
import models from "@/models"
const { ServiceOrderSupplier } = models

class ServiceOrderAvailableSuppliersRequests extends BaseRequests {
  static get selector() {
    return "serviceOrders.availableSuppliers"
  }

  all({ serviceOrderId, params = {}, options = {} } = {}) {
    this.verifyData("all", { serviceOrderId })

    return this.action({
      method:  "get",
      url:     `/service_orders/${serviceOrderId}/draft/available_suppliers`,
      params,
      options: {
        model: ServiceOrderSupplier,

        ...options
      }
    })
  }

  find({ serviceOrderId, supplierId, params = {}, options = {} } = {}) {
    this.verifyData("find", { serviceOrderId, supplierId })

    return this.action({
      method:  "get",
      url:     `/service_orders/${serviceOrderId}/draft/available_suppliers/${supplierId}`,
      params,
      options: {
        model: ServiceOrderSupplier,

        ...options
      }
    })
  }
}

export default ServiceOrderAvailableSuppliersRequests

<script>

import MobiautoVersionsTable from "@/views/vehicle-models/index/mobiauto-versions-table.vue"
import MobiautoVersionsTableHeader from "./mobiauto-versions-table-header.vue"
import MobiautoVersionRow from "./mobiauto-version-row.vue"

export default {
  components: {
    MobiautoVersionsTableHeader,
    MobiautoVersionRow
  },

  // TODO: Remover herança, não é o mesmo modelo!
  extends: MobiautoVersionsTable
}

</script>

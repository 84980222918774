<!--
<doc lang="markdown">
Exibe uma estampa com o valor de `stage` caso não seja produção para rápida diferenciação entre ambientes.
Como usar:
Básico: utilizando valor de `stage` como rótulo e exibindo apenas se o stage não for 'production'.
```pug
  stage-stamp
```
Avançado: mais uma condição para _exibição_ e utilizando um label específico.
```pug
  stage-stamp(v-if="!!stageLabel", :label="stageLabel")
```
</doc>
-->

<style lang="scss" scoped>

.stage-stamp {
  display: inline-block;
  margin: 0 auto;
  padding: 0 12px;
  transform: rotate(-20deg);
  opacity: .7;
  filter: blur(1.3px);
  border-radius: 20px;
  border-width: 6px;
  border-color: $dark-red;
  border-style: solid;
  border-image: var(--stamp-border-image);
  color: $dark-red;
  text-transform: uppercase;
  font-family: 'Courier New', CourierNew, serif;
  font-size: 22px;
  font-weight: bold;
  line-height: normal;
  height: auto;
  white-space: pre;
}

</style>


<template lang="pug">

span.stage-stamp(
  v-if="!isProductionStage",
  :style="cssVars"
) {{ labelText }}

</template>


<script>

import { conf } from "utils.vue"
import stamp from "@/assets/images/grunge-stamp-360x360.png"


export default {
  name:  "AppStageStamp",
  emits: [],

  props: {
    label: { type: String, default: null }
  },

  data() {
    return {
      i18nScope: "components.app-stage-stamp"
    }
  },

  computed: {
    stage() {
      return _.get(conf, "stage")
    },

    labelText() {
      let label = this.label || this.stage || "?"
      return label.toUpperCase()
    },

    isProductionStage() {
      return ["production", "prd", "prod"].includes(this.stage)
    },

    cssVars() {
      return {
        "--stamp-border-image": `url(${stamp}) 30`
      }
    }
  }
}

</script>

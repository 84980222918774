<template lang="pug">

  select-field(
    v-bind="$props",
    v-on="$listeners",
    :options="options"
  )

</template>


<script>

import Vue from "vue"

import SelectField from "@/components/form/select-field"
import models from "@/models"

const { Ticket } = models

export default {
  name: "TicketStateSelectField",

  extends: SelectField,

  data() {
    return {
      i18nScope: "components.ticket.ticket-state-select-field"
    }
  },

  computed: {
    options() {
      return Ticket.$enums.state.values.map(state => ({
        label: Ticket.$tEnum("state", state),
        value: state
      }))
    }
  },

  // Removendo props 'options' que está sendo reescrita como _computed_
  beforeCreate() {
    Vue.delete(this.$options.props, "options")
  }
}

</script>

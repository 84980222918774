// Base
import BaseRequests from "../../Base"

// Models
import models from "@/models"
const { Attachment } = models

class ServiceOrderAttachmentsRequests extends BaseRequests {
  static get selector() {
    return "serviceOrders.drafts.attachments"
  }

  all({ serviceOrderId, params = {}, options = {} } = {}) {
    this.verifyData("all", { serviceOrderId })

    return this.action({
      method:  "get",
      url:     `/service_orders/${serviceOrderId}/draft/attachments`,
      params,
      options: {
        model: Attachment,

        ...options
      }
    })
  }

  find({
    serviceOrderId,
    attachmentId,
    params = {},
    options = {}
  } = {}) {
    this.verifyData("find", { serviceOrderId, attachmentId })

    return this.action({
      method:  "get",
      url:     `/service_orders/${serviceOrderId}/draft/attachments/${attachmentId}`,
      params,
      options: {
        model: Attachment,

        ...options
      }
    })
  }

  create({ serviceOrderId, params = {}, options = {} } = {}) {
    this.verifyData("create", { serviceOrderId })

    return this.action({
      method: "post",
      url:    `/service_orders/${serviceOrderId}/draft/attachments`,
      params,
      options
    })
  }

  update({
    serviceOrderId,
    attachmentId,
    params = {},
    options = {}
  } = {}) {
    this.verifyData("update", { serviceOrderId, attachmentId })

    return this.action({
      method: "patch",
      url:    `/service_orders/${serviceOrderId}/draft/attachments/${attachmentId}`,
      params,
      options
    })
  }

  destroy({
    serviceOrderId,
    attachmentId,
    params = {},
    options = {}
  }) {
    this.verifyData("destroy", { serviceOrderId, attachmentId })

    return this.action({
      method: "delete",
      url:    `/service_orders/${serviceOrderId}/draft/attachments/${attachmentId}`,
      params,
      options
    })
  }
}

export default ServiceOrderAttachmentsRequests

<style lang="scss" scoped>

.modal-body {
  padding: 24px;

  .text {
    white-space: pre-line;
  }
}

</style>


<template lang="pug">

  .disable-action(v-if="$can('destroy', 'MaintenancePackage')")
    app-button(
      :disabled="disabled",
      :loading="submitting",
      @click="hidePopoverMenu(); showModal = true",
      theme="neutral",
      :border="border",
      :size="size",
      data-testid="disableButton"
    )
      slot

    app-modal(
      v-if="showModal",
      :close-button="!submitting",
      :heading="$t('.heading')",
      :width="540",
      :loading="submitting",
      footer,
      :confirm-label="$t('.btn.confirm.label')"
      :confirm-label-loading="$t('.btn.confirm.loading')",
      @close="onCloseModal",
      @cancel="onCloseModal",
      @confirm="submit",
      data-testid="disableModal"
    )
      .modal-body
        span.text {{ $t('.message', { name }) }}

</template>


<script>

export default {
  props: {
    name:     { type: String, default: "" },
    id:       { type: [String, Number], required: true },
    size:     { type: String, default: "small" },
    border:   { type: String, default: "rounded" },
    disabled: { type: Boolean, default: false }
  },

  data() {
    return {
      i18nScope: "maintenance-packages.components.disable-action",

      submitting: false,

      showModal: false
    }
  },

  methods: {
    onCloseModal() {
      if (!this.submitting) {
        this.showModal = false
      }
    },

    hidePopoverMenu() {
      // XXX: usando $parent para conseguir fechar o "popover-menu"!
      this.$parent.$emit("hide")
    },

    async submit() {
      try {
        this.submitting = true
        await this.$newSdk.maintenancePackages.disable({ maintenancePackageId: this.id })

        this.$notifications.info(this.$t(".notifications.submit.success", { name: this.name }))
        this.$emit("disabled")
      }
      catch (error) {
        const errors = _.get(error, "error.originalError.response.data.errors.maintenance_package") || []

        // XXX: Pode já ter sido desabilitado paralelamente por outra pessoa, nesse caso devemos ignorar o erro
        if (errors.includes("discarded")) this.$emit("disabled")
        else {
          this.$err.log(error)
          this.$notifications.error(this.$t(".notifications.submit.error"))
        }
      }
      finally {
        this.submitting = false
        this.showModal = false
      }
    }
  }
}

</script>

<style lang="scss" scoped>
.header {
  margin-bottom: 16px;

  .title {
    font-family: $secondary-font;
    font-weight: 500;
    font-size: 20px;
  }

  .count {
    font-size: 14px;
    font-weight: 400;
  }
}

.file-selector {
  margin-bottom: 16px;

  .drop-zone {
    background-color: $light-gray;
    height: 97px;
    border-radius: 8px;
    border: 2px dashed $light-gray-4;

    .message {
      font-family: $secondary-font;
      font-size: 14px;
      font-weight: 400;
      color: $gray-3;
    }

    .button {
      font-size: 14px;
      margin-top: 8px;

      .icon {
        margin-right: 4px;
      }
    }
  }

  &.dragover {
    .drop-zone {
      border-color: $primary;
      background: $light-orange;

      .message {
        color: $primary;
        font-size: 16px;
        text-transform: uppercase;
      }
    }
  }
}

.attachments {
  gap: 24px;
}
</style>

<template lang="pug">
  .attachments-list
    .header
      h2.title {{ $t(".title") }}

      .count(v-if="!fetching && !erred")
        span {{ $t('.attachmentsCount', { count: attachmentsCount }) }}

    file-selector.file-selector(
      multiple,
      @selected-files="uploadFiles"
    )
      template(#default="{ props: { isDragOver, selectFiles } }")
        .drop-zone.flex.column-direction.center.vertical-center
          template(v-if="isDragOver")
            span.message {{ $t('.dropzone.dragover-message') }}
          template(v-else)
            span.message {{ $t('.dropzone.message') }}
            app-button.button(theme="link", @click="selectFiles")
              i.icon.far.fa-paperclip
              span {{ $t('.btn.add.label') }}

    .attachments.flex.wrap(v-if="!empty")
      template(v-for="attachment in _attachments")
        service-order-attachment-card(
          :attachment="attachment",
          :deletable="editableList && !submitting",
          :editable="editableAttachments && !submitting",
          :service-order-id="serviceOrderId",
          @uploaded="signedId => onAttachmentUploaded(attachment, signedId)",
          @destroy="removeAttachment(attachment)"
        )
</template>

<script>
import Index from "../attachments/index.vue"

export default {
  extends: Index
}
</script>

<template lang="pug">

  .destroy-action(v-if="$can('destroy', 'VehicleModelGroup')")
    app-button(
      :disabled="disabled",
      :loading="deleting",
      @click="hidePopoverMenu(); showModal = true",
      theme="neutral",
      :border="border",
      :size="size",
      data-testid="deleteButton"
    )
      slot

    app-modal(
      v-if="showModal",
      :close-button="!deleting",
      :heading="$t('.heading')",
      :width="540",
      :loading="deleting",
      footer,
      :confirm-label="$t('btn.destroy.label')"
      :confirm-label-loading="$t('btn.destroy.loading')",
      @close="onCloseModal",
      @cancel="onCloseModal",
      @confirm="destroy",
      data-testid="destroyModal"
    )
      .body
        span.text {{ $t('.message', { name }) }}

</template>


<script>

export default {
  props: {
    name:     { type: String, default: "" },
    id:       { type: [String, Number], required: true },
    size:     { type: String, default: "small" },
    border:   { type: String, default: "rounded" },
    disabled: { type: Boolean, default: false }
  },

  data() {
    return {
      i18nScope: "vehicle-model-groups.components.destroy-action",

      deleting: false,

      showModal: false
    }
  },

  methods: {
    onCloseModal() {
      if (!this.deleting) {
        this.showModal = false
      }
    },

    hidePopoverMenu() {
      // XXX: usando $parent para conseguir fechar o "popover-menu"!
      this.$parent.$emit("hide")
    },

    async destroy() {
      try {
        this.deleting = true
        await this.$sdk.vehicleModelGroups.destroy({ id: this.id })

        this.$notifications.info(this.$t(".notifications.destroy.success", { name: this.name }))
        this.$emit("destroy")
      }
      catch (error) {
        this.$err.log(error)
        this.$notifications.error(this.$t(".notifications.destroy.error"))
      }
      finally {
        this.deleting = false
        this.showModal = false
      }
    }
  }
}

</script>

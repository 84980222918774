<style lang="scss" scoped>

.forbidden {
  min-height: 100%;
  width: 100%;
  background: $white;
}

.img {
  margin-top: 10vh;
  margin-bottom: 32px;
  width: 70%;

  @media only screen and (min-width: 1600px) {
    width: 1200px;
  }
}

.button-content {
  display: block;
  width: 200px;
  padding-bottom: 20px;

  .app-button + .app-button {
    margin-top: 16px;
  }
}

</style>


<template lang="pug">

  .forbidden.flex.vertical-center.column-direction
    img.img(:src="illustration")
    .button-content
      app-button.app-button(
        outline,
        size="small",
        full-width,
        @click="goBack",
      ) {{ $t(".btn.goBack") }}

</template>


<script>

// Assets
import illustration from "@/assets/images/403.png"

export default {
  name: "Forbidden",

  data() {
    return {
      i18nScope: "components.forbidden",

      illustration
    }
  },

  methods: {
    goBack() {
      // XXX: Não é possível usar $goBack diretamente no template porque o plugin é instalado após a emissão do evento `app:init`,
      // fazendo com que a função não exista por um momento e o template falhe
      this.$goBack()
    }
  }
}

</script>

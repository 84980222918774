import { mixin } from "@caiena/model"

import Base from "./base"
import Discardable from "./mixins/discardable"

class Supplier extends mixin(Base, [Discardable]) {
  static get attrs() {
    return _.uniq([
      ...super.attrs,
      "name",
      "tradingName",
      "externalId",
      "cnpj",
      "ie",
      "im",
      "ieIsento",
      "imIsento",
      "sapId",
      "hierarchy",
      "headOfficeId",
      "serviceOrderContactName",
      "serviceOrderEmail",
      "roundTheClock",
      "schedulingNotice",
      "classification",
      // 'supplierPhone',

      // Checkbox
      "rac",
      "racMensal",
      "racApp",
      "gtf",
      "seminovos",
      "premium",
      "zeroKm",
      "nationwideSupport",

      // Checkbox
      "internalFacilities",
      "supplierFacilities",
      "internal_facilities",
      "supplier_facilities",


      // Checkbox
      "internalCustomer",
      "externalCustomer",

      // Checkbox
      "pickupService",
      "rabbotPlataform",
      "movidaApp",

      // Read-only
      "blocked",
 

      // BlockState
      "currentBlockId",
      "currentBlockState",
      "currentDealExpiresSoon",
      "currentDealId",
      "nextBlockId",
      "nextBlockState"
    ])
  }

  static get relations() {
    return {
      headOffice:      { type: "belongsTo", model: "Supplier" },
      supportedCities: { type: "hasMany", model: "City" },
      sectors:         { type: "hasMany", model: "Sector" },
      address:         { type: "belongsTo", model: "Address" },
      serviceOrders:   { type: "hasMany", model: "ServiceOrder" },
      blocks:          { type: "hasMany", model: "SupplierBlock" },
      contacts:        { type: "hasMany", model: "Contact" }
    }
  }

  static get enums() {
    return {
      hierarchy: {
        head:   "head",
        branch: "branch"
      },

      classification: {
        CMM: "CMM",
        A:   "A",
        B:   "B",
        C:   "C",
        D:   "D"
      }
    }
  }

  static get constraints() {
    return {
      name:                    { presence: true, type: "string", length: { maximum: 1000 } },
      tradingName:             { presence: true, type: "string", length: { maximum: 1000 } },
      serviceOrderContactName: { presence: true, type: "string", length: { maximum: 1000 } },
      serviceOrderEmail:       { presence: true, type: "string", email: true },
      cnpj:                    { presence: true, type: "string" },
      ie:                      { type: "string", length: { maximum: 100 } },
      im:                      { type: "string", length: { maximum: 100 } },
      hierarchy:               { presence: true, type: "string" },
      sectors:                 { presence: true },
      headOfficeId:            {
        presence(value, attrs) {
          return attrs.hierarchy === "branch"
        }
      }
    }
  }

  get hasDealershipSector() {
    return this.sectors.some(sector => sector.isDealership)
  }
}

export default Supplier

<style lang="scss" scoped>

.phone-kind-select-field {
  .icon {
    width: 16px;
    height: 16px;
    font-size: 16px;
    margin-right: 8px;
  }
}

</style>


<template lang="pug">

  select-field.phone-kind-select-field(
    v-bind="$props",
    v-on="$listeners",
    :placeholder="$t('.placeholder')",
    :options="options"
  )
    template(#selected="{ props: { selected } }")
      .content.flex.vertical-center
        i.icon.fal.fa-tag
        label.label {{ selected.label }}

</template>


<script>

import Vue from "vue"

import SelectField from "@/components/form/select-field"

import models from "@/models"
const { Phone } = models

export default {
  extends: SelectField,

  props: {
    // Mudando comportamento para default ser true
    searchable: { type: Boolean, default: true }
  },

  data() {
    return {
      i18nScope: "suppliers.contacts.components.phone-kind-select-field"
    }
  },

  computed: {
    options() {
      return Phone.$enums.kind.values
        .map(value => ({
          value,
          label: Phone.$tEnum("kind", value)
        }))
    }
  },

  // Removendo props 'options' que está sendo reescrita como _computed_
  beforeCreate() {
    Vue.delete(this.$options.props, "options")
  }
}

</script>

import Base from "./base"

// representa Loja RAC! (integração direta do GMF)
// para _filiais_, use Branch
class Store extends Base {

  static get attrs() {
    return _.uniq([
      ...super.attrs,
      "name",
      "initials",
      "lat",
      "lng",
      "cnpj",
      "email",
      "phone",
      "sapId",

      // Contato
      "fleetManagerName",
      "fleetManagerEmail",

      // Endereço
      "addressLine2"
    ])
  }

  static get relations() {
    return {
      tickets:        { type: "hasMany", model: "Ticket" },
      storeSuppliers: { type: "hasMany", model: "StoreSupplier" },
      address:        { type: "hasOne", model: "Address" },
      settings:       { type: "hasOne", model: "StoreSettings" }
    }
  }
}


export default Store

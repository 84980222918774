<style lang="scss" scoped>

.search-form {
  .form {
    width: 100%;

    .row + .row {
      margin-top: 8px;
    }
  }
}

</style>


<template lang="pug">

  .search-form
    form.form(
      @submit.prevent="$emit('search')",
      method="get",
      data-testid="form"
    )
      ui-row.row(vertical-align="bottom")
        ui-col(:span="8")
          input-field(
            name="search[q]",
            v-model="params.q",
            :loading="loading",
            hide-label,
            :placeholder="$t('.fields.q.placeholder.full')",
            icon-left="far fa-search"
          )

        ui-col(:span="2")
          ticket-type-select-field(
            name="search[type]",
            :label="$t('.fields.type.label')",
            :placeholder="$t('.fields.type.placeholder')",
            :loading="loading",
            v-model="params.type",
            searchable,
            data-testid="typeSelect"
          )

        ui-col(:span="2")
          ticket-state-select-field(
            name="search[state]",
            :label="$t('.fields.state.label')",
            :placeholder="$t('.fields.state.placeholder')",
            :loading="loading",
            v-model="params.state",
            searchable,
            data-testid="stateSelect"
          )

      ui-row.row(vertical-align="bottom")
        ui-col(:span="7")
          supplier-select-field(
            name="search[supplierId]",
            :label="$t('.fields.supplier.label')",
            :placeholder="$t('.fields.supplier.placeholder')",
            :loading="loading",
            v-model="params.supplierId"
          )

        ui-col(:span="2")
          date-field(
            name="search[since]",
            :label="$t('.fields.since.label')",
            v-model="params.since",
            :loading="loading"
          )

        ui-col(:span="2")
          date-field(
            end-of-day,
            name="search[until]",
            :label="$t('.fields.until.label')"
            v-model="params.until",
            :loading="loading"
          )

        ui-col(:span="1")
          app-button(
            type="submit",
            :loading="loading",
            full-width,
            outline
          )
            span {{ $t('btn.search.label') }}
            span(slot="loading") {{ $t('btn.search.loading') }}

</template>


<script>

// Components
import TicketTypeSelectField from "@/views/tickets/index/ticket-type-select-field.vue"

export default {
  components: {
    TicketTypeSelectField
  },

  props: {
    loading: { type: Boolean, default: false },
    params:  { type: Object, default: () => ({}) }
  },

  data() {
    return {
      i18nScope: "stores.tickets.index.search-form"
    }
  }
}

</script>

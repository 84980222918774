<style lang="scss" scoped>

.service-multiple-select-field {
  .error {
    display: block;
    text-align: center;
    padding: 4px 12px;
    font-size: 14px;
    font-style: italic;

    .link {
      margin-left: 5px;
      font-size: 14px;
      font-style: italic;
      font-weight: normal;
    }
  }

  .empty {
    display: block;
    text-align: center;
    padding: 4px 12px;
    font-size: 14px;
    font-style: italic;
  }
}

</style>


<template lang="pug">

  .service-multiple-select-field
    select-field(
      :name="name",
      :label="label",
      :fetching="fetching",
      :placeholder="placeholder",
      :optional="optional",

      option-as-value,
      :loading="loading || fetchingOption",
      :options="options",
      :disabled="disabled",
      :default-filter="false",
      :errors="errors",
      searchable,

      @input="onSelect",

      @search="onSearch",
      @open="_fetch",
      @close="onClose",
      @inputSearch="(newSearch) => search = newSearch"
    )
      template(#menuContentHeader)
        template(v-if="fetchError")
          label.error
            span {{ $t('.error.message') }}
            app-button.link(theme="link", @click="onSearch") {{ $t('.error.link') }}

      template(#menuContentFooter)
        template(v-if="!fetching && !fetchError && empty")
          label.empty {{ $t('.empty') }}

      template(#fetching)
        li.option {{ $t('.loading') }}

      template(#option="{ props: { option, index, focus, active } }")
        service-option(
          :option="option",
          :active="isActiveOption(option)",
          :focus="focus",
        )

</template>


<script>

// Mixins
import FieldMixin from "@/mixins/field"

// Components
import ServiceOption from "./service-option.vue"

// Models
import models from "@/models"
const { Service } = models

export default {
  components: {
    ServiceOption
  },

  mixins: [FieldMixin],

  props: {
    value:    { type: Array, default: () => [] },
    kind:     { type: String, default: null },
    optional: { type: Boolean, default: false }
  },

  data() {
    return {
      i18nScope: "service-packages.components.service-multiple-select-field",

      search:         "",
      options:        [],
      fetchingOption: false,
      fetchError:     false,

      // Async
      fetching: false,
      request:  null
    }
  },

  computed: {
    empty() {
      return this.options.length === 0
    }
  },

  methods: {
    isActiveOption(option) {
      const hasOption = this.value.find(service => service.value === option.value)

      return !!hasOption
    },

    onClose() {
      this.options = []
    },

    onSelect(option) {
      if (!option) return

      let selectedOptions = this.value
      const optionIndex = selectedOptions.findIndex(service => service.value === option.value)

      if (optionIndex < 0) {
        selectedOptions = [...selectedOptions, option]
        this.$emit("input", selectedOptions)
      }
      else this.$emit("toggle-remove", option.data)
    },

    // Busca assincrona de _options_
    async _fetch() {
      let currentRequest

      try {
        if (this.request) this.request.cancel("Duplicated fetch")

        let params = { q: this.search }
        if (_.present(this.kind)) params.kind = this.kind

        currentRequest = this.$sdk.services.list({ params })
        // currentRequest = this.$sdk.services.list({ params: { q: this.search } })
        this.request = currentRequest

        this.fetching = true

        const response = await currentRequest
        let normalizedData = _.camelizeKeys([...response.data])

        this.options = normalizedData.map(data => {
          const service = new Service(data)
          return {
            value: data.id,
            label: service.name,
            data:  service
          }
        })
      }

      catch (error) {
        if (!error.cancelled) this.fetchError = true
        this.$err.log(error)
      }

      finally {
        if (this.request === currentRequest) {
          this.request = null
          this.fetching = false
        }
      }
    },

    // Debounce para evitar sobrecarregar servidor com disparos de busca
    fetch: _.debounce(function debounce() {
      this._fetch()
    }, 500),

    onSearch() {
      this.fetching = true
      this.fetchError = false
      this.fetch()
    }
  }
}

</script>

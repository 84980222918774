import Base from "./base"

class DealStateChangeEvent extends Base {
  static get attrs() {
    return _.uniq([
      ...super.attrs,
      "author",
      "from",
      "to",
      "description"
    ])
  }

  static get constraints() {
    return {
      description: { type: "string", presence: true }
    }
  }
}

export default DealStateChangeEvent

// Base
import BaseRequests from "../Base"

// Models
import models from "@/models"
const { MovidaAssistant } = models

class MonitoringAssistantRequests extends BaseRequests {
  static get selector() {
    return "monitoring.assistants"
  }

  all({ params = {}, options = {} } = {}) {
    return this.action({
      method:  "post",
      url:     `/monitoring/assistants/search`,
      params,
      options: {
        model: MovidaAssistant,

        ...options
      }
    })
  }

  find({ assistantRid, params = {}, options = {} } = {}) {
    this.verifyData("find", { assistantRid })

    return this.action({
      method:  "get",
      url:     `/monitoring/assistants/${assistantRid}`,
      params,
      options: {
        model: MovidaAssistant,

        ...options
      }
    })
  }
}

export default MonitoringAssistantRequests

<style lang="scss" scoped>

.error {
  align-self: flex-end;
  margin-top: 8px;
}

</style>


<template lang="pug">

  app-modal.edit-supplier-contact(
    :width="690",
    :heading="$t('.heading')",
    @close="$emit('close')"
  )
    form.form(@submit.prevent="submit", method="post")
      contact-form-fields(
        :resource="resource",
        :errors="errors"
      )

    template(#footer)
      .footer
        .container.full-width
          .toolbar.flex.space-between
            app-button(
              outline,
              theme="gray",
              @click="cancel"
            ) {{ $t('btn.cancel') }}

            app-button(
              @click="update",
              data-test="updateContact"
            )
              span {{ $t('.btn.update') }}

          .error.text-right(v-if="emailOrPhoneErrors")
            span.movida-common__error-message {{ $t('models.contact.errors.emailOrPhone') }}


</template>


<script>

// Libs
import { v4 as uuid } from "uuid"

// Components
import ContactFormFields from "./form-fields"

// Mixins
import Validatable from "@/mixins/validatable"

// Models
import models from "@/models"
const { Contact, Email, Phone } = models

export default {
  name: "EditSupplierContact",

  components: { ContactFormFields },

  mixins: [Validatable],

  props: {
    contact:      { type: Object, required: true },
    serverErrors: { type: Object, default: () => ({}) }
  },

  data() {
    return {
      i18nScope: "suppliers.contacts.edit",

      resource: this.cloneContact(this.contact),

      nestedAttributes: {
        emails: null,
        phones: null
      },

      uuid
    }
  },

  computed: {
    allErrors() {
      return _.merge(this.errors, this.serverErrors)
    },

    emailOrPhoneErrors() {
      return _.get(this.allErrors, "emailOrPhone") || _.get(this.allErrors, "emails[0]")
    }
  },

  methods: {
    cancel() {
      this.$emit("cancel")
      this.$emit("close")
    },

    cloneContact(contact) {
      const emails = contact.emails.map(email => new Email(_.cloneDeep(email)))
      const phones = contact.phones.map(phone => new Phone(_.cloneDeep(phone)))
      const clone = new Contact({
        ..._.cloneDeep(this.contact),
        emails,
        phones
      })

      return clone
    },

    async update() {
      const resource = new Contact(this.resource)
      resource.emails = resource.emails.filter(
        email => _.present(email.value) || _.present(email.kind) || email.$markedForDestruction
      )
      resource.phones = resource.phones.filter(
        phone => _.present(phone.value) || _.present(phone.kind) || phone.$markedForDestruction
      )

      if (!await this.validate(resource)) return

      Object.keys(resource).forEach(key => {
        // eslint-disable-next-line vue/no-mutating-props
        this.contact[key] = resource[key]
      })
      this.$emit("close")
    }
  }
}

</script>

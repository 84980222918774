import Base from "./base"

class Service extends Base {
  static typeWithoutPrefix(type) {
    return type.replace(/services::/i, "")
  }

  static typeToParam(type) {
    const typeWithoutPrefix = Service.typeWithoutPrefix(type)

    return _.kebabCase(typeWithoutPrefix)
  }

  static parseParamType(paramType) {
    // Lodash não possui _.pascalCase, sendo assim precisamos fazer este tratamento
    const typeWithoutPrefix = _.startCase(_.camelCase(paramType)).replace(/ /g, "")
    return typeWithoutPrefix === "Maintenance" ? typeWithoutPrefix : `Services::${typeWithoutPrefix}`
  }

  static get attrs() {
    return _.uniq([
      ...super.attrs,
      "name",
      "type",
      "kind",
      "description",
      "targetExecutionTime",
      "laborCostLinked",
      "sapId",
      "servicePackageId",
      "vehicleIssueSolutionsCount"
    ])
  }

  static get relations() {
    return {
      servicePackage: { type: "belongsTo", model: "ServicePackage" },
      vehicleIssues:  { type: "hasMany", model: "VehicleIssue" },

      // XXX modelos manyToMany no backend, utilizados como hasOne no frontend pelo comportamento do sistema
      ticketTypeService: { type: "hasOne", model: "TicketTypeService" }
    }
  }

  static get enums() {
    return {
      type: {
        // eslint-disable-next-line quote-props
        "Maintenance":                "Maintenance",
        "Services::Assistance":       "Services::Assistance",
        "Services::Armoring":         "Services::Armoring",
        "Services::Tapestry":         "Services::Tapestry",
        "Services::Locksmith":        "Services::Locksmith",
        "Services::Withdrawal":       "Services::Withdrawal",
        "Services::Documentation":    "Services::Documentation",
        "Services::Electric":         "Services::Electric",
        "Services::AutoBodyAndPaint": "Services::AutoBodyAndPaint",
        "Services::Setup":            "Services::Setup",
        "Services::Mechanic":         "Services::Mechanic",
        "Services::TiresAndWheels":   "Services::TiresAndWheels",
        "Services::Transport":        "Services::Transport",
        "Services::Glass":            "Services::Glass"
      },

      kind: {
        single:    "single",
        composite: "composite"
      }
    }
  }

  static get constraints() {
    return {
      type:  { presence: true },
      kind:  { presence: true },
      sapId: { presence: true, type: "string", length: { minimum: 10, maximum: 10 } },

      name:        { presence: true, type: "string", length: { maximum: 1000 } },
      description: { type: "string", length: { maximum: 10000 } },

      servicePackageId: {
        presence(value, attrs) {
          return attrs.kind === "composite"
        }
      },

      targetExecutionTime: {
        presence:     true,
        numericality: { greaterThan: 0, lessThan: 10000 }
      }
    }
  }

  get typeAsParam() {
    return Service.typeToParam(this.type)
  }

  get typeWithoutPrefix() {
    return Service.typeWithoutPrefix(this.type)
  }

  get isSingle() {
    return this.kind === "single"
  }

  get isComposite() {
    return this.kind === "composite"
  }
}

export default Service

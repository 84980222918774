<style lang="scss" scoped>

.error {
  display: block;
  text-align: center;
  padding: 4px 12px;
  font-size: 14px;
  font-style: italic;

  .link {
    margin-left: 5px;
    font-size: 14px;
    font-style: italic;
    font-weight: normal;
  }
}

.empty {
  display: block;
  text-align: center;
  padding: 4px 12px;
  font-size: 14px;
  font-style: italic;
}

.option {
  min-height: 40px;
  padding: 8px 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid $light-gray-3;
  cursor: pointer;

  .remove-icon-content {
    width: 24px;
    height: 24px;
    line-height: 24px;
    text-align: center;
    border-radius: 50px;
    color: $gray-2;
    transition: all 100ms linear;
    flex-shrink: 0;
  }

  &.active {
    color: $primary;
    background: $light-gray-2;
  }

  &.focus {
    background: $light-gray-2;
    color: $dark-primary-2;

    .remove-icon-content {
      background: $light-gray-3;
      color: $primary;
    }
  }
}

.create {
  font-size: 14px;
  font-weight: bold;
}

.label {
  display: block;
  font-weight: normal;
}

.content {
  overflow: hidden;
}

.description {
  display: block;
  font-size: 12px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

</style>


<template lang="pug">

  .sector-select-field
    select-field(
      :name="name",
      :label="label",
      :fetching="fetching",
      :placeholder="placeholder",
      :optional="optional",
      :hide-label="hideLabel",

      option-as-value,
      :loading="loading || fetchingOption",
      :options="options",
      :disabled="disabled",
      :default-filter="false",
      :errors="errors",
      searchable,

      @input="onSelect",

      @search="onSearch",
      @open="_fetch",
      @close="onClose",
      @inputSearch="(newSearch) => search = newSearch"
    )
      template(#menuContentHeader)
        template(v-if="fetchError")
          label.error
            span {{ $t('.error.message') }}
            app-button.link(theme="link", @click="onSearch") {{ $t('.error.link') }}


      template(#menuContentFooter)
        template(v-if="!fetching && !fetchError && empty")
          label.empty {{ $t('.empty') }}

      template(#fetching)
        li.option {{ $t('.loading') }}

      template(#option="{ props: { option, index, focus, active } }")
        .option(:class="{ focus, active: isActiveOption(option), create: option.id === 'create' }")
          .content
            label.label {{ option.label }}
            label.description {{ option.data.description }}
          .remove-icon-content(v-if="isActiveOption(option) && option.id !== 'create'")
            i.fal.fa-times

    app-modal(
      v-if="showNewModal",
      :heading="$t('.createAction.modalTitle')",
      :width="540",
      @close="showNewModal = false"
    )
      new-sector(
        :params="createParams",
        @create="onCreate",
        @cancel="showNewModal = false",
      )

</template>


<script>

// Local components
import NewSector from "./sector-multiple-select-field/new"

// Mixins
import FieldMixin from "@/mixins/field"

// Models
import models from "@/models"

export default {
  components: { NewSector },

  mixins: [FieldMixin],

  props: {
    value:            { type: [Array], default: () => [] },
    hideCreateOption: { type: Boolean, default: false },
    optional:         { type: Boolean, default: false }
  },

  data() {
    return {
      i18nScope: "suppliers.components.sector-multiple-select-field",

      search:         "",
      options:        [],
      fetchingOption: false,
      fetchError:     false,

      // Data para criar novo recurso
      showNewModal: false,

      // Async
      fetching: false,
      request:  null
    }
  },

  computed: {
    empty() {
      let options = this.options.filter(option => option.id !== "create")

      return options.length === 0
    },

    createParams() {
      return {
        name: this.search
      }
    },

    createOption() {
      return { id: "create", label: this.$t(".createAction.create", { name: this.search }), data: {} }
    }
  },

  methods: {
    hasSearchTextAsOption(options) {
      if (_.blank(this.search)) return false

      for (const option of options) {
        if (option.id === "create") continue

        const formattedOption = option.label.toLowerCase()
        const formattedSearch = this.search.toLowerCase()

        if (formattedOption === formattedSearch) return true
      }

      return false
    },

    onCreate(sector) {
      let option = {
        value: sector.id,
        label: sector.name,
        data:  sector
      }

      this.onSelect(option)
      this.showNewModal = false
    },

    isActiveOption(option) {
      const hasOption = this.value.find(sector => sector.value === option.value)

      return !!hasOption
    },

    onClose() {
      this.options = []
    },

    onSelect(option) {
      if (!option) return

      if (option.id === "create") {
        if (!this.hideCreateOption) {
          this.showNewModal = true
        }

        return
      }

      let selectedOptions = this.value
      const optionIndex = selectedOptions.findIndex(sector => sector.value === option.value)

      if (optionIndex < 0) {
        selectedOptions = [...selectedOptions, option]
      }
      else {
        selectedOptions.splice(optionIndex, 1)
      }

      this.$emit("input", selectedOptions)
    },

    // Busca assincrona de _options_
    async _fetch() {
      let currentRequest

      try {
        if (this.request) this.request.cancel("Duplicated fetch")

        currentRequest = this.$sdk.sectors.list({ params: { q: this.search } })
        this.request = currentRequest

        this.fetching = true

        const response = await currentRequest
        let normalizedData = _.camelizeKeys(response.data)

        const fetchedOptions = normalizedData.map(data => {
          const sector = new models.Sector({ ...data })
          return {
            value: data.id,
            label: sector.name,
            data:  sector
          }
        })

        this.hideCreateOption || _.blank(this.search) || this.hasSearchTextAsOption(fetchedOptions)
          ? this.options = [...fetchedOptions]
          : this.options = [...fetchedOptions, this.createOption]
      }

      catch (error) {
        this.fetchError = true
        this.$err.log(error)
      }

      finally {
        if (this.request === currentRequest) {
          this.request = null
          this.fetching = false
        }
      }
    },

    // Debounce para evitar sobrecarregar servidor com disparos de busca
    fetch: _.debounce(function debounce() {
      this._fetch()
    }, 500),

    onSearch() {
      this.fetching = true
      this.fetchError = false
      this.fetch()
    }
  }
}

</script>

<style scoped lang="scss">

.vehicle-model-group-row {
  display: grid;
  grid-template-columns: 70px 1fr 1fr 120px 64px;

  &.loading {
    .column {
      &.id-column {
        .id-tag {
          color: $gray-2;
        }
      }

      &.actions-column {
        ::v-deep .movida-common__app-button {
          color: $gray-2;

          &:hover {
            color: $orange;
          }
        }
      }

      .text,
      .link ::v-deep .app-span {
        color: $gray-2;
      }
    }
  }

  .column {
    padding: 0 12px;
    border-bottom: 1px solid $light-gray-3;
    height: 48px;
    overflow: hidden;

    &.name-column {
      .discarded-icon {
        margin-right: 6px;
      }
    }

    &.models-count-column {
      .text {
        font-family: $secondary-font;
      }

      .car-icon {
        margin-right: 8px;
        color: $gray-2;
      }
    }

    ::v-deep .movida-common__app-button {
      white-space: unset;
    }

    .button {
      overflow: hidden;
    }

    .text,
    .link ::v-deep .app-span {
      font-family: $primary-font;
      font-weight: 400;
      font-size: 14px;
      color: $dark-gray;
      text-align: initial;
    }

    .link ::v-deep .app-span:hover {
      color: $orange;
    }
  }
}

</style>


<template lang="pug">

  .vehicle-model-group-row(:class="{ loading }")
    .column.id-column.flex.vertical-center
      id-tag.id-tag(
        :value="vehicleModelGroup.id",
        data-testid="vehicleModelGroupId"
      )

    .column.name-column.flex.vertical-center
      discarded-icon.discarded-icon(v-if="vehicleModelGroup.$discarded")
      app-button.button(
        theme="link",
        :to="showRoute"
      )
        app-span.link(
          :value="vehicleModelGroup.name",
          crop,
          data-testid="vehicleModelGroupName"
        )

    .column.description-column.flex.vertical-center
      app-button.button(
        theme="link",
        :to="showRoute"
      )
        app-span.link(
          :value="vehicleModelGroup.description",
          crop,
          data-testid="vehicleModelGroupDescription"
        )

    .column.models-count-column.flex.vertical-center
      i.car-icon.fal.fa-car
      app-span.text(
        :value="vehicleModelGroup.vehicleModelsCount",
        data-testid="vehicleModelsCount"
      )

    .column.actions-column.flex.vertical-center
      .actions-column
        popover-menu(
          v-if="popoverMenuAuthorization",
          :show.sync="showPopover",
          data-testid="popoverMenu"
        )
          popover-menu-button(
            v-if="$can('read', vehicleModelGroup)",
            icon="fal fa-eye",
            :to="showRoute",
            :text="$t('.actions.btn.show.label')",
            @close-menu="showPopover = false"
          )

          popover-menu-button(
            v-if="$can('update', vehicleModelGroup)",
            icon="fal fa-pencil",
            :to="editRoute",
            :text="$t('.actions.btn.edit.label')",
            @close-menu="showPopover = false"
          )

          template(v-if="vehicleModelGroup.$discarded")
            enable-action.popover-menu-button(
              size="normal",
              border="square",
              :name="vehicleModelGroup.name",
              :id="vehicleModelGroup.id"
              @click.native="showPopover = false"
              @enabled="$emit('enabled')"
            )
              .icon-content
                i.icon.fal.fa-check-circle
              span {{ $t('.actions.btn.enable.label') }}

          template(v-else)
            disable-action.popover-menu-button(
              size="normal",
              border="square",
              :name="vehicleModelGroup.name",
              :id="vehicleModelGroup.id"
              @click.native="showPopover = false"
              @disabled="$emit('disabled')"
            )
              .icon-content
                i.icon.fal.fa-lock
              span {{ $t('.actions.btn.disable.label') }}

          destroy-action.destroy-button.popover-menu-button(
            size="normal",
            border="square",
            :name="vehicleModelGroup.name",
            :id="vehicleModelGroup.id"
            @click.native="showPopover = false"
            @destroy="$emit('destroy')"
          )
            .icon-content
              i.icon.fal.fa-trash-alt
            span {{ $t('.actions.btn.destroy.label') }}

</template>


<script>

import DestroyAction from "../components/destroy-action"
import DisableAction from "../components/disable-action"
import EnableAction from "../components/enable-action"

export default {
  components: {
    DestroyAction,
    DisableAction,
    EnableAction
  },

  props: {
    vehicleModelGroup: { type: Object, required: true },
    loading:           { type: Boolean, default: false }
  },

  data() {
    return {
      i18nScope: "vehicle-model-groups.index.vehicle-model-group-row",

      showPopover: false
    }
  },

  computed: {
    popoverMenuAuthorization() {
      return ["read", "update"].some(action => this.$can(action, this.vehicleModelGroup)) || this.$can("destroy", "VehicleModelGroup")
    },

    showRoute() {
      if (!this.$can("read", "VehicleModel")) return null

      return {
        name:   "vehicleModelGroup",
        params: {
          id: this.vehicleModelGroup.id
        }
      }
    },

    editRoute() {
      return {
        name:   "editVehicleModelGroup",
        params: {
          id: this.vehicleModelGroup.id
        }
      }
    }
  }
}

</script>

<style scoped lang="scss">

.subtitle {
  font-weight: bold;
  font-size: 20px;
  margin-bottom: 24px;
  font-family: $secondary-font;
  font-weight: 500;
}

.identification-section,
.labor-section,
.products-section {
  border-bottom: 1px solid $light-gray-3;
  padding: 24px 0;
}

.row:not(:first-of-type) {
  padding-top: 24px;
}

.table {
  margin-top: 24px;
}

</style>


<template lang="pug">

  .form-fields
    section.identification-section
      h2.subtitle {{ $t('.sections.identification.subtitle') }}

      loading-lines(v-if="fetching", :lines="4")
      template(v-else)
        ui-row.row
          ui-col(:span="12")
            input-field(
              name="maintenancePackage[name]",
              v-model="resource.name",
              :loading="submitting",
              :errors="errors.name",
              :placeholder="$t('.fields.name.placeholder')",
              data-test="nameInput"
            )

        ui-row.row
          ui-col(:span="12")
            textarea-field(
              name="maintenancePackage[description]",
              v-model="resource.description",
              :loading="submitting",
              :errors="errors.description",
              :placeholder="$t('.fields.description.placeholder')",
              optional
            )

          ui-col(:span="1")

    section.products-section
      h2.subtitle {{ $t('.sections.products.subtitle') }}

      product-multiple-select-field(
        name="maintenancePackage[products]",
        :label="$t('.fields.products.label')",
        :placeholder="$t('.fields.products.placeholder')",
        :loading="submitting",
        :disabled="fetching",
        :errors="errors.products",
        v-model="products",
        data-test="productsInput"
      )

      products-table.table(
        :products="resource.products",
        :loading="fetching",
        @remove="onRemoveProduct",
        removable,
        :show-links="false",
        data-test="table"
      )

</template>


<script>

// Components
import ProductsTable from "./products/products-table"

// Models
import models from "@/models"

export default {
  components: {
    ProductsTable
  },

  props: {
    fetching:   { type: Boolean, default: false },
    submitting: { type: Boolean, default: false },
    errors:     { type: Object, default: () => ({}) },
    resource:   { type: Object, default: () => ({}) }
  },

  data() {
    return {
      i18nScope: "maintenance-packages.forms.form-fields"
    }
  },

  computed: {
    products: {
      get() {
        if (_.blank(this.resource)) return []

        return this.resource.products.map(product => ({
          value: product.id,
          label: product.productName,
          data:  new models[product.type](product)
        }))
      },

      set(products) {
        // eslint-disable-next-line vue/no-mutating-props
        this.resource.products = products.map(product => product.data)
      }
    }
  },

  methods: {
    onRemoveProduct(removedProduct) {
      const products = [...this.resource.products]
      const productIndex = products.findIndex(product => product.id === removedProduct.id)

      if (productIndex < 0) return

      products.splice(productIndex, 1)
      // eslint-disable-next-line vue/no-mutating-props
      this.resource.products = products
    }
  }
}

</script>

import Base from "./base"

class Deal extends Base {
  static get attrs() {
    return _.uniq([
      ...super.attrs,
      "state",
      "startAt",
      "duration",
      "mechanicLaborPrice",
      "parentId",
      "amendmentId",
      "supplierId",

      // virtuals
      "expiresSoon"
    ])
  }

  // TODO: mover atributo `expiresSoon` para virtual!
  // para isso, model.js precisa definir getter/setter para virtuals também!
  // static get virtuals() {
  //   return [
  //     'expiresSoon'
  //   ]
  // }

  static get relations() {
    return {
      parent:                  { type: "belongsTo", model: "Deal" }, // optional
      supplier:                { type: "belongsTo", model: "Supplier" },
      dealProducts:            { type: "hasMany",   model: "DealProduct" },
      dealMaintenancePackages: { type: "hasMany",   model: "DealMaintenancePackage" },
      lastStateChangeEvent:    { type: "hasOne",    model: "DealStateChangeEvent" }
    }
  }

  static get enums() {
    return {
      state: {
        draft:    "draft",
        ongoing:  "ongoing",
        done:     "done",
        amended:  "amended",
        canceled: "canceled"
      }
    }
  }

  static get constraints() {
    return {
      startAt: {
        presence(value, instance) {
          return instance.state === "ongoing"
        }
      },

      duration: {
        presence(value, instance) {
          return instance.state === "ongoing"
        },

        numericality: {
          greaterThan: 0
        }
      }
    }
  }

  get isDraft() {
    return this.state === "draft"
  }

  get isOngoing() {
    return this.state === "ongoing"
  }

  get isAmended() {
    return this.state === "amended"
  }

  get isCanceled() {
    return this.state === "canceled"
  }

  get isDone() {
    return this.state === "done"
  }

  get fromAmendment() {
    return _.present(this.parentId)
  }

  get hasExpirationData() {
    return _.present(this.startAt) && _.present(this.duration)
  }

  get expirationDate() {
    if (!this.hasExpirationData) return null
    return moment(this.startAt, "YYYY-MM-DD").add(this.duration, "months")
  }

  get hasExpired() {
    if (!this.hasExpirationData) return null
    return this.expirationDate.isBefore(moment())
  }
}

export default Deal

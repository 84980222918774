// Base
import Role from "./role"

// Models
import models from "@/models"
const { Ticket, ServiceOrder, VehicleModel, Product } = models

class FleetAssistantRole extends Role {
  static build(user, options = {}) {
    return this.define((can, cannot) => {
      // Pode visualizar os veículos estejam nas Lojas em que trabalha
      can(["index"], "Vehicle")
      can(["read"], "Vehicle", vehicle => user.storeIds.includes(vehicle.currentStoreId))

      // Pode criar e visualizar Atendimentos onde os veículos estejam nas Lojas em que trabalha
      Ticket.classNames.forEach(className => {
        can(["index"], className)
        can(["read", "create", "update", "destroy"], className, ticket => user.storeIds.includes(ticket.storeId))
      })

      // Pode visualizar Ordens de Serviços onde os veículos estejam nas Lojas em que trabalha
      ServiceOrder.classNames.forEach(className => {
        can(["index"], className)
        can(["read", "update", "destroy"], className, serviceOrder => user.storeIds.includes(serviceOrder.storeId))
      })

      // Pode visualizar as Lojas em que trabalha
      can(["index"], "Store")
      can(["read"], "Store", store => user.storeIds.includes(store.id))

      can(["index", "read"], "Supplier")

      Product.classNames.forEach(className => can(["index", "read"], className))

      VehicleModel.classNames.forEach(className => can(["index", "read"], className))

      can(["index", "read"], "VehicleModelGroup")
      can(["index", "read"], "VehicleModelGroupMaintenanceTable")
      can(["index", "read"], "MaintenancePackage")
      can(["index", "read"], "Service")
      can(["index", "read"], "ServicePackage")
      can(["index", "read"], "VehiclePart")
    })
  }
}

export default FleetAssistantRole

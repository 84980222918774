import Base from "./base"

class mobiautoManufacturer extends Base {
  static get attrs() {
    return _.uniq([
      ...super.attrs,
      "id",
      "name"
    ])
  }
}

class mobiautoModel extends Base {
  static get attrs() {
    return _.uniq([
      ...super.attrs,
      "id",
      "name"
    ])
  }

  static get relations() {
    return {
      manufacturer: { type: "belongsTo", model: mobiautoManufacturer }
    }
  }
}

class MobiautoVersion extends Base {
  static get attrs() {
    return _.uniq([
      ...super.attrs,
      "fipeId",
      "name",
      "initialYear",
      "finalYear",
      "emptyVehicleModelGroup",
      "afterAutomaticVehicleModelCreation"
    ])
  }

  static get relations() {
    return {
      vehicleModelGroup: {type: "hasOne", model: "VehicleModelGroup"},
      vehicleModels: { type: "hasMany", model: "VehicleModel" },
      model:         { type: "belongsTo", model: mobiautoModel }
    }
  }

  get modelName() {
    return _.get(this.model, "name")
  }

  get manufacturerName() {
    return _.get(this.model, "manufacturer.name")
  }

  get displayName() {
    return `${this.manufacturerName}, ${this.modelName} ${this.name}`
  }

  get optionText() {
    return `${this.manufacturerName} ${this.modelName} ${this.name}`
  }
}

export default MobiautoVersion

<script>

import ServiceOrdersTable from "@/views/service-orders/index/service-orders-table.vue"
import ServiceOrderRow from "./service-order-row.vue"
import ServiceOrderRowSkeleton from "./service-order-row-skeleton.vue"
import ServiceOrdersTableHeader from "./service-orders-table-header.vue"

export default {
  components: {
    ServiceOrderRow,
    ServiceOrderRowSkeleton,
    ServiceOrdersTableHeader
  },

  extends: ServiceOrdersTable,

  data() {
    return {
      i18nScope: "stores.service-orders.index.service-orders-table"
    }
  }
}

</script>

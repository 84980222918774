import { i18n } from "@caiena/i18n"

const NESTED_REGEX = /Attributes$/

// From https://stackoverflow.com/a/46663081
// TODO Extrair para @caiena/lodash-ext como isObject()
function isObject(obj) {
  return obj instanceof Object && obj.constructor === Object
}

// Atribui erros de modelos aninhados (hasMany) na posição (index) marcado no frontend
// Caso seja um recurso já gravado no banco de dados, index === recurso.id. Caso seja um recurso
// novo index === recurso.tempId (uuid gerado pelo frontend)
function setErrorsToHasManyIndexes(errors, resource) {
  const newErrors = {}

  _.forEach(errors, async (attrErrors, attrName) => {
    if (NESTED_REGEX.test(attrName)) {
      const attrNameKey = attrName.replace(NESTED_REGEX, "")

      if (Array.isArray(attrErrors)) {
        newErrors[attrName] = {}

        attrErrors.forEach((err, index) => {
          const nestedResource = resource[attrNameKey][index]
          if (_.present(nestedResource)) {
            let position = nestedResource.id || nestedResource.tempId

            let parsedErrors = setErrorsToHasManyIndexes(err, nestedResource)
            newErrors[attrName][position] = parsedErrors
          }
        })
      }
      else if (isObject(attrErrors)) {
        newErrors[attrName] = setErrorsToHasManyIndexes(
          attrErrors,
          resource[attrNameKey]
        )
      }
    }
    else {
      newErrors[attrName] = attrErrors
    }
  })

  return newErrors
}

const HAS_MANY_REGEX = /\[[0-9]+\]/g

// Transforma os erros oriundos do servidor em traduções agrupadas por campo, tratando
// campos aninhados
function parseError(attributes, errors, model) {
  let messages = {}
  let isNestedField = attributes.length > 1

  if (isNestedField) {
    let [nestedModel, ...attrs] = attributes
    let errorMessages = parseError(attrs, errors, nestedModel)

    if (HAS_MANY_REGEX.test(nestedModel)) {
      const position = parseInt(nestedModel.replace(/[^0-9]/g, ""), 10)
      const modelWithoutIndex = nestedModel.replace(HAS_MANY_REGEX, "")
      const modelName = _.camelCase(`${modelWithoutIndex}Attributes`)

      messages[modelName] = []
      messages[modelName][position] = errorMessages
    }
    else {
      const modelName = _.camelCase(`${nestedModel}Attributes`)
      messages[modelName] = errorMessages
    }
  }
  else {
    const attribute = _.camelCase(attributes[0])

    const translatedErrors = errors.map(error => {
      const { code, ...options } = error
      return i18nError(_.camelCase(code), attribute, model, options)
    })
    messages = ({ [attribute]: translatedErrors })
  }

  return messages
}

// Normaliza dados e constrói array de erros traduzidos
function parseServerErrors(errorData, { model } = {}) {
  let messages = {}

  Object.keys(errorData).forEach(attribute => {
    // Normalizando erros para objetos
    let errors = errorData[attribute]
      .map(error => {
        if (!isObject(error)) return { code: error }
        return error
      })
    errors = _.uniqWith(errors, _.isEqual)

    const attributes = attribute.split(/\./)

    let error = parseError(attributes, errors, _.camelCase(model))
    _.merge(messages, error)
  })

  return messages
}

/**
 * Traduz mensagens de erro baseada em seu código e possível escopo
 *
 * @param  {String} code      Código de erro a ser traduzido.
 * @param  {String} attribute Nome do atributo que gerou o erro.
 * @param  {String} model     Nome do model que gerou o erro.
 * @param  {String} options   Parâmetros do erro.
 * @return {String}           Mensagem de erro traduzida.
 */
function i18nError(code, attribute, model, options = {}) {
  let scope = "errors"
  if (attribute) scope += `.${attribute}`
  if (model) scope = `models.${model}.${scope}`

  return i18n.t(`${scope}.${code}`, _.merge({ defaults: [{ scope: `errors.${code}` }] }, options))
}


export {
  setErrorsToHasManyIndexes,
  parseServerErrors,
  i18nError
}

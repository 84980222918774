// Ability
import { ability } from "@/modules/ability"

// Routes
import Vehicle from "@/views/vehicles/show.vue"
import Vehicles from "@/views/vehicles/index.vue"

export default [
  {
    path:      "/vehicles/:id",
    name:      "vehicle",
    component: Vehicle,
    meta:      {
      auth:        true,
      permissions: [() => ability.can("read", "Vehicle")]
    }
  },

  {
    path:      "/vehicles",
    name:      "vehicles",
    component: Vehicles,
    meta:      {
      auth:        true,
      permissions: [() => ability.can("index", "Vehicle")]
    }
  }
]

<style scoped lang="scss">

.header {
  margin-top: 24px;
  padding-bottom: 20px;
}

.title-text {
  font-size: 30px;
  font-weight: normal;
  font-weight: 500;
  font-family: $secondary-font;
  margin-right: 8px;
}

.icon {
  margin-right: 8px;
}

.back-btn {
  font-size: 18px;
  font-weight: 300;
  color: $gray-3;

  &:hover {
    color: $gray-2;
  }
}

.state-info,
.breadcrumbs {
  margin-bottom: 14px;
}

.page-content {
  margin-top: 24px;
}

.cancel-btn {
  margin-right: 24px;
}

.app-button.amend-btn {
  color: $primary;
}

.diamond-icon {
  margin: 0 8px;
  font-size: 4px;
  color: $gray-3;
}

.amendment-info {
  .message,
  .link {
    font-size: 14px;
    font-weight: 400;
  }

  .message {
    font-weight: 400;
    margin-right: 4px;
  }
}

</style>


<template lang="pug">

  .supplier-deal-show
    loading-lines.full-width(
      v-if="fetching",
      :lines="4"
    )
    template(v-else)
      header.header.flex.space-between.vertical-bottom
        .title
          breadcrumbs(:links="breadcrumbLinks")
          .state-info.flex.vertical-center
            deal-state(:value="deal.state")
            .flex(v-if="deal.fromAmendment")
              i.diamond-icon.spacer.fa.fa-diamond.flex.center.vertical-center
              .amendment-info
                span.message {{ this.$t('.amendmentFrom') }}
                app-button.link(
                  theme="link",
                  :to="{ name: 'supplierDeal', params: { id: this.supplierId, dealId: deal.parentId } }"
                ) {{ $t('.deal', { dealId: deal.parentId }) }}

          .flex.vertical-center
            h1.title-text {{ $t('.title') }}
            id-tag(size="big", :value="dealId")

        template(v-if="canPublishDraft")
          publish-draft.publish-button(
            :id="deal.id",
            @published="fetch",
            @errors="err => errors = err"
          )
            .icon-content
              i.icon.fas.fa-paper-plane
            span {{ $t('.actions.btn.publishDraft') }}

        template(v-if="canPublishAmendment")
          publish-amendment.publish-button(
            :id="deal.id",
            @published="fetch",
            @errors="err => errors = err"
          )
            .icon-content
              i.icon.fas.fa-paper-plane
            span {{ $t('.actions.btn.publishAmendment') }}

        .actions.flex.end(v-if="deal.isOngoing")
          cancel-deal.cancel-btn(
            :id="deal.id",
            @canceled="fetch",
            @errors="err => errors = err"
          )
            i.far.fa-file-times.icon
            span {{ $t('.actions.btn.cancel') }}

          app-button.btn.app-button.amend-btn(
            @click="amend"
            theme="neutral",
            size="small"
          )
            i.far.fa-paste.icon
            span {{ $t('.actions.btn.amend') }}

      .g-tab-navbar-content
        router-tab(
          :error="hasInformationErrors",
          :to="{ name: 'supplierDealInformation', params: { id: supplierId, dealId } }",
          :active="isInformationTab"
        ) {{ $t('.tab.info') }}

        router-tab(
          :error="hasProductsErrors",
          :to="productsRoute",
          :active="isProductsTab"
        ) {{ $t('.tab.products') }}

        router-tab(
          :error="hasMaintenancePackagesErrors",
          :to="maintenancePackagesRoute",
          :active="isMaintenancePackagesTab"
        ) {{ $t('.tab.maintenancePackages') }}

      .page-content
        router-view(
          :deal="deal",
          :errors="errors",
          :fetching="fetching",
          :editable="deal.isDraft"
        )

</template>


<script>

// View
import LoadedView from "@/views/loaded-view"

// Mixins
import FetchMixin from "@/mixins/fetch-mixin"

// Components
import DealState from "./components/deal-state"
import PublishDraft from "./publication/draft/new"
import PublishAmendment from "./publication/amendment/new"
import CancelDeal from "./cancellation/new"

export default {
  name: "SupplierDeal",

  components: {
    DealState,
    PublishDraft,
    PublishAmendment,
    CancelDeal
  },

  extends: LoadedView,

  mixins: [FetchMixin],

  data() {
    return {
      i18nScope: "suppliers.deals.show",
      routeName: "supplierDeal",

      supplierId: null,
      dealId:     null,

      errors: {},

      resource: {},

      submitRequest: null,
      submitting:    false
    }
  },

  computed: {
    deal: {
      get() { return this.resource },

      set(value) { this.resource = value }
    },

    hasProductsErrors() {
      return _.present(_.get(this.errors, "dealProducts"))
    },

    hasMaintenancePackagesErrors() {
      return _.present(_.get(this.errors, "dealMaintenancePackages"))
    },

    hasInformationErrors() {
      return _.present(_.get(this.errors, "duration")) || _.present(_.get(this.errors, "startAt"))
    },

    canPublishDraft() {
      return this.deal.isDraft && !this.deal.fromAmendment
    },

    canPublishAmendment() {
      return this.deal.isDraft && this.deal.fromAmendment
    },

    breadcrumbLinks() {
      return [
        {
          text: this.$t(".breadcrumbLinks.index"),
          to:   { name: "supplierDeals", params: { id: this.supplierId } }
        },
        {
          text: this.$t(".breadcrumbLinks.deal", { dealId: this.dealId }),
          to:   { name: "supplierDeal", params: { id: this.supplierId, dealId: this.dealId } }
        }
      ]
    },

    informationRoute() {
      return { name: "supplierDealInformation", params: { id: this.supplierId, dealId: this.dealId } }
    },

    productsRoute() {
      return { name: "supplierDealProducts", params: { id: this.supplierId, dealId: this.dealId } }
    },

    maintenancePackagesRoute() {
      return { name: "supplierDealMaintenancePackages", params: { id: this.supplierId, dealId: this.dealId } }
    },

    isInformationTab() {
      const resolved = this.$router.resolve(this.informationRoute)
      return this.$route.path.startsWith(resolved.href)
    },

    isProductsTab() {
      const resolved = this.$router.resolve(this.productsRoute)
      return this.$route.path.startsWith(resolved.href)
    },

    isMaintenancePackagesTab() {
      const resolved = this.$router.resolve(this.maintenancePackagesRoute)
      return this.$route.path.startsWith(resolved.href)
    }
  },

  methods: {
    parseRoute() {
      this.supplierId = this.$params.asInteger(this.$route.params.id)
      this.dealId = this.$params.asInteger(this.$route.params.dealId)
    },

    // @override Fetch Mixin
    fetchRequest() {
      return this.$newSdk.suppliers.findDeal({
        supplierId: this.supplierId,
        dealId:     this.dealId
      })
    },

    async amend() {
      this.$notifications.clear()
      this.submitting = true

      let currentRequest

      try {
        if (this.submitRequest) this.submitRequest.cancel("Duplicated submit")
        currentRequest = this.$sdk.suppliers.amendDeal({ dealId: this.dealId })
        this.submitRequest = currentRequest

        const { data } = await currentRequest
        const dealId = data.id

        this.$router.push({ name: "supplierDeal", params: { id: this.supplierId, dealId } })
      }
      catch (err) {
        if (!err.cancelled) {
          this.$err.log(err)

          const errData = _.get(err, "originalError.response.data.errors") || {}
          const alreadyAmended = Array.isArray(errData.parent_id) && errData.parent_id.includes("taken")
          const notification = alreadyAmended ? "taken" : "default"

          this.$notifications.error(this.$t(`.notifications.submit.error.${notification}`, { id: this.dealId }))
        }
      }
      finally {
        if (this.submitRequest === currentRequest) {
          this.submitRequest = null
          this.submitting = false
        }
      }
    }
  }
}

</script>

import Base from "./base"

class Email extends Base {
  static get attrs() {
    return _.uniq([
      ...super.attrs,
      "value",
      "kind",

      // Nested
      // TODO achar uma solução para parâmetros de construtor virtuais
      "tempId"
    ])
  }

  static get enums() {
    return {
      kind: { home: "home", work: "work", other: "other" }
    }
  }

  static get constraints() {
    return {
      value: { presence: true, type: "string", email: true }
    }
  }
}

export default Email

<style lang="scss" scoped>

.supplier-card {
  background-color: $light-gray;
  border-radius: 8px;
  padding: 16px;

  .name {
    font-family: $secondary-font;
    font-weight: 500;
    font-size: 18px;
  }

  .diamond-icon {
    font-size: 4px;
    color: $gray-3;
    margin: 0 8px;
  }

  .cnpj {
    font-family: $primary-monospace-font;
    font-weight: 400;
    color: $gray-3;
  }

  .icon-container {
    height: 16px;
    width: 16px;
    margin-right: 8px;

    .icon {
      color: $gray-2;
      font-size: 12px;
    }
  }

  .text {
    font-size: 14px;
  }

  .copy {
    margin-left: 8px;
    font-weight: 400;
    color: $orange;
  }
}

</style>


<template lang="pug">

  .supplier-card
    .flex.vertical-center
      h2.name {{ supplier.tradingName }}
      i.diamond-icon.fas.fa-diamond
      span.cnpj {{ cnpj }}

    .row.flex.vertical-center
      .icon-container.flex.center.vertical-center
        i.icon.fas.fa-house-user
      app-span.text(crop, :value="supplier.fullAddress")

    .row.flex.vertical-center
      .icon-container.flex.center.vertical-center
        i.icon.fas.fa-envelope
      template(v-if="supplier.contactName")
        app-span.text(crop, :value="supplier.contactName")
        i.diamond-icon.fas.fa-diamond
      app-span.text(crop, :value="supplier.email")
      copy-icon.copy(:copy-value="supplier.email")

</template>


<script>

import * as CNPJ from "@fnando/cnpj"


export default {
  props: {
    supplier: { type: Object, required: true }
  },

  data() {
    return {
      i18nScope: "monitoring.quotes.supplier-card"
    }
  },

  computed: {
    cnpj() {
      return CNPJ.format(this.supplier.cnpj)
    }
  }
}

</script>

<style lang="scss" scoped>

.popover {
  .popover-title {
    cursor: pointer;

    .product-icon {
      margin-right: 8px;
      color: $gray-2;
    }
  }

  .maintenance-package-preview {
    padding: 16px;
    max-width: 450px;

    .title {
      .maintenance-package {
        margin-right: 4px;
        overflow: hidden;
      }
    }

    .products {
      .loading {
        margin-top: 8px;

        ::v-deep .loading-line + .loading-line {
          margin-top: 4px;
        }
      }

      .reload {
        margin-top: 20px;
      }

      .product-row {
        margin-top: 8px;

        .icon {
          margin-right: 16px;
          height: 20px;
        }

        .name {
          font-size: 14px;
          margin-right: 4px;
        }
      }
    }
  }
}

</style>


<template lang="pug">

  .popover
    v-popover(
      trigger="hover",
      :delay="{ show: 300, hide: 300 }",
      :auto-hide="false",
      @apply-show="verifyAndFetch"
    )
      slot
        .popover-title
          i.product-icon.fal.fa-cube
          app-span.maintenance-package(
            :value="productsCount",
            data-testid="productsCount"
          )

      template(slot="popover")
        .maintenance-package-preview(data-testid="maintenancePackagePreview")
          .title.flex
            app-button.maintenance-package(
              theme="link",
              :to="maintenancePackageRoute"
            )
              app-span(
                :value="name",
                crop,
                data-testid="maintenancePackageName"
              )

            id-tag(
              :value="id",
              data-testid="maintenancePackageId"
            )

          .products
            template(v-if="fetching")
              loading-lines.loading(:min="100", :max="100", :lines="4")

            template(v-else-if="erred")
              popover-reload.reload(@reload="fetch")

            template(v-else)
              template(v-for="product in products")
                .product-row.flex.vertical-center(data-testid="product")
                  product-type-icon.icon(:type="product.type", :size="16")
                  app-span.name(
                    :value="product.name",
                    data-testid="productName"
                  )
                  id-tag(
                    :value="product.id",
                    data-testid="productId"
                  )

</template>


<script>

// Mixins
import FetchMixin from "@/mixins/fetch-mixin"

export default {
  mixins: [FetchMixin],

  props: {
    id:            { type: [Number, String], required: true },
    productsCount: { type: Number, required: true },
    name:          { type: String, required: true }
  },

  data() {
    return {
      i18nScope: "maintenance-packages.components.maintenance-package-preview",

      autofetch: false,
      resource:  {}
    }
  },

  computed: {
    products: {
      get() { return this.resource },

      set(value) { this.resource = value }
    },

    maintenancePackageRoute() {
      return {
        name:   "maintenancePackage",
        params: { id: this.id }
      }
    }
  },

  methods: {
    verifyAndFetch() {
      if (_.present(this.resource)) return
      this.fetch()
    },

    fetchRequest() {
      return this.$newSdk.maintenancePackages.find({ maintenancePackageId: this.id })
    },

    onFetchSuccess({ data }) {
      this.resource = data.products
    }
  }
}

</script>

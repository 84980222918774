<!--
<doc lang="markdown">

Tabela de referência para índices de carga de pneu.
Componente para exibição de dados, sem nenhuma prop, projetado com tamanho limitado,
pensando em popover ou modal.

Está em uso nos _fields_ de índice de carga de pneu, como "tooltip" (popover).


Uso em popover
---

```pug

v-popover(...)
  app-element(...)

  template(#popover)
    tire-load-index-popover-content

```

</doc>
-->


<style lang="scss" scoped>

.tire-load-index-popover-content {
  padding: 14px;

  .title {
    display: block;
    padding: 0px 12px;
    padding-bottom: 8px;

    margin: 0 12px;
    margin-bottom: 8px;
    border-bottom: 1px solid $light-gray-3;

    font-family: $secondary-font;
    font-size:   18px;
    font-weight: 500;
    color: $dark-gray;
  }

  .reference-table {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;

    width: 600px;
    max-height: 230px;
    overflow-x: auto;

    padding: 4px 20px;

    font-size: 14px;

    .item {
      padding: 2px 4px;
      min-width: 114px;
    }

    .value {
      font-family: $primary-monospace-font;
      font-weight: 500;
      padding-right: 4px;
    }

    .label {
      font-family: $primary-font;
      font-weight: 300;
    }
  }
}

</style>


<template lang="pug">

  .tire-load-index-popover-content
    label.title {{ $t(".title") }}

    .reference-table
      .item(v-for="(label, index) in indices")
        span.value {{ index }}:
        span.label {{ label }}

</template>


<script>

import LoadIndices from "@/models/tire/enums/load-indices"

export default {
  name: "TireLoadIndexPopoverContent",

  data() {
    return {
      i18nScope: "components.tire.tire-load-index-popover-content"
    }
  },

  computed: {
    indices() {
      return _.reduce(LoadIndices.keys, (result, key) => {
        let label = this.$t(".reference-table.item.label", {
          value: LoadIndices.kgFor(key)
        })
        result[key] = label

        return result
      }, {})
    }
  }
}

</script>

<template lang="pug">

  .products-table-header(:class="{ loading }")
    .column.id-column.unsortable.flex.vertical-center(data-testid="idColumn")
      span.text #

    .column.flex.unsortable.vertical-center(data-testid="iconColumn")
      i.icon.fas.fa-cube

    .column.flex.unsortable.vertical-center(data-testid="nameColumn")
      app-span.text {{ $t('.columns.name.label') }}

    .column.flex.unsortable.vertical-center(data-testid="sapColumn")
      sap-icon.sap-icon(color="gray")
      app-span.text {{ $t('.columns.sap.label') }}

    .column.flex.unsortable.vertical-center.center
      template(v-if="loading")
        i.loader-icon.fas.fa-spin.fa-spinner

</template>


<script>

import ProductsTableHeader from "@/views/products/index/products-table-header.vue"

export default {
  extends: ProductsTableHeader
}

</script>

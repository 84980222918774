<template lang="pug">
  finder-field(
    v-bind="forwardedProps"
    v-on="$listeners"
    debounce
    :fetch="fetch"
    searchable
  )
</template>

<script>
import models from '@/models'

export default {
  data() {
    return {
      request: null,
    }
  },
  computed: {
    forwardedProps() {
      return _.omit(
        this.$attrs,
        ['fetch', 'multiple', 'onFetchComplete', 'options', 'searchable']
      )
    }
  },
  methods: {
    async fetch(query) {
      let request
      try {
        if (this.request) this.request.cancel('Duplicated fetch')
        request = this.$sdk.manufacturers.list({ params: { q: query } })
        this.request = request

        const { data } = await request

        const norm = this.normalizeOptions(data)

        return norm
      } catch (error) {
        this.$err.log(error)
      } finally {
        if (request === this.request) this.request = null
      }
    },
    normalizeOptions(options) {
      return _.camelizeKeys(options)
        .map((option) => new models.Manufacturer(option))
        .map((option) => ({
          value: option.id,
          label: option.name,
          data: option,
        }))
    }
  },
}
</script>

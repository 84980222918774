<style scoped lang="scss">

.service-package-product-row {
  height: 70px;

  &:hover {
    background-color: $light-gray;
  }

  &.destroyed {
    height: 69px;
    background-color: $light-gray-2;
    font-family: $secondary-font;
    font-weight: 400;

    .undo {
      font-weight: normal;
      margin-left: 8px;
    }
  }

  &.disabled {
    background-color: $light-gray-2;
    cursor: not-allowed;
  }

  .added {
    color: $success;
    font-size: 14px;
    font-weight: 400;

    span {
      font-style: italic;
      margin-left: 4px;
    }
  }

  .actions {
    margin: 0 26px;

    .remove-button {
      font-size: 16px;

      &:hover {
        background-color: $light-gray-3;
        transition: 0s;
      }
    }
  }
}

</style>


<template lang="pug">

  .service-package-product-row.destroyed.flex.vertical-center.center(
    v-if="isMarkedForDestruction",
    :class="{ disabled }"
  )
    span {{ $t('.destroyed', { name: product.name }) }}
    app-button.undo(
      theme="link",
      :disabled="disabled",
      @click="$emit('undo-remove', product)"
    ) {{ $t('.undo') }}

  base-product-row.service-package-product-row(
    v-else,
    :product="product",
    :showLink="false",
    :class="{ disabled }"
  )
    template(slot="content-right")
      .origin(v-if="edit")
        .added(v-if="!isPersisted")
          i.icon.far.fa-check
          span {{ $t('.added') }}

    template(slot="actions")
      .actions(v-if="edit")
        app-button.remove-button(
          theme="neutral",
          size="small",
          :disabled="disabled",
          @click="$emit('remove', product)",
          data-testid="removeProduct"
        )
          i.fal.fa-trash-alt

</template>


<script>

export default {
  props: {
    product:     { type: Object, default: () => ({}) },
    isPersisted: { type: Boolean, default: false },
    disabled:    { type: Boolean, default: false },
    edit:        { type: Boolean, default: false }
  },

  data() {
    return {
      i18nScope: "service-packages.components.service-package-product-row"
    }
  },

  computed: {
    isMarkedForDestruction() {
      return this.product._destroy === true
    }
  }
}

</script>

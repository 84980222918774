<style lang="scss" scoped>

.deal-state-history {
  .initializing,
  .error {
    padding-top: 24px;
  }

  .error {
    .refetch,
    .retry {
      margin-left: 8px;
    }
  }
}

</style>


<template lang="pug">

  .deal-state-history
    template(v-if="initializing")
      loading-lines.initializing(:lines="4")

    template(v-else-if="erred")
      .error.flex
        span.error-message {{ $t('.error.message') }}

        .refetch
          template(v-if="fetching")
            i.icon.fas.fa-sync.fa-spin

          template(v-else)
            app-button.link(
              theme="link",
              @click="fetch"
            )
              i.icon.fas.fa-sync
              span.retry {{ $t('.error.retry') }}

    template(v-else)
      deal-state-change-row(
        v-for="(dealStageChange, index) in history",
        :key="`deal-state-change-${dealStageChange.id}`",
        :deal-state-change="dealStageChange",
        :deal="deal",
        :is-last="index === history.length - 1",
        data-testid="dealStateChange"
      )

</template>


<script>

// Mixins
import FetchMixin from "@/mixins/fetch-mixin"

// Components
import DealStateChangeRow from "./deal-state-change-row"

export default {
  components: {
    DealStateChangeRow
  },

  mixins: [FetchMixin],

  props: {
    deal: { type: Object, required: true }
  },

  data() {
    return {
      i18nScope: "suppliers.deals.information.show.deal-state-history",

      resource: []
    }
  },

  computed: {
    history: {
      get() { return this.resource },

      set(value) { this.resource = value }
    }
  },

  methods: {
    // @override Fetch Mixin
    fetchRequest() {
      return this.$newSdk.deals.listStateChanges({ dealId: this.deal.id })
    },

    // @override Fetch Mixin
    onFetchSuccess({ data }) {
      this.resource = data
        .concat({
          to:        "draft",
          createdAt: this.deal.createdAt
        })
    },

    // @override Fetch Mixin
    onFetchError(err) {
      // Não exibe notificação
      if (!err.cancelled) {
        this.fetchError = err
        this.erred = true
        this.$err.log(err)
      }
    }
  }
}

</script>

// Base
import BaseRequests from "../../Base"

// Models
import models from "@/models"
const { MovidaServiceOrderAttachment } = models

class MonitoringServiceOrderAttachmentsRequests extends BaseRequests {
  static get selector() {
    return "monitoring.serviceOrders.attachments"
  }

  all({ serviceOrderId, params = {}, options = {} } = {}) {
    this.verifyData("all", { serviceOrderId })

    return this.action({
      method:  "get",
      url:     `/monitoring/service_orders/${serviceOrderId}/attachments`,
      params,
      options: {
        model: MovidaServiceOrderAttachment,

        ...options
      }
    })
  }

  getDownloadURL({ serviceOrderId, attachmentId, params = {}, options = {} } = {}) {
    this.verifyData("getDownloadURL", { serviceOrderId })

    return this.action({
      method: "get",
      url:    `/monitoring/service_orders/${serviceOrderId}/attachments/${attachmentId}/download_url`,
      params,
      options
    })
  }

  createBatch({ serviceOrderId, params = {}, options = {} }) {
    this.verifyData("createBatch", { serviceOrderId })

    return this.action({
      method: "post",
      url:    `/monitoring/service_orders/${serviceOrderId}/attachments/batches`,
      params,
      options
    })
  }
}

export default MonitoringServiceOrderAttachmentsRequests

<style lang="scss" scoped>

.ticket-details-step {
  .header {
    margin-bottom: 24px;

    .title,
    .subtitle {
      font-family: $secondary-font;
      font-weight: 500;
    }

    .title {
      font-size: 38px;
    }

    .subtitle {
      font-size: 20px;
      margin-bottom: 8px;
    }
  }

  .section {
    & + .section {
      border-top: 1px solid $light-gray-3;
      padding-top: 24px;
      margin-top: 24px;
    }

    &.body {
      .banner {
        margin-bottom: 24px;
      }

      .half {
        width: 566px;
      }

      .section-title {
        font-family: $secondary-font;
        font-weight: 500;
        font-size: 18px;
      }

      .row {
        margin-top: 16px;
      }

      .vehicle-section {
        .field {
          width: 100%;

          & + .field {
            margin-left: 24px;
          }
        }

        .occurrence {
          margin-top: 16px;
          padding: 16px;
          background-color: $light-gray;
          border-radius: 8px;
        }
      }
    }

    &.footer {
      .cancel {
        color: $gray-3;

        &:hover {
          color: $orange;
        }
      }

      .back {
        margin-right: 24px;

        .icon {
          margin-right: 8px;
        }
      }

      .search .icon {
        margin-left: 8px;
      }
    }
  }
}

</style>


<template lang="pug">

  .ticket-details-step
    .header
      h2.subtitle {{ ServiceOrderRepair.$tEnum('kind', serviceOrder.kind) }}
      h1.title {{ $t('.title') }}

    .section.body
      vehicle-info-banner.banner(:vehicle="vehicle")

      .flex.space-between
        .half
          .section.kind-section
            h3.section-title {{ $t('.sections.kind.title') }}
            .row
              repair-kind-radio-field(
                v-model="serviceOrder.kind",
                :disabled="submitting",
                :errors="nestedErrors.kind"
              )

          .section.vehicle-section
            h3.section-title {{ $t('.sections.vehicle.title') }}
            .row.flex
              input-field.field(
                name="ticket[vehicleKm]",
                :label="$t('.fields.vehicleKm.label')",
                :placeholder="$t('.fields.vehicleKm.placeholder')",
                :loading="submitting",
                :suffix="$t('.fields.vehicleKm.suffix')",
                :errors="errors.vehicleKm"
                v-model="resource.vehicleKm",
                mask="#",
              )

              fuel-level-select-field.field(
                name="ticket[fuelLevel]",
                :label="$t('.fields.fuelLevel.label')",
                :placeholder="$t('form.select.placeholder')",
                :disabled="submitting",
                :errors="errors.fuelLevel",
                v-model="resource.fuelLevel",
                searchable,
                optional
              )

            template(v-if="isDamage")
              .row
                occurrence-select-field(
                  name="ticket[occurrence]",
                  :label="$t('.fields.occurrence.label')",
                  :loading="submitting",
                  :vehicle-id="vehicle.id",
                  :errors="nestedErrors.occurrenceId",
                  v-model="serviceOrder.occurrenceId",
                  @change-option="onChangeOccurrence"
                )

                template(v-if="occurrence")
                  .occurrence
                    occurrence-info(:occurrence="occurrence")

        .half
          last-tickets-preview(:vehicle="vehicle")

    .section.footer.flex.space-between
      template(v-if="edit")
        destroy-ticket-action(
          :ticket-id="resource.id",
          :ticket-store-id="serviceOrder.storeId",
          :loading="submitting",
          @destroy="onDestroy"
        )

      template(v-else)
        .cancel-actions
          app-button.back(
            outline,
            theme="gray",
            @click="$emit('back')"
          )
            i.icon.far.fa-arrow-left
            span {{ $t('.btn.back') }}

          app-button.cancel(
            theme="link",
            :to="{ name: 'tickets' }"
          ) {{ $t('.btn.cancel') }}

      app-button.search(
        :loading="submitting",
        @click="$emit('submit')"
      )
        span {{ $t('.btn.search') }}
        i.icon.far.fa-arrow-right

</template>


<script>

// Libs
import { events } from "utils.vue"

// Components
import VehicleInfoBanner from "@/views/tickets/drafts/components/vehicle-info-banner.vue"
import RepairKindRadioField from "./repair-kind-radio-field.vue"
import FuelLevelSelectField from "./fuel-level-select-field.vue"
import LastTicketsPreview from "./last-tickets-preview.vue"
import OccurrenceSelectField from "./occurrence-select-field.vue"
import OccurrenceInfo from "./occurrence-info.vue"
import DestroyTicketAction from "@/views/tickets/drafts/components/destroy-ticket-action.vue"

// Models
import models from "@/models"
const { ServiceOrderRepair } = models

export default {
  components: {
    VehicleInfoBanner,
    RepairKindRadioField,
    FuelLevelSelectField,
    LastTicketsPreview,
    OccurrenceSelectField,
    OccurrenceInfo,
    DestroyTicketAction
  },

  props: {
    vehicle:    { type: Object, required: true },
    resource:   { type: Object, required: true },
    edit:       { type: Boolean, default: false },
    submitting: { type: Boolean, default: false },
    errors:     { type: Object, default: () => ({}) }
  },

  data() {
    return {
      i18nScope: "tickets.drafts.vehicles.components.ticket-details-step",

      occurrence: null,

      ServiceOrderRepair
    }
  },

  computed: {
    serviceOrder() {
      return this.resource.serviceOrders[0]
    },

    isDamage() {
      return this.serviceOrder.kind === "damage"
    },

    nestedErrors() {
      return (this.errors.serviceOrdersAttributes || {})[0] || {}
    }
  },

  created() {
    this.$nextTick().then(() => {
      events.$emit("ticket-draft:step", 2)
    })
  },

  methods: {
    onDestroy() {
      this.$router.push({ name: "tickets" })
    },

    onChangeOccurrence(option) {
      this.occurrence = _.get(option, "data")
      this.serviceOrder.occurrenceId = _.get(this.occurrence, "id") || null
    }
  }
}

</script>

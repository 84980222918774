import { i18n } from "utils.vue"
import VehicleModel from "./vehicle-model"

class VehicleModelElectric extends VehicleModel {
  static get attrs() {
    return _.uniq([
      ...super.attrs,
      "electricEngineBatteryCapacity"
    ])
  }

  static get enums() {
    const enums = {
      ...super.enums
    }

    delete enums.type

    return enums
  }

  static get constraints() {
    return {
      ...super.constraints,

      electricEngineBatteryCapacity: {
        presence:     true,
        numericality: {
          greaterThan: 0
        }
      }
    }
  }

  static get virtuals() {
    return ["type"]
  }

  // Virtuals
  get type() {
    return "VehicleModels::Electric"
  }

  // Overrides
  get mobiautoVersionId() {
    return this.$get("mobiautoVersion.id")
  }

  // Formatted Attributes
  get formattedElectricEngineBatteryCapacity() {
    if (_.blank(this.electricEngineBatteryCapacity)) return null

    const count = this.electricEngineBatteryCapacity
    const capacity = i18n.l("number", this.electricEngineBatteryCapacity, { precision: 2 })

    return i18n.t("models.vehicleModelElectric.formattedAttributes.electricEngineBatteryCapacity", { capacity, count })
  }
}

export default VehicleModelElectric

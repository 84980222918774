<style lang="scss" scoped>

.form-fields {
  .icon {
    font-size: 20px;
    width: 20px;
    height: 20px;
    margin-right: 20px;
  }

  .clear {
    color: $gray-2;
  }

  .row-icon {
    margin-top: 32px;
  }

  .field + .field {
    margin-top: 24px;
  }

  .action-icons {
    margin-left: 16px;
  }

  .row {
    margin-right: 78px;

    &:first-of-type {
      .action-icons {
        margin-top: 25px;
      }
    }
  }

  .row + .row {
    margin-top: 12px;
  }

  .section {
    padding: 24px 34px;

    &:not(:last-of-type) {
      padding-bottom: 0px;
    }

    + .section {
      margin-top:24px;
      border-top: 1px solid $light-gray-3;
    }
  }
}

</style>


<template lang="pug">

  .form-fields
    .section.basic
      .field.flex
        i.icon.row-icon.fal.fa-user
        .fields.grow
          .row
            input-field(
              name='contact[name]',
              v-model="resource.name",
              :errors="errors.name",
              :placeholder="$t('.fields.name.placeholder')",
              data-test="contactName"
            )

      .field.flex
        i.icon.row-icon.fal.fa-briefcase
        .fields.grow
          .row
            input-field(
              name='contact[area]',
              v-model="resource.area",
              optional,
              :errors="errors.area",
              :placeholder="$t('.fields.area.placeholder')"
            )

    .section.phones
      .field.flex
        i.icon.row-icon.fal.fa-phone
        .fields.grow
          phone-nested-fields(
            name="supplier[contacts][phones]",
            v-model="resource.phones",
            :minimum="1",
            :errors="errors"
          )

    .section.emails
      .field.flex
        i.icon.row-icon.fal.fa-envelope
        .fields.grow
          email-nested-fields(
            name="supplier[contacts][emails]",
            v-model="resource.emails",
            :minimum="1",
            :errors="errors"
          )

</template>


<script>

import EmailNestedFields from "./form-fields/email-nested-fields"
import PhoneNestedFields from "./form-fields/phone-nested-fields"

export default {
  components: {
    EmailNestedFields,
    PhoneNestedFields
  },

  props: {
    errors:   { type: Object, default: () => ({}) },
    resource: { type: Object, default: () => ({}) }
  },

  data() {
    return {
      i18nScope: "suppliers.contacts.form-fields"
    }
  }
}

</script>

<style scoped lang="scss">

.header {
  .heading {
    font-size: 18px;
    font-weight: 500;
    height: 32px;
    line-height: 32px;
    font-family: $secondary-font;
  }

  .icon {
    color: $dark-gray;
    font-size: 16px;
    margin-right: 8px;
  }
}

.amendment {
  .body {
    .info + .info {
      margin-left: 72px;
    }

    .section {
      padding: 16px 24px;
    }

    .section + .section {
      border-top: 1px solid $light-gray-3;
    }
  }

  .icon-content {
    .icon {
      margin-right: 8px;
    }
  }
}

</style>


<template lang="pug">

  .new-publication(v-if="$can('create', 'Deal')")
    app-button(
      :disabled="disabled",
      :loading="submitting",
      @click="onShowModal",
    )
      slot

    .modal(v-if="showModal")
      app-modal.amendment(
        v-if="!showConfirmation",
        :close-button="!submitting",
        :width="1200",
        @close="onCloseModal"
      )
        template(slot="header")
          header.header.flex.vertical-center
            i.icon.fas.fa-paste
            h1.heading {{ $t('.heading') }}

        .body
          section.section
            ui-row
              info-label.info(
                :label="$t('.fields.createdAt.label')",
                :value="today"
              )
              info-label.info(
                :label="$t('.fields.author.label')",
                :value="user.name"
              )

          section.section
            textarea-field(
              v-model="resource.description",
              name="amendment-description",
              :label="$t('.fields.description.label')",
              :errors="errors.description"
              :placeholder="$t('.fields.description.placeholder')"
            )

        template(slot="footer")
          .footer
            app-button(
              @click="onCloseModal",
              outline,
              theme="gray",
              :loading="submitting"
            ) {{ $t('btn.cancel') }}

            app-button(
              @click="publish"
            )
              .icon-content
                i.icon.fas.fa-paper-plane
              span {{ $t('.btn.submit.label') }}

      app-modal(
        v-else,
        :close-button="!submitting",
        :width="540",
        :heading="$t('.confirmation.heading', { id })",
        @close="onCloseModal"
      )
        .body
          span.text {{ $t('.confirmation.message') }}

        template(slot="footer")
          .footer
            app-button(
              @click="showConfirmation = false"
              outline,
              theme="gray",
              :loading="submitting"
            ) {{ $t('btn.cancel') }}

            app-button(
              @click="submit",
              :loading="submitting",
              data-test="confirmDeleteButton"
            )
              span {{ $t('.confirmation.btn.submit.label') }}
              span(slot="loading") {{ $t('.confirmation.btn.submit.loading') }}

</template>


<script>

// Modules
import { lib as movidaCommonLib } from "movida-common.vue"

// Models
import models from "@/models"


const { parseModelJsErrors }   = movidaCommonLib.ParseErrors
const { DealStateChangeEvent } = models


export default {
  props: {
    id:       { type: Number, default: null },
    disabled: { type: Boolean, default: false }
  },

  data() {
    return {
      i18nScope: "suppliers.deals.publication.amendment.new",

      supplierId: this.$route.params.id,

      submitting: false,

      showModal: false,

      showConfirmation: false,

      resource: new DealStateChangeEvent(),
      user:     this.$auth.user,

      errors: {}
    }
  },

  computed: {
    today() {
      return this.$l("date.formats.default", new Date())
    }
  },

  methods: {
    onShowModal() {
      this.errors = {}
      this.showConfirmation = false
      this.showModal = true
    },

    async publish() {
      await this.resource.$validate()
      this.errors = parseModelJsErrors(this.resource.$errors)

      if (_.blank(this.errors)) this.showConfirmation = true
    },

    onCloseModal() {
      if (!this.submitting) {
        this.showModal = false
        this.showConfirmation = false
      }
    },

    async submit() {
      try {
        this.submitting = true
        await this.$sdk.suppliers.publishAmendmentDeal({
          id:     this.supplierId,
          dealId: this.id,
          params: this.resource.$serialize()
        })

        this.$notifications.info(this.$t(".notifications.submit.success", { id: this.id }))
        this.$emit("published")
      }
      catch (error) {
        this.$err.log(error)
        this.$notifications.error(this.$t(".notifications.submit.error"))
        let errData = _.get(error, "originalError.response.data.errors") || {}

        let errors = this.$i18n.errify(errData, { model: "DealStateChangeEvent" })

        this.$emit("errors", errors)
      }
      finally {
        this.submitting = false
        this.showModal = false
      }
    }
  }
}

</script>

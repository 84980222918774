<style scoped lang="scss">
</style>


<template lang="pug">

  select-field(
    v-bind="$props",
    v-on="$listeners",
    :options="options"
  )

</template>


<script>

import Vue from "vue"

import SelectField from "@/components/form/select-field"
import models from "@/models"

const { VehicleModel } = models

export default {
  extends: SelectField,

  data() {
    return {
      i18nScope: "vehicle-models.components.vehicle-model-category-select-field"
    }
  },

  computed: {
    options() {
      return VehicleModel.$enums.category.values.map(category => ({
        label: this.$t(`models.vehicleModel.enums.category.${category}`),
        value: category
      }))
    }
  },

  // Removendo props 'options' que está sendo reescrita como _computed_
  beforeCreate() {
    Vue.delete(this.$options.props, "options")
  }
}

</script>

<style scoped lang="scss">

.content-container {
  width: 100%;
  height: 100%;
  overflow-y: auto;

  &.authenticated {
    height: calc(100% - #{$navbar-height});
  }
}

#content {
  width: 100%;
  padding: 40px;
  overflow: auto;

  > .view-context,
  > .view {
    max-width: 1200px;
    margin: 0 auto;
  }
}

</style>


<template lang="pug">

  .default-layout
    navbar(v-if="authenticated")

    .content-container.flex(:class="{ authenticated }")
      sidebar#sidebar(v-if="showSidebar")

      #content(ref="content")
        template(v-if="!viewStore.hasError")
          view-context.view-context

        transition(:name="transition.name", :mode="transition.mode", @after-leave="onViewChange")
          template(v-if="!viewStore.hasError")
            router-view.view(data-test="router-view")

          template(v-else)
            component(
              :is="errorComponent",
              @refresh="$emit('refresh')",
              data-test="error-component"
            )

</template>


<script>

import { events } from "utils.vue"
import viewStore  from "@/store/view"

const DEFAULT_ROUTE_TRANSITION_NAME = "fade"
const DEFAULT_ROUTE_TRANSITION_MODE = "out-in"


export default {
  name: "DefaultLayout",

  props: {
    authenticated: { type: Boolean, default: true },
    transition:    {
      type:    Object,
      default: () => ({
        name: DEFAULT_ROUTE_TRANSITION_NAME,
        mode: DEFAULT_ROUTE_TRANSITION_MODE
      })
    }
  },

  data() {
    return {}
  },

  computed: {
    viewStore() {
      return viewStore
    },

    showSidebar() {
      return this.authenticated && !viewStore.hasError
    },

    errorComponent() {
      if (!viewStore.hasError) return null

      switch (_.camelize(viewStore.appError.status)) {
        case "404":
        case "notFound":
          return "not-found"

        case "403":
        case "forbidden":
          return "forbidden"

        default:
          return "server-error"
      }
    }
  },

  watch: {
    $route(to, from) {
      viewStore.appError = null
    }
  },

  created() {
    events.$on("view:error", this.handleViewError)
  },

  beforeDestroy() {
    events.$off("view:error", this.handleViewError)
  },

  methods: {

    handleViewError(errorName) {
      this.viewStore.appError = { status: errorName }
    },

    onViewChange() {
      // scroll behavior
      // ref: https://router.vuejs.org/guide/advanced/scroll-behavior.html#scroll-behavior
      // --
      // Estamos implementando aqui pois é aqui que temos o controle da transição entre views.
      // XXX .scrollTo(0, 0) não funciona no Edge (ref https://stackoverflow.com/questions/51517324/scrollto-method-doesnt-work-in-edge)
      if (!!this.$refs.content) this.$refs.content.scrollTop = 0
    }
  }
}

</script>

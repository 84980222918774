import { lib as movidaCommonLib } from "movida-common.vue"

const ConfirmationModalPlugin = movidaCommonLib.ConfirmationModalPlugin


function initialize(vue) {
  vue.use(ConfirmationModalPlugin)
}


export default initialize

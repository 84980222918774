import Base from "../../Base"

import models from "@/models"

const { ServiceOrderReopening } = models

class MonitoringServiceOrdersReopeningsRequests extends Base {
  static get selector() {
    return "monitoring.serviceOrders.reopenings"
  }

  create({ serviceOrderId, params = {}, options = {} } = {}) {
    this.verifyData("create", { serviceOrderId })

    return this.action({
      method:  "post",
      url:     `/monitoring/service_orders/${serviceOrderId}/reopenings`,
      params,
      options: {
        model: ServiceOrderReopening,

        ...options
      }
    })
  }
}

export default MonitoringServiceOrdersReopeningsRequests

.<style lang="scss" scoped>

.tickets-table-header {
  display: grid;
  grid-template-columns: 70px 40px 0.6fr 120px 1fr 180px 64px;
  background-color: $light-gray;
  height: 40px;
  border-bottom: 1px solid $light-gray-3;
  border-top: 1px solid $light-gray-3;

  &.loading {
    border-bottom: 2px solid $light-orange-2;
  }

  .unsortable {
    padding: 0 12px;
  }

  .column {
    font-size: 12px;
    height: 40px;

    .text {
      font-family: $secondary-font;
      font-size: 14px;
      color: $dark-gray;
      font-weight: 400;
    }

    .loader-icon {
      font-size: 16px;
      color: $orange;
    }

    &.state-column {
      .icon {
        font-size: 16px;
        height: 16px;
        width: 16px;
        color: $gray-2;
      }
    }
  }
}

</style>


<template lang="pug">

  .tickets-table-header(:class="{ loading }")
    .column.id-column.unsortable.flex.vertical-center(data-testid="idColumn")
      span.text #

    .column.state-column.unsortable.flex.vertical-center(data-testid="stateColumn")
      i.icon.fas.fa-user-headset(v-tooltip.top="{ offset: 4, content: $t('.columns.state.tooltip') }")

    .column.ticket-column.unsortable.flex.vertical-center(data-testid="ticketColumn")
      span.text {{ $t('.columns.tickets.label') }}

    .column.license-plate-column.unsortable.flex.vertical-center(data-testid="licensePlateColumn")
      span.text {{ $t('.columns.plate.label') }}

    .column.vehicle-column.unsortable.flex.vertical-center(data-testid="vehicleColumn")
      span.text {{ $t('.columns.vehicle.label') }}

    .column.date-column.unsortable.flex.vertical-center(data-testid="createdAtColumn")
      span.text {{ $t('.columns.date.label') }}

    .column.loader-column.unsortable.flex.vertical-center.center
      template(v-if="loading")
        i.loader-icon.fas.fa-spin.fa-spinner

</template>

<script>

import TicketsTableHeader from "@/views/tickets/index/tickets-table-header.vue"

export default {
  extends: TicketsTableHeader
}

</script>

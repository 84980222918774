<style lang="scss" scoped>

.deal-preview {
  .header {
    .ongoing-state {
      font-family: $secondary-font;
      font-weight: 500;
      font-size: 14px;
    }

    .expires-soon-warning {
      margin-top: 8px;

      .icon {
        margin-right: 4px;
        color: $dark-yellow;
        font-size: 14px;
      }

      .text {
        font-family: $primary-font;
        color: $dark-yellow;
        font-size: 14px;
        font-weight: 400;
      }
    }

    .title {
      padding-top: 6px;

      .id-tag {
        margin-left: 8px;
      }
    }
  }

  .content {
    margin-top: 12px;

    .text {
      display: block;

      font-family: $primary-font;
      color: $dark-gray;
      font-size: 14px;
      font-weight: 300;
    }

    .text + .text {
      margin-top: 2px;
    }
  }

  .actions {
    margin-top: 12px;
  }
}

</style>


<template lang="pug">

  .deal-preview
    .header
      .flex.vertical-center
        deal-state.ongoing-state(:value="deal.state")

      template(v-if="deal.expiresSoon")
        .expires-soon-warning.flex.vertical-center
          i.icon.fa.fa-exclamation-triangle
          app-span.text(
            :value="$t('.expireSoon.label')"
            crop
          )

      .title.flex.vertical-center(data-testid="dealTitle")
        app-button.app-button.deal-title(:to="showRoute", theme="link") {{ $models.Deal.$tModelName() }}
        id-tag.id-tag {{ deal.id }}

    .content
      app-span.text.start-at(:value="$t('.startAt.label', { startAt: formattedStartAt })", crop)
      app-span.text.duration(:value="$t('.duration.label', { duration })", crop)
      app-span.text.valid-until(:value="$t('.validUntil.label', { date: formattedExpirationDate })", crop)
      app-span.text.expires-in {{ $t('.expiresIn', { count: expirationMonthsDifference }) }}

</template>


<script>

// Components
import DealState from "../deals/components/deal-state"


export default {
  components: {
    DealState
  },

  props: {
    deal: { type: Object, required: true }
  },

  data() {
    return {
      i18nScope: "suppliers.components.deal-preview"
    }
  },

  computed: {
    showRoute() {
      return {
        name:   "supplierDeal",
        params: {
          id:     this.deal.supplierId,
          dealId: this.deal.id
        }
      }
    },

    formattedStartAt() {
      if (_.blank(this.deal.startAt)) return null
      return this.$l("date.formats.default", this.deal.startAt)
    },

    duration() {
      if (!_.get(this.deal, "duration")) return null
      return this.$t("models.deal.formattedAttributes.duration", { count: this.deal.duration })
    },

    formattedExpirationDate() {
      if (!this.deal.expirationDate) return null
      return this.$l("date.formats.default", this.deal.expirationDate.format())
    },

    expirationMonthsDifference() {
      if (!this.deal.hasExpirationData) return null

      return this.deal.expirationDate.diff(moment(), "months")
    }
  }
}

</script>

<style lang="scss" scoped>

.table-header {
  background-color: $light-gray;
  height: 40px;
  border-bottom: 1px solid $light-gray-3;
  border-top: 1px solid $light-gray-3;
  padding: 0 12px;

  .column {
    font-size: 12px;

    .all-label {
      color: $gray-2;
      margin-left: 16px;

      &.active {
        color: $dark-gray;
        font-weight: 500;
      }
    }
    .selected {
      margin-right: auto;
    }

    &.checkbox-column {
      margin-right: 16px;
    }

    &.selected-column {
      margin-right: auto;
    }

    .toggle-button {
      margin-right: 24px;
      font-family: $secondary-font;
      font-size: 14px;
      color: $gray-2;
      font-weight: normal;

      .icon {
        margin-right: 8px;
      }

      &.active {
        color: $dark-gray;
        font-weight: 400;
      }
    }

    .label {
      font-family: $secondary-font;
      font-size: 14px;
      font-weight: 500;
    }

    .selected-badge {
      margin-left: 8px;
      height: 14px;
      padding: 0 5px;
      border-radius: 12px;
      border: 1px solid $light-gray-3;

      .badge-label {
        vertical-align: super;
        font-size: 12px;
        font-weight: 500;
      }
    }
  }
}

</style>


<template lang="pug">

  .table-header.flex

    .column.flex.vertical-center.checkbox-column
      checkbox-field(
        name="selectAllOnPage",
        :value="allSelected",
        :disabled="loading",
        hide-value-text,
        @input="(value) => $emit('selectAllOnPage', value)"
      )

    .column.flex.vertical-center.selected-column
      app-button.toggle-button(
        :class="{ active: !loading && !filterSelected }",
        @click="$emit('filterSelected', false)",
        theme="link",
        size="small"
      )
        .all-issues.flex.vertical-center
          i.icon.fas.fa-lightbulb
          app-span(:value="$t('.fields.allIssues')")

      .selected-issues.flex.vertical-center
        app-button.toggle-button(
          :class="{ active: !loading && filterSelected }",
          :disabled="!hasSelectedOptions"
          @click="$emit('filterSelected', true)",
          theme="link",
          size="small"
        )
          i.icon.far.fa-check
          app-span(:value="$t('.fields.selectedIssues')")
          .selected-badge.vertical-center.center
            span.badge-label {{ selectedCount }}

    .column.flex.vertical-center.toggle-column
      app-button.all-label(
        @click="$emit('selectAll')",
        :loading="loading",
        :class="{ active: !loading }",
        theme="link",
        size="small",
      ) {{ $t('.fields.selectAll') }}

      app-button.all-label(
          @click="$emit('removeAll')",
          :loading="loading",
          :class="{ active: !loading }",
          theme="link",
          size="small",
      ) {{ $t('.fields.removeAll') }}

</template>


<script>

export default {
  name: "VehicleIssuesTableHeader",

  props: {
    selectedCount: { type: Number, required: true },

    loading:            { type: Boolean, default: false },
    allSelected:        { type: Boolean, default: false },
    filterSelected:     { type: Boolean, default: false },
    hasSelectedOptions: { type: Boolean, default: false }
  },

  data() {
    return {
      i18nScope: "services.components.vehicle-issues.table-header"
    }
  }
}

</script>

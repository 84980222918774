// Ability
import { ability } from "@/modules/ability"

// Routes
import Suppliers from "@/views/suppliers/index.vue"
import NewSupplier from "@/views/suppliers/new.vue"
import Supplier from "@/views/suppliers/show.vue"
import SupplierDefaultTab from "@/views/suppliers/default-tab/show.vue"
import SupplierDetails from "@/views/suppliers/details/show.vue"

import EditSupplierDefaultTab from "@/views/suppliers/default-tab/edit.vue"
import EditSupplierDetail from "@/views/suppliers/details/edit.vue"

// Deal
import SupplierDealsBase from "@/views/suppliers/deals/base.vue"
import SupplierDeals from "@/views/suppliers/deals/index.vue"
import SupplierDeal from "@/views/suppliers/deals/show.vue"
import SupplierDealInformation from "@/views/suppliers/deals/information/show.vue"
import EditSupplierDeal from "@/views/suppliers/deals/edit.vue"
import EditSupplierDealInformation from "@/views/suppliers/deals/information/edit.vue"

// Deal - Products
import SupplierDealProducts from "@/views/suppliers/deals/products/index.vue"
import NewSupplierDealProduct from "@/views/suppliers/deals/products/new.vue"
import SupplierDealProduct from "@/views/suppliers/deals/products/show.vue"
import EditSupplierDealProduct from "@/views/suppliers/deals/products/edit.vue"
import SupplierDealProductVehicleModelGroup from "@/views/suppliers/deals/products/vehicle-model-group/show.vue"

// Deal - Products - Data Loads
import NewSupplierDealProductsDataLoad from "@/views/suppliers/deals/products/data-loads/new.vue"
import SupplierDealProductsDataLoads from "@/views/suppliers/deals/products/data-loads/index.vue"
import SupplierDealProductsDataLoad from "@/views/suppliers/deals/products/data-loads/show.vue"

// Deal - Maintenance Packages
import SupplierDealMaintenancePackages from "@/views/suppliers/deals/maintenance-packages/index.vue"
import SupplierDealMaintenancePackage from "@/views/suppliers/deals/maintenance-packages/show.vue"
import NewSupplierDealMaintenancePackage from "@/views/suppliers/deals/maintenance-packages/new.vue"
import EditSupplierDealMaintenancePackage from "@/views/suppliers/deals/maintenance-packages/edit.vue"
import SupplierDealMaintenancePackageMaintenancePackage from "@/views/suppliers/deals/maintenance-packages/maintenance-package/show.vue"
import SupplierDealMaintenancePackageVehicleModelGroup from "@/views/suppliers/deals/maintenance-packages/vehicle-model-group/show.vue"

// Deal - Maintenance Packages - Data Loads
import NewSupplierDealMaintenancePackagesDataLoad from "@/views/suppliers/deals/maintenance-packages/data-loads/new.vue"
import SupplierDealMaintenancePackagesDataLoads from "@/views/suppliers/deals/maintenance-packages/data-loads/index.vue"
import SupplierDealMaintenancePackagesDataLoad from "@/views/suppliers/deals/maintenance-packages/data-loads/show.vue"

// Service Support
import SupplierServiceSupports from "@/views/suppliers/service-supports/index.vue"
// Service
import SupplierServiceSupportServiceVehicleModelGroups from "@/views/suppliers/service-supports/service-vehicle-model-groups/index.vue"
import NewSupplierServiceSupportServiceVehicleModelGroups from "@/views/suppliers/service-supports/service-vehicle-model-groups/new.vue"
import DestroySupplierServiceSupportServiceVehicleModelGroups from "@/views/suppliers/service-supports/service-vehicle-model-groups/destroy.vue"
// Maintenance
import SupplierServiceSupportMaintenanceVehicleModelGroups from "@/views/suppliers/service-supports/maintenance/vehicle-model-groups/index.vue"
import NewSupplierServiceSupportMaintenanceVehicleModelGroups from "@/views/suppliers/service-supports/maintenance/vehicle-model-groups/new.vue"
import SupplierServiceSupportMaintenanceVehicleModelGroup from "@/views/suppliers/service-supports/maintenance/vehicle-model-groups/show.vue"
import SupplierServiceSupportMaintenanceVehicleModelGroupVehicleModels from "@/views/suppliers/service-supports/maintenance/vehicle-model-groups/vehicle-models/index.vue"
import SupplierServiceSupportMaintenanceVehicleModelGroupMaintenanceTable from "@/views/suppliers/service-supports/maintenance/vehicle-model-groups/maintenance-table/show.vue"

// Service Orders
import SupplierServiceOrders from "@/views/suppliers/service-orders/index.vue"
import SupplierTickets from "@/views/suppliers/tickets/index.vue"

export default [
  {
    path:      "/suppliers/new",
    name:      "newSupplier",
    component: NewSupplier,
    meta:      {
      auth:        true,
      permissions: [() => ability.can("create", "Supplier")]
    }
  },

  {
    path:      "/suppliers/:id",
    component: Supplier,
    meta:      {
      auth:        true,
      permissions: [() => ability.can("read", "Supplier")]
    },

    children: [
      {
        path:      "",
        name:      "supplier",
        component: SupplierDefaultTab
      },
      {
        path:      "edit",
        name:      "editSupplier",
        component: EditSupplierDefaultTab,
        meta:      {
          permissions: [() => ability.can("update", "Supplier")]
        }
      },
      {
        path:      "details",
        name:      "supplierDetails",
        component: SupplierDetails
      },
      {
        path:      "details/edit",
        name:      "editSupplierDetails",
        component: EditSupplierDetail,
        meta:      {
          permissions: [() => ability.can("update", "Supplier")]
        }
      },
      {
        path:      "deals",
        component: SupplierDealsBase,
        meta:      {
          permissions: [() => ability.can("index", "Deal")]
        },

        children: [
          {
            path:      "",
            name:      "supplierDeals",
            component: SupplierDeals
          },

          {
            path:      ":dealId",
            component: SupplierDeal,
            name:      "supplierDeal",
            redirect:  ":dealId/information",
            meta:      {
              permissions: [() => ability.can("read", "Deal")]
            },

            children: [
              {
                path:      "information",
                name:      "supplierDealInformation",
                component: SupplierDealInformation,
                meta:      {
                  hideSupplierTabs: true
                }
              },
              {
                path:      "maintenance-packages",
                name:      "supplierDealMaintenancePackages",
                component: SupplierDealMaintenancePackages,
                meta:      {
                  hideSupplierTabs: true
                },

                children: [
                  {
                    path:      "new",
                    name:      "newSupplierDealMaintenancePackage",
                    component: NewSupplierDealMaintenancePackage,
                    meta:      {
                      hideSupplierTabs: true,
                      permissions:      [() => ability.can("update", "Deal")]
                    }
                  },
                  {
                    path:      "data-loads",
                    name:      "supplierDealMaintenancePackagesDataLoads",
                    component: SupplierDealMaintenancePackagesDataLoads,
                    meta:      {
                      hideSupplierTabs: true
                    }
                  },
                  {
                    path:      "data-loads/new",
                    name:      "newSupplierDealMaintenancePackagesDataLoad",
                    component: NewSupplierDealMaintenancePackagesDataLoad,
                    meta:      {
                      hideSupplierTabs: true,
                      permissions:      [() => ability.can("update", "Deal")]
                    }
                  },
                  {
                    path:      "data-loads/:dataLoadId",
                    name:      "supplierDealMaintenancePackagesDataLoad",
                    component: SupplierDealMaintenancePackagesDataLoad,
                    meta:      {
                      hideSupplierTabs: true
                    }
                  },
                  {
                    path:      ":maintenancePackageId",
                    name:      "supplierDealMaintenancePackage",
                    component: SupplierDealMaintenancePackage,
                    meta:      {
                      hideSupplierTabs: true
                    },
                    redirect: ":maintenancePackageId/maintenance-package",

                    children: [
                      {
                        path:      "maintenance-package",
                        name:      "supplierDealMaintenancePackageMaintenancePackage",
                        component: SupplierDealMaintenancePackageMaintenancePackage,
                        meta:      {
                          hideSupplierTabs: true
                        }
                      },
                      {
                        path:      "vehicle-model-group",
                        name:      "supplierDealMaintenancePackageVehicleModelGroup",
                        component: SupplierDealMaintenancePackageVehicleModelGroup,
                        meta:      {
                          hideSupplierTabs: true
                        }
                      }
                    ]
                  },
                  {
                    path:      ":maintenancePackageId/edit",
                    name:      "editSupplierDealMaintenancePackage",
                    component: EditSupplierDealMaintenancePackage,
                    meta:      {
                      hideSupplierTabs: true
                    }
                  }
                ]
              },
              {
                path:      "products",
                name:      "supplierDealProducts",
                component: SupplierDealProducts,
                meta:      {
                  hideSupplierTabs: true
                },

                children: [
                  {
                    path:      "new",
                    name:      "newSupplierDealProduct",
                    component: NewSupplierDealProduct,
                    meta:      {
                      hideSupplierTabs: true,
                      permissions:      [() => ability.can("update", "Deal")]
                    }
                  },
                  {
                    path:      "data-loads",
                    name:      "supplierDealProductsDataLoads",
                    component: SupplierDealProductsDataLoads,
                    meta:      {
                      hideSupplierTabs: true
                    }
                  },
                  {
                    path:      "data-loads/new",
                    name:      "newSupplierDealProductsDataLoad",
                    component: NewSupplierDealProductsDataLoad,
                    meta:      {
                      hideSupplierTabs: true,
                      permissions:      [() => ability.can("update", "Deal")]
                    }
                  },
                  {
                    path:      "data-loads/:dataLoadId",
                    name:      "supplierDealProductsDataLoad",
                    component: SupplierDealProductsDataLoad,
                    meta:      {
                      hideSupplierTabs: true
                    }
                  },
                  {
                    path:      ":dealProductId",
                    name:      "supplierDealProduct",
                    component: SupplierDealProduct,
                    meta:      {
                      hideSupplierTabs: true
                    },
                    redirect: ":dealProductId/vehicle-model-group",

                    children: [
                      {
                        path:      "vehicle-model-group",
                        name:      "supplierDealProductVehicleModelGroup",
                        component: SupplierDealProductVehicleModelGroup,
                        meta:      {
                          hideSupplierTabs: true
                        }
                      }
                    ]
                  },
                  {
                    path:      ":dealProductId/edit",
                    name:      "editSupplierDealProduct",
                    component: EditSupplierDealProduct,
                    meta:      {
                      hideSupplierTabs: true
                    }
                  }
                ]
              }
            ]
          },
          {
            path:      ":dealId/edit",
            name:      "editSupplierDeal",
            component: EditSupplierDeal,
            redirect:  ":dealId/edit/information",
            meta:      {
              hideSupplierTabs: true,
              permissions:      [() => ability.can("update", "Deal")]
            },

            children: [
              {
                path:      "information",
                name:      "editSupplierDealInformation",
                component: EditSupplierDealInformation,
                meta:      {
                  hideSupplierTabs: true
                }
              }
            ]
          }
        ]
      },
      {
        path:      "service-supports",
        name:      "supplierServiceSupports",
        component: SupplierServiceSupports
      },
      {
        path:      "service-supports/maintenance/vehicle-model-groups",
        name:      "supplierServiceSupportMaintenanceVehicleModelGroups",
        component: SupplierServiceSupportMaintenanceVehicleModelGroups,

        children: [
          {
            path:      "new",
            name:      "newSupplierServiceSupportMaintenanceVehicleModelGroups",
            component: NewSupplierServiceSupportMaintenanceVehicleModelGroups,
            meta:      {
              permissions: [() => ability.can("update", "Supplier")]
            }
          },
          {
            path:      ":maintenanceVehicleModelGroupId",
            name:      "supplierServiceSupportMaintenanceVehicleModelGroup",
            component: SupplierServiceSupportMaintenanceVehicleModelGroup,
            redirect:  ":maintenanceVehicleModelGroupId/vehicle-models",

            children: [
              {
                path:      "vehicle-models",
                name:      "supplierServiceSupportMaintenanceVehicleModelGroupVehicleModels",
                component: SupplierServiceSupportMaintenanceVehicleModelGroupVehicleModels
              },
              {
                path:      "maintenance-table",
                name:      "supplierServiceSupportMaintenanceVehicleModelGroupMaintenanceTable",
                component: SupplierServiceSupportMaintenanceVehicleModelGroupMaintenanceTable
              }
            ]
          }
        ]
      },
      {
        path:      "service-supports/:type/vehicle-model-groups",
        name:      "supplierServiceSupportServiceVehicleModelGroups",
        component: SupplierServiceSupportServiceVehicleModelGroups,

        children: [
          {
            path:      "new",
            name:      "newSupplierServiceSupportServiceVehicleModelGroups",
            component: NewSupplierServiceSupportServiceVehicleModelGroups,
            meta:      {
              permissions: [() => ability.can("update", "Supplier")]
            }
          },

          {
            path:      "destroy",
            name:      "destroySupplierServiceSupportServiceVehicleModelGroups",
            component: DestroySupplierServiceSupportServiceVehicleModelGroups,
            meta:      {
              permissions: [() => ability.can("update", "Supplier")]
            }
          }
        ]
      },
      {
        path:      "service-orders",
        name:      "supplierServiceOrders",
        component: SupplierServiceOrders,
        meta:      {
          permissions: [() => ability.can("index", "ServiceOrder")]
        }
      },

      {
        path:      "tickets",
        name:      "supplierTickets",
        component: SupplierTickets,
        meta:      {
          permissions: [() => ability.can("index", "Ticket")]
        }
      }
    ]
  },
  {
    path:      "/suppliers",
    name:      "suppliers",
    component: Suppliers,
    meta:      {
      auth:        true,
      permissions: [() => ability.can("index", "Supplier")]
    }
  }
]

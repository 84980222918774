<style lang="scss" scoped>

.supplier-popover {
  width: 420px;

  > .loading {
    width: 100%;
  }

  > .erred {
    font-size: 16px;
  }

  > .loading,
  > .erred {
    padding: 16px;
  }
}


.content {
  .row {
    display: table;
    width: 100%;

    > .col {
      display: table-cell;
      vertical-align: middle; // top, bottom

      padding: 16px;

      &.icon {
        padding-right: 20px;

        font-size: 16px;
        color: $gray-3;

        width: 52px;
        text-align: center;
      }

      &.data {
        padding-left: 0;
      }
    }
  }

  > .row + .row {
    border-top: 1px solid $light-gray-3;
  }

  .title {
    font-weight: 600;
    font-size: 16px;

    word-wrap: break-word;
    white-space: normal;
  }

  .sub-title {
    font-size: 14px;
    font-weight: 500;
  }

  .cnpj,
  .mono {
    font-family: $primary-monospace-font;
  }

  .text {
    font-weight: 400;
    font-size: 14px;
    word-wrap: break-word;
    white-space: normal;
  }


  .copy-icon {
    display: inline-block;
    padding-left: 8px;

    color: $orange;
    cursor: pointer;
  }

  .copy-container {
    cursor: pointer;

    .copy-icon {
      display: none;
    }

    &:hover {
      // background-color: $light-gray;

      .copy-icon {
        display: inline-block;
      }
    }
  }

  .contact-list {

    > .item {
      display: table;
      // width: 100%;

      > * {
        display: table-cell;
        vertical-align: middle; // top, bottom
      }

      .value {
        font-family: $primary-monospace-font;
        font-size: 14px;
      }

      .label {
        font-size: 12px;
        color: $gray-2;
      }

      .diamond-icon {
        color: $gray-2;
        font-size: 6px;
        padding: 0 8px;
      }

      .unlabeled {
        color: $gray;
        font-style: italic;
      }
    }

    > .item + .item {
      margin-top: 8px;
    }
  }

}

</style>


<template lang="pug">

.supplier-popover
  template(v-if="fetching")
    .loading
      loading-lines(:lines="3")

  template(v-else-if="erred")
    .erred.flex.column-direction.vertical-center
      span.text-center {{ $t('.fetch-error.message') }}
      app-button.retry(theme="link", @click="fetch") {{ $t('.fetch-error.retry') }}

  template(v-else-if="hasResource")
    .content
      .row
        .col.icon
          i.fas.fa-user-hard-hat
        .col.data
          .copy-container(@click="copyToClipboard(supplier.tradingName)")
            span.title {{ supplier.tradingName }}
            i.copy-icon.fal.fa-copy
          .copy-container(@click="copyToClipboard(supplier.name)")
            span.sub-title {{ supplier.name }}
            i.copy-icon.fal.fa-copy

      .row
        .col.icon
          i.fas.fa-building
        .col.data.copy-container(@click="copyToClipboard(formattedCNPJ)")
          span.text.cnpj {{ formattedCNPJ }}
          i.copy-icon.fal.fa-copy

      .row(v-if="hasPhone")
        .col.icon
          i.icon.fas.fa-phone

        .col.data

          .contact-list
            .item
              .copy-container(@click="copyToClipboard(supplier.phone)")
                span.value {{ supplier.phone }}
                i.copy-icon.fal.fa-copy
              i.diamond-icon.fas.fa-diamond
              span.label {{ $t(".phone.main") }}

            template(v-for="(contact, index) in supplier.contacts")
              .item
                .copy-container(@click="copyToClipboard(contact.phone)")
                  span.value {{ contact.phone }}
                  i.copy-icon.fal.fa-copy
                template(v-if="contact.label")
                  i.diamond-icon.fas.fa-diamond
                  span.label {{ contact.label }}
                template(v-else)
                  i.diamond-icon.fas.fa-diamond.unlabeled
                  span.label.unlabeled {{ $t(".phone.noLabel") }}

      .row
        .col.icon
          i.fas.fa-envelope

        .col.data.copy-container(@click="copyToClipboard(supplier.email)")
          span.text {{ supplier.email }}
          i.copy-icon.fal.fa-copy

      .row
        .col.icon
          i.fas.fa-location-dot

        .col.data.copy-container(@click="copyToClipboard(supplier.fullAddress)")
          span.text {{ supplier.fullAddress }}
          i.copy-icon.fal.fa-copy

</template>


<script>

// Mixins
import FetchMixin from "@/mixins/fetch-mixin"


export default {
  mixins: [FetchMixin],

  props: {
    supplierRid: { type: [Number, String], required: true },
    show:        { type: Boolean, required: true }
  },

  data() {
    return {
      i18nScope: "monitoring.supplier-popover",

      resource: {},

      autofetch: false
    }
  },

  computed: {
    supplier: {
      get()    { return this.resource },
      set(val) { this.resource = val }
    },

    hasResource() {
      return _.present(this.resource)
    },

    formattedCNPJ() {
      return this.supplier.formattedCNPJ
    },

    hasPhone() {
      return ["cellphone", "phone", "fax"].some(phone => _.present(this.supplier[phone]))
    }
  },

  watch: {
    show: {
      immediate: true,
      handler() {
        if (this.show && _.blank(this.resource)) this.fetch()
      }
    }
  },

  methods: {
    // @override Fetchable mixin
    fetchRequest() {
      let params = { partial: "with_contacts" }

      return this.$newSdk.monitoring.suppliers
        .find({
          supplierRid: this.supplierRid,
          params
        })
    },

    // TODO: extrair em função utilitária
    copyToClipboard(value) {
      let textarea = document.createElement("textarea")
      textarea.textContent = value
      textarea.style.position = "fixed"

      document.body.appendChild(textarea)
      textarea.select()

      try {
        document.execCommand("copy")

        let notificationText = this.$t("mixins.copyable.notifications.success", { value })

        this.$notifications.info(notificationText)
      }
      // eslint-disable-next-line no-empty
      catch (e) {}
      finally {
        document.body.removeChild(textarea)
      }
    }
  }
}

</script>

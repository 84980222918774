import Base from "./base"

class SupplierServiceVehicleModelGroup extends Base {
  static get attrs() {
    return _.uniq([
      ...super.attrs,
      "supplierId",
      "vehicleModelGroupId",
      "serviceId"
    ])
  }

  static get relations() {
    return {
      service:           { type: "hasOne", model: "Service" },
      vehicleModelGroup: { type: "hasOne", model: "VehicleModelGroup" }
    }
  }
}

export default SupplierServiceVehicleModelGroup

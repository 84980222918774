<style lang="scss" scoped>

.maintenance-package-header {
  .icon {
    margin-right: 16px;
    color: $gray-3;
    width: 16px;
    text-align: center;
  }

  .discarded-icon {
    margin-right: 4px;
  }

  .tag {
    margin-left: 4px;
  }

  .name {
    font-family: $secondary-font;
    font-size: 16px;
    font-weight: 400;
  }

  .maintenance-package-content {
    margin-top: 8px;

    .arrow {
      color: $light-gray-4;
    }
  }
}

</style>


<template lang="pug">

  .maintenance-package-header
    template(v-if="fetching")
      loading-lines(:lines="2")

    template(v-else)
      .flex.vertical-center
        i.icon.fas.fa-box
        .flex.vertical-bottom
          discarded-icon.discarded-icon(v-if="resource.$get('maintenancePackage.$discarded')")
          span.name(data-testid="maintenancePackageName") {{ resource.$get('maintenancePackage.name') }}
          id-tag.tag(data-testid="maintenancePackageId", :value="resource.$get('maintenancePackage.id')")

      .maintenance-package-content.flex.vertical-center
        i.icon.arrow.fal.fa-long-arrow-right
        i.icon.fas.fa-cars
        .flex.vertical-center
          span.name(data-testid="vehicleModelGroupName") {{ resource.$get('vehicleModelGroup.name') }}
          id-tag.tag(data-testid="vehicleModelGroupId", :value="resource.$get('vehicleModelGroup.id')")

</template>


<script>

export default {
  props: {
    resource: { type: Object, default: () => ({}) },
    fetching: { type: Boolean, default: false }
  },

  data() {
    return {
      i18nScope: "suppliers.deals.products.components.product-header"
    }
  }
}

</script>

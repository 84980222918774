<style lang="scss" scoped>

.services-table {
  .skeleton-table {
    margin-top: 16px;

    .skeleton-row + .skeleton-row {
      margin-top: 24px;
    }
  }

  .empty {
    margin-top: 24px;

    .message {
      font-style: italic;
      font-weight: 400;
    }

    .icon {
      user-select: none;
      margin-bottom: 4px;
      height: 88px;
      width: 88px;
    }
  }

  .list {
    margin-top: 16px;
  }
}

</style>


<template lang="pug">

  .services-table
    template(v-if="initializing")
      .skeleton-table
        service-row-skeleton.skeleton-row(
          v-for="i in 5",
          :key="`services-row-skeleton-${i}`"
        )

    template(v-else)
      services-table-header(
        :loading="loading",
        @sort="param => $emit('sort', param)"
        :sort-param="sortParam",
        :sort-order="sortOrder",
        data-testid="servicesTableHeader"
      )

      template(v-if="empty")
        .empty.flex.center
          .flex.column-direction.vertical-center
            img.icon(:src="emptyIcon")
            span.message {{ $t('index.empty.message') }}

      template(v-else)
        service-row(
          v-for="service in services",
          :key="service.id",
          :service="service",
          :loading="loading",
          @destroy="$emit('changed')",
          @disabled="$emit('changed')",
          @enabled="$emit('changed')",
          data-testid="serviceRow"
        )

</template>


<script>

// Assets
import emptyIcon from "@/assets/images/icons/vehicle-model-groups/empty.svg"

// Components
import ServiceRow from "./service-row.vue"
import ServiceRowSkeleton from "./service-row-skeleton"
import ServicesTableHeader from "./services-table-header"

export default {
  components: {
    ServiceRow,
    ServiceRowSkeleton,
    ServicesTableHeader
  },

  props: {
    services:     { type: Array, required: true },
    initializing: { type: Boolean, default: false },
    loading:      { type: Boolean, default: false },
    sortedBy:     { type: String, default: "" }
  },

  data() {
    return {
      i18nScope: "services.index.service-table",

      emptyIcon
    }
  },

  computed: {
    empty() {
      return _.blank(this.services)
    },

    sortParam() {
      return this.sortedBy.split(":")[0]
    },

    sortOrder() {
      return this.sortedBy.split(":")[1] || "asc"
    }
  }
}

</script>

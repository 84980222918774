<style lang="scss" scoped>
.section {
  padding: 16px 24px;

  + .section {
    border-top: 1px solid $light-gray-3;
  }
}
</style>

<template lang="pug">
  app-modal(
    :confirm-label="$t('.btn.confirm.label')",
    :confirm-label-loading="$t('.btn.confirm.label')",
    footer,
    :heading="$t('.heading')",
    :loading="submitting",
    :width="566",
    @close="onClose",
    @confirm="submit"
  )
    .new-supplier-authorization-mail-delivery
      .section
        service-order-info(
          :service-order="serviceOrder",
          :vehicle="serviceOrder.vehicle",
          :supplier="serviceOrder.supplier",
          :customer="serviceOrder.customer",
          :branch="serviceOrder.branch"
        )

      form.section(@submit.prevent="submit")
        clearable-input-field(
          v-model="resource.to"
          :disabled="submitting"
          :errors="errors.to"
          :label="$t('.fields.email.label')"
          mandatory
          name="supplierAuthorizationMailDelivery[to]"
          :placeholder="$t('.fields.email.placeholder')"
          type="email"
        )

</template>

<script>
import models from "@/models"
import ServiceOrderInfo from "@/views/monitoring/_components/service-order-info.vue"
import $validate from "@/lib/validate"

import ClearableInputField from "../../_components/clearable-input-field.vue"

const { MovidaServiceOrder, ServiceOrderSupplierAuthorizationMailDelivery } = models

export default {
  components: {
    ClearableInputField,
    ServiceOrderInfo
  },

  props: {
    serviceOrder: { type: MovidaServiceOrder, required: true }
  },

  data() {
    return {
      errors: {},

      resource: new ServiceOrderSupplierAuthorizationMailDelivery(),

      i18nScope: "monitoring.service-orders.supplier-authorization-mail-deliveries.new",

      initialData: null,

      submitRequest: null,

      submitting: false
    }
  },

  mounted() {
    this.initialData = this.serialize()
  },

  methods: {
    cancel() {
      if (this.submitRequest) {
        this.submitRequest.cancel()
        this.submitRequest = null
      }
    },

    checkDirty() {
      const currentData = this.serialize()

      return !_.isMatch(this.initialData, currentData)
    },

    async launchConfirmDialog() {
      const confirmed = await this.$confirm({
        heading: this.$t("mixins.form.confirmPageChange.heading"),
        message: this.$t("mixins.form.confirmPageChange.message"),

        actions: {
          confirm: this.$t("mixins.form.confirmPageChange.confirm"),
          cancel:  this.$t("mixins.form.confirmPageChange.cancel")
        }
      })

      if (confirmed) this.$emit("close")
    },

    notifyError() {
      this.$notifications.error(this.$t(".notifications.submit.error"))
    },

    notifySubmitting() {
      this.$notifications.warn(this.$t("requests.running.warning"))
    },

    notifySuccess({ to }) {
      this.$notifications.info(this.$t(".notifications.submit.success", { email: to }))
    },

    notifyValidationError() {
      this.$notifications.error(this.$t(".notifications.validation.error"))
    },

    onClose() {
      if (this.submitting) {
        this.notifySubmitting()
        return
      }

      if (this.checkDirty()) {
        this.launchConfirmDialog()
        return
      }

      this.$emit("close")
    },

    serialize() {
      return this.resource.$serialize()
    },

    async submit() {
      let request

      try {
        this.submitting = true

        if (!(await this.validate())) {
          this.notifyValidationError()
          return
        }

        const params = this.serialize()

        this.cancel()

        request = this.$newSdk.monitoring.serviceOrders.supplierAuthorizationMailDeliveries.create({
          serviceOrderId: this.serviceOrder.$id,
          params
        })

        this.submitRequest = request

        await request

        this.notifySuccess(params)

        this.$emit("close")
      }
      catch (error) {
        if (error.cancelled) return

        this.notifyError()
      }
      finally {
        this.submitting = false
        if (request === this.submitRequest) this.submitRequest = null
      }
    },

    async validate() {
      const errors = await $validate(this.resource)

      this.errors = errors
      return !_.present(errors)
    }
  }
}
</script>

<style lang="scss" scoped>

.deal-preview-icon {
  .missing {
    margin-left: 8px
  }

  .icon {
    cursor: pointer;
  }
}

.popover {
  padding: 16px;
}

</style>


<template lang="pug">

  .deal-preview-icon
    template(v-if="missing")
      img.missing(
        :src="missingIcon",
        v-tooltip.bottom="$t('.missing.tooltip')"
      )
    template(v-else)
      .section
        v-popover(
          trigger="hover",
          :delay="{ show: 300, hide: 300 }",
          :auto-hide="false",
          @apply-show="verifyAndFetch"
        )
          template(v-if="expiresSoon")
            img.icon(:src="expiresSoonIcon")
          template(v-else)
            img.icon(:src="ongoingIcon")

          template(slot="popover")
            .popover
              template(v-if="fetching")
                popover-preview-loading(:width="188")

              template(v-else-if="erred")
                popover-reload(@reload="fetch")

              template(v-else-if="!empty")
                deal-preview(:deal="resource")

</template>


<script>

// Mixins
import FetchMixin from "@/mixins/fetch-mixin"

// Assets
import ongoingIcon from "@/assets/images/icons/suppliers/deal-status/ongoing.svg"
import missingIcon from "@/assets/images/icons/suppliers/deal-status/missing.svg"
import expiresSoonIcon from "@/assets/images/icons/suppliers/deal-status/expires-soon.svg"

// Components
import PopoverPreviewLoading from "./popover-preview-loading.vue"
import DealPreview from "./deal-preview.vue"

export default {
  components: {
    PopoverPreviewLoading,
    DealPreview
  },

  mixins: [FetchMixin],

  props: {
    id:          { type: Number, default: null },
    expiresSoon: { type: Boolean, default: false }
  },

  data() {
    return {
      i18nScope: "suppliers.components.deal-preview-icon",
      autofetch: false,
      resource:  {},
      ongoingIcon,
      missingIcon,
      expiresSoonIcon
    }
  },

  computed: {
    missing() {
      return _.blank(this.id)
    },

    empty() {
      return _.blank(this.resource)
    }
  },

  methods: {
    verifyAndFetch() {
      if (_.present(this.resource)) return
      this.fetch()
    },

    fetchRequest() {
      return this.$newSdk.deals.find({ dealId: this.id })
    }
  }
}

</script>

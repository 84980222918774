import Product from "./product"

class MotorOil extends Product {
  static get attrs() {
    const attrs = super.attrs.filter(attr => attr !== "type")

    return attrs
  }

  // static get constraints() {}

  static get enums() {
    return {}
  }

  static get virtuals() {
    return [
      "type"
    ]
  }

  // virtuals
  get type() {
    return "MotorOil"
  }
}

export default MotorOil

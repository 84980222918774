<style lang="scss" scoped>

.option {
  min-height: 40px;
  padding: 8px 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid $light-gray-3;
  cursor: pointer;

  .remove-icon-content {
    width: 24px;
    height: 24px;
    line-height: 24px;
    text-align: center;
    border-radius: 50px;
    color: $gray-2;
    transition: all 100ms linear;
  }

  &.active {
    color: $primary;
    background: $light-gray-2;
  }

  &.focus {
    background: $light-gray-2;
    color: $dark-primary-2;

    .remove-icon-content {
      background: $light-gray-3;
      color: $primary;
    }
  }
}

.error {
  display: block;
  text-align: center;
  padding: 4px 12px;
  font-size: 14px;
  font-style: italic;

  .link {
    margin-left: 5px;
    font-size: 14px;
    font-style: italic;
    font-weight: normal;
  }
}

.empty {
  display: block;
  text-align: center;
  padding: 4px 12px;
  font-size: 14px;
  font-style: italic;
}

</style>


<template lang="pug">

  .store-select-field
    select-field(
      :name="name",
      :label="label",
      :fetching="fetching",
      :disabled="disabled",
      :placeholder="placeholder",
      :optional="optional",

      option-as-value,
      :loading="loading || fetchingOption",
      :options="options",
      :default-filter="false",
      :errors="errors",
      searchable,

      :value="option",
      @input="onSelect",

      @search="onSearch",
      @open="_fetch",
      @close="onClose",
      @inputSearch="(newSearch) => search = newSearch"
    )
      template(#menuContentHeader)
        template(v-if="fetchError")
          label.error
            span {{ $t('.error.message') }}
            app-button.link(theme="link", @click="onSearch") {{ $t('.error.link') }}

      template(#menuContentFooter)
        template(v-if="!fetching && !fetchError && empty")
          label.empty {{ $t('.empty') }}

      template(#fetching)
        li.option {{ $t('.loading') }}

      template(#option="{ props: { option, index, focus, active } }")
        .option(:class="{ focus, active }")
          span {{ option.label }}
          .remove-icon-content(v-if="active")
            i.fal.fa-times

</template>


<script>

// Mixins
import FieldMixin from "@/mixins/field"
import AsyncSelect from "@/mixins/select/async"

import models from "@/models"

export default {
  name: "StoreSelectField",

  extends: AsyncSelect,

  mixins: [FieldMixin],

  data() {
    return {
      i18nScope: "components.store.store-select-field",

      model: "Store",

      fetchingOption: false,
      fetchError:     false
    }
  },

  methods: {
    // @overrides extends AsyncSelect
    dataToOption(data) {
      const formattedData = (_.present(this.model))
        ? new models[this.model](data) : data

      return {
        value: formattedData.id,
        label: `${formattedData.initials} - ${formattedData.name}`,
        data:  formattedData
      }
    },

    // @overrides extends AsyncSelect
    async fetchOption(id) {
      try {
        this.fetchingOption = true

        const response = await this.$sdk.stores.get({ id })
        const normalizedData = _.camelizeKeys(response.data)

        this.option = this.dataToOption(normalizedData)
      }
      catch (err) {
        this.$err.log(err)
      }
      finally {
        this.fetchingOption = false
      }
    },

    // @overrides extends AsyncSelect
    fetchRequest() {
      return this.$sdk.stores.list({ params: { q: this.search } })
    },

    // @overrides extends AsyncSelect
    onFetchSuccess(response, { normalizedData }) {
      const fetchedOptions = normalizedData.map(data => (
        this.dataToOption(data)
      ))

      this.options =  [...fetchedOptions]
    },

    // @overrides extends AsyncSelect
    onFetchError(error) {
      this.$err.log(error)
    }
  }
}

</script>

<!-- <template lang="pug">
  //- XXX: não há extensão do template
  select-field(v-bind="$props", v-on="$listeners" :options="options")
</template>
 -->


<script>

// Models
import models from "@/models"

// Components
import SelectField from "@/components/form/select-field"

const { VehicleModelGroupMaintenanceTable } = models


export default {
  extends: SelectField,

  props: {
    options: {
      type:    Array,
      default: () => VehicleModelGroupMaintenanceTable.$enums.kind.values.map(kind => ({
        label: VehicleModelGroupMaintenanceTable.$tEnum("kind", kind),
        value: kind
      }))
    }
  }
}

</script>

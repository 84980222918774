<style lang="scss" scoped>

.service-orders {
  padding-bottom: 24px;

  .clear-filters {
    position: relative;

    .filters-button {
      position: absolute;
      margin-top: 10px;
    }
  }

  .list {
    margin-top: 50px;

    .table {
      margin-top: 16px;
    }
  }

  .paginator-content {
    padding-top: 24px;

    &.loading {
      max-width: 400px;
      margin: 0 auto;
    }
  }
}

</style>


<template lang="pug">

  .service-orders
    search-form.search(
      :loading="fetching",
      :params="parsedQueryParams",
      @search="search"
    )

    .clear-filters
      filters-button.filters-button(
        v-if="hasFilters",
        @clear="clearFilters"
      )

    .list
      .flex.end.vertical-bottom
        results-indicator(
          :page="parsedQueryParams.page",
          :per-page="pagination.perPage",
          :total="pagination.total",
          :loading="fetching"
        )

      service-orders-table.table(
        :service-orders="serviceOrders",
        :initializing="initializing",
        :loading="fetching",
        :sorted-by="parsedQueryParams.sort",
        @sort="onSortChange",
        @changed="fetch"
      )

    .paginator-content(v-if="fetching || showPaginator", :class="{ loading: fetching }")
      paginator(
        :loading="fetching",
        :per-page="pagination.perPage",
        :total="pagination.total",
        :value="parsedQueryParams.page",
        :disabled="fetching",
        @input="onPageChange",
        data-testid="paginator"
      )

</template>


<script>

// Models
import models from "@/models"

// View
import IndexView from "@/views/index-view"

// Mixins
import FetchMixin from "@/mixins/fetch-mixin"

// Components
import ServiceOrdersTable from "./index/service-orders-table"
import SearchForm from "./index/search-form"

export default {
  name: "ServiceOrders",

  components: {
    ServiceOrdersTable,
    SearchForm
  },

  extends: IndexView,

  mixins: [FetchMixin],

  data() {
    return {
      i18nScope: "service-orders.index",
      routeName: "serviceOrders",

      resource: [],

      // @requirement IndexView
      queryParamsStructure: {
        q:           "string",
        store_id:    "integer",
        type:        "string",
        state:       "string",
        supplier_id: "integer",
        sap_sync:    "string",
        since:       "string",
        until:       "string",
        page:        "integer",
        sort:        "string"
      }
    }
  },

  computed: {
    serviceOrders: {
      get() { return this.resource },

      set(value) { this.resource = value }
    }
  },

  methods: {
    parseRoute() {},

    // @override Fetch Mixin
    fetchRequest() {
      return this.$newSdk.serviceOrders.all({ params: this.requestQueryParams })
    },

    // @override Fetch Mixin
    onFetchSuccess({ data, headers }) {
      const serviceOrderModels = {
        "ServiceOrders::InternalMaintenance": "ServiceOrderInternalMaintenance",
        "ServiceOrders::Maintenance":         "ServiceOrderExternalMaintenance",
        "ServiceOrders::Repair":              "ServiceOrderRepair"
      }

      this.resource = data.map(serviceOrder => {
        const modelName = serviceOrderModels[serviceOrder.type]
        return new models[modelName](serviceOrder)
      })

      this.setPagination(headers)
    }
  }
}

</script>

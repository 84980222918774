// Base
import BaseRequests from "./Base"

class ProductsRequests extends BaseRequests {
  static get selector() {
    return "products"
  }

  all({ params = {}, options = {} } = {}) {
    return this.action({
      method:  "get",
      url:     "/products",
      params,
      options: {
        // XXX: Cada produto tem um modelo diferente baseado no tipo dele, e isso precisa ser tratado no componente.
        model: null,

        ...options
      }
    })
  }

  find({ productId, params = {}, options = {} }) {
    if (_.blank(productId)) {
      throw new Error('SDK Products - #find() - "productId" is a required prop')
    }

    return this.action({
      method:  "get",
      url:     `/products/${productId}`,
      params,
      options: {
        // XXX: Cada produto tem um modelo diferente baseado no tipo dele, e isso precisa ser tratado no componente.
        model: null,

        ...options
      }
    })
  }

  create({ params = {}, options = {} } = {}) {
    return this.action({
      method:  "post",
      url:     "/products",
      params,
      options: {
        // XXX: Cada produto tem um modelo diferente baseado no tipo dele, e isso precisa ser tratado no componente.
        model: null,

        ...options
      }
    })
  }

  update({ productId, params = {}, options = {} } = {}) {
    if (_.blank(productId)) {
      throw new Error('SDK Products - #update() - "productId" is a required prop')
    }

    return this.action({
      method:  "patch",
      url:     `/products/${productId}`,
      params,
      options: {
        // XXX: Cada produto tem um modelo diferente baseado no tipo dele, e isso precisa ser tratado no componente.
        model: null,

        ...options
      }
    })
  }

  enable({ productId, params = {}, options = {} } = {}) {
    if (_.blank(productId)) {
      throw new Error('NewSDK products - #enable() - "productId" is a required prop')
    }

    return this.action({
      method: "delete",
      url:    `/products/${productId}/discard`,
      params,
      options
    })
  }

  disable({ productId, params = {}, options = {} } = {}) {
    if (_.blank(productId)) {
      throw new Error('NewSDK products - #disable() - "productId" is a required prop')
    }

    return this.action({
      method: "post",
      url:    `/products/${productId}/discard`,
      params,
      options
    })
  }
}

export default ProductsRequests

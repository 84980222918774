<script>

import SupplierInfoCard from "../components/supplier-info-card.vue"

export default {
  extends: SupplierInfoCard,

  computed: {
    distanceToStore() {
      return _.get(this.serviceOrderSupplier, "bookingHistory.distance")
    }
  }
}

</script>

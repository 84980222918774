<style lang="scss" scoped>

.combustion-form-fields {
  .subtitle {
    font-weight: bold;
    font-size: 20px;
    font-family: $secondary-font;
    font-weight: 500;
  }

  .section-message {
    display: block;
    font-size: 16px;
    margin-bottom: 16px;
    line-height: 22px;
  }

  .section {
    border-bottom: 1px solid $light-gray-3;
    padding: 24px 0;
  }

  .row:not(:first-of-type) {
    padding-top: 24px;
  }
}

</style>


<template lang="pug">

  .combustion-form-fields
    h2.subtitle {{ $t('.sections.fuelAndOil.subtitle') }}
    span.section-message {{ $t('.sections.fuelAndOil.message') }}

    ui-row.row
      ui-col(:span="3")
        fuel-select-field(
          name="vehicleModelCombustion[fuel]",
          v-model="resource.fuel",
          :loading="submitting",
          :disabled="fetching",
          :errors="errors.fuel",
          :placeholder="$t('.fields.fuel.placeholder')"
        )

      ui-col(:span="3")
        money-field(
          name="vehicleModelCombustion[fuelTankCapacity]",
          v-model.number="resource.fuelTankCapacity",
          :loading="submitting",
          :disabled="fetching",
          :errors="errors.fuelTankCapacity",
          :placeholder="$t('.fields.fuelTankCapacity.placeholder')",
          :suffix="$t('.fields.motorOilCapacity.suffix')",
          :maxlength="6",
          :show-currency="false"
          data-testid= "fuelTankCapacity"
        )

      ui-col(:span="3")
        money-field(
          name="vehicleModelCombustion[motorOilCapacity]",
          v-model.number="resource.motorOilCapacity",
          :loading="submitting",
          :disabled="fetching",
          :errors="errors.motorOilCapacity",
          :placeholder="$t('.fields.motorOilCapacity.placeholder')",
          :suffix="$t('.fields.motorOilCapacity.suffix')",
          :maxlength="6",
          :show-currency="false"
          data-testid= "motorOilCapacity"
        )

</template>


<script>

import FuelSelectField from "./fuel-select-field"

export default {
  components: {
    FuelSelectField
  },

  props: {
    fetching:   { type: Boolean, default: false },
    submitting: { type: Boolean, default: false },
    errors:     { type: Object, default: () => ({}) },
    resource:   { type: Object, default: () => ({}) }
  },

  data() {
    return {
      i18nScope: "vehicle-models.forms.form-fields.combustion-form-fields"
    }
  }
}

</script>

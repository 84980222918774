// Base
import BaseRequests from "../Base"

// Models
import models from "@/models"
const { VehicleModelGroupMaintenanceTable } = models

class VehicleModelGroupMaintenanceTablesRequests extends BaseRequests {
  static get selector() {
    return "vehicleModelGroups.maintenanceTables"
  }

  all({ vehicleModelGroupId, params = {}, options = {} } = {}) {
    if (_.blank(vehicleModelGroupId)) {
      throw new Error('NewSDK vehicleModelGroups.maintenanceTables - #all() - "vehicleModelGroupId" is a required prop')
    }

    return this.action({
      method:  "get",
      url:     `/vehicle_model_groups/${vehicleModelGroupId}/maintenance_tables`,
      params,
      options: {
        model: VehicleModelGroupMaintenanceTable,

        ...options
      }
    })
  }
}

export default VehicleModelGroupMaintenanceTablesRequests

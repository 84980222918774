<style scoped lang="scss">

.phone-nested-fields {
  .fields + .fields {
    margin-top: 12px;
  }
}

</style>


<template lang="pug">

  .phone-nested-fields
    template(v-for="(resource, index) in nonDestroyedCollection")
      phone-fields.fields(
        :key="`phone-${keyFor(resource)}`",
        :name="`${name}[${keyFor(resource)}]`",
        :resource="resource",
        :errors="nestedErrors[keyFor(resource)]",
        :is-first="index === 0",
        :can-add="index === visibleResourcesCount - 1",
        @clear="clear(resource)",
        @add="add"
      )

</template>


<script>

// Mixins
import NestedFormMixin from "@/mixins/nested-form"

// Components
import PhoneFields from "./phone-fields"

export default {
  components: {
    PhoneFields
  },

  mixins: [NestedFormMixin],

  props: {
    errors: { type: Object, default: () => ({}) },
    name:   { type: String, required: true }
  },

  data() {
    return {
      i18nScope: "suppliers.contacts.form-fields.phone-nested-fields",

      model:          "Phone",
      nestedResource: "phones"
    }
  },

  computed: {
    nonDestroyedCollection() {
      return this.collection.filter(resource => !resource.$markedForDestruction)
    },

    visibleResourcesCount() {
      return this.nonDestroyedCollection.length
    }
  },

  methods: {
    indexFor(resource) {
      return _.findIndex(this.collection, entry => this.keyFor(resource) === this.keyFor(entry))
    },

    initialParamsForNewResource() {
      // XXX Apenas números nacionais por enquanto
      return { ddi: "55" }
    },

    clear(resource) {
      if (this.visibleResourcesCount === 1) {
        resource.value = null
        resource.kind = null
      }
      else this.remove(this.indexFor(resource))
    }
  }
}

</script>

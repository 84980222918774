<script>

// Extends
import AttachmentThumbnail from "./attachment-thumbnail.vue"

export default {
  name: "QuoteAttachmentThumbnail",

  extends: AttachmentThumbnail,

  props: {
    quoteId: { type: [String, Number], required: true }
  },

  methods: {
    // @override Fetch mixin
    fetchRequest() {
      return this.$newSdk.quotes.blobs.getDownloadURL({ quoteId: this.quoteId, blobId: this.attachment.blobId })
    }
  }
}

</script>

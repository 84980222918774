<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.7188 16.125L16.9375 13.75C16.5938 12.7188 15.625 12 14.5625 12H9.40625C8.34375 12 7.375 12.7188 7.03125 13.75L6.25 16.125C5.25 16.4375 4.5 17.375 4.5 18.5V22.5C4.5 23.3438 5.15625 24 6 24H6.5C7.3125 24 8 23.3438 8 22.5V22H16V22.5C16 23.3438 16.6562 24 17.5 24H18C18.8125 24 19.5 23.3438 19.5 22.5V18.5C19.5 17.375 18.7188 16.4375 17.7188 16.125ZM8 14.0625C8.1875 13.4375 8.75 13 9.40625 13H14.5625C15.2188 13 15.7812 13.4375 15.9688 14.0625L16.625 16H7.34375L8 14.0625ZM7 22.5C7 22.7812 6.75 23 6.5 23H6C5.71875 23 5.5 22.7812 5.5 22.5V22H7V22.5ZM18.5 22.5C18.5 22.7812 18.25 23 18 23H17.5C17.2188 23 17 22.7812 17 22.5V22H18.5V22.5ZM18.5 20V21H5.5V18.5C5.5 17.6875 6.15625 17 7 17H17C17.8125 17 18.5 17.6875 18.5 18.5V20ZM7.75 17.75C6.9375 17.75 6.375 18.2812 6.375 19C6.375 19.75 6.9375 20.25 7.75 20.25H8.0625C9.3125 20.25 9.5 19.625 9.5 19.25C9.5 18.5 8.5 17.75 7.75 17.75ZM8.0625 19.5C7.96875 19.5 7.84375 19.5 7.75 19.5C7.375 19.5 7.125 19.3125 7.125 19C7.125 18.7188 7.375 18.5 7.75 18.5C8.15625 18.5 8.75 18.9688 8.75 19.25C8.75 19.5 8.40625 19.5 8.0625 19.5ZM16.2188 17.75C15.4688 17.75 14.5 18.5 14.5 19.25C14.5 19.625 14.6562 20.2812 15.9062 20.2812L16.2188 20.25C17.0312 20.25 17.625 19.75 17.625 19C17.625 18.2812 17.0312 17.75 16.2188 17.75ZM16.2188 19.5C16.125 19.5 16 19.5 15.9062 19.5C15.5625 19.5 15.25 19.5 15.25 19.25C15.25 18.9688 15.8125 18.5 16.2188 18.5C16.5938 18.5 16.875 18.7188 16.875 19C16.875 19.3125 16.5938 19.5 16.2188 19.5Z"
      :fill="colorHash"
    />
    <path
      d="M11.7283 7.4375L11.1603 8.02604L10.8399 10H10L11.2962 2H12.1361L11.3691 6.72396L13.3013 4.36458H14.3742L12.3255 6.77083L13.612 10H12.6944L11.7283 7.4375Z"
      :fill="colorHash"
    />
    <path
      d="M16.0929 4.35938L15.9909 4.88542C16.3631 4.45486 16.7936 4.24653 17.2823 4.26042C17.538 4.26736 17.7613 4.33854 17.9523 4.47396C18.1465 4.60938 18.284 4.80903 18.3649 5.07292C18.7824 4.52083 19.2598 4.24826 19.7971 4.25521C20.1499 4.26562 20.4282 4.38542 20.6321 4.61458C20.8361 4.84375 20.9542 5.16319 20.9866 5.57292C21.006 5.8125 21.0044 6.05382 20.9817 6.29688L20.404 10H19.5738L20.1467 6.28646L20.1612 5.84896C20.1548 5.60243 20.0933 5.41146 19.9767 5.27604C19.8635 5.13715 19.7 5.06771 19.4864 5.06771C19.2307 5.06076 19.0009 5.17188 18.797 5.40104C18.5931 5.63021 18.4685 5.90278 18.4232 6.21875V6.24479L17.826 10H16.9813L17.559 6.29167C17.572 6.15278 17.5752 6.01042 17.5687 5.86458C17.5461 5.35417 17.326 5.09028 16.9085 5.07292C16.5104 5.06597 16.1786 5.29861 15.9132 5.77083L15.2335 10H14.3985L15.3112 4.36458L16.0929 4.35938Z"
      :fill="colorHash"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M6 1H5C3.89543 1 3 1.57953 3 2.5V7.5C3 8.42048 3.89543 9 5 9H6C7.10457 9 8 8.42048 8 7.5V2.5C8 1.57953 7.10457 1 6 1ZM5 0C3.34315 0 2 1.11929 2 2.5V7.5C2 8.88071 3.34315 10 5 10H6C7.65685 10 9 8.88071 9 7.5V2.5C9 1.11929 7.65685 0 6 0H5Z"
      :fill="colorHash"
    />
    <path
      d="M6.66518 0.655935L7.74475 0.945204L5.05575 9.64415L3.97619 9.35488L6.66518 0.655935Z"
      :fill="colorHash"
    />
  </svg>
</template>


<script>

import SupplierIconBase from "./supplier-icon-base"

export default {
  name: "ZeroKmIcon",

  extends: SupplierIconBase
}

</script>

<style scoped lang="scss">

.type-icon {
  margin-right: 8px;
  font-size: 18px;
}

.type-text {
  font-size: 14px;
  font-weight: normal;
}

.type-col {
  width: 165px;
}

.name-col {
  .discarded-icon {
    margin-right: 4px;
  }
}

.price-col {
  text-align: right;
  width: 150px;
}

.price  {
  &.free {
    text-decoration-line: line-through;
    color: $gray-2;
  }
}

</style>


<template lang="pug">

  tr.product-row.app-table-row-light
    td.code-col {{ product.sapId }}

    td.type-col
      .flex.vertical-center
        product-type-icon.type-icon(:type="product.type")
        span.no-shrink {{ $t(`models.product.enums.type.${product.type}`) }}

    td.name-col
      discarded-icon.discarded-icon(v-if="product.$discarded")
      span {{ product.name }}

    td.part-origin-col
      template(v-if="!!product.partOrigin")
        span {{ $t(`models.dealProduct.enums.partOrigin.${product.partOrigin}`) }}

    td.price-col
      span.price {{ quantity }}

    td.price-col
      span.price {{ $l('currency', product.price) }}

    td.price-col
      span.price(:class="{ free: product.freeOfCharge }") {{ $l('currency', product.total) }}

</template>


<script>

export default {
  props: {
    product: { type: Object, default: () => ({}) }
  },

  data() {
    return {
      i18nScope: "stores.tickets.service-orders.show.product-row"
    }
  },

  computed: {
    quantity() {
      const translation = (this.product.type === "MotorOil") ? ".liter" : ".unit"

      return this.$t(translation, { value: this.product.quantity })
    }
  }
}

</script>


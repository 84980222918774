<style scoped lang="scss">

.icon {
  margin-right: 8px;
}

.progress-bar  {
  width: 100%;
  height: 10px;
  background-color: $light-gray-3;
  border-radius: 4px;
  padding-top: 1px;
  margin-right: 8px;
}

.inner-bar {
  width: 25%;
  height: 8px;
  background-color: $primary;
  border-radius: 4px;
}

.percentage {
  font-size: 12px;
}

</style>


<template lang="pug">

  .download-button
    app-button(
      :loading="downloading",
      theme="neutral",
      size="small",
      @click="download"
    )
      i.fal.fa-download.icon
      span {{ $t('.btn.download') }}

    .progress-content.flex.vertical-center(v-if="downloading")
      .progress-bar
        .inner-bar(:style="percentageStyle")
      span.percentage {{ `${percentage}%` }}

</template>


<script>

export default {

  props: {
    serviceOrder: { type: Object, default: () => ({}) }
  },

  data() {
    return {
      i18nScope: "stores.tickets.service-orders.show.download-button",

      downloading: false,

      id: this.$route.params.serviceOrderId,

      downloadProgress: {
        loaded: 0,
        total:  0
      }
    }
  },

  computed: {
    filename() {
      if (_.blank(this.serviceOrder.code)) return `undefined.pdf`

      const name = this.serviceOrder.code.replace(/\//g, "-")

      return `${name}.pdf`
    },

    percentage() {
      const { total, loaded } = this.downloadProgress

      // Evita divisão por 0
      if (!total) return 0

      let value = (loaded / total) * 100
      return parseInt(value, 10)
    },

    percentageStyle() {
      return {
        width: `${this.percentage}%`
      }
    }
  },

  methods: {
    async download() {
      try {
        await this.xhrDownloadRequest(`/service_orders/${this.id}/download`, this.filename)
        this.$notifications.info(this.$t(".notifications.download.success", { filename: this.filename }))
      }
      catch (error) {
        this.$notifications.error(this.$t(".notifications.download.failure", { filename: this.filename }))
      }
    },

    xhrDownloadRequest(url, filename = null) {
      return new Promise(async (resolve, reject) => {
        this.downloading = true

        try {
          const response = await this.$http.get(`/service_orders/${this.id}/download`, {
            responseType:       "blob",
            onDownloadProgress: ({ loaded, total }) => {
              this.downloadProgress = { loaded, total }
            }
          })

          this.startDownload(response.data, this.filename)
          resolve()
        }
        catch (error) {
          reject(error)
        }
        finally {
          this.downloading = false
          this.downloadProgress = { loaded: 0, total: 0 }
        }
      })
    },

    startDownload(data, filename) {
      const url = window.URL.createObjectURL(new Blob([data]))
      const link = document.createElement("a")

      link.href = url
      link.setAttribute("download", this.filename)

      document.body.appendChild(link)
      link.click()
      link.parentNode.removeChild(link)
    }
  }
}

</script>

// Base
import BaseRequests from "../Base"

class TicketsDraftsRequests extends BaseRequests {
  static get selector() {
    return "tickets.drafts"
  }

  find({ ticketId, params = {}, options = {} }) {
    this.verifyData("find", { ticketId })

    return this.action({
      method:  "get",
      url:     `/tickets/${ticketId}/draft`,
      params,
      options: {
        model: null,

        ...options
      }
    })
  }

  create({ params = {}, options = {} }) {
    return this.action({
      method:  "post",
      url:     `/tickets/draft`,
      params,
      options: {
        model: null,

        ...options
      }
    })
  }

  update({ ticketId, params = {}, options = {} }) {
    this.verifyData("update", { ticketId })

    return this.action({
      method:  "patch",
      url:     `/tickets/${ticketId}/draft`,
      params,
      options: {
        model: null,

        ...options
      }
    })
  }

  destroy({ ticketId, params = {}, options = {} }) {
    this.verifyData("destroy", { ticketId })

    return this.action({
      method: "delete",
      url:    `/tickets/${ticketId}/draft`,
      params,
      options
    })
  }
}

export default TicketsDraftsRequests

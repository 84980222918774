<style scoped lang="scss">

.loading-lines {
  .shine-loading {
    background: #efefef;
    background-image: linear-gradient(to right, #efefef 5%, #f9f9f9 15%, #efefef 40%, #efefef 100%);
    background-repeat: no-repeat;
    background-size: 800px 104px;
    display: inline-block;
    position: relative;
    border-radius: 6px;

    animation-duration: 0.7s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: placeholderShimmer;
    animation-timing-function: linear;
  }

  .loading-line + .loading-line {
    margin-top: 8px;
  }

  .loading-line {
    display: flex;
  }

  .icon-load {
    width: 26px;
    margin-right: 12px;
  }

  @keyframes placeholderShimmer {
    0% {
      background-position: -500px 0;
    }

    100% {
      background-position: 500px 0;
    }
  }
}

</style>


<template lang="pug">

  .loading-lines(role="progressbar")
    .loading-line(v-for="line in lines", :index="line")
      .shine-loading.icon-load(v-if="icon", :style="iconStyle", data-test="icon")
      .shine-loading(:style="barStyle()", data-test="bar")

</template>


<script>
export default {
  name: "AppLoadingLines",

  emits: [],

  props: {
    lines:    { type: Number, default: 1  },
    height:   { type: Number, default: 20 },
    minWidth: { type: Number, default: 25 },
    maxWidth: { type: Number, default: 70 },
    icon:     { type: Boolean, default: false }
  },

  computed: {
    iconStyle() {
      return {
        height: `${this.height}px`
      }
    }
  },
  methods: {
    barStyle() {
      return {
        width:  `${Math.floor(Math.random() * (this.maxWidth - this.minWidth + 1)) + this.minWidth}%`,
        height: `${this.height}px`
      }
    }
  }
}

</script>

<style scoped lang="scss">

.email-nested-fields {
  .fields + .fields {
    margin-top: 12px;
  }
}

</style>


<template lang="pug">

  .email-nested-fields
    template(v-for="(resource, index) in nonDestroyedCollection")
      email-fields.fields(
        :key="`email-${keyFor(resource)}`",
        :name="`${name}[${keyFor(resource)}]`",
        :resource="resource",
        :errors="nestedErrors[keyFor(resource)]",
        :is-first="index === 0",
        :can-add="index === visibleResourcesCount - 1",
        @clear="clear(resource, index)"
        @add="add"
      )

</template>


<script>

// Mixins
import NestedFormMixin from "@/mixins/nested-form"

// Components
import EmailFields from "./email-fields"

export default {
  components: {
    EmailFields
  },

  mixins: [NestedFormMixin],

  props: {
    errors: { type: Object, default: () => ({}) },
    name:   { type: String, required: true }
  },

  data() {
    return {
      i18nScope: "suppliers.contacts.form-fields.email-nested-fields",

      model:          "Email",
      nestedResource: "emails"
    }
  },

  computed: {
    nonDestroyedCollection() {
      return this.collection.filter(resource => !resource.$markedForDestruction)
    },

    visibleResourcesCount() {
      return this.nonDestroyedCollection.length
    }
  },

  methods: {
    indexFor(resource) {
      return _.findIndex(this.collection, entry => this.keyFor(resource) === this.keyFor(entry))
    },

    clear(resource, index) {
      if (this.visibleResourcesCount === 1) {
        resource.value = null
        resource.kind = null
      }
      else this.remove(this.indexFor(resource))
    }
  }
}

</script>

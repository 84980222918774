import { Model } from "@caiena/model"
import Base from "./base"

class ServiceOrder extends Base {
  static get attrs() {
    return _.uniq([
      ...super.attrs,
      "externalId",
      "supplierId",
      "ticketId",
      "storeId",
      "sapId",
      "estimatedCompletedAt",
      "estimatedStartAt",
      "issuedAt",
      "code",
      "description",
      "note",
      "pickupService",
      "sapSync",
      "state",
      "storeFleetManagerEmail",
      "storeFleetManagerName",
      "total",
      "type",
      "vehicleKm",
      "vehicleLicensePlate"
    ])
  }

  static get relations() {
    return {
      ticket:      { type: "belongsTo", model: "Ticket"     },
      supplier:    { type: "belongsTo", model: "Supplier"   },
      vehicle:     { type: "belongsTo", model: "Vehicle"    },
      store:       { type: "belongsTo", model: "Store"      },
      attachments: { type: "hasMany",   model: "Attachment" }
    }
  }

  static get enums() {
    return {
      type: {
        "ServiceOrders::InternalMaintenance": "ServiceOrders::InternalMaintenance",
        "ServiceOrders::Maintenance":         "ServiceOrders::Maintenance",
        "ServiceOrders::Repair":              "ServiceOrders::Repair"
      },

      state: {
        draft:    "draft",
        open:     "open",
        ongoing:  "ongoing",
        executed: "executed",
        // XXX: o estado "done" não é usado ainda
        // done:     'done',
        canceled: "canceled"
      },

      sapSync: {
        success: "success",
        running: "running",
        error:   "error"
      }
    }
  }

  static get constraints() {
    return {
      type: { presence: true }
    }
  }

  static get classNames() {
    return [
      "ServiceOrder",
      "ServiceOrderInternalMaintenance",
      "ServiceOrderExternalMaintenance",
      "ServiceOrderRepair"
    ]
  }

  static build(data) {
    switch (_.get(data, "type")) {
      case "ServiceOrders::InternalMaintenance": {
        const ServiceOrderInternalMaintenance = Model.$lookupModel("ServiceOrderInternalMaintenance")
        return new ServiceOrderInternalMaintenance(data)
      }
      case "ServiceOrders::Maintenance": {
        const ServiceOrderExternalMaintenance = Model.$lookupModel("ServiceOrderExternalMaintenance")
        return new ServiceOrderExternalMaintenance(data)
      }
      case "ServiceOrders::Repair": {
        const ServiceOrderRepair = Model.$lookupModel("ServiceOrderRepair")
        return new ServiceOrderRepair(data)
      }
      default:
        return new ServiceOrder(data)
    }
  }

  get facilities() {
    const external = ["ServiceOrders::Maintenance"]
    const internal = ["ServiceOrders::InternalMaintenance"]

    if (external.includes(this.type)) return "external"
    if (internal.includes(this.type)) return "internal"
    return null
  }

  // XXX: "tem custo", independente de ter itens "freeOfCharge" ("sem cobrança"), pois pode
  // ter itens com preço 0.0 (sem marcação de "sem cobrança"/"freeOfcharge")
  // - o this.total é calculado: (total_nominal - descontos_concedidos)
  get hasCost() {
    return _.present(this.total) && this.total > 0
  }
}

export default ServiceOrder

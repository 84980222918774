<style scoped lang="scss">

.service-order-internal-maintenance {
  .header {
    border-bottom: 1px solid $light-gray-4;
    padding-bottom: 32px;
    margin-bottom: 32px;

    .context {
      margin-bottom: 8px;
    }

    .sap-sync {
      margin-bottom: 8px;
    }

    .title-content {
      margin-bottom: 4px;

      .state-icon {
        margin-right: 8px;
      }

      .title {
        font-size: 30px;
        font-weight: 500;
        font-family: $secondary-font;
      }

      .tags {
        .tag {
          margin-left: 8px;
        }
      }
    }
  }

  .diamond-icon {
    margin: 0 8px;
    font-size: 4px;
    color: $gray-3;
  }

  .column {
    width: calc(50% - 8px);
    margin-right: 8px;

    + .column {
      margin-right: 0px;
      margin-left: 8px;
    }
  }

  .info {
    .info-label + .info-label {
      margin-top: 24px;
    }

    .info-label {
      ::v-deep {
        .movida-common__info-label-content {
          align-items: flex-start;
        }

        .movida-common__info-label-info-content {
          width: 100%;
        }
      }

      .icon {
        color: $dark-gray;
        font-size: 20px;
        width: 20px;
        text-align: center;
        margin-right: 16px;
      }

      .facilities {
        margin-right: 8px;
      }
    }
  }

  .relations {
    margin-top: 24px;

    .relation {
      border: 1px solid $light-gray-3;
      border-radius: 4px;
      padding: 16px;
      height: 100%;
    }
  }

  .free-of-charge,
  .total,
  .products-table,
  .tables,
  .note-section {
    margin-top: 32px;
  }

  .free-of-charge {
    .free-tag {
      margin-right: 8px;
    }
  }

  .note-section {
    .note-icon {
      margin-right: 8px;
    }

    .note {
      padding: 12px;
      font-size: 16px;
      font-weight: 300;
      white-space: pre;
    }
  }
}

</style>


<template lang="pug">

  .service-order-internal-maintenance
    header.header
      .context.flex.space-between
        breadcrumbs.breadcrumbs(:links="breadcrumbLinks")
        download-button(:service-order="resource")

      sap-sync.sap-sync(
        v-if="needsSAPSync",
        :id="serviceOrderId",
        @synced="$emit('synced')"
      )
      .title-content.flex.vertical-baseline
        service-order-state-icon.state-icon(:state="resource.state", size="big")
        h1.title {{ $t('.title') }}
        .tags.flex.vertical-bottom.grow
          id-tag.tag(
            :value="resource.code",
            size="big",
            without-icon,
            v-tooltip.bottom="$t('.fields.code.tooltip')"
          )
          vetor-code-tag.tag(
            :value="resource.externalId",
            size="big",
            v-tooltip.bottom="$t('.fields.externalId.tooltip')"
          )
          sap-code-tag.sap.tag(
            :value="resource.sapId",
            size="big",
            v-tooltip.bottom="$t('.fields.sapId.tooltip')"
          )
          facilities-tag.tag(
            value="internal",
            size="big",
            v-tooltip.bottom="$t('.fields.facilities.internal.tooltip')"
          )

      .flex.vertical-center.row
        span {{ $t('.createdAt', { date: $standardTime(resource.createdAt) }) }}
        i.fas.fa-diamond.diamond-icon
        span {{ $t('date.timezone') }}

    .info.flex
      .column
        info-label.info-label(
          :loading="fetching",
          data-testid="issuedAt"
        )
          template(#label)
            .flex
              label.movida-common__info-label-label {{ $t('.fields.issuedAt.label') }}
              i.info-icon.movida-common__info-label-info-icon.far.fa-info-circle(
                v-tooltip.bottom="issuedAtTooltipOptions"
              )
          template(#icon)
            i.icon.fal.fa-file-import
          template(#default)
            span.row {{ $standardTime(resource.issuedAt) }}

        info-label.info-label(
          :loading="fetching",
          :label="$t('.fields.supplierNotification.label')"
          data-testid="supplierNotification"
        )
          template(#icon)
            i.icon.fal.fa-envelope
          template(#default)
            span.row {{ supplier.serviceOrderContactName }}
            supplier-email-history(
              :service-order-id="serviceOrderId",
              :default-email-address="supplier.serviceOrderEmail",
              data-testid="supplierEmailHistory"
            )

        info-label.info-label(
          :loading="fetching",
          :label="$t('.fields.fleetManagerNotification.label')"
          data-testid="fleetManagerNotification"
        )
          template(#icon)
            i.icon.fal.fa-envelope
          template(#default)
            .flex.vertical-center
              span.row {{ resource.storeFleetManagerName }}
            fleet-manager-email-history(
              :service-order-id="serviceOrderId",
              :default-email-address="resource.storeFleetManagerEmail",
              data-testid="fleetManagerEmailHistory"
            )

      .column
        info-label.info-label(
          :loading="fetching",
          :label="$t('.fields.estimatedStartAt.label')"
          :empty-text="$t('unregistered.feminine')",
          data-testid="estimatedStartAt"
        )
          template(#icon)
            i.icon.fal.fa-arrow-square-right
          template(#default)
            span {{ $standardTime(resource.estimatedStartAt) }}
            .flex.vertical-center
              facilities-tag.facilities(value="internal")
              span {{ $t('.internal') }}

        info-label.info-label(
          :loading="fetching",
          :label="$t('.fields.estimatedCompletedAt.label')"
          :empty-text="$t('unregistered.feminine')"
          data-testid="estimatedCompletedAt"
        )
          template(#icon)
            i.icon.fal.fa-check-square
          template(#default)
            span.row {{ $standardTime(resource.estimatedCompletedAt) }}

        info-label.info-label(
          :loading="fetching",
          :label="$t('.fields.executedNotification.label')",
          data-testid="executedNotification"
        )
          template(#icon)
            i.icon.fal.fa-envelope
          template(#default)
            executed-email-history(
              :service-order-id="serviceOrderId",
              :default-email-address="supplier.serviceOrderEmail",
              :can-resend="canResendExecutedEmail",
              data-testid="executedEmailHistory",
              @resend-error="$emit('resend-error')"
            )

    .relations.flex
      .column
        service-order-supplier-card.relation(
          :supplier="resource.supplier",
          :fetching="fetching",
          data-testid="supplierCard"
        )
      .column
        service-order-vehicle-card.relation(
          :vehicle-license-plate="resource.vehicleLicensePlate",
          :vehicle-km="resource.vehicleKm",
          :vehicle="resource.vehicle",
          :loading="fetching",
          data-testid="vehicleCard"
        )

    section.tables
      services-table.services-table(
        :services="resource.services",
        :fetching="fetching"
      )

      products-table.products-table(
        :products="resource.products",
        :fetching="fetching"
      )

    template(v-if="hasFreeOfCharge")
      section.free-of-charge.flex.vertical-center.end
        app-tag.free-tag(
          :value="$t('.freeOfCharge.tag')",
          color="green",
          size="small"
        )
        span {{ $t('.freeOfCharge.text') }}

    section.note-section(v-if="hasNote")
      app-table
        tr
          th.movida-common__app-table-th
            i.note-icon.fas.fa-exclamation-triangle
            | {{ $t('.fields.note.label') }}
        tr
          td.note
            span {{ resource.note }}

    section.total
      total-row(
        :total="resource.total",
        :loading="fetching"
      )

</template>


<script>

import DownloadButton from "../download-button"
import FleetManagerEmailHistory from "../fleet-manager-email-history"
import ProductsTable from "../products-table"
import ExecutedEmailHistory from "../executed-email-history"
import SapSync from "../sap-sync"
import ServiceOrderSupplierCard from "../service-order-supplier-card"
import ServiceOrderVehicleCard from "@/views/stores/tickets/components/service-order-vehicle-card"
import ServicesTable from "../services-table"
import SupplierEmailHistory from "../supplier-email-history"
import TotalRow from "../total-row"

export default {
  name: "InternalMaintenanceServiceOrder",

  components: {
    DownloadButton,
    FleetManagerEmailHistory,
    ProductsTable,
    ExecutedEmailHistory,
    SapSync,
    ServiceOrderSupplierCard,
    ServiceOrderVehicleCard,
    ServicesTable,
    SupplierEmailHistory,
    TotalRow
  },

  props: {
    resource: { type: Object, default: () => ({}) },
    fetching: { type: Boolean, default: false }
  },

  data() {
    return {
      i18nScope: "stores.tickets.service-orders.show.service-order-show.internal-maintenance",

      id:             this.$route.params.id,
      ticketId:       this.$route.params.ticketId,
      serviceOrderId: this.$route.params.serviceOrderId
    }
  },

  computed: {
    // resource alias
    serviceOrder() { return this.resource },

    canResendExecutedEmail() {
      return this.resource.state === "executed" && _.present(this.resource.sapId)
    },

    hasNote() {
      return _.present(this.resource.note)
    },

    needsSAPSync() {
      return _.blank(this.serviceOrder.sapId) && this.serviceOrder.hasCost
    },

    issuedAtTooltipOptions() {
      return {
        content: this.$t(".fields.issuedAt.tooltip"),
        offset:  "4",
        classes: ["preserve-whitespace"]
      }
    },

    supplier() {
      return this.resource.supplier || {}
    },

    hasFreeOfCharge() {
      if (_.present(this.resource.products)) {
        if (this.resource.products.some(product => product.freeOfCharge)) return true
      }

      if (_.present(this.resource.services)) {
        if (this.resource.services.some(service => service.freeOfCharge)) return true
      }

      return false
    },

    breadcrumbLinks() {
      return [
        {
          to:   { name: "storeTickets", params: { id: this.id } },
          text: this.$t(".breadcrumbLinks.tickets")
        },
        {
          to:   { name: "storeTicket", params: { id: this.id, ticketId: this.ticketId } },
          text: this.$t(".breadcrumbLinks.ticket", { id: this.ticketId })
        },
        {
          to: {
            name:   "storeTicketServiceOrder",
            params: {
              id:             this.id,
              ticketId:       this.ticketId,
              serviceOrderId: this.serviceOrderId
            }
          },
          text: this.$t(".breadcrumbLinks.serviceOrder", { id: this.serviceOrderId })
        }
      ]
    }
  }
}

</script>

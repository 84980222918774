<style lang="scss" scoped>

.service-orders-table-header {
  display: grid;
  grid-template-columns: 40px 132px 109px 92px 1fr 124px 100px 64px;
  background-color: $light-gray;
  height: 40px;
  border-bottom: 1px solid $light-gray-3;
  border-top: 1px solid $light-gray-3;

  &.loading {
    border-bottom: 2px solid $light-orange-2;
  }

  .unsortable {
    padding: 0 12px;
  }

  .column {
    font-size: 12px;

    &.state-column {
      .icon {
        font-size: 16px;
        color: $gray-2;
      }
    }

    .text {
      font-family: $secondary-font;
      font-size: 14px;
      color: $dark-gray;
      font-weight: 400;
    }

    .loader-icon {
      font-size: 16px;
      color: $orange;
    }
  }
}

</style>


<template lang="pug">

  .service-orders-table-header(:class="{ loading }")
    .column.state-column.unsortable.flex.vertical-center.center(data-testid="stateColumn")
      i.icon.fas.fa-file-invoice-dollar(v-tooltip.top="{ offset: 4, content: $t('.columns.state.tooltip') }")

    .column.type-column.unsortable.flex.vertical-center(data-testid="typeColumn")
      span.text {{ $t('.columns.type.label') }}

    //- .column(data-testid="typeColumn")
    //-   sortable-table-header-column(
    //-     sort-param="type",
    //-     :sort-order="sortOrder",
    //-     :active="isSortedBy('type')",
    //-     @sort="param => $emit('sort', param)"
    //-   )
    //-     span.text {{ $t('.columns.type.label') }}

    .column.code-column.unsortable.flex.vertical-center(data-testid="codeColumn")
      span.text {{ $t('.columns.code.label') }}

    .column.vetor-column.unsortable.flex.vertical-center(data-testid="vetorColumn")
      span.text {{ $t('.columns.vetor.label') }}

    //- .column(data-testid="supplierColumn")
    //-   sortable-table-header-column(
    //-     sort-param="supplier",
    //-     :sort-order="sortOrder",
    //-     :active="isSortedBy('supplier')",
    //-     @sort="param => $emit('sort', param)"
    //-   )
    //-     span.text {{ $t('.columns.supplier.label') }}

    .column.supplier-column.unsortable.flex.vertical-center(data-testid="supplierColumn")
      span.text {{ $t('.columns.supplier.label') }}

    //- .column(data-testid="totalColumn")
    //-   sortable-table-header-column(
    //-     sort-param="total",
    //-     :sort-order="sortOrder",
    //-     :active="isSortedBy('total')",
    //-     @sort="param => $emit('sort', param)"
    //-   )
    //-     span.text {{ $t('.columns.total.label') }}

    .column.total-column.unsortable.flex.vertical-center(data-testid="totalColumn")
      span.text {{ $t('.columns.total.label') }}

    //- .column(data-testid="createdAtColumn")
    //-   sortable-table-header-column(
    //-     sort-param="createdAt",
    //-     :sort-order="sortOrder",
    //-     :active="isSortedBy('createdAt')",
    //-     @sort="param => $emit('sort', param)"
    //-   )
    //-     span.text {{ $t('.columns.createdAt.label') }}

    .column.created-at-column.unsortable.flex.vertical-center(data-testid="createdAtColumn")
      span.text {{ $t('.columns.createdAt.label') }}

    .column.flex.unsortable.vertical-center.center
      template(v-if="loading")
        i.loader-icon.fas.fa-spin.fa-spinner

</template>


<script>

export default {
  props: {
    loading:   { type: Boolean, default: false },
    sortParam: { type: String, required: true },
    sortOrder: { type: String, required: true }
  },

  data() {
    return {
      i18nScope: "stores.tickets.show.service-orders-table-header"
    }
  },

  methods: {
    isSortedBy(param) {
      return this.sortParam === param
    }
  }
}

</script>

<style lang="scss" scoped>

// .supplier-hierarchy-icon {}

.icon {
  color: $gray-3;
  margin-right: 4px;
  font-size: 16px;
}

</style>


<template lang="pug">

  .supplier-hierarchy-icon(v-tooltip.bottom="tooltip")
    i.icon.fal(
      :class="iconClass",
    )

</template>


<script>

export default {
  name: "SupplierHierarchyIcon",

  props: {
    value: { type: [String, Number], default: null }
  },

  data() {
    return {
      i18nScope: "components.supplier.hierarchy-icon"
    }
  },

  computed: {
    tooltip() {
      return this.$t(`models.supplier.enums.hierarchy.${this.value}`, {
        defaultValue: this.$t("abbr.unavailable")
      })
    },

    iconClass() {
      switch (_.canonic(this.value)) {
        case "head": return "fa-house-user"
        default:
          return "fa-store-alt"
      }
    }
  }

}

</script>

<style lang="scss" scoped>

.index {
  .tickets-table {
    margin-top: 24px;
  }
}

</style>


<template lang="pug">

  .index
    index-table.tickets-table(
      :empty="empty",
      :sortedBy="sortedBy",
      :initializing="initializing",
      :cols-count="6",
      :column-sizes="columnSizes"
    )
      template(#tableHeader)
        tickets-table-header(
          :loading="fetching",
          @sort="param => $emit('sort', param)"
          :sort-param="sortParam",
          :sort-order="sortOrder",
          :column-sizes="columnSizes"
        )

      template(#tableRows)
        service-order-row(
          v-for="serviceOrder in serviceOrders",
          :key="serviceOrder.id",
          :service-order="serviceOrder",
          :loading="fetching",
          :column-sizes="columnSizes"
          @select="$emit('select', serviceOrder.id)"
        )

</template>


<script>

// Mixins
import FetchMixin from "@/mixins/fetch-mixin"

// Components
import TicketsTableHeader from "./tickets-table-header.vue"
import ServiceOrderRow from "./service-order-row.vue"

export default {
  components: {
    TicketsTableHeader,
    ServiceOrderRow
  },

  mixins: [FetchMixin],

  props: {
    vehicle:  { type: Object, required: true },
    sortedBy: { type: String, default: "" }
  },

  data() {
    return {
      i18nScope: "tickets.drafts.vehicles.components.last-tickets.index",

      resource: []
    }
  },

  computed: {
    serviceOrders: {
      get() { return this.resource },

      set(value) { this.resource = value }
    },

    empty() {
      return _.blank(this.resource)
    },

    columnSizes() {
      return "150px 44px 210px 1fr 156px 48px"
    },

    sortParam() {
      return this.sortedBy.split(":")[0]
    },

    sortOrder() {
      return this.sortedBy.split(":")[1] || "asc"
    }
  },

  methods: {
    // @Override FetchMixin
    fetchRequest() {
      return this.$newSdk.vehicles.serviceOrders.all({ vehicleId: this.vehicle.id })
    }
  }
}

</script>

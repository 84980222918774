.<style lang="scss" scoped>

.stores-table-header {
  display: grid;
  // grid-template-columns: 70px 95px 1fr 1fr 1fr 64px 64px 64px;
  grid-template-columns: 70px 95px 1fr 1fr 64px 64px 64px;
  background-color: $light-gray;
  height: 40px;
  border-bottom: 1px solid $light-gray-3;
  border-top: 1px solid $light-gray-3;

  &.loading {
    border-bottom: 2px solid $light-orange-2;
  }

  .unsortable {
    padding: 0 12px;
  }

  .column {
    font-size: 12px;

    .icon {
      font-size: 16px;
      color: $gray-2;
    }

    .text {
      font-family: $secondary-font;
      font-size: 14px;
      color: $dark-gray;
      font-weight: 400;
    }

    .loader-icon {
      font-size: 16px;
      color: $orange;
    }
  }
}

</style>


<template lang="pug">

  .stores-table-header(:class="{ loading }")
    .column(data-testid="idColumn")
      sortable-table-header-column(
        sort-param="id",
        :sort-order="sortOrder",
        :active="isSortedBy('id')",
        @sort="param => $emit('sort', param)"
      )
        span.text #

    .column(data-testid="initialsColumn")
      sortable-table-header-column(
        sort-param="initials",
        :sort-order="sortOrder",
        :active="isSortedBy('initials')",
        @sort="param => $emit('sort', param)"
      )
        span.text {{ $t('.columns.initials.label') }}

    .column(data-testid="nameColumn")
      sortable-table-header-column(
        sort-param="name",
        :sort-order="sortOrder",
        :active="isSortedBy('name')",
        @sort="param => $emit('sort', param)"
      )
        span.text {{ $t('.columns.name.label') }}

    .column(data-testid="cnpjColumn")
      sortable-table-header-column(
        sort-param="cnpj",
        :sort-order="sortOrder",
        :active="isSortedBy('cnpj')",
        @sort="param => $emit('sort', param)"
      )
        span.text {{ $t('.columns.cnpj.label') }}

    //- .column.unsortable.flex.vertical-center(data-testid="addressColumn")
    //-   span.text {{ $t('.columns.address.label') }}

    .column.unsortable.flex.vertical-center(data-testid="phoneColumn")
      i.icon.fas.fa-phone

    .column.unsortable.flex.vertical-center(data-testid="emailColumn")
      i.icon.fas.fa-envelope

    .column.unsortable.flex.vertical-center.center
      template(v-if="loading")
        i.loader-icon.fa.fa-spin.fa-spinner

</template>


<script>

export default {
  props: {
    loading:   { type: Boolean, default: false },
    sortParam: { type: String, required: true },
    sortOrder: { type: String, required: true }
  },

  data() {
    return {
      i18nScope: "stores.index.stores-table-header"
    }
  },

  methods: {
    isSortedBy(param) {
      return this.sortParam === param
    }
  }
}

</script>

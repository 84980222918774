<style lang="scss" scoped>

.vehicle-info-banner {
  background-color: $light-gray;
  border-radius: 8px;
  padding: 12px;

  .row {
    & + .row {
      margin-top: 8px;
    }

    .manufacturer-icon {
      margin-right: 10px;
    }

    .name,
    .year {
      font-family: $secondary-font;
      font-weight: 400;
      font-size: 24px;
    }

    .diamond-icon {
      --size: 8px;

      font-size: var(--size);
      color: $gray-3;
      width: var(--size);
      height: var(--size);
      margin: 10px;

      &.small {
        --size: 4px;
      }
    }

    .plate {
      margin-right: 16px;
    }

    .info {
      margin-right: 16px;
      font-family: $secondary-font;
      font-weight: 400;
      color: $gray-4;

      .icon-container {
        height: 20px;
        width: 20px;
        margin-right: 8px;
        font-size: 14px;
        color: $gray-2;
      }
    }

    .fipe {
      font-family: $primary-monospace-font;
      font-weight: 400;
      font-size: 16px;
      color: $gray-4;
    }
  }
}

</style>


<template lang="pug">

  .vehicle-info-banner
    .row.flex.vertical-center
      vehicle-manufacturer-icon.manufacturer-icon(
        :value="manufacturer.name",
        :size="24"
      )
      app-span.name(:value="name", crop)
      i.diamond-icon.fas.fa-diamond
      app-span.year(:value="vehicle.year")

    .row.flex.vertical-center
      vehicle-plate.plate(:value="vehicle.licensePlate")

      .info.flex.vertical-center
        .icon-container.flex.center.vertical-center
          i.icon.fas.fa-swatchbook
        span {{ vehicle.colorName }}

      .info.flex.vertical-center
        .icon-container.flex.center.vertical-center
          i.icon.fas.fa-tachometer-alt-average
        span {{ kilometrage }}

      .info.flex.vertical-center
        .icon-container.flex.center.vertical-center
          i.icon.fas.fa-gas-pump

        template(v-if="hasElectricEngineSource")
          app-span.text(:value="$t(`models.vehicleModel.enums.typePowerSource.${vehicleModel.type}`)")

          i.diamond-icon.fa.fa-diamond(v-if="isHybrid")

        template(v-if="vehicleModel.fuel")
          app-span.text(
            data-testid="vehicleModelFuel",
            :value="$t(`models.vehicleModelCombustion.enums.fuel.${vehicleModel.fuel}`)"
          )

      app-span.fipe(:value="fipe")

</template>


<script>

// Models
import models from "@/models"
const { VehicleModel, VehicleModelHybrid } = models

export default {
  props: {
    vehicle: { type: Object, required: true }
  },

  data() {
    return {
      i18nScope: "tickets.drafts.components.vehicle-info-banner",

      VehicleModel,
      VehicleModelHybrid
    }
  },

  computed: {
    vehicleModel() {
      return _.get(this.vehicle, "vehicleModel") || {}
    },

    version() {
      return _.get(this.vehicleModel, "mobiautoVersion") || {}
    },

    model() {
      return _.get(this.version, "model") || {}
    },

    manufacturer() {
      return _.get(this.model, "manufacturer") || {}
    },

    name() {
      return `${this.manufacturer.name} ${this.model.name} ${this.version.name}`
    },

    fipeId() {
      return _.get(this.vehicle, "fipeId")
    },

    kilometrage() {
      if (_.blank(this.vehicle.km)) return this.$t("unregistered.masculine")

      return this.$t(".km", { kilometrage: this.$asNumber(this.vehicle.km, { precision: 0 }) })
    },

    isHybrid() {
      return this.vehicleModel.type === "VehicleModels::Hybrid"
    },

    isElectric() {
      return this.vehicleModel.type === "VehicleModels::Electric"
    },

    hasElectricEngineSource() {
      return ["VehicleModels::Electric", "VehicleModels::Hybrid"].includes(this.vehicleModel.type)
    },

    fipe() {
      return this.$t(".fipe", { fipe: this.fipeId })
    }
  }
}

</script>

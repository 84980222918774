<style lang="scss" scoped>

.labor-cost-linked-indicator {
  .icon {
    font-size: var(--size);
  }

  .text {
    font-family: $secondary-font;
    font-size: 14px;
    font-weight: 500;

    margin-left: 8px;
  }
}

</style>


<template lang="pug">

  .labor-cost-linked-indicator.flex.vertical-center.center(:style="cssVars")
    i.icon.fa-fist-raised(
      v-tooltip.bottom="tooltip",
      :class="iconClass",
      data-testid="laborCostLinkedIndicatorIcon"
    )

    template(v-if="!compact")
      span.text {{ text }}

</template>


<script>

export default {
  props: {
    size:     { type: Number, default: 16 },
    disabled: { type: Boolean, default: false },
    compact:  { type: Boolean, default: false }
  },

  data() {
    return {
      i18nScope: "services.components.labor-cost-linked-indicator"
    }
  },

  computed: {
    text() {
      return this.$t(".text")
    },

    tooltip() {
      if (!this.compact) return

      return this.text
    },

    cssVars() {
      return {
        "--size": `${this.size}px`
      }
    },


    iconClass() {
      return this.disabled ? "fal" : "fas"
    }
  }
}

</script>

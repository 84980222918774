<style lang="scss" scoped>

.edit-service-package {
  padding-bottom: 24px;

  .header {
    margin-bottom: 32px;
    padding-bottom: 16px;
    border-bottom: 1px solid $light-gray-3;

    .title {
      font-size: 30px;
      font-weight: 500;
      font-family: $secondary-font;
    }
  }

  .fields {
    margin-bottom: 24px;
    padding-bottom: 24px;
    border-bottom: 1px solid $light-gray-3;
  }
}

</style>


<template lang="pug">

  .edit-service-package
    header.header
      h1.title {{ $t('.title') }}

    form.form(@submit.prevent="submit", method="post")
      form-fields.fields(
        :resource="resource",
        :submitting="submitting",
        :fetching="fetching",
        :errors="errors",
        :initial-product-ids="initialProductIds",
        :initial-service-ids="initialServiceIds"
      )

      section.flex.space-between.vertical-center
        app-button(
          :to="{ name: 'servicePackage', params: { id: this.servicePackageId } }",
          theme="gray",
          outline,
          :loading="submitting",
          :disabled="fetching"
        ) {{ $t('btn.cancel') }}

        app-button.button(
          type="submit",
          :loading="submitting",
          :disabled="fetching"
        )
          span {{ $t('btn.update.label') }}
          span(slot="loading") {{ $t('btn.update.loading') }}

</template>


<script>

// Mixins
import { mixins } from "movida-common.vue"
import FetchMixin from "@/mixins/fetch-mixin"

// Components
import FormFields from "./form-fields"

// Extends
import LoadedView from "@/views/loaded-view"

const { FormMixin } = mixins

export default {
  name: "EditServicePackage",

  components: {
    FormFields
  },

  extends: LoadedView,

  mixins: [FormMixin, FetchMixin],


  data() {
    return {
      i18nScope: "service-packages.edit",
      routeName: "editServicePackage",

      servicePackageId: null,

      resource: {}
    }
  },

  computed: {
    initialProductIds() {
      return _.get(this.initialSerializedAttributes, "product_ids")
    },

    initialServiceIds() {
      return _.get(this.initialSerializedAttributes, "service_ids")
    },

    services() {
      return this.resource.services
    },

    products() {
      return this.resource.products
    }
  },

  watch: {
    services() {
      this.resource.serviceIds = this.resource.services
        .filter(service => service._destroy !== true)
        .map(service => service.id)
    },

    products() {
      this.resource.productIds = this.resource.products
        .filter(product => product._destroy !== true)
        .map(product => product.id)
    }
  },

  methods: {
    parseRoute() {
      this.servicePackageId = this.$params.asInteger(this.$route.params.id)
    },

    // @override Form mixin
    serializeAttributes() {
      return this.resource.$serialize()
    },

    // @override Form mixin
    submitRequest() {
      return this.$newSdk.servicePackages.update({ servicePackageId: this.servicePackageId, params: this.serializeAttributes() })
    },

    // @override Form mixin
    submitSuccess(response) {
      this.$notifications.info(this.$t(".notifications.submit.success", { name: this.resource.name }))

      this.$router.push({ name: "servicePackage", params: { id: this.servicePackageId } })
    },

    // @override Form mixin
    submitError(err) {
      this.$err.log(err)
      this.$notifications.error(this.$t(".notifications.submit.error"))
    },

    // @override FetchMixin mixin
    fetchRequest() {
      return this.$newSdk.servicePackages.find({ servicePackageId: this.servicePackageId })
    },

    // @override FetchMixin mixin
    onFetchSuccess({ data }) {
      this.resource = data
      this.initialSerializedAttributes = this.serializeAttributes()
    }
  }
}

</script>

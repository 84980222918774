<style lang="scss" scoped>

.error {
  display: block;
  text-align: center;
  padding: 4px 12px;
  font-size: 14px;
  font-style: italic;

  .link {
    margin-left: 5px;
    font-size: 14px;
    font-style: italic;
    font-weight: normal;
  }
}

.empty {
  display: block;
  text-align: center;
  padding: 4px 12px;
  font-size: 14px;
  font-style: italic;
}

.content-column {
  overflow: hidden;
  padding-right: 12px;
  font-size: 14px;
  font-weight: 400;

  .diamond-icon {
    font-size: 4px;
    font-weight: 900;
    color: $gray-3;
    width: 4px;
    height: 4px;
    margin: 4px;
  }
}

::v-deep .listed-option + .listed-option {
  border-top: 1px solid $light-gray-3;
}

</style>


<template lang="pug">

  .occurrence-select-field
    select-field(
      :name="name",
      :label="label",
      :fetching="fetching",
      :disabled="disabled",
      :optional="optional",
      :tooltip="tooltip",

      option-as-value,
      :loading="loading || fetchingOption",
      :options="options",
      :default-filter="false",
      :errors="errors",
      searchable,

      :value="option",
      @input="onSelect",

      @search="onSearch",
      @open="_fetch",
      @close="onClose",
      @inputSearch="(newSearch) => search = newSearch"
    )
      template(#menuContentHeader)
        template(v-if="fetchError")
          label.error
            span {{ $t('.error.message') }}
            app-button.link(theme="link", @click="onSearch") {{ $t('.error.link') }}

      template(#menuContentFooter)
        template(v-if="!fetching && !fetchError && empty")
          label.empty {{ $t('.empty') }}

      template(#option="{ props: { option, index, focus, active } }")
        occurrence-option(
          :occurrence="option.data",
          :active="active",
          :focus="focus"
        )

      template(#menuContentFooter)
        template(v-if="!fetching && !fetchError && empty")
          label.empty {{ $t('.empty') }}

</template>


<script>

// Extends
import AsyncSelect from "@/mixins/select/async"

// Mixins
import FieldMixin from "@/mixins/field"

// Components
import OccurrenceOption from "./occurrence-select-field/occurrence-option"

export default {
  name: "OccurrenceSelectField",

  components: {
    OccurrenceOption
  },

  extends: AsyncSelect,

  mixins: [FieldMixin],

  props: {
    tooltip:   { type: [String, Object], default: null },
    vehicleId: { type: [String, Number], required: true }
  },

  data() {
    return {
      i18nScope: "tickets.drafts.vehicles.components.occurrence-select-field",

      fetchingOption: false,
      fetchError:     false
    }
  },

  methods: {
    // @overrides extends AsyncSelect
    dataToOption(data) {
      return {
        value: data.id,
        label: data.id,
        data
      }
    },

    // @overrides extends AsyncSelect
    async fetchOption(id) {
      try {
        this.fetchingOption = true

        const { data } = await this.$newSdk.vehicles.occurrences.all({ vehicleId: this.vehicleId, params: { active: true } })
        const occurrence = data.find(entry => entry.id === id)

        this.option = !!occurrence ? this.dataToOption(occurrence) : null
        this.$emit("change-option", this.option)
      }
      catch (err) {
        this.$err.log(err)
      }
      finally {
        this.fetchingOption = false
      }
    },

    // @overrides extends AsyncSelect
    fetchRequest() {
      return this.$newSdk.vehicles.occurrences.all({ vehicleId: this.vehicleId, params: { q: this.search, active: true } })
    },

    // @overrides extends AsyncSelect
    onFetchSuccess({ data }) {
      const fetchedOptions = data.map(instance => (
        this.dataToOption(instance)
      ))

      this.options =  [...fetchedOptions]
    },

    // @overrides extends AsyncSelect
    onFetchError(error) {
      this.$err.log(error)
    }
  }
}

</script>

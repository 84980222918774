<style lang="scss" scoped>

.heading {
  font-family: $secondary-font;
  font-size: 20px;
  font-weight: 400;
}

.supplier-deal-products-data-loads {
  max-height: 600px;

  .empty,
  .error-message {
    font-style: italic;
  }

  .data-load {
    height: 55px;

    & + .data-load {
      border-top: 1px solid $light-gray-3;
    }

    .file-icon {
      width: 24px;
      height: 24px;
      margin-right: 24px;
      margin-left: 12px;

      .icon {
        font-size: 24px;
      }
    }

    .content {
      height: 100%;
      overflow: hidden;

      .info {
        font-size: 14px;
        overflow: hidden;

        .name-link {
          max-width: 100%;

          .name {
            font-family: $secondary-font;
            font-size: 14px;
            font-weight: 400;
            color: $dark-gray;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;

            &:hover {
              color: $orange;
            }
          }
        }

        .secondary {
          .diamond-icon {
            margin: 0 8px;
            font-size: 4px;
            color: $gray-3;
          }
        }
      }

      .status {
        height: 24px;
        width: 24px;
        margin-right: 12px;
        margin-left: 12px;

        .icon {
          font-size: 16px;

          &.success {
            color: $dark-success;
          }

          &.error {
            color: $error;
          }
        }
      }
    }
  }

  .paginator-content {
    padding-top: 24px;

    &.loading {
      max-width: 400px;
      margin: 0 auto;
    }
  }
}

</style>


<template lang="pug">

  app-modal(
    :width="996",
    @close="goBack"
  )
    template(#header)
      header.header(:key="`modal-header-${uuid()}`")
        h1.heading {{ $t('.heading') }}

    .body.supplier-deal-products-data-loads(:key="`modal-${uuid()}`")
      template(v-if="fetching")
        loading-lines(:lines="4")

      template(v-else-if="hasFetchError")
        span.error-message {{ $t('.error.message') }}

      template(v-else-if="empty")
        span.empty {{ $t('.empty.message') }}

      template(v-else)
        .data-load.flex.vertical-center(
          v-for="dataLoad in resource",
          :key="`dataLoad-${dataLoad.id}`",
          data-testid="uploadRow"
        )
          .file-icon.no-shrink
            i.icon.fal.fa-file-excel
          .content.grow.flex.vertical-center
            .info.grow
              app-button.name-link(
                theme="link",
                :to="showRoute(dataLoad)"
              )
                span.name {{ dataLoad.fileName }}
              .secondary.flex
                span {{ $asHumanSize(dataLoad.fileSize) }}
                i.diamond-icon.fa.fa-diamond.flex.center.vertical-center
                span {{ $fromNow(dataLoad.createdAt) }}

            .status.flex.center.vertical-center.no-shrink
              i.icon(:class="iconClassFor(dataLoad)")

        .paginator-content(v-if="fetching || showPaginator", :class="{ loading: fetching }")
          paginator(
            :loading="fetching",
            :per-page="pagination.perPage",
            :total="pagination.total",
            @input="onChangePage",
            :value="params.page",
            :disabled="fetching"
          )

</template>


<script>

// Libs
import { v4 as uuid } from "uuid"

// Extends
import SearchableIndexView from "@/views/searchable-index-view"

export default {
  name: "SupplierDealProductsDataLoads",

  components: {},

  extends: SearchableIndexView,

  props: {
    backRouteQuery: { type: Object, default: () => ({}) }
  },

  data() {
    return {
      i18nScope: "suppliers.deals.products.data-loads.index",

      id:     this.$params.asInteger(this.$route.params.id),
      dealId: this.$params.asInteger(this.$route.params.dealId),

      // @override Fetchable mixin
      resource: {},
      model:    "DealDataLoad",

      // SearchableIndexView
      parseQueryParams: {
        page: "integer"
      },

      uuid
    }
  },

  computed: {
    backRoute() {
      return {
        name:   "supplierDealProducts",
        params: { id: this.id, dealId: this.dealId },
        query:  this.backRouteQuery
      }
    }
  },

  methods: {
    iconClassFor(entry) {
      switch (entry.status) {
        case "scheduled":
        default:
          return "fal fa-hourglass"
        case "running":
          return "fas fa-spinner fa-spin"
        case "done":
          return "fas fa-check-circle success"
        case "error":
          return "fas fa-times-circle error"
      }
    },

    showRoute(dataLoad) {
      return {
        name:   "supplierDealProductsDataLoad",
        params: { id: this.id, dealId: this.dealId, dataLoadId: dataLoad.id }
      }
    },

    // @override extends view.vue
    goBack() {
      // Voltando para rota pai ao fechar o modal
      this.$router.push(this.backRoute)
    },

    // @override Fetchable mixin
    fetch() {
      return this.$sdk.deals.listProductsDataLoads({ id: this.dealId, params: this.httpParams })
    },

    // @override Fetchable mixin
    onFetchSuccess(response) {
      this.setPagination(response.headers)
    },

    // @override Fetchable mixin
    onFetchError(err) {
      this.$err.log(err)
      this.$notifications.error(this.$t(".notifications.fetch.failure"))
    }
  }
}

</script>

<script>

// Extends
import SpreadsheetDownloadModal from "@/views/suppliers/deals/products/index/spreadsheet-download-modal"

// Components
import TemplateDownloadButton from "./template-download-button"
import CurrentSpreadsheetDownloadButton from "./current-spreadsheet-download-button"

export default {
  components: {
    TemplateDownloadButton,
    CurrentSpreadsheetDownloadButton
  },

  extends: SpreadsheetDownloadModal
}

</script>

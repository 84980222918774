<style scoped lang="scss">

.photo-content {
  margin-right: 20px;
  margin-left: 4px;
  text-align: center;
}

.supplier-row {
  padding: 16px 12px;
  border-top: 1px solid $light-gray-3;
  border-bottom: 1px solid $light-gray-3;
}

.supplier-row + .supplier-row {
  border-top: 1px solid transparent;
}

.content {
  flex-grow: 1;
  overflow: hidden;
}

.main-content {
  margin-bottom: 4px;
}

.fa-check-circle {
  color: $dark-green;
}

.fa-times-circle  {
  color: $dark-warning;
}

.name {
  font-family: $secondary-font;
  font-size: 18px;
  font-weight: normal;
  color: $dark-gray;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  &.link {
    &:hover {
      color: $primary;
    }
  }
}

.cnpj {
  font-family: $primary-monospace-font;
}

.secondary-text {
  font-size: 14px;
}

.divisor {
  margin: 0 8px;
  font-size: 4px;
  color: $gray-3;
}

.contacts {
  margin-right: 36px;
}

.contact-icon {
  margin-right: 8px;
}

.status {
  margin: 0 26px 0 14px;

  .icon {
    font-size: 20px;
    width: 20px;
    height: 20px;
  }
}

.schedule {
  margin-left: 8px;
  overflow: hidden;
}

</style>


<template lang="pug">

  .supplier-row.flex.vertical-center
    .status
      i.icon.fal(:class="statusIcon.class", v-tooltip.bottom="statusIcon.tooltip")
    .photo-content
      avatar(placeholder="fas fa-user-hard-hat")
      template(v-if="!hideHierarchy")
        .tags
          hierarchy-code-tag(:value="supplier.hierarchy", size="small")

    .content
      .main-content.flex.vertical-center
        slot(name="name")
          template(v-if="hideLinkName")
            span.name {{ supplier.tradingName || supplier.name }}
          template(v-else)
            router-link.name.link(
              :to="{ name: 'supplier', params: { id: supplier.id } }"
            ) {{ supplier.tradingName || supplier.name }}
        i.fas.fa-diamond.divisor
        label.cnpj {{ supplier.cnpj }}
        i.fas.fa-diamond.divisor
        sap-code-tag(:value="supplier.sapId")
        .schedule
          supplier-block-schedule(:supplier="supplier", @destroy="() => $emit('modified')")

      .address-content.flex.vertical-center(v-if="supplier.address")
        label.address.secondary-text {{ supplier.address.addressLine1 }}

      slot(name="thirdRow")

    //- .contacts.text-right
    //-   .phone-content
    //-     i.fal.fa-phone.contact-icon
    //-     span.secondary-text (11) 9 9568-6589
    //-   .email-content
    //-     i.fal.fa-envelope.contact-icon
    //-     span.secondary-text email@example.com

    .actions(v-if="hasActionsSlot")
      slot(name="actions")

</template>


<script>

export default {
  name: "BaseSupplierRow",

  props: {
    supplier:      { type: Object, default: () => ({}) },
    hideHierarchy: { type: Boolean, default: false },
    hideLinkName:  { type: Boolean, default: false }
  },

  data() {
    return {
      i18nScope: "components.supplier.base-supplier-row"
    }
  },

  computed: {
    statusIcon() {
      if (this.supplier.blocked) {
        return {
          class:   "fa-times-circle blocked",
          tooltip: this.$t(".tooltip.status.blocked")
        }
      }
      return {
        class: "fa-check-circle"
      }
    },

    hasActionsSlot() {
      return !!this.$slots.actions
    }
  }
}

</script>

<!--
<doc lang="markdown">
Botão para popover-menu

Como usar:
```pug
  popover-menu(:show.sync="showPopover")
    popover-menu-button(
      :to="{ name: 'storeTicket', params: { id, ticketId: ticket.id } }",
      icon="fal fa-eye",
      :text="$t('.actions.btn.show')",
      @close-menu="showPopover = false"
    )
```

</doc>
-->

<style scoped lang="scss">

.button-menu.disabled {
  color: $gray-2;
  background: $light-gray-2;

  &:hover,
  &:active,
  &:visited {
    color: $gray-2;
    background: $light-gray-2;
  }
}

</style>


<template lang="pug">

  app-button.popover-menu-button(
    :to="to",
    theme="link",
    border="square",
    :loading="loading",
    :disabled="disabled",
    @click="$emit('click')"
  )
    slot
      .button-menu.flex.vertical-center.full-width(:class="{ disabled }" @click="$emit('close-menu')")
        .icon-content(v-if="icon")
          i.icon(:class="{ [icon]: true }")
        span {{ text }}

</template>


<script>

export default {
  name: "PopoverMenuButton",

  props: {
    disabled: { type: Boolean, default: false },
    loading:  { type: Boolean, default: false },
    icon:     { type: String, default: null },
    text:     { type: String, default: null },
    to:       { type: Object, default: null }
  },

  data() {
    return {
      i18nScope: "components.popover-menu-button"
    }
  }
}

</script>

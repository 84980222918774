<style scoped lang="scss">

.deal-product-row {
  display: grid;
  grid-template-columns: 40px 1fr 1fr 140px 112px 64px;

  &.loading {
    .column {
      &.actions-column {
        ::v-deep .movida-common__app-button {
          color: $gray-2;

          &:hover {
            color: $orange;
          }
        }
      }

      .text,
      .link ::v-deep .app-span {
        color: $gray-2;
      }
    }
  }

  .column {
    padding: 0 12px;
    border-bottom: 1px solid $light-gray-3;
    height: 48px;
    overflow: hidden;

    &.vehicle-model-group-column,
    &.product-column {
      .discarded-icon {
        margin-right: 4px;
      }
    }

    &.price-column {
      .text {
        font-family: $secondary-font;
      }
    }

    .tag {
      margin-left: 4px;
    }

    ::v-deep .movida-common__app-button {
      white-space: unset;
    }

    .button {
      overflow: hidden;
    }

    .text,
    .link ::v-deep .app-span {
      font-family: $primary-font;
      font-weight: 400;
      font-size: 14px;
      color: $dark-gray;
      text-align: initial;
    }

    .link ::v-deep .app-span:hover {
      color: $orange;
    }
  }
}

</style>


<template lang="pug">

  .deal-product-row(:class="{ loading }")
    .column.type-column.flex.vertical-center.center
      product-type-icon.icon(:type="product.type", :size="16")

    .column.product-column.flex.vertical-center
      discarded-icon.discarded-icon(v-if="product.$discarded")
      app-button.button(
        theme="link",
        :to="showRoute"
      )
        app-span.link(
          :value="product.name",
          crop,
          data-testid="dealProductProductName"
        )
      id-tag.tag(:value="product.id", data-testid="dealProductProductId")

    .column.vehicle-model-group-column.flex.vertical-center
      discarded-icon.discarded-icon(v-if="vehicleModelGroup.$discarded")
      app-button.button(
        theme="link",
        :to="showRoute"
      )
        app-span.link(
          :value="vehicleModelGroup.name",
          crop,
          data-testid="dealProductVehicleModelGroupName"
        )
      id-tag.tag(:value="vehicleModelGroup.id", data-testid="dealProductVehicleModelGroupId")

    .column.part-origin-column.flex.vertical-center
      template(v-if="isPart")
        span.text(
          data-testid="dealProductPartOrigin"
        ) {{ $t(`models.dealProduct.enums.partOrigin.${dealProduct.partOrigin}`) }}

      template(v-else)
        span.text -

    .column.price-column.flex.vertical-center
      span.text(
        data-testid="dealProductPrice"
      ) {{ dealProduct.formattedPrice }}

    .column.actions-column.flex.vertical-center
      popover-menu.popover(
        v-if="popoverMenuAuthorization",
        :show.sync="showPopover",
        data-testid="popoverMenu"
      )
        popover-menu-button(
          v-if="$can('read', 'Deal')",
          icon="fal fa-eye",
          :to="showRoute",
          :text="$t('.actions.btn.show.label')",
          @close-menu="showPopover = false"
        )
        popover-menu-button(
          v-if="$can('update', 'Deal') && editable",
          icon="fal fa-pencil",
          :to="editRoute",
          :text="$t('.actions.btn.edit.label')",
          @close-menu="showPopover = false"
        )
        destroy-action.destroy-button.popover-menu-button(
          v-if="editable"
          :name="product.name",
          :deal-product-id="dealProduct.id"
          size="normal",
          border="square",
          @click.native="showPopover = false"
          @destroy="$emit('destroy')"
        )
          .icon-content
            i.icon.fal.fa-trash-alt
          span {{ $t('.actions.btn.destroy.label') }}

</template>


<script>

import DestroyAction from "../components/destroy-action"

export default {
  components: {
    DestroyAction
  },

  props: {
    dealProduct: { type: Object, required: true },
    editable:    { type: Boolean, required: true },
    loading:     { type: Boolean, default: false }
  },

  data() {
    return {
      i18nScope: "suppliers.deals.products.index.deal-product-row",

      supplierId: this.$params.asInteger(this.$route.params.id),
      dealId:     this.$params.asInteger(this.$route.params.dealId),

      showPopover: false
    }
  },

  computed: {
    popoverMenuAuthorization() {
      return ["read", "update"].some(action => this.$can(action, "Deal"))
    },

    product() {
      return _.get(this.dealProduct, "product")
    },

    vehicleModelGroup() {
      return _.get(this.dealProduct, "vehicleModelGroup")
    },

    showRoute() {
      return {
        name:   "supplierDealProduct",
        params: {
          id:            this.supplierId,
          dealId:        this.dealId,
          dealProductId: this.dealProduct.id
        }
      }
    },

    editRoute() {
      return {
        name:   "editSupplierDealProduct",
        params: {
          id:            this.supplierId,
          dealId:        this.dealId,
          dealProductId: this.dealProduct.id
        }
      }
    },

    isPart() {
      return _.get(this.product, "type") === "Part"
    }
  }
}

</script>

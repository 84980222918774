import Vue from "vue"
import { conf } from "utils.vue"

import GoogleMapsPlugin from "@/lib/google-maps/vue-plugin"

const key = _.get(conf, "googleMaps.key")

if (_.present(key)) {
  Vue.use(GoogleMapsPlugin, { key })
}

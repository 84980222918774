<script>

import AttachmentPreview from "@/components/attachments/attachment-preview.vue"

export default {
  extends: AttachmentPreview,

  props: {
    serviceOrderId: { type: [String, Number], required: true }
  },

  methods: {
    // @override Form mixin
    submitRequest() {
      return this.$newSdk.serviceOrders.drafts.attachments.update({
        serviceOrderId: this.serviceOrderId,
        attachmentId:   this.attachment.id,
        params:         this.serializeAttributes()
      })
    }
  }
}

</script>

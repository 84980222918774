import Base from "../base"

class Service extends Base {
  static get attrs() {
    return _.uniq([
      ...super.attrs,
      "sapId",
      "name",
      "discarded"
    ])
  }
}

class MovidaVehicleServiceOrderService extends Base {
  static get attrs() {
    return _.uniq([
      ...super.attrs,
      "remoteId",
      "provider",
      "quantity",
      "price",
      "totalPrice",
      "discount"
    ])
  }

  static get relations() {
    return {
      service:      { type: "hasOne", model: Service },
      serviceOrder: { type: "hasOne", model: "MovidaServiceOrder" }
    }
  }
}

export default MovidaVehicleServiceOrderService

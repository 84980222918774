// Modules
import auth from "@/modules/auth"
import { ability } from "@/modules/ability"

// Routes
import maintenancePackages from "./maintenance-packages"
import monitoring from "./monitoring"
import products from "./products"
import serviceOrders from "./service-orders"
import servicePackages from "./service-packages"
import services from "./services"
import settings from "./settings"
import stores from "./stores"
import suppliers from "./suppliers"
import tickets from "./tickets"
import ticketTypes from "./ticket-types"
import manageResources from "./manage-resources"
import vehicleModelGroups from "./vehicle-model-groups"
import vehicleModels from "./vehicle-models"
import vehicleParts from "./vehicle-parts"
import vehicles from "./vehicles"

import Login from "@/views/login.vue"
import ServerError from "@/components/server-error.vue"
import NotFound from "@/components/not-found.vue"
import Forbidden from "@/components/forbidden.vue"

const routes = [
  ...maintenancePackages,
  ...monitoring,
  ...products,
  ...serviceOrders,
  ...servicePackages,
  ...services,
  ...settings,
  ...stores,
  ...suppliers,
  ...tickets,
  ...ticketTypes,
  ...manageResources,
  ...vehicleModelGroups,
  ...vehicleModels,
  ...vehicleParts,
  ...vehicles,

  {
    path:      "/login",
    name:      "login",
    component: Login,
    meta:      { layout: "blank" }
  },

  {
    path:      "/500",
    alias:     ["/serverError"],
    name:      "serverError",
    component: ServerError,
    meta:      { layout: "blank" }
  },

  {
    path:      "/403",
    alias:     ["/forbidden"],
    name:      "forbidden",
    component: Forbidden,
    meta:      { layout: "blank" }
  },

  {
    path:     "/",
    alias:    ["/home"],
    name:     "root",
    redirect: () => {
      if (!auth.isAuthenticated()) return "/login"

      const baseRoutes = [
        { model: "Supplier",           route: "/suppliers"            },
        { model: "Store",              route: "/stores"               },
        { model: "Ticket",             route: "/tickets"              },
        { model: "Product",            route: "/products"             },
        { model: "VehicleModel",       route: "/vehicle-models"       },
        { model: "VehicleModelGroup",  route: "/vehicle-model-groups" },
        { model: "MaintenancePackage", route: "/maintenance-packages" },
        { model: "MovidaServiceOrder", route: "/monitoring"           },
        { model: "VehiclePart",        route: "/vehicle-parts"        },
        { model: "Service",            route: "/services"             },
        { model: "ServicePackage",     route: "/service-packages"     }
      ]

      // XXX: Redireciona para a primeira index autorizada, ou /forbidden
      const authorized = baseRoutes.find(({ model }) => ability.can("index", model)) || { route: "/forbidden" }
      return authorized.route
    }
  },

  {
    path:      "*",
    // alias:     ["/404"],
    name:      "notFound",
    component: NotFound,
    meta:      { layout: "blank" }
  }
]


export default routes
